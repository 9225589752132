import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import useTypedContext from "hooks/useTypedContext";
import { FiltersContext } from "components/Filters";
import { URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";
import { decodeURIParam } from "utils/urls";
import { SearchQueryOrderDirection } from "types/generated";

import { getSessionStorageKeys } from "../helpers";

const useFiltersSortHeader = () => {
  const storage = sessionStorage;

  const [searchParams, setSearchParams] = useSearchParams();
  const { sortOptions, initialSortOption, initialSortDirection, filtersOrderSettingsKey } =
    useTypedContext(FiltersContext);
  const { storageUrlSortDirection, storageUrlSortKey } =
    getSessionStorageKeys(filtersOrderSettingsKey);

  const [querySortOption, querySortDirection] = useMemo(() => {
    const querySortOption = decodeURIParam(searchParams.get(URL_SORT_KEY)) || initialSortOption;
    const querySortDirection = (decodeURIParam(searchParams.get(URL_SORT_DIRECTION)) ||
      initialSortDirection) as SearchQueryOrderDirection;

    return [querySortOption, querySortDirection];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleSortOptionChange = useCallback(
    (option: string, direction: SearchQueryOrderDirection, clb?: () => void) => {
      searchParams.set(URL_SORT_KEY, encodeURIComponent(option));
      searchParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
      storage.setItem(storageUrlSortKey, encodeURIComponent(option));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));

      setSearchParams(searchParams);

      clb?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  const handleSortDirectionChange = useCallback(
    (option: string, direction: SearchQueryOrderDirection, clb?: () => void) => {
      const newDirection =
        direction === SearchQueryOrderDirection.Asc
          ? SearchQueryOrderDirection.Desc
          : SearchQueryOrderDirection.Asc;
      searchParams.set(URL_SORT_KEY, encodeURIComponent(option));
      searchParams.set(URL_SORT_DIRECTION, encodeURIComponent(newDirection));
      storage.setItem(storageUrlSortKey, encodeURIComponent(option));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(newDirection));

      setSearchParams(searchParams);

      clb?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  const handleSortItemClick = useCallback(
    (value: string) => () => {
      if (value === querySortOption) {
        handleSortDirectionChange(value, querySortDirection);
      } else {
        handleSortOptionChange(value, querySortDirection);
      }
    },
    [querySortDirection, querySortOption, handleSortDirectionChange, handleSortOptionChange]
  );

  return {
    sortOptions,
    querySortOption,
    querySortDirection,
    onItemClick: handleSortItemClick,
  };
};

export default useFiltersSortHeader;
