import { useMemo } from "react";

import {
  LogoAzureDevOpsColored,
  LogoBitbucketColored,
  LogoGitColored,
  LogoGitHub,
  LogoGitLabColored,
} from "components/icons";
import { VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";

const vcsProviderConfig = [
  {
    type: VcsProvider.Github,
    name: HUMANIZE_PROVIDER[VcsProvider.Github],
    logo: LogoGitHub,
  },
  {
    type: VcsProvider.GithubEnterprise,
    name: HUMANIZE_PROVIDER[VcsProvider.GithubEnterprise],
    logo: LogoGitHub,
  },
  {
    type: VcsProvider.Git,
    name: HUMANIZE_PROVIDER[VcsProvider.Git],
    logo: LogoGitColored,
  },
  {
    type: VcsProvider.Gitlab,
    name: HUMANIZE_PROVIDER[VcsProvider.Gitlab],
    logo: LogoGitLabColored,
  },
  {
    type: VcsProvider.AzureDevops,
    name: HUMANIZE_PROVIDER[VcsProvider.AzureDevops],
    logo: LogoAzureDevOpsColored,
  },
  {
    type: VcsProvider.BitbucketCloud,
    name: HUMANIZE_PROVIDER[VcsProvider.BitbucketCloud],
    logo: LogoBitbucketColored,
  },
  {
    type: VcsProvider.BitbucketDatacenter,
    name: HUMANIZE_PROVIDER[VcsProvider.BitbucketDatacenter],
    logo: LogoBitbucketColored,
  },
];

export const useVcsProviderConfig = (supportedProviders?: VcsProvider[]) => {
  const vcsProviderConfigFiltered = useMemo(
    () =>
      supportedProviders
        ? vcsProviderConfig.filter(({ type }) => supportedProviders.includes(type))
        : [],
    [supportedProviders]
  );

  return vcsProviderConfigFiltered;
};
