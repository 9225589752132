import { Maybe } from "types/generated";

import styles from "../styles.module.css";

type EnvironmentVariableProps = {
  name: string;
  value: Maybe<string>;
};

const EnvironmentVariable = ({ name, value }: EnvironmentVariableProps) => (
  <>
    <div className={styles.variableName}>{name}</div>
    <div className={styles.variableValue}>{value || "*****"}</div>
  </>
);

export default EnvironmentVariable;
