import { ReactNode, createContext, useCallback } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature, SearchVcsIntegrationsOutput, VcsProvider } from "types/generated";
import useTierFeature from "views/Account/hooks/useTierFeature";

import { GET_DEFAULT_VCS_INTEGRATIONS } from "./gql";

type VCSIntegrationsListContextProps = {
  isDefaultIntegrationsLoading: boolean;
  isSetupDisabled: (provider: VcsProvider) => boolean;
  refetchDefaultIntegrations: () => void;
};

export const VCSIntegrationsListContext = createContext<
  VCSIntegrationsListContextProps | undefined
>(undefined);
VCSIntegrationsListContext.displayName = "VCSIntegrationsListContext";

type VCSIntegrationsListContextProviderProps = {
  children: ReactNode;
};

export const VCSIntegrationsListContextProvider = ({
  children,
}: VCSIntegrationsListContextProviderProps) => {
  const isMultiVCSFeatureEnabled = useTierFeature(BillingTierFeature.MultipleVcs);

  const { onError } = useTypedContext(FlashContext);

  const { data, loading, refetch } = useQuery<{
    searchVCSIntegrations: SearchVcsIntegrationsOutput;
  }>(GET_DEFAULT_VCS_INTEGRATIONS, {
    skip: isMultiVCSFeatureEnabled,
    fetchPolicy: "no-cache",
    onError,
  });

  const refetchDefaultIntegrations = useCallback(() => {
    if (!isMultiVCSFeatureEnabled) {
      refetch();
    }
  }, [isMultiVCSFeatureEnabled, refetch]);

  return (
    <VCSIntegrationsListContext.Provider
      value={{
        isDefaultIntegrationsLoading: loading,
        isSetupDisabled: (provider: VcsProvider) =>
          !isMultiVCSFeatureEnabled &&
          !!data?.searchVCSIntegrations.edges.find((edge) => edge.node?.provider === provider),
        refetchDefaultIntegrations,
      }}
    >
      {children}
    </VCSIntegrationsListContext.Provider>
  );
};
