import { AnsibleTaskStatus } from "types/generated";
import { IconComponent } from "types/Icon";

export enum AnsibleGroupBy {
  Tasks = "Tasks",
  Hosts = "Hosts",
}

export enum Components {
  Text = "Text",
  Group = "Group",
}

export type ConfigNode = {
  id: string;
  name: string;
  count?: number;
  order?: number;
  last?: boolean;
  lastUnderRole?: boolean;
  hasChildrenToLoad?: boolean;
  description?: string;
  status?: AnsibleTaskStatus;
  hasCumulativeStatus?: boolean;
  parent?: string;
  limitMargin?: boolean;
  hasMiddleGroup?: boolean;
  timestamp?: number;
  checkMode?: boolean;
  group?: Array<ConfigNode>;
  link?: {
    path: string;
    queryKey?: string;
    queryValue?: string;
  };
  hostName?: string;
  playbookName?: string;
  roleName?: string;
  taskName?: string;
  type?: Components;
  diff?: string;
  logs?: string;
  icon?: IconComponent;
  shouldCountOrder?: boolean;
  runId?: string;
  stackId?: string;
};
