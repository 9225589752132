import { useMemo } from "react";

import { AUTO_ATTACHMENT_KEY } from "constants/labels";

/**
 * Extract only the labels related to auto-attachment from the provided list
 */
export const useAutoAttachmentLabels = (allLabels: Record<"value", string>[] | undefined) => {
  return useMemo(
    () => allLabels?.filter((item) => item.value.startsWith(AUTO_ATTACHMENT_KEY)) || [],
    [allLabels]
  );
};
