import Box from "ds/components/Box";
import Tag from "ds/components/Tag";
import Typography from "ds/components/Typography";

type InputNameProps = {
  name: string;
  type: string;
  description: string;
};

const InputName = ({ name, type, description }: InputNameProps) => {
  return (
    <Box direction="column" gap="small">
      <Box gap="medium" align="center">
        <Typography tag="span" variant="p-body2">
          {name}
        </Typography>
        <Tag tag={type} />
      </Box>
      {description && (
        <Typography tag="p" variant="p-body3" color="secondary">
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default InputName;
