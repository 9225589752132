import cx from "classnames";
import { Dialog, DialogProps } from "react-aria-components";

import styles from "./styles.module.css";

type DropdownListNewProps = {
  className?: string;
  children: DialogProps["children"];
};

const DropdownListNew = ({ children, className }: DropdownListNewProps) => {
  return <Dialog className={cx(styles.dropdownList, className)}>{children}</Dialog>;
};

export default DropdownListNew;
