import { gql } from "@apollo/client";

export const GET_STACK_LOGS = gql`
  query GetStackLogsNext(
    $stackId: ID!
    $runId: ID!
    $state: RunState!
    $token: String
    $stateVersion: Int
  ) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        logs(state: $state, token: $token, stateVersion: $stateVersion) {
          exists
          finished
          expired
          hasMore
          messages {
            message
          }
          nextToken
        }
      }
    }
  }
`;

export const GET_MODULE_LOGS = gql`
  query GetModuleLogsNext($moduleId: ID!, $runId: ID!, $state: RunState!, $token: String) {
    module(id: $moduleId) {
      id
      run(id: $runId) {
        id
        logs(state: $state, token: $token) {
          exists
          finished
          hasMore
          expired
          messages {
            message
          }
          nextToken
        }
      }
    }
  }
`;
