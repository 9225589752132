import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import cx from "classnames";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Box from "ds/components/Box";
import Tag from "ds/components/Tag";
import Link from "ds/components/Link";

import styles from "./styles.module.css";
import { isLabelWrapped } from "./helpers";
import { WebhookFormFields } from "../types";

const LabelsField = () => {
  const [isLabelsListExpanded, setLabelsListExpanded] = useState(false);
  const [isToggleControlVisible, setToggleControlVisibility] = useState(false);
  const [labelFieldValue, setLabelFieldValue] = useState("");
  const labelsListRef = useRef<HTMLDivElement>(null);
  const lastLabelRef = useRef<HTMLDivElement>(null);

  const {
    clearErrors,
    formState: { errors },
    setError,
    getValues,
  } = useFormContext<WebhookFormFields>();

  const { fields, append, remove } = useFieldArray<WebhookFormFields>({
    name: "labels",
  });

  const handleCreateLabel = (e: KeyboardEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const labels = getValues("labels");

    // labels validation
    if (labels.some((item) => item.value === value)) {
      setError("labels", {
        type: "manual",
        message: `Label "${value}" already added`,
      });
    } else {
      clearErrors("labels");
    }

    // set new label
    if (e.key === "Enter" && value && !errors.labels) {
      append({ value });
      (e.target as HTMLInputElement).value = "";
    }
  };

  const handleRemoveLabel = (index: number) => () => {
    remove(index);
  };

  const toggleLabelsList = () => {
    setLabelsListExpanded(!isLabelsListExpanded);
  };

  const handleUpdateLabel = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target.value;
    setLabelFieldValue(value);
  };

  useEffect(() => {
    if (labelsListRef.current && lastLabelRef.current) {
      if (isLabelWrapped(labelsListRef.current, lastLabelRef.current)) {
        setToggleControlVisibility(true);
      } else {
        setToggleControlVisibility(false);
        setLabelsListExpanded(false);
      }
    }
    // Clear label field on drawer change
    setLabelFieldValue("");
  }, [fields]);

  return (
    <>
      <FormField
        label="Labels"
        error={errors?.labels?.message}
        helperText={labelFieldValue.length > 0 ? "Press Enter to add" : ""}
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Add new labels"
            onKeyUp={handleCreateLabel}
            onChange={handleUpdateLabel}
            error={!!errors?.labels?.message}
            value={labelFieldValue}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Box direction="column" align="start">
        <Box
          ref={labelsListRef}
          direction="row"
          className={cx(styles.labels, {
            [styles.expanded]: isLabelsListExpanded,
          })}
        >
          {fields.map((tag, i) => (
            <Tag
              key={tag.id}
              tag={tag.value}
              className={styles.label}
              onRemove={handleRemoveLabel(i)}
              ref={i === fields.length - 1 ? lastLabelRef : null}
            />
          ))}
        </Box>

        {isToggleControlVisible && fields.length > 2 && (
          <Link onClick={toggleLabelsList}>
            {isLabelsListExpanded ? "Hide labels" : "Show all labels"}
          </Link>
        )}
      </Box>
    </>
  );
};

export default LabelsField;
