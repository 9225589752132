import { Stack, StackInput, VcsProvider } from "types/generated";

import { type StackSettingsSourceCodeFormFields } from "./Edit";

export const getFormDefaultValues = (stackSettings: Stack): StackSettingsSourceCodeFormFields => ({
  projectRoot: stackSettings.projectRoot || "",
  branch: stackSettings.branch,
  repositoryURL: stackSettings.repositoryURL || undefined,
  namespace: stackSettings.namespace,
  repository: stackSettings.repository,
  provider: stackSettings.provider,
  vcsIntegrationId: stackSettings.vcsIntegration?.id,
  additionalProjectGlobs: stackSettings.additionalProjectGlobs.length
    ? stackSettings.additionalProjectGlobs.map((value) => ({ value }))
    : [{ value: "" }],
});

export const getProviderEmptyValues = (): Omit<
  StackSettingsSourceCodeFormFields,
  "additionalProjectGlobs"
> => ({
  projectRoot: "",
  branch: "",
  repositoryURL: undefined,
  namespace: "",
  repository: "",
  provider: VcsProvider.Github,
  vcsIntegrationId: undefined,
});

export const mapCreateStackSourceCodeUpdateInput = (
  formData: StackSettingsSourceCodeFormFields
): Partial<StackInput> => {
  return {
    ...formData,
    projectRoot: formData.projectRoot || null,
    repositoryURL: formData.repositoryURL?.trim() || null,
    additionalProjectGlobs: formData.additionalProjectGlobs.map(({ value }) => value),
  };
};
