import { gql } from "@apollo/client";

import { stackVendorConfigFragment } from "components/form/graphql";

/**
 * searchRunIgnoredTriggers used to get only the latest ignored run trigger for the stack
 */
export const LIST_RUNS = gql`
  query ListRuns($stackId: ID!, $before: ID, $hasIgnoredRunsAccess: Boolean!) {
    stack(id: $stackId) {
      id
      blocker {
        id
      }
      canWrite
      searchRunIgnoredTriggers(
        input: { first: 1, orderBy: { field: "createdAt", direction: DESC } }
      ) @include(if: $hasIgnoredRunsAccess) {
        edges {
          node {
            createdAt
            commit {
              hash
              timestamp
              authorLogin
              authorName
              url
              message
            }
            policyAttachments {
              policy {
                id
                name
              }
              policyName
            }
            reasonType
          }
        }
      }
      ...stackVendorConfig
      runs(before: $before) {
        id
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
        }
        createdAt
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
          ignoreCount
          okCount
          rescueCount
          skipCount
          unreachableCount
          failedCount
        }
        finished
        needsApproval
        runtimeConfig {
          terraform {
            version
            workflowTool
          }
          terragrunt {
            terraformVersion
            terragruntVersion
            tool
          }
        }
        state
        title
        triggeredBy
        updatedAt
      }
      trackedCommit {
        url
        hash
      }
      trackedBranchHead {
        url
        hash
      }
      trackedCommitSetBy
      state
    }
  }
  ${stackVendorConfigFragment}
`;
