import cx from "classnames";

import Box, { type BoxProps } from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type DropdownSectionProps = {
  title?: string;
  topDivider?: boolean;
} & Partial<BoxProps>;

const DropdownSection = ({
  children,
  className,
  title,
  topDivider = true,
  ...restProps
}: DropdownSectionProps) => {
  return (
    <Box
      direction="column"
      align="stretch"
      justify="between"
      fullWidth
      {...restProps}
      className={cx(
        styles.section,
        topDivider && styles.topDivider,
        title && styles.hasTitle,
        className
      )}
    >
      {title && (
        <Typography
          tag="span"
          variant="p-t7"
          color="secondary"
          transform="uppercase"
          className={styles.title}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default DropdownSection;
