import { ReactNode } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type DropdownHeaderProps = {
  className?: string;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
};

const DropdownHeader = ({ className, title, subtitle, children }: DropdownHeaderProps) => {
  return (
    <Box
      direction="column"
      align="stretch"
      justify="between"
      gap="medium"
      fullWidth
      className={cx(styles.header, className)}
    >
      {(title || subtitle) && (
        <Box gap="small" direction="column">
          {title && (
            <Typography variant="p-t6" tag="p">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="p-body3" tag="p" color="secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
      )}

      {children}
    </Box>
  );
};

export default DropdownHeader;
