import beautify from "xml-beautifier";

import FormGroup from "components/FormDefault/Group";
import FormViewer from "components/FormDefault/Viewer";
import { SamlSettings } from "types/generated";
import FormFooter from "components/FormDefault/Footer";
import FormButton from "components/FormDefault/Button";

import { humanizedNameIDFormats } from "./NameIDHelpers";
import styles from "./styles.module.css";

type ViewConfigurationProps = {
  integration: SamlSettings;
  onFormClose: () => unknown;
};

const ViewConfiguration = ({ integration, onFormClose }: ViewConfigurationProps) => {
  return (
    <div className={styles.ssoSection}>
      {integration.adminLogin && (
        <FormGroup
          labelText="Admin login:"
          labelStyle={{
            muted: true,
          }}
          divided
        >
          <FormViewer>{integration.adminLogin}</FormViewer>
        </FormGroup>
      )}
      <FormGroup
        labelText="NameID Format:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{humanizedNameIDFormats[integration.nameIDFormat]}</FormViewer>
      </FormGroup>
      {integration.idpMetadata && (
        <FormGroup
          labelText="IdP metadata:"
          labelStyle={{
            muted: true,
          }}
          top
        >
          <FormViewer scrollable>
            <div className={styles.ssoXmlMetadata}>{beautify(integration.idpMetadata)}</div>
          </FormViewer>
        </FormGroup>
      )}
      {integration.idpMetadataURL && (
        <FormGroup
          labelText="IdP metadata URL:"
          labelStyle={{
            muted: true,
          }}
        >
          <FormViewer>{integration.idpMetadataURL}</FormViewer>
        </FormGroup>
      )}

      <FormFooter top>
        <FormButton type="button" onClick={onFormClose} pill>
          Close
        </FormButton>
      </FormFooter>
    </div>
  );
};

export default ViewConfiguration;
