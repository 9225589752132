export const mergeWithAfterRunHooks = (
  baseHooks: string[] | undefined,
  afterRunHooks: string[] | undefined
) => {
  if (!baseHooks && !afterRunHooks) {
    return undefined;
  }

  if (!baseHooks) {
    return afterRunHooks;
  }

  if (!afterRunHooks) {
    return baseHooks;
  }

  return [...baseHooks, ...afterRunHooks];
};
