import cx from "classnames";
import { Column } from "react-aria-components";
import { useCallback, useState } from "react";
import { shallow } from "zustand/shallow";

import Toggle from "ds/components/ToggleNew";
import DropdownNew from "ds/components/Dropdown/New";
import { ChevronNew } from "components/icons";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import Box from "ds/components/Box";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import styles from "./styles.module.css";
import useSelectionStore from "./useSelectionStore";

const COLUMN_WIDTH = 70;

const getTooltip = (isSelected: boolean) =>
  isSelected ? "Unselect all items" : "Select all items on this page";

type TableColumnSelectAllProps<SelectAllType = string> = {
  allIds: string[];
  options?: Array<{ id: SelectAllType; title: string }>;
};
const TableColumnSelectAll = ({ allIds, options }: TableColumnSelectAllProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [addSelectedIds, unselectIds, selected, isSelectionOn, setSelectedAllOption] =
    useSelectionStore(
      ({ unselectIds, selected, setSelectedAllOption, addSelectedIds }) => [
        addSelectedIds,
        unselectIds,
        allIds.every((id) => selected.has(id)),
        selected.size > 0 && allIds.some((id) => selected.has(id)),
        setSelectedAllOption,
      ],
      shallow
    );

  const isIndeterminate = isSelectionOn && !selected;

  const onChange = useCallback(
    () => (selected || isIndeterminate ? unselectIds(allIds) : addSelectedIds(allIds)),
    [addSelectedIds, unselectIds, isIndeterminate, selected, allIds]
  );

  return (
    <Column
      textValue="Select all items"
      id="selection"
      className={cx(styles.checkboxColumn, isSelectionOn && styles.checkboxColumnSticky)}
      maxWidth={COLUMN_WIDTH}
      minWidth={COLUMN_WIDTH}
      width={COLUMN_WIDTH}
    >
      <Box align="center" gap="small">
        <Toggle
          isIndeterminate={isIndeterminate}
          tooltip={getTooltip}
          slot={null}
          variant="checkbox"
          aria-label="Select all items on this page"
          isSelected={selected}
          onChange={onChange}
        />
        {options?.length && (
          <DropdownNew
            onOpenChange={setDropdownOpen}
            isOpen={isDropdownOpen}
            triggerComponent={
              <ButtonIconNew
                iconRotate={isDropdownOpen ? "270" : "90"}
                icon={ChevronNew}
                variant="ghost"
              >
                Select all options
              </ButtonIconNew>
            }
          >
            {({ close }) => (
              <DropdownSection>
                {options.map(({ id, title }) => (
                  <DropdownSectionItem
                    key={id}
                    onClick={() => {
                      close();
                      setSelectedAllOption?.(id);
                    }}
                  >
                    {title}
                  </DropdownSectionItem>
                ))}
              </DropdownSection>
            )}
          </DropdownNew>
        )}
      </Box>
    </Column>
  );
};

export default TableColumnSelectAll;
