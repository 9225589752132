import { show, useModal, create } from "@ebay/nice-modal-react";

import Typography from "ds/components/Typography";
import Banner from "ds/components/Banner";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

import useDeleteWorkerPool from "../WorkerPools/useDeleteWorkerPool";

type WorkerPoolDeleteConfirmationProps = {
  name: string;
  id: string;
  viewPageMode?: boolean;
};

const WorkerPoolDeleteConfirmation = create(function WorkerPoolDeleteConfirmation({
  name,
  id,
  viewPageMode,
}: WorkerPoolDeleteConfirmationProps) {
  const { deleteWorkerPool, loading } = useDeleteWorkerPool(viewPageMode);

  const modal = useModal();

  const handleCycle = () => {
    deleteWorkerPool(id).then(() => modal.hide());
  };

  return (
    <DeleteConfirmationModal
      title="Delete worker pool"
      confirmationButtonLabel="Delete"
      onConfirm={handleCycle}
      isLoading={loading}
      isDismissable={!loading}
    >
      <Banner variant="info" fullWidth>
        Just a gentle reminder that deleting the worker pool is an irreversible operation and you
        will not be able to reuse its credentials for other workers.
      </Banner>

      <Typography variant="p-body2" tag="p" margin="large 0 0 0">
        Are you sure you want to delete your{" "}
        <Typography tag="span" variant="p-t5">
          {name}
        </Typography>{" "}
        worker pool?
      </Typography>
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: WorkerPoolDeleteConfirmationProps) =>
  show(WorkerPoolDeleteConfirmation, props);
