import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

import { DELETE_MODULE_WEBHOOK } from "./gql";

type DeleteConfirmationProps = {
  id: string;
  moduleId: string;
  endpoint: string;
  refetchQueriesOnDelete?: string[];
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  moduleId,
  endpoint,
  refetchQueriesOnDelete,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [deleteWebhook, { loading, data }] = useMutation(DELETE_MODULE_WEBHOOK, {
    refetchQueries: refetchQueriesOnDelete,
    awaitRefetchQueries: true,
    variables: {
      id,
      moduleId,
    },
  });

  const handleDeleteWebhook = () => {
    deleteWebhook()
      .then(() => {
        modal.resolve();
        modal.hide();
        reportSuccess({ message: "Webhook integration successfully deleted" });
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete webhook integration"
      onConfirm={handleDeleteWebhook}
      isLoading={loading}
      isDismissable={!loading && !data?.webhooksIntegrationDelete?.id}
    >
      <ConfirmationModalMessage>
        Are you sure you want to delete the webhook integration with the endpoint:{" "}
        <strong>{endpoint}</strong>?
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
