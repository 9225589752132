import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { Run, RunState, Stack, StackState } from "types/generated";

// Check if the user can discard the stack run having only a stack
export const canDiscardStackRun = (stack: Stack): boolean =>
  stack.state === StackState.Unconfirmed && hasAtLeastStackWriteAccess(stack);

// Check if the user can discard the run based on the run
export const canDiscardRun = (run: Run): boolean =>
  run.state === RunState.Ready ||
  run.state === RunState.Queued ||
  run.state === RunState.Unconfirmed;
