import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { VcsIntegrationUser } from "types/generated";

import VCSIntegrationUsedByListItem from ".";

type VCSIntegrationUsedByVirtualizedListItemProps = {
  items: VcsIntegrationUser[];
};

const VCSIntegrationUsedByVirtualizedListItem = (
  props: ListChildComponentProps<
    VCSIntegrationUsedByVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <VCSIntegrationUsedByListItem
        key={data.items[index].stackId}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(VCSIntegrationUsedByVirtualizedListItem, areEqual);
