import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import Button from "ds/components/Button";

const NewModuleAttachCloudFormSubmitButton = () => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  return (
    <Box justify="end">
      <Button
        type="submit"
        variant="contrast"
        loading={isSubmitting}
        disabled={isSubmitting || !isValid}
      >
        Attach
      </Button>
    </Box>
  );
};

export default NewModuleAttachCloudFormSubmitButton;
