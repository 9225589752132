import { useCallback } from "react";

import { VcsProvider } from "types/generated";

import useDeleteGitHubIntegration from "./Integrations/ManageGitHubEnterprise/useDeleteGitHubEnterpriseIntegration";
import useDeleteGitLabIntegration from "./Integrations/ManageGitLab/useDeleteGitLabIntegration";
import useDeleteAzureDevOpsIntegration from "./Integrations/ManageAzureDevOps/useDeleteAzuredevOpsIntegration";
import useDeleteBitbucketDataCenterIntegration from "./Integrations/ManageBitbucketDataCenter/useDeleteBitbucketDataCenterIntegration";
import useDeleteBitbucketCloudIntegration from "./Integrations/ManageBitbucketCloud/useDeleteBitbucketCloudIntegration";

const useDeleteVcsIntegration = () => {
  const { deleteGitHubEnterpriseIntegration, loading: gitHubLoading } =
    useDeleteGitHubIntegration();
  const { deleteGitLabIntegration, loading: gitLabLoading } = useDeleteGitLabIntegration();
  const { deleteAzureDevOpsIntegration, loading: azureLoading } = useDeleteAzureDevOpsIntegration();
  const { deleteBitbucketDataCenterIntegration, loading: bitbucketDataCenterLoading } =
    useDeleteBitbucketDataCenterIntegration();
  const { deleteBitbucketCloudIntegration, loading: bitbucketCloudLoading } =
    useDeleteBitbucketCloudIntegration();

  const handleDeleteVcsIntegration = useCallback(
    (
      id: string,
      provider: VcsProvider,
      totalUsedByEntitiesQty: number,
      successCallback?: () => void
    ) => {
      if (provider === VcsProvider.GithubEnterprise) {
        return deleteGitHubEnterpriseIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.Gitlab) {
        return deleteGitLabIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.AzureDevops) {
        return deleteAzureDevOpsIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.BitbucketDatacenter) {
        return deleteBitbucketDataCenterIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.BitbucketCloud) {
        return deleteBitbucketCloudIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      return;
    },
    [
      deleteAzureDevOpsIntegration,
      deleteBitbucketCloudIntegration,
      deleteBitbucketDataCenterIntegration,
      deleteGitHubEnterpriseIntegration,
      deleteGitLabIntegration,
    ]
  );

  return {
    deleteVcsIntegration: handleDeleteVcsIntegration,
    loading:
      gitHubLoading ||
      gitLabLoading ||
      azureLoading ||
      bitbucketDataCenterLoading ||
      bitbucketCloudLoading,
  };
};

export default useDeleteVcsIntegration;
