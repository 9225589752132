import {
  BitbucketDatacenterIntegration,
  MutationBitbucketDatacenterIntegrationCreateArgs,
  MutationBitbucketDatacenterIntegrationUpdateArgs,
  VcsCheck,
} from "types/generated";
import { ROOT_SPACE_ID } from "constants/space";

import { ManageBitbucketDataCenterFormFields } from "./types";
import { VCS_INTEGRATION_TYPES } from "../../constants";

export const getFormDefaultValues = (
  integration?: BitbucketDatacenterIntegration
): ManageBitbucketDataCenterFormFields => ({
  name: integration?.name || "",
  space: integration?.space?.id || "",
  apiHost: integration?.apiHost || "",
  userFacingHost: integration?.userFacingHost || "",
  username: integration?.username || "",
  accessToken: "",
  integrationType: integration?.isDefault
    ? VCS_INTEGRATION_TYPES.DEFAULT
    : VCS_INTEGRATION_TYPES.MULTIPLE,
  labels: integration?.labels?.map((label) => ({ value: label })) || [],
  description: integration?.description || "",
  vcsChecks: integration?.vcsChecks || VcsCheck.Individual,
});

export const mapCreateIntegrationInputValues = (
  values: ManageBitbucketDataCenterFormFields
): MutationBitbucketDatacenterIntegrationCreateArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    accessToken: values.accessToken.trim(),
    apiHost: values.apiHost.trim(),
    customInput: {
      isDefault,
      name: values.name.trim(),
      labels: values.labels.map((label) => label.value) || [],
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      description: values.description?.trim() || null,
      vcsChecks: values.vcsChecks,
    },
    userFacingHost: values.userFacingHost.trim(),
    username: values.username.trim(),
  };
};

export const mapUpdateIntegrationInputValues = (
  values: ManageBitbucketDataCenterFormFields,
  integrationId: string
): MutationBitbucketDatacenterIntegrationUpdateArgs => ({
  accessToken: values.accessToken?.trim() || null,
  apiHost: values.apiHost.trim(),
  customInput: {
    id: integrationId,
    labels: values.labels.map((label) => label.value) || [],
    space: values.space,
    description: values.description?.trim() || null,
    vcsChecks: values.vcsChecks,
  },
  userFacingHost: values.userFacingHost.trim(),
  username: values.username.trim(),
});
