import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  MutationStackScheduledRunUpdateArgs,
  ScheduledRun,
  ScheduledRunInput,
} from "types/generated";

import { UPDATE_STACK_SCHEDULED_RUN } from "./gql";

type UseStackScheduledRunUpdate = {
  refetchQueries?: string[];
};

const useStackScheduledRunUpdate = ({ refetchQueries }: UseStackScheduledRunUpdate) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackScheduledRunUpdate, { loading }] = useMutation<
    {
      stackScheduledRunUpdate: ScheduledRun;
    },
    MutationStackScheduledRunUpdateArgs
  >(UPDATE_STACK_SCHEDULED_RUN, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError,
  });

  const updateScheduledRun = useCallback(
    (
      stack: string,
      scheduledRun: string,
      input: ScheduledRunInput,
      successCallback?: () => void
    ) => {
      stackScheduledRunUpdate({
        variables: {
          stack,
          scheduledRun,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledRunUpdate) {
            reportSuccess({
              message: "Scheduled run was updated successfully",
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackScheduledRunUpdate]
  );

  return {
    updateScheduledRun,
    loading,
  };
};

export default useStackScheduledRunUpdate;
