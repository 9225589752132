import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom-v5-compat";

import { AccountContext } from "views/AccountWrapper";
import Col from "components/column/Col";
import { Info } from "components/icons";
import { BillingTierFeature } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useTierFeature from "views/Account/hooks/useTierFeature";

import styles from "./styles.module.css";

type TierInfoProps = {
  feature: BillingTierFeature;
  top?: boolean;
  lowercase?: boolean;
  upgradeText?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

const TierInfoLegacy = (props: TierInfoProps) => {
  const { children, feature, top, upgradeText, lowercase, className, onClick } = props;
  const { viewer } = useTypedContext(AccountContext);
  const hasFeature = useTierFeature(feature);
  const upgradeTextContent = upgradeText ? ` ${upgradeText}` : `.`;
  const upgradeLink = (
    <Link
      to={`/settings/billing`}
      className={cx(styles.link, {
        [styles.linkLowercase]: lowercase,
      })}
      {...(onClick ? { onClick } : null)}
    >
      {`Upgrade${upgradeText ? `` : `.`}`}
    </Link>
  );

  if (hasFeature) return null;

  return (
    <div className={cx(styles.wrapper, top && styles.wrapperTop, className)}>
      <div className={styles.content}>
        {children}
        {viewer.admin ? (
          <>
            {upgradeLink} {upgradeText}
          </>
        ) : (
          ` Ask your admin to upgrade${upgradeTextContent}`
        )}
      </div>
      <Col className={styles.icon} auto>
        <Info />
      </Col>
    </div>
  );
};

export default TierInfoLegacy;
