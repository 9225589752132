import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import CodeEditor from "components/CodeEditor";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

type RuntimeConfigDetailsDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  runtimeConfig?: string;
  customRuntimeConfig?: string;
};

const RuntimeConfigDetailsDrawer = ({
  runtimeConfig,
  customRuntimeConfig,
  isVisible,
  onClose,
}: RuntimeConfigDetailsDrawerProps) => {
  return (
    <Drawer visible={isVisible} onOutsideClick={onClose}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Runtime config details" />
        <DrawerCloseIcon handleCloseDrawer={onClose} />
      </DrawerHeader>
      <DrawerBody fullHeight gap="medium">
        {runtimeConfig && (
          <>
            <Typography tag="span" variant="p-t7">
              Runtime config
            </Typography>
            <Box direction="column" className={styles.codeEditorWrapper}>
              <CodeEditor
                body={runtimeConfig}
                language="yaml"
                readOnly
                rounded
                options={{ automaticLayout: true }}
              />
            </Box>
          </>
        )}
        {customRuntimeConfig && (
          <>
            <Typography tag="span" variant="p-t7" margin="large 0 0 0">
              Custom config
            </Typography>
            <Box direction="column" className={styles.codeEditorWrapper}>
              <CodeEditor
                className={styles.codeEditor}
                body={customRuntimeConfig}
                language="yaml"
                readOnly
                options={{ automaticLayout: true }}
              />
            </Box>
          </>
        )}
      </DrawerBody>
    </Drawer>
  );
};

export default RuntimeConfigDetailsDrawer;
