import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { WorkerPool } from "types/generated";
import { fileToBase64 } from "utils/file";

import { CREATE_WORKER_POOL } from "../gql";
import { CreateWorkerPoolFields } from "./types";

const useCreateWorkerPool = () => {
  const { onError } = useTypedContext(FlashContext);

  const [createWorkerPool] = useMutation<{ workerPoolCreate: WorkerPool }>(CREATE_WORKER_POOL, {
    onError,
    refetchQueries: ["SearchWorkerPools"],
  });

  const onCreate = async (formData: CreateWorkerPoolFields) => {
    const base64 = await fileToBase64(formData.certificateFile as File);
    const certificateSigningRequest = base64 ? base64.toString().split(",")[1] : "";

    return createWorkerPool({
      variables: {
        name: formData.name,
        description: formData.description,
        certificateSigningRequest,
        labels: formData.labels.map((item) => item.value),
        space: formData.space,
      },
    });
  };

  return { onCreate };
};

export default useCreateWorkerPool;
