import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import { AfterCommandsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import NewStackSummaryHook from "views/Account/NewStack/Summary/Hook";

import { ContextFormFields } from "../types";
import NewContextSummaryPanel from "./Panel";
import { ContextCreationWizardStep } from "../constants";

const NewContextSummaryHooks = () => {
  const { getValues } = useFormContext<ContextFormFields>();
  const formValues = getValues();
  const hasValues = Object.entries(formValues.hooks).some(
    ([, { before, after }]) => after?.length || before?.length
  );

  return (
    <NewContextSummaryPanel name="Hooks" step={ContextCreationWizardStep.Hooks}>
      {hasValues && (
        <Box gap="medium" direction="column">
          {Object.entries(formValues.hooks).map(([key, { before, after }]) => {
            if (!before?.length && !after?.length) {
              return null;
            }

            return <NewStackSummaryHook key={key} name={key} before={before} after={after} />;
          })}
        </Box>
      )}
      {!hasValues && (
        <EmptyState
          padding="large"
          icon={AfterCommandsColored}
          title="Hooks not defined"
          caption="Customize Spacelift run lifecycle by adding commands to run before and after various execution
    phases."
        />
      )}
    </NewContextSummaryPanel>
  );
};

export default NewContextSummaryHooks;
