import { Controller, useFormContext } from "react-hook-form";

import ToggleField from "ds/components/Form/ToggleField";

const StackSettingsIntegrationsCloudAttachFormWriteToggleField = () => {
  const { control } = useFormContext<{ read: boolean; write: boolean }>();
  return (
    <Controller
      name="write"
      control={control}
      rules={{
        validate: (value, values) =>
          value === false && values.read === false
            ? "At least one permission must be selected"
            : undefined,
      }}
      render={({ field }) => (
        <ToggleField
          variant="switch"
          onChange={field.onChange}
          checked={field.value}
          title="Write"
          description="Integration will be used during write phases of runs (for example, applies)"
        />
      )}
    />
  );
};

export default StackSettingsIntegrationsCloudAttachFormWriteToggleField;
