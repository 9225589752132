/* eslint react/prop-types: warn */
import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { gql, useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom-v5-compat";

import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";

export const TRIGGER_VERSION = gql`
  mutation VersionCreate($moduleId: ID!) {
    versionCreate(module: $moduleId) {
      id
    }
  }
`;

const TriggerButton = ({ moduleId, disabled }) => {
  const { onError, reportSuccess } = useContext(FlashContext);
  const [triggerVersion, { loading }] = useMutation(TRIGGER_VERSION, {
    variables: { moduleId },
    refetchQueries: ["GetModule"],
  });

  const [nextUrl, setNextUrl] = useState(null);

  const onClick = () => {
    triggerVersion()
      .then(
        ({
          data: {
            versionCreate: { id },
          },
        }) => {
          reportSuccess({ message: `Version ${id} successfully triggered` });
          setNextUrl(`/module/${moduleId}/version/${id}`);
        }
      )
      .catch(onError);
  };

  if (nextUrl) {
    return <Navigate to={nextUrl} replace />;
  }

  return (
    <Button disabled={loading || disabled} loading={loading} onClick={onClick} variant="primary">
      Trigger
    </Button>
  );
};

TriggerButton.propTypes = {
  moduleId: PropTypes.string.isRequired,
};

export default TriggerButton;
