import { gql } from "@apollo/client";

export const GET_VERSION_SUBMODULE = gql`
  query GetVersionSubmodule($moduleId: ID!, $versionId: ID!) {
    module(id: $moduleId) {
      id
      version(id: $versionId) {
        id
        metadata {
          submodules {
            name
            readme
            inputs {
              name
              type
              description
              default
              required
            }
            outputs {
              description
              name
            }
            dependencies
            path
            providerDependencies {
              name
              namespace
              source
              version
            }
            resources {
              name
              type
            }
          }
        }
      }
    }
  }
`;
