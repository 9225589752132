import { memo } from "react";

import { WorkerPool } from "types/generated";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import WorkerPoolResetAction from "../WorkerPool/ResetAction";
import WorkerPoolDeleteAction from "../WorkerPool/DeleteAction";
import WorkerPoolCycleAction from "../WorkerPool/CycleAction";
import { showCycleConfirmation } from "../WorkerPool/CycleConfirmation";
import { showDeleteConfirmation } from "../WorkerPool/DeleteConfirmation";
import { showResetConfirmation } from "../WorkerPool/ResetConfirmation";

type PrivateWorkerPoolActionsDropdownProps = {
  workerPoolId: WorkerPool["id"];
  workerPoolName: WorkerPool["name"];
  hasUsers: boolean;
  hasBusyWorkers: boolean;
  onEdit: () => void;
  canManageWorkerPools: boolean;
};

const PrivateWorkerPoolActionsDropdown = ({
  workerPoolId,
  workerPoolName,
  hasUsers,
  hasBusyWorkers,
  onEdit,
  canManageWorkerPools,
}: PrivateWorkerPoolActionsDropdownProps) => {
  const handleOnCycle = () => {
    showCycleConfirmation({ id: workerPoolId, name: workerPoolName });
  };

  const handleOnReset = () => {
    showResetConfirmation({ id: workerPoolId, name: workerPoolName, viewPageMode: true });
  };

  const handleOnDelete = () => {
    showDeleteConfirmation({ id: workerPoolId, name: workerPoolName, viewPageMode: true });
  };

  return (
    <DropdownMenuEllipsis dotsSize="medium" tooltip="Worker pool actions">
      <CopyFieldDropdownMenuItem title="Copy ID" value={workerPoolId} />
      {canManageWorkerPools && (
        <>
          <DropdownMenuItem onAction={onEdit}>Edit</DropdownMenuItem>
          <WorkerPoolCycleAction onAction={handleOnCycle} hasBusyWorkers={hasBusyWorkers} />
          <WorkerPoolResetAction onAction={handleOnReset} hasBusyWorkers={hasBusyWorkers} />
          <WorkerPoolDeleteAction
            onAction={handleOnDelete}
            hasUsers={hasUsers}
            hasBusyWorkers={hasBusyWorkers}
          />
        </>
      )}
    </DropdownMenuEllipsis>
  );
};

export default memo(PrivateWorkerPoolActionsDropdown);
