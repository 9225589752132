import cx from "classnames";
import moment from "moment";
import useLocalStorage from "@rehooks/local-storage";

import AccessPolicyWarning from "components/AccessPolicyWarning";
import DocumentationBar from "components/DocumentationBar";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import SideNavigation from "components/SideNavigation";
import { DOCUMENTATION_BAR_CLOSED } from "constants/local_storage_keys";
import { getNowTime } from "utils/time";

import styles from "./styles.module.css";
import { LayoutContext } from "./Context";

type LayoutProps = {
  className?: string;
  children: React.ReactNode;
};

const Layout = ({ children, className }: LayoutProps) => {
  const [isExpandedMode, toggleMode] = useLocalStorage("sideNavigationExpandedMode", true);
  const [documentationBarClosed] = useLocalStorage(DOCUMENTATION_BAR_CLOSED);

  const {
    viewer: { since, cannotEvaluateAccessPolicies },
  } = useTypedContext(AccountContext);

  const firstLoginTime = since ? moment.unix(since) : null;
  const now = moment(getNowTime());

  const shouldShowDocumentationBar =
    !documentationBarClosed && firstLoginTime && now.diff(firstLoginTime, "days") < 14;

  const isDocumentationBarVisible = !!shouldShowDocumentationBar;

  return (
    <LayoutContext.Provider
      value={{
        isExpandedMode: !!isExpandedMode,
        isDocumentationBarVisible,
      }}
    >
      <main className={cx(styles.layout, className)}>
        <SideNavigation onChange={toggleMode} />

        <div
          id="layoutContent"
          className={cx(styles.content, { [styles.hasExpandedSideMenu]: isExpandedMode })}
        >
          {isDocumentationBarVisible && <DocumentationBar />}
          {cannotEvaluateAccessPolicies && <AccessPolicyWarning />}
          {children}
        </div>
      </main>
    </LayoutContext.Provider>
  );
};

export default Layout;
