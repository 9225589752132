import FeedbackActions from "ds/components/Feedback/Actions";
import LinkNew from "ds/components/LinkNew";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";

import styles from "./styles.module.css";
import { showPolicyDiffCalloutModal } from "./Modal";

type PolicyDiffCalloutProps = {
  body: string;
  evaluationBody: string;
  disabledActions: boolean;
};

const PolicyDiffCallout = ({ body, evaluationBody, disabledActions }: PolicyDiffCalloutProps) => {
  const showPolicyDiffModal = () =>
    showPolicyDiffCalloutModal({
      evaluationBody,
      body,
    });
  return (
    <Box className={styles.wrapper} padding="large large 0 large">
      <Banner variant="warning">
        The current policy body doesn’t match the policy used when evaluating the sampled input. You
        can view the differences between the policies by clicking 'Show changes'
        <FeedbackActions>
          <LinkNew disabled={disabledActions} onPress={showPolicyDiffModal} size="small">
            Show changes
          </LinkNew>
        </FeedbackActions>
      </Banner>
    </Box>
  );
};

export default PolicyDiffCallout;
