import { useModal, create, show } from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Icon from "ds/components/Icon";
import { MenuModules, MenuStacks } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import LinkNew from "ds/components/LinkNew";
import FormFieldTextConfirm from "components/FormFields/Confirm";
import { pluralizeNext } from "shared/Pluralize";

import useDeletePolicy from "./useDeletePolicy";
import styles from "./styles.module.css";

type DeleteConfirmationProps = {
  id: string;
  hasAttachedEntities: boolean;
  name: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  hasAttachedEntities,
  name,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { attachedEntities, attachmentsError, entityCount, loading, onDelete, pendingAttachments } =
    useDeletePolicy(id);

  const policyDeleteForm = useForm<{ deleteConfirmationText: string }>({
    defaultValues: { deleteConfirmationText: "" },
    mode: "onChange",
  });

  const onConfirm = () => {
    onDelete().then(() => {
      modal.resolve(true);
      modal.hide();
    });
  };

  const onClose = () => {
    modal.resolve(false);
    modal.hide();
  };

  if (attachmentsError) {
    onClose();
  }

  return (
    <DeleteConfirmationModal
      title="Delete policy"
      onConfirm={onConfirm}
      onClose={onClose}
      isLoading={loading}
      isDismissable={!loading}
      isConfirmationDisabled={
        loading || (hasAttachedEntities && !policyDeleteForm.formState.isValid)
      }
    >
      {hasAttachedEntities ? (
        <Box direction="column" gap="medium">
          <Typography tag="p" variant="p-body2">
            By deleting <strong>{name}</strong> policy you will detach it from{" "}
            {pendingAttachments ? (
              <Skeleton width="5rem" />
            ) : (
              <>
                <strong>{pluralizeNext(attachedEntities.length, "entity", "entities")}</strong>.
              </>
            )}
          </Typography>
          {pendingAttachments && <Skeleton height="44px" />}
          {entityCount.stacks > 0 && (
            <Box justify="between" gap="x-large" padding="large" className={styles.entitySummary}>
              <Box gap="large">
                <Icon src={MenuStacks} name="stacks" />
                <Typography tag="p" variant="p-body2">
                  Stacks
                </Typography>
              </Box>
              <strong>{entityCount.stacks}</strong>
            </Box>
          )}
          {entityCount.modules > 0 && (
            <Box justify="between" gap="x-large" padding="large" className={styles.entitySummary}>
              <Box gap="large">
                <Icon src={MenuModules} name="Modules" />
                <Typography tag="p" variant="p-body2">
                  Modules
                </Typography>
              </Box>
              <strong>{entityCount.modules}</strong>
            </Box>
          )}
          <Typography tag="p" variant="p-body2" margin="medium 0">
            Confirm policy deletion below or verify it's attached entities on the{" "}
            <LinkNew href={`/policy/${id}/used-by`} target="_blank">
              Used by screen
            </LinkNew>
          </Typography>
          <FormProvider {...policyDeleteForm}>
            <FormFieldTextConfirm
              fontVariant="p-body2"
              fieldName="deleteConfirmationText"
              textToConfirm={name}
              disabled={loading}
            />
          </FormProvider>
        </Box>
      ) : (
        <Typography tag="p" variant="p-body2">
          {pendingAttachments ? (
            <Skeleton />
          ) : (
            <>
              Are you sure you want to delete <strong>{name}</strong> policy?
            </>
          )}
        </Typography>
      )}
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
