import { useLocation } from "react-router-dom-v5-compat";

import { DOCUMENTATION_BAR_CLOSED } from "constants/local_storage_keys";
import Button from "ds/components/Button";
import Feedback from "ds/components/Feedback";
import FeedbackActions from "ds/components/Feedback/Actions";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { AnalyticsPageGlobal } from "hooks/useAnalytics/pages/global";
import { SCHEDULE_DEMO_LINK } from "constants/url";

import {
  ANALYTICS_TITLE_BUTTON_CLICKED,
  DOCUMENTATION_BUTTON_TEXT,
  DOCUMENTATION_URL,
  GO_TO_LAUNCHPAD_BUTTON_TEXT,
  LAUNCHPAD_NAVIGATE_PATH,
  SCHEDULE_DEMO_BUTTON_TEXT,
} from "./constants";

const DocumentationBar = () => {
  const location = useLocation();
  const { viewer } = useTypedContext(AccountContext);

  const isAdmin = viewer.admin;

  const analyticsPage = AnalyticsPageGlobal.DocumentationBanner;

  if (location.pathname.startsWith("/launchpad")) {
    return null;
  }

  return (
    <Feedback
      variant="promo"
      type="callout"
      storageKey={DOCUMENTATION_BAR_CLOSED}
      title="Learn how to get most out of Spacelift"
    >
      {isAdmin
        ? "Go to LaunchPad to explore Spacelift yourself"
        : "Read our documentation to learn about Spacelift"}{" "}
      or Schedule a demo with one of our experts.
      <FeedbackActions>
        {isAdmin ? (
          <Button
            variant="secondary"
            size="small"
            to={LAUNCHPAD_NAVIGATE_PATH}
            analyticsPage={analyticsPage}
            analyticsTitle={ANALYTICS_TITLE_BUTTON_CLICKED}
            analyticsProps={{
              buttonText: GO_TO_LAUNCHPAD_BUTTON_TEXT,
              url: LAUNCHPAD_NAVIGATE_PATH,
            }}
          >
            {GO_TO_LAUNCHPAD_BUTTON_TEXT}
          </Button>
        ) : (
          <Button
            variant="secondary"
            size="small"
            href={DOCUMENTATION_URL}
            target="_blank"
            analyticsPage={analyticsPage}
            analyticsTitle={ANALYTICS_TITLE_BUTTON_CLICKED}
            analyticsProps={{
              buttonText: DOCUMENTATION_BUTTON_TEXT,
              url: DOCUMENTATION_URL,
            }}
          >
            {DOCUMENTATION_BUTTON_TEXT}
          </Button>
        )}

        <Button
          variant="primary"
          size="small"
          href={SCHEDULE_DEMO_LINK}
          target="_blank"
          rel="noopener noreferrer"
          analyticsPage={analyticsPage}
          analyticsTitle={ANALYTICS_TITLE_BUTTON_CLICKED}
          analyticsProps={{
            buttonText: SCHEDULE_DEMO_BUTTON_TEXT,
            url: SCHEDULE_DEMO_LINK,
          }}
        >
          {SCHEDULE_DEMO_BUTTON_TEXT}
        </Button>
      </FeedbackActions>
    </Feedback>
  );
};

export default DocumentationBar;
