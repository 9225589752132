import { gql } from "@apollo/client";

export const CREATE_WEBHOOK_INTEGRATION = gql`
  mutation CreateWebhookIntegrationForModule($moduleId: ID!, $input: WebhooksIntegrationInput!) {
    webhooksIntegrationCreate(stack: $moduleId, input: $input) {
      id
    }
  }
`;

export const UPDATE_WEBHOOK_INTEGRATION = gql`
  mutation UpdateWebhookIntegrationForModule(
    $id: ID!
    $moduleId: ID!
    $input: WebhooksIntegrationInput!
  ) {
    webhooksIntegrationUpdate(id: $id, stack: $moduleId, input: $input) {
      id
    }
  }
`;
