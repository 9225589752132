import React, { useRef, useState } from "react";
import cx from "classnames";

import Button from "components/button/Button";
import InputText from "components/input/InputText";
import { ConfigElement } from "types/generated";

import styles from "../styles.module.css";

type EditEnvironmentVariableProps = {
  element: ConfigElement;
  onCancel: () => unknown;
  onSave: (value: string | undefined) => unknown;
  loading: boolean;
};

const EditEnvironmentVariable = ({
  element,
  loading,
  onCancel,
  onSave,
}: EditEnvironmentVariableProps) => {
  const originValue = element.value || "";

  const valueRef = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState(true);

  const onChange = () => setDisabled(valueRef.current?.value === originValue);

  const save = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    onSave(valueRef.current?.value);
  };

  return (
    <div className={styles.variable}>
      <div className={styles.variableName}>{element.id}</div>
      <InputText
        className={cx(styles.variableValue, styles.variableValueEditing)}
        defaultValue={originValue}
        onChange={onChange}
        ref={valueRef}
      />
      <div>
        {loading ? (
          <Button disabled sm2 loading pill>
            Saving
          </Button>
        ) : (
          <>
            <Button onClick={save} disabled={disabled} sm pill left>
              Save
            </Button>
            <Button onClick={onCancel} sm pill right>
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditEnvironmentVariable;
