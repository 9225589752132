import { useNavigate } from "react-router-dom-v5-compat";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import useTaskCreate from "shared/Stack/useTaskCreate";

import { useRunShortcut } from "../../hooks/useRunShortcut";

type PerformTaskAgainButtonProps = {
  command: string | null;
  stackId: string;
};

const PerformTaskAgainButton = ({ command, stackId }: PerformTaskAgainButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [createTask, { loading, data }] = useTaskCreate({
    refetchQueries: ["GetRun"],
  });

  const isLoading = loading || !!data?.taskCreate?.id;

  const performTaskAgain = useCallback(() => {
    createTask({ stack: stackId, command: command || "", skipInitialization: false }, (data) => {
      if (data?.taskCreate?.id) {
        const taskId = data.taskCreate.id;
        reportSuccess({ message: `Task ${taskId} successfully triggered` });
        navigate(`/stack/${stackId}/run/${taskId}`);
      }
    });
  }, [createTask, stackId, command, reportSuccess, navigate]);

  useRunShortcut({
    label: "Perform again",
    code: "mod+alt+p",
    callback: performTaskAgain,
    loading: isLoading,
  });

  return (
    <Button
      variant="secondary"
      loading={isLoading}
      disabled={isLoading}
      onClick={performTaskAgain}
      size="small"
    >
      Perform again
    </Button>
  );
};

export default PerformTaskAgainButton;
