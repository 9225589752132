import ReplanRequestChanges from "./Changes";
import { getChangesFromEntryNote } from "./helpers";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryUsername from "../../components/HistoryEntryUsername";
import {
  REPLAN_REQUEST_CHANGES_TEST_ID,
  REPLAN_REQUEST_IGNORED_CHANGES_TEST_ID,
} from "./constants";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type ReplanRequestedEntryProps = {
  timestamp: number;
  until: number | null;
  note: string | null;
  username: string | null;
};

const ReplanRequestedEntry = ({ timestamp, until, note, username }: ReplanRequestedEntryProps) => {
  if (!note) {
    // Note is empty, do not render anything
    return null;
  }

  const { replannedChanges, ignoredChanges } = getChangesFromEntryNote(note);

  if (!replannedChanges || !ignoredChanges) {
    // Note could not be parsed, do not render anything
    return null;
  }

  const label = (
    <HistoryEntryLabel>
      <strong>Replan requested</strong> <HistoryEntryUsername username={username} />
    </HistoryEntryLabel>
  );

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.ReplanRequested}
      timestamp={timestamp}
      until={until}
      label={label}
    >
      <ReplanRequestChanges
        title="Targeted replan of the following resources"
        changes={replannedChanges}
        dataTestId={REPLAN_REQUEST_CHANGES_TEST_ID}
      />
      {ignoredChanges.length > 0 && (
        <ReplanRequestChanges
          title="Ignored resources"
          changes={ignoredChanges}
          dataTestId={REPLAN_REQUEST_IGNORED_CHANGES_TEST_ID}
        />
      )}
    </HistoryEntryWrapper>
  );
};

export default ReplanRequestedEntry;
