import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";

import useTypedContext from "hooks/useTypedContext";
import useUpdateStack from "shared/Stack/useUpdateStack";
import { VcsProvider } from "types/generated";
import FormSourceCode from "components/Forms/SourceCode";
import { SourceCodeProjects } from "components/Forms/SourceCode/types";
import FormFieldProjectGlobs from "components/FormFields/ProjectGlobs";
import CardWrapper from "components/CardWrapper";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackSettingsContextData } from "../Context";
import {
  getFormDefaultValues,
  getProviderEmptyValues,
  mapCreateStackSourceCodeUpdateInput,
} from "./helpers";
import StackSettingsFormFooter from "../components/FormFooter";
import { REFETCH_STACK_AND_STACK_SETTINGS_QUERIES } from "../constants";

export type StackSettingsSourceCodeFormFields = {
  namespace: string;
  repository: string;
  projectRoot: string;
  branch: string;
  provider: VcsProvider;
  vcsIntegrationId: string | undefined;
  repositoryURL?: string;
  additionalProjectGlobs: Array<{ value: string }>;
};

const StackSettingsSourceCodeEdit = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsSourceCode,
  });

  const { stackUpdate, loading } = useUpdateStack({
    stack: stackSettings,
    refetchQueries: REFETCH_STACK_AND_STACK_SETTINGS_QUERIES,
  });

  const sourceCodeForm = useForm<StackSettingsSourceCodeFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty },
    trigger,
  } = sourceCodeForm;

  const resetFormFields = (type: VcsProvider) => {
    if (type === stackSettings.provider) {
      reset(getFormDefaultValues(stackSettings));
    } else {
      reset({
        ...getFormDefaultValues(stackSettings),
        ...getProviderEmptyValues(),
        provider: type,
      });
    }

    trigger();
  };

  const onSubmit = (formData: StackSettingsSourceCodeFormFields) => {
    stackUpdate(mapCreateStackSourceCodeUpdateInput(formData), () => {
      trackSegmentEvent("Saved", { vcs: formData.provider });
    });
  };

  const values = watch();

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  return (
    <FormProvider {...sourceCodeForm}>
      <FormSourceCode
        spaceId={stackSettings.spaceDetails.id}
        provider={values.provider}
        vcsIntegrationId={values.vcsIntegrationId}
        repository={values.repository}
        resetFormFields={resetFormFields}
        projectType={SourceCodeProjects.Stack}
        innerFormMargin="0"
        innerFormGap="x-large"
      />

      <CardWrapper variant="filled" gap="large" direction="column">
        <FormFieldProjectGlobs />
      </CardWrapper>

      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={!isValid || !isDirty || loading}
        submitLoading={loading}
      />
    </FormProvider>
  );
};

export default StackSettingsSourceCodeEdit;
