import { FunctionComponent, useMemo } from "react";

import Box from "ds/components/Box";
import { AwsIntegration, AzureIntegration } from "types/generated";
import Tile from "ds/components/Tile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import TileCheckboxGrid from "components/Forms/SourceCode/TileCheckboxGrid";
import MissingDataBanner from "components/MissingDataBanner";

import { ModuleCreationCloud } from "../../types";
import AWSIntegrationForm from "./AWSIntegration";
import AzureIntegrationForm from "./AzureIntegration";
import GCPIntegration from "./GCPIntegration";

type NewModuleAttachCloudFormProps = {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: ModuleCreationCloud;
  }>;
  attachableAzureIntegrations?: AzureIntegration[];
  attachableAwsIntegrations?: AwsIntegration[];
  cloud?: ModuleCreationCloud;
  setCloud: (value?: ModuleCreationCloud) => void;
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
  moduleId: string;
  onSuccess?: () => void;
  refetchQueries?: string[];
  canAttachGcpIntegration?: boolean;
};

const NewModuleAttachCloudForm = ({
  cloudConfig,
  attachableAzureIntegrations,
  attachableAwsIntegrations,
  cloud,
  setCloud,
  hasData,
  refetch,
  refetching,
  moduleId,
  onSuccess,
  refetchQueries,
  canAttachGcpIntegration,
}: NewModuleAttachCloudFormProps) => {
  const handleSubmit = () => {
    setCloud(undefined);
    onSuccess?.();
  };

  const filteredCloudConfig = useMemo(() => {
    if (!canAttachGcpIntegration) {
      return cloudConfig.filter(({ type }) => type !== ModuleCreationCloud.GCP);
    }

    return cloudConfig;
  }, [canAttachGcpIntegration, cloudConfig]);

  return (
    <>
      {!hasData && (
        <Box margin="large 0 0 0" direction="column">
          <MissingDataBanner
            text="Couldn't load cloud integrations. Please try to refresh or come back later."
            refreshHandler={refetch}
            refreshLoading={refetching}
          />
        </Box>
      )}
      <Box direction="column" gap="x-large" margin="x-large 0 large 0">
        <TileCheckboxGrid>
          {filteredCloudConfig.map(({ name, logo, type }) =>
            filteredCloudConfig.length === 1 ? (
              <TileWrapper key={type} selected={cloud === type} onClick={() => setCloud(type)}>
                <TileContent direction="row" align="center">
                  <IconTile icon={logo} variant={cloud === type ? "primary" : "secondary"} />
                  <TileTitle>{name}</TileTitle>
                </TileContent>
              </TileWrapper>
            ) : (
              <Tile
                key={type}
                selected={cloud === type}
                title={name}
                icon={logo}
                onClick={() => setCloud(type)}
              />
            )
          )}
        </TileCheckboxGrid>
      </Box>

      {cloud === ModuleCreationCloud.AWS && (
        <AWSIntegrationForm
          attachableIntegrations={attachableAwsIntegrations}
          moduleId={moduleId}
          onSuccess={handleSubmit}
          refetchQueries={refetchQueries}
        />
      )}

      {cloud === ModuleCreationCloud.Azure && (
        <AzureIntegrationForm
          attachableIntegrations={attachableAzureIntegrations}
          moduleId={moduleId}
          onSuccess={handleSubmit}
          refetchQueries={refetchQueries}
        />
      )}

      {cloud === ModuleCreationCloud.GCP && canAttachGcpIntegration && (
        <GCPIntegration
          moduleId={moduleId}
          onSuccess={handleSubmit}
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};

export default NewModuleAttachCloudForm;
