import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import { useLocation } from "react-router-dom-v5-compat";

import useTypedFlags from "hooks/useTypedFlags";
import FlashContext from "components/FlashMessages/FlashContext";
import {
  ApiKey,
  AuthorizationScheme,
  AzureIntegration,
  Maybe,
  SlackIntegration,
} from "types/generated";
import Box from "ds/components/Box";
import Callout from "ds/components/Callout";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import { isSaasDistribution } from "utils/distribution";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DSButton from "ds/components/Button";
import PageWrapper from "components/PageWrapper";
import SubSidebar from "components/SubSidebar";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import ConfirmDeleteButton from "./ConfirmDeleteButton";
import styles from "./styles.module.css";
import SettingsRoutes from "./Routes";
import SettingsAccountDeleteWarning from "./AccountDeleteWarning";
import { SettingsContext } from "./Context";
import {
  OrganizationAccessTabs,
  OrganizationAuthTabs,
  OrganizationOtherTabs,
  OrganizationSecurityTabs,
  UserManagementActivationStatus,
} from "./types";
import { pathConfig } from "./constants";
import { getAnalyticsTab } from "./getAnalyticsTab";
import { GET_ACCOUNT_SETTINGS, TOGGLE_ACCOUNT_DELETION_MARK } from "./gql";
const isSaas = isSaasDistribution();

type GetAccountSettingsGqlSelfHosted = {
  authorizationScheme: AuthorizationScheme;
  slackIntegration: Maybe<SlackIntegration>;
  accountCanBeDeletedAt: number;
  accountCanBeDeleted: boolean;
  apiKeys: ApiKey[];
  markedForDeletion: boolean;
  azureIntegrations: AzureIntegration[];
  securityEmail: undefined;
};

type GetAccountSettingsGqlSaaS = GetAccountSettingsGqlSelfHosted & {
  securityEmail: string;
};

type GetAccountSettingsGql = GetAccountSettingsGqlSaaS | GetAccountSettingsGqlSelfHosted;

const Settings = () => {
  const { mfa, billingUsage } = useTypedFlags();

  const { onError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);
  const location = useLocation();
  const isAdmin = viewer.admin;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.Organization,
  });

  const { loading, data, error, networkStatus } = useQuery<GetAccountSettingsGql>(
    GET_ACCOUNT_SETTINGS,
    {
      variables: {
        isSaas,
      },
      onError,
    }
  );

  const [toggleAccountDeletionMark, { loading: loadingMarking }] = useMutation(
    TOGGLE_ACCOUNT_DELETION_MARK,
    {
      refetchQueries: [{ query: GET_ACCOUNT_SETTINGS, variables: { isSaas } }],
    }
  );

  const toggleForDeletionMark = () => {
    trackSegmentAnalyticsEvent(
      data?.markedForDeletion ? "Mark for Deletion Click" : "Do not Delete Click",
      {
        tab: getAnalyticsTab(location.pathname),
      }
    );
    toggleAccountDeletionMark().then().catch(onError);
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const isLoading = loading && networkStatus !== NetworkStatus.refetch;

  if (isLoading || !data) {
    return <PageLoading />;
  }

  const activationStatus =
    data?.authorizationScheme === AuthorizationScheme.ManagedUsers
      ? UserManagementActivationStatus.ACTIVE
      : UserManagementActivationStatus.INACTIVE;

  const settingsContextValue = {
    activationStatus,
  };

  return (
    <SettingsContext.Provider value={settingsContextValue}>
      <ViewHeader>
        <ViewHeaderWrapper direction="row">
          <ViewHeaderTitle titleSize="p-t3">Organization settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
        {isSaas && (
          <ViewHeaderWrapper direction="row" align="end">
            {data.markedForDeletion ? (
              <>
                {data.accountCanBeDeleted && <ConfirmDeleteButton />}
                <DSButton variant="primary" onClick={toggleForDeletionMark}>
                  Do not delete
                </DSButton>
              </>
            ) : (
              <DSButton
                variant="dangerSecondary"
                loading={loadingMarking}
                onClick={toggleForDeletionMark}
              >
                Mark for deletion
              </DSButton>
            )}
          </ViewHeaderWrapper>
        )}
      </ViewHeader>
      <Box className={styles.content}>
        <SubSidebar>
          <SubSidebarSection>
            <SubSidebarSectionTitle>ACCESS</SubSidebarSectionTitle>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAccessTabs.USERS,
              }}
              to={pathConfig[OrganizationAccessTabs.USERS]}
              v5Compat
            >
              Users
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAccessTabs.IDP_GROUP_MAPPING,
              }}
              to={pathConfig[OrganizationAccessTabs.IDP_GROUP_MAPPING]}
              v5Compat
            >
              IdP group mapping
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAccessTabs.API_KEYS,
              }}
              to={pathConfig[OrganizationAccessTabs.API_KEYS]}
              v5Compat
            >
              API keys
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAccessTabs.LOGIN_POLICIES,
              }}
              to={pathConfig[OrganizationAccessTabs.LOGIN_POLICIES]}
              v5Compat
            >
              Login policy
            </SubSidebarSectionItem>

            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationAccessTabs.MANAGEMENT_STRATEGY,
              }}
              to={pathConfig[OrganizationAccessTabs.MANAGEMENT_STRATEGY]}
              v5Compat
            >
              Management strategy
            </SubSidebarSectionItem>
          </SubSidebarSection>
          {isSaas && (
            <SubSidebarSection>
              <SubSidebarSectionTitle>Authentication</SubSidebarSectionTitle>
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationAuthTabs.SSO,
                }}
                to={pathConfig[OrganizationAuthTabs.SSO]}
                v5Compat
              >
                Single Sign-on
              </SubSidebarSectionItem>
              {mfa && isAdmin && (
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationAuthTabs.MFA,
                  }}
                  to={pathConfig[OrganizationAuthTabs.MFA]}
                  v5Compat
                >
                  Multi-factor authentication
                </SubSidebarSectionItem>
              )}
            </SubSidebarSection>
          )}
          <SubSidebarSection>
            <SubSidebarSectionTitle>SECURITY</SubSidebarSectionTitle>

            {isSaas && (
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPageOrganization.Organization}
                analyticsTitle="Switch View"
                analyticsProps={{
                  tab: OrganizationSecurityTabs.SECURITY_EMAIL,
                }}
                to={pathConfig[OrganizationSecurityTabs.SECURITY_EMAIL]}
                v5Compat
              >
                Security email
              </SubSidebarSectionItem>
            )}
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationSecurityTabs.AUDIT_TRAIL,
              }}
              to={pathConfig[OrganizationSecurityTabs.AUDIT_TRAIL]}
              v5Compat
            >
              Audit Trail
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationSecurityTabs.SESSIONS,
              }}
              to={pathConfig[OrganizationSecurityTabs.SESSIONS]}
              v5Compat
            >
              Sessions
            </SubSidebarSectionItem>
          </SubSidebarSection>
          <SubSidebarSection>
            <SubSidebarSectionTitle>OTHER</SubSidebarSectionTitle>

            <SubSidebarSectionItem
              analyticsPage={AnalyticsPageOrganization.Organization}
              analyticsTitle="Switch View"
              analyticsProps={{
                tab: OrganizationOtherTabs.INTEGRATIONS,
              }}
              to={pathConfig[OrganizationOtherTabs.INTEGRATIONS]}
              v5Compat
            >
              Integrations
            </SubSidebarSectionItem>

            {isSaas && (
              <>
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationOtherTabs.BILLING,
                  }}
                  to={pathConfig[OrganizationOtherTabs.BILLING]}
                  v5Compat
                >
                  Billing
                </SubSidebarSectionItem>
                {billingUsage && (
                  <SubSidebarSectionItem
                    analyticsPage={AnalyticsPageOrganization.Organization}
                    analyticsTitle="Switch View"
                    analyticsProps={{
                      tab: OrganizationOtherTabs.USAGE,
                    }}
                    to={pathConfig[OrganizationOtherTabs.USAGE]}
                    v5Compat
                  >
                    Usage
                  </SubSidebarSectionItem>
                )}
                <SubSidebarSectionItem
                  analyticsPage={AnalyticsPageOrganization.Organization}
                  analyticsTitle="Switch View"
                  analyticsProps={{
                    tab: OrganizationOtherTabs.LIMITS,
                  }}
                  to={pathConfig[OrganizationOtherTabs.LIMITS]}
                  v5Compat
                >
                  Limits
                </SubSidebarSectionItem>
              </>
            )}
          </SubSidebarSection>
        </SubSidebar>

        <PageWrapper className={styles.wrapper}>
          {data.markedForDeletion && (
            <Callout variant="danger" title="Account marked for deletion">
              <SettingsAccountDeleteWarning
                accountCanBeDeleted={data.accountCanBeDeleted}
                accountCanBeDeletedAt={data.accountCanBeDeletedAt}
              />
            </Callout>
          )}

          <SettingsRoutes apiKeys={data.apiKeys} securityEmail={data.securityEmail} />
        </PageWrapper>
      </Box>
    </SettingsContext.Provider>
  );
};

export default Settings;
