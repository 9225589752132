import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { StackDependencyReference, StackDependencyReferenceInput } from "types/generated";

import { STACK_DEPENDENCIES_ADD_REFERENCES } from "./gql";

const useAddOutputReference = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackDependenciesAddReferences] = useMutation<{
    stackDependenciesAddReferences: StackDependencyReference[];
  }>(STACK_DEPENDENCIES_ADD_REFERENCES, {
    refetchQueries: ["GetStack"],
    onError,
  });

  const handleAddReferences = useCallback(
    (
      stackDependencyID: string,
      references: StackDependencyReferenceInput[],
      callback?: () => void
    ) => {
      stackDependenciesAddReferences({
        variables: {
          references,
          stackDependencyID,
        },
      })
        .then(({ data }) => {
          if (
            data?.stackDependenciesAddReferences &&
            data.stackDependenciesAddReferences.length > 0
          ) {
            reportSuccess({
              message: `Output reference successfully added`,
            });
            callback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackDependenciesAddReferences]
  );

  return {
    addOutputReference: handleAddReferences,
  };
};

export default useAddOutputReference;
