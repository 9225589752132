import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActions/types";
import { Run, Stack } from "types/generated";

import { StackRunsMutationVariables } from "./useStackRunsBulkActions";

export enum StackRunsBulkActions {
  Confirm = "Confirm",
  Discard = "Discard",
  ReviewApprove = "Approve",
  ReviewReject = "Reject",
}

export type StackRunsBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
  metadata: Record<string, string | boolean>;
};

export type StackRunsBulkActionsSelectedAction = BulkEntityActionItem<
  StackRunsBulkActions,
  Run,
  StackRunsMutationVariables
>;

export type DefaultMutationVariables = {
  stack: Stack;
};

export type ReviewRunVariables = DefaultMutationVariables & {
  reviewNote?: string;
};
