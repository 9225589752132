import { RefObject, useCallback } from "react";

import BulkActions from "components/BulkActions";
import BulkActionsDrawer from "components/BulkActions/Drawer";
import BulkActionsDrawerActionSteps from "components/BulkActions/Drawer/ActionSteps";
import BulkActionsDrawerActionStepsHeader from "components/BulkActions/Drawer/ActionSteps/Header";
import BulkActionsDrawerActionStepsInfoBanner from "components/BulkActions/Drawer/ActionSteps/InfoBanner";
import BulkActionsDrawerResultsStep from "components/BulkActions/Drawer/ResultsStep";
import BulkActionsDrawerResultsStepHeader from "components/BulkActions/Drawer/ResultsStep/Header";
import BulkActionsDrawerResultsStepBody from "components/BulkActions/Drawer/ResultsStep/Body";
import BulkActionsDrawerResultsStepBodyEmptyTab from "components/BulkActions/Drawer/ResultsStep/Body/EmptyTab";
import BulkActionsDrawerResultsStepFooter from "components/BulkActions/Drawer/ResultsStep/Footer";
import { getDefaultOnContinueWith } from "components/BulkActions/Drawer/ResultsStep/helpers";
import { type BulkActionResultsStepContinueWith } from "components/BulkActions/Drawer/ResultsStep/types";
import BulkActionsFloatingBar from "components/BulkActions/FloatingBar";
import BulkActionsFloatingBarHeader from "components/BulkActions/FloatingBar/Header";
import BulkActionsFloatingBarSteps from "components/BulkActions/FloatingBar/Steps";
import { type BulkActionItemTriggerResult } from "components/BulkActions/helpers";
import {
  BulkActionItemID,
  BulkActionResultTabs,
  BulkActionsCloseMethod,
  BulkActionsResult,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import useBulkActions from "components/BulkActions/useBulkActions";
import useExecutionQueue from "components/BulkActions/useExecutionQueue";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { Stack } from "types/generated";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";
import { useResultTabItems } from "components/BulkActions/useResultTabItems";
import { LoadingOnSolid } from "components/icons";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";

import StacksBulkActionsConfirmActionForm from "./ConfirmActionForm";
import StacksBulkActionsConfirmationView from "./ConfirmationView";
import StacksBulkActionsResultItem from "./ResultItem";
import StacksBulkActionsSelectedItemsView from "./SelectedItemsView";
import { StackBulkActionsAnalyticsPayload } from "./types";
import useStackBulkActions from "./useStackBulkActions";
import { StackMutationVariables } from "./useStackBulkActions/types";

type StacksBulkActionsProps = {
  virtualizedListContainerRef?: RefObject<HTMLElement>;
  selectedSet: Set<BulkActionItemID>;
  stacksMap: Map<BulkActionItemID, Stack>;
  onBulkResetAll: () => void;
  onBulkContinueWith: (continueWith: Set<BulkActionItemID>) => void;
  onItemDismiss: (id: BulkActionItemID) => void;
  onFinish: () => Promise<unknown>;
  isLoadingItems?: boolean;
  onSeeDetailsCallback?: () => void;
};

const StacksBulkActions = ({
  selectedSet,
  stacksMap,
  onBulkResetAll,
  onBulkContinueWith,
  onItemDismiss,
  virtualizedListContainerRef,
  onFinish,
  isLoadingItems,
  onSeeDetailsCallback,
}: StacksBulkActionsProps) => {
  const selectedItemsCount = selectedSet.size;

  const stacksBulkActions = useStackBulkActions();
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksList,
  });

  const {
    selectedBulkAction,
    resetSelectedAction,
    availableActions,
    itemsPerAction,
    actionsPerItem,
    selectedEntityItems,
    applicableSelectedEntityItems,
    skippedSelectedEntityItems,
  } = useBulkActions({
    actions: stacksBulkActions,
    selectedSet,
    entityItemsMap: stacksMap,
    analyticsPage: AnalyticsPageStack.StacksList,
  });

  const onItemTrigger = useCallback(
    (
      id: BulkActionItemID,
      mutationVariables: StackMutationVariables
    ): Promise<BulkActionItemTriggerResult> => {
      const stack = stacksMap.get(id);
      if (!stack || !selectedBulkAction) {
        return Promise.reject();
      }
      if (!selectedBulkAction.condition(stack)) {
        return Promise.resolve(BulkActionsResult.Skipped);
      }
      return selectedBulkAction.mutation(stack, mutationVariables);
    },
    [stacksMap, selectedBulkAction]
  );

  const {
    triggerExecution,
    bulkActionItemResults,
    stopExecution,
    stopExecutionForItem,
    bulkActionResultsMetadata,
    resetBulkActionsExecutionQueue,
  } = useExecutionQueue({
    onItemTrigger,
    onAllItemsFinished: onFinish,
    analyticsPage: AnalyticsPageStack.StacksList,
  });

  const { resultsTabItems, saveEntityMap, resetEntityMap } =
    useResultTabItems<Stack>(bulkActionItemResults);

  const handleContinueWith = useCallback(
    (continueWith: BulkActionResultsStepContinueWith) => {
      getDefaultOnContinueWith(resultsTabItems, onBulkContinueWith)(continueWith);
      resetSelectedAction();
      resetBulkActionsExecutionQueue();
    },
    [onBulkContinueWith, resetSelectedAction, resultsTabItems, resetBulkActionsExecutionQueue]
  );

  const handleClose = useCallback(
    (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => {
      onBulkResetAll();
      resetSelectedAction();
      stopExecution();
      resetBulkActionsExecutionQueue();
      resetEntityMap();
      trackSegmentAnalyticsEvent("Bulk Actions - Exited", {
        view: variant,
        method,
      });
    },
    [
      onBulkResetAll,
      resetBulkActionsExecutionQueue,
      resetEntityMap,
      resetSelectedAction,
      stopExecution,
      trackSegmentAnalyticsEvent,
    ]
  );

  const handleOnConfirm = useCallback(
    (
      mutationVariables: StackMutationVariables,
      analyticsPayload: StackBulkActionsAnalyticsPayload
    ) => {
      if (selectedBulkAction) {
        const items = itemsPerAction.get(selectedBulkAction.key) || [];
        const skippedItems = skippedSelectedEntityItems.map((item) => item.id);
        triggerExecution(items, mutationVariables, skippedItems);
        saveEntityMap(stacksMap);
        trackSegmentAnalyticsEvent("Bulk Actions - Action Confirmed", {
          applicableCount: items?.length || 0,
          notApplicableCount: skippedItems.length,
          action: selectedBulkAction.key,
          ...analyticsPayload,
        });
      }
    },
    [
      itemsPerAction,
      saveEntityMap,
      selectedBulkAction,
      skippedSelectedEntityItems,
      stacksMap,
      trackSegmentAnalyticsEvent,
      triggerExecution,
    ]
  );

  const handleOnCancel = (variant: BulkActionsVariant) => () => {
    if (selectedBulkAction) {
      resetSelectedAction();
      const items = itemsPerAction.get(selectedBulkAction.key);
      trackSegmentAnalyticsEvent("Bulk actions - Action Cancelled", {
        applicableCount: items?.length || 0,
        notApplicableCount: skippedSelectedEntityItems.length,
        action: selectedBulkAction.key,
        view: variant,
      });
    }
  };

  return (
    <BulkActions
      selectedItemsCount={selectedItemsCount}
      onClose={handleClose}
      isExecutingBulkActions={!!resultsTabItems[BulkActionResultTabs.Pending].count}
    >
      <BulkActionsFloatingBar virtualizedListContainerRef={virtualizedListContainerRef}>
        <BulkActionsFloatingBarHeader
          selectedItemsCount={selectedItemsCount}
          applicableItemsCount={
            selectedBulkAction?.key ? itemsPerAction.get(selectedBulkAction.key)?.length : 0
          }
          analyticsPage={AnalyticsPageStack.StacksList}
          hideDetails={isLoadingItems}
          onSeeDetailsCallback={onSeeDetailsCallback}
        />
        <BulkActionsFloatingBarSteps>
          {!selectedBulkAction && !isLoadingItems && (
            <BulkActionsPatternsActionsList
              variant={BulkActionsVariant.FloatingBar}
              availableActions={availableActions}
              onEmptyActionsCancel={handleClose}
            />
          )}

          {selectedBulkAction && !isLoadingItems && (
            <StacksBulkActionsConfirmActionForm
              variant={BulkActionsVariant.FloatingBar}
              action={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.FloatingBar)}
            />
          )}
          {isLoadingItems && (
            <Box padding="small 0">
              <span className="sr-only" role="alert" aria-busy="true">
                Loading your stacks...
              </span>
              <Icon src={LoadingOnSolid} />
            </Box>
          )}
        </BulkActionsFloatingBarSteps>
      </BulkActionsFloatingBar>

      {!isLoadingItems && (
        <BulkActionsDrawer>
          <BulkActionsDrawerActionSteps>
            <BulkActionsDrawerActionStepsHeader
              analyticsPage={AnalyticsPageStack.StacksList}
              step={
                !selectedBulkAction
                  ? BulkActionsStep.ChooseAction
                  : BulkActionsStep.ActionConfirmation
              }
            />
            <BulkActionsDrawerActionStepsInfoBanner />

            {!selectedBulkAction && (
              <StacksBulkActionsSelectedItemsView
                items={selectedEntityItems}
                onItemDismiss={onItemDismiss}
                availableActions={availableActions}
                actionsPerItem={actionsPerItem}
                onEmptyActionsCancel={() =>
                  handleClose(BulkActionsVariant.Drawer, BulkActionsCloseMethod.EmptyActionsButton)
                }
              />
            )}
            {selectedBulkAction && (
              <StacksBulkActionsConfirmationView
                applicableItems={applicableSelectedEntityItems}
                skippedItems={skippedSelectedEntityItems}
                onItemDismiss={onItemDismiss}
                selectedBulkAction={selectedBulkAction}
                onConfirm={handleOnConfirm}
                onCancel={handleOnCancel(BulkActionsVariant.Drawer)}
              />
            )}
          </BulkActionsDrawerActionSteps>

          <BulkActionsDrawerResultsStep>
            {(props) => (
              <>
                <BulkActionsDrawerResultsStepHeader
                  title={selectedBulkAction?.resultTitle || "Results"}
                  analyticsPage={AnalyticsPageStack.StacksList}
                />
                <BulkActionsDrawerResultsStepBody {...props} counter={resultsTabItems}>
                  {({ currentTab }) =>
                    resultsTabItems[currentTab].count === 0 ? (
                      <BulkActionsDrawerResultsStepBodyEmptyTab currentTab={currentTab} />
                    ) : (
                      resultsTabItems[currentTab].entitiesList.map(({ status, items }) =>
                        items.map((stack) => (
                          <StacksBulkActionsResultItem
                            key={stack.id}
                            tab={currentTab}
                            stack={stack}
                            resultsMetadata={bulkActionResultsMetadata}
                            stopExecution={() => stopExecutionForItem(stack.id)}
                            status={status}
                          />
                        ))
                      )
                    )
                  }
                </BulkActionsDrawerResultsStepBody>
                <BulkActionsDrawerResultsStepFooter
                  {...props}
                  bulkActionItemResults={bulkActionItemResults}
                  onContinueWith={handleContinueWith}
                  stopExecution={stopExecution}
                  analyticsPage={AnalyticsPageStack.StacksList}
                />
              </>
            )}
          </BulkActionsDrawerResultsStep>
        </BulkActionsDrawer>
      )}
    </BulkActions>
  );
};

export default StacksBulkActions;
