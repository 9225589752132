import { gql } from "@apollo/client";

import { moduleEditingDetails } from "components/form/graphql";

export const GET_MODULE_SETTINGS = gql`
  query GetModuleSettings($id: ID!) {
    module(id: $id) {
      ...moduleEditingDetails
    }
  }
  ${moduleEditingDetails}
`;

export const DELETE_MODULE = gql`
  mutation DeleteModule($moduleId: ID!) {
    moduleDelete(id: $moduleId) {
      id
    }
  }
`;
