import { VcsProvider } from "types/generated";

export const checkWithMultipleVCSIntegrations = (provider?: VcsProvider): boolean => {
  return (
    provider === VcsProvider.GithubEnterprise ||
    provider === VcsProvider.AzureDevops ||
    provider === VcsProvider.Gitlab ||
    provider === VcsProvider.BitbucketCloud ||
    provider === VcsProvider.BitbucketDatacenter
  );
};
