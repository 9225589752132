import { moduleDocument, stackDocument } from "./documents";

const Access = `package spacelift

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
# ⚠️ Access policies only take effect once attached to a Stack or Module ⚠️
#
# Access policy can give read or write access to individual Stacks and/or
# Modules to non-admin users in your account. As input, it receives a
# JSON document like this:
#
# {
#   "request": {
#     "remote_ip": "string",
#     "timestamp_ns": "number - current Unix timestamp in nanoseconds"
#   },
#   "session": {
#     "login": "string",
#     "name":  "string",
#     "teams": ["string"]
#   },
#   "stack": {
${stackDocument(5)}
#   },
#   "module": {
${moduleDocument(5)}
#   }
# }
#
# Your policy can define an arbitrary number of following rules:
#
#   - write: gives the current user write access to the stack;
#   - read: gives the current user read access to the stack;
#   - deny: denies the current user any access to the stack;
#
# Keep in mind the following:
#
#   - it takes one positive value of "deny" to deny access no matter what the
#     other results are;
#   - write access automatically assumes read access;
#
# Below are few examples of what you can do with these rules:
#
# 1) Allow members of the DevOps team to write a stack:
# write {
#   input.session.teams[_] == "DevOps"
# }
#
# 2) Deny access on weekends:
# deny {
#   today   := time.weekday(input.request.timestamp_ns)
#   weekend := {"Saturday", "Sunday"}
#
#   weekend[today]
# }
#
# 3) Deny access if the stack is administrative:
# deny {
#   input.stack.administrative
# }
#
# 4) Give write access to an arbitrary user:
#
# write {
#   input.session.login = "OurAwesomeConsultant"
# }
#
#
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
`;

export default Access;
