import { ReactNode } from "react";

import DocumentationIconButton from "components/DocumentationIconButton";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import { getDocsUrl } from "utils/getDocsUrl";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesContext } from "../SpacesProvider";
import BlueprintsUpSell from "./UpSell";
import BlueprintsFeatureGateTooltip from "./FeatureGate/Tooltip";
import { BlueprintActions } from "./FeatureGate/types";

type BlueprintsPageLayoutProps = {
  children?: ReactNode;
  onCreate?: () => void;
  showUpSell?: boolean;
  hideActions?: boolean;
};

const BlueprintsPageLayout = ({
  children,
  onCreate,
  showUpSell,
  hideActions,
}: BlueprintsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Blueprints · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Blueprints</ViewHeaderTitle>

        {!hideActions && !showUpSell && (
          <ViewHeaderWrapper direction="row">
            <SearchInput
              placeholder="Search blueprints..."
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />

            <DocumentationIconButton
              href={getDocsUrl("/concepts/blueprint")}
              tooltipText="Go to blueprints documentation"
            />

            {hasEntityCreateAccess && onCreate && (
              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.CreateBlueprint}
                on={({ isDisabled, ...props }) => (
                  <Button {...props} onClick={onCreate} variant="primary" disabled={isDisabled}>
                    Create blueprint
                  </Button>
                )}
              />
            )}
          </ViewHeaderWrapper>
        )}
      </ViewHeader>

      {showUpSell ? <BlueprintsUpSell /> : children}
    </>
  );
};

export default BlueprintsPageLayout;
