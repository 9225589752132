import { memo, useEffect, useMemo, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { subDays } from "date-fns";

import Filters from "components/Filters";
import useURLParams from "hooks/useURLParams";
import Toggle from "ds/components/Toggle";
import {
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
  SearchSuggestionsOutput,
} from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersPanel from "components/Filters/FiltersPanel";
import SortDropdown from "components/Filters/SortDropdown";
import Box from "ds/components/Box";
import FiltersSplit from "components/Filters/Split";
import { getSearchQuery } from "components/SearchInput/helpers";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";
import DatepickerRange from "ds/components/Datepicker/Range";
import { AnalyticsPageNotifications } from "hooks/useAnalytics/pages/notifications";

import { SEARCH_NOTIFICATIONS_SUGGESTIONS } from "../gql";
import {
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_PERIOD,
  initialSortDirection,
  initialSortOption,
} from "../constants";
import { POLL_INTERVAL } from "../constants";
import { PERIODS, notificationsSuggestionsDictionary } from "./constants";
import { NotificationsContext } from "../Context";
import DismissAllNotificationsButton from "../DismissAllNotificationsButton";

type StackFiltersLayoutProps = {
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
  hasItems: boolean;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
  newNotificationsCount?: number;
};

const NotificationsFiltersLayout = ({
  allSelected,
  onSelectAll,
  onResetAll,
  predicates,
  children,
  hasItems,
  currentSavedView,
  setCurrentSavedView,
  newNotificationsCount,
}: StackFiltersLayoutProps) => {
  const fieldsRef = useRef<string[] | null>(null);

  const { setCurrentDateRange, currentDateRange } = useTypedContext(NotificationsContext);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [loadSearchNotificationsSuggestions, { data: filteringData, loading, refetch }] =
    useLazyQuery<{
      searchNotificationsSuggestions: SearchSuggestionsOutput;
    }>(SEARCH_NOTIFICATIONS_SUGGESTIONS, { pollInterval: POLL_INTERVAL });

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchNotificationsSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            label: notificationsSuggestionsDictionary[field.name] || field.name,
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, notificationsFiltersItemsOptionsMap] = useMemo<
    [FilterItem[], FiltersItemsOptionsMap]
  >(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        // timestamp is not a filter, it's a date range option
        .filter((field) => field.filterable && field.name !== "timestamp")
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = notificationsSuggestionsDictionary[key] || key;
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const handleSelectAllToggle = () => {
    if (!allSelected) {
      onSelectAll();
    } else {
      onResetAll();
    }
  };

  const loadSearchSuggestions = (initial = false) => {
    try {
      loadSearchNotificationsSuggestions({
        variables: {
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields: initial ? null : fieldsRef.current,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    fieldsRef.current = fields;

    refetch({
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields,
      },
    });
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={notificationsFiltersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersDictionary={notificationsSuggestionsDictionary}
      filtersType="notifications"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageNotifications.NotificationsList} />

        <FiltersContentWrapper>
          <FiltersPanel>
            <Box direction="row">
              {hasItems && (
                <Toggle
                  variant="checkbox"
                  id="select_all"
                  checked={allSelected}
                  onChange={handleSelectAllToggle}
                  ariaLabel={allSelected ? "Unselect all" : "Select all"}
                >
                  Select all
                </Toggle>
              )}
            </Box>

            <Box direction="row" gap="medium">
              {!!newNotificationsCount && <DismissAllNotificationsButton />}
              <DatepickerRange
                isButton
                periods={PERIODS}
                initialPeriod={INITIAL_PERIOD}
                endDate={currentDateRange.end}
                startDate={currentDateRange.start}
                onChange={setCurrentDateRange}
                minDate={subDays(new Date(), 14)}
                maxDate={new Date()}
              />

              <SortDropdown />
            </Box>
          </FiltersPanel>

          {children}
        </FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(NotificationsFiltersLayout);
