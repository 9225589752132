import { ConfigElement, ConfigType } from "types/generated";

import EditEnvironmentVariable from "./EditEnvironmentVariable";
import EditFileMount from "./EditFileMount";

type EditConfigElementProps = {
  element: ConfigElement;
  onCancel: () => unknown;
  onSave: (value: string | undefined) => unknown;
  loading: boolean;
};

const EditConfigElement = ({ element, loading, onCancel, onSave }: EditConfigElementProps) => (
  <>
    {element.type === ConfigType.EnvironmentVariable && (
      <EditEnvironmentVariable
        element={element}
        loading={loading}
        onCancel={onCancel}
        onSave={onSave}
      />
    )}
    {element.type === ConfigType.FileMount && (
      <EditFileMount
        relativePath={element.id}
        loading={loading}
        onCancel={onCancel}
        onSave={onSave}
        value={element.value || ""}
      />
    )}
  </>
);

export default EditConfigElement;
