import { debounce } from "lodash-es";
import { RefObject, useCallback, useState } from "react";

export const useAutoScroll = (ref: RefObject<HTMLDivElement>) => {
  // Logic is the following:
  // Always scroll down to the end of the input, unless the user explicitly
  // scrolls it up, at which point stop scrolling down automatically and only
  // resume if the user manually scrolls down to the very bottom.
  const [autoScroll, setAutoScroll] = useState(true);

  const autoScrollToBottom = useCallback(() => {
    if (autoScroll && ref.current) {
      const { scrollHeight, clientHeight } = ref.current;
      ref.current.scrollTop = Math.max(scrollHeight - clientHeight, 0);
    }
  }, [autoScroll, ref]);

  const handleScroll = debounce(() => {
    if (ref.current === null) {
      return;
    }

    const { scrollHeight, scrollTop, clientHeight } = ref.current;
    const isScrollEnd = scrollHeight - scrollTop === clientHeight;

    setAutoScroll(isScrollEnd);
  }, 100);

  return {
    handleScroll,
    autoScrollToBottom,
  };
};
