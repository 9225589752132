import { useCallback, useEffect, useMemo, useRef } from "react";
import camelCase from "lodash-es/camelCase";
import cx from "classnames";
import {
  Modal as AriaModal,
  Dialog as AriaDialog,
  ModalOverlay as AriaModalOverlay,
  ModalContext,
} from "react-aria-components";

import { ignoreOutsideClick } from "hooks/useOutsideClick";

import styles from "./styles.module.css";
import { ModalInnerProps, ModalProps } from "./types";

const ModalInner = ({ children, role, isExiting, onExit }: ModalInnerProps) => {
  const onExitRef = useRef(onExit);

  useEffect(() => {
    onExitRef.current = onExit;
  }, [onExit]);

  useEffect(() => {
    return () => {
      if (isExiting) {
        onExitRef.current?.();
      }
    };
  }, [isExiting]);

  return (
    <AriaDialog role={role} className={styles.dialog}>
      {children}
    </AriaDialog>
  );
};

const Modal = ({
  children,
  size = "default",
  withAnimation = true,
  role,
  isOpen,
  onOpen,
  onClose,
  onExit,
  shouldIgnoreOutsideClick,
  isDismissable = true,
}: ModalProps) => {
  const handleOnOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    [onOpen, onClose]
  );

  const modalContext = useMemo(() => ({ isDismissable }), [isDismissable]);

  return (
    <ModalContext.Provider value={modalContext}>
      <AriaModalOverlay
        isKeyboardDismissDisabled={!isDismissable}
        isOpen={isOpen}
        onOpenChange={handleOnOpenChange}
        className={({ isEntering, isExiting }) =>
          cx({
            [styles.enterAnimation]: isEntering,
            [styles.exitAnimation]: isExiting,
            [styles.overlay]: withAnimation,
          })
        }
        {...(shouldIgnoreOutsideClick ? ignoreOutsideClick : null)}
      >
        <AriaModal
          className={({ isEntering, isExiting }) =>
            cx(
              styles.modal,
              size && styles[camelCase(size)],
              withAnimation && {
                [styles.enterAnimation]: isEntering,
                [styles.exitAnimation]: isExiting,
              }
            )
          }
        >
          {({ isExiting }) => (
            <ModalInner role={role} isExiting={isExiting} onExit={onExit}>
              {children}
            </ModalInner>
          )}
        </AriaModal>
      </AriaModalOverlay>
    </ModalContext.Provider>
  );
};

export default Modal;
