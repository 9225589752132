import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Stack } from "types/generated";

import { GET_STACK_SETTINGS } from "./gql";

const useGetStackSettings = (stackId: string | undefined) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, error, loading } = useQuery<{ stack: Stack }>(GET_STACK_SETTINGS, {
    onError,
    variables: {
      stackId,
    },
    skip: !stackId,
  });

  return {
    stackSettings: data?.stack,
    error,
    loading,
  };
};

export default useGetStackSettings;
