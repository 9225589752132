import { Pages } from "./types";

export const enum AnalyticsPageVCS {
  VCSAgentPool = "VCSAgentPool",
  VCSAgentPoolNew = "VCSAgentPoolNew",
  VCSAgentPoolsList = "VCSAgentPoolsList",
  VCSIntegrations = "VCSIntegrations",
}

const vcsAgentPools = "VCS Agent Pools";
const vcsIntegrations = "Source Code Integrations";

export const pagesVCS: Pages<AnalyticsPageVCS> = {
  [AnalyticsPageVCS.VCSAgentPoolNew]: [vcsAgentPools, "New VCS Agent Pool"],
  [AnalyticsPageVCS.VCSIntegrations]: [vcsIntegrations, "Source Code Integrations list"],
  [AnalyticsPageVCS.VCSAgentPool]: [vcsAgentPools, "VCS Agent Pool"],
  [AnalyticsPageVCS.VCSAgentPoolsList]: [vcsAgentPools, "VCS Agent Pools List"],
};
