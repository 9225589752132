import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageSpaces } from "hooks/useAnalytics/pages/spaces";

import { SPACE_DELETE } from "../gql";

type DeleteConfirmationProps = {
  name: string;
  id: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  name,
  id,
}: DeleteConfirmationProps) {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageSpaces.Spaces,
  });

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteSpace, { loading, data }] = useMutation(SPACE_DELETE, {
    variables: {
      spaceId: id,
    },
    awaitRefetchQueries: true,
    refetchQueries: ["GetSpaces"],
  });

  const handleDeleteSpace = () => {
    deleteSpace()
      .then(() => {
        reportSuccess({
          message: `Space "${name}" was successfully deleted`,
        });

        trackSegmentEvent("Space Deleted", {
          id,
        });

        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete space"
      onConfirm={handleDeleteSpace}
      isLoading={loading}
      isDismissable={!loading && !data?.spaceDelete?.id}
    >
      <Typography variant="p-body2" tag="span">
        Are you sure you want to delete the space <strong>{name}</strong>? Removing the space will
        have irreversible consequences.
      </Typography>
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
