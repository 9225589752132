import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Navigate } from "react-router-dom-v5-compat";

import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import { createPlainDataLayer, getSubdomain } from "shared/Analytics";
import useTypedContext from "hooks/useTypedContext";
import { BillingTier, SubscriptionInput } from "types/generated";
import { GET_TIER } from "views/Account/SubscriptionWrapper";

import FormContent from "../Form";

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription(
    $paymentMethodID: String!
    $input: SubscriptionInput!
    $tier: BillingTier
  ) {
    billingSubscriptionCreate(paymentMethodID: $paymentMethodID, input: $input, tier: $tier) {
      isActive
      selfServePortalUrl
      billingCycleStart
    }
  }
`;

type BillingUpgradeFormProps = {
  tier: BillingTier;
};

const BillingUpgradeForm = ({ tier }: BillingUpgradeFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { viewer } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [createSubscription, { loading }] = useMutation(CREATE_SUBSCRIPTION, {
    refetchQueries: [{ query: GET_TIER }],
  });
  const [shouldRedirect, setShouldRedirect] = useState<boolean | null>(null);

  const sendData = (input: SubscriptionInput, paymentMethodID: string) => {
    createSubscription({ variables: { paymentMethodID, input, tier } })
      .then(() => {
        setShouldRedirect(true);
        createPlainDataLayer({
          event: "upgrade_plan",
          hostname: getSubdomain(),
          plan_type: tier,
          identityProvider: viewer.identityProvider,
          isFirstUser: viewer.isFirstUser ? "true" : "false",
        });

        reportSuccess({ message: "Subscription plan was upgraded" });
      })
      .catch(onError);
  };

  const handleSubmit = async (input: SubscriptionInput) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        onError(error);
      } else {
        sendData(input, paymentMethod.id);
      }
    } else {
      onError(new Error("Unable to read card"));
    }
  };

  if (shouldRedirect) {
    return <Navigate to="/settings/billing" />;
  }

  return <FormContent handleSubmit={handleSubmit} loading={loading} card />;
};

export default BillingUpgradeForm;
