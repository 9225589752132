import { ReactNode } from "react";
import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";
import { ConfirmationModalProps } from "ds/components/ConfirmationModal/types";

type NewContextFooterContinueConfirmationProps = {
  onConfirm: () => void;
  warning?: ReactNode;
  confirmationButtonLabel: ConfirmationModalProps["confirmationButtonLabel"];
};

const NewContextFooterContinueConfirmation = create(function NewContextFooterContinueConfirmation({
  onConfirm,
  warning,
  confirmationButtonLabel,
}: NewContextFooterContinueConfirmationProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <ConfirmationModal
      onConfirm={handleOnConfirm}
      size="large"
      confirmationButtonVariant="primary"
      confirmationButtonLabel={confirmationButtonLabel}
    >
      <Typography tag="p" variant="p-body2">
        {warning || "You have unsubmitted changes"}
      </Typography>
    </ConfirmationModal>
  );
});

export const showNewContextFooterContinueConfirmation = (
  props: NewContextFooterContinueConfirmationProps
) => show(NewContextFooterContinueConfirmation, props);
