import { useMutation } from "@apollo/client";

import { UPDATE_MODULE } from "components/form/graphql";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GetData, UpdateModuleGql } from "./types";
import { ModuleFormFields } from "../types";
import { ModuleFormContext } from "../context";
import { getModuleConfig } from "../getModuleConfig";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

export const useUpdateModule = (isDataChanged?: boolean) => {
  const { createdModuleId, setStepperNavDisabled } = useTypedContext(ModuleFormContext);
  const { onError } = useTypedContext(FlashContext);

  const [updateModule] = useMutation<UpdateModuleGql>(UPDATE_MODULE);

  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const updateModuleHandler = ({
    setLoading,
    callback,
    getData,
  }: {
    setLoading: (loading: boolean) => void;
    callback?: () => void;
    getData?: GetData;
  }) => {
    Promise.resolve(getData?.())
      .then((formData: ModuleFormFields | void) => {
        if (createdModuleId && formData && isDataChanged) {
          setLoading(true);
          setStepperNavDisabled(true);

          updateModule({
            variables: {
              moduleId: createdModuleId,
              ...getModuleConfig(formData, true),
            },
          })
            .then(({ data }) => {
              if (data?.moduleUpdateV2.id) {
                callback?.();
                trackSegmentEvent("Module Updated");
              } else {
                onError(new Error("Unable to update module"));
              }
            })
            .catch((e) => {
              onError(e);
            })
            .finally(() => {
              setLoading(false);
              setStepperNavDisabled(false);
            });
        } else {
          callback?.();
        }
      })
      .catch(onError);
  };

  return updateModuleHandler;
};
