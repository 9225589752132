import { StackIntegrationGcp } from "types/generated";
import KeyValue from "components/KeyValue";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";

type StackSettingsIntegrationsCloudAttachedItemGCPFieldsType = {
  integration: StackIntegrationGcp;
};

const StackSettingsIntegrationsCloudAttachedItemGCPFields = ({
  integration,
}: StackSettingsIntegrationsCloudAttachedItemGCPFieldsType) => {
  return (
    <>
      <Typography tag="p" variant="p-body3" color="secondary" margin="0 0 large">
        Your runs and tasks will receive a temporary OAuth token associated with service account.
        The following scopes will be requested when generating the token:
      </Typography>
      <KeyValue name="Service account">{integration.serviceAccountEmail}</KeyValue>
      {integration.tokenScopes.length > 0 && (
        <KeyValue name="Scopes">
          <Box direction="column" align="end" gap="medium">
            {integration.tokenScopes.map((scope, index) => (
              <Typography tag="p" variant="s-body3" key={index}>
                {scope}
              </Typography>
            ))}
          </Box>
        </KeyValue>
      )}
    </>
  );
};

export default StackSettingsIntegrationsCloudAttachedItemGCPFields;
