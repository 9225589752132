import { gql } from "@apollo/client";

export const GET_WEBHOOK = gql`
  query GetWebhook($id: ID!, $nextToken: String) {
    namedWebhooksIntegration(id: $id) {
      id
      space {
        id
        name
        accessLevel
      }
      deliveries(token: $nextToken) {
        nodes {
          id
          status
          timestamp
        }
        nextToken
      }
      enabled
      endpoint
      name
      labels
      secret
      secretHeaders
      createdAt
    }
  }
`;

export const GET_WEBHOOK_DELIVERY = gql`
  query GetWebhookDeliveryDetails($id: ID!, $key: String!) {
    namedWebhooksIntegration(id: $id) {
      id
      deliveryDetails(key: $key) {
        requestHeaders {
          name
          values
        }
        requestBody
        responseHeaders {
          name
          values
        }
        responseError
        responseBody
      }
    }
  }
`;
