import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Stack } from "types/generated";

import { GET_STACK_OUTPUTS } from "../gql";
import { SelectOptionOutput } from "./types";

const useGetOutputs = (stackId?: string) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, previousData, loading } = useQuery<{ stack: Stack }>(GET_STACK_OUTPUTS, {
    variables: {
      stackId,
    },
    skip: !stackId,
    onError,
  });

  const outputsOptions: SelectOptionOutput[] = useMemo(() => {
    return (data?.stack.outputs || previousData?.stack.outputs || [])?.map((output) => ({
      value: output.id,
      label: output.id,
      description: output.sensitive ? "*******" : output.value || "",
    }));
  }, [data, previousData]);

  return {
    outputsOptions,
    loading,
  };
};

export default useGetOutputs;
