import { KubernetesWorkflowTool, TerraformWorkflowTool, TerragruntTool } from "types/generated";
import { DEFAULT_SPACE_NAME } from "views/constants";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";

import {
  StackCreationVendor,
  StackCreationVersionType,
  StackCreationWizardNavStep,
  StackCreationWizardStep,
  StackFormFields,
} from "./types";

export const availableSteps: Array<StackCreationWizardNavStep> = [
  {
    name: "Stack details",
    summaryName: "Details",
    step: StackCreationWizardStep.Details,
  },
  {
    name: "Connect to source code",
    summaryName: "Source code",
    step: StackCreationWizardStep.Vcs,
  },
  {
    name: "Choose vendor",
    summaryName: "Vendor",
    step: StackCreationWizardStep.Vendor,
  },
  {
    name: "Define behavior",
    isOptional: true,
    summaryName: "Behavior",
    step: StackCreationWizardStep.Behaviour,
  },
  {
    name: "Add hooks",
    isOptional: true,
    summaryName: "Hooks",
    step: StackCreationWizardStep.Hooks,
  },
  {
    name: "Attach cloud",
    isOptional: true,
    summaryName: "Cloud integration",
    step: StackCreationWizardStep.Cloud,
  },
  {
    name: "Attach policies",
    isOptional: true,
    summaryName: "Attached policies",
    step: StackCreationWizardStep.Policies,
  },
  {
    name: "Attach context",
    isOptional: true,
    summaryName: "Attached contexts",
    step: StackCreationWizardStep.AttachContext,
  },
];

export const stackCreationVendorKeys: Record<
  StackCreationVendor,
  "terraform" | "pulumi" | "cloudFormation" | "kubernetes" | "ansible" | "terragrunt"
> = {
  [StackCreationVendor.Terraform]: "terraform",
  [StackCreationVendor.Pulumi]: "pulumi",
  [StackCreationVendor.CloudFormation]: "cloudFormation",
  [StackCreationVendor.Kubernetes]: "kubernetes",
  [StackCreationVendor.Ansible]: "ansible",
  [StackCreationVendor.Terragrunt]: "terragrunt",
};

export const stackCreationWizardNavSummaryStep = {
  name: "Summary",
  step: StackCreationWizardStep.Summary,
  isOptional: false,
};

export const INITIAL_FORM_DATA: StackFormFields = {
  [StackCreationWizardStep.Vendor]: {
    vendor: StackCreationVendor.Terraform,
    terraform: {
      workflowTool: TerraformWorkflowTool.TerraformFoss,
      useSmartSanitization: true,
      manageState: true,
      externalStateAccessEnabled: false,
      importExistingStateFile: false,
      workspace: "",
      uploadedStateObjectId: null,
      uploadedStateFile: null,
      version: {
        type: StackCreationVersionType.Specific,
        value: "",
      },
    },
    pulumi: {
      stackName: "",
      loginURL: "",
    },
    cloudFormation: {
      stackName: "",
      region: "",
      entryTemplateFile: "",
      templateBucket: "",
    },
    kubernetes: {
      kubernetesWorkflowTool: KubernetesWorkflowTool.Kubernetes,
      namespace: "",
      kubectlVersion: "",
    },
    ansible: {
      playbook: "",
    },
    terragrunt: {
      useSmartSanitization: true,
      useRunAll: false,
      tool: TerragruntTool.TerraformFoss,
      workflowVersion: {
        type: StackCreationVersionType.Specific,
        value: "",
      },
      terragruntVersion: {
        type: StackCreationVersionType.Specific,
        value: "",
      },
    },
  },
  [StackCreationWizardStep.Behaviour]: {
    workerPool: WORKER_POOL_SHARED_VALUE,
    runnerImage: "",
    administrative: false,
    githubActionDeploy: false,
    autodeploy: false,
    autoretry: false,
    enableWellKnownSecretMasking: true,
    localPreviewEnabled: false,
    protectFromDeletion: false,
    enableSensitiveOutputUpload: false,
  },
  [StackCreationWizardStep.Details]: {
    name: "",
    labels: [],
    description: "",
    space: DEFAULT_SPACE_NAME,
  },
  [StackCreationWizardStep.Vcs]: {
    provider: undefined,
    vcsIntegrationId: undefined,
    repository: "",
    namespace: "",
    branch: "",
  },
  [StackCreationWizardStep.Hooks]: {},
  [StackCreationWizardStep.AttachContext]: null,
  [StackCreationWizardStep.Policies]: null,
  [StackCreationWizardStep.Cloud]: null,
};

export const ANALYTICS_VERSION_STACK_CREATION_V3 = "Stack creation redesign V3";
