import cx from "classnames";
import { ReactNode } from "react";
import capitalize from "lodash-es/capitalize";
import lowerCase from "lodash-es/lowerCase";

import Box, { BoxProps } from "ds/components/Box";
import { ChevronNew, Spinner } from "components/icons";
import BaseAction from "ds/components/BaseAction";
import Icon from "ds/components/Icon";
import { withTestId } from "utils/withTestId";
import Timestamp from "components/Timestamp";
import useAnalytics from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { RunContext } from "views/shared/run/Context";

import styles from "./styles.module.css";
import State from "../HistoryEntryState";
import Separator from "./Separator";
import HistoryEntryDuration from "../HistoryEntryDuration";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryLabel from "../HistoryEntryLabel";
import {
  RUN_HISTORY_ENTRY_CONTENT_TEST_ID,
  RUN_HISTORY_ENTRY_INFO_TEST_ID,
  RUN_HISTORY_ENTRY_LABEL_TEST_ID,
  RUN_HISTORY_ENTRY_TEST_ID,
} from "./constants";

type HistoryEntryWrapperProps = Omit<BoxProps, "children"> & {
  children?: ReactNode;
  state: RunHistoryEntryType;
  isOpen?: boolean;
  isLoading?: boolean;
  timestamp?: number;
  until?: number | null;
  label?: ReactNode;
  onClick?: () => void;
  terminal?: boolean;
};

const HistoryEntryWrapper = ({
  children,
  state,
  isOpen,
  onClick,
  isLoading,
  label,
  timestamp,
  until,
  terminal,
  ...rest
}: HistoryEntryWrapperProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();
  const {
    run: { state: runState },
  } = useTypedContext(RunContext);

  const handleToggle = () => {
    if (!onClick) return;

    trackSegmentAnalyticsEvent("Run Logs - toggle logs stage", {
      runState,
      logsState: state,
      isExpanded: !isOpen, // state after toggling
    });
    onClick();
  };

  label ??= (
    <HistoryEntryLabel>
      <strong>{capitalize(lowerCase(state))}</strong>
    </HistoryEntryLabel>
  );

  const content = (
    <>
      <span className={styles.labelWrapper} {...withTestId(RUN_HISTORY_ENTRY_LABEL_TEST_ID)}>
        {label}
      </span>
      {isLoading && (
        <Icon size="large" src={Spinner} className={styles.spinner} aria-label="Loading" />
      )}
      {!terminal && timestamp && (
        <>
          <Separator />
          <HistoryEntryDuration start={timestamp} end={until || null} />
        </>
      )}
      {timestamp && (
        <>
          <Separator />
          <Timestamp color="secondary" timestamp={timestamp} />
        </>
      )}
    </>
  );

  return (
    <li className={cx(styles.wrapper)} {...withTestId(RUN_HISTORY_ENTRY_TEST_ID)}>
      <State type={state} className={styles.state} />
      <Box className={styles.info} {...withTestId(RUN_HISTORY_ENTRY_INFO_TEST_ID)}>
        {onClick && (
          <BaseAction onClick={handleToggle} className={styles.handle}>
            <Icon
              src={ChevronNew}
              rotate={isOpen ? "90" : undefined}
              className={styles.handleArrowIcon}
            />
            {content}
          </BaseAction>
        )}
        {!onClick && content}
      </Box>
      {children && (
        <Box
          direction="column"
          align="start"
          gap="medium"
          fullWidth
          className={styles.content}
          {...withTestId(RUN_HISTORY_ENTRY_CONTENT_TEST_ID)}
          {...rest}
        >
          {children}
        </Box>
      )}
    </li>
  );
};

export default HistoryEntryWrapper;
