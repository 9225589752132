import { gql } from "@apollo/client";

export const GET_MODULE_VERSIONS = gql`
  query GetPaginatedModuleVersions($moduleId: ID!, $beforeVersionId: ID, $includeFailed: Boolean) {
    module(id: $moduleId) {
      id
      versions(before: $beforeVersionId, includeFailed: $includeFailed) {
        id
        number
      }
    }
  }
`;
