import { Controller, FormProvider, useForm } from "react-hook-form";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Banner from "ds/components/Banner";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Box from "ds/components/Box";
import Input from "ds/components/Input";
import useAttachContext from "shared/Context/useAttachContext";
import useGetAttachableContexts from "shared/Context/useGetAttachableContexts";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { AttachContextDrawerProps, AttachContextFormFields } from "./types";

const priorityFieldOptions = {
  valueAsNumber: true,
  min: 0,
  required: true,
  validate: (value: number) => /^[0-9]+$/.test(value.toString()),
};

const AttachContextDrawer = ({
  onDrawerClose,
  attachedContextIds,
  stackId,
  spaceId,
}: AttachContextDrawerProps) => {
  const form = useForm<AttachContextFormFields>({
    defaultValues: {
      contextId: "",
      priority: 0,
    },
    mode: "onChange",
  });

  const { control, register, handleSubmit, formState } = form;

  const { contexts, loading } = useGetAttachableContexts({
    spaceId,
    attachedContextIds,
  });

  const { attachContext, loading: attachContextLoading } = useAttachContext([
    "GetModuleAttachedContexts",
  ]);

  const handleAttach = async (formData: AttachContextFormFields) => {
    attachContext(formData.contextId, stackId, formData.priority, onDrawerClose);
  };

  const isFormSubmitting = formState.isSubmitting || attachContextLoading;

  return (
    <FormProvider {...form}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Attach context" />
        <DrawerCloseIcon handleCloseDrawer={onDrawerClose} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Banner variant="info">
          You can only attach contexts from the current space and parent spaces that you inherit
          from.
        </Banner>

        <Box margin="large 0 0" gap="large" grid gridTemplate="1fr 8rem">
          <Controller
            name="contextId"
            control={control}
            rules={{ required: "Context is required." }}
            render={({ field, fieldState }) => (
              <FormField
                error={fieldState.error?.message}
                noMargin
                label="Select context"
                tooltipWidthMode="maxWidthSm"
                tooltipInfo="A context can only be attached once to a given module, so if it's already attached, it will not be visible in the dropdown menu."
              >
                {({ ariaInputProps }) => (
                  <Select
                    options={contexts}
                    autocomplete
                    value={field.value}
                    onChange={field.onChange}
                    loading={loading}
                    error={!!fieldState.error?.message}
                    ariaInputProps={ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />

          <FormField
            noMargin
            label="Priority"
            tooltipInfo="All the contexts attached to a module are sorted by priority (lowest first), though values don't need to be unique."
            tooltipWidthMode="maxWidthSm"
          >
            {({ ariaInputProps }) => (
              <Input
                error={!!formState.errors.priority}
                {...register("priority", priorityFieldOptions)}
                {...ariaInputProps}
              />
            )}
          </FormField>
        </Box>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onDrawerClose} disabled={isFormSubmitting}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(handleAttach)}
              loading={isFormSubmitting}
              disabled={!formState.isValid || attachContextLoading}
            >
              Attach
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default AttachContextDrawer;
