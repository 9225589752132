import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";

type BulkActionsDrawerItemTitleProps = {
  text: string;
};

const BulkActionsDrawerItemTitle = ({ text }: BulkActionsDrawerItemTitleProps) => {
  return (
    <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthXl">
      {(props) => (
        <Typography {...props} tag="p" variant="p-t6">
          {text}
        </Typography>
      )}
    </TextEllipsis>
  );
};

export default BulkActionsDrawerItemTitle;
