import { Stack } from "types/generated";
import { AccountViewer } from "components/form/graphql/types";

import { canManageStacksAndRuns } from "../utils";

/**
 * Check if the current user can disable the stack
 */
export const canDisableStack = (stack: Stack, viewer: AccountViewer | undefined): boolean => {
  const isNotDisabled = !stack.isDisabled;
  const hasPermissionToDisable = canManageStacksAndRuns(stack, viewer);

  return isNotDisabled && hasPermissionToDisable;
};
