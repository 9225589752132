import { memo } from "react";

import PageWrapper from "components/PageWrapper";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import Box from "ds/components/Box";

import StackSchedulingEmpty from "./Empty";
import StackSchedulingDrawer from "./Drawer";
import useGetStackScheduling from "./useGetScheduling";
import StackManageDriftDetectionListItem from "../ManageDriftDetection/ListItem";
import StackManageScheduledDeleteListItem from "../ManageScheduledDelete/ListItem";
import StackManageScheduledTaskListItem from "../ManageScheduledTask/ListItem";
import StackManageScheduledRunListItem from "../ManageScheduledRun/ListItem";

type StackSchedulingListProps = {
  stackId: string;
};

const StackSchedulingList = ({ stackId }: StackSchedulingListProps) => {
  const {
    items,
    hasPrivateWorkerPool,
    state,
    isStackBlocked,
    isPageLoading,
    isPageEmpty,
    error,
    stopPolling,
  } = useGetStackScheduling(stackId);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  return (
    <PageWrapper margin="0" padding="x-large" direction="column">
      {isPageEmpty && <StackSchedulingEmpty />}

      {!isPageEmpty && (
        <Box direction="column" gap="x-large" limitWidth="medium">
          {items.map((item) => {
            if (item.__typename === "DriftDetectionIntegration") {
              // FYI: there is possible to have only one DriftDetectionIntegration
              return (
                <StackManageDriftDetectionListItem
                  key={item.__typename}
                  integration={item}
                  stackId={stackId}
                  hasPrivateWorkerPool={hasPrivateWorkerPool}
                  stackState={state}
                />
              );
            }

            if (item.__typename === "ScheduledDelete") {
              return (
                <StackManageScheduledDeleteListItem
                  key={item.id}
                  integration={item}
                  hasPrivateWorkerPool={hasPrivateWorkerPool}
                  isStackBlocked={isStackBlocked}
                />
              );
            }

            if (item.__typename === "ScheduledTask") {
              return (
                <StackManageScheduledTaskListItem
                  key={item.id}
                  integration={item}
                  hasPrivateWorkerPool={hasPrivateWorkerPool}
                />
              );
            }

            if (item.__typename === "ScheduledRun") {
              return (
                <StackManageScheduledRunListItem
                  key={item.id}
                  integration={item}
                  hasPrivateWorkerPool={hasPrivateWorkerPool}
                />
              );
            }

            return null;
          })}
        </Box>
      )}

      <StackSchedulingDrawer />
    </PageWrapper>
  );
};

export default memo(StackSchedulingList);
