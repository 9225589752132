import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type PoliciesDetachConfirmationModalProps = {
  policyName: string;
  onConfirm: () => void;
};

const PoliciesDetachConfirmationModal = create(function PoliciesDetachConfirmationModal({
  onConfirm,
  policyName,
}: PoliciesDetachConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    modal.resolve();
    modal.hide();
    onConfirm();
  };

  return (
    <ConfirmationModal
      title="Detach policy"
      confirmationButtonLabel="Detach"
      confirmationButtonVariant="dangerPrimary"
      onConfirm={handleOnConfirm}
      size="large"
    >
      <Typography tag="p" variant="p-body2">
        Are you sure you want to detach{" "}
        <Typography tag="span" variant="p-t6">
          {policyName}
        </Typography>{" "}
        policy?
      </Typography>
    </ConfirmationModal>
  );
});

export const showPoliciesDetachConfirmationModal = (props: PoliciesDetachConfirmationModalProps) =>
  show(PoliciesDetachConfirmationModal, props);
