import { gql } from "@apollo/client";

export const GET_USERS_ACTIVITY = gql`
  query GetUsersActivity {
    usage {
      logins {
        username
        timestamp
      }
    }
  }
`;
