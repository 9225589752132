import { ReactNode } from "react";
import cx from "classnames";
import { Link, LinkProps as AriaLinkProps } from "react-aria-components";

import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import Icon from "../Icon";
import TextEllipsis from "../TextEllipsis";
import Tooltip from "../Tooltip";

export type LinkProps = {
  variant?: "primary" | "secondary" | "danger" | "inversed";
  disabled?: boolean;
  size?: "small" | "medium" | "inherit";
  children: ReactNode;
  className?: string;
  endIcon?: IconComponent;
  startIcon?: IconComponent;
  tooltip?: ReactNode;
} & AriaLinkProps;

const LinkNew = ({
  className,
  variant = "primary",
  size = "medium",
  disabled,
  startIcon,
  endIcon,
  children,
  href,
  tooltip,
  ...rest
}: LinkProps) => {
  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <Link
          {...tooltipProps}
          {...rest}
          href={href}
          isDisabled={disabled}
          className={cx(
            styles.link,
            styles[variant],
            styles[size],
            className,
            tooltipProps.className
          )}
        >
          {startIcon && <Icon src={startIcon} className={styles.icon} />}
          <TextEllipsis tooltipPlacement="bottom" tooltip={children}>
            {(props) => <span {...props}>{children}</span>}
          </TextEllipsis>
          {endIcon && <Icon src={endIcon} className={styles.icon} />}
        </Link>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default LinkNew;
