import Box from "ds/components/Box";
import { AfterCommandsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";

import { StackHooksFormFields } from "../types";
import { adaptFieldsToCommands } from "../hooksAdapters";
import NewStackSummaryHook from "./Hook";

type NewStackSummaryHooksProps = {
  data?: StackHooksFormFields;
};

const NewStackSummaryHooks = ({ data }: NewStackSummaryHooksProps) => {
  const hasValues = Object.values(data ?? {}).some((value) => value?.length);

  if (!hasValues) {
    return (
      <EmptyState
        padding="large"
        icon={AfterCommandsColored}
        title="Hooks not defined"
        caption=" Customize Spacelift run lifecycle by adding commands to run before and after various execution
    phases."
      />
    );
  }

  const adaptedFields = adaptFieldsToCommands(data);

  return (
    <Box gap="medium" direction="column">
      {Object.entries(adaptedFields).map(([key, { before, after }]) => {
        if (!before?.length && !after.length) {
          return null;
        }

        return <NewStackSummaryHook key={key} name={key} before={before} after={after} />;
      })}
    </Box>
  );
};

export default NewStackSummaryHooks;
