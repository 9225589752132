import cx from "classnames";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { AnsibleTaskStatus } from "types/generated";
import Counter from "ds/components/Counter";

import { createLegendItems } from "./utils";
import styles from "./styles.module.css";
import { ConfigNode } from "../types";
import { ANSIBLE_DICTIONARY } from "../constants";

type ConfigManagementTreeGridLegend = {
  nodes: ConfigNode[];
};

const ConfigManagementTreeGridLegend = ({ nodes }: ConfigManagementTreeGridLegend) => {
  const legend = createLegendItems(nodes);

  return (
    <Box padding="medium x-large" className={styles.legendScrollContainer}>
      <Box className={styles.legendContainer}>
        {Object.entries(legend).map(([key, value]) => (
          <Box key={key} gap="medium" align="center">
            <div className={cx(styles.legendItemColor, styles[key.toLowerCase()])} />
            <Typography tag="span" variant="p-body3">
              {ANSIBLE_DICTIONARY[key as AnsibleTaskStatus]}
            </Typography>
            <Counter size="small" count={value} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ConfigManagementTreeGridLegend;
