import cx from "classnames";

import { CrossNew, Magnifier } from "components/icons";
import Input from "ds/components/Input";
import LoadingIndicator from "ds/components/LoadingIndicator";

import styles from "./styles.module.css";
import { AutocompleteProps } from "./types";
import useAutocomplete from "./useAutocomplete";

const LOADING_MINIMUM_DURATION = 200;

const Autocomplete = (props: AutocompleteProps) => {
  const { query, onChange, className, placeholder = "Search", onEnterKey, loading } = props;

  const { handleOnChange, handleOnKeyDown, clearQuery } = useAutocomplete({
    onChange,
    onEnterKey,
  });

  return (
    <div className={cx(styles.autocompleteWrapper, className)}>
      <LoadingIndicator
        loading={loading}
        minimumDuration={LOADING_MINIMUM_DURATION}
        className={styles.loadingIndicator}
      >
        <Magnifier className={cx(styles.magnifier)} aria-hidden />
      </LoadingIndicator>

      <Input
        value={query}
        onChange={handleOnChange}
        placeholder={placeholder}
        onKeyDown={handleOnKeyDown}
      />
      {query.length > 0 && <CrossNew className={styles.clear} onClick={clearQuery} />}
    </div>
  );
};

export default Autocomplete;
