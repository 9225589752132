import { gql } from "@apollo/client";

export const GET_SHARED_MODULES = gql`
  query GetSharedModules {
    sharedModules {
      id
      description
      labels
      name
      ownerSubdomain
      current {
        id
        number
        state
        yanked
      }
      latest {
        id
      }
    }
  }
`;
