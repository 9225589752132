import { RunStateTransition } from "types/generated";

import { canShowElement } from "./elements";
import { RunElementType, RunEntryContext } from "../types";
import QueuedEntry from "../entries/QueuedEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createQueuedEntry = (
  { timestamp, until, stateVersion }: RunStateTransition,
  { canDiscard, stackId, runId, runQueryToRefetch }: RunEntryContext
) => {
  return (
    <QueuedEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      showDiscardButton={canShowElement(RunElementType.TerminateRunAction)}
      canDiscard={canDiscard}
      stackId={stackId}
      runId={runId}
      runQueryToRefetch={runQueryToRefetch}
    />
  );
};
