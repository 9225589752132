import type { ReactNode } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import Box from "ds/components/Box";
import { Copy } from "components/icons";
import useCopyToClipboard from "hooks/useCopyToClipboard";

type FormFieldViewTextCopyWrapperProps = {
  children?: ReactNode;
  value: string;
};

const FormFieldViewTextCopyWrapper = ({ value, children }: FormFieldViewTextCopyWrapperProps) => {
  const copyHandler = useCopyToClipboard(value);

  return (
    <Box direction="row" gap="medium" justify="between" align="center">
      {children}
      <ButtonIcon onClick={copyHandler} icon={Copy} variant="ghost">
        Copy
      </ButtonIcon>
    </Box>
  );
};

export default FormFieldViewTextCopyWrapper;
