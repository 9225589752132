import BadgeNext from "ds/components/BadgeNext";

import styles from "./styles.module.css";
import { NetworkStatuses, WebhookDeliveryStatusProps, NETWORK_STATUSES } from "./types";

const translateStatus = (status: number) => {
  if (status === 0) {
    return NETWORK_STATUSES[NetworkStatuses.Special].variant;
  }

  if (status >= 200 && status < 300) {
    return NETWORK_STATUSES[NetworkStatuses.Ok].variant;
  }

  if (status >= 300 && status < 400) {
    return NETWORK_STATUSES[NetworkStatuses.Redirect].variant;
  }

  if (status >= 400 && status < 600) {
    return NETWORK_STATUSES[NetworkStatuses.Error].variant;
  }

  return NETWORK_STATUSES[NetworkStatuses.Special].variant;
};

const WebhookDeliveriesStatus = ({ status }: WebhookDeliveryStatusProps) => {
  return (
    <BadgeNext
      className={styles.statusBadge}
      text={`${status}`}
      variant={translateStatus(status)}
      type="strong"
    />
  );
};

export default WebhookDeliveriesStatus;
