import { gql } from "@apollo/client";

export const GET_BITBUCKET_CLOUD_INTEGRATION = gql`
  query GetVCSBitbucketCloudIntegration($id: ID!) {
    bitbucketCloudIntegration(id: $id) {
      vcsChecks
      description
      id
      isDefault
      labels
      name
      space {
        id
        name
        accessLevel
      }
      username
    }
  }
`;
