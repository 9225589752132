import { gql } from "@apollo/client";

export const CREATE_API_KEY = gql`
  mutation CreateApiKeyInDrawer($input: ApiKeyInput!) {
    apiKeyCreate(input: $input) {
      id
      name
      secret
      type
    }
  }
`;
