import { Popover, PopoverProps, MenuTrigger, Menu, MenuTriggerProps } from "react-aria-components";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.css";

type DropdownMenuProps = {
  children: ReactNode;
  triggerComponent: ReactNode;
  placement?: PopoverProps["placement"];
  isOpen?: boolean;
  onOpenChange?: MenuTriggerProps["onOpenChange"];
  className?: string;
};

const DropdownMenu = ({
  children,
  triggerComponent,
  placement = "bottom left",
  isOpen,
  onOpenChange,
  className,
}: DropdownMenuProps) => {
  return (
    <MenuTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
      {triggerComponent}
      <Popover placement={placement}>
        <Menu className={cx(styles.menu, className)}>{children}</Menu>
      </Popover>
    </MenuTrigger>
  );
};

export default DropdownMenu;
