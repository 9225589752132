import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type FormProps = {
  center?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Form = ({ children, center, className }: FormProps) => {
  return (
    <section className={cx(styles.form, className)}>
      <section
        className={cx(styles.body, {
          [styles.center]: center,
        })}
      >
        <section className={styles.content}>{children}</section>
      </section>
    </section>
  );
};

export default Form;
