import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";

import { StackSettingsGetTooltipHelperReturnType } from "../../types";

export const getLoginURLTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Login URL</TooltipModalTitle>
      <TooltipModalBody align="start">
        State backend address Pulumi should log into during run initialization.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getStackNameTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Stack name</TooltipModalTitle>
      <TooltipModalBody align="start">
        The stack name which should be used when persisting to the state backend.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});
