import { Profile, StackIcon } from "components/icons";
import { NotificationType } from "types/generated";
import { IconComponent } from "types/Icon";

export const iconPerType: Record<NotificationType, IconComponent> = {
  [NotificationType.Stack]: StackIcon,
  [NotificationType.Account]: Profile,
  [NotificationType.AwsIntegration]: StackIcon,
  [NotificationType.Policy]: StackIcon,
  [NotificationType.WorkerPool]: StackIcon,
  [NotificationType.Module]: StackIcon,
};
