import { useMemo } from "react";

import { getDescriptionId, getErrorId } from "./helpers";

type UseAriaInputPropsOptions = {
  id: string;
  hasHelper: boolean;
  hasError: boolean;
  isOptional?: boolean;
};

const getDescribedBy = ({ id, hasHelper, hasError }: UseAriaInputPropsOptions) => {
  if (hasError) {
    return getErrorId(id);
  }
  if (hasHelper) {
    return getDescriptionId(id);
  }
  return undefined;
};

export type AriaInputProps = {
  id: string;
  "aria-invalid": boolean;
  "aria-describedby": string | undefined;
  "aria-required": boolean;
};

const useAriaInputProps = ({
  id,
  hasHelper,
  hasError,
  isOptional,
}: UseAriaInputPropsOptions): AriaInputProps => {
  return useMemo(
    () => ({
      id,
      ["aria-invalid"]: hasError,
      ["aria-describedby"]: getDescribedBy({ id, hasHelper, hasError }),
      ["aria-required"]: !isOptional,
    }),
    [isOptional, hasError, hasHelper, id]
  );
};

export default useAriaInputProps;
