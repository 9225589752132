import { useEffect } from "react";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ErrorType } from "types/Error";

import { useNavigation } from "./useNavigation";
import AccountDetailsDrawerContent from "./DrawerContent";

const isSelfHosted = isSelfHostedDistribution();
const isSaas = isSaasDistribution();

const AccountDetails = () => {
  const { isDrawerVisible, handleCloseDrawer } = useNavigation();
  const { onError } = useTypedContext(FlashContext);

  const handleOnError = (error: ErrorType) => {
    onError(error);
    handleCloseDrawer();
  };

  useEffect(() => {
    if (!isSaas && !isSelfHosted && isDrawerVisible) {
      handleCloseDrawer(); // you discovered an Easter egg that shouldn't ever happen
    }
  }, [isDrawerVisible, handleCloseDrawer]);

  return (
    // FYI: absolute right destroys accessible keyboard interactions on stacks list
    <Drawer position="fixedRight" visible={isDrawerVisible} onOutsideClick={handleCloseDrawer}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Account details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <AccountDetailsDrawerContent onError={handleOnError} />
    </Drawer>
  );
};

export default AccountDetails;
