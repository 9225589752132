import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import { Run, RunIgnoredTrigger } from "types/generated";
import { FILTERS_ORDER_SETTINGS_KEY } from "views/Account/Stacks/constants";

export const groupPerCommitOnTimeline = <RunType extends Run | RunIgnoredTrigger>(
  runs: RunType[]
) => {
  return Array.from(
    runs.reduce<[string, RunType[]][]>((acc, run) => {
      const lastIndex = acc.length - 1;
      const prevGroupHash = acc[lastIndex] && acc[lastIndex][0];

      if (run.commit.hash === prevGroupHash) {
        acc[lastIndex][1].push(run);
      } else {
        acc.push([run.commit.hash, [run]]);
      }

      return acc;
    }, [])
  );
};

export const getStacksBackUrl = () => {
  return getBreadcrumbsBackUrl("/stacks", FILTERS_ORDER_SETTINGS_KEY);
};
