import { Pages } from "./types";

export const enum AnalyticsPageResources {
  ResourcesRoot = "ResourcesRoot",
  Resources = "Resources",
  ConfigurationManagement = "ConfigurationManagement",
}

const RESOURCES_TITLE = "Resources";

export const pagesResources: Pages<AnalyticsPageResources> = {
  [AnalyticsPageResources.ResourcesRoot]: [RESOURCES_TITLE],
  [AnalyticsPageResources.Resources]: [RESOURCES_TITLE, "Resources view"],
  [AnalyticsPageResources.ConfigurationManagement]: [RESOURCES_TITLE, "Configuration Management"],
};
