import { nanoid } from "nanoid";

import { HooksInput } from "types/generated";

export const phases = {
  init: "Initialization",
  plan: "Planning",
  apply: "Applying",
  destroy: "Destroying",
  perform: "Performing",
  run: "Finally",
} as const;

export type PhaseName = keyof typeof phases;

type BeforeHooks = "beforeInit" | "beforePlan" | "beforeApply" | "beforeDestroy" | "beforePerform";

type AfterHooks =
  | "afterInit"
  | "afterPlan"
  | "afterApply"
  | "afterDestroy"
  | "afterPerform"
  | "afterRun";

export const phaseHooks: Record<
  PhaseName,
  readonly [before: BeforeHooks | null, after: AfterHooks | null]
> = {
  init: ["beforeInit", "afterInit"],
  plan: ["beforePlan", "afterPlan"],
  apply: ["beforeApply", "afterApply"],
  destroy: ["beforeDestroy", "afterDestroy"],
  perform: ["beforePerform", "afterPerform"],
  run: [null, "afterRun"],
} as const;

export type HookType = "before" | "after";

export type Commands = Record<HookType, Command[]>;

export type Command = {
  text: string;
  id: string;
};

export const toCommands = (value: string[] | undefined): Command[] =>
  value?.map((command) => ({ text: command, id: nanoid() })) ?? [];

export const adaptCommandsToFields = (
  commands: Partial<Commands>,
  selectedPhase: PhaseName
): Partial<HooksInput> => {
  const beforeCommands = commands?.before?.map((command) => command.text) || [];
  const afterCommands = commands?.after?.map((command) => command.text) || [];

  switch (selectedPhase) {
    case "init":
      return { beforeInit: beforeCommands, afterInit: afterCommands };
    case "plan":
      return { beforePlan: beforeCommands, afterPlan: afterCommands };
    case "apply":
      return { beforeApply: beforeCommands, afterApply: afterCommands };
    case "destroy":
      return { beforeDestroy: beforeCommands, afterDestroy: afterCommands };
    case "perform":
      return { beforePerform: beforeCommands, afterPerform: afterCommands };
    case "run":
      return { afterRun: afterCommands };
  }
};

export const adaptFieldsToCommands = (
  fields?: HooksInput
): Record<PhaseName, Partial<Commands>> => {
  return {
    init: {
      before: toCommands(fields?.beforeInit),
      after: toCommands(fields?.afterInit),
    },
    plan: {
      before: toCommands(fields?.beforePlan),
      after: toCommands(fields?.afterPlan),
    },
    apply: {
      before: toCommands(fields?.beforeApply),
      after: toCommands(fields?.afterApply),
    },
    perform: {
      before: toCommands(fields?.beforePerform),
      after: toCommands(fields?.afterPerform),
    },
    destroy: {
      before: toCommands(fields?.beforeDestroy),
      after: toCommands(fields?.afterDestroy),
    },
    run: {
      after: toCommands(fields?.afterRun),
    },
  };
};
