import ChartTooltipTotalHeader from "ds/components/Charts/components/Tooltip/TotalHeader";

type NumberOfDeploymentsTooltipProps = {
  value: number;
};

const NumberOfDeploymentsTooltip = ({ value }: NumberOfDeploymentsTooltipProps) => {
  return (
    <ChartTooltipTotalHeader
      title="Total: "
      count={value.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })}
    />
  );
};

export default NumberOfDeploymentsTooltip;
