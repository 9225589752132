import { create, show, useModal } from "@ebay/nice-modal-react";
import { ReactNode } from "react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type ChangeManagementStrategyConfirmationModalProps = {
  onConfirm: () => void;
  name: string;
  description: ReactNode;
};

const ChangeManagementStrategyConfirmationModal = create(
  function ChangeManagementStrategyConfirmationModal({
    onConfirm,
    name,
    description,
  }: ChangeManagementStrategyConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      modal.resolve();
      modal.hide();
      onConfirm();
    };

    return (
      <ConfirmationModal
        title={`Enable ${name.toLowerCase()}`}
        confirmationButtonLabel="Enable"
        confirmationButtonVariant="primary"
        onConfirm={handleOnConfirm}
        size="large"
      >
        <Typography variant="p-body2" tag="p">
          {description}
        </Typography>
      </ConfirmationModal>
    );
  }
);

export const showChangeManagementStrategyConfirmationModal = (
  props: ChangeManagementStrategyConfirmationModalProps
) => show(ChangeManagementStrategyConfirmationModal, props);
