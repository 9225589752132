import { useMutation } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SavedFilterListItem, SavedFilterView } from "components/Filters/types";

import { UPDATE_VIEW } from "../gql";

const useChangeViewPublicVisibility = (view?: SavedFilterView | SavedFilterListItem) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updateView] = useMutation(UPDATE_VIEW, {
    refetchQueries: ["SavedViewsList"],
  });

  const changeViewPublicVisibility = useCallback(() => {
    updateView({
      variables: {
        id: view?.id,
        isPublic: !view?.isPublic,
      },
    })
      .then(() => {
        reportSuccess({ message: "View was successfully updated" });

        if (!view?.isPublic) {
          datadogRum.addAction("[Saved Views] Change to public");
        }
      })
      .catch(onError);
  }, [onError, reportSuccess, view?.isPublic, view?.id, updateView]);

  return changeViewPublicVisibility;
};

export default useChangeViewPublicVisibility;
