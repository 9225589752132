import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { SpaceAccessLevel, TerraformProviderVersion } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import MetaInfoList from "components/MetaInfoList";
import { Incognito, MenuSpaces, Networking } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import ViewHeader from "components/ViewHeader";
import Drawer from "ds/components/Drawer";
import Box from "ds/components/Box";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import EmptyState from "ds/components/EmptyState";
import useTitle from "hooks/useTitle";
import Link from "ds/components/Link";
import { AccountContext } from "views/AccountWrapper";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { getDocsUrl } from "utils/getDocsUrl";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import PrivateProviderRegistryTierInfo from "components/TierInfo/PrivateProviderRegistryTierInfo";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { GET_PROVIDER_WITH_VERSIONS } from "./gql";
import styles from "./styles.module.css";
import ProviderVersion from "./ProviderVersion";
import ProviderVersionDrawer from "./ProviderVersionDrawer";
import ProviderVersionEditDrawer from "./ProviderVersionEditDrawer";
import ProviderDetailsDrawer from "./ProviderDetailsDrawer";
import ProviderDrawer from "./ProviderDrawer";
import { FILTERS_ORDER_SETTINGS_KEY_PROVIDERS } from "./constants";
import { GetProviderWithVersionsGql } from "./types";
import { showProviderDeleteConfirmation } from "./ProviderDeleteConfirmation";
import useDeleteProvider from "./useDeleteProvider";

const Provider = () => {
  const { providerId } = useParams<{ providerId: string }>();
  const { viewer } = useTypedContext(AccountContext);
  const { reportSuccess, reportError } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isUpdateDrawerVisible, setUpdateDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const handleCloseUpdateDrawer = () => {
    setUpdateDrawerVisibility(false);
  };

  const handleOpenUpdateProviderDrawer = () => {
    setUpdateDrawerVisibility(true);
    setDetailsDrawerVisibility(false);
  };

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
    setDetailsDrawerVisibility(false);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleBackToDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
  };

  const [deleteProvider] = useDeleteProvider({
    refetchQueries: ["GetProviders"],
  });

  const handleDeleteProvider = () => {
    if (providerId) {
      deleteProvider({ id: providerId }, (data) => {
        if (data?.terraformProviderDelete?.deleted) {
          navigate("/providers");
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderDelete.id}" was successfully deleted`,
          });
        } else {
          reportError({
            message: "Something went wrong while deleting Terraform provider, please try again.",
          });
        }
      });
    }
  };

  const handleDeleteProviderConfirmation = () => {
    if (providerId) {
      showProviderDeleteConfirmation({
        providerId,
        onConfirm: handleDeleteProvider,
      });
    }
  };

  const [isProviderVersionDrawerVisible, setProviderVersionDrawerVisibility] = useState(false);
  const [isProviderVersionEditDrawerVisible, setProviderVersionEditDrawerVisibility] =
    useState(false);

  const [focusedProviderVersion, setFocusedProviderVersion] = useState<
    TerraformProviderVersion | undefined
  >(undefined);

  const handleCloseProviderVersionDrawer = () => {
    setProviderVersionDrawerVisibility(false);
    setFocusedProviderVersion(undefined);
  };

  const handleOpenProviderVersionDrawer = (providerVersion: TerraformProviderVersion) => {
    setFocusedProviderVersion(providerVersion);
    setProviderVersionDrawerVisibility(true);
  };

  const handleCloseProviderVersionEditDrawer = () => {
    setProviderVersionEditDrawerVisibility(false);
    setFocusedProviderVersion(undefined);
  };

  const handleOpenProviderVersionEditDrawer = (providerVersion: TerraformProviderVersion) => {
    setFocusedProviderVersion(providerVersion);
    setProviderVersionEditDrawerVisibility(true);
  };

  const handleOpenDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
  };

  const handleCloseDetailsDrawer = () => {
    setDetailsDrawerVisibility(false);
  };

  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<GetProviderWithVersionsGql>(
    GET_PROVIDER_WITH_VERSIONS,
    {
      variables: {
        id: providerId,
      },
      onError,
      nextFetchPolicy: "cache-first",
    }
    // APOLLO CLIENT UPDATE
  );

  const provider = data?.terraformProvider;

  const canManageProvider =
    viewer.admin || provider?.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  useBreadcrumbs(
    [
      {
        title: "Terraform registry",
        link: getBreadcrumbsBackUrl("/modules", FILTERS_ORDER_SETTINGS_KEY_PROVIDERS),
      },
      {
        title: "Providers",
        link: getBreadcrumbsBackUrl("/providers", FILTERS_ORDER_SETTINGS_KEY_PROVIDERS),
      },
      {
        title: provider?.id || "",
      },
    ],
    [provider?.id]
  );

  useTitle(`Provider · ${provider?.id}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !provider?.id) {
    return <PageLoading />;
  }

  if (!provider?.id) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{provider.id}</ViewHeaderTitle>
          </ViewHeaderWrapper>
          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <Button
              variant="secondary"
              onClick={handleOpenDetailsDrawer}
              active={isDetailsDrawerVisible}
            >
              Details
            </Button>

            {canManageProvider && (
              <DropdownMenuEllipsis tooltip="Provider actions" dotsSize="medium">
                <DropdownMenuItem onAction={handleOpenUpdateProviderDrawer}>Edit</DropdownMenuItem>

                <DropdownMenuItem onAction={handleDeleteProviderConfirmation} danger>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuEllipsis>
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={provider.spaceDetails.name}
              href={`/spaces/${provider.space}`}
            />
            <MetaInfoListItem icon={provider?.public ? Networking : Incognito}>
              {provider.public ? "Public" : "Private"}
            </MetaInfoListItem>
            {provider.latestVersionNumber && (
              <MetaInfoListItem>{provider.latestVersionNumber}</MetaInfoListItem>
            )}
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>
      <PrivateProviderRegistryTierInfo type="callout" />
      <Box direction="column" grow="1" fullWidth>
        <PageInfo title="Versions" />

        {!loading && provider.versions.length === 0 && (
          <EmptyState
            title="You have not published any versions of this provider"
            caption={
              <>
                Publishing is normally done through a CI/CD system, but you can also do it manually
                from your workstation. You can learn more about publishing Terraform providers{" "}
                <Link
                  size="small"
                  href={getDocsUrl("/vendors/terraform/provider-registry#publishing-a-provider")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </Link>
                .
              </>
            }
          />
        )}

        <Box direction="column" gap="large" className={styles.list}>
          {provider.versions.map((version) => (
            <ProviderVersion
              key={version.id}
              version={version}
              provider={provider}
              onProviderDrawerOpen={handleOpenProviderVersionDrawer}
              onProviderVersionEdit={handleOpenProviderVersionEditDrawer}
            />
          ))}
        </Box>
      </Box>
      <Drawer
        position="absoluteRight"
        visible={isUpdateDrawerVisible}
        onOutsideClick={handleCloseUpdateDrawer}
      >
        <ProviderDrawer handleCloseDrawer={handleCloseUpdateDrawer} provider={provider} />
      </Drawer>
      <Drawer
        position="absoluteRight"
        visible={isDetailsDrawerVisible}
        onOutsideClick={handleCloseDetailsDrawer}
      >
        {provider && (
          <ProviderDetailsDrawer
            item={provider}
            onClose={handleCloseDetailsDrawer}
            onEdit={handleOpenUpdateProviderDrawer}
            onOpenFullDescription={handleOpenFullDescriptionDrawer}
          />
        )}
      </Drawer>
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={provider.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
        onBackToDetails={handleBackToDetailsDrawer}
      />
      <Drawer
        position="absoluteRight"
        visible={isProviderVersionEditDrawerVisible}
        onOutsideClick={handleCloseProviderVersionEditDrawer}
      >
        {focusedProviderVersion && (
          <ProviderVersionEditDrawer
            providerVersion={focusedProviderVersion}
            provider={provider}
            handleCloseDrawer={handleCloseProviderVersionEditDrawer}
          />
        )}
      </Drawer>
      <Drawer
        position="absoluteRight"
        visible={isProviderVersionDrawerVisible}
        onOutsideClick={handleCloseProviderVersionDrawer}
      >
        {focusedProviderVersion && (
          <ProviderVersionDrawer
            providerVersion={focusedProviderVersion}
            provider={provider}
            handleCloseDrawer={handleCloseProviderVersionDrawer}
          />
        )}
      </Drawer>
    </>
  );
};

export default Provider;
