import { Pages } from "./types";

export const enum AnalyticsPageContext {
  Context = "Context",
  ContextEdit = "ContextEdit",
  ContextHooks = "ContextHooks",
  ContextMountedFiles = "ContextMountedFiles",
  ContextNew = "ContextNew",
  ContextUsedBy = "ContextUsedBy",
  ContextVariables = "ContextVariables",
  ContextsList = "ContextsList",
}

const contexts = "Contexts";

export const pagesContext: Pages<AnalyticsPageContext> = {
  [AnalyticsPageContext.Context]: [contexts, "Context"],
  [AnalyticsPageContext.ContextEdit]: [contexts, "Edit Context"],
  [AnalyticsPageContext.ContextNew]: [contexts, "New Context"],
  [AnalyticsPageContext.ContextsList]: [contexts, "Contexts list"],
  [AnalyticsPageContext.ContextHooks]: [contexts, "Hooks"],
  [AnalyticsPageContext.ContextMountedFiles]: [contexts, "Mounted files"],
  [AnalyticsPageContext.ContextUsedBy]: [contexts, "Used by"],
  [AnalyticsPageContext.ContextVariables]: [contexts, "Variables"],
};
