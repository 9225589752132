import TierInfo from "components/TierInfo";
import { BillingTierFeature } from "types/generated";

const BlueprintFeatureGateCallout = () => (
  <TierInfo
    type="callout"
    variant="danger"
    feature={BillingTierFeature.Blueprints}
    title="Blueprints are not supported by your plan"
  >
    You may still preview or delete the blueprints that you have created but you will not be able to
    create stacks with them. Upgrade to <strong>Business plan or higher</strong> to be able to use
    the feature again.
  </TierInfo>
);

export default BlueprintFeatureGateCallout;
