import { useCallback, useState } from "react";

import { SearchQueryFieldConstraintTimeInLast } from "types/generated";

import { PeriodValue } from "./types";

type UseDatepickerRangeProps = {
  initialPeriod: SearchQueryFieldConstraintTimeInLast;
  periods: Record<string, PeriodValue>;
};

const useDatepickerRange = ({ initialPeriod, periods }: UseDatepickerRangeProps) => {
  const [dateRange, setDateRange] = useState<{
    start: Date;
    end: Date;
  }>(periods[initialPeriod].range);

  const [datePeriod, setDatePeriod] = useState<SearchQueryFieldConstraintTimeInLast | undefined>(
    initialPeriod
  );

  const handleSetDateRange = useCallback(
    (dateRange: { start: Date; end: Date }, period?: SearchQueryFieldConstraintTimeInLast) => {
      setDateRange(dateRange);
      setDatePeriod(period || undefined);
    },
    []
  );

  return {
    dateRange,
    datePeriod,
    handleSetDateRange,
  };
};

export default useDatepickerRange;
