import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useDiscardRun from "shared/Run/useDiscardRun";

import { useRunShortcut } from "../../hooks/useRunShortcut";

type DiscardRunButtonProps = {
  stackId: string;
  runId: string;
  runQueryToRefetch?: string;
  analyticsPage?: AnalyticsPage;
};

const DiscardRunButton = ({
  runId,
  stackId,
  runQueryToRefetch,
  analyticsPage,
}: DiscardRunButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [discardRun, { loading, data }] = useDiscardRun({
    refetchQueries: [runQueryToRefetch || "GetRun"],
  });

  const onClick = useCallback(
    () =>
      discardRun({ stack: stackId, run: runId, note: null }, (data) => {
        if (data?.runDiscard?.id) {
          reportSuccess({ message: `Run ${data.runDiscard.id} successfully discarded` });
          if (analyticsPage) {
            trackSegmentAnalyticsEvent?.("Run discarded", {
              runType: data?.runDiscard?.type,
            });
          }
        }
      }),
    [analyticsPage, discardRun, reportSuccess, runId, stackId, trackSegmentAnalyticsEvent]
  );

  const isLoading = loading || !!data?.runDiscard?.id;

  useRunShortcut({
    label: "Discard",
    code: "alt+d",
    callback: onClick,
    loading: isLoading,
  });

  return (
    <Button
      variant="secondary"
      size="small"
      disabled={isLoading}
      loading={isLoading}
      onClick={onClick}
    >
      Discard
    </Button>
  );
};

export default DiscardRunButton;
