import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { UserManagementActivationStatus } from "../types";
import { SettingsContext } from "../Context";
import UserManagementWarningCalloutContent from "./Content";

type UserManagementWarningCalloutProps = {
  analyticsPage?:
    | AnalyticsPageOrganization.OrganizationUsers
    | AnalyticsPageOrganization.OrganizationIdpGroupMapping
    | AnalyticsPageOrganization.OrganizationApiKeys;
};

const UserManagementWarningCallout = ({ analyticsPage }: UserManagementWarningCalloutProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);

  if (activationStatus === UserManagementActivationStatus.INACTIVE) {
    return <UserManagementWarningCalloutContent analyticsPage={analyticsPage} />;
  }

  return null;
};

export default UserManagementWarningCallout;
