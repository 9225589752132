import TextLink from "components/DocumentationSnippets/TextLink";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

export const FAQ_ITEMS = [
  {
    title: "Does Spacelift support monorepos?",
    content: (
      <>
        Spacelift does support monorepos. You can set a project root in your stack settings. Our
        default push policy will only trigger runs on changes within the project root. Additionally,
        you can set project globs to specify files and directories outside of the project root that
        the stack cares about.
      </>
    ),
    learnMoreContent: (
      <Typography tag="p" variant="p-body2">
        <Typography tag="span" variant="p-t6">
          Project root
        </Typography>{" "}
        <br />
        The Project root points to the directory within the repo where the project should start
        executing. This is especially useful for monorepos, or indeed repositories hosting multiple
        somewhat independent projects. This setting plays very well with Git push policies, allowing
        you to easily express generic rules on what it means for the stack to be affected by a code
        change.
        <br />
        <br />
        The project root can be overridden by the runtime configuration specified in the
        .spacelift/config.yml file.
        <br />
        <br />
        <Typography tag="span" variant="p-t6">
          Project globs
        </Typography>{" "}
        <br />
        If you have a project root set, you can still add project globs which allows you to specify
        files and directories outside of the project root that the stack cares about. In the absence
        of push policies, any changes made to the project root and any paths specified by project
        globs will trigger Spacelift runs.
        <br />
        <br />
        You can review our{" "}
        <TextLink href="https://github.com/spacelift-io/spacelift-policies-example-library">
          policy example
        </TextLink>{" "}
        repo and see how you can customize this further
      </Typography>
    ),
    learnMoreTitle: "Monorepos",
  },
  {
    title: "My stack is not being triggered, why?",
    content: <>The main culprits are usually a push policy or the wrong branch being tracked.</>,
    learnMoreTitle: "Stack is not being triggered",
    learnMoreContent: (
      <Typography tag="p" variant="p-body2">
        If you do not have a push policy in place, you can attach a push policy with the{" "}
        <TextLink href={getDocsUrl("/concepts/policy/push-policy#default-git-push-policy")}>
          default policy
        </TextLink>{" "}
        and{" "}
        <TextLink href={getDocsUrl("/concepts/policy#sampling-policy-inputs")}>
          enable sampling
        </TextLink>{" "}
        to then review the inputs in the{" "}
        <TextLink href={getDocsUrl("/concepts/policy#policy-workbench")}>policy workbench</TextLink>{" "}
        to confirm that Spacelift is actually receiving the push event. If you are using Gitlab, you
        need to set up{" "}
        <TextLink href={getDocsUrl("/integrations/source-control/gitlab.html#setting-up-webhooks")}>
          webhooks
        </TextLink>{" "}
        for every project.
        <br />
        <br />
        You can review the branch you are tracking in your stack settings.
        <br />
        <br />
        We also recommend checking your VCS provider is not currently experiencing any issues.
      </Typography>
    ),
  },
  {
    title: "Can I get Spacelift feedback on my local code?",
    content: (
      <>
        You can use{" "}
        <TextLink href="https://github.com/spacelift-io/spacectl" size="small">
          spacectl
        </TextLink>{" "}
        stack local-preview to run a local plan on a Spacelift stack.
      </>
    ),
  },
  {
    title: "Can Spacelift modules be used outside of Spacelift?",
    content: (
      <>
        Yes, modules in the private registry can be used outside of Spacelift with proper credential
        management. More information can be found{" "}
        <TextLink
          href={getDocsUrl(
            "/vendors/terraform/module-registry.html#using-modules-outside-of-spacelift"
          )}
          size="small"
        >
          here
        </TextLink>{" "}
      </>
    ),
  },
  {
    title: "Can I use multiple policies of the same type?",
    content: (
      <>
        When there are multiple policies of the same type, they are evaluated independently and then
        the decisions are merged. With Login policy, denies will take precedence over allows. That
        is why we recommend having a single Login policy. It is easier to reason about it.
        <br />
        <br />
        Otherwise, policies could look perfectly fine but block each other with deny rules. This is
        also true for the Push policy type but Plan policies, for example, are fine because they
        usually don’t conflict.
      </>
    ),
  },
  {
    title: "How can the workflow be customized?",
    content: (
      <>
        You can use hooks to customize your workflow. Hooks refer to extra commands that can be
        added to customize the workflow at various stages of a process. These hooks are essentially
        scripts or commands that are executed before and after certain phases in the Spacelift
        workflow
      </>
    ),
  },
  {
    title: "What is the role of a Root Space Admin?",
    content: (
      <>
        A Root Space Admin is perceived as an admin of the whole account, with privileges like
        modifying the space tree and accessing account-wide settings.
      </>
    ),
  },
  {
    title: "How does inheritance affect role propagation in spaces?",
    content: (
      <>
        When inheritance is enabled, a user with any role in a child space also gets a Read role in
        the spaces up the tree.
      </>
    ),
  },
];
