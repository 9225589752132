import { BillingTier } from "types/generated";

export const PLAN_FEATURES_BY_TIER: Partial<
  Record<BillingTier, { negative?: boolean; text: string; tooltip?: string }[]>
> = {
  [BillingTier.V4Free]: [
    {
      text: "2 users",
      tooltip:
        "API keys are also considered virtual users and are billed like regular users, too. Thus, each API key used (exchanged for a token) during any given billing cycle counts against the total number of users.",
    },
    { text: "Policy as Code engine based on OPA", negative: true },
    { text: "1 public worker" },
    { text: "Notifications", negative: true },
    { text: "SSO OIDC" },
    { text: "Private workers", negative: true },
  ],
  [BillingTier.V4Starter]: [
    { text: "Notifications" },
    { text: "OIDC integrations" },
    { text: "Webhooks" },
    { text: "Drift detection", negative: true },
    { text: "Private module registry" },
    { text: "Private workers", negative: true },
  ],
  [BillingTier.V4StarterPlus]: [
    { text: "Drift detection" },
    { text: "Blueprints", negative: true },
    { text: "Unlimited users" },
    { text: "Targeted replans", negative: true },
    { text: "1 private worker" },
    { text: "Advanced scheduling", negative: true },
  ],
  [BillingTier.V4Business]: [
    { text: "Blueprints" },
    { text: "Advanced scheduling" },
    { text: "Drift detection" },
    { text: "Private provider registry" },
    { text: "Targeted replans" },
    { text: "Concurrent VCS", negative: true },
  ],
  [BillingTier.V4Enterprise]: [
    { text: "Premium support and services" },
    { text: "SSO integration with SAML 2.0" },
    { text: "Concurrent VCS" },
    { text: "OIDC API Keys" },
    { text: "Audit trail" },
    { text: "Private source control system" },
  ],
};
