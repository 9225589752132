import kebabCase from "lodash-es/kebabCase";
import cx from "classnames";

import styles from "./styles.module.css";

type PolicyOutcomeProps = {
  outcome: string;
};

const PolicyOutcome = ({ outcome }: PolicyOutcomeProps) => {
  const outcomeText = outcome === "pull_request" ? "pull request" : outcome;
  return (
    <span className={cx(styles.policyOutcome, styles[kebabCase(outcome)])}>
      <span className={styles.outcomeText}>{outcomeText}</span>
    </span>
  );
};

export default PolicyOutcome;
