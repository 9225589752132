import FlashContext from "components/FlashMessages/FlashContext";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useTypedContext from "hooks/useTypedContext";
import useDisableStack from "shared/Stack/useDisableStack";
import { Stack } from "types/generated";

type DisableButtonProps = {
  stackId: Stack["id"];
};

const DisableButton = ({ stackId }: DisableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [disableStack, { loading }] = useDisableStack({
    refetchQueries: ["GetStack"],
  });

  const handleClick = () =>
    disableStack({ id: stackId }, (data) => {
      if (data?.stackDisable.id) {
        reportSuccess({ message: "Stack successfully disabled" });
      }
    });

  return (
    <DropdownMenuItem onAction={handleClick} isDisabled={loading} loading={loading}>
      Disable
    </DropdownMenuItem>
  );
};

export default DisableButton;
