import { GitCommitNew } from "components/icons";
import { Commit, VcsProvider } from "types/generated";
import { getCommmitDigitsCount } from "utils/commit";

import MetaInfoListItem from "./Item";

type MetaInfoCommitProps = {
  commit: Commit;
  provider: VcsProvider;
};

const MetaInfoCommit = ({ commit, provider }: MetaInfoCommitProps) => {
  return (
    <MetaInfoListItem
      href={commit.url}
      linkText={commit.hash.slice(0, getCommmitDigitsCount(provider))}
      icon={GitCommitNew}
      tooltip={`Commit set by ${commit.authorName}`}
      target="_blank"
      type="commit"
    />
  );
};

export default MetaInfoCommit;
