import { createContext } from "react";

import { ContextCreationWizardStep } from "./constants";
import { ContextFormStepData } from "./types";
import { AttachedProject } from "./AttachedProject/types";

type ContextFormContextProps = {
  currentStep: ContextCreationWizardStep;
  currentStepData?: ContextFormStepData;
  setCurrentStep: (step: ContextCreationWizardStep) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  attachedProjects: AttachedProject[];
  autoAttachedProjects: AttachedProject[];
  setAttachedProjects: (projects: AttachedProject[], isAuto?: boolean) => void;
};

export const ContextFormContext = createContext<ContextFormContextProps | undefined>(undefined);
ContextFormContext.displayName = "ContextFormContext";
