import React from "react";
import cx from "classnames";

import Breadcrumbs from "components/Breadcrumbs";
import BreadcrumbStepBack from "components/Breadcrumbs/StepBack";

import styles from "./styles.module.css";
import ViewHeaderScrollCollapse from "./ScrollCollapse";

type ViewHeaderProps = {
  firstLevel?: boolean;
  id?: string;
  noBreadCrumbs?: boolean;
  noSticky?: boolean;
  children: React.ReactNode;
  backConfirmation?: string;
};

const ViewHeader = ({
  children,
  firstLevel,
  noBreadCrumbs,
  noSticky,
  id,
  backConfirmation,
}: ViewHeaderProps) => {
  return (
    <div
      id={id}
      className={cx(styles.header, { [styles.firstLevel]: firstLevel, [styles.sticky]: !noSticky })}
    >
      {!noBreadCrumbs && (
        <ViewHeaderScrollCollapse>
          <Breadcrumbs />
        </ViewHeaderScrollCollapse>
      )}

      <header>
        {!noBreadCrumbs && <BreadcrumbStepBack confirmation={backConfirmation} />}
        {children}
      </header>
    </div>
  );
};

export default ViewHeader;
