import { mergeRefs } from "@react-aria/utils";
import { useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";

import Tag from "ds/components/Tag";
import { LABEL_FOLDER_KEY } from "constants/labels";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownNew from "ds/components/Dropdown/New";
import { ChevronNew } from "components/icons";
import Box from "ds/components/Box";
import Tooltip from "ds/components/Tooltip";
import ButtonNew from "ds/components/Button/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useElementWidth from "hooks/useElementWidth";

import styles from "./styles.module.css";
import TagsListFilterableNewSingleTag from "./SingleTag";

const SPACE = 4;
const INITIAL_MULTIPLE_TAGS_DROPDOWN_WIDTH = 90;

type TagsListFilterableProps = {
  applyFolderFilter?: (value: string) => void;
  applyLabelFilter: (value: string) => void;
  tags: string[];
};

const TagsListFilterableNew = ({
  tags,
  applyFolderFilter,
  applyLabelFilter,
}: TagsListFilterableProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mutlipleTagsDropdownRef = useRef<HTMLButtonElement>(null);
  const [tagsSizes, setTagSizes] = useState<Record<string, number>>({});

  const containerSize = useElementWidth(tags.length ? containerRef.current : null);
  const mutlipleTagsDropdownSize = useElementWidth(
    tags.length ? mutlipleTagsDropdownRef.current : null,
    INITIAL_MULTIPLE_TAGS_DROPDOWN_WIDTH
  );

  const hiddenTags = useMemo(() => {
    if (!tags.length) {
      return [];
    }

    const hiddenTags: string[] = [];

    let summOfSizes = mutlipleTagsDropdownSize;

    for (let i = 0; i < tags.length; i++) {
      const size = tagsSizes[tags[i]] + SPACE;
      const isLast = i === tags.length - 1;

      summOfSizes += size;

      if (isLast) {
        summOfSizes -= mutlipleTagsDropdownSize;
      }

      if (containerSize && summOfSizes > containerSize) {
        hiddenTags.push(...tags.slice(i));
        return hiddenTags;
      }
    }

    return hiddenTags;
  }, [containerSize, tags, tagsSizes, mutlipleTagsDropdownSize]);

  const tagOnClickHandler = useCallback(
    (tag: string, callback?: () => void) => {
      const isFolder = tag.startsWith(LABEL_FOLDER_KEY);

      if (isFolder && applyFolderFilter) {
        applyFolderFilter(tag.replace(LABEL_FOLDER_KEY, ""));
      } else {
        applyLabelFilter(tag);
      }

      callback?.();
    },
    [applyFolderFilter, applyLabelFilter]
  );

  if (tags.length === 0) {
    return null;
  }

  if (tags.length === 1) {
    return (
      <Tooltip
        on={({ ref, ...props }) => (
          <Tag
            {...props}
            ref={ref}
            className={styles.tag}
            tag={tags[0]}
            onClick={() => tagOnClickHandler(tags[0])}
          />
        )}
      >
        Add to filters
      </Tooltip>
    );
  }

  const moreTagsTooltip = `${hiddenTags.length === tags.length ? `${hiddenTags.length} labels` : `+${hiddenTags.length}`}`;

  return (
    <Box
      ref={containerRef}
      className={styles.container}
      fullWidth
      gap="small"
      justify="start"
      align="center"
    >
      {tags.map((tag) => (
        <TagsListFilterableNewSingleTag
          setTagSizes={setTagSizes}
          currentSize={tagsSizes[tag]}
          tagOnClickHandler={tagOnClickHandler}
          shouldHide={hiddenTags.includes(tag)}
          key={tag}
          tag={tag}
          containerSize={containerSize}
        />
      ))}
      {!!hiddenTags.length && (
        <DropdownNew
          triggerComponent={
            <Tooltip
              on={({ ref, ...tooltipProps }) => (
                <ButtonNew
                  {...tooltipProps}
                  ref={mergeRefs<HTMLButtonElement>(ref, mutlipleTagsDropdownRef)}
                  aria-label="Show more tags"
                  className={cx(
                    styles.button,
                    !!containerSize && styles.active,
                    tooltipProps.className
                  )}
                  variant="secondary"
                  size="small"
                  endIcon={ChevronNew}
                  endIconRotate="90"
                  withTextEllipsis
                >
                  {moreTagsTooltip}
                </ButtonNew>
              )}
            >
              Show more labels
            </Tooltip>
          }
        >
          {({ close }) => (
            <DropdownSection className={styles.dropdownSection} align="start">
              {hiddenTags.map((tag) => {
                return (
                  <DropdownSectionItem
                    tooltip="Add to filters"
                    onClick={() => tagOnClickHandler(tag, close)}
                    tooltipPlacement="right"
                    key={tag}
                  >
                    <Tag className={styles.tag} tag={tag} />
                  </DropdownSectionItem>
                );
              })}
            </DropdownSection>
          )}
        </DropdownNew>
      )}
    </Box>
  );
};

export default TagsListFilterableNew;
