import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { Blueprint } from "types/generated";
import DescriptionDetails from "components/DescriptionDetails";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

type BlueprintDetailsProps = {
  item: Blueprint;
  onEdit?: () => void;
  handleCloseDrawer: () => void;
  onOpenFullDescription: () => void;
};

const BlueprintDetails = ({
  item,
  onEdit,
  onOpenFullDescription,
  handleCloseDrawer,
}: BlueprintDetailsProps) => {
  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Blueprint details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Name" value={item.name} />

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={item.space.name}
            href={`/spaces/${item.space.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={item.description}
          onAddDescription={onEdit}
          onOpenFullDescription={onOpenFullDescription}
        />

        <FormFieldTimestamp label="Last updated" timestamp={item.updatedAt} />

        {item.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={item.labels} alwaysExpanded />
          </FormField>
        )}
      </DrawerBody>
    </>
  );
};

export default BlueprintDetails;
