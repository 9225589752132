import { MouseEvent, useEffect, useId, useMemo, useRef, useState } from "react";

import useOutsideClick from "hooks/useOutsideClick";
import DropdownList from "ds/components/Dropdown/List";
import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import { DropdownProps } from "./types";

const Dropdown = ({
  children,
  onVisibilityChange,
  closeDropdownTrigger = 0,
  renderTriggerComponent,
  className,
  position = "bottomLeft",
}: DropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const closeDropdown = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    onVisibilityChange?.(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useOutsideClick(wrapperRef, closeDropdown);

  useEscapeKeypress(isVisible, closeDropdown);

  useEffect(() => {
    if (closeDropdownTrigger > 0) {
      closeDropdown();
    }
  }, [closeDropdownTrigger]);

  const id = useId();

  const ariaProps = useMemo(
    () => ({
      "aria-expanded": isVisible,
      "aria-haspopup": true,
      "aria-controls": id,
    }),
    [isVisible, id]
  );

  return (
    <DropdownWrapper ref={wrapperRef} className={className}>
      {renderTriggerComponent({ onClick: handleClick, isVisible, ariaProps })}

      <DropdownList active={isVisible} wrapperRef={wrapperRef} position={position} id={id}>
        {children({ closeDropdown })}
      </DropdownList>
    </DropdownWrapper>
  );
};

export default Dropdown;
