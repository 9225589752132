import { type FetchResult } from "@apollo/client";

export type BulkActionItemID = string;

export type BaseEntity = { id: string };

export enum BulkActionsVariant {
  Drawer = "drawer",
  FloatingBar = "floatingBar",
}

export enum BulkActionsStep {
  ChooseAction = "choose-action",
  ActionConfirmation = "action-confirmation",
  Results = "results",
}

export enum BulkActionActionConfirmationTabs {
  Applicable = "applicable",
  NotApplicable = "notApplicable",
}

export enum BulkActionsResult {
  Completed = "completed",
  Pending = "pending",
  Failed = "failed",
  Skipped = "skipped",
  Queued = "queued",
  Stopped = "stopped",
}

export enum BulkActionResultTabs {
  Completed = "completed",
  Failed = "failed",
  Pending = "pending",
}

export enum BulkActionsCloseMethod {
  Cross = "Cross",
  ResultsCloseButton = "Results close button",
  EmptyActionsButton = "Empty actions button",
  UnselectedAllItem = "All items unselected",
}

export type BulkActionsResults = Record<BulkActionItemID, BulkActionsResult>;

export type BulkEntityActionItem<Action extends string, Entity, MutationVariables> = {
  key: Action;
  title: string;
  resultTitle?: string;
  condition: (item: Entity) => boolean;
  mutation: (
    item: Entity,
    mutationVariables: MutationVariables
  ) => Promise<FetchResult<unknown> | void>;
};

export type BulkActionsActionDescriptor<Action extends string> = {
  action: Action;
  title: string;
  resultTitle?: string;
};

export type BulkActionsActionsPerItem<Action extends string> = Map<
  BulkActionItemID,
  BulkActionsActionDescriptor<Action>[]
>;
