import { ReactNode, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Route, Routes, useLocation } from "react-router-dom-v5-compat";

import Stepper from "components/Stepper";
import StepperStep from "components/Stepper/Step";
import Box from "ds/components/Box";
import { GitHubAppManifest } from "types/generated";
import { VCS_INTEGRATIONS_PATH } from "views/Account/VCS/constants";

import {
  ManageGitHubEnterpriseWizardFormFields,
  VCSIntegrationsManageGitHubEnterpriseWizardSteps as WizardSteps,
} from "./types";
import VCSIntegrationsManageGitHubEnterpriseWizardStepIntro from "./StepIntro";
import VCSIntegrationsManageGitHubEnterpriseWizardStepIntegration from "./StepIntegration";
import VCSIntegrationsManageGitHubEnterpriseWizardStepOwner from "./StepOwner";
import VCSIntegrationsManageGitHubEnterpriseWizardStepCreateApp from "./StepCreateApp";
import VCSIntegrationsManageGitHubEnterpriseWizardStepFinal from "./StepFinal";
import useGenerateGitHubAppManifest from "./useGenerateGitHubAppManifest";
import { mapGenerateManifestInputValues } from "./helpers";

type VCSIntegrationsManageGitHubEnterpriseWizardProps = {
  children?: ReactNode;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageGitHubEnterpriseWizard = ({
  children,
  onIsDirtyChange,
}: VCSIntegrationsManageGitHubEnterpriseWizardProps) => {
  const manageGitHubEnterpriseWizardForm = useForm<ManageGitHubEnterpriseWizardFormFields>({
    defaultValues: {
      apiHost: "",
      host: "",
      ownerName: "",
      ownerType: undefined,
    },
    mode: "onChange",
  });

  const [currentStep, setCurrentStep] = useState<WizardSteps>(WizardSteps.STEP_INTRO);
  const [generatedManifest, setGeneratedManifest] = useState<GitHubAppManifest | undefined>(
    undefined
  );

  const location = useLocation();
  const theFinalStep = location.pathname === `${VCS_INTEGRATIONS_PATH}/manage/github/callback`;

  const { generateManifest, loading } = useGenerateGitHubAppManifest();

  const handleChangeStep = useCallback(
    (nextStep: WizardSteps) => () => {
      // to prevent occasional click from the final step
      if (!theFinalStep) {
        setCurrentStep(nextStep);
      }
    },
    [theFinalStep]
  );

  const handleGenerateManifest = useCallback(
    async (formData: ManageGitHubEnterpriseWizardFormFields) => {
      await generateManifest(mapGenerateManifestInputValues(formData), setGeneratedManifest);

      handleChangeStep(WizardSteps.STEP_CREATE_APP)();
    },
    [generateManifest, handleChangeStep]
  );

  useEffect(() => {
    onIsDirtyChange(currentStep !== WizardSteps.STEP_INTRO);
  }, [currentStep, onIsDirtyChange]);

  return (
    <Box direction="row" grow="1" fullWidth scrollable>
      {(currentStep !== WizardSteps.STEP_INTRO || theFinalStep) && (
        <Stepper>
          <StepperStep
            active={currentStep === WizardSteps.STEP_INTEGRATION}
            onClick={handleChangeStep(WizardSteps.STEP_INTEGRATION)}
            disabled={theFinalStep}
          >
            GitHub vs self-hosted
          </StepperStep>
          <StepperStep
            active={currentStep === WizardSteps.STEP_OWNER}
            onClick={handleChangeStep(WizardSteps.STEP_OWNER)}
            disabled={currentStep === WizardSteps.STEP_INTEGRATION || theFinalStep}
          >
            Personal vs Organization
          </StepperStep>
          <StepperStep
            active={currentStep === WizardSteps.STEP_CREATE_APP}
            onClick={handleChangeStep(WizardSteps.STEP_CREATE_APP)}
            disabled={
              currentStep === WizardSteps.STEP_OWNER ||
              currentStep === WizardSteps.STEP_INTEGRATION ||
              theFinalStep
            }
          >
            Create GitHub App
          </StepperStep>
          <StepperStep active={theFinalStep} disabled={!theFinalStep}>
            Additional information
          </StepperStep>
        </Stepper>
      )}

      <Box direction="column" relative fullWidth>
        <Routes>
          <Route
            index
            element={
              <FormProvider {...manageGitHubEnterpriseWizardForm}>
                {currentStep === WizardSteps.STEP_INTRO && (
                  <VCSIntegrationsManageGitHubEnterpriseWizardStepIntro
                    goToNextStep={handleChangeStep(WizardSteps.STEP_INTEGRATION)}
                  >
                    {children}
                  </VCSIntegrationsManageGitHubEnterpriseWizardStepIntro>
                )}

                {currentStep === WizardSteps.STEP_INTEGRATION && (
                  <VCSIntegrationsManageGitHubEnterpriseWizardStepIntegration
                    goToNextStep={handleChangeStep(WizardSteps.STEP_OWNER)}
                    goToPreviousStep={handleChangeStep(WizardSteps.STEP_INTRO)}
                  />
                )}

                {currentStep === WizardSteps.STEP_OWNER && (
                  <VCSIntegrationsManageGitHubEnterpriseWizardStepOwner
                    loading={loading}
                    goToNextStep={manageGitHubEnterpriseWizardForm.handleSubmit(
                      handleGenerateManifest
                    )}
                    goToPreviousStep={handleChangeStep(WizardSteps.STEP_INTEGRATION)}
                  />
                )}

                {currentStep === WizardSteps.STEP_CREATE_APP && (
                  <VCSIntegrationsManageGitHubEnterpriseWizardStepCreateApp
                    manifest={generatedManifest}
                    goToPreviousStep={handleChangeStep(WizardSteps.STEP_OWNER)}
                  />
                )}
              </FormProvider>
            }
          />

          <Route
            path="callback"
            element={<VCSIntegrationsManageGitHubEnterpriseWizardStepFinal />}
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseWizard;
