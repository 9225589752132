import DrawerBody from "ds/components/Drawer/Body";
import LoadingIndicator from "ds/components/LoadingIndicator";

const Loader = () => (
  <DrawerBody fullHeight align="center" justify="center">
    <LoadingIndicator size="large" loading />
  </DrawerBody>
);

export default Loader;
