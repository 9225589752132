import MarkdownGithub from "components/markdown/MarkdownGithub";
import PageInfo from "components/PageWrapper/Info";

import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../components/PageIntoTitle";

type ModuleVersionReadmeProps = {
  content: string | undefined;
};

const ModuleVersionReadme = ({ content }: ModuleVersionReadmeProps) => {
  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Readme"
            tooltip="Module documentation generated based on your README.md file."
          />
        }
      />
      <ModuleVersionScrollableWrapper surfaceColor="primary">
        <MarkdownGithub markup={content || "No readme available"} />
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionReadme;
