import { RunStateTransition } from "types/generated";

import { canShowElement } from "./elements";
import { RunElementType, RunEntryContext } from "../types";
import PerformingEntry from "../entries/PerformingEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";
import { mergeWithAfterRunHooks } from "./mergeWithAfterRunHooks";

export const createPerformingEntry = (
  { timestamp, until, stateVersion, hasLogs }: RunStateTransition,
  { runHooks, runId, stackId, isModuleRun, canStop, isRunFinished }: RunEntryContext
) => {
  let afterHooks = runHooks.afterPerform;

  if (isRunFinished && canShowElement(RunElementType.AfterRunHooks)) {
    afterHooks = mergeWithAfterRunHooks(afterHooks, runHooks.afterRun);
  }

  return (
    <PerformingEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      stateVersion={stateVersion}
      hasLogs={hasLogs}
      openLogsByDefault={canShowElement(RunElementType.Logs)}
      showStopButton={canShowElement(RunElementType.TerminateRunAction)}
      beforeHooks={runHooks.beforePerform}
      afterHooks={afterHooks}
      runId={runId}
      stackId={stackId}
      isModuleRun={isModuleRun}
      canStop={canStop}
    />
  );
};
