import { type ReactNode } from "react";

import DrawerFooter from "ds/components/Drawer/Footer";

type BulkActionsDrawerActionStepsFooterProps = {
  children: ReactNode;
};
const BulkActionsDrawerActionStepsFooter = ({
  children,
}: BulkActionsDrawerActionStepsFooterProps) => (
  <DrawerFooter direction="column" align="stretch" gap="large">
    {children}
  </DrawerFooter>
);

export default BulkActionsDrawerActionStepsFooter;
