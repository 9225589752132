import useLocalStorage from "@rehooks/local-storage";
import { useCallback, useState } from "react";

export type UseCloseFeedbackProps = {
  closeCallback?: ({ closedPermanently }: { closedPermanently: boolean }) => void;
  initialSaveToLocalStorage: boolean;
  storageKey?: string;
};

const useCloseFeedback = ({
  closeCallback,
  initialSaveToLocalStorage,
  storageKey,
}: UseCloseFeedbackProps) => {
  const [saveToLocalStorage, setSaveToLocalStorage] = useState<boolean>(initialSaveToLocalStorage);
  const [localStorageHidden, setLocalStorageHidden] = useLocalStorage<boolean>(
    storageKey || "",
    false
  );
  const [isHidden, setIsHidden] = useState<boolean>(localStorageHidden);

  const handleClose = useCallback(() => {
    setIsHidden(true);
    if (saveToLocalStorage) {
      setLocalStorageHidden(true);
    }

    closeCallback?.({ closedPermanently: saveToLocalStorage });
  }, [saveToLocalStorage, closeCallback, setLocalStorageHidden]);

  const toggleSaveToLocalStorage = useCallback(() => {
    setSaveToLocalStorage((val) => !val);
  }, []);

  return {
    isHidden,
    handleClose,
    saveToLocalStorage,
    toggleSaveToLocalStorage,
  };
};

export default useCloseFeedback;
