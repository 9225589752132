import { gql } from "@apollo/client";

export const GET_STACK_OUTPUTS = gql`
  query GetStackOutputs($stackId: ID!) {
    stack(id: $stackId) {
      id
      outputs {
        id
        sensitive
        value
      }
    }
  }
`;
