import { useState } from "react";

import { WebhookDeliveryDetails } from "types/generated";

import WebhookDetailsResponse from "./Response";
import styles from "./styles.module.css";

type WebhookDeliveriesProps = {
  item?: WebhookDeliveryDetails;
};

const WebhookDetails = ({ item }: WebhookDeliveriesProps) => {
  const [activeTab, setActiveTab] = useState("request");

  const handleSetActiveTab = (tab: string) => () => setActiveTab(tab);

  return (
    <div>
      <nav className={styles.nav}>
        <button
          className={`${styles.navItem} ${activeTab === "request" ? styles.navItemActive : ""}`}
          onClick={handleSetActiveTab("request")}
          role="tab"
        >
          Request
        </button>
        <button
          className={`${styles.navItem} ${activeTab === "response" ? styles.navItemActive : ""}`}
          onClick={handleSetActiveTab("response")}
          role="tab"
        >
          Response
        </button>
      </nav>
      <div className={styles.content}>
        {activeTab === "request" && (
          <WebhookDetailsResponse headers={item?.requestHeaders} body={item?.requestBody} />
        )}
        {activeTab === "response" && (
          <WebhookDetailsResponse
            headers={item?.responseHeaders}
            body={item?.responseBody}
            error={item?.responseError}
          />
        )}
      </div>
    </div>
  );
};

export default WebhookDetails;
