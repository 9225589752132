import { gql } from "@apollo/client";

export const GET_BILLING = gql`
  query GetBilling {
    billedExternally
    onTrialUntil
    billingSubscription {
      isActive
      selfServePortalUrl
      status
      billingInfo {
        address
        city
        companyName
        country
        email
        fullName
        state
        zipCode
      }
      billingInterval
      billingAmount
      billingRenewalDate
      addons {
        name
      }
    }
    tier
    availableBillingAddons {
      name
      tiers
      monthlyPrice
      yearlyPrice
    }
    availableSelfServicePlans {
      tier
      monthlyPrice
      yearlyPrice
    }
  }
`;
