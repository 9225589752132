import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showMarkAsBadConfirmation } from "./Modal";
import { MarkAsBadProps } from "./types";

const MarkAsBadDropdownItem = ({
  moduleId,
  id,
  versionNumber,
  versionState,
  onClickCb,
}: MarkAsBadProps) => {
  const handleShowModal = () => {
    showMarkAsBadConfirmation({
      id,
      versionState,
      versionNumber,
      moduleId,
    });
    onClickCb?.();
  };

  return <DropdownMenuItem onAction={handleShowModal}>Mark as bad</DropdownMenuItem>;
};

export default MarkAsBadDropdownItem;
