import { useFormContext } from "react-hook-form";

import Link from "ds/components/Link";
import BeforeAfterCommandsList from "components/BeforeAfterCommands/List";
import { Command, HookType, toCommands } from "utils/hooks";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { getDocsUrl } from "utils/getDocsUrl";

import ListTitle from "../ListTitle";
import { StackHooksFormData } from "../../types";

type StackHooksListProps = { type: HookType; hooks: Command[]; isDragActive: boolean };

const StackHooksList = ({ type, hooks, isDragActive }: StackHooksListProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);
  const form = useFormContext<StackHooksFormData>();

  const handleAddCommands = (type: HookType, lines: string[]) => {
    form.setValue(`hooks.${type}`, hooks.concat(toCommands(lines)));
  };

  const handleRemoveCommand = (type: HookType, id: string) => {
    form.setValue(
      `hooks.${type}`,
      hooks.filter((hook) => hook.id !== id)
    );
  };

  const handleEditCommand = (type: HookType, id: string, text: string) => {
    form.setValue(
      `hooks.${type}`,
      hooks.map((command) => (command.id === id ? { ...command, text } : command))
    );
  };

  return (
    <BeforeAfterCommandsList
      isDragActive={isDragActive}
      type={type}
      title={
        <ListTitle
          title="Stack hooks"
          tooltipBody={
            <>
              Stack hooks can be edited as well as their priority changed within stack. In the
              before phase their priority is secondary to the context hooks in opposite to the after
              phase.
              <br />
              <Link
                // TODO: Add docs about hook ordering
                href={getDocsUrl("/concepts/stack/stack-settings.html#note-on-hook-ordering")}
                target="_blank"
              >
                Learn more
              </Link>
            </>
          }
        />
      }
      commands={hooks}
      onAddCommands={handleAddCommands}
      onRemoveCommand={handleRemoveCommand}
      onEditCommand={handleEditCommand}
      readOnly={!canManageStackAndRuns}
    />
  );
};

export default StackHooksList;
