import { get, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";

type ContextConfigFormPathFieldProps = {
  name: string;
};

const validatePath = (value: string) => {
  if (value.startsWith("/")) {
    return "Mount path must be relative";
  }

  if (value.endsWith("/")) {
    return "Mount path must point to a file";
  }

  return true;
};

const ContextConfigFormPathField = ({ name }: ContextConfigFormPathFieldProps) => {
  const { register, formState } = useFormContext();
  const error = get(formState.errors, name);

  return (
    <FormField label="Path" error={error?.message} fullWidth>
      {({ ariaInputProps }) => (
        <Box align="center" gap="small">
          <Typography variant="p-body3" tag="span">
            /mnt/workspace/
          </Typography>

          <Input
            placeholder="Enter path here..."
            error={!!error}
            {...register(name, {
              required: "Path field is required.",
              validate: validatePath,
            })}
            {...ariaInputProps}
          />
        </Box>
      )}
    </FormField>
  );
};

export default ContextConfigFormPathField;
