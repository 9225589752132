import { gql } from "@apollo/client";

export const GET_RESOURCES_COUNT_BY_VENDOR = gql`
  query GetResourcesCountByVendor {
    metrics {
      resourcesCountByVendor {
        labels
        value
      }
      hasStacks
    }
  }
`;
