export const searchModuleSuggestionsDictionary: Partial<Record<ModuleSuggestions, string>> = {
  label: "Labels",
  starred: "Favorite",
  slug: "ID",
  projectRoot: "Project root",
  workerPool: "Worker pool",
  integration: "Source code integration",
  createdAt: "Created at",
  moduleProvider: "Module provider",
};

export enum ModuleSuggestions {
  Administrative = "administrative",
  Branch = "branch",
  Starred = "starred",
  Folder = "folder",
  Slug = "slug",
  Label = "label",
  ProjectRoot = "projectRoot",
  Repository = "repository",
  Integration = "integration",
  Space = "space",
  WorkerPool = "workerPool",
  CreatedAt = "createdAt",
  ModuleProvider = "moduleProvider",
}
