import { ReactNode } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type UsersInviteWrapperProps = {
  children: ReactNode;
};

const UsersInviteWrapper = ({ children }: UsersInviteWrapperProps) => {
  return (
    <div className={styles.wrapper}>
      <Box className={styles.container} direction="column" justify="center" align="center">
        <Typography variant="p-t2" tag="p" className={styles.title}>
          <span className={styles.text}>Collaborate with</span>
          {` `}
          your team
        </Typography>
        {children}
      </Box>
    </div>
  );
};

export default UsersInviteWrapper;
