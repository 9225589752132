import { useState, type JSX } from "react";

import useTypedContext from "hooks/useTypedContext";

import { BulkActionsDataContext } from "../../Context";
import { BulkActionResultTabs, BulkActionsStep } from "../../types";

type BulkActionsDrawerResultsStepChildrenProps = {
  currentTab: BulkActionResultTabs;
  setCurrentTab: (tab: BulkActionResultTabs) => void;
};

type BulkActionsDrawerResultsStepProps = {
  children: (props: BulkActionsDrawerResultsStepChildrenProps) => JSX.Element;
};

const BulkActionsDrawerResultsStep = ({ children }: BulkActionsDrawerResultsStepProps) => {
  const [currentTab, setCurrentTab] = useState<BulkActionResultTabs>(
    BulkActionResultTabs.Completed
  );

  const { bulkActionsStep } = useTypedContext(BulkActionsDataContext);

  if (bulkActionsStep !== BulkActionsStep.Results) {
    return null;
  }
  return <>{children({ currentTab, setCurrentTab })}</>;
};

export default BulkActionsDrawerResultsStep;
