import { SelfHostedInstanceContext } from "views/SelfHosted/InstanceProvider/Context";

import useTypedContext from "./useTypedContext";

const useSelfHostedInstanceInfo = () => {
  const { instanceInfo } = useTypedContext(SelfHostedInstanceContext);

  return instanceInfo;
};

export default useSelfHostedInstanceInfo;
