import { Navigate } from "react-router-dom-v5-compat";

import useTitle from "hooks/useTitle";
import useMFA from "hooks/useMFA";
import { isMFASupported } from "utils/mfa";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

const isMFA = isMFASupported();

// RedirectPage is used by backend, if they need to redirect to app based on localStorage "redirectTo" value
const RedirectPage = () => {
  useTitle("Redirecting...");

  const { shouldUseMFA, shouldRegisterSecurityKey } = useMFA();

  const redirectTo = localStorage.getItem("redirectTo") || "/";

  const shouldRedirectToMFA = isMFA && shouldUseMFA;
  const shouldRedirectToEnableMFA = isMFA && shouldRegisterSecurityKey;

  if (shouldRedirectToEnableMFA) {
    return <Navigate to="/enable_mfa" replace />;
  }

  if (shouldRedirectToMFA) {
    return <Navigate to="/mfa" replace />;
  }

  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  return <SpaceliftLoader />;
};

export default RedirectPage;
