export const reorderSingleDNDList = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  if (!list.length) return list;

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return [...result];
};

export const reorderMultipleDNDLists = <T, K extends string = string>(
  lists: Record<K, T[]>,
  startListId: K,
  endListId: K,
  startIndex: number,
  endIndex: number
): Record<K, T[]> => {
  const current = [...lists[startListId]];
  const next = [...lists[endListId]];
  const target = current[startIndex];

  if (startListId === endListId) {
    const reordered = reorderSingleDNDList(current, startIndex, endIndex);
    const result = {
      ...lists,
      [startListId]: reordered,
    };
    return result;
  }

  current.splice(startIndex, 1);
  next.splice(endIndex, 0, target);

  const result = {
    ...lists,
    [startListId]: current,
    [endListId]: next,
  };

  return result;
};
