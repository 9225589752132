import { BadgeVariants } from "ds/components/BadgeNext/types";
import {
  RunExternalDependencyStatus,
  RunPolicyReceipt,
  RunReview,
  RunState,
} from "types/generated";
import { AnalyticsPage } from "hooks/useAnalytics";

export enum RunHistoryEntryType {
  Comment = "comment",
  Queued = "queued",
  Ready = "ready",
  Discarded = "discarded",
  Preparing = "preparing",
  Initializing = "initializing",
  Planning = "planning",
  Unconfirmed = "unconfirmed",
  ReplanRequested = "replanRequested",
  Failed = "failed",
  Confirmed = "confirmed",
  Applying = "applying",
  Finished = "finished",
  Canceled = "canceled",
  Stopped = "stopped",
  Performing = "performing",
  Skipped = "skipped",
  Destroying = "destroying",
  PendingReview = "pendingReview",
  WorkerPool = "workerPool",
  Dependencies = "dependencies",
  ApprovalPolicies = "approvalPolicies",
  PlanPolicies = "planPolicies",
  TriggerPolicies = "triggerPolicies",
  InitializationPolicies = "initializationPolicies",
  NotificationPolicies = "notificationPolicies",
  PushPolicies = "pushPolicies",
}

export type RunShortcut = {
  id: string;
  label: string;
  code: string;
  callback: () => Promise<unknown> | Promise<void> | void;
  loading?: boolean;
  disabled?: boolean;
};

export type RunShortcuts = Array<RunShortcut>;

export enum RunElementType {
  TerminateRunAction = "terminateRunAction",
  Logs = "logs",
  AfterRunHooks = "afterRunHooks",
  ConfirmRunAction = "confirmRunAction",
  ReviewForm = "reviewForm",
  ApprovalPolicies = "approvalPolicies",
  PlanPolicies = "planPolicies",
  TriggerPolicies = "triggerPolicies",
  InitializationPolicies = "initializationPolicies",
  NotificationPolicies = "notificationPolicies",
  PushPolicies = "pushPolicies",
}

export type RunApprovalPoliciesGroup = Array<RunPolicyReceipt | RunReview>;

export enum RunApprovalPoliciesEntryType {
  Policies = "policies",
  Review = "review",
}

type RunApprovalPoliciesEntryPolicies = {
  type: RunApprovalPoliciesEntryType.Policies;
  policies: Array<RunPolicyReceipt>;
};

type RunApprovalPoliciesEntryReview = {
  type: RunApprovalPoliciesEntryType.Review;
  review: RunReview;
};

export type RunApprovalPoliciesEntryList = Array<
  RunApprovalPoliciesEntryPolicies | RunApprovalPoliciesEntryReview
>;

type RunHook = string[] | undefined;

export type RunEntryContext = {
  canManage: boolean;
  isReviewable: boolean;
  runHooks: {
    afterRun: RunHook;
    afterApply: RunHook;
    beforeApply: RunHook;
    afterDestroy: RunHook;
    beforeDestroy: RunHook;
    afterInit: RunHook;
    beforeInit: RunHook;
    afterPerform: RunHook;
    beforePerform: RunHook;
    afterPlan: RunHook;
    beforePlan: RunHook;
  };
  isSimpleView: boolean;
  canConfirm: boolean;
  canDiscard: boolean;
  canPerformAgain: boolean;
  stackId: string;
  runId: string;
  runTaskCommand: string | null;
  isStackDisabled: boolean;
  isModuleRun: boolean;
  canRetry: boolean;
  runRetryBlocker: string | null;
  canStop: boolean;
  isRunFinished: boolean;
  isTaskRun: boolean;
  canPromote: boolean;
  runPromoteBlocker: string | null;
  isProposedRun: boolean;
  runQueryToRefetch: string;
  analyticsPage?: AnalyticsPage;
};

export type RunEntryEvaluationProps = {
  evaluationLabel: string;
  evaluationBadgeVariant: BadgeVariants;
  evaluationTimestamp: number;
};

export type RunDependenciesEntryEvaluationProps = Omit<
  RunEntryEvaluationProps,
  "evaluationBadgeVariant"
> & {
  evaluationState: RunExternalDependencyStatus | RunState;
};

export type RunPolicyEvaluation = {
  label: string;
  badgeVariant: BadgeVariants;
  timestamp: number;
  isImportant: boolean;
};
