import useLocalStorage from "@rehooks/local-storage";
import { useCallback } from "react";

import PageLoading from "components/loading/PageLoading";
import { NoResultsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import useErrorHandle from "hooks/useErrorHandle";
import { AnalyticsPageResources } from "hooks/useAnalytics/pages/resources";
import useAnalytics from "hooks/useAnalytics";

import ConfigManagementFiltersLayout from "./FiltersLayout";
import ConfigManagementTreeGrid from "./TreeGrid";
import {
  DEFAULT_GROUP_BY_MODE,
  DEFAULT_LAYOUT_MODE,
  GROUP_BY_MODE_LOCAL_STORAGE_KEY,
  LAYOUT_MODE_LOCAL_STORAGE_KEY,
} from "./constants";
import styles from "./styles.module.css";
import { LayoutMode } from "./TreeGrid/types";
import ConfigManagementPageLayout from "./PageLayout";
import useSearchAnsibleHosts from "./useSearchAnsibleNodes";
import ConfigManagementEmptyState from "./EmptyState";
import { AnsibleGroupBy } from "./types";

const ConfigManagement = () => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageResources.ConfigurationManagement,
  });

  const [groupBy, setGroupBy] = useLocalStorage<AnsibleGroupBy>(
    GROUP_BY_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_GROUP_BY_MODE
  );

  const handleGroupByChange = useCallback(
    (groupBy: AnsibleGroupBy) => {
      setGroupBy(groupBy);
      trackSegmentAnalyticsEvent("Grouped by changed", { type: groupBy });
    },
    [trackSegmentAnalyticsEvent, setGroupBy]
  );

  const [layoutMode, setLayoutMode] = useLocalStorage<LayoutMode>(
    LAYOUT_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_LAYOUT_MODE
  );

  const handleLayoutModeChange = useCallback(
    (mode: LayoutMode) => {
      setLayoutMode(mode);
      trackSegmentAnalyticsEvent("Layout mode changed", { mode });
    },
    [trackSegmentAnalyticsEvent, setLayoutMode]
  );

  const { isPageEmpty, predicates, nodes, hasNoFilteringResults, isPageLoading, error } =
    useSearchAnsibleHosts(groupBy, layoutMode);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <ConfigManagementPageLayout
      hideSearch={isPageEmpty}
      loading={isPageLoading}
      setLayoutMode={handleLayoutModeChange}
      layoutMode={layoutMode}
      groupBy={groupBy}
      setGroupBy={handleGroupByChange}
    >
      <ConfigManagementFiltersLayout key={groupBy} groupBy={groupBy} predicates={predicates}>
        {isPageLoading && <PageLoading />}

        {hasNoFilteringResults && (
          <EmptyState title="No results" caption="Try different filters." icon={NoResultsColored} />
        )}

        {!!nodes.length && (
          <ConfigManagementTreeGrid
            nodes={nodes}
            chartClassName={styles.chart}
            layoutMode={layoutMode}
            analyticsPage={AnalyticsPageResources.ConfigurationManagement}
          />
        )}

        {isPageEmpty && <ConfigManagementEmptyState />}
      </ConfigManagementFiltersLayout>
    </ConfigManagementPageLayout>
  );
};

export default ConfigManagement;
