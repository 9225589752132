import React, { useRef, useState } from "react";

import InputText from "components/input/InputText";
import Button from "components/button/Button";
import { ConfigInput, ConfigType } from "types/generated";

import styles from "../styles.module.css";

type NewEnvironmentVariableProps = {
  loading: boolean;
  onSave: (element: ConfigInput, reset: () => unknown) => unknown;
};

const NewEnvironmentVariable = ({ loading, onSave }: NewEnvironmentVariableProps) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<HTMLInputElement>(null);

  const [disabled, setDisabled] = useState(true);

  const isDisabled = () => nameRef.current?.value === "";

  const onChange = () => setDisabled(isDisabled);

  const reset = () => {
    if (nameRef.current) nameRef.current.value = "";
    if (valueRef.current) valueRef.current.value = "";
    setDisabled(true);
    nameRef.current?.focus();
  };

  const handleSave = (writeOnly: boolean) => (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    if (nameRef.current?.value && valueRef.current) {
      onSave(
        {
          id: nameRef.current.value,
          value: valueRef.current.value,
          writeOnly,
          type: ConfigType.EnvironmentVariable,
          fileMode: null,
          description: null,
        },
        reset
      );
    }
  };

  return (
    <>
      <InputText
        ref={nameRef}
        className={styles.newConfigInput}
        onChange={onChange}
        placeholder="Name"
      />
      <InputText
        ref={valueRef}
        className={styles.newConfigInput}
        onChange={onChange}
        placeholder="Value"
      />
      <div>
        {loading ? (
          <Button disabled sm2 loading pill>
            Saving
          </Button>
        ) : (
          <>
            <Button onClick={handleSave(false)} disabled={disabled} sm left pill>
              Plain
            </Button>
            <Button onClick={handleSave(true)} disabled={disabled} sm right pill>
              Secret
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default NewEnvironmentVariable;
