import { VcsProvider } from "types/generated";

export type VcsProviderInSpacesGql = {
  providersInSpace: VcsProvider[];
};

export type InternalFormData = {
  workerPoolLabel?: string;
  spaceLabel?: string;
  vcsIntegrationLabel?: string;
};

export type SourceCodeSettingsField = {
  provider?: VcsProvider;
  repository: string;
  vcsIntegrationId?: string;
  namespace: string;
  repositoryURL?: string;
  branch: string;
  projectRoot: string;
};

export enum SourceCodeFields {
  Repository = "repository",
  Branch = "branch",
  ProjectRoot = "projectRoot",
  RepositoryURL = "repositoryURL",
  Provider = "provider",
  Integration = "integration",
}

export enum SourceCodeProjects {
  Stack = "stack",
  Module = "module",
}
