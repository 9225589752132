import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type MetricCardGroupProps = {
  children: React.ReactNode;
} & BoxProps;

const MetricCardGroup = ({ children, ...boxProps }: MetricCardGroupProps) => {
  return (
    <Box
      direction="row"
      gap="large"
      padding="large"
      align="center"
      className={styles.metricGroup}
      fullWidth
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default MetricCardGroup;
