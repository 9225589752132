import { gql } from "@apollo/client";

export const SEARCH_NOTIFICATIONS = gql`
  query SearchStackNotifications($input: SearchInput!) {
    searchNotifications(input: $input) {
      edges {
        cursor
        node {
          id
          title
          body
          dismissed
          severity
          timestamp
          target
          type
          groupingKey
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const DISMISS_NOTIFICATIONS = gql`
  mutation DismissNotifications($ids: [ID!]!) {
    dismissNotifications(ids: $ids) {
      id
      dismissed
    }
  }
`;
