import {
  Blocked,
  Checklist,
  CogThin,
  MenuRuns,
  Pencil,
  Replan,
  Stop,
  ThumbDownEmpty,
  ThumbUpEmpty,
  Tick,
  Trash,
  Visible,
  WarningRounded,
} from "components/icons";
import { StackState } from "types/generated";

import { RunHistoryEntryConfig } from "./types";

export const STACK_STATES: Record<StackState, RunHistoryEntryConfig> = {
  [StackState.Applying]: { icon: MenuRuns, variant: "blue", text: "Applying" },
  [StackState.Confirmed]: { icon: ThumbUpEmpty, variant: "gray", text: "Confirmed" },
  [StackState.Destroying]: { icon: Trash, variant: "blue", text: "Destroying" },
  [StackState.Discarded]: { icon: ThumbDownEmpty, variant: "red", text: "Discarded" },
  [StackState.Failed]: { icon: WarningRounded, variant: "red", text: "Failed" },
  [StackState.Finished]: { icon: Tick, variant: "green", text: "Finished" },
  [StackState.Initializing]: { icon: CogThin, variant: "blue", text: "Initializing" },
  [StackState.None]: { icon: Blocked, variant: "gray", text: "None" },
  [StackState.Planning]: { icon: Pencil, variant: "blue", text: "Planning" },
  [StackState.Preparing]: { icon: Checklist, variant: "blue", text: "Preparing" },
  [StackState.PreparingApply]: { icon: Checklist, variant: "blue", text: "Preparing" },
  [StackState.PreparingReplan]: { icon: Checklist, variant: "blue", text: "Preparing" },
  [StackState.ReplanRequested]: { icon: Replan, variant: "gray", text: "Replan requested" },
  [StackState.Stopped]: { icon: Stop, variant: "red", text: "Stopped" },
  [StackState.Unconfirmed]: { icon: Visible, variant: "yellow", text: "Unconfirmed" },
};
