import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { DriftDetectionIntegrationInput } from "types/generated";
import { StackContext } from "views/Stack/Context";

import { UPDATE_STACK_DRIFT_DETECTION_SCHEDULING } from "./gql";

const useUpdateDriftDetection = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const [stackIntegrationDriftDetectionUpdate, { loading }] = useMutation(
    UPDATE_STACK_DRIFT_DETECTION_SCHEDULING,
    {
      refetchQueries: ["GetStackScheduling"],
      awaitRefetchQueries: true,
      onError,
    }
  );

  const updateDriftDetection = useCallback(
    (input: DriftDetectionIntegrationInput, successCallback?: () => void) => {
      stackIntegrationDriftDetectionUpdate({
        variables: {
          stack: stack.id,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackIntegrationDriftDetectionUpdate) {
            reportSuccess({
              message: `Drift Detection schedule was updated successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stack.id, stackIntegrationDriftDetectionUpdate]
  );

  return {
    updateDriftDetection,
    loading,
  };
};

export default useUpdateDriftDetection;
