import { ConfigElementWithSource } from "types/generated";

import { EnvironmentType } from "../types";
import ConfigElementWrapper from "./ConfigElementWrapper";

type EnvironmentEditConfigMapProps = {
  type: EnvironmentType;
  stackId: string;
  config: ConfigElementWithSource[];
};

const EnvironmentEditConfigMap = ({ config, stackId, type }: EnvironmentEditConfigMapProps) => {
  return (
    <>
      {config.map(({ context, element }) => (
        <ConfigElementWrapper
          key={element.id + element.type}
          context={context}
          element={element}
          stackId={stackId}
          type={type}
        />
      ))}
    </>
  );
};

export default EnvironmentEditConfigMap;
