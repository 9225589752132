import { BillingTierFeature } from "types/generated";
import { FeedbackType } from "ds/components/Feedback/types";

import TierInfo from ".";

type SharedModulesTierInfoProps = {
  type: FeedbackType;
};

const SharedModulesTierInfo = ({ type }: SharedModulesTierInfoProps) => {
  return (
    <TierInfo
      type={type}
      variant="promo"
      feature={BillingTierFeature.PrivateModuleSharing}
      title="Sharing modules is not supported by your plan"
    >
      You can share the module but connection won’t work.
    </TierInfo>
  );
};

export default SharedModulesTierInfo;
