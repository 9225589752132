import Timestamp from "components/time/Timestamp";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { ManagedStateVersion, RunType } from "types/generated";
import { StackContext } from "views/Stack/Context";

type StackStateHistoryListItemRollbackEventTitleProps = {
  item: ManagedStateVersion;
};

const StackStateHistoryListItemRollbackEventTitle = ({
  item,
}: StackStateHistoryListItemRollbackEventTitleProps) => {
  const { stack } = useTypedContext(StackContext);

  const { run, importedTimestamp } = item;

  if (importedTimestamp) {
    return (
      <Typography tag="span" variant="p-body2">
        Rolled back by user {item.rollbackUsername} to imported state{" "}
        {importedTimestamp && (
          <Typography tag="span" variant="p-t6">
            <Timestamp timestamp={importedTimestamp} />
          </Typography>
        )}
      </Typography>
    );
  }

  if (!run) {
    return (
      <Typography tag="span" variant="p-body2">
        Rolled back by user {item.rollbackUsername} to initial state file
      </Typography>
    );
  }

  const hasTaskType = run.type === RunType.Task;

  const getTitle = (smallLink?: boolean) => (
    <>
      Rolled back by {item.rollbackUsername} to state of{" "}
      <Link to={`/stack/${stack.id}/run/${run.id}`} size={!smallLink ? "medium" : "small"}>
        {hasTaskType ? "this task" : "this run"}
      </Link>
    </>
  );

  return (
    <TextEllipsis tooltip={getTitle(true)} tooltipWidthMode="maxWidthSm">
      {(props) => (
        <Typography tag="span" variant="p-body2" {...props}>
          {getTitle()}
        </Typography>
      )}
    </TextEllipsis>
  );
};

export default StackStateHistoryListItemRollbackEventTitle;
