import { Download, LeftRight, Minus, Plus, PlusMinus, Tilde, Trash } from "components/icons";
import Icon from "ds/components/Icon";
import { Delta, EntityChangeType } from "types/generated";

export const runHasChanges = (delta: Delta) => {
  if (
    !!delta.addCount ||
    !!delta?.changeCount ||
    !!delta?.deleteCount ||
    !!delta?.importCount ||
    !!delta?.moveCount ||
    !!delta?.forgetCount
  ) {
    return true;
  }

  return false;
};

export const ansibleRunHasTasks = (delta: Delta) => {
  if (
    !!delta.okCount ||
    !!delta?.changeCount ||
    !!delta?.ignoreCount ||
    !!delta?.rescueCount ||
    !!delta?.unreachableCount ||
    !!delta?.failedCount ||
    !!delta?.skipCount
  ) {
    return true;
  }

  return false;
};

export const iconByType = (type: EntityChangeType | undefined) => {
  switch (type) {
    case EntityChangeType.Add:
      return <Icon src={Plus} />;
    case EntityChangeType.Change:
      return <Icon src={Tilde} />;
    case EntityChangeType.Delete:
      return <Icon src={Minus} />;
    case EntityChangeType.ReplaceCreateBeforeDestroy:
    case EntityChangeType.ReplaceDestroyBeforeCreate:
      return <Icon src={PlusMinus} />;
    case EntityChangeType.Import:
      return <Icon src={Download} />;
    case EntityChangeType.Move:
      return <Icon src={LeftRight} />;
    case EntityChangeType.Forget:
      return <Icon src={Trash} />;
    default: {
      return undefined;
    }
  }
};

export const getChangeType = ({ moved, type }: { moved: boolean; type: EntityChangeType }) =>
  moved ? EntityChangeType.Move : type;
