import { useState, useEffect, MouseEvent } from "react";
import { Base64 } from "js-base64";

import CodeEditor from "components/CodeEditor";
import Button from "components/button/Button";
import { Spinner } from "components/icons";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { toBlob } from "../helpers";
import styles from "../styles.module.css";

type EditFileMountProps = {
  relativePath: string;
  loading: boolean;
  onCancel: () => void;
  onSave: (value: string) => void;
  value: string;
};

const EditFileMount = ({ relativePath, loading, onCancel, onSave, value }: EditFileMountProps) => {
  const { onError } = useTypedContext(FlashContext);
  const [editorBody, setEditorBody] = useState<string>("");
  const [showInline, setShowInline] = useState(false);
  const [isEditorLoading, setLoading] = useState(false);

  const save = (evt: MouseEvent) => {
    evt.preventDefault();
    const fileValue = showInline ? Base64.encode(editorBody) : value;
    onSave(fileValue);
  };

  const handleEditorChange = (value?: string) => {
    setEditorBody(value || "");
  };

  const handlePreview = async () => {
    if (value) {
      const preview = toBlob(value);

      if (preview.size > 524288) {
        setShowInline(false);
      }

      setLoading(true);

      try {
        const result = await preview.text();
        setShowInline(true);
        setEditorBody(result);
      } catch (e) {
        onError(e);
      } finally {
        setLoading(false);
      }
    } else {
      setEditorBody("");
      setShowInline(true);
    }
  };

  useEffect(() => {
    handlePreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.variable}>
      <div className={styles.variableName}>
        <span className={styles.variableText}>/mnt/workspace/</span>
        {relativePath}
      </div>
      <div>
        {loading ? (
          <Button disabled sm2 loading pill>
            Saving
          </Button>
        ) : (
          <>
            <Button
              onClick={save}
              disabled={value === null && editorBody === undefined}
              sm
              pill
              left
            >
              Save
            </Button>
            <Button onClick={onCancel} sm pill right danger>
              Cancel
            </Button>
          </>
        )}
      </div>
      {isEditorLoading && <Spinner className={styles.variablePreviewSpinner} />}
      {showInline && !isEditorLoading && (
        <CodeEditor
          className={styles.variablePreviewEditor}
          body={editorBody}
          onChange={handleEditorChange}
          language="rego"
        />
      )}
    </div>
  );
};

export default EditFileMount;
