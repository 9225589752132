import React from "react";
import cx from "classnames";

import ListBoxLink from "./Link";
import { ListBoxItemLink } from "./types";
import styles from "./styles.module.css";

type ListBoxItemProps = {
  icon?: React.ElementType;
  link?: ListBoxItemLink;
  className?: string;
  children?: React.ReactNode;
  iconClassName?: string;
};

const ListBoxItem = (props: ListBoxItemProps) => {
  const { link, icon: Icon, children, className } = props;

  return (
    <span className={cx(styles.listBoxItem, className)}>
      {Icon && <Icon className={cx(styles.listBoxIcon, props.iconClassName)} />}
      {link && <ListBoxLink {...link} />}

      {children}
    </span>
  );
};

export default ListBoxItem;
