import useTypedContext from "hooks/useTypedContext";

import ModuleSettingsPageLayout from "../components/PageLayout";
import { ModuleSettingsContext } from "../Context";
import ModuleSettingsBehaviorViewForm from "./ViewForm";
import ModuleSettingsBehaviorEditForm from "./EditForm";
import ModuleSettingsFormWrapper from "../components/FormWrapper";

const ModuleSettingsBehavior = () => {
  const { canManageModule } = useTypedContext(ModuleSettingsContext);

  return (
    <ModuleSettingsPageLayout title="Behavior" description="Define additional module settings.">
      <ModuleSettingsFormWrapper>
        {canManageModule ? <ModuleSettingsBehaviorEditForm /> : <ModuleSettingsBehaviorViewForm />}
      </ModuleSettingsFormWrapper>
    </ModuleSettingsPageLayout>
  );
};

export default ModuleSettingsBehavior;
