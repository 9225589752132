import React, { useCallback } from "react";
import mitt from "mitt";
import { ApolloError } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { FlashEvent } from "types/FlashMessage";
import { ErrorType } from "types/Error";
import { shouldShowAccountError, shouldShowUnauthorizedError } from "hooks/useErrorHandle/helpers";
import { isSaasDistribution } from "utils/distribution";
import { ToastVariant } from "ds/components/ToastNew";
import useNavigationDefaultView from "hooks/useNavigationDefaultView";

import { FlashMessageProps } from "./types";
import { getMessageWithExtensions } from "./helpers";

const isSaas = isSaasDistribution();

type FlashContextType = {
  onError: (error: ErrorType) => void;
  reportError: (props: FlashMessageProps) => void;
  reportInfo: (props: FlashMessageProps) => void;
  reportSuccess: (props: FlashMessageProps) => void;
  subscribe: (callback: (props: FlashEvent) => void) => void;
  unsubscribe: (callback: (props: FlashEvent) => void) => void;
};

const FlashContext = React.createContext<FlashContextType | undefined>(undefined);
FlashContext.displayName = "FlashContext";

type FlashContextProviderProps = {
  children: React.ReactNode;
};

export const FlashContextProvider = (props: FlashContextProviderProps) => {
  const { children } = props;

  const defaultView = useNavigationDefaultView();

  const location = useLocation();
  const navigate = useNavigate();

  const [emitter] = React.useState(() => mitt<Record<string, FlashEvent>>());
  const eventName = "flash-message";

  const addEvent = (eventProps: FlashEvent) => emitter.emit(eventName, eventProps);

  const actionCallback = () => navigate("/");

  const subscribe = useCallback(
    (callback: (props: FlashEvent) => void) => emitter.on(eventName, callback),
    [emitter]
  );

  const unsubscribe = useCallback(
    (callback: (props: FlashEvent) => void) => emitter.off(eventName, callback),
    [emitter]
  );

  const value: FlashContextType = {
    onError: (error) => {
      const showAccountError = shouldShowAccountError(error);
      const showUnauthorizedError = shouldShowUnauthorizedError(error);

      if (showAccountError || showUnauthorizedError) {
        return false;
      }

      if (error instanceof ApolloError && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(({ message, extensions }) =>
          addEvent({
            title: "API error",
            message: getMessageWithExtensions(message, extensions),
            variant: ToastVariant.Error,
          })
        );
      } else if (error instanceof Error && !window.navigator.onLine) {
        addEvent({
          title: "Unable to connect",
          message: "Please check your internet connection.",
          variant: ToastVariant.Error,
        });
      } else if (error instanceof Error && error.message === "Failed to fetch") {
        // do nothing
        // this is a network error, but we don't want to show it to the user
        // because it's not really an error
        // in most cases it's network resetting in a sleep mode
      } else if (
        error instanceof ApolloError &&
        error.message === "Response not successful: Received status code 429"
      ) {
        addEvent({
          title: "Rate limit exceeded",
          message: "The rate limit capacity has been exceeded",
          variant: ToastVariant.Error,
          actionCallback,
          actionTitle: "Home page",
        });
      } else {
        // TODO consider different behaviour as we don't have an access to acount on this level to verify some views
        if (location.pathname === defaultView.config.to) {
          const message = isSaas
            ? "We had a problem processing your request. We've been notified and will look into the underlying issue shortly."
            : "We had a problem processing your request.";

          addEvent({
            title: "Sorry, we had a problem",
            message: message,
            variant: ToastVariant.Error,
          });
        } else {
          const message = isSaas
            ? "We had a problem processing your request. We've been notified and will look into the underlying issue shortly. For now, try going back to the Home page."
            : "We had a problem processing your request. For now, try going back to the Home page.";

          addEvent({
            title: "Sorry, we had a problem",
            message: message,
            actionCallback,
            actionTitle: "Home page",
            variant: ToastVariant.Error,
          });
        }
      }

      return undefined;
    },
    reportError: ({ title = "Error", ...restProps }) =>
      addEvent({ ...restProps, title, variant: ToastVariant.Error }),
    reportInfo: (props) => addEvent({ title: "Info", variant: ToastVariant.Info, ...props }),
    reportSuccess: ({ title = "Success", ...restProps }) =>
      addEvent({
        ...restProps,
        title,
        variant: ToastVariant.Positive,
      }),
    subscribe,
    unsubscribe,
  };

  return <FlashContext.Provider value={value}>{children}</FlashContext.Provider>;
};

export default FlashContext;
