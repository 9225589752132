import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

const GET_POLICY_NAMES = gql`
  query GetPolicyNames {
    policies {
      name
    }
  }
`;

const useTakenPolicyNames = () => {
  const { onError } = useTypedContext(FlashContext);

  const { data: policyNamesData } = useQuery<{
    policies: Array<{ name: string }>;
  }>(GET_POLICY_NAMES, {
    onError,
  });

  const takenPolicyNames = useMemo(() => {
    return policyNamesData?.policies.map((policy) => policy.name.toLowerCase()) || [];
  }, [policyNamesData]);

  return takenPolicyNames;
};

export default useTakenPolicyNames;
