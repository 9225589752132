import { memo, useCallback } from "react";

import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { showVCSDeleteIntegrationModal } from "views/Account/VCS/components/DeleteIntegrationModal";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type VCSIntegrationsListItemDropdownActionsProps = {
  id: string;
  name: string;
  totalUsedByEntitiesQty: number;
  onShowDetails: () => void;
  onIntegrationDelete: () => void;
  onTestConnection?: () => void;
  onEdit: () => void;
  isTestingConnection?: boolean;
  canManageVCSIntegrations: boolean;
  usedByPath: string;
};

const VCSIntegrationsListItemDropdownActions = ({
  id,
  name,
  totalUsedByEntitiesQty,
  onShowDetails,
  onIntegrationDelete,
  onTestConnection,
  onEdit,
  isTestingConnection,
  canManageVCSIntegrations,
  usedByPath,
}: VCSIntegrationsListItemDropdownActionsProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const onDeleteConfirmationModalShow = useCallback(() => {
    showVCSDeleteIntegrationModal({
      attachedEntitiesQty: totalUsedByEntitiesQty,
      integrationName: name,
      linkPath: usedByPath,
      onConfirm: onIntegrationDelete,
      onClose: () => trackSegmentAnalyticsEvent("Integration - Unlink Exit"),
    });

    trackSegmentAnalyticsEvent("Integration - Unlink Start");
  }, [name, onIntegrationDelete, totalUsedByEntitiesQty, trackSegmentAnalyticsEvent, usedByPath]);

  return (
    <DropdownMenuEllipsis tooltip="Integration actions">
      {id && <CopyFieldDropdownMenuItem title="Copy ID" value={id} />}

      <DropdownMenuItem onAction={onShowDetails}>See details</DropdownMenuItem>
      {canManageVCSIntegrations && (
        <>
          {onTestConnection && (
            <DropdownMenuItem onAction={onTestConnection} loading={isTestingConnection}>
              Test
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onAction={onEdit}>Edit</DropdownMenuItem>
          <DropdownMenuItem onAction={onDeleteConfirmationModalShow} danger>
            Delete
          </DropdownMenuItem>
        </>
      )}
    </DropdownMenuEllipsis>
  );
};

export default memo(VCSIntegrationsListItemDropdownActions);
