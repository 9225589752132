import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import useGetAttachablePolicies from "shared/Policy/useGetAttachablePolicies";
import { PolicyType } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Feedback from "ds/components/Feedback";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useAttachPolicy from "shared/Policy/useAttachPolicy";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { GET_POLICIES_REFETCH_QUERIES } from "./constants";

type AttachPolicyFormFields = {
  policyType: PolicyType;
  policy: string;
};

type PoliciesAttachDrawerProps = {
  alreadyAttachedPolicies: Set<string>;
  entityId: string;
  spaceId: string;
  visible: boolean;
  onClose: () => void;
};

const PoliciesAttachDrawer = ({
  alreadyAttachedPolicies,
  entityId,
  spaceId,
  visible,
  onClose,
}: PoliciesAttachDrawerProps) => {
  const attachPolicyForm = useForm<AttachPolicyFormFields>({
    mode: "onChange",
    defaultValues: {
      policyType: undefined,
      policy: undefined,
    },
  });

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
    reset,
  } = attachPolicyForm;

  const policyType = watch("policyType");

  const { policiesOptions, policyTypesOptions, loading } = useGetAttachablePolicies({
    spaceId,
    alreadyAttachedPolicies,
    skip: !visible,
  });

  const { attachPolicy, loading: isAttaching } = useAttachPolicy(
    "module",
    GET_POLICIES_REFETCH_QUERIES
  );

  const attachablePolicyOptions = useMemo(
    () => policiesOptions.filter((policy) => policy.type === policyType),
    [policiesOptions, policyType]
  );

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleAttach = (formData: AttachPolicyFormFields) => {
    attachPolicy(formData.policy, entityId, handleClose);
  };

  const policyTypesTooltip = (
    <>
      <TooltipModalTitle>Policy type</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Spacelift uses an open-source project called{" "}
          <TextLink href="https://www.openpolicyagent.org/">
            <Typography tag="span" variant="p-body3">
              Open Policy Agent
            </Typography>
          </TextLink>{" "}
          and its rule language,{" "}
          <TextLink href="https://www.openpolicyagent.org/docs/latest/policy-language/">
            <Typography tag="span" variant="p-body3">
              Rego
            </Typography>
          </TextLink>
          , to execute user-defined pieces of code we call Policies at various decision points.
          Policies come in different flavors that we call types, with each type being executed at a
          different decision point.
        </Typography>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/policy")} />
      </TooltipModalBody>
    </>
  );

  return (
    <Drawer visible={visible}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Attach policy" />
        <DrawerCloseIcon handleCloseDrawer={handleClose} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Feedback type="banner" variant="info">
          You can only attach policies from the current space and parent spaces that you inherit
          from.
        </Feedback>

        <Controller
          name="policyType"
          control={control}
          rules={{ required: "Policy type is required." }}
          render={({ field, fieldState }) => (
            <FormField
              label="Policy type"
              error={fieldState.error?.message}
              tooltipInfoVariant="modal"
              tooltipInfo={policyTypesTooltip}
            >
              {({ ariaInputProps }) => (
                <Select
                  autocomplete
                  value={field.value}
                  options={policyTypesOptions}
                  onChange={field.onChange}
                  loading={loading}
                  error={!!fieldState.error?.message}
                  ariaInputProps={ariaInputProps}
                />
              )}
            </FormField>
          )}
        />

        <Controller
          name="policy"
          control={control}
          rules={{ required: "Policy is required." }}
          render={({ field, fieldState }) => (
            <FormField label="Select policy" error={fieldState.error?.message}>
              {({ ariaInputProps }) => (
                <Select
                  autocomplete
                  value={field.value}
                  options={attachablePolicyOptions}
                  onChange={field.onChange}
                  loading={loading}
                  disabled={!policyType}
                  error={!!fieldState.error?.message}
                  ariaInputProps={ariaInputProps}
                />
              )}
            </FormField>
          )}
        />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleClose} disabled={isAttaching}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(handleAttach)}
              loading={isAttaching}
              disabled={!isValid || isAttaching}
            >
              Attach
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default PoliciesAttachDrawer;
