import { useQuery, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import { MenuPolicies, Profile } from "components/icons";
import PageLoading from "components/loading/PageLoading";
import PageWrapper from "components/PageWrapper";
import { AuthorizationScheme } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { humanizeAuthorizationSchemeName } from "./helpers";
import { GET_AUTHORIZATION_SCHEME, UPDATE_ACCOUNT_AUTH_SCHEME } from "./gql";
import ManagementStrategyItem from "./Item";
import { ManagementStrategy as ManagementStrategyType } from "../types";

type GetAuthorizationSchemeGql = {
  authorizationScheme: AuthorizationScheme;
};

const ManagementStrategy = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery<GetAuthorizationSchemeGql>(GET_AUTHORIZATION_SCHEME, {
    onError,
  });

  const [updateAccountAuthScheme, { loading: changingScheme }] = useMutation(
    UPDATE_ACCOUNT_AUTH_SCHEME,
    {
      refetchQueries: ["GetAuthorizationScheme"],
    }
  );

  useTitle(`Organization Settings · Management strategy · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationManagementStrategy,
  });

  const ErrorContent = useErrorHandle(error);

  const handleChange = (schema: AuthorizationScheme) => {
    updateAccountAuthScheme({ variables: { schema } })
      .then(({ data: { accountUpdateAuthorizationScheme } }) => {
        reportSuccess({
          message: `Authorization scheme was changed to ${humanizeAuthorizationSchemeName(
            accountUpdateAuthorizationScheme
          )}`,
        });
      })
      .catch(onError);
  };

  const getConfirmationCallback =
    (managementStrategy: ManagementStrategyType, authorizationScheme: AuthorizationScheme) =>
    () => {
      trackSegmentAnalyticsEvent("Select", {
        managementStrategy,
      });
      handleChange(authorizationScheme);
    };

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading || !data?.authorizationScheme || changingScheme) {
    return <PageLoading />;
  }

  const activeScheme = data.authorizationScheme;

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">
          <ViewHeaderWrapper direction="row" align="center" __deprecatedGap="1.1rem">
            Management strategy
          </ViewHeaderWrapper>
        </ViewHeaderTitle>
      </ViewHeader>
      <PageWrapper>
        <Box gap="large">
          <ManagementStrategyItem
            confirmationCallback={getConfirmationCallback(
              ManagementStrategyType.LOGIN_POLICY,
              AuthorizationScheme.LoginPolicies
            )}
            confirmationDescription={
              <>
                After enabling login policy feature, all active sessions (except the current one)
                will be invalidated.
                <br />
                <br />
                You will be still able to edit and activate user management again.
              </>
            }
            active={activeScheme === AuthorizationScheme.LoginPolicies}
            title="Login policy"
            icon={MenuPolicies}
          >
            Use login policies for full control over your login process. This allows you to support
            very advanced use-cases.
          </ManagementStrategyItem>
          <ManagementStrategyItem
            confirmationCallback={getConfirmationCallback(
              ManagementStrategyType.USER_MANAGEMENT,
              AuthorizationScheme.ManagedUsers
            )}
            confirmationDescription={
              <>
                After enabling the user management feature, all active sessions (except the current
                one) will be invalidated. Only API Keys with admin access will continue to work.
                <br />
                <br />
                You will be still able to edit and re-activate your existing login policy.
              </>
            }
            active={activeScheme === AuthorizationScheme.ManagedUsers}
            title="User management"
            icon={Profile}
          >
            Use user management for simpler use-cases. This is a great option for getting started
            quickly, and you have the option of switching to login policies later if required.
          </ManagementStrategyItem>
        </Box>
      </PageWrapper>
    </>
  );
};

export default ManagementStrategy;
