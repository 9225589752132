import { ToastState } from "@react-stately/toast";

import { ToastVariant } from "ds/components/ToastNew";
import { FlashEvent } from "types/FlashMessage";

const DEFAULT_TIMEOUT = 5000;

export const getMessagePriority = (flashEvent: FlashEvent, state: ToastState<FlashEvent>) => {
  if (flashEvent.variant === ToastVariant.Error) {
    return Infinity;
  }

  const nonErrorToasts = state.visibleToasts.filter(
    (toast) => toast.content.variant !== ToastVariant.Error
  );

  const lastItemPriority = nonErrorToasts[nonErrorToasts.length - 1]?.priority || 0;

  return lastItemPriority - 1;
};

export const getMessageTimeout = (
  flashEvent: FlashEvent,
  defaultTimeout: number = DEFAULT_TIMEOUT
) => {
  // if a timeout is explicitly set, use that
  if (flashEvent.timeout !== undefined) {
    return flashEvent.timeout;
  }

  // If has action or error, there should be no timeout
  if (
    (flashEvent.actionCallback && flashEvent.actionTitle) ||
    flashEvent.variant === ToastVariant.Error
  ) {
    return undefined;
  }

  return defaultTimeout;
};
