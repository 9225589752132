import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type ColProps = {
  className?: string;
  auto?: boolean;
  size?: number;
  children: React.ReactNode;
};

const Col = (props: ColProps) => {
  const { children, className, auto, ...otherProps } = props;

  return (
    <div
      className={cx(styles.col, className, {
        [styles.colAuto]: auto,
      })}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Col;
