import { RunState, RunType } from "types/generated";

import { ChartColor } from "../Chart/types";

export const DEFAULT_COLOR = {
  primary: "var(--chart-non-semantic-bar-1st-segment-rest-background-color)",
  inactive: "var(--chart-non-semantic-bar-1st-segment-inactive-background-color)",
};

// FYI this map contains only run states that are presented in the UI
export const RUN_STATE_COLOR_MAP: Partial<Record<RunState, ChartColor>> = {
  [RunState.Queued]: {
    primary: "var(--chart-non-semantic-bar-1st-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-1st-segment-inactive-background-color)",
  },
  [RunState.Initializing]: {
    primary: "var(--chart-non-semantic-bar-2nd-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-2nd-segment-inactive-background-color)",
  },
  [RunState.Planning]: {
    primary: "var(--chart-non-semantic-bar-3rd-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-3rd-segment-inactive-background-color)",
  },
  [RunState.Preparing]: {
    primary: "var(--chart-non-semantic-bar-4th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-4th-segment-inactive-background-color)",
  },
  [RunState.Ready]: {
    primary: "var(--chart-non-semantic-bar-5th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-5th-segment-inactive-background-color)",
  },
  [RunState.Unconfirmed]: {
    primary: "var(--chart-non-semantic-bar-6th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-6th-segment-inactive-background-color)",
  },
  [RunState.Performing]: {
    primary: "var(--chart-non-semantic-bar-7th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-7th-segment-inactive-background-color)",
  },
  [RunState.ReplanRequested]: {
    primary: "var(--chart-non-semantic-bar-8th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-8th-segment-inactive-background-color)",
  },
  [RunState.PreparingReplan]: {
    primary: "var(--chart-non-semantic-bar-9th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-9th-segment-inactive-background-color)",
  },
  [RunState.Confirmed]: {
    primary: "var(--chart-non-semantic-bar-10th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-10th-segment-inactive-background-color)",
  },
  [RunState.Applying]: {
    primary: "var(--chart-non-semantic-bar-11th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-11th-segment-inactive-background-color)",
  },
  [RunState.PreparingApply]: {
    primary: "var(--chart-non-semantic-bar-12th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-12th-segment-inactive-background-color)",
  },
  [RunState.Destroying]: {
    primary: "var(--chart-non-semantic-bar-13th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-13th-segment-inactive-background-color)",
  },
  [RunState.PendingReview]: {
    primary: "var(--chart-non-semantic-bar-14th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-14th-segment-inactive-background-color)",
  },
};

export const RUN_TYPE_COLOR_MAP: Record<RunType, ChartColor> = {
  [RunType.Proposed]: {
    primary: "var(--chart-non-semantic-bar-1st-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-1st-segment-inactive-background-color)",
  },
  [RunType.Tracked]: {
    primary: "var(--chart-non-semantic-bar-2nd-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-2nd-segment-inactive-background-color)",
  },
  [RunType.Task]: {
    primary: "var(--chart-non-semantic-bar-3rd-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-3rd-segment-inactive-background-color)",
  },
  [RunType.Testing]: {
    primary: "var(--chart-non-semantic-bar-4th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-4th-segment-inactive-background-color)",
  },
  [RunType.Destroy]: {
    primary: "var(--chart-non-semantic-bar-5th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-5th-segment-inactive-background-color)",
  },
  [RunType.Parse]: {
    primary: "var(--chart-non-semantic-bar-6th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-6th-segment-inactive-background-color)",
  },
  [RunType.Unknown]: {
    primary: "var(--chart-non-semantic-bar-7th-segment-rest-background-color)",
    inactive: "var(--chart-non-semantic-bar-7th-segment-inactive-background-color)",
  },
};

export const URL_GROUP_BY_KEY = "groupBy";
