import moment from "moment";

import { DriftDetectionIntegration, DriftDetectionIntegrationInput } from "types/generated";

import { StackSchedulingDriftDetectionFormFields } from "./types";

export const getFormDefaultValues = (integration?: DriftDetectionIntegration) => ({
  cronSchedule: integration?.schedule || ["*/15 * * * *"],
  timezone: integration?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  reconcile: integration?.reconcile || false,
  ignoreState: integration?.ignoreState || false,
});

export const mapCreateFormData = (
  values: StackSchedulingDriftDetectionFormFields
): DriftDetectionIntegrationInput => ({
  schedule: values.cronSchedule,
  timezone: values.timezone,
  reconcile: values.reconcile,
  ignoreState: values.ignoreState,
});

export const makeDriftDetectionRunsLink = (stackId: string) => {
  const values = [[{ value: stackId, label: stackId }], [{ value: "true", label: "true" }]];
  const fields = ["stack.id", "driftDetection"];

  const nowUnix = moment().unix();
  const startDate = moment.unix(nowUnix).subtract(3, "days").unix();
  const endDate = moment.unix(nowUnix).add(3, "days").unix();

  const dateRange = `${btoa(JSON.stringify({ startDate, endDate, label: "" }))}`;

  return `/runs?filterValues=${btoa(
    encodeURIComponent(JSON.stringify(values))
  )}&filterFields=${btoa(JSON.stringify(fields))}&dateRange=${dateRange}`;
};
