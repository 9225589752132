import { FunctionComponent } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import { StackAwsIntegrationAttachment, StackAzureIntegrationAttachment } from "types/generated";
import { Loading, Trash } from "components/icons";
import IconTile from "ds/components/IconTile";
import KeyValue from "components/KeyValue";

import { StackCreationCloud } from "../../types";
import NewStackAttachCloudDetailsAWSFields from "./AWSFields";
import NewStackAttachCloudDetailsAzureFields from "./AzureFields";
import useDetachCloud from "../hooks/useDetachCloud";
import NewStackAttachCloudDetailsSwitchIcon from "./SwitchIcon";

type NewStackAttachCloudDetailsProps = (
  | {
      attachedIntegration: StackAwsIntegrationAttachment;
      attachedIntegrationType: StackCreationCloud.AWS;
    }
  | {
      attachedIntegration: StackAzureIntegrationAttachment;
      attachedIntegrationType: StackCreationCloud.Azure;
    }
) & {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: StackCreationCloud;
  }>;
};

const NewStackAttachCloudDetails = ({
  attachedIntegration,
  attachedIntegrationType,
  cloudConfig,
}: NewStackAttachCloudDetailsProps) => {
  const { detach, detachLoading } = useDetachCloud();

  const handleDettach = () => {
    const attachmentId = attachedIntegration.id;

    if (attachedIntegrationType && attachmentId) {
      detach(attachedIntegrationType, { attachmentId });
    }
  };

  const config = cloudConfig.find(({ type }) => type === attachedIntegrationType);

  if (!config) {
    return null;
  }

  const Logo = config.logo;

  return (
    <CardWrapper variant="outlined" direction="column" margin="large 0">
      <Box justify="between" align="center" margin="0 0 large 0">
        <Box align="center" gap="medium">
          <IconTile icon={Logo} />
          <Typography tag="p" variant="p-t5">
            {config.name} integration
          </Typography>
        </Box>
        <ButtonIcon
          disabled={detachLoading}
          icon={detachLoading ? Loading : Trash}
          onClick={handleDettach}
          variant={detachLoading ? "ghost" : "ghostDanger"}
        >
          Detach
        </ButtonIcon>
      </Box>
      {attachedIntegrationType === StackCreationCloud.AWS && (
        <NewStackAttachCloudDetailsAWSFields integration={attachedIntegration} />
      )}

      {attachedIntegrationType === StackCreationCloud.Azure && (
        <NewStackAttachCloudDetailsAzureFields integration={attachedIntegration} />
      )}

      <KeyValue align="center" name="Read">
        <NewStackAttachCloudDetailsSwitchIcon checked={attachedIntegration.read} />
      </KeyValue>
      <KeyValue align="center" name="Write">
        <NewStackAttachCloudDetailsSwitchIcon checked={attachedIntegration.write} />
      </KeyValue>
    </CardWrapper>
  );
};

export default NewStackAttachCloudDetails;
