import { gql } from "@apollo/client";

export const CREATE_BITBUCKET_CLOUD_INTEGRATION = gql`
  mutation CreateVCSBitbucketCloudIntegration(
    $appPassword: String!
    $customInput: CustomVCSInput!
    $username: String!
  ) {
    bitbucketCloudIntegrationCreate(
      appPassword: $appPassword
      customInput: $customInput
      username: $username
    ) {
      id
      name
    }
  }
`;

export const UPDATE_BITBUCKET_CLOUD_INTEGRATION = gql`
  mutation UpdateVCSBitbucketCloudIntegration(
    $appPassword: String
    $customInput: CustomVCSUpdateInput
    $username: String!
  ) {
    bitbucketCloudIntegrationUpdate(
      appPassword: $appPassword
      customInput: $customInput
      username: $username
    ) {
      id
      name
    }
  }
`;

export const DELETE_BITBUCKET_CLOUD_INTEGRATION = gql`
  mutation DeleteVCSBitbucketCloudIntegration($id: ID!) {
    bitbucketCloudIntegrationDelete(id: $id) {
      id
    }
  }
`;

export const CHECK_BITBUCKET_CLOUD_HAS_DEFAULT_INTEGRATION = gql`
  query CheckVCSBitbucketCloudHasDefaultIntegration {
    checkBitbucketCloudHasDefaultIntegration: bitbucketCloudIntegration {
      id
    }
  }
`;
