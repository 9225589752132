export const prepareName = (type: string) => {
  switch (type) {
    case "PREPARING_APPLY":
    case "PREPARING_REPLAN":
      return "PREPARING";
    case "REPLAN_REQUESTED":
      return "REPLAN REQUESTED";
    case "PENDING_REVIEW":
      return "PENDING REVIEW";
    default:
      return type;
  }
};
