import { Children } from "react";

import Box from "ds/components/Box";

type TileCheckboxGridProps = {
  children: React.ReactNode;
};

const TileCheckboxGrid = ({ children }: TileCheckboxGridProps) => {
  const childrenCount = Children.count(children);
  const isSingle = childrenCount === 1;
  const twoColumn = childrenCount === 2 || childrenCount === 4;
  let gridTemplate = "repeat(3, 1fr)";

  if (isSingle) {
    gridTemplate = "1fr";
  }

  if (twoColumn) {
    gridTemplate = "repeat(2, 1fr)";
  }

  return (
    <Box grid gridTemplate={gridTemplate} gap="large">
      {children}
    </Box>
  );
};
export default TileCheckboxGrid;
