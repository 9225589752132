import { memo } from "react";

import Drawer from "ds/components/Drawer";

import LoginPoliciesInfoDrawerContent from "./Content";

type LoginPoliciesInfoDrawerProps = {
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
};

const LoginPoliciesInfoDrawer = ({
  isDrawerVisible,
  handleCloseDrawer,
}: LoginPoliciesInfoDrawerProps) => {
  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer}>
      <LoginPoliciesInfoDrawerContent handleCloseDrawer={handleCloseDrawer} />
    </Drawer>
  );
};

export default memo(LoginPoliciesInfoDrawer);
