import EmptyState from "ds/components/EmptyState";
import { WorkerPoolsColored } from "components/icons";
import Link from "ds/components/Link";
import { getDocsUrl } from "utils/getDocsUrl";

const PrivateWorkerPoolWorkersEmpty = () => {
  return (
    <EmptyState
      title="No workers found"
      icon={WorkerPoolsColored}
      caption={
        <>
          No workers in this worker pool are currently connected to our message broker.
          <br />
          <Link
            size="small"
            href={getDocsUrl("/concepts/worker-pools#setting-up")}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn how to set up workers.
          </Link>
        </>
      }
    />
  );
};

export default PrivateWorkerPoolWorkersEmpty;
