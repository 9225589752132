import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type ListBoxInfoWrapperProps = {
  className?: string;
  children: React.ReactNode;
};

const ListBoxInfoWrapper = (props: ListBoxInfoWrapperProps) => (
  <div className={cx(styles.listBoxInfo, props.className)}>{props.children}</div>
);

export default ListBoxInfoWrapper;
