import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type IdpGroupMappingListConfirmationModalProps = {
  onConfirm: () => void;
  name: string;
};

const IdpGroupMappingListConfirmationModal = create(function IdpGroupMappingListConfirmationModal({
  onConfirm,
  name,
}: IdpGroupMappingListConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    modal.resolve();
    modal.hide();
    onConfirm();
  };

  return (
    <ConfirmationModal
      title="Delete group access"
      confirmationButtonLabel="Delete"
      confirmationButtonVariant="dangerPrimary"
      onConfirm={handleOnConfirm}
      size="large"
    >
      <Typography variant="p-body2" tag="p">
        Are you sure you want to delete {name} group access?
      </Typography>
    </ConfirmationModal>
  );
});

export const showIdpGroupMappingListConfirmationModal = (
  props: IdpGroupMappingListConfirmationModalProps
) => show(IdpGroupMappingListConfirmationModal, props);
