import Heading from "../Typography/Heading";

type DrawerHeaderTitleProps = {
  title: string;
};

const DrawerHeaderTitle = ({ title }: DrawerHeaderTitleProps) => {
  return (
    <Heading variant="p-t4" slot="title">
      {title}
    </Heading>
  );
};

export default DrawerHeaderTitle;
