import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import { BillingInfo, Maybe, SubscriptionInput } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import FormContent from "../Form";

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscriptionInfo($input: SubscriptionInput!) {
    billingSubscriptionUpdateInfo(input: $input) {
      billingInfo {
        address
        city
        companyName
        country
        email
        fullName
        state
        zipCode
      }
    }
  }
`;

type BillingInvoiceFormWrapperProps = {
  billingInfo: Maybe<BillingInfo>;
};

const BillingInvoiceFormWrapper = ({ billingInfo }: BillingInvoiceFormWrapperProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [updateSubscription, { loading }] = useMutation(UPDATE_SUBSCRIPTION);
  const [shouldRedirect, setShouldRedirect] = useState<boolean | null>(null);

  const sendData = (input: SubscriptionInput) => {
    updateSubscription({ variables: { input } })
      .then(() => {
        setShouldRedirect(true);
        reportSuccess({ message: "Invoice details were updated" });
      })
      .catch(onError);
  };

  if (shouldRedirect) {
    return <Navigate to="/settings/billing" />;
  }

  return <FormContent handleSubmit={sendData} loading={loading} {...billingInfo} />;
};

export default BillingInvoiceFormWrapper;
