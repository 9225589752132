const populateLogicalTimestampsForTimeline = (
  logicalTimestamps: Record<string, number>,
  sortedEdges: string[],
  edgesMap: Record<string, string[]>
) => {
  const result = { ...logicalTimestamps };

  for (const source of sortedEdges) {
    const sourceTimestamp = result[source];
    if (edgesMap[source]) {
      for (const successor of edgesMap[source]) {
        if (result[successor] < sourceTimestamp + 1) {
          result[successor] = sourceTimestamp + 1;
        }
      }
    }
  }

  return result;
};

export default populateLogicalTimestampsForTimeline;
