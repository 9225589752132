import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import Typography from "ds/components/Typography";

const PersonalSettingsGroupsSortHeader = () => {
  return (
    <FiltersSortHeaderWrapper>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Group
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default PersonalSettingsGroupsSortHeader;
