import { useCallback, Dispatch } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { VcsProvider } from "types/generated";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { SelectOption } from "ds/components/Select/types";
import useQueryAttachableVCSIntegrationsOptions from "components/AttachableVCSIntegrationsOptions/useQueryAttachableVCSIntegrationsOptions";
import { SelectedVcsIntegration } from "components/AttachableVCSIntegrationsOptions/types";

import VcsIntegrationFieldSelectOption from "./SelectOption";
import VcsIntegrationFieldTooltip from "./Tooltip";
import { getTooltipAnalyticsProps } from "../utils";
import { InternalFormData, SourceCodeSettingsField, SourceCodeProjects } from "../types";

type SourceCodeIntegrationFieldProps = {
  provider: VcsProvider;
  spaceId: string;
  setInternalFormData?: Dispatch<React.SetStateAction<InternalFormData>>;
  analyticsVersion?: string;
  projectType: SourceCodeProjects;
};

const SourceCodeIntegrationField = ({
  provider,
  spaceId,
  setInternalFormData,
  analyticsVersion,
  projectType,
}: SourceCodeIntegrationFieldProps) => {
  const { control, setValue, watch } = useFormContext<SourceCodeSettingsField>();

  const formValues = watch();

  const handleChange = useCallback(
    (value: string, option?: SelectOption) => {
      setValue("vcsIntegrationId", value, { shouldValidate: true, shouldDirty: true });

      if (option) {
        setInternalFormData?.((state) => ({ ...state, vcsIntegrationLabel: option.label }));
      }

      setValue("repository", "", { shouldDirty: true });
      setValue("branch", "", { shouldDirty: true });
    },
    [setInternalFormData, setValue]
  );

  const handleSetInitialValue = useCallback(
    (integration: SelectedVcsIntegration) => {
      setValue("vcsIntegrationId", integration.id, { shouldValidate: true, shouldDirty: true });
      setInternalFormData?.((state) => ({ ...state, vcsIntegrationLabel: integration.name }));
    },
    [setInternalFormData, setValue]
  );

  const { options, loading } = useQueryAttachableVCSIntegrationsOptions({
    provider,
    spaceId,
    onSelect: handleSetInitialValue,
    initialValue: formValues.vcsIntegrationId,
  });

  return (
    <Controller
      name="vcsIntegrationId"
      control={control}
      rules={{ required: "Integration is required" }}
      render={({ field, fieldState }) => (
        <FormField
          label="Integration"
          {...getTooltipAnalyticsProps("Source Code", "Integration", projectType, {
            provider: formValues.vcsIntegrationId,
            version: analyticsVersion,
          })}
          tooltipInfoVariant="modal"
          tooltipInfo={<VcsIntegrationFieldTooltip provider={provider} projectType={projectType} />}
          error={fieldState.error?.message}
          noMargin
        >
          {({ ariaInputProps }) => (
            <Select
              value={field.value}
              options={options}
              onChange={handleChange}
              error={!!fieldState.error?.message}
              loading={loading}
              renderOption={VcsIntegrationFieldSelectOption}
              autocomplete
              ariaInputProps={ariaInputProps}
              ref={field.ref}
            />
          )}
        </FormField>
      )}
    />
  );
};

export default SourceCodeIntegrationField;
