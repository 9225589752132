import { useMemo } from "react";

import useURLParams from "hooks/useURLParams";
import TreeChart from "components/TreeChart";
import TreeChartTextNode from "components/TreeChart/TextNode";
import { ConfigNode as ChartConfigNode } from "components/TreeChart/types";
import Box from "ds/components/Box";
import TreeChartGroupNode from "components/TreeChart/GroupNode";
import { getNodeHeight } from "components/TreeChart/GroupNode/getNodeHeight";
import { AnalyticsPage } from "hooks/useAnalytics";

import ConfigManagementTreeGridList from "./List";
import { TASK_SEARCH_KEY } from "./constants";
import ConfigManagementTreeGridDrawer from "./Drawer";
import { Components, ConfigNode } from "../types";
import { LayoutMode } from "./types";
import ConfigManagementTreeGridLegend from "./Legend";

type ConfigManagementTreeGridProps = {
  nodes: ConfigNode[];
  sortable?: boolean;
  layoutMode?: LayoutMode;
  chartClassName?: string;
  analyticsPage?: AnalyticsPage;
};

const nodeTypes = {
  [Components.Text]: {
    Component: TreeChartTextNode,
    height: () => 60,
  },
  [Components.Group]: {
    Component: TreeChartGroupNode,
    height: getNodeHeight,
  },
};

const ConfigManagementTreeGrid = ({
  nodes,
  sortable,
  layoutMode,
  chartClassName,
  analyticsPage,
}: ConfigManagementTreeGridProps) => {
  const urlParams = useURLParams();

  const chartNodes = useMemo(() => nodes.filter((node) => !!node.type), [nodes]);

  const taskId = urlParams.get(TASK_SEARCH_KEY) as string;
  const task = taskId ? nodes.find((node) => node.id === taskId) : undefined;

  return (
    <>
      {layoutMode === LayoutMode.Diagram ? (
        <Box direction="column" surfaceColor="primary" className={chartClassName}>
          <TreeChart
            analyticsPage={analyticsPage}
            nodes={chartNodes as ChartConfigNode<Components>[]}
            margin={{ top: 30, left: 30, right: 30 }}
            nodeTypes={nodeTypes}
            activeId={taskId}
          />
          <ConfigManagementTreeGridLegend nodes={nodes} />
        </Box>
      ) : (
        <ConfigManagementTreeGridList
          analyticsPage={analyticsPage}
          sortable={sortable}
          nodes={nodes}
          activeId={taskId}
        />
      )}
      <ConfigManagementTreeGridDrawer task={task} />
    </>
  );
};

export default ConfigManagementTreeGrid;
