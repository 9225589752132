import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { AccountContext } from "views/AccountWrapper";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import { AnalyticsPage } from "hooks/useAnalytics";

import LockButton from "../LockButton";
import UnlockButton from "../UnlockButton";

type StackLockOrUnlockButtonProps = {
  analyticsPage?: AnalyticsPage;
};

const StackLockOrUnlockButton = ({ analyticsPage }: StackLockOrUnlockButtonProps) => {
  const { stack } = useTypedContext(StackContext);
  const { viewer } = useTypedContext(AccountContext);

  const canLock = canLockStack(stack);
  const canUnlock = canUnlockStack(stack, viewer);

  return (
    <>
      {canLock && <LockButton stackId={stack.id} analyticsPage={analyticsPage} />}
      {canUnlock && <UnlockButton stackId={stack.id} analyticsPage={analyticsPage} />}
    </>
  );
};

export default StackLockOrUnlockButton;
