import capitalize from "lodash-es/capitalize";
import isObject from "lodash-es/isObject";

import Timestamp from "components/time/Timestamp";

const ENTITIES_LABELS: Record<string, string> = {
  address: "Address",
  id: "ID",
  name: "Resource name",
  parent: "Parent",
  type: "Resource type",
  creator: "Created by",
  updater: "Last updated by",
  stack: "Stack",
  labels: "Stack labels",
  vendor: "Vendor-specific data",
  thirdPartyMetadata: "Third-party metadata",
  drifted: "Drifted",
};

export const translateEntityValue = (key: string, value?: { id: string } | number | string) => {
  if (!value) return "none";

  if (key === "creator" || key === "updater") {
    return isObject(value) ? value.id : "";
  }

  if (key === "vendor") {
    return JSON.stringify(Object.values(value)[0], null, 2);
  }

  if (key === "drifted") {
    return typeof value === "number" ? <Timestamp timestamp={value} /> : "";
  }

  if (typeof value !== "string") {
    return JSON.stringify(value, null, 2);
  }

  return value;
};

export const translateEntity = (entity: string | { id: string }, key: string) => {
  return translateEntityValue(key, entity);
};

export const generateEntityLabel = (entity: string | Record<string, string>, key: string) => {
  if (key === "vendor") {
    const [vendorName] = Object.keys(entity);

    if (vendorName === "cloudFormation") {
      return `${ENTITIES_LABELS[key]} (CloudFormation)`;
    }

    return `${ENTITIES_LABELS[key]} (${capitalize(vendorName)})`;
  }

  if (key.startsWith("thirdPartyMetadata.")) {
    const name = key.split(".", 2)[1];

    return `${ENTITIES_LABELS.thirdPartyMetadata} (${capitalize(name)})`;
  }

  return ENTITIES_LABELS[key];
};
