import { ReactNode } from "react";

import Box from "ds/components/Box";
import { useHideOnScrollContext } from "components/HideOnScroll/HideOnScrollContext";

type RunPageWrapperProps = {
  children: ReactNode;
};

const RunPageWrapper = ({ children }: RunPageWrapperProps) => {
  const { setScrollableElRef } = useHideOnScrollContext();
  return (
    <Box
      direction="column"
      padding="large"
      fullWidth
      gap="large"
      margin="0 0 x-large"
      grow="1"
      scrollable
      style={{ height: "0" }}
      ref={setScrollableElRef}
    >
      {children}
    </Box>
  );
};

export default RunPageWrapper;
