import { useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";

/**
 * @deprecated use `useSearchParams` from `react-router-dom-v5-compat` instead
 */
const useURLParams = () => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  return params;
};

export default useURLParams;
