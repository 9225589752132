import { RunState } from "types/generated";

import RunPhaseLogs from "../../components/RunPhaseLogs";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import StopRunButton from "../../components/StopRunButton";
import { RunHistoryEntryType } from "../../types";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";

type InitializingEntryProps = {
  timestamp: number;
  until: number | null;
  stateVersion: number | null;
  hasLogs: boolean;
  openLogsByDefault: boolean;
  showStopButton: boolean;
  beforeHooks: string[] | undefined;
  afterHooks: string[] | undefined;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
  canStop: boolean;
  runQueryToRefetch: string;
};

const InitializingEntry = ({
  timestamp,
  until,
  stateVersion,
  hasLogs,
  openLogsByDefault,
  showStopButton,
  beforeHooks,
  afterHooks,
  runId,
  stackId,
  isModuleRun,
  canStop,
  runQueryToRefetch,
}: InitializingEntryProps) => {
  const [showLogs, toggleLogs] = useVisibilityToggle(openLogsByDefault, hasLogs);

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.Initializing}
      timestamp={timestamp}
      until={until}
      isLoading={!until}
      onClick={toggleLogs}
      isOpen={showLogs}
    >
      {showLogs && (
        <RunPhaseLogs
          beforeHooks={beforeHooks}
          afterHooks={afterHooks}
          state={RunState.Initializing}
          stateVersion={stateVersion}
          until={until}
          runId={runId}
          stackId={stackId}
          isModuleRun={isModuleRun}
        >
          {canStop && showStopButton && (
            <StopRunButton runId={runId} stackId={stackId} runQueryToRefetch={runQueryToRefetch} />
          )}
        </RunPhaseLogs>
      )}
    </HistoryEntryWrapper>
  );
};

export default InitializingEntry;
