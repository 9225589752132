import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import { getDocsUrl } from "utils/getDocsUrl";

const Documentation = () => (
  <Typography tag="p" variant="p-body2">
    There are a few common settings that apply to all of modules. You'll have a chance to define
    them in the next step:
    <br />
    <br />
    The basic settings are:
    <ul>
      <li>
        whether the module is{" "}
        <TextLink href={getDocsUrl("/concepts/stack/stack-settings#administrative")}>
          administrative
        </TextLink>
        ;
      </li>
      <li>
        <TextLink href={getDocsUrl("/concepts/worker-pools")}>worker pool</TextLink> to use, if
        applicable (default uses the Spacelift public worker pool);
      </li>
    </ul>
    The advanced settings are:
    <ul>
      <li>
        whether or not to enable the local preview{" "}
        <TextLink href="https://github.com/spacelift-io/spacectl">spacectl</TextLink> CLI feature;
      </li>
      <li>whether or not to protect the module from deletion;</li>
    </ul>
  </Typography>
);

export default Documentation;
