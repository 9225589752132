import { RunType } from "types/generated";

export const RUN_TYPES: Record<RunType, string> = {
  UNKNOWN: "UNKNOWN",
  PROPOSED: "PROPOSED",
  TRACKED: "TRACKED",
  TASK: "TASK",
  TESTING: "TESTING",
  DESTROY: "DESTROY",
  PARSE: "PARSE",
};
