import cx from "classnames";
import { Column, ColumnResizer } from "react-aria-components";
import { useState } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { Dots, NavigationChevronDownSm, NavigationChevronUpDown } from "components/icons";
import DropdownNew from "ds/components/Dropdown/New";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Icon from "ds/components/Icon";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import { ColumnConfigValue, ColumnSize } from "./types";
import styles from "./styles.module.css";

type TableColumnProps = {
  value: ColumnConfigValue;
  size?: ColumnSize;
  shouldGrow?: boolean;
  isRowHeader?: boolean;
  hideColumn?: (id: string) => void;
};

const TableColumn = ({ value, isRowHeader, hideColumn, size }: TableColumnProps) => {
  const {
    id,
    title,
    sticky,
    resizable,
    icon,
    defaultWidth,
    width,
    noHeader,
    textValue,
    iconOnly,
    ...columnProps
  } = value;

  const [dropdownActive, setDropdownActive] = useState(false);

  const canHide = !columnProps.static && !isRowHeader;

  const a11yValue = title || textValue;

  return (
    <Column
      {...columnProps}
      key={id}
      width={width}
      defaultWidth={size || defaultWidth}
      className={cx(
        styles.column,
        sticky && styles.columnSticky,
        dropdownActive && styles.active,
        columnProps.allowsSorting && styles.sortable,
        canHide && styles.interactive
      )}
      textValue={a11yValue}
      isRowHeader={isRowHeader}
      id={id}
    >
      {({ sortDirection, allowsSorting }) => {
        if (noHeader) {
          return <span className="sr-only">{a11yValue}</span>;
        }

        return (
          <Box gap="small" align="center" justify="between">
            <Box
              align="center"
              gap="small"
              tabIndex={allowsSorting ? 0 : undefined}
              className={styles.columnHeader}
            >
              {icon && <Icon className={styles.columnNameIcon} src={icon} color="secondary" />}
              {iconOnly && <span className="sr-only">{a11yValue}</span>}

              {title && !iconOnly && (
                <Typography
                  className={styles.columnName}
                  color="secondary"
                  tag="span"
                  variant="p-t7"
                >
                  {title}
                </Typography>
              )}
              {allowsSorting && (
                <Icon
                  color="secondary"
                  className={styles.columnCellSortableIcon}
                  rotate={sortDirection === "ascending" ? "180" : undefined}
                  src={sortDirection ? NavigationChevronDownSm : NavigationChevronUpDown}
                />
              )}
            </Box>
            {(canHide || resizable) && (
              <Box align="center">
                {canHide && (
                  <DropdownNew
                    onOpenChange={setDropdownActive}
                    triggerComponent={
                      <ButtonIconNew
                        className={styles.columnDropdownEllipsis}
                        icon={Dots}
                        variant="ghost"
                      >
                        Column options
                      </ButtonIconNew>
                    }
                  >
                    {({ close }) => (
                      <DropdownSection>
                        <DropdownSectionItem onClick={() => (hideColumn?.(id as string), close)}>
                          Hide
                        </DropdownSectionItem>
                      </DropdownSection>
                    )}
                  </DropdownNew>
                )}
                {resizable && <ColumnResizer className={styles.columnResizer} />}
              </Box>
            )}
          </Box>
        );
      }}
    </Column>
  );
};

export default TableColumn;
