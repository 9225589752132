import { Pages } from "./types";

export const enum AnalyticsPageLogin {
  Exchange = "Exchange",
  Login = "Login",
  LoginSSO = "LoginSSO",
  LoginTerraform = "LoginTerraform",
}

export const pagesLogin: Pages<AnalyticsPageLogin> = {
  [AnalyticsPageLogin.Exchange]: ["Exchange"],
  [AnalyticsPageLogin.Login]: ["Login"],
  [AnalyticsPageLogin.LoginTerraform]: ["Login terraform"],
  [AnalyticsPageLogin.LoginSSO]: ["SSO"],
};
