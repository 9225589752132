import { LogoOpenTofu, SettingsGear, LogoTerraform } from "components/icons";
import { TERRAGRUNT_MANUALLY_PROVISIONED } from "constants/vendor";
import { TerragruntTool } from "types/generated";

export const getTerragruntToolDetails = (tool: TerragruntTool, toolVersion: string | undefined) => {
  let toolIcon = LogoTerraform;
  let toolText = toolVersion;

  if (tool === TerragruntTool.OpenTofu) {
    toolIcon = LogoOpenTofu;
  } else if (tool === TerragruntTool.ManuallyProvisioned) {
    toolIcon = SettingsGear;
    toolText = TERRAGRUNT_MANUALLY_PROVISIONED;
  }

  return {
    icon: toolIcon,
    text: toolText,
  };
};
