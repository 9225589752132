import { ReactNode } from "react";
import { ToastAria } from "@react-aria/toast";

import Typography from "../../Typography";
import styles from "./styles.module.css";

type ToastHeaderProps = {
  children: ReactNode;
  ariaProps?: ToastAria["titleProps"];
};

const ToastTitle = ({ children, ariaProps }: ToastHeaderProps) => {
  return (
    <Typography {...ariaProps} tag="p" className={styles.title}>
      {children}
    </Typography>
  );
};

export default ToastTitle;
