import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Stack } from "types/generated";

import StackListItem, { StackListItemProps } from ".";

type StackVirtualizedListItemProps = {
  items: Stack[];
  onCheckItem: StackListItemProps["onCheckItem"];
  onShowFullDescription: StackListItemProps["onShowFullDescription"];
  selectedSet: Set<string>;
};

const StackVirtualizedListItem = (
  props: ListChildComponentProps<StackVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style} id={index === 0 ? "stack-list-item" : undefined}>
      <StackListItem
        key={data.items[index].id}
        stack={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onCheckItem={data.onCheckItem}
        onShowFullDescription={data.onShowFullDescription}
        checked={data.selectedSet.has(data.items[index].id)}
      />
    </div>
  );
};

export default memo(StackVirtualizedListItem, areEqual);
