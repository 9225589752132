import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

import { DELETE_BLUEPRINT } from "../gql";
import { REFETCH_QUERIES_LIST } from "../constants";

type DeleteConfirmationProps = {
  name: string;
  id: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  name,
  id,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteBlueprint, { loading, data }] = useMutation(DELETE_BLUEPRINT, {
    refetchQueries: REFETCH_QUERIES_LIST,
    awaitRefetchQueries: true,
    variables: { id },
  });

  const handleDeleteBlueprint = () => {
    deleteBlueprint()
      .then(() => {
        reportSuccess({
          message: `Blueprint "${name}" was successfully deleted`,
        });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete blueprint"
      name={name}
      onConfirm={handleDeleteBlueprint}
      isLoading={loading}
      isDismissable={!loading && !data?.blueprintDelete?.id}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
