import {
  HierarchyFilterItemMapOption,
  HierarchyFilterItemOption,
  HierarchyFilterOptionsMap,
} from "../types";

export const getOptionLabel = (value: string, isProjectRootFilter: boolean): string => {
  return isProjectRootFilter && !value ? "(empty, defaults to repo root)" : value;
};

export const checkLabelFilter = (filterName: string) => filterName === "label";
export const projectRootFilter = (filterName: string) => filterName === "projectRoot";

export const makeHierarchyOptions = (
  options: HierarchyFilterItemOption[],
  searchInput: string
): [HierarchyFilterOptionsMap, HierarchyFilterItemMapOption[]] => {
  const optionsMap: HierarchyFilterOptionsMap = new Map(
    options.map((item) => [item.id, { ...item, children: [] }])
  );

  options.forEach((item) => {
    if (item.parentId) {
      const parentItem = optionsMap.get(item.parentId);
      if (parentItem) {
        parentItem.children.push(item.id);
      }
    }
  });

  const firstLevelOptions =
    searchInput.length > 0
      ? [...optionsMap.values()]
      : [...optionsMap.values()].filter((item) => !item.parentId);

  return [optionsMap, firstLevelOptions];
};
