import cx from "classnames";

import { EntityChangeType } from "types/generated";

import { getChangeType, iconByType } from "../helpers";
import styles from "./styles.module.css";

type ChangeTypeIconProps = {
  moved: boolean;
  type: EntityChangeType;
};

const ChangeTypeIcon = ({ moved, type }: ChangeTypeIconProps) => {
  const changeType = getChangeType({ type, moved });
  const icon = iconByType(changeType);
  return icon ? (
    <span className={cx(styles.icon, styles[String(changeType).toLowerCase()])}>{icon}</span>
  ) : null;
};

export default ChangeTypeIcon;
