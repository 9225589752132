import useTypedContext from "hooks/useTypedContext";

import { ModuleSettingsContext } from "../Context";
import ModuleSettingsPageLayout from "../components/PageLayout";
import ModuleSettingsDetailsEditForm from "./EditForm";
import ModuleSettingsDetailsViewForm from "./ViewForm";
import ModuleSettingsFormWrapper from "../components/FormWrapper";

const ModuleSettingsDetails = () => {
  const { canManageModule } = useTypedContext(ModuleSettingsContext);

  return (
    <ModuleSettingsPageLayout
      title="Module details"
      description="Choose the space that it will belong to and add labels for improved organization and filtering."
    >
      <ModuleSettingsFormWrapper>
        {canManageModule ? <ModuleSettingsDetailsEditForm /> : <ModuleSettingsDetailsViewForm />}
      </ModuleSettingsFormWrapper>
    </ModuleSettingsPageLayout>
  );
};

export default ModuleSettingsDetails;
