import { useModal, show, create } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

const getCopy = (isExecutingBulkActions: boolean) =>
  isExecutingBulkActions
    ? {
        title: "Stop performing",
        buttonLabel: "Stop performing",
        content: (
          <>
            Are you sure you want to stop performing the selected actions? <br /> <br /> This will
            terminate the ongoing work on the remaining items.
          </>
        ),
      }
    : {
        title: "Please confirm",
        buttonLabel: "Leave",
        content: (
          <>
            Are you sure you want to close the drawer? <br /> <br /> You will not be able to come
            back to this step.
          </>
        ),
      };

type BulkActionsNewDrawerResultsStepCloseConfirmationModalProps = {
  isExecutingBulkActions: boolean;
  onConfirm: () => void;
};

const BulkActionsNewDrawerResultsStepCloseConfirmationModal = create(
  function BulkActionsNewDrawerResultsStepCloseConfirmationModal({
    isExecutingBulkActions,
    onConfirm,
  }: BulkActionsNewDrawerResultsStepCloseConfirmationModalProps) {
    const modal = useModal();

    const { buttonLabel, content, title } = getCopy(isExecutingBulkActions);

    const handleConfirm = () => {
      modal.resolve();
      modal.hide();
      onConfirm();
    };

    return (
      <ConfirmationModal
        title={title}
        confirmationButtonLabel={buttonLabel}
        onConfirm={handleConfirm}
      >
        <ConfirmationModalMessage>{content}</ConfirmationModalMessage>
      </ConfirmationModal>
    );
  }
);

export default BulkActionsNewDrawerResultsStepCloseConfirmationModal;

export const showBulkActionsNewDrawerResultsStepCloseConfirmationModal = (
  props: BulkActionsNewDrawerResultsStepCloseConfirmationModalProps
) => show(BulkActionsNewDrawerResultsStepCloseConfirmationModal, props);
