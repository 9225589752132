import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Space } from "types/generated";

import SpacesListItem from "./Item";

type SpacesListVirtualizedItemProps = {
  spaces: Space[];
  handleRequestRoleChange: (space: Space) => unknown;
};

const SpacesListVirtualizedItem = (
  props: ListChildComponentProps<SpacesListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.spaces[index];
  const key = `${index} ${item.id}`;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <SpacesListItem
        key={key}
        item={item}
        handleRequestRoleChange={data.handleRequestRoleChange}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(SpacesListVirtualizedItem, areEqual);
