import ButtonIcon from "ds/components/ButtonIcon";
import { CrossNew } from "components/icons";

type DrawerCloseIconProps = {
  handleCloseDrawer: () => void;
};

const DrawerCloseIcon = ({ handleCloseDrawer }: DrawerCloseIconProps) => (
  <ButtonIcon icon={CrossNew} onClick={handleCloseDrawer} variant="ghost">
    Close
  </ButtonIcon>
);

export default DrawerCloseIcon;
