import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  AwsIntegrationStackAttachment,
  AzureIntegrationStackAttachment,
  Maybe,
} from "types/generated";

import { DETACH_AWS_INTEGRATION, DETACH_AZURE_INTEGRATION } from "../gql";
import { StackCreationCloud } from "../../types";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";

type Variables = {
  attachmentId: string;
};

const useDetachCloud = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [detachAwsIntegration, { loading: detachAwsLoading }] = useMutation<{
    awsIntegrationDetach: Maybe<AwsIntegrationStackAttachment>;
  }>(DETACH_AWS_INTEGRATION, {
    refetchQueries: ["GetAttachedStackIntegrations"],
    awaitRefetchQueries: true,
  });

  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const detachAws = useCallback(
    ({ attachmentId }: Variables) => {
      detachAwsIntegration({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.awsIntegrationDetach?.id) {
            reportSuccess({ message: "AWS integration was detached" });
            trackSegmentEvent("AWS integration detached");
          }
        })
        .catch(onError);
    },
    [detachAwsIntegration, reportSuccess, onError, trackSegmentEvent]
  );

  const [detachAzureIntegration, { loading: detachAzureLoading }] = useMutation<{
    azureIntegrationDetach: Maybe<AzureIntegrationStackAttachment>;
  }>(DETACH_AZURE_INTEGRATION, {
    refetchQueries: ["GetAttachedStackIntegrations"],
    awaitRefetchQueries: true,
  });

  const detachAzure = useCallback(
    ({ attachmentId }: Variables) => {
      detachAzureIntegration({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.azureIntegrationDetach?.id) {
            reportSuccess({ message: "Azure integration was detached" });
            trackSegmentEvent("Azure integration detached");
          }
        })
        .catch(onError);
    },
    [detachAzureIntegration, reportSuccess, onError, trackSegmentEvent]
  );

  const detach = useCallback(
    (type: StackCreationCloud, variables: Variables) => {
      if (type === StackCreationCloud.AWS) {
        return detachAws(variables);
      }

      if (type === StackCreationCloud.Azure) {
        return detachAzure(variables);
      }
    },
    [detachAws, detachAzure]
  );

  return {
    detach,
    detachLoading: detachAwsLoading || detachAzureLoading,
  };
};

export default useDetachCloud;
