import { ReactNode } from "react";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type DrawerHeaderProps = {
  children: ReactNode;
} & Pick<BoxProps, "gap" | "justify">;

const DrawerHeader = ({ children, ...restProps }: DrawerHeaderProps) => {
  return (
    <Box
      direction="row"
      shrink="0"
      align="center"
      className={styles.header}
      fullWidth
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default DrawerHeader;
