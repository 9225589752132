import { gql } from "@apollo/client";

export const GENERATE_GITHUB_APP_MANIFEST = gql`
  mutation GenerateGitHubAppManifestInVCS($input: GitHubAppManifestInput!) {
    githubAppGenerateManifest(input: $input) {
      endpoint
      manifest
    }
  }
`;

export const CREATE_GITHUB_APP_FROM_MANIFEST = gql`
  mutation CreateGitHubAppFromManifestInVCS(
    $code: String!
    $state: String!
    $customInput: CustomVCSInput
  ) {
    githubAppCreateFromManifest(code: $code, state: $state, customInput: $customInput) {
      apiHost
    }
  }
`;
