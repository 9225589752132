import { Stack } from "types/generated";
import Timestamp from "components/time/Timestamp";

import StackListItemTextValue from "./TextValue";

type StackListItemUpdatedAtCellProps = { stack: Stack };

const StackListItemUpdatedAtCell = ({ stack }: StackListItemUpdatedAtCellProps) => {
  if (!stack.stateSetAt) {
    return null;
  }

  return (
    <StackListItemTextValue>
      <Timestamp timestamp={stack.stateSetAt!} />
    </StackListItemTextValue>
  );
};

export default StackListItemUpdatedAtCell;
