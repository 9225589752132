import { gql } from "@apollo/client";

export const SEARCH_BLUEPRINTS = gql`
  query SearchBlueprints($input: SearchInput!) {
    searchBlueprints(input: $input) {
      edges {
        cursor
        node {
          id
          name
          state
          description
          labels
          createdAt
          updatedAt
          rawTemplate
          inputs {
            id
            name
            default
            description
            options
            type
          }
          space {
            id
            name
            accessLevel
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_BLUEPRINTS_SUGGESTIONS = gql`
  query SearchBlueprintsSuggestions($input: SearchSuggestionsInput!) {
    searchBlueprintsSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const DELETE_BLUEPRINT = gql`
  mutation BlueprintDelete($id: ID!) {
    blueprintDelete(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_BLUEPRINT = gql`
  mutation BlueprintCreate($input: BlueprintCreateInput!) {
    blueprintCreate(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_BLUEPRINT = gql`
  mutation UpdateBlueprint($id: ID!, $input: BlueprintCreateInput!) {
    blueprintUpdate(id: $id, input: $input) {
      id
      name
      description
      createdAt
      state
      inputs {
        id
        name
        default
        description
        options
        type
      }
      space {
        id
        name
        accessLevel
      }
      labels
      rawTemplate
    }
  }
`;

export const CLONE_BLUEPRINT = gql`
  mutation CloneBlueprint($id: ID!, $input: BlueprintCloneInput!) {
    blueprintClone(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const BLUEPRINT_CREATE_STACK = gql`
  mutation BlueprintCreateStack($id: ID!, $input: BlueprintStackCreateInput!) {
    blueprintCreateStack(id: $id, input: $input) {
      stackID
      runID
    }
  }
`;
