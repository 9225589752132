import { Controller, useFormContext } from "react-hook-form";
import { useCallback } from "react";

import Box from "ds/components/Box";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import useTypedContext from "hooks/useTypedContext";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { TerragruntTool } from "types/generated";
import CardWrapper from "components/CardWrapper";
import { TERRAGRUNT_TOOL_INFO_TOOLTIP, TERRAGRUNT_TOOL_OPTIONS } from "constants/terragrunt_tools";
import ToggleField from "ds/components/Form/ToggleField";
import { getDocsUrl } from "utils/getDocsUrl";

import VersionInput from "../VersionInput";
import { StackVendorFormFields } from "../../types";
import useAsyncTerragruntVersionValidation from "./useAsyncTerragruntVersionValidation";
import VersionTooltipContent from "../VersionTooltipContent";
import { getValidateTerragruntVersion } from "./getValidateTerragruntVersion";
import { StackFormContext } from "../../context";
import { getSwitchTooggleAnalyticsProps, getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";
import { getValidateToolVersion } from "./getValidateToolVersion";

type NewStackVendorTerragruntProps = {
  terraformVersions?: string[];
  openTofuVersions?: string[];
  terragruntVersions?: string[];
  reloadVersionsData: () => void;
  reloadLoading: boolean;
};

const NewStackVendorTerragrunt = ({
  terraformVersions,
  openTofuVersions,
  terragruntVersions,
  reloadVersionsData,
  reloadLoading,
}: NewStackVendorTerragruntProps) => {
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const { setAsyncValidationLoading } = useTypedContext(StackFormContext);
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const { control, trigger, watch, setValue } = useFormContext<StackVendorFormFields>();
  useAsyncTerragruntVersionValidation(setAsyncValidationLoading);

  const terragruntValues = watch().terragrunt;

  const workflowVersions =
    terragruntValues.tool === TerragruntTool.OpenTofu ? openTofuVersions : terraformVersions;
  const vendorToolName =
    terragruntValues.tool === TerragruntTool.OpenTofu ? "OpenTofu" : "Terraform";
  const isManualMode = terragruntValues.tool === TerragruntTool.ManuallyProvisioned;

  const handleWorkflowToolChange = useCallback(
    (value: TerragruntTool) => {
      if (terragruntValues.tool === value) {
        return;
      }

      let version: string;
      if (value === TerragruntTool.TerraformFoss) {
        version = terraformVersions ? terraformVersions[0] : "";
      } else if (value === TerragruntTool.OpenTofu) {
        version = openTofuVersions ? openTofuVersions[0] : "";
      } else {
        version = "";
      }

      setValue("terragrunt.tool", value);
      setValue("terragrunt.workflowVersion", {
        value: version,
        type: terragruntValues.workflowVersion.type,
      });

      trigger();

      trackSegmentEvent("Workflow tool", { tool: value, provider: "Terragrunt" });
    },
    [
      terragruntValues.tool,
      terragruntValues.workflowVersion.type,
      setValue,
      trigger,
      trackSegmentEvent,
      terraformVersions,
      openTofuVersions,
    ]
  );

  const toolController = (
    <Controller
      name="terragrunt.tool"
      control={control}
      render={({ field, fieldState }) => (
        <FormField
          error={fieldState.error?.message}
          noMargin
          label="Tool:"
          {...getTooltipAnalyticsProps("Vendor", "Tool", {
            provider: "Terragrunt",
            version: analyticsVersion,
          })}
          tooltipInfo={
            <>
              <TooltipModalTitle>Tool</TooltipModalTitle>
              <TooltipModalBody align="start">{TERRAGRUNT_TOOL_INFO_TOOLTIP}</TooltipModalBody>
            </>
          }
          tooltipInfoVariant="modal"
        >
          {({ ariaInputProps }) => (
            <Select
              value={field.value}
              options={TERRAGRUNT_TOOL_OPTIONS}
              onChange={handleWorkflowToolChange}
              error={!!fieldState.error?.message}
              ariaInputProps={ariaInputProps}
            />
          )}
        </FormField>
      )}
    />
  );

  const toolVersionController = (
    <Controller
      name="terragrunt.workflowVersion"
      control={control}
      rules={{
        validate: getValidateToolVersion(workflowVersions, vendorToolName),
      }}
      render={({ field, fieldState }) => {
        const version = field.value;
        return (
          <VersionInput
            reloadLoading={reloadLoading}
            reloadVersionsData={reloadVersionsData}
            title={`${vendorToolName} version`}
            analyticsProps={{
              location: "Vendor",
              name: "Terraform / OpenTofu version",
              provider: "Terragrunt",
              version: analyticsVersion,
            }}
            tooltipInfo={
              <>
                <TooltipModalTitle>Choose version</TooltipModalTitle>
                <TooltipModalBody align="start">
                  <VersionTooltipContent />
                  <Link
                    href={getDocsUrl("/concepts/stack/stack-settings#terraform-version")}
                    target="_blank"
                  >
                    Learn more
                  </Link>
                </TooltipModalBody>
              </>
            }
            errorMessage={fieldState.error?.message}
            type={version?.type}
            version={version?.value}
            supportedVersions={workflowVersions}
            onChange={field.onChange}
            ref={field.ref}
          />
        );
      }}
    />
  );

  return (
    <Box gap="x-large" direction="column">
      <Controller
        name="terragrunt.terragruntVersion"
        control={control}
        rules={{
          validate: getValidateTerragruntVersion(terragruntVersions),
        }}
        render={({ field, fieldState }) => {
          const version = field.value;
          return (
            <VersionInput
              reloadLoading={reloadLoading}
              reloadVersionsData={reloadVersionsData}
              title="Terragrunt version"
              analyticsProps={{
                location: "Vendor",
                name: "Terragrunt version",
                provider: "Terragrunt",
                version: analyticsVersion,
              }}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Choose version</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <VersionTooltipContent />
                  </TooltipModalBody>
                </>
              }
              errorMessage={fieldState.error?.message}
              type={version?.type}
              version={version?.value}
              supportedVersions={terragruntVersions}
              onChange={field.onChange}
              ref={field.ref}
            />
          );
        }}
      />

      {isManualMode && toolController}
      {!isManualMode && (
        <CardWrapper variant="outlined" direction="column" gap="x-large">
          {toolController}
          {toolVersionController}
        </CardWrapper>
      )}

      <Box gap="large" direction="column">
        <Controller
          name="terragrunt.useSmartSanitization"
          control={control}
          render={({ field }) => (
            <ToggleField
              variant="switch"
              onChange={(arg) => {
                field.onChange(arg);
                trigger("terragrunt.workflowVersion");
              }}
              {...getSwitchTooggleAnalyticsProps("Vendor", "Use smart sanitization", false, {
                provider: "Terragrunt",
                version: analyticsVersion,
              })}
              checked={field.value}
              title="Smart Sanitization (recommended)"
              description={`If set, Spacelift will attempt to sanitize the resources created by terraform using the information provided by terraform as to which fields are "sensitive" or not`}
            />
          )}
        />

        <Controller
          name="terragrunt.useRunAll"
          control={control}
          render={({ field }) => (
            <ToggleField
              variant="switch"
              onChange={field.onChange}
              {...getSwitchTooggleAnalyticsProps("Vendor", "Use run all", false, {
                provider: "Terragrunt",
                version: analyticsVersion,
              })}
              checked={field.value}
              title="Use All Run"
              description={`Enable this property to use Terragrunt's "run-all" capabilities to execute commands across all projects in the workspace.`}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default NewStackVendorTerragrunt;
