import {
  AzureDevOpsRepoIntegration,
  MutationAzureDevOpsRepoIntegrationCreateArgs,
  MutationAzureDevOpsRepoIntegrationUpdateArgs,
  VcsCheck,
} from "types/generated";
import { ROOT_SPACE_ID } from "constants/space";

import { ManageAzureDevOpsFormFields } from "./types";
import { VCS_INTEGRATION_TYPES } from "../../constants";

export const getFormDefaultValues = (
  integration?: AzureDevOpsRepoIntegration
): ManageAzureDevOpsFormFields => ({
  name: integration?.name || "",
  space: integration?.space?.id || "",
  labels: integration?.labels?.map((label) => ({ value: label })) || [],
  organizationURL: integration?.organizationURL || "",
  userFacingHost: integration?.userFacingHost || "",
  personalAccessToken: "",
  description: integration?.description || "",
  integrationType: integration?.isDefault
    ? VCS_INTEGRATION_TYPES.DEFAULT
    : VCS_INTEGRATION_TYPES.MULTIPLE,
  vcsChecks: integration?.vcsChecks || VcsCheck.Individual,
});

export const mapCreateAzureDevOpsIntegrationInputValues = (
  values: ManageAzureDevOpsFormFields
): MutationAzureDevOpsRepoIntegrationCreateArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    customInput: {
      isDefault,
      name: values.name.trim(),
      labels: values.labels.map((label) => label.value) || [],
      // FYI: set the root to be able to convert to the multiple integration from the default one
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      description: values.description?.trim() || null,
      vcsChecks: values.vcsChecks,
    },
    organizationURL: values.organizationURL.trim(),
    personalAccessToken: values.personalAccessToken.trim(),
    userFacingHost: values.userFacingHost.trim(),
  };
};

export const mapUpdateAzureDevOpsIntegrationInputValues = (
  values: ManageAzureDevOpsFormFields,
  integrationId: string
): MutationAzureDevOpsRepoIntegrationUpdateArgs => ({
  customInput: {
    id: integrationId,
    labels: values.labels.map((label) => label.value) || [],
    description: values.description?.trim() || null,
    space: values.space,
    vcsChecks: values.vcsChecks,
  },
  organizationURL: values.organizationURL.trim(),
  personalAccessToken: values.personalAccessToken?.trim() || null,
});
