export const extractRepositoryDetails = (url?: string) => {
  if (!url) {
    return {};
  }
  try {
    const currentURL = new URL(url);
    return extractDetails(cleanupPath(currentURL));
  } catch (e) {
    if (e instanceof TypeError) {
      return { namespace: null, repository: null, err: e.message };
    } else {
      return { namespace: null, repository: null, err: "Invalid URL" };
    }
  }
};

const cleanupPath = (url: URL) => {
  // trim first slash
  const pathname = url.pathname.substring(1);
  // trim .git suffix
  return pathname.substring(0, pathname.lastIndexOf(".git")) || pathname;
};

const extractDetails = (pathname: string) => {
  const index = pathname.lastIndexOf("/");
  return {
    namespace: pathname.substring(0, index),
    repository: pathname.substring(index + 1),
    err: null,
  };
};
