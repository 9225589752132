import { ChangeEventHandler, KeyboardEventHandler, useCallback, useMemo, useState } from "react";

type CommandsInputCallback = (commands: string[]) => void;

export const useCommandsInput = (
  addCallback: CommandsInputCallback,
  changeCallback?: (value: string) => void
) => {
  const [input, setInput] = useState("");

  const inputLines = useMemo(() => {
    return input.split(/\n/).filter((line) => line.length > 0);
  }, [input]);

  const handleAddCommand = useCallback(() => {
    if (!input.trim()) {
      return;
    }

    addCallback(inputLines);
    setInput("");
    changeCallback?.("");
  }, [changeCallback, addCallback, inputLines, input]);

  const handleInputKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (e.key === "Enter") {
        if (e.shiftKey) {
          setInput((value) => value + "\n");
          return;
        }

        handleAddCommand();
      }
    },
    [handleAddCommand]
  );

  const handleInputChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      const value = e.target.value.replace(/\n$/g, "");
      setInput(value);
      changeCallback?.(value);
    },
    [changeCallback]
  );

  return {
    input,
    handleInputKeyDown,
    handleInputChange,
    handleAddCommand,
  };
};
