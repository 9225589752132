import DiscardRunButton from "../../components/DiscardRunButton";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";

type ReadyEntryProps = {
  timestamp: number;
  until: number | null;
  showDiscardButton: boolean;
  canDiscard: boolean;
  stackId: string;
  runId: string;
  runQueryToRefetch: string;
};

const ReadyEntry = ({
  timestamp,
  until,
  showDiscardButton,
  canDiscard,
  stackId,
  runId,
  runQueryToRefetch,
}: ReadyEntryProps) => {
  return (
    <HistoryEntryWrapper state={RunHistoryEntryType.Ready} timestamp={timestamp} until={until}>
      {canDiscard && showDiscardButton && (
        <DiscardRunButton stackId={stackId} runId={runId} runQueryToRefetch={runQueryToRefetch} />
      )}
    </HistoryEntryWrapper>
  );
};

export default ReadyEntry;
