import { useEffect, useMemo, useRef, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import InfiniteLoader from "react-window-infinite-loader";

import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { NamedWebhooksIntegration, SearchNamedWebhooksIntegrationOutput } from "types/generated";
import useURLParams from "hooks/useURLParams";
import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import ListEntitiesNew from "components/ListEntitiesNew";
import { uniqByKey } from "utils/uniq";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import { SavedFilterView } from "components/Filters/types";

import { SEARCH_WEBHOOKS } from "./gql";
import WebhooksPageLayout from "./PageLayout";
import { initialSortDirection, initialSortOption, ITEMS_LIMIT, POLL_INTERVAL } from "./constants";
import FiltersLayout from "./FiltersLayout";
import WebhookVirtualizedListItem from "./ListItem/Virtualized";
import WebhooksEmpty from "./Empty";

const Webhooks = () => {
  const cachedWebhooksEdges = useRef<NamedWebhooksIntegration[]>([]);

  const isRefetching = useRef(false);
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  // Disable reloading when variables has changed to not remount entire sidebar
  const sortOptionFieldsRef = useRef(sortOptionFields);

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { onError } = useTypedContext(FlashContext);

  const {
    error,
    loading,
    data,
    stopPolling,
    fetchMore: fetchMoreNotifications,
    refetch,
    networkStatus,
  } = useQuery<{
    searchNamedWebhooksIntegrations: SearchNamedWebhooksIntegrationOutput;
  }>(SEARCH_WEBHOOKS, {
    variables: {
      input: {
        first: ITEMS_LIMIT,
        after: null,
        ...(sortOptionFieldsRef.current && { orderBy: sortOptionFieldsRef.current }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [memoizedWebhooks] = useMemo(() => {
    const sourceEdges = data?.searchNamedWebhooksIntegrations?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedWebhooksEdges.current : sourceEdges;

    if (!loading) {
      cachedWebhooksEdges.current = sourceEdges;
    }

    return [edges];
  }, [data?.searchNamedWebhooksIntegrations?.edges, loading]);

  const notificationsQueryRefetch = async () => {
    try {
      isRefetching.current = true;
      await refetch({
        input: {
          first: ITEMS_LIMIT,
          after: null,
          fullTextSearch: searchInput,
          predicates,
          ...(sortOptionFields && { orderBy: sortOptionFields }),
        },
      });
    } catch (e) {
      onError(e);
    } finally {
      isRefetching.current = false;
    }
  };

  const loadMoreItems = async () => {
    try {
      if (
        data?.searchNamedWebhooksIntegrations.pageInfo.endCursor &&
        data?.searchNamedWebhooksIntegrations.pageInfo.hasNextPage
      ) {
        await fetchMoreNotifications({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult &&
              fetchMoreResult.searchNamedWebhooksIntegrations.edges.length > 0
            ) {
              return {
                searchNamedWebhooksIntegrations: {
                  ...fetchMoreResult.searchNamedWebhooksIntegrations,
                  edges: uniqByKey(
                    [
                      ...(prev.searchNamedWebhooksIntegrations.edges || []),
                      ...fetchMoreResult.searchNamedWebhooksIntegrations.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data.searchNamedWebhooksIntegrations.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const isItemLoaded = (value: number) => value < memoizedWebhooks.length;

  // filtering, refetch query with url params
  useEffect(() => {
    void notificationsQueryRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, predicates, sortOptionFields]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (
    !isRefetching.current &&
    networkStatus === NetworkStatus.loading &&
    loading &&
    !data?.searchNamedWebhooksIntegrations
  ) {
    return (
      <WebhooksPageLayout>
        <PageLayoutSkeleton />
      </WebhooksPageLayout>
    );
  }

  if (
    !isRefetching.current &&
    networkStatus !== NetworkStatus.refetch &&
    !loading &&
    !data?.searchNamedWebhooksIntegrations
  ) {
    return <NotFoundPage />;
  }

  const hasNoResults = searchInput.length > 0 || predicates.length > 0;

  return (
    <WebhooksPageLayout>
      <FiltersLayout
        predicates={predicates}
        hasNoResults={!memoizedWebhooks.length}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
      >
        {data && !memoizedWebhooks.length && <WebhooksEmpty hasNoResults={hasNoResults} />}

        {data && memoizedWebhooks.length > 0 && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={memoizedWebhooks.length + ITEMS_LIMIT}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={memoizedWebhooks.length}
                itemProps={{
                  items: memoizedWebhooks,
                }}
                virtualizedItem={WebhookVirtualizedListItem}
                itemKey={(index) => memoizedWebhooks[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
      </FiltersLayout>
    </WebhooksPageLayout>
  );
};

export default Webhooks;
