import FormParagraph from "components/FormDefault/Paragraph";
import FormList from "components/FormDefault/List";
import { getBaseUrl } from "utils/getBaseUrl";

import styles from "./styles.module.css";

const baseUrl = getBaseUrl();

const IdentityProviderConfiguration = () => {
  return (
    <div className={styles.providerConfigurationCopy}>
      <FormParagraph className={styles.paragraph}>
        When setting up Spacelift as an OIDC application in your identity provider, please use the
        following setting:
      </FormParagraph>
      <FormList className={styles.list}>
        <li>
          <strong>Authorized redirect URL:</strong>
          &nbsp;
          <span className={styles.ssoUrl}>{baseUrl}/oidc/exchange</span>
        </li>
      </FormList>
      <FormParagraph className={styles.paragraph}>
        Apart from the standard <code>openid</code> scope, Spacelift requires also the{" "}
        <code>email</code> scope to work. If supported by the Identity Provider, Spacelift will also
        ask for the <code>profile</code> and <code>groups</code> scopes in order to provider better
        quality input for the Login Policies.
      </FormParagraph>
    </div>
  );
};

export default IdentityProviderConfiguration;
