import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfigElement } from "types/generated";

import { DELETE_CONTEXT_CONFIG } from "../../gql";

type DeleteConfirmationProps = {
  id: string;
  contextId: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  contextId,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteConfig, { loading, data }] = useMutation<{
    contextConfigDelete: ConfigElement | null;
  }>(DELETE_CONTEXT_CONFIG, {
    refetchQueries: ["GetContext"],
    variables: { configId: id, contextId },
    awaitRefetchQueries: true,
  });

  const onDelete = () => {
    deleteConfig()
      .then(({ data }) => {
        if (data && data.contextConfigDelete) {
          reportSuccess({
            message: `Mounted file ${data.contextConfigDelete.id} successfully deleted`,
          });
          modal.hide();
        }
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete mounted file"
      name={id}
      onConfirm={onDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.contextConfigDelete?.id}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
