import { getLocalTimeZone } from "@internationalized/date";
import { getUnixTime, fromUnixTime } from "date-fns/esm";

import { RuntimeConfigInput, ScheduledRun, ScheduledRunInput } from "types/generated";

import { ScheduledRunFormFields } from "./types";

export const getFormDefaultValues = (
  defaultDateTime: Date,
  integration?: ScheduledRun
): ScheduledRunFormFields => ({
  name: integration?.name || "",
  withCustomRuntimeConfig: !!integration?.customRuntimeConfig?.yaml,
  customRuntimeConfig: integration?.customRuntimeConfig?.yaml || "",
  recurring: !integration?.timestampSchedule,
  dateTime: integration?.timestampSchedule
    ? fromUnixTime(integration.timestampSchedule)
    : defaultDateTime,
  timezone: integration?.timezone || getLocalTimeZone(),
  cronSchedule: integration?.cronSchedule || ["*/15 * * * *"],
});

const emptyRuntimeConfigInput: RuntimeConfigInput = {
  afterApply: null,
  afterDestroy: null,
  afterInit: null,
  afterPerform: null,
  afterPlan: null,
  afterRun: null,
  beforeApply: null,
  beforeDestroy: null,
  beforeInit: null,
  beforePerform: null,
  beforePlan: null,
  environment: null,
  projectRoot: null,
  runnerImage: null,
  yaml: null,
};

export const mapCreateFormData = (values: ScheduledRunFormFields): ScheduledRunInput => ({
  name: values.name,
  // * The yaml property, or the other properties must be set, but not both
  runtimeConfig: values.withCustomRuntimeConfig
    ? { ...emptyRuntimeConfigInput, yaml: values.customRuntimeConfig }
    : null,
  cronSchedule: values.recurring ? values.cronSchedule : null,
  timezone: values.recurring ? values.timezone : null,
  timestampSchedule: values.recurring ? null : getUnixTime(values.dateTime),
});
