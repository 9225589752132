import { gql, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import Header from "components/header/Header";
import HeaderTitle from "components/header/Title";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useTypedContext from "hooks/useTypedContext";
import { BillingInfo, Maybe } from "types/generated";

import Form from "./Wrapper";
import styles from "../Form/styles.module.css";

export const GET_BILLING_DATA = gql`
  query GetBillingData {
    billingSubscription {
      billingInfo {
        address
        city
        companyName
        country
        email
        fullName
        state
        zipCode
      }
    }
  }
`;

type BillingSubscription = Maybe<{
  billingSubscription: Maybe<{
    billingInfo: Maybe<BillingInfo>;
  }>;
}>;

const BillingInvoiceForm = () => {
  const { onError } = useTypedContext(FlashContext);
  const { error, loading, data } = useQuery<BillingSubscription>(GET_BILLING_DATA, { onError });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (!data?.billingSubscription) {
    return <NotFoundPage />;
  }

  return (
    <>
      <div className={styles.billingFormHeader}>
        <Header>
          <HeaderTitle>Update your invoice details</HeaderTitle>
        </Header>
      </div>
      <div className={styles.billingFormWrapper}>
        <div className={styles.billingFormContent}>
          <Form {...data.billingSubscription} />
        </div>
      </div>
    </>
  );
};

export default BillingInvoiceForm;
