import { useFormContext } from "react-hook-form";

import FormFieldRowWrapper from "ds/components/Form/Field/RowWrapper";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FormArrayField from "ds/components/Form/ArrayField";

import { ModuleShareFormFields } from "../../types";
import { validateAccountNameFormat, validateAccountNameUniqueness } from "./utils";

const EMPTY_VALUE = { value: "" };

const ShareAccountsField = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<ModuleShareFormFields>();

  const handleOnKeyDown = (callback?: () => void) => (e: React.KeyboardEvent) => {
    if (callback && e.key === "Enter") {
      callback();
    }
  };

  return (
    <FormArrayField<ModuleShareFormFields, "sharedAccounts">
      name="sharedAccounts"
      addButtonLabel="Add another account"
      emptyValue={EMPTY_VALUE}
    >
      {({ field, index, addEmptyItem, suffix }) => {
        return (
          <FormField
            key={field.id}
            error={errors?.sharedAccounts?.[index]?.value?.message}
            label={index === 0 ? "Accounts" : undefined}
            isOptional
            fullWidth
            noMargin
          >
            {({ ariaInputProps }) => (
              <FormFieldRowWrapper>
                <Input
                  onKeyDown={handleOnKeyDown(addEmptyItem)}
                  placeholder="Enter account name"
                  error={!!errors?.sharedAccounts?.[index]?.value?.message}
                  {...register(`sharedAccounts.${index}.value`, {
                    validate: {
                      unique: validateAccountNameUniqueness,
                      format: validateAccountNameFormat,
                    },
                  })}
                  {...ariaInputProps}
                />
                {suffix}
              </FormFieldRowWrapper>
            )}
          </FormField>
        );
      }}
    </FormArrayField>
  );
};

export default ShareAccountsField;
