import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import InfiniteLoader from "react-window-infinite-loader";
import { getUnixTime } from "date-fns";

import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import {
  Notification,
  SearchNotificationsOutput,
  SearchQueryFieldConstraintTimeInLast,
  SearchSuggestionsFieldType,
} from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import useURLParams from "hooks/useURLParams";
import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import ListEntitiesNew from "components/ListEntitiesNew";
import { uniqByKey } from "utils/uniq";
import { getSearchQuery } from "components/SearchInput/helpers";
import {
  getFiltersPredicationFromURI,
  getSortOptionFromURI,
  makeConstraintByType,
} from "components/Filters/helpers";
import NoAccessPage from "components/error/NoAccessPage";
import { SavedFilterView } from "components/Filters/types";
import useBulkActionsSelection from "components/BulkActions/useBulkActionsSelection";
import { useBulkDismissNotifications } from "shared/Notification/useDismissNotification";

import NotificationsBulkActions from "./BulkActions";
import { SEARCH_NOTIFICATIONS } from "./gql";
import { getAllItemsForSelectAll } from "./helpers";
import NotificationsPageLayout from "./PageLayout";
import {
  INITIAL_PERIOD,
  initialSortDirection,
  initialSortOption,
  ITEMS_LIMIT,
  POLL_INTERVAL,
} from "./constants";
import FiltersLayout from "./FiltersLayout";
import NotificationVirtualizedListItem from "./ListItem/Virtualized";
import NotificationsEmpty from "./Empty";
import { NotificationsContext } from "./Context";
import { PERIODS } from "./FiltersLayout/constants";

const Notifications = () => {
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const cachedNotificationEdges = useRef<Notification[]>([]);

  const {
    selectedSet,
    allSelected,
    onBulkSelectAll,
    onBulkResetAll,
    toggleItem,
    unselectItem,
    syncAllSelectedItems,
    syncSelectedItemsWithVisibleOnes,
  } = useBulkActionsSelection();

  const isRefetching = useRef(false);
  const [currentDateRange, setCurrentDateRange] = useState<{
    start: Date;
    end: Date;
  }>(PERIODS[INITIAL_PERIOD].range);
  const [currentPeriod, setCurrentPeriod] = useState<
    SearchQueryFieldConstraintTimeInLast | undefined
  >(INITIAL_PERIOD);
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);
  const [dismissNotifications, { loading: dismissLoading }] = useBulkDismissNotifications();

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    if (currentDateRange) {
      predicatesMap?.set("timestamp", {
        field: "timestamp",
        exclude: null,
        constraint: makeConstraintByType(
          SearchSuggestionsFieldType.Time,
          typeof currentDateRange === "string"
            ? [currentDateRange]
            : [getUnixTime(currentDateRange.start), getUnixTime(currentDateRange.end)]
        ),
      });
    }

    return [...(predicatesMap?.values() || [])];
  }, [urlParams, currentDateRange]);

  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  // Disable reloading when variables has changed to not remount entire sidebar
  const sortOptionFieldsRef = useRef(sortOptionFields);

  const {
    error,
    loading,
    data,
    stopPolling,
    fetchMore: fetchMoreNotifications,
    refetch,
    networkStatus,
  } = useQuery<{
    searchNotifications: SearchNotificationsOutput;
    newNotificationsCount: number;
  }>(SEARCH_NOTIFICATIONS, {
    variables: {
      input: {
        first: ITEMS_LIMIT,
        after: null,
        ...(sortOptionFieldsRef.current && { orderBy: sortOptionFieldsRef.current }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [memoizedNotifications, memoizedNotificationsMap] = useMemo(() => {
    const sourceEdges = data?.searchNotifications?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedNotificationEdges.current : sourceEdges;

    if (!loading) {
      cachedNotificationEdges.current = sourceEdges;
    }

    return [edges, new Map(edges.map((edge) => [edge.id, edge]))];
  }, [data?.searchNotifications?.edges, loading]);

  const notificationsQueryRefetch = async () => {
    try {
      isRefetching.current = true;
      await refetch({
        input: {
          first: ITEMS_LIMIT,
          after: null,
          fullTextSearch: searchInput,
          predicates,
          ...(sortOptionFields && { orderBy: sortOptionFields }),
        },
      });
    } catch (e) {
      onError(e);
    } finally {
      isRefetching.current = false;
    }
  };

  const loadMoreItems = async () => {
    try {
      if (
        data?.searchNotifications.pageInfo.endCursor &&
        data?.searchNotifications.pageInfo.hasNextPage
      ) {
        await fetchMoreNotifications({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchNotifications.edges.length > 0) {
              return {
                ...prev,
                searchNotifications: {
                  ...fetchMoreResult.searchNotifications,
                  edges: uniqByKey(
                    [
                      ...(prev.searchNotifications.edges || []),
                      ...fetchMoreResult.searchNotifications.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data.searchNotifications.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleBulkActionsFinish = async () => {
    await notificationsQueryRefetch();
  };

  const handleBulkSelectAll = useCallback(() => {
    onBulkSelectAll(getAllItemsForSelectAll(memoizedNotifications));
  }, [memoizedNotifications, onBulkSelectAll]);

  const isItemLoaded = (value: number) => value < memoizedNotifications.length;

  const handleSetCurrentDateRange = (
    dateRange: { start: Date; end: Date },
    period?: SearchQueryFieldConstraintTimeInLast
  ) => {
    setCurrentDateRange(dateRange);
    setCurrentPeriod(period || undefined);
  };

  // filtering, refetch query with url params
  useEffect(() => {
    void notificationsQueryRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, predicates, sortOptionFields]);

  // mark selected new loaded notifications if allSelected is true
  useEffect(() => {
    if (allSelected) {
      syncAllSelectedItems(getAllItemsForSelectAll(memoizedNotifications));
    }
  }, [allSelected, memoizedNotifications, syncAllSelectedItems]);

  // sync the selected items with the visible items on the list (filter out the ones that are not visible, for example in result of filters changing)
  useEffect(() => {
    if (selectedSet.size) {
      syncSelectedItemsWithVisibleOnes(memoizedNotificationsMap);
    }
  }, [memoizedNotificationsMap, selectedSet.size, syncSelectedItemsWithVisibleOnes]);

  const handleDismiss = (ids: Array<string>) =>
    dismissNotifications({ ids }).then(handleBulkActionsFinish);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (!viewer.admin) {
    return <NoAccessPage />;
  }

  if (
    !isRefetching.current &&
    networkStatus === NetworkStatus.loading &&
    loading &&
    !data?.searchNotifications
  ) {
    return (
      <NotificationsPageLayout>
        <PageLayoutSkeleton />
      </NotificationsPageLayout>
    );
  }

  if (
    !isRefetching.current &&
    networkStatus !== NetworkStatus.refetch &&
    !loading &&
    !data?.searchNotifications
  ) {
    return <NotFoundPage />;
  }

  const hasNoResults =
    searchInput.length > 0 || (predicates.length > 0 && currentPeriod !== INITIAL_PERIOD);

  return (
    <NotificationsContext.Provider
      value={{
        currentDateRange,
        setCurrentDateRange: handleSetCurrentDateRange,
      }}
    >
      <NotificationsPageLayout>
        <FiltersLayout
          predicates={predicates}
          allSelected={allSelected}
          onSelectAll={handleBulkSelectAll}
          onResetAll={onBulkResetAll}
          hasItems={memoizedNotifications.length > 0}
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
          newNotificationsCount={data?.newNotificationsCount}
        >
          {data && !memoizedNotifications.length && (
            <NotificationsEmpty hasNoResults={hasNoResults} />
          )}

          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={memoizedNotifications.length + ITEMS_LIMIT}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={memoizedNotifications.length}
                itemProps={{
                  items: memoizedNotifications,
                  onCheckItem: toggleItem,
                  selectedSet,
                }}
                virtualizedItem={NotificationVirtualizedListItem}
                itemKey={(index) => memoizedNotifications[index].id}
                onItemsRendered={onItemsRendered}
                outerContainerRef={virtualizedListContainerRef}
              />
            )}
          </InfiniteLoader>

          <NotificationsBulkActions
            virtualizedListContainerRef={virtualizedListContainerRef}
            selectedSet={selectedSet}
            notificationsMap={memoizedNotificationsMap}
            onBulkResetAll={onBulkResetAll}
            handleDismissNotifications={handleDismiss}
            onItemDismiss={unselectItem}
            dismissLoading={dismissLoading}
          />
        </FiltersLayout>
      </NotificationsPageLayout>
    </NotificationsContext.Provider>
  );
};

export default Notifications;
