import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type TreeProps = BoxProps & {
  compact?: boolean;
};

const Tree = ({ children, className, compact, ...rest }: TreeProps) => {
  return (
    <Box
      direction="column"
      fullWidth
      {...rest}
      className={cx(styles.tree, compact && styles.compact, className)}
    >
      {children}
    </Box>
  );
};

type TreeBranchProps = BoxProps & {
  isLast?: boolean;
};

export const TreeBranch = ({ children, className, isLast, ...rest }: TreeBranchProps) => {
  return (
    <Box
      direction="column"
      fullWidth
      {...rest}
      className={cx(styles.branch, isLast && styles.last, className)}
    >
      {children}
    </Box>
  );
};

type TreeBranchLinkProps = BoxProps & {
  position?: "top" | "center" | "bottom";
  full?: boolean;
};

export const TreeBranchLink = ({
  children,
  className,
  position = "center",
  full,
  ...rest
}: TreeBranchLinkProps) => {
  return (
    <Box
      fullWidth
      {...rest}
      className={cx(styles.branchLink, styles[position], full && styles.full, className)}
    >
      {children}
    </Box>
  );
};

export default Tree;
