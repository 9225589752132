import { useQuery } from "@apollo/client";

import Box from "ds/components/Box";
import { Maybe, SlackIntegration } from "types/generated";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import useTitle from "hooks/useTitle";

import IntegrationsLayout from "./PageLayout";
import IntegrationsSlack from "./Slack";
import IntegrationsMSTeams from "./MSTeams";
import IntegrationsDataDog from "./DataDog";
import { GET_ACCOUNT_INTEGRATIONS } from "./gql";
import styles from "./styles.module.css";

type GetAccountIntegrationsGql = {
  slackIntegration: Maybe<SlackIntegration>;
};

const Integrations = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery<GetAccountIntegrationsGql>(GET_ACCOUNT_INTEGRATIONS, {
    onError,
  });

  useTitle(`Organization Settings · Integrations · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.slackIntegration) {
    return <PageLoading />;
  }

  return (
    <IntegrationsLayout>
      <Box className={styles.wrapper} gap="large">
        <IntegrationsSlack integration={data?.slackIntegration} />
        <IntegrationsMSTeams />
        <IntegrationsDataDog />
      </Box>
    </IntegrationsLayout>
  );
};

export default Integrations;
