import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { GitHubAppManifest, GitHubAppManifestInput } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GENERATE_GITHUB_APP_MANIFEST } from "./gql";

const useGenerateGitHubAppManifest = () => {
  const { onError } = useTypedContext(FlashContext);

  const [generateGitHubAppManifest, { loading }] = useMutation<{
    githubAppGenerateManifest: GitHubAppManifest;
  }>(GENERATE_GITHUB_APP_MANIFEST, { onError });

  const generateManifest = useCallback(
    (input: GitHubAppManifestInput, callback: (manifest: GitHubAppManifest) => void) => {
      return generateGitHubAppManifest({
        variables: {
          input,
        },
      })
        .then(({ data }) => {
          if (data?.githubAppGenerateManifest) {
            callback(data.githubAppGenerateManifest);
          }
        })
        .catch(onError);
    },
    [generateGitHubAppManifest, onError]
  );

  return {
    generateManifest,
    loading,
  };
};

export default useGenerateGitHubAppManifest;
