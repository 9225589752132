import { ReactNode, useCallback, useRef, useState } from "react";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";
import { Transition } from "react-transition-group";
import { usePreventScroll } from "react-aria";

import useOutsideClick from "hooks/useOutsideClick";
import { withTestId } from "utils/withTestId";
import useAnalytics from "hooks/useAnalytics";

import styles from "./styles.module.css";
import Box from "../Box";
import { DRAWER_INITIAL_WIDTHS, MAX_DRAWER_WIDTH } from "./constants";

export type DrawerProps = {
  visible: boolean;
  children: ReactNode;
  onOutsideClick?: () => void;
  className?: string;
  visibleClassName?: string;
  position?: "absoluteRight" | "fixedRight";
  dataTestId?: string;
  variant?: "normal" | "wide";
  isResizable?: boolean;
  onEnteredCallback?: () => void;
  disablePreventScroll?: boolean;
  mountOnEnter?: boolean;
};

const Drawer = ({
  dataTestId,
  visible,
  children,
  onOutsideClick,
  className,
  visibleClassName,
  position = "fixedRight",
  variant = "normal",
  isResizable = false,
  disablePreventScroll = false,
  mountOnEnter = false,
  onEnteredCallback,
}: DrawerProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const defaultDrawerWidth = DRAWER_INITIAL_WIDTHS[variant];
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleOutsideClick = useCallback(() => {
    if (visible && onOutsideClick) {
      onOutsideClick();
    }
  }, [onOutsideClick, visible]);

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    trackSegmentAnalyticsEvent("Drawer resized", {
      width: drawerWidth,
    });
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      const newWidth = offsetRight;
      if (newWidth > defaultDrawerWidth && newWidth < MAX_DRAWER_WIDTH) {
        setDrawerWidth(newWidth);
      }
    },
    [defaultDrawerWidth]
  );

  usePreventScroll({ isDisabled: !visible || disablePreventScroll });

  useOutsideClick(drawerRef, handleOutsideClick, "mouseup", !!onOutsideClick);

  return (
    <Transition
      nodeRef={drawerRef}
      in={visible}
      timeout={200}
      onEntered={onEnteredCallback}
      mountOnEnter={mountOnEnter}
    >
      {(state) => (
        <Box
          {...withTestId(dataTestId)}
          direction="column"
          fullWidth
          ref={drawerRef}
          className={cx(
            styles.drawer,
            styles[camelCase(`variant-${variant}`)],
            {
              [styles.visible]: visible,
              ...(visibleClassName && { [visibleClassName]: visible }),
            },
            state === "exited" && styles.visibilityHidden,
            position && styles[position],
            isResizable && styles.draggable,
            className
          )}
          style={isResizable ? { width: drawerWidth } : undefined}
        >
          {isResizable && (
            <div
              role="button"
              tabIndex={0}
              onMouseDown={() => handleMouseDown()}
              className={styles.dragger}
            />
          )}

          {visible && children}
        </Box>
      )}
    </Transition>
  );
};

export default Drawer;
