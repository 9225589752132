import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Tile from "ds/components/Tile";
import { EditNote, LogoGitHub } from "components/icons";
import TileTitle from "ds/components/Tile/Title";

import { GitHubEnterpriseIntegrationManageMode } from "./types";

type VCSIntegrationsManageGitHubEnterpriseHeaderProps = {
  integrationManageMode: GitHubEnterpriseIntegrationManageMode;
  setIntegrationManageMode: (mode: GitHubEnterpriseIntegrationManageMode) => void;
};

const VCSIntegrationsManageGitHubEnterpriseHeader = ({
  integrationManageMode,
  setIntegrationManageMode,
}: VCSIntegrationsManageGitHubEnterpriseHeaderProps) => {
  return (
    <Box direction="column" justify="center" align="center" margin="x-large 0 0">
      <Typography variant="p-t4" tag="h3" align="center">
        Do you want to use our wizard or enter the details manually?
      </Typography>

      <Box fullWidth gap="large" margin="x-large 0">
        <Tile
          icon={LogoGitHub}
          title={<TileTitle variant="p-t6"> Set up via wizard</TileTitle>}
          indicator="radio"
          selected={integrationManageMode === "wizard"}
          onClick={() => setIntegrationManageMode("wizard")}
        />

        <Tile
          icon={EditNote}
          title={<TileTitle variant="p-t6"> Set up manually</TileTitle>}
          indicator="radio"
          selected={integrationManageMode === "manual"}
          onClick={() => setIntegrationManageMode("manual")}
        />
      </Box>
    </Box>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseHeader;
