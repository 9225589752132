import { gql } from "@apollo/client";

export const GET_AUDIT_TRAIL_DELIVERY = gql`
  query GetAuditTrailDelivery($key: String!) {
    auditTrailWebhook {
      endpoint
      deliveryDetails(key: $key) {
        requestHeaders {
          name
          values
        }
        requestBody
        responseHeaders {
          name
          values
        }
        responseError
        responseBody
      }
    }
  }
`;

export const GET_WEBHOOK_DELIVERY = gql`
  query GetWebhookDelivery($key: String!, $id: ID!, $stack: ID!) {
    stack(id: $stack) {
      id
      integrations {
        webhook(id: $id) {
          id
          deliveryDetails(key: $key) {
            requestHeaders {
              name
              values
            }
            requestBody
            responseHeaders {
              name
              values
            }
            responseError
            responseBody
          }
        }
      }
    }
  }
`;
