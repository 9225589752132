import { useCallback, useState } from "react";

import ViewHeader from "components/ViewHeader";
import { WorkerPool } from "types/generated";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Button from "ds/components/Button";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { ClockNew, Incognito, MenuSpaces } from "components/icons";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import DocumentationIconButton from "components/DocumentationIconButton";
import Timestamp from "components/time/Timestamp";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { hasSpaceManageAccess } from "utils/user";
import TabWithCounter from "ds/components/Tab/WithCounter";
import { getDocsUrl } from "utils/getDocsUrl";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import CreateWorkerPool from "../WorkerPools/CreateWorkerPool";
import PrivateWorkerPoolActionsDropdown from "./ActionsDropdown";
import PrivateWorkerPoolDetails from "./Details";
import { NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE } from "../WorkerPool/constants";

type PrivateWorkerPoolHeaderProps = {
  workerPool: WorkerPool;
  refetchQuery?: string;
};

const PrivateWorkerPoolHeader = ({ workerPool, refetchQuery }: PrivateWorkerPoolHeaderProps) => {
  const [isEditDrawerVisible, setEditDrawerVisibility] = useState(false);
  const [isDetailsDrawerVisible, setDetailsDrawerVisibility] = useState(false);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const handleEdit = useCallback(() => {
    setEditDrawerVisibility(true);
  }, []);

  const handleEditWorkerPoolFromDetailsDrawer = useCallback(() => {
    setDetailsDrawerVisibility(false);
    setEditDrawerVisibility(true);
  }, []);

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
    setDetailsDrawerVisibility(false);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  const handleBackToDetailsDrawer = () => {
    setDetailsDrawerVisibility(true);
    setFullDescriptionDrawerVisible(false);
  };

  const hasUsers = workerPool.usersCount > 0;
  const hasBusyWorkers = workerPool.busyWorkers > 0;

  const workerPoolUrl = `/worker-pool/${workerPool.id}`;
  const runsCount = workerPool.schedulableRunsCount;
  const hasWarning = workerPool.workersCount === 0 && runsCount > 0;

  const canManageWorkerPools = hasSpaceManageAccess(workerPool.spaceDetails.accessLevel);

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{workerPool.name}</ViewHeaderTitle>
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <DocumentationIconButton
              href={getDocsUrl("/concepts/worker-pools")}
              tooltipText="Go to worker pools documentation"
            />

            <Button
              variant="secondary"
              onClick={() => setDetailsDrawerVisibility(true)}
              active={false}
            >
              Details
            </Button>

            <PrivateWorkerPoolActionsDropdown
              workerPoolId={workerPool.id}
              workerPoolName={workerPool.name}
              canManageWorkerPools={canManageWorkerPools}
              hasUsers={hasUsers}
              hasBusyWorkers={hasBusyWorkers}
              onEdit={handleEdit}
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={workerPool.spaceDetails.name}
              href={`/spaces/${workerPool.spaceDetails.id}`}
            />
            <MetaInfoListItem icon={Incognito}>Private</MetaInfoListItem>
            <MetaInfoListItem icon={ClockNew}>
              <Timestamp timestamp={workerPool.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>

        <ViewHeaderNavigation>
          <TabWithCounter
            exact
            to={{ pathname: workerPoolUrl, state: workerPool }}
            count={workerPool.workersCount}
            label="Workers"
          />

          <TabWithCounter
            to={{ pathname: `${workerPoolUrl}/queue`, state: workerPool }}
            count={runsCount}
            counterExceptionVariant="danger"
            tooltip={hasWarning ? NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE : undefined}
            label="Queue"
          />

          <TabWithCounter
            to={{ pathname: `${workerPoolUrl}/used-by`, state: workerPool }}
            count={workerPool.usersCount}
            label="Used by"
          />
        </ViewHeaderNavigation>
      </ViewHeader>

      <CreateWorkerPool
        workerPool={workerPool}
        isDrawerVisible={isEditDrawerVisible}
        setDrawerVisibility={setEditDrawerVisibility}
        refetchQuery={refetchQuery}
      />

      <PrivateWorkerPoolDetails
        workerPool={workerPool}
        isDrawerVisible={isDetailsDrawerVisible}
        setDrawerVisibility={setDetailsDrawerVisibility}
        onOpenFullDescription={handleOpenFullDescriptionDrawer}
        onEdit={handleEditWorkerPoolFromDetailsDrawer}
        canManageWorkerPools={canManageWorkerPools}
      />

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={workerPool.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
        onBackToDetails={handleBackToDetailsDrawer}
      />
    </>
  );
};

export default PrivateWorkerPoolHeader;
