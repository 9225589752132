const buildGraph = (edges: Array<[string, string]>) => {
  const graph = new Map();

  for (const [u, v] of edges) {
    if (!graph.has(u)) {
      graph.set(u, []);
    }
    if (!graph.has(v)) {
      graph.set(v, []);
    }
    graph.get(u).push(v);
  }

  return graph;
};

const topologicalSort = (edges: Array<[string, string]>) => {
  const graph = buildGraph(edges);
  const inDegree = new Map();
  const zeroInDegreeQueue = [];
  const topologicalOrder = [];

  // Initialize in-degree of each vertex to 0
  for (const u of graph.keys()) {
    inDegree.set(u, 0);
  }

  // Calculate in-degree of each vertex
  for (const u of graph.keys()) {
    for (const v of graph.get(u)) {
      inDegree.set(v, inDegree.get(v) + 1);
    }
  }

  // Collect all vertices with 0 in-degree
  for (const [u, degree] of inDegree.entries()) {
    if (degree === 0) {
      zeroInDegreeQueue.push(u);
    }
  }

  // Process vertices with 0 in-degree
  while (zeroInDegreeQueue.length > 0) {
    const u: string = zeroInDegreeQueue.shift();
    topologicalOrder.push(u);

    for (const v of graph.get(u)) {
      inDegree.set(v, inDegree.get(v) - 1);
      if (inDegree.get(v) === 0) {
        zeroInDegreeQueue.push(v);
      }
    }
  }

  // Check if there was a cycle in the graph
  if (topologicalOrder.length !== graph.size) {
    throw new Error("The graph has at least one cycle, topological sort not possible.");
  }

  return topologicalOrder;
};

export default topologicalSort;
