import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import cx from "classnames";

import ButtonIcon from "ds/components/ButtonIcon";
import FlashContext from "components/FlashMessages/FlashContext";
import CodeEditor from "components/CodeEditor";
import { Policy, PolicyType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";
import { MoveLeft, NavigationChevronDownUp, NavigationChevronUpDown } from "components/icons";
import PageLoading from "components/loading/PageLoading";
import useAnalytics from "hooks/useAnalytics";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import PageInfoHeader from "ds/components/PageInfoHeader";
import { withEnterKeyPress } from "utils/browser";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { beautifyCode } from "utils/strings";
import { EMPTY_POLICY_SAMPLE_RESULTS_MSG } from "shared/Policy/constants";
import { SIMULATE_POLICY } from "shared/Policy/gql";

import styles from "./styles.module.css";
import { GET_POLICY_EVALUATION_SAMPLE, GET_POLICY_EVALUATION_RECORDS } from "./gql";
import { EMPTY_INPUT_MSG } from "./constants";
import PolicyDetailsInputSelect from "./InputSelect";
import PolicyDiffCallout from "../PolicyDiffCallout";

type PolicySimulationProps = {
  body: string;
  policyId: string;
  type: PolicyType;
  isSimulationVisible: boolean;
  setIsSimulationVisible: (value: boolean) => void;
  defaultAnalyticsProperties?: TrackAnalyticsEventProperties;
  className?: string;
  analyticsPage: AnalyticsPagePolicy | AnalyticsPageOrganization;
};

const PolicySimulation = ({
  body,
  type,
  policyId,
  isSimulationVisible,
  setIsSimulationVisible,
  defaultAnalyticsProperties,
  className,
  analyticsPage,
}: PolicySimulationProps) => {
  const { onError, reportError } = useTypedContext(FlashContext);
  const [isInputEditorExpanded, setIsInputEditorExpanded] = useState(false);
  const [isOutputEditorExpanded, setIsOutputEditorExpanded] = useState(false);
  const [sampleInput, setSampleInput] = useState(EMPTY_INPUT_MSG);
  const [simulationOutput, setSimulationOutput] = useState(EMPTY_POLICY_SAMPLE_RESULTS_MSG);
  const [evaluationBody, setEvaluationBody] = useState("");

  const handleInputChange = (value?: string) => {
    setSampleInput(value || "");
  };

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: defaultAnalyticsProperties,
  });

  const [currentPolicyInputKey, setCurrentPolicyInputKey] = useState("");

  const { data } = useQuery<{ policy: Policy }>(GET_POLICY_EVALUATION_RECORDS, {
    variables: {
      policyId,
    },
    onCompleted: (data) => {
      if (data.policy.evaluationRecords.length > 0) {
        setCurrentPolicyInputKey(data.policy.evaluationRecords[0].key);
      }
    },
    onError,
    // APOLLO CLIENT UPDATE
  });

  const { loading: evaluationSampleInputLoading } = useQuery<{ policy: Policy }>(
    GET_POLICY_EVALUATION_SAMPLE,
    {
      variables: {
        policyId,
        key: currentPolicyInputKey,
      },
      skip: !currentPolicyInputKey,
      onCompleted: (data) => {
        if (data.policy.evaluationSample) {
          setSampleInput(beautifyCode(data.policy.evaluationSample.input));
          setEvaluationBody(data.policy.evaluationSample.body);
        } else {
          reportError({ message: "We couldn't find input body for the simulation" });
        }
      },
      onError,
      // APOLLO CLIENT UPDATE
    }
  );

  const [simulatePolicy, { loading: simulationLoading }] = useMutation<{
    policySimulate: string;
  }>(SIMULATE_POLICY, {
    variables: {
      body,
      input: sampleInput,
      type,
    },
    // APOLLO CLIENT UPDATE
    onCompleted: (data) => {
      if (data) {
        setIsInputEditorExpanded(false);
        setSimulationOutput(beautifyCode(data.policySimulate));
      }
    },
    onError,
  });

  const toggleSimulationVisibility = () => {
    trackSegmentAnalyticsEvent("Simulation Panel Click", {
      status: isSimulationVisible ? "close" : "open",
    });
    setIsSimulationVisible(!isSimulationVisible);
  };

  const handleSimulation = () => {
    trackSegmentAnalyticsEvent("Simulate Click");
    simulatePolicy().catch(onError);
  };

  const handleInputKeyChange = (value: string) => {
    setIsOutputEditorExpanded(false);
    setSimulationOutput(EMPTY_POLICY_SAMPLE_RESULTS_MSG);
    setCurrentPolicyInputKey(value);
  };

  const toggleInputEditor = () => {
    if (evaluationSampleInputLoading) return;

    if (isOutputEditorExpanded && !isInputEditorExpanded) {
      setIsOutputEditorExpanded(false);
    }

    trackSegmentAnalyticsEvent("Size Click", {
      section: "input",
      size: isInputEditorExpanded ? "minimize" : "expand",
    });

    setIsInputEditorExpanded(!isInputEditorExpanded);
  };

  const toggleOutputEditor = () => {
    if (evaluationSampleInputLoading) return;

    if (isInputEditorExpanded && !isOutputEditorExpanded) {
      setIsInputEditorExpanded(false);
    }

    trackSegmentAnalyticsEvent("Size Click", {
      section: "output",
      size: isOutputEditorExpanded ? "minimize" : "expand",
    });

    setIsOutputEditorExpanded(!isOutputEditorExpanded);
  };

  const isDiffWarningVisible = evaluationBody && evaluationBody !== body;

  return (
    <Box
      className={cx(styles.simulationWrapper, isSimulationVisible && styles.expanded)}
      direction="column"
    >
      <div
        className={cx(styles.simulationPanel, isSimulationVisible && styles.disabled)}
        role="button"
        tabIndex={0}
        onClick={toggleSimulationVisibility}
        onKeyDown={withEnterKeyPress(toggleSimulationVisibility)}
      >
        <div className={styles.simulationPanelContent}>
          <ButtonIcon
            icon={MoveLeft}
            iconRotate="270"
            disabled={isSimulationVisible}
            variant="ghost"
          >
            Open simulation
          </ButtonIcon>
          <Typography variant="p-t7" tag="span">
            Simulation panel
          </Typography>
        </div>
      </div>
      <Box
        direction="column"
        className={cx(styles.simulationBody, isSimulationVisible && styles.visible, className)}
      >
        <Box direction="column">
          <PageInfoHeader
            title={
              <Box align="center" gap="medium">
                <ButtonIcon
                  onClick={toggleSimulationVisibility}
                  icon={MoveLeft}
                  iconRotate="180"
                  variant="ghost"
                >
                  Hide simulation
                </ButtonIcon>
                Simulation
              </Box>
            }
          />

          {isDiffWarningVisible && (
            <PolicyDiffCallout
              disabledActions={!isSimulationVisible}
              body={body}
              evaluationBody={evaluationBody}
            />
          )}

          <Box
            className={styles.simulationInputWrapper}
            padding="large"
            __deprecatedGap="1rem"
            align="end"
          >
            <PolicyDetailsInputSelect
              inputs={data?.policy?.evaluationRecords || []}
              handleInputKeyChange={handleInputKeyChange}
              currentPolicyInputKey={currentPolicyInputKey}
            />
            <Button variant="contrast" onClick={handleSimulation} loading={simulationLoading}>
              Simulate
            </Button>
          </Box>
        </Box>
        <Box
          // FYI: it allows to change size of editors when diff warning is visible
          key={String(isDiffWarningVisible)}
          direction="column"
          className={styles.editors}
        >
          <Box
            direction="column"
            className={cx(styles.editorSection, {
              [styles.expanded]: isInputEditorExpanded,
              [styles.hidden]: isOutputEditorExpanded,
            })}
          >
            <Box className={styles.editorHeader} justify="between" align="center">
              <Typography color="secondary" tag="span" variant="p-t6">
                Input
              </Typography>
              <ButtonIcon
                disabled={evaluationSampleInputLoading}
                onClick={toggleInputEditor}
                icon={isInputEditorExpanded ? NavigationChevronDownUp : NavigationChevronUpDown}
                variant="ghost"
              >
                {isInputEditorExpanded ? "Collapse" : "Expand"}
              </ButtonIcon>
            </Box>
            <div className={cx(styles.editorWrapper, isOutputEditorExpanded && styles.hidden)}>
              {evaluationSampleInputLoading && <PageLoading />}
              {!evaluationSampleInputLoading && (
                <CodeEditor
                  className={styles.editor}
                  body={sampleInput}
                  onChange={handleInputChange}
                  language="rego"
                />
              )}
            </div>
          </Box>
          <Box
            direction="column"
            className={cx(styles.editorSection, {
              [styles.expanded]: isOutputEditorExpanded,
              [styles.hidden]: isInputEditorExpanded,
            })}
          >
            <Box className={styles.editorHeader} justify="between" align="center">
              <Typography color="secondary" tag="span" variant="p-t6">
                Output
              </Typography>
              <ButtonIcon
                disabled={evaluationSampleInputLoading}
                onClick={toggleOutputEditor}
                icon={isOutputEditorExpanded ? NavigationChevronDownUp : NavigationChevronUpDown}
                variant="ghost"
              >
                {isOutputEditorExpanded ? "Collapse" : "Expand"}
              </ButtonIcon>
            </Box>
            <div className={cx(styles.editorWrapper, isInputEditorExpanded && styles.hidden)}>
              <CodeEditor
                className={styles.editor}
                readOnly
                body={simulationOutput}
                language="json"
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PolicySimulation;
