import PageWrapperTitle from "components/PageWrapper/Title";
import Box from "ds/components/Box";
import TooltipInfo from "ds/components/TooltipInfo";

type ModuleVersionPageIntoTitleProps = {
  title: string;
  tooltip: string;
};

const ModuleVersionPageIntoTitle = ({ title, tooltip }: ModuleVersionPageIntoTitleProps) => {
  return (
    <Box align="center" gap="medium">
      <PageWrapperTitle>{title}</PageWrapperTitle>
      <TooltipInfo>{tooltip}</TooltipInfo>
    </Box>
  );
};

export default ModuleVersionPageIntoTitle;
