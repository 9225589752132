import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  BitbucketDatacenterIntegration,
  MutationBitbucketDatacenterIntegrationCreateArgs,
} from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { CREATE_BITBUCKET_DATA_CENTER_INTEGRATION } from "./gql";

const useCreateBitbucketDataCenterIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [bitbucketDatacenterIntegrationCreate, { loading }] = useMutation<{
    bitbucketDatacenterIntegrationCreate: BitbucketDatacenterIntegration;
  }>(CREATE_BITBUCKET_DATA_CENTER_INTEGRATION, {
    onError,
  });

  const createBitbucketDataCenterIntegration = useCallback(
    (input: MutationBitbucketDatacenterIntegrationCreateArgs, successCallback?: () => void) => {
      bitbucketDatacenterIntegrationCreate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.bitbucketDatacenterIntegrationCreate) {
            reportSuccess({
              message: `Integration ${data.bitbucketDatacenterIntegrationCreate.name} was successfully created`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Bitbucket DC - Setup Finished", {
              labels: input.customInput?.labels?.length,
              space: input.customInput?.spaceID,
            });
          }
        })
        .catch(onError);
    },
    [bitbucketDatacenterIntegrationCreate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    createBitbucketDataCenterIntegration,
    loading,
  };
};

export default useCreateBitbucketDataCenterIntegration;
