import { gql } from "@apollo/client";

export const UPDATE_BILLING_INFO = gql`
  mutation UpdateBillingInfo($input: SubscriptionInput!) {
    billingSubscriptionUpdateInfo(input: $input) {
      billingInfo {
        address
        city
        companyName
        country
        email
        fullName
        state
        zipCode
      }
    }
  }
`;
