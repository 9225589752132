import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useCallback } from "react";

import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Tile from "ds/components/Tile";
import { Home, LogoGitHub } from "components/icons";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { validateRequiredURL } from "utils/formValidators";
import { VcsProvider } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import TileTitle from "ds/components/Tile/Title";

import VCSIntegrationsDocumentationButton from "../../components/DocumentationButton";
import VCSIntegrationsBackButton from "../../components/BackButton";
import VCSIntegrationsContinueButton from "../../components/ContinueButton";
import {
  ManageGitHubEnterpriseWizardFormFields,
  ManageGitHubEnterpriseWizardIntegration,
} from "./types";
import { isIntegrationStepValid } from "./helpers";

type VCSIntegrationsManageGitHubEnterpriseWizardStepIntegrationProps = {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

const VCSIntegrationsManageGitHubEnterpriseWizardStepIntegration = ({
  goToNextStep,
  goToPreviousStep,
}: VCSIntegrationsManageGitHubEnterpriseWizardStepIntegrationProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const { control, register, formState } = useFormContext<ManageGitHubEnterpriseWizardFormFields>();

  const integrationChoice = useWatch({
    name: "integration",
    control,
  });

  const isStepValid = isIntegrationStepValid(integrationChoice, formState);

  const trackWizardTypeContinueClick = useCallback(() => {
    trackSegmentAnalyticsEvent("GitHub Wizard - Type Continue Click", { type: integrationChoice });
  }, [integrationChoice, trackSegmentAnalyticsEvent]);

  const handleGoToNextStep = useCallback(() => {
    goToNextStep();
    trackWizardTypeContinueClick();
  }, [goToNextStep, trackWizardTypeContinueClick]);

  return (
    <>
      <FullScreenModalBody gap="x-large">
        <Typography variant="p-t4" tag="h3" align="center">
          Are you integrating with GitHub.com or a self-hosted installation?
        </Typography>

        <Box fullWidth gap="large">
          <Controller
            name="integration"
            control={control}
            render={({ field }) => (
              <>
                <Tile
                  icon={LogoGitHub}
                  title={<TileTitle variant="p-t6">GitHub.com</TileTitle>}
                  indicator="radio"
                  selected={field.value === ManageGitHubEnterpriseWizardIntegration.GITHUB}
                  onClick={() => field.onChange(ManageGitHubEnterpriseWizardIntegration.GITHUB)}
                />

                <Tile
                  icon={Home}
                  title={<TileTitle variant="p-t6">Self-hosted</TileTitle>}
                  indicator="radio"
                  selected={field.value === ManageGitHubEnterpriseWizardIntegration.SELF_HOSTED}
                  onClick={() =>
                    field.onChange(ManageGitHubEnterpriseWizardIntegration.SELF_HOSTED)
                  }
                />
              </>
            )}
          />
        </Box>

        {integrationChoice === ManageGitHubEnterpriseWizardIntegration.SELF_HOSTED && (
          <>
            <FormField label="URL of installation" error={formState.errors?.host?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter URL of installation here"
                  error={!!formState.errors?.host}
                  {...register("host", {
                    validate: validateRequiredURL({ urlHttps: true }),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormField label="API URL" error={formState.errors?.apiHost?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter API URL here"
                  error={!!formState.errors?.apiHost}
                  {...register("apiHost", {
                    validate: validateRequiredURL({ urlHttps: true }),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </>
        )}
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.GithubEnterprise} />

        <Box gap="medium">
          <VCSIntegrationsBackButton onClick={goToPreviousStep} />

          <VCSIntegrationsContinueButton onClick={handleGoToNextStep} disabled={!isStepValid} />
        </Box>
      </FullScreenModalFooter>
    </>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseWizardStepIntegration;
