import { ReactNode } from "react";

import Box from "ds/components/Box";

type ViewHeaderNavigationProps = {
  children: ReactNode;
};

const ViewHeaderNavigation = ({ children }: ViewHeaderNavigationProps) => {
  return (
    <Box align="center" justify="start" margin="medium 0 0" gap="medium" fullWidth>
      {children}
    </Box>
  );
};

export default ViewHeaderNavigation;
