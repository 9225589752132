import { gql } from "@apollo/client";

export const GET_STACK_ANSIBLE_HOSTS = gql`
  query GetStackAnsibleHosts($input: RunOrStackArgs) {
    ansibleHosts(input: $input) {
      id
      name
      taskExecutions {
        task {
          path
          name
          playbook {
            name
            path
          }
          role {
            name
            path
          }
        }
        status
        timestamp
        checkMode
        stdout
        diff
        stackId
        runId
      }
    }
  }
`;
