import { type ReactNode } from "react";

import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";

import { BulkActionsVariant } from "../../types";
import { BUTTON_PROPS_BY_VARIANT } from "../helpers";

type BulkActionsChooseActionStepActionMoreDropdownProps = {
  variant: BulkActionsVariant;
  children: ReactNode;
};

const BulkActionsChooseActionStepActionMoreDropdown = ({
  variant,
  children,
}: BulkActionsChooseActionStepActionMoreDropdownProps) => {
  const buttonProps = BUTTON_PROPS_BY_VARIANT[variant];
  return (
    <DropdownMenuEllipsis
      tooltip="More bulk actions"
      buttonVariant={buttonProps.variant}
      dotsSize={buttonProps.size}
    >
      {children}
    </DropdownMenuEllipsis>
  );
};

export default BulkActionsChooseActionStepActionMoreDropdown;
