import { ReactNode } from "react";
import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type BreadcrumbsConfirmationModalProps = {
  content: ReactNode;
  onConfirm: () => void;
};

const BreadcrumbsConfirmationModal = create(function BreadcrumbsConfirmationModal({
  onConfirm,
  content,
}: BreadcrumbsConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    modal.resolve();
    modal.hide();
    onConfirm();
  };

  return (
    <ConfirmationModal
      title="Are you sure you want to go back?"
      confirmationButtonLabel="Go back"
      confirmationButtonVariant="dangerPrimary"
      onConfirm={handleOnConfirm}
      size="large"
    >
      <Typography tag="p" variant="p-body2">
        {content}
      </Typography>
    </ConfirmationModal>
  );
});

export const showBreadcrumbsConfirmationModal = (props: BreadcrumbsConfirmationModalProps) =>
  show(BreadcrumbsConfirmationModal, props);
