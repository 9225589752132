import cx from "classnames";

import Icon from "ds/components/Icon";
import FloatingTooltip from "ds/components/Tooltip";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import { transformDeltaCount } from "./helpers";
import { DeltaType } from "./types";

type DeltaCountsCellProps = {
  count: number;
  icon?: IconComponent;
  label: string;
  type: DeltaType;
};

const DeltaCountsCell = ({ count, label, icon, type }: DeltaCountsCellProps) => {
  return (
    <FloatingTooltip
      on={({ className, ...props }) => (
        <span
          {...props}
          className={cx(
            styles.cell,
            styles[type],
            count > 0 && styles.colored,
            !icon && styles.noIcon,
            className
          )}
        >
          {icon && <Icon src={icon} className={styles.icon} />}
          <span aria-hidden>{transformDeltaCount(count)}</span>
        </span>
      )}
    >
      {label}
    </FloatingTooltip>
  );
};

export default DeltaCountsCell;
