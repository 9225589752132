import { gql } from "@apollo/client";

export const GET_CHANGES = gql`
  query GetRunChanges(
    $stackId: ID!
    $runId: ID!
    $changeType: EntityChangeType!
    $phaseType: EntityChangePhase!
    $limit: Int
    $offset: String
  ) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        title
        changesV2(
          input: { first: $limit, changeType: $changeType, changePhase: $phaseType, after: $offset }
        ) {
          edges {
            cursor
            node {
              entity {
                address
                id
                name
              }
              type
              phase
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;
