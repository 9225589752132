import { useCallback } from "react";

import { Chevron } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";

import { CodeChangesChangeItem } from "../types";
import styles from "../styles.module.css";
import Row from "./Row";
import { isArray, hasChildren } from "../helpers";
import Label from "./Label";
import { CodeChangesContext } from "../CodeChangesContext";
import { braceClose, braceOpen, bracketClose, bracketOpen, ellipsis } from "../constants";

type GroupProps = {
  item: CodeChangesChangeItem;
};

const Group = ({ item }: GroupProps) => {
  const { collapsedGroups, toggleGroup } = useTypedContext(CodeChangesContext);

  const handleExpansion = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      if (
        e.type === "click" ||
        (e.type === "keydown" && (e as React.KeyboardEvent).code === "Space")
      ) {
        toggleGroup(item.id);
      }
    },
    [item.id, toggleGroup]
  );

  const isCollapsed = collapsedGroups.has(item.id);
  const childIsArray = isArray(item);
  const openGroup = childIsArray ? bracketOpen : braceOpen;
  const closeGroup = childIsArray ? bracketClose : braceClose;

  return (
    <Box direction="column" gap="small">
      {hasChildren(item) ? (
        <>
          <Box
            align="center"
            gap="medium"
            className={styles.codeChangesGroupLabelRow}
            tabIndex={0}
            onKeyDown={handleExpansion}
            onClick={handleExpansion}
            role="button"
          >
            <Icon
              className={styles.codeChangesChevron}
              src={Chevron}
              rotate={isCollapsed ? "270" : undefined}
            />
            <Label keyName={item.key} />
            <span>{openGroup}</span>
            {isCollapsed && (
              <>
                <span className={styles.codeChangesEllipsis}>{ellipsis}</span>
                <span>{closeGroup}</span>
                <span className={styles.codeChangesGroupCount}>
                  {item.children.length} {item.children.length === 1 ? "item" : "items"}
                </span>
              </>
            )}
          </Box>
          {!isCollapsed && (
            <>
              <Box
                direction="column"
                gap="small"
                margin="0 0 0 small"
                padding="0 0 0 x-large"
                className={styles.codeChangesGroupContent}
              >
                {item.children.map((child) => (
                  <Group key={child.id} item={child} />
                ))}
              </Box>
              <div className={styles.codeChangesGroupClosingRow}>
                <span>{closeGroup}</span>
              </div>
            </>
          )}
        </>
      ) : (
        // TODO: Study using subgrid to align the values in the row
        <Row item={item} />
      )}
    </Box>
  );
};

export default Group;
