import { useMutation, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { Session } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import Button from "ds/components/Button";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";

import { GET_SESSIONS, SESSION_DELETE_ALL } from "./gql";
import Item from "./Item";
import styles from "./styles.module.css";
import { showSessionsDeactivateAllConfirmation } from "./DeactivateAllConfirmation";

const Sessions = () => {
  const { accountName } = useTypedContext(AccountContext);
  const { reportSuccess, onError } = useTypedContext(FlashContext);

  useTitle(`Organization Settings · Sessions · ${accountName}`);

  const { data, loading, error } = useQuery<{ sessions: Session[] }>(GET_SESSIONS, { onError });
  const [sessionDeleteAll, { loading: sessionDeleteAllLoading }] = useMutation<{
    deletedSessions: Session[];
  }>(SESSION_DELETE_ALL, { refetchQueries: ["GetSessions"] });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const handleDeactivation = () => {
    const asyncFn = async () => {
      try {
        await sessionDeleteAll();

        reportSuccess({ message: `Sessions were deactivated` });
      } catch (error) {
        onError(error);
      }
    };

    void asyncFn();
  };

  const onModalShow = () => {
    showSessionsDeactivateAllConfirmation({ onConfirm: handleDeactivation });
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" justify="between" align="center" fullWidth>
          <ViewHeaderTitle tag="h2">Sessions</ViewHeaderTitle>

          <Button variant="dangerSecondary" loading={sessionDeleteAllLoading} onClick={onModalShow}>
            Deactivate all sessions
          </Button>
        </ViewHeaderWrapper>
      </ViewHeader>

      <div className={styles.wrapper}>
        {loading && <PageLoading />}

        {data?.sessions.map((session) => <Item key={session.id} session={session} />)}
      </div>
    </>
  );
};

export default Sessions;
