import BulkActionsChooseActionStep from "components/BulkActions/ChooseActionStep";
import BulkActionsChooseActionStepActionItem from "components/BulkActions/ChooseActionStep/ActionItem";
import BulkActionsChooseActionStepActionMoreDropdown from "components/BulkActions/ChooseActionStep/ActionMoreDropdown";
import BulkActionsChooseActionStepActionMoreDropdownItem from "components/BulkActions/ChooseActionStep/ActionMoreDropdown/Item";
import BulkActionsChooseActionStepEmpty from "components/BulkActions/ChooseActionStep/Empty";
import { BulkActionsApplicableAction } from "components/BulkActions/ChooseActionStep/types";
import { BulkActionsCloseMethod, BulkActionsVariant } from "components/BulkActions/types";

type BulkActionsPatternsActionsListProps<Action extends string> = {
  variant: BulkActionsVariant;
  availableActions: Array<BulkActionsApplicableAction<Action>>;
  onEmptyActionsCancel?: (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => void;
};

const BulkActionsPatternsActionsList = <Action extends string>({
  variant,
  availableActions,
  onEmptyActionsCancel,
}: BulkActionsPatternsActionsListProps<Action>) => {
  const handleCancel = onEmptyActionsCancel
    ? () => onEmptyActionsCancel(variant, BulkActionsCloseMethod.EmptyActionsButton)
    : undefined;

  return (
    <BulkActionsChooseActionStep variant={variant} availableActions={availableActions}>
      {({ visibleItems, hiddenItems }) => (
        <>
          {!availableActions.length && (
            <BulkActionsChooseActionStepEmpty onCancel={handleCancel} variant={variant} />
          )}
          {visibleItems.map(({ action, title, ...restActionProps }) => (
            <BulkActionsChooseActionStepActionItem
              key={action}
              action={action}
              variant={variant}
              {...restActionProps}
            >
              {title}
            </BulkActionsChooseActionStepActionItem>
          ))}
          {hiddenItems.length > 0 && (
            <BulkActionsChooseActionStepActionMoreDropdown variant={variant}>
              {hiddenItems.map(({ action, title, ...restActionProps }) => (
                <BulkActionsChooseActionStepActionMoreDropdownItem
                  key={action}
                  action={action}
                  variant={variant}
                  {...restActionProps}
                >
                  {title}
                </BulkActionsChooseActionStepActionMoreDropdownItem>
              ))}
            </BulkActionsChooseActionStepActionMoreDropdown>
          )}
        </>
      )}
    </BulkActionsChooseActionStep>
  );
};

export default BulkActionsPatternsActionsList;
