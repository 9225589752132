import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { MutationGithubAppCreateFromManifestArgs } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { CREATE_GITHUB_APP_FROM_MANIFEST } from "./gql";

const useCreateGitHubAppFromManifest = () => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [createGitHubAppFromManifest, { loading }] = useMutation(CREATE_GITHUB_APP_FROM_MANIFEST, {
    onError,
  });

  const createGitHubApp = useCallback(
    (variables: MutationGithubAppCreateFromManifestArgs, successCallback?: () => void) => {
      createGitHubAppFromManifest({
        variables,
      })
        .then(({ data }) => {
          if (data?.githubAppCreateFromManifest) {
            reportSuccess({ message: "New integration was created" });
            successCallback?.();

            trackSegmentAnalyticsEvent("GitHub Wizard - Setup Finished");
          }
        })
        .catch(onError);
    },
    [createGitHubAppFromManifest, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return { createGitHubApp, loading };
};

export default useCreateGitHubAppFromManifest;
