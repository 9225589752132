import { FunctionComponent, SVGProps } from "react";

import {
  LogoCloudformation,
  LogoKubernetes,
  LogoAnsible,
  LogoOpenTofu,
  LogoPulumi,
  LogoTerraform,
  LogoTerragrunt,
} from "components/icons";

// TODO: [dashboard] those colors will be changed because of accesibility issues
export const vendors: Record<
  string,
  {
    logo: FunctionComponent<SVGProps<SVGSVGElement>>;
    color: string;
    activeColor: string;
    inactiveColor: string;
  }
> = {
  Terraform: {
    logo: LogoTerraform,
    color: "var(--semantic-color-surface-data-vendor-terraform-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-terraform-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-terraform-inactive)",
  },
  Ansible: {
    logo: LogoAnsible,
    color: "var(--semantic-color-surface-data-vendor-ansible-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-ansible-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-ansible-inactive)",
  },
  CloudFormation: {
    logo: LogoCloudformation,
    color: "var(--semantic-color-surface-data-vendor-cloudformation-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-cloudformation-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-cloudformation-inactive)",
  },
  Kubernetes: {
    logo: LogoKubernetes,
    color: "var(--semantic-color-surface-data-vendor-kubernetes-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-kubernetes-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-kubernetes-inactive)",
  },
  Pulumi: {
    logo: LogoPulumi,
    color: "var(--semantic-color-surface-data-vendor-pulumi-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-pulumi-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-pulumi-inactive)",
  },
  OpenTofu: {
    logo: LogoOpenTofu,
    color: "var(--semantic-color-surface-data-vendor-opentofu-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-opentofu-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-opentofu-inactive)",
  },
  Terragrunt: {
    logo: LogoTerragrunt,
    color: "var(--semantic-color-surface-data-vendor-terragrunt-enabled)",
    activeColor: "var(--semantic-color-surface-data-vendor-terragrunt-hovered)",
    inactiveColor: "var(--semantic-color-surface-data-vendor-terragrunt-inactive)",
  },
};
