import { useRef, MouseEvent, useMemo } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import Tag from "ds/components/Tag";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownPortalled from "ds/components/Dropdown/DropdownPortalled";
import { useTagsList } from "components/TagsList/useTagsList";
import { TagsListProps } from "components/TagsList/types";
import TagsListExpander from "components/TagsList/Expander";
import TagsListWrapper from "components/TagsList/Wrapper";
import { LABEL_FOLDER_KEY } from "constants/labels";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";

type TagsListFilterableProps = {
  applyFolderFilter?: (value: string) => void;
  applyLabelFilter: (value: string) => void;
} & TagsListProps;

const TagsListFilterable = ({
  tags,
  onExpand,
  className,
  icon,
  alwaysExpanded = false,
  applyFolderFilter,
  applyLabelFilter,
}: TagsListFilterableProps) => {
  const portalRef = useRef<HTMLDivElement>(null);
  const memoizedTags = useMemo(() => tags, [tags]);

  const { labelsWrappersRef, isExpanded, toggleLabelsVisibility } = useTagsList(memoizedTags, {
    alwaysExpanded,
    onExpand,
  });

  const tagOnClickHandler = (tag: string, callback: () => void) => {
    const isFolder = tag.startsWith(LABEL_FOLDER_KEY);

    if (isFolder && applyFolderFilter) {
      applyFolderFilter(tag.replace(LABEL_FOLDER_KEY, ""));
    } else {
      applyLabelFilter(tag);
    }

    callback();
  };

  if (tags.length === 0) {
    return null;
  }

  return (
    <Box
      ref={labelsWrappersRef}
      direction="row"
      justify="between"
      className={cx(styles.tagsListWrapper, className)}
      fullWidth
    >
      <div className={styles.portal} ref={portalRef} />

      <TagsListWrapper isExpanded={isExpanded}>
        {memoizedTags.map((tag) => (
          <DropdownPortalled
            isAbsolute
            key={tag}
            portalElementRef={portalRef}
            position="bottomRight"
            renderTriggerComponent={({ onClick, ref }) => (
              <Tag
                ref={ref}
                key={tag}
                tag={tag}
                onClick={(e) => onClick(e as MouseEvent<Element>)}
                data-tags="tagField"
                icon={icon}
              />
            )}
          >
            {({ closeDropdown }) => {
              return (
                <DropdownSection>
                  <DropdownSectionItem onClick={() => tagOnClickHandler(tag, closeDropdown)}>
                    Add to filters
                  </DropdownSectionItem>
                </DropdownSection>
              );
            }}
          </DropdownPortalled>
        ))}
      </TagsListWrapper>

      <TagsListExpander
        toggleLabelsVisibility={toggleLabelsVisibility}
        isExpanded={isExpanded}
        alwaysExpanded={alwaysExpanded}
      />
    </Box>
  );
};

export default TagsListFilterable;
