import { gql } from "@apollo/client";

import actionFields from "views/shared/run/ActionButtons/ActionFragment";

export const GET_VERSION_RUN = gql`
  query GetVersionRun($moduleId: ID!, $runId: ID!, $versionId: ID!) {
    module(id: $moduleId) {
      id
      version(id: $versionId) {
        id
        number
      }
      run(id: $runId) {
        ...actionFields
        branch
        command
        canRetry
        retryBlocker
        isPrioritized
        createdAt
        comments {
          __typename
          body
          createdAt
          username
        }
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
        }
        needsApproval
        expectFailure
        expired
        isMostRecent
        reviews {
          id
          author
          decision
          note
          timestamp
        }
        planPoliciesOutcomes {
          id
          deny
          warn
          createdAt
        }
        policyReceipts {
          id
          createdAt
          policyName
          policyType
          policySlug
          outcome
          sampled
          sampleExpired
          flags
        }
        runtimeConfig {
          terraform {
            version
          }
        }
        title
        triggeredBy
        type
      }
    }
  }
  ${actionFields}
`;
