import Callout from "ds/components/Callout";
import Button from "ds/components/Button";
import FeedbackActions from "ds/components/Feedback/Actions";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { USERS_MANAGEMENT_STRATEGY_INFO_KEY } from "./constants";

type UserManagementWarningCalloutContentProps = {
  analyticsPage?:
    | AnalyticsPageOrganization.OrganizationUsers
    | AnalyticsPageOrganization.OrganizationIdpGroupMapping
    | AnalyticsPageOrganization.OrganizationApiKeys;
};

const UserManagementWarningCalloutContent = ({
  analyticsPage,
}: UserManagementWarningCalloutContentProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  return (
    <Callout
      variant="warning"
      title="User management is inactive"
      storageKey={USERS_MANAGEMENT_STRATEGY_INFO_KEY}
      closeCallback={() => trackSegmentAnalyticsEvent?.("Callout Close")}
    >
      Change management strategy to user management to send invites and activate access rules
      <FeedbackActions>
        <Button
          to="/settings/management-strategy/"
          analyticsPage={analyticsPage}
          analyticsTitle="Callout Strategy Click"
          variant="contrast"
          size="small"
        >
          Choose strategy
        </Button>
      </FeedbackActions>
    </Callout>
  );
};

export default UserManagementWarningCalloutContent;
