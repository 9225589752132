import { createContext } from "react";

import { Module, Run as RunT, Stack } from "types/generated";

type RunContextType = {
  run: RunT;
  stack: Stack | Module;
  versionId?: string;
};

export const RunContext = createContext<RunContextType | undefined>(undefined);
RunContext.displayName = "RunContext";
