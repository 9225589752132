import { gql } from "@apollo/client";

export const GET_DEBUG_INFO = gql`
  query GetDebugInfo {
    debugInfo {
      commitSha
      selfHostedVersion
      selfHostedLicence {
        validity {
          begin
          end
        }
      }
    }
  }
`;
