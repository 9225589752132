import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationWorkerDrainSetArgs, Worker } from "types/generated";

type FetchWorkerDrainSetData = { workerDrainSet: Worker };
export const SET_WORKER_DRAIN: TypedDocumentNode<
  FetchWorkerDrainSetData,
  MutationWorkerDrainSetArgs
> = gql`
  mutation SetWorkerDrain($workerPool: ID!, $id: ID!, $drain: Boolean!) {
    workerDrainSet(workerPool: $workerPool, id: $id, drain: $drain) {
      id
      busy
      drained
    }
  }
`;
