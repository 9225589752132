import { gql } from "@apollo/client";

export const GET_AZURE_DEVOPS_INTEGRATION = gql`
  query GetAzureDevOpsIntegration($id: ID!) {
    azureDevOpsRepoIntegration(id: $id) {
      vcsChecks
      description
      id
      labels
      name
      isDefault
      space {
        id
        name
        accessLevel
      }
      organizationURL
      userFacingHost
      webhookUrl
    }
  }
`;
