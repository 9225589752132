import { useMemo } from "react";

import CodeEditor from "components/CodeEditor";
import { WebhookDeliveryDetails } from "types/generated";
import { isJson } from "utils/isJson";

import styles from "./styles.module.css";

type WebhookDetailsProps = {
  headers?: WebhookDeliveryDetails["responseHeaders"] | WebhookDeliveryDetails["requestHeaders"];
  body?: WebhookDeliveryDetails["responseBody"] | WebhookDeliveryDetails["requestBody"];
  error?: WebhookDeliveryDetails["responseError"];
};

const WebhookDetailsResponse = ({ headers, body, error }: WebhookDetailsProps) => {
  const headerData = useMemo(() => {
    return (
      headers &&
      [...headers]
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce(
          (acc, header, index) => `${acc}${index > 0 ? "\n" : ""}${header.name}: ${header.values}`,
          ""
        )
    );
  }, [headers]);

  const bodyData = useMemo(() => {
    if (!body) {
      return;
    }

    if (isJson(body)) {
      return JSON.stringify(JSON.parse(body), null, 2);
    }

    return body;
  }, [body]);

  return (
    <div>
      {headerData && (
        <>
          <div className={styles.header}>Headers</div>
          <CodeEditor
            body={headerData}
            language={"yaml"}
            options={{
              lineNumbers: "off",
              glyphMargin: false,
            }}
            readOnly
            autoHeight
          />
        </>
      )}
      {bodyData && (
        <>
          <div className={styles.header}>Payload</div>
          <CodeEditor
            body={bodyData}
            language={isJson(bodyData) ? "json" : "html"}
            options={{
              glyphMargin: false,
              lineNumbers: "off",
            }}
            readOnly
            autoHeight
          />
        </>
      )}
      {error && (
        <>
          <div className={styles.header}>Error</div>
          <CodeEditor
            body={error}
            language={"plaintext"}
            options={{
              lineNumbers: "off",
              glyphMargin: false,
            }}
            readOnly
            autoHeight
          />
        </>
      )}
    </div>
  );
};

export default WebhookDetailsResponse;
