import { FormEventHandler, ReactNode } from "react";

import Box from "ds/components/Box";

type StackSettingsIntegrationsCloudAttachFormWrapperProps = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  children: ReactNode;
};

const StackSettingsIntegrationsCloudAttachFormWrapper = ({
  onSubmit,
  children,
}: StackSettingsIntegrationsCloudAttachFormWrapperProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Box direction="column" gap="x-large">
        {children}
      </Box>
    </form>
  );
};

export default StackSettingsIntegrationsCloudAttachFormWrapper;
