import { RunIgnoredTrigger, Stack } from "types/generated";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import BadgeNext from "ds/components/BadgeNext";
import { Invisible } from "components/icons";
import ListEntitiesItem from "components/ListEntitiesItem";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import StackIgnoredRunsElementReason from "./Reason";

type StackIgnoredRunsElementProps = {
  stack: Stack;
  run: RunIgnoredTrigger;
};

const StackIgnoredRunsElement = ({ run }: StackIgnoredRunsElementProps) => (
  <ListEntitiesItem
    direction="row"
    align="center"
    grid
    gridTemplate={COLUMN_ORDER}
    gap={COLUMN_GAP}
    padding="x-large"
  >
    <Box>
      <BadgeNext startIcon={Invisible} variant="gray" type="strong" text="Ignored" />
    </Box>
    <Typography tag="p" variant="p-body2">
      <StackIgnoredRunsElementReason ignoredRun={run} />
    </Typography>
  </ListEntitiesItem>
);

export default StackIgnoredRunsElement;
