import {
  Stack,
  StackConfigVendorInput,
  StackConfigVendorTerragrunt,
  TerragruntTool,
} from "types/generated";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";
import { stackValidator } from "utils/formValidators";
import { VersionInputOption, VERSION_INPUT_TYPE } from "components/FormFields/VersionInput/types";
import { VENDOR_DICTIONARY, VENDOR_KEY } from "constants/vendor";

import { type StackSettingsTerragruntVendorFormFields } from "./Edit";

export const VERSION_EMPTY_VALUE: VersionInputOption = {
  value: "",
  type: VERSION_INPUT_TYPE.SPECIFIC,
};

export const getFormDefaultValues = (
  stackSettings: Stack
): StackSettingsTerragruntVendorFormFields => {
  const config = stackSettings.vendorConfig as StackConfigVendorTerragrunt;
  const isManualMode = config.tool === TerragruntTool.ManuallyProvisioned;

  let toolVersion = VERSION_EMPTY_VALUE;

  if (!isManualMode) {
    toolVersion = {
      type: config.effectiveVersions?.effectiveTerraformVersion
        ? VERSION_INPUT_TYPE.RANGE
        : VERSION_INPUT_TYPE.SPECIFIC,
      value: config.terraformVersion || config.effectiveVersions?.effectiveTerraformVersion || "",
    };
  }

  return {
    terragruntVersion: {
      type: config.effectiveVersions?.effectiveTerragruntVersion
        ? VERSION_INPUT_TYPE.RANGE
        : VERSION_INPUT_TYPE.SPECIFIC,
      value: config.terragruntVersion || config.effectiveVersions?.effectiveTerragruntVersion || "",
    },
    tool: config.tool,
    toolVersion,
    useSmartSanitization: config.useSmartSanitization,
    useRunAll: config.useRunAll,
  };
};

export const getValidateTerragruntVersion =
  (terragruntVersions: string[] = []) =>
  (version: VersionInputOption, formValues: StackSettingsTerragruntVendorFormFields) => {
    if (!version.value.trim()) {
      return "Version is required";
    }

    const rangeError =
      formValues.terragruntVersion.type === VERSION_INPUT_TYPE.RANGE &&
      stackValidator.validateVersionRange(
        version.value,
        terragruntVersions,
        VENDOR_DICTIONARY.TERRAGRUNT
      );

    return rangeError || true;
  };

export const getValidateToolVersion =
  (toolVersions: string[] = [], toolName: string) =>
  (version: VersionInputOption, formValues: StackSettingsTerragruntVendorFormFields) => {
    if (!version.value.trim()) {
      return "Version is required";
    }

    const sanitizationError = stackValidator.validateUseSmartSanitizationField(
      formValues.useSmartSanitization,
      version.value,
      toolVersions
    );

    let rangeError;
    if (formValues.toolVersion?.type === VERSION_INPUT_TYPE.RANGE) {
      rangeError = stackValidator.validateVersionRange(version.value, toolVersions, toolName);
    }

    return sanitizationError || rangeError || true;
  };

export const mapCreateStackVendorUpdateInput = (
  formData: StackSettingsTerragruntVendorFormFields
): StackConfigVendorInput => ({
  ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
  [VENDOR_KEY.TERRAGRUNT]: {
    ...formData,
    terragruntVersion: formData.terragruntVersion.value,
    terraformVersion: formData.toolVersion.value || null,
  },
});
