import { Fragment } from "react";

import { Folder } from "components/icons";
import { LABEL_FOLDER_KEY } from "constants/labels";
import Icon from "ds/components/Icon";

export const normalizeTags = (tags: string[], readOnly: boolean) => {
  if (readOnly) {
    return tags.map((tag) =>
      tag.startsWith(LABEL_FOLDER_KEY) ? (
        <Fragment key={tag}>
          <Icon src={Folder} className="react-tag-input__tag__icon" />{" "}
          {tag.replace(LABEL_FOLDER_KEY, "")}
        </Fragment>
      ) : (
        tag
      )
    ) as string[];
  }

  return tags;
};
