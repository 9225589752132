import { useState } from "react";

import PageInfo from "components/PageWrapper/Info";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import Button from "ds/components/Button";
import useGetAttachedPolicies from "shared/Policy/useGetAttachedPolicies";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import { MagnetColored, PolicyColored } from "components/icons";
import CollapsibleSectionPanel from "components/CollapsibleSectionPanel";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";

import { ModuleContext } from "../Context";
import PoliciesAttachDrawer from "./AttachDrawer";
import ModulePoliciesListItem from "./ListItem";
import { EMPTY_STATE_CAPTION } from "./constants";

const ModulePolicies = () => {
  const { module, canManageModule } = useTypedContext(ModuleContext);
  const [isAttachDrawerActive, setAttachDrawerActive] = useState(false);

  const handleCloseAttachDrawer = () => setAttachDrawerActive(false);

  useTitle(`Policies · ${module.name}`);

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: module.id,
    },
  ]);

  const {
    manuallyAttachedPolicies,
    autoAttachedPolicies,
    allAttachedPoliciesSet,
    isPageLoading,
    isPageNotFound,
    error,
  } = useGetAttachedPolicies({
    entityId: module.id,
    entityType: "module",
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PageInfo title="Policies">
        {canManageModule && (
          <Button variant="primary" onClick={() => setAttachDrawerActive(true)}>
            Attach policy
          </Button>
        )}
      </PageInfo>

      <Box direction="column" gap="x-large">
        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Manually attached"
          count={manuallyAttachedPolicies.length}
          initialIsCollapsed={false}
        >
          {manuallyAttachedPolicies.map((policy) => (
            <ModulePoliciesListItem key={policy.id} policy={policy} />
          ))}

          {manuallyAttachedPolicies.length === 0 && (
            <EmptyState
              padding="large"
              icon={PolicyColored}
              title="No policies attached yet."
              caption={EMPTY_STATE_CAPTION}
            />
          )}
        </CollapsibleSectionPanel>

        <CollapsibleSectionPanel
          ariaLevel={3}
          title="Auto-attached"
          count={autoAttachedPolicies.length}
          initialIsCollapsed={false}
        >
          {autoAttachedPolicies.map((policy) => (
            <ModulePoliciesListItem key={policy.id} policy={policy} moduleLabels={module.labels} />
          ))}

          {autoAttachedPolicies.length === 0 && (
            <EmptyState
              padding="large"
              icon={MagnetColored}
              title="No policies auto-attached yet."
              caption={EMPTY_STATE_CAPTION}
            />
          )}
        </CollapsibleSectionPanel>
      </Box>

      <PoliciesAttachDrawer
        visible={isAttachDrawerActive}
        onClose={handleCloseAttachDrawer}
        alreadyAttachedPolicies={allAttachedPoliciesSet}
        entityId={module.id}
        spaceId={module.spaceDetails.id}
      />
    </>
  );
};

export default ModulePolicies;
