import { VcsProvider } from "types/generated";

import ListBoxLink from "../listBox/Link";

type UserLinkProps = {
  name: string;
  provider: VcsProvider;
  login?: string;
};

const UserLink = ({ login, name, provider }: UserLinkProps) => {
  if (login && provider === VcsProvider.Github) {
    return <ListBoxLink url={`https://github.com/${login}`} text={login} />;
  }

  return <>{name}</>;
};

export default UserLink;
