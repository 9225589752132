import { Stack, StackInput } from "types/generated";

import { type StackSettingsDetailsFormFields } from "./Edit";

export const getFormDefaultValues = (stackSettings: Stack): StackSettingsDetailsFormFields => ({
  name: stackSettings.name,
  space: stackSettings.spaceDetails.id,
  labels: stackSettings.labels.map((value) => ({ value })) || [],
  description: stackSettings.description || "",
});

export const mapCreateStackDetailsUpdateInput = (
  formData: StackSettingsDetailsFormFields
): Partial<StackInput> => {
  return {
    ...formData,
    labels: formData.labels.map(({ value }) => value),
  };
};
