import { IntegrationColored } from "components/icons";
import CollapsibleList from "components/CollapsibleList";
import EmptyState from "ds/components/EmptyState";

import NewModuleAttachedPolicy from "../AttachedPolicy";
import { AttachedPolicy } from "../types";

type NewModuleManuallyAttachedPoliciesProps = {
  items: AttachedPolicy[];
};

const NewModuleManuallyAttachedPolicies = ({ items }: NewModuleManuallyAttachedPoliciesProps) => {
  return (
    <CollapsibleList
      ariaLevel={2}
      title="Manually attached"
      count={items.length}
      emptyPlaceholder={
        <EmptyState
          padding="large"
          icon={IntegrationColored}
          title="No policies are attached to this module"
          caption="Use policies list to attach a policy to module."
        />
      }
      initialIsCollapsed={false}
    >
      {items.map((item) => (
        <NewModuleAttachedPolicy key={item.id} item={item} />
      ))}
    </CollapsibleList>
  );
};

export default NewModuleManuallyAttachedPolicies;
