import Skeleton from "react-loading-skeleton";

import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import Box from "ds/components/Box";

const MOCK = Array(3).fill(null);

type DashboardWidgetsRunsSkeletonProps = {
  noCaption?: boolean;
};

const DashboardWidgetsRunsSkeleton = ({ noCaption }: DashboardWidgetsRunsSkeletonProps) => (
  <Box direction="column" gap="medium" fullWidth>
    {MOCK.map((_, i) => (
      <TileWrapper key={i}>
        <TileContent>
          <Box justify="between" align="center">
            <Box direction="column" align="start" gap="small">
              <Skeleton count={1} width={170} height={17} />
              {noCaption ? null : <Skeleton count={1} width={130} height={17} />}
            </Box>

            <Box direction="column" align="end" gap="medium">
              <Skeleton count={1} width={50} height={19} />
              <Skeleton count={1} width={60} height={19} />
            </Box>
          </Box>
        </TileContent>
      </TileWrapper>
    ))}
  </Box>
);

export default DashboardWidgetsRunsSkeleton;
