import { gql } from "@apollo/client";

export const DELETE_AWS_INTEGRATION = gql`
  mutation DeleteAwsIntegration($id: ID!) {
    awsIntegrationDelete(id: $id) {
      id
    }
  }
`;

export const CREATE_AWS_INTEGRATION = gql`
  mutation CreateAwsIntegration(
    $name: String!
    $roleArn: String!
    $generateCredentialsInWorker: Boolean!
    $externalId: String
    $durationSeconds: Int
    $labels: [String!]!
    $space: ID
  ) {
    awsIntegrationCreate(
      name: $name
      roleArn: $roleArn
      generateCredentialsInWorker: $generateCredentialsInWorker
      externalID: $externalId
      durationSeconds: $durationSeconds
      labels: $labels
      space: $space
    ) {
      id
    }
  }
`;

export const UPDATE_AWS_INTEGRATION = gql`
  mutation UpdateAwsIntegration(
    $id: ID!
    $name: String!
    $roleArn: String!
    $generateCredentialsInWorker: Boolean!
    $externalId: String!
    $durationSeconds: Int!
    $labels: [String!]!
    $space: ID
  ) {
    awsIntegrationUpdate(
      id: $id
      name: $name
      roleArn: $roleArn
      generateCredentialsInWorker: $generateCredentialsInWorker
      externalID: $externalId
      durationSeconds: $durationSeconds
      labels: $labels
      space: $space
    ) {
      id
    }
  }
`;
