import { Download, LeftRight, Trash } from "components/icons";

import DeltaCountsCell from "./Cell";
import { DeltaType } from "./types";
import styles from "./styles.module.css";

type DeltaCountsIMFBarProps = {
  importCount: number;
  moveCount: number;
  forgetCount: number;
};

const DeltaCountsIMFBar = ({ importCount, moveCount, forgetCount }: DeltaCountsIMFBarProps) => (
  <div className={styles.group}>
    {importCount > 0 && (
      <DeltaCountsCell
        count={importCount}
        label={`Imported resources: ${importCount}`}
        icon={Download}
        type={DeltaType.IMPORT}
      />
    )}
    {moveCount > 0 && (
      <DeltaCountsCell
        count={moveCount}
        label={`Moved resources: ${moveCount}`}
        icon={LeftRight}
        type={DeltaType.MOVE}
      />
    )}
    {forgetCount > 0 && (
      <DeltaCountsCell
        count={forgetCount}
        label={`Forgotten resources: ${forgetCount}`}
        icon={Trash}
        type={DeltaType.FORGET}
      />
    )}
  </div>
);

export default DeltaCountsIMFBar;
