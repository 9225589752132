import { useApolloClient, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  AuditTrailWebhook,
  MutationAuditTrailWebhookDeleteHeadersArgs,
  MutationAuditTrailWebhookSetHeadersArgs,
} from "types/generated";
import { CallbackTrack } from "hooks/useAnalytics";

import {
  AUDIT_TRAIL_HEADERS_DELETE,
  AUDIT_TRAIL_HEADERS_SET,
  CREATE_AUDIT_TRAIL_WEBHOOK,
} from "../gql";
import { AuditTrailWebhookSubmit } from "./parseAuditTrailFormFields";

type UseSubmitAuditTrailWebhookProps = {
  isEdit: boolean;
  onFormClose: () => void;
  trackSegmentAnalyticsEvent: CallbackTrack;
};

export const useSubmitAuditTrailWebhook = ({
  isEdit,
  onFormClose,
  trackSegmentAnalyticsEvent,
}: UseSubmitAuditTrailWebhookProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const client = useApolloClient();

  const [createAuditTrailSetWebhook, { loading }] = useMutation(CREATE_AUDIT_TRAIL_WEBHOOK);

  const [auditTrailSetHeaders] = useMutation<
    AuditTrailWebhook,
    MutationAuditTrailWebhookSetHeadersArgs
  >(AUDIT_TRAIL_HEADERS_SET);

  const [auditTrailDeleteHeaders] = useMutation<
    AuditTrailWebhook,
    MutationAuditTrailWebhookDeleteHeadersArgs
  >(AUDIT_TRAIL_HEADERS_DELETE);

  const onSubmitAuditTrail = async ({
    createAuditTrailSetWebhookInput,
    auditTrailSetHeadersInput,
    auditTrailDeleteHeadersInput,
  }: AuditTrailWebhookSubmit) => {
    trackSegmentAnalyticsEvent(isEdit ? "Save" : "Edit Save");

    try {
      await createAuditTrailSetWebhook({
        variables: {
          input: createAuditTrailSetWebhookInput,
        },
      });

      // Nested try-catch to not block redirect and show separate error in case webhook headers update has failed
      try {
        if (auditTrailDeleteHeadersInput.customHeaderKeys.length) {
          await auditTrailDeleteHeaders({
            variables: {
              input: auditTrailDeleteHeadersInput,
            },
          });
        }

        if (auditTrailSetHeadersInput.customHeaders.entries.length) {
          await auditTrailSetHeaders({
            variables: {
              input: auditTrailSetHeadersInput,
            },
          });
        }
      } catch (e) {
        onError(e);
      }

      await client.refetchQueries({ include: ["GetAuditTrailWebhook"] });
      onFormClose();
      reportSuccess({ message: "Audit trail webhook was saved" });
    } catch (e) {
      onError(e);
    }
  };

  return { onSubmitAuditTrail, loading };
};
