import { Edge, MarkerType, Node } from "reactflow";
import * as d3 from "d3";

import { Space } from "types/generated";

import { SpaceItemType } from "./types";
import { SPACE_NODE_WIDTH } from "./constants";

export const makeSpacesTree = (
  spaces: Space[]
): d3.HierarchyPointNode<SpaceItemType> | undefined => {
  const spacesMap = new Map<string, SpaceItemType>(
    spaces.map((space) => [
      space.id,
      {
        item: space,
        children: [],
        position: { x: 0, y: 0 },
      },
    ])
  );

  spaces.forEach((space) => {
    if (space.parentSpace) {
      const parent = spacesMap.get(space.parentSpace);

      if (parent) {
        parent.children.push(space.id);
      }
    }
  });

  const tempSpaces = [...spacesMap.values()];

  if (tempSpaces.length > 0) {
    const stratified = d3
      .stratify<SpaceItemType>()
      .id((d: SpaceItemType) => d.item.id)
      .parentId((d) => d.item.parentSpace)(tempSpaces);

    // Create a tree builder based on the tree nodes we found and the node sizes.
    const tree = d3.tree<SpaceItemType>().nodeSize([330, 150])(stratified);

    return tree;
  }

  return undefined;
};

export const makeNodesList = (
  tree: d3.HierarchyPointNode<SpaceItemType>
): Node<SpaceItemType>[] => {
  const nodes: Node<SpaceItemType>[] = [];

  tree.each((node) => {
    const position = { x: node.x - SPACE_NODE_WIDTH / 2, y: node.y };
    nodes.push({
      id: node.data.item.id,
      data: {
        ...node.data,
        position,
      },
      position,
      type: "space",
    });
  });

  return nodes;
};

export const makeEdges = (spaces: Space[]): Edge[] => {
  return spaces.reduce<Edge[]>((acc, space) => {
    if (space.parentSpace) {
      acc.push({
        id: `${space.parentSpace}-${space.id}`,
        source: space.parentSpace,
        target: space.id,
        type: "smoothstep",
        animated: !space.inheritEntities,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      });
    }
    return acc;
  }, []);
};

export const getViewOptions = (nodesCount: number) => ({
  padding: nodesCount > 4 ? 1 : 3,
});
