import { useFormContext } from "react-hook-form";

import ButtonIcon from "ds/components/ButtonIcon";
import Box from "ds/components/Box";
import { Trash } from "components/icons";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";

import { ContextMountedFilesListItemRegularProps } from "./types";
import ContextMountedFilesListItemHeader from "./Header";
import DraftForm from "./DraftForm";

const ContextMountedFilesListItemDraft = ({
  item,
  onRemoveFileDraft,
  onOpenDetailsDrawer,
  isCollapsed,
  toggle,
}: ContextMountedFilesListItemRegularProps) => {
  const { formState } = useFormContext();
  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle} withTransition>
      <ContextMountedFilesListItemHeader
        item={item}
        isCollapsed={isCollapsed}
        onOpenEditDrawer={onOpenDetailsDrawer}
      >
        <Box align="center" justify="end">
          <ButtonIcon
            onClick={onRemoveFileDraft}
            icon={Trash}
            disabled={formState.isSubmitting}
            variant="ghostDanger"
          >
            Delete
          </ButtonIcon>
        </Box>
      </ContextMountedFilesListItemHeader>
      <CollapsiblePanelContent padding="medium large large">
        <DraftForm onCancel={onRemoveFileDraft} />
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default ContextMountedFilesListItemDraft;
