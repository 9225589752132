import { ReactNode, forwardRef } from "react";

import { IconComponent } from "types/Icon";

import Dropdown from "../Dropdown";
import ButtonIcon from "../ButtonIcon";
import type { TooltipProps } from "../Tooltip/types";

type DropdownIconActionProps = {
  children: ({ closeDropdown }: { closeDropdown: () => void }) => ReactNode;
  icon: IconComponent;
  onVisibilityChange?: (isVisible: boolean) => void;
  closeDropdownTrigger?: number;
  tooltipMode?: TooltipProps["mode"];
  tooltip: string;
};

/**
 * @deprecated use DS DropdownMenuEllipsis instead
 */
const DropdownIconAction = forwardRef(function DropdownIconAction(
  {
    children,
    icon,
    onVisibilityChange,
    closeDropdownTrigger,
    tooltipMode,
    tooltip,
  }: DropdownIconActionProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <Dropdown
      closeDropdownTrigger={closeDropdownTrigger}
      onVisibilityChange={onVisibilityChange}
      renderTriggerComponent={({ onClick, isVisible, ariaProps }) => (
        <ButtonIcon
          tooltipMode={tooltipMode}
          ref={ref}
          icon={icon}
          onClick={onClick}
          disableTooltip={isVisible}
          active={isVisible}
          variant="ghost"
          {...ariaProps}
        >
          {tooltip}
        </ButtonIcon>
      )}
    >
      {children}
    </Dropdown>
  );
});

export default DropdownIconAction;
