import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useTypedContext from "hooks/useTypedContext";
import KeyValue from "components/KeyValue";

import { ModuleVcsFormFields } from "../types";
import { ModuleFormContext } from "../context";

type NewModuleSummaryVcsProps = {
  data: ModuleVcsFormFields;
};

const NewModuleSummaryVcs = ({ data }: NewModuleSummaryVcsProps) => {
  const { internalData } = useTypedContext(ModuleFormContext);

  return (
    <>
      <KeyValue name="Provider">{data.provider && HUMANIZE_PROVIDER[data.provider]}</KeyValue>

      {data.vcsIntegrationId && (
        <KeyValue name="Integration">
          {internalData.vcsIntegrationLabel || data.vcsIntegrationId}
        </KeyValue>
      )}

      {data.repository && data.namespace && (
        <KeyValue name="Repository">{`${data.namespace}/${data.repository}`}</KeyValue>
      )}
      <KeyValue name="Branch">{data.branch}</KeyValue>
      {data.repositoryURL && <KeyValue name="Repository URL">{data.repositoryURL}</KeyValue>}
      {data.projectRoot && <KeyValue name="Project root">{data.projectRoot}</KeyValue>}
    </>
  );
};

export default NewModuleSummaryVcs;
