import { Stack, StackConfigVendorInput, StackConfigVendorKubernetes } from "types/generated";
import { VENDOR_KEY } from "constants/vendor";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";

import { type StackSettingsKubernetesVendorFormFields } from "./Edit";

export const getFormDefaultValues = (
  stackSettings: Stack
): StackSettingsKubernetesVendorFormFields => {
  const config = stackSettings.vendorConfig as StackConfigVendorKubernetes;

  return {
    namespace: config.namespace,
    kubectlVersion: config.kubectlVersion,
    kubernetesWorkflowTool: config.kubernetesWorkflowTool,
  };
};

export const mapCreateStackVendorUpdateInput = (
  formData: StackSettingsKubernetesVendorFormFields
): StackConfigVendorInput => ({
  ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
  [VENDOR_KEY.KUBERNETES]: {
    ...formData,
    kubectlVersion: formData.kubectlVersion || null,
  },
});
