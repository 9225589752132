import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationTerraformProviderDeleteArgs, TerraformProvider } from "types/generated";

// TODO: make gql queries and mutation typed
export const GET_GPG_KEYS = gql`
  query GetGpgKeys {
    gpgKeys {
      id
      name
      description
      createdAt
      createdBy
      revokedAt
      revokedBy
    }
  }
`;

export const CREATE_GPG_KEY = gql`
  mutation CreateGpgKey($asciiArmor: String!, $description: String, $name: String!) {
    gpgKeyCreate(asciiArmor: $asciiArmor, description: $description, name: $name) {
      name
    }
  }
`;

export const UPDATE_GPG_KEY = gql`
  mutation UpdateGpgKey($id: ID!, $description: String) {
    gpgKeyUpdate(id: $id, description: $description) {
      name
    }
  }
`;

export const REVOKE_GPG_KEY = gql`
  mutation RevokeGpgKey($id: ID!) {
    gpgKeyRevoke(id: $id) {
      name
      revokedAt
    }
  }
`;

export const GET_PROVIDERS = gql`
  query GetProviders {
    terraformProviders {
      id
      description
      space
      latestVersionNumber
      labels
      public
      createdAt
      spaceDetails {
        id
        name
        accessLevel
      }
    }
  }
`;

export const GET_DOWNLOADABLE_PROVIDER_VERSION_ARTIFACT_URL = gql`
  query GetDownloadableProviderVersionArtifacts(
    $providerId: ID!
    $versionNumber: String!
    $artifactFilename: String!
  ) {
    terraformProvider(id: $providerId) {
      version(number: $versionNumber) {
        artifactDownloadUrl(filename: $artifactFilename)
      }
    }
  }
`;

export const GET_PROVIDER_WITH_VERSIONS = gql`
  query GetProviderWithVersions($id: ID!) {
    terraformProvider(id: $id) {
      id
      description
      space
      latestVersionNumber
      labels
      public
      spaceDetails {
        id
        name
        accessLevel
      }
      versions {
        id
        artifacts {
          filename
          sizeBytes
        }
        description
        number
        status
      }
    }
  }
`;

export const CREATE_PROVIDER = gql`
  mutation CreateProvider($description: String, $labels: [String!], $space: ID!, $type: ID!) {
    terraformProviderCreate(
      description: $description
      labels: $labels
      space: $space
      type: $type
    ) {
      id
    }
  }
`;

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($description: String, $labels: [String!], $space: ID!, $id: ID!) {
    terraformProviderUpdate(description: $description, labels: $labels, space: $space, id: $id) {
      id
    }
  }
`;

type FetchDeleteProviderData = {
  terraformProviderDelete: Pick<TerraformProvider, "id" | "deleted">;
};
export const DELETE_PROVIDER: TypedDocumentNode<
  FetchDeleteProviderData,
  MutationTerraformProviderDeleteArgs
> = gql`
  mutation DeleteProvider($id: ID!) {
    terraformProviderDelete(id: $id) {
      id
      deleted
    }
  }
`;

export const PUBLISH_PROVIDER_VERSION = gql`
  mutation PublishProviderVersion($version: ID!) {
    terraformProviderVersionPublish(version: $version) {
      number
    }
  }
`;

export const REVOKE_PROVIDER_VERSION = gql`
  mutation RevokeProviderVersion($version: ID!) {
    terraformProviderVersionRevoke(version: $version) {
      number
      status
    }
  }
`;

export const DELETE_PROVIDER_VERSION = gql`
  mutation DeleteProviderVersion($version: ID!) {
    terraformProviderVersionDelete(version: $version) {
      number
      deleted
    }
  }
`;

export const UPDATE_PROVIDER_VERSION = gql`
  mutation UpdateProviderVersion($version: ID!, $description: String!) {
    terraformProviderVersionUpdate(description: $description, version: $version) {
      number
    }
  }
`;
