import { NetworkStatus, useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { VENDOR_KEY } from "constants/vendor";

import { GET_STACK_VENDOR_RESOURCES } from "./gql";
import { StackVendorResources } from "./types";
import { makeVendorQueryVariables } from "./helpers";

type UseGetVendorResourcesProps = {
  onCompleted?: (data: StackVendorResources) => void;
  vendorSpecific?: VENDOR_KEY;
};

const useGetVendorResources = ({ onCompleted, vendorSpecific }: UseGetVendorResourcesProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, refetch, networkStatus } = useQuery<StackVendorResources>(
    GET_STACK_VENDOR_RESOURCES,
    {
      variables: makeVendorQueryVariables(vendorSpecific),
      onError,
      onCompleted,
    }
  );

  return {
    terraformVersions: data?.terraformVersions,
    openTofuVersions: data?.openTofuVersions,
    kubectlVersions: data?.kubectlVersions,
    terragruntVersions: data?.terragruntVersions,
    isDataLoading: loading && networkStatus !== NetworkStatus.refetch,
    isRefetching: loading && networkStatus === NetworkStatus.refetch,
    refetch,
  };
};

export default useGetVendorResources;
