import { useEffect } from "react";
import { ApolloError } from "@apollo/client";

import useErrorHandle from "hooks/useErrorHandle";

const useErrorHandlerSourceCode = (
  setAccountOrAuthErrorContent?: (content: JSX.Element) => void,
  error?: ApolloError
) => {
  const AccountOrAuthErrorContent = useErrorHandle(error);

  useEffect(() => {
    if (AccountOrAuthErrorContent) {
      setAccountOrAuthErrorContent?.(AccountOrAuthErrorContent);
    }
  }, [AccountOrAuthErrorContent, setAccountOrAuthErrorContent]);
};

export default useErrorHandlerSourceCode;
