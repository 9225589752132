import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { WebhookDelivery } from "types/generated";

import WebhookDeliveryListItem from ".";

type StackSettingsIntegrationsWebhookDeliveryListItemVirtualizedProps = {
  items: WebhookDelivery[];
};

const StackSettingsIntegrationsWebhookDeliveryListItemVirtualized = (
  props: ListChildComponentProps<
    StackSettingsIntegrationsWebhookDeliveryListItemVirtualizedProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const item = data.items[index];
  const key = item.id;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <WebhookDeliveryListItem key={key} item={item} setRowHeight={handleSetRowHeight} />
    </div>
  );
};

export default memo(StackSettingsIntegrationsWebhookDeliveryListItemVirtualized, areEqual);
