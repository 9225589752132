import { RunPolicyReceipt } from "types/generated";

import { RunElementType, RunEntryContext } from "../types";
import PushPoliciesEntry from "../entries/PushPoliciesEntry";
import { canShowElement } from "./elements";

export const createPushPoliciesEntry = (
  list: RunPolicyReceipt[],
  { stackId, runId, isModuleRun }: RunEntryContext
) => {
  const lastPush = list[list.length - 1];

  return (
    <PushPoliciesEntry
      key={lastPush.id}
      list={list}
      openListByDefault={canShowElement(RunElementType.InitializationPolicies)}
      stackId={stackId}
      runId={runId}
      isModuleRun={isModuleRun}
    />
  );
};
