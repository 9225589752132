import { UNSAFE_NavigationContext, useNavigate } from "react-router-dom-v5-compat";
import { Location, History, UnregisterCallback } from "history";
import { useCallback, useContext, useEffect, useRef } from "react";

import { showBlockNavigationModal } from "./BlockNavigationModal";

type UseBlockNavigationModalProps = {
  isNavigationBlocked: boolean;
  title?: string;
  description?: string;
};

/**
 * This hook will need to be refactored once we update to react-router v6 with the data routers (https://reactrouter.com/en/6.26.1/routers/picking-a-router) use the `useBlocker` hook instead + useBeforeUnload.
 * For any react-router-dom updates verify if the NavigationContext still returns the history object.
 * The casting here is necessary as react-router-dom team cut out the types on purpose (https://github.com/remix-run/react-router/issues/8139#issuecomment-954431589)
 */
const useBlockNavigationModal = ({
  isNavigationBlocked,
  title,
  description,
}: UseBlockNavigationModalProps) => {
  const { block } = useContext(UNSAFE_NavigationContext).navigator as unknown as History;
  const navigate = useNavigate();

  const locationRef = useRef<Location<unknown>>();
  const unregisterCallbackRef = useRef<UnregisterCallback>();

  const onNavigationConfirm = useCallback(() => {
    unregisterCallbackRef.current?.();
    if (locationRef.current) {
      navigate(locationRef.current);
    }
  }, [navigate]);

  useEffect(() => {
    unregisterCallbackRef.current = block((location) => {
      if (isNavigationBlocked) {
        locationRef.current = location;
        showBlockNavigationModal({ title, description, onConfirm: onNavigationConfirm });
        return false;
      }

      return undefined;
    });

    return () => unregisterCallbackRef.current?.();
  }, [isNavigationBlocked, block, title, description, onNavigationConfirm]);
};

export default useBlockNavigationModal;
