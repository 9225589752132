import {
  DriftDetectionIntegration,
  ScheduledDelete,
  ScheduledRun,
  ScheduledTask,
} from "types/generated";

export type SchedulingItem =
  | ScheduledDelete
  | ScheduledTask
  | DriftDetectionIntegration
  | ScheduledRun;

export enum SCHEDULING_TYPE {
  DRIFT_DETECTION = "DriftDetection",
  DELETE_STACK = "DeleteStack",
  TASK = "Task",
  RUN = "Run",
}
