import { useState } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { Policy, PolicyType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import LoginPolicyEditDetailsDrawer from "views/Account/LoginPolicyEditDetailsDrawer";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import LoginPolicyList from "./List";
import { GET_POLICIES } from "./gql";
import LoginPolicyPageLayout from "./PageLayout";
import LoginPolicySortHeader from "./SortHeader";
import LoginPolicyCreateDrawer from "./CreateDrawer";
import LoginPoliciesInfoDrawer from "./InfoDrawer";
import { PoliciesQueryResponse } from "./types";
import LoginPoliciesEmpty from "./Empty";
import { SettingsContext } from "../Context";
import { getManagementStrategy } from "../helpers";

const LoginPolicies = () => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);
  const [isCreateLoginPolicyDrawerVisible, setIsCreateLoginPolicyDrawerVisible] = useState(false);
  const [policyToEditInDrawer, setPolicyToEditInDrawer] = useState<Policy>();

  const setIsEditDetailsLoginPolicyDrawerVisible = (isVisible: boolean) => {
    if (!isVisible) {
      setPolicyToEditInDrawer(undefined);
    } else {
      trackSegmentAnalyticsEvent("Edit details click");
    }
  };

  const { accountName } = useTypedContext(AccountContext);

  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<PoliciesQueryResponse>(GET_POLICIES, {
    onError,
  });

  useTitle(`Organization Settings · Login Policies List · ${accountName}`);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationLoginPolicies,

    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    setIsInfoDrawerVisible(true);
  };

  const openCreateDrawer = () => {
    trackSegmentAnalyticsEvent("Create Policy Start");
    setIsCreateLoginPolicyDrawerVisible(true);
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.policies) {
    return <PageLoading />;
  }

  if (!data?.policies) {
    return <NotFoundPage />;
  }

  const loginPolicies = data?.policies.filter(({ type }) => type === PolicyType.Login);

  return (
    <LoginPolicyPageLayout openInfoDrawer={openInfoDrawer} openCreateDrawer={openCreateDrawer}>
      <LoginPoliciesInfoDrawer
        isDrawerVisible={isInfoDrawerVisible}
        handleCloseDrawer={() => setIsInfoDrawerVisible(false)}
      />
      <LoginPolicyCreateDrawer
        isDrawerVisible={isCreateLoginPolicyDrawerVisible}
        setDrawerVisibility={setIsCreateLoginPolicyDrawerVisible}
      />
      <LoginPolicyEditDetailsDrawer
        policyId={policyToEditInDrawer?.id}
        name={policyToEditInDrawer?.name}
        labels={policyToEditInDrawer?.labels}
        body={policyToEditInDrawer?.body}
        isDrawerVisible={!!policyToEditInDrawer}
        setDrawerVisibility={setIsEditDetailsLoginPolicyDrawerVisible}
      />
      {loginPolicies.length === 0 && <LoginPoliciesEmpty />}
      {loginPolicies.length > 0 && (
        <>
          <LoginPolicySortHeader />
          <LoginPolicyList
            loginPolicies={loginPolicies}
            setPolicyToEditInDrawer={setPolicyToEditInDrawer}
          />
        </>
      )}
    </LoginPolicyPageLayout>
  );
};

export default LoginPolicies;
