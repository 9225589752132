import { StackAwsIntegrationAttachment } from "types/generated";
import KeyValue from "components/KeyValue";
import { getDuration } from "utils/time";

type NewStackAttachCloudDetailsAWSFieldsType = {
  integration: StackAwsIntegrationAttachment;
};

const NewStackAttachCloudDetailsAWSFields = ({
  integration,
}: NewStackAttachCloudDetailsAWSFieldsType) => {
  return (
    <>
      <KeyValue name="Name">{integration.name}</KeyValue>
      <KeyValue name="Role ARN">{integration.roleArn}</KeyValue>
      <KeyValue name="Duration">{getDuration(integration.durationSeconds)}</KeyValue>
      <KeyValue name="External Id">{integration.externalId}</KeyValue>
    </>
  );
};

export default NewStackAttachCloudDetailsAWSFields;
