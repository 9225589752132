import { Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form";

import ToggleField, { ToggleFieldProps } from "ds/components/Form/ToggleField";

import { getEnableSensitiveOutputUploadTooltip } from "./tooltip";

type StackEnableSensitiveOutputUploadFormFieldProps<TFields extends FieldValues> = {
  name: FieldPath<TFields>;
  toggleFieldProps?: Partial<ToggleFieldProps>;
};

const StackEnableSensitiveOutputUploadFormField = <TFields extends FieldValues>({
  name,
  toggleFieldProps,
}: StackEnableSensitiveOutputUploadFormFieldProps<TFields>) => {
  const { control } = useFormContext<TFields>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ToggleField
          variant="switch"
          onChange={field.onChange}
          checked={field.value}
          title="Transfer sensitive outputs across dependencies"
          description="Allow outputs marked as sensitive to be passed to dependencies."
          tooltipInfo={getEnableSensitiveOutputUploadTooltip()}
          {...toggleFieldProps}
        />
      )}
    />
  );
};

export default StackEnableSensitiveOutputUploadFormField;
