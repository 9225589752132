import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { DriftDetectionIntegrationInput } from "types/generated";
import { StackContext } from "views/Stack/Context";

import { CREATE_STACK_DRIFT_DETECTION_SCHEDULING } from "./gql";

const useCreateDriftDetection = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const [stackIntegrationDriftDetectionCreate, { loading }] = useMutation(
    CREATE_STACK_DRIFT_DETECTION_SCHEDULING,
    {
      refetchQueries: ["GetStackScheduling"],
      awaitRefetchQueries: true,
      onError,
    }
  );

  const createDriftDetection = useCallback(
    (input: DriftDetectionIntegrationInput, successCallback?: () => void) => {
      stackIntegrationDriftDetectionCreate({
        variables: {
          stack: stack.id,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackIntegrationDriftDetectionCreate) {
            reportSuccess({
              message: `Drift Detection schedule was set up successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stack.id, stackIntegrationDriftDetectionCreate]
  );

  return {
    createDriftDetection,
    loading,
  };
};

export default useCreateDriftDetection;
