import React, { useEffect } from "react";
import debounce from "lodash-es/debounce";

type InfiniteScrollProps = {
  onScrollEnd: () => unknown;
  hasMore?: boolean;
  children: React.ReactNode;
};

const OFFSET = 100;

const InfiniteScroll = ({ children, onScrollEnd, hasMore }: InfiniteScrollProps) => {
  const handleScroll = debounce(() => {
    const { scrollTop, offsetHeight } = document.documentElement;

    if (window.innerHeight + scrollTop >= offsetHeight - OFFSET) {
      onScrollEnd();
    }
  }, 100);

  const attachListener = () => (hasMore ? window.addEventListener("scroll", handleScroll) : null);

  const detachListener = () => window.removeEventListener("scroll", handleScroll);

  useEffect(() => {
    attachListener();
    return () => {
      detachListener();
      handleScroll.cancel();
    };
  });

  return <>{children}</>;
};

export default InfiniteScroll;
