import { nanoid } from "nanoid";

import { HookCommands, HookCommandsToCompare, HooksPhases, StackHooksFormFields } from "./types";

const toCommands = (value: string[] | undefined) =>
  value?.map((command) => ({ text: command, id: nanoid() })) ?? [];

export const adaptCommandsToFields = (
  commands: HookCommands,
  selectedPhase: HooksPhases
): Partial<StackHooksFormFields> => {
  const beforeCommands = commands.before?.map((command) => command.text);
  const afterCommands = commands.after?.map((command) => command.text);

  switch (selectedPhase) {
    case HooksPhases.Init:
      return { beforeInit: beforeCommands, afterInit: afterCommands };
    case HooksPhases.Plan:
      return { beforePlan: beforeCommands, afterPlan: afterCommands };
    case HooksPhases.Apply:
      return { beforeApply: beforeCommands, afterApply: afterCommands };
    case HooksPhases.Destroy:
      return { beforeDestroy: beforeCommands, afterDestroy: afterCommands };
    case HooksPhases.Perform:
      return { beforePerform: beforeCommands, afterPerform: afterCommands };
    case HooksPhases.Finally:
      return { afterRun: afterCommands };
  }
};

export const adaptFieldsToCommands = (
  fields?: StackHooksFormFields
): Record<HooksPhases, HookCommands> => {
  const commands: Record<HooksPhases, HookCommands> = {
    [HooksPhases.Init]: {
      before: toCommands(fields?.beforeInit),
      after: toCommands(fields?.afterInit),
    },
    [HooksPhases.Plan]: {
      before: toCommands(fields?.beforePlan),
      after: toCommands(fields?.afterPlan),
    },
    [HooksPhases.Apply]: {
      before: toCommands(fields?.beforeApply),
      after: toCommands(fields?.afterApply),
    },
    [HooksPhases.Destroy]: {
      before: toCommands(fields?.beforeDestroy),
      after: toCommands(fields?.afterDestroy),
    },
    [HooksPhases.Perform]: {
      before: toCommands(fields?.beforePerform),
      after: toCommands(fields?.afterPerform),
    },
    [HooksPhases.Finally]: {
      after: toCommands(fields?.afterRun),
    },
  };

  return commands;
};

export const adaptFieldsToCompare = (
  fields?: StackHooksFormFields
): Record<HooksPhases, HookCommandsToCompare> => {
  const commands: Record<HooksPhases, HookCommandsToCompare> = {
    [HooksPhases.Init]: {
      before: fields?.beforeInit,
      after: fields?.afterInit,
    },
    [HooksPhases.Plan]: {
      before: fields?.beforePlan,
      after: fields?.afterPlan,
    },
    [HooksPhases.Apply]: {
      before: fields?.beforeApply,
      after: fields?.afterApply,
    },
    [HooksPhases.Destroy]: {
      before: fields?.beforeDestroy,
      after: fields?.afterDestroy,
    },
    [HooksPhases.Perform]: {
      before: fields?.beforePerform,
      after: fields?.afterPerform,
    },
    [HooksPhases.Finally]: {
      after: fields?.afterRun,
    },
  };

  return commands;
};
