import FormFieldViewText from "components/FormFields/ViewText";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import { getDocsUrl } from "utils/getDocsUrl";

type StackSettingsViewVendorFormFieldProps = {
  vendorName: string;
};

const StackSettingsViewVendorFormField = ({
  vendorName,
}: StackSettingsViewVendorFormFieldProps) => {
  return (
    <FormFieldViewText
      label="Vendor"
      value={vendorName}
      tooltipInfo={
        <>
          <TooltipModalTitle>Vendor</TooltipModalTitle>
          <TooltipModalBody align="start">
            Your infrastructure tool.
            <ReadMoreDocsLink
              docsUrl={getDocsUrl("/concepts/stack/creating-a-stack#configure-backend")}
            />
          </TooltipModalBody>
        </>
      }
      tooltipInfoVariant="modal"
    />
  );
};

export default StackSettingsViewVendorFormField;
