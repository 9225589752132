import { useState } from "react";

import { AspectType } from "types/generated";
import Select from "ds/components/Select";
import Typography from "ds/components/Typography";

import UsageChart from "../Chart";
import { GroupByKey } from "../Chart/constants";
import RunMinutesTotals from "./Totals";
import UsageLayoutHeader from "../Layout/Header";
import UsageLayoutHeaderActions from "../Layout/Header/Actions";
import { getColorMapper } from "./colorMapper";
import useFilters from "./useFilters";
import CSVDownloadButton from "../CSVDownload/Button";
import { getDefaultTimeFilter } from "../utils";

const GROUP_BY_OPTIONS = [
  { value: GroupByKey.RunType, label: "Run type" },
  { value: GroupByKey.RunState, label: "Run state" },
];

const UsageRunMinutes = () => {
  const { groupBy, setGroupBy } = useFilters();

  const [timeFilter] = useState(getDefaultTimeFilter);

  const colorMapper = getColorMapper(groupBy);

  return (
    <>
      <UsageLayoutHeader title="Current period usage chart">
        <UsageLayoutHeaderActions>
          <Select
            value={groupBy}
            options={GROUP_BY_OPTIONS}
            onChange={setGroupBy}
            renderValueNode={(option) => (
              <Typography variant="p-t7" tag="span">
                Grouped by: {option.label}
              </Typography>
            )}
          />
          <CSVDownloadButton timeFilter={timeFilter} aspectType={AspectType.RunMinutes} />
        </UsageLayoutHeaderActions>
      </UsageLayoutHeader>
      <RunMinutesTotals timeFilter={timeFilter} />
      <UsageChart
        timeFilter={timeFilter}
        groupBy={groupBy}
        aspectType={AspectType.RunMinutes}
        leftAxisLabel="Minutes"
        colorMapper={colorMapper}
        tooltipHeader="Total minutes:"
        showPercentiles={false}
      />
    </>
  );
};

export default UsageRunMinutes;
