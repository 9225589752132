import { RunDependency, RunExternalDependency } from "types/generated";

import DependenciesEntry from "../entries/DependenciesEntry";
import { RunEntryContext } from "../types";
import { combineDependencies, getDependencyEvaluation } from "../entries/DependenciesEntry/utils";

export const createDependenciesEntry = (
  runDependencies: RunDependency[] | undefined,
  externalDependencies: RunExternalDependency[] | undefined,
  { isSimpleView }: RunEntryContext
) => {
  const combinedDependencies = combineDependencies(runDependencies, externalDependencies);

  const evaluation = getDependencyEvaluation(combinedDependencies);

  if (!evaluation.isImportant && isSimpleView) {
    return null;
  }

  return (
    <DependenciesEntry
      key="dependencies"
      dependencies={combinedDependencies}
      isSimpleView={isSimpleView}
      evaluationLabel={evaluation.state}
      evaluationState={evaluation.state}
      evaluationTimestamp={evaluation.timestamp}
    />
  );
};
