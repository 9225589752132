import { useEffect, useRef } from "react";

// returns previous passed value
const usePrevious = <T>(value: T, shouldReset?: boolean): T | undefined => {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return shouldReset ? value : ref.current;
};

export default usePrevious;
