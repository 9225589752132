import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { GithubEnterpriseIntegration, Maybe } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { DELETE_GITHUB_ENTERPRISE_INTEGRATION } from "./gql";

const useDeleteGitHubIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [deleteGitHubEnterprise, { loading }] = useMutation<{
    githubEnterpriseIntegrationDelete: Maybe<GithubEnterpriseIntegration>;
  }>(DELETE_GITHUB_ENTERPRISE_INTEGRATION, {
    refetchQueries: ["SearchVCSIntegrations"],
    onError,
  });

  const deleteGitHubEnterpriseIntegration = useCallback(
    (id: string, attachedEntitiesQty: number, successCallback?: () => void) => {
      return deleteGitHubEnterprise({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `${
                data?.githubEnterpriseIntegrationDelete?.name || "GitHub"
              }  integration successfully deleted`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration - Unlink Confirm", {
              attachedEntitiesQty,
              integration: "GitHub",
            });
          }
        })
        .catch(onError);
    },
    [deleteGitHubEnterprise, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return { deleteGitHubEnterpriseIntegration, loading };
};

export default useDeleteGitHubIntegration;
