import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActions/types";
import useTypedContext from "hooks/useTypedContext";
import { useBulkConfirmRun } from "shared/Run/useConfirmRun";
import { canConfirmRun } from "shared/Run/useConfirmRun/accessValidation";
import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";
import { useBulkReviewRun } from "shared/Run/useReviewRun";
import { canReviewRun } from "shared/Run/useReviewRun/accessValidation";
import { AccountContext } from "views/AccountWrapper";
import { RunReviewDecision } from "types/generated";

import { StackRunEntity } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "./constants";
import { ReviewRunVariables, RunsBulkActions } from "./types";

export type RunsMutationVariables = ReviewRunVariables;

const useRunsBulkActions = (): Array<
  BulkEntityActionItem<RunsBulkActions, StackRunEntity, RunsMutationVariables>
> => {
  const { viewer } = useTypedContext(AccountContext);
  const [runConfirm] = useBulkConfirmRun();
  const [runDiscard] = useBulkDiscardRun();
  const [runReview] = useBulkReviewRun();

  return useMemo<
    Array<BulkEntityActionItem<RunsBulkActions, StackRunEntity, RunsMutationVariables>>
  >(
    () => [
      {
        key: RunsBulkActions.Confirm,
        title: BULK_ACTION_NAMES[RunsBulkActions.Confirm],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.Confirm],
        condition: (run) => canConfirmRun(run, run.stack, viewer),
        mutation: (run) => runConfirm({ stack: run.stack.id, run: run.id, note: null }),
      },
      {
        key: RunsBulkActions.Discard,
        title: BULK_ACTION_NAMES[RunsBulkActions.Discard],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.Discard],
        condition: canDiscardRun,
        mutation: (run) => runDiscard({ stack: run.stack.id, run: run.id, note: null }),
      },
      {
        key: RunsBulkActions.ReviewApprove,
        title: BULK_ACTION_NAMES[RunsBulkActions.ReviewApprove],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.ReviewApprove],
        condition: (run) => canReviewRun(run, run.stack),
        mutation: (run, mutationVariables) =>
          runReview({
            stack: run.stack.id,
            run: run.id,
            decision: RunReviewDecision.Approve,
            note: mutationVariables.reviewNote || "",
          }),
      },
      {
        key: RunsBulkActions.ReviewReject,
        title: BULK_ACTION_NAMES[RunsBulkActions.ReviewReject],
        resultTitle: BULK_ACTION_RESULTS_TITLE[RunsBulkActions.ReviewReject],
        condition: (run) => canReviewRun(run, run.stack),
        mutation: (run, mutationVariables) =>
          runReview({
            stack: run.stack.id,
            run: run.id,
            decision: RunReviewDecision.Reject,
            note: mutationVariables.reviewNote || "",
          }),
      },
    ],
    [runConfirm, runDiscard, runReview, viewer]
  );
};

export default useRunsBulkActions;
