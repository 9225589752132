import { Stack } from "types/generated";
import { useBulkStackSyncCommit } from "shared/Stack/useSyncCommit";
import { canSyncCommit } from "shared/Stack/useSyncCommit/accessValidation";

import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { StackBulkActions } from "../types";

const useSyncCommitBulkAction = () => {
  const [syncCommit] = useBulkStackSyncCommit();

  return {
    key: StackBulkActions.SyncCommit,
    title: BULK_ACTION_NAMES[StackBulkActions.SyncCommit],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.SyncCommit],
    condition: canSyncCommit,
    mutation: (stack: Stack) => syncCommit({ id: stack.id }),
  };
};

export default useSyncCommitBulkAction;
