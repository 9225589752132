import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { NamedWebhooksIntegration } from "types/generated";

import WebhookListItem from ".";

type WebhookVirtualizedListItemProps = {
  items: NamedWebhooksIntegration[];
};

const WebhookVirtualizedListItem = (
  props: ListChildComponentProps<WebhookVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <WebhookListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(WebhookVirtualizedListItem, areEqual);
