import { useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PolicyStackAttachment } from "types/generated";

import { DELETE_POLICY, GET_POLICY_ATTACHMENTS } from "./List/gql";
import { REFETCH_QUERIES_ON_DELETE } from "./List/constants";

const useDeletePolicy = (id: string) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const {
    data,
    loading: attachmentsLoading,
    error,
  } = useQuery<{
    policy: {
      attachedStacks: PolicyStackAttachment[];
      hasAttachedStacks: boolean;
    };
  }>(GET_POLICY_ATTACHMENTS, {
    variables: { id },
    onError,
    fetchPolicy: "no-cache",
  });

  const [deletePolicy, { loading: deleteLoading }] = useMutation(DELETE_POLICY, {
    refetchQueries: REFETCH_QUERIES_ON_DELETE,
    variables: {
      id,
      force: data?.policy?.hasAttachedStacks,
    },
    onError,
  });

  const onDelete = () => {
    return deletePolicy()
      .then(({ data }) => {
        if (data?.policyDelete?.id) {
          reportSuccess({
            message: `Policy "${data.policyDelete.name}" was successfully deleted`,
          });
        } else {
          reportError({
            message: "Something went wrong while deleting the policy, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const entityCount = useMemo(() => {
    const { hasAttachedStacks, attachedStacks } = data?.policy ?? {};
    if (!hasAttachedStacks || !attachedStacks) {
      return { stacks: 0, modules: 0 };
    }

    return attachedStacks.reduce(
      (acc, stack) => {
        if (stack.isModule) {
          acc.modules++;
        } else {
          acc.stacks++;
        }
        return acc;
      },
      { stacks: 0, modules: 0 }
    );
  }, [data?.policy]);

  return {
    attachedEntities: data?.policy?.attachedStacks ?? [],
    attachmentsError: error,
    entityCount,
    loading: deleteLoading || attachmentsLoading,
    onDelete,
    pendingAttachments: attachmentsLoading,
  };
};

export default useDeletePolicy;
