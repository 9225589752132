import React from "react";
import useLocalStorage from "@rehooks/local-storage";
import Split from "react-split";
import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import {
  FILTER_COLLAPSED_MIN_WIDTH,
  FILTER_DEFAULT_MIN_WIDTH,
  FILTER_EXPANDED_STORAGE_KEY,
  FILTER_SPLIT_SIZES_STORAGE_KEY,
} from "../constants";
import { FiltersContext } from "..";
import { createStorageKey } from "../helpers";

const INITIAL_FILTERS_SPLIT_SIZES = [0, 100];

type FiltersSplitProps = {
  children: React.ReactNode;
};

const FiltersSplit = ({ children }: FiltersSplitProps) => {
  const { filterType, filtersRevealedOnHover } = useTypedContext(FiltersContext);
  const [filtersExpanded] = useLocalStorage(
    createStorageKey(filterType, FILTER_EXPANDED_STORAGE_KEY),
    true
  );

  const [filtersSplitSizes, setFiltersSplitSizes] = useLocalStorage(
    createStorageKey(filterType, FILTER_SPLIT_SIZES_STORAGE_KEY),
    INITIAL_FILTERS_SPLIT_SIZES
  );

  const handleDragEnd = (newSize: number[]) => {
    setFiltersSplitSizes(newSize);
  };

  return (
    <Split
      direction="horizontal"
      sizes={filtersExpanded ? filtersSplitSizes || INITIAL_FILTERS_SPLIT_SIZES : [0, 100]}
      minSize={[filtersExpanded ? FILTER_DEFAULT_MIN_WIDTH : FILTER_COLLAPSED_MIN_WIDTH]}
      expandToMin={false}
      gutterSize={0}
      gutterAlign="center"
      snapOffset={0}
      dragInterval={1}
      className={cx(styles.splitWrapper, {
        [styles.filtersCollapsed]: !filtersExpanded,
        [styles.filtersRevealedOnHover]: filtersRevealedOnHover,
      })}
      onDragEnd={handleDragEnd}
    >
      {children}
    </Split>
  );
};

export default FiltersSplit;
