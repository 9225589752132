import { useMemo } from "react";

import Box from "ds/components/Box";
import Tag from "ds/components/Tag";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Typography from "ds/components/Typography";
import LinkAdd from "ds/components/Link/LinkAdd";

import { useTagsList } from "./useTagsList";
import { TagsListProps } from "./types";
import TagsListExpander from "./Expander";
import TagsListWrapper from "./Wrapper";

const TagsList = ({
  tags,
  onExpand,
  className,
  icon,
  alwaysExpanded = false,
  fullWidthTags = false,
  onAddTags,
  addTagsUrl,
  withEmptyPlaceholder = false,
  withTopMargin = true,
}: TagsListProps) => {
  const { labelsWrappersRef, isExpanded, toggleLabelsVisibility } = useTagsList(
    useMemo(() => tags, [tags]),
    {
      alwaysExpanded,
      onExpand,
    }
  );

  if (tags.length === 0 && (onAddTags || addTagsUrl)) {
    return (
      <MetaInfoListItem>
        <LinkAdd text="Add labels" onClick={onAddTags} to={addTagsUrl} />
      </MetaInfoListItem>
    );
  }

  // FYI: mostly should be used on Details drawers
  if (tags.length === 0 && !onAddTags && withEmptyPlaceholder) {
    return (
      <MetaInfoListItem>
        <Typography tag="span" variant="p-body2" color="disabled">
          No labels
        </Typography>
      </MetaInfoListItem>
    );
  }

  // FYI: mostly is used on List pages
  if (tags.length === 0 && !onAddTags && !withEmptyPlaceholder) {
    return null;
  }

  return (
    <Box ref={labelsWrappersRef} direction="row" justify="between" className={className} fullWidth>
      <TagsListWrapper isExpanded={isExpanded} withTopMargin={withTopMargin}>
        {tags.map((tag) => (
          <Tag key={tag} tag={tag} data-tags="tagField" icon={icon} fullWidth={fullWidthTags} />
        ))}
      </TagsListWrapper>

      <TagsListExpander
        toggleLabelsVisibility={toggleLabelsVisibility}
        isExpanded={isExpanded}
        alwaysExpanded={alwaysExpanded}
      />
    </Box>
  );
};

export default TagsList;
