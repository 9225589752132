import { isEqual } from "lodash-es";
import { createWithEqualityFn } from "zustand/traditional";

type SelectionStore<T extends string = string> = {
  activeId: string | undefined;
  selectedAllOption: T | undefined;
  setSelectedAllOption: (selectedAllOption: T | undefined) => void;
  setActiveId: (activeId: string | undefined) => void;
  selected: Set<string>;
  selectIds: (ids: string[]) => void;
  setAvailableIds: (keyName: T, ids: string[]) => void;
  addAvailableIds: (keyName: T, id: string[]) => void;
  availableIds: Record<T, Set<string>>;
  resetAllSelected: () => void;
  resetStore: () => void;
  toggle: (id: string) => void;
  unselect: (id: string) => void;
  unselectIds: (ids: string[]) => void;
  addSelectedIds: (ids: string[]) => void;
};

const initialStoreData = {
  activeId: undefined,
  selectedAllOption: undefined,
  selected: new Set<string>(),
  availableIds: {},
};

// TODO: create factory function to have ability to specify types of store data
// FYI: it has been created to resolve react aria table issue with not optimized rerendering https://github.com/adobe/react-spectrum/issues/5707
// It can be removed after bumping to react 19
const useSelectionStore = createWithEqualityFn<SelectionStore>(
  (set) => ({
    ...initialStoreData,
    setActiveId: (activeId) => set({ activeId }),
    setSelectedAllOption: (selectedAllOption) =>
      set((state) => {
        if (selectedAllOption === undefined) {
          return { selectedAllOption, selected: new Set() };
        }

        if (
          selectedAllOption === state.selectedAllOption &&
          state.availableIds[selectedAllOption]
        ) {
          return { selected: new Set(state.availableIds[selectedAllOption]) };
        }

        return { selectedAllOption };
      }),
    selectIds: (ids) => set({ selected: new Set(ids) }),
    setAvailableIds: (keyName, ids) => set({ availableIds: { [keyName]: new Set(ids) } }),
    addAvailableIds: (keyName, ids) =>
      set((state) => {
        const newSet = state.availableIds[keyName]
          ? new Set(state.availableIds[keyName])
          : new Set<string>();

        ids.forEach((id) => {
          if (!newSet.has(id)) {
            newSet.add(id);
          }
        });

        return {
          availableIds: { [keyName]: newSet },
        };
      }),
    resetAllSelected: () => set({ selected: new Set(), selectedAllOption: undefined }),
    resetStore: () => set(initialStoreData),
    addSelectedIds: (ids) =>
      set((state) => {
        const newSet = new Set(state.selected);

        ids.forEach((id) => {
          if (!newSet.has(id)) {
            newSet.add(id);
          }
        });

        return { selected: newSet };
      }),
    unselect: (id) =>
      set((state) => {
        const newSet = new Set(state.selected);
        if (newSet.has(id)) {
          newSet.delete(id);
        }

        if (state.selectedAllOption && newSet.size === 0) {
          return { selected: newSet, selectedAllOption: undefined };
        }

        return { selected: newSet };
      }),
    unselectIds: (ids) =>
      set((state) => {
        const newSet = new Set(state.selected);

        ids.forEach((id) => {
          if (newSet.has(id)) {
            newSet.delete(id);
          }
        });

        if (state.selectedAllOption && newSet.size === 0) {
          return { selected: newSet, selectedAllOption: undefined };
        }

        return { selected: newSet };
      }),
    toggle: (id) =>
      set((state) => {
        const newSet = new Set(state.selected);
        if (newSet.has(id)) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }

        if (state.selectedAllOption && newSet.size === 0) {
          return { selected: newSet, selectedAllOption: undefined };
        }

        return { selected: newSet };
      }),
  }),
  (a, b) => isEqual(a, b)
);

export default useSelectionStore;
