import useTypedContext from "hooks/useTypedContext";
import Stepper from "components/Stepper";
import StepperStep from "components/Stepper/Step";
import Typography from "ds/components/Typography";

import { StackFormContext } from "../context";
import { stackCreationWizardNavSummaryStep } from "../constants";
import { isStepRequired } from "../utils";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";
import { StackCreationWizardStep } from "../types";

const NewStackStepper = () => {
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const {
    availableSteps,
    currentStep,
    currentStepIndex,
    setCurrentStep,
    formData,
    stepperNavDisabled,
    isIntermediateStepActive,
    setIsIntermediateStepActive,
  } = useTypedContext(StackFormContext);

  const setStep = (step: StackCreationWizardStep) => {
    if (isIntermediateStepActive) {
      setIsIntermediateStepActive(false);
    }

    setCurrentStep(step);
    trackSegmentEvent("Step click", {
      currentStep,
      newStep: step,
    });
  };

  return (
    <Stepper>
      <Typography tag="h5" variant="p-t7" color="secondary" transform="uppercase">
        Follow the steps
      </Typography>
      {[...availableSteps, stackCreationWizardNavSummaryStep].map((item, index) => (
        <StepperStep
          disabled={stepperNavDisabled || index > currentStepIndex}
          key={item.step}
          active={item.step === currentStep}
          onClick={index < currentStepIndex ? () => setStep(item.step) : undefined}
        >
          {item.name}
          {item.isOptional && !isStepRequired(item.step, formData) && " (optional)"}
        </StepperStep>
      ))}
    </Stepper>
  );
};

export default NewStackStepper;
