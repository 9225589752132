import { gql } from "@apollo/client";

export const CHECK_BLUEPRINTS = gql`
  query CheckBlueprints {
    searchBlueprints(input: { first: 1 }) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
