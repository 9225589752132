import { ReactNode, createContext, useCallback, useMemo } from "react";
import useLocalStorage from "@rehooks/local-storage";

import { ViewItem } from "./types";

export type ViewItemsVisibility = Record<string, boolean>;

const convertItemsForStorage = (items: ViewItem[]) => {
  return items.reduce<ViewItemsVisibility>((acc, item) => {
    acc[item.id] = item.selected;
    return acc;
  }, {});
};

type ViewCustomizationContextProviderProps = {
  children: ReactNode;
  localStorageKey: string;
  initialItems: ViewItem[];
};

type ViewCustomizationContextProps = {
  viewItems: ViewItem[];
  itemsVisibility: ViewItemsVisibility;
  onChange: (newItems: ViewItem[]) => void;
};

export const ViewCustomizationContext = createContext<ViewCustomizationContextProps | undefined>(
  undefined
);
ViewCustomizationContext.displayName = "ViewCustomizationContext";

const ViewCustomizationContextProvider = ({
  children,
  localStorageKey,
  initialItems,
}: ViewCustomizationContextProviderProps) => {
  const [localStorageItemsVisibility, setItemsVisibility] = useLocalStorage<ViewItemsVisibility>(
    localStorageKey,
    convertItemsForStorage(initialItems)
  );

  const onChange = useCallback(
    (newItems: ViewItem[]) => {
      setItemsVisibility(convertItemsForStorage(newItems));
    },
    [setItemsVisibility]
  );

  const itemsVisibility = useMemo(() => {
    const result: ViewItemsVisibility = {};

    for (const item of initialItems) {
      result[item.id] = localStorageItemsVisibility?.[item.id] ?? item.selected;
    }

    return result;
  }, [initialItems, localStorageItemsVisibility]);

  const viewItems = useMemo(
    () =>
      initialItems
        .filter((item) => item.configurable !== false)
        .map((item) => ({
          ...item,
          selected: itemsVisibility[item.id],
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [itemsVisibility, initialItems]
  );

  const contextValue = useMemo(
    () => ({ viewItems, itemsVisibility, onChange }),
    [viewItems, itemsVisibility, onChange]
  );

  return (
    <ViewCustomizationContext.Provider value={contextValue}>
      {children}
    </ViewCustomizationContext.Provider>
  );
};

export default ViewCustomizationContextProvider;
