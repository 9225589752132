import { Moon, Sun } from "components/icons";
import { SyntaxTheme } from "types/SyntaxTheme";
import { ThemePalette } from "views/Theme";

export const getPreviewSyntaxTheme = (syntaxTheme: SyntaxTheme) => {
  return `/images/syntax_themes/${syntaxTheme}.png`;
};

export const getSyntaxThemeTitle = (themeMode: ThemePalette) => {
  if (themeMode === "dark") {
    return "for Dark mode";
  }

  return "for Light mode";
};

export const getSyntaxThemeIcon = (themeMode: ThemePalette) => {
  if (themeMode === "dark") {
    return Moon;
  }

  return Sun;
};

export const getSyntaxThemeDescription = (themeMode: ThemePalette) => {
  if (themeMode === "dark") {
    return `This theme will be active when your system is set to “dark mode”`;
  }

  return `This theme will be active when your system is set to “light mode”`;
};
