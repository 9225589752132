import { ReactNode } from "react";

import { BillingTierFeature } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTierFeature from "views/Account/hooks/useTierFeature";
import Button from "ds/components/Button";
import FeedbackActions from "ds/components/Feedback/Actions";
import Feedback from "ds/components/Feedback";
import { FeedbackType, FeedbackVariant } from "ds/components/Feedback/types";
import { AnalyticsPageGlobal } from "hooks/useAnalytics/pages/global";

type TierInfoProps = {
  feature: BillingTierFeature;
  type: FeedbackType;
  variant: FeedbackVariant;
  children: ReactNode;
  title?: string;
  className?: string;
  adminActionText?: string;
  notAdminTextPlaceholder?: string;
  storageKey?: string;
};

const TierInfo = ({
  feature,
  title,
  children,
  className,
  type = "callout",
  variant = "danger",
  adminActionText = "Upgrade",
  notAdminTextPlaceholder = "Ask your admin to upgrade.",
  storageKey,
}: TierInfoProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const isActive = useTierFeature(feature);

  if (isActive) return null;

  return (
    <Feedback
      title={title}
      variant={variant}
      className={className}
      type={type}
      storageKey={storageKey}
    >
      {children}
      <FeedbackActions>
        {viewer.admin ? (
          <Button
            variant="contrast"
            size="small"
            to="/settings/billing"
            analyticsPage={AnalyticsPageGlobal.UpgradePlanBanner}
            analyticsTitle="Upgrade Button Clicked"
            analyticsProps={{
              buttonText: adminActionText,
              billingTierFeature: feature,
              variant,
              type,
            }}
          >
            {adminActionText}
          </Button>
        ) : (
          notAdminTextPlaceholder
        )}
      </FeedbackActions>
    </Feedback>
  );
};

export default TierInfo;
