import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
  URL_SEARCH_KEY,
  URL_SORT_DIRECTION,
  URL_SORT_KEY,
} from "constants/url_query_keys";
import { getSessionStorageKeys } from "components/Filters/helpers";

export const makeRunBackUrl = (
  parentUrl: string,
  isTask = false,
  isProposedRun = false,
  driftDetectionRun = false
) => {
  if (isTask) {
    return `${parentUrl}/tasks`;
  } else if (isProposedRun && !driftDetectionRun) {
    return `${parentUrl}/prs`;
  }

  return parentUrl;
};

export const getBreadcrumbsBackUrl = (url: string, key: string) => {
  const storage = sessionStorage;
  const urlParams = new URLSearchParams();
  const {
    storageUrlFilterKeysKey,
    storageUrlFilterTypesKey,
    storageUrlFilterValuesKey,
    storageUrlSearchKey,
    storageUrlSortDirection,
    storageUrlSortKey,
  } = getSessionStorageKeys(key);

  const searchParam = storage.getItem(storageUrlSearchKey);

  const sortKey = storage.getItem(storageUrlSortKey);
  const sortDirection = storage.getItem(storageUrlSortDirection);

  const filterKeys = storage.getItem(storageUrlFilterKeysKey);
  const filterTypes = storage.getItem(storageUrlFilterTypesKey);
  const filterValues = storage.getItem(storageUrlFilterValuesKey);

  if (searchParam) {
    urlParams.set(URL_SEARCH_KEY, searchParam);
  }

  if (!!sortKey && !!sortDirection) {
    urlParams.set(URL_SORT_KEY, sortKey);
    urlParams.set(URL_SORT_DIRECTION, sortDirection);
  }

  if (!!filterKeys && !!filterTypes && !!filterValues) {
    urlParams.set(URL_FILTER_KEYS_KEY, filterKeys);
    urlParams.set(URL_FILTER_TYPES_KEY, filterTypes);
    urlParams.set(URL_FILTER_VALUES_KEY, filterValues);
  }

  const params = urlParams.toString();
  const urlParameters = params.length > 0 ? `?${params}` : "";

  return `${url}${urlParameters}`;
};
