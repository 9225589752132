import cx from "classnames";
import { useId } from "react";

import Box from "ds/components/Box";
import { SyntaxTheme } from "types/SyntaxTheme";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileTitle from "ds/components/Tile/Title";
import Radio from "ds/components/Radio";

import styles from "./styles.module.css";
import { getPreviewSyntaxTheme } from "./utils";

type ThemeAppearanceSyntaxTileProps = {
  themeKey: SyntaxTheme;
  title: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
};

const ThemeAppearanceSyntaxTile = ({
  themeKey,
  title,
  selected,
  onClick,
  className,
}: ThemeAppearanceSyntaxTileProps) => {
  const id = useId();

  return (
    <Box className={cx(styles.syntaxTile, className)}>
      <TileWrapper selected={selected} onClick={onClick}>
        <Box direction="column">
          <Box align="start" justify="between" grow="1">
            <Radio className={styles.radio} id={id} checked={selected}>
              <TileTitle>{title}</TileTitle>
            </Radio>
          </Box>
          <Box grow="1" margin="large 0 0 0">
            <img
              src={getPreviewSyntaxTheme(themeKey)}
              alt={title}
              title={title}
              className={styles.previewImage}
            />
          </Box>
        </Box>
      </TileWrapper>
    </Box>
  );
};

export default ThemeAppearanceSyntaxTile;
