import { ReactNode } from "react";

import styles from "../styles.module.css";
import { quote } from "../constants";

const ValueType = ({ children }: { children: ReactNode }) => {
  if (children === null) {
    return <span className={styles.codeChangesNull}>{"null"}</span>;
  }
  if (children === undefined) {
    return <span className={styles.codeChangesUndefined}>{"undefined"}</span>;
  }
  if (typeof children === "string") {
    return (
      <span className={styles.codeChangesString}>
        {quote}
        {children}
        {quote}
      </span>
    );
  }
  if (typeof children === "number") {
    return <span className={styles.codeChangesNumber}>{children}</span>;
  }
  if (typeof children === "boolean") {
    return <span className={styles.codeChangesBoolean}>{children.toString()}</span>;
  }

  return null;
};

export default ValueType;
