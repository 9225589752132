import { useMemo } from "react";

import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useTypedContext from "hooks/useTypedContext";
import KeyValue from "components/KeyValue";

import { StackVcsFormFields } from "../types";
import NewStackSummaryValueList from "./ValueList";
import { StackFormContext } from "../context";

type NewStackSummaryVcsProps = {
  data: StackVcsFormFields;
};

const NewStackSummaryVcs = ({ data }: NewStackSummaryVcsProps) => {
  const { internalData } = useTypedContext(StackFormContext);

  const additionalProjectGlobsValues = useMemo(
    () => data.additionalProjectGlobs?.map((glob) => glob.value),
    [data.additionalProjectGlobs]
  );

  return (
    <>
      <KeyValue name="Provider">{data.provider && HUMANIZE_PROVIDER[data.provider]}</KeyValue>

      {data.vcsIntegrationId && (
        <KeyValue name="Integration">
          {internalData.vcsIntegrationLabel || data.vcsIntegrationId}
        </KeyValue>
      )}

      {data.repository && data.namespace && (
        <KeyValue name="Repository">{`${data.namespace}/${data.repository}`}</KeyValue>
      )}
      <KeyValue name="Branch">{data.branch}</KeyValue>
      {data.repositoryURL && <KeyValue name="Repository URL">{data.repositoryURL}</KeyValue>}
      {data.projectRoot && <KeyValue name="Project root">{data.projectRoot}</KeyValue>}
      <KeyValue name="Additional project globs">
        {additionalProjectGlobsValues?.length && (
          <NewStackSummaryValueList values={additionalProjectGlobsValues} />
        )}
      </KeyValue>
    </>
  );
};

export default NewStackSummaryVcs;
