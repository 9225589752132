import { Output } from "components/icons";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import Link from "ds/components/Link";
import { pluralize } from "shared/Pluralize";
import LinkAdd from "ds/components/Link/LinkAdd";

import OutputReferencesTextWrapper from "./TextWrapper";
import OutputReferencesTooltipWrapper from "./TooltipWrapper";

const DISALLOWED_COPY = "stacks do not support output references";

type OutputReferencesProps = {
  referenceCount?: number;
  onEdit?: () => void;
  disallowed?: boolean;
  vendorName?: string;
};

export const OutputReferences = ({
  onEdit,
  referenceCount,
  disallowed,
  vendorName,
}: OutputReferencesProps) => {
  const isReadOnly = !onEdit;
  const hasReferences = !!referenceCount;

  if (disallowed) {
    return (
      <OutputReferencesTextWrapper>
        <OutputReferencesTextWrapper>{vendorName}</OutputReferencesTextWrapper> {DISALLOWED_COPY}
      </OutputReferencesTextWrapper>
    );
  }

  if (isReadOnly) {
    return hasReferences ? (
      <Box gap="medium" align="center">
        <OutputReferencesTextWrapper>
          {referenceCount} Output {pluralize("reference", referenceCount)}
        </OutputReferencesTextWrapper>

        <OutputReferencesTooltipWrapper>
          This stack will run only if at least one of its output references changes.
        </OutputReferencesTooltipWrapper>
      </Box>
    ) : (
      <OutputReferencesTextWrapper>No output references</OutputReferencesTextWrapper>
    );
  }

  return hasReferences ? (
    <Box gap="medium" align="center">
      <Icon src={Output} />

      <OutputReferencesTextWrapper>
        {referenceCount} Output {pluralize("reference", referenceCount)}
      </OutputReferencesTextWrapper>

      <OutputReferencesTooltipWrapper>
        This stack will run only if at least one of the output references changes.
      </OutputReferencesTooltipWrapper>

      <Link size="medium" onClick={onEdit}>
        Edit
      </Link>
    </Box>
  ) : (
    <LinkAdd text="Add output reference" onClick={onEdit} />
  );
};
