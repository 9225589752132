import { gql } from "@apollo/client";

export const GET_STACK_DEPENDENCIES_GRAPH = gql`
  query GetStackDependenciesGraph($stackId: ID!) {
    stack(id: $stackId) {
      id
      dependenciesFullGraph {
        id
        stack {
          id
          name
          space {
            id
            name
            accessLevel
          }
          vendorConfig {
            __typename
          }
        }
        dependsOnStack {
          id
          name
          space {
            id
            name
            accessLevel
          }
          vendorConfig {
            __typename
          }
        }
        referenceCount
        references {
          id
          inputName
          outputName
          triggerAlways
        }
      }
    }
  }
`;
