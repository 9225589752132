import Button from "ds/components/Button";
import { Stack } from "types/generated";
import { LockNew } from "components/icons";
import { AnalyticsPage } from "hooks/useAnalytics";

import { showStackLockNoteModal } from "../LockNoteModal";

type LockButtonProps = {
  stackId: Stack["id"];
  analyticsPage?: AnalyticsPage;
};

const LockButton = ({ stackId, analyticsPage }: LockButtonProps) => {
  const onClick = () => {
    showStackLockNoteModal({
      stackId,
      analyticsPage,
    });
  };

  return (
    <Button onClick={onClick} startIcon={LockNew} variant="secondary">
      Lock
    </Button>
  );
};

export default LockButton;
