import { ReactNode } from "react";

import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

type StackListItemTextValueProps = { tooltip?: ReactNode; children: ReactNode };

const StackListItemTextValue = ({ tooltip, children }: StackListItemTextValueProps) => {
  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <Box className={styles.textContainer}>
          <span {...tooltipProps}>
            <TextEllipsis tooltipPlacement="bottom" tooltip={children}>
              {(props) => (
                <Typography {...props} tag="p" variant="p-body3">
                  {children}
                </Typography>
              )}
            </TextEllipsis>
          </span>
        </Box>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default StackListItemTextValue;
