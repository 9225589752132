import { EntityChangeType } from "types/generated";
import { capitalizeFirstLetter } from "utils/strings";

import { Pages } from "./types";

export const enum AnalyticsPageStack {
  StackNew = "StackNew",
  StacksConfigurationManagement = "StacksConfigurationManagement",
  StacksContexts = "StacksContexts",
  StacksEnvironment = "StacksEnvironment",
  StacksList = "StacksList",
  StacksOutputs = "StacksOutputs",
  StacksPolicies = "StacksPolicies",
  StacksPRs = "StacksPRs",
  StacksResources = "StacksResources",
  StacksRunChanges = "StacksRunChanges",
  StacksRunList = "StacksRunList",
  StacksRunView = "StacksRunView",
  StacksScheduling = "StacksScheduling",
  StacksTaskList = "StacksTaskList",
  StackStateHistory = "StackStateHistory",
  StackScheduling = "StackScheduling",
  StackSettingsDetails = "StackSettingsDetails",
  StackSettingsSourceCode = "StackSettingsSourceCode",
  StackSettingsVendor = "StackSettingsVendor",
  StackSettingsBehavior = "StackSettingsBehavior",
  StackSettingsIntegrations = "StackSettingsIntegrations",
  StackSettingsDeletion = "StackSettingsDeletion",
  Ansible = "Ansible",
}

const stacks = "Stacks";
const stackTasks = "Tasks";
const stackSettings = "Settings";
const stackRuns = "Runs";

export const pagesStack: Pages<AnalyticsPageStack> = {
  [AnalyticsPageStack.StackNew]: [stacks, "New Stack"],
  [AnalyticsPageStack.StacksList]: [stacks, "Stack list"],
  [AnalyticsPageStack.StacksTaskList]: [stacks, stackTasks, "Task list"],
  [AnalyticsPageStack.StacksEnvironment]: [stacks, "Environment"],
  [AnalyticsPageStack.StacksScheduling]: [stacks, "Scheduling"],
  [AnalyticsPageStack.StacksConfigurationManagement]: [stacks, "Configuration Management"],
  [AnalyticsPageStack.StacksPolicies]: [stacks, "Policies"],
  [AnalyticsPageStack.StacksContexts]: [stacks, stackSettings, "Contexts"],
  [AnalyticsPageStack.StacksOutputs]: [stacks, "Outputs"],
  [AnalyticsPageStack.StacksPRs]: [stacks, "PRs"],
  [AnalyticsPageStack.StacksResources]: [stacks, "Resources"],
  [AnalyticsPageStack.StacksRunList]: [stacks, stackRuns, "Run list"],
  [AnalyticsPageStack.StacksRunView]: [stacks, "Run"],
  [AnalyticsPageStack.StackStateHistory]: [stacks, "State history"],
  [AnalyticsPageStack.StackScheduling]: [stacks, "Scheduling"],
  [AnalyticsPageStack.StacksRunChanges]: (changeType?: EntityChangeType) => {
    return [
      stacks,
      stackRuns,
      "Run",
      changeType ? capitalizeFirstLetter(changeType.toLowerCase()) : "",
    ];
  },
  [AnalyticsPageStack.StackSettingsDetails]: [stacks, stackSettings, "Stack Details"],
  [AnalyticsPageStack.StackSettingsSourceCode]: [stacks, stackSettings, "Source Code"],
  [AnalyticsPageStack.StackSettingsVendor]: [stacks, stackSettings, "Vendor"],
  [AnalyticsPageStack.StackSettingsBehavior]: [stacks, stackSettings, "Behavior"],
  [AnalyticsPageStack.StackSettingsIntegrations]: [stacks, stackSettings, "Integrations"],
  [AnalyticsPageStack.StackSettingsDeletion]: [stacks, stackSettings, "Stack Deletion"],
  [AnalyticsPageStack.Ansible]: ["Ansible"],
};
