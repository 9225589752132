import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryUsername from "../../components/HistoryEntryUsername";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type CanceledEntryProps = {
  timestamp: number;
  username: string | null;
};

// TODO: no designs
const CanceledEntry = ({ timestamp, username }: CanceledEntryProps) => {
  const label = (
    <HistoryEntryLabel>
      <strong>Canceled</strong> <HistoryEntryUsername username={username} />
    </HistoryEntryLabel>
  );

  return (
    <HistoryEntryWrapper
      label={label}
      state={RunHistoryEntryType.Canceled}
      timestamp={timestamp}
      terminal
    />
  );
};

export default CanceledEntry;
