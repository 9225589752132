import { RunReviewDecision } from "types/generated";
import { canReviewStackRun } from "shared/Run/useReviewRun/accessValidation";
import { useBulkReviewRun } from "shared/Run/useReviewRun";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import {
  ReviewStackVariables,
  StackActionItemForBulkActions,
  StackMutationVariables,
} from "./types";

const useReviewStackBulkAction = (decision: RunReviewDecision): StackActionItemForBulkActions => {
  const [reviewRun] = useBulkReviewRun();
  const action =
    decision === RunReviewDecision.Approve
      ? StackBulkActions.ReviewApprove
      : StackBulkActions.ReviewReject;

  return {
    key: action,
    title: BULK_ACTION_NAMES[action],
    resultTitle: BULK_ACTION_RESULTS_TITLE[action],
    condition: canReviewStackRun,
    mutation: (stack, mutationVariables) => {
      // TODO: wait for TS will fix Extract for generic union types and then remove the type casting
      const { reviewNote } = mutationVariables as Extract<
        StackMutationVariables,
        ReviewStackVariables
      >;
      return reviewRun({
        stack: stack.id,
        run: stack.blocker!.id,
        decision,
        note: reviewNote || "",
      });
    },
  };
};

export default useReviewStackBulkAction;
