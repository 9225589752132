import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import FormFieldViewBoolean from "components/FormFields/ViewBoolean";
import { VCS_PROVIDERS } from "constants/vcs_providers";
import useTypedFlags from "hooks/useTypedFlags";
import { getEnableSensitiveOutputUploadTooltip } from "components/Forms/Stack/EnableSensitiveOutputUploadFormField/tooltip";

import { StackSettingsContextData } from "../Context";
import StackSettingsBehaviorInfoBanner from "./InfoBanner";
import {
  getAdministrativeTooltip,
  getAllowRunPromotionTooltip,
  getAutodeployTooltip,
  getAutoretryTooltip,
  getEnableSecretMaskingTooltip,
  getLocalPreviewEnabledTooltip,
  getProtectFromDeletionTooltip,
  getRunnerImageTooltip,
  getWorkerPoolTooltip,
} from "./getTooltips";

const StackSettingsBehaviorView = () => {
  const { showLeakingSensitiveOutputsThroughDependencies } = useTypedFlags();

  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const workerPoolName = stackSettings.workerPool?.name;
  const isGithub = stackSettings.provider === VCS_PROVIDERS.Github;

  return (
    <>
      <FormFieldViewText
        label="Worker pool"
        value={workerPoolName || "Using shared public worker pool"}
        {...getWorkerPoolTooltip()}
        noMargin
      />
      {stackSettings.runnerImage && (
        <FormFieldViewText
          label="Runner image"
          value={stackSettings.runnerImage}
          {...getRunnerImageTooltip()}
          noMargin
        />
      )}
      <FormFieldViewBoolean
        label="Administrative"
        value={stackSettings.administrative}
        {...getAdministrativeTooltip()}
        noMargin
      />
      {isGithub && (
        <FormFieldViewBoolean
          label="Allow run promotion"
          value={stackSettings.githubActionDeploy}
          {...getAllowRunPromotionTooltip()}
          noMargin
        />
      )}
      <FormFieldViewBoolean
        label="Autodeploy"
        value={stackSettings.autodeploy}
        {...getAutodeployTooltip()}
        noMargin
      />
      <FormFieldViewBoolean
        label="Autoretry"
        value={stackSettings.autoretry}
        {...getAutoretryTooltip()}
        noMargin
      />
      <FormFieldViewBoolean
        label="Enable local preview"
        value={stackSettings.localPreviewEnabled}
        {...getLocalPreviewEnabledTooltip()}
        noMargin
      />
      <FormFieldViewBoolean
        label="Enable secret masking"
        value={stackSettings.enableWellKnownSecretMasking}
        {...getEnableSecretMaskingTooltip()}
        noMargin
      />
      <FormFieldViewBoolean
        label="Protect from deletion"
        value={stackSettings.protectFromDeletion}
        {...getProtectFromDeletionTooltip()}
        noMargin
      />
      {showLeakingSensitiveOutputsThroughDependencies && (
        <FormFieldViewBoolean
          label="Transfer sensitive outputs across dependencies"
          value={stackSettings.enableSensitiveOutputUpload}
          tooltipInfo={getEnableSensitiveOutputUploadTooltip()}
          tooltipInfoVariant="modal"
          noMargin
        />
      )}
      <StackSettingsBehaviorInfoBanner />
    </>
  );
};

export default StackSettingsBehaviorView;
