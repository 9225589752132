import { BulkActionActionConfirmationTabs } from "components/BulkActions/types";
import EmptyState from "ds/components/EmptyState";
import { NoResultsColored } from "components/icons";

type BulkActionsDrawerActionStepsConfirmationEmptyTabProps = {
  currentTab: BulkActionActionConfirmationTabs;
};

const EMPTY_STATE_TITLE: Record<BulkActionActionConfirmationTabs, string> = {
  [BulkActionActionConfirmationTabs.Applicable]: "No applicable items",
  [BulkActionActionConfirmationTabs.NotApplicable]: "No skipped items",
};

const BulkActionsDrawerActionStepsConfirmationEmptyTab = ({
  currentTab,
}: BulkActionsDrawerActionStepsConfirmationEmptyTabProps) => {
  return <EmptyState title={EMPTY_STATE_TITLE[currentTab]} icon={NoResultsColored} fullHeight />;
};

export default BulkActionsDrawerActionStepsConfirmationEmptyTab;
