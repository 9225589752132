import styles from "./styles.module.css";

type MenuItemTextProps = {
  children?: React.ReactNode;
};

const MenuItemText = ({ children }: MenuItemTextProps) => {
  return <span className={styles.menuText}>{children}</span>;
};

export default MenuItemText;
