import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";
import { useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { ConfirmationModalInput } from "ds/components/ConfirmationModal/Input";

import { DELETE_OIDC } from "./gql";

type DisableConfirmationProps = {
  hasManagedUsers?: boolean;
};

const DisableConfirmation = create(function DisableConfirmation({
  hasManagedUsers,
}: DisableConfirmationProps) {
  const modal = useModal();
  const [isValid, setIsValid] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,
    defaultCallbackTrackProperties: { method: "OIDC" },
  });

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [oidcDelete, { loading, data }] = useMutation(DELETE_OIDC, {
    refetchQueries: ["GetSSOSettings"],
  });

  const handleDelete = () => {
    trackSegmentAnalyticsEvent("Confirm disable click");
    oidcDelete()
      .then(() => {
        reportSuccess({ message: "OIDC successfully disabled" });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Disable OIDC"
      onConfirm={handleDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.oidcDelete?.clientId}
      confirmationButtonLabel="Disable"
      isConfirmationDisabled={!isValid}
    >
      <ConfirmationModalMessage>
        Are you sure you want to disable current OIDC integration?{" "}
        {hasManagedUsers && (
          <>
            <strong>OIDC managed users</strong> will be removed and this action cannot be undone.
          </>
        )}
      </ConfirmationModalMessage>

      <ConfirmationModalInput setIsValid={setIsValid} keyWord="disable" />
    </DeleteConfirmationModal>
  );
});

export const showDisableConfirmation = (props: DisableConfirmationProps) =>
  show(DisableConfirmation, props);
