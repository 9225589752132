import { FormState } from "react-hook-form";

import {
  AccountType,
  GitHubAppManifestInput,
  MutationGithubAppCreateFromManifestArgs,
} from "types/generated";
import { VCS_INTEGRATION_TYPES } from "views/Account/VCS/constants";
import { ROOT_SPACE_ID } from "constants/space";

import {
  ManageGitHubEnterpriseWizardFinalDetailsFormFields,
  ManageGitHubEnterpriseWizardFormFields,
  ManageGitHubEnterpriseWizardIntegration,
} from "./types";
import { DEFAULT_GITHUB_ENTERPRISE_API_HOST, DEFAULT_GITHUB_ENTERPRISE_HOST } from "./constants";

export const isIntegrationStepValid = (
  integrationChoice: ManageGitHubEnterpriseWizardIntegration,
  formState: FormState<ManageGitHubEnterpriseWizardFormFields>
) => {
  if (integrationChoice === ManageGitHubEnterpriseWizardIntegration.GITHUB) {
    return true;
  }

  if (integrationChoice === ManageGitHubEnterpriseWizardIntegration.SELF_HOSTED) {
    if (
      formState.dirtyFields.host &&
      formState.dirtyFields.apiHost &&
      !formState.errors.host &&
      !formState.errors.apiHost
    ) {
      return true;
    }
  }

  return false;
};

export const isOwnerStepValid = (
  ownerChoice: AccountType,
  formState: FormState<ManageGitHubEnterpriseWizardFormFields>
) => {
  if (ownerChoice === AccountType.User) {
    return true;
  }

  if (ownerChoice === AccountType.Org) {
    if (formState.dirtyFields.ownerName && !formState.errors.ownerName) {
      return true;
    }
  }

  return false;
};

export const mapGenerateManifestInputValues = (
  values: ManageGitHubEnterpriseWizardFormFields
): GitHubAppManifestInput => ({
  apiHost:
    values.integration === ManageGitHubEnterpriseWizardIntegration.GITHUB
      ? DEFAULT_GITHUB_ENTERPRISE_API_HOST
      : values.apiHost,
  host:
    values.integration === ManageGitHubEnterpriseWizardIntegration.GITHUB
      ? DEFAULT_GITHUB_ENTERPRISE_HOST
      : values.host,
  ownerName: values.ownerName,
  ownerType: values.ownerType,
});

export const mapCreateGitHubAppFromManifestInputValues = (
  values: ManageGitHubEnterpriseWizardFinalDetailsFormFields,
  code: string,
  state: string
): MutationGithubAppCreateFromManifestArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    code,
    state,
    customInput: {
      isDefault,
      name: values.name,
      // FYI: set the root to be able to convert to the multiple integration from the default one
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      labels: values.labels.map((label) => label.value) || [],
      description: values.description || null,
      vcsChecks: values.vcsChecks,
    },
  };
};
