import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { SortOption } from "components/Filters/types";
import { SearchSuggestionsOutput } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { useCachedFilterFields } from "components/Filters/hooks";

import { POLL_INTERVAL } from "../constants";
import { SEARCH_WORKER_POOLS_SUGGESTIONS } from "../gql";

export const useSearchWorkerPoolsSuggestions = () => {
  const { onError } = useTypedContext(FlashContext);

  //TODO: refactor to use useQuery hook, there is no need in this case to use useLazyQuery
  const [loadWorkerPoolsSuggestions, { data: filteringData }] = useLazyQuery<{
    searchWorkerPoolsSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_WORKER_POOLS_SUGGESTIONS, { pollInterval: POLL_INTERVAL });

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchWorkerPoolsSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return cachedFiltersData
      .filter((field) => field.orderable)
      .map((field) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
  }, [cachedFiltersData]);

  const loadSearchSuggestions = () => {
    try {
      loadWorkerPoolsSuggestions({
        variables: {
          input: {
            fullTextSearch: null,
            predicates: null,
            fields: null,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sortOptions,
  };
};
