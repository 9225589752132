import { useParams } from "react-router-dom-v5-compat";
import { useCallback } from "react";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useTitle from "hooks/useTitle";
import WorkerPoolQueuedRunsView from "views/Account/WorkerPool/QueuedRunsView";
import PageLoading from "components/loading/PageLoading";

import useSearchQueuedRuns from "./useSearchQueuedRuns";
import useSearchQueuedRunsSuggestions from "./useSearchQueuedRunsSuggestions";
import PrivateWorkerPoolHeader from "../Header";

const PrivateWorkerPoolQueuedRuns = () => {
  const { workerPoolId = "" } = useParams<{ workerPoolId: string }>();

  const {
    workerPool,
    entities,
    loading,
    error,
    stopPolling,
    refetch,
    isPageEmpty,
    hasNoFilteringResults,
    isPageLoading,
  } = useSearchQueuedRuns(workerPoolId);

  const {
    filters,
    filtersMap,
    sortOptions,
    loading: filtersLoading,
    refetchActiveSections,
    filteredCount,
  } = useSearchQueuedRunsSuggestions({
    workerPoolId: workerPool?.id,
  });

  const onBulkActionsFinish = useCallback(async () => refetch(), [refetch]);

  useTitle(`Queue · ${workerPool?.name || ""}`);

  useBreadcrumbs(
    [
      {
        title: "Worker pools",
        link: "/worker-pools",
      },
      {
        title: workerPool?.name || "",
      },
    ],
    [workerPool]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (!workerPool) {
    return <NotFoundPage />;
  }

  return (
    <WorkerPoolQueuedRunsView
      entities={entities}
      renderedEntitiesCount={entities.length}
      hasNoFilteringResults={hasNoFilteringResults}
      isPageEmpty={isPageEmpty}
      onBulkActionsFinish={onBulkActionsFinish}
      loadingIndication={loading}
      filters={filters}
      filtersMap={filtersMap}
      sortOptions={sortOptions}
      filtersLoading={filtersLoading}
      refetchActiveSections={refetchActiveSections}
      filteredCount={filteredCount}
    >
      <PrivateWorkerPoolHeader
        workerPool={workerPool}
        refetchQuery="SearchPrivateWorkerPoolQueuedRuns"
      />
    </WorkerPoolQueuedRunsView>
  );
};

export default PrivateWorkerPoolQueuedRuns;
