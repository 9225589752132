import { getLocalTimeZone, now, parseAbsoluteToLocal } from "@internationalized/date";
import { intlFormat } from "date-fns";

type GetNowDateTimeProps = {
  timeShiftMinutes?: number;
  timezone?: string;
};
export const getNowDateTime = ({
  timeShiftMinutes = 0,
  timezone = getLocalTimeZone(),
}: GetNowDateTimeProps = {}) =>
  parseAbsoluteToLocal(
    now(timezone).add({ minutes: timeShiftMinutes }).toAbsoluteString()
  ).toDate();

// FYI: it duplicates intlFormat options from date-fns
type IntlFormatOptions = {
  localeMatcher?: "lookup" | "best fit";
  weekday?: "narrow" | "short" | "long";
  era?: "narrow" | "short" | "long";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  timeZoneName?: "short" | "long";
  formatMatcher?: "basic" | "best fit";
  hour12?: boolean;
  timeZone?: string;
};

const INTL_DATE_FORMAT: IntlFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

const INTL_DATE_READABLE_LONG_FORMAT: IntlFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const INTL_DATE_TIME_SHORT_FORMAT_NO_ZONE: IntlFormatOptions = {
  ...INTL_DATE_FORMAT,
  hour: "numeric",
  minute: "numeric",
};
const INTL_DATE_TIME_SHORT_FORMAT: IntlFormatOptions = {
  ...INTL_DATE_TIME_SHORT_FORMAT_NO_ZONE,
  timeZoneName: "short",
};

const INTL_DATE_TIME_FULL_FORMAT: IntlFormatOptions = {
  ...INTL_DATE_TIME_SHORT_FORMAT,
  second: "numeric",
  timeZoneName: "short",
};

export type FormatDateTimeByLocaleProps = {
  date: Date;
  format?: "date" | "dateReadableLong" | "dateTimeShort" | "dateTimeShortNoZone" | "dateTimeFull";
  renderTimezone?: string;
};
export const formatDateTimeByLocale = ({
  date,
  format = "date",
  renderTimezone,
}: FormatDateTimeByLocaleProps) => {
  switch (format) {
    case "date":
      return intlFormat(date, { ...INTL_DATE_FORMAT, timeZone: renderTimezone });
    case "dateReadableLong":
      return intlFormat(date, { ...INTL_DATE_READABLE_LONG_FORMAT, timeZone: renderTimezone });
    case "dateTimeShort":
      return intlFormat(date, { ...INTL_DATE_TIME_SHORT_FORMAT, timeZone: renderTimezone });
    case "dateTimeShortNoZone":
      return intlFormat(date, { ...INTL_DATE_TIME_SHORT_FORMAT_NO_ZONE, timeZone: renderTimezone });
    case "dateTimeFull":
      return intlFormat(date, { ...INTL_DATE_TIME_FULL_FORMAT, timeZone: renderTimezone });
  }
};
