import { RunStateTransition } from "types/generated";

import { RunElementType, RunEntryContext } from "../types";
import { canShowElement } from "./elements";
import PreparingEntry from "../entries/PreparingEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createPreparingEntry = (
  { timestamp, until, stateVersion, hasLogs, state }: RunStateTransition,
  { isSimpleView, runId, stackId, isModuleRun }: RunEntryContext,
  hasInitializationPolicies: boolean
) => {
  // We can hide the preparing entry in simplified mode if the run state is finished
  // and does not have any associated init policy receipt.
  if (isSimpleView && !hasInitializationPolicies && until) {
    return null;
  }

  return (
    <PreparingEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      state={state}
      stateVersion={stateVersion}
      hasLogs={hasLogs}
      openLogsByDefault={canShowElement(RunElementType.Logs)}
      runId={runId}
      stackId={stackId}
      isModuleRun={isModuleRun}
    />
  );
};
