// eslint-disable-next-line no-restricted-imports
import { camelCaseKeys, useFlags } from "launchdarkly-react-client-sdk";

import { flags, Flags } from "../views/flags";

const useTypedFlags = () => {
  const ldFlags = useFlags<Flags>();

  /**
   * There is an issue with the LaunchDarkly SDK where it returns an empty object
   * if there is some problem with the LD initialization, like network issues.
   * LaunchDarkly is aware of this and say this is the expected behavior.
   * https://support.launchdarkly.com/hc/en-us/articles/16441198122523-React-Web-SDK-does-not-serve-fallback-values-for-flags-when-SDK-client-fails-initialization
   */
  if (Object.keys(ldFlags).length === 0) {
    return camelCaseKeys(flags);
  }

  return ldFlags;
};

export default useTypedFlags;
