import { gql } from "@apollo/client";

export const GET_RESOURCES_COUNT_BY_DRIFT = gql`
  query GetResourcesCountByDrift {
    metrics {
      resourcesCountByDrift {
        labels
        value
      }
      driftDetectionCoverage {
        labels
        value
      }
      hasStacks
    }
  }
`;
