import { gql, TypedDocumentNode } from "@apollo/client";

import { Module, MutationModuleDisableArgs } from "types/generated";

type FetchModuleDisableData = { moduleDisable: Module };
export const DISABLE_MODULE: TypedDocumentNode<FetchModuleDisableData, MutationModuleDisableArgs> =
  gql`
    mutation DisableModule($id: ID!) {
      moduleDisable(id: $id) {
        id
        isDisabled
      }
    }
  `;
