import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

import { StackSettingsGetTooltipHelperReturnType } from "../types";

export const getWorkerPoolTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Worker pool</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          By using a private worker pool you make sure that you have full control over your
          infrastructure changes, and can even access resources that would otherwise not be
          reachable from the public internet.
        </Typography>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/worker-pools/")} />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getRunnerImageTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Runner image</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Every Spacelift job is executed in a separate Docker container, setting a custom runner
          image provides a convenient way to prepare the exact runtime environment your
          infra-as-code flow is designed to use.
        </Typography>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/stack/stack-settings#runner-image")} />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getAdministrativeTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Administrative stack</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Administrative stacks can create, update and destroy Spacelift resources.
        </Typography>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/stack/stack-settings#administrative")} />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getAllowRunPromotionTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Allow run promotion</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Indicates whether proposed runs can be promoted (deployed) using the Spacelift API or
          GitHub Actions.
        </Typography>
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getAutodeployTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Autodeploy</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Consider setting it to true when you always do a code review before merging to the tracked
          branch, and/or want to rely on plan policies.
        </Typography>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/stack/stack-settings#autodeploy")} />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getAutoretryTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Autoretry</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Indicates whether tracked runs will automatically retry invalidated proposed runs.
        </Typography>

        <Typography tag="p" variant="p-body3">
          When set to true, any proposed runs on branches will be retried as soon as they are
          invalidated by a tracked run.
        </Typography>

        <Typography tag="p" variant="p-body3">
          Autoretry is only available on stacks using private workers.
        </Typography>
        <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/stack/stack-settings#autoretry")} />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getLocalPreviewEnabledTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Local preview</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          When set to true, proposed runs can be created based on user-uploaded local workspaces.
          This way you can preview how your code changes will execute without creating a commit.
        </Typography>
        <ReadMoreDocsLink
          docsUrl={getDocsUrl("/concepts/stack/stack-settings#enable-local-preview")}
        />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getEnableSecretMaskingTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Secret masking</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Indicates whether secret patterns will be automatically redacted from logs. For full list
          of the supported patterns refer to the documentation.
        </Typography>
        <ReadMoreDocsLink
          docsUrl={getDocsUrl("/concepts/stack/stack-settings#enable-well-known-secret-masking")}
        />
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getProtectFromDeletionTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Protect from deletion</TooltipModalTitle>
      <TooltipModalBody align="start">
        <Typography tag="p" variant="p-body3">
          Deletion protection helps protect your stacks from accidental deletion. When enabled, any
          attempts to delete your stack will fail.
        </Typography>
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});
