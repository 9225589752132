import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import { StackConfigVendorCloudFormation } from "types/generated";
import { VENDOR_DICTIONARY } from "constants/vendor";

import {
  getRegionTooltip,
  getStackNameTooltip,
  getEntryTemplateFileTooltip,
  getTemplateBucketTooltip,
} from "./getTooltips";
import { StackSettingsContextData } from "../../Context";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";

const StackSettingsVendorCloudFormationView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const config = stackSettings.vendorConfig as StackConfigVendorCloudFormation;

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.CLOUD_FORMATION} />
      <FormFieldViewText label="Region" value={config.region} {...getRegionTooltip()} noMargin />
      <FormFieldViewText
        label="Stack name"
        value={config.stackName}
        {...getStackNameTooltip()}
        noMargin
      />
      <FormFieldViewText
        label="Entry template file"
        value={config.entryTemplateFile}
        {...getEntryTemplateFileTooltip()}
        noMargin
      />
      <FormFieldViewText
        label="Template bucket"
        value={config.templateBucket}
        {...getTemplateBucketTooltip()}
        noMargin
      />
    </>
  );
};

export default StackSettingsVendorCloudFormationView;
