import { useCallback, KeyboardEvent } from "react";

import { TreeNode } from "utils/tree";

type UseKeyboardNavigationProps<T> = {
  disabledKeys?: string[];
  expandedKeys: Set<string>;
  visibleNodes: TreeNode<T>[];
  onSelect?: (id: string) => void;
  onFocus?: (id: string) => void;
  onEscape?: () => void;
  toggleKey: (key: string) => void;
};

/**
 * Hook for keyboard navigation in tree-like structures
 * According to the ARIA specification, the following keys should be used:
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/treeitem_role#keyboard_interactions
 */
const useKeyboardNavigation = <T>({
  disabledKeys,
  expandedKeys,
  visibleNodes,
  onSelect,
  onFocus,
  onEscape,
  toggleKey,
}: UseKeyboardNavigationProps<T>) => {
  return useCallback(
    (e: KeyboardEvent, id: string) => {
      if (e.key === "Escape") {
        e.preventDefault();
        onEscape?.();
        return;
      }

      if (e.key === "Enter") {
        if (!onSelect || disabledKeys?.includes(id)) {
          return;
        }

        onSelect(id);

        return;
      }

      const node = visibleNodes.find((item) => item.id === id);

      if (!node) {
        return;
      }

      const hasChildren = node.children.length > 0;

      const isExpanded = expandedKeys.has(id);

      const currentIndex = visibleNodes.findIndex((node) => node.id === id);

      if (e.key === "ArrowDown") {
        e.preventDefault();
        const nextNode = visibleNodes[currentIndex + 1];

        if (!nextNode) {
          return;
        }

        onFocus?.(nextNode.id);
      }

      if (e.key === "ArrowUp") {
        e.preventDefault();
        const prevNode = visibleNodes[currentIndex - 1];

        if (!prevNode) {
          return;
        }

        onFocus?.(prevNode.id);
      }

      if (e.key === "ArrowRight") {
        e.preventDefault();
        if (!isExpanded && hasChildren) {
          toggleKey(id);
        } else {
          const nextNode = visibleNodes.find((node) => node.parentId === id);

          if (!nextNode) {
            return;
          }

          onFocus?.(nextNode.id);
        }
      }

      if (e.key === "ArrowLeft") {
        e.preventDefault();
        if (isExpanded && hasChildren) {
          toggleKey(id);
        } else {
          const parentId = node.parentId;

          if (!parentId) {
            return;
          }

          onFocus?.(parentId);
        }
      }

      if (e.key === "Home") {
        const firstNode = visibleNodes[0];

        if (!firstNode) {
          return;
        }

        onFocus?.(firstNode.id);
      }

      if (e.key === "End") {
        const lastNode = visibleNodes[visibleNodes.length - 1];

        if (!lastNode) {
          return;
        }

        onFocus?.(lastNode.id);
      }
    },
    [disabledKeys, visibleNodes, expandedKeys, onSelect, onFocus, toggleKey, onEscape]
  );
};

export default useKeyboardNavigation;
