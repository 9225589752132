import { NiceModalContext } from "@ebay/nice-modal-react";
import { useContext, useMemo } from "react";

export const useDrawerVisibilityForId = (id: string) => {
  const modalsMap = useContext(NiceModalContext);

  const modals = useMemo(() => Object.values(modalsMap), [modalsMap]);

  if (modals.length === 0) {
    return false;
  }

  for (const modal of modals) {
    if (modal.args?.id === id) {
      // If the modal is visible or is about to be visible
      return modal.visible || modal.delayVisible;
    }
  }

  return false;
};
