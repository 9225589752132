import { runDocument, stackDocument } from "./documents";

const Trigger = `package spacelift

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
# ⚠️ Trigger policies only take effect once attached to a Stack ⚠️
#
# Trigger policy allows you to determine what happens when a state-blocking run
# in this stack terminates. It can trigger changes in other stacks, or be used
# for retries. The outcome of the policy is a list of stack IDs for which a
# tracked run should be automatically scheduled. The list can be empty.
#
# {
#   "run": {
${runDocument(5)}
#   },
#   "stack": {
${stackDocument(5)}
#   },
#   "stacks": [{
${stackDocument(5)}
#   }]
# }
#
# Below are a few examples of what you can do here:
#
# 1) Retry a failed run, unless it's triggered by a policy or individual.
#
# trigger[stack.id] {
#   stack := input.stack
#   input.run.state == "FAILED"
#   input.run.type == "TRACKED"
#   is_null(input.run.triggered_by)
# }
#
# 2) If successful, trigger another stack, as long as it carries a magic label:
#
# trigger[stack.id] {
#   stack := input.stacks[_]
#   input.run.state == "FINISHED"
#   stack.labels[_] == concat("", ["depends-on:", input.stack.id])
# }
#
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
`;

export default Trigger;
