import { ApolloError, NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";
import sortBy from "lodash-es/sortBy";

import { StackContextAttachment } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GET_MODULE_ATTACHED_CONTEXTS, GET_STACK_ATTACHED_CONTEXTS } from "./gql";
import { EntityType, AttachedStacks } from "./types";
type UseAttachedPoliciesProps = {
  entityId: string;
  entityType: EntityType;
};

type UseAttachedPoliciesData = {
  manuallyAttachedContexts: AttachedStacks<false>;
  autoAttachedContexts: AttachedStacks<true>;
  manuallyAttachedContextIds: string[];
  isPageLoading: boolean;
  isPageNotFound: boolean;
  error: ApolloError | undefined;
};

const useGetAttachedContexts = ({
  entityId,
  entityType,
}: UseAttachedPoliciesProps): UseAttachedPoliciesData => {
  const isStack = entityType === "stack";

  const { onError } = useTypedContext(FlashContext);

  const { data, error, loading, networkStatus } = useQuery<{
    entity: {
      attachedContexts: StackContextAttachment[];
    };
  }>(isStack ? GET_STACK_ATTACHED_CONTEXTS : GET_MODULE_ATTACHED_CONTEXTS, {
    onError,
    fetchPolicy: "no-cache",
    variables: {
      entityId,
    },
  });

  const [manuallyAttachedContexts, autoAttachedContexts, manuallyAttachedContextIds] =
    useMemo(() => {
      const contexts = sortBy(data?.entity?.attachedContexts || [], "priority");
      const manuallyAttachedContextIds: string[] = [];
      const manuallyAttachedContexts: AttachedStacks<false> = [];
      const autoAttachedContexts: AttachedStacks<true> = [];

      for (const context of contexts) {
        if (context.isAutoattached) {
          autoAttachedContexts.push({
            ...context,
            isAutoattached: true,
          });
        } else {
          manuallyAttachedContextIds.push(context.contextId);

          manuallyAttachedContexts.push({
            ...context,
            isAutoattached: false,
          });
        }
      }

      return [manuallyAttachedContexts, autoAttachedContexts, manuallyAttachedContextIds];
    }, [data?.entity?.attachedContexts]);

  return {
    manuallyAttachedContexts,
    autoAttachedContexts,
    manuallyAttachedContextIds,
    isPageLoading: loading && networkStatus === NetworkStatus.loading,
    isPageNotFound: !loading && !error && !data?.entity,
    error,
  };
};

export default useGetAttachedContexts;
