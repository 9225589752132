import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { SOURCE_CODE_FIELDS_TOOLTIPS } from "../constants";
import { SourceCodeFields, SourceCodeProjects } from "../types";

type ProjectRootTooltipProps = {
  projectType: SourceCodeProjects;
};

const ProjectRootTooltip = ({ projectType }: ProjectRootTooltipProps) => (
  <>
    <TooltipModalTitle>Project root</TooltipModalTitle>
    <TooltipModalBody align="start">
      {SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.ProjectRoot][projectType].text}
      <ReadMoreDocsLink
        docsUrl={SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.ProjectRoot][projectType].link}
      />
    </TooltipModalBody>
  </>
);

export default ProjectRootTooltip;
