import { useEffect } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import { isSaasDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import { Session } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

import setLocation from "../shared/setLocation";

export const LOGOUT = gql`
  mutation Logout {
    logout {
      id
    }
  }
`;

type LogoutGql = {
  logout: Session;
};

const isSaas = isSaasDistribution();

const Logout = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { onError } = useTypedContext(FlashContext);
  const [logout, { error }] = useMutation<LogoutGql>(LOGOUT);

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const { data } = await logout();

        if (data) {
          datadogRum.clearUser();
          datadogRum.stopSessionReplayRecording();

          void client.resetStore();
          if (isSaas) {
            setLocation("https://spacelift.io");
          } else {
            navigate("/see-you-later", { replace: true });
          }
        }
      } catch (e) {
        onError(e);
      }
    };

    void asyncEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return <SpaceliftLoader />;
};

export default Logout;
