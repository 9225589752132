import { gql } from "@apollo/client";

export const GET_CONTEXTS = gql`
  query NewStackGetContexts($id: ID!) {
    attachableContextsInSpace(id: $id) {
      id
      name
    }
  }
`;

export const GET_ATTACHED_CONTEXTS = gql`
  query GetAttachedContexts($stackId: ID!) {
    stack(id: $stackId) {
      attachedContexts {
        contextId
        contextName
        contextDescription
        contextLabels
        id
        isAutoattached
        priority
      }
    }
  }
`;

export const ATTACH_CONTEXT = gql`
  mutation NewStackAttachContext($stackId: ID!, $contextId: ID!, $priority: Int!) {
    contextAttach(stack: $stackId, id: $contextId, priority: $priority) {
      id
    }
  }
`;

export const DETACH_CONTEXT = gql`
  mutation NewStackDetachContext($id: ID!) {
    contextDetach(id: $id) {
      id
    }
  }
`;
