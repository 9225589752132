import { SamlNameIdFormat } from "types/generated";

export const humanizedNameIDFormats = {
  [SamlNameIdFormat.Transient]: "Transient",
  [SamlNameIdFormat.EmailAddress]: "Email Address",
  [SamlNameIdFormat.Persistent]: "Persistent",
};

export const nameIDFormatOptions = [
  SamlNameIdFormat.Transient,
  SamlNameIdFormat.EmailAddress,
  SamlNameIdFormat.Persistent,
].map((value) => ({ value, label: humanizedNameIDFormats[value] }));
