import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { CYCLE_WORKER_POOL } from "./gql";

const useCycleWorkerPool = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [cycleWorkerPool, { loading }] = useMutation<{ workerPoolCycle: boolean }>(
    CYCLE_WORKER_POOL
  );

  const handleCycleWorkerPool = useCallback(
    (workerPoolId: string) => {
      return cycleWorkerPool({ variables: { workerPoolId } })
        .then(({ data }) => {
          if (data) {
            reportSuccess({ message: "Worker pool was cycled" });
          }
        })
        .catch(onError);
    },
    [cycleWorkerPool, onError, reportSuccess]
  );

  return {
    cycleWorkerPool: handleCycleWorkerPool,
    loading,
  };
};

export default useCycleWorkerPool;
