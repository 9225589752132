import React, { useState } from "react";
import cx from "classnames";
import { useMutation } from "@apollo/client";
import { writeStorage } from "@rehooks/local-storage";
import { datadogRum } from "@datadog/browser-rum";

import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import { FiltersContext } from "components/Filters";
import Button from "ds/components/Button";
import Toggle from "ds/components/Toggle";
import FlashContext from "components/FlashMessages/FlashContext";
import FormField from "ds/components/Form/Field";
import { getDefaultViewStorageKey } from "components/Filters/helpers";
import PageLoading from "components/loading/PageLoading";

import { getFiltersFromSavedData } from "../helpers";
import { SAVE_VIEW } from "../gql";
import styles from "./styles.module.css";
import { useSortOptions } from "../useSortOptions";
import { SavedFiltersContext } from "../Context";

type FiltersSavedViewsCreateFormProps = {
  closeForm: () => void;
};

const FiltersSavedViewsCreateForm = ({ closeForm }: FiltersSavedViewsCreateFormProps) => {
  const { reportSuccess, onError } = useTypedContext(FlashContext);
  const { activeFilters, filterType, setCurrentView, defaultEmptyFilterView } =
    useTypedContext(FiltersContext);

  const { loading, privateViews } = useTypedContext(SavedFiltersContext);

  const [name, setName] = useState("");
  const [defaultChecked, setDefaultChecked] = useState(false);
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const [saveView] = useMutation(SAVE_VIEW, { refetchQueries: ["SavedViewsList"] });
  const sortOptions = useSortOptions();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    saveView({
      variables: {
        input: {
          name,
          isPublic: false,
          type: filterType,
          data: JSON.stringify({
            key: "activeFilters",
            value: JSON.stringify({
              filters: [...activeFilters],
              ...sortOptions,
            }),
          }),
        },
      },
    })
      .then(({ data: { savedFilterCreate } }) => {
        if (savedFilterCreate) {
          reportSuccess({ message: `View ${savedFilterCreate.name} was successfully created` });
          const { order, filters, sortOption, sortDirection, search } = getFiltersFromSavedData(
            savedFilterCreate.data,
            defaultEmptyFilterView
          );
          if (defaultChecked) {
            writeStorage(getDefaultViewStorageKey(filterType), savedFilterCreate.id);
          }
          setCurrentView({
            name: savedFilterCreate.name,
            id: savedFilterCreate.id,
            isPublic: savedFilterCreate.isPublic,
            order,
            filters,
            isMine: true,
            sortOption,
            sortDirection,
            search,
          });

          setName("");
          setDefaultChecked(false);
          datadogRum.addAction("[Saved Views] Save View");
        }
      })
      .catch(onError);

    closeForm();
  };

  const error = privateViews?.find((view) => view.name === name)
    ? "View name already exists"
    : undefined;

  if (loading) {
    return <PageLoading />;
  }

  return (
    <form onSubmit={handleSubmit} className={cx(styles.wrapper, styles.wrapperSticky)}>
      <FormField
        error={error}
        label="Name"
        helperText="Save your filters, sorting and search as a view."
      >
        {({ ariaInputProps }) => (
          <Input
            onChange={handleNameChange}
            value={name}
            placeholder="Name your view"
            error={!!error}
            autoFocus
            {...ariaInputProps}
          />
        )}
      </FormField>
      <FormField>
        <Toggle
          variant="checkbox"
          checked={defaultChecked}
          id="default"
          onChange={() => setDefaultChecked(!defaultChecked)}
          ariaLabel={defaultChecked ? "Unset as your default" : "Set as your default"}
        >
          Use as your default
        </Toggle>
      </FormField>
      <div className={styles.buttons}>
        <Button variant="secondary" onClick={closeForm}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={!name || !!error}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default FiltersSavedViewsCreateForm;
