import { SelfHostedFeatureFlagsContext } from "views/SelfHosted/FeatureFlagsProvider/Context";

import useTypedContext from "./useTypedContext";

const useSelfHostedFlags = () => {
  const { flags } = useTypedContext(SelfHostedFeatureFlagsContext);

  return flags;
};

export default useSelfHostedFlags;
