import { HTMLAttributes, ReactNode } from "react";
import cx from "classnames";

import {
  NavigationChevronDownSm,
  NavigationChevronUpDown,
  NavigationChevronUpSm,
} from "components/icons";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { SearchQueryOrderDirection } from "types/generated";
import BaseAction from "ds/components/BaseAction";
import { FlexAlign } from "types/Flex";
import Box from "ds/components/Box";

import { SortOption } from "../types";
import styles from "./styles.module.css";

type AriaSort = "none" | "ascending" | "descending";

type FiltersSortHeaderSortableColumnProps = {
  option: SortOption;
  querySortOption: string | null;
  querySortDirection: SearchQueryOrderDirection | null;
  onClick: (value: string) => () => void;
  children?: ReactNode;
  gridColStart?: string;
  align?: FlexAlign;
  buttonAriaLabel?: string;
  wrapperAriaRole?: HTMLAttributes<HTMLElement>["role"];
};

const FiltersSortHeaderSortableColumn = ({
  option,
  querySortOption,
  querySortDirection,
  align = "center",
  onClick,
  children,
  gridColStart,
  buttonAriaLabel,
  wrapperAriaRole,
}: FiltersSortHeaderSortableColumnProps) => {
  const isColumnheader = wrapperAriaRole === "columnheader";
  let ariaSort: AriaSort =
    querySortDirection === SearchQueryOrderDirection.Asc ? "ascending" : "descending";

  if (querySortOption !== option.value) {
    ariaSort = "none";
  }

  return (
    <Box
      role={wrapperAriaRole}
      aria-sort={isColumnheader ? ariaSort : undefined}
      style={gridColStart ? { gridColumnStart: gridColStart } : undefined}
    >
      <BaseAction
        onClick={onClick(option.value)}
        align={align}
        className={cx(styles.columnItem, styles.sortable)}
        justify="start"
        aria-label={buttonAriaLabel}
        fullWidth
      >
        <Typography tag="span" variant="p-t6" className={styles.sortLabel}>
          {children || option.label}
        </Typography>

        <div className={styles.sortingIcons} aria-hidden>
          {option.value !== querySortOption && <Icon src={NavigationChevronUpDown} />}

          {option.value === querySortOption && (
            <>
              {querySortDirection === SearchQueryOrderDirection.Asc ? (
                <Icon src={NavigationChevronUpSm} />
              ) : (
                <Icon src={NavigationChevronDownSm} />
              )}
            </>
          )}
        </div>
      </BaseAction>
    </Box>
  );
};

export default FiltersSortHeaderSortableColumn;
