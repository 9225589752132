import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Typography from "ds/components/Typography";
import { Maybe, Policy } from "types/generated";

import { DELETE_POLICY } from "./gql";

type DeleteConfirmationProps = {
  id: string;
  name: string;
  refetchQueries?: string[];
  isLoginPolicyActive?: boolean;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  name,
  isLoginPolicyActive,
  refetchQueries,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteLoginPolicy, { loading, data }] = useMutation<{ policyDelete: Maybe<Policy> }>(
    DELETE_POLICY,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    }
  );

  const handleLoginPolicyDelete = () => {
    deleteLoginPolicy({ variables: { id } })
      .then(() => {
        modal.resolve(true);
        modal.hide();
        reportSuccess({ message: "Login policy successfully deleted" });
      })
      .catch(onError);
  };

  const onClose = () => {
    modal.resolve(false);
    modal.hide();
  };

  return (
    <DeleteConfirmationModal
      title="Delete login policy"
      onConfirm={handleLoginPolicyDelete}
      onClose={onClose}
      isLoading={loading}
      isDismissable={!loading || !data?.policyDelete?.id}
    >
      <Typography tag="p" variant="p-body2">
        Are you sure you want to delete <strong>{name}</strong> policy?{" "}
        {isLoginPolicyActive && (
          <>
            After deleting the Login policy, all active sessions (except the current one){" "}
            <Typography tag="span" variant="p-t6">
              will be invalidated
            </Typography>
            .
          </>
        )}
      </Typography>
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
