import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CodeChangesGroupNode } from "./types";
import styles from "./styles.module.css";
import Group from "./components/Group";
import { CodeChangesContext } from "./CodeChangesContext";

type CodeChangesProps = {
  changes: CodeChangesGroupNode;
  collapsedGroups?: Set<string>;
  onGroupToggle?: (collapsedGroups: Set<string>) => void;
  onResize?: (height: number) => void;
};

const CodeChanges = ({
  changes,
  collapsedGroups: externalState,
  onGroupToggle,
  onResize,
}: CodeChangesProps) => {
  const [collapsedGroups, setCollapsedGroups] = useState<Set<string>>(new Set(externalState));
  const ref = useRef<HTMLDivElement>(null);

  const toggleGroup = useCallback(
    (id: string) => {
      setCollapsedGroups((oldSet) => {
        const newSet = new Set(oldSet);
        if (newSet.has(id)) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }

        onGroupToggle?.(newSet);
        return newSet;
      });
    },
    [onGroupToggle]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      onResize?.(entry.contentRect.height);
    });

    if (ref.current && onResize) resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [onResize]);

  const contextValue = useMemo(
    () => ({ collapsedGroups, toggleGroup }),
    [collapsedGroups, toggleGroup]
  );

  return (
    <CodeChangesContext.Provider value={contextValue}>
      <div className={styles.codeChangesWrapper} ref={ref}>
        {changes.children.map((child) => (
          <Group key={child.id} item={child} />
        ))}
      </div>
    </CodeChangesContext.Provider>
  );
};

export default CodeChanges;
