import { gql, TypedDocumentNode } from "@apollo/client";

import { DataPoint, Maybe, MetricsInput } from "types/generated";

type GetNumberOfDeploymentsData = {
  metricsRange: Maybe<{
    numberOfDeploymentsRange: Array<Omit<DataPoint, "__typename" | "labels">>;
  }>;
};

export const GET_NUMBER_OF_DEPLOYMENTS: TypedDocumentNode<
  GetNumberOfDeploymentsData,
  MetricsInput
> = gql`
  query GetNumberOfDeployments($startTime: Int!, $endTime: Int!) {
    metricsRange(input: { startTime: $startTime, endTime: $endTime }) {
      numberOfDeploymentsRange {
        timestamp
        value
      }
    }
  }
`;
