import { useHref, useSearchParams } from "react-router-dom-v5-compat";
import { useMemo } from "react";

import { ACCOUNT_DETAILS_QUERY_PARAM, ACCOUNT_DETAILS_VISIBLE_PARAM } from "./constants";

export const useAccountDetailsLocationDescriptor = () => {
  const [showAccountDetailsParams] = useSearchParams();

  const search = useMemo(() => {
    const urlParams = new URLSearchParams(showAccountDetailsParams);
    urlParams.set(ACCOUNT_DETAILS_QUERY_PARAM, ACCOUNT_DETAILS_VISIBLE_PARAM);

    return urlParams.toString();
  }, [showAccountDetailsParams]);

  return useHref({ search });
};
