import { ReactNode } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { SpacingShorthand } from "types/Spacing";

import styles from "./styles.module.css";

type HistoryEntryNoteProps = {
  children: ReactNode;
  className?: string;
  margin?: SpacingShorthand;
};

function HistoryEntryNote({ children, className, margin }: HistoryEntryNoteProps) {
  return (
    <Box padding="small medium" className={styles.note} margin={margin}>
      <Typography tag="div" variant="p-body3" className={className}>
        {children}
      </Typography>
    </Box>
  );
}

export default HistoryEntryNote;
