import { ModuleShareFormFields } from "../../types";

export const validateAccountNameFormat = (currentValue: string) => {
  if (currentValue.length === 0) {
    return undefined;
  }

  return /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/.test(currentValue)
    ? undefined
    : "Account name must consist of lower case alphanumeric characters or '-'";
};

export const validateAccountNameUniqueness = (
  currentValue: string,
  { sharedAccounts }: ModuleShareFormFields
) => {
  if (!currentValue || !sharedAccounts) {
    return undefined;
  }

  const occurrences = sharedAccounts.filter(({ value }) => value === currentValue).length;

  return occurrences > 1 ? "Account name must be unique" : undefined;
};
