import React from "react";
import { LocationDescriptor } from "history";
import cx from "classnames";
import { NavLink } from "react-router-dom-v5-compat";

import styles from "./styles.module.css";

type SettingItemLinkProps = {
  title: string;
  description: JSX.Element | string;
  to: LocationDescriptor;
  icon?: JSX.Element;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const SettingItemLink = (props: SettingItemLinkProps) => {
  const { children, title, description, to, icon, className, disabled } = props;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) e.preventDefault();
  };

  return (
    <div className={cx(styles.linkWrapper, className)}>
      <NavLink
        to={to}
        className={cx(styles.link, {
          [styles.disabled]: disabled,
        })}
        onClick={handleClick}
      >
        {icon}

        <div>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
        </div>
      </NavLink>

      {children}
    </div>
  );
};

export default SettingItemLink;
