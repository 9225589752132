import { SortableTableColumn } from "components/SortableTable";

export const COLUMN_ORDER = "minmax(12rem, 4fr) 2fr 1fr 1fr";

export const COLUMN_ORDER_WITH_OWNER = "minmax(12rem, 4fr) 2fr 3fr 1fr 1fr";

export const COLUMNS: SortableTableColumn[] = [
  {
    value: "name",
    label: "Key name",
  },
  {
    value: "id",
    label: "Key ID",
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

export const OWNER_COLUMNS: SortableTableColumn[] = [
  {
    value: "owner",
    label: "Owner (login)",
  },
  {
    value: "name",
    label: "Key name",
  },
  {
    value: "id",
    label: "Key ID",
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];
