import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";

import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Feedback from "ds/components/Feedback";
import { ScheduledRun } from "types/generated";
import { getNowDateTime } from "utils/date";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FormFieldSegmentedSchedule from "components/FormFields/SegmentedSchedule";
import ToggleField from "ds/components/Form/ToggleField";
import CodeEditor from "components/CodeEditor";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import useStackScheduledRunCreate from "shared/Stack/Scheduling/useStackScheduledRunCreate";
import { StackContext } from "views/Stack/Context";
import useStackScheduledRunUpdate from "shared/Stack/Scheduling/useStackScheduledRunUpdate";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { ScheduledRunFormFields } from "./types";
import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import { StackSchedulingContextApi } from "../Context";

const REFETCH_QUERIES = ["GetStackScheduling"];

type StackManageScheduledRunProps = {
  integration?: ScheduledRun;
  onCancel: () => void;
};

const StackManageScheduledRun = ({ integration, onCancel }: StackManageScheduledRunProps) => {
  const isEditMode = !!integration;

  const minDate = getNowDateTime({ timezone: "UTC" });
  const defaultDateTime = getNowDateTime({ timezone: "UTC", timeShiftMinutes: 15 });

  const { stack } = useTypedContext(StackContext);
  const { onClose } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const scheduledRunForm = useForm<ScheduledRunFormFields>({
    defaultValues: getFormDefaultValues(defaultDateTime, integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isValid, isDirty },
    control,
    watch,
    handleSubmit,
    trigger,
  } = scheduledRunForm;

  const { createScheduledRun, loading: creationLoading } = useStackScheduledRunCreate({
    refetchQueries: REFETCH_QUERIES,
  });
  const { updateScheduledRun, loading: updateLoading } = useStackScheduledRunUpdate({
    refetchQueries: REFETCH_QUERIES,
  });

  const loading = creationLoading || updateLoading;

  const isRecurring = watch("recurring");
  const withCustomRuntimeConfigWatched = watch("withCustomRuntimeConfig");

  const handlePrimaryAction = (formData: ScheduledRunFormFields) => {
    const input = mapCreateFormData(formData);

    if (isEditMode) {
      updateScheduledRun(stack.id, integration.id, input, () => {
        onClose();
        trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.RUN });
      });
    } else {
      createScheduledRun(stack.id, input, () => {
        onClose();
        trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.RUN });
      });
    }
  };

  useEffect(() => {
    if (isEditMode && !isRecurring) {
      trigger();
    }
  }, [isEditMode, trigger, isRecurring]);

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isEditMode ? "Edit run schedule" : "Create run schedule"} />
        <DrawerCloseIcon handleCloseDrawer={onCancel} />
      </DrawerHeader>
      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Run schedule only works on private workers.
        </Feedback>

        <FormProvider {...scheduledRunForm}>
          <FormField label="Schedule name" isOptional error={errors?.name?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Type schedule name..."
                error={!!errors?.name}
                {...register("name")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <Controller
            name="withCustomRuntimeConfig"
            control={control}
            render={({ field }) => (
              <ToggleField
                variant="switch"
                title="Attach custom runtime config"
                onChange={field.onChange}
                checked={field.value}
              />
            )}
          />

          {withCustomRuntimeConfigWatched && (
            <Controller
              control={control}
              name="customRuntimeConfig"
              render={({ field }) => (
                <FormField label="Custom config" noMargin>
                  <CodeEditor
                    body={field.value}
                    onChange={field.onChange}
                    language="yaml"
                    skeletonCount={7}
                    fixedHeight={180}
                    rounded
                  />
                </FormField>
              )}
            />
          )}

          <FormFieldSegmentedSchedule minDate={minDate} />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(handlePrimaryAction)}
                loading={loading}
                disabled={loading || !isValid || (isEditMode && !isDirty)}
              >
                {isEditMode ? "Save" : "Create"}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </FormProvider>
      </DrawerBody>
    </>
  );
};

export default StackManageScheduledRun;
