import useTypedContext from "hooks/useTypedContext";

import BreadcrumbsItem from "./BreadcrumbsItem";
import { BreadcrumbsContextData } from "./Context";
import styles from "./styles.module.css";

const Breadcrumbs = () => {
  const items = useTypedContext(BreadcrumbsContextData);

  if (items.length <= 0) return null;

  return (
    <ul className={styles.breadcrumbs}>
      {items.map((item, i) => (
        <BreadcrumbsItem key={item.title || i} item={item} />
      ))}
    </ul>
  );
};
export default Breadcrumbs;
