import Link from "ds/components/Link";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";

const StackRunsIgnoredRunCalloutPermanentCloseConfirmationTooltip = () => (
  <>
    <TooltipModalTitle>Don't show the warning again</TooltipModalTitle>
    <TooltipModalBody align="start">
      <Typography tag="p" variant="p-body3">
        You can change this in your{" "}
        <Link to={"/personal-settings/notifications-customization"} inheritFont>
          personal settings.
        </Link>
      </Typography>
    </TooltipModalBody>
  </>
);

export default StackRunsIgnoredRunCalloutPermanentCloseConfirmationTooltip;
