import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ApiKey } from "types/generated";

import { DELETE_API_KEY } from "./gql";

type DeleteConfirmationProps = {
  id: string;
  name: string;
  refetchQueriesOnDelete?: string[];
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  name,
  refetchQueriesOnDelete,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [deleteApiKey, { loading }] = useMutation(DELETE_API_KEY, {
    refetchQueries: refetchQueriesOnDelete,
    awaitRefetchQueries: true,
  });

  const handleApiKeyDelete = (id: ApiKey["id"]) => {
    deleteApiKey({ variables: { id } })
      .then(() => {
        modal.resolve();
        modal.hide();
        reportSuccess({ message: "API key successfully deleted" });
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete API key"
      name={name}
      onConfirm={() => handleApiKeyDelete(id)}
      isLoading={loading}
      isDismissable={!loading}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
