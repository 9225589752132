import { gql } from "@apollo/client";

export const CREATE_OIDC = gql`
  mutation CreateOIDC(
    $clientId: String!
    $clientCredentials: String!
    $identityProviderHost: String!
    $claimMapping: ClaimMappingInput
  ) {
    oidcCreate(
      clientId: $clientId
      clientCredentials: $clientCredentials
      identityProviderHost: $identityProviderHost
      claimMapping: $claimMapping
    ) {
      adminLogin
    }
  }
`;

export const DELETE_OIDC = gql`
  mutation DeleteOIDC {
    oidcDelete {
      clientId
    }
  }
`;

export const UPDATE_OIDC = gql`
  mutation UpdateOIDC($input: OIDCUpdateInput!) {
    oidcUpdate(input: $input) {
      claimMapping {
        entries {
          name
          value
        }
      }
    }
  }
`;
