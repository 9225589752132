import { useSearchParams } from "react-router-dom-v5-compat";

import { DefaultDashboardTabs, TAB_QUERY_STRING } from "./constants";

const useCurrentTab = () => {
  const [params] = useSearchParams();

  return params.get(TAB_QUERY_STRING) || DefaultDashboardTabs.Overview;
};

export default useCurrentTab;
