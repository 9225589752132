import { gql } from "@apollo/client";

export const GET_ENVIRONMENT = gql`
  query GetEnvironment($stackId: ID!) {
    stack(id: $stackId) {
      id
      attachedContexts {
        id
        contextName
        config {
          ...configElement
        }
      }
      canWrite
      name
      runtimeConfig {
        context {
          contextId
          contextName
        }
        element {
          ...configElement
        }
        stackDependency {
          id
          dependsOnStack {
            id
            name
          }
        }
      }
      trackedCommit {
        url
        hash
      }
      trackedBranchHead {
        url
        hash
      }
      trackedCommitSetBy
    }
  }
  fragment configElement on ConfigElement {
    createdAt
    id
    runtime
    type
    value
    writeOnly
  }
`;
