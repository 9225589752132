import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { REVOKE_GPG_KEY } from "../gql";
import { GpgKeyListItemDropdownProps, RevokeGpgKeyGql } from "./types";
import { showGpgKeyListItemDropdownDeleteConfirmation } from "./DeleteConfirmation";
import { showUpdateGpgKeyDrawer } from "../UpdateGpgKeyDrawer";

const GpgKeyListItemDropdown = ({ item }: GpgKeyListItemDropdownProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const [revokeGpgKey] = useMutation<RevokeGpgKeyGql>(REVOKE_GPG_KEY, {
    refetchQueries: ["GetGpgKeys"],
  });

  const handleRevokeGpgKey = () => {
    revokeGpgKey({ variables: { id: item.id } })
      .then(({ data }) => {
        if (data?.gpgKeyRevoke?.revokedAt) {
          reportSuccess({
            message: `GPG key "${data.gpgKeyRevoke.name}" was successfully revoked`,
          });
        } else {
          reportError({
            message: "Something went wrong while revoking GPG key, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const handleEditGpgKey = () => {
    showUpdateGpgKeyDrawer({ id: item.id, gpgKey: item });
  };

  const canRevokeGpgKey = !item.revokedAt && viewer.admin;

  const onRevokeConfirmation = () => {
    showGpgKeyListItemDropdownDeleteConfirmation({
      onConfirm: handleRevokeGpgKey,
      keyName: item.name,
    });
  };

  return (
    <DropdownMenuEllipsis tooltip="GPG key actions">
      {viewer.admin && <DropdownMenuItem onAction={handleEditGpgKey}>Edit</DropdownMenuItem>}
      <CopyFieldDropdownMenuItem title="Copy ID" value={item.id} />
      {canRevokeGpgKey && (
        <DropdownMenuItem onAction={onRevokeConfirmation} danger>
          Revoke
        </DropdownMenuItem>
      )}
    </DropdownMenuEllipsis>
  );
};

export default GpgKeyListItemDropdown;
