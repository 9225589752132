import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import { StackConfigVendorKubernetes } from "types/generated";
import { kubernetesWorkflowToolLabel } from "constants/kubernetes_workflow_tools";
import { VENDOR_DICTIONARY } from "constants/vendor";

import { StackSettingsContextData } from "../../Context";
import {
  getKubectlVersionTooltip,
  getNamespaceTooltip,
  getWorkflowToolTooltip,
} from "./getTooltips";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";

const StackSettingsVendorKubernetesView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const config = stackSettings.vendorConfig as StackConfigVendorKubernetes;

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.KUBERNETES} />
      <FormFieldViewText
        label="Namespace"
        value={config.namespace}
        {...getNamespaceTooltip()}
        noMargin
      />
      <FormFieldViewText
        label="Workflow tool"
        value={kubernetesWorkflowToolLabel(config.kubernetesWorkflowTool)}
        {...getWorkflowToolTooltip()}
        noMargin
      />
      <FormFieldViewText
        label="Kubectl version"
        value={config.kubectlVersion}
        {...getKubectlVersionTooltip()}
        noMargin
      />
    </>
  );
};

export default StackSettingsVendorKubernetesView;
