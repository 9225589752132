import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type ManageDriftDetectionDeleteConfirmationModalProps = {
  onConfirm: () => void;
};

const ManageDriftDetectionDeleteConfirmationModal = create(
  function ManageDriftDetectionDeleteConfirmationModal({
    onConfirm,
  }: ManageDriftDetectionDeleteConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      modal.resolve();
      modal.hide();
      onConfirm();
    };

    return (
      <ConfirmationModal
        title="Delete scheduling"
        confirmationButtonLabel="Delete"
        confirmationButtonVariant="dangerPrimary"
        onConfirm={handleOnConfirm}
        size="large"
      >
        <Typography tag="p" variant="p-body2">
          Are you sure you want to delete{" "}
          <Typography tag="span" variant="p-t6">
            Drift detection
          </Typography>{" "}
          scheduling?
        </Typography>
      </ConfirmationModal>
    );
  }
);

export const showManageDriftDetectionDeleteConfirmationModal = (
  props: ManageDriftDetectionDeleteConfirmationModalProps
) => show(ManageDriftDetectionDeleteConfirmationModal, props);
