import { useParams } from "react-router-dom-v5-compat";

import { PUBLIC_WORKER_POOL_ID } from "./constants";

export const useWorkerPoolInfo = () => {
  const { workerPoolId } = useParams<{ workerPoolId: string }>();
  return {
    workerPoolId: workerPoolId || "",
    isWorkerPoolPublic: PUBLIC_WORKER_POOL_ID === workerPoolId,
  };
};
