import { gql } from "@apollo/client";

export const GET_ACCOUNT_SETTINGS = gql`
  query GetAccountSettings($isSaas: Boolean = false) {
    authorizationScheme
    slackIntegration {
      teamName
    }
    accountCanBeDeleted
    accountCanBeDeletedAt
    apiKeys {
      admin
      id
      createdAt
      creator
      description
      lastUsedAt
      teams
      name
      type
      oidcSettings {
        clientId
        issuer
        subjectExpression
      }
      teamCount
      spaceCount
      accessRules {
        space
        spaceAccessLevel
      }
    }
    markedForDeletion
    azureIntegrations {
      adminConsentProvided
      adminConsentURL
      applicationId
      createdAt
      updatedAt
      displayName
      id
      labels
      name
      tenantId
      defaultSubscriptionId
      attachedStacks {
        id
        isModule
        stackId
        stackName
        read
        write
        subscriptionId
      }
    }
    securityEmail @include(if: $isSaas)
  }
`;

export const TOGGLE_ACCOUNT_DELETION_MARK = gql`
  mutation ToggleAccountDeletionMark {
    accountToggleDeletionMark
  }
`;
