import EmptyState from "ds/components/EmptyState";
import { GroupColored } from "components/icons";
import Box from "ds/components/Box";

const IdpGroupMappingEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You don’t have any IdP groups yet"
        icon={GroupColored}
        caption="Map groups from your identity provider to assign them access to Spacelift."
      />
    </Box>
  );
};

export default IdpGroupMappingEmpty;
