import { gql } from "@apollo/client";

export const SPACE_CREATE = gql`
  mutation SpaceCreate($input: SpaceInput!) {
    spaceCreate(input: $input) {
      id
      name
      description
      parentSpace
      inheritEntities
      accessLevel
      labels
    }
  }
`;

export const SPACE_UPDATE = gql`
  mutation SpaceUpdate($spaceId: ID!, $input: SpaceInput!) {
    spaceUpdate(space: $spaceId, input: $input) {
      id
      name
      description
      parentSpace
      inheritEntities
      accessLevel
      labels
    }
  }
`;

export const SPACE_DELETE = gql`
  mutation SpaceDelete($spaceId: ID!) {
    spaceDelete(space: $spaceId) {
      id
    }
  }
`;
