import { KubernetesWorkflowTool } from "types/generated";

/**
 * The Kubernetes workflow tool options to use in a select.
 */
export const KubernetesWorkflowToolOptions = [
  { label: "Kubernetes", value: KubernetesWorkflowTool.Kubernetes },
  { label: "Custom", value: KubernetesWorkflowTool.Custom },
];

/**
 * Gets the label value for the specified workflow tool.
 * @param value the workflow tool enum value
 * @returns the label value, or the raw enum value if the tool is unknown.
 */
export function kubernetesWorkflowToolLabel(value: KubernetesWorkflowTool): string {
  const option = KubernetesWorkflowToolOptions.find((o) => o.value === value);
  if (option) {
    return option.label;
  }

  return value;
}
