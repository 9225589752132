import { ReactNode, useEffect } from "react";

import useTypedContext from "hooks/useTypedContext";

import WarningContext from ".";

export const useObserveForWarning = (shouldShowWarning: boolean, warningMessage: ReactNode) => {
  const { warning, setWarning, clearWarning } = useTypedContext(WarningContext);

  if (shouldShowWarning && !warning) {
    setWarning(warningMessage);
  } else if (!shouldShowWarning && warning) {
    clearWarning();
  }

  useEffect(() => clearWarning, [clearWarning]);
};
