import { ReactNode } from "react";

import {
  PlanPoliciesOutcome,
  PolicyType,
  RunComment,
  RunDependency,
  RunExternalDependency,
  RunPolicyReceipt,
  RunReview,
  RunState,
  RunStateTransition,
} from "types/generated";

import { RunEntryContext } from "../types";
import { createApprovalPoliciesEntry } from "./createApprovalPoliciesEntry";
import { createPlanPoliciesEntry } from "./createPlanPoliciesEntry";
import { createTriggerPoliciesEntry } from "./createTriggerPoliciesEntry";
import { createInitializationPoliciesEntry } from "./createInitializationPoliciesEntry";
import { createNotificationPoliciesEntry } from "./createNotificationPoliciesEntry";
import { createPushPoliciesEntry } from "./createPushPoliciesEntry";
import { resetShownElements } from "./elements";
import { createPreparingEntry } from "./createPreparingEntry";
import { createUnconfirmedEntry } from "./createUnconfirmedEntry";
import { createCommentEntry } from "./createCommentEntry";
import { createSkippedEntry } from "./createSkippedEntry";
import { createDestroyingEntry } from "./createDestroyingEntry";
import { createPendingReviewEntry } from "./createPendingReviewEntry";
import { createPerformingEntry } from "./createPerformingEntry";
import { createStoppedEntry } from "./createStoppedEntry";
import { createCanceledEntry } from "./createCanceledEntry";
import { createFinishedEntry } from "./createFinishedEntry";
import { createApplyingEntry } from "./createApplyingEntry";
import { createConfirmedEntry } from "./createConfirmedEntry";
import { createFailedEntry } from "./createFailedEntry";
import { createReplanRequestedEntry } from "./createReplanRequestedEntry";
import { createDiscardedEntry } from "./createDiscardedEntry";
import { createReadyEntry } from "./createReadyEntry";
import { createQueuedEntry } from "./createQueuedEntry";
import { createInitializingEntry } from "./createInitializingEntry";
import { createPlanningEntry } from "./createPlanningEntry";
import { createDependenciesEntry } from "./createDependenciesEntry";
import { createCollection } from "./createCollection";
import { isRunPolicyReceipt } from "./isRunPolicyReceipt";

type CombinedItems = Array<
  RunStateTransition | RunPolicyReceipt | RunReview | RunComment | PlanPoliciesOutcome
>;

type TimelineItems = {
  history: Array<RunStateTransition>;
  policyReceipts: Array<RunPolicyReceipt>;
  reviews: Array<RunReview>;
  comments: Array<RunComment>;
  planPoliciesOutcomes: Array<PlanPoliciesOutcome>;
  runDependencies: Array<RunDependency> | undefined;
  externalDependencies: Array<RunExternalDependency> | undefined;
};

export const createRunTimeline = (
  {
    history,
    policyReceipts,
    reviews,
    comments,
    planPoliciesOutcomes,
    runDependencies,
    externalDependencies,
  }: TimelineItems,
  context: RunEntryContext
) => {
  const combinedItems = ([] as CombinedItems)
    .concat(history, policyReceipts, comments, reviews, planPoliciesOutcomes)
    .sort((a, b) => {
      const aTimestamp = "timestamp" in a ? a.timestamp : a.createdAt;
      const bTimestamp = "timestamp" in b ? b.timestamp : b.createdAt;
      const difference = aTimestamp - bTimestamp;

      if (
        difference === 0 &&
        "stateVersion" in a &&
        "stateVersion" in b &&
        a.stateVersion !== null &&
        b.stateVersion !== null
      ) {
        // If the timestamps are the same, we want to sort by state version
        // to ensure that we show the correct state transitions in the correct order.
        return a.stateVersion - b.stateVersion;
      }

      return difference;
    });

  // If we're in a simple view, we don't want to show before/after hooks around logs.
  if (context.isSimpleView) {
    context = {
      ...context,
      runHooks: {
        afterRun: undefined,
        afterApply: undefined,
        beforeApply: undefined,
        afterDestroy: undefined,
        beforeDestroy: undefined,
        afterInit: undefined,
        beforeInit: undefined,
        afterPerform: undefined,
        beforePerform: undefined,
        afterPlan: undefined,
        beforePlan: undefined,
      },
    };
  }

  const approvalPoliciesCollection = createCollection<RunPolicyReceipt | RunReview>();
  const planPoliciesCollection = createCollection<RunPolicyReceipt>();
  const triggerPoliciesCollection = createCollection<RunPolicyReceipt>();
  const initializationPoliciesCollection = createCollection<RunPolicyReceipt>();
  const notificationPoliciesCollection = createCollection<RunPolicyReceipt>();
  const pushPoliciesCollection = createCollection<RunPolicyReceipt>();

  let planPoliciesOutcome: PlanPoliciesOutcome | null = null;

  const timeline: ReactNode[] = [];

  for (let i = combinedItems.length - 1; i >= 0; i--) {
    const item = combinedItems[i];

    if (item.__typename === "RunStateTransition") {
      let willRenderApprovalPolicies = false;
      let initializationPoliciesEntry: ReactNode | null = null;

      if (notificationPoliciesCollection.hasItems) {
        timeline.push(
          createNotificationPoliciesEntry(notificationPoliciesCollection.drain(), context)
        );
      }

      if (
        approvalPoliciesCollection.hasItems &&
        (item.state === RunState.Queued ||
          item.state === RunState.Unconfirmed ||
          item.state === RunState.PendingReview)
      ) {
        const approvalPoliciesEntry = createApprovalPoliciesEntry(
          approvalPoliciesCollection.drain(),
          context
        );

        if (approvalPoliciesEntry) {
          timeline.push(approvalPoliciesEntry);
          willRenderApprovalPolicies = true;
        }
      }

      if (planPoliciesCollection.hasItems && item.state === RunState.Planning) {
        timeline.push(
          createPlanPoliciesEntry(planPoliciesCollection.drain(), planPoliciesOutcome, context)
        );
        planPoliciesOutcome = null;
      }

      if (
        triggerPoliciesCollection.hasItems &&
        (item.state === RunState.Canceled ||
          item.state === RunState.Discarded ||
          item.state === RunState.Stopped ||
          item.state === RunState.Failed ||
          item.state === RunState.Finished)
      ) {
        timeline.push(createTriggerPoliciesEntry(triggerPoliciesCollection.drain(), context));
      }

      if (
        item.state === RunState.Queued &&
        (runDependencies?.length || externalDependencies?.length)
      ) {
        timeline.push(createDependenciesEntry(runDependencies, externalDependencies, context));
      }

      if (initializationPoliciesCollection.hasItems && item.state === RunState.Preparing) {
        initializationPoliciesEntry = createInitializationPoliciesEntry(
          initializationPoliciesCollection.drain(),
          context
        );
      }

      switch (item.state) {
        case RunState.Preparing:
        case RunState.PreparingApply:
        case RunState.PreparingReplan:
          timeline.push(createPreparingEntry(item, context, !!initializationPoliciesEntry));
          break;
        case RunState.Planning:
          timeline.push(createPlanningEntry(item, context));
          break;
        case RunState.Initializing:
          timeline.push(createInitializingEntry(item, context));
          break;
        case RunState.Queued:
          timeline.push(createQueuedEntry(item, context));
          break;
        case RunState.Ready:
          timeline.push(createReadyEntry(item, context));
          break;
        case RunState.Unconfirmed:
          timeline.push(createUnconfirmedEntry(item, context, willRenderApprovalPolicies));
          break;
        case RunState.Discarded:
          timeline.push(createDiscardedEntry(item, context));
          break;
        case RunState.ReplanRequested:
          timeline.push(createReplanRequestedEntry(item));
          break;
        case RunState.Failed:
          timeline.push(createFailedEntry(item, context));
          break;
        case RunState.Confirmed:
          timeline.push(createConfirmedEntry(item));
          break;
        case RunState.Applying:
          timeline.push(createApplyingEntry(item, context));
          break;
        case RunState.Finished:
          timeline.push(createFinishedEntry(item, context));
          break;
        case RunState.Canceled:
          timeline.push(createCanceledEntry(item));
          break;
        case RunState.Stopped:
          timeline.push(createStoppedEntry(item, context));
          break;
        case RunState.Performing:
          timeline.push(createPerformingEntry(item, context));
          break;
        case RunState.PendingReview:
          timeline.push(createPendingReviewEntry(item));
          break;
        case RunState.Destroying:
          timeline.push(createDestroyingEntry(item, context));
          break;
        case RunState.Skipped:
          timeline.push(createSkippedEntry(item));
          break;
        default:
          break;
      }

      // Runs started by trigger policies should display policy receipts
      // before the queued run state to show the reason for the run.
      if (triggerPoliciesCollection.hasItems && item.state === RunState.Queued) {
        timeline.push(createTriggerPoliciesEntry(triggerPoliciesCollection.drain(), context));
      }

      if (initializationPoliciesEntry) {
        timeline.push(initializationPoliciesEntry);
      }

      if (pushPoliciesCollection.hasItems && item.state === RunState.Queued) {
        timeline.push(createPushPoliciesEntry(pushPoliciesCollection.drain(), context));
      }
    } else if (item.__typename === "RunReview" || isRunPolicyReceipt(item, PolicyType.Approval)) {
      approvalPoliciesCollection.add(item);
    } else if (isRunPolicyReceipt(item, PolicyType.Plan)) {
      planPoliciesCollection.add(item);
    } else if (isRunPolicyReceipt(item, PolicyType.Trigger) && !context.isSimpleView) {
      triggerPoliciesCollection.add(item);
    } else if (isRunPolicyReceipt(item, PolicyType.Initialization)) {
      initializationPoliciesCollection.add(item);
    } else if (isRunPolicyReceipt(item, PolicyType.Notification) && !context.isSimpleView) {
      notificationPoliciesCollection.add(item);
    } else if (isRunPolicyReceipt(item, PolicyType.GitPush) && !context.isSimpleView) {
      pushPoliciesCollection.add(item);
    } else if (item.__typename === "RunComment") {
      timeline.push(createCommentEntry(item));
    } else if (item.__typename === "PlanPoliciesOutcome") {
      planPoliciesOutcome = item;
    }
  }

  resetShownElements();

  return timeline;
};
