import { gql } from "@apollo/client";

import actionFields from "views/shared/run/ActionButtons/ActionFragment";

export const GET_RUN_V3 = gql`
  query GetRunV3($stackId: ID!, $runId: ID!) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        ...actionFields
        branch
        createdAt
        changesV3(input: {}) {
          resources {
            address
            previousAddress
            metadata {
              type
            }
          }
        }
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
        }
        expired
        isMostRecent
        supportsDetailedResources
        title
        type
      }
    }
  }
  ${actionFields}
`;
