import { BillingAddonType, BillingTier } from "types/generated";

import { StripeSubscriptionStatus, V4BillingTier } from "./types";

export const TRIAL_DAYS_COUNT = 14;
export const URL_INTERVAL_PARAM = "interval";

export const TIERS_SEQUENCE: V4BillingTier[] = [
  BillingTier.V4Free,
  BillingTier.V4Starter,
  BillingTier.V4StarterPlus,
  BillingTier.V4Business,
  BillingTier.V4Enterprise,
];

export const TALK_TO_SALES_LINK = "https://spacelift.io/contact";

/**
 * TODO: we need to decide how to handle incomplete and incomplete_expired statuses.
 * Currently on the backend, incomplete is considered as "isActive" but incomplete_expired is not.
 * Hence user will never see incomplete_expired status.
 * We have a backend ticket to look into this deeper and potentially remove isActive field from billingSubscription
 * as well as handle incomplete and incomplete_expired statuses in a more consistent way.
 * https://app.clickup.com/t/869594d3q
 */
export const SUBSCRIPTION_ACTIVE_STATUSES = [
  StripeSubscriptionStatus.Active,
  StripeSubscriptionStatus.Incomplete,
];

export const PAYMENT_FAILED_STATUSES = [
  StripeSubscriptionStatus.IncompleteExpired,
  StripeSubscriptionStatus.PastDue,
  StripeSubscriptionStatus.Unpaid,
];

export const STARTER_ANNUAL_DISCOUNT = 17;

export const ADDON_LABELS: Record<BillingAddonType, string> = {
  [BillingAddonType.UnlimitedSeats]: "Unlimited users",
  [BillingAddonType.AdditionalPublicWorker]: "+ 1 Public Worker",
};
