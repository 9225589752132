import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { WorkerPoolUser } from "types/generated";

import WorkerPoolUsedByListItem from ".";

type WorkerPoolUsedByVirtualizedListItemProps = {
  items: WorkerPoolUser[];
};

const WorkerPoolUsedByVirtualizedListItem = (
  props: ListChildComponentProps<
    WorkerPoolUsedByVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <WorkerPoolUsedByListItem
        key={data.items[index].stackId}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(WorkerPoolUsedByVirtualizedListItem, areEqual);
