import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import { ManagedUserGroupIntegration } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";
import TabPanel from "ds/components/TabPanel";
import TabWithCounter from "ds/components/Tab/WithCounter";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { GroupsIntegrationsDrawerTabs, UpdateGroupIntegrationFields } from "./types";
import useUpdateGroup from "./useUpdateGroupIntegration";
import styles from "./styles.module.css";
import { UserManagementActivationStatus } from "../../types";

type GroupsIntegrationsDetailsDrawerContentProps = {
  integration: ManagedUserGroupIntegration;
  setDrawerVisibility: (isVisible: boolean) => void;
  activationStatus: UserManagementActivationStatus;
};

const GroupsIntegrationsDetailsDrawerContent = ({
  integration,
  setDrawerVisibility,
  activationStatus,
}: GroupsIntegrationsDetailsDrawerContentProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [currentTab, setCurrentTab] = useState("spaces");

  const groupIntegrationUpdateForm = useForm<UpdateGroupIntegrationFields>({
    defaultValues: {
      spaces:
        integration.accessRules?.length > 0
          ? integration.accessRules
          : [{ space: undefined, spaceAccessLevel: undefined }],
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = groupIntegrationUpdateForm;

  const { onUpdate } = useUpdateGroup();

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const onSubmit: SubmitHandler<UpdateGroupIntegrationFields> = (formData) => {
    const input = {
      id: integration.id,
      integrationType: integration.integrationType,
      integrationName: integration.integrationName,
      accessRules: formData.spaces,
      slackChannelID: integration.slackChannelID || "",
    };

    onUpdate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Slack access was updated`,
          });

          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  return (
    <FormProvider {...groupIntegrationUpdateForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Access details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight hasStickyFooter>
        {activationStatus === UserManagementActivationStatus.INACTIVE && (
          <Banner variant="warning" title="User management is inactive">
            Access rules will not take effect until you activate user management.
            <FeedbackActions>
              <Button
                to="/settings/management-strategy/"
                analyticsPage={AnalyticsPageOrganization.OrganizationSlackAccess}
                analyticsTitle="Callout Strategy Click"
                variant="contrast"
                size="small"
              >
                Choose strategy
              </Button>
            </FeedbackActions>
          </Banner>
        )}

        <FormFieldViewText label="Name" value={integration.integrationName} />

        <FormFieldViewText label="Slack channel ID" value={integration.slackChannelID} withCopy />

        <Box direction="row" align="center" className={styles.tabs}>
          <TabWithCounter
            onClick={setCurrentTab}
            isActive={currentTab === GroupsIntegrationsDrawerTabs.Spaces}
            id={GroupsIntegrationsDrawerTabs.Spaces}
            label="Spaces"
            count={integration.accessRules?.length}
          />
        </Box>

        <TabPanel
          isActive={currentTab === GroupsIntegrationsDrawerTabs.Spaces}
          id={GroupsIntegrationsDrawerTabs.Spaces}
        >
          <div className={styles.listWrapper}>
            <FormFieldSpace />
          </div>
        </TabPanel>

        <DrawerFooter sticky>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save changes
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default GroupsIntegrationsDetailsDrawerContent;
