import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";

import { UPDATE_VERSION_DESCRIPTION } from "../../gql";

type ModuleVersionAddDescriptionDrawerProps = {
  description: string | null;
  moduleId: string;
  versionId: string;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
};

type FormValues = {
  description: string | null;
};

const ModuleVersionAddDescriptionDrawer = ({
  description,
  moduleId,
  versionId,
  isDrawerVisible,
  setDrawerVisibility,
}: ModuleVersionAddDescriptionDrawerProps) => {
  const handleClose = () => setDrawerVisibility(false);

  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleCancel = () => {
    trackSegmentAnalyticsEvent("Module Registry - Edit Description Canceled");
    handleClose();
    reset();
  };

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updateVersionDescription] = useMutation(UPDATE_VERSION_DESCRIPTION, {
    refetchQueries: ["GetVersionNext", "GetModuleVersionsNext"],
    awaitRefetchQueries: true,
  });

  const builderForm = useForm<FormValues>({
    defaultValues: {
      description,
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitSuccessful, isSubmitting },
  } = builderForm;

  const onSubmit = (values: FormValues) => {
    return updateVersionDescription({
      variables: {
        id: versionId,
        module: moduleId,
        description: values.description,
      },
    })
      .then(() => {
        reportSuccess({ message: "Description successfully saved" });
        trackSegmentAnalyticsEvent("Module Registry - Version Description Edited");
        handleClose();
      })
      .catch(onError);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        description,
      });
    }
  }, [isSubmitSuccessful, reset, description]);

  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCancel}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Edit description" />
        <DrawerCloseIcon handleCloseDrawer={handleCancel} />
      </DrawerHeader>
      <FormProvider {...builderForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerBody>
            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter description here..."
                  maxRows={15}
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <DrawerFooter>
              <DrawerFooterActions>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isValid || !isDirty || isSubmitting}
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default ModuleVersionAddDescriptionDrawer;
