import { gql } from "@apollo/client";

export const GET_STATE_UPLOAD_URL = gql`
  mutation StateUploadUrl {
    stateUploadUrl {
      url
      objectId
    }
  }
`;

export const CHECK_OPEN_TOFU_VERSION = gql`
  query CheckOpenTofuVersion($constraints: String!) {
    openTofuEffectiveVersion(constraints: $constraints)
  }
`;

export const CHECK_TERRAFORM_VERSION = gql`
  query CheckTerraformVersion($constraints: String!) {
    terraformEffectiveVersion(constraints: $constraints)
  }
`;
