import { RefObject, useCallback } from "react";
import cx from "classnames";
import { useLocation } from "react-router-dom-v5-compat";

import ButtonIcon from "ds/components/ButtonIcon";
import ListEntitiesItem from "components/ListEntitiesItem";
import { TreeNode } from "utils/tree";
import Box from "ds/components/Box";
import { ChevronNew, Loading } from "components/icons";
import Counter from "ds/components/Counter";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import Badge from "ds/components/Badge";
import Icon from "ds/components/Icon";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import Typography from "ds/components/Typography";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import styles from "./styles.module.css";
import {
  COLUMN_GAP,
  COLUMN_ORDER,
  LEVEL_MARGIN,
  CONFIG_MANAGEMENT_TREE_LIST_ROW_TEST_ID,
} from "./constants";
import { ConfigNode } from "../../types";
import { TASK_SEARCH_KEY } from "../constants";

type ConfigManagementTreeGridListRowProps = {
  node: TreeNode<ConfigNode>;
  isActive: boolean;
  isExpanded: boolean;
  searchQuery?: string;
  onToggle: () => void;
  loading?: boolean;
  hasChildrenToLoad?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
  description?: string;
  analyticsPage?: AnalyticsPage;
};

const ConfigManagementTreeGridListRow = ({
  isActive,
  isExpanded,
  searchQuery,
  onToggle,
  node,
  hasChildrenToLoad,
  loading,
  innerRef,
  description,
  analyticsPage,
}: ConfigManagementTreeGridListRowProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });
  const location = useLocation();
  const newUrlParams = new URLSearchParams(location.search);
  newUrlParams.set(TASK_SEARCH_KEY, node.id);

  const hasChildren = (node.children && node.children.length > 0) || hasChildrenToLoad;
  const rowLevel = node.path.length;
  const expandable = hasChildren || !!node.item.limitMargin ? "0px" : LEVEL_MARGIN;
  const marginLeft = `calc(${LEVEL_MARGIN} * ${rowLevel - 1} + ${expandable})`;

  const linkOnClickCallback = useCallback(
    () => trackSegmentAnalyticsEvent("Drawer opened"),
    [trackSegmentAnalyticsEvent]
  );

  return (
    <ListEntitiesItem
      key={node.id}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isActive}
      data-testid={CONFIG_MANAGEMENT_TREE_LIST_ROW_TEST_ID}
      ref={innerRef}
      role="row"
      aria-expanded={isExpanded}
      aria-level={rowLevel}
    >
      <Box
        gap="medium"
        align="center"
        style={{
          marginLeft,
        }}
        className={cx({
          [styles.ordered]: node.item.order,
          [styles.first]: node.item.order === 1,
          [styles.last]: node.item.last,
          [styles.hasChildrenOpen]: isExpanded && hasChildren,
          [styles.hasParentTask]: node.item.hasMiddleGroup,
          [styles.lastUnderRole]: node.item.lastUnderRole,
        })}
        role="rowheader"
      >
        {node.item.order && (
          <Box margin="0 medium 0 0" className={styles.order}>
            <Counter count={node.item.order} />
          </Box>
        )}
        {hasChildren && (
          <Box shrink="0" padding={loading ? "small" : "0"}>
            {loading ? (
              <Icon src={Loading} />
            ) : (
              <ButtonIcon
                icon={ChevronNew}
                iconRotate={isExpanded ? "90" : undefined}
                onClick={onToggle}
                variant="ghost"
              >
                {isExpanded ? "Collapse" : "Expand"}
              </ButtonIcon>
            )}
          </Box>
        )}
        <Box>
          <Box direction="column" gap="small" fullWidth>
            {!node.item.link ? (
              <ListEntitiesItemTitle title={node.name} />
            ) : (
              <ListEntitiesItemLink
                title={node.name}
                to={`${node.item.link?.path}?${newUrlParams}`}
                searchQuery={searchQuery}
                replaceLocation
                onClick={linkOnClickCallback}
              />
            )}
            {description && (
              <Typography tag="span" variant="p-body3" color="secondary">
                {description}
              </Typography>
            )}
          </Box>
        </Box>

        {node.item.count && <Counter count={node.item.count} />}
      </Box>
      <Box direction="row" gap="medium" align="center" shrink="0" role="gridcell">
        {node.item.status && (
          <Badge variant="ansibleStatus" state={node.item.status}>
            {node.item.status}
          </Badge>
        )}

        {node.item.checkMode && <Badge state="neutral">CHECK MODE</Badge>}
      </Box>
    </ListEntitiesItem>
  );
};

export default ConfigManagementTreeGridListRow;
