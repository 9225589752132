import { useNavigate } from "react-router-dom-v5-compat";

import { ChevronNew } from "components/icons";
import Box from "ds/components/Box";
import IconTile from "ds/components/IconTile";
import TileContent from "ds/components/Tile/Content";
import TileIndicator from "ds/components/Tile/Indicator";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import { IconComponent } from "types/Icon";
import Icon from "ds/components/Icon";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";

export type LaunchPadExploreNextItemProps = {
  title: string;
  icon: IconComponent;
  to: string;
  iconTileClassName: string;
  analyticsTitle: string;
};

const LaunchPadExploreNextItem = ({
  title,
  icon,
  to,
  iconTileClassName,
  analyticsTitle,
}: LaunchPadExploreNextItemProps) => {
  const navigate = useNavigate();
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,
  });

  const handleClick = () => {
    trackSegmentAnalyticsEvent(analyticsTitle);
    navigate(to);
  };

  return (
    <TileWrapper onClick={handleClick}>
      <Box justify="between" grow="1">
        <TileContent direction="column">
          <IconTile icon={icon} className={iconTileClassName} />
          <TileTitle variant="p-t6">{title}</TileTitle>
        </TileContent>
        <TileIndicator indicator={<Icon src={ChevronNew} />} />
      </Box>
    </TileWrapper>
  );
};

export default LaunchPadExploreNextItem;
