import WebhookDetailsResponse from "components/WebhookDeliveries/Details/Response";
import { WebhookDeliveryDetails } from "types/generated";

import styles from "./styles.module.css";

type WebhookDetailsProps = {
  headers?: WebhookDeliveryDetails["responseHeaders"] | WebhookDeliveryDetails["requestHeaders"];
  body?: WebhookDeliveryDetails["responseBody"] | WebhookDeliveryDetails["requestBody"];
  error?: WebhookDeliveryDetails["responseError"];
};

const WebhookDeliveryRequest = ({ headers, body, error }: WebhookDetailsProps) => {
  return (
    <div className={styles.wrapper}>
      <WebhookDetailsResponse headers={headers} body={body} error={error} />
    </div>
  );
};

export default WebhookDeliveryRequest;
