import { MenuContexts } from "components/icons";
import Box from "ds/components/Box";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import { Maybe } from "types/generated";

import EnvironmentPreviewVariableBadge from "./Badge";

type EnvironmentPreviewVariableProps = {
  name: string;
  value: Maybe<string>;
  contextName?: string;
};

const EnvironmentPreviewVariable = ({
  name,
  value,
  contextName,
}: EnvironmentPreviewVariableProps) => {
  const gridTemplate = contextName ? "2fr 2fr 1fr" : "4fr 1fr";
  return (
    <TileWrapper>
      <Box align="center" gap="large" grid gridTemplate={gridTemplate} fullWidth>
        <TileTitle variant="p-t6">{name}</TileTitle>

        {contextName && (
          <Box justify="end" fullWidth>
            <EnvironmentPreviewVariableBadge text={contextName} icon={MenuContexts} />
          </Box>
        )}

        <Box justify="end" fullWidth>
          <EnvironmentPreviewVariableBadge text={value || "•••••"} />
        </Box>
      </Box>
    </TileWrapper>
  );
};

export default EnvironmentPreviewVariable;
