import { createContext } from "react";

import { Stack } from "types/generated";

export type StackSettingsContextDataProps = {
  stackSettings: Stack;
  canManageStack: boolean;
};

export const StackSettingsContextData = createContext<StackSettingsContextDataProps | undefined>(
  undefined
);
