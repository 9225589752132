import { gql, TypedDocumentNode } from "@apollo/client";

import { Module, MutationModuleEnableArgs } from "types/generated";

type FetchModuleEnableData = { moduleEnable: Module };
export const ENABLE_MODULE: TypedDocumentNode<FetchModuleEnableData, MutationModuleEnableArgs> =
  gql`
    mutation EnableModule($id: ID!) {
      moduleEnable(id: $id) {
        id
        isDisabled
      }
    }
  `;
