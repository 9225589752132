import { memo } from "react";

import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import useDiscardRun from "./useDiscardRun";

type WorkerPoolQueuedRunsListItemDropdownProps = {
  runId: string;
  stackId: string;
  isPrioritized: boolean;
  setPriority: (isPrioritized: boolean) => () => void;
  isPublic?: boolean;
  canDiscard: boolean;
};

const WorkerPoolQueuedRunsListItemDropdown = ({
  stackId,
  runId,
  isPrioritized,
  setPriority,
  isPublic,
  canDiscard,
}: WorkerPoolQueuedRunsListItemDropdownProps) => {
  const { discardRun, discarding } = useDiscardRun();

  const handleSetPriority = (isPrioritized: boolean) => () => {
    setPriority(isPrioritized)();
  };

  const handleDiscardRun = () => discardRun(stackId, runId);

  const showPrioritize = !isPublic && !isPrioritized;
  const showDePrioritize = !isPublic && isPrioritized;
  const showDiscard = canDiscard;

  if (!showPrioritize && !showDePrioritize && !showDiscard) {
    return null;
  }

  return (
    <DropdownMenuEllipsis tooltip="Run actions">
      {showPrioritize && (
        <DropdownMenuItem onAction={handleSetPriority(true)}>Prioritize</DropdownMenuItem>
      )}
      {showDePrioritize && (
        <DropdownMenuItem onAction={handleSetPriority(false)}>De-prioritize</DropdownMenuItem>
      )}
      {showDiscard && (
        <DropdownMenuItem onAction={handleDiscardRun} loading={discarding}>
          Discard
        </DropdownMenuItem>
      )}
    </DropdownMenuEllipsis>
  );
};

export default memo(WorkerPoolQueuedRunsListItemDropdown);
