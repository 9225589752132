import { NetworkStatus, useQuery } from "@apollo/client";

import { Context } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GET_CONTEXTS } from "./gql";

const EMPTY_CONTEXTS: Context[] = [];

const useSpaceContexts = (space: string) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, networkStatus, refetch, error } = useQuery<{
    attachableContextsInSpace: Context[];
  }>(GET_CONTEXTS, {
    variables: { id: space },
    onError,
  });

  const isLoading = loading && networkStatus !== NetworkStatus.refetch;
  const isRefetching = loading && networkStatus === NetworkStatus.refetch;

  return {
    spaceContexts: data?.attachableContextsInSpace || EMPTY_CONTEXTS,
    loading: isLoading,
    refetching: isRefetching,
    hasData: !isLoading && !!data?.attachableContextsInSpace,
    refetch,
    error,
  };
};

export default useSpaceContexts;
