import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Policy } from "types/generated";

import LoginPoliciesListItem from "./Item";

type LoginPoliciesListVirtualizedItemProps = {
  loginPolicies: Policy[];
  setPolicyToEditInDrawer: (policy: Policy) => void;
};

const LoginPoliciesListVirtualizedItem = (
  props: ListChildComponentProps<LoginPoliciesListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.loginPolicies[index];

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);
  return (
    <div style={style}>
      <LoginPoliciesListItem
        key={item.id}
        item={item}
        setRowHeight={handleSetRowHeight}
        editDetailsHandler={() => data.setPolicyToEditInDrawer(item)}
      />
    </div>
  );
};

export default memo(LoginPoliciesListVirtualizedItem, areEqual);
