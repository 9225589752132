import { stackValidator } from "utils/formValidators";

import { StackCreationVersion, StackCreationVersionType, StackVendorFormFields } from "../../types";

export const getValidateToolVersion =
  (toolVersions: string[] = [], toolName: string) =>
  (version: StackCreationVersion, formValues: StackVendorFormFields) => {
    if (!version.value.trim()) {
      return "Version is required";
    }

    const sanitizationError = stackValidator.validateUseSmartSanitizationField(
      formValues.terragrunt.useSmartSanitization,
      version.value,
      toolVersions
    );

    let rangeError;
    if (formValues.terragrunt.workflowVersion.type === StackCreationVersionType.Range) {
      rangeError = stackValidator.validateVersionRange(version.value, toolVersions, toolName);
    }

    return sanitizationError || rangeError || true;
  };
