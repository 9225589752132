import { useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import Icon from "ds/components/Icon";
import { CrossNew, Tick } from "components/icons";
import Timestamp from "components/time/Timestamp";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";

import { COLUMN_ORDER } from "./constants";
import WebhookDropdown from "../WebhookDropdown";
import { WebhookIntegrationWithTimestamp } from "./types";

type WebhookListItemProps = {
  item: WebhookIntegrationWithTimestamp;
  setRowHeight?: (size: number) => void;
  moduleId: string;
  onEditClick: (item: WebhookIntegrationWithTimestamp) => void;
  onDetailsClick: (item: WebhookIntegrationWithTimestamp) => void;
  canManageModule: boolean;
};

const refetchQueriesOnDelete = ["GetModuleWebhooks"];

const WebhookListItem = ({
  item,
  setRowHeight,
  moduleId,
  onEditClick,
  onDetailsClick,
  canManageModule,
}: WebhookListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap="0 large"
      ref={rowRef}
    >
      <Box align="center">
        <ListEntitiesItemLink to={`../webhook/${item.id}`} title={item.endpoint} v5Compat />
      </Box>

      <Box align="center" gap="small">
        {item.enabled ? (
          <>
            <Icon src={Tick} color="success" />
            <Typography tag="span" variant="p-body2" color="success">
              Enabled
            </Typography>
          </>
        ) : (
          <>
            <Icon src={CrossNew} color="danger" />
            <Typography tag="span" variant="p-body2" color="danger">
              Disabled
            </Typography>
          </>
        )}
      </Box>

      <Box align="center">
        <TextEllipsis
          tooltip={<Timestamp timestamp={item.createdAt} />}
          tooltipWidthMode="maxWidthSm"
        >
          {(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              <Timestamp timestamp={item.createdAt} />
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box align="end">
        <WebhookDropdown
          id={item.id}
          endpoint={item.endpoint}
          refetchQueriesOnDelete={refetchQueriesOnDelete}
          onEditClick={() => onEditClick(item)}
          onDetailsClick={() => onDetailsClick(item)}
          moduleId={moduleId}
          canManageModule={canManageModule}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default WebhookListItem;
