import { ReactNode, useEffect, useRef } from "react";

import Box from "ds/components/Box";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { useToggle } from "hooks/useToggle";
import { SpacingShorthand } from "types/Spacing";
import { CollapsiblePanelAriaLevel } from "components/CollapsiblePanel/types";

import CollapsibleListHeader from "./Header";
import CollapsibleListEmptyPlaceholder from "./EmptyPlaceholder";

type CollapsibleListProps = {
  children: ReactNode;
  count?: number;
  ariaLevel: CollapsiblePanelAriaLevel;
  title: ReactNode;
  emptyPlaceholder?: ReactNode;
  initialIsCollapsed?: boolean;
  action?: ReactNode;
  alwaysVisibleContent?: ReactNode;
  padding?: SpacingShorthand;
} & AnalyticsCommonProps;

const CollapsibleList = ({
  ariaLevel,
  title,
  count,
  children,
  emptyPlaceholder,
  initialIsCollapsed = true,
  action,
  analyticsPage,
  analyticsProps,
  analyticsTitle,
  alwaysVisibleContent,
  padding = "0 large large large",
}: CollapsibleListProps) => {
  const trackSegmentEvent = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: analyticsProps,
  });
  const [isCollapsed, toggle] = useToggle(initialIsCollapsed);
  const previousCount = useRef(count);

  const handleToggle = () => {
    toggle();

    if (analyticsTitle && trackSegmentEvent) {
      trackSegmentEvent(analyticsTitle, { collapsed: !isCollapsed });
    }
  };

  const hasEmptyPlaceholder = emptyPlaceholder !== undefined;

  const emptyPlaceholderRendered =
    typeof emptyPlaceholder === "string" ? (
      <CollapsibleListEmptyPlaceholder>{emptyPlaceholder}</CollapsibleListEmptyPlaceholder>
    ) : (
      emptyPlaceholder
    );

  useEffect(() => {
    if (previousCount.current !== count) {
      toggle(false);
    }

    previousCount.current = count;
  }, [count, toggle]);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={handleToggle} withTransition>
      <CollapsibleListHeader title={title} count={count} action={action} ariaLevel={ariaLevel} />
      {alwaysVisibleContent && (
        <CollapsiblePanelContent padding={padding} alwaysVisible>
          {alwaysVisibleContent}
        </CollapsiblePanelContent>
      )}
      <CollapsiblePanelContent padding={padding}>
        <Box direction="column" gap="medium">
          {count === 0 && hasEmptyPlaceholder ? emptyPlaceholderRendered : children}
        </Box>
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default CollapsibleList;
