import { Stack, StackConfigVendorInput, StackConfigVendorPulumi } from "types/generated";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";
import { VENDOR_KEY } from "constants/vendor";

import { type StackSettingsPulumiVendorFormFields } from "./Edit";

export const getFormDefaultValues = (stackSettings: Stack): StackSettingsPulumiVendorFormFields => {
  const config = stackSettings.vendorConfig as StackConfigVendorPulumi;

  return {
    stackName: config.stackName,
    loginURL: config.loginURL,
  };
};

export const mapCreateStackVendorUpdateInput = (
  formData: StackSettingsPulumiVendorFormFields
): StackConfigVendorInput => ({
  ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
  [VENDOR_KEY.PULUMI]: formData,
});
