import { ReactNode, useEffect, useState } from "react";

import { Loading } from "components/icons";
import { IconComponent } from "types/Icon";

import Icon, { IconProps } from "../Icon";

export type LoadingIndicatorProps = {
  loading?: boolean;
  className?: string;
  minimumDuration?: number;
  size?: IconProps["size"];
  color?: IconProps["color"];
  children?: ReactNode;
  icon?: IconComponent;
};

const LoadingIndicator = ({
  loading = false,
  className,
  size = "default",
  minimumDuration,
  children,
  icon: IconSrc = Loading,
  color,
}: LoadingIndicatorProps) => {
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    if (loading && !isLoading) {
      setIsLoading(true);
    }
  }, [loading, isLoading]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;

    if (minimumDuration && !loading && isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, minimumDuration);
    }

    if (!minimumDuration && !loading && isLoading) {
      setIsLoading(false);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [isLoading, loading, minimumDuration]);

  return (
    (isLoading && <Icon src={IconSrc} className={className} size={size} color={color} />) || (
      <>{children}</>
    )
  );
};

export default LoadingIndicator;
