import React from "react";
import { createPortal } from "react-dom";

import { PORTAL_ELEMENT_ID } from "./constants";

type PortalProps = {
  children: React.ReactNode;
  customMountElement?: Element | null;
};

const Portal = ({ children, customMountElement }: PortalProps) => {
  const mount = document.getElementById(PORTAL_ELEMENT_ID);
  const destination = customMountElement || mount;

  return destination && createPortal(children, destination);
};

export default Portal;
