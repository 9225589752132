import useTypedContext from "hooks/useTypedContext";

import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsVendorAnsibleEdit from "./Edit";
import StackSettingsVendorAnsibleView from "./View";
import { StackSettingsContextData } from "../../Context";

const StackSettingsVendorAnsible = (props: StackSettingsVendorComponentBaseProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return canManageStack ? (
    <StackSettingsVendorAnsibleEdit {...props} />
  ) : (
    <StackSettingsVendorAnsibleView />
  );
};

export default StackSettingsVendorAnsible;
