import { RunState } from "types/generated";

import RunPhaseLogs from "../../components/RunPhaseLogs";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";

type PreparingEntryProps = {
  timestamp: number;
  until: number | null;
  state: RunState;
  stateVersion: number | null;
  hasLogs: boolean;
  openLogsByDefault: boolean;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const PreparingEntry = ({
  timestamp,
  until,
  state,
  stateVersion,
  hasLogs,
  openLogsByDefault,
  runId,
  stackId,
  isModuleRun,
}: PreparingEntryProps) => {
  const [showLogs, toggleLogs] = useVisibilityToggle(openLogsByDefault, hasLogs);

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.Preparing}
      timestamp={timestamp}
      until={until}
      isLoading={!until}
      onClick={toggleLogs}
      isOpen={showLogs}
    >
      {showLogs && (
        <RunPhaseLogs
          state={state}
          stateVersion={stateVersion}
          until={until}
          runId={runId}
          stackId={stackId}
          isModuleRun={isModuleRun}
        />
      )}
    </HistoryEntryWrapper>
  );
};

export default PreparingEntry;
