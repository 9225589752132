import React from "react";

import styles from "./styles.module.css";

type SettingsItemsListProps = {
  children: React.ReactNode;
};

const SettingsItemsList = ({ children }: SettingsItemsListProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default SettingsItemsList;
