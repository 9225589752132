import { gql } from "@apollo/client";

export const NEW_STACK_RESOURCES = gql`
  query NewStackResources {
    terraformVersions
    terragruntVersions
    kubectlVersions
    openTofuVersions
  }
`;
