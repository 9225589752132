import cx from "classnames";
import { datadogRum } from "@datadog/browser-rum";
import { writeStorage } from "@rehooks/local-storage";

import ButtonIcon from "ds/components/ButtonIcon";
import { FiltersContext } from "components/Filters";
import { AccountContext } from "views/AccountWrapper";
import { Profile, Trash, Visible, Invisible, Reload } from "components/icons";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Typography from "ds/components/Typography";
import PopoverActionBar from "ds/components/PopoverActionBar";
import useTypedContext from "hooks/useTypedContext";
import { getDefaultViewStorageKey } from "components/Filters/helpers";
import { useDefaultView } from "components/Filters/useDefaultView";
import { DEFAULT_VIEW_ID, DEFAULT_VIEW_NAME } from "components/Filters/constants";
import TextEllipsis from "ds/components/TextEllipsis";
import Box from "ds/components/Box";

import { FiltersSavedViewsListItemProps } from "./types";
import styles from "./styles.module.css";
import useChangeViewPublicVisibility from "./useChangeViewPublicVisibility";
import { showDeleteConfirmation } from "../DeleteConfirmation";

const FiltersSavedViewsListItem = ({ applyView, item }: FiltersSavedViewsListItemProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { currentView, defaultEmptyFilterView, filterType, filtersOrder } =
    useTypedContext(FiltersContext);
  const isViewCreator = item.createdBy === viewer.id;

  const defaultView = useDefaultView(filtersOrder, filterType, defaultEmptyFilterView);

  const changeViewPublicVisibility = useChangeViewPublicVisibility(item);

  const handleApply = () => {
    datadogRum.addAction("[Saved Views] Click View Item");
    applyView(item.data, item.name, item.id, item.isPublic, isViewCreator);
  };

  const resetView = () => {
    if (item.id === currentView?.id) {
      applyView(
        defaultView.rawData,
        defaultView.data?.name || DEFAULT_VIEW_NAME,
        defaultView.data?.id || DEFAULT_VIEW_ID,
        false,
        true
      );
    }
  };

  const clickDeleteHandler = () => {
    datadogRum.addAction("[Saved Views] Click Delete");
    showDeleteConfirmation({
      id: item.id,
      name: item.name,
    }).then(() => {
      resetView();
    });
  };

  const handleSetDefaultView = () => {
    datadogRum.addAction("[Saved Views] Click Use as your default view");

    writeStorage(getDefaultViewStorageKey(filterType), item.id);

    if (currentView?.id === DEFAULT_VIEW_ID) {
      applyView(item.data, item.name, item.id, item.isPublic, isViewCreator);
    }
  };

  return (
    <li className={styles.listItem}>
      <PopoverActionBar className={styles.actionBar}>
        <ButtonIcon icon={Reload} onClick={handleSetDefaultView} variant="ghost">
          Use as your default view
        </ButtonIcon>

        {isViewCreator && viewer.admin && (
          <ButtonIcon
            icon={item.isPublic ? Invisible : Visible}
            onClick={changeViewPublicVisibility}
            variant="ghost"
          >
            {item.isPublic ? "Make it private" : "Share within the account"}
          </ButtonIcon>
        )}
        {(isViewCreator || viewer.admin) && (
          <ButtonIcon icon={Trash} onClick={clickDeleteHandler} variant="ghostDanger">
            Delete filter
          </ButtonIcon>
        )}
      </PopoverActionBar>
      <Box
        direction="column"
        onClick={handleApply}
        className={cx(styles.listButton, {
          [styles.active]: currentView && item.id === currentView.id,
        })}
        role="button"
        tabIndex={0}
      >
        <TextEllipsis tooltip={item.name || "Filters"} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              {item.name || "Filters"}
            </Typography>
          )}
        </TextEllipsis>

        <MetaInfoList className={styles.metaList}>
          <MetaInfoListItem className={styles.metaListItem}>
            {item.isPublic ? "Shared" : "Private"}
          </MetaInfoListItem>
          {item.isPublic && (
            <MetaInfoListItem icon={Profile} className={styles.metaListItem}>
              {item.createdBy}
            </MetaInfoListItem>
          )}
        </MetaInfoList>
      </Box>
    </li>
  );
};

export default FiltersSavedViewsListItem;
