import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { GitlabIntegration, Maybe } from "types/generated";

import { CHECK_GITLAB_HAS_DEFAULT_INTEGRATION } from "./gql";

const useCheckGitLabHasDefaultIntegration = (skip: boolean) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{
    checkGitLabHasDefaultIntegration: Maybe<Pick<GitlabIntegration, "id">>;
  }>(CHECK_GITLAB_HAS_DEFAULT_INTEGRATION, {
    onError,
    fetchPolicy: "no-cache",
    skip,
  });

  return {
    hasDefaultIntegration: typeof data?.checkGitLabHasDefaultIntegration?.id !== "undefined",
    loading,
  };
};

export default useCheckGitLabHasDefaultIntegration;
