import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import Typography from "ds/components/Typography";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";

const ApiKeysSortHeader = () => {
  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      {/* TODO: next iteration */}
      {/* <FiltersSortHeaderStaticColumn>
        <BulkActionsSelectAllTickbox />
      </FiltersSortHeaderStaticColumn> */}
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Name
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Type
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Key ID
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Space
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Groups
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Last used
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default ApiKeysSortHeader;
