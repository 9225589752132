import { ErrorColored } from "components/icons";
import SystemMessage from "components/SystemMessage";
import useTitle from "hooks/useTitle";
import LinkNew from "ds/components/LinkNew";

const UnauthorizedPage = () => {
  useTitle("Unauthorized · Spacelift");

  return (
    <SystemMessage
      icon={ErrorColored}
      title="Unauthorized"
      message={
        <>
          Sorry, looks like you're not authorized to access this Spacelift account. This could well
          be an error - try <LinkNew href="/login">logging in again</LinkNew> to see if that helps.
        </>
      }
    />
  );
};

export default UnauthorizedPage;
