import { useFormContext, useWatch } from "react-hook-form";
import { useCallback } from "react";

import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import Box from "ds/components/Box";
import { GitHubAppManifest } from "types/generated";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";
import { Output } from "components/icons";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import VCSIntegrationsBackButton from "../../components/BackButton";
import { ManageGitHubEnterpriseWizardFormFields } from "./types";

type VCSIntegrationsManageGitHubEnterpriseWizardStepCreateAppProps = {
  manifest?: GitHubAppManifest;
  goToPreviousStep: () => void;
};

const VCSIntegrationsManageGitHubEnterpriseWizardStepCreateApp = ({
  manifest,
  goToPreviousStep,
}: VCSIntegrationsManageGitHubEnterpriseWizardStepCreateAppProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const { control } = useFormContext<ManageGitHubEnterpriseWizardFormFields>();

  const [ownerChoice, integrationChoice] = useWatch({
    name: ["ownerType", "integration"],
    control,
  });

  const trackWizardRedirectContinueClick = useCallback(() => {
    trackSegmentAnalyticsEvent("GitHub Wizard - Redirect Continue Click", {
      ownership: ownerChoice,
      type: integrationChoice,
    });
  }, [integrationChoice, ownerChoice, trackSegmentAnalyticsEvent]);

  return (
    <FullScreenModalBody gap="x-large">
      {!manifest && (
        <>
          <Typography variant="p-t4" tag="h3" align="center">
            Something went wrong.
          </Typography>

          <Typography variant="p-body2" tag="p" align="center">
            We couldn't generate the GitHub app manifest. <br />
            Please contact us at support or try to start the wizard from the beginning.
          </Typography>
        </>
      )}

      {manifest && (
        <form action={manifest.endpoint} method="post" onSubmit={trackWizardRedirectContinueClick}>
          <Box direction="column" fullWidth gap="x-large">
            <input value={manifest.manifest} type="hidden" name="manifest" id="manifest" />

            <Typography variant="p-t4" tag="h3" align="center">
              We'll now redirect you to GitHub to create an application
            </Typography>

            <Box justify="center" gap="medium">
              <VCSIntegrationsBackButton onClick={goToPreviousStep} />

              <Button type="submit" variant="primary" endIcon={Output}>
                Continue
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </FullScreenModalBody>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseWizardStepCreateApp;
