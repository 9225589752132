import { gql } from "@apollo/client";

import { PRIVATE_WORKER_POOL_FIELDS } from "../gql";

export const SEARCH_PRIVATE_WORKER_POOL_SCHEDULABLE_RUNS = gql`
  ${PRIVATE_WORKER_POOL_FIELDS}
  query SearchPrivateWorkerPoolSchedulableRuns($workerPoolId: ID!, $input: SearchInput!) {
    workerPool(id: $workerPoolId) {
      ...PrivateWorkerPoolFields
      searchSchedulableRuns(input: $input) {
        edges {
          cursor
          node {
            isModule
            position
            stackId
            stackName
            provider
            run {
              id
              type
              title
              finished
              triggeredBy
              createdAt
              updatedAt
              driftDetection
              isPrioritized
              state
              commit {
                authorName
                hash
                url
              }
            }
            version
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const SEARCH_PRIVATE_WORKER_POOL_SCHEDULABLE_RUNS_SUGGESTIONS = gql`
  ${PRIVATE_WORKER_POOL_FIELDS}
  query SearchPrivateWorkerPoolSchedulableRunsSuggestions(
    $workerPoolId: ID!
    $input: SearchSuggestionsInput!
  ) {
    workerPool(id: $workerPoolId) {
      ...PrivateWorkerPoolFields
      searchSchedulableRunsSuggestions(input: $input) {
        filteredCount
        fields {
          name
          orderable
          filterable
          type
          possibleValuesBoolean {
            values
            counts
          }
          possibleValuesEnum {
            values
            counts
          }
          possibleValuesString {
            values
            counts
          }
          possibleValuesHierarchy {
            values {
              id
              parentId
              displayValue
            }
            counts
          }
        }
      }
    }
  }
`;
