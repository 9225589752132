import { useModal, create, show } from "@ebay/nice-modal-react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

type DeleteConfirmationProps = {
  name: string;
  removeTabCallback: () => void;
};

const DashboardDeleteConfirmation = create(function DeleteConfirmation({
  name,
  removeTabCallback,
}: DeleteConfirmationProps) {
  const { reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const handleDeleteView = () => {
    removeTabCallback();
    modal.resolve();
    modal.hide();
    reportSuccess({
      message: `Dashboard view "${name}" was successfully deleted`,
    });
  };

  return (
    <DeleteConfirmationModal
      title="Delete dashboard view"
      name={name}
      onConfirm={handleDeleteView}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DashboardDeleteConfirmation, props);
