import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import { VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";

import { ModuleSettingsContext } from "../Context";

const ModuleSettingsSourceCodeViewForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);

  return (
    <>
      <FormFieldViewText label="Provider" value={HUMANIZE_PROVIDER[module.provider]} />
      {module.vcsIntegration && (
        <>
          <FormFieldViewText label="Integration" value={module.vcsIntegration.name} />
        </>
      )}
      {module.provider === VcsProvider.Git && !!module.repositoryURL && (
        <FormFieldViewText label="URL" value={module.repositoryURL} />
      )}
      <FormFieldViewText label="Namespace" value={module.namespace} />
      <FormFieldViewText label="Repository" value={module.repository} />
      <FormFieldViewText label="Branch" value={module.branch} />
      <FormFieldViewText
        label="Project root"
        value={module.projectRoot}
        emptyPlaceholder="No project root"
      />
    </>
  );
};

export default ModuleSettingsSourceCodeViewForm;
