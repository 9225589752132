import cx from "classnames";
import { create, show, useModal } from "@ebay/nice-modal-react";

import BaseAction from "ds/components/BaseAction";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { StackDependencyDetail } from "types/generated";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

import styles from "./styles.module.css";

type DependenciesDeleteConfirmationModalProps = {
  onConfirm: () => void;
  itemStack: StackDependencyDetail;
  relatedStack: StackDependencyDetail;
};

const DependenciesDeleteConfirmationModal = create(function DependenciesDeleteConfirmationModal({
  onConfirm,
  itemStack,
  relatedStack,
}: DependenciesDeleteConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <DeleteConfirmationModal
      title="Delete dependency"
      confirmationButtonLabel="Delete dependency"
      onConfirm={handleOnConfirm}
    >
      <ConfirmationModalMessage>
        Are you sure you want to delete the dependency between{" "}
        <TextEllipsis tooltip={itemStack.name} tooltipWidthMode="maxWidthSm">
          {(tooltipReferenceProps) => (
            <Typography
              {...tooltipReferenceProps}
              tag="h6"
              variant="p-t6"
              className={cx(styles.stackName, tooltipReferenceProps.className)}
            >
              <BaseAction to={`/stack/${itemStack.id}/dependencies`} onClick={modal.hide}>
                {itemStack.name}
              </BaseAction>
            </Typography>
          )}
        </TextEllipsis>
        <TextEllipsis tooltip={relatedStack.name} tooltipWidthMode="maxWidthSm">
          {(tooltipReferenceProps) => (
            <Typography
              {...tooltipReferenceProps}
              tag="h6"
              variant="p-t6"
              className={cx(styles.stackName, tooltipReferenceProps.className)}
            >
              <BaseAction to={`/stack/${relatedStack.id}/dependencies`} onClick={modal.hide}>
                {relatedStack.name}
              </BaseAction>
            </Typography>
          )}
        </TextEllipsis>
        Removing the dependency will have irreversible consequences.
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDependenciesDeleteConfirmationModal = (
  props: DependenciesDeleteConfirmationModalProps
) => show(DependenciesDeleteConfirmationModal, props);
