import { useCallback } from "react";

import useDiscardRunShared from "shared/Run/useDiscardRun";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { useModifyRunErrorHandler } from "./useModifyRunError";

const useDiscardRun = () => {
  const onError = useModifyRunErrorHandler();
  const { reportSuccess } = useTypedContext(FlashContext);

  const [discardRun, { loading }] = useDiscardRunShared({
    //TODO: provide refetchQueries as a property
    refetchQueries: ["SearchPrivateWorkerPoolQueuedRuns"],
    onError: onError,
  });

  const handleRunDiscard = useCallback(
    (stackId: string, runId: string) => {
      discardRun({ stack: stackId, run: runId, note: null }, (data) => {
        if (data?.runDiscard?.id) {
          reportSuccess({ message: `Run ${runId} was successfully discarded` });
        }
      });
    },
    [discardRun, reportSuccess]
  );

  return {
    discardRun: handleRunDiscard,
    discarding: loading,
  };
};

export default useDiscardRun;
