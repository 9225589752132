import { Controller } from "react-hook-form";
import { useCallback } from "react";

import FormFieldRowWrapper from "ds/components/Form/Field/RowWrapper";
import Link from "ds/components/Link";
import Select from "ds/components/Select";
import { spaceAccessLevelOptions } from "constants/space";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import FormField from "ds/components/Form/Field";
import useAnalytics from "hooks/useAnalytics";
import Box from "ds/components/Box";
import SpaceSelect from "components/SpaceSelect";
import FormArrayField from "ds/components/Form/ArrayField";
import { getDocsUrl } from "utils/getDocsUrl";

import { FormFieldSpacesProps, SpaceField, TFieldValues } from "./types";
import { validateSpaceFields } from "./helpers";
import { useSpacesOptions } from "./useSpacesOptions";
import FormFieldsSpacesRoleOption from "./RoleOption";
import styles from "./styles.module.css";

const EMPTY_VALUE = { space: undefined, spaceAccessLevel: undefined };

const FormFieldSpaces = ({ allowEmpty, isDisabled, analyticsPage }: FormFieldSpacesProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const onRemove = () => {
    trackSegmentAnalyticsEvent?.("Space Access Delete");
  };

  const onAdd = () => {
    trackSegmentAnalyticsEvent?.("Add Another Access Click");
  };

  const validate = useCallback(
    (fields: SpaceField[]) => validateSpaceFields(fields, allowEmpty),
    [allowEmpty]
  );

  const { disabledSpaces, areSpacesOptionsAvailable, canAddMoreRows } = useSpacesOptions();

  return (
    <Box margin="large 0 0">
      <FormArrayField<TFieldValues, "spaces">
        name="spaces"
        addButtonLabel="Add another access"
        emptyValue={EMPTY_VALUE}
        onRemove={onRemove}
        onAdd={onAdd}
        validate={validate}
        disabled={isDisabled}
        isAddDisabled={!canAddMoreRows}
      >
        {({ value, index, control, suffix }) => (
          <Box align="start" gap="medium" fullWidth>
            <Controller
              name={`spaces.${index}.space`}
              control={control}
              render={({ field, fieldState }) => (
                <FormField
                  error={fieldState.error?.message}
                  label={index === 0 ? "Space" : undefined}
                  tooltipInfoVariant="modal"
                  tooltipMode="parent"
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>Space access</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        Assign access to dedicated spaces, you can add more than one access type and
                        create access rules.
                        <Link href={getDocsUrl("/concepts/spaces")} target="_blank">
                          Learn more
                        </Link>
                      </TooltipModalBody>
                    </>
                  }
                  fullWidth
                  noMargin
                  className={styles.formField}
                >
                  {({ ariaInputProps }) => (
                    <SpaceSelect
                      value={value.space}
                      disabledIds={disabledSpaces}
                      onChange={field.onChange}
                      error={!!fieldState.error?.message}
                      disabled={isDisabled || !areSpacesOptionsAvailable}
                      ariaProps={ariaInputProps}
                    />
                  )}
                </FormField>
              )}
            />
            <Controller
              name={`spaces.${index}.spaceAccessLevel`}
              control={control}
              render={({ field, fieldState }) => (
                <FormField
                  error={fieldState.error?.message}
                  label={index === 0 ? "Role" : undefined}
                  tooltipInfoVariant="modal"
                  tooltipMode="parent"
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>Choose access type</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        <Typography tag="p" variant="p-body3">
                          <Typography tag="span" variant="p-t7">
                            Read
                          </Typography>{" "}
                          - cannot create or modify neither stacks nor any attachable entities, but
                          can view them
                          <br />
                          <Typography tag="span" variant="p-t7">
                            Write
                          </Typography>{" "}
                          - an extension to Read, as it can actually trigger runs in the stacks it
                          sees
                          <br />
                          <Typography tag="span" variant="p-t7">
                            Admin
                          </Typography>{" "}
                          - can create and modify stacks and attachable entities, as well as trigger
                          runs
                        </Typography>

                        <Link
                          href={getDocsUrl("/concepts/spaces/access-control#roles")}
                          target="_blank"
                        >
                          Learn more
                        </Link>
                      </TooltipModalBody>
                    </>
                  }
                  fullWidth
                  noMargin
                  className={styles.formField}
                >
                  {({ ariaInputProps }) => (
                    <FormFieldRowWrapper>
                      <Select
                        value={value.spaceAccessLevel}
                        options={spaceAccessLevelOptions}
                        onChange={field.onChange}
                        error={!!fieldState.error?.message}
                        dropdownPosition="bottomRight"
                        disabled={isDisabled}
                        renderOption={FormFieldsSpacesRoleOption}
                        ariaInputProps={ariaInputProps}
                      />
                      {suffix}
                    </FormFieldRowWrapper>
                  )}
                </FormField>
              )}
            />
          </Box>
        )}
      </FormArrayField>
    </Box>
  );
};

export default FormFieldSpaces;
