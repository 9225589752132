import { RefObject, useLayoutEffect, useRef } from "react";

import Portal from "components/Portal";

type BulkActionsFloatingBarSpacerProps = {
  isVisible: boolean;
  floatingBlockRef: RefObject<HTMLDivElement>;
  virtualizedListContainerRef?: RefObject<HTMLElement>;
};

const BOTTOM_PADDING_SIZE = 24; // bottom padding in the floating bar wrapper, made via bottom position to prevent scrollbars from appearing

/**
 * This component renders a fake "tombstone" component into the virtualized list container, to not
 * cover the content with the floating bar.
 *
 * Position sticky did not work, with a low amount of items in the virtualized list.
 *
 * If you don't pass the virtualizedListContainerRef, the spacer will not be rendered.
 */
const BulkActionsFloatingBarSpacer = ({
  isVisible,
  floatingBlockRef,
  virtualizedListContainerRef,
}: BulkActionsFloatingBarSpacerProps) => {
  const spacerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!spacerRef.current) {
      return;
    }
    spacerRef.current.style.display = isVisible ? "block" : "none";
  }, [isVisible]);

  useLayoutEffect(() => {
    if (!isVisible || !floatingBlockRef.current || !spacerRef.current) {
      return;
    }

    const observer = new ResizeObserver(([entry]) => {
      const { blockSize } = entry.borderBoxSize[0];
      if (spacerRef.current) {
        const size = blockSize ? blockSize + BOTTOM_PADDING_SIZE : 0;
        spacerRef.current.style.height = `${size}px`;
      }
    });

    observer.observe(floatingBlockRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isVisible, floatingBlockRef]);

  if (!virtualizedListContainerRef?.current) {
    return null;
  }

  return (
    <Portal customMountElement={virtualizedListContainerRef.current}>
      <div ref={spacerRef} />
    </Portal>
  );
};
export default BulkActionsFloatingBarSpacer;
