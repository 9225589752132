import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_AWS_INTEGRATION } from "./gql";

const useDeleteAwsIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deleteIntegration, { loading }] = useMutation(DELETE_AWS_INTEGRATION, {
    refetchQueries: ["GetCloudIntegrations"],
  });

  const onDelete = (id: string, callback?: () => void) => {
    deleteIntegration({ variables: { id } })
      .then(() => {
        reportSuccess({ message: "Integration successfully removed" });
        callback?.();
      })
      .catch(onError);
  };

  return { onDelete, loading };
};

export default useDeleteAwsIntegration;
