import { useMemo } from "react";

import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI } from "components/Filters/helpers";

import { AnsibleGroupBy } from "./types";
import { LayoutMode } from "./TreeGrid/types";
import useSearchAnsibleHosts from "./useSearchAnsibleHosts";
import useSearchAnsibleTasks from "./useSearchAnsibleTasks";
import { DEPRECATED_FILTER_NAMES } from "./constants";

const useSearchAnsibleNodes = (groupBy: AnsibleGroupBy, layoutMode: LayoutMode) => {
  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])].filter(
      (value) => !DEPRECATED_FILTER_NAMES.includes(value.field)
    );
  }, [urlParams]);

  const input = useMemo(
    () => ({
      first: 500,
      after: null,
      requestedPage: null,
      fullTextSearch: searchInput,
      predicates,
      orderBy: null,
    }),
    [predicates, searchInput]
  );

  const hostNodes = useSearchAnsibleHosts(input, layoutMode, groupBy === AnsibleGroupBy.Tasks);
  const taskNodes = useSearchAnsibleTasks(input, layoutMode, groupBy === AnsibleGroupBy.Hosts);

  if (groupBy === AnsibleGroupBy.Tasks) {
    return taskNodes;
  }

  if (groupBy === AnsibleGroupBy.Hosts) {
    return hostNodes;
  }

  return {
    isPageEmpty: true,
    predicates,
    nodes: [],
    hasNoFilteringResults: false,
    isPageLoading: false,
    error: null,
  };
};

export default useSearchAnsibleNodes;
