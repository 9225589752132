import cx from "classnames";
import { useCallback } from "react";

import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";
import { TooltipReferenceProps } from "ds/components/Tooltip/types";
import BaseActionButton from "ds/components/BaseAction/Button";

import styles from "./styles.module.css";

type OrdinalLegendItemProps = {
  text: string;
  tooltip?: string;
  onClick?: () => void;
  color?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const OrdinalLegendItem = ({
  text,
  tooltip,
  onClick,
  color,
  onMouseLeave,
  onMouseEnter,
}: OrdinalLegendItemProps) => {
  const renderTooltip = useCallback(
    (props: TooltipReferenceProps) => (
      <Box {...props} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {onClick ? (
          <BaseActionButton
            className={cx(props.className, styles.legendLabel, styles.clickable)}
            onPress={onClick}
          >
            <Typography variant="p-body3" tag="span" color="secondary">
              {text}
            </Typography>
          </BaseActionButton>
        ) : (
          <Typography
            className={cx(props.className, styles.legendLabel)}
            variant="p-body3"
            tag="span"
            color="secondary"
          >
            {text}
          </Typography>
        )}
      </Box>
    ),
    [onClick, text, onMouseEnter, onMouseLeave]
  );

  return (
    <Box key={text} direction="row" align="center" gap="medium">
      <div
        className={styles.legendSquare}
        style={{
          background: color,
        }}
      />
      {tooltip ? (
        <Tooltip widthMode="maxWidthSm" on={renderTooltip}>
          {tooltip}
        </Tooltip>
      ) : (
        <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm">
          {renderTooltip}
        </TextEllipsis>
      )}
    </Box>
  );
};

export default OrdinalLegendItem;
