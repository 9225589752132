import { Stack, VcsProvider } from "types/generated";

import { hasAtLeastStackWriteAccess } from "../utils";

export const isTrackedCommitOutdated = (stack: Stack): boolean =>
  !!stack.trackedCommit &&
  !!stack.trackedBranchHead &&
  stack.trackedCommit.hash !== stack.trackedBranchHead.hash;

/**
 * Check if the current user can lock the stack
 * @param stack
 * @returns boolean
 */
export const canSyncCommit = (stack?: Stack): boolean => {
  if (!stack) {
    return false;
  }

  const isRawGitProvider = stack.provider === VcsProvider.Git;

  const hasPermissionToSync = hasAtLeastStackWriteAccess(stack);

  return (isTrackedCommitOutdated(stack) || isRawGitProvider) && hasPermissionToSync;
};
