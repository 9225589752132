import { ReactNode } from "react";

import Box from "ds/components/Box";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Typography from "ds/components/Typography";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { DocsUrl } from "utils/getDocsUrl";

type StackSettingsPageLayoutProps = {
  children: ReactNode;
  title: string;
  description?: string;
  readMoreDocsLink?: DocsUrl;
  navigation?: ReactNode;
  actions?: ReactNode;
};

const StackSettingsPageLayout = ({
  children,
  title,
  description,
  readMoreDocsLink,
  navigation,
  actions,
}: StackSettingsPageLayoutProps) => {
  return (
    <Box direction="column" grow="1">
      <ViewHeader noBreadCrumbs noSticky>
        <ViewHeaderWrapper justify="between" fullWidth>
          <Box direction="column">
            <ViewHeaderTitle titleSize="p-t5" tag="h2">
              {title}
            </ViewHeaderTitle>

            {description && (
              <Typography tag="p" variant="p-body3" color="secondary">
                {description}
                {readMoreDocsLink && (
                  <>
                    {" "}
                    <ReadMoreDocsLink docsUrl={readMoreDocsLink} />
                  </>
                )}
              </Typography>
            )}
          </Box>

          <Box align="center">{actions}</Box>
        </ViewHeaderWrapper>
        {navigation && <ViewHeaderNavigation>{navigation}</ViewHeaderNavigation>}
      </ViewHeader>

      {children}
    </Box>
  );
};

export default StackSettingsPageLayout;
