import { FormProvider, useForm } from "react-hook-form";

import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import useAttachCloudIntegration from "shared/CloudIntegration/useAttachCloudIntegration";
import { GCP_INTEGRATION_DEFAULT_SCOPES } from "shared/CloudIntegration/constants";

import GCPScopesField from "./Fields/GCPScopesField";
import FormWrapper from "./FormWrapper";
import Actions from "./Actions";

type StackSettingsIntegrationsCloudAttachFormGCPIntegrationProps = {
  entityId: string;
  onSuccess: () => void;
  onCancel: () => void;
  refetchQueries?: string[];
};

type FormValues = {
  scopes: Array<{ value: string }>;
};

const StackSettingsIntegrationsCloudAttachFormGCPIntegration = ({
  onSuccess,
  onCancel,
  entityId,
  refetchQueries,
}: StackSettingsIntegrationsCloudAttachFormGCPIntegrationProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      scopes: GCP_INTEGRATION_DEFAULT_SCOPES.map((value) => ({ value })),
    },
  });

  const { handleSubmit } = form;

  const { attach } = useAttachCloudIntegration({
    entityId,
    onSuccess,
    refetchQueries,
  });

  const onSubmit = (data: FormValues) =>
    attach({
      type: CLOUD_INTEGRATIONS.GCP,
      scopes: data.scopes.map((scope) => scope.value),
    });

  return (
    <FormProvider {...form}>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <GCPScopesField />
        <Actions onCancel={onCancel} />
      </FormWrapper>
    </FormProvider>
  );
};

export default StackSettingsIntegrationsCloudAttachFormGCPIntegration;
