import WarningBar from "components/warning/WarningBar";

import CLIWrapper from "./CLIWrapper";
import styles from "./styles.module.css";

const CLIAuthFailure = () => {
  return (
    <CLIWrapper>
      <div className={styles.text}>
        <b>Spacelift CLI</b>
      </div>
      <WarningBar className={styles.zeroMargin}>
        The authentication flow did not complete successfully.
      </WarningBar>
    </CLIWrapper>
  );
};

export default CLIAuthFailure;
