import { ReactNode } from "react";

type StepperProps = {
  children: ReactNode;
};

import styles from "./styles.module.css";

const Stepper = ({ children }: StepperProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default Stepper;
