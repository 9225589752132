import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { SortOption } from "components/Filters/types";
import { WorkerPool } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { useCachedFilterFields } from "components/Filters/hooks";

import { SEARCH_PRIVATE_WORKER_POOL_WORKERS_SUGGESTIONS } from "../gql";

type UseSearchPrivateWorkerPoolsSuggestions = {
  workerPoolId: string;
};

const useSearchPrivateWorkerPoolsSuggestions = ({
  workerPoolId,
}: UseSearchPrivateWorkerPoolsSuggestions) => {
  const { onError } = useTypedContext(FlashContext);

  //TODO: refactor to use useQuery hook, there is no need in this case to use useLazyQuery
  const [loadPrivateWorkerPoolWorkersSuggestions, { data }] = useLazyQuery<{
    workerPool: WorkerPool;
  }>(SEARCH_PRIVATE_WORKER_POOL_WORKERS_SUGGESTIONS, { onError });

  const rawSearchWorkersSuggestions = useMemo(() => {
    if (data?.workerPool) {
      const { searchWorkersSuggestions } = data.workerPool;
      return searchWorkersSuggestions;
    }

    return undefined;
  }, [data?.workerPool]);

  const cachedFiltersData = useCachedFilterFields(rawSearchWorkersSuggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return cachedFiltersData
      .filter((field) => field.orderable)
      .map((field) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
  }, [cachedFiltersData]);

  const loadSearchSuggestions = () => {
    try {
      loadPrivateWorkerPoolWorkersSuggestions({
        variables: {
          workerPoolId,
          input: {
            fullTextSearch: null,
            predicates: null,
            fields: null,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sortOptions,
  };
};

export default useSearchPrivateWorkerPoolsSuggestions;
