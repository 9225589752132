import { FormEvent, useState } from "react";
import { Navigate } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import InputText from "components/input/InputText";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";

const ApiKeyToken = () => {
  const [loading, setLoading] = useState(false);
  const { reportError, reportSuccess } = useTypedContext(FlashContext);

  const [id, setId] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [secret, setSecret] = useState("");

  const onEmptyUser = () => {
    setId("");
    setSecret("");
    reportError({ title: "Exchange API key for a token error", message: "Invalid credentials" });
  };

  const onSuccess = () => {
    setRedirect(true);
    reportSuccess({ message: "Logged in using an API key" });
  };

  const handleIdChange = (e: FormEvent<HTMLInputElement>) => {
    setId(e.currentTarget.value);
  };

  const handleSecretChange = (e: FormEvent<HTMLInputElement>) => {
    setSecret(e.currentTarget.value);
  };

  const onSubmit = () => {
    const params = new URLSearchParams();
    params.append("id", id);
    params.append("secret", secret);

    setLoading(true);
    fetch("/api_key_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    })
      .then((response) => {
        if (response.ok) {
          onSuccess();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch(onEmptyUser)
      .finally(() => setLoading(false));
  };

  if (redirect) {
    return <Navigate to="/" replace />;
  }

  return (
    <form className={styles.exchangeApiKey}>
      <h3>Exchange API key for a token (debug mode)</h3>
      <div className={styles.exchangeApiKeyContent}>
        <div>
          <div className={styles.exchangeApiKeyLabel}>API key ID:</div>
          <InputText
            className={styles.exchangeApiKeyInput}
            disabled={loading}
            value={id}
            onChange={handleIdChange}
          />
        </div>
        <div>
          <div className={styles.exchangeApiKeyLabel}>API key secret:</div>
          <InputText
            className={styles.exchangeApiKeyInput}
            type="password"
            disabled={loading}
            value={secret}
            onChange={handleSecretChange}
          />
        </div>
      </div>
      <div>
        <Button onClick={onSubmit} variant="primary" disabled={loading}>
          Exchange
        </Button>
      </div>
    </form>
  );
};

export default ApiKeyToken;
