import { gql } from "@apollo/client";

export const SAVE_VIEW = gql`
  mutation SaveView($input: SavedFilterInput!) {
    savedFilterCreate(input: $input) {
      id
      name
      data
      isPublic
      createdBy
    }
  }
`;

export const SAVED_VIEWS_LIST = gql`
  query SavedViewsList($type: String) {
    savedFilters(type: $type) {
      id
      name
      data
      isPublic
      createdBy
      type
    }
  }
`;

export const UPDATE_VIEW = gql`
  mutation UpdateView($id: ID!, $isPublic: Boolean!, $data: String, $name: String) {
    savedFilterUpdate(id: $id, isPublic: $isPublic, data: $data, name: $name) {
      id
      data
      name
    }
  }
`;

export const REMOVE_VIEW = gql`
  mutation RemoveView($id: ID!) {
    savedFilterDelete(id: $id) {
      id
    }
  }
`;
