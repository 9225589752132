import { useCallback, useEffect, useMemo, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { isEqual } from "lodash-es";
import useLocalStorage from "@rehooks/local-storage";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SearchQueryRequestedPage, Stack } from "types/generated";
import useSelectionStore from "components/Table/useSelectionStore";
import usePrevious from "hooks/usePrevious";

import { SEARCH_STACKS } from "./gql";
import { POLL_INTERVAL, STACK_LIMIT_STORAGE_KEY, STACKS_LIMIT } from "./constants";
import useFiltersVariables from "./useFiltersVariables";

const useStacks = (
  propagateAvailableStacks: React.Dispatch<React.SetStateAction<Record<string, Stack>>>
) => {
  const [rowsPerPage] = useLocalStorage<number>(STACK_LIMIT_STORAGE_KEY);
  const [requestedPage, setRequestedPage] = useState<SearchQueryRequestedPage | null>(null);
  const [after, setAfter] = useState<string | null>(null);

  const { onError } = useTypedContext(FlashContext);

  const { filterVariables, filterVariablesLoaded } = useFiltersVariables();

  const resetPaginationVariables = useMemo(() => {
    return {
      fullTextSearch: filterVariables.searchInput,
      predicates: filterVariables.predicates,
      ...(filterVariables.sortOptionFields && { orderBy: filterVariables.sortOptionFields }),
    };
  }, [filterVariables]);

  const previousResetPaginationVariables = usePrevious(resetPaginationVariables);

  const { resetAllSelected } = useSelectionStore();

  // Reset selection when variables changed
  useEffect(() => {
    if (!isEqual(previousResetPaginationVariables, resetPaginationVariables)) {
      resetAllSelected();
      setRequestedPage(null);
      setAfter(null);
    }
  }, [previousResetPaginationVariables, resetPaginationVariables, resetAllSelected]);

  const initialVariables = useMemo(() => {
    return {
      input: {
        ...resetPaginationVariables,
        first: rowsPerPage || STACKS_LIMIT,
        after,
        requestedPage,
      },
    };
  }, [resetPaginationVariables, rowsPerPage, after, requestedPage]);

  const { error, loading, data, stopPolling, networkStatus, previousData, refetch } = useQuery(
    SEARCH_STACKS,
    {
      variables: initialVariables,
      onError,
      pollInterval: POLL_INTERVAL,
      // avoid request executing twice while fetchMore
      nextFetchPolicy: "cache-first",
      // APOLLO CLIENT UPDATE
      onCompleted: (data) => {
        propagateAvailableStacks((results) =>
          data?.searchStacks.edges.reduce((acc, next) => {
            return { ...acc, [next.node.id]: next.node };
          }, results)
        );
      },
      skip: !filterVariablesLoaded,
    }
  );

  const loadStacks = useCallback(
    async ({
      cursor,
      requestedPage,
    }: {
      cursor: string | null;
      rowsPerPage: number;
      requestedPage: SearchQueryRequestedPage | null;
    }) => {
      try {
        setAfter(cursor);
        setRequestedPage(requestedPage);
      } catch (error) {
        onError(error);
      }
    },
    [onError]
  );

  const initialLoading =
    (loading && networkStatus === NetworkStatus.loading && !data?.searchStacks) ||
    !filterVariablesLoaded;

  const loadingContent =
    loading &&
    (networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.fetchMore ||
      networkStatus === NetworkStatus.setVariables);

  const stacks = useMemo(
    () =>
      data?.searchStacks?.edges.map((edge) => edge.node) ||
      previousData?.searchStacks?.edges.map((edge) => edge.node) ||
      [],
    [data?.searchStacks?.edges, previousData?.searchStacks?.edges]
  );

  return {
    refetch,
    loadStacks,
    stopPolling,
    error,
    stacks,
    initialLoading,
    noResults: !loading && !data?.searchStacks,
    endCursor: data?.searchStacks?.pageInfo.endCursor,
    startCursor: data?.searchStacks?.pageInfo.startCursor,
    isEmpty: data && !stacks.length,
    loadingContent,
    predicates: filterVariables.predicates,
  };
};

export default useStacks;
