import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { WebhooksIntegrationInput } from "types/generated";

import { CREATE_WEBHOOK_INTEGRATION } from "./gql";

type UseCreateWebhookProps = {
  refetchQueries?: string[];
};

type HandleCreateWebhook = {
  entityId: string; //* Stack or Module ID
  input: WebhooksIntegrationInput;
  successCallback?: () => void;
};

const useCreateWebhook = ({ refetchQueries }: UseCreateWebhookProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [createWebhook, { loading }] = useMutation(CREATE_WEBHOOK_INTEGRATION, {
    refetchQueries,
    onError,
  });

  const handleCreateWebhook = useCallback(
    ({ entityId, input, successCallback }: HandleCreateWebhook) => {
      createWebhook({
        variables: {
          entityId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.webhooksIntegrationCreate?.id) {
            reportSuccess({
              message: `Webhook integration was successfully created`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [createWebhook, onError, reportSuccess]
  );

  return {
    createWebhook: handleCreateWebhook,
    loading,
  };
};

export default useCreateWebhook;
