import BadgeNext from "ds/components/BadgeNext";
import { IconComponent } from "types/Icon";
import { MenuModules, MenuStacks } from "components/icons";
import { BadgeVariants } from "ds/components/BadgeNext/types";

type ProjectTypeConfig = {
  variant: BadgeVariants;
  text: string;
  icon: IconComponent;
};

export enum ProjectType {
  Stack = "stack",
  Module = "module",
}

export const PROJECT_TYPES: Record<ProjectType, ProjectTypeConfig> = {
  [ProjectType.Stack]: { variant: "blue", text: "Stack", icon: MenuStacks },
  [ProjectType.Module]: { variant: "yellow", text: "Module", icon: MenuModules },
};

type ProjectTypeBadgeProps = {
  projectType: ProjectType;
  className?: string;
};

const ProjectTypeBadge = ({ projectType, className }: ProjectTypeBadgeProps) => {
  return (
    <BadgeNext
      variant={PROJECT_TYPES[projectType].variant}
      startIcon={PROJECT_TYPES[projectType].icon}
      className={className}
      text={PROJECT_TYPES[projectType].text}
      type="weak"
    />
  );
};

export default ProjectTypeBadge;
