import { useForm } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import useTitle from "hooks/useTitle";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import ButtonNew from "ds/components/Button/New";
import Box from "ds/components/Box";
import SystemMessage from "components/SystemMessage";
import useSelfHostedInstanceInfo from "hooks/useSelfHostedInstanceInfo";
import NotFoundPage from "components/error/NotFoundPage";

type SelfHostedCreateAccountFormFields = {
  accountName: string;
};

const SelfHostedCreateAccount = () => {
  useTitle("Create account · Spacelift");

  const instanceInfo = useSelfHostedInstanceInfo();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SelfHostedCreateAccountFormFields>({
    mode: "onChange",
  });

  const handleCreateAccount = () => {
    // TODO: wait for API to be implemented
  };

  if (!instanceInfo || instanceInfo?.hasAccountBeenCreated) {
    return <NotFoundPage />;
  }

  return (
    <SystemMessage
      title="Create a Spacelift account"
      message="Define your account name which is used for certain things like creating external IDs as part of role assumption."
    >
      <form onSubmit={handleSubmit(handleCreateAccount)}>
        <Box direction="column" gap="xx-large">
          <FormField label="Account name" error={errors?.accountName?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                error={!!errors?.accountName}
                {...register("accountName", {
                  validate: stringIsRequired("Account name field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <ButtonNew type="submit" variant="primary" fullWidth>
            Create & Explore Spacelift
          </ButtonNew>
        </Box>
      </form>
    </SystemMessage>
  );
};

export default SelfHostedCreateAccount;
