import { forwardRef } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import cx from "classnames";
import "./styles.css";

type MarkdownProps = {
  markup: string;
  className?: string;
};

const Markdown = forwardRef(function Markdown(
  props: MarkdownProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <section ref={ref} className={cx("markdown-body", props.className)}>
      <ReactMarkdown remarkPlugins={[gfm]} skipHtml>
        {props.markup}
      </ReactMarkdown>
    </section>
  );
});

export default Markdown;
