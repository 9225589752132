import { ReactNode, useCallback, useState } from "react";

import WarningContext from ".";

type WarningContextProviderProps = {
  children: ReactNode;
};

const WarningContextProvider = ({ children }: WarningContextProviderProps) => {
  const [warning, setWarning] = useState<ReactNode | undefined>();

  const clearWarning = useCallback(() => setWarning(undefined), []);
  const contextValue = {
    setWarning,
    clearWarning,
    warning,
  };
  return <WarningContext.Provider value={contextValue}>{children}</WarningContext.Provider>;
};

export default WarningContextProvider;
