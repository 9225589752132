import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import PageInfo from "components/PageWrapper/Info";
import TerraformRegistryViewHeader from "views/Account/TerraformRegistry/ViewHeader";
import SharedModulesTierInfo from "components/TierInfo/SharedModulesTierInfo";

import { FILTERS_ORDER_SETTINGS_KEY } from "../../constants";

type SharedModulesPageLayoutProps = {
  children: ReactNode;
};

const SharedModulesPageLayout = ({ children }: SharedModulesPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Shared modules · ${accountName}`);

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="Shared modules">
        <SearchInput
          placeholder="Search by name, ID, etc..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        />
      </PageInfo>

      <SharedModulesTierInfo type="callout" />

      {children}
    </>
  );
};

export default SharedModulesPageLayout;
