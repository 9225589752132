import useTypedContext from "hooks/useTypedContext";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { replaceSubdomain } from "utils/history";
import Link from "ds/components/Link";

import { ModuleSettingsContext } from "../Context";

const ModuleSettingsSharingViewForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);

  return (
    <>
      <FormField label="Accounts">
        {module.sharedAccounts.length === 0 && (
          <Typography tag="p" variant="p-body2" color="disabled">
            No accounts
          </Typography>
        )}
        {module.sharedAccounts.map((account) => (
          <Typography key={account} tag="p">
            <Link
              href={replaceSubdomain(window.location.origin, account)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {account}
            </Link>
          </Typography>
        ))}
      </FormField>
    </>
  );
};

export default ModuleSettingsSharingViewForm;
