import { FilterItemOption } from "components/Filters/types";
import Box from "ds/components/Box";
import InfiniteList from "components/InfiniteList";

import { getOptionLabel } from "../../helpers";
import FilterSectionEmpty from "../FilterSectionEmpty";
import styles from "../styles.module.css";
import FilterOption from "./FilterOption";

type FilterOptionsListProps = {
  options: FilterItemOption[];
  handleSelectValue: (value: string) => void;
  handleAddWildcard: (value: string) => void;
  selectedValues: Set<string>;
  isProjectRootFilter: boolean;
  isSearchable: boolean;
  searchInputIsWildcard: boolean;
  searchInput: string;
  disabled?: boolean;
  titleId: string;
};

const FilterOptionsList = (props: FilterOptionsListProps) => {
  const {
    options,
    handleSelectValue,
    isProjectRootFilter,
    isSearchable,
    selectedValues,
    searchInputIsWildcard,
    searchInput,
    handleAddWildcard,
    disabled,
    titleId,
  } = props;

  const wildcardIsNotSelected = !selectedValues.has(searchInput);

  return (
    <InfiniteList items={options}>
      {(items, onScroll) => (
        <Box
          role="group"
          aria-labelledby={titleId}
          direction="column"
          className={styles.valuesList}
          onScroll={onScroll}
        >
          {wildcardIsNotSelected &&
            isSearchable &&
            searchInputIsWildcard &&
            searchInput.length > 0 && (
              <FilterOption
                key="wildcard"
                value={searchInput}
                label={searchInput}
                postfix="new wildcard"
                onChange={handleAddWildcard}
                checked={selectedValues.has(searchInput)}
              />
            )}

          {items.map((item) => (
            <FilterOption
              disabled={disabled}
              key={item.value.toString()}
              value={item.value.toString()}
              label={getOptionLabel(item.value.toString(), isProjectRootFilter)}
              postfix={item.count}
              onChange={handleSelectValue}
              checked={selectedValues.has(item.value.toString())}
            />
          ))}

          {options.length === 0 && <FilterSectionEmpty />}
        </Box>
      )}
    </InfiniteList>
  );
};

export default FilterOptionsList;
