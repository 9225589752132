import { gql } from "@apollo/client";

export const GET_STACK_WEBHOOK_DELIVERY_DETAILS = gql`
  query GetStackWebhookDeliveryDetails($deliveryId: String!, $webhookId: ID!, $stackId: ID!) {
    stack(id: $stackId) {
      id
      integrations {
        webhook(id: $webhookId) {
          id
          deliveryDetails(key: $deliveryId) {
            requestHeaders {
              name
              values
            }
            requestBody
            responseHeaders {
              name
              values
            }
            responseError
            responseBody
          }
        }
      }
    }
  }
`;
