import { useQuery } from "@apollo/client";

import { BillingTierFeature, SearchBlueprintsOutput } from "types/generated";
import useTierFeature from "views/Account/hooks/useTierFeature";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { CHECK_BLUEPRINTS } from "./gql";

const useBlueprintsCheck = () => {
  const { onError } = useTypedContext(FlashContext);
  const isFeatureEnabled = useTierFeature(BillingTierFeature.Blueprints);

  const { loading, data } = useQuery<{
    searchBlueprints: SearchBlueprintsOutput;
  }>(CHECK_BLUEPRINTS, {
    fetchPolicy: "no-cache",
    skip: isFeatureEnabled,
    onError,
  });

  const hasBlueprints = loading ? undefined : !!data?.searchBlueprints.edges.length;

  return {
    checking: loading,
    showUpSell: !isFeatureEnabled && hasBlueprints === false,
  };
};

export default useBlueprintsCheck;
