import { gql } from "@apollo/client";

export const CREATE_BITBUCKET_DATA_CENTER_INTEGRATION = gql`
  mutation CreateVCSBitbucketDataCenterIntegration(
    $accessToken: String!
    $apiHost: String!
    $customInput: CustomVCSInput!
    $userFacingHost: String!
    $username: String
  ) {
    bitbucketDatacenterIntegrationCreate(
      accessToken: $accessToken
      apiHost: $apiHost
      customInput: $customInput
      userFacingHost: $userFacingHost
      username: $username
    ) {
      id
      name
    }
  }
`;

export const UPDATE_BITBUCKET_DATA_CENTER_INTEGRATION = gql`
  mutation UpdateVCSBitbucketDataCenterIntegration(
    $accessToken: String
    $apiHost: String!
    $customInput: CustomVCSUpdateInput
    $userFacingHost: String!
    $username: String
  ) {
    bitbucketDatacenterIntegrationUpdate(
      accessToken: $accessToken
      apiHost: $apiHost
      customInput: $customInput
      userFacingHost: $userFacingHost
      username: $username
    ) {
      id
      name
    }
  }
`;

export const DELETE_BITBUCKET_DATA_CENTER_INTEGRATION = gql`
  mutation DeleteVCSBitbucketDataCenterIntegration($id: ID!) {
    bitbucketDatacenterIntegrationDelete(id: $id) {
      id
    }
  }
`;

export const CHECK_BITBUCKET_DATA_CENTER_HAS_DEFAULT_INTEGRATION = gql`
  query CheckVCSBitbucketDataCenterHasDefaultIntegration {
    checkBitbucketDataCenterHasDefaultIntegration: bitbucketDatacenterIntegration {
      id
    }
  }
`;
