import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isThisYear from "date-fns/isThisYear";

import Tooltip from "ds/components/Tooltip";
import Typography, { TypographyProps } from "ds/components/Typography";

type TimestampProps = {
  timestamp: number;
  color?: TypographyProps["color"];
  variant?: TypographyProps["variant"];
};

const Timestamp = ({ timestamp, color, variant = "p-body3" }: TimestampProps) => {
  const timestampMs = timestamp * 1000;
  const shouldShowYear = !isThisYear(timestampMs);
  const finalFormat = shouldShowYear ? "MMM do, yyyy, pp" : "MMM do, pp";

  return (
    <Tooltip
      on={(props) => (
        <Typography {...props} tag="span" variant={variant} color={color}>
          {format(timestampMs, finalFormat)}
        </Typography>
      )}
    >
      {formatDistanceToNow(timestampMs, { addSuffix: true, includeSeconds: true })}
    </Tooltip>
  );
};

export default Timestamp;
