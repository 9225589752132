import ListEntitiesNew from "components/ListEntitiesNew";
import Box from "ds/components/Box";

import PersonalSettingsGroupsListVirtualizedItem from "./VirtualizedItem";
import styles from "./styles.module.css";

type PersonalSettingsGroupsListProps = {
  groups: string[];
};

const PersonalSettingsGroupsList = ({ groups }: PersonalSettingsGroupsListProps) => {
  return (
    <Box direction="column" grow="1">
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={groups.length}
          itemProps={{
            groups,
          }}
          virtualizedItem={PersonalSettingsGroupsListVirtualizedItem}
          itemKey={(index) => `${index} ${groups[index]}`}
        />
      </div>
    </Box>
  );
};

export default PersonalSettingsGroupsList;
