import { RunElementType } from "../types";

let shownElements: RunElementType[] = [];

export const resetShownElements = () => {
  shownElements = [];
};

export const canShowElement = (type: RunElementType) => {
  if (!shownElements.includes(type)) {
    shownElements.push(type);
    return true;
  }

  return false;
};
