import { useSearchParams } from "react-router-dom-v5-compat";
import { useCallback } from "react";

import { setUpdatedFiltersUrlParams } from "components/Filters/helpers";
import { ActiveFiltersMap } from "components/Filters/types";
import { StackSuggestions } from "constants/stack";
import { SearchSuggestionsFieldType, StackState } from "types/generated";
import { URL_FILTER_TAB_KEY, URL_SEARCH_KEY } from "constants/url_query_keys";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";

export enum PredefinedFilterType {
  AllStacks = "allStacks",
  NeedsAttention = "needsAttention",
  Failed = "failed",
  OnHold = "onHold",
  InProgress = "inProgress",
  Finished = "finished",
}

const usePredefinedFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const predefinedFilterTab = searchParams.get(URL_FILTER_TAB_KEY);

  const handleUpdateUrlParams = useCallback(
    (activeFilters: ActiveFiltersMap, filterTabKey: string) => {
      if (filterTabKey) {
        searchParams.set(URL_FILTER_TAB_KEY, filterTabKey);
        searchParams.delete(URL_SEARCH_KEY);
      } else {
        searchParams.delete(URL_FILTER_TAB_KEY);
      }

      const updatedParams = setUpdatedFiltersUrlParams(
        activeFilters,
        searchParams,
        sessionStorage,
        FILTERS_ORDER_SETTINGS_KEY
      );

      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams]
  );

  const setPredefinedFilter = useCallback(
    (type: PredefinedFilterType) => {
      if (
        type === predefinedFilterTab ||
        (type === PredefinedFilterType.AllStacks && !predefinedFilterTab)
      ) {
        return;
      }

      switch (type) {
        case PredefinedFilterType.Failed:
          handleUpdateUrlParams(
            new Map([
              [
                StackSuggestions.State,
                {
                  key: StackSuggestions.State,
                  filterName: StackSuggestions.State,
                  type: SearchSuggestionsFieldType.Enum,
                  values: [StackState.Failed],
                },
              ],
            ]),
            type
          );
          break;
        case PredefinedFilterType.NeedsAttention:
          handleUpdateUrlParams(
            new Map([
              [
                StackSuggestions.State,
                {
                  key: StackSuggestions.State,
                  filterName: StackSuggestions.State,
                  type: SearchSuggestionsFieldType.Enum,
                  values: [StackState.Unconfirmed],
                },
              ],
            ]),
            type
          );
          break;
        case PredefinedFilterType.OnHold:
          handleUpdateUrlParams(
            new Map([
              [
                StackSuggestions.State,
                {
                  key: StackSuggestions.State,
                  filterName: StackSuggestions.State,
                  type: SearchSuggestionsFieldType.Enum,
                  values: [StackState.Confirmed, StackState.None, StackState.ReplanRequested],
                },
              ],
            ]),
            type
          );
          break;
        case PredefinedFilterType.InProgress:
          handleUpdateUrlParams(
            new Map([
              [
                StackSuggestions.State,
                {
                  key: StackSuggestions.State,
                  filterName: StackSuggestions.State,
                  type: SearchSuggestionsFieldType.Enum,
                  values: [
                    StackState.Applying,
                    StackState.Destroying,
                    StackState.Initializing,
                    StackState.Planning,
                    StackState.PreparingApply,
                    StackState.PreparingReplan,
                  ],
                },
              ],
            ]),
            type
          );
          break;
        case PredefinedFilterType.Finished:
          handleUpdateUrlParams(
            new Map([
              [
                StackSuggestions.State,
                {
                  key: StackSuggestions.State,
                  filterName: StackSuggestions.State,
                  type: SearchSuggestionsFieldType.Enum,
                  values: [StackState.Finished],
                },
              ],
            ]),
            type
          );
          break;
        case PredefinedFilterType.AllStacks:
          handleUpdateUrlParams(new Map(), type);
          break;
        default:
          break;
      }
    },
    [handleUpdateUrlParams, predefinedFilterTab]
  );

  return {
    predefinedFilterTab,
    setPredefinedFilter,
  };
};

export default usePredefinedFilters;
