import { lazy, Suspense } from "react";

import CodeEditorLoadingSkeleton from "./LoadingSkeleton";
import { CodeEditorMonacoProps } from "./Monaco";

type CodeEditorProps = CodeEditorMonacoProps;

const LazyCodeEditorMonaco = lazy(() => import("./Monaco"));

const CodeEditor = (props: CodeEditorProps) => {
  return (
    <Suspense fallback={<CodeEditorLoadingSkeleton skeletonCount={props.skeletonCount} />}>
      <LazyCodeEditorMonaco {...props} />
    </Suspense>
  );
};

export default CodeEditor;
