import { Datum } from "ds/components/Charts/PieChart/types";

export const getPercentages = (data: Datum[], total: number) => {
  let maxDifId = "";
  let maxDiffValue = 0;
  let minDifId = "";
  let minDiffValue = 0;
  let percentageSum = 0;

  const percentages = data.reduce(
    (acc, next) => {
      if (!next.value) {
        return { ...acc, [next.id]: 0 };
      }

      const rawValue = (next.value * 100) / total;
      const value = Math.round(rawValue * 100) / 100;
      const roundDiff = rawValue - value;

      if (roundDiff < minDiffValue) {
        minDiffValue = next.value;
        minDifId = next.id;
      }

      if (roundDiff > maxDiffValue) {
        maxDiffValue = next.value;
        maxDifId = next.id;
      }

      percentageSum += value;

      return { ...acc, [next.id]: value };
    },
    {} as Record<string, number>
  );

  const diff = 100 - percentageSum;

  if (diff > 0 && maxDifId in percentages) {
    percentages[maxDifId] = Math.round((percentages[maxDifId] + diff) * 100) / 100;
  } else if (diff < 0 && minDifId in percentages) {
    percentages[minDifId] = Math.round((percentages[minDifId] + diff) * 100) / 100;
  }

  return percentages;
};
