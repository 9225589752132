import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import { canDiscardStackRun } from "shared/Run/useDiscardRun/accessValidation";

import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { StackBulkActions } from "../types";
import { StackActionItemForBulkActions } from "./types";

const useDiscardStackRunBulkAction = (): StackActionItemForBulkActions => {
  const [runDiscard] = useBulkDiscardRun();

  return {
    key: StackBulkActions.Discard,
    title: BULK_ACTION_NAMES[StackBulkActions.Discard],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Discard],
    condition: canDiscardStackRun,
    mutation: (stack) => runDiscard({ stack: stack.id, note: null, run: null }),
  };
};

export default useDiscardStackRunBulkAction;
