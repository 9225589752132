import { ThumbUpEmpty } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import { LayoutContext } from "components/layout/Context";

import MenuItem from "../MenuItem";
import { FEATUREBASE_URL } from "./consts";

const FeaturebaseSH = () => {
  const { isExpandedMode } = useTypedContext(LayoutContext);

  return (
    <MenuItem
      short={!isExpandedMode}
      icon={ThumbUpEmpty}
      href={FEATUREBASE_URL}
      ariaLabel="Feedback dashboard"
    >
      Feedback dashboard
    </MenuItem>
  );
};

export default FeaturebaseSH;
