import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { trackSegmentEvent } from "shared/Analytics";
import Button from "components/button/Button";
import { BillingTier } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { GET_TIER } from "views/Account/SubscriptionWrapper";

import { GET_ACCOUNT_DATA } from "../../../../gql";
import { GET_USAGE } from "../gql";

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($tier: BillingTier!) {
    billingSubscriptionUpdateTier(tier: $tier) {
      isActive
      selfServePortalUrl
      billingCycleStart
    }
  }
`;

type BillingUpgradeButtonProps = {
  tier: BillingTier;
  onModalClose: () => void;
};

const BillingUpgradeButton = ({ tier, onModalClose }: BillingUpgradeButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [updateSubscription, { loading }] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: [{ query: GET_USAGE }, { query: GET_ACCOUNT_DATA }, { query: GET_TIER }],
  });

  const buttonText =
    tier === BillingTier.Enterprise ? "Downgrade to Cloud" : "Upgrade to Enterprise";

  const newTier = useCallback(() => {
    return tier === BillingTier.Enterprise ? BillingTier.V3Cloud : BillingTier.Enterprise;
  }, [tier]);

  const handleUpgrade = useCallback(() => {
    updateSubscription({ variables: { tier: newTier() } })
      .then(() => {
        reportSuccess({ message: "Subscription was updated" });
        trackSegmentEvent("Upgrade button clicked");
        onModalClose();
      })
      .catch(onError);
  }, [onError, onModalClose, newTier, reportSuccess, updateSubscription]);

  return (
    <Button type="button" onClick={handleUpgrade} pill full loading={loading}>
      {buttonText}
    </Button>
  );
};

export default BillingUpgradeButton;
