import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { StackDependencyReference } from "types/generated";

import { STACK_DEPENDENCIES_DELETE_REFERENCES } from "./gql";

const useDeleteOutputReference = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackDependenciesDeleteReferences] = useMutation<{
    stackDependenciesDeleteReferences: StackDependencyReference[];
  }>(STACK_DEPENDENCIES_DELETE_REFERENCES, {
    refetchQueries: ["GetStack"],
    onError,
  });

  const handleDeleteReferences = useCallback(
    (referenceId: string) => {
      stackDependenciesDeleteReferences({
        variables: {
          referenceIds: [referenceId],
        },
      })
        .then(({ data }) => {
          if (
            data?.stackDependenciesDeleteReferences &&
            data.stackDependenciesDeleteReferences.length > 0
          ) {
            reportSuccess({
              message: `Output reference successfully deleted`,
            });
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackDependenciesDeleteReferences]
  );

  return {
    deleteOutputReference: handleDeleteReferences,
  };
};

export default useDeleteOutputReference;
