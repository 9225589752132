import { History } from "history";
import trimStart from "lodash-es/trimStart";

import { VcsProvider } from "types/generated";

type RepoUrlProps = {
  apiHost: string;
  namespace: string;
  repository: string;
  repositoryURL: string | null;
  provider: VcsProvider;
};
export const generateRepoUrl = ({
  apiHost,
  namespace,
  repository,
  repositoryURL,
  provider,
}: RepoUrlProps) => {
  if (provider === VcsProvider.AzureDevops) {
    return `${apiHost}/${namespace}/_git/${repository}`;
  }

  if (provider === VcsProvider.BitbucketDatacenter) {
    return `${apiHost}/projects/${namespace}/repos/${repository}`;
  }

  if (provider === VcsProvider.Git && repositoryURL !== null) {
    return repositoryURL;
  }

  return `${apiHost}/${namespace}/${repository}`;
};

type RepoUrlTextProps = {
  namespace: string;
  repository: string;
};
export const generateRepoUrlText = ({ namespace, repository }: RepoUrlTextProps) => {
  if (namespace === "") {
    return repository;
  }
  return `${namespace}/${repository}`;
};

type BranchUrlProps = {
  apiHost: string;
  namespace: string;
  repository: string;
  repositoryURL: string | null;
  provider: VcsProvider;
  branch: string;
  projectRoot?: string | null;
};
export const generateBranchUrl = ({
  apiHost,
  namespace,
  repository,
  repositoryURL,
  provider,
  branch,
  projectRoot,
}: BranchUrlProps) => {
  if (provider === VcsProvider.AzureDevops) {
    const url = `${apiHost}/${namespace}/_git/${repository}?version=GB${branch}`;

    if (!projectRoot) {
      return url;
    }

    return `${url}&path=${projectRoot}`;
  }

  if (provider === VcsProvider.BitbucketDatacenter) {
    const url = `${apiHost}/projects/${namespace}/repos/${repository}/browse`;
    if (!projectRoot) {
      return url + `?at=refs/heads/${branch}`;
    }

    return `${url}/${trimStart(projectRoot, "/")}?at=refs/heads/${branch}`;
  }

  const branchUrl = getBranchUrlSegment(provider, repositoryURL);

  if (!projectRoot) {
    return `${apiHost}/${namespace}/${repository}/${branchUrl}/${branch}`;
  }

  projectRoot = trimStart(projectRoot, "/");
  return `${apiHost}/${namespace}/${repository}/${branchUrl}/${branch}/${projectRoot}`;
};

const getBranchUrlSegment = (provider: VcsProvider, repositoryURL: string | null) => {
  if (provider === VcsProvider.BitbucketCloud) {
    return "src";
  }
  if (provider === VcsProvider.Git && repositoryURL?.includes("bitbucket.org")) {
    return "branch";
  }
  return "tree";
};

type CommitUrlProps = {
  host: string;
  namespace: string;
  repository: string;
  commit: string;
  provider: VcsProvider;
};
export const generateCommitUrl = ({
  host,
  namespace,
  repository,
  commit,
  provider,
}: CommitUrlProps) => {
  if (provider === VcsProvider.AzureDevops) {
    return `${host}/${namespace}/_git/${repository}/commit/${commit}`;
  }

  if (provider === VcsProvider.BitbucketCloud) {
    return `${host}/${namespace}/${repository}/commits/${commit}`;
  }

  if (provider === VcsProvider.BitbucketDatacenter) {
    return `${host}/projects/${namespace}/repos/${repository}/commits/${commit}`;
  }

  return `${host}/${namespace}/${repository}/commit/${commit}`;
};

export const updateURLWithParams = (
  params: URLSearchParams,
  history: History<unknown>,
  replaceHistory = false
) => {
  const newParams = params.toString();

  if (replaceHistory) {
    history.replace({ search: `${newParams ? `?${newParams}` : ""}` });
  } else {
    history.push({ search: `${newParams ? `?${newParams}` : ""}` });
  }
};

export const decodeURIParam = (param: string | null) => {
  if (param) {
    return decodeURIComponent(param);
  }

  return undefined;
};

export const getHostName = () => {
  if (typeof window !== "undefined") {
    const hostname = window.location.hostname;
    const matchResult = hostname.match(
      /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/
    );

    if (matchResult !== null) {
      return matchResult[1];
    }
  }

  return "spacelift.io";
};
