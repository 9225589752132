import { gql } from "@apollo/client";

export const CREATE_GITHUB_ENTERPRISE_INTEGRATION = gql`
  mutation CreateGitHubEnterpriseIntegrationInVCS(
    $apiHost: String!
    $userFacingHost: String
    $appID: String!
    $customInput: CustomVCSInput!
    $privateKey: String!
    $webhookSecret: String!
  ) {
    githubEnterpriseIntegrationCreate(
      apiHost: $apiHost
      userFacingHost: $userFacingHost
      appID: $appID
      customInput: $customInput
      privateKey: $privateKey
      webhookSecret: $webhookSecret
    ) {
      id
      apiHost
      name
    }
  }
`;

export const UPDATE_GITHUB_ENTERPRISE_INTEGRATION = gql`
  mutation UpdateGitHubEnterpriseIntegration(
    $apiHost: String!
    $customInput: CustomVCSUpdateInput
    $privateKey: String
    $userFacingHost: String
    $webhookSecret: String!
  ) {
    githubEnterpriseIntegrationUpdate(
      apiHost: $apiHost
      customInput: $customInput
      privateKey: $privateKey
      userFacingHost: $userFacingHost
      webhookSecret: $webhookSecret
    ) {
      id
      apiHost
      name
    }
  }
`;
