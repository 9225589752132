import { Route, Routes, useParams } from "react-router-dom-v5-compat";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";
import NotFoundPage from "components/error/NotFoundPage";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import { hasSpaceManageAccess } from "utils/user";

import StackSettingsDetails from "./Details";
import StackSettingsSourceCode from "./SourceCode";
import StackSettingsVendor from "./Vendor";
import StackSettingsBehavior from "./Behavior";
import StackSettingsIntegrations from "./Integrations";
import StackSettingsDeletion from "./Deletion";
import { getStacksBackUrl } from "../helpers";
import { StackSettingsContextData } from "./Context";
import useGetStackSettings from "./useGetStackSettings";
import StackSettingsRoutesLayout from "./components/RoutesLayout";
import StackSettingsIntegrationsWebhook from "./Integrations/Webhook";
import StackSettingsIntegrationsWebhookDelivery from "./Integrations/WebhookDelivery";

const StackSettings = () => {
  const { stackId } = useParams<{ stackId: string }>();

  const { stackSettings, loading, error } = useGetStackSettings(stackId);

  useTitle(stackSettings?.name ? `Settings · ${stackSettings.name}` : "Settings");

  useBreadcrumbs(
    [
      {
        title: "Stacks",
        link: getStacksBackUrl(),
      },
      {
        title: stackSettings?.name || "",
        link: `/stack/${stackId}`,
      },
      {
        title: "Stack settings",
      },
    ],
    [stackSettings?.name, stackId]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !stackSettings) {
    return <PageLoading />;
  }

  if (!stackSettings) {
    return <NotFoundPage />;
  }

  const canManageStack = hasSpaceManageAccess(stackSettings.spaceDetails.accessLevel);

  return (
    <StackSettingsContextData.Provider
      value={{
        stackSettings,
        canManageStack,
      }}
    >
      <Routes>
        <Route element={<StackSettingsRoutesLayout />}>
          <Route index element={<StackSettingsDetails />} />
          <Route path="source-code" element={<StackSettingsSourceCode />} />
          <Route path="vendor" element={<StackSettingsVendor />} />
          <Route path="behavior" element={<StackSettingsBehavior />} />
          <Route path="integrations/*" element={<StackSettingsIntegrations />} />
          <Route path="deletion" element={<StackSettingsDeletion />} />
        </Route>
        <Route
          path="integrations/webhook/:webhookId"
          element={<StackSettingsIntegrationsWebhook />}
        />
        <Route
          path="integrations/webhook/:webhookId/delivery/:deliveryId/*"
          element={<StackSettingsIntegrationsWebhookDelivery />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </StackSettingsContextData.Provider>
  );
};

export default StackSettings;
