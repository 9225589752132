import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { downloadFile } from "utils/file";
import { WorkerPool } from "types/generated";

import { RESET_WORKER_POOL } from "./gql";

const useResetWorkerPool = (viewPageMode?: boolean) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [resetWorkerPool, { loading }] = useMutation<{ workerPoolReset: WorkerPool }>(
    RESET_WORKER_POOL,
    {
      refetchQueries: [viewPageMode ? "GetWorkerPool" : "SearchWorkerPools"],
    }
  );

  const handleResetWorkerPool = useCallback(
    (workerPoolId: string, certificateSigningRequest: string) => {
      return resetWorkerPool({ variables: { workerPoolId, certificateSigningRequest } })
        .then(({ data }) => {
          if (data) {
            downloadFile(`worker-pool-${workerPoolId}.config`, data.workerPoolReset.config);
            reportSuccess({ message: "Worker pool was reset" });
          }
        })
        .catch(onError);
    },
    [resetWorkerPool, onError, reportSuccess]
  );

  return {
    resetWorkerPool: handleResetWorkerPool,
    loading,
  };
};

export default useResetWorkerPool;
