import { useModal, create, show } from "@ebay/nice-modal-react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { AwsIntegration } from "types/generated";

import useDeleteAwsIntegration from "../useDeleteAwsIntegration";
import AwsIntegrationsDeleteConfirmationContent from "./Content";

type DeleteConfirmationProps = {
  integration: AwsIntegration;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  integration,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { onDelete, loading: deleteLoading } = useDeleteAwsIntegration();

  const onConfirm = () => {
    onDelete(integration.id, () => {
      modal.resolve();
      modal.hide();
    });
  };

  return (
    <DeleteConfirmationModal
      size="large"
      title="Delete integration"
      onConfirm={onConfirm}
      isLoading={deleteLoading}
      isDismissable={!deleteLoading}
    >
      <AwsIntegrationsDeleteConfirmationContent integration={integration} />
    </DeleteConfirmationModal>
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
