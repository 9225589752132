import { WorkerPool } from "types/generated";

import { CreateWorkerPoolFields } from "./types";

export const setFormDefaultValues = (workerPool?: WorkerPool): CreateWorkerPoolFields => ({
  name: workerPool?.name || "",
  certificateFile: null,
  description: workerPool?.description || "",
  space: workerPool?.spaceDetails.id || "",
  labels: workerPool?.labels.map((value) => ({ value })) || [],
});
