import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { ConfigElement, ConfigType } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import ContextConfigFormNameField from "components/ContextConfigForm/NameField";
import ContextConfigFormValueField from "components/ContextConfigForm/ValueField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

import { ADD_CONTEXT_CONFIG, UPDATE_CONTEXT_CONFIG } from "./gql";
import { ContextVariablesFormFields, ContextVariablesFormDrawerProps } from "./types";

const ContextVariablesFormDrawer = createDrawer(
  ({ variable, contextId }: ContextVariablesFormDrawerProps) => {
    const isEditMode = !!variable;

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const drawer = useModal();

    const variableForm = useForm<ContextVariablesFormFields>({
      defaultValues: {
        id: variable?.id || "",
        description: variable?.description || "",
        value: variable?.value || "",
        writeOnly: variable ? variable.writeOnly : true,
        type: ConfigType.EnvironmentVariable,
      },
      mode: "onChange",
    });

    const {
      handleSubmit,
      formState: { isValid, isDirty },
      watch,
    } = variableForm;

    const [addContextConfig] = useMutation<{ contextConfigAdd: ConfigElement }>(
      ADD_CONTEXT_CONFIG,
      {
        refetchQueries: ["GetContext"],
        variables: { contextId },
      }
    );

    const [updateContextConfig] = useMutation<{ contextConfigAdd: ConfigElement }>(
      UPDATE_CONTEXT_CONFIG,
      {
        onError,
        refetchQueries: ["GetContext"],
        variables: { contextId },
      }
    );

    const closeDrawer = () => {
      drawer.resolve();
      drawer.hide();
    };

    const onCreateSubmit = (formData: ContextVariablesFormFields) => {
      addContextConfig({
        variables: {
          input: {
            id: formData.id,
            description: formData.description,
            value: formData.value,
            writeOnly: formData.writeOnly,
            type: formData.type,
          },
        },
      })
        .then(({ data }) => {
          if (data?.contextConfigAdd) {
            reportSuccess({
              message: `Context variable is successfully created`,
            });

            closeDrawer();
          }
        })
        .catch(onError);
    };

    const onEditSubmit = (formData: ContextVariablesFormFields) => {
      updateContextConfig({
        variables: {
          input: {
            id: formData.id,
            description: formData.description,
            value: formData.value,
            writeOnly: formData.writeOnly,
            type: formData.type,
          },
        },
      })
        .then(({ data }) => {
          if (data?.contextConfigAdd) {
            reportSuccess({
              message: `Context variable is successfully saved`,
            });

            closeDrawer();
          }
        })
        .catch(onError);
    };

    const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
      if (isEditMode) {
        onEditSubmit(formData);
      } else {
        onCreateSubmit(formData);
      }
    };

    return (
      <DrawerSimple>
        <FormProvider {...variableForm}>
          <DrawerHeader justify="between">
            {!isEditMode && <DrawerHeaderTitle title="Add variable" />}
            {isEditMode && <DrawerHeaderTitle title="Edit variable" />}
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <Box margin="0 0 large">
              {isEditMode ? (
                <FormFieldViewText label="Name" value={variable.id} />
              ) : (
                <ContextConfigFormNameField name="id" />
              )}
            </Box>
            <ContextConfigFormValueField name="value" isSecret={watch("writeOnly")} />
            <Box margin="large 0 large">
              <ContextConfigFormIsSecretField name="writeOnly" />
            </Box>
            <ContextConfigFormDescriptionField name="description" />

            <DrawerFooter>
              <DrawerFooterActions>
                <Button variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || !isDirty}
                >
                  Save
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showContextVariablesFormDrawer = createDrawerTrigger(ContextVariablesFormDrawer);
