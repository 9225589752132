import { useEffect } from "react";

import setLocation from "shared/setLocation";
import SpaceliftLoader from "components/loading/PageLoading";
import useTitle from "hooks/useTitle";

const LoginTerraform = () => {
  useTitle("Redirecting to provider...");

  useEffect(() => {
    setLocation(`/terraform/authz${window?.location.search}`);
  }, []);

  return <SpaceliftLoader />;
};

export default LoginTerraform;
