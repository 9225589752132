import { useCallback, useMemo, useState, useEffect, useRef } from "react";

import { convertToTreeStructure, isNodeVisible, updateTreeRendering } from "utils/tree";
import Box from "ds/components/Box";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import ConfigManagementTreeGridListSortHeader from "./SortHeader";
import styles from "./styles.module.css";
import ConfigManagementTreeGridListRow from "./Row";
import { ConfigNode } from "../../types";
import ConfigManagementTreeGridListSortHeaderStatic from "./SortHeaderStatic";

type ConfigManagementTreeGridListProps = {
  nodes: ConfigNode[];
  activeId?: string;
  sortable?: boolean;
  analyticsPage?: AnalyticsPage;
};
const ConfigManagementTreeGridList = ({
  nodes,
  activeId,
  sortable,
  analyticsPage,
}: ConfigManagementTreeGridListProps) => {
  const selectedRef = useRef<HTMLDivElement>(null);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const { flatList, tree } = useMemo(
    () => convertToTreeStructure<ConfigNode>(nodes, "id", "parent", "name"),
    [nodes]
  );

  const flatListWithOrder = useMemo(() => {
    let order = 1;
    let orderUnderRole = 1;

    return flatList.map((currentItem, i) => {
      if (!currentItem.item.shouldCountOrder) {
        // Reset counter when we leave tasks or roles
        order = 1;

        return currentItem;
      }

      const previousItem = flatList[i - 1];
      const nextItem = flatList[i + 1];

      // Check if task is still under the same playbook
      if (
        (previousItem &&
          currentItem.parentId === previousItem.parentId &&
          !currentItem.item.hasMiddleGroup) ||
        (previousItem && !currentItem.item.hasMiddleGroup && previousItem.item.hasMiddleGroup)
      ) {
        order++;
      }

      // Check if task is still under the same role
      if (
        previousItem &&
        currentItem.parentId === previousItem.parentId &&
        currentItem.item.hasMiddleGroup
      ) {
        orderUnderRole++;
      } else {
        orderUnderRole = 1;
      }

      const lastUnderRole =
        currentItem.item.hasMiddleGroup && nextItem && !nextItem.item.hasMiddleGroup;

      // Task is last one item or last under playbook
      const last = !nextItem || !nextItem.item.shouldCountOrder;

      return {
        ...currentItem,
        item: {
          ...currentItem.item,
          order: currentItem.item.hasMiddleGroup ? orderUnderRole : order,
          lastUnderRole,
          last,
        },
      };
    });
  }, [flatList]);

  const allKeysSet = useMemo(() => {
    const allKeys = flatListWithOrder.map((item) => item.id);
    return new Set(allKeys);
  }, [flatListWithOrder]);

  const [expandedKeys, setExpandedKeys] = useState(new Set<string>());
  const [loadingKeys, setLoadingKeys] = useState(new Set<string>());
  const [renderedKeys, setRenderedKeys] = useState(new Set<string>());
  const visibleNodes = useMemo(
    () => flatListWithOrder.filter((node) => isNodeVisible(node, renderedKeys, expandedKeys)),
    [expandedKeys, renderedKeys, flatListWithOrder]
  );

  useEffect(() => {
    selectedRef?.current?.scrollIntoView({ block: "center", inline: "nearest" });
  }, []);

  // Expand node when only one is available
  useEffect(() => {
    if (!activeId && expandedKeys.size === 0 && tree.length === 1) {
      setExpandedKeys(allKeysSet);
    }
  }, [tree, allKeysSet, expandedKeys, activeId]);

  useEffect(() => {
    const { renderedNodes } = updateTreeRendering(
      flatListWithOrder,
      flatListWithOrder.map((node) => node.path)
    );

    setRenderedKeys(renderedNodes);
  }, [allKeysSet, flatListWithOrder]);

  useEffect(() => {
    if (activeId && !expandedKeys.has(activeId)) {
      const item = flatListWithOrder.find(({ id }) => id === activeId);

      if (item?.path && item.parentId && !expandedKeys.has(item.parentId)) {
        const { expandedNodes } = updateTreeRendering(flatListWithOrder, [item?.path]);
        setExpandedKeys(expandedNodes);
      }
    }
  }, [activeId, expandedKeys, flatList, flatListWithOrder]);

  const toggleKey = useCallback(
    (key: string, hasChildrenToLoad?: boolean) => () => {
      // TODO: in the next iteration when API ready add query to load children and the switch keys
      if (!hasChildrenToLoad) {
        setExpandedKeys((prev) => {
          const newKeys = new Set(prev);
          if (newKeys.has(key)) {
            newKeys.delete(key);
            trackSegmentAnalyticsEvent?.("List collapsed");
          } else {
            trackSegmentAnalyticsEvent?.("List expanded");
            newKeys.add(key);
          }
          return newKeys;
        });
      } else {
        setLoadingKeys((prev) => {
          const newKeys = new Set(prev);
          if (newKeys.has(key)) {
            newKeys.delete(key);
            trackSegmentAnalyticsEvent?.("List collapsed");
          } else {
            newKeys.add(key);
            trackSegmentAnalyticsEvent?.("List expanded");
          }
          return newKeys;
        });
        // TODO: then unset when loaded or error
      }
    },
    [setExpandedKeys, trackSegmentAnalyticsEvent]
  );

  return (
    <Box direction="column" grow="1" role="treegrid">
      {visibleNodes.length > 0 && sortable && <ConfigManagementTreeGridListSortHeader />}
      {visibleNodes.length > 0 && !sortable && <ConfigManagementTreeGridListSortHeaderStatic />}

      <Box direction="column" grow="1" className={styles.listWrapper} role="rowgroup" fullWidth>
        {visibleNodes.map((node) => (
          <ConfigManagementTreeGridListRow
            key={node.id}
            node={node}
            isActive={activeId === node.id}
            loading={loadingKeys.has(node.id)}
            hasChildrenToLoad={node.item.hasChildrenToLoad}
            isExpanded={expandedKeys.has(node.id)}
            onToggle={toggleKey(node.id, node.item.hasChildrenToLoad)}
            innerRef={activeId === node.id ? selectedRef : undefined}
            description={node.item.description}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ConfigManagementTreeGridList;
