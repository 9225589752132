import Badge from "ds/components/BadgeNext/New";
import { BadgeVariant } from "ds/components/BadgeNext/types";
import { AuditTrailEventType } from "types/generated";

import { titleCase } from "../../helpers";

type AuditTrailListItemEventTypeBadgeProps = {
  eventType: AuditTrailEventType;
};

const AuditTrailListItemEventTypeBadge = ({ eventType }: AuditTrailListItemEventTypeBadgeProps) => {
  let variant = BadgeVariant.gray;

  switch (eventType) {
    case AuditTrailEventType.Update:
      variant = BadgeVariant.blue;
      break;
    case AuditTrailEventType.Create:
      variant = BadgeVariant.green;
      break;
    case AuditTrailEventType.Delete:
      variant = BadgeVariant.red;
      break;
    case AuditTrailEventType.None:
      variant = BadgeVariant.yellow;
      break;
    case AuditTrailEventType.Unknown:
      variant = BadgeVariant.gray;
      break;
  }

  return <Badge type="strong" variant={variant} text={titleCase(eventType)} />;
};

export default AuditTrailListItemEventTypeBadge;
