export const content = [
  {
    title: "Spacelift in action",
    backgroundClass: "bgSpacelift",
    videoId: "Xnu16b26iPc",
  },
  {
    title: "Stacks introduction",
    backgroundClass: "bgStacks",
    videoId: "WFYXKCbc9w4",
  },
  {
    title: "Policies at spacelift",
    backgroundClass: "bgPolicies",
    videoId: "QFao3g2j3Qc",
  },
  {
    title: "Spacelift contexts",
    backgroundClass: "bgContexts",
    videoId: "UFs7BkVESag",
  },
];
