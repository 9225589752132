import Button from "ds/components/Button";
import SystemMessage from "components/SystemMessage";
import { ErrorColored } from "components/icons";

const NotFoundPage = () => (
  <SystemMessage
    title="Page not found"
    message={
      <>
        Sorry, we couldn't find what you're looking for. If you're certain something should be here,
        you may try reloading the page.
      </>
    }
    icon={ErrorColored}
  >
    <Button variant="primary" size="medium" href="/">
      Go to Homepage
    </Button>
  </SystemMessage>
);

export default NotFoundPage;
