import { LegendOrdinal } from "@visx/legend";
import { ScaleOrdinal } from "d3";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import OrdinalLegendItem from "./Item";

type OrdinalLegendProps = {
  colorScale: ScaleOrdinal<string, string>;
  legendTitle?: string;
};

const OrdinalLegend = ({ colorScale, legendTitle }: OrdinalLegendProps) => (
  <Box direction="column" align="center" gap="medium">
    {!!legendTitle && (
      <Typography variant="p-t8" tag="span" color="secondary">
        {legendTitle}
      </Typography>
    )}
    <LegendOrdinal scale={colorScale}>
      {(legends) => (
        <Box
          direction="row"
          justify="center"
          align="center"
          gap="x-large"
          className={styles.legendsContainer}
        >
          {legends.map(({ value, text }) => (
            <OrdinalLegendItem key={text} text={text} color={value} />
          ))}
        </Box>
      )}
    </LegendOrdinal>
  </Box>
);

export default OrdinalLegend;
