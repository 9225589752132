import Box from "ds/components/Box";
import { SelectRenderOption } from "ds/components/Select";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import SelectOptionHighlights from "ds/components/Select/OptionHighlights";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import { SelectOptionOutput } from "./types";

type OutputReferencesDrawerSelectOptionProps = SelectOptionOutput & SelectRenderOption;

const OutputReferencesDrawerSelectOption = ({
  value,
  label,
  description,
  onChange,
  closeSelect,
  checked,
  searchHighlightResult,
  key,
  ...restProps
}: OutputReferencesDrawerSelectOptionProps) => {
  const handleOnChange = () => {
    onChange?.(value);
    closeSelect?.();
  };

  return (
    <DropdownSectionItem key={key} active={checked} onClick={handleOnChange}>
      <Box {...restProps} direction="column" gap="small" justify="center" align="start" fullWidth>
        <Box fullWidth>
          <TextEllipsis tooltipPlacement="left" tooltipWidthMode="maxWidthSm" tooltip={label}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                <SelectOptionHighlights highlights={searchHighlightResult} fullText={label} />
              </Typography>
            )}
          </TextEllipsis>
        </Box>

        <Box fullWidth>
          <TextEllipsis tooltipPlacement="left" tooltipWidthMode="maxWidthSm" tooltip={description}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body3" color="secondary">
                {description}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      </Box>
    </DropdownSectionItem>
  );
};

export default OutputReferencesDrawerSelectOption;
