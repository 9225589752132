import { gql } from "@apollo/client";

export const GET_CONTEXTS = gql`
  query NewModuleGetContexts($id: ID!) {
    attachableContextsInSpace(id: $id) {
      id
      name
    }
  }
`;

export const ATTACH_CONTEXT = gql`
  mutation NewModuleAttachContext($stackId: ID!, $contextId: ID!, $priority: Int!) {
    contextAttach(stack: $stackId, id: $contextId, priority: $priority) {
      id
    }
  }
`;

export const DETACH_CONTEXT = gql`
  mutation NewModuleDetachContext($id: ID!) {
    contextDetach(id: $id) {
      id
    }
  }
`;
