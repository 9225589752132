import { StackAzureIntegrationAttachment } from "types/generated";
import KeyValue from "components/KeyValue";

type StackSettingsIntegrationsCloudAttachedItemAzureFieldsType = {
  integration: StackAzureIntegrationAttachment;
};

const StackSettingsIntegrationsCloudAttachedItemAzureFields = ({
  integration,
}: StackSettingsIntegrationsCloudAttachedItemAzureFieldsType) => {
  return (
    <>
      <KeyValue name="Name">{integration.integrationName}</KeyValue>
      <KeyValue name="Subscription ID">
        {integration.subscriptionId || integration.defaultSubscriptionId}
      </KeyValue>
    </>
  );
};

export default StackSettingsIntegrationsCloudAttachedItemAzureFields;
