import { IconComponent } from "types/Icon";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";

type TerraformVersionProps = {
  icon: IconComponent;
  text?: string;
};

const VendorVersion = ({ text, icon }: TerraformVersionProps) => {
  return (
    <Box align="center" __deprecatedGap="0.5rem">
      <Icon src={icon} noShrink />
      {text && (
        <Typography variant="p-body3" tag="span">
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default VendorVersion;
