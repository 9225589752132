import { Route, Routes } from "react-router-dom-v5-compat";

import VCSIntegrationsList from "./List";
import VCSCreateIntegrations from "./Create";
import VCSEditIntegrations from "./Edit";
import { EDIT_INTEGRATIONS_PATH, MANAGE_INTEGRATIONS_PATH } from "./constants";

const VCSIntegrations = () => {
  return (
    <Routes>
      <Route index element={<VCSIntegrationsList />} />
      <Route path={EDIT_INTEGRATIONS_PATH} element={<VCSEditIntegrations />} />
      <Route path={MANAGE_INTEGRATIONS_PATH} element={<VCSCreateIntegrations />} />
    </Routes>
  );
};

export default VCSIntegrations;
