import { memo, useCallback } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { PolicyTemplate } from "types/generated";
import { CreatePolicyFields } from "views/Account/PolicyCreateDrawer/types";

import PoliciesTemplatesListItem from "./Item";

type PoliciesTemplatesVirtualizedListItemProps = {
  items: PolicyTemplate[];
  openCreateDrawer: (defaultValues: Partial<CreatePolicyFields>, templateId: string) => void;
  canManagePolicies: boolean;
  setVisibleTemplatePreviews: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  visibleTemplatePreviews: Record<string, boolean>;
  onShowFullDescription: (policy: PolicyTemplate) => void;
};

const PoliciesTemplatesVirtualizedListItem = (
  props: ListChildComponentProps<
    PoliciesTemplatesVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  const type = data.items[index].type;
  const labels = data.items[index].labels;
  const id = data.items[index].id;
  const name = data.items[index].name;
  const description = data.items[index].description;
  const openCreateDrawerHandler = data.openCreateDrawer;
  const setVisibleTemplatePreviews = data.setVisibleTemplatePreviews;

  const openCreateDrawer = useCallback(
    () =>
      openCreateDrawerHandler(
        {
          type,
          labels: labels?.map((value) => ({ value })) || [],
          name,
          description: description || "",
        },
        id
      ),
    [type, id, name, labels, openCreateDrawerHandler, description]
  );

  const onToggle = useCallback(() => {
    setVisibleTemplatePreviews((prev) => ({ ...prev, [id]: !prev[id] }));
  }, [setVisibleTemplatePreviews, id]);

  return (
    <div style={style}>
      <PoliciesTemplatesListItem
        key={id}
        name={data.items[index].name}
        labels={data.items[index].labels || []}
        type={type}
        description={data.items[index].description}
        body={data.items[index].body}
        openCreateDrawer={openCreateDrawer}
        setRowHeight={handleSetRowHeight}
        canManagePolicies={data.canManagePolicies}
        onToggle={onToggle}
        isCollapsed={!data.visibleTemplatePreviews[id]}
        onShowFullDescription={() => data.onShowFullDescription(data.items[index])}
      />
    </div>
  );
};

export default memo(PoliciesTemplatesVirtualizedListItem, areEqual);
