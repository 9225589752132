import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect } from "react";

import { TadaColored } from "components/icons";
import Box from "ds/components/Box";
import Timestamp from "components/time/Timestamp";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardWidgetsEmptyStateRuns from "../EmptyStateRuns";
import DashboardWidgetsRunTile from "../RunTile";
import DashboardWidgetsRunsSkeleton from "../RunsSkeleton";
import { GetRunsRequiringAttention } from "./types";
import { GET_RUNS_REQUIRING_ATTENTION } from "./gql";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";

// TODO: [dashboard] add limit to query once added to API
const LIMIT = 3;

const DashboardWidgetsRunWorkflow = () => {
  const pollInterval = usePollInterval();

  const { loading, data, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetRunsRequiringAttention>(GET_RUNS_REQUIRING_ATTENTION, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.metrics?.runsRequiringAttention;

  const runs = data?.metrics?.runsRequiringAttention?.slice(0, LIMIT);
  let emptyState;

  const isEmpty = !isLoading && !runs?.length;

  const hasRuns = !!data?.metrics?.hasRuns;

  if (!hasRuns) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns showDocs widget={Widget.RunsThatRequireAttention} />
    );
  }

  if (hasRuns && isEmpty) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns
        caption="All set! Your workflow is up-to-date—no approvals needed!"
        icon={TadaColored}
        widget={Widget.RunsThatRequireAttention}
        onlyCaption
      />
    );
  }

  if (showErrorBanner) {
    return (
      <MissingDataBanner
        text="Couldn’t load runs data. Please try to refresh or come back later. "
        refreshHandler={refetch}
        refreshLoading={loading}
        fullWidth
      />
    );
  }

  if (isLoading) {
    return <DashboardWidgetsRunsSkeleton />;
  }

  return (
    <Box direction="column" gap="medium" fullWidth>
      {emptyState}

      {!emptyState &&
        runs?.map(({ id, title, createdAt, state, stackTile, needsApproval }, i) => (
          <DashboardWidgetsRunTile
            key={i}
            id={id}
            title={title}
            state={state}
            stackTile={stackTile}
            caption={
              <>
                Committed <Timestamp timestamp={createdAt} />
              </>
            }
            needsApproval={needsApproval}
            analyticsTitle="Runs That Require Attention Widget"
          />
        ))}
    </Box>
  );
};

export default DashboardWidgetsRunWorkflow;
