import { memo } from "react";

import { ChevronNew } from "components/icons";
import Button from "ds/components/Button";
import Dropdown from "ds/components/Dropdown";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useTypedFlags from "hooks/useTypedFlags";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackSchedulingContextApi, StackSchedulingContextData } from "../Context";

type StackSchedulingCreateDropdownProps = {
  componentLocation?: "Header" | "Empty state";
};

const StackSchedulingCreateDropdown = ({
  componentLocation,
}: StackSchedulingCreateDropdownProps) => {
  const { scheduledRunsFrontend } = useTypedFlags();
  const { canManageStackAndRuns } = useTypedContext(StackContext);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const { driftDetectionExist } = useTypedContext(StackSchedulingContextData);
  const { onCreate } = useTypedContext(StackSchedulingContextApi);

  const handleCreating = (type: SCHEDULING_TYPE, clb: () => void) => () => {
    onCreate(type);
    clb();
    trackSegmentEvent("Create Schedule Clicked", { type, buttonLocation: componentLocation });
  };

  if (!canManageStackAndRuns) return null;

  return (
    <Dropdown
      renderTriggerComponent={({ onClick, ariaProps }) => (
        <Button
          variant="primary"
          onClick={onClick}
          endIcon={ChevronNew}
          endIconRotate="90"
          {...ariaProps}
        >
          Create schedule
        </Button>
      )}
    >
      {({ closeDropdown }) => (
        <DropdownSection>
          {!driftDetectionExist && (
            <DropdownSectionItem
              onClick={handleCreating(SCHEDULING_TYPE.DRIFT_DETECTION, closeDropdown)}
            >
              Drift detection
            </DropdownSectionItem>
          )}
          <DropdownSectionItem
            onClick={handleCreating(SCHEDULING_TYPE.DELETE_STACK, closeDropdown)}
          >
            Stack deletion
          </DropdownSectionItem>
          <DropdownSectionItem onClick={handleCreating(SCHEDULING_TYPE.TASK, closeDropdown)}>
            Task
          </DropdownSectionItem>
          {scheduledRunsFrontend && (
            <DropdownSectionItem onClick={handleCreating(SCHEDULING_TYPE.RUN, closeDropdown)}>
              Run
            </DropdownSectionItem>
          )}
        </DropdownSection>
      )}
    </Dropdown>
  );
};

export default memo(StackSchedulingCreateDropdown);
