import { partition } from "lodash-es";

import { ConfigElementWithSource, ConfigType } from "types/generated";

/**
 * @returns the list of filtered config elements without the output references
 * @returns the Map of output references per stacks
 */
export const filterOutputReferences = (
  elements: ConfigElementWithSource[]
): [ConfigElementWithSource[], Map<string, ConfigElementWithSource[]>] => {
  if (!elements) return [[], new Map()];

  return elements.reduce<[ConfigElementWithSource[], Map<string, ConfigElementWithSource[]>]>(
    (acc, item) => {
      if (item.stackDependency) {
        const stackName = item.stackDependency.dependsOnStack.name;

        if (!acc[1].has(stackName)) {
          acc[1].set(stackName, []);
        }

        acc[1].get(stackName)!.push(item);
      } else {
        acc[0].push(item);
      }

      return acc;
    },
    [[], new Map()]
  );
};

export const isSpaceliftConfig = (configElement: ConfigElementWithSource) => {
  return configElement.element.runtime && configElement.element.id.startsWith("TF_VAR_spacelift");
};

export const getConfigs = (
  configElements: ConfigElementWithSource[] = []
): {
  variables: ConfigElementWithSource[];
  mountedFiles: ConfigElementWithSource[];
  spaceliftVariables: ConfigElementWithSource[];
  outputReferences: Map<string, ConfigElementWithSource[]>;
} => {
  const [filteredRuntimeConfigElements, outputReferences] = filterOutputReferences(configElements);

  const [allVariables, mountedFiles] = partition(
    filteredRuntimeConfigElements,
    (configElement) => configElement.element.type === ConfigType.EnvironmentVariable
  );

  const [spaceliftVariables, variables] = partition(allVariables, isSpaceliftConfig);

  return {
    variables,
    spaceliftVariables,
    mountedFiles,
    outputReferences,
  };
};
