import { Maybe } from "types/generated";

import DownloadFile from "./DownloadFile";
import ShowWriteOnlyFile from "./ShowWriteOnlyFile";
import styles from "../styles.module.css";

type FileMountProps = {
  relativePath: string;
  value: Maybe<string>;
  writeOnly: boolean;
  previewButtons?: JSX.Element;
};

const FileMount = ({ relativePath, value, writeOnly, previewButtons }: FileMountProps) => (
  <>
    <div className={styles.variableName}>
      <span className={styles.variableText}>/mnt/workspace/</span>
      <span>{relativePath}</span>
    </div>
    <div className={styles.variableValue}>
      {!writeOnly && value && (
        <>
          <DownloadFile fileName={relativePath} base64Value={value} />
          {previewButtons}
        </>
      )}
      {writeOnly && <ShowWriteOnlyFile />}
    </div>
  </>
);

export default FileMount;
