import useLocalStorage from "@rehooks/local-storage";

import Typography from "ds/components/Typography";
import { getDefaultViewStorageKey } from "components/Filters/helpers";
import useTypedContext from "hooks/useTypedContext";
import { FiltersContext } from "components/Filters";

import { FiltersSavedViewsListProps } from "./types";
import FiltersSavedViewsListItem from "./Item";
import FiltersSavedViewsListItemDefault from "./ItemDefault";
import styles from "./styles.module.css";

const FiltersSavedViewsList = ({
  applyView,
  items = [],
  placeHolderText,
  isDefaultViewAlwaysVisible,
}: FiltersSavedViewsListProps) => {
  const { filterType } = useTypedContext(FiltersContext);
  const [defaultViewId] = useLocalStorage<string>(getDefaultViewStorageKey(filterType));
  const isPlaceholderVisible = items.length === 0 && !isDefaultViewAlwaysVisible;

  return (
    <div>
      {isPlaceholderVisible ? (
        <div className={styles.placeholder}>
          <Typography tag="span" variant="p-body3">
            {placeHolderText}
          </Typography>
        </div>
      ) : (
        <ul className={styles.list}>
          <FiltersSavedViewsListItemDefault applyView={applyView} />
          {items
            .filter((item) => item.id !== defaultViewId)
            .map((item) => {
              return <FiltersSavedViewsListItem item={item} applyView={applyView} key={item.id} />;
            })}
        </ul>
      )}
    </div>
  );
};

export default FiltersSavedViewsList;
