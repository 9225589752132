import cx from "classnames";

import { ReactComponent as LogoText } from "./logo-text.svg";
import { ReactComponent as LogoSymbol } from "./logo-symbol.svg";
import styles from "./styles.module.css";
import Box from "../Box";

type SpaceliftLogoProps = {
  short?: boolean;
  className?: string;
};

const SpaceliftLogo = ({ short, className }: SpaceliftLogoProps) => {
  return (
    <Box align="center" className={cx(styles.wrapper, short && styles.short, className)}>
      <div className={styles.logoTextWrapper}>
        <LogoText className={styles.logoText} />
      </div>
      <LogoSymbol className={styles.logoSymbol} />
    </Box>
  );
};

export default SpaceliftLogo;
