import { ReactNode } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import { Info } from "components/icons";
import Box from "ds/components/Box";
import BadgeNext from "ds/components/BadgeNext";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import useTypedContext from "hooks/useTypedContext";
import ListContentWrapper from "components/ListContentWrapper";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { TIER_FREE_PLANS } from "constants/tiers";

import {
  LOGIN_POLICY_USER_MANAGEMENT_INFO_STORAGE_KEY,
  LOGIN_POLICY_INACTIVE_INFO_STORAGE_KEY,
  LOGIN_POLICIES_TRIAL_INFO_KEY,
} from "./constants";
import { UserManagementActivationStatus } from "../types";
import { SettingsContext } from "../Context";

type LoginPoliciesPageLayoutProps = {
  children: ReactNode;
  openCreateDrawer?: () => void;
  openInfoDrawer: () => void;
};

const LoginPoliciesPageLayout = ({
  children,
  openCreateDrawer,
  openInfoDrawer,
}: LoginPoliciesPageLayoutProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { isTrial, tier } = useTypedContext(SubscriptionContext);
  const isFreeTier = !isTrial && TIER_FREE_PLANS.includes(tier);
  const isLoginPolicyActive = activationStatus === UserManagementActivationStatus.INACTIVE;

  return (
    <>
      {isLoginPolicyActive && (
        <Callout
          variant="info"
          title="User management"
          storageKey={LOGIN_POLICY_USER_MANAGEMENT_INFO_STORAGE_KEY}
        >
          We have introduced a new user management feature that can be used instead of login
          policies. This new management strategy provides a simplified way of managing access to
          your Spacelift account.
          <FeedbackActions>
            <Button to="/settings/management-strategy/" variant="contrast" size="small">
              Choose strategy
            </Button>
          </FeedbackActions>
        </Callout>
      )}
      {!isFreeTier && !isLoginPolicyActive && (
        <Callout
          variant="warning"
          title="Login policy is inactive"
          storageKey={LOGIN_POLICY_INACTIVE_INFO_STORAGE_KEY}
        >
          Your login policies won’t work until you change management strategy from user management
          to login policy.
          <FeedbackActions>
            <Button to="/settings/management-strategy/" variant="contrast" size="small">
              Choose strategy
            </Button>
          </FeedbackActions>
        </Callout>
      )}
      {isFreeTier && (
        <Callout variant="danger" storageKey={LOGIN_POLICIES_TRIAL_INFO_KEY}>
          Login policies will not be evaluated for free accounts. Upgrade to one of the paid tiers
          to make use of them.
          <FeedbackActions>
            <Button to="/settings/billing" variant="contrast" size="small">
              Upgrade
            </Button>
          </FeedbackActions>
        </Callout>
      )}
      <ViewHeader firstLevel>
        <Box align="center" __deprecatedGap="1.1rem">
          <ViewHeaderTitle tag="h2">Login policy</ViewHeaderTitle>
          <ButtonIcon onClick={openInfoDrawer} icon={Info} variant="ghost">
            Open info drawer
          </ButtonIcon>
          <BadgeNext
            variant={isLoginPolicyActive ? "green" : "gray"}
            type="strong"
            text={isLoginPolicyActive ? "ACTIVE" : "INACTIVE"}
          />
        </Box>
        <ViewHeaderWrapper direction="row">
          {openCreateDrawer && (
            <Button onClick={openCreateDrawer} variant="primary">
              Create policy
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default LoginPoliciesPageLayout;
