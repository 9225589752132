import { ReactNode } from "react";

import SearchInput from "components/SearchInput";
import DocumentationIconButton from "components/DocumentationIconButton";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import PageInfo from "components/PageWrapper/Info";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { SpacesContext } from "views/Account/SpacesProvider";
import { BillingTierFeature } from "types/generated";
import TierInfo from "components/TierInfo";

import { VCS_INTEGRATIONS_DOCUMENTATION_LINK } from "./constants";
import VCSIntegrationsSetupDropdown from "./SetupDropdown";
import VCSPageHeader from "../../components/PageHeader";
import { VCS_INTEGRATIONS_LIST_FILTERS_KEY } from "../../constants";

const MULTI_VCS_TIER_IFO = "multiVCSTierInfo";

type VCSIntegrationsPageLayoutProps = {
  children: ReactNode;
  isPageEmpty?: boolean;
};

const VCSIntegrationsPageLayout = ({ children, isPageEmpty }: VCSIntegrationsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Source Code Integrations · ${accountName}`);

  return (
    <>
      <VCSPageHeader />

      <PageInfo title="Integrations">
        <SearchInput
          placeholder="Search source code integrations..."
          filtersOrderSettingsKey={VCS_INTEGRATIONS_LIST_FILTERS_KEY}
          disabled={isPageEmpty}
        />

        <DocumentationIconButton
          href={VCS_INTEGRATIONS_DOCUMENTATION_LINK}
          tooltipText="Go to source code integrations documentation"
          analyticsTitle="Docs Click"
          analyticsPage={AnalyticsPageVCS.VCSIntegrations}
          analyticsProps={{ location: "list-page-header" }}
        />

        {hasEntityCreateAccess && <VCSIntegrationsSetupDropdown />}
      </PageInfo>

      <TierInfo
        type="callout"
        variant="promo"
        feature={BillingTierFeature.MultipleVcs}
        title="Concurrent VCS integrations"
        storageKey={MULTI_VCS_TIER_IFO}
      >
        Upgrade your plan to benefit from multiple concurrent VCS integrations of the same type.
      </TierInfo>

      {children}
    </>
  );
};

export default VCSIntegrationsPageLayout;
