import { gql, TypedDocumentNode } from "@apollo/client";

import type { MutationRunReviewArgs, RunReview } from "types/generated";

export type FetchRunReviewData = { runReview: Pick<RunReview, "id" | "timestamp"> };
export const RUN_REVIEW: TypedDocumentNode<FetchRunReviewData, MutationRunReviewArgs> = gql`
  mutation AddRunReview($stack: ID!, $run: ID!, $decision: RunReviewDecision!, $note: String) {
    runReview(stack: $stack, run: $run, decision: $decision, note: $note) {
      id
      timestamp
    }
  }
`;
