import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import { VcsProvider } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

type VcsIntegrationFieldTooltipProps = {
  provider: VcsProvider;
};

const VcsIntegrationFieldTooltip = ({ provider }: VcsIntegrationFieldTooltipProps) => {
  return (
    <>
      <TooltipModalTitle>Integration</TooltipModalTitle>
      <TooltipModalBody align="start">
        {HUMANIZE_PROVIDER[provider]} integration used to access the source code for this stack.
        {/* TODO: update VCS Integration Doc Link */}
        <ReadMoreDocsLink docsUrl={getDocsUrl("/integrations/source-control")} />
      </TooltipModalBody>
    </>
  );
};

export default VcsIntegrationFieldTooltip;
