import { useQuery } from "@apollo/client";
import { Navigate, Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useEffect } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature, Module, RunState } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import { hasSpaceManageAccess } from "utils/user";
import NotFoundPage from "components/error/NotFoundPage";
import TierInfo from "components/TierInfo";

import useLatestModuleVersion from "../useLatestModuleVersion";
import { GET_VERSION } from "./gql";
import { VersionContext } from "./Context";
import VersionMetadataCallout from "./components/MetadataCallout";
import ModuleVersionPageWrapper from "./components/PageWrapper";
import ModuleVersionOverview from "./Overview";
import ModuleVersionMainLayout from "./components/MainLayout";
import ModuleVersionExample from "./Example";
import ModuleVersionExamples from "./Examples";
import ModuleVersionSubmodule from "./Submodule";
import ModuleVersionSubmodules from "./Submodules";
import ModuleVersionRun from "./Run";
import { ModuleContext } from "../Context";

const POLLING_INTERVAL = 5000;

const ModuleVersionNext = () => {
  const { onError } = useTypedContext(FlashContext);
  const { module: moduleCtx } = useTypedContext(ModuleContext);

  const { versionId: versionIdParam, moduleId } = useParams<{
    versionId: string;
    moduleId: string;
  }>();

  const versionId = useLatestModuleVersion(versionIdParam as string, moduleCtx);

  const { data, error, loading, startPolling, stopPolling } = useQuery<{ module: Module }>(
    GET_VERSION,
    {
      onError,
      variables: { id: versionId, moduleId },
    }
  );

  const version = data?.module?.version;

  useEffect(
    () => {
      if (!version?.metadata) {
        startPolling(POLLING_INTERVAL);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [version?.metadataParsingRun?.id]
  );

  useEffect(
    () => {
      if (version?.metadata || version?.metadataParsingRun?.state === RunState.Failed) {
        stopPolling();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [version]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (loading && !version) {
    return <PageLoading />;
  }

  if (!version) {
    return <NotFoundPage />;
  }

  const module = data.module;

  const canManageModule = hasSpaceManageAccess(module.spaceDetails.accessLevel);

  return (
    <VersionContext.Provider value={{ version, module, canManageModule }}>
      <ModuleVersionPageWrapper>
        {module.workerPool && (
          <TierInfo type="callout" variant="danger" feature={BillingTierFeature.PrivateWorkers}>
            This module is set up to use a private worker pool, but the current plan does not
            support this functionality. Jobs will not be processed until you upgrade to a plan that
            supports private workers.
          </TierInfo>
        )}

        {version.metadataParsingRun && <VersionMetadataCallout run={version?.metadataParsingRun} />}

        <Routes>
          <Route index element={<Navigate to="overview" replace />} />

          <Route path="changelog" element={<Navigate to="../overview/changelog" replace />} />
          <Route path="inputs" element={<Navigate to="../overview/inputs" replace />} />
          <Route path="outputs" element={<Navigate to="../overview/outputs" replace />} />
          <Route path="resources" element={<Navigate to="../overview/resources" replace />} />
          <Route path="runs" element={<Navigate to="../overview/tests" replace />} />
          <Route path="consumers" element={<Navigate to="../overview/consumers" replace />} />
          <Route path="dependencies" element={<Navigate to="../overview/dependencies" replace />} />

          <Route element={<ModuleVersionMainLayout />}>
            <Route path="overview/*" element={<ModuleVersionOverview />} />
            <Route path="examples" element={<ModuleVersionExamples />} />
            <Route path="submodules" element={<ModuleVersionSubmodules />} />
          </Route>

          <Route path="example/:exampleId/*" element={<ModuleVersionExample />} />
          <Route path="submodule/:submoduleId/*" element={<ModuleVersionSubmodule />} />
          <Route path="run/:runId" element={<ModuleVersionRun />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ModuleVersionPageWrapper>
    </VersionContext.Provider>
  );
};

export default ModuleVersionNext;
