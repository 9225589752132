import { RunStateTransition } from "types/generated";

import CanceledEntry from "../entries/CanceledEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createCanceledEntry = ({ timestamp, username, stateVersion }: RunStateTransition) => {
  return (
    <CanceledEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      username={username}
    />
  );
};
