import PerformTaskAgainButton from "../PerformTaskAgainButton";
import RetryRunButton from "../RetryRunButton";

type UnfinishedRunActionsProps = {
  stackId: string;
  runId: string;
  canRetry: boolean;
  runRetryBlocker: string | null;
  isTaskRun: boolean;
  isModuleRun: boolean;
  canPerformAgain: boolean;
  runTaskCommand: string | null;
};

const UnfinishedRunActions = ({
  stackId,
  runId,
  canRetry,
  runRetryBlocker,
  isTaskRun,
  isModuleRun,
  canPerformAgain,
  runTaskCommand,
}: UnfinishedRunActionsProps) => (
  <>
    {canPerformAgain && <PerformTaskAgainButton command={runTaskCommand} stackId={stackId} />}
    {(canRetry || (runRetryBlocker && !isTaskRun && !isModuleRun)) && (
      <RetryRunButton runId={runId} stackId={stackId} runRetryBlocker={runRetryBlocker} />
    )}
  </>
);

export default UnfinishedRunActions;
