import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import { getDocsUrl } from "utils/getDocsUrl";

const Documentation = () => (
  <Typography tag="p" variant="p-body2">
    Regardless of which of the supported backends (Terraform, Pulumi etc.) you're setting up your
    stack to use, there are a few common settings that apply to all of them. You'll have a chance to
    define them in the next step:
    <br />
    <br />
    The basic settings are:
    <ul>
      <li>
        whether the stack is{" "}
        <TextLink href={getDocsUrl("/concepts/stack/stack-settings#administrative")}>
          administrative
        </TextLink>
        ;
      </li>
      <li>
        <TextLink href={getDocsUrl("/concepts/worker-pools")}>worker pool</TextLink> to use, if
        applicable (default uses the Spacelift public worker pool); The branch that you specify set
        here is what we called a tracked branch. By default,
      </li>
    </ul>
    The advanced settings are:
    <ul>
      <li>
        whether the changes should{" "}
        <TextLink href={getDocsUrl("/concepts/stack/stack-settings#autodeploy")}>
          automatically deploy
        </TextLink>
        ;
      </li>
      <li>
        whether obsolete tests should be{" "}
        <TextLink href={getDocsUrl("/concepts/stack/stack-settings#autoretry")}>
          automatically retried
        </TextLink>
        ;
      </li>
      <li>whether or not to protect the stack from deletion;</li>
      <li>
        whether or not to enable the local preview{" "}
        <TextLink href="https://github.com/spacelift-io/spacectl">spacectl</TextLink> CLI feature;
      </li>
      <li>
        whether or not{" "}
        <TextLink href={getDocsUrl("/concepts/run/run-promotion")}>run promotion</TextLink> is
        enabled;
      </li>
      <li>optionally specify a custom Docker image to use to for your job container;</li>
    </ul>
  </Typography>
);

export default Documentation;
