import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

const FullScreenModalWrapper = ({
  children,
  direction = "column",
  className,
  ...restProps
}: BoxProps) => {
  return (
    <Box direction={direction} className={cx(styles.wrapper, className)} {...restProps}>
      {children}
    </Box>
  );
};

export default FullScreenModalWrapper;
