import { useQuery } from "@apollo/client";

import { DebugInfo } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import DrawerBody from "ds/components/Drawer/Body";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import { ErrorType } from "types/Error";

import Loader from "./components/Loader";
import { GET_DEBUG_INFO } from "./gql";
import AccountDetailsSaas from "./Saas";
import AccountDetailsSelfHosted from "./SelfHosted";

const isSelfHosted = isSelfHostedDistribution();
const isSaas = isSaasDistribution();

type AccountDetailsDrawerContentProps = {
  onError: (error: ErrorType) => void;
};

const AccountDetailsDrawerContent = ({ onError }: AccountDetailsDrawerContentProps) => {
  const { loading, error, data } = useQuery<{ debugInfo: DebugInfo }>(GET_DEBUG_INFO, {
    onError,
    skip: isSaas,
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }
  if (loading && !data?.debugInfo) {
    return <Loader />;
  }

  return (
    <DrawerBody fullHeight>
      {isSaas && <AccountDetailsSaas />}
      {isSelfHosted && <AccountDetailsSelfHosted debugInfo={data?.debugInfo} />}
    </DrawerBody>
  );
};

export default AccountDetailsDrawerContent;
