import LoadingIndicator, { type LoadingIndicatorProps } from "ds/components/LoadingIndicator";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type PaginationIndicatorProps = {
  currentCount: number;
  totalCount: number;
  loading?: boolean;
  minimumLoadingDuration?: LoadingIndicatorProps["minimumDuration"];
};

const PaginationIndicator = ({
  currentCount,
  totalCount,
  loading,
  minimumLoadingDuration,
}: PaginationIndicatorProps) => {
  return (
    <Typography variant="p-body3" tag="div" className={styles.paginationIndicator}>
      <LoadingIndicator
        loading={loading}
        className={styles.loader}
        minimumDuration={minimumLoadingDuration}
      />
      Showing {currentCount} of {totalCount}
    </Typography>
  );
};

export default PaginationIndicator;
