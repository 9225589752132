import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import useTypedContext from "hooks/useTypedContext";
import { Delta, RunType } from "types/generated";
import DeltaCounts from "components/DeltaCounts";
import Typography from "ds/components/Typography";
import Tab from "ds/components/Tab";
import { isModuleRunType } from "utils/run";

import { runHasChanges } from "../../helpers";
import { RunContext } from "../../Context";

const RunHeaderNonDetailedChangesNav = () => {
  const { run } = useTypedContext(RunContext);

  const isTask = run.type === RunType.Task;
  const isStackRun = !isTask && !isModuleRunType(run.type);
  const hasChanges = !!run.delta && runHasChanges(run.delta);

  return (
    <ViewHeaderNavigation>
      <Tab exact end label="History" to="." v5Compat />

      {isStackRun && !!run.delta && !hasChanges && (
        <Typography variant="p-t6" tag="div" margin="0 medium">
          No changes
        </Typography>
      )}

      {isStackRun && hasChanges && !!run.delta?.addCount && (
        <Tab
          to="changes/add"
          v5Compat
          label="Add"
          suffixComponent={
            <DeltaCounts
              delta={{ addCount: run.delta.addCount } as Delta}
              skipEmpty
              variant="flexible"
              size="small"
            />
          }
        />
      )}

      {isStackRun && hasChanges && !!run.delta?.changeCount && (
        <Tab
          to="changes/change"
          v5Compat
          label="Change"
          suffixComponent={
            <DeltaCounts
              delta={{ changeCount: run.delta.changeCount } as Delta}
              skipEmpty
              variant="flexible"
              size="small"
            />
          }
        />
      )}

      {isStackRun && hasChanges && !!run.delta?.deleteCount && (
        <Tab
          to="changes/delete"
          v5Compat
          label="Delete"
          suffixComponent={
            <DeltaCounts
              delta={{ deleteCount: run.delta.deleteCount } as Delta}
              skipEmpty
              variant="flexible"
              size="small"
            />
          }
        />
      )}
    </ViewHeaderNavigation>
  );
};

export default RunHeaderNonDetailedChangesNav;
