import { NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { StackAwsIntegrationAttachment, StackAzureIntegrationAttachment } from "types/generated";

import { StackCreationCloud } from "../../types";
import { GET_ATTACHED_STACK_INTEGRATIONS } from "../gql";
import { GetAttachedCloudIntegrationsGql } from "../types";
import { StackFormContext } from "../../context";

type AttachedIntegration =
  | {
      attachedIntegration: StackAwsIntegrationAttachment;
      attachedIntegrationType: StackCreationCloud.AWS;
    }
  | {
      attachedIntegration: StackAzureIntegrationAttachment;
      attachedIntegrationType: StackCreationCloud.Azure;
    }
  | {
      attachedIntegration: undefined;
      attachedIntegrationType: undefined;
    };

type UseAttachedIntegration = AttachedIntegration & {
  loading: boolean;
  refetching: boolean;
  hasData: boolean;
  refetch: () => void;
};

const useAttachedCloudIntegration = (): UseAttachedIntegration => {
  const { onError } = useTypedContext(FlashContext);
  const { createdStackId } = useTypedContext(StackFormContext);

  const { loading, data, networkStatus, refetch } = useQuery<GetAttachedCloudIntegrationsGql>(
    GET_ATTACHED_STACK_INTEGRATIONS,
    {
      onError,
      variables: {
        stackId: createdStackId,
      },
      skip: !createdStackId,
      fetchPolicy: "no-cache",
    }
  );

  const integration = useMemo<AttachedIntegration>(() => {
    if (data?.stack?.integrations.awsV2.length) {
      return {
        attachedIntegrationType: StackCreationCloud.AWS,
        attachedIntegration: data.stack.integrations.awsV2[0],
      };
    }

    if (data?.stack?.integrations.azure.length) {
      return {
        attachedIntegrationType: StackCreationCloud.Azure,
        attachedIntegration: data.stack.integrations.azure[0],
      };
    }

    return { attachedIntegrationType: undefined, attachedIntegration: undefined };
  }, [data]);

  return {
    ...integration,
    loading: loading && networkStatus !== NetworkStatus.refetch,
    refetching: loading && networkStatus === NetworkStatus.refetch,
    refetch,
    hasData: !loading && !!data?.stack,
  };
};

export default useAttachedCloudIntegration;
