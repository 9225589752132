import BadgeNext from "ds/components/BadgeNext";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";

import { SettingsContext } from "../Context";
import { UserManagementActivationStatus } from "../types";

const UserManagementActivationBadge = () => {
  const { activationStatus } = useTypedContext(SettingsContext);
  if (activationStatus === UserManagementActivationStatus.ACTIVE) {
    return <BadgeNext variant="green" type="strong" text={activationStatus} />;
  }

  return (
    <Tooltip
      placement="bottom"
      on={(props) => <BadgeNext variant="gray" type="strong" text="INACTIVE" {...props} />}
    >
      Preview mode. Activate in management strategy
    </Tooltip>
  );
};

export default UserManagementActivationBadge;
