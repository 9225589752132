import {
  GitlabIntegration,
  MutationGitlabIntegrationCreateArgs,
  MutationGitlabIntegrationUpdateArgs,
  VcsCheck,
} from "types/generated";
import { generateWebhookSecret } from "utils/webhook";
import { ROOT_SPACE_ID } from "constants/space";

import { ManageGitLabFormFields } from "./types";
import { VCS_INTEGRATION_TYPES } from "../../constants";

export const getFormDefaultValues = (integration?: GitlabIntegration): ManageGitLabFormFields => ({
  name: integration?.name || "",
  space: integration?.space?.id || "",
  labels: integration?.labels?.map((label) => ({ value: label })) || [],
  apiHost: integration?.apiHost || "https://gitlab.com",
  userFacingHost: integration?.userFacingHost || "https://gitlab.com",
  webhookUrl: integration?.webhookUrl || "",
  webhookSecret: integration?.webhookSecret || generateWebhookSecret(),
  description: integration?.description || "",
  privateToken: "",
  integrationType: integration?.isDefault
    ? VCS_INTEGRATION_TYPES.DEFAULT
    : VCS_INTEGRATION_TYPES.MULTIPLE,
  vcsChecks: integration?.vcsChecks || VcsCheck.Individual,
});

export const mapCreateGitLabIntegrationInputValues = (
  values: ManageGitLabFormFields
): MutationGitlabIntegrationCreateArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    apiHost: values.apiHost.trim(),
    customInput: {
      isDefault,
      name: values.name.trim(),
      labels: values.labels.map((label) => label.value) || [],
      // FYI: set the root to be able to convert to the multiple integration from the default one
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      description: values.description?.trim() || null,
      vcsChecks: values.vcsChecks,
    },
    userFacingHost: values.userFacingHost.trim(),
    privateToken: values.privateToken.trim(),
  };
};

export const mapUpdateGitLabIntegrationInputValues = (
  values: ManageGitLabFormFields,
  integrationId: string
): MutationGitlabIntegrationUpdateArgs => ({
  apiHost: values.apiHost.trim(),
  customInput: {
    id: integrationId,
    labels: values.labels.map((label) => label.value) || [],
    space: values.space,
    description: values.description?.trim() || null,
    vcsChecks: values.vcsChecks,
  },
  privateToken: values.privateToken?.trim() || null,
  userFacingHost: values.userFacingHost.trim(),
});
