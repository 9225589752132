import { fromUnixTime, getUnixTime } from "date-fns";
import { getLocalTimeZone } from "@internationalized/date";

import { ScheduledTask, ScheduledTaskInput } from "types/generated";

import { ScheduledTaskFormFields } from "./types";

export const getFormDefaultValues = (
  defaultDateTime: Date,
  integration?: ScheduledTask
): ScheduledTaskFormFields => ({
  command: integration?.command || "",
  recurring: !integration?.timestampSchedule,
  dateTime: integration?.timestampSchedule
    ? fromUnixTime(integration.timestampSchedule)
    : defaultDateTime,
  timezone: integration?.timezone || getLocalTimeZone(),
  cronSchedule: integration?.cronSchedule || ["*/15 * * * *"],
});

export const mapCreateFormData = (values: ScheduledTaskFormFields): ScheduledTaskInput => ({
  command: values.command,
  cronSchedule: values.recurring ? values.cronSchedule : null,
  timezone: values.recurring ? values.timezone : null,
  timestampSchedule: values.recurring ? null : getUnixTime(values.dateTime),
});
