import { gql } from "@apollo/client";

export const ACCOUNT_MFA_SETTINGS = gql`
  query AccountMFASettings {
    hasSSO
    enforceMFA
    securityKeys {
      id
      createdAt
      name
      owner
      currentOwner
    }
    viewer {
      id
      securityKeys {
        id
        createdAt
        name
        owner
      }
    }
  }
`;

export const ACCOUNT_TOGGLE_ENFORCING_MFA = gql`
  mutation AccountToggleEnforcingMFA {
    accountToggleEnforcingMFA
  }
`;
