import Toggle from "ds/components/Toggle";
import Tooltip from "ds/components/Tooltip";

type BulkActionsSelectAllCheckboxProps = {
  allSelected?: boolean;
  onSelectAll?: () => void;
  onResetAll?: () => void;
};

const BulkActionsSelectAllCheckbox = ({
  allSelected,
  onSelectAll,
  onResetAll,
}: BulkActionsSelectAllCheckboxProps) => {
  const handleSelectAllToggle = () => {
    if (!allSelected) {
      onSelectAll?.();
    } else {
      onResetAll?.();
    }
  };

  return (
    <Tooltip
      on={(props) => (
        <Toggle
          {...props}
          variant="checkbox"
          id="select_all_items"
          checked={allSelected}
          onChange={handleSelectAllToggle}
          ariaLabel={allSelected ? "Unselect all items" : "Select all items"}
        />
      )}
    >
      {allSelected ? "Unselect all" : "Select all"}
    </Tooltip>
  );
};

export default BulkActionsSelectAllCheckbox;
