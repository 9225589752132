import { ReactNode } from "react";

import { InfoNew } from "components/icons";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import TooltipModal from "ds/components/TooltipModal";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { TooltipProps } from "ds/components/Tooltip/types";
import { ButtonProps } from "ds/components/Button";

import ButtonIcon from "../ButtonIcon";

export type TooltipInfoVariant = "standard" | "modal";

type TooltipInfoProps = Omit<TooltipProps, "on" | "children"> & {
  children: ReactNode;
  variant?: TooltipInfoVariant;
  widthMode?: TooltipProps["widthMode"];
  iconClassName?: string;
  iconColor?: ButtonProps["variant"];
  mode?: TooltipProps["mode"];
} & AnalyticsCommonProps;

const TooltipInfoFloating = ({
  children,
  iconClassName,
  variant = "standard",
  iconColor = "ghost",
  analyticsTitle,
  analyticsPage,
  analyticsProps,
  widthMode,
  ...tooltipProps
}: TooltipInfoProps) => {
  if (variant === "standard") {
    return (
      <Tooltip
        widthMode={widthMode}
        on={(props) => <Icon {...props} src={InfoNew} className={iconClassName} />}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <TooltipModal
      on={(props, isOpen) => (
        <ButtonIcon
          {...props}
          active={isOpen}
          icon={InfoNew}
          variant={iconColor}
          className={iconClassName}
          analyticsPage={analyticsPage}
          analyticsTitle={analyticsTitle}
          analyticsProps={analyticsProps}
          disableTooltip={isOpen}
        >
          Open more information
        </ButtonIcon>
      )}
      {...tooltipProps}
    >
      {children}
    </TooltipModal>
  );
};

export default TooltipInfoFloating;
