import { useMemo } from "react";

import Filters from "components/Filters";
import Toggle from "ds/components/Toggle";
import { SearchSuggestionsFieldType, SearchSuggestionsOutput } from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersPanel from "components/Filters/FiltersPanel";
import SortDropdown from "components/Filters/SortDropdown";
import Box from "ds/components/Box";
import FiltersSplit from "components/Filters/Split";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";
import ListContentWrapper from "components/ListContentWrapper";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { getFilterKey } from "./helpers";
import {
  FILTERS_ORDER_SETTINGS_KEY,
  initialSortDirection,
  initialSortOption,
  searchStackRunChangesSuggestionsDictionary,
} from "../constants";
import styles from "./styles.module.css";

type FiltersLayoutProps = {
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
  children: React.ReactNode;
  hasItems: boolean;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
  suggestions?: SearchSuggestionsOutput;
  loading: boolean;
  handlePollingActiveSections: (fields: string[]) => void;
  selectable: boolean;
  calloutSlot?: React.ReactNode;
};

const FiltersLayout = ({
  allSelected,
  onSelectAll,
  onResetAll,
  children,
  hasItems,
  currentSavedView,
  setCurrentSavedView,
  suggestions,
  loading,
  handlePollingActiveSections,
  selectable,
  calloutSlot,
}: FiltersLayoutProps) => {
  const cachedFiltersData = useCachedFilterFields(suggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            label: getFilterKey(field.name),
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, filtersItemsOptionsMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        .filter((field) => field.filterable)
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          } else {
            key = getFilterKey(key);
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const handleSelectAllToggle = () => {
    if (!allSelected) {
      onSelectAll();
    } else {
      onResetAll();
    }
  };

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={filtersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      filtersDictionary={searchStackRunChangesSuggestionsDictionary}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="stackRunChanges"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageStack.StacksRunChanges} />

        <FiltersContentWrapper>
          {calloutSlot}

          <FiltersPanel>
            <Box direction="row">
              {hasItems && selectable && (
                <Toggle
                  variant="checkbox"
                  id="select_all"
                  checked={allSelected}
                  onChange={handleSelectAllToggle}
                  ariaLabel={allSelected ? "Unselect all" : "Select all"}
                >
                  Select all
                </Toggle>
              )}
            </Box>

            <SortDropdown />
          </FiltersPanel>

          <ListContentWrapper className={styles.content}>{children}</ListContentWrapper>
        </FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default FiltersLayout;
