import { useBulkUnlockStack } from "shared/Stack/useUnlockStack";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { StackActionItemForBulkActions } from "./types";

const useUnlockBulkAction = (): StackActionItemForBulkActions => {
  const { viewer } = useTypedContext(AccountContext);

  const [stackUnlock] = useBulkUnlockStack();

  return {
    key: StackBulkActions.Unlock,
    title: BULK_ACTION_NAMES[StackBulkActions.Unlock],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Unlock],
    condition: (stack) => canUnlockStack(stack, viewer),
    mutation: (stack) => stackUnlock({ id: stack.id }),
  };
};

export default useUnlockBulkAction;
