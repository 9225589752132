import { useMemo } from "react";
import cx from "classnames";

import CopyField from "components/CopyField";
import { StackOutput } from "types/generated";
import { isJson } from "utils/isJson";
import CodeEditor from "components/CodeEditor";

import styles from "./styles.module.css";

type OutputsItemProps = {
  item: StackOutput;
  onEditorMount?: () => void;
};

const OutputsItem = ({ item, onEditorMount }: OutputsItemProps) => {
  const isValueJson = useMemo(() => item.value && isJson(item.value), [item.value]);
  const value = useMemo(
    () =>
      item.value && isValueJson ? JSON.stringify(JSON.parse(item.value), null, 2) : item.value,
    [item.value, isValueJson]
  );

  const valueChildren = item.sensitive ? "Value for this output is sensitive." : value;

  return (
    <li className={styles.listItem} id={item.id}>
      <div
        className={cx(styles.name, {
          [styles.nameSticky]: !item.sensitive,
          [styles.copyFieldPresent]: !item.sensitive && typeof value === "string",
        })}
      >
        <span>{item.id}</span>
        {!item.sensitive && typeof value === "string" && (
          <CopyField
            fullWidth={false}
            title="Copy Outputs"
            value={value}
            className={styles.copyOutputs}
          />
        )}
      </div>

      <div className={styles.value}>
        <CodeEditor
          body={valueChildren || ""}
          // eslint-disable-next-line
          language={`${item.sensitive ? "plaintext" : isValueJson ? "json" : "shell"}`}
          maxAutoHeight={Infinity}
          options={{
            lineNumbers: "off",
            glyphMargin: false,
          }}
          autoHeight
          readOnly
          onMountCallback={onEditorMount}
          skeletonCount={2}
        />
      </div>
    </li>
  );
};

export default OutputsItem;
