import { useState } from "react";

import FormField from "ds/components/Form/Field";
import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import TagsList from "components/TagsList";
import DescriptionDetails from "components/DescriptionDetails";
import FullDescriptionDrawer from "components/FullDescription/Drawer";

import { ModuleSettingsContext } from "../Context";

const ModuleSettingsDetailsViewForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const [isFullDescriptionDrawerVisible, setIsFullDescriptionDrawerVisible] = useState(false);

  return (
    <>
      <FormFieldViewText label="Name" value={module.id} />
      <FormFieldViewText label="Provider" value={module.terraformProvider} />
      <FormFieldViewText label="Space" value={module.spaceDetails.name} />
      <FormField label="Labels">
        <TagsList tags={module.labels} alwaysExpanded withEmptyPlaceholder withTopMargin={false} />
      </FormField>
      <DescriptionDetails
        description={module.description}
        onOpenFullDescription={() => setIsFullDescriptionDrawerVisible(true)}
      />
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={module.description}
        onCloseDrawer={() => setIsFullDescriptionDrawerVisible(false)}
        position="fixedRight"
      />
    </>
  );
};

export default ModuleSettingsDetailsViewForm;
