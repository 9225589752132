import { BillingTierFeature } from "types/generated";
import { FeedbackType } from "ds/components/Feedback/types";

import TierInfo from ".";

type SharedModulesTierInfoProps = {
  type: FeedbackType;
};

const PrivateProviderRegistryTierInfo = ({ type }: SharedModulesTierInfoProps) => {
  return (
    <TierInfo
      variant="promo"
      feature={BillingTierFeature.TerraformProviderVersions}
      type={type}
      title="Private provider registry is not supported by your plan"
    >
      You may still create providers but they won’t work until you upgrade to tier that supports
      them.
    </TierInfo>
  );
};

export default PrivateProviderRegistryTierInfo;
