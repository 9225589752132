import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import useEnableModule from "shared/Module/useEnableModule";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type EnableButtonProps = {
  moduleId: Module["id"];
  refetchQueries?: string[];
  callback?: () => void;
};

const EnableButton = ({ moduleId, callback, refetchQueries }: EnableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [enableModule, { loading }] = useEnableModule({
    refetchQueries,
  });

  const handleClick = () => {
    enableModule({ id: moduleId }, (data) => {
      if (data?.moduleEnable) {
        reportSuccess({ message: "Module successfully enabled" });
        callback?.();
      }
    });
  };

  return (
    <DropdownMenuItem onAction={handleClick} loading={loading} isDisabled={loading}>
      Enable
    </DropdownMenuItem>
  );
};

export default EnableButton;
