import { PaymentMethod } from "@stripe/stripe-js";
import { createContext } from "react";

type BillingUpgradeContextProps = {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  setPaymentMethod: (paymentMethod: PaymentMethod | null) => void;
  paymentMethod: PaymentMethod | null;
};

export const BillingUpgradeContext = createContext<BillingUpgradeContextProps | undefined>(
  undefined
);
BillingUpgradeContext.displayName = "BillingUpgradeContext";
