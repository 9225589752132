import { gql } from "@apollo/client";

export const SEARCH_WORKER_POOLS = gql`
  query SearchWorkerPools($input: SearchInput!) {
    publicWorkerPool {
      parallelism
      busyWorkers
      schedulableRunsCount
    }
    searchWorkerPools(input: $input) {
      edges {
        cursor
        node {
          id
          name
          description
          spaceDetails {
            id
            name
            accessLevel
          }
          labels
          busyWorkers
          schedulableRunsCount
          usersCount
          workersCount
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_WORKER_POOLS_SUGGESTIONS = gql`
  query SearchWorkerPoolsSuggestions($input: SearchSuggestionsInput!) {
    searchWorkerPoolsSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
      }
    }
  }
`;

export const CREATE_WORKER_POOL = gql`
  mutation CreateWorkerPool(
    $name: String!
    $description: String
    $certificateSigningRequest: String!
    $labels: [String!]
    $space: ID
  ) {
    workerPoolCreate(
      name: $name
      description: $description
      certificateSigningRequest: $certificateSigningRequest
      labels: $labels
      space: $space
    ) {
      config
      workerPool {
        id
        name
      }
    }
  }
`;

export const UPDATE_WORKER_POOL = gql`
  mutation UpdateWorkerPool(
    $id: ID!
    $name: String!
    $description: String
    $labels: [String!]
    $space: ID
  ) {
    workerPoolUpdate(
      id: $id
      name: $name
      description: $description
      labels: $labels
      space: $space
    ) {
      workerPool {
        id
        name
      }
    }
  }
`;

export const DELETE_WORKER_POOL = gql`
  mutation DeleteWorkerPool($workerPoolId: ID!) {
    workerPoolDelete(id: $workerPoolId) {
      id
      name
    }
  }
`;

export const RESET_WORKER_POOL = gql`
  mutation ResetWorkerPool($workerPoolId: ID!, $certificateSigningRequest: String!) {
    workerPoolReset(id: $workerPoolId, certificateSigningRequest: $certificateSigningRequest) {
      config
    }
  }
`;

export const CYCLE_WORKER_POOL = gql`
  mutation CycleWorkerPool($workerPoolId: ID!) {
    workerPoolCycle(id: $workerPoolId)
  }
`;
