import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";

import { VersionInputOption, VERSION_INPUT_TYPE } from "components/FormFields/VersionInput/types";
import { TerragruntTool } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useGetVendorResources from "shared/Stack/Vendor/useGetVendorResources";
import { VENDOR_DICTIONARY, VENDOR_KEY } from "constants/vendor";
import FormFieldVersionInput from "components/FormFields/VersionInput";
import VersionTooltipContent from "components/FormFields/VersionInput/VersionTooltipContent";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import FormField from "ds/components/Form/Field";
import { TERRAGRUNT_TOOL_OPTIONS } from "constants/terragrunt_tools";
import Select from "ds/components/Select";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import CardWrapper from "components/CardWrapper";
import ToggleField from "ds/components/Form/ToggleField";
import { getDocsUrl } from "utils/getDocsUrl";

import {
  VERSION_EMPTY_VALUE,
  getFormDefaultValues,
  getValidateTerragruntVersion,
  getValidateToolVersion,
  mapCreateStackVendorUpdateInput,
} from "./helpers";
import { StackSettingsContextData } from "../../Context";
import StackSettingsFormFooter from "../../components/FormFooter";
import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import { getToolTooltip } from "./getTooltips";

export type StackSettingsTerragruntVendorFormFields = {
  terragruntVersion: VersionInputOption;
  tool: TerragruntTool;
  toolVersion: VersionInputOption;
  useSmartSanitization: boolean;
  useRunAll: boolean;
};

type StackSettingsVendorTerragruntEditProps = StackSettingsVendorComponentBaseProps;

const StackSettingsVendorTerragruntEdit = ({
  onStackUpdate,
  updateLoading,
}: StackSettingsVendorTerragruntEditProps) => {
  const { terragruntVersions, terraformVersions, openTofuVersions, isRefetching, refetch } =
    useGetVendorResources({
      vendorSpecific: VENDOR_KEY.TERRAGRUNT,
    });
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const terragruntVendorForm = useForm<StackSettingsTerragruntVendorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
    control,
    watch,
    setValue,
    trigger,
  } = terragruntVendorForm;

  const watchedTerragruntTool = watch("tool");
  const watchedTerragruntToolVersion = watch("toolVersion");
  const isManualMode = watchedTerragruntTool === TerragruntTool.ManuallyProvisioned;
  const workflowVersions =
    watchedTerragruntTool === TerragruntTool.OpenTofu ? openTofuVersions : terraformVersions;
  const vendorToolName =
    watchedTerragruntTool === TerragruntTool.OpenTofu
      ? VENDOR_DICTIONARY.OPEN_TOFU
      : VENDOR_DICTIONARY.TERRAFORM;

  const handleToolChange = useCallback(
    (onFieldChange: (value: TerragruntTool) => void) => (value: TerragruntTool) => {
      if (watchedTerragruntTool === value) {
        return undefined;
      }

      let versionOption: VersionInputOption = VERSION_EMPTY_VALUE;
      if (value === TerragruntTool.TerraformFoss) {
        versionOption = {
          value: terraformVersions ? terraformVersions[0] : "",
          type: watchedTerragruntToolVersion.type || VERSION_INPUT_TYPE.SPECIFIC,
        };
      } else if (value === TerragruntTool.OpenTofu) {
        versionOption = {
          value: openTofuVersions ? openTofuVersions[0] : "",
          type: watchedTerragruntToolVersion.type || VERSION_INPUT_TYPE.SPECIFIC,
        };
      }

      setValue("toolVersion", versionOption);
      onFieldChange(value);

      trigger();
    },
    [
      openTofuVersions,
      setValue,
      terraformVersions,
      trigger,
      watchedTerragruntTool,
      watchedTerragruntToolVersion?.type,
    ]
  );

  const onSubmit = (formData: StackSettingsTerragruntVendorFormFields) => {
    onStackUpdate(mapCreateStackVendorUpdateInput(formData));
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  const toolController = (
    <Controller
      name="tool"
      control={control}
      render={({ field, fieldState }) => (
        <FormField error={fieldState.error?.message} noMargin label="Tool" {...getToolTooltip()}>
          {({ ariaInputProps }) => (
            <Select
              value={field.value}
              options={TERRAGRUNT_TOOL_OPTIONS}
              onChange={handleToolChange(field.onChange)}
              error={!!fieldState.error?.message}
              ariaInputProps={ariaInputProps}
            />
          )}
        </FormField>
      )}
    />
  );

  const toolVersionController = (
    <Controller
      name="toolVersion"
      control={control}
      rules={{
        validate: getValidateToolVersion(workflowVersions, vendorToolName),
      }}
      render={({ field, fieldState }) => {
        const version = field.value;
        return (
          <FormFieldVersionInput
            reloadLoading={isRefetching}
            reloadVersionsData={refetch}
            title={`${vendorToolName} version`}
            tooltipInfo={
              <>
                <TooltipModalTitle>Choose version</TooltipModalTitle>
                <TooltipModalBody align="start">
                  <VersionTooltipContent />
                  <ReadMoreDocsLink
                    docsUrl={getDocsUrl("/concepts/stack/stack-settings#terraform-version")}
                  />
                </TooltipModalBody>
              </>
            }
            errorMessage={fieldState.error?.message}
            type={version.type}
            version={version.value}
            supportedVersions={workflowVersions}
            onChange={field.onChange}
          />
        );
      }}
    />
  );

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.TERRAGRUNT} />

      <Controller
        name="terragruntVersion"
        control={control}
        rules={{
          validate: getValidateTerragruntVersion(terragruntVersions),
        }}
        render={({ field, fieldState }) => {
          const version = field.value;
          return (
            <FormFieldVersionInput
              title="Terragrunt version"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Choose version</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <VersionTooltipContent />
                  </TooltipModalBody>
                </>
              }
              reloadLoading={isRefetching}
              reloadVersionsData={refetch}
              errorMessage={fieldState.error?.message}
              type={version.type}
              version={version.value}
              supportedVersions={terragruntVersions}
              onChange={field.onChange}
            />
          );
        }}
      />

      {isManualMode && toolController}
      {!isManualMode && (
        <CardWrapper variant="outlined" direction="column" gap="x-large">
          {toolController}
          {toolVersionController}
        </CardWrapper>
      )}

      <Controller
        name="useSmartSanitization"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            align="center"
            onChange={(arg) => {
              field.onChange(arg);
              trigger("toolVersion");
            }}
            checked={field.value}
            title="Smart Sanitization (recommended)"
            description='If set, Spacelift will attempt to sanitize the resources created by terraform using the information provided by terraform as to which fields are "sensitive" or not'
          />
        )}
      />

      <Controller
        name="useRunAll"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            align="center"
            onChange={field.onChange}
            checked={field.value}
            title="Use All Run"
            description={`Enable this property to use Terragrunt's "run-all" capabilities to execute commands across all projects in the workspace.`}
          />
        )}
      />

      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={!isValid || !isDirty || updateLoading}
        submitLoading={updateLoading}
      />
    </>
  );
};

export default StackSettingsVendorTerragruntEdit;
