import { ReactNode, useCallback } from "react";

import { IconComponent } from "types/Icon";
import Tile from "ds/components/Tile";

import { showChangeManagementStrategyConfirmationModal } from "./ConfirmationModal";

type ManagementStrategyItemProps = {
  active: boolean;
  title: string;
  icon: IconComponent;
  children: ReactNode;
  confirmationDescription: ReactNode;
  confirmationCallback: () => void;
};

const ManagementStrategyItem = ({
  active,
  children,
  icon,
  title,
  confirmationDescription,
  confirmationCallback,
}: ManagementStrategyItemProps) => {
  const handleClick = useCallback(() => {
    if (!active) {
      showChangeManagementStrategyConfirmationModal({
        name: title,
        description: confirmationDescription,
        onConfirm: confirmationCallback,
      });
    }
  }, [active, confirmationCallback, confirmationDescription, title]);

  return (
    <Tile
      title={title}
      icon={icon}
      description={children}
      selected={active}
      indicator="radio"
      onClick={handleClick}
    />
  );
};

export default ManagementStrategyItem;
