import Dropdown from "ds/components/Dropdown";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { StackState } from "types/generated";
import StackStateBadge from "components/StackStateBadge";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";

type DropdownBadgeProps = {
  link?: string;
  state: StackState;
  applyFilter: (value: StackState) => void;
};

const DropdownBadge = ({ link, state, applyFilter }: DropdownBadgeProps) => {
  const getHandleOnClick = (callback: () => void) => () => {
    applyFilter(state);
    callback();
  };

  return (
    <Dropdown
      position="bottomRight"
      renderTriggerComponent={({ onClick, ariaProps }) => (
        <StackStateBadge
          className={styles.stackState}
          state={state}
          onClick={onClick}
          withDropdown
          ariaProps={ariaProps}
        />
      )}
    >
      {({ closeDropdown }) => {
        return (
          <DropdownSection>
            {link && <DropdownSectionItem to={link}>Go to run</DropdownSectionItem>}
            <DropdownSectionItem onClick={getHandleOnClick(closeDropdown)}>
              Add to filters
            </DropdownSectionItem>
          </DropdownSection>
        );
      }}
    </Dropdown>
  );
};

export default DropdownBadge;
