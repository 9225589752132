export const getDetailsFromRepo = (repo: string) => {
  const parts = repo.split("-", 3);

  if (parts.length < 3 || parts[0] !== "terraform") {
    return {
      name: repo,
      provider: "",
    };
  }

  const [, provider, ...nameParts] = repo.split("-");

  return {
    name: nameParts.join("-"),
    provider,
  };
};

const isModuleNameValid = (name: string): boolean => /^[a-z][a-z0-9-_]*[a-z0-9]$/.test(name);

export const validateNameField = (value?: string) => {
  if (!value || value.trim() === "") {
    return "Name field is required.";
  }

  if (!isModuleNameValid(value)) {
    return "Name must start and end with lowercase letter and may only contain lowercase letters, digits, dashes and underscores";
  }

  return true;
};
