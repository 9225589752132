import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryPredicate } from "types/generated";

import { AnsibleTaskData, SEARCH_ANSIBLE_TASKS } from "./gql";
import { createAnsibleNodesByTasks } from "./TreeGrid/utils";
import { LayoutMode } from "./TreeGrid/types";

const useSearchAnsibleTasks = (
  input: {
    first: number;
    after: null;
    requestedPage: null;
    fullTextSearch: string;
    predicates: SearchQueryPredicate[];
    orderBy: null;
  },
  layoutMode: LayoutMode,
  skip?: boolean
) => {
  const { onError } = useTypedContext(FlashContext);
  const cachedAnsibleTasksEdges = useRef<AnsibleTaskData[]>([]);
  const location = useLocation();
  const {
    data: tasksData,
    loading: tasksLoading,
    networkStatus: tasksNetworkStatus,
    error: tasksError,
  } = useQuery(SEARCH_ANSIBLE_TASKS, {
    variables: { input },
    onError,
    skip,
  });

  const ansibleTasks = useMemo(() => {
    const sourceEdges = tasksData?.searchAnsibleTasks?.edges.map((edge) => edge.node) || [];
    const edges =
      tasksLoading && !sourceEdges.length ? cachedAnsibleTasksEdges.current : sourceEdges;

    if (!tasksLoading) {
      cachedAnsibleTasksEdges.current = sourceEdges;
    }

    return edges;
  }, [tasksData?.searchAnsibleTasks?.edges, tasksLoading]);

  const nodes = useMemo(() => {
    const isChart = layoutMode === LayoutMode.Diagram;

    return createAnsibleNodesByTasks(ansibleTasks, isChart, location.pathname);
  }, [ansibleTasks, layoutMode, location.pathname]);

  const isTasksPageLoading =
    tasksLoading && !ansibleTasks.length && tasksNetworkStatus === NetworkStatus.loading;

  const response = useMemo(() => {
    return {
      error: tasksError,
      loading: tasksLoading,
      isPageLoading: isTasksPageLoading,
      isPageEmpty: !!(
        tasksData &&
        !ansibleTasks.length &&
        !input.fullTextSearch &&
        input.predicates.length === 0
      ),
      hasNoFilteringResults:
        !!tasksData &&
        !ansibleTasks.length &&
        (!!input.fullTextSearch || input.predicates.length > 0),
    };
  }, [
    ansibleTasks.length,
    input.fullTextSearch,
    input.predicates.length,
    isTasksPageLoading,
    tasksData,
    tasksError,
    tasksLoading,
  ]);

  return {
    nodes,
    predicates: input.predicates,
    ...response,
  };
};

export default useSearchAnsibleTasks;
