import { gql } from "@apollo/client";

export const GET_AUTOATTACHMENTS_FOR_LABELS = gql`
  query GetAutoattachmentsForLabels($space: ID!, $labels: [String!]!) {
    autoattachmentForLabels(space: $space, labels: $labels) {
      count
      sample {
        id
        isModule
        labels
        name
        space
        spaceDetails {
          name
        }
      }
    }
  }
`;
