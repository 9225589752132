import { Module } from "types/generated";

export const getDefaultValues = (module: Module) => {
  return {
    projectRoot: module.projectRoot || "",
    branch: module.branch,
    repositoryURL: module.repositoryURL?.trim() || undefined,
    namespace: module.namespace,
    repository: module.repository,
    provider: module.provider,
    vcsIntegrationId: module.vcsIntegration?.id,
  };
};
