import escapeRegExp from "lodash-es/escapeRegExp";

export const TreeListHighlightLabel = ({ label, query }: { label: string; query?: string }) => {
  if (!query) {
    return <>{label}</>;
  }

  const parts = label.split(new RegExp(`(${escapeRegExp(query)})`, "gi"));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
      )}
    </>
  );
};
