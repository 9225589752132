import { gql } from "@apollo/client";

export const GET_VERSION_EXAMPLE = gql`
  query GetVersionExample($moduleId: ID!, $versionId: ID!) {
    module(id: $moduleId) {
      id
      version(id: $versionId) {
        id
        metadata {
          examples {
            name
            readme
            path
            inputs {
              name
              type
              description
              default
              required
            }
            outputs {
              description
              name
            }
          }
        }
      }
    }
  }
`;
