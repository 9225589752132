import { createContext } from "react";

import { SearchQueryFieldConstraintTimeInLast } from "types/generated";

type NotificationsContextProps = {
  currentDateRange: { start: Date; end: Date; period?: SearchQueryFieldConstraintTimeInLast };
  setCurrentDateRange: (
    dateRange: { start: Date; end: Date },
    period?: SearchQueryFieldConstraintTimeInLast
  ) => void;
};

export const NotificationsContext = createContext<NotificationsContextProps | undefined>(undefined);
NotificationsContext.displayName = "NotificationsContext";
