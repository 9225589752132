import { useRef } from "react";

import PerformTaskButton from "views/shared/run/ActionButtons/PerformTaskButton";
import { Stack } from "types/generated";
import { useTaskForm } from "views/shared/stack/TaskForm/useTaskForm";
import TaskForm from "views/shared/stack/TaskForm";

type NewTaskFormProps = {
  stackId: Stack["id"];
};

const NewTaskForm = ({ stackId }: NewTaskFormProps) => {
  const { skipInitialization, setSkipInitialization, setCommandValue, commandValue } =
    useTaskForm();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleSubmit = () => {
    buttonRef?.current?.click();
  };

  return (
    <TaskForm
      onSubmit={handleSubmit}
      skipInitialization={skipInitialization}
      setSkipInitialization={setSkipInitialization}
      setCommandValue={setCommandValue}
      commandValue={commandValue}
      withBottomMargin
    >
      <PerformTaskButton
        ref={buttonRef}
        disabled={commandValue === ""}
        stackId={stackId}
        command={commandValue.trim()}
        skipInitialization={skipInitialization}
        pill
      >
        Perform
      </PerformTaskButton>
    </TaskForm>
  );
};

export default NewTaskForm;
