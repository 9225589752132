import useLocalStorage from "@rehooks/local-storage";
import { useCallback } from "react";

import { STACK_LIST_VERSION_STORAGE_KEY } from "constants/stack";
import Box from "ds/components/Box";
import ToggleField from "ds/components/Form/ToggleField";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedFlags from "hooks/useTypedFlags";

const STACKS_LIST_SWITCH_ID = "stacks-list-switch";

enum StacksListVersion {
  New = "new",
  Old = "old",
}

const StacksSwitch = () => {
  const { stackListRedesignFrontend } = useTypedFlags();
  const [stacksListVersion, setStacksListVersion] = useLocalStorage(STACK_LIST_VERSION_STORAGE_KEY);

  const trackSegmentAnalyticsEvent = useAnalytics({ page: AnalyticsPageStack.StacksList });

  const switchVersion = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedVersion = e.target.checked ? StacksListVersion.New : StacksListVersion.Old;
      trackSegmentAnalyticsEvent("Stack list version switched", { version: updatedVersion });
      setStacksListVersion(updatedVersion);
    },
    [trackSegmentAnalyticsEvent, setStacksListVersion]
  );

  if (!stackListRedesignFrontend) {
    return null;
  }

  return (
    <Box align="center" padding="0 large 0 0">
      <ToggleField
        id={STACKS_LIST_SWITCH_ID}
        variant="switch"
        checked={stacksListVersion === StacksListVersion.New}
        onChange={switchVersion}
        title="New stack list"
      />
    </Box>
  );
};

export default StacksSwitch;
