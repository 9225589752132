import { gql } from "@apollo/client";

export const GET_STATE_VERSIONS = gql`
  query GetStateVersions($stackId: ID!, $input: SearchInput!) {
    stack(id: $stackId) {
      id
      searchManagedStateVersions(input: $input) {
        edges {
          cursor
          node {
            id
            lastModified
            rollback
            rollbackUsername
            imported
            importedUsername
            importedTimestamp
            run {
              id
              driftDetection
              commit {
                hash
                message
              }
              title
              type
              triggeredBy
            }
            size
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const ROLLBACK_STATE = gql`
  mutation RollbackState($stackId: ID!, $stateVersionId: String!) {
    stackManagedStateRollback(stackId: $stackId, managedStateVersionId: $stateVersionId) {
      id
    }
  }
`;
