import BadgeNext from "ds/components/BadgeNext";
import { PlanPoliciesOutcome, RunPolicyReceipt } from "types/generated";
import Tree from "ds/components/Tree";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunEntryEvaluationProps, RunHistoryEntryType } from "../../types";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";
import Messages from "./Messages";
import HistoryEntryPolicy from "../../components/HistoryEntryPolicy";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type PlanPoliciesEntryProps = RunEntryEvaluationProps & {
  list: RunPolicyReceipt[];
  outcome: PlanPoliciesOutcome | null;
  openListByDefault: boolean;
  isSimpleView: boolean;
  stackId: string;
  runId: string;
  isModuleRun: boolean;
};

const PlanPoliciesEntry = ({
  list,
  outcome,
  openListByDefault,
  isSimpleView,
  evaluationLabel,
  evaluationBadgeVariant,
  evaluationTimestamp,
  stackId,
  runId,
  isModuleRun,
}: PlanPoliciesEntryProps) => {
  const [show, toggle] = useVisibilityToggle(openListByDefault);

  const label = (
    <>
      <HistoryEntryLabel>
        <strong>Plan policies</strong> evaluated to
      </HistoryEntryLabel>
      <BadgeNext
        text={evaluationLabel.toUpperCase()}
        variant={evaluationBadgeVariant}
        type="strong"
      />
    </>
  );

  const outcomeMessages = outcome && <Messages outcome={outcome} />;

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.PlanPolicies}
      timestamp={evaluationTimestamp}
      onClick={toggle}
      isOpen={show}
      label={label}
      terminal
    >
      {show && isSimpleView && outcomeMessages}
      {show && !isSimpleView && (
        <Tree margin="0 0 0 medium">
          {outcomeMessages}

          {list.map((policy) => (
            <HistoryEntryPolicy
              key={policy.id}
              item={policy}
              runId={runId}
              stackId={stackId}
              isModuleRun={isModuleRun}
            />
          ))}
        </Tree>
      )}
    </HistoryEntryWrapper>
  );
};

export default PlanPoliciesEntry;
