import { RunPolicyReceipt } from "types/generated";

import { RunPolicyEvaluation } from "../../types";

export const getPolicyEvaluation = (policies: RunPolicyReceipt[]): RunPolicyEvaluation => {
  const firstRejected = policies.find((policy) => policy.outcome === "reject");

  if (firstRejected) {
    return {
      label: "Rejected",
      badgeVariant: "red",
      timestamp: firstRejected.createdAt,
      isImportant: true,
    };
  }

  const firstUndecided = policies.find((policy) => policy.outcome === "undecided");

  if (firstUndecided) {
    return {
      label: "Undecided",
      badgeVariant: "gray",
      timestamp: firstUndecided.createdAt,
      isImportant: false,
    };
  }

  return {
    label: "Approved",
    badgeVariant: "green",
    timestamp: policies[0].createdAt,
    isImportant: false,
  };
};
