import styles from "./styles.module.css";

type DeltaStripProps = {
  resources: number;
  addCount?: number;
  changeCount?: number;
  deleteCount?: number;
  moveCount?: number;
  importCount?: number;
  forgetCount?: number;
};

const DeltaStrip = ({
  addCount,
  changeCount,
  deleteCount,
  resources,
  moveCount,
  importCount,
  forgetCount,
}: DeltaStripProps) => {
  if (
    typeof addCount !== "number" &&
    typeof changeCount !== "number" &&
    typeof deleteCount !== "number" &&
    typeof moveCount !== "number" &&
    typeof importCount !== "number" &&
    typeof forgetCount !== "number"
  ) {
    return null;
  }

  const style = (count: number) => {
    const width = (
      (count / (resources + (addCount || 0) + (moveCount || 0) + (moveCount || 0))) *
      100
    ).toFixed(2);
    const currentWidth = parseInt(width) > 100 ? "100" : width;

    return {
      width: `${currentWidth}%`,
    };
  };

  return (
    <div className={styles.deltaStrip}>
      {typeof importCount === "number" && (
        <div className={styles.deltaStripImport} style={style(importCount)}></div>
      )}
      {typeof moveCount === "number" && (
        <div className={styles.deltaStripMove} style={style(moveCount)}></div>
      )}
      {typeof forgetCount === "number" && (
        <div className={styles.deltaStripForget} style={style(forgetCount)}></div>
      )}
      {typeof addCount === "number" && (
        <div className={styles.deltaStripAdd} style={style(addCount)}></div>
      )}
      {typeof deleteCount === "number" && (
        <div className={styles.deltaStripDelete} style={style(deleteCount)}></div>
      )}
      {typeof changeCount === "number" && (
        <div className={styles.deltaStripChange} style={style(changeCount)}></div>
      )}
    </div>
  );
};

export default DeltaStrip;
