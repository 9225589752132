import { type ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";

import { BulkActionsDataContext } from "../../Context";
import { BulkActionsStep } from "../../types";

type BulkActionsDrawerActionStepsProps = {
  children: ReactNode;
};

const BulkActionsDrawerActionSteps = ({ children }: BulkActionsDrawerActionStepsProps) => {
  const { bulkActionsStep } = useTypedContext(BulkActionsDataContext);

  if (
    bulkActionsStep === BulkActionsStep.ActionConfirmation ||
    bulkActionsStep === BulkActionsStep.ChooseAction
  ) {
    return <>{children}</>;
  }

  return null;
};

export default BulkActionsDrawerActionSteps;
