import cx from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.css";

type FormParagraphProps = {
  children: ReactNode;
  className?: string;
};

const FormParagraph = ({ children, className }: FormParagraphProps) => {
  return <p className={cx(styles.paragraph, className)}>{children}</p>;
};

export default FormParagraph;
