import { gql } from "@apollo/client";

export const DELETE_INTEGRATION = gql`
  mutation DeleteAzureIntegration($id: ID!) {
    azureIntegrationDelete(id: $id) {
      id
    }
  }
`;

export const CREATE_INTEGRATION = gql`
  mutation CreateAzureIntegration(
    $name: String!
    $tenantId: String!
    $labels: [String!]!
    $defaultSubscriptionId: String
    $space: ID
  ) {
    azureIntegrationCreate(
      name: $name
      tenantID: $tenantId
      labels: $labels
      defaultSubscriptionId: $defaultSubscriptionId
      space: $space
    ) {
      id
    }
  }
`;

export const UPDATE_INTEGRATION = gql`
  mutation UpdateAzureIntegration(
    $id: ID!
    $name: String!
    $labels: [String!]!
    $defaultSubscriptionId: String
    $space: ID
  ) {
    azureIntegrationUpdate(
      id: $id
      name: $name
      labels: $labels
      defaultSubscriptionId: $defaultSubscriptionId
      space: $space
    ) {
      id
    }
  }
`;
