import { gql } from "@apollo/client";

export const GET_VCS_AGENT_POOL = gql`
  query GetVCSAgentPool($vcsAgentPoolId: ID!) {
    vcsAgentPool(id: $vcsAgentPoolId) {
      id
      name
      description
      config
      deleted
      agentConnections {
        id
        metadata
      }
    }
  }
`;

export const RESET_VCS_AGENT_POOL = gql`
  mutation ResetVCSAgentPool($vcsAgentPoolId: ID!) {
    vcsAgentPoolReset(id: $vcsAgentPoolId) {
      id
      name
      config
    }
  }
`;

export const DELETE_VCS_AGENT_POOL = gql`
  mutation DeleteVCSAgentPool($vcsAgentPoolId: ID!) {
    vcsAgentPoolDelete(id: $vcsAgentPoolId) {
      id
      name
    }
  }
`;
