import Typography from "ds/components/Typography";

const Documentation = () => (
  <Typography tag="p" variant="p-body2">
    Attaching contexts to a module allows you to take advantage of shared variables, mounted files
    and hooks. If you attach multiple contexts to a module, they are sorted by priority (lowest
    first) -- this means that values don't need to be unique.
  </Typography>
);

export default Documentation;
