export enum AttachableProjectType {
  STACK = "Stacks",
  MODULE = "Modules",
}

export type AttachableProject = {
  value: string;
  label: string;
  spaceDetails: {
    name: string;
    id: string;
  };
  type: AttachableProjectType;
};
