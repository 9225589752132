import { EntityChangeType, ChangeValue } from "types/generated";

export type RunChangesValueV3 = {
  floatVal: number;
  stringVal: string;
  boolVal: boolean;
} & ChangeValue;

export type RunChangesEntityChangeType = EntityChangeType;

export enum RunChangeValueV3 {
  ChangeValueBoolean = "ChangeValueBoolean",
  ChangeValueFloat = "ChangeValueFloat",
  ChangeValueString = "ChangeValueString",
}

export type EncodedJsonValue = string | number | boolean | undefined;

export type EncodedJsonValueTuple = [before: EncodedJsonValue, after: EncodedJsonValue];

export type JsonLocation = {
  row: number;
  value: EncodedJsonValue | EncodedJsonValueTuple;
};
