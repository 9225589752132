import { forwardRef } from "react";

import Typography, { TypographyProps } from "ds/components/Typography";

type CollapsiblePanelTitleProps = Omit<TypographyProps, "tag" | "variant"> & {
  variant?: TypographyProps["variant"];
};

const CollapsiblePanelTitle = forwardRef(function CollapsiblePanelTitle(
  { children, className, variant = "p-t6", ...rest }: CollapsiblePanelTitleProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <Typography ref={ref} tag="span" variant={variant} className={className} {...rest}>
      {children}
    </Typography>
  );
});

export default CollapsiblePanelTitle;
