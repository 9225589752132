export enum FilterItemKeys {
  NAME = "name",
  ID = "id",
  INHERIT_ENTITIES = "inheritEntities",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.NAME]: "name",
  [FilterItemKeys.ID]: "id",
  [FilterItemKeys.INHERIT_ENTITIES]: "inherit",
};
