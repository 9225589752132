import { RunPolicyReceipt } from "types/generated";

import { RunElementType, RunEntryContext } from "../types";
import { canShowElement } from "./elements";
import NotificationPoliciesEntry from "../entries/NotificationPoliciesEntry";

export const createNotificationPoliciesEntry = (
  list: RunPolicyReceipt[],
  { stackId, runId, isModuleRun }: RunEntryContext
) => {
  const lastNotification = list[list.length - 1];

  return (
    <NotificationPoliciesEntry
      key={lastNotification.id}
      list={list}
      openListByDefault={canShowElement(RunElementType.NotificationPolicies)}
      stackId={stackId}
      runId={runId}
      isModuleRun={isModuleRun}
    />
  );
};
