import { Module } from "types/generated";

export const getDefaultValues = (module: Module) => {
  const sharedAccounts =
    module.sharedAccounts.length === 0
      ? [{ value: "" }]
      : module.sharedAccounts.map((value) => ({ value }));

  return {
    sharedAccounts,
  };
};
