import { ArrowThin } from "components/icons";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";

type VCSIntegrationsBackButtonProps = {
  onClick: () => void;
};

const VCSIntegrationsBackButton = ({ onClick }: VCSIntegrationsBackButtonProps) => {
  return (
    <Button
      variant="secondary"
      startIcon={({ className }) => <Icon src={ArrowThin} rotate="270" className={className} />}
      onClick={onClick}
    >
      Back
    </Button>
  );
};

export default VCSIntegrationsBackButton;
