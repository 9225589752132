import { ICON_SIZE } from "components/TreeChart/GroupNode/contstants";

const ConfigManagementTreeGridLegendPlaybookIcon = () => (
  <svg
    height={ICON_SIZE}
    width={ICON_SIZE}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4669 6.69506C12.4669 6.22562 12.8475 5.84506 13.3169 5.84506H21C21.4694 5.84506 21.85 6.22562 21.85 6.69506C21.85 7.1645 21.4694 7.54506 21 7.54506H13.3169C12.8475 7.54506 12.4669 7.1645 12.4669 6.69506Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4669 16.9391C12.4669 16.4697 12.8475 16.0891 13.3169 16.0891H21C21.4694 16.0891 21.85 16.4697 21.85 16.9391C21.85 17.4086 21.4694 17.7891 21 17.7891H13.3169C12.8475 17.7891 12.4669 17.4086 12.4669 16.9391Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4304 12.9239C10.7624 13.2558 10.7624 13.794 10.4304 14.126L4.70734 19.849L2.39895 17.5406C2.06701 17.2087 2.06701 16.6705 2.39895 16.3386C2.7309 16.0066 3.26909 16.0066 3.60103 16.3386L4.70734 17.4449L9.22833 12.9239C9.56028 12.5919 10.0985 12.5919 10.4304 12.9239Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4304 2.2525C10.7624 2.58444 10.7624 3.12264 10.4304 3.45458L4.70734 9.17766L2.39895 6.86927C2.06701 6.53732 2.06701 5.99913 2.39895 5.66719C2.7309 5.33524 3.26909 5.33524 3.60103 5.66719L4.70734 6.77349L9.22833 2.2525C9.56028 1.92055 10.0985 1.92055 10.4304 2.2525Z"
      fill="currentColor"
    />
  </svg>
);

export default ConfigManagementTreeGridLegendPlaybookIcon;
