import { AttachableVCSIntegrationsOption } from "components/AttachableVCSIntegrationsOptions/types";
import { SelectRenderOption } from "ds/components/Select";
import SelectOption from "ds/components/Select/Option";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";

const SourceCodeIntegrationFieldSelectOption = ({
  value,
  label,
  hasInstallations,
  checked,
  onChange,
  closeSelect,
  searchHighlightResult,
}: AttachableVCSIntegrationsOption & SelectRenderOption) => {
  return (
    <SelectOption
      innerRef={createSelectOptionRef(checked)}
      key={value}
      value={value}
      postfix={hasInstallations === false ? "not installed" : undefined}
      postFixColor={hasInstallations === false ? "warning" : undefined}
      label={label || value}
      checked={checked}
      onChange={onChange}
      closeSelect={closeSelect}
      searchHighlightResult={searchHighlightResult}
      highlightSelected
    />
  );
};

export default SourceCodeIntegrationFieldSelectOption;
