import { useParams } from "react-router-dom-v5-compat";
import { FetchMoreOptions, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import InfiniteLoader from "react-window-infinite-loader";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import { getStacksBackUrl } from "views/Stack/helpers";
import ViewHeader from "components/ViewHeader";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import PageLoading from "components/loading/PageLoading";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Typography from "ds/components/Typography";
import ListEntitiesNew from "components/ListEntitiesNew";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { Webhook } from "components/icons";
import { WebhooksTierInfo } from "components/TierInfo/WebhooksTierInfo";
import HideOnScrollProvider from "components/HideOnScroll/HideOnScrollContext";

import { StackSettingsContextData } from "../../Context";
import { GetStackWebhookDeliveriesGql } from "./types";
import { GET_STACK_WEBHOOK } from "./gql";
import { COLUMN_ORDER } from "./constants";
import StackSettingsIntegrationsWebhookDeliveryListItemVirtualized from "./DeliveryListItem/Virtualized";
import StackSettingsLeaveFeedbackButton from "../../components/LeaveFeedbackButton";

const StackSettingsIntegrationsWebhook = () => {
  const { webhookId } = useParams<{ webhookId: string }>();

  const { onError } = useTypedContext(FlashContext);
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  useTitle(`Recent deliveries · ${stackSettings.name}`);

  useBreadcrumbs(
    [
      {
        title: "Stacks",
        link: getStacksBackUrl(),
      },
      {
        title: stackSettings.name,
        link: `/stack/${stackSettings.id}`,
      },
      {
        title: "Stack settings",
        link: `/stack/${stackSettings.id}/settings/integrations/webhooks`,
      },
      {
        title: "Recent deliveries",
      },
    ],
    [stackSettings.id, stackSettings.name]
  );

  const { loading, data, fetchMore } = useQuery<GetStackWebhookDeliveriesGql>(GET_STACK_WEBHOOK, {
    onError,
    variables: {
      id: webhookId,
      stackId: stackSettings.id,
    },
    nextFetchPolicy: "cache-first",
  });

  const token = data?.stack?.integrations?.webhook?.deliveries?.nextToken;

  const deliveries = useMemo(
    () => data?.stack?.integrations?.webhook?.deliveries?.nodes || [],
    [data?.stack?.integrations?.webhook?.deliveries?.nodes]
  );

  const updateQuery: FetchMoreOptions<GetStackWebhookDeliveriesGql>["updateQuery"] = (
    prev,
    { fetchMoreResult }
  ) => {
    if (!fetchMoreResult) return prev;

    if (
      prev?.stack?.integrations?.webhook?.deliveries?.nodes &&
      fetchMoreResult?.stack?.integrations?.webhook?.deliveries?.nodes
    ) {
      return {
        stack: {
          ...fetchMoreResult.stack,
          integrations: {
            ...fetchMoreResult.stack.integrations,
            webhook: {
              ...fetchMoreResult.stack.integrations.webhook,
              deliveries: {
                ...fetchMoreResult.stack.integrations.webhook.deliveries,
                nextToken: fetchMoreResult.stack.integrations.webhook.deliveries.nextToken,
                nodes: [
                  ...(prev.stack.integrations.webhook.deliveries.nodes || []),
                  ...(fetchMoreResult.stack.integrations.webhook.deliveries.nodes || []),
                ],
              },
            },
          },
        },
      };
    }
    return { stack: { ...prev.stack } };
  };

  const loadMoreItems = useCallback(async () => {
    if (!token || loading) return;
    try {
      await fetchMore({
        variables: { nextToken: token },
        updateQuery,
      });
    } catch (error) {
      onError(error);
    }
  }, [fetchMore, onError, token, loading]);

  const isItemLoaded = (value: number) => value < deliveries.length;

  return (
    <HideOnScrollProvider>
      <ViewHeader>
        <ViewHeaderWrapper>
          <ViewHeaderTitle titleSize="p-t3">Recent deliveries</ViewHeaderTitle>
        </ViewHeaderWrapper>
        <ViewHeaderWrapper>
          <StackSettingsLeaveFeedbackButton />
        </ViewHeaderWrapper>
      </ViewHeader>
      <WebhooksTierInfo />

      {loading && !data && <PageLoading />}

      {deliveries.length > 0 && (
        <>
          <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER}>
            <FiltersSortHeaderStaticColumn>
              <Typography tag="span" variant="p-t6">
                Delivery ID
              </Typography>
            </FiltersSortHeaderStaticColumn>

            <FiltersSortHeaderStaticColumn>
              <Typography tag="span" variant="p-t6">
                Status code
              </Typography>
            </FiltersSortHeaderStaticColumn>
            <FiltersSortHeaderStaticColumn>
              <Typography tag="span" variant="p-t6">
                Delivered at
              </Typography>
            </FiltersSortHeaderStaticColumn>
          </FiltersSortHeaderWrapper>

          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={deliveries.length + 50}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={deliveries.length}
                itemProps={{
                  items: deliveries,
                }}
                virtualizedItem={StackSettingsIntegrationsWebhookDeliveryListItemVirtualized}
                itemKey={(index) => deliveries[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        </>
      )}

      {deliveries.length === 0 && !loading && (
        <Box align="center" justify="center" grow="1" fullWidth>
          <CardWrapper variant="filled" direction="column">
            <EmptyState padding="large" icon={Webhook} title="No deliveries yet" />
          </CardWrapper>
        </Box>
      )}
    </HideOnScrollProvider>
  );
};

export default StackSettingsIntegrationsWebhook;
