import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { ROLLBACK_STATE } from "./gql";

const useStateRollback = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [rollbackState, { loading }] = useMutation(ROLLBACK_STATE, {
    refetchQueries: ["GetStateVersions"],
  });

  const handleRollbackState = useCallback(
    (stackId: string, stateVersionId: string) => {
      rollbackState({ variables: { stackId, stateVersionId } })
        .then(() => {
          reportSuccess({ message: "State is rolled back successfully" });
        })
        .catch(onError);
    },
    [onError, reportSuccess, rollbackState]
  );

  return { rollbackState: handleRollbackState, loading };
};

export default useStateRollback;
