import SystemMessage from "components/SystemMessage";
import { ErrorColored } from "components/icons";
import LinkNew from "ds/components/LinkNew";

const SPACELIFT_SIGNUP_LINK = "https://spacelift.io/free-trial";

const AccountNotFoundError = () => {
  return (
    <SystemMessage
      icon={ErrorColored}
      title="Account not found"
      message={
        <>
          Looks like you don't have account yet.
          <br></br>
          You can <LinkNew href={SPACELIFT_SIGNUP_LINK}>create one</LinkNew>
        </>
      }
    />
  );
};

export default AccountNotFoundError;
