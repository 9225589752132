import ListEntitiesNew from "components/ListEntitiesNew";
import { ManagedUserGroup } from "types/generated";
import Box from "ds/components/Box";

import UsersListVirtualizedItem from "./VirtualizedItem";
import styles from "./styles.module.css";

type IdpGroupMappingListProps = {
  groups: ManagedUserGroup[];
  handleGroupClick: (details: ManagedUserGroup) => unknown;
};

const IdpGroupMappingList = ({ groups, handleGroupClick }: IdpGroupMappingListProps) => {
  return (
    <Box direction="column" grow="1">
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={groups.length}
          itemProps={{
            groups,
            handleGroupClick,
          }}
          virtualizedItem={UsersListVirtualizedItem}
          itemKey={(index) => groups[index].id}
        />
      </div>
    </Box>
  );
};

export default IdpGroupMappingList;
