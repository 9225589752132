import { useCallback, useState } from "react";

import FullScreenModal from "ds/components/FullScreenModal";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import VCSIntegrationsManageGitHubEnterpriseManual from "./Manual";
import useCloseFullScreenModal from "../useCloseFullScreenModal";
import { GitHubEnterpriseIntegrationManageMode } from "./types";
import VCSIntegrationsManageGitHubEnterpriseHeader from "./Header";
import VCSIntegrationsManageGitHubEnterpriseWizard from "./Wizard";

const VCSIntegrationsManageGitHubEnterprise = () => {
  const [isDirty, setIsDirty] = useState(false);
  const [integrationManageMode, setIntegrationManageMode] =
    useState<GitHubEnterpriseIntegrationManageMode>("wizard");

  const { handleOnClose } = useCloseFullScreenModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const modalCloseClickHandler = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    handleOnClose();
    trackSegmentAnalyticsEvent(
      integrationManageMode === "wizard" ? "GitHub Wizard - Exit" : "GitHub Manual - Exit"
    );
  }, [handleOnClose, integrationManageMode, trackSegmentAnalyticsEvent, isDirty]);

  const toggleIsDirty = useCallback((value: boolean) => {
    setIsDirty(value);
  }, []);

  return (
    <FullScreenModal title="Set up GitHub integration" onClose={modalCloseClickHandler}>
      {integrationManageMode === "wizard" && (
        <VCSIntegrationsManageGitHubEnterpriseWizard onIsDirtyChange={toggleIsDirty}>
          <VCSIntegrationsManageGitHubEnterpriseHeader
            integrationManageMode={integrationManageMode}
            setIntegrationManageMode={setIntegrationManageMode}
          />
        </VCSIntegrationsManageGitHubEnterpriseWizard>
      )}

      {integrationManageMode === "manual" && (
        <VCSIntegrationsManageGitHubEnterpriseManual onIsDirtyChange={toggleIsDirty}>
          <VCSIntegrationsManageGitHubEnterpriseHeader
            integrationManageMode={integrationManageMode}
            setIntegrationManageMode={setIntegrationManageMode}
          />
        </VCSIntegrationsManageGitHubEnterpriseManual>
      )}
    </FullScreenModal>
  );
};

export default VCSIntegrationsManageGitHubEnterprise;
