import { RefObject, useEffect } from "react";

const IGNORE_OUTSIDE_CLICK_ATTRIBUTE = "data-ignore-outside-click";

export const ignoreOutsideClick = {
  [IGNORE_OUTSIDE_CLICK_ATTRIBUTE]: true,
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(
  ref: RefObject<HTMLElement>,
  callback: (e: MouseEvent) => void,
  event: "mousedown" | "mouseup" = "mousedown",
  active = true
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (
        typeof target?.closest === "function" &&
        target?.closest(`[${IGNORE_OUTSIDE_CLICK_ATTRIBUTE}]`)
      ) {
        return;
      }

      if (ref.current && !ref.current.contains(target) && target.tagName !== "rect") {
        callback(event);
      }
    }

    if (active) {
      // Bind the event listener
      document.addEventListener(event, handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener(event, handleClickOutside);
      };
    }

    return;
  }, [ref, callback, event, active]);
}

export default useOutsideClick;
