import { type ReactNode } from "react";

import Box from "ds/components/Box";

type BulkActionsFloatingBarStepsProps = {
  children: ReactNode;
};

const BulkActionsFloatingBarSteps = ({ children }: BulkActionsFloatingBarStepsProps) => (
  <Box direction="column" padding="large" gap="large">
    {children}
  </Box>
);

export default BulkActionsFloatingBarSteps;
