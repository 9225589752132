import { useEffect } from "react";
import { ApolloError } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";

import { StackFormContext } from "./context";

const useErrorHandlerNewStack = (error?: ApolloError) => {
  const { setAccountOrAuthErrorContent } = useTypedContext(StackFormContext);

  const AccountOrAuthErrorContent = useErrorHandle(error);

  useEffect(() => {
    if (AccountOrAuthErrorContent) {
      setAccountOrAuthErrorContent(AccountOrAuthErrorContent);
    }
  }, [AccountOrAuthErrorContent, setAccountOrAuthErrorContent]);
};

export default useErrorHandlerNewStack;
