import { useBulkTaskCreate } from "shared/Stack/useTaskCreate";
import { canCreateTask } from "shared/Stack/useTaskCreate/accessValidation";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { RunTaskVariables, StackActionItemForBulkActions, StackMutationVariables } from "./types";

const useRunTaskBulkAction = (): StackActionItemForBulkActions => {
  const [createTask] = useBulkTaskCreate();
  const { viewer } = useTypedContext(AccountContext);

  return {
    key: StackBulkActions.RunTask,
    title: BULK_ACTION_NAMES[StackBulkActions.RunTask],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.RunTask],
    condition: (stack) => canCreateTask(stack, viewer),
    mutation: (stack, mutationVariables) => {
      // TODO: wait for TS will fix Extract for generic union types and then remove the type casting
      const { command, skipInitialization } = mutationVariables as Extract<
        StackMutationVariables,
        RunTaskVariables
      >;
      return createTask({
        stack: stack.id,
        command,
        skipInitialization: !!skipInitialization,
      });
    },
  };
};

export default useRunTaskBulkAction;
