import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DescriptionDetails from "components/DescriptionDetails";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { Policy } from "types/generated";
import { POLICY_TYPES_TITLES } from "constants/policy";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

type PolicyDetailsDrawerProps = {
  onOpenFullDescription: () => void;
  handleCloseDrawer: () => void;
  policy: Policy;
};

const PolicyDetailsDrawer = ({
  onOpenFullDescription,
  handleCloseDrawer,
  policy,
}: PolicyDetailsDrawerProps) => {
  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Policy details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Name" value={policy.name} />
        <FormFieldViewText label="Type" value={POLICY_TYPES_TITLES[policy.type]} noTextEllipsis />

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={policy.spaceDetails.name}
            href={`/spaces/${policy.spaceDetails.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={policy.description}
          onOpenFullDescription={onOpenFullDescription}
        />
        <FormFieldTimestamp timestamp={policy.createdAt} label="Created at" />
        <FormFieldTimestamp timestamp={policy.updatedAt} label="Last updated" />

        {policy.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={policy.labels} alwaysExpanded />
          </FormField>
        )}
      </DrawerBody>
    </>
  );
};

export default PolicyDetailsDrawer;
