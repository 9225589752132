import {
  LogoAnsible,
  LogoCloudFormationColored,
  LogoKubernetesColored,
  LogoOpenTofuColored,
  LogoPulumiColored,
  LogoTerraformColored,
  LogoTerragruntColored,
} from "components/icons";

import { StackCreationVendor } from "../types";

export const vendorConfig = [
  {
    type: StackCreationVendor.Terraform,
    name: "Terraform / OpenTofu",
    logos: [LogoTerraformColored, LogoOpenTofuColored],
  },
  {
    type: StackCreationVendor.Pulumi,
    name: "Pulumi",
    logos: [LogoPulumiColored],
  },
  {
    type: StackCreationVendor.CloudFormation,
    name: "CloudFormation",
    logos: [LogoCloudFormationColored],
  },
  {
    type: StackCreationVendor.Kubernetes,
    name: "Kubernetes",
    logos: [LogoKubernetesColored],
  },
  {
    type: StackCreationVendor.Terragrunt,
    name: "Terragrunt (Beta)",
    logos: [LogoTerragruntColored],
  },
  {
    type: StackCreationVendor.Ansible,
    name: "Ansible",
    logos: [LogoAnsible],
  },
];
