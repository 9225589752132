import { NetworkStatus, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import { uniqByKey } from "utils/uniq";

import { GET_STATE_VERSIONS } from "./gql";
import { ITEMS_LIMIT } from "./constants";
import { StackContext } from "../Context";

type ResponseData = {
  stack: {
    id: Stack["id"];
    searchManagedStateVersions: Stack["searchManagedStateVersions"];
  };
};

const useGetStateVersions = () => {
  const { onError } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const { data, error, loading, networkStatus, previousData, fetchMore } = useQuery<ResponseData>(
    GET_STATE_VERSIONS,
    {
      variables: {
        stackId: stack.id,
        input: { first: ITEMS_LIMIT, after: null, orderBy: null },
      },
      onError,
      // avoid requesting initial query while fetchMore
      nextFetchPolicy: "cache-first",
    }
  );

  const managedStateVersions = useMemo(() => {
    return (
      data?.stack.searchManagedStateVersions?.edges ||
      previousData?.stack.searchManagedStateVersions?.edges ||
      []
    ).map((edge) => edge.node);
  }, [
    data?.stack.searchManagedStateVersions?.edges,
    previousData?.stack.searchManagedStateVersions?.edges,
  ]);

  const loadMoreItems = useCallback(async () => {
    try {
      if (
        data?.stack.searchManagedStateVersions?.pageInfo.endCursor &&
        data?.stack.searchManagedStateVersions?.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult?.stack.searchManagedStateVersions &&
              fetchMoreResult.stack.searchManagedStateVersions.edges.length > 0
            ) {
              return {
                stack: {
                  ...prev.stack,
                  ...fetchMoreResult.stack,
                  searchManagedStateVersions: {
                    ...fetchMoreResult.stack.searchManagedStateVersions,
                    edges: uniqByKey(
                      [
                        ...(prev.stack.searchManagedStateVersions?.edges || []),
                        ...fetchMoreResult.stack.searchManagedStateVersions.edges,
                      ],
                      "cursor"
                    ),
                  },
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data.stack.searchManagedStateVersions.pageInfo.endCursor,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  }, [
    data?.stack.searchManagedStateVersions?.pageInfo.endCursor,
    data?.stack.searchManagedStateVersions?.pageInfo.hasNextPage,
    fetchMore,
    onError,
  ]);

  return {
    managedStateVersions,
    error,
    isPageLoading: loading && !data?.stack && networkStatus === NetworkStatus.loading,
    isPageEmpty: !loading && !error && managedStateVersions.length === 0,
    isPageNotFound: !loading && !error && !data?.stack,
    hasNextPage: !!data?.stack.searchManagedStateVersions?.pageInfo?.hasNextPage,
    loadMoreItems,
  };
};

export default useGetStateVersions;
