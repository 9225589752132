import get from "lodash-es/get";

import { SearchQueryOrderDirection } from "types/generated";

export const sortByField = <T>(
  arr: T[],
  field: string,
  direction: SearchQueryOrderDirection
): T[] => {
  return arr.slice().sort((a, b) => {
    const aValue = get(a, field);
    const bValue = get(b, field);

    if (typeof aValue === "string" && typeof bValue === "string") {
      const comparison = aValue.localeCompare(bValue);
      if (comparison !== 0) {
        return direction === SearchQueryOrderDirection.Asc ? comparison : -comparison;
      }
    } else {
      if (aValue < bValue) {
        return direction === SearchQueryOrderDirection.Asc ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === SearchQueryOrderDirection.Asc ? 1 : -1;
      }
    }

    return 0;
  });
};
