import React from "react";
import cx from "classnames";

import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import BaseAction, { BaseActionProps } from "../BaseAction";
import Icon from "../Icon";

type ListItemActionProps = BaseActionProps & {
  icon?: IconComponent;
  active?: boolean;
  innerRef?: React.RefObject<HTMLElement> | React.RefCallback<HTMLElement>;
  children?: React.ReactNode;
};

const ListItemAction = (props: ListItemActionProps) => {
  const { children, icon, className, innerRef, active, ...restProps } = props;

  return (
    <BaseAction
      {...restProps}
      className={cx(
        styles.listItemAction,
        {
          [styles.active]: active,
        },
        className
      )}
      ref={innerRef as React.RefObject<HTMLAnchorElement>}
    >
      {icon && <Icon src={icon} size="large" noShrink />}

      {children}
    </BaseAction>
  );
};

export default ListItemAction;
