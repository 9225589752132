import fuzzysort from "fuzzysort";
import escapeRegExp from "lodash-es/escapeRegExp";
import keyBy from "lodash-es/keyBy";

type EntityBase = {
  name?: string;
  id: string;
  labels?: string[];
  starred?: boolean;
};
type NormalizedObject = { name?: string; id: string; labels: string };
type SearchConfig<T> = {
  keys: (keyof T)[];
  scoreThreshold: number;
};
export type FuzzyMatch = Set<number>;

export const fuzzySearch = <T extends EntityBase>(
  objects: T[],
  phrase: string,
  config: SearchConfig<T>
): T[] => {
  const fuzzyKeys = config.keys as string[];

  const objectsById = keyBy(objects, "id");

  const normalizedObjects = objects.map((props) => {
    const basedOnSearchKeys = config.keys.reduce<Record<string, unknown>>((acc, key) => {
      acc[key as string] = props[key];

      return acc;
    }, {});

    return {
      ...basedOnSearchKeys,
      name: props.name,
      id: props.id,
      labels: (props.labels || []).join(" "),
    };
  });

  const foundData = phrase.split(" ").reduce<NormalizedObject[]>((acc, part) => {
    if (part.trim().length > 0) {
      const foundList = fuzzysort.go(part.trim(), acc, {
        allowTypo: true,
        keys: fuzzyKeys,
      });

      return foundList.filter((item) => item.score > config.scoreThreshold).map((item) => item.obj);
    }

    return acc;
  }, normalizedObjects);

  return foundData.map((item) => objectsById[item.id]);
};

export const getAutocompleteHighlights = (
  label: string | undefined,
  searchQuery: string | undefined
) => {
  if (!label || !searchQuery) return;

  const regex = new RegExp(escapeRegExp(searchQuery), "i");

  if (!regex.test(label)) return;

  return fuzzysort.single(searchQuery, label) || undefined;
};
