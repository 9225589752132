import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";

import { BulkActionsDataContext } from "../Context";
import { BulkActionsStep } from "../types";

type BulkActionsConfirmActionStepProps = {
  children: ReactNode;
};

const BulkActionsConfirmActionStep = ({ children }: BulkActionsConfirmActionStepProps) => {
  const { bulkActionsStep } = useTypedContext(BulkActionsDataContext);

  if (bulkActionsStep !== BulkActionsStep.ActionConfirmation) {
    return null;
  }
  return <>{children}</>;
};

export default BulkActionsConfirmActionStep;
