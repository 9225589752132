import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Button from "ds/components/Button";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Markdown from "components/markdown/Markdown";

type ModuleVersionMarkedAsBadNoteDrawerProps = {
  note: string | null;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
};

const ModuleVersionMarkedAsBadNoteDrawer = ({
  note,
  isDrawerVisible,
  setDrawerVisibility,
}: ModuleVersionMarkedAsBadNoteDrawerProps) => {
  const handleCloseDrawer = () => setDrawerVisibility(false);

  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer} variant="wide">
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Note" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody>
        <Markdown markup={note || ""} />
      </DrawerBody>
      <DrawerFooter>
        <DrawerFooterActions>
          <Button variant="secondary" onClick={handleCloseDrawer}>
            Cancel
          </Button>
        </DrawerFooterActions>
      </DrawerFooter>
    </Drawer>
  );
};

export default ModuleVersionMarkedAsBadNoteDrawer;
