import { useCallback, useEffect } from "react";
import { UseFormSetError } from "react-hook-form";
import debounce from "lodash-es/debounce";
import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useURLParams from "hooks/useURLParams";

import { StackDetailsFormFields } from "../types";
import { SEARCH_STACK_NAME_SUGGESTIONS } from "./gql";
import { SearchStackNameSuggestionsGql } from "./types";
import { StackFormContext } from "../context";

const DEBOUNCE_TIMEOUT = 300;

const useAsyncValidation = (
  setError: UseFormSetError<StackDetailsFormFields>,
  prevName?: string
) => {
  const urlParams = useURLParams();
  const hasNotCopiedConfiguration = urlParams.size === 0;
  const { setAsyncValidationLoading, createdStackId } = useTypedContext(StackFormContext);
  const { onError } = useTypedContext(FlashContext);

  const { refetch: loadSearchStacksSuggestions } = useQuery<SearchStackNameSuggestionsGql>(
    SEARCH_STACK_NAME_SUGGESTIONS,
    {
      onError,
      skip: true,
      fetchPolicy: "no-cache",
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateName = useCallback(
    debounce(async (name: string) => {
      if (prevName === name && (hasNotCopiedConfiguration || createdStackId)) {
        return;
      }

      try {
        setAsyncValidationLoading(true);
        const response = await loadSearchStacksSuggestions({
          input: {
            fullTextSearch: name,
            predicates: [],
            fields: ["name"],
          },
        });

        const possibleValues =
          response?.data.searchStacksSuggestions.fields[0].possibleValuesString;

        if (
          possibleValues?.counts?.length &&
          possibleValues.values.some((item) => item.toLowerCase() === name.trim().toLowerCase())
        ) {
          return setError("name", {
            type: "custom",
            message: "Stack with this name already exists",
          });
        }
      } catch (error) {
        onError(error);
      } finally {
        setAsyncValidationLoading(false);
      }
    }, DEBOUNCE_TIMEOUT),
    [loadSearchStacksSuggestions, setError, onError]
  );

  useEffect(() => () => setAsyncValidationLoading(false), [setAsyncValidationLoading]);

  return { validateName };
};

export default useAsyncValidation;
