import { useEffect } from "react";

import useTypedContext from "hooks/useTypedContext";
import { initBeamer, createPlainDataLayer, setSegmentGroup, initPylonChat } from "shared/Analytics";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { AccountContext } from "views/AccountWrapper";
import { ThemeContext } from "views/Theme";

const BEAMER_ID = process.env.REACT_APP_BEAMER_ID;

type ThirdPartyLibsWrapperProps = {
  children: React.ReactNode;
};

const ThirdPartyLibsWrapper = ({ children }: ThirdPartyLibsWrapperProps) => {
  const { accountId, viewer } = useTypedContext(AccountContext);
  const { tier, isTrial } = useTypedContext(SubscriptionContext);
  const { currentTheme } = useTypedContext(ThemeContext);

  useEffect(() => {
    if (BEAMER_ID) {
      // initBeamer checks if script is already initialized, no updates
      initBeamer({ beamerId: BEAMER_ID, viewer, tier, isTrial, theme: currentTheme });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initPylonChat({
      email: viewer.email,
      name: viewer.name,
      avatar: viewer.avatarURL,
    });
  }, [viewer]);

  useEffect(() => {
    // TODO: check if we still need this
    createPlainDataLayer({ account_status: isTrial ? "trial" : "paid" });
  }, [isTrial]);

  useEffect(() => {
    setSegmentGroup();
  }, [tier, isTrial, accountId, viewer?.id]);

  return <>{children}</>;
};

export default ThirdPartyLibsWrapper;
