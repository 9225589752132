import FormFieldViewBoolean from "components/FormFields/ViewBoolean";
import FormFieldViewText from "components/FormFields/ViewText";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import FormField from "ds/components/Form/Field";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import { WebhooksIntegration } from "types/generated";

type StackSettingsIntegrationsWebhooksDetailsProps = {
  webhook: WebhooksIntegration;
  onCloseDrawer: () => void;
};

const StackSettingsIntegrationsWebhooksDetails = ({
  webhook,
  onCloseDrawer,
}: StackSettingsIntegrationsWebhooksDetailsProps) => {
  const handleCancel = () => {
    onCloseDrawer();
  };

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Details" />
        <DrawerCloseIcon handleCloseDrawer={onCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <FormFieldViewText label="Endpoint" value={webhook.endpoint} />
        <FormField label="Secret">
          <SecretFieldDetails secret={webhook.secret} />
        </FormField>
        <FormFieldViewBoolean label="Enabled" value={webhook.enabled} />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default StackSettingsIntegrationsWebhooksDetails;
