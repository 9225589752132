import { Fragment, useId } from "react";
import { toKeyName } from "is-hotkey";

import BaseAction from "ds/components/BaseAction";
import Typography from "ds/components/Typography";
import { isMacLike } from "utils/navigator";

import { RunShortcut } from "../../types";
import styles from "./styles.module.css";

const keySymbols: Record<string, string> = {
  shift: "⇧",
  control: isMacLike ? "⌃" : "Ctrl",
  alt: isMacLike ? "⌥" : "Alt",
  meta: "⌘",
  enter: "↵",
  arrowleft: "←",
  arrowright: "→",
};

type NavigationBarActionProps = RunShortcut;

const NavigationBarAction = ({ callback, code, label, loading }: NavigationBarActionProps) => {
  const labelId = useId();
  const keys = code.split("+");

  return (
    <BaseAction
      className={styles.shortcut}
      onClick={callback}
      disabled={loading}
      aria-labelledby={labelId}
    >
      <span className={styles.code} aria-hidden>
        {keys.map((key, index) => {
          const keyName = toKeyName(key);
          const keySymbol = keySymbols[keyName] || keyName;

          return (
            <Fragment key={key}>
              <Typography tag="kbd" variant="p-body4" color="primary" className={styles.key}>
                {keySymbol}
              </Typography>
              {index < keys.length - 1 && (
                <Typography tag="span" variant="p-body4" color="secondary">
                  +
                </Typography>
              )}
            </Fragment>
          );
        })}
      </span>
      <Typography tag="span" variant="p-body4" color="secondary" id={labelId}>
        {label}
      </Typography>
    </BaseAction>
  );
};

export default NavigationBarAction;
