import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationRunConfirmArgs, Run } from "types/generated";

export type FetchRunConfirmData = { runConfirm: Pick<Run, "id" | "type"> };
export const RUN_CONFIRM: TypedDocumentNode<FetchRunConfirmData, MutationRunConfirmArgs> = gql`
  mutation RunConfirm($stack: ID!, $run: ID, $note: String) {
    runConfirm(stack: $stack, run: $run, note: $note) {
      id
      type
    }
  }
`;
