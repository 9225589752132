import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import { PUBLIC_WORKER_POOL_NAME } from "views/Account/WorkerPool/constants";
import WorkerPoolUsedByView from "views/Account/WorkerPool/UsedByView";

import useSearchPublicWorkerPoolUsedBy from "./useSearchPublicWorkerPoolUsedBy";
import useSearchPublicWorkerPoolUsedBySuggestions from "./useSearchPublicWorkerPoolUsedBySuggestions";

const PublicWorkerPoolUsedBy = () => {
  const {
    publicWorkerPool,
    entities,
    isPageRefetching,
    error,
    stopPolling,
    loadMoreItems,
    isPageEmpty,
    hasNoFilteringResults,
  } = useSearchPublicWorkerPoolUsedBy();

  const { sortOptions } = useSearchPublicWorkerPoolUsedBySuggestions();

  useTitle(`Used by · ${PUBLIC_WORKER_POOL_NAME}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  return (
    <WorkerPoolUsedByView
      isPublic={true}
      entities={entities}
      loadingIndication={isPageRefetching}
      loadMoreEntities={loadMoreItems}
      isPageEmpty={isPageEmpty}
      hasNoFilteringResults={hasNoFilteringResults}
      sortOptions={sortOptions}
      totalUsersCount={publicWorkerPool?.usersCount || 0}
    />
  );
};

export default PublicWorkerPoolUsedBy;
