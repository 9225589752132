import { FeatureFlagEvaluation } from "types/generated";
import { selfHostedFlags } from "views/flags";

export const queryFlagsVariable = Object.entries(selfHostedFlags).map<FeatureFlagEvaluation>(
  ([flag, defaultValue]) => {
    if (typeof defaultValue === "boolean") {
      return {
        boolEvaluation: {
          name: flag,
          defaultValue,
        },
        intEvaluation: null,
      };
    }

    if (typeof defaultValue === "number") {
      return {
        boolEvaluation: null,
        intEvaluation: {
          name: flag,
          defaultValue,
        },
      };
    }

    return { boolEvaluation: null, intEvaluation: null };
  }
);
