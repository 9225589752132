import { ReactNode, useState } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import { Info } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import ListContentWrapper from "components/ListContentWrapper";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import UserManagementWarningCallout from "../UserManagementWarningCallout";
import IdpGroupMappingMapGroupDrawer from "./MapGroupDrawer";
import UserManagementActivationBadge from "../UserManagementActivationBadge";
import IdpGroupMappingInfoDrawer from "./InfoDrawer";
import { getManagementStrategy } from "../helpers";
import { SettingsContext } from "../Context";

type IdpGroupMappingPageLayoutProps = {
  children: ReactNode;
};

const IdpGroupMappingPageLayout = ({ children }: IdpGroupMappingPageLayoutProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);
  const [isMapIdpGroupDrawerVisible, setMapIdpGroupDrawerVisibility] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    setIsInfoDrawerVisible(true);
  };

  const openMapIdpGroupDrawer = () => {
    trackSegmentAnalyticsEvent("Open map IdP group");
    setMapIdpGroupDrawerVisibility(true);
  };

  return (
    <>
      <UserManagementWarningCallout
        analyticsPage={AnalyticsPageOrganization.OrganizationIdpGroupMapping}
      />
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <Box direction="column">
            <Box align="center" __deprecatedGap="1.1rem">
              <ViewHeaderTitle tag="h2">IdP group mapping</ViewHeaderTitle>
              <ButtonIcon onClick={openInfoDrawer} icon={Info} variant="ghost">
                Open info drawer
              </ButtonIcon>
              <UserManagementActivationBadge />
            </Box>
          </Box>
          <ViewHeaderWrapper direction="row">
            <Button onClick={openMapIdpGroupDrawer} variant="primary">
              Map IdP group
            </Button>
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
      </ViewHeader>
      <IdpGroupMappingMapGroupDrawer
        isDrawerVisible={isMapIdpGroupDrawerVisible}
        setDrawerVisibility={setMapIdpGroupDrawerVisibility}
      />
      <IdpGroupMappingInfoDrawer
        isDrawerVisible={isInfoDrawerVisible}
        handleCloseDrawer={() => setIsInfoDrawerVisible(false)}
      />
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default IdpGroupMappingPageLayout;
