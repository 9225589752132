import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { RunsColored } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import { IconComponent } from "types/Icon";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

import { Widget } from "../types";

type DashboardWidgetsEmptyStateRunsProps = {
  title?: string;
  caption?: string;
  onlyCaption?: boolean;
  showDocs?: boolean;
  icon?: IconComponent;
  widget?: Widget;
};

const DashboardWidgetsEmptyStateRuns = ({
  title,
  icon,
  caption,
  onlyCaption,
  showDocs,
  widget,
}: DashboardWidgetsEmptyStateRunsProps) => {
  const finalTitle = title || "You don't have any runs yet";
  const finalCaption = caption || "Go to one of your stacks and trigger your first run.";

  const finalIcon = icon || RunsColored;

  return (
    <TileWrapper>
      <Box padding="small" fullWidth justify="center">
        <EmptyState
          title={onlyCaption ? undefined : finalTitle}
          icon={finalIcon}
          caption={finalCaption}
        >
          {showDocs && (
            <Box gap="medium">
              <DocumentationButton
                analyticsPage={AnalyticsPageDashboard.Dashboard}
                analyticsTitle="Widget - Documentation Clicked"
                analyticsProps={{ widget }}
                to={getDocsUrl("/concepts/run")}
                label="Documentation"
              />
              {/* TODO: [dashboard] userflow trigger */}
              {/* <Button variant="primary">Show me how</Button> */}
            </Box>
          )}
        </EmptyState>
      </Box>
    </TileWrapper>
  );
};

export default DashboardWidgetsEmptyStateRuns;
