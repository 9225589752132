import {
  AwsIntegration,
  AzureIntegration,
  StackAwsIntegrationAttachment,
  StackAzureIntegrationAttachment,
} from "types/generated";

export enum CLOUD_INTEGRATIONS {
  AWS = "AWS",
  Azure = "Azure",
  GCP = "GCP",
}

export type CloudIntegrationAttachment =
  | StackAwsIntegrationAttachment
  | StackAzureIntegrationAttachment;

export type GetAttachableCloudIntegrationsGql = {
  attachableAzureIntegrationsInSpace: AzureIntegration[];
  attachableAwsIntegrationsInSpace: AwsIntegration[];
};
