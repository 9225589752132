import { gql } from "@apollo/client";

export const GET_ATTACHABLE_CLOUD_INTEGRATIONS_IN_SPACE = gql`
  query GetAttachableCloudIntegrationsInSpace($spaceId: ID!) {
    attachableAzureIntegrationsInSpace(id: $spaceId) {
      id
      name
      defaultSubscriptionId
    }
    attachableAwsIntegrationsInSpace(id: $spaceId) {
      id
      name
      legacy
    }
  }
`;

export const GET_EXTERNAL_ID_FOR_ENTITY = gql`
  query GetExternalCloudIntegrationIdForEntity(
    $integrationId: ID!
    $entityId: ID!
    $read: Boolean!
    $write: Boolean!
  ) {
    awsIntegration(id: $integrationId) {
      id
      externalIdForStack(stackId: $entityId, read: $read, write: $write) {
        externalId
        assumeRolePolicyStatement
      }
    }
  }
`;

export const ATTACH_AWS_CLOUD_INTEGRATION = gql`
  mutation AttachAwsCloudIntegration(
    $integrationId: ID!
    $entityId: ID!
    $read: Boolean!
    $write: Boolean!
  ) {
    awsIntegrationAttach(id: $integrationId, stack: $entityId, read: $read, write: $write) {
      id
    }
  }
`;

export const ATTACH_AZURE_CLOUD_INTEGRATION = gql`
  mutation AttachAzureCloudIntegration(
    $integrationId: ID!
    $entityId: ID!
    $read: Boolean!
    $write: Boolean!
    $subscriptionId: String
  ) {
    azureIntegrationAttach(
      id: $integrationId
      stack: $entityId
      read: $read
      write: $write
      subscriptionId: $subscriptionId
    ) {
      id
    }
  }
`;

export const ATTACH_GCP_CLOUD_INTEGRATION = gql`
  mutation AttachGcpCloudIntegration($entityId: ID!, $scopes: [String!]!) {
    stackIntegrationGcpCreate(id: $entityId, tokenScopes: $scopes) {
      activated
    }
  }
`;

export const DETACH_AWS_CLOUD_INTEGRATION = gql`
  mutation DetachAwsCloudIntegration($attachmentId: ID!) {
    awsIntegrationDetach(id: $attachmentId) {
      id
    }
  }
`;

export const DETACH_AZURE_CLOUD_INTEGRATION = gql`
  mutation NewModuleDetachAzureCloudIntegration($attachmentId: ID!) {
    azureIntegrationDetach(id: $attachmentId) {
      id
    }
  }
`;

export const DETACH_GCP_CLOUD_INTEGRATION = gql`
  mutation NewModuleDetachGcpCloudIntegration($entityId: ID!) {
    stackIntegrationGcpDelete(id: $entityId) {
      activated
    }
  }
`;
