import { getApolloClient } from "apollo/client";

import { GROUP_USER_EVENT } from "./gql";

export const groupUserEvent = () => {
  const apolloClient = getApolloClient();

  if (!apolloClient) {
    return;
  }

  apolloClient
    .mutate({
      mutation: GROUP_USER_EVENT,
    })
    .catch(() => {
      // do nothing, this is the best effort approach
    });
};
