import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { UserGroupStatus } from "types/generated";

type UserGroupStatusConfig = {
  variant: BadgeVariants;
  text: string;
};

export const USER_GROUP_STATUSES: Record<UserGroupStatus, UserGroupStatusConfig> = {
  [UserGroupStatus.Active]: { variant: "green", text: "Active" },
  [UserGroupStatus.Inactive]: { variant: "gray", text: "Inactive" },
};

type UserGroupStatusBadgeProps = {
  status: UserGroupStatus;
  className?: string;
};

const UserGroupStatusBadge = ({ status, className }: UserGroupStatusBadgeProps) => {
  return (
    <BadgeNext
      variant={USER_GROUP_STATUSES[status].variant}
      type="strong"
      className={className}
      text={USER_GROUP_STATUSES[status].text}
    />
  );
};

export default UserGroupStatusBadge;
