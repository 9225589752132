import { SpaceAccessField } from "../MapGroupDrawer/types";

export type UpdateGroupFields = {
  spaces: SpaceAccessField[];
};

export enum GroupsDrawerTabs {
  Spaces = "spaces",
  Users = "users",
}
