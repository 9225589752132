import { MouseEvent, useCallback } from "react";

import { StackDependencyDetail } from "types/generated";
import DependencyItem from "views/Stack/components/DependencyItem";

import { showDependenciesDeleteConfirmationModal } from "../DeleteConfirmationModal";

type DependenciesColumnItemProps = {
  dependencyId: string;
  itemStack: StackDependencyDetail;
  relatedStack: StackDependencyDetail;
  onDelete?: (dependencyId: string) => void;
  onOutputReferencesEdit?: () => void;
  referenceCount?: number;
  isFocused: boolean;
  isOutputReferencesDisallowed?: boolean;
  disallowedVendorName?: string;
};

const DependenciesColumnItem = ({
  dependencyId,
  itemStack,
  relatedStack,
  onDelete,
  onOutputReferencesEdit,
  referenceCount,
  isFocused,
  isOutputReferencesDisallowed,
  disallowedVendorName,
}: DependenciesColumnItemProps) => {
  const handleDeleteDependency = useCallback(() => {
    onDelete?.(dependencyId);
  }, [dependencyId, onDelete]);

  const handleDeleteDependencyConfirmation = (e: MouseEvent) => {
    e.stopPropagation();

    showDependenciesDeleteConfirmationModal({
      onConfirm: handleDeleteDependency,
      itemStack,
      relatedStack,
    });
  };

  return (
    <DependencyItem
      item={itemStack}
      onDelete={onDelete ? handleDeleteDependencyConfirmation : undefined}
      onOutputReferencesEdit={onOutputReferencesEdit}
      referenceCount={referenceCount}
      isFocused={isFocused}
      isOutputReferencesDisallowed={isOutputReferencesDisallowed}
      disallowedVendorName={disallowedVendorName}
    />
  );
};

export default DependenciesColumnItem;
