import { OverviewStaticWidget } from "./types";

export const NEW_TAB_IN_DRAWER_ID = "new-dashboard-view-in-drawer";
export const NEW_TAB_IN_LIST_ID = "new-dashboard-view";

export const VIEWS_LIMIT = 8;

export enum DefaultDashboardTabs {
  Overview = "overview",
  Metrics = "metrics",
}

export const DEFAULT_TAB_IDS = [
  DefaultDashboardTabs.Overview,
  DefaultDashboardTabs.Metrics,
] as string[];

export const DEFAULT_TAB_TITLES = {
  [DefaultDashboardTabs.Overview]: "Overview",
  [DefaultDashboardTabs.Metrics]: "Metrics",
};

export const OVERVIEW_STATIC_WIDGETS = {
  [OverviewStaticWidget.LaunchPad]: "LaunchPad",
  [OverviewStaticWidget.UserActivity]: "User activity",
};

export const TAB_QUERY_STRING = "tab";

export const DASHBOARD_STORAGE_KEY = "dashboard";

export const DASHBOARD_HEADER_PENDO_FEEDBACK_ID = "pendo-feedback-id-for-dashboard-header";
export const DASHBOARD_OVERVIEW_COLUMN_PENDO_FEEDBACK_ID =
  "pendo-feedback-id-for-dashboard-overview-column";
