import { SCHEDULE_TYPE } from "./types";

export const SCHEDULE_TYPES = [
  {
    label: "Recurring",
    value: SCHEDULE_TYPE.RECURRING,
  },
  {
    label: "Specific time",
    value: SCHEDULE_TYPE.SPECIFIC_TIME,
  },
];
