import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import { VcsProvider } from "types/generated";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import InstallationLink from "./InstallationLink";

type RepositoryTooltipProps = {
  vcsProvider: VcsProvider;
  whitelistable?: boolean;
};

const RepositoryGitHub = ({ whitelistable }: { whitelistable?: boolean }) => (
  <>
    <TooltipModalTitle>Repository</TooltipModalTitle>
    <TooltipModalBody align="start">
      GitHub repository containing the source code for this stack.
      {whitelistable && (
        <>
          <span>
            If your repository is not on this list, you may need to explicitly whitelist it in your{" "}
            <InstallationLink>GitHub installation</InstallationLink> configuration.
          </span>
          Note that we only list up to 100 repositories. If you have more, you can still type the
          full name in this field.
        </>
      )}
      <ReadMoreDocsLink
        docsUrl={getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository")}
      />
    </TooltipModalBody>
  </>
);

const Repository = ({ provider, whitelistable }: { provider: string; whitelistable?: boolean }) => (
  <>
    <TooltipModalTitle>Repository</TooltipModalTitle>
    <TooltipModalBody align="start">
      {provider} repository containing the source code for this stack.
      {whitelistable && (
        <>
          If you don't see your repository in the list, make sure that you access token can access
          it.
          <br />
          Note that we only list up to 100 repositories. If you have more, you can still type the
          full name in this field.
        </>
      )}
      <ReadMoreDocsLink
        docsUrl={getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository")}
      />
    </TooltipModalBody>
  </>
);

const RepositoryTooltip = ({ vcsProvider, whitelistable }: RepositoryTooltipProps) => {
  switch (vcsProvider) {
    case VcsProvider.Github:
      return <RepositoryGitHub whitelistable={whitelistable} />;
    default:
      return <Repository provider={HUMANIZE_PROVIDER[vcsProvider]} whitelistable={whitelistable} />;
  }
};

export default RepositoryTooltip;
