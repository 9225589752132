import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";

const DashboardResourcesChartSkeletonChartTile = () => {
  return (
    <TileWrapper>
      <TileContent gap="small">
        <Box align="center" gap="medium" fullWidth>
          <Skeleton count={1} height={36} width={36} borderRadius={8} />
          <Box direction="column" gap="small">
            <Box gap="medium" align="center">
              <Skeleton count={1} height={18} width={70} />
            </Box>
            <Skeleton count={1} height={18} width={36} />
          </Box>
        </Box>
      </TileContent>
    </TileWrapper>
  );
};

export default DashboardResourcesChartSkeletonChartTile;
