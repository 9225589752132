import { Stack } from "types/generated";
import { useBulkDisableStack } from "shared/Stack/useDisableStack";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { canDisableStack } from "shared/Stack/useDisableStack/accessValidation";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";

const useDisableStackBulkAction = () => {
  const { viewer } = useTypedContext(AccountContext);
  const [disableStack] = useBulkDisableStack();

  return {
    key: StackBulkActions.Disable,
    title: BULK_ACTION_NAMES[StackBulkActions.Disable],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Disable],
    condition: (stack: Stack) => canDisableStack(stack, viewer),
    mutation: (stack: Stack) => disableStack({ id: stack.id }),
  };
};

export default useDisableStackBulkAction;
