import { gql } from "@apollo/client";

export const GET_AVERAGE_RUN_DURATION = gql`
  query GetAverageRunDuration($startTime: Int!, $endTime: Int!) {
    metricsRange(input: { startTime: $startTime, endTime: $endTime }) {
      averageRunDuration {
        value
      }
      averageRunDurationRange {
        value
        timestamp
      }
      hasRuns
    }
  }
`;
