import { RunStateTransition } from "types/generated";

import UnfinishedRunActions from "../components/UnfinishedRunActions";
import { RunEntryContext } from "../types";
import StoppedEntry from "../entries/StoppedEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createStoppedEntry = (
  { timestamp, username, stateVersion }: RunStateTransition,
  {
    stackId,
    runId,
    canRetry,
    runRetryBlocker,
    isTaskRun,
    isModuleRun,
    canPerformAgain,
    runTaskCommand,
  }: RunEntryContext
) => (
  <StoppedEntry
    key={getRunStateTransitionId(timestamp, stateVersion)}
    timestamp={timestamp}
    username={username}
  >
    <UnfinishedRunActions
      stackId={stackId}
      runId={runId}
      canRetry={canRetry}
      runRetryBlocker={runRetryBlocker}
      isTaskRun={isTaskRun}
      isModuleRun={isModuleRun}
      canPerformAgain={canPerformAgain}
      runTaskCommand={runTaskCommand}
    />
  </StoppedEntry>
);
