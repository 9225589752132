import { useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import StackDetails from "views/Stack/components/Details";
import FullDescriptionDrawer from "components/FullDescription/Drawer";

import { StackSettingsContextData } from "../Context";

const StackSettingsDetailsView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const handleOpenFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFullDescriptionDrawerVisible(false);
  };

  return (
    <>
      <StackDetails stack={stackSettings} onOpenFullDescription={handleOpenFullDescriptionDrawer} />
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={stackSettings.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
        onBackToDetails={handleCloseFullDescriptionDrawer}
      />
    </>
  );
};

export default StackSettingsDetailsView;
