import { useCallback } from "react";
import { ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";

import RunChangesListItemElement from "./Element";
import { EntityChangeWithId } from "../types";

type RunChangesListItemProps = {
  resources: EntityChangeWithId[];
  onCheckItem: (item: EntityChangeWithId, checked: boolean) => unknown;
  selectedSet: Set<string>;
  selectable: boolean;
};

const RunChangesListItem = (
  props: ListChildComponentProps<RunChangesListItemProps & ListEntitiesBaseItemProps>
) => {
  const {
    index,
    style,
    data: { resources, setRowHeight, selectedSet, onCheckItem, selectable },
  } = props;

  const item = resources[index];

  const handleSetRowHeight = useCallback(
    (size: number) => setRowHeight(index, size + 8),
    [index, setRowHeight]
  );

  return (
    <div style={style}>
      <RunChangesListItemElement
        item={item}
        onCheckItem={onCheckItem}
        checked={selectedSet.has(resources[index].address)}
        setRowHeight={handleSetRowHeight}
        selectable={selectable}
      />
    </div>
  );
};

export default RunChangesListItem;
