import { gql } from "@apollo/client";

export const GET_VERSION = gql`
  query GetVersionNext($moduleId: ID!, $id: ID!) {
    module(id: $moduleId) {
      id
      name
      spaceDetails {
        accessLevel
      }
      workflowTool
      current {
        number
        state
        yanked
        yankNote
      }
      workerPool {
        id
      }
      provider
      version(id: $id) {
        id
        createdAt
        triggeredBy
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
          message
        }
        notes
        number
        metadata {
          root {
            name
            readme
            inputs {
              name
              type
              description
              default
              required
            }
            outputs {
              description
              name
            }
            dependencies
            providerDependencies {
              name
              namespace
              source
              version
            }
            resources {
              name
              type
            }
            changelog
          }
          submodules {
            name
            path
            inputs {
              name
              type
              required
            }
          }
          examples {
            name
          }
        }
        metadataParsingRun {
          id
          state
          title
        }
        runs {
          state
          createdAt
          id
          title
          expectFailure
          commit {
            hash
            url
          }
        }
        state
        consumers {
          id
          name
          spaceDetails {
            id
            name
          }
        }
        consumerCount
        yanked
        yankedBy
        yankNote
      }
    }
  }
`;

export const UPDATE_VERSION_DESCRIPTION = gql`
  mutation UpdateVersionDescription($id: ID!, $module: ID!, $description: String) {
    versionAnnotate(id: $id, module: $module, notes: $description) {
      id
    }
  }
`;
