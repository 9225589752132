import React from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import DSButton from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SettingsGear, ThumbUpEmpty } from "components/icons";
import ButtonIcon from "ds/components/ButtonIcon";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";
import { getDocsUrl } from "utils/getDocsUrl";

import {
  FILTERS_ORDER_SETTINGS_KEY,
  STACK_LIST_CUSTOMIZE_VIEW_BUTTON_ID,
  STACK_LIST_NEW_FEEDBACK_ID,
} from "./constants";
import usePredefinedFilters, { PredefinedFilterType } from "./usePredefinedFilters";
import StacksSwitch from "../StacksSwitch";
import { SpacesContext } from "../SpacesProvider";

type StacksPageLayoutProps = {
  children: React.ReactNode;
  searchCallback?: () => void;
  handleOpenCustomizeDrawer?: () => void;
};

const StacksPageLayout = ({
  children,
  searchCallback,
  handleOpenCustomizeDrawer,
}: StacksPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Stacks · ${accountName}`);

  const { predefinedFilterTab, setPredefinedFilter } = usePredefinedFilters();

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Stacks</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <StacksSwitch />
          <SearchInput
            callback={searchCallback}
            placeholder="Search by name, ID or labels"
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/stack")}
            tooltipText="Go to stacks documentation"
          />

          <ButtonIcon
            size="medium"
            variant="secondary"
            onClick={handleOpenCustomizeDrawer}
            id={STACK_LIST_NEW_FEEDBACK_ID}
            icon={ThumbUpEmpty}
          >
            Leave feedback
          </ButtonIcon>

          <ButtonIcon
            size="medium"
            variant="secondary"
            onClick={handleOpenCustomizeDrawer}
            icon={SettingsGear}
            id={STACK_LIST_CUSTOMIZE_VIEW_BUTTON_ID}
          >
            Customize list
          </ButtonIcon>

          {hasEntityCreateAccess && (
            <DSButton
              to="/new/stack"
              variant="primary"
              analyticsPage={AnalyticsPageStack.StacksList}
              analyticsTitle="Create Stack"
            >
              Create stack
            </DSButton>
          )}
        </ViewHeaderWrapper>
        <ViewHeaderNavigation>
          <Tab
            id={PredefinedFilterType.AllStacks}
            onClick={() => setPredefinedFilter(PredefinedFilterType.AllStacks)}
            label="All"
            isActive={
              !predefinedFilterTab || predefinedFilterTab === PredefinedFilterType.AllStacks
            }
          />
          <Tab
            id={PredefinedFilterType.NeedsAttention}
            onClick={() => setPredefinedFilter(PredefinedFilterType.NeedsAttention)}
            label="Needs Attention"
            isActive={predefinedFilterTab === PredefinedFilterType.NeedsAttention}
          />
          <Tab
            id={PredefinedFilterType.Failed}
            onClick={() => setPredefinedFilter(PredefinedFilterType.Failed)}
            label="Failed"
            isActive={predefinedFilterTab === PredefinedFilterType.Failed}
          />
          <Tab
            id={PredefinedFilterType.OnHold}
            onClick={() => setPredefinedFilter(PredefinedFilterType.OnHold)}
            label="On Hold"
            isActive={predefinedFilterTab === PredefinedFilterType.OnHold}
          />
          <Tab
            id={PredefinedFilterType.InProgress}
            onClick={() => setPredefinedFilter(PredefinedFilterType.InProgress)}
            label="In progress"
            isActive={predefinedFilterTab === PredefinedFilterType.InProgress}
          />
          <Tab
            id={PredefinedFilterType.Finished}
            onClick={() => setPredefinedFilter(PredefinedFilterType.Finished)}
            label="Finished"
            isActive={predefinedFilterTab === PredefinedFilterType.Finished}
          />
        </ViewHeaderNavigation>
      </ViewHeader>
      {children}
    </>
  );
};

export default StacksPageLayout;
