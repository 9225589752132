import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import useURLParams from "hooks/useURLParams";

import { ACCOUNT_DETAILS_QUERY_PARAM, ACCOUNT_DETAILS_VISIBLE_PARAM } from "./constants";

export const useNavigation = () => {
  const urlParams = useURLParams();
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    isDrawerVisible: useMemo(
      () =>
        decodeURIComponent(urlParams.get(ACCOUNT_DETAILS_QUERY_PARAM) || "") ===
        ACCOUNT_DETAILS_VISIBLE_PARAM,
      [urlParams]
    ),
    handleCloseDrawer: useCallback(() => {
      const params = new URLSearchParams(String(searchParams));
      params.delete(ACCOUNT_DETAILS_QUERY_PARAM);
      setSearchParams(params);
    }, [searchParams, setSearchParams]),
  };
};
