import Skeleton from "react-loading-skeleton";

import TileWrapper from "ds/components/Tile/Wrapper";
import Box from "ds/components/Box";

import EnvironmentPreviewSection from "../Section";
import styles from "./styles.module.css";

const SkeletonItem = () => (
  <TileWrapper>
    <Box align="center" gap="large" grid gridTemplate="4fr 1fr" fullWidth>
      <Skeleton count={1} height={20} width={200} className={styles.name} />

      <Box justify="end" fullWidth>
        <Skeleton count={1} height={20} width={50} />
      </Box>
    </Box>
  </TileWrapper>
);

const EnvironmentPreviewSkeleton = () => {
  return (
    <>
      <EnvironmentPreviewSection title="Variables">
        <Box direction="column" gap="medium" fullWidth>
          <SkeletonItem />
          <SkeletonItem />
        </Box>
      </EnvironmentPreviewSection>

      <EnvironmentPreviewSection title="Spacelift Environment">
        <Box direction="column" gap="medium" fullWidth>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </Box>
      </EnvironmentPreviewSection>
    </>
  );
};

export default EnvironmentPreviewSkeleton;
