import { ReactNode } from "react";

import { Cross } from "components/icons";

import styles from "./styles.module.css";

type WarningBarItemProps = {
  children: ReactNode;
};

const WarningBarItem = ({ children }: WarningBarItemProps) => {
  return (
    <p className={styles.warningItemText}>
      <Cross className={styles.warningCrossIcon} /> {children}
    </p>
  );
};

export default WarningBarItem;
