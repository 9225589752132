import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActions/types";
import useTypedContext from "hooks/useTypedContext";
import { useBulkConfirmRun } from "shared/Run/useConfirmRun";
import { canConfirmRun } from "shared/Run/useConfirmRun/accessValidation";
import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";
import { useBulkReviewRun } from "shared/Run/useReviewRun";
import { Run, RunReviewDecision } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import { StackContext } from "views/Stack/Context";
import { canReviewRun } from "shared/Run/useReviewRun/accessValidation";

import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "./constants";
import { DefaultMutationVariables, ReviewRunVariables, StackRunsBulkActions } from "./types";

export type StackRunsMutationVariables = ReviewRunVariables | DefaultMutationVariables;

const useStackRunsBulkActions = (): Array<
  BulkEntityActionItem<StackRunsBulkActions, Run, StackRunsMutationVariables>
> => {
  const { viewer } = useTypedContext(AccountContext);
  const { stack } = useTypedContext(StackContext);

  const [runConfirm] = useBulkConfirmRun();
  const [runDiscard] = useBulkDiscardRun();
  const [runReview] = useBulkReviewRun();

  return useMemo<
    Array<BulkEntityActionItem<StackRunsBulkActions, Run, StackRunsMutationVariables>>
  >(
    () => [
      {
        key: StackRunsBulkActions.Confirm,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.Confirm],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.Confirm],
        condition: (run) => canConfirmRun(run, stack, viewer),
        mutation: (run) => runConfirm({ stack: stack.id, run: run.id, note: null }),
      },
      {
        key: StackRunsBulkActions.Discard,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.Discard],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.Discard],
        condition: canDiscardRun,
        mutation: (run) => runDiscard({ stack: stack.id, run: run.id, note: null }),
      },
      {
        key: StackRunsBulkActions.ReviewApprove,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.ReviewApprove],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.ReviewApprove],
        condition: (run) => canReviewRun(run, stack),
        mutation: (run, { reviewNote }: ReviewRunVariables) =>
          runReview({
            stack: stack.id,
            run: run.id,
            note: reviewNote || "",
            decision: RunReviewDecision.Approve,
          }),
      },
      {
        key: StackRunsBulkActions.ReviewReject,
        title: BULK_ACTION_NAMES[StackRunsBulkActions.ReviewReject],
        resultTitle: BULK_ACTION_RESULTS_TITLE[StackRunsBulkActions.ReviewReject],
        condition: (run) => canReviewRun(run, stack),
        mutation: (run, { reviewNote }: ReviewRunVariables) =>
          runReview({
            stack: stack.id,
            run: run.id,
            note: reviewNote || "",
            decision: RunReviewDecision.Reject,
          }),
      },
    ],
    [runConfirm, runDiscard, runReview, stack, viewer]
  );
};

export default useStackRunsBulkActions;
