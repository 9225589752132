import { AuthorizationScheme } from "types/generated";
import UsersInviteWrapper from "views/Account/Settings/Users/InviteWrapper";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import type { PersonalSettingsAuthorizationScheme } from "../types";
import PersonalSettingsGroupsPageLayout from "./PageLayout";
import PersonalSettingsSpacesSortHeader from "./SortHeader";
import PersonalSettingsInviteForm from "../InviteForm";
import PersonalSettingsGroupsList from "./List";
import PersonalSettingsGroupsEmpty from "./Empty";

const PersonalSettingsGroups = ({ authorizationScheme }: PersonalSettingsAuthorizationScheme) => {
  const { viewer } = useTypedContext(AccountContext);

  const userManagementActive = authorizationScheme === AuthorizationScheme.ManagedUsers;

  return (
    <PersonalSettingsGroupsPageLayout>
      {userManagementActive && (
        <UsersInviteWrapper>
          <PersonalSettingsInviteForm analyticsPage={AnalyticsPagePersonal.PersonalIdpGroups} />
        </UsersInviteWrapper>
      )}

      {viewer.teams.length === 0 && <PersonalSettingsGroupsEmpty />}
      {viewer.teams.length > 0 && (
        <>
          <PersonalSettingsSpacesSortHeader />
          <PersonalSettingsGroupsList groups={viewer.teams} />
        </>
      )}
    </PersonalSettingsGroupsPageLayout>
  );
};

export default PersonalSettingsGroups;
