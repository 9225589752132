import useLocalStorage from "@rehooks/local-storage";
import { useCallback, useState } from "react";

import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { SearchQueryRequestedPage } from "types/generated";

type UsePaginationProps<T> = {
  fetchItems: ({
    cursor,
    rowsPerPage,
    requestedPage,
  }: {
    cursor: string | null;
    rowsPerPage: number;
    requestedPage: SearchQueryRequestedPage;
  }) => Promise<void>;
  startCursor?: string;
  endCursor?: string;
  items?: T[];
  initialLimit: number;
  itemsCount: number;
  storageKey: string;
  analyticsPage?: AnalyticsPage;
  analyticsProps?: TrackAnalyticsEventProperties;
};

const INITIAL_PAGE = 1;

const usePagination = <T>({
  fetchItems,
  items,
  startCursor,
  endCursor,
  initialLimit,
  itemsCount,
  storageKey,
  analyticsPage,
  analyticsProps,
}: UsePaginationProps<T>) => {
  const trackAnalytics = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: analyticsProps,
  });

  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>(storageKey, initialLimit);

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const from = (page - 1) * rowsPerPage + 1;
  // TODO: refactor when new API with count provided
  const to = Math.min(page * rowsPerPage, itemsCount || items?.length || 0);
  const lastPage = Math.ceil(itemsCount / rowsPerPage);

  const goToNextPage = useCallback(async () => {
    trackAnalytics?.("Pagination control clicked", {
      direction: "next",
    });

    if (endCursor) {
      const newPage = page + 1;
      setPage(newPage);

      await fetchItems({
        cursor: endCursor,
        rowsPerPage,
        requestedPage: SearchQueryRequestedPage.Next,
      });
    }
  }, [fetchItems, endCursor, page, rowsPerPage, trackAnalytics]);

  const goToPrevPage = useCallback(async () => {
    trackAnalytics?.("Pagination control clicked", {
      direction: "prev",
    });

    if (startCursor) {
      const newPage = page - 1;
      setPage(newPage);

      await fetchItems({
        cursor: newPage === 1 ? null : startCursor,
        rowsPerPage,
        requestedPage:
          newPage === 1 ? SearchQueryRequestedPage.First : SearchQueryRequestedPage.Previous,
      });
    }
  }, [fetchItems, rowsPerPage, page, startCursor, trackAnalytics]);

  const updateRowsPerPage = useCallback(
    async (rows: number) => {
      trackAnalytics?.("Rows per page changed", {
        rowCountSelected: rows,
      });
      setRowsPerPage(rows);
      setPage(INITIAL_PAGE);

      await fetchItems({
        cursor: null,
        rowsPerPage: rows,
        requestedPage: SearchQueryRequestedPage.First,
      });
    },
    [fetchItems, setRowsPerPage, trackAnalytics]
  );

  const goToLastPage = useCallback(async () => {
    trackAnalytics?.("Pagination control clicked", {
      direction: "last",
    });
    setPage(lastPage);

    await fetchItems({
      cursor: null,
      rowsPerPage,
      requestedPage: SearchQueryRequestedPage.Last,
    });
  }, [fetchItems, lastPage, rowsPerPage, trackAnalytics]);

  const goToFirstPage = useCallback(async () => {
    trackAnalytics?.("Pagination control clicked", {
      direction: "first",
    });
    setPage(INITIAL_PAGE);

    await fetchItems({
      cursor: null,
      rowsPerPage,
      requestedPage: SearchQueryRequestedPage.First,
    });
  }, [fetchItems, rowsPerPage, trackAnalytics]);

  const resetToInitialPage = useCallback(() => {
    setPage(INITIAL_PAGE);
  }, []);

  return {
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    setRowsPerPage: updateRowsPerPage,
    from,
    to,
    rowsPerPage,
    itemsCount,
    lastPage,
    page,
    resetToInitialPage,
  };
};

export default usePagination;
