import { gql } from "@apollo/client";

export const SAVED_VIEW = gql`
  query SavedView($id: ID!) {
    savedFilter(id: $id) {
      id
      name
      data
      isPublic
      createdBy
      type
    }
  }
`;
