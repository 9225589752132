import { useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Typography, { TypographyProps } from "ds/components/Typography";

type FormFieldTextConfirmProps = {
  fieldName: string;
  textToConfirm: string;
  disabled?: boolean;
  fontVariant?: TypographyProps["variant"];
};

const FormFieldTextConfirm = ({
  fieldName,
  textToConfirm,
  disabled,
  fontVariant = "p-body3",
}: FormFieldTextConfirmProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Record<string, string>>();

  return (
    <FormField
      label={
        <Typography tag="span" variant={fontVariant}>
          Please type "<strong>{textToConfirm}</strong>" to confirm.
        </Typography>
      }
      error={errors?.[fieldName]?.message}
      noMargin
    >
      {({ ariaInputProps }) => (
        <Input
          {...register(fieldName, {
            validate: (value) => value === textToConfirm,
          })}
          error={!!errors?.[fieldName]?.message}
          disabled={disabled}
          {...ariaInputProps}
        />
      )}
    </FormField>
  );
};

export default FormFieldTextConfirm;
