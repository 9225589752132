const policyInput = (padding: number, lines: string[]) => {
  const prefix = "#" + " ".repeat(padding);
  return lines.map((line) => prefix + line).join("\n");
};

export const moduleDocument = (padding: number) =>
  policyInput(padding, [
    '"administrative": "boolean",',
    '"branch": "string",',
    '"id": "string",',
    '"labels": ["string"],',
    '"name": "string",',
    '"namespace": "string or null",',
    '"repository": "string",',
    '"terraform_provider": "string",',
  ]);

export const runDocument = (padding: number) =>
  policyInput(padding, [
    '"creator_session": {',
    '  "admin": "boolean",',
    '  "creator_ip": "string",',
    '  "login": "string",',
    '  "name": "string"',
    '  "teams": ["string"]',
    '  "machine": "boolean - whether the run was kicked off by a human or a machine"',
    "},",
    '"created_at": "number (timestamp in nanoseconds)",',
    '"id": "string",',
    '"runtime_config": {',
    '  "before_init": ["string"],',
    '  "environment": "map[string]string",',
    '  "project_root": "string",',
    '  "runner_image": "string",',
    '  "terraform_version": "string"',
    "},",
    '"state": "string",',
    '"triggered_by": "string or null",',
    '"type": "string - PROPOSED or TRACKED",',
    '"updated_at": "number (timestamp in nanoseconds)"',
  ]);

export const stackDocument = (padding: number) =>
  policyInput(padding, [
    '"administrative": "boolean",',
    '"autodeploy: "boolean",',
    '"autoretry: "boolean",',
    '"branch": "string",',
    '"id": "string",',
    '"labels": ["string"],',
    '"locked_by": "string or null",',
    '"name": "string",',
    '"namespace": "string or null",',
    '"project_root": "string or null",',
    '"repository": "string",',
    '"state": "string",',
    '"terraform_version": "string or null"',
  ]);
