import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Stack } from "types/generated";

import { DELETE_STACK } from "./gql";

type HandleDeleteStackProps = {
  stackId: string;
  shouldDeleteResources?: boolean;
  successCallback: () => void;
};

const useDeleteStack = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deleteStack, { loading }] = useMutation<{
    stackDelete: Stack;
  }>(DELETE_STACK, {
    onError,
  });

  const handleDeleteStack = useCallback(
    ({ stackId, shouldDeleteResources, successCallback }: HandleDeleteStackProps) => {
      deleteStack({ variables: { stackId, shouldDeleteResources } })
        .then(({ data }) => {
          if (data?.stackDelete) {
            reportSuccess({ message: "Stack was successfully deleted" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [deleteStack, onError, reportSuccess]
  );

  return {
    deleteStack: handleDeleteStack,
    loading,
  };
};

export default useDeleteStack;
