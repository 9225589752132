import Box from "ds/components/Box";
import useTitle from "hooks/useTitle";

import DashboardViewHeader from "./ViewHeader";
import DashboardContextProvider from "./Provider";
import DashboardList from "./List";
import styles from "./styles.module.css";

const Dashboard = () => {
  useTitle("Spacelift Dashboard");

  return (
    <DashboardContextProvider>
      <Box className={styles.wrapper} direction="column" fullWidth>
        <DashboardViewHeader />
        <DashboardList />
      </Box>
    </DashboardContextProvider>
  );
};

export default Dashboard;
