import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import { getDisabledTriggerMessage } from "shared/Stack/utils";
import { AccountContext } from "views/AccountWrapper";
import useLockStack from "shared/Stack/useLockStack";
import useUnlockStack from "shared/Stack/useUnlockStack";
import useTriggerStack from "shared/Stack/useTriggerStack";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import { STAR_SET } from "../gql";
import { ANALYTICS } from "../constants";
const REFETCH_QUERIES = ["SearchStacksNew", "SearchStacksSuggestionsNew"];

type StackListItemActionsDropdownCellProps = {
  stack: Stack;
  setTriggerDropdownVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const StackListItemActionsDropdownCell = ({
  stack,
  setTriggerDropdownVisibility,
}: StackListItemActionsDropdownCellProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [starSet] = useMutation<{ starSet: Stack }>(STAR_SET, {
    refetchQueries: REFETCH_QUERIES,
  });

  const handleStarStack = () => {
    starSet({ variables: { id: stack.id, star: !stack.starred } }).catch(onError);
  };

  const [lockStack] = useLockStack({
    refetchQueries: ["SearchStacksSuggestionsNew", "SearchStacksNew"],
  });
  const [stackUnlock] = useUnlockStack({
    refetchQueries: ["SearchStacksSuggestionsNew", "SearchStacksNew"],
  });
  const [runTrigger] = useTriggerStack();

  const handleLockStack = () => {
    lockStack({ id: stack.id, note: null }, (data) => {
      if (data?.stackLock.id) {
        reportSuccess({ message: "Stack successfully locked" });
      }
    });
  };

  const handleUnlockStack = () => {
    stackUnlock({ id: stack.id }, (data) => {
      if (data?.stackUnlock.id) {
        reportSuccess({ message: "Stack successfully unlocked" });
      }
    });
  };

  const handleTriggerStack = () => {
    runTrigger({ stack: stack.id, runtimeConfig: null }, (data) => {
      if (data?.runTrigger.id) {
        reportSuccess({ message: "Run successfully triggered" });
      }
    });
  };

  const canLock = canLockStack(stack);
  const canUnlock = canUnlockStack(stack, viewer);
  const disableTriggerButtonMessage = getDisabledTriggerMessage(stack);
  const stackUrl = `/stack/${stack.id}`;

  return (
    <DropdownMenuEllipsis tooltip="Stack actions" onOpenChange={setTriggerDropdownVisibility}>
      <DropdownMenuItem
        {...ANALYTICS}
        analyticsTitle="Trigger run clicked"
        onAction={handleTriggerStack}
        tooltip={disableTriggerButtonMessage}
        isDisabled={!!disableTriggerButtonMessage}
      >
        Trigger run
      </DropdownMenuItem>
      {canLock && (
        <DropdownMenuItem
          {...ANALYTICS}
          analyticsTitle="Lock stack clicked"
          onAction={handleLockStack}
        >
          Lock stack
        </DropdownMenuItem>
      )}
      {canUnlock && (
        <DropdownMenuItem
          {...ANALYTICS}
          analyticsTitle="Unlock stack clicked"
          onAction={handleUnlockStack}
        >
          Unlock stack
        </DropdownMenuItem>
      )}
      <CopyFieldDropdownMenuItem
        {...ANALYTICS}
        analyticsTitle="Copy ID clicked"
        title="Copy ID"
        value={stack.id}
      />
      <DropdownMenuItem
        {...ANALYTICS}
        analyticsTitle={"Star clicked"}
        analyticsProps={{
          ...ANALYTICS.analyticsProps,
          value: stack.starred ? "remove" : "add",
        }}
        onAction={handleStarStack}
      >
        {stack.starred ? "Remove from favorites" : "Add to favorites"}
      </DropdownMenuItem>
      <DropdownMenuItem
        {...ANALYTICS}
        analyticsTitle="Settings clicked"
        href={`${stackUrl}/settings`}
      >
        Settings
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default StackListItemActionsDropdownCell;
