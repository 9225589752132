import { CSSProperties } from "react";
import { AnserJsonEntry } from "anser";

export const getLogLineStyles = (entry: AnserJsonEntry) => {
  const style: CSSProperties = {};

  if (entry.decorations.includes("bold")) {
    style.fontWeight = 800;
  }

  if (entry.decorations.includes("dim")) {
    style.opacity = "70%";
  }

  if (entry.decorations.includes("underline")) {
    style.textDecoration = "underline";
  }

  return style;
};
