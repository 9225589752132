import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type FiltersSortHeaderStaticColumnProps = BoxProps;

const FiltersSortHeaderStaticColumn = ({
  className,
  children,
  align = "center",
  ...restProps
}: FiltersSortHeaderStaticColumnProps) => {
  return (
    <Box {...restProps} align={align} className={cx(styles.columnItem, className)}>
      {children}
    </Box>
  );
};

export default FiltersSortHeaderStaticColumn;
