import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { AuditTrailEntry } from "types/generated";

import AuditTrailListItem from ".";

type AuditTrailListItemVirtualizedProps = {
  items: AuditTrailEntry[];
  onOpenDetails: (item: AuditTrailEntry) => void;
};

const AuditTrailListItemVirtualized = (
  props: ListChildComponentProps<AuditTrailListItemVirtualizedProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <AuditTrailListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onOpenDetails={data.onOpenDetails}
      />
    </div>
  );
};

export default memo(AuditTrailListItemVirtualized, areEqual);
