import { useToggle } from "hooks/useToggle";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";

import LaunchPadExploreNextItem from "./Item";
import { EXPLORE_NEXT_FEATURES } from "./constants";

const LaunchPadExploreNext = () => {
  const [isCollapsed, handleToggle] = useToggle(false);

  return (
    <CollapsiblePanel
      variant="section"
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      withTransition
    >
      <CollapsiblePanelHeader justify="between" padding="x-large" align="center" ariaLevel={2}>
        <CollapsiblePanelToggleTrigger align="center" gap="medium">
          <CollapsiblePanelToggleIndicator />
          <CollapsiblePanelTitle variant="p-t5">Explore next</CollapsiblePanelTitle>
        </CollapsiblePanelToggleTrigger>
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent
        padding="0 x-large x-large x-large"
        gap="large"
        grid
        gridTemplate="1fr 1fr 1fr"
      >
        {EXPLORE_NEXT_FEATURES.map((feature) => (
          <LaunchPadExploreNextItem key={feature.title} {...feature} />
        ))}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadExploreNext;
