import { ReactNode } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import { Spacing } from "types/Spacing";

import styles from "./styles.module.css";

type FiltersSortHeaderWrapperProps = {
  columnOrder?: string;
  columnGap?: Spacing;
  children?: ReactNode;
  ariaRole?: string;
  className?: string;
};

const FiltersSortHeaderWrapper = ({
  columnOrder = "1fr 1fr 1fr",
  columnGap = "0",
  children,
  ariaRole,
  className,
}: FiltersSortHeaderWrapperProps) => {
  return (
    <Box
      className={cx(styles.headerWrapper, className)}
      direction="row"
      gridTemplate={columnOrder}
      gap={`0 ${columnGap}`}
      grid
      fullWidth
      role={ariaRole}
    >
      {children}
    </Box>
  );
};

export default FiltersSortHeaderWrapper;
