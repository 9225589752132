export const replaceSubdomain = (url: string, subdomain: string): string => {
  const replace = `://${subdomain}.`;

  // Prepend https://
  if (!/^[a-zA-Z0-9_-]*:\/\//.test(url)) {
    url = `https://${url}`;
  }

  // Check if we got a subdomain in url
  const match = url.match(/\.[a-zA-Z0-9_-]*\b/g);
  if (match && match.length > 1) {
    return url.replace(/(:\/\/[a-zA-Z0-9_-]+\.)/, replace);
  }

  return url.replace(/:\/\/([a-zA-Z0-9_-]*\.)/, `${replace}$1`);
};
