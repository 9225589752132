import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type StackManageScheduledDeleteConfirmationModalProps = {
  onConfirm: () => void;
};

const StackManageScheduledDeleteConfirmationModal = create(
  function StackManageScheduledDeleteConfirmationModal({
    onConfirm,
  }: StackManageScheduledDeleteConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      modal.resolve();
      modal.hide();
      onConfirm();
    };

    return (
      <ConfirmationModal
        title="Delete scheduling"
        confirmationButtonLabel="Delete"
        confirmationButtonVariant="dangerPrimary"
        onConfirm={handleOnConfirm}
        size="large"
      >
        <Typography tag="p" variant="p-body2">
          Are you sure you want to delete{" "}
          <Typography tag="span" variant="p-t6">
            Delete stack
          </Typography>{" "}
          scheduling?
        </Typography>
      </ConfirmationModal>
    );
  }
);

export const showStackManageScheduledDeleteConfirmationModal = (
  props: StackManageScheduledDeleteConfirmationModalProps
) => show(StackManageScheduledDeleteConfirmationModal, props);
