import { fromUnixTime } from "date-fns";

import { DebugInfo } from "types/generated";
import { formatDateTimeByLocale } from "utils/date";

export const useLicensingDates = (debugInfo?: DebugInfo) => {
  const validityStartDate = debugInfo?.selfHostedLicence?.validity.begin;
  const validityEndDate = debugInfo?.selfHostedLicence?.validity.end;

  const startDate = validityStartDate
    ? formatDateTimeByLocale({
        date: fromUnixTime(validityStartDate),
        format: "dateReadableLong",
        renderTimezone: "UTC",
      })
    : "Not set";

  const endDate = validityEndDate
    ? formatDateTimeByLocale({
        date: fromUnixTime(validityEndDate),
        format: "dateReadableLong",
        renderTimezone: "UTC",
      })
    : "Not set";

  return { startDate, endDate };
};
