import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { VersionState } from "types/generated";
import { YANKED_VERSION_VALUE } from "views/constants";

type VersionStateConfig = {
  variant: BadgeVariants;
  text: string;
};

const VERSION_STATES: Record<VersionState, VersionStateConfig> = {
  [VersionState.Active]: { variant: "green", text: "Active" },
  [VersionState.Failed]: { variant: "red", text: "Failed" },
  [VersionState.Pending]: { variant: "yellow", text: "Pending" },
};

type VersionStateBadgeProps = {
  state: VersionState;
  className?: string;
  isYanked?: boolean;
  text?: string;
};

const VersionStateBadge = ({ state, className, isYanked, text }: VersionStateBadgeProps) => {
  const badgeText = isYanked ? YANKED_VERSION_VALUE : text;
  return (
    <BadgeNext
      variant={
        isYanked ? VERSION_STATES[VersionState.Failed].variant : VERSION_STATES[state].variant
      }
      type="strong"
      className={className}
      text={badgeText || VERSION_STATES[state].text}
    />
  );
};

export default VersionStateBadge;
