import { ReactNode } from "react";

import HistoryEntryNote from "../../components/HistoryEntryNote";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryUsername from "../../components/HistoryEntryUsername";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type DiscardedEntryProps = {
  timestamp: number;
  note: string | null;
  username: string | null;
  children: ReactNode;
};

const DiscardedEntry = ({ timestamp, note, username, children }: DiscardedEntryProps) => {
  const label = (
    <HistoryEntryLabel>
      <strong>Discarded</strong> <HistoryEntryUsername username={username} />
    </HistoryEntryLabel>
  );

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.Discarded}
      timestamp={timestamp}
      label={label}
      terminal
    >
      {note && <HistoryEntryNote>{note}</HistoryEntryNote>}
      {children}
    </HistoryEntryWrapper>
  );
};

export default DiscardedEntry;
