import { BulkActionsApplicableAction } from "components/BulkActions/ChooseActionStep/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import {
  BulkActionItemID,
  BulkActionsActionsPerItem,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import { Run, Stack } from "types/generated";
import BulkActionsDrawerActionStepsItems from "components/BulkActions/Drawer/ActionSteps/Items";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";

import { StackRunsBulkActions } from "./types";
import StackRunsBulkActionsSelectedItem from "./SelectedItem";

type StackRunsBulkActionsSelectedItemsViewProps = {
  items: Run[];
  stack: Stack;
  onItemDismiss: (id: BulkActionItemID) => void;
  availableActions: Array<BulkActionsApplicableAction<StackRunsBulkActions>>;
  actionsPerItem: BulkActionsActionsPerItem<StackRunsBulkActions>;
  onEmptyActionsCancel: () => void;
};

const StackRunsBulkActionsSelectedItemsView = ({
  items,
  stack,
  onItemDismiss,
  actionsPerItem,
  availableActions,
  onEmptyActionsCancel,
}: StackRunsBulkActionsSelectedItemsViewProps) => {
  return (
    <>
      <BulkActionsDrawerActionStepsItems selectedItemsCount={items.length}>
        {items.map((item) => (
          <StackRunsBulkActionsSelectedItem
            key={item.id}
            item={item}
            stack={stack}
            onDismiss={onItemDismiss}
            itemActions={actionsPerItem.get(item.id)}
            allActionsQty={availableActions.length}
            step={BulkActionsStep.ChooseAction}
          />
        ))}
      </BulkActionsDrawerActionStepsItems>

      <BulkActionsDrawerActionStepsFooter>
        <BulkActionsPatternsActionsList
          variant={BulkActionsVariant.Drawer}
          availableActions={availableActions}
          onEmptyActionsCancel={onEmptyActionsCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default StackRunsBulkActionsSelectedItemsView;
