import { ApolloError } from "@apollo/client";
import get from "lodash-es/get";

import { ErrorType } from "types/Error";

/* eslint-disable */
export const shouldShowAccountError = (error: ErrorType | undefined): boolean =>
  error &&
  get(error, "networkError.result.errors") &&
  // @ts-ignore
  error.networkError?.result?.errors.find(
    (item: { message: string }) => item.message === "Account not found"
  );
/* eslint-enable */

export const shouldShowUnauthorizedError = (error: ErrorType | undefined) =>
  error instanceof ApolloError &&
  error.graphQLErrors &&
  error.graphQLErrors.length > 0 &&
  error.graphQLErrors.find((item) => item.message === "unauthorized" || item.message === "denied");
