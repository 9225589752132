import { SearchQueryOrderDirection } from "types/generated";

import { StackRunChangesSuggestions } from "./types";

export const initialSortOption = "address";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const FILTERS_ORDER_SETTINGS_KEY = "stackRunChangesFiltersOrder";
export const CHANGES_PER_PAGE = 20;
export const POLL_INTERVAL = 10000;

export const searchStackRunChangesSuggestionsDictionary: Partial<
  Record<StackRunChangesSuggestions, string>
> = {
  address: "address",
  terraform_provider: "terraform provider",
  resource_type: "resource type",
  change_type: "change type",
  location: "location",
};

export const TARGETED_REPLAN_PROMO_KEY = "targetedReplanPromo";
