import {
  create,
  hide,
  NiceModalContext,
  NiceModalHocProps,
  show,
  useModal,
} from "@ebay/nice-modal-react";
import { ComponentProps, ComponentType, FC, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom-v5-compat";

export const createDrawer = <Props extends object>(Component: ComponentType<Props>) => {
  return create<Props & NiceModalHocProps>(function DrawerComponent(drawerProps) {
    const modals = useContext(NiceModalContext);
    const didMount = useRef(false);
    const location = useLocation();
    const initialLocation = useRef(location);
    const currentModal = useModal();

    useEffect(() => {
      if (didMount.current) {
        return;
      }

      didMount.current = true;

      for (const modal of Object.values(modals)) {
        if (modal.visible && modal.id !== drawerProps.id) {
          hide(modal.id);
        }
      }
    }, [modals, drawerProps.id]);

    if (initialLocation.current !== location && currentModal.visible) {
      currentModal.hide();
    }

    return <Component {...drawerProps} />;
  }) as FC<Props>;
};

export const createDrawerTrigger =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T extends React.FC<any>>(Component: T) =>
  (props?: ComponentProps<T> & Partial<NiceModalHocProps>) =>
    show(Component, props);
