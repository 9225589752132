import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const FILTERS_ORDER_SETTINGS_KEY = "usersAccessFiltersOrder";

export const INITIAL_SORT_OPTION = "name";
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;
export const COLUMN_ORDER = `minmax(120px, 2fr) minmax(120px, 1.5fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) ${dropdownEllipsisWidth}`;
export const COLUMN_GAP: Spacing = "large";
