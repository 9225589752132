import { ReactNode } from "react";

import Typography from "ds/components/Typography";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

type EnvironmentPreviewSectionProps = {
  title: string;
  children?: ReactNode;
};

const EnvironmentPreviewSection = ({ title, children }: EnvironmentPreviewSectionProps) => {
  return (
    <Box direction="column" className={styles.section} fullWidth>
      <Box padding="large">
        <Typography tag="h5" variant="p-t5">
          {title}
        </Typography>
      </Box>
      <Box padding="large" gap="medium">
        {children}
      </Box>
    </Box>
  );
};

export default EnvironmentPreviewSection;
