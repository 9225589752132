import Note from "ds/components/Note";
import { Stack } from "types/generated";

type LockNoticeProps = {
  lockedAt: Stack["lockedAt"];
  lockedBy: Stack["lockedBy"];
  note?: Stack["lockNote"];
  viewer: string;
};

const LockNotice = ({ lockedAt, lockedBy, note, viewer }: LockNoticeProps) => (
  <Note
    createdAt={lockedAt}
    createdBy={lockedBy}
    viewer={viewer}
    title="This stack was locked for exclusive use"
    content={note}
    variant="disabled"
  />
);

export default LockNotice;
