import isObject from "lodash-es/isObject";
import { useNavigate } from "react-router-dom-v5-compat";

import useURLParams from "hooks/useURLParams";

const areLabelsProperArray = (labels: unknown): labels is Record<"value", string>[] =>
  Array.isArray(labels) &&
  labels.every((label) => isObject(label) && "value" in label && typeof label.value === "string");

export const useDetailsFromUrl = () => {
  const urlParams = useURLParams();
  const navigate = useNavigate();
  const name = urlParams.get("name");
  const labels = urlParams.get("labels");

  let parsedLabels;
  let parsedName;

  if (labels) {
    try {
      const labelsFromUrl = JSON.parse(decodeURIComponent(atob(labels)));
      if (areLabelsProperArray(labelsFromUrl)) {
        parsedLabels = labelsFromUrl.map((label) => label.value);
      }
    } catch {
      // labels are malformed reset them to undefined
      parsedLabels = undefined;
    }
  }

  if (name) {
    try {
      parsedName = decodeURIComponent(atob(name));
    } catch {
      // name is malformed redirect to settings
      navigate("/settings");
    }
  }

  return {
    name: parsedName,
    labels: parsedLabels,
  };
};
