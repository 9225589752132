import { Fragment, ReactNode } from "react";

import Box from "ds/components/Box";
import SpaceliftLogo from "ds/components/SpaceliftLogo";
import CardWrapper from "components/CardWrapper";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import { IconComponent } from "types/Icon";

import SystemMessageLinks from "./Links";
import styles from "./styles.module.css";
import SystemMessagePageWrapper from "./PageWrapper";

type SystemMessageProps = {
  title: string;
  message: ReactNode;
  icon?: IconComponent;
  children?: ReactNode;
  withPageWrapper?: boolean;
  className?: string;
};

const SystemMessage = ({
  title,
  message,
  icon,
  children,
  withPageWrapper = true,
  className,
}: SystemMessageProps) => {
  const PageWrapper = withPageWrapper ? SystemMessagePageWrapper : Fragment;

  return (
    <PageWrapper>
      <Box
        direction="column"
        gap="xx-large"
        align="center"
        justify="center"
        fullWidth
        className={className}
      >
        <SpaceliftLogo />

        <CardWrapper
          direction="column"
          gap="x-large"
          variant="outlined"
          padding="xx-large"
          surfaceColor="primary"
          className={styles.cardWrapper}
        >
          {icon && (
            <Box justify="center">
              <Icon src={icon} size="x-large" />
            </Box>
          )}

          <Box direction="column" gap="small">
            <Typography tag="h1" variant="p-t5" align="center">
              {title}
            </Typography>
            {message && (
              <Typography tag="p" variant="p-body2" align="center">
                {message}
              </Typography>
            )}
          </Box>

          {children}
        </CardWrapper>

        <SystemMessageLinks />
      </Box>
    </PageWrapper>
  );
};

export default SystemMessage;
