import { forwardRef } from "react";
import cx from "classnames";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";

import styles from "./styles.module.css";

type InputTextareaProps = {
  className?: string;
} & TextareaAutosizeProps;

const InputTextarea = forwardRef(function InputTextarea(
  { className, ...otherProps }: InputTextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <TextareaAutosize
      ref={ref}
      className={cx(styles.input, styles.textarea, className)}
      cols={30}
      minRows={5}
      maxRows={5}
      {...otherProps}
    />
  );
});

export default InputTextarea;
