import { useCallback, useState } from "react";

export const useToggle = (initialState: boolean) => {
  const [isCollapsed, setIsCollapsed] = useState(initialState);

  const toggle = useCallback(
    (forcedValue?: boolean) =>
      setIsCollapsed(
        typeof forcedValue === "boolean" ? forcedValue : (currentValue) => !currentValue
      ),
    []
  );

  return [isCollapsed, toggle] as const;
};
