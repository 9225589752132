import { VcsProvider } from "types/generated";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";
import { sanitizeInput } from "shared/Stack/helpers";

import {
  StackAnsibleVendorFormFields,
  StackCloudFormationVendorFormFields,
  StackCreationWizardStep,
  StackFormFields,
  StackKubernetesVendorFormFields,
  StackPulumiVendorFormFields,
  StackTerraformVendorFormFields,
  StackTerragruntVendorFormFields,
  StackVendorFormFields,
} from "./types";
import { stackCreationVendorKeys } from "./constants";

const makeVendorFieldsForMutation = (
  fields:
    | StackTerraformVendorFormFields
    | StackTerragruntVendorFormFields
    | StackPulumiVendorFormFields
    | StackCloudFormationVendorFormFields
    | StackKubernetesVendorFormFields
    | StackAnsibleVendorFormFields
) => {
  const shouldClearWorkspace =
    ("workspace" in fields && fields.workspace === "") ||
    ("manageState" in fields && fields.manageState);

  return {
    ...fields,
    // override with null only when workspace is an empty string
    ...(shouldClearWorkspace ? { workspace: null } : null),
    ...("version" in fields ? { version: fields.version.value || undefined } : null),
    ...("workflowVersion" in fields ? { terraformVersion: fields.workflowVersion.value } : null),
    ...("terragruntVersion" in fields
      ? { terragruntVersion: fields.terragruntVersion.value }
      : null),
  };
};

const getVendorConfig = (formData: StackVendorFormFields) => {
  const { vendor, ...restFormData } = formData;
  const vendorKey = stackCreationVendorKeys[vendor];
  const fields = makeVendorFieldsForMutation(restFormData[vendorKey]);

  return {
    config: {
      [vendorKey]: fields,
    },
    manageState: "manageState" in fields && fields.manageState,
    stackObjectID: "uploadedStateObjectId" in fields ? fields.uploadedStateObjectId : null,
  };
};

export const getStackConfig = (
  formData: StackFormFields,
  showLeakingSensitiveOutputsThroughDependencies: boolean
) => {
  const formRunnerImage = formData[StackCreationWizardStep.Behaviour]?.runnerImage;
  const vendorConfig = getVendorConfig(formData[StackCreationWizardStep.Vendor]);
  const labels = formData[StackCreationWizardStep.Details]?.labels.map(({ value }) => value);
  const runnerImage = formRunnerImage?.length === 0 ? null : formRunnerImage?.trim();
  const workerPool =
    formData[StackCreationWizardStep.Behaviour]?.workerPool === WORKER_POOL_SHARED_VALUE
      ? null
      : formData[StackCreationWizardStep.Behaviour]?.workerPool;
  const namespace =
    formData[StackCreationWizardStep.Vcs]?.provider === VcsProvider.Github
      ? ""
      : formData[StackCreationWizardStep.Vcs]?.namespace;
  const additionalProjectGlobs = formData[StackCreationWizardStep.Vcs]?.additionalProjectGlobs?.map(
    ({ value }) => value
  );

  // we are not going to send here policy, context and cloud data as it is saved separately
  return {
    input: sanitizeInput(
      {
        ...formData[StackCreationWizardStep.Details],
        ...formData[StackCreationWizardStep.Hooks],
        ...formData[StackCreationWizardStep.Behaviour],
        ...formData[StackCreationWizardStep.Vcs],
        labels,
        runnerImage,
        workerPool,
        namespace,
        additionalProjectGlobs,
        vendorConfig: vendorConfig?.config,
      },
      showLeakingSensitiveOutputsThroughDependencies
    ),
    manageState: vendorConfig?.manageState,
    stackObjectID: vendorConfig?.stackObjectID,
  };
};
