import { useState } from "react";
import cx from "classnames";

import { Chevron } from "components/icons";
import { ConfigElementWithSource } from "types/generated";

import styles from "./styles.module.css";
import { EnvironmentType } from "../types";
import EnvironmentEditConfigMap from "../edit/ConfigMap";
import EnvironmentViewConfigMap from "../view/ConfigMap";

type EnvironmentCollapsibleSectionCommonProps = {
  sectionName: string;
  config: ConfigElementWithSource[];
};

type EnvironmentCollapsibleSectionProps = EnvironmentCollapsibleSectionCommonProps &
  (
    | {
        editMode?: false;
        stackId?: string;
        type?: EnvironmentType;
      }
    | {
        editMode: true;
        stackId: string;
        type: EnvironmentType;
      }
  );

// FYI: the EnvironmentCollapsibleSection contains legacy styling and structure similar to src/components/AttachedContext
// These both components should be refactored and merged into one
const EnvironmentCollapsibleSection = ({
  sectionName,
  config,
  editMode,
  type,
  stackId,
}: EnvironmentCollapsibleSectionProps) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <div className={styles.collapsibleSection}>
      <button onClick={toggleShow} className={styles.button}>
        <h3 className={styles.header}>
          <Chevron
            className={cx(styles.chevron, {
              [styles.chevronUp]: show,
            })}
          />
          {sectionName}
        </h3>
      </button>
      {show && (
        <div>
          {!editMode && <EnvironmentViewConfigMap runtimeConfig={config} />}

          {editMode && <EnvironmentEditConfigMap type={type} stackId={stackId} config={config} />}
        </div>
      )}
    </div>
  );
};

export default EnvironmentCollapsibleSection;
