import { VcsProvider } from "types/generated";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";

import { ModuleCreationWizardStep, ModuleFormFields } from "./types";

export const getModuleConfig = (formData: ModuleFormFields, isUpdating?: boolean) => {
  const labels = formData[ModuleCreationWizardStep.Details]?.labels.map(({ value }) => value);
  const workerPool =
    formData[ModuleCreationWizardStep.Behavior]?.workerPool === WORKER_POOL_SHARED_VALUE
      ? null
      : formData[ModuleCreationWizardStep.Behavior]?.workerPool;
  const namespace =
    formData[ModuleCreationWizardStep.Vcs]?.provider === VcsProvider.Github
      ? ""
      : formData[ModuleCreationWizardStep.Vcs]?.namespace;
  const name = formData[ModuleCreationWizardStep.Details]?.name
    ? formData[ModuleCreationWizardStep.Details]?.name
    : null;

  const terraformProvider =
    formData[ModuleCreationWizardStep.Details]?.terraformProvider || "default";

  const sharedAccounts = formData[ModuleCreationWizardStep.Share]?.sharedAccounts?.map(
    ({ value }) => value
  );

  const runnerImage = formData[ModuleCreationWizardStep.Behavior]?.runnerImage || null;

  const updateInput = {
    ...formData[ModuleCreationWizardStep.Behavior],
    ...formData[ModuleCreationWizardStep.Vcs],
    ...formData[ModuleCreationWizardStep.Details],
    workerPool,
    labels,
    sharedAccounts,
    runnerImage,
  };

  if (isUpdating) {
    return {
      input: {
        ...updateInput,
      },
    };
  }

  // we are not going to send here policy, context and cloud data as it is saved separately
  return {
    input: {
      ...formData[ModuleCreationWizardStep.Details],
      ...formData[ModuleCreationWizardStep.Vcs],
      namespace,
      updateInput,
      name,
      terraformProvider,
    },
  };
};
