import { RunState } from "types/generated";

import RunPhaseLogs from "../../components/RunPhaseLogs";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import StopRunButton from "../../components/StopRunButton";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";
import { RunHistoryEntryType } from "../../types";

type PerformingEntryProps = {
  timestamp: number;
  until: number | null;
  stateVersion: number | null;
  hasLogs: boolean;
  openLogsByDefault: boolean;
  showStopButton: boolean;
  beforeHooks: string[] | undefined;
  afterHooks: string[] | undefined;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
  canStop: boolean;
};

// TODO no designs
const PerformingEntry = ({
  timestamp,
  until,
  stateVersion,
  hasLogs,
  openLogsByDefault,
  showStopButton,
  beforeHooks,
  afterHooks,
  runId,
  stackId,
  isModuleRun,
  canStop,
}: PerformingEntryProps) => {
  const [showLogs, toggleLogs] = useVisibilityToggle(openLogsByDefault, hasLogs);

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.Performing}
      timestamp={timestamp}
      until={until}
      isLoading={!until}
      onClick={toggleLogs}
      isOpen={showLogs}
    >
      {showLogs && (
        <RunPhaseLogs
          beforeHooks={beforeHooks}
          afterHooks={afterHooks}
          state={RunState.Performing}
          stateVersion={stateVersion}
          until={until}
          runId={runId}
          stackId={stackId}
          isModuleRun={isModuleRun}
        >
          {canStop && showStopButton && <StopRunButton runId={runId} stackId={stackId} />}
        </RunPhaseLogs>
      )}
    </HistoryEntryWrapper>
  );
};

export default PerformingEntry;
