import { gql } from "@apollo/client";

export const GET_UPCOMING_DRIFT_DETECTION_SCHEDULES = gql`
  query GetUpcomingDriftDetectionSchedules {
    metrics {
      upcomingDriftDetectionSchedules {
        driftDetetionIntegration {
          nextSchedule
          timezone
        }
        stackTile {
          name
          slug
        }
      }
    }
  }
`;
