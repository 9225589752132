import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import { SchedulingItem } from "shared/Stack/Scheduling/types";
import useTypedFlags from "hooks/useTypedFlags";

import { GET_STACK_SCHEDULING } from "./gql";
import { StackSchedulingContextApi } from "../Context";

type ResponseData = {
  stack: {
    state: Stack["state"];
    blocked: Stack["blocked"];
    integrations: Stack["integrations"];
    scheduledDeletes: Stack["scheduledDeletes"];
    scheduledTasks: Stack["scheduledTasks"];
    scheduledRuns: Stack["scheduledRuns"];
    workerPool: Stack["workerPool"];
  };
};

const useGetStackScheduling = (stackId: string) => {
  const { scheduledRunsFrontend } = useTypedFlags();

  const { onError } = useTypedContext(FlashContext);
  const { setDriftDetectionExist } = useTypedContext(StackSchedulingContextApi);

  const { data, error, loading, networkStatus, stopPolling } = useQuery<ResponseData>(
    GET_STACK_SCHEDULING,
    {
      variables: {
        stack: stackId,
        includeScheduledRuns: scheduledRunsFrontend,
      },
      onError,
      pollInterval: 9000,
    }
  );

  const items = useMemo<SchedulingItem[]>(() => {
    return [
      ...((data?.stack?.integrations?.driftDetection && [data.stack.integrations.driftDetection]) ||
        []),
      ...(data?.stack?.scheduledDeletes || []),
      ...(data?.stack?.scheduledTasks || []),
      ...((scheduledRunsFrontend && data?.stack?.scheduledRuns) || []),
    ];
  }, [
    data?.stack?.integrations?.driftDetection,
    data?.stack?.scheduledDeletes,
    data?.stack?.scheduledTasks,
    data?.stack?.scheduledRuns,
    scheduledRunsFrontend,
  ]);

  useEffect(() => {
    setDriftDetectionExist(!!data?.stack?.integrations?.driftDetection);
  }, [data?.stack?.integrations?.driftDetection, setDriftDetectionExist]);

  return {
    items,
    isStackBlocked: data?.stack?.blocked || false,
    hasPrivateWorkerPool: !!data?.stack?.workerPool,
    state: data?.stack?.state || undefined,
    isPageLoading: loading && !data?.stack && networkStatus === NetworkStatus.loading,
    isPageEmpty: !error && items.length === 0,
    error,
    stopPolling,
  };
};

export default useGetStackScheduling;
