import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import pMap from "p-map";

import { Stack } from "types/generated";

import { GET_STACK_ENTITIES } from "./gql";

function useParallelQuery(stackIds: string[]) {
  const client = useApolloClient();
  const [results, setResults] = useState<Record<string, Stack>>({});

  useEffect(() => {
    setResults({});

    const abortController = new AbortController();

    const abort = () => {
      abortController.abort();
    };

    const fetchStack = (id: string) =>
      client
        .query({
          query: GET_STACK_ENTITIES,
          variables: { id },
          fetchPolicy: "cache-first",
          context: {
            fetchOptions: {
              signal: abortController.signal,
            },
          },
        })
        .then((result) => setResults((prev) => ({ ...prev, [id]: result.data.stack })))
        .catch(abort);

    (async () => {
      try {
        await pMap(stackIds, fetchStack, {
          concurrency: 25,
          signal: abortController.signal,
        });
      } catch (e) {
        const isAbortError = e instanceof DOMException && e.name === "AbortError";

        // Ignore abort errors and report all others
        if (!isAbortError) {
          reportError(e);
        }
      }
    })();

    return abort;
  }, [client, stackIds]);

  const loaded = Object.keys(results).length;
  const total = stackIds.length;

  return {
    results,
    loaded,
    total,
    finished: loaded === total,
  };
}

export default useParallelQuery;
