import useTypedContext from "hooks/useTypedContext";
import { SpaceAccessLevel } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { ProviderListItemDropdownProps } from "./types";
import { showProviderDeleteConfirmation } from "../ProviderDeleteConfirmation";
import useDeleteProvider from "../useDeleteProvider";

const ProviderListItemDropdown = ({ item, onEdit }: ProviderListItemDropdownProps) => {
  const { reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const [deleteProvider] = useDeleteProvider({
    refetchQueries: ["GetProviderWithVersions", "GetProviders"],
  });

  const handleDeleteProvider = () => {
    deleteProvider({ id: item.id }, (data) => {
      if (data?.terraformProviderDelete?.deleted) {
        reportSuccess({
          message: `Terraform provider "${data.terraformProviderDelete.id}" was successfully deleted`,
        });
      } else {
        reportError({
          message: "Something went wrong while deleting Terraform provider, please try again.",
        });
      }
    });
  };

  const handleEditProvider = () => {
    onEdit(item);
  };

  const handleDeleteProviderConfirmation = () => {
    showProviderDeleteConfirmation({
      providerId: item.id,
      onConfirm: handleDeleteProvider,
    });
  };

  const canManageProvider =
    viewer.admin || item.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  if (!canManageProvider) {
    return null;
  }

  return (
    <DropdownMenuEllipsis tooltip="Provider actions">
      <DropdownMenuItem onAction={handleEditProvider}>Edit</DropdownMenuItem>

      <DropdownMenuItem onAction={handleDeleteProviderConfirmation} danger>
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default ProviderListItemDropdown;
