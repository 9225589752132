import { create, useModal, show } from "@ebay/nice-modal-react";

import Button from "ds/components/Button";
import Link from "ds/components/Link";
import { TadaColored } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import Heading from "ds/components/Typography/Heading";
import { getDocsUrl } from "utils/getDocsUrl";

import { UserManagementActivationStatus } from "../../types";
import styles from "./styles.module.css";

type UsersInviteSuccessModalProps = {
  activationStatus: UserManagementActivationStatus;
};

const UsersInviteSuccessModal = create(function UsersInviteSuccessModal({
  activationStatus,
}: UsersInviteSuccessModalProps) {
  const userManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;
  const modal = useModal();

  return (
    <Modal isOpen={modal.visible} size="large" onExit={modal.remove}>
      <ModalContent>
        <Box direction="column" align="center" justify="center" padding="large">
          <TadaColored className={styles.icon} />
          <Heading variant="p-t3" className={styles.title}>
            {userManagementActive ? "New user invited!" : "Invitation prepared"}
          </Heading>
          <Typography variant="p-body2" tag="p" className={styles.info}>
            {userManagementActive ? (
              <>
                You will be able to manage their user and group access rules once they accept the
                invite.{" "}
                <Link href={getDocsUrl("/concepts/spaces/access-control.html")} target="_blank">
                  Learn more
                </Link>{" "}
                about our access rules.
              </>
            ) : (
              <>
                Your invitation will be sent when you enable user
                <br /> management in{" "}
                <Link to="/settings/management-strategy">Management strategy</Link>.
              </>
            )}
          </Typography>

          <Button variant="primary" onClick={modal.hide}>
            Okay
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export const showInviteSuccessModal = (props: UsersInviteSuccessModalProps) =>
  show(UsersInviteSuccessModal, props);
