import EmptyState from "ds/components/EmptyState";
import { GroupColored } from "components/icons";
import Box from "ds/components/Box";

const PersonalSettingsGroupsEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You don’t have any Groups yet"
        icon={GroupColored}
        caption="This is where your group access list will appear once your admin will add you to any of the
        groups in your organization."
      />
    </Box>
  );
};

export default PersonalSettingsGroupsEmpty;
