import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type WorkerPoolResetActionProps = {
  onAction: () => void;
  hasBusyWorkers: boolean;
};

const hasBusyWorkersTooltip = (
  <>
    Please drain all workers first to make sure that no work is being performed by this worker pool.
  </>
);

const WorkerPoolResetAction = ({ onAction, hasBusyWorkers }: WorkerPoolResetActionProps) => (
  <DropdownMenuItem
    tooltipWidthMode="maxWidthSm"
    tooltipPlacement="left"
    tooltip={
      <>
        {hasBusyWorkers && (
          <>
            {hasBusyWorkersTooltip}
            <br />
            <br />
          </>
        )}
        Resetting this worker pool means that existing workers can no longer be used and will need
        to be restarted with the new configuration.
      </>
    }
    onAction={onAction}
    isDisabled={hasBusyWorkers}
  >
    Reset
  </DropdownMenuItem>
);

export default WorkerPoolResetAction;
