import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";

import useTypedContext from "hooks/useTypedContext";
import useUpdateStack from "shared/Stack/useUpdateStack";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import Box from "ds/components/Box";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Textarea from "ds/components/Textarea";
import FormFieldTagsMagicBanner from "components/FormFields/Tags/MagicLabels";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackSettingsContextData } from "../Context";
import { getFormDefaultValues, mapCreateStackDetailsUpdateInput } from "./helpers";
import StackSettingsFormFooter from "../components/FormFooter";
import { REFETCH_STACK_AND_STACK_SETTINGS_QUERIES } from "../constants";
import { getStackDetailsNameTooltip } from "../components/getTooltips";

export type StackSettingsDetailsFormFields = {
  name: string;
  space: string;
  labels: Array<{ value: string }>;
  description: string;
};

const StackSettingsDetailsEdit = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsDetails,
  });

  const { stackUpdate, loading } = useUpdateStack({
    stack: stackSettings,
    refetchQueries: REFETCH_STACK_AND_STACK_SETTINGS_QUERIES,
  });

  const stackDetailsForm = useForm<StackSettingsDetailsFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    reset,
  } = stackDetailsForm;

  const onSubmit = (formData: StackSettingsDetailsFormFields) => {
    stackUpdate(mapCreateStackDetailsUpdateInput(formData), () => {
      trackSegmentEvent("Saved");
    });
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  return (
    <FormProvider {...stackDetailsForm}>
      <FormField
        noMargin
        label="Name"
        error={errors?.name?.message}
        {...getStackDetailsNameTooltip()}
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Name your stack"
            {...register("name", {
              validate: stringIsRequired(),
              setValueAs: (value: string) => value.trim(),
            })}
            error={!!errors?.name?.message}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <FormFieldSpace noMargin />
      <Box direction="column" fullWidth>
        <FormFieldTags noMargin label="Labels" tagName="label" name="labels" isOptional />
      </Box>
      <FormFieldTagsMagicBanner entityType="stack" />
      <FormField noMargin label="Description" isOptional>
        {({ ariaInputProps }) => (
          <Textarea
            placeholder="Enter description here..."
            maxRows={15}
            {...register("description")}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={!isValid || !isDirty || loading}
        submitLoading={loading}
      />
    </FormProvider>
  );
};

export default StackSettingsDetailsEdit;
