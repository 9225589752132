import { useCallback } from "react";
import type { MenuItemProps } from "react-aria-components";

import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";

type UseAnalyticsActionProps = AnalyticsCommonProps & {
  onAction: MenuItemProps["onAction"];
};
const useAnalyticsAction = ({
  analyticsPage,
  analyticsProps,
  analyticsTitle,
  onAction,
}: UseAnalyticsActionProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const handleAnalyticsAction = useCallback(() => {
    if (analyticsTitle) {
      trackSegmentAnalyticsEvent(analyticsTitle, analyticsProps);
    }
    onAction?.();
  }, [analyticsProps, analyticsTitle, onAction, trackSegmentAnalyticsEvent]);

  return analyticsTitle ? handleAnalyticsAction : onAction;
};

export default useAnalyticsAction;
