import { gql } from "@apollo/client";

export const QUERY_ATTACHABLE_VCS_INTEGRATIONS = gql`
  query QueryAttachableVcsIntegration($spaceId: ID!, $provider: VCSProvider!) {
    attachableVCSIntegrationsForProviderInSpace(id: $spaceId, provider: $provider) {
      id
      name
      details {
        __typename
        ... on GithubEnterpriseIntegration {
          id
          githubAppStatus
          installations {
            installationId
          }
        }
        ... on GitlabIntegration {
          id
        }
        ... on AzureDevOpsRepoIntegration {
          id
        }
        ... on BitbucketCloudIntegration {
          id
        }
        ... on BitbucketDatacenterIntegration {
          id
        }
      }
    }
  }
`;
