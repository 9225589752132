import { ReactNode } from "react";
import cx from "classnames";

import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import Box from "../Box";
import Icon, { IconProps } from "../Icon";

type IconTileProps = (
  | {
      icon: IconComponent;
      children?: ReactNode;
    }
  | {
      icon?: IconComponent;
      children: ReactNode;
    }
) & {
  variant?: "primary" | "secondary" | "neutral";
  className?: string;
  color?: IconProps["color"];
  backgroundColor?: string;
};

const IconTile = ({
  variant = "secondary",
  icon,
  children,
  className,
  color,
  backgroundColor,
}: IconTileProps) => {
  return (
    <Box
      align="center"
      justify="center"
      shrink="0"
      style={{ backgroundColor }}
      className={cx(styles.iconTile, styles[variant], className)}
    >
      {icon ? <Icon color={color} size="large" src={icon} /> : children}
    </Box>
  );
};

export default IconTile;
