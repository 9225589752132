import Link, { type LinkProps } from "ds/components/Link";

const TextLink = ({ children, ...restProps }: LinkProps) => {
  return (
    <Link {...restProps} variant="underline" target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
};

export default TextLink;
