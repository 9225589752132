import { Stack } from "types/generated";
import { AccountViewer } from "components/form/graphql/types";

import { canManageStacksAndRuns } from "../utils";

/**
 * Check if the current user can enable the stack
 */
export const canEnableStack = (stack: Stack, viewer: AccountViewer | undefined): boolean => {
  const isDisabled = stack.isDisabled;
  const hasPermissionToEnable = canManageStacksAndRuns(stack, viewer);

  return isDisabled && hasPermissionToEnable;
};
