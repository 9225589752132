import { isValidKey } from "../helpers";
import { colon, quote } from "../constants";
import styles from "../styles.module.css";

type LabelProps = {
  keyName?: string | number;
};

const Label = ({ keyName }: LabelProps) => {
  if (!isValidKey(keyName)) {
    return null;
  }

  return (
    <span>
      {typeof keyName === "string" ? (
        <span>
          {quote}
          {keyName}
          {quote}
        </span>
      ) : (
        <span className={styles.codeChangesNumber}>{keyName}</span>
      )}
      {colon}
    </span>
  );
};

export default Label;
