import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroupIntegration } from "types/generated";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import useDeleteIntegration from "./useDeleteIntegration";
import { showManageSlackAccessListConfirmationModal } from "./ConfirmationModal";

type ManageSlackAccessListDropdownProps = {
  handleGroupDetailsClick: (details: ManagedUserGroupIntegration) => void;
  item: ManagedUserGroupIntegration;
};

const ManageSlackAccessListDropdown = ({
  handleGroupDetailsClick,
  item,
}: ManageSlackAccessListDropdownProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { onDelete, loading } = useDeleteIntegration();

  const handleAccessDetailsClick = () => {
    handleGroupDetailsClick(item);
  };

  const handleRemoveIntegration = () => {
    onDelete(item.id)
      .then(() => {
        reportSuccess({
          message: `Slack access was deleted`,
        });
      })
      .catch(onError);
  };

  const handleDeleteConfirm = () => {
    showManageSlackAccessListConfirmationModal({
      onConfirm: handleRemoveIntegration,
      name: item.integrationName,
    });
  };

  return (
    <div className={styles.wrapper}>
      <DropdownMenuEllipsis tooltip="Slack access actions">
        <DropdownMenuItem onAction={handleAccessDetailsClick}>Access details</DropdownMenuItem>
        <DropdownMenuItem danger onAction={handleDeleteConfirm} loading={loading}>
          Delete
        </DropdownMenuItem>
      </DropdownMenuEllipsis>
    </div>
  );
};

export default ManageSlackAccessListDropdown;
