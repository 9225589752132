import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { ManagedUserGroupIntegration } from "types/generated";

import GroupsIntegrationsListItem from "./Item";
import { UserManagementActivationStatus } from "../../types";

type ManageSlackAccessListVirtualizedItemProps = {
  integrations: ManagedUserGroupIntegration[];
  activationStatus: UserManagementActivationStatus;
  handleGroupIntegrationClick: (details: ManagedUserGroupIntegration) => void;
};

const ManageSlackAccessListVirtualizedItem = (
  props: ListChildComponentProps<
    ManageSlackAccessListVirtualizedItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const item = data.integrations[index];
  const key = `${index} ${item.id}`;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <GroupsIntegrationsListItem
        key={key}
        item={item}
        handleGroupIntegrationClick={data.handleGroupIntegrationClick}
        setRowHeight={handleSetRowHeight}
        activationStatus={data.activationStatus}
      />
    </div>
  );
};

export default memo(ManageSlackAccessListVirtualizedItem, areEqual);
