import { isSaasDistribution } from "./distribution";

const DOMAIN = "https://docs.spacelift.io";
const DEFAULT_SELF_HOSTED_VERSION = "latest";
const isSaas = isSaasDistribution();

const getVersion = () => {
  const version = process.env.REACT_APP_SELF_HOSTED_VERSION || DEFAULT_SELF_HOSTED_VERSION;
  if (version.includes("preview")) {
    return DEFAULT_SELF_HOSTED_VERSION;
  }
  return version;
};

declare const docsUrlTag: unique symbol;

export type DocsUrl = string & { [docsUrlTag]: true };

export const getDocsUrl = (path = "/") =>
  (isSaas ? `${DOMAIN}${path}` : `${DOMAIN}/self-hosted/${getVersion()}${path}`) as DocsUrl;
