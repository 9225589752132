import { useMemo } from "react";

import Filters from "components/Filters";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import {
  FilterItem,
  FiltersItemsOptionsMap,
  SavedFilterView,
  SortOption,
} from "components/Filters/types";
import { useCachedFilterFields } from "components/Filters/hooks";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import { SearchSuggestionsFieldType, SearchSuggestionsOutput } from "types/generated";

import {
  FILTERS_ORDER_SETTINGS_KEY,
  FILTER_ITEMS_DICTIONARY,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../constants";

type ContextsFiltersLayoutProps = {
  children: React.ReactNode;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
  suggestions?: SearchSuggestionsOutput;
  loading: boolean;
  handlePollingActiveSections: (fields: string[]) => void;
};

function ContextsFiltersLayout({
  children,
  currentSavedView,
  setCurrentSavedView,
  suggestions,
  loading,
  handlePollingActiveSections,
}: ContextsFiltersLayoutProps) {
  const cachedFiltersData = useCachedFilterFields(suggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            label: field.name,
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, filtersItemsOptionsMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        .filter((field) => field.filterable)
        .map((field) => {
          let key = field.name;

          if (key === "label") {
            labelsCounter += 1;
            key = `label${labelsCounter}`;
          }

          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key,
            filterName: field.name,
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={filtersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={INITIAL_SORT_OPTION}
      initialSortDirection={INITIAL_SORT_DIRECTION}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="contexts"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
      filtersDictionary={FILTER_ITEMS_DICTIONARY}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageContext.ContextsList} />
        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
}

export default ContextsFiltersLayout;
