import { ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";
import Box from "../Box";

type ModalFooterProps = {
  children: ReactNode;
  className?: string;
};

const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return (
    <Box className={cx(styles.footer, className)} align="center" justify="end">
      {children}
    </Box>
  );
};

export default ModalFooter;
