import { ReactNode } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import { CrossNew } from "components/icons";

import Typography from "../Typography";
import Box from "../Box";
import styles from "./styles.module.css";

type FullScreenModalHeaderProps = {
  children: ReactNode;
  onClose: () => void;
};

const FullScreenModalHeader = ({ children, onClose }: FullScreenModalHeaderProps) => {
  return (
    <Box direction="row" justify="between" padding="large" align="center" className={styles.header}>
      <Typography variant="p-t4" tag="h1">
        {children}
      </Typography>
      <ButtonIcon icon={CrossNew} onClick={onClose} variant="ghost">
        Close
      </ButtonIcon>
    </Box>
  );
};

export default FullScreenModalHeader;
