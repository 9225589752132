import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { ConfigType } from "types/generated";
import ContextConfigFormPathField from "components/ContextConfigForm/PathField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";

import { ContextVariablesFormFields, ContextMountedFilesFormDrawerProps } from "./types";

const ContextMountedFilesFormDrawer = createDrawer(
  ({ handleFileAdd, item }: ContextMountedFilesFormDrawerProps) => {
    const fileForm = useForm<ContextVariablesFormFields>({
      defaultValues: {
        id: item?.id || "",
        description: item?.description || "",
        writeOnly: item?.writeOnly ? item.writeOnly : true,
        type: ConfigType.FileMount,
      },
      mode: "onChange",
    });

    const drawer = useModal();

    const {
      handleSubmit,
      formState: { isValid, isDirty },
    } = fileForm;

    const closeDrawer = () => {
      drawer.resolve();
      drawer.hide();
    };

    const onCreateSubmit = (formData: ContextVariablesFormFields) => {
      const fileData = {
        id: formData.id,
        description: formData.description || "",
        writeOnly: formData.writeOnly,
        type: formData.type,
        isDraft: true,
        createdAt: null,
        value: null,
      };
      handleFileAdd(fileData);
      closeDrawer();
    };

    const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
      onCreateSubmit(formData);
    };

    return (
      <DrawerSimple>
        <FormProvider {...fileForm}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Add file" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <Box margin="0 0 large">
              <ContextConfigFormPathField name="id" />
            </Box>
            <ContextConfigFormIsSecretField name="writeOnly" />
            <ContextConfigFormDescriptionField name="description" />

            <DrawerFooter>
              <DrawerFooterActions>
                <Button variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || !isDirty}
                >
                  {item ? "Save" : "Continue"}
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showContextMountedFilesFormDrawer = createDrawerTrigger(ContextMountedFilesFormDrawer);
