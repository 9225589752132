import { Stack, StackConfigVendorCloudFormation, StackConfigVendorInput } from "types/generated";
import { VENDOR_KEY } from "constants/vendor";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";

import { type StackSettingsCloudFormationVendorFormFields } from "./Edit";

export const getFormDefaultValues = (
  stackSettings: Stack
): StackSettingsCloudFormationVendorFormFields => {
  const config = stackSettings.vendorConfig as StackConfigVendorCloudFormation;

  return {
    stackName: config.stackName,
    region: config.region,
    entryTemplateFile: config.entryTemplateFile,
    templateBucket: config.templateBucket,
  };
};

export const mapCreateStackVendorUpdateInput = (
  formData: StackSettingsCloudFormationVendorFormFields
): StackConfigVendorInput => ({
  ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
  [VENDOR_KEY.CLOUD_FORMATION]: formData,
});
