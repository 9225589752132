import { RunIgnoredTrigger, RunIgnoredTriggerReasonType, Stack } from "types/generated";

import StackRunsIgnoredRunCalloutCommitLink from "./CommitLink";

type StackRunsIgnoredRunCalloutTitleProps = {
  stack: Stack;
  ignoredRun: RunIgnoredTrigger;
};

const getReason = (reasonType: RunIgnoredTriggerReasonType) => {
  switch (reasonType) {
    case RunIgnoredTriggerReasonType.DisabledByLabel:
      return " was ignored because the default push policy was disabled using a label.";
    case RunIgnoredTriggerReasonType.CustomPolicy:
      return " was ignored due to custom policies:";
    case RunIgnoredTriggerReasonType.NoAffectedFilesInProjectRoot:
      return " was ignored because no files in the project root were affected.";
    case RunIgnoredTriggerReasonType.TagsIgnoredByDefault:
      return " was ignored because, by default, runs are not triggered on pushing a tag.";
    default:
      return null;
  }
};

const StackRunsIgnoredRunCalloutTitle = ({
  stack,
  ignoredRun,
}: StackRunsIgnoredRunCalloutTitleProps) => {
  const vcsProvider = stack.provider;
  const reason = getReason(ignoredRun?.reasonType);
  return reason ? (
    <>
      The last commit{" "}
      <StackRunsIgnoredRunCalloutCommitLink ignoredRun={ignoredRun} vcsProvider={vcsProvider} />
      {reason}
    </>
  ) : null;
};

export default StackRunsIgnoredRunCalloutTitle;
