import { Controller, useFormContext } from "react-hook-form";

import FormField, { FormFieldProps } from "ds/components/Form/Field";
import SpaceSelect from "components/SpaceSelect";

import FormFieldSpaceTooltipInfo from "./TooltipInfo";

type FormFieldSpaceProps = Pick<
  FormFieldProps,
  | "tooltipAnalyticsPage"
  | "tooltipAnalyticsProps"
  | "tooltipAnalyticsTitle"
  | "noMargin"
  | "tooltipInfo"
> & {
  loading?: boolean;
  disabled?: boolean;
};

const FormFieldSpace = ({ disabled, loading, tooltipInfo, ...rest }: FormFieldSpaceProps) => {
  const { control } = useFormContext<{ space: string }>();

  return (
    <Controller
      name="space"
      control={control}
      rules={{ required: "Space field is required." }}
      render={({ field, fieldState }) => (
        <FormField
          {...rest}
          error={fieldState.error?.message}
          label="Space"
          tooltipInfoVariant="modal"
          tooltipInfo={tooltipInfo || <FormFieldSpaceTooltipInfo />}
        >
          {({ ariaInputProps }) => (
            <SpaceSelect
              ref={field.ref}
              value={field.value}
              onChange={field.onChange}
              error={!!fieldState.error?.message}
              loading={loading}
              disabled={disabled}
              ariaProps={ariaInputProps}
            />
          )}
        </FormField>
      )}
    />
  );
};

export default FormFieldSpace;
