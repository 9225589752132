import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Typography from "ds/components/Typography";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";

import { COLUMN_GAP, COLUMN_ORDER } from "./constants";
import { FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "../constants";

const ConfigManagementTreeGridListSortHeader = () => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP} ariaRole="row">
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.NAME, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
        wrapperAriaRole="columnheader"
      />
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Status
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default ConfigManagementTreeGridListSortHeader;
