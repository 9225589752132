import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type ChartTooltipItemValueProps = {
  color: string;
  label: string;
  value: string;
};

const ChartTooltipItemValue = ({ color, label, value }: ChartTooltipItemValueProps) => (
  <Box direction="row" justify="between" align="center" gap="medium">
    <Box key={label} direction="row" gap="medium" align="center">
      <div
        className={styles.itemValueSquare}
        style={{
          background: color,
        }}
      />
      <Typography variant="p-body3" tag="span" className={styles.itemValueLabel} color="primary">
        {label}
      </Typography>
    </Box>
    <Typography variant="p-body3" tag="span" color="primary">
      {value}
    </Typography>
  </Box>
);

export default ChartTooltipItemValue;
