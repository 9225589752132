import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import { SearchQueryOrderDirection, ConfigType, ConfigElement } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import { VariablesColored } from "components/icons";
import SortableTable, { SortableTableColumn } from "components/SortableTable";

import { ContextContext } from "../Context";
import { COLUMN_ORDER_VARIABLES } from "../constants";
import ContextVariablesListItem from "./ListItem";
import { showContextVariablesFormDrawer } from "./FormDrawer";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
  {
    value: "value",
    label: "Value",
    static: true,
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

const ContextVariables = () => {
  const { context, canManageContext } = useTypedContext(ContextContext);

  const { config } = context;

  const handleEditClick = (variable: ConfigElement) => {
    showContextVariablesFormDrawer({ id: variable.id, variable, contextId: context.id });
  };

  const openCreateVariableDrawer = () => {
    showContextVariablesFormDrawer({ contextId: context.id });
  };

  const items = config.filter((item) => item.type === ConfigType.EnvironmentVariable);

  return (
    <>
      <PageInfo title="Variables">
        {canManageContext && (
          <Button variant="primary" onClick={openCreateVariableDrawer}>
            Add variable
          </Button>
        )}
      </PageInfo>

      <SortableTable
        items={items}
        columns={columns}
        columnOrder={COLUMN_ORDER_VARIABLES}
        renderItems={(sorted) => (
          <>
            {sorted.map((item) => (
              <ContextVariablesListItem key={item.id} item={item} onEdit={handleEditClick} />
            ))}
          </>
        )}
        initialSortBy="createdAt"
        initialDirection={SearchQueryOrderDirection.Desc}
      />
      {items.length === 0 && (
        <EmptyState
          padding="large 0"
          icon={VariablesColored}
          title="You do not have any variables yet"
          caption="Environment variables are represented as a key-value mapping available to all processes running in a given environment. Click on the button below to create a new variable."
        />
      )}
    </>
  );
};

export default ContextVariables;
