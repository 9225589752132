import { ReactNode } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./styles.module.css";

type FadeTransitionProps = {
  children: ReactNode;
  visible: boolean;
};

const FadeTransition = ({ children, visible }: FadeTransitionProps) => {
  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

export default FadeTransition;
