import { TERRAGRUNT_TOOL_INFO_TOOLTIP } from "constants/terragrunt_tools";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";

import { StackSettingsGetTooltipHelperReturnType } from "../../types";

export const getToolTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Tool</TooltipModalTitle>
      <TooltipModalBody align="start">{TERRAGRUNT_TOOL_INFO_TOOLTIP}</TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});
