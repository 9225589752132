import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ScheduledTaskInput } from "types/generated";
import { StackContext } from "views/Stack/Context";

import { CREATE_STACK_SCHEDULING_TASK } from "./gql";

const useCreateScheduledTask = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const [stackScheduledTaskCreate, { loading }] = useMutation(CREATE_STACK_SCHEDULING_TASK, {
    refetchQueries: ["GetStackScheduling"],
    awaitRefetchQueries: true,
    onError,
  });

  const createScheduledTask = useCallback(
    (input: ScheduledTaskInput, successCallback?: () => void) => {
      stackScheduledTaskCreate({
        variables: {
          stack: stack.id,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledTaskCreate) {
            reportSuccess({
              message: `Task schedule was set up successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stack.id, stackScheduledTaskCreate]
  );

  return {
    createScheduledTask,
    loading,
  };
};

export default useCreateScheduledTask;
