import { getApolloClient } from "apollo/client";

import { TRACK_USER_EVENT } from "./gql";
import { SegmentPropertiesObject } from "./types";
import { getDefaultContext } from "./utils";

export const trackUserEvent = (
  name: string,
  properties?: SegmentPropertiesObject,
  context?: SegmentPropertiesObject
) => {
  const apolloClient = getApolloClient();

  if (!apolloClient) {
    return;
  }

  apolloClient
    .mutate({
      mutation: TRACK_USER_EVENT,
      variables: {
        name,
        properties: properties ? JSON.stringify(properties) : undefined,
        context: JSON.stringify({ ...getDefaultContext(), ...context }),
      },
      fetchPolicy: "no-cache",
    })
    .catch(() => {
      // do nothing, this is the best effort approach
    });
};
