import { ConfigElement, ConfigType, Maybe, StackContextAttachment } from "types/generated";
import { COMPUTED_VALUE } from "constants/environment";

import EnvironmentPreviewVariable from "../Variable";
import EnvironmentPreviewFile from "../File";

type EnvironmentPreviewConfigElementProps = ConfigElement & {
  context?: Maybe<StackContextAttachment>;
};

const EnvironmentPreviewConfigElement = ({
  id,
  runtime = false,
  type,
  value,
  writeOnly,
  context,
}: EnvironmentPreviewConfigElementProps) => {
  const computedValue = runtime ? COMPUTED_VALUE : value && value.replace(/ /g, "\u00a0");

  if (type === ConfigType.EnvironmentVariable) {
    return (
      <EnvironmentPreviewVariable
        name={id}
        value={computedValue}
        contextName={context?.contextName}
      />
    );
  }

  if (type === ConfigType.FileMount) {
    return <EnvironmentPreviewFile relativePath={id} value={value} writeOnly={writeOnly} />;
  }

  return null;
};

export default EnvironmentPreviewConfigElement;
