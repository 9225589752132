import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { BitbucketCloudIntegration, Maybe } from "types/generated";

import { GET_BITBUCKET_CLOUD_INTEGRATION } from "./gql";

const useGetBitbucketCloudIntegration = (id: Maybe<string>) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error } = useQuery<{
    bitbucketCloudIntegration: BitbucketCloudIntegration;
  }>(GET_BITBUCKET_CLOUD_INTEGRATION, {
    variables: {
      id,
    },
    onError,
    fetchPolicy: "no-cache",
  });

  return {
    integration: data?.bitbucketCloudIntegration,
    isPageLoading: loading && !data?.bitbucketCloudIntegration,
    error,
  };
};

export default useGetBitbucketCloudIntegration;
