import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Maybe, MutationStackScheduledRunDeleteArgs, ScheduledRun } from "types/generated";

import { DELETE_STACK_SCHEDULED_RUN } from "./gql";

type UseStackScheduledRunDeleteProps = {
  refetchQueries?: string[];
};

const useStackScheduledRunDelete = ({ refetchQueries }: UseStackScheduledRunDeleteProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackScheduledRunDelete, { loading }] = useMutation<
    {
      stackScheduledRunDelete: Maybe<ScheduledRun>;
    },
    MutationStackScheduledRunDeleteArgs
  >(DELETE_STACK_SCHEDULED_RUN, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError,
  });

  const deleteScheduledRun = useCallback(
    (stack: string, scheduledRun: string, successCallback?: () => void) => {
      stackScheduledRunDelete({
        variables: {
          scheduledRun,
          stack,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledRunDelete) {
            reportSuccess({
              message: "Scheduled run was removed successfully",
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackScheduledRunDelete]
  );

  return {
    deleteScheduledRun,
    loading,
  };
};

export default useStackScheduledRunDelete;
