import { RunStateTransition } from "types/generated";

import PendingReviewEntry from "../entries/PendingReviewEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createPendingReviewEntry = ({
  timestamp,
  until,
  note,
  stateVersion,
}: RunStateTransition) => {
  return (
    <PendingReviewEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      note={note}
    />
  );
};
