import FormGroup from "components/FormDefault/Group";
import FormViewer from "components/FormDefault/Viewer";
import PrivateText from "components/PrivateText";
import FormBooleanIcon from "components/FormDefault/BooleanIcons";
import { AuditTrailWebhook } from "types/generated";
import { AuditTrailHeadersTooltip } from "components/TooltipSnippets";

import Deliveries from "./Deliveries";

type AuditTrailViewProps = AuditTrailWebhook;

const View = (props: AuditTrailViewProps) => {
  const { enabled, endpoint, secret, includeRuns, customHeaders = [] } = props;

  return (
    <>
      <FormGroup
        labelText="Endpoint:"
        labelStyle={{
          muted: true,
        }}
        divided
      >
        <FormViewer>{endpoint}</FormViewer>
      </FormGroup>

      <FormGroup
        labelText="Secret:"
        labelStyle={{
          muted: true,
        }}
        divided
      >
        <FormViewer>
          <PrivateText text={secret} />
        </FormViewer>
      </FormGroup>

      <FormGroup
        labelText="Enabled:"
        labelStyle={{
          muted: true,
        }}
        divided
      >
        <FormViewer>
          <FormBooleanIcon success={enabled} />
        </FormViewer>
      </FormGroup>

      <FormGroup
        labelText="Include runs:"
        labelStyle={{
          muted: true,
        }}
        divided
      >
        <FormViewer>
          <FormBooleanIcon success={includeRuns} />
        </FormViewer>
      </FormGroup>

      <FormGroup
        labelText="Headers:"
        labelStyle={{
          muted: true,
        }}
        labelIcon={<AuditTrailHeadersTooltip />}
        divided
      >
        <FormViewer>{customHeaders.join(", ") || "None"}</FormViewer>
      </FormGroup>
      <Deliveries />
    </>
  );
};

export default View;
