import { endOfDay, startOfDay, startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";

import { PeriodValue } from "ds/components/Datepicker/types";

export enum UsageDateRange {
  CurrentBillingPeriod = "CurrentBillingPeriod",
  Today = "Today",
  ThisWeek = "ThisWeek",
  ThisMonth = "ThisMonth",
  Last30Days = "Last30Days",
  Last90Days = "Last90Days",
}

export enum GroupByKey {
  StackSlug = "stackSlug",
  WorkerPoolSlug = "workerPoolSlug",
  Public = "public",
  RunState = "runState",
  RunType = "runType",
}

export const PERIODS: Record<UsageDateRange, PeriodValue> = {
  [UsageDateRange.CurrentBillingPeriod]: {
    label: "Current billing period",
    range: () => ({
      start: subMonths(startOfDay(new Date()), 1),
      end: endOfDay(new Date()),
    }),
  },
  [UsageDateRange.Today]: {
    label: "Today",
    range: () => ({
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    }),
  },
  [UsageDateRange.ThisWeek]: {
    label: "This week",
    range: () => ({
      start: startOfWeek(new Date()),
      end: endOfDay(new Date()),
    }),
  },
  [UsageDateRange.ThisMonth]: {
    label: "This month",
    range: () => ({
      start: startOfMonth(new Date()),
      end: endOfDay(new Date()),
    }),
  },
  [UsageDateRange.Last30Days]: {
    label: "Last 30 days",
    range: () => ({
      start: subDays(new Date(), 30),
      end: endOfDay(new Date()),
    }),
  },
  [UsageDateRange.Last90Days]: {
    label: "Last 90 days",
    range: () => ({
      start: subDays(new Date(), 90),
      end: endOfDay(new Date()),
    }),
  },
};
