import { gql } from "@apollo/client";

import { PRIVATE_WORKER_POOL_FIELDS } from "../gql";

export const SEARCH_PRIVATE_WORKER_POOL_USED_BY = gql`
  ${PRIVATE_WORKER_POOL_FIELDS}
  query SearchPrivateWorkerPoolUsedBy($workerPoolId: ID!, $input: SearchInput!) {
    workerPool(id: $workerPoolId) {
      ...PrivateWorkerPoolFields
      searchUsers(input: $input) {
        edges {
          cursor
          node {
            stackId
            stackName
            isModule
            spaceDetails {
              id
              name
              accessLevel
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const SEARCH_PRIVATE_WORKER_POOL_USED_BY_SUGGESTIONS = gql`
  ${PRIVATE_WORKER_POOL_FIELDS}
  query SearchPrivateWorkerPoolUsedBySuggestions(
    $workerPoolId: ID!
    $input: SearchSuggestionsInput!
  ) {
    workerPool(id: $workerPoolId) {
      ...PrivateWorkerPoolFields
      searchUsersSuggestions(input: $input) {
        filteredCount
        fields {
          name
          orderable
        }
      }
    }
  }
`;
