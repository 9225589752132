import { ReactNode } from "react";

import HistoryEntryNote from "../../components/HistoryEntryNote";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";

type FailedEntryProps = {
  children: ReactNode;
  timestamp: number;
  note: string | null;
};

const FailedEntry = ({ timestamp, note, children }: FailedEntryProps) => {
  return (
    <HistoryEntryWrapper state={RunHistoryEntryType.Failed} timestamp={timestamp} terminal>
      {note && <HistoryEntryNote>{note}</HistoryEntryNote>}
      {children}
    </HistoryEntryWrapper>
  );
};

export default FailedEntry;
