import IgnoredRunPoliciesList from "components/IgnoredRunPoliciesList";
import { RunIgnoredTrigger, RunIgnoredTriggerReasonType } from "types/generated";

type StackIgnoredRunsElementReasonProps = {
  ignoredRun: RunIgnoredTrigger;
};

const StackIgnoredRunsElementReason = ({ ignoredRun }: StackIgnoredRunsElementReasonProps) => {
  switch (ignoredRun?.reasonType) {
    case RunIgnoredTriggerReasonType.DisabledByLabel:
      return <>Ignored because the default push policy was disabled using a label.</>;
    case RunIgnoredTriggerReasonType.CustomPolicy:
      return (
        <>
          Ignored due to custom policies:
          <IgnoredRunPoliciesList ignoredRun={ignoredRun} variant="p-body2" />
        </>
      );
    case RunIgnoredTriggerReasonType.NoAffectedFilesInProjectRoot:
      return <>Ignored because no files in the project root were affected.</>;
    case RunIgnoredTriggerReasonType.TagsIgnoredByDefault:
      return <>Ignored because, by default, runs are not triggered on pushing a tag</>;
    default:
      return null;
  }
};

export default StackIgnoredRunsElementReason;
