import { SidebarNavigationItemRule } from "types/Navigation";

export const checkIsActive = (pathname: string, rule?: SidebarNavigationItemRule) => {
  if (!rule) {
    return false;
  }

  if (typeof rule === "function") {
    return rule(pathname);
  }

  return rule.some((singleRule) => pathname.startsWith(singleRule));
};
