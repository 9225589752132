import { FormEvent, ReactNode } from "react";
import cx from "classnames";

import DeprecatedToggle from "components/DeprecatedToggle";
import InputText from "components/input/InputText";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";

type NewTaskFormProps = {
  onSubmit: (e: FormEvent) => void;
  commandValue: string;
  setCommandValue: (value: string) => void;
  skipInitialization: boolean;
  setSkipInitialization: (value: boolean) => void;
  children?: ReactNode;
  withBottomMargin?: boolean;
};

const NewTaskForm = ({
  onSubmit,
  commandValue,
  setCommandValue,
  skipInitialization,
  setSkipInitialization,
  withBottomMargin,
  children,
}: NewTaskFormProps) => {
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setCommandValue(e.currentTarget.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <form
      className={cx(styles.form, { [styles.withBottomMargin]: withBottomMargin })}
      onSubmit={handleSubmit}
    >
      <Tooltip
        on={(props) => (
          <DeprecatedToggle
            {...props}
            checked={!skipInitialization}
            className={styles.checkbox}
            onChange={() => setSkipInitialization(!skipInitialization)}
          />
        )}
        placement="bottom"
        widthMode="maxWidthSm"
      >
        Initialize the workspace before running this task. This means executing all the{" "}
        pre-initialization hooks as well as running the vendor-specific initialization procedure.
        Toggle this off only if you want to explicitly skip this step.
      </Tooltip>
      <InputText
        className={styles.input}
        value={commandValue}
        type="text"
        placeholder="Command"
        onChange={handleChange}
      />
      {children}
    </form>
  );
};

export default NewTaskForm;
