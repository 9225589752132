import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import { SearchQueryOrderDirection } from "types/generated";

import { COLUMN_GAP, COLUMN_ORDER } from "./constants";
import { FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "../constants";

type SpacesTreeGridListSortHeaderProps = {
  sortOption: FilterItemKeys;
  sortDirection: SearchQueryOrderDirection;
  onSortChange: (option: FilterItemKeys) => void;
};

const SpacesTreeGridListSortHeader = ({
  sortOption,
  sortDirection,
  onSortChange,
}: SpacesTreeGridListSortHeaderProps) => {
  const onItemClick = (value: string) => () => {
    onSortChange(value as FilterItemKeys);
  };

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP} ariaRole="row">
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.NAME, FILTER_ITEMS_DICTIONARY)}
        querySortOption={sortOption}
        querySortDirection={sortDirection}
        onClick={onItemClick}
        wrapperAriaRole="columnheader"
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.ID, FILTER_ITEMS_DICTIONARY)}
        querySortOption={sortOption}
        querySortDirection={sortDirection}
        onClick={onItemClick}
        wrapperAriaRole="columnheader"
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.INHERIT_ENTITIES, FILTER_ITEMS_DICTIONARY)}
        querySortOption={sortOption}
        querySortDirection={sortDirection}
        onClick={onItemClick}
        wrapperAriaRole="columnheader"
      />
    </FiltersSortHeaderWrapper>
  );
};

export default SpacesTreeGridListSortHeader;
