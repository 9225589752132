import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { RunWithPosition } from "types/generated";

import WorkerPoolQueuedRunsListItem from ".";

type WorkerPoolQueuedRunsVirtualizedListItemProps = {
  items: RunWithPosition[];
  isPublic?: boolean;
  selectedSet: Set<string>;
  onCheckItem: (runId: string) => void;
};

const WorkerPoolQueuedRunsVirtualizedListItem = (
  props: ListChildComponentProps<
    WorkerPoolQueuedRunsVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <WorkerPoolQueuedRunsListItem
        isPublic={data.isPublic}
        key={data.items[index].run.id}
        queuedRun={data.items[index]}
        setRowHeight={handleSetRowHeight}
        checked={data.selectedSet.has(data.items[index].run.id)}
        onCheckItem={data.onCheckItem}
      />
    </div>
  );
};

export default memo(WorkerPoolQueuedRunsVirtualizedListItem, areEqual);
