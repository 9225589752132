import { gql } from "@apollo/client";

export const SEARCH_PUBLIC_WORKER_POOL_SCHEDULABLE_RUNS = gql`
  query SearchPublicWorkerPoolSchedulableRuns($input: SearchInput!) {
    publicWorkerPool {
      schedulableRunsCount
      usersCount
      searchSchedulableRuns(input: $input) {
        edges {
          cursor
          node {
            isModule
            position
            stackId
            stackName
            provider
            run {
              id
              type
              title
              finished
              triggeredBy
              createdAt
              updatedAt
              driftDetection
              isPrioritized
              state
              commit {
                authorName
                hash
                url
              }
            }
            version
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const SEARCH_PUBLIC_WORKER_POOL_SCHEDULABLE_RUNS_SUGGESTIONS = gql`
  query SearchPublicWorkerPoolSchedulableRunsSuggestions($input: SearchSuggestionsInput!) {
    publicWorkerPool {
      schedulableRunsCount
      usersCount
      searchSchedulableRunsSuggestions(input: $input) {
        filteredCount
        fields {
          name
          orderable
          filterable
          type
          possibleValuesBoolean {
            values
            counts
          }
          possibleValuesEnum {
            values
            counts
          }
          possibleValuesString {
            values
            counts
          }
          possibleValuesHierarchy {
            values {
              id
              parentId
              displayValue
            }
            counts
          }
        }
      }
    }
  }
`;
