import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroup } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { getManagementStrategy } from "views/Account/Settings/helpers";
import { SettingsContext } from "views/Account/Settings/Context";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import FlashContext from "components/FlashMessages/FlashContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import useDeleteGroup from "./useDeleteGroup";
import { showIdpGroupMappingListConfirmationModal } from "./ConfirmationModal";

type IdpGroupMappingListDropdownProps = {
  handleGroupDetailsClick: (details: ManagedUserGroup) => unknown;
  item: ManagedUserGroup;
};

const IdpGroupMappingListDropdown = ({
  handleGroupDetailsClick,
  item,
}: IdpGroupMappingListDropdownProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { onDelete, loading } = useDeleteGroup();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const handleRemoveGroup = () => {
    trackSegmentAnalyticsEvent("Delete confirm");
    onDelete(item.id)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Group access was deleted`,
          });
        }
      })
      .catch(onError);
  };

  const getOnDeleteClick = () => {
    showIdpGroupMappingListConfirmationModal({
      onConfirm: handleRemoveGroup,
      name: item.groupName,
    });
    trackSegmentAnalyticsEvent("Delete click");
  };

  const handleAccessDetailsClick = () => {
    handleGroupDetailsClick(item);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownMenuEllipsis tooltip="Group actions">
        <DropdownMenuItem onAction={handleAccessDetailsClick}>Access details</DropdownMenuItem>
        <DropdownMenuItem loading={loading} danger onAction={getOnDeleteClick}>
          Delete
        </DropdownMenuItem>
      </DropdownMenuEllipsis>
    </div>
  );
};

export default IdpGroupMappingListDropdown;
