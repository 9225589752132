import { ReactNode } from "react";

import Box from "ds/components/Box";

type ChartTooltipProps = {
  children: ReactNode;
};

const ChartTooltip = ({ children }: ChartTooltipProps) => {
  return (
    <Box direction="column" gap="medium">
      {children}
    </Box>
  );
};

export default ChartTooltip;
