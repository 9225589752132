import CodeEditor from "components/CodeEditor";

import styles from "./styles.module.css";

type AuditTrailEntriesDetailsBodyProps = {
  value: string | null;
};

const AuditTrailEntriesDetailsBody = ({ value }: AuditTrailEntriesDetailsBodyProps) => {
  if (value === null) {
    return <>None</>;
  }

  let body = value;

  try {
    body = JSON.stringify(JSON.parse(body), null, 2);
  } catch {
    // ignore
  }

  return (
    <CodeEditor
      language="json"
      body={body}
      readOnly
      className={styles.body}
      options={{
        lineNumbers: "off",
      }}
    />
  );
};

export default AuditTrailEntriesDetailsBody;
