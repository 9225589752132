import useTypedContext from "hooks/useTypedContext";

import StackSettingsFormWrapper from "../components/FormWrapper";
import StackSettingsPageLayout from "../components/PageLayout";
import StackSettingsBehaviorEdit from "./Edit";
import StackSettingsBehaviorView from "./View";
import { StackSettingsContextData } from "../Context";

const StackSettingsBehavior = () => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return (
    <StackSettingsPageLayout title="Behavior" description="Additional stack settings.">
      <StackSettingsFormWrapper>
        {canManageStack ? <StackSettingsBehaviorEdit /> : <StackSettingsBehaviorView />}
      </StackSettingsFormWrapper>
    </StackSettingsPageLayout>
  );
};

export default StackSettingsBehavior;
