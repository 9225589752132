import { gql } from "@apollo/client";

export const GET_API_KEYS = gql`
  query GetApiKeys {
    viewer {
      apiKeys {
        id
        createdAt
        description
        admin
        teams
        type
        teamCount
        lastUsedAt
        name
        spaceCount
        accessRules {
          space
          spaceAccessLevel
        }
        oidcSettings {
          clientId
          issuer
          subjectExpression
        }
      }
    }
  }
`;
