import { useContext, Context } from "react";

const useTypedContext = <T>(Context: Context<T | undefined>): T => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      `"useTypedContext" Error: Passed ${Context.displayName || "UnknownContext"} must be within its Provider. Or check that the context value is not falsy`
    );
  }

  return context;
};

export default useTypedContext;
