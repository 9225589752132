import ListEntitiesNew from "components/ListEntitiesNew";
import { ManagedUserGroupIntegration } from "types/generated";
import Box from "ds/components/Box";

import { UserManagementActivationStatus } from "../../types";
import ManageSlackAccessListVirtualizedItem from "./VirtualizedItem";
import styles from "./styles.module.css";

type ManageSlackAccessListProps = {
  integrations: ManagedUserGroupIntegration[];
  handleGroupIntegrationClick: (details: ManagedUserGroupIntegration) => void;
  activationStatus: UserManagementActivationStatus;
};

const ManageSlackAccessList = ({
  integrations,
  handleGroupIntegrationClick,
  activationStatus,
}: ManageSlackAccessListProps) => {
  return (
    <Box direction="column" grow="1">
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={integrations.length}
          itemProps={{
            integrations,
            handleGroupIntegrationClick,
            activationStatus,
          }}
          virtualizedItem={ManageSlackAccessListVirtualizedItem}
          itemKey={(index) => integrations[index].id}
        />
      </div>
    </Box>
  );
};

export default ManageSlackAccessList;
