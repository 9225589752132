import { Stack } from "types/generated";
import LinkNew from "ds/components/LinkNew";

import useStackListAnalytics from "../useStackListAnalytics";

type StackListItemSpaceCellProps = { stack: Stack };

const StackListItemSpaceCell = ({ stack }: StackListItemSpaceCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  if (!stack.spaceDetails) {
    return null;
  }

  return (
    <LinkNew
      size="small"
      href={`/spaces/${stack.spaceDetails.id}`}
      onPress={() => trackAnalytics("Space link clicked")}
    >
      {stack.spaceDetails.name}
    </LinkNew>
  );
};

export default StackListItemSpaceCell;
