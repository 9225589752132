import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom-v5-compat";

import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { NamedWebhooksIntegration } from "types/generated";

import WebhookForm from "../NewWebhook";
import { GET_EDIT_WEBHOOK } from "./gql";

const EditWebhook = () => {
  const { webhookId } = useParams<{ webhookId: string }>();
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<{
    namedWebhooksIntegration: NamedWebhooksIntegration;
  }>(GET_EDIT_WEBHOOK, {
    variables: {
      id: webhookId,
    },
    onError,
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.namedWebhooksIntegration) {
    return <PageLoading />;
  }

  if (!data?.namedWebhooksIntegration) {
    return <NotFoundPage />;
  }

  return <WebhookForm webhook={data.namedWebhooksIntegration} />;
};

export default EditWebhook;
