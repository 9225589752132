import { EntityChangeType } from "types/generated";
import { getChangeId } from "utils/changes";

import { ReplanRequestChangeRawType, ReplanRequestChangeType } from "./types";

const getChangeType = (type: string) => {
  switch (type.toLowerCase()) {
    case "added":
      return EntityChangeType.Add;
    case "changed":
      return EntityChangeType.Change;
    case "deleted":
      return EntityChangeType.Delete;
    case "destroy-before-create-replaced":
      return EntityChangeType.ReplaceDestroyBeforeCreate;
    case "create-before-destroy-replaced":
      return EntityChangeType.ReplaceCreateBeforeDestroy;
    default:
      return undefined;
  }
};

const isChangeReplanned = (change: ReplanRequestChangeRawType) => {
  // Change is explicitly marked as ignored
  if (change.replanned === false) {
    return false;
  }

  // Change is explicitly marked as replanned
  if (change.replanned === true) {
    return true;
  }

  // Change is not explicitly marked, which means it was saved before we started
  // tracking this information and during that time we only stored changes that
  // were going to be replanned.
  return true;
};

export const getChangesFromEntryNote = (note: string) => {
  const replannedChanges: ReplanRequestChangeType[] = [];
  const ignoredChanges: ReplanRequestChangeType[] = [];

  try {
    const changes = JSON.parse(note) as ReplanRequestChangeRawType[];

    for (const change of changes) {
      const normalizedChange = {
        id: getChangeId(change.address, change.change_type),
        address: change.address,
        type: getChangeType(change.change_type),
        moved: change.moved || false,
        previousAddress: change.previous_address,
      };

      if (isChangeReplanned(change)) {
        replannedChanges.push(normalizedChange);
      } else {
        ignoredChanges.push(normalizedChange);
      }
    }

    return { replannedChanges, ignoredChanges };
  } catch {
    return { replannedChanges: null, ignoredChanges: null };
  }
};
