import { ReactNode } from "react";

import { ContextMountedFile } from "../types";

export type ContextMountedFilesListItemProps = {
  item: ContextMountedFile;
  onRemoveFileDraft?: () => unknown;
  onOpenDetailsDrawer?: () => unknown;
  onOpenFileDetailsDrawer?: (item: ContextMountedFile) => void;
};

export enum FileMountDrawerTabs {
  Upload = "upload",
  AddManually = "manually",
}

export type ContextMountedFilesListItemActionsProps = {
  item: ContextMountedFile;
  onEdit: () => void;
  forceOverwrite: boolean;
};

export type ContextMountedFilesListItemRegularProps = {
  item: ContextMountedFile;
  isCollapsed: boolean;
  onRemoveFileDraft?: () => unknown;
  onOpenDetailsDrawer?: () => unknown;
  onOpenFileDetailsDrawer?: (item: ContextMountedFile) => void;
  toggle: (forcedValue?: boolean) => void;
};

export type ContextMountedFileFormFields = {
  file: File | null;
  fileBody: string;
};

export type ContextMountedFilesListItemHeaderProps = {
  isCollapsed: boolean;
  item: ContextMountedFile;
  children: ReactNode;
  onOpenEditDrawer?: () => unknown;
};
