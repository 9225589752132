import { gql } from "@apollo/client";

export const GET_STACK_POLICY_RECEIPT_SAMPLE = gql`
  query GetStackPolicyReceiptSample($stackId: ID!, $runId: ID!, $receiptId: ID!) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        policyReceiptSample(id: $receiptId) {
          body
          input
        }
      }
    }
  }
`;

export const GET_MODULE_POLICY_RECEIPT_SAMPLE = gql`
  query GetModulePolicyReceiptSample($stackId: ID!, $runId: ID!, $receiptId: ID!) {
    module(id: $stackId) {
      id
      run(id: $runId) {
        id
        policyReceiptSample(id: $receiptId) {
          body
          input
        }
      }
    }
  }
`;
