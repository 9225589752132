import { ReactNode, memo } from "react";
import cx from "classnames";
import { Row } from "react-aria-components";

import styles from "./styles.module.css";
import TableRowCheckbox from "./RowCheckbox";
import useSelectionStore from "./useSelectionStore";

type TableRowProps = {
  children: ReactNode;
  id: string;
  name: string;
};

const TableRow = ({ children, id, name }: TableRowProps) => {
  const [isActive] = useSelectionStore(({ activeId }) => [activeId === id]);

  return (
    <Row key={id} id={id} className={cx(styles.row, { [styles.active]: isActive })}>
      <TableRowCheckbox name={name} id={id} />
      {children}
    </Row>
  );
};

export default memo(TableRow);
