import styles from "./styles.module.css";
import Box from "../../Box";
import Chart from "./Chart";
import { Datum, PieChartBaseProps } from "./types";
import OrdinalLegendItem from "../components/OrdinalLegend/Item";

type PieChartProps = PieChartBaseProps & {
  legendEnabled?: boolean;
  onLegendClick?: (value: Datum) => void;
  onLegendItemMouseEnter?: (value: string) => void;
  onLegendItemMouseLeave?: () => void;
};

const PieChart = ({
  legendEnabled,
  onLegendClick,
  onLegendItemMouseEnter,
  onLegendItemMouseLeave,
  ...pieChartProps
}: PieChartProps) => {
  return (
    <Box direction="column" align="center" gap="large" fullWidth>
      <Box grow="1" shrink="1" fullWidth className={styles.chartContainer}>
        <Chart {...pieChartProps} />
      </Box>

      {legendEnabled && (
        <Box padding="x-large 0 0 0" justify="center" wrap align="center" gap="medium">
          {pieChartProps.data.map((item) => (
            <OrdinalLegendItem
              key={item.id}
              onClick={onLegendClick ? () => onLegendClick(item) : undefined}
              onMouseEnter={
                onLegendItemMouseEnter ? () => onLegendItemMouseEnter(item.id) : undefined
              }
              onMouseLeave={onLegendItemMouseLeave}
              color={item.color}
              tooltip={onLegendClick ? `Go to ${item.label}` : undefined}
              text={item.label}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PieChart;
