import { useState } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import { Copy, Invisible, Visible } from "components/icons";
import useCopyToClipboard from "hooks/useCopyToClipboard";

import Typography from "../Typography";
import Box from "../Box";

type SecretFieldDetailsProps = {
  secret: string;
  avoidCopy?: boolean;
};

const SecretFieldDetails = ({ secret, avoidCopy }: SecretFieldDetailsProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const copySecret = useCopyToClipboard(secret);

  const handleCopy = () => {
    copySecret();
    setIsVisible(false);
  };

  return (
    <Box direction="row" gap="medium" justify="between">
      <Typography tag="span" variant="p-body2" wordBreak>
        {isVisible ? (
          secret
        ) : (
          <>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</>
        )}
      </Typography>
      <Box direction="row" gap="medium" shrink="0" align="center">
        {!avoidCopy && (
          <ButtonIcon onClick={handleCopy} icon={Copy} variant="ghost">
            Copy
          </ButtonIcon>
        )}
        <ButtonIcon
          onClick={() => setIsVisible(!isVisible)}
          icon={isVisible ? Invisible : Visible}
          variant="ghost"
        >
          {isVisible ? "Hide" : "Show"}
        </ButtonIcon>
      </Box>
    </Box>
  );
};

export default SecretFieldDetails;
