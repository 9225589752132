import { useNavigate, useLocation } from "react-router-dom-v5-compat";

import Callout from "ds/components/Callout";
import useTypedContext from "hooks/useTypedContext";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import { VCS_INTEGRATIONS_PATH } from "views/Account/VCS/constants";

import { StackContext } from "../Context";

const StackDetachedIntegrationCallout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { stack, stackUrl } = useTypedContext(StackContext);

  const sourceCodeUrl = `${stackUrl}/settings/source-code`;

  const isSourceCodePage = location.pathname === sourceCodeUrl;

  if (!stack.vcsDetached) {
    return null;
  }

  const navigateToSourceCodeSettings = () => {
    navigate(sourceCodeUrl);
  };

  const navigateToVCSManagement = () => {
    navigate(VCS_INTEGRATIONS_PATH);
  };

  return (
    <Callout variant="danger">
      Source code {stack.vcsIntegration?.name && <strong>{stack.vcsIntegration.name}</strong>}{" "}
      integration was detached from this stack. Please attach a different integration or set up a
      new one.
      <FeedbackActions>
        {!isSourceCodePage && (
          <Button variant="contrast" size="small" onClick={navigateToSourceCodeSettings}>
            Attach integration
          </Button>
        )}
        <Button variant="secondary" size="small" onClick={navigateToVCSManagement}>
          Set up integration
        </Button>
      </FeedbackActions>
    </Callout>
  );
};

export default StackDetachedIntegrationCallout;
