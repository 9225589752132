import { useModal, create, show } from "@ebay/nice-modal-react";
import { gql, useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

import { EnvironmentType } from "../types";
import { ENVIRONMENT_QUERY } from "../constants";

const DELETE_STACK_CONFIG = gql`
  mutation DeleteStackConfig($stackId: ID!, $configId: ID!) {
    stackConfigDelete(stack: $stackId, id: $configId) {
      id
      type
    }
  }
`;

type DeleteConfirmationProps = {
  id: string;
  stackId: string;
  type: EnvironmentType;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  stackId,
  type,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteConfig, { loading, data }] = useMutation(DELETE_STACK_CONFIG, {
    refetchQueries: [ENVIRONMENT_QUERY[type]],
    variables: { configId: id, stackId },
  });

  const onDelete = () => {
    deleteConfig()
      .then(({ data: { stackConfigDelete: cfg } }) => {
        reportSuccess({ message: `Config ${cfg.id} successfully deleted` });
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete config"
      name={id}
      onConfirm={onDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.stackConfigDelete?.id}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
