import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { StackAwsIntegrationAttachment, StackAzureIntegrationAttachment } from "types/generated";

import { ATTACH_AWS_INTEGRATION, ATTACH_AZURE_INTEGRATION } from "../gql";
import { StackFormContext } from "../../context";
import { StackCreationCloud } from "../../types";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";

type Variables = {
  integrationId: string;
  read: boolean;
  write: boolean;
  azureSubscriptionId?: string;
};

const useAttachCloud = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { createdStackId } = useTypedContext(StackFormContext);
  const [isIntegrationAwsWarningVisible, setIsIntegrationAwsWarningVisible] = useState(false);
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const [attachAwsIntegration, { loading: attachAwsLoading }] = useMutation<{
    awsIntegrationAttach: StackAwsIntegrationAttachment;
  }>(ATTACH_AWS_INTEGRATION, { refetchQueries: ["GetAttachedStackIntegrations"] });

  const hideIntegrationAwsWarning = useCallback(() => setIsIntegrationAwsWarningVisible(false), []);

  const [attachAzureIntegration, { loading: attachAzureLoading }] = useMutation<{
    azureIntegrationAttach: StackAzureIntegrationAttachment;
  }>(ATTACH_AZURE_INTEGRATION, {
    refetchQueries: ["GetAttachedStackIntegrations"],
    awaitRefetchQueries: true,
  });

  const attachAws = useCallback(
    ({ integrationId, read, write }: Variables) => {
      attachAwsIntegration({
        variables: {
          read,
          write,
          integrationId,
          stackId: createdStackId,
        },
      })
        .then(({ data }) => {
          if (data?.awsIntegrationAttach?.id) {
            reportSuccess({ message: "AWS integration attached to stack" });
            trackSegmentEvent("AWS integration attached");
          }
        })
        .catch((e) => {
          if (
            e.message === "you need to configure trust relationship section in your AWS account"
          ) {
            setIsIntegrationAwsWarningVisible(true);
          }
          onError(e);
        });
    },
    [
      attachAwsIntegration,
      reportSuccess,
      onError,
      setIsIntegrationAwsWarningVisible,
      createdStackId,
      trackSegmentEvent,
    ]
  );

  const attachAzure = useCallback(
    ({ integrationId, read, write, azureSubscriptionId }: Variables) => {
      attachAzureIntegration({
        variables: {
          read,
          write,
          integrationId,
          stackId: createdStackId,
          subscriptionId: azureSubscriptionId,
        },
      })
        .then(({ data }) => {
          if (data?.azureIntegrationAttach?.id) {
            reportSuccess({ message: "Azure integration attached to stack" });
            trackSegmentEvent("Azure integration attached");
          }
        })
        .catch(onError);
    },
    [attachAzureIntegration, reportSuccess, onError, createdStackId, trackSegmentEvent]
  );

  const attach = useCallback(
    (type: StackCreationCloud, variables: Variables) => {
      if (type === StackCreationCloud.AWS) {
        return attachAws(variables);
      }

      if (type === StackCreationCloud.Azure) {
        return attachAzure(variables);
      }
    },
    [attachAws, attachAzure]
  );

  return {
    attach,
    attachLoading: attachAzureLoading || attachAwsLoading,
    isIntegrationAwsWarningVisible,
    hideIntegrationAwsWarning,
  };
};

export default useAttachCloud;
