import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";

import { StackSettingsGetTooltipHelperReturnType } from "../../types";

export const getRegionTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Region</TooltipModalTitle>
      <TooltipModalBody align="start">
        Region the CloudFormation stack is supposed to be inside of.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getStackNameTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Stack name</TooltipModalTitle>
      <TooltipModalBody align="start">
        Name of the corresponding CloudFormation stack for this Spacelift stack.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getEntryTemplateFileTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Entry template file</TooltipModalTitle>
      <TooltipModalBody align="start">
        Path to the template file in your repository which describes the root CloudFormation stack.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});

export const getTemplateBucketTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Template bucket</TooltipModalTitle>
      <TooltipModalBody align="start">
        S3 bucket for storing processed CloudFormation templates.
      </TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});
