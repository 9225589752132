import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DescriptionDetails from "components/DescriptionDetails";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { Context } from "types/generated";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";

type ContextDetailsDrawerProps = {
  context: Context;
};

const ContextDetailsDrawer = createDrawer(({ context }: ContextDetailsDrawerProps) => {
  const onOpenFullDescription = useShowFullDescriptionWithBackArrow(context.description);

  return (
    <DrawerSimple isDismissable>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Context details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Name" value={context.name} />

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={context.spaceDetails.name}
            href={`/spaces/${context.spaceDetails.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={context.description}
          onOpenFullDescription={onOpenFullDescription}
        />

        {context.createdAt && (
          <FormFieldTimestamp timestamp={context.createdAt} label="Created at" />
        )}
        {context.updatedAt && (
          <FormFieldTimestamp timestamp={context.updatedAt} label="Updated at" />
        )}

        {context.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={context.labels} alwaysExpanded />
          </FormField>
        )}
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showContextDetailsDrawer = createDrawerTrigger(ContextDetailsDrawer);
