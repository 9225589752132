import cronsTrue from "cronstrue";

export const EMPTY_VALUE = "";

export const convertCronExpression = (value: string) => {
  try {
    return cronsTrue.toString(value.trim());
  } catch {
    return undefined;
  }
};
