import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

// TODO modules-redesign we need to verify with data team what kind of events we want to track
export const useNewModuleAnalyticsSegmentEvent = () => {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageModule.ModuleNew,
  });

  return trackSegmentEvent;
};
