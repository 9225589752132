import { Commands, PhaseName } from "utils/hooks";

export enum ContextCreationWizardStep {
  Details,
  Attachment,
  Environment,
  Hooks,
  Summary,
}

export enum ContextCreationWizardStepName {
  Details = "Details",
  Attachment = "Attach",
  Environment = "Environment",
  Hooks = "Hooks",
  Summary = "Summary",
}

export const contextCreationWizardNavSteps = [
  {
    name: "Context details",
    step: ContextCreationWizardStep.Details,
    shortName: ContextCreationWizardStepName.Details,
  },
  {
    name: "Attach context (optional)",
    step: ContextCreationWizardStep.Attachment,
    shortName: ContextCreationWizardStepName.Attachment,
  },
  {
    name: "Setup environment (optional)",
    step: ContextCreationWizardStep.Environment,
    shortName: ContextCreationWizardStepName.Environment,
  },
  {
    name: "Add hooks (optional)",
    step: ContextCreationWizardStep.Hooks,
    shortName: ContextCreationWizardStepName.Hooks,
  },
  {
    name: "Summary",
    step: ContextCreationWizardStep.Summary,
    shortName: ContextCreationWizardStepName.Summary,
  },
];

export const PROJECTS_LIMIT = 100;

export const defaultHooksValues: Record<PhaseName, Partial<Commands>> = {
  init: {
    before: [],
    after: [],
  },
  plan: {
    before: [],
    after: [],
  },
  apply: {
    before: [],
    after: [],
  },
  destroy: {
    before: [],
    after: [],
  },
  perform: {
    before: [],
    after: [],
  },
  run: {
    after: [],
  },
};

export const CONTEXT_ANALYTICS_VERSION = "New Context v1";
