import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";
import { RunExternalDependencyStatus } from "types/generated";

type ExternalDependencyConfig = {
  variant: BadgeVariants;
  text: string;
};

export const STATUSES: Record<RunExternalDependencyStatus, ExternalDependencyConfig> = {
  [RunExternalDependencyStatus.Failed]: { variant: "red", text: "Failed" },
  [RunExternalDependencyStatus.Finished]: { variant: "green", text: "Finished" },
  [RunExternalDependencyStatus.Pending]: { variant: "blue", text: "Pending" },
};

type ExternalDependencyBadgeProps = {
  state: RunExternalDependencyStatus;
  className?: string;
};

const ExternalDependencyBadge = ({ state, className }: ExternalDependencyBadgeProps) => {
  if (!STATUSES[state]) {
    return null;
  }

  return (
    <BadgeNext variant={STATUSES[state].variant} type="strong" className={className} text={state} />
  );
};

export default ExternalDependencyBadge;
