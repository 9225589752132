import { Pages } from "./types";

export const enum AnalyticsPageNotifications {
  NotificationsList = "NotificationsList",
}

const notifications = "Notifications";

export const pagesNotifications: Pages<AnalyticsPageNotifications> = {
  [AnalyticsPageNotifications.NotificationsList]: [notifications, "Notifications list"],
};
