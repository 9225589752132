import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import Typography from "ds/components/Typography";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";

const UsersFiltersLayoutHeader = () => {
  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Username
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Role
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Space
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Group
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Last login
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Login method
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Status
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default UsersFiltersLayoutHeader;
