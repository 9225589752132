import { gql, TypedDocumentNode } from "@apollo/client";

import type { MutationRunDiscardArgs, Run } from "types/generated";

export type FetchRunDiscardData = { runDiscard: Pick<Run, "id" | "type"> };
export const RUN_DISCARD: TypedDocumentNode<FetchRunDiscardData, MutationRunDiscardArgs> = gql`
  mutation RunDiscard($stack: ID!, $run: ID, $note: String) {
    runDiscard(stack: $stack, run: $run, note: $note) {
      id
      type
    }
  }
`;
