import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationTaskCreateArgs, Run } from "types/generated";

export type FetchStackRunTaskData = { taskCreate: Pick<Run, "id" | "triggeredBy"> };
export const STACK_TASK_CREATE: TypedDocumentNode<FetchStackRunTaskData, MutationTaskCreateArgs> =
  gql`
    mutation TaskCreate($stack: ID!, $command: String!, $skipInitialization: Boolean!) {
      taskCreate(stack: $stack, command: $command, skipInitialization: $skipInitialization) {
        id
        triggeredBy
      }
    }
  `;
