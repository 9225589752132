import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { Module } from "types/generated";

const LATEST_KEY = "latest";

const useLatestModuleVersion = (versionId: string, module: Module): string => {
  const history = useHistory();

  const latestVersionId = module?.latest?.id;

  if (versionId === LATEST_KEY) {
    if (latestVersionId) {
      // return the latest version if there is one
      return latestVersionId;
    } else {
      history.replace(`/module/${module.id}`);
      return versionId;
    }
  }

  return versionId;
};

export default useLatestModuleVersion;
