import { RefObject, createContext, KeyboardEvent } from "react";

type TreeListContextType = {
  expandedKeys: Set<string>;
  renderedKeys: Set<string>;
  onChange?: (id: string) => void;
  onFocus: (id: string) => void;
  onKeyDown: (event: KeyboardEvent, id: string) => void;
  toggleKey: (key: string) => void;
  selectedKey?: string;
  searchQuery?: string;
  selectedRef?: RefObject<HTMLDivElement>;
  disabledKeys?: string[];
  focusedKey?: string;
};

export const TreeListContext = createContext<TreeListContextType | undefined>(undefined);
TreeListContext.displayName = "TreeListContext";
