import Typography from "ds/components/Typography";

// TODO module-redesign verify copy with marketing team
const Documentation = () => (
  <>
    <Typography tag="p" variant="p-t7" margin="0 0 small 0">
      Sharing between accounts
    </Typography>
    <Typography tag="p" variant="p-body2">
      Unlike Stacks, modules can be shared between Spacelift accounts in a sense that while they're
      always managed by a single account, they can be made accessible to an arbitrary number of
      other accounts. In order to share the module with other accounts, please add their names in
      subdomain form (all lowercase)
    </Typography>
  </>
);

export default Documentation;
