import { PolicyType } from "types/generated";

import templates from "./defaultTemplates";
import tooltips from "./tooltips";

type PolicyData = {
  name: string;
  shortLabel: string;
  template: string;
  tooltip: JSX.Element;
};

export const POLICY_TYPES_DATA: { [key in PolicyType]?: PolicyData } = {
  [PolicyType.Access]: {
    name: "Access policy",
    shortLabel: "ACCESS",
    template: templates.ACCESS,
    tooltip: tooltips.ACCESS,
  },
  [PolicyType.GitPush]: {
    name: "Push policy",
    shortLabel: "PUSH",
    template: templates.PUSH,
    tooltip: tooltips.PUSH,
  },
  [PolicyType.Initialization]: {
    name: "Initialization policy",
    shortLabel: "INIT",
    template: templates.INITIALIZATION,
    tooltip: tooltips.INITIALIZATION,
  },
  [PolicyType.Login]: {
    name: "Login policy",
    shortLabel: "LOGIN",
    template: templates.LOGIN,
    tooltip: tooltips.LOGIN,
  },
  [PolicyType.Plan]: {
    name: "Plan policy",
    shortLabel: "PLAN",
    template: templates.PLAN,
    tooltip: tooltips.PLAN,
  },
  [PolicyType.Task]: {
    name: "Task policy",
    shortLabel: "TASK",
    template: templates.TASK,
    tooltip: tooltips.TASK,
  },
  [PolicyType.Trigger]: {
    name: "Trigger policy",
    shortLabel: "TRIGGER",
    template: templates.TRIGGER,
    tooltip: tooltips.TRIGGER,
  },
  [PolicyType.Approval]: {
    name: "Approval policy",
    shortLabel: "APPROVAL",
    template: templates.APPROVAL,
    tooltip: tooltips.APPROVAL,
  },
  [PolicyType.Notification]: {
    name: "Notification policy",
    shortLabel: "NOTIFICATION",
    template: templates.NOTIFICATION,
    tooltip: tooltips.NOTIFICATION,
  },
};
