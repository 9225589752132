import { gql } from "@apollo/client";

export const GET_ATTACHABLE_CLOUD_INTEGRATIONS = gql`
  query GetAttachableCloudIntegrations($spaceId: ID!) {
    attachableAzureIntegrationsInSpace(id: $spaceId) {
      id
      name
      defaultSubscriptionId
    }
    attachableAwsIntegrationsInSpace(id: $spaceId) {
      id
      name
      legacy
    }
  }
`;

export const GET_ATTACHED_MODULE_INTEGRATIONS = gql`
  query GetAttachedModuleIntegrations($moduleId: ID!) {
    module(id: $moduleId) {
      integrations {
        awsV2 {
          id
          read
          write
          durationSeconds
          externalId
          name
          roleArn
          integrationId
        }
        azure {
          id
          read
          write
          defaultSubscriptionId
          subscriptionId
          integrationName
          integrationId
        }
      }
    }
  }
`;

export const ATTACH_AWS_INTEGRATION = gql`
  mutation NewModuleAttachAwsIntegration(
    $integrationId: ID!
    $stackId: ID!
    $read: Boolean!
    $write: Boolean!
  ) {
    awsIntegrationAttach(id: $integrationId, stack: $stackId, read: $read, write: $write) {
      id
    }
  }
`;

export const ATTACH_AZURE_INTEGRATION = gql`
  mutation NewModuleAttachAzureIntegration(
    $integrationId: ID!
    $stackId: ID!
    $read: Boolean!
    $write: Boolean!
    $subscriptionId: String
  ) {
    azureIntegrationAttach(
      id: $integrationId
      stack: $stackId
      read: $read
      write: $write
      subscriptionId: $subscriptionId
    ) {
      id
    }
  }
`;

export const DETACH_AWS_INTEGRATION = gql`
  mutation NewModuleDetachAwsIntegration($attachmentId: ID!) {
    awsIntegrationDetach(id: $attachmentId) {
      id
    }
  }
`;

export const DETACH_AZURE_INTEGRATION = gql`
  mutation NewModuleDetachAzureIntegration($attachmentId: ID!) {
    azureIntegrationDetach(id: $attachmentId) {
      id
    }
  }
`;

export const DETACH_GCP_INTEGRATION = gql`
  mutation NewModuleDetachGcpIntegration($moduleId: ID!) {
    stackIntegrationGcpDelete(id: $moduleId) {
      activated
    }
  }
`;

export const ATTACH_GCP_INTEGRATION = gql`
  mutation NewModuleAttachGcpIntegration($moduleId: ID!, $scopes: [String!]!) {
    stackIntegrationGcpCreate(id: $moduleId, tokenScopes: $scopes) {
      activated
    }
  }
`;
