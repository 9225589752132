import { gql } from "@apollo/client";

export const GET_GITLAB_INTEGRATION = gql`
  query GetGitLabIntegration($id: ID!) {
    gitlabIntegration(id: $id) {
      vcsChecks
      apiHost
      description
      id
      labels
      name
      isDefault
      space {
        id
        name
        accessLevel
      }
      userFacingHost
      webhookSecret
      webhookUrl
    }
  }
`;
