import { useState } from "react";
import cx from "classnames";

import { ConfigElement } from "types/generated";
import ConfigElementComponent from "components/config/view/ConfigElement";
import { Chevron } from "components/icons";

import styles from "./styles.module.css";

type AttachedContextProps = {
  contextName: string;
  config: ConfigElement[];
};

// FYI: the AttachedContext contains legacy styling and structure similar to src/components/Environment/EnvironmentCollapsibleSection
// These both components should be refactored and merged into one
const AttachedContext = ({ contextName, config }: AttachedContextProps) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <div className={styles.attachedContext}>
      <button onClick={toggleShow} className={styles.button}>
        <h3 className={styles.header}>
          <Chevron
            className={cx(styles.chevron, {
              [styles.chevronUp]: show,
            })}
          />
          {contextName}
        </h3>
      </button>
      {show && (
        <div>
          {config.map((element) => (
            <ConfigElementComponent key={element.id + element.type} {...element} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AttachedContext;
