import { RunStateTransition } from "types/generated";

import { canShowElement } from "./elements";
import { RunElementType, RunEntryContext } from "../types";
import InitializingEntry from "../entries/InitializingEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";
import { mergeWithAfterRunHooks } from "./mergeWithAfterRunHooks";

export const createInitializingEntry = (
  { timestamp, until, stateVersion, hasLogs }: RunStateTransition,
  {
    runHooks,
    runId,
    stackId,
    isModuleRun,
    canStop,
    isRunFinished,
    runQueryToRefetch,
  }: RunEntryContext
) => {
  let afterHooks = runHooks.afterInit;

  if (isRunFinished && canShowElement(RunElementType.AfterRunHooks)) {
    afterHooks = mergeWithAfterRunHooks(afterHooks, runHooks.afterRun);
  }

  return (
    <InitializingEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      stateVersion={stateVersion}
      hasLogs={hasLogs}
      openLogsByDefault={canShowElement(RunElementType.Logs)}
      showStopButton={canShowElement(RunElementType.TerminateRunAction)}
      beforeHooks={runHooks.beforeInit}
      afterHooks={afterHooks}
      runId={runId}
      stackId={stackId}
      isModuleRun={isModuleRun}
      canStop={canStop}
      runQueryToRefetch={runQueryToRefetch}
    />
  );
};
