import cx from "classnames";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";

type MessageProps = {
  text: string;
  type: "deny" | "warn";
};

function Message({ text, type }: MessageProps) {
  return (
    <Box className={styles.item} gap="large" fullWidth align="center">
      <span className={cx(styles.type, styles[type])} />

      <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthXl">
        {(props) => (
          <Typography {...props} tag="span" variant="p-body3">
            {text}
          </Typography>
        )}
      </TextEllipsis>
    </Box>
  );
}

export default Message;
