import { Outlet } from "react-router-dom-v5-compat";

import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import Callout from "ds/components/Callout";
import Link from "ds/components/Link";

import styles from "./styles.module.css";
import { FILTERS_ORDER_SETTINGS_KEY } from "../../../Modules/constants";
import { ModuleSettingsContext } from "../../Context";

const MODULE_SETTINGS_INFO_KEY = "moduleSettingsInfoCallout";

const ModuleSettingsRouteLayout = () => {
  const { module, canManageModule } = useTypedContext(ModuleSettingsContext);

  useTitle(module?.name ? `Settings · ${module.name}` : "Settings");

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: getBreadcrumbsBackUrl("/modules", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: module?.name || "",
        link: `/module/${module?.id}`,
      },
      {
        title: "Module settings",
      },
    ],
    [module?.name, module?.id]
  );

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper>
          <ViewHeaderTitle titleSize="p-t3">Module settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>
      <Callout variant="warning" className={styles.banner} storageKey={MODULE_SETTINGS_INFO_KEY}>
        All your attached{" "}
        <Link to={`/module/${module.id}/policies`} size="small" v5Compat>
          policies
        </Link>{" "}
        and{" "}
        <Link to={`/module/${module.id}/contexts`} size="small" v5Compat>
          contexts
        </Link>{" "}
        are now placed in dedicated tabs in the Module navigation.
      </Callout>
      <Box grow="1">
        <SubSidebar>
          <SubSidebarSection>
            <SubSidebarSectionItem to="." v5Compat end>
              Module details
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to="source-code" v5Compat>
              Source code
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to="behavior" v5Compat>
              Behavior
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to="integrations" v5Compat>
              Integrations
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to="sharing" v5Compat>
              Sharing
            </SubSidebarSectionItem>
          </SubSidebarSection>

          {canManageModule && (
            <SubSidebarSection>
              <SubSidebarSectionTitle>Danger area</SubSidebarSectionTitle>
              <SubSidebarSectionItem to="deletion" v5Compat>
                Module deletion
              </SubSidebarSectionItem>
            </SubSidebarSection>
          )}
        </SubSidebar>

        <Box direction="column" className={styles.wrapper}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default ModuleSettingsRouteLayout;
