import FormList from "components/FormDefault/List";
import FormParagraph from "components/FormDefault/Paragraph";
import { getBaseUrl } from "utils/getBaseUrl";

import styles from "./styles.module.css";

const baseUrl = getBaseUrl();

const IdentityProviderConfiguration = () => {
  return (
    <div className={styles.providerConfigurationCopy}>
      <FormParagraph className={styles.paragraph}>
        When setting up Spacelift as a SAML 2.0 application in your identity provider, please use
        the following settings:
      </FormParagraph>
      <FormList className={styles.list}>
        <li>
          <strong>Single sign-on URL:</strong>
          &nbsp;
          <span className={styles.ssoUrl}>{baseUrl}/saml/acs</span>
        </li>
        <li>
          <strong>Entity ID (audience):</strong>
          &nbsp;
          <span className={styles.ssoUrl}>{baseUrl}/saml/metadata</span>
        </li>
      </FormList>
      <FormParagraph className={styles.paragraph}>
        You may want to provide the following optional attributes in the SAML mapping:
      </FormParagraph>
      <FormList className={styles.list}>
        <li>
          <strong>FirstName:</strong>
          &nbsp;
          <span className={styles.ssoExplanation}>is used to build human-friendly user name;</span>
        </li>
        <li>
          <strong>LastName:</strong>
          &nbsp;
          <span className={styles.ssoExplanation}>is used to build human-friendly user name;</span>
        </li>
        <li>
          <strong>Teams:</strong>
          &nbsp;
          <span className={styles.ssoExplanation}>
            can be used by policies to determine access to the Spacelift account and/or individual
            Stacks;
          </span>
        </li>
      </FormList>
    </div>
  );
};

export default IdentityProviderConfiguration;
