import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type ViewHeaderWrapperProps = BoxProps;

const ViewHeaderWrapper = ({ children, className, ...restProps }: ViewHeaderWrapperProps) => (
  <Box {...restProps} className={cx(styles.headerWrapper, className)}>
    {children}
  </Box>
);

export default ViewHeaderWrapper;
