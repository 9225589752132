import React, { forwardRef } from "react";
import cx from "classnames";

import BaseAction, { BaseActionProps } from "ds/components/BaseAction";
import { IconComponent } from "types/Icon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import styles from "./styles.module.css";

export type ServiceActionProps = BaseActionProps &
  AnalyticsCommonProps & {
    icon?: IconComponent;
    children?: React.ReactNode;
  };

const ServiceAction = forwardRef(function ServiceAction(
  props: ServiceActionProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const { className, icon: Icon, children, ...restProps } = props;

  const iconOnly = !children && Icon;

  return (
    <BaseAction
      ref={ref}
      className={cx(className, styles.serviceAction, {
        [styles.iconOnly]: iconOnly,
      })}
      {...restProps}
    >
      {Icon && <Icon className={styles.icon} />}

      {children}
    </BaseAction>
  );
});

export default ServiceAction;
