import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import { StackConfigVendorPulumi } from "types/generated";
import { VENDOR_DICTIONARY } from "constants/vendor";

import { StackSettingsContextData } from "../../Context";
import { getLoginURLTooltip, getStackNameTooltip } from "./getTooltips";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";

const StackSettingsVendorPulumiView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const config = stackSettings.vendorConfig as StackConfigVendorPulumi;

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.PULUMI} />
      <FormFieldViewText
        label="Login URL"
        value={config.loginURL}
        {...getLoginURLTooltip()}
        noMargin
      />
      <FormFieldViewText
        label="Stack name"
        value={config.stackName}
        {...getStackNameTooltip()}
        noMargin
      />
    </>
  );
};

export default StackSettingsVendorPulumiView;
