import { gql } from "@apollo/client";

export const GET_SESSIONS = gql`
  query GetSessions {
    sessions {
      admin
      createdAt
      current
      expiresAt
      id
      ipAddress
      login
      name
      type
    }
  }
`;

export const SESSION_DELETE = gql`
  mutation SessionDelete($id: ID!) {
    sessionDelete(id: $id) {
      id
    }
  }
`;

export const SESSION_DELETE_ALL = gql`
  mutation SessionDeleteAll {
    sessionDeleteAll {
      id
    }
  }
`;
