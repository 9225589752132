import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import useURLParams from "hooks/useURLParams";
import { PublicWorkerPool, SearchWorkerPoolsOutput, WorkerPool } from "types/generated";
import { uniqByKey } from "utils/uniq";
import { getSearchQuery } from "components/SearchInput/helpers";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";

import {
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  ITEMS_LIMIT,
  POLL_INTERVAL,
} from "./constants";
import { SEARCH_WORKER_POOLS } from "./gql";

const isSaas = isSaasDistribution();
const isSelfHosted = isSelfHostedDistribution();

const useSearchWorkerPools = () => {
  const { onError } = useTypedContext(FlashContext);
  const cachedWorkerPoolEdges = useRef<WorkerPool[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { error, loading, data, stopPolling, fetchMore, networkStatus } = useQuery<{
    publicWorkerPool: PublicWorkerPool;
    searchWorkerPools: SearchWorkerPoolsOutput;
  }>(SEARCH_WORKER_POOLS, {
    variables: {
      input: {
        predicates,
        orderBy: sortOptionFields,
        fullTextSearch: searchInput,
        first: ITEMS_LIMIT,
        after: null,
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const memoizedPrivateWorkerPools = useMemo(() => {
    const sourceEdges = data?.searchWorkerPools?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedWorkerPoolEdges.current : sourceEdges;

    if (!loading) {
      cachedWorkerPoolEdges.current = sourceEdges;
    }

    return edges;
  }, [data?.searchWorkerPools?.edges, loading]);

  const loadMoreItems = async () => {
    try {
      if (
        data?.searchWorkerPools.pageInfo.endCursor &&
        data?.searchWorkerPools.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchWorkerPools.edges.length > 0) {
              return {
                publicWorkerPool: prev.publicWorkerPool,
                searchWorkerPools: {
                  ...fetchMoreResult.searchWorkerPools,
                  edges: uniqByKey(
                    [
                      ...(prev.searchWorkerPools.edges || []),
                      ...fetchMoreResult.searchWorkerPools.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data.searchWorkerPools.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              orderBy: sortOptionFields,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  return {
    publicWorkerPool: data?.publicWorkerPool,
    entities: memoizedPrivateWorkerPools,
    error,
    stopPolling,
    isPageLoading: loading && !data?.searchWorkerPools && networkStatus === NetworkStatus.loading,
    isPageEmpty: !!data && !memoizedPrivateWorkerPools.length,
    notFound:
      (isSaas && !loading && (!data?.searchWorkerPools || !data?.publicWorkerPool)) ||
      (isSelfHosted && !loading && !data?.searchWorkerPools),
    loadMoreItems,
    hasNextPage: !!data?.searchWorkerPools?.pageInfo?.hasNextPage,
    isSearchActive: !!searchInput,
  };
};

export default useSearchWorkerPools;
