import React, { createContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { LDContext, useLDClient } from "launchdarkly-react-client-sdk";

import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import { BillingTier, BillingTierFeature } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { identifyHubspotUser } from "shared/Analytics";
import SkeletonLoader from "components/loading/SkeletonLoader";
import useTypedFlags from "hooks/useTypedFlags";

type GetTierGql = {
  onTrialUntil?: number;
  tier: BillingTier;
  tierFeatures: BillingTierFeature[];
};

export const GET_TIER = gql`
  query GetTier {
    onTrialUntil
    tier
    tierFeatures
  }
`;

export type SubscriptionContextType = {
  tier: BillingTier;
  tierFeatures: BillingTierFeature[];
  isTrial: boolean;
};

export const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);
SubscriptionContext.displayName = "SubscriptionContext";

type SubscriptionWrapperProps = {
  children: React.ReactNode;
};

type UserBillingTier = {
  billing_tier: string;
};

type LaunchDarklyUser = LDContext & UserBillingTier;

const SubscriptionWrapper = ({ children }: SubscriptionWrapperProps) => {
  const { accountId, viewer } = useTypedContext(AccountContext);
  const { bananaMode } = useTypedFlags();
  const { onError } = useTypedContext(FlashContext);
  const { data } = useQuery<GetTierGql>(GET_TIER, {
    onError,
  });

  const ldClient = useLDClient();

  useEffect(() => {
    if (bananaMode) {
      document.querySelector("body")?.setAttribute("data-banana-mode", "banana");
    }
  }, [bananaMode]);

  useEffect(() => {
    if (data?.tier) {
      const currentUser = ldClient?.getContext() as LaunchDarklyUser;
      if (currentUser) {
        currentUser.billing_tier = data.tier;

        ldClient?.identify(currentUser);
      }

      identifyHubspotUser({
        accountId: accountId,
        email: viewer.email,
        id: viewer.id,
        provider: viewer.identityProvider,
        name: viewer.name,
        tier: data.tier,
      });
    }
  }, [accountId, viewer, data?.tier, ldClient]);

  const isLoading = !data;

  if (isLoading) {
    return <SkeletonLoader initial />;
  }

  return (
    <SubscriptionContext.Provider
      value={{
        tier: data.tier,
        tierFeatures: data.tierFeatures,
        isTrial: !!data.onTrialUntil,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionWrapper;
