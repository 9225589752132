import moment from "moment";
import { MouseEvent } from "react";

import Box from "ds/components/Box";
import Select from "ds/components/Select";
import FormFieldLabel from "ds/components/Form/Field/Label";
import { PolicyEvaluationRecord } from "types/generated";
import Typography from "ds/components/Typography";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";

import styles from "./styles.module.css";
import { BADGE_STATES } from "./constants";
import EvaluationRecordStatusBadge from "./EvaluationRecordStatusBadge";
import { EvaluationRecordOutcomeStatus } from "./types";

type PolicyDetailsInputSelectProps = {
  inputs: PolicyEvaluationRecord[];
  handleInputKeyChange: (value: string) => void;
  currentPolicyInputKey: string;
};

const PolicyDetailsInputSelect = ({
  inputs,
  handleInputKeyChange,
  currentPolicyInputKey,
}: PolicyDetailsInputSelectProps) => {
  const policyInputsOptions = inputs.map((value) => ({
    label: moment.unix(value.timestamp).format("YYYY-MM-DD HH:mm:ss"),
    value: value.key,
    result: value.outcome,
  }));

  const currentPolicyInput =
    currentPolicyInputKey &&
    policyInputsOptions.find(
      (policyInputsOption) => policyInputsOption.value === currentPolicyInputKey
    );

  const inputKeyValue = currentPolicyInput ? currentPolicyInput.value : "";

  const handleOnChange =
    (value: string, onChange: (value: string) => void, closeSelect?: () => void) =>
    (e?: MouseEvent) => {
      e?.stopPropagation();

      onChange(value);
      closeSelect?.();
    };

  return (
    <Box
      className={styles.simulationInputWrapperField}
      gap="small"
      direction="column"
      justify="end"
    >
      <FormFieldLabel label="Sampled previous inputs" />
      <Select
        selectedValueClassName={inputKeyValue && styles.simulationSelectValueButton}
        renderValueNode={(value) => {
          return (
            <Box justify="between" align="center" __deprecatedGap="1rem" grow="1">
              <Typography tag="span" variant="p-body3">
                {value.label}
              </Typography>
              <EvaluationRecordStatusBadge
                status={BADGE_STATES[value.result] || EvaluationRecordOutcomeStatus.Info}
                text={value.result.toLocaleUpperCase()}
              />
            </Box>
          );
        }}
        renderOption={({ label, result, checked, value, onChange, closeSelect, index }) => (
          <DropdownSectionItem
            onClick={handleOnChange(value, onChange, closeSelect)}
            key={`${index} ${label}`}
            active={checked}
            innerRef={createSelectOptionRef(checked)}
          >
            <Box justify="between" align="center" __deprecatedGap="1rem" grow="1">
              <Typography tag="span" variant={checked ? "p-t7" : "p-body3"}>
                {label}
              </Typography>
              <EvaluationRecordStatusBadge
                status={BADGE_STATES[result] || EvaluationRecordOutcomeStatus.Info}
                text={result.toLocaleUpperCase()}
              />
            </Box>
          </DropdownSectionItem>
        )}
        onChange={handleInputKeyChange}
        options={policyInputsOptions}
        value={inputKeyValue}
      />
    </Box>
  );
};

export default PolicyDetailsInputSelect;
