import Skeleton from "react-loading-skeleton";

export type CodeEditorLoadingSkeletonProps = {
  skeletonCount?: number;
};
const CodeEditorLoadingSkeleton = ({ skeletonCount = 10 }: CodeEditorLoadingSkeletonProps) => {
  return (
    <Skeleton
      count={skeletonCount}
      height="16"
      style={{ width: "calc(100% - 24px)", margin: "12px 12px 0" }}
    />
  );
};

export default CodeEditorLoadingSkeleton;
