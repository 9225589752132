import { Time, getLocalTimeZone, parseAbsolute, toTimeZone } from "@internationalized/date";

export const formatAbsoluteToZonedDateTime = (date: Date, renderTimezone?: string) => {
  const localZonedDateTime = parseAbsolute(date.toISOString(), getLocalTimeZone());

  return renderTimezone ? toTimeZone(localZonedDateTime, renderTimezone) : localZonedDateTime;
};

export const getDateParts = (date: Date, renderTimezone?: string) => {
  const zonedDateTime = formatAbsoluteToZonedDateTime(date, renderTimezone);
  const zonedTime = new Time(zonedDateTime.hour, zonedDateTime.minute);

  return {
    zonedDateTime,
    time: zonedTime,
  };
};
