import { Route, Routes } from "react-router-dom-v5-compat";

import PrivateWorkerPoolWorkers from "./Workers";
import PrivateWorkerPoolQueuedRuns from "./QueuedRuns";
import PrivateWorkerPoolUsedBy from "./UsedBy";

const PrivateWorkerPool = () => {
  return (
    <Routes>
      <Route index element={<PrivateWorkerPoolWorkers />} />
      <Route path="queue" element={<PrivateWorkerPoolQueuedRuns />} />
      <Route path="used-by" element={<PrivateWorkerPoolUsedBy />} />
    </Routes>
  );
};

export default PrivateWorkerPool;
