import FiltersSortHeaderStatic from "components/Filters/SortHeader/Static";

import { COLUMN_GAP, COLUMN_ORDER, STATIC_HEADER_OPTIONS } from "./constants";

const ConfigManagementTreeGridListSortHeaderStatic = () => {
  return (
    <FiltersSortHeaderStatic
      sortOptions={STATIC_HEADER_OPTIONS}
      columnOrder={COLUMN_ORDER}
      columnGap={COLUMN_GAP}
    />
  );
};

export default ConfigManagementTreeGridListSortHeaderStatic;
