import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Maybe } from "types/generated";

import { GET_GITHUB_ENTERPRISE_INTEGRATION } from "./gql";

const useGetGitHubEnterpriseIntegration = (id: Maybe<string>) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error } = useQuery(GET_GITHUB_ENTERPRISE_INTEGRATION, {
    variables: {
      id,
    },
    onError,
    fetchPolicy: "no-cache",
  });

  return {
    integration: data?.githubEnterpriseIntegration,
    isPageLoading: loading && !data?.githubEnterpriseIntegration,
    error,
  };
};

export default useGetGitHubEnterpriseIntegration;
