import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { UPDATE_MODULE } from "components/form/graphql";
import useTypedContext from "hooks/useTypedContext";
import { Module, ModuleUpdateV2Input, VcsProvider } from "types/generated";

type UseUpdateModuleOptions = {
  module: Module;
  onSuccess?: () => void;
};

export const useUpdateModule = ({ module, onSuccess }: UseUpdateModuleOptions) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updateModuleMutation, { loading }] = useMutation(UPDATE_MODULE, {
    variables: { moduleId: module.id },
    refetchQueries: ["GetModuleSettings"],
    awaitRefetchQueries: true,
  });

  const updateModule = async (overrides: Partial<ModuleUpdateV2Input>) => {
    const input: ModuleUpdateV2Input = {
      administrative: module.administrative,
      branch: module.branch,
      description: module.description,
      githubActionDeploy: module.githubActionDeploy,
      labels: module.labels,
      namespace: module.provider === VcsProvider.Github ? null : module.namespace,
      projectRoot: module.projectRoot,
      protectFromDeletion: module.protectFromDeletion,
      localPreviewEnabled: module.localPreviewEnabled,
      provider: module.provider,
      repository: module.repository,
      repositoryURL: module.repositoryURL,
      sharedAccounts: module.sharedAccounts,
      space: module.spaceDetails.id,
      workerPool: module.workerPool ? module.workerPool.id : null,
      workflowTool: module.workflowTool,
      vcsIntegrationId: module.vcsIntegration ? module.vcsIntegration.id : null,
      runnerImage: null,
      ...overrides,
    };

    return updateModuleMutation({ variables: { input } })
      .then(({ data }) => {
        if (data?.moduleUpdateV2) {
          reportSuccess({ message: "Module was successfully updated" });
          onSuccess?.();
        }
      })
      .catch((e) => {
        onError(e);
        throw e;
      });
  };

  return { updateModule, loading };
};
