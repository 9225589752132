import { useRef } from "react";
import cx from "classnames";
import { useToastRegion } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import { useFocusRing } from "react-aria";
import { mergeProps } from "@react-aria/utils";

import FlashMessage from "components/FlashMessage";
import { FlashEvent } from "types/FlashMessage";

import styles from "./styles.module.css";

const TOAST_REGION_PROPS = {};

type FlashMessagesRegionProps = {
  state: ToastState<FlashEvent>;
};

const FlashMessagesRegion = ({ state }: FlashMessagesRegionProps) => {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(TOAST_REGION_PROPS, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div
      {...mergeProps(regionProps, focusProps)}
      ref={ref}
      className={cx(styles.flashMessagesRegion, isFocusVisible && styles.focusVisible)}
      tabIndex={-1}
    >
      {state.visibleToasts.map((toast) => (
        <FlashMessage key={toast.key} toast={toast} state={state} />
      ))}
    </div>
  );
};

export default FlashMessagesRegion;
