import { ReactNode } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ContextHookListItemProps = {
  children: ReactNode;
};

const ContextHookListItem = ({ children }: ContextHookListItemProps) => {
  return (
    <Box padding="0 medium" align="center" justify="between" className={styles.item}>
      {children}
    </Box>
  );
};

export default ContextHookListItem;
