import { gql } from "@apollo/client";

export const GET_REPOSITORIES = gql`
  query GetRepositories($provider: VCSProvider!, $vcsIntegrationId: ID) {
    repositories(provider: $provider, vcsIntegrationID: $vcsIntegrationId) {
      name: id
      namespace
    }
  }
`;

export type GetRepositoriesGql = {
  repositories: { name: string; namespace: string }[];
};
