import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

export const initialSortOption = "name";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const POLL_INTERVAL = 6000;
export const ITEMS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "blueprintsFiltersOrder";
export const COLUMN_ORDER = `minmax(120px, 4.5fr) minmax(90px, 1fr) minmax(110px, 1fr) 10rem ${dropdownEllipsisWidth}`;
export const COLUMN_GAP: Spacing = "large";

export const REFETCH_QUERIES_LIST = ["SearchBlueprints", "SearchBlueprintsSuggestions"];

export const INITIAL_TEMPLATE = `# Check the documentation for more information: ${getDocsUrl("/concepts/blueprint")}
#
# Tip: This code-editor has the same engine as VS Code (Monaco).
# Right-click, then click 'Command Palette' to see a list of available commands.
#
# Examples:
#
# 1. Basic stack configuration with custom stack name, description, and repository
inputs:
  - id: stack_name
    name: The name of the stack
  - id: repository
    name: The git repository of the stack
  - id: space
    name: Name of the space where the stack should be. Defaults to root.
    type: select
    default: root
    options:
      - legacy
      - root
  - id: trigger_run
    name: Trigger a run upon stack creation
    type: boolean
    default: false
stack:
  name: \${{ inputs.stack_name }}
  space: \${{ inputs.space }}
  description: >
    Stack created from a blueprint by \${{ context.user.name }} logged in as \${{ context.user.login }}
  labels:
    - "blueprints/\${{ context.blueprint.name }}"
  vcs:
    branch: main
    repository: \${{ inputs.repository }}
    provider: GITHUB
  vendor:
    terraform:
      manage_state: true
      version: "1.3.0"
options:
  trigger_run: \${{ inputs.trigger_run }}
# 2. A stack with hooks
# inputs:
#   - id: repository
#     name: The git repository of the stack
#   - id: connstring
#     name: The connection string to the database
#     type: secret
# options:
#   # Immediately triggers a run after the stack is created
#   trigger_run: true
# stack:
#   # context object contains a couple of handy properties
#   # such as time, random_string, random_number, random_uuid
#   name: integration-tests-\${{ context.random_string }}
#   space: root
#   description: |
#     This stack was created on \${{ context.time }} for testing hooks.
#   vcs:
#     branch: main
#     # Note that this is just the name of the repository, not the full URL
#     repository: \${{ inputs.repository }}
#     provider: GITHUB
#   environment:
#     variables:
#       - name: TF_VAR_CONNSTRING
#         value: \${{ inputs.connstring }}
#         description: The connection string to the database
#         secret: true
#   hooks:
#     apply:
#       before: ["echo 'before apply'"]
#       after: ["echo 'after apply'"]
#     init:
#       before: ["echo 'before init'"]
#       after: ["echo 'after init'"]
#     plan:
#       before: ["echo 'before plan'"]
#       after: ["echo 'after plan'"]
#     perform:
#       before: ["echo 'before perform'"]
#       after: ["echo 'after perform'"]
#     destroy:
#       before: ["echo 'before destroy'"]
#       after: ["echo 'after destroy'"]
#   vendor:
#     kubernetes:
#       # Notice the quotes around the expression to keep the YAML parser happy
#       namespace: "\${{ inputs.repository.contains('prod') ? 'prod' : 'dev' }}"
# 3. An example showing the stack behavior configurations
# inputs:
#   - id: stack_name
#     name: The name of the stack
#   - id: repository
#     name: The git repository of the stack
# stack:
#   name: \${{ inputs.stack_name }}
#   space: root
#   description: Today is \${{ context.time.getDayOfMonth() }} of \${{ context.time.getMonth() }}.
#   labels:
#     - 'Environment/\${{ inputs.stack_name.contains("prod") ? "Production" : "Development" }}'
#     - Vendor/Cloudformation
#   administrative: false
#   allow_promotion: false
#   auto_deploy: false
#   is_disabled: false
#   auto_retry: false
#   local_preview_enabled: true
#   protect_from_deletion: false
#   runner_image: public.ecr.aws/mycorp/spacelift-runner:latest
#   worker_pool: 01GQ29K8SYXKZVHPZ4HG00BK2E
#   environment:
#     variables:
#       - name: DEPLOYMENT_ID
#         value: \${{ context.random_uuid }}
#   vcs:
#     branch: main
#     repository: \${{ inputs.repository }}
#     provider: GITHUB
#   vendor:
#     terraform:
#       manage_state: true
#       version: "1.3.0"
# 4. Attachable components
# stack:
#   name: random-stack-name-\${{ context.random_string }}
#   space: root
#   worker_pool: 01GQ29K8SYXKZVHPZ4HG00BK2E
#   attachments:
#     contexts:
#       - id: my-first-context-vnfq2
#         priority: 1
#     clouds:
#       aws:
#         id: 01GQ29K8SYXKZVHPZ4HG00BK2E
#         read: true
#         write: true
#       azure:
#         id: 01GQ30K2SYXKZVHMX4HG32KD4A
#         read: true
#         write: true
#         subscription_id: 12345678-1234-1234-1234-123456789012
#     policies:
#       - my-push-policy-1
#       - my-approval-policy-1
#   environment:
#     mounted_files:
#       - path: auth.json
#         content: |
#           {
#             "a": "b"
#           }
#   vcs:
#     branch: main
#     repository: \${{ inputs.repository }}
#     provider: GITHUB
#   vendor:
#     terraform:
#       manage_state: true
#       version: "1.3.0"
# 5. Schedules
# inputs:
#   - id: repository
#     name: The git repository of the stack
# stack:
#   name: schedules-stack-\${{ context.random_string }}
#   space: root
#   vcs:
#     branch: main
#     repository: \${{ inputs.repository }}
#     provider: GITHUB
#   schedules:
#     drift:
#       cron:
#         - "0 0 * * *"
#         - "5 5 * * 0"
#       reconcile: true
#       ignore_state: true # If true, the schedule will run even if the stack is in a failed state
#       timezone: UTC
#     tasks:
#       # You need to provide either a cron or an epoch timestamp
#       - command: "terraform apply -auto-approve"
#         cron:
#           - "0 0 * * *"
#       - command: "terraform apply -auto-approve"
#         timestamp_unix: \${{ int(timestamp('2024-01-01T10:00:20.021-05:00')) }}
#     delete:
#       delete_resources: \${{ inputs.repository != 'prod' }}
#       timestamp_unix: \${{ int(context.time) + 1800 }}
#       # int(google.protobuf.Timestamp) converts the timestamp object to an epoch timestamp
#       # https://github.com/google/cel-spec/blob/v0.7.1/doc/langdef.md#list-of-standard-definitions
#   vendor:
#     terraform:
#       manage_state: true
#       version: "1.3.0"`;
