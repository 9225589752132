import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { ManagedUserGroup } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import { GET_MANAGED_USER_GROUP } from "../gql";

type useGetGroupProps = {
  id?: string;
};

const useGetGroup = ({ id }: useGetGroupProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading, error } = useQuery<{ managedUserGroup: ManagedUserGroup }>(
    GET_MANAGED_USER_GROUP,
    {
      onError,
      variables: {
        id,
      },
      skip: !id,
    }
  );

  return { group: data?.managedUserGroup, loading, error };
};

export default useGetGroup;
