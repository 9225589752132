import SystemMessage from "components/SystemMessage";
import { HandColored } from "components/icons";
import Button from "ds/components/Button";
import useTitle from "hooks/useTitle";

const SelfHostedLogout = () => {
  useTitle("See you later · Spacelift");

  return (
    <SystemMessage
      title="See you later!"
      message="You have been successfully logged out. If you would like to log in again use the login button."
      icon={HandColored}
    >
      <Button to="/login" variant="primary" fullWidth>
        Login again
      </Button>
    </SystemMessage>
  );
};

export default SelfHostedLogout;
