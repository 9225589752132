import { RunState } from "types/generated";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import RunPhaseLogs from "../../components/RunPhaseLogs";
import { RunHistoryEntryType } from "../../types";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";

type DestroyingEntryProps = {
  timestamp: number;
  until: number | null;
  hasLogs: boolean;
  beforeHooks: string[] | undefined;
  afterHooks: string[] | undefined;
  stateVersion: number | null;
  openLogsByDefault: boolean;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const DestroyingEntry = ({
  timestamp,
  until,
  hasLogs,
  stateVersion,
  beforeHooks,
  afterHooks,
  openLogsByDefault,
  runId,
  stackId,
  isModuleRun,
}: DestroyingEntryProps) => {
  const [showLogs, toggleLogs] = useVisibilityToggle(openLogsByDefault, hasLogs);

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.Destroying}
      timestamp={timestamp}
      until={until}
      isLoading={!until}
      onClick={toggleLogs}
      isOpen={showLogs}
    >
      {showLogs && (
        <RunPhaseLogs
          beforeHooks={beforeHooks}
          afterHooks={afterHooks}
          state={RunState.Destroying}
          stateVersion={stateVersion}
          until={until}
          runId={runId}
          stackId={stackId}
          isModuleRun={isModuleRun}
        />
      )}
    </HistoryEntryWrapper>
  );
};

export default DestroyingEntry;
