import { Run } from "types/generated";
import State from "components/state/State";
import ListBoxLink from "components/listBox/Link";
import VendorVersion from "components/VendorVersion";
import { LogoTerraform } from "components/icons";

import styles from "./styles.module.css";

type RunItemProps = {
  run: Run;
  moduleId: string;
};

const RunItem = (props: RunItemProps) => {
  const { run, moduleId } = props;

  return (
    <div className={styles.prListRunWrapper}>
      <State type={run.state} full compact />

      <div className={styles.prListRunContent}>
        <h3 className={styles.prListRunName}>
          <ListBoxLink
            url={`/module/${moduleId}/run/${run.id}`}
            text={run.expectFailure ? `${run.title} (should fail)` : run.title}
            routerLink
            headerLink
          />

          {run.runtimeConfig && (
            <div className={styles.prListRunIcons}>
              <VendorVersion
                icon={LogoTerraform}
                text={run.runtimeConfig?.terraform?.version || undefined}
              />
            </div>
          )}
        </h3>
      </div>
    </div>
  );
};

export default RunItem;
