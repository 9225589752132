import { useMemo } from "react";
import { scaleOrdinal } from "@visx/scale";

import styles from "./styles.module.css";
import Box from "../../Box";
import Chart from "./Chart";
import { LineChartBaseProps } from "./types";

const LineChart = (lineChartProps: LineChartBaseProps) => {
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: lineChartProps.items,
        range: lineChartProps.colors,
      }),
    [lineChartProps.colors, lineChartProps.items]
  );

  return (
    <Box className={styles.lineChart} direction="column" align="center" gap="large" fullWidth>
      <Box grow="1" shrink="1" fullWidth className={styles.chartContainer}>
        <Chart {...lineChartProps} colorScale={colorScale} />
      </Box>
    </Box>
  );
};

export default LineChart;
