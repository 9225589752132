import { SyntaxTheme } from "types/SyntaxTheme";

export const AVAILABLE_SYNTAX_THEMES = [
  {
    key: SyntaxTheme.Light,
    title: "Light",
  },
  {
    key: SyntaxTheme.Dark,
    title: "Dark",
  },
  {
    key: SyntaxTheme.Nord,
    title: "Nord",
  },
  {
    key: SyntaxTheme.Dracula,
    title: "Dracula",
  },
  {
    key: SyntaxTheme.SolarizedLight,
    title: "Solarized Light",
  },
  {
    key: SyntaxTheme.SolarizedDark,
    title: "Solarized Dark",
  },
];
