import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import CopyFieldIcon from "components/CopyField/Icon";

import styles from "./styles.module.css";

type VCSWebhookEndpointFieldProps = {
  integrationNameValue: string;
  webhookUrlValue: string;
};

const VCSWebhookEndpointField = ({
  integrationNameValue,
  webhookUrlValue,
}: VCSWebhookEndpointFieldProps) => {
  return (
    <FormField label="Webhook endpoint">
      {({ ariaInputProps }) => (
        <Box gap="medium" grow="0" fullWidth>
          <Input
            placeholder="Enter the integration name to generate the webhook endpoint"
            value={integrationNameValue && webhookUrlValue}
            disabled
            {...ariaInputProps}
          />

          <CopyFieldIcon
            disabled={!integrationNameValue}
            value={webhookUrlValue}
            className={styles.copyField}
            title="Copy webhook endpoint"
          />
        </Box>
      )}
    </FormField>
  );
};

export default VCSWebhookEndpointField;
