import { ReactNode } from "react";

import { VcsCheck, VcsIntegrationDetails } from "types/generated";
import { VCS_INTEGRATION_DETAILS_UNION } from "constants/vcs_providers";
import { capitalizeFirstLetter } from "utils/strings";

import VCSIntegrationsDetailsGitHub from "./GitHub";
import VCSIntegrationsDetailsGitHubEnterprise from "./GitHubEnterprise";
import VCSIntegrationsDetailsGitLab from "./GitLab";
import VCSIntegrationsDetailsAzureDevOps from "./AzureDevOps";
import VCSIntegrationsDetailsBitbucketCloud from "./BitbucketCloud";
import VCSIntegrationsDetailsBitbucketDataCenter from "./BitbucketDataCenter";

export const getIntegrationDetails = (details: VcsIntegrationDetails): ReactNode => {
  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.GITHUB_INTEGRATION) {
    return <VCSIntegrationsDetailsGitHub details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION) {
    return <VCSIntegrationsDetailsGitHubEnterprise details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.GITLAB_INTEGRATION) {
    return <VCSIntegrationsDetailsGitLab details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.AZURE_DEVOPS_REPO_INTEGRATION) {
    return <VCSIntegrationsDetailsAzureDevOps details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.BITBUCKET_CLOUD_INTEGRATION) {
    return <VCSIntegrationsDetailsBitbucketCloud details={details} />;
  }

  if (details.__typename === VCS_INTEGRATION_DETAILS_UNION.BITBUCKET_DATACENTER_INTEGRATION) {
    return <VCSIntegrationsDetailsBitbucketDataCenter details={details} />;
  }

  return null;
};

export const VCSCheckLabels: { [key: string]: string } = {
  [VcsCheck.Aggregated]: capitalizeFirstLetter(VcsCheck.Aggregated.toLowerCase()),
  [VcsCheck.Individual]: capitalizeFirstLetter(VcsCheck.Individual.toLowerCase()),
  [VcsCheck.All]:
    capitalizeFirstLetter(VcsCheck.Individual.toLowerCase()) +
    ", " +
    capitalizeFirstLetter(VcsCheck.Aggregated.toLowerCase()),
};
