import Box from "ds/components/Box";

import { CodeChangesValueNode } from "../types";
import Value from "./Value";
import Label from "./Label";
import Prefix from "./Prefix";

type RowProps = {
  item: CodeChangesValueNode;
};

const Row = ({ item }: RowProps) => {
  return (
    <Box gap="medium">
      <Prefix changeType={item.type} />
      <Label keyName={item.key} />
      <Value item={item} />
    </Box>
  );
};

export default Row;
