import { Stack } from "types/generated";
import DeltaCounts from "components/DeltaCounts";
import { isAnsibleStackVendor } from "utils/stack";

type StackListItemDeltaCellProps = { stack: Stack };

const StackListItemDeltaCell = ({ stack }: StackListItemDeltaCellProps) => {
  if (!stack.delta) {
    return null;
  }

  return (
    <DeltaCounts
      size="small"
      align="start"
      variant="flexible"
      direction="column"
      delta={stack.delta}
      isAnsible={isAnsibleStackVendor(stack)}
    />
  );
};

export default StackListItemDeltaCell;
