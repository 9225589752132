import { useMatch } from "react-router-dom-v5-compat";

import Box from "ds/components/Box";
import Badge from "ds/components/Badge";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { NavigationItem } from "./types";

type ViewHeaderNavigationResizeItemProps<T> = {
  item: NavigationItem;
  sharedState?: T;
};

const ViewHeaderNavigationResizeItem = <T,>({
  item,
  sharedState,
}: ViewHeaderNavigationResizeItemProps<T>) => {
  const match = useMatch(item.link);

  const isActive = !!match?.pattern.end;
  return (
    <DropdownMenuItem
      key={item.link}
      active={isActive}
      href={item.link}
      routerOptions={sharedState ? { state: sharedState } : undefined}
    >
      {item.beta ? (
        <Box gap="medium" align="center">
          {item.title}
          <Badge size="small" state="success">
            BETA
          </Badge>
        </Box>
      ) : (
        item.title
      )}
    </DropdownMenuItem>
  );
};

export default ViewHeaderNavigationResizeItem;
