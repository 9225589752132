import React from "react";
import cx from "classnames";

import Col from "components/column/Col";
import { Info } from "components/icons";

import styles from "./styles.module.css";

type InfoBoxProps = {
  className?: string;
  noMargin?: boolean;
  children: React.ReactNode;
};

const InfoBox = ({ children, className, noMargin }: InfoBoxProps) => (
  <div
    className={cx(styles.wrapper, className, {
      [styles.wrapperNoMargin]: noMargin,
    })}
  >
    <div className={styles.text}>{children}</div>
    <Col className={styles.icon} auto>
      <Info />
    </Col>
  </div>
);

export default InfoBox;
