import { gql, TypedDocumentNode } from "@apollo/client";

import { FeatureFlagEvaluationResult, QueryEvaluateFeatureFlagsArgs } from "types/generated";

type FetchSelfHostedFlagsData = { evaluateFeatureFlags: Array<FeatureFlagEvaluationResult> };
export const GET_SELF_HOSTED_FLAGS: TypedDocumentNode<
  FetchSelfHostedFlagsData,
  QueryEvaluateFeatureFlagsArgs
> = gql`
  query SelfHostedFlags($input: FeatureFlagEvaluationInput!) {
    evaluateFeatureFlags(input: $input) {
      name
      value
    }
  }
`;
