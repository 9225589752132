import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";

import StackSettingsIntegrationsWebhooksListItem from ".";
import { WebhookIntegrationWithTimestamp } from "./types";

type StackSettingsIntegrationsWebhooksListItemVirtualizedProps = {
  items: WebhookIntegrationWithTimestamp[];
  stackId: string;
  onEditClick: (item: WebhookIntegrationWithTimestamp) => void;
  onDetailsClick: (item: WebhookIntegrationWithTimestamp) => void;
  canManageStack: boolean;
};

const StackSettingsIntegrationsWebhooksListItemVirtualized = (
  props: ListChildComponentProps<
    StackSettingsIntegrationsWebhooksListItemVirtualizedProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const item = data.items[index];
  const key = item.id;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <StackSettingsIntegrationsWebhooksListItem
        key={key}
        item={item}
        setRowHeight={handleSetRowHeight}
        stackId={data.stackId}
        onEditClick={data.onEditClick}
        onDetailsClick={data.onDetailsClick}
        canManageStack={data.canManageStack}
      />
    </div>
  );
};

export default memo(StackSettingsIntegrationsWebhooksListItemVirtualized, areEqual);
