export enum DrawerTabs {
  Details = "Details",
  Logs = "Logs",
  Diff = "Diff",
}

export type Diff = Record<
  string,
  { before: string | Record<string, unknown>; after: string | Record<string, unknown> }
>;
