import { BulkActionsApplicableAction } from "components/BulkActions/ChooseActionStep/types";
import {
  BulkActionItemID,
  BulkActionsActionsPerItem,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import { Notification } from "types/generated";
import BulkActionsDrawerActionStepsItems from "components/BulkActions/Drawer/ActionSteps/Items";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";

import { NotificationsBulkActions } from "./types";
import NotificationsBulkActionsSelectedItem from "./SelectedItem";

type NotificationsBulkActionsSelectedItemsViewProps = {
  items: Notification[];
  onItemDismiss: (id: BulkActionItemID) => void;
  availableActions: Array<BulkActionsApplicableAction<NotificationsBulkActions>>;
  actionsPerItem: BulkActionsActionsPerItem<NotificationsBulkActions>;
  onEmptyActionsCancel: () => void;
};

const NotificationsBulkActionsSelectedItemsView = ({
  items,
  onItemDismiss,
  actionsPerItem,
  availableActions,
  onEmptyActionsCancel,
}: NotificationsBulkActionsSelectedItemsViewProps) => {
  return (
    <>
      <BulkActionsDrawerActionStepsItems selectedItemsCount={items.length}>
        {items.map((item) => (
          <NotificationsBulkActionsSelectedItem
            key={item.id}
            item={item}
            onDismiss={onItemDismiss}
            itemActions={actionsPerItem.get(item.id)}
            allActionsQty={availableActions.length}
            step={BulkActionsStep.ChooseAction}
          />
        ))}
      </BulkActionsDrawerActionStepsItems>

      <BulkActionsDrawerActionStepsFooter>
        <BulkActionsPatternsActionsList
          variant={BulkActionsVariant.Drawer}
          availableActions={availableActions}
          onEmptyActionsCancel={onEmptyActionsCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default NotificationsBulkActionsSelectedItemsView;
