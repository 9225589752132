import { useEffect, useRef } from "react";
import { nanoid } from "nanoid";

import useTypedContext from "hooks/useTypedContext";

import { RunElementsContext } from "../RunElementsContext";
import { RunShortcut } from "../types";

export const useRunShortcut = ({
  label,
  callback,
  disabled,
  code,
  loading,
}: Omit<RunShortcut, "id">) => {
  const { setShortcuts } = useTypedContext(RunElementsContext);
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = nanoid();

    setShortcuts((prev) =>
      prev.concat({
        id,
        label,
        callback: callbackRef.current,
        disabled,
        code,
        loading,
      })
    );

    return () => {
      setShortcuts((prev) => prev.filter((action) => action.id !== id));
    };
  }, [label, disabled, code, loading, setShortcuts]);
};
