import { type ReactNode } from "react";

import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";

type SettingsNewVendorViewVersionProps = {
  label: string;
  version: string;
  versionTooltip: ReactNode;
  effectiveVersion?: string | null;
  effectiveVersionTooltip: string;
};

const SettingsNewVendorViewVersion = ({
  label,
  version,
  versionTooltip,
  effectiveVersion,
  effectiveVersionTooltip,
}: SettingsNewVendorViewVersionProps) =>
  effectiveVersion ? (
    <FormField label={label} tooltipInfo={versionTooltip} tooltipInfoVariant="modal" noMargin>
      <Typography tag="span" variant="p-body2">
        {version}{" "}
        <Tooltip
          on={(props) => (
            <Typography {...props} tag="span" variant="p-body3">
              (Effective: {effectiveVersion})
            </Typography>
          )}
          widthMode="maxWidthSm"
        >
          {effectiveVersionTooltip}
        </Tooltip>
      </Typography>
    </FormField>
  ) : (
    <FormFieldViewText
      label={label}
      value={version}
      tooltipInfo={versionTooltip}
      tooltipInfoVariant="modal"
      noMargin
    />
  );

export default SettingsNewVendorViewVersion;
