import { gql } from "@apollo/client";

export const GET_ATTACHABLE_CONTEXTS_IN_SPACE = gql`
  query GetAttachableContextsInSpace($id: ID!) {
    attachableContextsInSpace(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

const attachedContextFragment = gql`
  fragment attachedContext on StackContextAttachment {
    id
    contextId
    contextName
    contextDescription
    contextLabels
    isAutoattached
    priority
  }
`;

export const GET_STACK_ATTACHED_CONTEXTS = gql`
  query GetStackAttachedContexts($entityId: ID!) {
    entity: stack(id: $entityId) {
      attachedContexts {
        ...attachedContext
      }
    }
  }
  ${attachedContextFragment}
`;

export const GET_MODULE_ATTACHED_CONTEXTS = gql`
  query GetModuleAttachedContexts($entityId: ID!) {
    entity: module(id: $entityId) {
      attachedContexts {
        ...attachedContext
      }
    }
  }
  ${attachedContextFragment}
`;

export const ATTACH_CONTEXT_TO_ENTITY = gql`
  mutation StackAttachContext($entityId: ID!, $contextId: ID!, $priority: Int!) {
    contextAttach(stack: $entityId, id: $contextId, priority: $priority) {
      id
      isAutoattached
      isModule
      priority
      stackId
      stackName
    }
  }
`;

export const DETACH_CONTEXT_FROM_ENTITY = gql`
  mutation StackDetachContext($id: ID!) {
    contextDetach(id: $id) {
      id
    }
  }
`;
