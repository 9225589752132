import { ReactNode } from "react";

import styles from "./styles.module.css";

type HistoryTimelineProps = {
  children: ReactNode;
};

const HistoryTimeline = ({ children }: HistoryTimelineProps) => {
  return <ol className={styles.wrapper}>{children}</ol>;
};

export default HistoryTimeline;
