import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";

import { COLUMN_GAP, COLUMN_ORDER, FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "../constants";

function ContextsFiltersLayoutHeader() {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();
  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.Name, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.Space, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />

      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.CreatedAt, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
}

export default ContextsFiltersLayoutHeader;
