import { useMemo } from "react";
import { noop } from "lodash-es";
import { NetworkStatus } from "@apollo/client";

import { LaunchPadFeatureSelfHosted } from "./types";
import { LaunchPadsFeatureProgress } from "../types";

// TODO: wait for API to be implemented
const useSelfHostedOnboardingProgress = () => {
  const progressByFeature: LaunchPadsFeatureProgress<LaunchPadFeatureSelfHosted> = useMemo(
    () => ({
      [LaunchPadFeatureSelfHosted.ConfigureSSO]: {
        isCompleted: false,
      },
      [LaunchPadFeatureSelfHosted.CreateWorkerPool]: {
        isCompleted: true,
      },
      [LaunchPadFeatureSelfHosted.SetupSlackIntegration]: {
        isCompleted: false,
      },
      [LaunchPadFeatureSelfHosted.SetupVCSIntegration]: {
        isCompleted: false,
      },
      [LaunchPadFeatureSelfHosted.InviteTeammates]: {
        isCompleted: true,
      },
    }),
    []
  );

  const isOnboardingComplete = Object.values(progressByFeature).every((value) => value.isCompleted);

  return {
    onboardingProgressInitialLoading: false,
    progressByFeature,
    isOnboardingComplete,
    error: undefined,
    refetch: noop,
    loading: false,
    networkStatus: NetworkStatus.ready,
  };
};

export default useSelfHostedOnboardingProgress;
