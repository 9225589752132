import { useModal, create, show } from "@ebay/nice-modal-react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import useDeleteWebhook from "shared/Webhook/useDeleteWebhook";
import { REFETCH_STACK_SETTINGS_QUERIES } from "views/Stack/Settings/constants";

type StackSettingsIntegrationsWebhooksDropdownProps = {
  id: string;
  stackId: string;
  endpoint: string;
  refetchQueriesOnDelete?: string[];
};

const StackSettingsIntegrationsWebhooksDropdown = create(
  function StackSettingsIntegrationsWebhooksDropdown({
    id,
    stackId,
    endpoint,
  }: StackSettingsIntegrationsWebhooksDropdownProps) {
    const modal = useModal();

    const { deleteWebhook, loading } = useDeleteWebhook({
      refetchQueries: REFETCH_STACK_SETTINGS_QUERIES,
    });

    const handleDeleteWebhook = () => {
      deleteWebhook({
        webhookId: id,
        entityId: stackId,
        successCallback: () => {
          modal.resolve();
          modal.hide();
        },
      });
    };

    return (
      <DeleteConfirmationModal
        title="Delete webhook integration"
        onConfirm={handleDeleteWebhook}
        isLoading={loading}
        isDismissable={!loading}
      >
        <ConfirmationModalMessage>
          Are you sure you want to delete the webhook integration with the endpoint:{" "}
          <strong>{endpoint}</strong>?
        </ConfirmationModalMessage>
      </DeleteConfirmationModal>
    );
  }
);

export const showDeleteConfirmation = (props: StackSettingsIntegrationsWebhooksDropdownProps) =>
  show(StackSettingsIntegrationsWebhooksDropdown, props);
