import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  MutationStackScheduledRunCreateArgs,
  ScheduledRun,
  ScheduledRunInput,
} from "types/generated";

import { CREATE_STACK_SCHEDULED_RUN } from "./gql";

type UseStackScheduledRunCreate = {
  refetchQueries?: string[];
};

const useStackScheduledRunCreate = ({ refetchQueries }: UseStackScheduledRunCreate) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackScheduledRunCreate, { loading }] = useMutation<
    {
      stackScheduledRunCreate: ScheduledRun;
    },
    MutationStackScheduledRunCreateArgs
  >(CREATE_STACK_SCHEDULED_RUN, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError,
  });

  const createScheduledRun = useCallback(
    (stack: string, input: ScheduledRunInput, successCallback?: () => void) => {
      stackScheduledRunCreate({
        variables: {
          stack,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledRunCreate) {
            reportSuccess({
              message: "Scheduled run was created successfully",
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackScheduledRunCreate]
  );

  return {
    createScheduledRun,
    loading,
  };
};

export default useStackScheduledRunCreate;
