import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GetGcpIntegrationAttachmentGql } from "../types";
import { GET_MODULE_ATTACHED_GCP_INTEGRATION } from "../gql";

const useAttachedGcpIntegration = (moduleId: string | undefined) => {
  const { onError } = useTypedContext(FlashContext);

  const { loading, data } = useQuery<GetGcpIntegrationAttachmentGql>(
    GET_MODULE_ATTACHED_GCP_INTEGRATION,
    {
      onError,
      variables: {
        moduleId,
      },
      skip: !moduleId,
      fetchPolicy: "no-cache",
    }
  );

  return {
    integration: data?.module?.integrations?.gcp,
    loading,
  };
};

export default useAttachedGcpIntegration;
