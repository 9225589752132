import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import useDeleteOutputReference from "./useDeleteOutputReference";
import { showOutputReferencesDeleteConfirmationModal } from "./DeleteConfirmationModal";

type OutputReferencesDrawerDeleteActionProps = {
  name: string;
  referenceId: string;
};

const OutputReferencesDrawerDeleteAction = ({
  name,
  referenceId,
}: OutputReferencesDrawerDeleteActionProps) => {
  const { deleteOutputReference } = useDeleteOutputReference();

  const handleDelete = () => {
    deleteOutputReference(referenceId);
  };

  const onModalShow = () => {
    showOutputReferencesDeleteConfirmationModal({ name, onConfirm: handleDelete });
  };

  return (
    <DropdownMenuItem onAction={onModalShow} danger>
      Delete reference
    </DropdownMenuItem>
  );
};

export default OutputReferencesDrawerDeleteAction;
