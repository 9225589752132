import { useEffect, useState, useCallback } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import Button from "ds/components/Button";
import FormGroup from "components/FormDefault/Group";
import FormFooter from "components/FormDefault/Footer";
import { AwsIntegration } from "types/generated";
import Select from "components/select/Select";
import TagInput from "components/TagInput";
import useTypedContext from "hooks/useTypedContext";
import formStyles from "components/FormDefault/styles.module.css";
import { DEFAULT_SPACE_NAME } from "views/constants";
import SpaceOption from "components/select/SpaceOption";
import { SpacesContext } from "views/Account/SpacesProvider";
import { getDuration } from "utils/time";

import styles from "./styles.module.css";

type AwsIntegrationFormProps =
  | {
      onCreate: (
        name: string,
        roleArn: string,
        externalId: string,
        labels: string[],
        durationSeconds: number,
        generateCredentialsInWorker: boolean,
        space?: string
      ) => void;
      onCancel: () => void;
      integration?: undefined;
      loading: boolean;
    }
  | {
      onUpdate: (
        id: AwsIntegration["id"],
        name: string,
        roleArn: string,
        externalId: string,
        labels: string[],
        durationSeconds: number,
        generateCredentialsInWorker: boolean,
        space?: string
      ) => void;
      onCancel: () => void;
      integration: AwsIntegration;
      loading: boolean;
    };

const AzureIntegrationForm = (props: AwsIntegrationFormProps) => {
  const { manageableSpaces, manageableSpacesSelectOptions } = useTypedContext(SpacesContext);
  const { onCancel, loading, integration } = props;
  const isEditing = !!integration;

  const [name, setName] = useState(integration?.name || "");
  const [roleArn, setRoleArn] = useState(integration?.roleArn || "");
  const [externalId, setExternalId] = useState(integration?.externalId || "");
  const [generateCredentialsInWorker, setGenerateCredentialsInWorker] = useState(
    integration?.generateCredentialsInWorker || false
  );
  const [durationSeconds, setDurationSecond] = useState(integration?.durationSeconds || 3600);
  const [labels, setLabels] = useState<string[]>(integration?.labels || []);
  const [canAdd, setCanAdd] = useState(false);
  const [space, setSpace] = useState(
    integration
      ? integration?.spaceDetails.id
      : manageableSpaces.find((s) => s.id === DEFAULT_SPACE_NAME)?.id || manageableSpaces[0].id
  );

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (props.integration) {
        props.onUpdate(
          props.integration.id,
          name,
          roleArn,
          externalId,
          labels,
          durationSeconds,
          generateCredentialsInWorker,
          space
        );
      } else {
        props.onCreate(
          name,
          roleArn,
          externalId,
          labels,
          durationSeconds,
          generateCredentialsInWorker,
          space
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.integration,
      name,
      roleArn,
      externalId,
      labels,
      durationSeconds,
      generateCredentialsInWorker,
      space,
    ]
  );

  useEffect(() => {
    setCanAdd(!!name && !!roleArn && !loading);
  }, [name, roleArn, loading]);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setter(e.target.value);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Name:"
        type="text"
        placeholder="Name (required)"
        value={name}
        onChange={handleInputChange(setName)}
      />
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Role ARN:"
        type="text"
        placeholder="Role (required)"
        value={roleArn}
        onChange={handleInputChange(setRoleArn)}
      />
      {manageableSpacesSelectOptions && (
        <FormGroup labelText="Space:" labelClassName={styles.formLabel}>
          <Select
            className={formStyles.input}
            onChange={({ value }) => setSpace(value as string)}
            options={manageableSpacesSelectOptions}
            value={manageableSpacesSelectOptions.find((option) => option.value === space)}
            optionComponent={SpaceOption}
          />
        </FormGroup>
      )}
      <FormGroup labelText="Labels:" labelClassName={styles.formLabel}>
        <TagInput entity="Label" placeholder="Add labels" tags={labels} setTags={setLabels} />
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        id="generateCredentialsInWorker"
        labelText="Assume role on worker:"
        type="checkbox"
        checked={generateCredentialsInWorker}
        onChange={() => setGenerateCredentialsInWorker(!generateCredentialsInWorker)}
      />

      {generateCredentialsInWorker && (
        <FormGroup
          labelClassName={styles.formLabel}
          labelText="External ID:"
          type="text"
          placeholder="Paste external ID"
          value={externalId}
          onChange={handleInputChange(setExternalId)}
        />
      )}
      <FormGroup labelText="Duration:" labelClassName={styles.formLabel}>
        <div>
          <p className={styles.durationText}>
            Set the duration of the role session. The value specified can range from 15 minutes up
            to the maximum session duration that is set in AWS IAM for the role, though no longer
            than {generateCredentialsInWorker ? "12 hours" : "1 hour"}.
          </p>
          <div className={styles.durationWrapper}>
            <Slider
              value={durationSeconds}
              styles={{
                handle: {
                  borderColor: "var(--semantic-color-surface-status-success-default)",
                },
                track: {
                  backgroundColor: "var(--semantic-color-surface-status-success-default)",
                },
              }}
              className={styles.slider}
              min={900}
              max={generateCredentialsInWorker ? 43200 : 3600}
              step={generateCredentialsInWorker ? 900 : 300}
              onChange={(value) => setDurationSecond(value as number)}
            />
            <span className={styles.duration}>{` ${getDuration(durationSeconds)}`}</span>
          </div>
        </div>
      </FormGroup>

      <FormFooter top>
        <Button onClick={() => onCancel()} variant="secondary" className={styles.cancelButton}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" disabled={!canAdd}>
          {isEditing ? "Edit Integration" : "Create Integration"}
        </Button>
      </FormFooter>
    </form>
  );
};

export default AzureIntegrationForm;
