import { gql } from "@apollo/client";

export const GET_MODULE_RUNTIME_CONFIG = gql`
  query GetModuleRuntimeConfig($moduleId: ID!) {
    module(id: $moduleId) {
      runtimeConfig {
        context {
          contextId
          contextName
        }
        element {
          createdAt
          id
          runtime
          type
          value
          writeOnly
        }
      }
    }
  }
`;
