import { ReactNode } from "react";

import Typography from "../../Typography";

type TileDescriptionProps = {
  children: ReactNode;
};

const TileDescription = ({ children }: TileDescriptionProps) => (
  <Typography variant="p-body3" tag="p" color="secondary">
    {children}
  </Typography>
);

export default TileDescription;
