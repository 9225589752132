import Callout from "ds/components/Callout";
import useTypedContext from "hooks/useTypedContext";
import {
  AVAILABLE_POLICY_TYPES,
  POLICY_TYPES_FEATURES,
  POLICY_TYPES_TITLES,
} from "constants/policy";
import { PolicyType } from "types/generated";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";

import { POLICIES_DISABLED_INFO_KEY } from "./List/constants";
import { SubscriptionContext } from "../SubscriptionWrapper";

const PoliciesFeatureGateCallout = () => {
  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const disabledPolicies = AVAILABLE_POLICY_TYPES.reduce((acc, next) => {
    const feature = POLICY_TYPES_FEATURES[next];

    if (!feature) {
      return acc;
    }

    if (!tierFeatures.includes(feature)) {
      return [...acc, next];
    }

    return acc;
  }, [] as PolicyType[]);
  const disabledPoliciesTitles = disabledPolicies.map((policy) => POLICY_TYPES_TITLES[policy]);

  const disabledPoliciesTitlesGroup = disabledPoliciesTitles.slice(0, -1);
  const lastDisabledPolicyTitle = disabledPoliciesTitles.slice(-1)[0];

  if (disabledPolicies.length === 0) {
    return null;
  }
  return (
    <Callout
      variant="promo"
      title="Upgrade plan to use all policy types"
      storageKey={POLICIES_DISABLED_INFO_KEY}
    >
      {`${disabledPoliciesTitlesGroup.join(", ")} ${disabledPoliciesTitlesGroup.length ? "and" : ""} ${lastDisabledPolicyTitle} ${disabledPoliciesTitlesGroup.length ? "policies" : "policy"} will not be evaluated for your account. Upgrade your plan to make use of ${disabledPoliciesTitles.length > 1 ? "them" : "it"}.`}
      <FeedbackActions>
        <Button to="/settings/billing" variant="contrast" size="small">
          Upgrade
        </Button>
      </FeedbackActions>
    </Callout>
  );
};

export default PoliciesFeatureGateCallout;
