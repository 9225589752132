import { useMutation, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import removeMd from "remove-markdown";
import { useNavigate } from "react-router-dom-v5-compat";

import CodeEditor from "components/CodeEditor";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NoAccessPage from "components/error/NoAccessPage";
import FlashContext from "components/FlashMessages/FlashContext";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useAnalytics from "hooks/useAnalytics";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import { PolicyTemplate } from "types/generated";
import CodeEditorLoadingSkeleton from "components/CodeEditor/LoadingSkeleton";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import { POLICY_TYPES_DATA } from "../Policies/helpers";
import { CREATE_POLICY, GET_TEMPLATE_BODY } from "./gql";
import { useDetailsFromUrl } from "./useDetailsFromUrl";
import PolicyCreateDrawer from "../PolicyCreateDrawer";
import { FILTERS_ORDER_SETTINGS_KEY as TEMPLATES_FILTERS_ORDER_SETTINGS_KEY } from "../Policies/Templates/constants";
import { FILTERS_ORDER_SETTINGS_KEY } from "../Policies/List/constants";
import { SpacesContext } from "../SpacesProvider";

const NewPolicy = () => {
  const navigate = useNavigate();
  const { accountName, viewer } = useTypedContext(AccountContext);
  const { hasManageableSpaces } = useTypedContext(SpacesContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [isDetailsDrawerVisible, setIsDetailsDrawerVisible] = useState(false);
  const { name, labels, type, space, description, templateId, takenPolicyNames } =
    useDetailsFromUrl();

  useTitle(`New policy · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPagePolicy.PolicyNew,
  });

  const [body, setBody] = useState((type && POLICY_TYPES_DATA[type]?.template) || "");

  const handleEditorChange = (value?: string) => {
    setBody(value || "");
  };

  const { loading: templateLoading } = useQuery<{ policyTemplate: PolicyTemplate }>(
    GET_TEMPLATE_BODY,
    {
      variables: {
        id: templateId,
      },
      skip: !templateId,
      onError,
      onCompleted: (data) => {
        if (data) {
          setBody(data.policyTemplate.body);
        }
      },
    }
  );

  const [createPolicy, { loading }] = useMutation<{ policyCreatev2: { name: string } }>(
    CREATE_POLICY,
    {
      onError,
      // APOLLO CLIENT UPDATE
      onCompleted: (data) => {
        if (data) {
          trackSegmentAnalyticsEvent("Policy created", { createdUsingTemplate: !!templateId });
          reportSuccess({ message: `Policy ${data.policyCreatev2.name} successfully created` });
          navigate(`/policies`);
        }
      },
    }
  );

  const handleCreatePolicy = () => {
    createPolicy({
      variables: {
        input: {
          name,
          body,
          type,
          labels,
          space,
          description,
          policyTemplateULID: templateId,
        },
      },
    }).catch(onError);
  };

  useBreadcrumbs([
    {
      title: "Policies",
      link: templateId
        ? getBreadcrumbsBackUrl("/policies-templates", TEMPLATES_FILTERS_ORDER_SETTINGS_KEY)
        : getBreadcrumbsBackUrl("/policies", FILTERS_ORDER_SETTINGS_KEY),
    },
    {
      title: "New policy",
    },
  ]);

  const subtitle = useMemo(() => description && removeMd(description), [description]);

  if (!viewer.admin && !hasManageableSpaces) {
    return <NoAccessPage />;
  }

  return (
    <>
      <ViewHeader backConfirmation="Your changes will not be saved.">
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle subtitle={subtitle}>{name}</ViewHeaderTitle>
          <DropdownMenuEllipsis tooltip="Policy actions" dotsSize="medium">
            <DropdownMenuItem
              onAction={() => {
                setIsDetailsDrawerVisible(true);
              }}
            >
              Edit details
            </DropdownMenuItem>
          </DropdownMenuEllipsis>
        </ViewHeaderWrapper>
      </ViewHeader>

      <PolicyCreateDrawer
        isEditMode
        title="Edit details"
        submitButtonTitle="Update"
        setDrawerVisibility={setIsDetailsDrawerVisible}
        isDrawerVisible={isDetailsDrawerVisible}
        takenPolicyNames={takenPolicyNames}
        templateId={templateId}
        defaultValues={{
          name: name || "",
          labels: labels?.map((value) => ({ value })),
          description,
          ...(type && { type }),
          ...(space && { space }),
        }}
        submitCallback={() => setIsDetailsDrawerVisible(false)}
      />

      <Box justify="stretch">
        <Box direction="column" className={styles.bodyWrapper}>
          <Box justify="between" align="center" className={styles.header}>
            <Typography tag="h2" variant="p-t5">
              Policy body
            </Typography>
            <Button
              variant="primary"
              disabled={templateLoading}
              loading={loading}
              onClick={handleCreatePolicy}
            >
              Create policy
            </Button>
          </Box>

          {templateLoading ? (
            <CodeEditorLoadingSkeleton />
          ) : (
            <CodeEditor body={body} onChange={handleEditorChange} language="rego" />
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewPolicy;
