import { createContext, useMemo, useState, ReactNode } from "react";

import {
  BreadcrumbsContextDataProps,
  BreadcrumbsContextApiProps,
  BreadcrumbsContextItem,
} from "./types";

export const BreadcrumbsContextData = createContext<BreadcrumbsContextDataProps | undefined>(
  undefined
);
BreadcrumbsContextData.displayName = "BreadcrumbsContextData";

export const BreadcrumbsContextApi = createContext<BreadcrumbsContextApiProps | undefined>(
  undefined
);
BreadcrumbsContextApi.displayName = "BreadcrumbsContextApi";

type BreadcrumbsContextProviderProps = {
  children: ReactNode;
};

const BreadcrumbsContextProvider = ({ children }: BreadcrumbsContextProviderProps) => {
  const [items, setItems] = useState<BreadcrumbsContextItem[]>([]);

  const handleSetItems = (newItems: BreadcrumbsContextItem[]) => {
    setItems(newItems);
  };

  const handleResetItems = () => setItems([]);

  const apiStore = useMemo(
    () => ({
      setItems: handleSetItems,
      resetItems: handleResetItems,
    }),
    []
  );

  return (
    <BreadcrumbsContextData.Provider value={items}>
      <BreadcrumbsContextApi.Provider value={apiStore}>{children}</BreadcrumbsContextApi.Provider>
    </BreadcrumbsContextData.Provider>
  );
};

export default BreadcrumbsContextProvider;
