import { gql } from "@apollo/client";

export const GET_STACK_HOOKS = gql`
  query GetStackHooks($stackId: ID!) {
    stack(id: $stackId) {
      id
      name
      afterApply
      afterDestroy
      afterInit
      afterPerform
      afterPlan
      afterRun
      beforeApply
      beforeDestroy
      beforeInit
      beforePerform
      beforePlan
      attachedContexts {
        contextHooks {
          afterApply
          afterDestroy
          afterInit
          afterPerform
          afterPlan
          afterRun
          beforeApply
          beforeDestroy
          beforeInit
          beforePerform
          beforePlan
        }
        contextId
        contextLabels
        contextName
        id
        isAutoattached
        priority
      }
    }
  }
`;
