import { gql } from "@apollo/client";

export const GET_USAGE = gql`
  query GetUsage {
    billedExternally
    onTrialUntil
    usage {
      logins {
        username
        timestamp
      }
      billingPeriodStart
      billingPeriodEnd
      pricePerSeat
      pricePerWorker
      upcomingInvoiceTotalAmount
      usedSeats
      usedWorkers
      usedPrivateMinutes
      usedPublicMinutes
    }
    billingSubscription {
      isActive
      selfServePortalUrl
      billingCycleStart
      status
    }
    tier
  }
`;
