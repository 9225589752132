import { ReactNode } from "react";
import cx from "classnames";

type SubSidebarProps = {
  children: ReactNode;
  className?: string;
};

import styles from "./styles.module.css";

const SubSidebar = ({ children, className }: SubSidebarProps) => {
  return <div className={cx(styles.wrapper, className)}>{children}</div>;
};

export default SubSidebar;
