import { OverlayTriggerStateContext } from "react-aria-components";
import { useCallback, useContext } from "react";

import Button from "../Button";
import { useIsDismissable } from "./useIsDismissable";

type ModalCancelButtonProps = {
  onClick?: () => void;
};

const ModalCancelButton = ({ onClick }: ModalCancelButtonProps) => {
  const state = useContext(OverlayTriggerStateContext);
  const isDismissable = useIsDismissable();

  const handleClose = useCallback(() => {
    onClick?.();
    state.close();
  }, [onClick, state]);

  return (
    <Button variant="secondary" onClick={handleClose} disabled={!isDismissable}>
      Cancel
    </Button>
  );
};

export default ModalCancelButton;
