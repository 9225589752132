import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  BitbucketDatacenterIntegration,
  MutationBitbucketDatacenterIntegrationUpdateArgs,
} from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { UPDATE_BITBUCKET_DATA_CENTER_INTEGRATION } from "./gql";

const useUpdateBitbucketDataCenterIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [bitbucketDatacenterIntegrationUpdate, { loading }] = useMutation<{
    bitbucketDatacenterIntegrationUpdate: BitbucketDatacenterIntegration;
  }>(UPDATE_BITBUCKET_DATA_CENTER_INTEGRATION, {
    onError,
    refetchQueries: ["SearchVCSIntegrations"],
  });

  const updateBitbucketDataCenterIntegration = useCallback(
    (input: MutationBitbucketDatacenterIntegrationUpdateArgs, successCallback?: () => void) => {
      bitbucketDatacenterIntegrationUpdate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.bitbucketDatacenterIntegrationUpdate) {
            reportSuccess({
              message: `Integration ${data.bitbucketDatacenterIntegrationUpdate.name} was successfully updated`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration Edit - Finish", {
              integration: "Bitbucket DC",
            });
          }
        })
        .catch(onError);
    },
    [bitbucketDatacenterIntegrationUpdate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    updateBitbucketDataCenterIntegration,
    loading,
  };
};

export default useUpdateBitbucketDataCenterIntegration;
