import { EntityChangeType } from "types/generated";

import styles from "../styles.module.css";

type PrefixProps = {
  changeType?: EntityChangeType;
};

const displayValue: Partial<Record<EntityChangeType, string>> = {
  [EntityChangeType.Add]: "+",
  [EntityChangeType.Delete]: "-",
  [EntityChangeType.Change]: "~",
  [EntityChangeType.Noop]: "\u00a0", // &nbsp;
  [EntityChangeType.ReplaceCreateBeforeDestroy]: "~",
  [EntityChangeType.ReplaceDestroyBeforeCreate]: "~",
};

const colorClass: Partial<Record<EntityChangeType, string>> = {
  [EntityChangeType.Add]: "var(--delta-count-added-text-color)",
  [EntityChangeType.Delete]: "var(--delta-count-deleted-text-color)",
  [EntityChangeType.Change]: "var(--delta-count-changed-text-color)",
  [EntityChangeType.Noop]: "var(--color-default-primary)",
  [EntityChangeType.ReplaceCreateBeforeDestroy]: "var(--delta-count-changed-text-color)",
  [EntityChangeType.ReplaceDestroyBeforeCreate]: "var(--delta-count-changed-text-color)",
};

const Prefix = ({ changeType = EntityChangeType.Noop }: PrefixProps) => {
  return (
    <span style={{ color: colorClass[changeType] }} className={styles.codeChangesPrefix}>
      {displayValue[changeType] ?? "?"}
    </span>
  );
};

export default Prefix;
