import cx from "classnames";
import { CSSProperties, useEffect, useRef } from "react";

import styles from "./styles.module.css";
import { RunChangesItem } from "./types";

type LineElementProps = {
  item: RunChangesItem;
  setRowHeight?: (size: number) => void;
  style?: CSSProperties;
};

const LineElement = ({ item, setRowHeight }: LineElementProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  useEffect(
    handleRowHeight,
    // eslint-disable-next-line
    [rowRef]
  );

  return (
    <div className={cx(styles.item, styles[item.type.toLowerCase()])} ref={rowRef}>
      <span className={styles.title} title={item.entity.address}>
        {item.entity.address}
      </span>
      <span className={cx(styles.status, styles[item.status.toLowerCase()])}>{item.status}</span>
    </div>
  );
};

export default LineElement;
