import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GET_GITHUB_INTEGRATION } from "./gql";

const useGetGitHubIntegration = () => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error } = useQuery(GET_GITHUB_INTEGRATION, {
    onError,
    fetchPolicy: "no-cache",
  });

  return {
    integration: data?.githubIntegration,
    isPageLoading: loading && !data?.githubIntegration,
    error,
  };
};

export default useGetGitHubIntegration;
