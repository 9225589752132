import { ReactNode } from "react";

type SubSidebarSectionTitleProps = {
  children: ReactNode;
};

import styles from "./styles.module.css";

const SubSidebarSectionTitle = ({ children }: SubSidebarSectionTitleProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default SubSidebarSectionTitle;
