import { useFormContext } from "react-hook-form";

import Button from "ds/components/Button";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";

import styles from "./styles.module.css";

type StackSettingsIntegrationsCloudAttachFormActionsProps = {
  onCancel: () => void;
};

const StackSettingsIntegrationsCloudAttachFormActions = ({
  onCancel,
}: StackSettingsIntegrationsCloudAttachFormActionsProps) => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  return (
    <DrawerFooter className={styles.wholeWidthFix}>
      <DrawerFooterActions>
        <Button type="submit" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          type="submit"
          variant="primary"
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          Attach
        </Button>
      </DrawerFooterActions>
    </DrawerFooter>
  );
};

export default StackSettingsIntegrationsCloudAttachFormActions;
