import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import { WORKER_POOL_SHARED_VALUE, WORKER_POOL_SHARED_LABEL } from "constants/worker_pool";
import KeyValue from "components/KeyValue";
import useTypedFlags from "hooks/useTypedFlags";

import { StackBehaviourFormFields } from "../types";
import { formatToggleValue } from "./formatToggleValue";
import { StackFormContext } from "../context";

type NewStackSummaryBehaviourProps = {
  data: StackBehaviourFormFields;
};

const NewStackSummaryBehaviour = ({ data }: NewStackSummaryBehaviourProps) => {
  const { showLeakingSensitiveOutputsThroughDependencies } = useTypedFlags();
  const { internalData } = useTypedContext(StackFormContext);

  return (
    <>
      <KeyValue name="Worker pool">
        <Typography tag="p" variant="p-body2">
          {data.workerPool === WORKER_POOL_SHARED_VALUE && WORKER_POOL_SHARED_LABEL}
          {data.workerPool !== WORKER_POOL_SHARED_VALUE ? internalData.workerPoolLabel : undefined}
        </Typography>
      </KeyValue>
      <KeyValue name="Administrative">{formatToggleValue(data.administrative)}</KeyValue>
      <KeyValue name="Allow run promotion">{formatToggleValue(data.githubActionDeploy)}</KeyValue>
      <KeyValue name="Autodeploy">{formatToggleValue(data.autodeploy)}</KeyValue>
      <KeyValue name="Autoretry">{formatToggleValue(data.autoretry)}</KeyValue>
      <KeyValue name="Enable local preview">{formatToggleValue(data.localPreviewEnabled)}</KeyValue>
      <KeyValue name="Enable secret masking">
        {formatToggleValue(data.enableWellKnownSecretMasking)}
      </KeyValue>
      <KeyValue name="Protect from deletion">
        {formatToggleValue(data.protectFromDeletion)}
      </KeyValue>
      {showLeakingSensitiveOutputsThroughDependencies && (
        <KeyValue name="Transfer sensitive outputs across dependencies">
          {formatToggleValue(data.enableSensitiveOutputUpload)}
        </KeyValue>
      )}
      <KeyValue name="Runner image">{data.runnerImage}</KeyValue>
    </>
  );
};

export default NewStackSummaryBehaviour;
