import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import Typography from "ds/components/Typography";

import { COLUMN_GAP, COLUMN_ORDER } from "./constants";

const LoginPoliciesSortHeader = () => {
  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Name
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Created at
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Last update
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default LoginPoliciesSortHeader;
