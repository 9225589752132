import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { ManagedUser } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import { GET_MANAGED_USER } from "../gql";

type useGetUserProps = {
  id?: string;
};

const useGetUser = ({ id }: useGetUserProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { data, loading, error } = useQuery<{ managedUser: ManagedUser }>(GET_MANAGED_USER, {
    onError,
    variables: {
      id: id,
    },
    skip: !id,
  });

  return { user: data?.managedUser, loading, error };
};

export default useGetUser;
