import { gql } from "@apollo/client";

import { stackVendorConfigFragment } from "components/form/graphql";

export const SEARCH_STACKS_SUGGESTIONS = gql`
  query SearchStacksSuggestions($input: SearchSuggestionsInput!) {
    searchStacksSuggestions(input: $input) {
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const SEARCH_STACKS = gql`
  query SearchStacks($input: SearchInput!) {
    searchStacks(input: $input) {
      edges {
        cursor
        node {
          id
          administrative
          apiHost
          branch
          blocked
          blueprint {
            name
            ulid
          }
          description
          blocker {
            id
            state
            needsApproval
            type
          }
          delta {
            addCount
            changeCount
            deleteCount
            resources
            importCount
            forgetCount
            moveCount
            ignoreCount
            okCount
            rescueCount
            skipCount
            unreachableCount
            failedCount
          }
          canWrite
          isDisabled
          createdAt
          effectiveTerraformVersion
          labels
          lockedAt
          lockedBy
          lockNote
          managesStateFile
          name
          namespace
          projectRoot
          provider
          repository
          spaceDetails {
            id
            name
            accessLevel
          }
          starred
          state
          stateSetAt
          stateSetBy
          vcsDetached
          trackedCommit {
            url
            hash
          }
          trackedBranchHead {
            url
            hash
          }
          trackedCommitSetBy
          ...stackVendorConfig
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
    tier #a hacky way to make token refresh when polling stack in the background
  }
  ${stackVendorConfigFragment}
`;
