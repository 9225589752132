import { gql } from "@apollo/client";

export const CREATE_SAML = gql`
  mutation CreateSAML($metadata: String!, $dynamic: Boolean!, $nameIDFormat: SAMLNameIDFormat) {
    samlCreate(metadata: $metadata, dynamic: $dynamic, nameIDFormat: $nameIDFormat) {
      adminLogin
    }
  }
`;

export const DELETE_SAML = gql`
  mutation DeleteSAML {
    samlDelete {
      adminLogin
    }
  }
`;
