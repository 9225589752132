import { useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import useTypedContext from "hooks/useTypedContext";
import { RunType, Stack } from "types/generated";
import {
  shouldStopPolling,
  shouldContinuePollingNextRun,
  shouldStopPollingNextRun,
} from "utils/run";
import usePrevious from "hooks/usePrevious";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { makeRunBackUrl } from "components/Breadcrumbs/helpers";
import RunNext from "views/shared/RunNext";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackContext } from "../Context";
import { GET_RUN, GET_NEXT_RUN_INFO } from "./gql";
import { getStacksBackUrl } from "../helpers";

const POLL_EVERY = 5000;

const StackRun = () => {
  const navigate = useNavigate();
  const { runId } = useParams<{ runId: string }>();

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack, stackUrl, configurationManagementViewEnabled } = useTypedContext(StackContext);

  const { data, error, loading, startPolling, stopPolling } = useQuery<{
    stack: Stack;
  }>(GET_RUN, {
    onError,
    fetchPolicy: "network-only",
    variables: { stackId: stack.id, runId },
    // APOLLO CLIENT UPDATE
  });

  const [
    ,
    {
      data: nextRunData,
      error: nextRunError,
      startPolling: nextRunStartPolling,
      stopPolling: nextRunStopPolling,
    },
  ] = useLazyQuery<{
    stack: Stack;
  }>(GET_NEXT_RUN_INFO, {
    onError,
    fetchPolicy: "network-only",
    variables: { stackId: stack.id, runId },
  });

  useEffect(
    () => {
      startPolling(POLL_EVERY);
      if (nextRunData?.stack?.run?.id === runId) {
        nextRunStopPolling();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runId]
  );

  const runData = nextRunData?.stack?.run || data?.stack?.run;
  // fixes browser back case
  const previousRunID = usePrevious(runId);
  const previousNextValue = usePrevious<boolean | "loading">(
    runData && runId === previousRunID ? !!runData.next?.id : "loading",
    runId !== previousRunID
  );
  const isTask = data?.stack?.run?.type === RunType.Task;
  const isProposedRun = data?.stack?.run?.type === RunType.Proposed;

  const handleRedirect = (runUrl: string) => {
    navigate(runUrl);
  };

  useEffect(() => {
    if (previousNextValue !== "loading") {
      if (!previousNextValue && !!runData?.next?.id) {
        const redirectUrl = `${stackUrl}/run/${runData.next?.id}`;
        reportSuccess({
          message: "A newer run is now available",
          actionTitle: "Go to run",
          actionCallback: () => handleRedirect(redirectUrl),
          timeout: 30000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousNextValue, runData]);

  useEffect(() => {
    const run = data?.stack?.run;

    if (runId && run?.id === runId && shouldStopPolling(run)) {
      stopPolling();
      if (shouldContinuePollingNextRun(run)) {
        nextRunStartPolling(POLL_EVERY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.stack?.run]);

  useEffect(() => {
    if (
      runId &&
      nextRunData?.stack?.run?.id === runId &&
      shouldStopPollingNextRun(nextRunData.stack.run)
    ) {
      nextRunStopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextRunData?.stack?.run]);

  useBreadcrumbs(
    [
      {
        title: "Stacks",
        link: getStacksBackUrl(),
      },
      {
        title: stack.name,
        link: makeRunBackUrl(stackUrl, isTask, isProposedRun, data?.stack?.run?.driftDetection),
      },
      {
        title: (isTask ? data?.stack?.run?.command : data?.stack?.run?.title) || "",
      },
    ],
    [data?.stack?.run?.title, data?.stack?.run?.command, data?.stack?.run?.driftDetection]
  );

  const ErrorContent = useErrorHandle(error || nextRunError);

  if (ErrorContent) {
    stopPolling();
    nextRunStopPolling();
    return ErrorContent;
  }

  if (loading && !data?.stack?.run) {
    return <PageLoading />;
  }

  if (!data?.stack?.run) {
    return <NotFoundPage />;
  }

  return (
    <RunNext
      run={data?.stack?.run}
      stack={stack}
      analyticsPage={AnalyticsPageStack.StacksRunView}
      configurationManagementViewEnabled={configurationManagementViewEnabled}
    />
  );
};

export default StackRun;
