import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";

import { TFieldValues } from "./types";

export const useSpacesOptions = () => {
  const { manageableSpaces } = useTypedContext(SpacesContext);
  const { control } = useFormContext<TFieldValues>();

  const spaces = useWatch({ name: "spaces", control });

  const availableSpacesSelectOptions = manageableSpaces.filter(
    ({ id }) => !spaces.find(({ space }) => space === id)
  );

  const disabledSpaces: string[] = useMemo(() => {
    return spaces.map(({ space }) => space).filter((space) => !!space);
  }, [spaces]) as string[];

  return {
    disabledSpaces,
    areSpacesOptionsAvailable: !!availableSpacesSelectOptions.length,
    canAddMoreRows: spaces.length < manageableSpaces.length,
  };
};
