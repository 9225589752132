import { Stack } from "types/generated";
import { TerraformWorkflowTool as WorkflowTool } from "types/generated";
import { TERRAFORM_CUSTOM_VENDOR, VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import {
  LogoAnsible,
  LogoCloudformation,
  LogoKubernetes,
  LogoOpenTofu,
  LogoPulumi,
  LogoTerraform,
  LogoTerragrunt,
  SettingsGear,
} from "components/icons";
import { getStackVendorInfo } from "utils/vendor";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";

import StackListItemTextValue from "./TextValue";

type StackListItemVendorCellProps = { stack: Stack };

const StackListItemVendorCell = ({ stack }: StackListItemVendorCellProps) => {
  const { vendorConfig, effectiveTerraformVersion } = stack;

  // TF Stacks/Stack view
  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM) {
    let label = effectiveTerraformVersion || vendorConfig.version || "";

    let icon = LogoTerraform;
    if (vendorConfig?.workflowTool === WorkflowTool.OpenTofu) {
      icon = LogoOpenTofu;
    }

    if (vendorConfig?.workflowTool === WorkflowTool.Custom) {
      icon = SettingsGear;
      label = TERRAFORM_CUSTOM_VENDOR;
    }

    return (
      (label && (
        <StackListItemTextValue>
          <Box gap="small">
            <Icon src={icon} />
            {label}
          </Box>
        </StackListItemTextValue>
      )) || (
        <StackListItemTextValue>
          <Icon src={icon} />
        </StackListItemTextValue>
      )
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT) {
    const vendorInfo = getStackVendorInfo(vendorConfig);

    if (!vendorInfo)
      return (
        <StackListItemTextValue>
          <Icon src={LogoTerragrunt} />
        </StackListItemTextValue>
      );

    return (
      <Box direction="column" gap="small">
        <StackListItemTextValue>
          <Box gap="small">
            <Icon src={vendorInfo.vendor.icon} />
            {vendorInfo.vendor.text}
          </Box>
        </StackListItemTextValue>

        {vendorInfo.vendorTool && (
          <StackListItemTextValue>
            <Box gap="small">
              <Icon src={vendorInfo.vendorTool.icon} />
              {vendorInfo.vendorTool.text}
            </Box>
          </StackListItemTextValue>
        )}
      </Box>
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.PULUMI) {
    return (
      <StackListItemTextValue tooltip="Pulumi">
        <Icon src={LogoPulumi} />
      </StackListItemTextValue>
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.KUBERNETES) {
    return (
      <StackListItemTextValue tooltip="Kubernetes">
        <Icon src={LogoKubernetes} />
      </StackListItemTextValue>
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION) {
    return (
      <StackListItemTextValue tooltip="CloudFormation">
        <Icon src={LogoCloudformation} />
      </StackListItemTextValue>
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.ANSIBLE) {
    return (
      <StackListItemTextValue tooltip="Ansible">
        <Icon src={LogoAnsible} />
      </StackListItemTextValue>
    );
  }

  return null;
};

export default StackListItemVendorCell;
