import { SearchQueryOrderDirection, Space } from "types/generated";

import { FilterItemKeys } from "./constants";

export const getSortPredicate =
  (sortBy: FilterItemKeys, sortDirection: SearchQueryOrderDirection) => (a: Space, b: Space) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy];

    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortDirection === SearchQueryOrderDirection.Asc
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    if (typeof valueA === "boolean" && typeof valueB === "boolean") {
      return sortDirection === SearchQueryOrderDirection.Asc
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    }

    return 0;
  };
