import { memo, useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash-es/debounce";
import { useSearchParams } from "react-router-dom-v5-compat";

import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { Magnifier } from "components/icons";
import Input from "ds/components/Input";
import { getSessionStorageKeys } from "components/Filters/helpers";

import styles from "./styles.module.css";

type SearchInputProps = {
  placeholder?: string;
  filtersOrderSettingsKey: string;
  disabled?: boolean;
  callback?: () => void;
};

const SearchInput = ({
  placeholder,
  filtersOrderSettingsKey,
  disabled,
  callback,
}: SearchInputProps) => {
  const storage = sessionStorage;

  const [searchParams, setSearchParams] = useSearchParams();
  const { storageUrlSearchKey } = getSessionStorageKeys(filtersOrderSettingsKey);
  const urlSearchInput = decodeURIComponent(
    searchParams.get(URL_SEARCH_KEY) || storage.getItem(storageUrlSearchKey) || ""
  );

  const [searchInput, setSearchInput] = useState(urlSearchInput);
  const [isDebouncePending, setIsDebouncePending] = useState(false);

  const handleUpdateURLParams = useCallback(
    (value: string) => {
      setIsDebouncePending(false);

      let urlParameters = searchParams;

      // verifying if window location search has been modified by other component which leads to effect where debounced references are outdated
      if (location.search !== window.location.search) {
        urlParameters = new URLSearchParams(window.location.search);
      }

      if (value) {
        urlParameters.set(URL_SEARCH_KEY, encodeURIComponent(value));
        storage.setItem(storageUrlSearchKey, encodeURIComponent(value));
      } else {
        urlParameters.delete(URL_SEARCH_KEY);
        storage.removeItem(storageUrlSearchKey);
      }

      callback?.();
      setSearchParams(urlParameters);
    },
    [callback, searchParams, storage, storageUrlSearchKey, setSearchParams]
  );

  const debouncedUpdateURLParams = useMemo(
    // TODO: filter refactor - sync query with local state
    () => debounce(handleUpdateURLParams, 300),
    [handleUpdateURLParams]
  );

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDebouncePending(true);
    setSearchInput(e.target.value);
    debouncedUpdateURLParams(e.target.value);
  };

  useEffect(() => {
    // reset the 'searchInput' state when URL hasn't URL_SEARCH_KEY param
    if (!searchParams.has(URL_SEARCH_KEY) && searchInput.length > 0 && !isDebouncePending) {
      setSearchInput("");
    }
    // set the 'searchInput' state when URL has URL_SEARCH_KEY param
    else if (searchParams.has(URL_SEARCH_KEY)) {
      const value = searchParams.get(URL_SEARCH_KEY);
      if (value && value !== storage.getItem(storageUrlSearchKey)) {
        storage.setItem(storageUrlSearchKey, value);
      }

      // TODO: delete it and create the proper effect to set the initial value from URL [related BUG](https://app.clickup.com/t/86963fdnc)
      // if (value) {
      //   const decodedValue = decodeURIComponent(value);

      //   if (decodedValue !== searchInput) {
      //     setSearchInput(decodedValue);
      //   }
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, storage]);

  return (
    <div className={styles.searchInput}>
      <Input
        className={styles.input}
        type="text"
        onChange={handleSearchInputChange}
        placeholder={placeholder}
        value={searchInput}
        disabled={disabled}
      />

      <Magnifier className={styles.icon} aria-hidden />
    </div>
  );
};

export default memo(SearchInput);
