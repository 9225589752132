import WarningContext from "components/WarningContext";
import Button, { ButtonProps } from "ds/components/Button";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";

import { showNewStackFooterConfirmation } from "./Confirmation";

type NewStackFooterContinueButtonProps = {
  onClickHandler: () => void;
} & ButtonProps;

const NewStackFooterContinueButton = ({
  onClickHandler,
  children,
  ...buttonProps
}: NewStackFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  const onModalShow = () => {
    showNewStackFooterConfirmation({
      onConfirm: onContinue,
      warning,
      confirmationButtonLabel: children?.toString(),
    });
  };

  return (
    <Button
      {...buttonProps}
      analyticsPage={AnalyticsPageStack.StackNew}
      onClick={warning ? onModalShow : onContinue}
    >
      {children}
    </Button>
  );
};

export default NewStackFooterContinueButton;
