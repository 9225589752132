import { gql } from "@apollo/client";

export const CREATE_CONTEXT_V2 = gql`
  mutation CreateContextV2($input: ContextInput!) {
    contextCreateV2(input: $input) {
      id
      name
    }
  }
`;
