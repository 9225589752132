import { gql } from "@apollo/client";

export const STACK_DEPENDENCIES_ADD_REFERENCES = gql`
  mutation StackDependenciesAddReferences(
    $references: [StackDependencyReferenceInput!]!
    $stackDependencyID: ID!
  ) {
    stackDependenciesAddReferences(references: $references, stackDependencyID: $stackDependencyID) {
      id
    }
  }
`;

export const STACK_DEPENDENCIES_DELETE_REFERENCES = gql`
  mutation StackDependenciesDeleteReferences($referenceIds: [ID!]!) {
    stackDependenciesDeleteReferences(ids: $referenceIds) {
      id
    }
  }
`;

export const STACK_DEPENDENCIES_UPDATE_REFERENCES = gql`
  mutation StackDependenciesUpdateReferences($references: [StackDependencyReferenceUpdateInput!]!) {
    stackDependenciesUpdateReferences(references: $references) {
      id
    }
  }
`;
