import { ReactNode, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { camelCase } from "lodash-es";

import { SefHostedFlags, selfHostedFlags } from "views/flags";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { isSelfHostedDistribution } from "utils/distribution";

import { SelfHostedFeatureFlagsContext } from "./Context";
import { GET_SELF_HOSTED_FLAGS } from "./gql";
import { queryFlagsVariable } from "./helpers";

const isSelfHosted = isSelfHostedDistribution();

type SelfHostedFeatureFlagsProviderProps = {
  children: ReactNode;
};

const SelfHostedFeatureFlagsProvider = ({ children }: SelfHostedFeatureFlagsProviderProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data } = useQuery(GET_SELF_HOSTED_FLAGS, {
    variables: {
      input: {
        flags: queryFlagsVariable,
      },
    },
    onError,
    fetchPolicy: "network-only",
    skip: !isSelfHosted,
  });

  const normalizedFlags = useMemo(() => {
    return (data?.evaluateFeatureFlags || []).reduce<SefHostedFlags>((acc, flag) => {
      const defaultValueType = typeof selfHostedFlags[flag.name as keyof typeof selfHostedFlags];

      return {
        ...acc,
        [camelCase(flag.name)]:
          defaultValueType === "number" ? Number(flag.value) : flag.value.toLowerCase() === "true",
      };
    }, {} as SefHostedFlags);
  }, [data?.evaluateFeatureFlags]);

  return (
    <SelfHostedFeatureFlagsContext.Provider value={{ flags: normalizedFlags }}>
      {children}
    </SelfHostedFeatureFlagsContext.Provider>
  );
};

export default SelfHostedFeatureFlagsProvider;
