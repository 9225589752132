import { gql } from "@apollo/client";

export const GET_RECENT_DRIFT_DETECTION_RUNS = gql`
  query GetRecentDriftDetectionRuns {
    metrics {
      recentDriftDetectionProposedRuns {
        id
        title
        state
        stackTile {
          slug
          name
        }
      }
      hasRuns
    }
  }
`;
