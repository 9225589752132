import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import SpaceliftLoader from "components/loading/SpaceliftLoader";
import { isSelfHostedDistribution } from "utils/distribution";
import useSelfHostedFlags from "hooks/useSelfHostedFlags";
import useSelfHostedInstanceInfo from "hooks/useSelfHostedInstanceInfo";

const isSelfHosted = isSelfHostedDistribution();

type SelfHostedInstanceProviderGateProps = {
  children: ReactNode;
};

/**
 * @description SelfHosted only,
 * * used to gate the self-hosted version of the application till data from "/instance_info" is reached.
 * * then based on the data, it will unblock the usual login flow or force redirecting to the admin login page.
 */
const SelfHostedInstanceProviderGate = ({ children }: SelfHostedInstanceProviderGateProps) => {
  const { selfHostedV3InstallationFlow } = useSelfHostedFlags();
  const instanceInfo = useSelfHostedInstanceInfo();

  const navigate = useNavigate();

  const isSelfHostedAppEnabled =
    instanceInfo?.hasAccountBeenCreated && !instanceInfo?.isAdminLoginEnabled;

  useEffect(() => {
    if (instanceInfo?.isAdminLoginEnabled && isSelfHosted && selfHostedV3InstallationFlow) {
      navigate("/admin-login", { replace: true });
    }
  }, [instanceInfo?.isAdminLoginEnabled, navigate, selfHostedV3InstallationFlow]);

  if (!isSelfHosted || (isSelfHosted && !selfHostedV3InstallationFlow)) {
    return <>{children}</>;
  }

  return isSelfHostedAppEnabled ? <>{children}</> : <SpaceliftLoader />;
};

export default SelfHostedInstanceProviderGate;
