import { gql } from "@apollo/client";

export const CREATE_STACK_SCHEDULING_TASK = gql`
  mutation StackSchedulingTaskCreate($stack: ID!, $input: ScheduledTaskInput!) {
    stackScheduledTaskCreate(stack: $stack, input: $input) {
      id
    }
  }
`;

export const UPDATE_STACK_SCHEDULING_TASK = gql`
  mutation StackSchedulingTaskUpdate($scheduleId: ID!, $stack: ID!, $input: ScheduledTaskInput!) {
    stackScheduledTaskUpdate(scheduledTask: $scheduleId, stack: $stack, input: $input) {
      id
    }
  }
`;

export const DELETE_STACK_SCHEDULING_TASK = gql`
  mutation StackSchedulingTaskDelete($scheduleId: ID!, $stack: ID!) {
    stackScheduledTaskDelete(scheduledTask: $scheduleId, stack: $stack) {
      id
    }
  }
`;
