import useTypedContext from "hooks/useTypedContext";

import { StackFormContext } from "../context";
import { HooksPhases, StackCreationVendor, StackCreationWizardStep } from "../types";

export const useValidation = () => {
  const { formData } = useTypedContext(StackFormContext);
  const stepData = formData[StackCreationWizardStep.Hooks];

  const isPulumiVendor =
    formData[StackCreationWizardStep.Vendor]?.vendor === StackCreationVendor.Pulumi;

  const isBeforeInitValid = !isPulumiVendor || stepData.beforeInit?.length;
  const isBeforeApplyValid = !isPulumiVendor || stepData.beforeApply?.length;

  const getBannerError = () => {
    if (!isBeforeInitValid && !isBeforeApplyValid) {
      return "Before initialization and before apply commands are required for Pulumi vendor";
    }

    if (!isBeforeInitValid) {
      return "Before initialization command is required for Pulumi vendor";
    }

    if (!isBeforeApplyValid) {
      return "Before apply command is required for Pulumi vendor";
    }

    return undefined;
  };

  const isWorkflowValidForChosenVendor = (workflow: HooksPhases) => {
    switch (workflow) {
      case HooksPhases.Init: {
        return isBeforeInitValid;
      }
      case HooksPhases.Apply: {
        return isBeforeApplyValid;
      }
      default: {
        return true;
      }
    }
  };

  return {
    bannerError: getBannerError(),
    areWorkflowsValid: Object.values(HooksPhases).every((value) =>
      isWorkflowValidForChosenVendor(value)
    ),
    isWorkflowValidForChosenVendor,
    isStepOptional: !isPulumiVendor,
  };
};
