import { ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type ViewWrapperProps = {
  children: ReactNode;
  className?: string;
};

/**

 * @deprecated Use DS FullScreenModal instead
 */
const ViewWrapper = ({ children, className }: ViewWrapperProps) => {
  return <div className={cx(styles.wrapper, className)}>{children}</div>;
};

export default ViewWrapper;
