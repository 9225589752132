import { RunStateTransition } from "types/generated";

import ReplanRequestedEntry from "../entries/ReplanRequestedEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createReplanRequestedEntry = ({
  timestamp,
  until,
  note,
  username,
  stateVersion,
}: RunStateTransition) => {
  return (
    <ReplanRequestedEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      note={note}
      username={username}
    />
  );
};
