import { gql } from "@apollo/client";

export const SEARCH_STACKS_AND_MODULES = gql`
  query SearchContextStacks($input: SearchInput!) {
    searchModules(input: $input) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          spaceDetails {
            id
            name
          }
        }
      }
    }
    searchStacks(input: $input) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          spaceDetails {
            id
            name
          }
        }
      }
    }
  }
`;
