import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";
import TileContent from "ds/components/Tile/Content";
import TileIndicator from "ds/components/Tile/Indicator";
import TileWrapper from "ds/components/Tile/Wrapper";

import { LaunchPadFeatureMetadata } from "../../types";

const LaunchPadGetStartedChecklistSkeletonTile = () => (
  <TileWrapper>
    <Box align="center" justify="between" grow="1">
      <TileContent direction="row" align="center">
        <Skeleton count={1} height={36} width={36} borderRadius={8} />
        <Skeleton count={1} height={20} width={300} borderRadius={8} />
      </TileContent>
      <TileIndicator indicator={<Skeleton count={1} height={16} width={16} borderRadius={4} />} />
    </Box>
  </TileWrapper>
);

type LaunchPadGetStartedChecklistSkeletonProps<T extends string> = {
  features: LaunchPadFeatureMetadata<T>[];
};

const LaunchPadGetStartedChecklistSkeleton = <T extends string>({
  features,
}: LaunchPadGetStartedChecklistSkeletonProps<T>) => (
  <>
    {features.map(({ feature }) => (
      <LaunchPadGetStartedChecklistSkeletonTile key={feature} />
    ))}
  </>
);

export default LaunchPadGetStartedChecklistSkeleton;
