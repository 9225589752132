import { get, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import SecretInput from "ds/components/SecretInput";
import Input from "ds/components/Input";

type ContextConfigFormValueFieldProps = {
  name: string;
  isSecret?: boolean;
};

const validateValue = (value: string) => {
  if (/\r|\n/.exec(value)) {
    return "Newlines are not valid in environment variables - mount or encode it";
  }

  return true;
};

const ContextConfigFormValueField = ({ name, isSecret }: ContextConfigFormValueFieldProps) => {
  const { register, formState } = useFormContext();
  const error = get(formState.errors, name);
  const Component = isSecret ? SecretInput : Input;

  return (
    <FormField label="Value" noMargin fullWidth error={error?.message}>
      {({ ariaInputProps }) => (
        <Component
          placeholder="Enter variable value here..."
          error={!!error}
          {...register(name, {
            required: "Value field is required.",
            validate: validateValue,
          })}
          {...ariaInputProps}
        />
      )}
    </FormField>
  );
};

export default ContextConfigFormValueField;
