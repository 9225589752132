import moment from "moment";

import { SCHEDULING_TYPE, SchedulingItem } from "shared/Stack/Scheduling/types";
import { getHumanizedTimestamp } from "utils/time";

export const getScheduleDrawerEntityTypePerItem = (item: SchedulingItem) => {
  if (item.__typename === "DriftDetectionIntegration") {
    return SCHEDULING_TYPE.DRIFT_DETECTION;
  }

  if (item.__typename === "ScheduledDelete") {
    return SCHEDULING_TYPE.DELETE_STACK;
  }

  if (item.__typename === "ScheduledRun") {
    return SCHEDULING_TYPE.RUN;
  }

  return SCHEDULING_TYPE.TASK;
};

export const getNextSchedule = (nextSchedule: number | undefined): string => {
  const now = moment().unix();

  if (nextSchedule && nextSchedule > now) {
    return `Starts ${getHumanizedTimestamp(nextSchedule)}`;
  } else if (nextSchedule && now >= nextSchedule) {
    return "Is starting";
  }

  return "Is in progress";
};
