import Box from "ds/components/Box";
import Button, { ButtonProps } from "ds/components/Button";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { BulkActionsApiContext } from "components/BulkActions/Context";

import { BulkActionsVariant } from "../../types";

type BulkActionsActionConfirmationProps = {
  variant: BulkActionsVariant;
  actionLabel: string;
  confirmDisabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const BUTTON_PROPS: Record<
  BulkActionsVariant,
  {
    size: ButtonProps["size"];
    variantNo: ButtonProps["variant"];
    variantYes: ButtonProps["variant"];
  }
> = {
  [BulkActionsVariant.FloatingBar]: {
    size: "small",
    variantNo: "outlineOnDark",
    variantYes: "outlineOnDark",
  },
  [BulkActionsVariant.Drawer]: { size: "medium", variantNo: "secondary", variantYes: "primary" },
};

const BulkActionsConfirmActionStepInlineConfirmation = ({
  variant,
  actionLabel,
  confirmDisabled,
  onCancel,
  onConfirm,
}: BulkActionsActionConfirmationProps) => {
  const { onCancelAction, onConfirmAction } = useTypedContext(BulkActionsApiContext);

  const buttonProps = BUTTON_PROPS[variant];

  const handleOnConfirm = () => {
    onConfirmAction();
    onConfirm?.();
  };

  const handleOnCancel = () => {
    onCancelAction();
    onCancel?.();
  };

  return (
    <Box align="center" justify="between" fullWidth>
      <Typography tag="p" variant="p-body3">
        Are you sure you want to <strong>{actionLabel}</strong>?
      </Typography>
      <Box gap="medium">
        <Button size={buttonProps.size} variant={buttonProps.variantNo} onClick={handleOnCancel}>
          No
        </Button>
        <Button
          size={buttonProps.size}
          variant={buttonProps.variantYes}
          disabled={confirmDisabled}
          onClick={handleOnConfirm}
        >
          Yes
        </Button>
      </Box>
    </Box>
  );
};

export default BulkActionsConfirmActionStepInlineConfirmation;
