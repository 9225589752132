import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SourceCodeProjects } from "components/Forms/SourceCode/types";

export const getTooltipAnalyticsProps = (
  location: string,
  name: string,
  projectType: SourceCodeProjects,
  props?: TrackAnalyticsEventProperties
) => {
  return {
    tooltipAnalyticsPage:
      projectType === SourceCodeProjects.Module
        ? AnalyticsPageModule.ModuleNew
        : AnalyticsPageStack.StackNew,
    tooltipAnalyticsTitle: "Tooltip click",
    tooltipAnalyticsProps: { location, name, ...props },
  };
};
