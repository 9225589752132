import { Pages } from "./types";

export const enum AnalyticsPageGlobal {
  DocumentationBanner = "DocumentationBanner",
  UpgradePlanBanner = "UpgradePlanBanner",
}

export const pagesGlobal: Pages<AnalyticsPageGlobal> = {
  [AnalyticsPageGlobal.DocumentationBanner]: ["Documentation banner"],
  [AnalyticsPageGlobal.UpgradePlanBanner]: ["Upgrade plan banner"],
};
