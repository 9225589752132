import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { REQUEST_MANAGED_USER_INVITE } from "../Spaces/gql";

const useInviteUser = () => {
  const { onError } = useTypedContext(FlashContext);

  const [inviteManagedUser, { loading }] = useMutation(REQUEST_MANAGED_USER_INVITE, {
    onError,
    refetchQueries: ["GetManagedUsers"],
  });

  const onUserInvite = (email: string, userName: string) => {
    return inviteManagedUser({
      variables: {
        userName,
        email,
      },
    });
  };

  return { onUserInvite, loading };
};

export default useInviteUser;
