import { ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type FiltersSavedViewsPanelNotificationStatusProps = {
  isVisible: boolean;
  children: ReactNode;
  onClick?: () => void;
};

const FiltersSavedViewsPanelNotificationStatus = ({
  isVisible,
  children,
}: FiltersSavedViewsPanelNotificationStatusProps) => {
  return (
    <div className={cx(styles.notificationStatus, { [styles.isVisible]: isVisible })}>
      {children}
    </div>
  );
};

export default FiltersSavedViewsPanelNotificationStatus;
