// TODO: refactor all vendor places to use one place sourced constants
// FYI: this type is used for mapping with StackConfigVendor.__typename,
// mostly for showing established vendor configurations in the UI
export enum VENDOR_CONFIG_TYPENAME {
  ANSIBLE = "StackConfigVendorAnsible",
  CLOUD_FORMATION = "StackConfigVendorCloudFormation",
  KUBERNETES = "StackConfigVendorKubernetes",
  PULUMI = "StackConfigVendorPulumi",
  TERRAFORM = "StackConfigVendorTerraform",
  TERRAGRUNT = "StackConfigVendorTerragrunt",
}

// FYI: this type is used for providing all available vendor options mostly while creating a new stack,
// because the generated types don't contain a list of all available vendors
export enum VENDOR_KEY {
  ANSIBLE = "ansible",
  CLOUD_FORMATION = "cloudFormation",
  KUBERNETES = "kubernetes",
  PULUMI = "pulumi",
  TERRAFORM = "terraform",
  TERRAGRUNT = "terragrunt",
}

export const VENDOR_KEY_BY_TYPENAME: Record<VENDOR_CONFIG_TYPENAME, VENDOR_KEY> = {
  [VENDOR_CONFIG_TYPENAME.ANSIBLE]: VENDOR_KEY.ANSIBLE,
  [VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION]: VENDOR_KEY.CLOUD_FORMATION,
  [VENDOR_CONFIG_TYPENAME.KUBERNETES]: VENDOR_KEY.KUBERNETES,
  [VENDOR_CONFIG_TYPENAME.PULUMI]: VENDOR_KEY.PULUMI,
  [VENDOR_CONFIG_TYPENAME.TERRAFORM]: VENDOR_KEY.TERRAFORM,
  [VENDOR_CONFIG_TYPENAME.TERRAGRUNT]: VENDOR_KEY.TERRAGRUNT,
};

export enum VENDOR_DICTIONARY {
  ANSIBLE = "Ansible",
  CLOUD_FORMATION = "CloudFormation",
  KUBERNETES = "Kubernetes",
  OPEN_TOFU = "OpenTofu",
  PULUMI = "Pulumi",
  TERRAFORM = "Terraform",
  TERRAGRUNT = "Terragrunt",
}

export const TERRAGRUNT_MANUALLY_PROVISIONED = "Manually provisioned";

export const TERRAFORM_CUSTOM_VENDOR = "Custom vendor";
