import { useMemo } from "react";

import useTypedFlags from "hooks/useTypedFlags";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { generateBranchUrl, generateRepoUrl, generateRepoUrlText } from "utils/urls";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import { BranchNew, ClockNew, MenuSpaces, TimeMachine } from "components/icons";
import Timestamp from "components/time/Timestamp";
import ManagedStateInfo from "components/ManagedStateInfo";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import MetaInfoVendor from "components/MetaInfoList/Vendor";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import MetaInfoProjectRoot from "components/MetaInfoList/ProjectRoot";
import MetaInfoStackCommit from "components/MetaInfoList/StackCommit";
import MetaInfoBlueprint from "components/MetaInfoList/Blueprint";
import Button from "ds/components/Button";
import { projectRootTooltipContent } from "components/TooltipSnippets";
import ViewHeaderNavigationResize from "components/ViewHeader/Navigation/Resize";
import { type NavigationItem } from "components/ViewHeader/Navigation/types";
import VCSDetachedIndicator from "components/VCSDetachedIndicator";
import StackStateBadge from "components/StackStateBadge";
import useSetStackFavorite from "shared/Stack/useSetStackFavorite";
import FlashContext from "components/FlashMessages/FlashContext";
import { isAnsibleStackVendor } from "utils/stack";
import ToggleField from "ds/components/Form/ToggleField";
import Box from "ds/components/Box";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import DisableButton from "../DisableButton";
import EnableButton from "../EnableButton";
import styles from "./styles.module.css";

enum MenuItems {
  Runs = "Runs",
  IgnoredRuns = "IgnoredRuns",
  Tasks = "Tasks",
  PRs = "PRs",
  Environment = "Environment",
  Hooks = "Hooks",
  Contexts = "Contexts",
  Resources = "Resources",
  ConfigurationManagement = "ConfigurationManagement",
  Outputs = "Outputs",
  Dependencies = "Dependencies",
  StateHistory = "StateHistory",
  Notifications = "Notifications",
  Scheduling = "Scheduling",
  Policies = "Policies",
  Settings = "Settings",
}

const hideOrder = [MenuItems.StateHistory, MenuItems.Hooks, MenuItems.Resources];

const REFETCH_QUERIES = ["GetStack"];

const StackHeader = () => {
  const {
    stack,
    stackUrl,
    canManageStackAndRuns,
    hasIgnoredRunsAccess,
    isDetailsDrawerVisible,
    handleOpenDetailsDrawer,
    handleSetConfigurationManagementViewEnabled,
    configurationManagementViewEnabled,
  } = useTypedContext(StackContext);

  const { reportSuccess } = useTypedContext(FlashContext);

  const { setStackFavorite, loading: setFavoriteLoading } = useSetStackFavorite({
    refetchQueries: REFETCH_QUERIES,
    awaitRefetchQueries: true,
  });

  const { ansibleConfigurationManagementFrontend, hiddenBetaTagsAnsible } = useTypedFlags();

  const {
    id,
    state,
    name,
    apiHost,
    namespace,
    repository,
    repositoryURL,
    provider,
    branch,
    stateSetAt,
    administrative,
    managesStateFile,
    projectRoot,
    spaceDetails,
    vendorConfig,
    effectiveTerraformVersion,
    isDisabled,
    blueprint,
  } = stack;

  const isAnsibleVendor = isAnsibleStackVendor(stack) && configurationManagementViewEnabled;
  const isTerraformVendor = vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM;

  const menuItems = useMemo(
    (): NavigationItem[] => [
      {
        key: MenuItems.Runs,
        link: stackUrl,
        title: "Tracked runs",
        visible: true,
        exact: true,
      },
      {
        key: MenuItems.IgnoredRuns,
        link: `${stackUrl}/ignored-runs`,
        title: "Ignored runs",
        visible: hasIgnoredRunsAccess,
      },
      {
        key: MenuItems.Tasks,
        link: `${stackUrl}/tasks`,
        title: "Tasks",
        visible: true,
      },
      {
        key: MenuItems.PRs,
        link: `${stackUrl}/prs`,
        title: "PRs",
        visible: true,
      },
      {
        key: MenuItems.Environment,
        link: `${stackUrl}/environment`,
        title: "Environment",
        visible: true,
      },
      {
        key: MenuItems.Hooks,
        link: `${stackUrl}/hooks`,
        title: "Hooks",
        visible: true,
      },
      {
        key: MenuItems.Contexts,
        link: `${stackUrl}/contexts`,
        title: "Contexts",
        visible: true,
      },
      {
        key: MenuItems.Resources,
        link: `${stackUrl}/resources`,
        title: "Resources",
        visible: !(ansibleConfigurationManagementFrontend && isAnsibleVendor),
      },
      {
        key: MenuItems.ConfigurationManagement,
        link: `${stackUrl}/configuration-management`,
        title: "Configuration Management",
        beta: !hiddenBetaTagsAnsible,
        visible: ansibleConfigurationManagementFrontend && isAnsibleVendor,
      },
      {
        key: MenuItems.Outputs,
        link: `${stackUrl}/outputs`,
        title: "Outputs",
        visible: !isAnsibleVendor,
      },
      {
        key: MenuItems.Dependencies,
        link: `${stackUrl}/dependencies`,
        title: "Dependencies",
        visible: true,
      },
      {
        key: MenuItems.Notifications,
        link: `${stackUrl}/notifications`,
        title: "Notifications",
        visible: true,
      },
      {
        key: MenuItems.Scheduling,
        link: `${stackUrl}/scheduling`,
        title: "Scheduling",
        visible: true,
      },
      {
        key: MenuItems.Policies,
        link: `${stackUrl}/policies`,
        title: "Policies",
        visible: true,
      },
      {
        key: MenuItems.StateHistory,
        link: `${stackUrl}/state-history`,
        title: "State history",
        visible: isTerraformVendor && managesStateFile,
      },
    ],
    [
      ansibleConfigurationManagementFrontend,
      hiddenBetaTagsAnsible,
      isAnsibleVendor,
      isTerraformVendor,
      managesStateFile,
      stackUrl,
      hasIgnoredRunsAccess,
    ]
  );

  const handleStarStack = (clb?: () => void) => () => {
    setStackFavorite({ id: stack.id, star: !stack.starred }, (data) => {
      if (typeof data?.starSet?.starred === "boolean") {
        reportSuccess({
          message: data.starSet.starred
            ? "Stack added to favorites"
            : "Stack removed from favorites",
        });
      }

      clb?.();
    });
  };

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" justify="between" fullWidth>
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle>{name}</ViewHeaderTitle>
          {stack.vcsDetached && <VCSDetachedIndicator />}
          <StackStateBadge state={state} />
        </ViewHeaderWrapper>

        <ViewHeaderWrapper direction="row" align="end" shrink="0">
          {ansibleConfigurationManagementFrontend && isAnsibleStackVendor(stack) && (
            <Box padding="0 0 small 0" id="enable-configuration-management-view">
              <ToggleField
                variant="switch"
                title="Enable configuration management view"
                checked={configurationManagementViewEnabled}
                onChange={(e) => {
                  handleSetConfigurationManagementViewEnabled(e.target.checked);
                }}
              />
            </Box>
          )}
          <Button
            variant="secondary"
            onClick={handleOpenDetailsDrawer}
            active={isDetailsDrawerVisible}
          >
            Details
          </Button>

          <DropdownMenuEllipsis
            tooltip="Stack actions"
            id="stack-view--dropdown-actions-with-settings"
            dotsSize="medium"
          >
            <CopyFieldDropdownMenuItem title="Copy ID" value={id} />
            <DropdownMenuItem
              onAction={handleStarStack(close)}
              loading={setFavoriteLoading}
              isDisabled={setFavoriteLoading}
            >
              {stack.starred ? "Remove from favorites" : "Add to favorites"}
            </DropdownMenuItem>
            {canManageStackAndRuns && !isDisabled && <DisableButton stackId={id} />}
            {canManageStackAndRuns && isDisabled && <EnableButton stackId={id} />}
            <DropdownMenuItem href={`${stackUrl}/settings`}>Settings</DropdownMenuItem>
          </DropdownMenuEllipsis>
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>

      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          {stack.isStateRollback && managesStateFile && (
            // TODO: refactor MetaInfoListItem, add the "color" prop
            <MetaInfoListItem icon={TimeMachine} className={styles.rolledBack}>
              State rolled back
            </MetaInfoListItem>
          )}
          {spaceDetails && (
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={spaceDetails.name}
              href={`/spaces/${spaceDetails.id}`}
            />
          )}
          {blueprint && <MetaInfoBlueprint blueprint={blueprint} />}
          <MetaInfoVendor
            vendorConfig={vendorConfig}
            effectiveTerraformVersion={effectiveTerraformVersion}
          />
          {apiHost && (
            <MetaInfoListItem
              linkText={generateRepoUrlText({
                namespace,
                repository,
              })}
              href={generateRepoUrl({
                apiHost,
                namespace,
                repository,
                repositoryURL,
                provider,
              })}
              icon={VCS_PROVIDERS_ICONS[provider]}
              target="_blank"
            />
          )}
          {projectRoot && (
            <MetaInfoProjectRoot projectRoot={projectRoot} tooltip={projectRootTooltipContent} />
          )}
          {apiHost && (
            <MetaInfoListItem
              linkText={branch}
              href={generateBranchUrl({
                apiHost,
                namespace,
                repository,
                repositoryURL,
                provider,
                branch,
              })}
              icon={BranchNew}
              target="_blank"
            />
          )}
          <MetaInfoStackCommit stack={stack} />
          {stateSetAt && (
            <MetaInfoListItem icon={ClockNew}>
              <Timestamp timestamp={stateSetAt} />
            </MetaInfoListItem>
          )}
          {administrative && <MetaInfoListItem>Administrative</MetaInfoListItem>}
          {isTerraformVendor && managesStateFile && (
            <MetaInfoListItem>
              <ManagedStateInfo />
            </MetaInfoListItem>
          )}
        </MetaInfoList>
      </ViewHeaderScrollCollapse>

      <ViewHeaderNavigationResize items={menuItems} hideOrder={hideOrder} />
    </ViewHeader>
  );
};

export default StackHeader;
