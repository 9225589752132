import React from "react";

import Typography, { TypographyProps } from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ViewHeaderTitleProps = {
  titleSize?: TypographyProps["variant"];
  subtitle?: React.ReactNode;
  tag?: TypographyProps["tag"];
  children: React.ReactNode;
  tooltipDelay?: number;
};

const ViewHeaderTitle = (props: ViewHeaderTitleProps) => {
  const { children, titleSize = "p-t4", subtitle, tooltipDelay, tag = "h1" } = props;

  const title = (
    <TextEllipsis
      tooltip={children}
      tooltipPlacement="bottom"
      tooltipWidthMode="maxWidthXl"
      delay={tooltipDelay}
    >
      {(props) => (
        <Typography {...props} variant={titleSize} tag={tag}>
          {children}
        </Typography>
      )}
    </TextEllipsis>
  );

  if (!subtitle) return <>{title}</>;

  return (
    <Box>
      <div className={styles.headerTitle}>
        {title}
        <TextEllipsis
          tooltip={subtitle}
          tooltipPlacement="bottom"
          tooltipWidthMode="maxWidthXl"
          delay={tooltipDelay}
        >
          {(props) => (
            <Typography {...props} variant="p-body2" tag="p">
              {subtitle}
            </Typography>
          )}
        </TextEllipsis>
      </div>
    </Box>
  );
};

export default ViewHeaderTitle;
