// import { MenuStacks } from "components/icons";
// import Box from "ds/components/Box";
// import ChartTooltipDivider from "ds/components/Charts/components/Tooltip/Divider";
import ChartTooltipTotalHeader from "ds/components/Charts/components/Tooltip/TotalHeader";
// import Icon from "ds/components/Icon";
// import Typography from "ds/components/Typography";

type StackFailuresTooltipProps = {
  value: number;
};

const StackFailuresTooltip = ({ value }: StackFailuresTooltipProps) => {
  return (
    <>
      <ChartTooltipTotalHeader
        title="Total: "
        count={value.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })}
      />
      {/* Add when supported by API* */}
      {/* <ChartTooltipDivider />
      <Typography tag="p">Top 3 failed stacks</Typography>
      <Box direction="column" gap="large">
        <Box gap="medium" align="center">
          <Icon color="primary" src={MenuStacks} />
          <Typography color="primary" tag="span" variant="p-body2">
            terraform-aws-s3-bucket
          </Typography>
        </Box>
        <Box gap="medium" align="center">
          <Icon color="primary" src={MenuStacks} />
          <Typography color="primary" tag="span" variant="p-body2">
            terraform-aws-s3-bucket
          </Typography>
        </Box>
        <Box gap="medium" align="center">
          <Icon color="primary" src={MenuStacks} />
          <Typography color="primary" tag="span" variant="p-body2">
            terraform-aws-s3-bucket
          </Typography>
        </Box>
      </Box> */}
    </>
  );
};

export default StackFailuresTooltip;
