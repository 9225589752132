import { RefObject, useCallback } from "react";

import BulkActions from "components/BulkActions";
import BulkActionsFloatingBar from "components/BulkActions/FloatingBar";
import BulkActionsFloatingBarHeader from "components/BulkActions/FloatingBar/Header";
import BulkActionsFloatingBarSteps from "components/BulkActions/FloatingBar/Steps";
import {
  BulkActionItemID,
  BulkActionsCloseMethod,
  BulkActionsResult,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import useBulkActions from "components/BulkActions/useBulkActions";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { Module } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";
import BulkActionsDrawer from "components/BulkActions/Drawer";
import BulkActionsDrawerActionSteps from "components/BulkActions/Drawer/ActionSteps";
import BulkActionsDrawerActionStepsHeader from "components/BulkActions/Drawer/ActionSteps/Header";
import BulkActionsDrawerActionStepsInfoBanner from "components/BulkActions/Drawer/ActionSteps/InfoBanner";
import BulkActionsDrawerResultsStep from "components/BulkActions/Drawer/ResultsStep";
import BulkActionsDrawerResultsStepHeader from "components/BulkActions/Drawer/ResultsStep/Header";
import BulkActionsDrawerResultsStepBody from "components/BulkActions/Drawer/ResultsStep/Body";
import { getDefaultOnContinueWith } from "components/BulkActions/Drawer/ResultsStep/helpers";
import useExecutionQueue from "components/BulkActions/useExecutionQueue";
import { BulkActionItemTriggerResult } from "components/BulkActions/helpers";
import BulkActionsDrawerResultsStepBodyEmptyTab from "components/BulkActions/Drawer/ResultsStep/Body/EmptyTab";
import BulkActionsDrawerResultsStepFooter from "components/BulkActions/Drawer/ResultsStep/Footer";
import { BulkActionResultsStepContinueWith } from "components/BulkActions/Drawer/ResultsStep/types";
import { useResultTabItems } from "components/BulkActions/useResultTabItems";

import useModuleBulkActions from "./useModuleBulkActions";
import ModulesBulkActionsConfirmActionForm from "./ConfirmActionForm";
import ModulesBulkActionsSelectedItemsView from "./SelectedItemsView";
import ModulesBulkActionsConfirmationView from "./ConfirmationView";
import ModulesBulkActionsResultItem from "./ResultItem";
import { ModuleBulkActionsAnalyticsPayload } from "./types";

type ModulesBulkActionsProps = {
  virtualizedListContainerRef: RefObject<HTMLElement>;
  selectedSet: Set<BulkActionItemID>;
  modulesMap: Map<BulkActionItemID, Module>;
  onBulkResetAll: () => void;
  onFinish: () => Promise<unknown>;
  onItemDismiss: (id: BulkActionItemID) => void;
  onBulkContinueWith: (continueWith: Set<BulkActionItemID>) => void;
};

const ModulesBulkActions = ({
  selectedSet,
  modulesMap,
  onBulkResetAll,
  virtualizedListContainerRef,
  onItemDismiss,
  onFinish,
  onBulkContinueWith,
}: ModulesBulkActionsProps) => {
  const selectedItemsCount = selectedSet.size;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageModule.ModulesList,
  });

  const moduleBulkActions = useModuleBulkActions();

  const {
    selectedBulkAction,
    itemsPerAction,
    availableActions,
    resetSelectedAction,
    skippedSelectedEntityItems,
    selectedEntityItems,
    actionsPerItem,
    applicableSelectedEntityItems,
  } = useBulkActions({
    actions: moduleBulkActions,
    selectedSet,
    entityItemsMap: modulesMap,
    analyticsPage: AnalyticsPageModule.ModulesList,
  });

  const onItemTrigger = useCallback(
    (id: BulkActionItemID): Promise<BulkActionItemTriggerResult> => {
      const module = modulesMap.get(id);
      if (!module || !selectedBulkAction) {
        return Promise.reject();
      }
      if (!selectedBulkAction.condition(module)) {
        return Promise.resolve(BulkActionsResult.Skipped);
      }
      return selectedBulkAction.mutation(module, undefined);
    },
    [modulesMap, selectedBulkAction]
  );

  const {
    triggerExecution,
    bulkActionItemResults,
    stopExecution,
    stopExecutionForItem,
    bulkActionResultsMetadata,
    resetBulkActionsExecutionQueue,
  } = useExecutionQueue({
    onItemTrigger,
    onAllItemsFinished: onFinish,
    analyticsPage: AnalyticsPageModule.ModulesList,
  });

  const { resultsTabItems, saveEntityMap, resetEntityMap } =
    useResultTabItems<Module>(bulkActionItemResults);

  const handleClose = useCallback(
    (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => {
      onBulkResetAll();
      resetSelectedAction();
      stopExecution();
      resetBulkActionsExecutionQueue();
      resetEntityMap();
      trackSegmentAnalyticsEvent("Bulk Actions - Exited", {
        view: variant,
        method,
      });
    },
    [
      onBulkResetAll,
      resetBulkActionsExecutionQueue,
      resetEntityMap,
      resetSelectedAction,
      stopExecution,
      trackSegmentAnalyticsEvent,
    ]
  );

  const handleOnConfirm = useCallback(
    (analyticsPayload: ModuleBulkActionsAnalyticsPayload) => {
      if (selectedBulkAction) {
        const items = itemsPerAction.get(selectedBulkAction.key) || [];
        const skippedItems = skippedSelectedEntityItems.map((item) => item.id);

        triggerExecution(items, undefined, skippedItems);
        saveEntityMap(modulesMap);

        trackSegmentAnalyticsEvent("Bulk Actions - Action Confirmed", {
          applicableCount: items?.length || 0,
          notApplicableCount: skippedItems.length,
          action: selectedBulkAction.key,
          ...analyticsPayload,
        });
      }
    },
    [
      itemsPerAction,
      modulesMap,
      saveEntityMap,
      selectedBulkAction,
      skippedSelectedEntityItems,
      trackSegmentAnalyticsEvent,
      triggerExecution,
    ]
  );

  const handleOnCancel = (variant: BulkActionsVariant) => () => {
    if (selectedBulkAction) {
      resetSelectedAction();
      const items = itemsPerAction.get(selectedBulkAction.key);
      trackSegmentAnalyticsEvent("Bulk actions - Action Cancelled", {
        applicableCount: items?.length || 0,
        notApplicableCount: skippedSelectedEntityItems.length,
        action: selectedBulkAction.key,
        view: variant,
      });
    }
  };

  const handleContinueWith = useCallback(
    (continueWith: BulkActionResultsStepContinueWith) => {
      getDefaultOnContinueWith(resultsTabItems, onBulkContinueWith)(continueWith);
      resetSelectedAction();
      resetBulkActionsExecutionQueue();
    },
    [onBulkContinueWith, resetSelectedAction, resultsTabItems, resetBulkActionsExecutionQueue]
  );

  return (
    <BulkActions
      selectedItemsCount={selectedItemsCount}
      onClose={handleClose}
      isExecutingBulkActions={false}
    >
      <BulkActionsFloatingBar virtualizedListContainerRef={virtualizedListContainerRef}>
        <BulkActionsFloatingBarHeader
          selectedItemsCount={selectedItemsCount}
          applicableItemsCount={
            selectedBulkAction?.key ? itemsPerAction.get(selectedBulkAction.key)?.length : 0
          }
          analyticsPage={AnalyticsPageModule.ModulesList}
        />
        <BulkActionsFloatingBarSteps>
          {!selectedBulkAction && (
            <BulkActionsPatternsActionsList
              variant={BulkActionsVariant.FloatingBar}
              availableActions={availableActions}
              onEmptyActionsCancel={handleClose}
            />
          )}

          {selectedBulkAction && (
            <ModulesBulkActionsConfirmActionForm
              variant={BulkActionsVariant.FloatingBar}
              action={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.FloatingBar)}
            />
          )}
        </BulkActionsFloatingBarSteps>
      </BulkActionsFloatingBar>

      <BulkActionsDrawer>
        <BulkActionsDrawerActionSteps>
          <BulkActionsDrawerActionStepsHeader
            analyticsPage={AnalyticsPageModule.ModulesList}
            step={
              !selectedBulkAction
                ? BulkActionsStep.ChooseAction
                : BulkActionsStep.ActionConfirmation
            }
          />
          <BulkActionsDrawerActionStepsInfoBanner />

          {!selectedBulkAction && (
            <ModulesBulkActionsSelectedItemsView
              items={selectedEntityItems}
              onItemDismiss={onItemDismiss}
              availableActions={availableActions}
              actionsPerItem={actionsPerItem}
              onEmptyActionsCancel={() =>
                handleClose(BulkActionsVariant.Drawer, BulkActionsCloseMethod.EmptyActionsButton)
              }
            />
          )}

          {selectedBulkAction && (
            <ModulesBulkActionsConfirmationView
              applicableItems={applicableSelectedEntityItems}
              skippedItems={skippedSelectedEntityItems}
              onItemDismiss={onItemDismiss}
              selectedBulkAction={selectedBulkAction}
              onConfirm={handleOnConfirm}
              onCancel={handleOnCancel(BulkActionsVariant.Drawer)}
            />
          )}
        </BulkActionsDrawerActionSteps>

        <BulkActionsDrawerResultsStep>
          {({ currentTab, setCurrentTab }) => (
            <>
              <BulkActionsDrawerResultsStepHeader
                title={selectedBulkAction?.resultTitle || "Results"}
                analyticsPage={AnalyticsPageModule.ModulesList}
              />

              <BulkActionsDrawerResultsStepBody
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                counter={resultsTabItems}
              >
                {() =>
                  resultsTabItems[currentTab].count === 0 ? (
                    <BulkActionsDrawerResultsStepBodyEmptyTab currentTab={currentTab} />
                  ) : (
                    resultsTabItems[currentTab].entitiesList.map(({ status, items }) =>
                      items.map((item) => (
                        <ModulesBulkActionsResultItem
                          key={item.id}
                          tab={currentTab}
                          item={item}
                          resultsMetadata={bulkActionResultsMetadata}
                          stopExecution={() => stopExecutionForItem(item.id)}
                          status={status}
                        />
                      ))
                    )
                  )
                }
              </BulkActionsDrawerResultsStepBody>

              <BulkActionsDrawerResultsStepFooter
                currentTab={currentTab}
                bulkActionItemResults={bulkActionItemResults}
                onContinueWith={handleContinueWith}
                stopExecution={stopExecution}
                analyticsPage={AnalyticsPageModule.ModulesList}
              />
            </>
          )}
        </BulkActionsDrawerResultsStep>
      </BulkActionsDrawer>
    </BulkActions>
  );
};

export default ModulesBulkActions;
