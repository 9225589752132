import { useCallback } from "react";

import useAnalytics from "hooks/useAnalytics";
import { ButtonProps } from "ds/components/Button";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import { ManagementStrategy } from "../Settings/types";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type LoginPolicyManageDropdownProps = {
  handleLoginPolicyEditDetailsClick: () => void;
  loginPolicyDeleteCallback?: () => void;
  refetchQueriesOnDelete?: string[];
  id: string;
  name: string;
  buttonVariant?: ButtonProps["variant"];
  size?: "small" | "medium";
  isLoginPolicyActive: boolean;
  analyticsPage:
    | AnalyticsPageOrganization.OrganizationLoginPoliciesList
    | AnalyticsPageOrganization.OrganizationLoginPolicyDetails;
};

const LoginPolicyManageDropdown = ({
  handleLoginPolicyEditDetailsClick,
  loginPolicyDeleteCallback,
  refetchQueriesOnDelete,
  id,
  name,
  buttonVariant = "secondary",
  size = "small",
  isLoginPolicyActive,
  analyticsPage,
}: LoginPolicyManageDropdownProps) => {
  const managementStrategy = isLoginPolicyActive
    ? ManagementStrategy.LOGIN_POLICY
    : ManagementStrategy.USER_MANAGEMENT;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: { managementStrategy },
  });

  const onDeleteClick = () => {
    trackSegmentAnalyticsEvent("Start Delete");

    showDeleteConfirmation({
      isLoginPolicyActive,
      name,
      id,
      refetchQueries: refetchQueriesOnDelete,
    }).then((confirmed) => {
      if (confirmed) {
        trackSegmentAnalyticsEvent("Confirm Delete");
        loginPolicyDeleteCallback?.();
      } else {
        trackSegmentAnalyticsEvent("Cancel Delete");
      }
    });
  };

  const copyCallback = useCallback(() => {
    trackSegmentAnalyticsEvent("Copy ID");
  }, [trackSegmentAnalyticsEvent]);

  return (
    <DropdownMenuEllipsis
      dotsSize={size}
      buttonVariant={buttonVariant}
      tooltip="Login policy actions"
    >
      <DropdownMenuItem onAction={handleLoginPolicyEditDetailsClick}>Edit details</DropdownMenuItem>
      <CopyFieldDropdownMenuItem title="Copy ID" value={id} onCopy={copyCallback} />
      <DropdownMenuItem onAction={onDeleteClick} danger>
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default LoginPolicyManageDropdown;
