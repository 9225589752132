import { useNavigate } from "react-router-dom-v5-compat";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";

import { ModuleCreationWizardFormStep, ModuleCreationWizardStep, ModuleFormFields } from "../types";
import { ModuleFormContext } from "../context";
import NewModuleFooter from "../Footer";
import NewModuleSummaryPanel from "./Panel";
import NewModuleSummaryDetails from "./Details";
import NewModuleSummaryBehaviour from "./Behavior";
import NewModuleSummaryShare from "./Share";
import NewModuleSummaryVcs from "./Vcs";
import NewModuleSummaryAttachedContexts from "./AttachedContexts";
import NewModuleSummaryPolicies from "./Policies";
import NewModuleSummaryCloud from "./Cloud";

type Components = {
  [key in ModuleCreationWizardFormStep]: React.FunctionComponent<{ data: ModuleFormFields[key] }>;
};

const components: Partial<Components> = {
  [ModuleCreationWizardStep.Details]: NewModuleSummaryDetails,
  [ModuleCreationWizardStep.Behavior]: NewModuleSummaryBehaviour,
  [ModuleCreationWizardStep.Share]: NewModuleSummaryShare,
  [ModuleCreationWizardStep.Vcs]: NewModuleSummaryVcs,
};

const NewModuleSummary = () => {
  const { availableSteps, formData, createdModuleId } = useTypedContext(ModuleFormContext);
  const navigate = useNavigate();

  const finishModuleCreation = () => {
    navigate(`/module/${createdModuleId}`, { replace: true });
  };

  const data: Array<{
    name: string;
    step: ModuleCreationWizardFormStep;
  }> = availableSteps.map(({ summaryName, step }) => ({
    name: summaryName,
    step,
  }));

  return (
    <>
      <FullScreenModalBody fullWidth>
        <Typography tag="h1" variant="p-t4" align="center">
          Summary
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Please verify your module setup. Once module is created you still will be able to apply
          changes from Module settings menu.
        </Typography>

        <Box direction="column" gap="large" margin="large 0">
          {data.map((panelData: { step: ModuleCreationWizardFormStep; name: string }) => {
            if (panelData.step === ModuleCreationWizardStep.Contexts) {
              return (
                <NewModuleSummaryAttachedContexts title={panelData.name} key={panelData.step} />
              );
            }
            if (panelData.step === ModuleCreationWizardStep.Policies) {
              return <NewModuleSummaryPolicies title={panelData.name} key={panelData.step} />;
            }

            if (panelData.step === ModuleCreationWizardStep.Cloud) {
              return (
                <NewModuleSummaryCloud
                  title={panelData.name}
                  key={panelData.step}
                  createdModuleId={createdModuleId}
                />
              );
            }

            const Component = components[panelData.step] as React.FunctionComponent<{
              data: ModuleFormFields[ModuleCreationWizardFormStep];
            }>;
            const data = formData[panelData.step];

            if (!Component || !data) {
              return null;
            }

            return (
              <NewModuleSummaryPanel key={panelData.step} {...panelData}>
                <Box direction="column">
                  <Component data={data} />
                </Box>
              </NewModuleSummaryPanel>
            );
          })}
        </Box>
      </FullScreenModalBody>
      <NewModuleFooter handleOnCreateModuleClick={finishModuleCreation} />
    </>
  );
};

export default NewModuleSummary;
