import { gql } from "@apollo/client";

export const UPDATE_STACK = gql`
  mutation UpdateStack($stackId: ID!, $input: StackInput!) {
    stackUpdate(id: $stackId, input: $input) {
      id
    }
  }
`;

export const DELETE_STACK = gql`
  mutation DeleteStack($stackId: ID!, $shouldDeleteResources: Boolean) {
    stackDelete(id: $stackId, destroyResources: $shouldDeleteResources) {
      id
    }
  }
`;

export const STAR_STACK = gql`
  mutation StarSet($id: ID!, $star: Boolean!) {
    starSet(id: $id, star: $star) {
      id
      starred
    }
  }
`;
