import { ReactNode } from "react";

import Box from "ds/components/Box";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Typography from "ds/components/Typography";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";

type ModuleSettingsPageLayoutProps = {
  title: string;
  description: string;
  actions?: ReactNode;
  navigation?: ReactNode;
  children: ReactNode;
};

const ModuleSettingsPageLayout = ({
  children,
  title,
  description,
  actions,
  navigation,
  ...rest
}: ModuleSettingsPageLayoutProps) => {
  return (
    <Box direction="column" grow="1">
      <ViewHeader noBreadCrumbs noSticky>
        <ViewHeaderWrapper justify="between" fullWidth>
          <Box direction="column">
            <ViewHeaderTitle titleSize="p-t5">{title}</ViewHeaderTitle>
            <Typography tag="p" variant="p-body3" color="secondary">
              {description}
            </Typography>
          </Box>
          <Box align="center">{actions}</Box>
        </ViewHeaderWrapper>
        {navigation && <ViewHeaderNavigation>{navigation}</ViewHeaderNavigation>}
      </ViewHeader>
      <Box direction="column" grow="1" {...rest}>
        {children}
      </Box>
    </Box>
  );
};

export default ModuleSettingsPageLayout;
