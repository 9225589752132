import { gql } from "@apollo/client";

import actionFields from "views/shared/run/ActionButtons/ActionFragment";

// Remember about syncing this query with the GetVersionRun query in
// src/views/module/Version/Run/index.tsx
export const GET_RUN = gql`
  query GetModuleRun($moduleId: ID!, $runId: ID!) {
    module(id: $moduleId) {
      id
      isDisabled
      run(id: $runId) {
        ...actionFields
        branch
        promoteBlocker
        command
        createdAt
        isPrioritized
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
        }
        comments {
          __typename
          body
          createdAt
          username
        }
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
        }
        expectFailure
        needsApproval
        driftDetection
        expired
        isMostRecent
        reviews {
          id
          author
          decision
          note
          timestamp
        }
        planPoliciesOutcomes {
          id
          deny
          warn
          createdAt
        }
        policyReceipts {
          id
          flags
          createdAt
          policyName
          policyType
          policySlug
          outcome
          sampled
          sampleExpired
        }
        runtimeConfig {
          terraform {
            version
          }
        }
        title
        triggeredBy
        type
        next {
          id
        }
        previous {
          id
        }
      }
    }
  }
  ${actionFields}
`;
