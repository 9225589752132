import useLocalStorage from "@rehooks/local-storage";
import { useEffect, useMemo } from "react";

import { NAVIGATION_CONFIGURATION_STORAGE_KEY } from "constants/navigation";
import useNavigationConfig from "hooks/useNavigationConfig";
import {
  SideNavigationItemType,
  NavigationConfigType,
  SidebarStorageConfig,
} from "types/Navigation";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

const getConfigItems = (navigationConfig: NavigationConfigType, types: SideNavigationItemType[]) =>
  types
    .map((type) => (navigationConfig[type] ? { ...navigationConfig[type], type } : undefined))
    .filter((item) => !!item);

const getAvailableItems = (navigationConfig: NavigationConfigType) => {
  const allNavigationItems = Object.keys(navigationConfig) as SideNavigationItemType[];

  return allNavigationItems.filter((type) => {
    const config = navigationConfig[type];

    if (!config) {
      return false;
    }

    return typeof config.hidden !== "undefined" ? !config.hidden : true;
  });
};

const mergeVisibleItems = (
  navigationConfig: NavigationConfigType,
  items: SideNavigationItemType[],
  newItems: SideNavigationItemType[]
) => {
  const newVisibleItems = [...items];
  const allNavigationItems = Object.keys(navigationConfig) as SideNavigationItemType[];

  // try to push item into place similar to defined in config
  newItems.forEach((item) => {
    const indexOfNewItem = allNavigationItems.indexOf(item);

    if (indexOfNewItem >= newVisibleItems.length) {
      newVisibleItems.push(item);
    } else {
      newVisibleItems.splice(indexOfNewItem, 0, item);
    }
  });

  return newVisibleItems;
};

export function useNavigationItemsConfig() {
  const { viewer } = useTypedContext(AccountContext);

  const [sidebarStorageConfig, setSidebarStorageConfig] = useLocalStorage<SidebarStorageConfig>(
    NAVIGATION_CONFIGURATION_STORAGE_KEY
  );

  const navigationConfig = useNavigationConfig({ isAdmin: viewer.admin });

  const allAvailableNavigationItems = getAvailableItems(navigationConfig);

  const isNotCustomized = !sidebarStorageConfig;

  const [shouldUpdateStorageConfig, newVisibleItems, newHiddenItems] = useMemo(() => {
    if (isNotCustomized) {
      return [false, [], []];
    }

    const currentVisibleItems = sidebarStorageConfig?.visible || [];
    const currentHiddenItems = sidebarStorageConfig?.hidden || [];

    const removedVisibleItems = currentVisibleItems.filter(
      (navigationItemKey) => !allAvailableNavigationItems.includes(navigationItemKey)
    );

    const removedHiddenItems = currentHiddenItems.filter(
      (navigationItemKey) => !allAvailableNavigationItems.includes(navigationItemKey)
    );

    const newItems = allAvailableNavigationItems.filter(
      (navigationItemKey) =>
        !currentVisibleItems.includes(navigationItemKey) &&
        !currentHiddenItems.includes(navigationItemKey)
    );

    const shouldUpdateStorageConfig =
      removedHiddenItems.length || removedVisibleItems.length || newItems.length;

    const newVisibleItems = mergeVisibleItems(
      navigationConfig,
      currentVisibleItems.filter((item) => !removedVisibleItems.includes(item)),
      newItems
    );

    const newHiddenItems = currentHiddenItems.filter((item) => !removedHiddenItems.includes(item));

    return [shouldUpdateStorageConfig, newVisibleItems, newHiddenItems];
  }, [
    sidebarStorageConfig?.visible,
    sidebarStorageConfig?.hidden,
    isNotCustomized,
    allAvailableNavigationItems,
    navigationConfig,
  ]);

  useEffect(() => {
    if (shouldUpdateStorageConfig) {
      setSidebarStorageConfig({
        visible: newVisibleItems,
        hidden: newHiddenItems,
      });
    }
  }, [shouldUpdateStorageConfig, newVisibleItems, newHiddenItems, setSidebarStorageConfig]);

  if (isNotCustomized) {
    return {
      visibleItems: getConfigItems(navigationConfig, allAvailableNavigationItems),
      hiddenItems: [],
    };
  }

  return {
    visibleItems: getConfigItems(navigationConfig, newVisibleItems),
    hiddenItems: getConfigItems(navigationConfig, newHiddenItems),
  };
}
