import { GraphQLErrorExtensions } from "graphql";

export const getMessageWithExtensions = (message: string, extensions?: GraphQLErrorExtensions) => {
  const extensionsErrors = extensions
    ? Object.entries(extensions)
        .map((item) => item.join(": "))
        .join("\n")
    : "";

  return message + extensionsErrors;
};
