import { useMemo } from "react";

import {
  MenuBlueprints,
  MenuCloudIntegrations,
  MenuContexts,
  MenuDashboard,
  MenuIntegrations,
  MenuModules,
  MenuPolicies,
  MenuResources,
  MenuRuns,
  MenuSpaces,
  MenuStacks,
  MenuWebhooks,
  MenuWorkerPools,
} from "components/icons";
import { SideNavigationItemType, NavigationConfigType } from "types/Navigation";
import { VCS_INTEGRATIONS_PATH } from "views/Account/VCS/constants";

import useTypedFlags from "./useTypedFlags";

type UseNavigationConfigProps = {
  isAdmin: boolean;
};

const useNavigationConfig = ({ isAdmin }: UseNavigationConfigProps): NavigationConfigType => {
  const { ansibleConfigurationManagementFrontend } = useTypedFlags();

  return useMemo<NavigationConfigType>(
    () => ({
      ...(isAdmin
        ? {
            [SideNavigationItemType.Dashboard]: {
              title: "Dashboard",
              icon: MenuDashboard,
              to: "/dashboard",
              rule: ["/dashboard"],
            },
          }
        : {}),
      [SideNavigationItemType.Stacks]: {
        title: "Stacks",
        icon: MenuStacks,
        to: "/stacks",
        rule: ["/stack", "/new/stack"],
      },
      [SideNavigationItemType.Blueprints]: {
        title: "Blueprints",
        icon: MenuBlueprints,
        to: "/blueprints",
        rule: ["/blueprint"],
      },
      [SideNavigationItemType.Modules]: {
        icon: MenuModules,
        title: "Terraform registry",
        to: "/modules",
        rule: ["/module", "/provider", "/gpg-key", "/new/module", "/shared-modules"],
      },
      [SideNavigationItemType.Runs]: {
        title: "Runs",
        icon: MenuRuns,
        to: "/runs",
        rule: ["/runs"],
      },
      [SideNavigationItemType.Spaces]: {
        title: "Spaces",
        icon: MenuSpaces,
        to: "/spaces",
        rule: ["/spaces"],
      },
      [SideNavigationItemType.Resources]: {
        title: "Resources",
        icon: MenuResources,
        to: "/resources",
        id: ansibleConfigurationManagementFrontend
          ? (hasAnsibleStacks?: boolean) =>
              hasAnsibleStacks ? "resources-with-configuration-management" : undefined
          : undefined,
        rule: ["/resources"],
      },
      [SideNavigationItemType.Policies]: {
        title: "Policies",
        icon: MenuPolicies,
        to: "/policies",
        rule: ["/policy", "/new/policy", "/policies"],
      },
      [SideNavigationItemType.Contexts]: {
        title: "Contexts",
        icon: MenuContexts,
        to: "/contexts",
        rule: ["/context", "/new/context"],
      },
      [SideNavigationItemType.WorkerPools]: {
        title: "Worker pools",
        icon: MenuWorkerPools,
        to: "/worker-pools",
        rule: ["/worker-pool"],
      },
      [SideNavigationItemType.Webhooks]: {
        title: "Webhooks",
        icon: MenuWebhooks,
        to: "/webhooks",
        rule: ["/webhook"],
      },
      [SideNavigationItemType.VCSManagement]: {
        title: "Source code",
        icon: MenuIntegrations,
        to: VCS_INTEGRATIONS_PATH,
        rule: ["/vcs-agent-pool", "/new/vcs-agent-pool", "/vcs/"],
      },
      [SideNavigationItemType.CloudIntegrations]: {
        title: "Cloud integrations",
        icon: MenuCloudIntegrations,
        to: "/cloud-integrations",
        rule: ["/cloud-integrations"],
      },
    }),
    [ansibleConfigurationManagementFrontend, isAdmin]
  );
};

export default useNavigationConfig;
