import { isEqual } from "lodash-es";

export type TreeNode<T> = {
  id: string;
  name: string;
  parentId: string | null;
  item: T;
  children: TreeNode<T>[];
  path: string[];
};

export const convertToTreeStructure = <T extends object>(
  data: T[],
  idKey: keyof T,
  parentKey: keyof T,
  nameKey: keyof T,
  sortPredicate?: (a: T, b: T) => number
): { tree: TreeNode<T>[]; flatList: TreeNode<T>[] } => {
  const itemMap: Map<string, TreeNode<T>> = new Map();
  const rootItems: TreeNode<T>[] = [];
  const flatList: TreeNode<T>[] = []; // Flat list to store the nodes in render order

  // Initial setup of tree nodes with empty children and an empty path array
  data.forEach((item) => {
    itemMap.set(String(item[idKey]), {
      item,
      children: [],
      path: [],
      id: String(item[idKey]),
      parentId: item[parentKey] ? String(item[parentKey]) : null,
      name: String(item[nameKey]),
    });
  });

  data.forEach((item) => {
    const itemId = String(item[idKey]);
    const parentId = item[parentKey] ? String(item[parentKey]) : null;
    const treeNode = itemMap.get(itemId);

    if (!treeNode) return;

    if (parentId && itemMap.has(parentId)) {
      const parentTreeNode = itemMap.get(parentId);

      treeNode.path = [itemId];

      // Find the path of the node
      let currentParent = parentTreeNode;
      while (currentParent) {
        treeNode.path.unshift(String(currentParent.id));
        currentParent = itemMap.get(String(currentParent.parentId));
      }

      parentTreeNode?.children.push(treeNode);
    } else {
      treeNode.path = [itemId]; // Root node path starts with its own ID
      rootItems.push(treeNode);
    }
  });

  if (data.length > 0 && sortPredicate) {
    sortTree(rootItems, sortPredicate);
  }

  const addToFlatList = (node: TreeNode<T>) => {
    flatList.push(node);
    node.children.forEach(addToFlatList);
  };

  rootItems.forEach(addToFlatList);

  return { tree: rootItems, flatList };
};

export const search = <T>(
  flatList: TreeNode<T>[],
  query: string,
  searchByKeys: (keyof TreeNode<T>)[] = ["name"]
): string[][] => {
  const matchedPaths: string[][] = [];
  const queryValue = query.toLowerCase();

  flatList.forEach((treeNode) => {
    const searchByKeysValues = searchByKeys.map((key) => String(treeNode[key]).toLowerCase());

    const hasMatch = searchByKeysValues.some((value) => value.includes(queryValue));

    if (hasMatch) {
      matchedPaths.push(treeNode.path);
    }
  });

  return matchedPaths;
};

export const updateTreeRendering = <T>(
  flatList: TreeNode<T>[],
  matchedPaths: string[][]
): { expandedNodes: Set<string>; renderedNodes: Set<string> } => {
  const expandedNodes = new Set<string>();
  const renderedNodes = new Set<string>();

  flatList.forEach((node) => {
    const nodePath = node.path;
    const nodeId = node.id;
    const { matched, ancestor, descendant } = determineRelationship(nodePath, matchedPaths);

    if (matched || ancestor) {
      expandedNodes.add(nodeId);
      renderedNodes.add(nodeId);
    }

    if (descendant) {
      renderedNodes.add(nodeId);
    }
  });

  return { expandedNodes, renderedNodes };
};

const determineRelationship = (
  nodePath: string[],
  matchedPaths: string[][]
): { matched: boolean; ancestor: boolean; descendant: boolean } => {
  let matched = false;
  let ancestor = false;
  let descendant = false;

  for (const path of matchedPaths) {
    if (!matched) {
      matched = isEqual(nodePath, path);
    }

    if (!ancestor) {
      ancestor = nodePath.length < path.length && isEqual(path.slice(0, nodePath.length), nodePath);
    }

    if (!descendant) {
      descendant = nodePath.length > path.length && isEqual(nodePath.slice(0, path.length), path);
    }
  }

  return {
    matched,
    ancestor,
    descendant,
  };
};

export const isNodeVisible = <T>(
  node: TreeNode<T>,
  renderedKeys: Set<string>,
  expandedKeys: Set<string>
): boolean => {
  const nodeRendered = renderedKeys.has(node.id);

  if (!nodeRendered) {
    return false;
  }

  const parentNodes = node.path.slice(0, -1);
  // Check if every node in the path is both rendered and expanded
  return parentNodes.every((id) => renderedKeys.has(id) && expandedKeys.has(id));
};

const sortTree = <T>(treeNodes: TreeNode<T>[], sortPredicate: (a: T, b: T) => number): void => {
  treeNodes.forEach((node) => {
    if (node.children.length > 0) {
      sortTree(node.children, sortPredicate);
    }
  });

  treeNodes.sort((a, b) => {
    return sortPredicate(a.item, b.item);
  });
};
