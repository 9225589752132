import { CodeChangesChangeItem, CodeChangesGroupNode } from "./types";

export const hasChildren = (item: CodeChangesChangeItem): item is CodeChangesGroupNode => {
  return (
    Array.isArray((item as CodeChangesGroupNode).children) &&
    (item as CodeChangesGroupNode).children.length > 0
  );
};

// checks if the item is an array or an array-like object
export const isArray = (item: CodeChangesChangeItem): boolean => {
  if (!hasChildren(item)) return false;

  // check if all the children have numeric keys
  return item.children.every((child) => !isNaN(Number(child.key)));
};

export const isValidKey = (key?: string | number | null): boolean => {
  return key !== undefined && key !== null && key !== "";
};
