export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const downloadFile = (name: string, text: string): void => {
  const file = new Blob([text], { type: "text/plain" });
  downloadFileFromURL(URL.createObjectURL(file), name);
};

export const downloadFileFromURL = (url: string, name = "") => {
  const link = document.createElement("a");
  link.download = name;
  link.target = "_blank";
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
