import { forwardRef, ReactNode } from "react";
import cx from "classnames";
import { camelCase } from "lodash-es";

import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";
import { SyntaxTheme } from "types/SyntaxTheme";

import styles from "./styles.module.css";

type ThemedLogsProps = {
  onScroll?: () => void;
  children?: ReactNode;
  className?: string;
};

const ThemedLogs = forwardRef(function ThemedLogs(
  { onScroll, children, className }: ThemedLogsProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { isDarkMode, currentLightModeSyntaxTheme, currentDarkModeSyntaxTheme } =
    useTypedContext(ThemeContext);

  const syntaxTheme = isDarkMode ? currentDarkModeSyntaxTheme : currentLightModeSyntaxTheme;

  return (
    <Box
      className={cx(styles.container, styles[camelCase(syntaxTheme)], className)}
      ref={ref}
      onScroll={onScroll}
      data-theme={syntaxTheme === SyntaxTheme.Dark ? "dark" : "light"}
    >
      {children}
    </Box>
  );
});

export default ThemedLogs;
