import { RunStateTransition } from "types/generated";

import SkippedEntry from "../entries/SkippedEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createSkippedEntry = ({ timestamp, note, stateVersion }: RunStateTransition) => {
  return (
    <SkippedEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      note={note}
    />
  );
};
