import { gql, TypedDocumentNode } from "@apollo/client";

import { stackVendorConfigFragment } from "components/form/graphql";
import {
  QuerySearchStacksArgs,
  SearchStacksOutput,
  SearchSuggestionsField,
  SearchSuggestionsInput,
  SearchSuggestionsOutput,
  Stack,
  StackConnection,
} from "types/generated";

type SearchStacksSuggestionsData = {
  searchStacksSuggestions: {
    fields: Array<Omit<SearchSuggestionsField, "possibleValuesTime">>;
    filteredCount: SearchSuggestionsOutput["filteredCount"];
  };
};

export const SEARCH_STACKS_SUGGESTIONS: TypedDocumentNode<
  SearchStacksSuggestionsData,
  SearchSuggestionsInput
> = gql`
  query SearchStacksSuggestionsNew($input: SearchSuggestionsInput!) {
    searchStacksSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

type SearchStacksMinimalData = {
  searchStacks: {
    edges: Array<{
      cursor: StackConnection["cursor"];
      // TODO: pick properties
      node: Stack;
    }>;
    pageInfo: SearchStacksOutput["pageInfo"];
  };
};

export const MINIMAL_SEARCH_STACKS: TypedDocumentNode<
  SearchStacksMinimalData,
  QuerySearchStacksArgs
> = gql`
  query SearchStacksNewMinimal($input: SearchInput!) {
    searchStacks(input: $input) {
      edges {
        cursor
        node {
          id
          name
          state
          canWrite
          isDisabled
          lockedAt
          lockedBy
          spaceDetails {
            id
            accessLevel
          }
          blocker {
            id
            state
            needsApproval
          }
          trackedCommit {
            url
            hash
          }
          trackedBranchHead {
            url
            hash
          }
          provider
          vcsDetached
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;

type SearchStacksData = {
  searchStacks: {
    edges: Array<{
      cursor: StackConnection["cursor"];
      // TODO: pick properties
      node: Stack;
    }>;
    pageInfo: SearchStacksOutput["pageInfo"];
  };
};

export const SEARCH_STACKS: TypedDocumentNode<SearchStacksData, QuerySearchStacksArgs> = gql`
  query SearchStacksNew($input: SearchInput!) {
    searchStacks(input: $input) {
      edges {
        cursor
        node {
          id
          administrative
          apiHost
          branch
          blocked
          blueprint {
            name
            ulid
          }
          description
          blocker {
            id
            state
            needsApproval
          }
          delta {
            addCount
            changeCount
            deleteCount
            resources
            importCount
            forgetCount
            moveCount
            ignoreCount
            okCount
            rescueCount
            skipCount
            unreachableCount
            failedCount
          }
          canWrite
          isDisabled
          createdAt
          effectiveTerraformVersion
          labels
          lockedAt
          lockedBy
          lockNote
          managesStateFile
          name
          namespace
          projectRoot
          provider
          repository
          spaceDetails {
            id
            name
            accessLevel
          }
          starred
          state
          stateSetAt
          stateSetBy
          vcsDetached
          isStateRollback
          trackedCommit {
            url
            hash
          }
          trackedBranchHead {
            url
            hash
          }
          trackedCommitSetBy
          ...stackVendorConfig
          scheduledDeletes {
            id
            nextSchedule
          }
          integrations {
            driftDetection {
              nextSchedule
            }
          }
          isDrifted
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
    tier #a hacky way to make token refresh when polling stack in the background
  }
  ${stackVendorConfigFragment}
`;

export const STAR_SET = gql`
  mutation StarSet($id: ID!, $star: Boolean!) {
    starSet(id: $id, star: $star) {
      id
      starred
    }
  }
`;
