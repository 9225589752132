import { useState } from "react";

export const useTaskForm = () => {
  const [commandValue, setCommandValue] = useState("");
  const [skipInitialization, setSkipInitialization] = useState(false);

  return {
    commandValue,
    setCommandValue,
    skipInitialization,
    setSkipInitialization,
  };
};
