import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import Banner from "ds/components/Banner";
import { BoxProps } from "ds/components/Box";
import Typography from "ds/components/Typography";
import { DocsUrl, getDocsUrl } from "utils/getDocsUrl";

type EntityType = "stack" | "module";

type FormFieldTagsMagicBannerProps = {
  margin?: BoxProps["margin"];
  docsUrl?: DocsUrl;
  entityType: EntityType;
};

const FormFieldTagsMagicBanner = ({
  margin,
  docsUrl = getDocsUrl("/concepts/stack/stack-settings.html#labels"),
  entityType,
}: FormFieldTagsMagicBannerProps) => {
  return (
    <Banner
      title={`There are magic labels that you can add to your ${entityType === "stack" ? "stacks" : "modules"}`}
      variant="info"
      margin={margin}
    >
      <Typography tag="p" variant="p-body3" margin="0 0 medium 0">
        These labels add/remove functionalities based on their presence. For example, you can
        auto-attach policies and contexts, enable infracost, or even add the plan on your PR as a
        comment.
      </Typography>
      <ReadMoreDocsLink docsUrl={docsUrl} />
    </Banner>
  );
};

export default FormFieldTagsMagicBanner;
