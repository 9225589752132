import { TimeMachine } from "components/icons";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import Tooltip from "ds/components/Tooltip";

import { StackContext } from "../Context";
import { showStackStateHistoryRollBackConfirmationModal } from "./RollbackConfirmationModal";

type StackStateHistoryRollBackActionProps = {
  onRollback: () => void;
  isCurrentState: boolean;
  loading: boolean;
};

const lockTooltip = "Please lock the stack first.";
const adminAccessTooltip = "Only admin can roll back state.";
const currentStateTooltip = "You cannot roll back the current state.";

const StackStateHistoryRollBackAction = ({
  onRollback,
  isCurrentState,
  loading,
}: StackStateHistoryRollBackActionProps) => {
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);

  const actionIsDisabled = !canManageStackAndRuns || !stack.lockedBy || isCurrentState;

  const onModalShow = () => {
    showStackStateHistoryRollBackConfirmationModal({ onConfirm: onRollback });
  };

  return (
    <Tooltip
      active={actionIsDisabled}
      on={(props) => (
        <Button
          {...props}
          variant="secondary"
          startIcon={TimeMachine}
          onClick={onModalShow}
          disabled={actionIsDisabled}
          loading={loading}
          fullWidth
        >
          Roll back
        </Button>
      )}
    >
      {!canManageStackAndRuns && adminAccessTooltip}
      {canManageStackAndRuns && isCurrentState && currentStateTooltip}
      {!stack.lockedBy && canManageStackAndRuns && !isCurrentState && lockTooltip}
    </Tooltip>
  );
};

export default StackStateHistoryRollBackAction;
