import Typography from "ds/components/Typography";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import BulkActionsSelectAllCheckbox from "components/BulkActionsPatterns/SelectAllCheckbox";
import TooltipInfo from "ds/components/TooltipInfo";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import Box from "ds/components/Box";
import { getDocsUrl } from "utils/getDocsUrl";

import { COLUMN_GAP, COLUMN_ORDER, SortableColumns } from "../constants";

type PrivateWorkerPoolWorkersFiltersLayoutHeaderProps = {
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
};

const PrivateWorkerPoolWorkersFiltersLayoutHeader = ({
  allSelected,
  onResetAll,
  onSelectAll,
}: PrivateWorkerPoolWorkersFiltersLayoutHeaderProps) => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderStaticColumn>
        <BulkActionsSelectAllCheckbox
          allSelected={allSelected}
          onResetAll={onResetAll}
          onSelectAll={onSelectAll}
        />
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Status
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderSortableColumn
        option={{
          label: "ID",
          value: SortableColumns.ULID,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderStaticColumn>
        <Box gap="small" align="center">
          <Typography tag="span" variant="p-t6">
            Metadata tags
          </Typography>
          <TooltipInfo variant="modal">
            <TooltipModalTitle>Metadata tags</TooltipModalTitle>
            <TooltipModalBody align="start">
              These tags are set via SPACELIFT_METADATA_ environment variables.
              <Link
                href={getDocsUrl("/concepts/worker-pools#passing-metadata-tags")}
                target="_blank"
              >
                Learn more
              </Link>
            </TooltipModalBody>
          </TooltipInfo>
        </Box>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderSortableColumn
        option={{
          label: "Created at",
          value: SortableColumns.CREATED_AT,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
};

export default PrivateWorkerPoolWorkersFiltersLayoutHeader;
