import { ReactNode } from "react";
import cx from "classnames";

import Typography from "../Typography";
import styles from "./styles.module.css";

type FeedbackHeadlineProps = {
  children?: ReactNode;
  className?: string;
};

const FeedbackHeadline = ({ className, children }: FeedbackHeadlineProps) => {
  return (
    <Typography tag="p" variant="p-t7" className={cx(styles.headline, className)}>
      {children}
    </Typography>
  );
};

export default FeedbackHeadline;
