import cx from "classnames";

import { BeforeCommandsColored, AfterCommandsColored } from "components/icons";
import { HookType } from "utils/hooks";
import DropEmptyState from "components/DropEmptyState";

import styles from "./styles.module.css";

type BeforeAfterCommandsEmptyStateProps = {
  type: HookType;
  hidden: boolean;
  isDragActive: boolean;
};

const BeforeAfterCommandsEmptyState = ({
  type,
  hidden,
  isDragActive,
}: BeforeAfterCommandsEmptyStateProps) => {
  return (
    <DropEmptyState
      dropCaption="You can drop the command here"
      isDragActive={isDragActive && !hidden}
      icon={type === "before" ? BeforeCommandsColored : AfterCommandsColored}
      caption={`This is where your ${type} commands will appear`}
      padding={hidden ? undefined : "large"}
      margin={hidden ? undefined : "0 0 large"}
      className={cx(styles.box, {
        [styles.hidden]: hidden,
      })}
    />
  );
};

export default BeforeAfterCommandsEmptyState;
