import { memo, useEffect, useRef } from "react";

import { SharedModule } from "types/generated";
import Box from "ds/components/Box";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import ListEntitiesItem from "components/ListEntitiesItem";
import ModuleVersionStateBadge from "components/ModuleVersionStateBadge";
import TagsList from "components/TagsList";
import { replaceSubdomain } from "utils/history";
import CopyFieldIcon from "components/CopyField/Icon";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { Profile } from "components/icons";

import styles from "./styles.module.css";

export type ModuleListItemProps = {
  module: SharedModule;
  setRowHeight?: (size: number) => void;
  onShowFullDescription: (module: SharedModule) => void;
};

const ModuleListItem = (props: ModuleListItemProps) => {
  const { module, setRowHeight, onShowFullDescription } = props;

  const rowRef = useRef<HTMLDivElement>(null);

  const moduleOrigin = replaceSubdomain(window.location.origin, module.ownerSubdomain);

  const moduleUrl = `${moduleOrigin}/module/${module.id}`;

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(module);
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  const moduleLatestVersionUrl = module.latest ? `${moduleUrl}/version/latest` : null;

  return (
    <ListEntitiesItem ref={rowRef} className={styles.listItem} direction="column" gap="small">
      <Box justify="between">
        <Box gap="medium" align="center">
          <ListEntitiesItemLink href={moduleUrl} title={module.name} titleVariant="p-t5" />

          <ModuleVersionStateBadge
            state={module.current?.state}
            number={module.current?.number}
            yanked={module.current?.yanked}
          />
        </Box>

        <CopyFieldIcon title="Copy ID" value={module.id} />
      </Box>

      <Box direction="column" gap="medium">
        {module.description && (
          <ListEntitiesItemDescription
            className={styles.moduleDescription}
            description={module.description}
            onShowFullDescription={handleShowFullDescription}
          />
        )}
        <MetaInfoList>
          <MetaInfoListItem icon={Profile} tooltip="Owner">
            {module.ownerSubdomain}
          </MetaInfoListItem>

          {moduleLatestVersionUrl && (
            <MetaInfoListItem
              linkText="See the latest stable version"
              href={moduleLatestVersionUrl}
            />
          )}
        </MetaInfoList>
      </Box>

      <TagsList tags={module.labels} onExpand={handleRowHeight} />
    </ListEntitiesItem>
  );
};

export default memo(ModuleListItem);
