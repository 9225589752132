import { Run, Stack } from "types/generated";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActions/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActions/Drawer/ActionSteps/Confirmation/EmptyTab";

import StackRunsBulkActionsSelectedItem from "./SelectedItem";
import StackRunsBulkActionsConfirmActionForm from "./ConfirmActionForm";
import { StackRunsBulkActionsAnalyticsPayload, StackRunsBulkActionsSelectedAction } from "./types";
import { StackRunsMutationVariables } from "./useStackRunsBulkActions";

type StackRunsBulkActionsConfirmationViewProps = {
  stack: Stack;
  applicableItems: Run[];
  skippedItems: Run[];
  onItemDismiss: (id: BulkActionItemID) => void;
  selectedBulkAction: StackRunsBulkActionsSelectedAction;
  onConfirm: (
    mutationVariables: StackRunsMutationVariables,
    analyticsPayload: StackRunsBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const StackRunsBulkActionsConfirmationView = ({
  stack,
  applicableItems,
  skippedItems,
  onItemDismiss,
  selectedBulkAction,
  onConfirm,
  onCancel,
}: StackRunsBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <StackRunsBulkActionsSelectedItem
                key={item.id}
                item={item}
                stack={stack}
                onDismiss={onItemDismiss}
                itemCategory={currentTab}
                step={BulkActionsStep.ActionConfirmation}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <StackRunsBulkActionsConfirmActionForm
          stack={stack}
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default StackRunsBulkActionsConfirmationView;
