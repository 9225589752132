import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { Maybe, VcsProvider } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { MULTIPLE_VCS_PROVIDERS } from "./constants";
import {
  TEST_AZURE_DEVOPS_INTEGRATION,
  TEST_BITBUCKET_CLOUD_INTEGRATION,
  TEST_BITBUCKET_DATACENTER_INTEGRATION,
  TEST_GITHUB_INTEGRATION,
  TEST_GITLAB_INTEGRATION,
} from "./gql";

type UseTestVCSIntegrationConnectionProps = {
  provider: VcsProvider;
  id: Maybe<string>;
  name: string;
};

const MUTATION_BY_PROVIDER = {
  [MULTIPLE_VCS_PROVIDERS.AZURE_DEVOPS]: TEST_AZURE_DEVOPS_INTEGRATION,
  [MULTIPLE_VCS_PROVIDERS.BITBUCKET_CLOUD]: TEST_BITBUCKET_CLOUD_INTEGRATION,
  [MULTIPLE_VCS_PROVIDERS.BITBUCKET_DATACENTER]: TEST_BITBUCKET_DATACENTER_INTEGRATION,
  [MULTIPLE_VCS_PROVIDERS.GITHUB_ENTERPRISE]: TEST_GITHUB_INTEGRATION,
  [MULTIPLE_VCS_PROVIDERS.GITHUB]: TEST_GITHUB_INTEGRATION,
  [MULTIPLE_VCS_PROVIDERS.GITLAB]: TEST_GITLAB_INTEGRATION,
};

const useTestVCSIntegrationConnection = ({
  provider,
  id,
  name,
}: UseTestVCSIntegrationConnectionProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [testIntegrationConnection, { loading }] = useMutation(MUTATION_BY_PROVIDER[provider]);

  const handleTestIntegrationConnection = useCallback(() => {
    testIntegrationConnection({
      variables: {
        ...((id && { id }) || {}),
      },
    })
      .then(({ data }) => {
        if (data) {
          reportSuccess({ message: `Connecting to ${name} succeeded` });
        }
      })
      .catch(onError);
  }, [id, name, onError, reportSuccess, testIntegrationConnection]);

  return {
    testIntegrationConnection: handleTestIntegrationConnection,
    loading,
  };
};

export default useTestVCSIntegrationConnection;
