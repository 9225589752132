import { EntityChangeV2 } from "types/generated";

export enum RunChangesStatus {
  PLANNED = "PLANNED",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  UNCHANGED = "UNCHANGED",
  UNPLANNED = "UNPLANNED",
}

export type RunChangesItem = EntityChangeV2 & {
  status: RunChangesStatus;
};
