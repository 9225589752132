import Box from "ds/components/Box";
import { AnalyticsPage } from "hooks/useAnalytics";

import ConfirmRunButton from "../../components/ConfirmRunButton";
import DiscardRunButton from "../../components/DiscardRunButton";
import HistoryEntryNote from "../../components/HistoryEntryNote";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";

type UnconfirmedEntryProps = {
  timestamp: number;
  until: number | null;
  note: string | null;
  showDiscardButton: boolean;
  showConfirmButton: boolean;
  canConfirm: boolean;
  canDiscard: boolean;
  runId: string;
  stackId: string;
  isStackDisabled: boolean;
  analyticsPage?: AnalyticsPage;
};

const UnconfirmedEntry = ({
  timestamp,
  until,
  note,
  showConfirmButton,
  showDiscardButton,
  canConfirm,
  canDiscard,
  runId,
  stackId,
  isStackDisabled,
  analyticsPage,
}: UnconfirmedEntryProps) => {
  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.Unconfirmed}
      timestamp={timestamp}
      until={until}
    >
      {note && <HistoryEntryNote>{note}</HistoryEntryNote>}
      <Box gap="medium">
        {canConfirm && showConfirmButton && (
          <ConfirmRunButton
            runId={runId}
            stackId={stackId}
            isStackDisabled={isStackDisabled}
            analyticsPage={analyticsPage}
          />
        )}
        {canDiscard && showDiscardButton && (
          <DiscardRunButton stackId={stackId} runId={runId} analyticsPage={analyticsPage} />
        )}
      </Box>
    </HistoryEntryWrapper>
  );
};

export default UnconfirmedEntry;
