import { useNavigate } from "react-router-dom-v5-compat";

import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import { showDeleteConfirmation } from "../Policies/DeleteConfirmation";

type PolicyDetailsManageDropdownProps = {
  handlePolicyEditDetailsClick: () => void;
  id: string;
  name: string;
  size?: "small" | "medium";
  canManagePolicies: boolean;
  hasAttachedEntities: boolean;
};

const PolicyDetailsManageDropdown = ({
  handlePolicyEditDetailsClick,
  id,
  name,
  size = "small",
  canManagePolicies,
  hasAttachedEntities,
}: PolicyDetailsManageDropdownProps) => {
  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPagePolicy.PolicyDetails,
  });

  const copyFieldCallback = () => trackSegmentAnalyticsEvent("Copy ID");

  const handleDelete = () => {
    trackSegmentAnalyticsEvent("Start Delete");
    showDeleteConfirmation({
      id,
      hasAttachedEntities,
      name,
    }).then((finished) => {
      if (finished) {
        navigate(`/policies`);
        trackSegmentAnalyticsEvent("Confirm Delete");
      } else {
        trackSegmentAnalyticsEvent("Cancel Delete");
      }
    });
  };

  return (
    <DropdownMenuEllipsis tooltip="Policy actions" dotsSize={size}>
      {canManagePolicies && (
        <DropdownMenuItem onAction={handlePolicyEditDetailsClick}>Edit details</DropdownMenuItem>
      )}
      <CopyFieldDropdownMenuItem title="Copy ID" value={id} onCopy={copyFieldCallback} />
      {canManagePolicies && (
        <DropdownMenuItem onAction={handleDelete} danger>
          Delete
        </DropdownMenuItem>
      )}
    </DropdownMenuEllipsis>
  );
};

export default PolicyDetailsManageDropdown;
