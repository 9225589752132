import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";

import { StackSettingsGetTooltipHelperReturnType } from "../../types";

export const getPlaybookTooltip = (): StackSettingsGetTooltipHelperReturnType => ({
  tooltipInfo: (
    <>
      <TooltipModalTitle>Playbook</TooltipModalTitle>
      <TooltipModalBody align="start">Playbook file to run in the stack.</TooltipModalBody>
    </>
  ),
  tooltipInfoVariant: "modal",
});
