import Feedback from "ds/components/Feedback";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";

import JoinTexts from "./JoinText";

type ActionAvailabilityBannerProps = {
  itemActions?: string[];
  allActionsQty: number;
};

export const ActionAvailabilityBanner = ({
  itemActions,
  allActionsQty,
}: ActionAvailabilityBannerProps) => {
  const hasAtLeastOneAvailableAction = !!itemActions?.length;
  const applicableToAllActions = itemActions?.length === allActionsQty;
  return (
    <>
      {!hasAtLeastOneAvailableAction && (
        <Feedback type="banner" variant="warning" fullWidth>
          This item has no available actions.
        </Feedback>
      )}

      {!applicableToAllActions && hasAtLeastOneAvailableAction && (
        <Feedback type="banner" variant="info" fullWidth>
          <TextEllipsis
            tooltipWidthMode="maxWidthSm"
            tooltip={
              <>
                Available actions: <JoinTexts texts={itemActions} />.
              </>
            }
          >
            {(props) => (
              <Typography {...props} tag="p">
                Available actions: <JoinTexts texts={itemActions} />.
              </Typography>
            )}
          </TextEllipsis>
        </Feedback>
      )}
    </>
  );
};
