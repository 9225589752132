import BulkActionsConfirmActionStep from "components/BulkActions/ConfirmActionStep";
import { BulkActionsVariant } from "components/BulkActions/types";

import {
  StackBulkActions,
  StackBulkActionsAnalyticsPayload,
  StackBulkActionsSelectedAction,
} from "../types";
import StacksBulkActionsConfirmActionStepDefault from "./Default";
import StacksBulkActionsConfirmActionStepLock from "./Lock";
import StacksBulkActionsConfirmActionStepReview from "./Review";
import StacksBulkActionsConfirmActionStepRunTask from "./RunTask";
import { StacksBulkActionsConfirmActionStepProps } from "./types";
import { StackMutationVariables } from "../useStackBulkActions/types";

type StacksBulkActionsConfirmActionFormProps = {
  action: StackBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (
    mutationVariables: StackMutationVariables,
    analyticsPayload: StackBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const StacksBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
}: StacksBulkActionsConfirmActionFormProps) => {
  const props: StacksBulkActionsConfirmActionStepProps = {
    bulkActionsVariant: variant,
    onConfirm,
    onCancel,
    action,
  };

  let actionComponent = <StacksBulkActionsConfirmActionStepDefault {...props} />;

  if (action.key === StackBulkActions.Lock) {
    actionComponent = <StacksBulkActionsConfirmActionStepLock {...props} />;
  }

  if (action.key === StackBulkActions.RunTask) {
    actionComponent = <StacksBulkActionsConfirmActionStepRunTask {...props} />;
  }

  if (
    action.key === StackBulkActions.ReviewReject ||
    action.key === StackBulkActions.ReviewApprove
  ) {
    actionComponent = <StacksBulkActionsConfirmActionStepReview {...props} />;
  }

  return <BulkActionsConfirmActionStep>{actionComponent}</BulkActionsConfirmActionStep>;
};

export default StacksBulkActionsConfirmActionForm;
