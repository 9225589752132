import { type FetchResult } from "@apollo/client";

import {
  type BulkActionItemID,
  BulkActionsActionsPerItem,
  BulkActionsResult,
  type BulkEntityActionItem,
} from "./types";

type MakeSelectedDataProps<Action extends string, Entity, MutationVariables> = {
  actions: BulkEntityActionItem<Action, Entity, MutationVariables>[];
  selectedSet: Set<BulkActionItemID>;
  entityItemsMap: Map<BulkActionItemID, Entity>;
};

type MakeSelectedResult<Action extends string, Entity> = {
  selectedEntityItems: Entity[];
  itemsPerAction: Map<Action, BulkActionItemID[]>;
  actionsPerItem: BulkActionsActionsPerItem<Action>;
};

/**
 * Make selected data for bulk actions
 * * FYI the helper unions three traverses to the one,
 * * all of the made data could be needed (* is needed for almost all use cases) for bulk actions at the same time
 */
export const makeBulkActionsData = <Action extends string, Entity, MutationVariables>({
  actions,
  selectedSet,
  entityItemsMap,
}: MakeSelectedDataProps<Action, Entity, MutationVariables>): MakeSelectedResult<
  Action,
  Entity
> => {
  return Array.from(selectedSet).reduce<MakeSelectedResult<Action, Entity>>(
    (acc, id) => {
      const entityItem = entityItemsMap.get(id);

      if (entityItem) {
        actions.forEach((action) => {
          if (action.condition(entityItem)) {
            const items = acc.itemsPerAction.get(action.key) || [];
            items.push(id);
            acc.itemsPerAction.set(action.key, items);

            const actions = acc.actionsPerItem.get(id) || [];
            actions.push({ action: action.key, title: action.title });
            acc.actionsPerItem.set(id, actions);
          }
        });

        acc.selectedEntityItems.push(entityItem);
      }

      return acc;
    },
    {
      selectedEntityItems: [],
      itemsPerAction: new Map(),
      actionsPerItem: new Map(),
    }
  );
};

export type BulkActionItemTriggerResult =
  | FetchResult<unknown>
  | Exclude<BulkActionsResult, "pending">
  | void;
/**
 * Verify return type form bulk action item trigger result
 */
export const isBulkActionResult = (
  result: BulkActionItemTriggerResult
): result is Exclude<BulkActionsResult, "pending" | "queued"> =>
  typeof result === "string" ? Object.values(BulkActionsResult).includes(result) : false;

export type BulkActionResultsMetadata = {
  message: string;
};

export type BulkActionResultsMetadataMap = Map<BulkActionItemID, BulkActionResultsMetadata>;

export type BulkActionItemResults = {
  [key in BulkActionsResult]: Set<BulkActionItemID>;
};
export const bulkActionsResultsList: BulkActionsResult[] = [
  BulkActionsResult.Failed,
  BulkActionsResult.Completed,
  BulkActionsResult.Pending,
  BulkActionsResult.Queued,
  BulkActionsResult.Stopped,
  BulkActionsResult.Skipped,
];

export const emptyBulkActionResults: BulkActionItemResults = {
  [BulkActionsResult.Failed]: new Set<BulkActionItemID>(),
  [BulkActionsResult.Completed]: new Set<BulkActionItemID>(),
  [BulkActionsResult.Pending]: new Set<BulkActionItemID>(),
  [BulkActionsResult.Queued]: new Set<BulkActionItemID>(),
  [BulkActionsResult.Stopped]: new Set<BulkActionItemID>(),
  [BulkActionsResult.Skipped]: new Set<BulkActionItemID>(),
};

export const cloneBulkActionResults = (prev: BulkActionItemResults) =>
  bulkActionsResultsList.reduce(
    (acc, result) => {
      acc[result] = new Set(prev[result]);
      return acc;
    },
    { ...emptyBulkActionResults }
  );
