import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryPredicate } from "types/generated";

import { AnsibleHostData, SEARCH_ANSIBLE_HOSTS } from "./gql";
import { createAnsibleNodesByHost } from "./TreeGrid/utils";
import { LayoutMode } from "./TreeGrid/types";

const useSearchAnsibleHosts = (
  input: {
    first: number;
    after: null;
    requestedPage: null;
    fullTextSearch: string;
    predicates: SearchQueryPredicate[];
    orderBy: null;
  },
  layoutMode: LayoutMode,
  skip?: boolean
) => {
  const { onError } = useTypedContext(FlashContext);
  const cachedAnsibleHostsEdges = useRef<AnsibleHostData[]>([]);

  const {
    data: hostsData,
    loading: hostsLoading,
    networkStatus: hostsNetworkStatus,
    error: hostsError,
  } = useQuery(SEARCH_ANSIBLE_HOSTS, {
    variables: { input },
    onError,
    skip,
  });

  const ansibleHosts = useMemo(() => {
    const sourceEdges = hostsData?.searchAnsibleHosts?.edges.map((edge) => edge.node) || [];
    const edges =
      hostsLoading && !sourceEdges.length ? cachedAnsibleHostsEdges.current : sourceEdges;

    if (!hostsLoading) {
      cachedAnsibleHostsEdges.current = sourceEdges;
    }

    return edges;
  }, [hostsData?.searchAnsibleHosts?.edges, hostsLoading]);

  const location = useLocation();

  const nodes = useMemo(() => {
    const isChart = layoutMode === LayoutMode.Diagram;

    return createAnsibleNodesByHost(ansibleHosts, isChart, location.pathname);
  }, [ansibleHosts, layoutMode, location.pathname]);

  const isHostsPageLoading =
    hostsLoading && !ansibleHosts.length && hostsNetworkStatus === NetworkStatus.loading;

  const response = useMemo(() => {
    return {
      error: hostsError,
      loading: hostsLoading,
      isPageLoading: isHostsPageLoading,
      isPageEmpty: !!(
        hostsData &&
        !ansibleHosts.length &&
        !input.fullTextSearch &&
        input.predicates.length === 0
      ),
      hasNoFilteringResults:
        !!hostsData &&
        !ansibleHosts.length &&
        (!!input.fullTextSearch || input.predicates.length > 0),
    };
  }, [
    ansibleHosts.length,
    hostsData,
    hostsError,
    hostsLoading,
    input.fullTextSearch,
    input.predicates.length,
    isHostsPageLoading,
  ]);

  return {
    nodes,
    predicates: input.predicates,
    ...response,
  };
};

export default useSearchAnsibleHosts;
