import { UIEvent, useEffect, useMemo, useState } from "react";
import { type DebouncedFunc } from "lodash-es";
import debounce from "lodash-es/debounce";

type InfiniteListProps<T> = {
  items: T[];
  children: (
    items: T[],
    onScroll: DebouncedFunc<(e: UIEvent<HTMLDivElement>) => void>
  ) => React.ReactNode;
  screensGap?: number;
  itemsPerPage?: number;
};

const SCREENS_GAP_QUANTITY = 6;
const LIST_ITEMS_PER_PAGE = 100;

const InfiniteList = <T,>(props: InfiniteListProps<T>) => {
  const {
    items,
    children,
    screensGap = SCREENS_GAP_QUANTITY,
    itemsPerPage = LIST_ITEMS_PER_PAGE,
  } = props;

  const [count, setCount] = useState(1);
  const [hasMore, setHasMore] = useState(items.length > itemsPerPage * count);

  const handleLoadMore = debounce((e: UIEvent<HTMLDivElement>) => {
    if (hasMore) {
      const target = e.target as HTMLDivElement;
      const isBottom = target.scrollHeight - target.scrollTop <= target.clientHeight * screensGap;

      if (isBottom) {
        const newCount = count + 1;
        setHasMore(items.length > itemsPerPage * newCount);
        setCount(newCount);
      }
    }
  }, 20);

  const infiniteItems = useMemo(() => {
    return items.slice(0, itemsPerPage * count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, count]);

  useEffect(() => {
    setCount(1);
    setHasMore(items.length > itemsPerPage * 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  return <>{children(infiniteItems, handleLoadMore)}</>;
};

export default InfiniteList;
