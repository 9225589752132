import { type ReactNode } from "react";

import FormFieldSpaceTooltipInfo from "components/FormFields/Space/TooltipInfo";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { type TooltipInfoVariant } from "ds/components/TooltipInfo";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import { getDocsUrl } from "utils/getDocsUrl";

type GetTooltipReturnType = {
  tooltipInfoVariant: TooltipInfoVariant;
  tooltipInfo: ReactNode;
};

export const getStackDetailsNameTooltip = (): GetTooltipReturnType => ({
  tooltipInfoVariant: "modal",
  tooltipInfo: (
    <>
      <TooltipModalTitle>Stack name</TooltipModalTitle>
      <TooltipModalBody align="start">
        It should be unique within one account, and we recommend that it closely represents the body
        of resources managed by this stack.
        <ReadMoreDocsLink
          docsUrl={getDocsUrl("/concepts/stack/stack-settings#name-and-description")}
        />
      </TooltipModalBody>
    </>
  ),
});

export const getStackDetailsSpaceTooltip = (): GetTooltipReturnType => ({
  tooltipInfoVariant: "modal",
  tooltipInfo: <FormFieldSpaceTooltipInfo />,
});
