import { Pages } from "./types";

export const enum AnalyticsPageCloudIntegrations {
  AWSEdit = "AWSEdit",
  AWSList = "AWSList",
  AWSView = "AWSView",
  AzureEdit = "AzureEdit",
  AzureList = "AzureList",
  AzureView = "AzureView",
  GCPList = "GCPList",
}

const cloudIntegrations = "Cloud Integrations";

export const pagesCloudIntegration: Pages<AnalyticsPageCloudIntegrations> = {
  [AnalyticsPageCloudIntegrations.AWSEdit]: [cloudIntegrations, "AWS Edit"],
  [AnalyticsPageCloudIntegrations.AWSList]: [cloudIntegrations, "AWS List"],
  [AnalyticsPageCloudIntegrations.AWSView]: [cloudIntegrations, "AWS View"],
  [AnalyticsPageCloudIntegrations.AzureEdit]: [cloudIntegrations, "Azure Edit"],
  [AnalyticsPageCloudIntegrations.AzureList]: [cloudIntegrations, "Azure List"],
  [AnalyticsPageCloudIntegrations.AzureView]: [cloudIntegrations, "Azure View"],
  [AnalyticsPageCloudIntegrations.GCPList]: [cloudIntegrations, "GCP List"],
};
