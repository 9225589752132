import { gql } from "@apollo/client";

export const GET_POLICY_EVALUATION_SAMPLE = gql`
  query GetPolicyEvaluationSample($key: String!, $policyId: ID!) {
    policy(id: $policyId) {
      id
      evaluationSample(key: $key) {
        input
        body
      }
    }
  }
`;

export const GET_POLICY_EVALUATION_RECORDS = gql`
  query GetPolicyEvaluationRecords($policyId: ID!) {
    policy(id: $policyId) {
      id
      evaluationRecords {
        key
        outcome
        timestamp
      }
    }
  }
`;
