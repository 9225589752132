import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";

import WebhookListItem from ".";
import { WebhookIntegrationWithTimestamp } from "./types";

type WebhookListItemVirtualizedProps = {
  items: WebhookIntegrationWithTimestamp[];
  moduleId: string;
  onEditClick: (item: WebhookIntegrationWithTimestamp) => void;
  onDetailsClick: (item: WebhookIntegrationWithTimestamp) => void;
  canManageModule: boolean;
};

const WebhookListItemVirtualized = (
  props: ListChildComponentProps<WebhookListItemVirtualizedProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.items[index];
  const key = item.id;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <WebhookListItem
        key={key}
        item={item}
        setRowHeight={handleSetRowHeight}
        moduleId={data.moduleId}
        onEditClick={data.onEditClick}
        onDetailsClick={data.onDetailsClick}
        canManageModule={data.canManageModule}
      />
    </div>
  );
};

export default memo(WebhookListItemVirtualized, areEqual);
