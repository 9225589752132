import useTypedContext from "hooks/useTypedContext";
import SharedModulesTierInfo from "components/TierInfo/SharedModulesTierInfo";

import ModuleSettingsPageLayout from "../components/PageLayout";
import { ModuleSettingsContext } from "../Context";
import ModuleSettingsSharingEditForm from "./EditForm";
import ModuleSettingsSharingViewForm from "./ViewForm";
import ModuleSettingsFormWrapper from "../components/FormWrapper";

const ModuleSettingsSharing = () => {
  const { canManageModule } = useTypedContext(ModuleSettingsContext);

  return (
    <ModuleSettingsPageLayout
      title="Sharing"
      description="Share the module with other Spacelift accounts. Module can only be managed from the main account."
    >
      <SharedModulesTierInfo type="callout" />

      <ModuleSettingsFormWrapper>
        {canManageModule ? <ModuleSettingsSharingEditForm /> : <ModuleSettingsSharingViewForm />}
      </ModuleSettingsFormWrapper>
    </ModuleSettingsPageLayout>
  );
};

export default ModuleSettingsSharing;
