import { createContext } from "react";

import { VcsProvider, Version } from "types/generated";

export type VersionContextType = {
  version: Version;
  module: {
    id: string;
    name: string;
    provider: VcsProvider;
    current: {
      number: string;
    } | null;
  };
  canManageModule: boolean;
};

export const VersionContext = createContext<VersionContextType | undefined>(undefined);
VersionContext.displayName = "VersionContext";
