import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { Blueprint, BlueprintState } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import FormFieldSpace from "components/FormFields/Space";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { CLONE_BLUEPRINT, CREATE_BLUEPRINT, UPDATE_BLUEPRINT } from "../gql";
import { REFETCH_QUERIES_LIST } from "../constants";

type CreateBlueprintFormFields = {
  name: string;
  description?: string;
  space: string;
  labels: Record<"value", string>[];
};

type CreateBlueprintDrawerProps = {
  handleCloseDrawer: () => void;
  blueprint?: Blueprint;
  isCloneMode?: boolean;
};

const getPrimaryButtonText = (isEditMode: boolean, isCloneMode?: boolean): string => {
  if (isEditMode) {
    return "Save";
  }
  if (isCloneMode) {
    return "Clone";
  }

  return "Create blueprint draft";
};

const CreateBlueprintDrawer = ({
  handleCloseDrawer,
  blueprint,
  isCloneMode,
}: CreateBlueprintDrawerProps) => {
  const isEditMode = !!blueprint && !isCloneMode;

  const navigate = useNavigate();

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const blueprintName = isCloneMode ? `${blueprint?.name} (clone)` : blueprint?.name;

  const builderForm = useForm<CreateBlueprintFormFields>({
    defaultValues: {
      name: blueprint ? blueprintName : "",
      description: blueprint?.description || "",
      space: blueprint?.space.id || "",
      labels: blueprint?.labels.map((value) => ({ value })) || [],
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [createBlueprint] = useMutation<{ blueprintCreate: Blueprint }>(CREATE_BLUEPRINT, {
    refetchQueries: REFETCH_QUERIES_LIST,
  });

  const [updateBlueprint] = useMutation<{ blueprintUpdate: Blueprint }>(UPDATE_BLUEPRINT, {
    onError,
    refetchQueries: REFETCH_QUERIES_LIST,
  });

  const [cloneBlueprint] = useMutation<{ blueprintClone: Blueprint }>(CLONE_BLUEPRINT, {
    onError,
  });

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  const onCreateSubmit = (formData: CreateBlueprintFormFields) => {
    createBlueprint({
      variables: {
        input: {
          name: formData.name,
          description: formData.description,
          space: formData.space,
          labels: formData.labels.map((item) => item.value),
          state: BlueprintState.Draft,
          template: "",
        },
      },
    })
      .then(({ data }) => {
        if (data?.blueprintCreate) {
          reportSuccess({
            message: `Blueprint "${data.blueprintCreate.name}" is successfully created`,
          });

          reset();
          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  const onCloneSubmit = (formData: CreateBlueprintFormFields, blueprintItem: Blueprint) => {
    cloneBlueprint({
      variables: {
        id: blueprintItem.id,
        input: {
          name: formData.name,
          description: formData.description,
          space: formData.space,
          labels: formData.labels.map((item) => item.value),
        },
      },
    })
      .then(({ data }) => {
        if (data?.blueprintClone) {
          reportSuccess({
            message: `Blueprint "${data.blueprintClone.name}" is successfully created`,
          });

          reset();
          handleCloseDrawer();

          navigate(`/blueprint/${data.blueprintClone.id}`);
        }
      })
      .catch(onError);
  };

  const onEditSubmit = (formData: CreateBlueprintFormFields, blueprintItem: Blueprint) => {
    updateBlueprint({
      variables: {
        id: blueprintItem.id,
        input: {
          name: formData.name,
          description: formData.description,
          space: formData.space,
          labels: formData.labels.map((item) => item.value),
          state: blueprintItem.state,
          template: blueprintItem.rawTemplate,
        },
      },
    })
      .then(({ data }) => {
        if (data?.blueprintUpdate) {
          reportSuccess({
            message: `Blueprint "${data.blueprintUpdate.name}" is successfully saved`,
          });

          reset();
          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  const onSubmit: SubmitHandler<CreateBlueprintFormFields> = (formData) => {
    if (isEditMode && blueprint?.id) {
      onEditSubmit(formData, blueprint);
    } else if (isCloneMode && blueprint?.id) {
      onCloneSubmit(formData, blueprint);
    } else {
      onCreateSubmit(formData);
    }
  };

  useEffect(() => {
    // sync blueprint data with form after blueprint is loaded from the server (edit mode)
    if (blueprint) {
      reset({
        labels: blueprint.labels.map((value) => ({ value })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blueprint]);

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader justify="between">
        {!isEditMode && !isCloneMode && <DrawerHeaderTitle title="Create new blueprint" />}
        {isEditMode && <DrawerHeaderTitle title="Edit blueprint" />}
        {isCloneMode && <DrawerHeaderTitle title="Clone blueprint" />}
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <FormField label="Name" error={errors?.name?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter blueprint name here..."
              error={!!errors?.name}
              {...register("name", { required: "Name field is required." })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormFieldSpace />

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter blueprint description here..."
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormFieldTags label="Labels" tagName="label" name="labels" />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || (!isCloneMode && !isDirty)}
            >
              {getPrimaryButtonText(isEditMode, isCloneMode)}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default CreateBlueprintDrawer;
