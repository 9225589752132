export const createUrl = (blob: Blob) => window.URL.createObjectURL(blob);

export const toBlob = (byteString: string) => {
  const buffer = new ArrayBuffer(byteString.length);
  const array = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    array[i] = byteString.charCodeAt(i);
  }

  return new Blob([array], { type: "application/octet-stream" });
};
