import { gql } from "@apollo/client";

export const GET_AUTHORIZATION_SCHEME = gql`
  query GetAuthorizationScheme {
    authorizationScheme
  }
`;

export const UPDATE_ACCOUNT_AUTH_SCHEME = gql`
  mutation updateAccountAuthScheme($schema: AuthorizationScheme!) {
    accountUpdateAuthorizationScheme(schema: $schema)
  }
`;
