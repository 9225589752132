import { Fragment } from "react";

type DurationProps = {
  seconds: number;
};

const Duration = ({ seconds }: DurationProps) => {
  // Hours, only show when necessary.
  const hours = Math.floor(seconds / 3600);

  const segments = [
    Math.floor((seconds % 3600) / 60), // Minutes, always show
    seconds % 60, // Seconds, always show
  ];

  if (hours > 0) {
    segments.unshift(hours);
  }

  return (
    <Fragment>{segments.map((segment) => segment.toString().padStart(2, "0")).join(":")}</Fragment>
  );
};

export default Duration;
