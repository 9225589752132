import { NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { GET_ATTACHED_MODULE_INTEGRATIONS } from "views/Account/NewModule/AttachCloud/gql";

import { CloudIntegrationAttachment, GetCloudIntegrationAttachmentsGql } from "../types";

const useAttachedCloudIntegrations = (moduleId: string | undefined) => {
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, networkStatus, refetch } = useQuery<GetCloudIntegrationAttachmentsGql>(
    GET_ATTACHED_MODULE_INTEGRATIONS,
    {
      onError,
      variables: {
        moduleId,
      },
      skip: !moduleId,
      fetchPolicy: "no-cache",
    }
  );

  const integrations = useMemo(() => {
    const awsIntegrations = data?.module?.integrations?.awsV2 || [];
    const azureIntegrations = data?.module?.integrations?.azure || [];

    return ([] as Array<CloudIntegrationAttachment>)
      .concat(awsIntegrations)
      .concat(azureIntegrations);
  }, [data]);

  return {
    integrations,
    loading: loading && networkStatus !== NetworkStatus.refetch,
    refetching: loading && networkStatus === NetworkStatus.refetch,
    refetch,
    hasData: !loading && !!data?.module,
  };
};

export default useAttachedCloudIntegrations;
