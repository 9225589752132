import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { ChartColored } from "components/icons";

const DashboardWidgetsNumberOfDeploymentsEmpty = () => {
  return (
    <TileWrapper>
      <Box padding="small" fullWidth justify="center">
        <EmptyState title="There's no data to show yet" icon={ChartColored} />
      </Box>
    </TileWrapper>
  );
};

export default DashboardWidgetsNumberOfDeploymentsEmpty;
