import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

type PublishConfirmationModalProps = {
  onPublish: () => void;
};

const PublishConfirmationModal = create(function PublishConfirmationModal({
  onPublish,
}: PublishConfirmationModalProps) {
  const modal = useModal();

  const handleConfirmAction = () => {
    onPublish();
    modal.hide();
  };

  return (
    <ConfirmationModal
      onConfirm={handleConfirmAction}
      confirmationButtonVariant="primary"
      confirmationButtonLabel="Publish blueprint"
    >
      <ConfirmationModalMessage>
        Are you sure you want to publish your blueprint?{" "}
        <strong>This action cannot be undone.</strong>
      </ConfirmationModalMessage>
    </ConfirmationModal>
  );
});

export const showPublishConfirmationModal = (props: PublishConfirmationModalProps) =>
  show(PublishConfirmationModal, props);
