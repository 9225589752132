import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { ApiKey } from "types/generated";

import ApiKeysListItem from "./Item";

type ApiKeysListVirtualizedItemProps = {
  apiKeys: ApiKey[];
  handleApiKeyOpenDetails: (details: ApiKey) => unknown;
  refetchQueriesOnDelete?: string[];
};

const ApiKeysListVirtualizedItem = (
  props: ListChildComponentProps<ApiKeysListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.apiKeys[index];

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <ApiKeysListItem
        key={item.id}
        item={item}
        handleApiKeyOpenDetails={data.handleApiKeyOpenDetails}
        setRowHeight={handleSetRowHeight}
        refetchQueriesOnDelete={data.refetchQueriesOnDelete}
      />
    </div>
  );
};

export default memo(ApiKeysListVirtualizedItem, areEqual);
