import BulkActionsDrawerItem from "components/BulkActions/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActions/Drawer/Item/Dismiss";
import {
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActions/types";
import Box from "ds/components/Box";
import useAnalytics from "hooks/useAnalytics";
import { Worker } from "types/generated";
import { ActionAvailabilityBanner } from "components/BulkActions/Drawer/Item/ActionAvailabiltyBanner";
import State from "components/state/State";
import { AnalyticsPageWorkerPool } from "hooks/useAnalytics/pages/workerPool";
import BulkActionsDrawerItemTitle from "components/BulkActions/Drawer/Item/Title";

import { BulkPrivateWorkerPoolWorkersActions } from "./types";

type PrivateWorkerPoolWorkersBulkActionsSelectedItemProps = {
  item: Worker;
  onDismiss: (id: BulkActionItemID) => void;
} & (
  | {
      step: BulkActionsStep.ActionConfirmation;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      step: BulkActionsStep.ChooseAction;
      itemActions: BulkActionsActionDescriptor<BulkPrivateWorkerPoolWorkersActions>[] | undefined;
      allActionsQty: number;
    }
);

const PrivateWorkerPoolWorkersBulkActionsSelectedItem = ({
  item,
  itemActions,
  onDismiss,
  allActionsQty,
  step,
}: PrivateWorkerPoolWorkersBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageWorkerPool.WorkerPoolWorkers,
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;

  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <State type={item.status} compact />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Box fullWidth zeroMinWidth>
        <BulkActionsDrawerItemTitle text={item.id} />
      </Box>

      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default PrivateWorkerPoolWorkersBulkActionsSelectedItem;
