import { useBulkConfirmRun } from "shared/Run/useConfirmRun";
import { canConfirmStackRun } from "shared/Run/useConfirmRun/accessValidation";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { StackActionItemForBulkActions } from "./types";

const useConfirmStackRunBulkAction = (): StackActionItemForBulkActions => {
  const [runConfirm] = useBulkConfirmRun();

  return {
    key: StackBulkActions.Confirm,
    title: BULK_ACTION_NAMES[StackBulkActions.Confirm],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Confirm],
    condition: canConfirmStackRun,
    mutation: (stack) => runConfirm({ stack: stack.id, note: null, run: null }),
  };
};

export default useConfirmStackRunBulkAction;
