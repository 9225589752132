import { SpaceAccessField } from "../InviteDrawer/types";

export type UpdateUserFields = {
  id: string;
  spaces: SpaceAccessField[];
  slackMemberID: string;
};

export enum UsersDrawerTabs {
  Spaces = "spaces",
  Groups = "groups",
  Integrations = "integrations",
}
