import { useCallback, useEffect, useRef } from "react";
import cx from "classnames";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import Tab from "ds/components/Tab";
import Box from "ds/components/Box";
import TabPanel from "ds/components/TabPanel";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import Icon from "ds/components/Icon";
import { CrossNew, Plus } from "components/icons";
import Tooltip from "ds/components/Tooltip";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import DashboardManageDrawerTab from "./Tab";
import { DashboardContext } from "../context";
import { DEFAULT_TAB_IDS, NEW_TAB_IN_DRAWER_ID } from "../constants";
import styles from "./styles.module.css";

const DashboardManageDrawer = () => {
  const {
    saveManagementDrawerChangesToStorage,
    isManagementDrawerConfigModified,
    isManageDrawerVisible,
    closeManageDrawer,
    managementDrawerTabs,
    addNewTabInDrawer,
    removeTabInDrawer,
    setCurrentManageDrawerTab,
    currentManageDrawerTab,
    canAddMoreManagementDrawerViews,
    shouldFocusOnInput,
  } = useTypedContext(DashboardContext);

  const inputRef = useRef<HTMLInputElement>(null);
  const tabToScrollIntoRef = useRef<HTMLDivElement>(null);

  const handleSave = () => {
    saveManagementDrawerChangesToStorage();
    closeManageDrawer(false);
  };

  const handleTabDelete = (id: string) => () => {
    removeTabInDrawer(id);
  };

  useEffect(() => {
    if (isManageDrawerVisible && tabToScrollIntoRef.current) {
      tabToScrollIntoRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  }, [isManageDrawerVisible, currentManageDrawerTab]);

  useEffect(() => {
    if (isManageDrawerVisible && inputRef.current && shouldFocusOnInput) {
      inputRef.current.focus();
    }
  }, [isManageDrawerVisible, currentManageDrawerTab, shouldFocusOnInput]);

  const drawerCloseHandler = useCallback(async () => {
    if (isManagementDrawerConfigModified) {
      await showSimpleLeaveConfirmation({
        title: "Do you want to leave dashboard view edition process?",
        message: "Your changes will not be saved.",
      });
    }

    closeManageDrawer();
  }, [closeManageDrawer, isManagementDrawerConfigModified]);

  const hasTitleError = managementDrawerTabs.some(
    (tab) => !DEFAULT_TAB_IDS.includes(tab.id) && !tab.title
  );

  return (
    <Drawer
      onEnteredCallback={() => shouldFocusOnInput && inputRef.current?.focus()}
      visible={isManageDrawerVisible}
      onOutsideClick={drawerCloseHandler}
    >
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Manage view" />
        <DrawerCloseIcon handleCloseDrawer={drawerCloseHandler} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Box padding="0 0 x-large 0">
          <Box className={styles.tabsContainerScollable}>
            <Box direction="row" gap="medium" align="center" className={styles.tabsContainer}>
              {managementDrawerTabs.map(({ id, title }) => (
                <div
                  key={id}
                  className={cx(!DEFAULT_TAB_IDS.includes(id) && styles.tabLabelWrapper)}
                >
                  <Tab
                    innerRef={currentManageDrawerTab === id ? tabToScrollIntoRef : undefined}
                    onClick={setCurrentManageDrawerTab}
                    isActive={currentManageDrawerTab === id}
                    id={id}
                    className={cx(!DEFAULT_TAB_IDS.includes(id) && styles.tabLabel)}
                    aria-label={title}
                    label={
                      DEFAULT_TAB_IDS.includes(id) ? (
                        title
                      ) : (
                        <TextEllipsis tooltip={title} tooltipWidthMode="maxWidthSm">
                          {(props) => (
                            <Typography {...props} tag="span" variant="p-t7">
                              {title}
                            </Typography>
                          )}
                        </TextEllipsis>
                      )
                    }
                  />
                  {!DEFAULT_TAB_IDS.includes(id) && (
                    <ButtonIconNew
                      className={styles.tabLabelAction}
                      icon={CrossNew}
                      onPress={handleTabDelete(id)}
                      variant="ghost"
                    >
                      Delete tab
                    </ButtonIconNew>
                  )}
                </div>
              ))}
              <Tab
                id={NEW_TAB_IN_DRAWER_ID}
                aria-label={
                  canAddMoreManagementDrawerViews ? "Add new tab" : "Views limit exceeded"
                }
                onClick={canAddMoreManagementDrawerViews ? addNewTabInDrawer : undefined}
                isActive={false}
                label={
                  <Tooltip on={(props) => <Icon {...props} src={Plus} />}>
                    {canAddMoreManagementDrawerViews ? "Add new tab" : "Views limit exceeded"}
                  </Tooltip>
                }
                disabled={!canAddMoreManagementDrawerViews}
              />
            </Box>
          </Box>
        </Box>

        {managementDrawerTabs.map(({ id }) => (
          <TabPanel key={id} isActive={currentManageDrawerTab === id} id={id}>
            <DashboardManageDrawerTab inputRef={inputRef} tab={id} />
          </TabPanel>
        ))}
      </DrawerBody>
      <DrawerFooter sticky gap="medium">
        <Button variant="secondary" onClick={drawerCloseHandler}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!isManagementDrawerConfigModified || hasTitleError}
          onClick={handleSave}
        >
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default DashboardManageDrawer;
