import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import useSetPrioritizeRun from "shared/Run/useSetPrioritizeRun";

import { useRunShortcut } from "../../hooks/useRunShortcut";

type PrioritizeRunButtonProps = {
  runId: string;
  stackId: string;
  isRunPrioritized: boolean;
  runQueryToRefetch: string;
};

const PrioritizeRunButton = ({
  runId,
  stackId,
  isRunPrioritized,
  runQueryToRefetch,
}: PrioritizeRunButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [setRunPriority, { loading }] = useSetPrioritizeRun({
    refetchQueries: [runQueryToRefetch],
  });

  const onClick = useCallback(
    () =>
      setRunPriority({ stack: stackId, run: runId, prioritize: !isRunPrioritized }, (data) => {
        if (data?.runPrioritizeSet.id) {
          reportSuccess({
            message: `Run ${data.runPrioritizeSet.id} priority successfully changed`,
          });
        }
      }),
    [setRunPriority, stackId, runId, isRunPrioritized, reportSuccess]
  );

  const label = isRunPrioritized ? "De-prioritize" : "Prioritize";

  useRunShortcut({
    label,
    code: "mod+alt+p",
    callback: onClick,
    loading,
  });

  return (
    <Button variant="secondary" size="small" disabled={loading} loading={loading} onClick={onClick}>
      {label}
    </Button>
  );
};

export default PrioritizeRunButton;
