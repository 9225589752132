import { Navigate, Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useQuery } from "@apollo/client";

import ViewHeader from "components/ViewHeader";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import { FILTERS_ORDER_SETTINGS_KEY } from "views/Account/Modules/constants";
import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import Tab from "ds/components/Tab";
import WebhookDetailsResponse from "components/WebhookDeliveries/Details/Response";
import Box from "ds/components/Box";
import { useHideOnScrollContext } from "components/HideOnScroll/HideOnScrollContext";

import { ModuleSettingsContext } from "../Context";
import { GET_MODULE_WEBHOOK_DELIVERY_DETAILS } from "./gql";

const ModuleSettingsIntegrationsWebhookDelivery = () => {
  const { onError } = useTypedContext(FlashContext);
  const { module } = useTypedContext(ModuleSettingsContext);
  const { setScrollableElRef } = useHideOnScrollContext();

  const { webhookId, deliveryId } = useParams<{
    webhookId: string;
    deliveryId: string;
  }>();

  const { loading, data, error } = useQuery(GET_MODULE_WEBHOOK_DELIVERY_DETAILS, {
    onError,
    variables: {
      webhookId,
      deliveryId,
      moduleId: module.id,
    },
    fetchPolicy: "no-cache",
  });

  useTitle(data?.module ? `Delivery · ${deliveryId}` : "Delivery");

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: getBreadcrumbsBackUrl("/modules", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: module?.name || "",
        link: `/module/${module?.id}`,
      },
      {
        title: "Module settings",
        link: `/module/${module?.id}/settings/integrations/webhooks`,
      },
      {
        title: "Recent deliveries",
        link: `/module/${module?.id}/settings/integrations/webhook/${webhookId}`,
      },
      {
        title: `Delivery ${deliveryId}`,
      },
    ],
    [module?.name, module?.id, webhookId]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module) {
    return <NotFoundPage />;
  }

  const item = data.module.integrations.webhook.deliveryDetails;

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper>
          <ViewHeaderTitle titleSize="p-t3">Delivery {deliveryId}</ViewHeaderTitle>
        </ViewHeaderWrapper>
        <ViewHeaderNavigation>
          <Tab to="request" label="Request" v5Compat />
          <Tab to="response" label="Response" v5Compat />
        </ViewHeaderNavigation>
      </ViewHeader>
      <Box ref={setScrollableElRef} padding="0 x-large" fullWidth direction="column">
        <Routes>
          <Route index element={<Navigate to="request" replace />} />
          <Route
            path="request"
            element={
              <WebhookDetailsResponse headers={item?.requestHeaders} body={item?.requestBody} />
            }
          />
          <Route
            path="response"
            element={
              <WebhookDetailsResponse
                headers={item?.responseHeaders}
                body={item?.responseBody}
                error={item?.responseError}
              />
            }
          />
        </Routes>
      </Box>
    </>
  );
};

export default ModuleSettingsIntegrationsWebhookDelivery;
