import { Fragment, memo, useCallback, useMemo } from "react";
import { Line, LinePath } from "@visx/shape";
import { GlyphCircle } from "@visx/glyph";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { NumberLike } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { ScaleOrdinal } from "d3";
import { Point } from "d3-dag/dist/dag";

import Box from "ds/components/Box";
import useContainerSize from "hooks/useContainerSize";

import styles from "./styles.module.css";
import ChartTooltip from "../components/Tooltip";
import { tooltipContainerStyles } from "../components/Tooltip/helpers";
import AxisLabel from "../components/AxisLabel";
import { LINE_CHART_MARGIN, TOOLTIP_OFFSET, Y_AXIS_LABEL_OFFSET } from "./constants";
import LineChartOverlay from "./Overlay";
import useConfig from "./useConfig";
import { Datum, LineChartBaseProps } from "./types";

type LineChartProps = LineChartBaseProps & {
  colorScale: ScaleOrdinal<string, string>;
};

type TooltipData = {
  data: Datum;
  hoveredIndex: number;
  hoveredValue: number;
};

const tooltipStyles = { ...defaultStyles, ...tooltipContainerStyles };

const formatYAxisLabel = (value: NumberLike) => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(value.valueOf());
};

const LineChart = ({
  data,
  xKey,
  items,
  formatXAxisLabel,
  aspectRatio,
  leftNumTicks = 6,
  bottomNumTicks = 5,
  colorScale,
  leftAxisLabel,
  renderTooltip,
  tooltipReactToScroll,
}: LineChartProps) => {
  const { tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: tooltipReactToScroll,
  });

  const {
    containerRef: svgContainerRef,
    width: parentWidth,
    height: parentHeight,
  } = useContainerSize();

  const { yScale, xScale, xMax, yMax, height } = useConfig({
    parentWidth,
    parentHeight,
    aspectRatio,
    data,
    items,
    xKey,
  });

  const handleMouseEnter = useCallback(
    (coordinates: Point | null) => {
      showTooltip({
        tooltipTop: coordinates?.y,
        tooltipLeft: coordinates?.x,
      });
    },
    [showTooltip]
  );

  const shouldRender = xMax >= 0 && yMax >= 0;
  const tooltipLeftSafe = (tooltipLeft || 0) - LINE_CHART_MARGIN.left;

  const closestTooltipPoint = useMemo(
    () =>
      tooltipOpen &&
      data.reduce((acc, next) => {
        const newX = xScale(next[xKey]) || 0;
        const oldX = xScale(acc[xKey]) || 0;
        if (Math.abs(newX - tooltipLeftSafe) < Math.abs(oldX - tooltipLeftSafe)) {
          return next;
        }

        return acc;
      }, data[0]),
    [data, tooltipLeftSafe, tooltipOpen, xKey, xScale]
  );

  return (
    <Box ref={svgContainerRef} fullWidth grow="1">
      <Box ref={containerRef} fullWidth>
        {shouldRender && (
          <svg width="100%" height={height} className={styles.lineChart}>
            <Group top={LINE_CHART_MARGIN.top} left={LINE_CHART_MARGIN.left}>
              <GridRows
                scale={yScale}
                width={xMax}
                strokeDasharray="1,0"
                stroke="var(--line-chart-grid-color)"
              />
              <AxisLeft
                numTicks={leftNumTicks}
                scale={yScale}
                hideTicks
                stroke="var(--line-chart-axis-y-color)"
                tickComponent={(tickProps) => <AxisLabel {...tickProps} x={tickProps.x - 5} />}
                label={leftAxisLabel}
                labelOffset={Y_AXIS_LABEL_OFFSET}
                labelClassName={styles.axisLabel}
                tickFormat={formatYAxisLabel}
              />
              <AxisBottom
                scale={xScale}
                top={yMax}
                numTicks={bottomNumTicks}
                hideTicks
                tickFormat={formatXAxisLabel}
                tickComponent={AxisLabel}
                stroke="var(--line-chart-axis-x-color)"
                tickValues={closestTooltipPoint ? [closestTooltipPoint?.[xKey]] : undefined}
              />

              {items.map((item) => (
                <Fragment key={item}>
                  <LinePath
                    data={data}
                    stroke={colorScale(item)}
                    strokeWidth="!"
                    x={(d) => xScale(d[xKey]) || 0}
                    y={(d) => yScale(d[item]) || 0}
                  />
                </Fragment>
              ))}

              <LineChartOverlay
                xMax={xMax}
                yMax={yMax}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={hideTooltip}
              />

              {tooltipOpen && closestTooltipPoint && (
                <Line
                  from={{ x: xScale(closestTooltipPoint[xKey]), y: 0 }}
                  to={{
                    x: xScale(closestTooltipPoint[xKey]),
                    y: yMax,
                  }}
                  stroke="var(--line-chart-y-mark-line-color)"
                  strokeWidth="1"
                  pointerEvents="none"
                />
              )}

              {closestTooltipPoint &&
                items.map((item) => (
                  <Fragment key={item}>
                    <GlyphCircle
                      left={xScale(closestTooltipPoint[xKey])}
                      top={yScale(closestTooltipPoint[item])}
                      size={240}
                      fill={colorScale(item)}
                      opacity={0.3}
                      pointerEvents="none"
                    />
                    <GlyphCircle
                      left={xScale(closestTooltipPoint[xKey])}
                      top={yScale(closestTooltipPoint[item])}
                      size={80}
                      fill={colorScale(item)}
                      stroke="var(--line-chart-bg-color)"
                      strokeWidth="1"
                      pointerEvents="none"
                    />
                  </Fragment>
                ))}
            </Group>
          </svg>
        )}

        {closestTooltipPoint && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeftSafe + LINE_CHART_MARGIN.left}
            offsetLeft={TOOLTIP_OFFSET.left}
            offsetTop={TOOLTIP_OFFSET.top}
            style={tooltipStyles}
          >
            <ChartTooltip>{renderTooltip?.(closestTooltipPoint)}</ChartTooltip>
          </TooltipInPortal>
        )}
      </Box>
    </Box>
  );
};

export default memo(LineChart);
