import { create, useModal, show } from "@ebay/nice-modal-react";

import Button from "ds/components/Button";
import { TadaColored } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Heading from "ds/components/Typography/Heading";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";

import styles from "./styles.module.css";

const InviteSuccessModal = create(function InviteSuccessModal() {
  const modal = useModal();

  const handleConfirm = () => {
    modal.resolve();
    modal.hide();
  };

  return (
    <Modal isOpen={modal.visible} size="large" onExit={modal.remove}>
      <ModalContent>
        <Box direction="column" align="center" justify="center" padding="large" fullWidth>
          <TadaColored className={styles.icon} />
          <Heading variant="p-t3" className={styles.title} slot="title">
            Request sent
          </Heading>
          <Typography variant="p-body2" tag="p" className={styles.info}>
            We have let your admin know about your request.
          </Typography>

          <Button variant="primary" onClick={handleConfirm}>
            Okay
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
});

export const showInviteSuccessModal = () => show(InviteSuccessModal);
