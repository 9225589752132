import { useCallback, useState } from "react";
import { create, show, useModal } from "@ebay/nice-modal-react";

import Button from "ds/components/Button";
import InputTextarea from "components/input/InputTextarea";
import ModalSimple from "ds/components/ModalSimple";
import ModalHeader from "ds/components/Modal/Header";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import ModalCancelButton from "ds/components/Modal/CancelButton";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useLockStack from "shared/Stack/useLockStack";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import styles from "./styles.module.css";

type StackLockNoteModalProps = {
  stackId: string;
  analyticsPage?: AnalyticsPage;
};

const StackLockNoteModal = create(function StackLockNoteModal({
  stackId,
  analyticsPage,
}: StackLockNoteModalProps) {
  const modal = useModal();

  const [notes, setNotes] = useState("");

  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [stackLock, { loading }] = useLockStack({
    refetchQueries: ["GetStack"],
  });

  const handleNoteAdd = useCallback(
    (note: string | null) => {
      stackLock({ id: stackId, note: note }, (data) => {
        if (data?.stackLock) {
          reportSuccess({ message: "Stack successfully locked" });
          trackSegmentAnalyticsEvent?.("Stack locked");
          modal.hide();
        }
      });
    },
    [modal, reportSuccess, stackId, stackLock, trackSegmentAnalyticsEvent]
  );

  return (
    <ModalSimple size="large">
      <ModalHeader title="Add a note" />

      <ModalContent>
        <InputTextarea
          className={styles.textArea}
          id="lockNote"
          name="lockNote"
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter the note here..."
          value={notes}
          minRows={3}
          maxRows={10}
          autoFocus
        />
      </ModalContent>
      <ModalFooter>
        <ModalCancelButton onClick={modal.hide} />
        <Button
          onClick={() => handleNoteAdd(notes)}
          variant="primary"
          loading={loading}
          disabled={loading}
        >
          Add note
        </Button>
      </ModalFooter>
    </ModalSimple>
  );
});

export const showStackLockNoteModal = (props: StackLockNoteModalProps) =>
  show(StackLockNoteModal, props);
