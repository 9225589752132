import { Tick, CrossNew } from "components/icons";
import Icon from "ds/components/Icon";

type SwitchIconProps = {
  checked: boolean;
};

const NewStackAttachCloudDetailsSwitchIcon = ({ checked }: SwitchIconProps) =>
  checked ? (
    <Icon src={Tick} size="large" color="success" />
  ) : (
    <Icon src={CrossNew} size="large" color="danger" />
  );

export default NewStackAttachCloudDetailsSwitchIcon;
