import { gql } from "@apollo/client";

const attachedPolicyFragment = gql`
  fragment attachedPolicy on StackPolicyAttachment {
    id
    policyId
    policyName
    policyType
    policyBody
    policyLabels
    isAutoattached
  }
`;

export const GET_STACK_ATTACHED_POLICIES = gql`
  query GetStackAttachedPolicies($entityId: ID!) {
    entity: stack(id: $entityId) {
      attachedPolicies {
        ...attachedPolicy
      }
    }
  }
  ${attachedPolicyFragment}
`;

export const GET_MODULE_ATTACHED_POLICIES = gql`
  query GetModuleAttachedPolicies($entityId: ID!) {
    entity: module(id: $entityId) {
      attachedPolicies {
        ...attachedPolicy
      }
    }
  }
  ${attachedPolicyFragment}
`;

export const GET_ATTACHABLE_POLICIES_IN_SPACE = gql`
  query GetAttachablePoliciesInSpace($spaceId: ID!) {
    attachablePoliciesInSpace(id: $spaceId) {
      id
      name
      type
    }
  }
`;

export const ATTACH_POLICY_TO_ENTITY = gql`
  mutation AttachPolicyToEntity($policyId: ID!, $entityId: ID!) {
    policyAttach(id: $policyId, stack: $entityId) {
      id
    }
  }
`;

export const DETACH_POLICY_FROM_ENTITY = gql`
  mutation DetachPolicyFromEntity($attachmentId: ID!) {
    policyDetach(id: $attachmentId) {
      id
    }
  }
`;

export const SIMULATE_POLICY = gql`
  mutation SimulatePolicy($body: String!, $input: String!, $type: PolicyType!) {
    policySimulate(body: $body, input: $input, type: $type)
  }
`;
