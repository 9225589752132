import { gql } from "@apollo/client";

export const GET_PERSONAL_SPACES = gql`
  query GetPersonalSpace {
    spaces {
      id
      name
      accessLevel
    }
  }
`;

export const REQUEST_MANAGED_USER_INVITE = gql`
  mutation RequestManagedUserInvite($email: String!, $userName: String!) {
    managedUserRequestInvite(email: $email, userName: $userName)
  }
`;

export const REQUEST_MANAGED_USER_ROLE_CHANGE = gql`
  mutation RequestManagedUserRoleChange($spaceId: ID!, $requestedAccessLevel: SpaceAccessLevel!) {
    managedUserRequestRoleChange(spaceId: $spaceId, requestedAccessLevel: $requestedAccessLevel)
  }
`;
