import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_STACK_DRIFT_DETECTION_INTEGRATION } from "./gql";

const useDeleteDriftDetection = (stackId: string) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deleteIntegration, { loading }] = useMutation(DELETE_STACK_DRIFT_DETECTION_INTEGRATION, {
    refetchQueries: ["GetStackScheduling"],
    awaitRefetchQueries: true,
    variables: { stackId },
  });

  const handleDelete = useCallback(
    (successCallback?: () => void) => {
      deleteIntegration()
        .then(({ data }) => {
          if (data?.stackIntegrationDriftDetectionDelete) {
            reportSuccess({ message: "Drift Detection schedule successfully removed" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [deleteIntegration, onError, reportSuccess]
  );

  return {
    deleteIntegration: handleDelete,
    loading,
  };
};

export default useDeleteDriftDetection;
