import { useState } from "react";

import DropdownNew from "ds/components/Dropdown/New";
import ButtonNew from "ds/components/Button/New";
import { ChevronNew } from "components/icons";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import { DropdownOptions } from "./types";

type ModuleVersionSecondaryLayoutDropdownProps = {
  label: string;
  options: DropdownOptions;
};

const ModuleVersionSecondaryLayoutDropdown = ({
  label: dropdownLabel,
  options: dropdownOptions,
}: ModuleVersionSecondaryLayoutDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownNew
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      triggerComponent={
        <ButtonNew variant="secondary" active={isOpen} endIcon={ChevronNew} endIconRotate="90">
          {dropdownLabel}
        </ButtonNew>
      }
    >
      <DropdownSection>
        {dropdownOptions.map((option) => (
          <DropdownSectionItem
            key={option.to}
            to={option.to}
            active={option.isActive}
            v5Compat
            preventDefaultOnClick={false}
            onClick={() => setIsOpen(false)}
          >
            {option.label}
          </DropdownSectionItem>
        ))}
      </DropdownSection>
    </DropdownNew>
  );
};

export default ModuleVersionSecondaryLayoutDropdown;
