import cx from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.css";

type FormViewerProps = {
  children: ReactNode;
  scrollable?: boolean;
};

const FormViewer = ({ children, scrollable }: FormViewerProps) => {
  const viewerClass = cx(styles.viewer, {
    [styles.scrollable]: scrollable,
  });

  return <div className={viewerClass}>{children}</div>;
};

export default FormViewer;
