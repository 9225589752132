import { VcsProvider } from "types/generated";

import {
  LogoAzureDevOps,
  LogoAzureDevOpsColored,
  LogoBitbucket,
  LogoBitbucketColored,
  LogoGit,
  LogoGitHub,
  LogoGitLab,
  LogoGitLabColored,
  LogoShowcase,
} from "./index";

export const VCS_PROVIDERS_ICONS = {
  [VcsProvider.AzureDevops as string]: LogoAzureDevOps,
  [VcsProvider.BitbucketCloud as string]: LogoBitbucket,
  [VcsProvider.BitbucketDatacenter as string]: LogoBitbucket,
  [VcsProvider.Github as string]: LogoGitHub,
  [VcsProvider.GithubEnterprise as string]: LogoGitHub,
  [VcsProvider.Gitlab as string]: LogoGitLab,
  [VcsProvider.Showcase as string]: LogoShowcase,
  [VcsProvider.Git as string]: LogoGit,
};

export const VCS_PROVIDERS_COLORED_ICONS = {
  [VcsProvider.AzureDevops as string]: LogoAzureDevOpsColored,
  [VcsProvider.BitbucketCloud as string]: LogoBitbucketColored,
  [VcsProvider.BitbucketDatacenter as string]: LogoBitbucketColored,
  [VcsProvider.Github as string]: LogoGitHub,
  [VcsProvider.GithubEnterprise as string]: LogoGitHub,
  [VcsProvider.Gitlab as string]: LogoGitLabColored,
  [VcsProvider.Showcase as string]: LogoShowcase,
};
