import { useCallback } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import { StackContextAttachment } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Tag from "ds/components/Tag";
import { getAttachedLabel } from "utils/labels";
import { SpacesContext } from "views/Account/SpacesProvider";

import PriorityTag from "./PriorityTag";
import styles from "./styles.module.css";

type ContextAttachment = Omit<StackContextAttachment, "isAutoattached" | "contextHooks">;

type ManualContextAttachmentProps = {
  context: ContextAttachment & {
    isAutoattached: false;
  };
  stackLabels?: undefined;
  onDetach?: (id: string) => void;
  onPriorityChange?: (id: string, value: number) => void;
};

type AutoContextAttachmentProps = {
  context: ContextAttachment & {
    isAutoattached: true;
  };
  stackLabels: string[];
  onDetach?: undefined;
  onPriorityChange?: undefined;
};

type AllContextAttachmentProps = {
  onDescriptionDrawerOpen: () => void;
  readonly?: boolean;
} & (ManualContextAttachmentProps | AutoContextAttachmentProps);

const ContextAttachment = ({
  context,
  stackLabels,
  onDescriptionDrawerOpen,
  onDetach,
  onPriorityChange,
  readonly,
}: AllContextAttachmentProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const handleDetach = useCallback(async () => onDetach?.(context.id), [context.id, onDetach]);

  const handlePriorityChange = useCallback(
    async (value: number) => onPriorityChange?.(context.contextId, value),
    [context.contextId, onPriorityChange]
  );

  return (
    <Box
      grid
      gridTemplate="minmax(0, 1fr) min-content"
      gridAreas={`"info priority"`}
      gap="large"
      align="center"
      fullWidth
      className={cx(styles.wrapper, {
        [styles.readonly]: readonly || context.isAutoattached,
      })}
    >
      <Box gridArea="info" align="center" gap="medium">
        <Box gap="small" direction="column" zeroMinWidth>
          <Typography tag="span" variant="p-t6">
            {context.contextName}
          </Typography>
          {context.contextDescription && (
            <ListEntitiesItemDescription
              description={context.contextDescription}
              onShowFullDescription={onDescriptionDrawerOpen}
            />
          )}
        </Box>
      </Box>
      <Box gridArea="priority">
        {context.isAutoattached && stackLabels && (
          <Tag tag={getAttachedLabel(stackLabels, context.contextLabels)} />
        )}
        {!context.isAutoattached &&
          (hasEntityCreateAccess && !readonly ? (
            <PriorityTag
              value={context.priority}
              onChange={handlePriorityChange}
              onDetach={handleDetach}
            />
          ) : (
            <Tag tag={`Priority: ${context.priority}`} />
          ))}
      </Box>
    </Box>
  );
};

export default ContextAttachment;
