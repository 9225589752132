import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";

import { COLUMN_GAP, COLUMN_ORDER, FilterItemKeys, FILTER_ITEMS_DICTIONARY } from "../constants";

const PrivateWorkerPoolUsedByFiltersLayoutHeader = () => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.TYPE, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />

      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.NAME)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.SPACE)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
};

export default PrivateWorkerPoolUsedByFiltersLayoutHeader;
