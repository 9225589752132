import { Client } from "@bugsnag/js";

export const addNonceToInlineScripts = (nonce: string | undefined) => {
  if (nonce) {
    document.querySelectorAll("script.inline-script_csp-nonce-hash").forEach((script) => {
      script.setAttribute("nonce", nonce);
    });
  }
};

const CSP_NOTIFIER_WHITELIST_URLS = [
  "spacelift-io.app.spacelift.dev",
  "spacelift-ci-gh.app.spacelift.dev",
  "spacelift-io.app.spacelift.io",
];
// TODO remove once we have CSP errors handle on preprod and prod
export const cspViolationListener = (bugsnagClient: Client) => {
  const { host } = window.location;

  const listener = (e: SecurityPolicyViolationEvent) => {
    const isWhiteListed = CSP_NOTIFIER_WHITELIST_URLS.some((url) => host.includes(url));

    if (isWhiteListed) {
      bugsnagClient.notify(new Error("CSP Violation"), (event) => {
        event.addMetadata("CSP", {
          blockedURI: e.blockedURI,
          violatedDirective: e.violatedDirective,
          originalPolicy: e.originalPolicy,
        });
      });
    }
  };

  document.addEventListener("securitypolicyviolation", listener);
};
