export const isElementOverflown = (element: HTMLElement) => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

export const scrollToElementById = (
  elementId: string,
  { behavior = "smooth", block = "start", inline }: ScrollIntoViewOptions = {}
) => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView({ behavior, block, inline });
  }
};
