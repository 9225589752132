import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { ManagedStateVersion } from "types/generated";

import StackStateHistoryListItem from ".";

type StackStateHistoryVirtualizedListItemProps = {
  items: ManagedStateVersion[];
};

const StackStateHistoryVirtualizedListItem = (
  props: ListChildComponentProps<
    StackStateHistoryVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <StackStateHistoryListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
        isCurrentState={index === 0}
      />
    </div>
  );
};

export default memo(StackStateHistoryVirtualizedListItem, areEqual);
