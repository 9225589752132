export const ITEM_COUNTER_SPACE = 8;
export const ITEM_COUNTER_HEIGHT = 20;
export const ITEM_COUNTER_WIDTH = 20;
export const ITEM_COUNTER_DIGIT_WIDTH = 8;
export const ICON_SIZE = 16;
export const ICON_SPACE = 4;
export const ITEMS_PER_ROW = 10;
export const ITEM_SIZE = 24;
export const ITEM_SPACE = 4;
export const ITEM_BORDER_RADIUS = 8;
export const MOUSE_LEAVE_TIMEOUT = 200;
export const MIDDLE_GROUP_BORDER_SPACE = 1;
export const MIDDLE_GROUP_BORDER_RADIUS = 9;
export const TEXT_HEIGHT = 20;
export const TEXT_GAP = 5;
export const CONNECTION_SPACE = 10;
export const CONNECTION_DY = 5;
