import { Group } from "@visx/group";
import {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import isEqual from "lodash-es/isEqual";
import cx from "classnames";

import Icon from "ds/components/Icon";

import styles from "./styles.module.css";
import { Position } from "../types";
import { createEndId, createStartId } from "../utils";
import {
  ITEM_COUNTER_SPACE,
  ITEM_COUNTER_WIDTH,
  ICON_SIZE,
  ICON_SPACE,
  ITEM_COUNTER_HEIGHT,
  ITEM_COUNTER_DIGIT_WIDTH,
  CONNECTION_SPACE,
  CONNECTION_DY,
} from "./contstants";
import { TreeChartGroupNodeProps } from "./types";
import TreeChartGroupNodeRects from "./Rects";

const TreeChartGroupNode = ({
  id,
  item,
  setConnectionPoints,
  connectionPoints,
  columnWidth,
  onMouseEnter,
  onMouseLeave,
  activeId,
}: TreeChartGroupNodeProps) => {
  const counterTextRef = useRef<SVGTextElement>(null);
  const textRef = useRef<SVGTextElement>(null);
  const [tooltipActive, setTooltipActive] = useState(false);
  const [counterX, setCounterX] = useState(0);

  const counterWidth = useMemo(() => {
    const value = item.group?.length || 0;
    const textLength = String(value).length;

    return (textLength - 1) * ITEM_COUNTER_DIGIT_WIDTH + ITEM_COUNTER_WIDTH;
  }, [item.group?.length]);

  const createMouseEnterHandler = useCallback(
    (tooltipValue: ReactNode) => (event: React.MouseEvent<SVGTextElement | SVGRectElement>) => {
      const position = (event.target as SVGTextElement | SVGRectElement).getBBox();
      const x = position.x + item.position.x + position.width / 2;
      const y = position.y + item.position.y;

      onMouseEnter(tooltipValue, { x, y });
    },
    [onMouseEnter, item.position.x, item.position.y]
  );

  const iconWidthAndSpace = item.icon ? ICON_SIZE + ICON_SPACE : 0;

  useLayoutEffect(() => {
    // Set X position for counter
    if (textRef.current) {
      const newPosition = textRef.current.getBBox().width + ITEM_COUNTER_SPACE + iconWidthAndSpace;

      if (newPosition !== counterX) {
        setCounterX(newPosition);
      }
    }

    // Text ellipsis
    const cunterElemSpace = item.group?.length ? counterWidth + ITEM_COUNTER_SPACE : 0;

    if (textRef.current) {
      let newText = item.name;
      let textLength = textRef.current.getComputedTextLength();

      while (textLength > 0 && textLength > columnWidth - cunterElemSpace) {
        if (!tooltipActive) {
          setTooltipActive(true);
        }

        newText = newText.slice(0, -1).trim();
        textRef.current.textContent = `${newText}...`;
        textLength = textRef.current.getComputedTextLength();
      }
    }

    // Set connection points
    const startPointID = createStartId(id);
    const startPointPosition = {
      x: item.position.x - CONNECTION_SPACE,
      y: item.position.y + CONNECTION_DY,
    };

    const endPointID = createEndId(id);
    const endPointPosition = {
      x:
        item.position.x +
        CONNECTION_SPACE +
        (textRef?.current?.getBBox().width || 0) +
        cunterElemSpace +
        iconWidthAndSpace,
      y: item.position.y + CONNECTION_DY,
    };

    if (
      !isEqual(startPointPosition, connectionPoints[startPointID]) ||
      !isEqual(endPointPosition, connectionPoints[endPointID])
    ) {
      setConnectionPoints(
        (connectionPoints) =>
          ({
            ...connectionPoints,
            [startPointID]: startPointPosition,
            [endPointID]: endPointPosition,
          }) as Record<string, Position>
      );
    }
  }, [
    connectionPoints,
    item.position.x,
    item.position.y,
    id,
    setConnectionPoints,
    columnWidth,
    item.name,
    item.group?.length,
    tooltipActive,
    counterX,
    counterWidth,
    iconWidthAndSpace,
  ]);

  return (
    <Group className={styles.groupNode} left={item.position.x} top={item.position.y} id={id}>
      <Group className={cx(styles.groupNode)}>
        {item.icon ? <Icon src={item.icon} /> : null}

        <text
          dominantBaseline="hanging"
          textAnchor="start"
          dx={iconWidthAndSpace}
          className={styles.text}
          ref={textRef}
          aria-label={item.name}
          onMouseLeave={tooltipActive ? onMouseLeave : undefined}
          onMouseEnter={tooltipActive ? createMouseEnterHandler(item.name) : undefined}
        >
          {item.name}
        </text>
        <rect
          className={styles.counterRect}
          y={-4}
          ry={ITEM_COUNTER_HEIGHT / 2}
          rx={ITEM_COUNTER_HEIGHT / 2}
          x={counterX}
          width={counterWidth}
          height={ITEM_COUNTER_HEIGHT}
        />

        <text
          ref={counterTextRef}
          textAnchor="middle"
          dominantBaseline="hanging"
          x={counterX + counterWidth / 2}
          className={styles.counterText}
        >
          {item.group?.length || 0}
        </text>

        {item.group && (
          <TreeChartGroupNodeRects
            activeId={activeId}
            items={item.group}
            createMouseEnterHandler={createMouseEnterHandler}
            onMouseLeave={onMouseLeave}
          />
        )}
      </Group>
    </Group>
  );
};

export default memo(TreeChartGroupNode) as (p: TreeChartGroupNodeProps) => ReactElement;
