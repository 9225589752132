import React from "react";

import styles from "./styles.module.css";

type SettingItemProps = {
  children: React.ReactNode;
};

const SettingItem = ({ children }: SettingItemProps) => {
  return <div className={styles.item}>{children}</div>;
};

export default SettingItem;
