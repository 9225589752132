import { memo } from "react";

import Filters from "components/Filters";
import { SearchQueryOrderDirection, SearchQueryPredicate } from "types/generated";
import { FilterItem } from "components/Filters/types";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";

const emptyFilters: FilterItem[] = [];
const emptyFiltersMap = new Map();
const noop = () => void 0;

type UsageFiltersLayoutProps = {
  predicates?: SearchQueryPredicate[];
  children: React.ReactNode;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => unknown;
};

const UsageFiltersLayout = ({
  children,
  currentSavedView,
  setCurrentSavedView,
}: UsageFiltersLayoutProps) => {
  return (
    <Filters
      filters={emptyFilters}
      filtersItemsOptionsMap={emptyFiltersMap}
      sortOptions={[]}
      initialSortOption={""}
      initialSortDirection={SearchQueryOrderDirection.Desc}
      filtersLoading={false}
      pollActiveSections={noop}
      filtersOrderSettingsKey={""}
      filtersType={""}
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar />

        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(UsageFiltersLayout);
