import cx from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.css";
import PageWrapperTitle from "./Title";

type PageInfoProps = {
  title: ReactNode;
  className?: string;
  children?: React.ReactNode;
};

const PageInfo = ({ title, children, className }: PageInfoProps) => {
  return (
    <div className={cx(styles.pageInfo, className)}>
      {typeof title === "string" ? <PageWrapperTitle>{title}</PageWrapperTitle> : title}
      <div className={styles.actions}>{children}</div>
    </div>
  );
};

export default PageInfo;
