import cx from "classnames";
import { ReactNode } from "react";

import styles from "./styles.module.css";

type FormListProps = {
  children: ReactNode;
  className?: string;
};

const FormList = ({ children, className }: FormListProps) => {
  return <ul className={cx(styles.list, className)}>{children}</ul>;
};

export default FormList;
