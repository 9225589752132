import LaunchPadChecklistLayout from "../components/ChecklistLayout";
import { GET_STARTED_FEATURES } from "./features";
import useSelfHostedOnboardingProgress from "./useSelfHostedOnboardingProgress";

const LaunchPadChecklistSelfHosted = () => {
  const { onboardingProgressInitialLoading, progressByFeature } = useSelfHostedOnboardingProgress();

  return (
    <LaunchPadChecklistLayout
      onboardingProgressInitialLoading={onboardingProgressInitialLoading}
      features={GET_STARTED_FEATURES}
      progressByFeature={progressByFeature}
    />
  );
};

export default LaunchPadChecklistSelfHosted;
