import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { useSearchParams } from "react-router-dom-v5-compat";

import { Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import { getSearchQuery } from "components/SearchInput/helpers";
import { SavedFilterView } from "components/Filters/types";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import TableContextProvider from "components/Table/Context";
import Table from "components/Table";
import TableRow from "components/Table/Row";
import { URL_FILTER_TAB_KEY } from "constants/url_query_keys";
import Box from "ds/components/Box";
import usePagination from "components/Table/Pagination/usePagination";
import TablePagination from "components/Table/Pagination";
import usePrevious from "hooks/usePrevious";

import StackListItem from "./ListItem";
import StacksPageLayout from "./PageLayout";
import StacksEmpty from "./Empty";
import FiltersLayout from "./FiltersLayout";
import {
  STACKS_COLUMN_CONFIG,
  ROW_HEADER_ID,
  STACK_COLUMNS_STORAGE_KEY,
  STACKS_CUSTOM_END_COLUMN_CONFIG,
  STACKS_LIMIT,
  // SELECT_ALL_OPTIONS,
  STACK_LIMIT_STORAGE_KEY,
  ANALYTICS,
} from "./constants";
import styles from "./styles.module.css";
import StacksBulkActions from "../Stacks/BulkActions";
import CustomizeViewDrawer from "./CustomizeViewDrawer";
import useStacks from "./useStacks";
import useSort from "./useSort";
import useSelectionStore from "../../../components/Table/useSelectionStore";
import useAllAvailableStacks from "./useAllAvailableStacks";

const Stacks = () => {
  const [renderTableKey, setRenderTableKey] = useState(1);
  const onConfigReset = useCallback(() => setRenderTableKey((old) => old + 1), []);
  const virtualizedListContainerRef = useRef<HTMLDivElement | null>(null);
  const [isCustomizeDrawerVisible, setIsCustomizeDrawerVisible] = useState(false);
  const handleCloseCustomizeDrawer = useCallback(() => setIsCustomizeDrawerVisible(false), []);

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedStack, setFocusedStack] = useState<Stack | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const searchInput = getSearchQuery(searchParams);

  const {
    allAvailableStacks,
    loading: allStacksLoading,
    setAllAvailableStacks,
    fetchAll,
  } = useAllAvailableStacks();

  const {
    initialLoading,
    error,
    stacks,
    loadStacks,
    stopPolling,
    endCursor,
    startCursor,
    noResults,
    isEmpty,
    loadingContent,
    predicates,
    refetch,
  } = useStacks(setAllAvailableStacks);

  const { selectIds, resetAllSelected, selected, unselect, setActiveId, resetStore } =
    useSelectionStore();

  const [itemsCount, setItemsCount] = useState<number>(0);

  const paginationAnalyticsProps = useMemo(
    () => ({
      ...ANALYTICS.analyticsProps,
      rowTotalCount: itemsCount,
    }),
    [itemsCount]
  );

  const {
    rowsPerPage,
    setRowsPerPage,
    from,
    to,
    lastPage,
    page,
    goToNextPage,
    goToPrevPage,
    goToFirstPage,
    goToLastPage,
    resetToInitialPage: resetPaginationToInitialPage,
  } = usePagination({
    ...ANALYTICS,
    analyticsProps: paginationAnalyticsProps,
    fetchItems: loadStacks,
    endCursor,
    startCursor,
    items: stacks,
    initialLimit: STACKS_LIMIT,
    itemsCount,
    storageKey: STACK_LIMIT_STORAGE_KEY,
  });

  const { handleSortOptionChange, sortDescriptor } = useSort(resetPaginationToInitialPage);

  const predefinedFilterTab = searchParams.get(URL_FILTER_TAB_KEY);
  const previousPredefinedFilterTab = usePrevious(predefinedFilterTab);

  // reset pagination and selected when filter changed
  useEffect(() => {
    if (previousPredefinedFilterTab !== predefinedFilterTab) {
      resetPaginationToInitialPage();
      resetAllSelected();
    }
  }, [
    resetPaginationToInitialPage,
    previousPredefinedFilterTab,
    predefinedFilterTab,
    resetAllSelected,
  ]);

  useEffect(() => {
    if (searchParams.size === 0 && page !== 1) {
      goToFirstPage();
    }
  }, [searchParams, page, goToFirstPage]);

  // reset store when unmouned
  useEffect(() => resetStore, [resetStore]);

  const handleOpenFullDescriptionDrawer = useCallback(
    (stack: Stack) => {
      setFocusedStack(stack);
      setActiveId(stack.id);
      setIsCustomizeDrawerVisible(false);

      setFullDescriptionDrawerVisible(true);
    },
    [setActiveId]
  );

  const handleCloseFullDescriptionDrawer = useCallback(() => {
    setFocusedStack(undefined);
    setActiveId(undefined);
    setFullDescriptionDrawerVisible(false);
  }, [setActiveId]);

  const handleOpenCustomizeDrawer = useCallback(() => {
    handleCloseFullDescriptionDrawer();
    setIsCustomizeDrawerVisible(true);
  }, [handleCloseFullDescriptionDrawer]);

  const onSeeDetailsCallback = useCallback(() => {
    handleCloseFullDescriptionDrawer();
    handleCloseCustomizeDrawer();
  }, [handleCloseFullDescriptionDrawer, handleCloseCustomizeDrawer]);

  const memoizedSelectedStacksMap = useMemo(
    () =>
      new Map(
        Object.keys(allAvailableStacks).length
          ? Object.entries(allAvailableStacks)
          : stacks.map((edge) => [edge.id, edge])
      ),
    [stacks, allAvailableStacks]
  );

  const handleBulkActionsFinish = useCallback(async () => {
    refetch();
    fetchAll();
  }, [refetch, fetchAll]);

  const onBulkContinueWith = useCallback(
    (set: Set<string>) => {
      selectIds(Array.from(set));
    },
    [selectIds]
  );

  const memoComponent = useCallback(
    (item: Stack) => (
      <TableRow key={item.id} id={item.id} name={item.name}>
        <StackListItem stack={item} onShowFullDescription={handleOpenFullDescriptionDrawer} />
      </TableRow>
    ),
    [handleOpenFullDescriptionDrawer]
  );

  const onBulkResetAll = useCallback(() => {
    resetAllSelected();
  }, [resetAllSelected]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (initialLoading) {
    return (
      <StacksPageLayout>
        <PageLayoutSkeleton />
      </StacksPageLayout>
    );
  }

  if (noResults) {
    return <NotFoundPage />;
  }

  return (
    <TableContextProvider
      selectable
      columnsConfig={STACKS_COLUMN_CONFIG}
      endCustomColumns={STACKS_CUSTOM_END_COLUMN_CONFIG}
      rowHeaderId={ROW_HEADER_ID}
      localStorageId={STACK_COLUMNS_STORAGE_KEY}
      setSortDescriptor={handleSortOptionChange}
      sortDescriptor={sortDescriptor}
      {...ANALYTICS}
    >
      <StacksPageLayout
        handleOpenCustomizeDrawer={handleOpenCustomizeDrawer}
        searchCallback={resetPaginationToInitialPage}
      >
        <FiltersLayout
          itemsCount={itemsCount}
          setItemsCount={setItemsCount}
          predicates={predicates}
          currentSavedView={currentSavedView}
          setCurrentSavedView={setCurrentSavedView}
          applyFilterCallback={resetPaginationToInitialPage}
        >
          {!!stacks.length && (
            <Box
              direction="column"
              zeroMinWidth
              justify="between"
              className={cx(stacks.length && styles.contentWrapper)}
              style={{
                height: `calc(100vh - ${virtualizedListContainerRef.current?.getBoundingClientRect().top}px)`,
              }}
            >
              <Box className={styles.tableWrapper}>
                <Table
                  key={renderTableKey}
                  ref={virtualizedListContainerRef}
                  ariaLabel="Stacks list"
                  items={stacks}
                  loadingContent={loadingContent}
                  // TODO: [stack list v2] enable in the next iteration
                  // selectAllOptions={SELECT_ALL_OPTIONS}
                >
                  {memoComponent}
                </Table>

                {(!loadingContent || selected.size > 0 || allStacksLoading) &&
                  !isCustomizeDrawerVisible &&
                  !isFullDescriptionDrawerVisible && (
                    <StacksBulkActions
                      virtualizedListContainerRef={virtualizedListContainerRef}
                      selectedSet={selected}
                      stacksMap={memoizedSelectedStacksMap}
                      onBulkResetAll={onBulkResetAll}
                      onBulkContinueWith={onBulkContinueWith}
                      onItemDismiss={unselect}
                      onFinish={handleBulkActionsFinish}
                      isLoadingItems={allStacksLoading}
                      onSeeDetailsCallback={onSeeDetailsCallback}
                    />
                  )}
              </Box>

              <TablePagination
                from={from}
                to={to}
                loading={loadingContent}
                goToNextPage={goToNextPage}
                setRowsPerPage={setRowsPerPage}
                goToPrevPage={goToPrevPage}
                goToFirstPage={goToFirstPage}
                goToLastPage={goToLastPage}
                rowsPerPage={rowsPerPage}
                itemsCount={itemsCount}
                lastPage={lastPage}
                page={page}
              />
            </Box>
          )}
          {isEmpty && <StacksEmpty hasNoResults={!!searchInput || predicates.length > 0} />}
        </FiltersLayout>
        <FullDescriptionDrawer
          position="fixedRight"
          visible={isFullDescriptionDrawerVisible}
          description={focusedStack?.description}
          onCloseDrawer={handleCloseFullDescriptionDrawer}
          onOutsideClick={handleCloseFullDescriptionDrawer}
        />
        <CustomizeViewDrawer
          isVisible={isCustomizeDrawerVisible}
          onClose={handleCloseCustomizeDrawer}
          onConfigReset={onConfigReset}
        />
      </StacksPageLayout>
    </TableContextProvider>
  );
};

export default Stacks;
