import { ReactNode } from "react";
import cx from "classnames";

import BaseAction, { BaseActionProps } from "ds/components/BaseAction";
import Typography from "ds/components/Typography";

type StepperStepProps = BaseActionProps & {
  children: ReactNode;
  active?: boolean;
  onClick?: () => void;
};

import styles from "./styles.module.css";

const StepperStep = ({ active, children, disabled, onClick }: StepperStepProps) => {
  return (
    <BaseAction
      className={cx(styles.wrapper, active && styles.active)}
      disabled={disabled}
      onClick={onClick}
    >
      <Typography tag="div" variant="p-body2" className={styles.icon} aria-hidden>
        &#8203;
      </Typography>
      <Typography tag="span" variant="p-t6">
        {children}
      </Typography>
    </BaseAction>
  );
};

export default StepperStep;
