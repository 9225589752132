import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  ManagedUserGroupIntegration,
  ManagedUserGroupIntegrationCreateInput,
} from "types/generated";

import { CREATE_MANAGED_USER_GROUP_INTEGRATION } from "../gql";

const useCreateIntegration = () => {
  const { onError } = useTypedContext(FlashContext);

  const [createManagedUserGroupIntegration] = useMutation<{
    managedUserGroupIntegrationCreate: ManagedUserGroupIntegration;
  }>(CREATE_MANAGED_USER_GROUP_INTEGRATION, {
    onError,
    refetchQueries: ["GetManagedUserGroupIntegrations"],
  });

  const onCreate = (input: ManagedUserGroupIntegrationCreateInput) => {
    return createManagedUserGroupIntegration({
      variables: {
        input,
      },
    });
  };

  return { onCreate };
};

export default useCreateIntegration;
