import Counter from "ds/components/Counter";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelClickableHeader from "components/CollapsiblePanel/ClickableHeader";
import { withTestId } from "utils/withTestId";
import { useToggle } from "hooks/useToggle";

import styles from "./styles.module.css";
import { ReplanRequestChangeType } from "./types";
import ReplanRequestChange from "./Change";

type ReplanRequestChangesProps = {
  title: string;
  changes: ReplanRequestChangeType[];
  dataTestId?: string;
};

function ReplanRequestChanges({ title, changes, dataTestId }: ReplanRequestChangesProps) {
  const [isCollapsed, toggle] = useToggle(true);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} withTransition onToggle={toggle}>
      <CollapsiblePanelClickableHeader
        gap="medium"
        padding="small large"
        wrapperClassName={styles.header}
        ariaLevel={4}
      >
        <CollapsiblePanelToggleIndicator />

        <CollapsiblePanelTitle variant="p-t7">{title}</CollapsiblePanelTitle>

        <Counter count={changes.length} />
      </CollapsiblePanelClickableHeader>

      <CollapsiblePanelContent {...withTestId(dataTestId)}>
        {changes.map((change) => (
          <ReplanRequestChange key={change.id} change={change} />
        ))}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
}

export default ReplanRequestChanges;
