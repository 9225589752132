import { useNavigate } from "react-router-dom-v5-compat";
import { create, show, useModal } from "@ebay/nice-modal-react";

import Link from "ds/components/Link";
import Typography from "ds/components/Typography";
import { pluralize, pluralizeVerb } from "shared/Pluralize";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

type VCSDeleteIntegrationModalProps = {
  attachedEntitiesQty: number;
  integrationName: string;
  linkPath?: string;
  onConfirm: () => void;
  onClose: () => void;
};

const VCSDeleteIntegrationModal = create(function VCSDeleteIntegrationModal({
  attachedEntitiesQty,
  integrationName,
  linkPath,
  onConfirm,
  onClose,
}: VCSDeleteIntegrationModalProps) {
  const modal = useModal();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (linkPath) {
      modal.hide();
      navigate(linkPath);
    }
  };

  const handleConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <DeleteConfirmationModal title="Delete integration" onConfirm={handleConfirm} onClose={onClose}>
      <ConfirmationModalMessage>
        Are you sure you want to delete <strong>{integrationName}</strong>?
      </ConfirmationModalMessage>

      {attachedEntitiesQty > 0 && (
        <>
          <br />
          <br />
          <Typography tag="p" variant="p-body2">
            <b>{attachedEntitiesQty}</b> {pluralize("stack", attachedEntitiesQty)}/
            {pluralize("module", attachedEntitiesQty)} still{" "}
            {pluralizeVerb("use", attachedEntitiesQty)} this integration.{" "}
            {linkPath && <Link onClick={handleLinkClick}>Go to "Used by" page</Link>}
          </Typography>
        </>
      )}
    </DeleteConfirmationModal>
  );
});

export const showVCSDeleteIntegrationModal = (props: VCSDeleteIntegrationModalProps) =>
  show(VCSDeleteIntegrationModal, props);
