import cx from "classnames";
import { Handle, NodeProps, Position } from "reactflow";

import DependencyItem from "views/Stack/components/DependencyItem";
import { StackDependencyDetail } from "types/generated";

import styles from "./styles.module.css";

const StackDependencyNode = ({ data }: NodeProps<StackDependencyDetail>) => {
  return (
    <>
      <Handle id={data.id} type="target" position={Position.Left} className={styles.topPoint} />
      <DependencyItem item={data} className={styles.node} graphMode />
      <Handle
        id={data.id}
        className={cx(styles.sourceHandle)}
        type="source"
        position={Position.Right}
      />
    </>
  );
};

export default StackDependencyNode;
