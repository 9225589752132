import { FC } from "react";

export enum SideNavigationItemType {
  Dashboard = "Dashboard",
  Stacks = "Stacks",
  Blueprints = "Blueprints",
  Modules = "Modules",
  Runs = "Runs",
  Spaces = "Spaces",
  Resources = "Resources",
  Policies = "Policies",
  Contexts = "Contexts",
  WorkerPools = "WorkerPools",
  Webhooks = "Webhooks",
  VCSManagement = "VCSManagement",
  CloudIntegrations = "CloudIntegrations",
}

export type SidebarStorageConfig = {
  visible: SideNavigationItemType[];
  hidden: SideNavigationItemType[];
};

export type SidebarNavigationItemRule = Array<string> | ((path: string) => boolean);

export type SidebarNavigationItem = {
  title: string;
  icon: FC;
  to: string;
  rule: SidebarNavigationItemRule;
  hidden?: boolean;
  beta?: boolean;
  id?: (rule?: boolean) => string | undefined;
};

type OptionalSideNavigationItemTypes = SideNavigationItemType.Dashboard;

export type NavigationConfigType = Omit<
  Record<SideNavigationItemType, SidebarNavigationItem>,
  OptionalSideNavigationItemTypes
> &
  Partial<Record<OptionalSideNavigationItemTypes, SidebarNavigationItem>>;
