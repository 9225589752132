import { Dispatch, SetStateAction, createContext } from "react";

import {
  InternalFormData,
  ModuleCreationWizardNavStep,
  ModuleCreationWizardStep,
  ModuleFormFields,
} from "./types";

type ModuleFormContextProps = {
  availableSteps: Array<ModuleCreationWizardNavStep>;
  currentStep: ModuleCreationWizardStep;
  currentStepIndex: number;
  formData: ModuleFormFields;
  setFormData: Dispatch<SetStateAction<ModuleFormFields>>;
  updateStepData: (
    step: ModuleCreationWizardStep,
    data: ModuleFormFields[ModuleCreationWizardStep]
  ) => void;
  setCurrentStep: (step: ModuleCreationWizardStep) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToSummary: () => void;
  setAsyncValidationLoading: (loading: boolean) => void;
  asyncValidationLoading: boolean;
  setStepperNavDisabled: (disabled: boolean) => void;
  stepperNavDisabled: boolean;
  internalData: InternalFormData;
  setInternalFormData: Dispatch<React.SetStateAction<InternalFormData>>;
  createdModuleId: string;
  setCreatedModuleId: (id: string) => void;
  isIntermediateStepActive: boolean;
  setIsIntermediateStepActive: (active: boolean) => void;
  setAccountOrAuthErrorContent: (content: JSX.Element) => void;
};

export const ModuleFormContext = createContext<ModuleFormContextProps | undefined>(undefined);
ModuleFormContext.displayName = "ModuleFormContext";
