import EmptyState from "ds/components/EmptyState";
import { PlaceholderTimeColored } from "components/icons";

const WorkerPoolQueuedRunsEmpty = () => {
  return (
    <EmptyState
      title="No schedulable runs found"
      icon={PlaceholderTimeColored}
      caption="You have no schedulable runs, everything is running smoothly."
    />
  );
};

export default WorkerPoolQueuedRunsEmpty;
