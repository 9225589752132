import { RunStateTransition } from "types/generated";

import ConfirmedEntry from "../entries/ConfirmedEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createConfirmedEntry = ({
  timestamp,
  until,
  username,
  stateVersion,
}: RunStateTransition) => {
  return (
    <ConfirmedEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      username={username}
    />
  );
};
