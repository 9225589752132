import { gql } from "@apollo/client";

export const GET_RUNS_REQUIRING_ATTENTION = gql`
  query GetRunsRequiringAttention {
    metrics {
      runsRequiringAttention {
        id
        title
        state
        stackTile {
          slug
          name
        }
        createdAt
        needsApproval
      }
      hasRuns
    }
  }
`;
