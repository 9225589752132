import { gql, TypedDocumentNode } from "@apollo/client";

import {
  AnsibleHost,
  AnsibleHostsConnection,
  AnsiblePlaybook,
  AnsibleRole,
  AnsibleTask,
  AnsibleTaskExecution,
  Maybe,
  PageInfo,
  QuerySearchAnsibleHostsArgs,
  QuerySearchAnsibleHostsSuggestionsArgs,
  QuerySearchAnsibleTasksArgs,
  QuerySearchAnsibleTasksSuggestionsArgs,
  SearchSuggestionsField,
} from "types/generated";

type SearchAnsibleHostsSuggestionsData = {
  searchAnsibleHostsSuggestions: {
    fields: Array<Omit<SearchSuggestionsField, "possibleValuesTime">>;
  };
};

export const SEARCH_ANSIBLE_HOSTS_SUGGESTIONS: TypedDocumentNode<
  SearchAnsibleHostsSuggestionsData,
  QuerySearchAnsibleHostsSuggestionsArgs
> = gql`
  query SearchAnsibleHostsSuggestions($input: SearchSuggestionsInput!) {
    searchAnsibleHostsSuggestions(input: $input) {
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

type SearchAnsibleTasksSuggestionsData = {
  searchAnsibleTasksSuggestions: {
    fields: Array<Omit<SearchSuggestionsField, "possibleValuesTime">>;
  };
};

export const SEARCH_ANSIBLE_TASKS_SUGGESTIONS: TypedDocumentNode<
  SearchAnsibleTasksSuggestionsData,
  QuerySearchAnsibleTasksSuggestionsArgs
> = gql`
  query SearchAnsibleTasksSuggestions($input: SearchSuggestionsInput!) {
    searchAnsibleTasksSuggestions(input: $input) {
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export type AnsibleHostData = {
  id: AnsibleHost["id"];
  name: AnsibleHost["name"];
  taskExecutions: Array<{
    task: {
      name: AnsibleTask["name"];
      path: AnsibleTask["path"];
      playbook: AnsiblePlaybook;
      role: Maybe<AnsibleRole>;
    };
    status: AnsibleTaskExecution["status"];
    timestamp: AnsibleTaskExecution["timestamp"];
    checkMode: AnsibleTaskExecution["checkMode"];
    stdout: AnsibleTaskExecution["stdout"];
    runId: AnsibleTaskExecution["runId"];
    stackId: AnsibleTaskExecution["stackId"];
    diff: AnsibleTaskExecution["diff"];
  }>;
};

type SearchAnsibleHostsData = {
  searchAnsibleHosts: Maybe<{
    edges: Array<{
      cursor: AnsibleHostsConnection["cursor"];
      node: AnsibleHostData;
    }>;
    pageInfo: Omit<PageInfo, "startCursor">;
  }>;
};

export const SEARCH_ANSIBLE_HOSTS: TypedDocumentNode<
  SearchAnsibleHostsData,
  QuerySearchAnsibleHostsArgs
> = gql`
  query SearchAnsibleHosts($input: SearchInput!) {
    searchAnsibleHosts(input: $input) {
      edges {
        cursor
        node {
          id
          name
          taskExecutions {
            task {
              name
              path
              playbook {
                name
                path
              }
              role {
                name
                path
              }
            }
            status
            timestamp
            checkMode
            stdout
            runId
            diff
            stackId
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export type AnsibleTaskData = {
  name: AnsibleTask["name"];
  path: AnsibleTask["path"];
  playbook: AnsiblePlaybook;
  role: Maybe<AnsibleRole>;
  taskExecutions: Array<{
    status: AnsibleTaskExecution["status"];
    timestamp: AnsibleTaskExecution["timestamp"];
    checkMode: AnsibleTaskExecution["checkMode"];
    stdout: AnsibleTaskExecution["stdout"];
    host: {
      id: AnsibleHost["id"];
      name: AnsibleHost["name"];
    };
    runId: AnsibleTaskExecution["runId"];
    stackId: AnsibleTaskExecution["stackId"];
    diff: AnsibleTaskExecution["diff"];
  }>;
};

type SearchAnsibleTasksData = {
  searchAnsibleTasks: Maybe<{
    edges: Array<{
      cursor: AnsibleHostsConnection["cursor"];
      node: AnsibleTaskData;
    }>;
    pageInfo: Omit<PageInfo, "startCursor">;
  }>;
};

export const SEARCH_ANSIBLE_TASKS: TypedDocumentNode<
  SearchAnsibleTasksData,
  QuerySearchAnsibleTasksArgs
> = gql`
  query SearchAnsibleTasks($input: SearchInput!) {
    searchAnsibleTasks(input: $input) {
      edges {
        cursor
        node {
          name
          path
          playbook {
            name
            path
          }
          role {
            name
            path
          }
          taskExecutions {
            status
            timestamp
            checkMode
            stdout
            runId
            host {
              id
              name
            }
            diff
            stackId
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;
