export const searchAnsibleHostsSuggestionsDictionary: Partial<
  Record<AnsibleHostsSuggestions, string>
> = {
  playbookPath: "playbook path",
};

export enum AnsibleHostsSuggestions {
  Name = "name",
  Playbook = "playbook",
  PlaybookPath = "playbookPath",
  Role = "role",
  Stack = "stack",
}
