import { useQuery } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Module } from "types/generated";

import { GET_MODULE_VERSIONS } from "./gql";

type ModuleVersionGql = {
  module: Module;
};

const useModuleVersions = (moduleId: string, includeFailed: boolean = false) => {
  const { onError } = useTypedContext(FlashContext);
  const [hasMore, setHasMore] = useState(true);

  const { loading, data, fetchMore } = useQuery<ModuleVersionGql>(GET_MODULE_VERSIONS, {
    onError,
    variables: { moduleId, includeFailed },
    nextFetchPolicy: "cache-first",
  });

  const handleLoadMore = useCallback(async () => {
    const versions = data?.module?.versions;

    if (loading || !versions) {
      return;
    }

    const lastVersion = versions[versions.length - 1];

    if (!lastVersion) {
      return;
    }

    try {
      const { data } = await fetchMore({
        variables: { beforeVersionId: lastVersion.id },
      });

      setHasMore(data?.module?.versions ? data.module.versions.length === 50 : false);
    } catch (err) {
      onError(err);
    }
  }, [data, fetchMore, loading, onError]);

  const versions = useMemo(() => data?.module?.versions || [], [data]);

  return {
    versions,
    loading: loading && !versions.length,
    handleLoadMore,
    hasMore,
  };
};

export default useModuleVersions;
