import React from "react";

import { IconComponent } from "types/Icon";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type CloudIntegrationsEmptyPlaceholderProps = {
  buttonText?: string;
  header: string;
  icon: IconComponent;
  onAdd?: () => void;
  children: React.ReactNode;
};

const CloudIntegrationsEmptyPlaceholder = ({
  buttonText,
  children,
  header,
  icon: Icon,
  onAdd,
}: CloudIntegrationsEmptyPlaceholderProps) => {
  return (
    <div className={styles.wrapper}>
      <Icon className={styles.icon} />
      <Typography tag="h5" variant="p-t4" className={styles.header}>
        {header}
      </Typography>
      <Typography className={styles.text} tag="p" variant="p-body2">
        {children}
      </Typography>
      {onAdd && buttonText && (
        <Button variant="primary" onClick={onAdd}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
export default CloudIntegrationsEmptyPlaceholder;
