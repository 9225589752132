import { useCallback, useEffect, useReducer, useRef } from "react";
import { useToastState } from "@react-stately/toast";
import { mergeProps } from "@react-aria/utils";
import { useFocusWithin, useHover } from "react-aria";

import useTypedContext from "hooks/useTypedContext";
import { FlashEvent } from "types/FlashMessage";
import { ToastVariant } from "ds/components/ToastNew";

import FlashContext from "./FlashContext";
import FlashMessagesRegion from "./Region";
import { getMessagePriority, getMessageTimeout } from "./utils";

type FlashMessagesProps = {
  defaultTimeout?: number;
};

const FlashMessages = ({ defaultTimeout }: FlashMessagesProps) => {
  const { subscribe, unsubscribe } = useTypedContext(FlashContext);

  const state = useToastState<FlashEvent>({ maxVisibleToasts: Infinity, hasExitAnimation: true });
  const visibleErrorToastIdRef = useRef<string>();

  const rerender = useReducer((state) => !state, false)[1];

  const { hoverProps } = useHover({
    onHoverStart: rerender,
    onHoverEnd: rerender,
  });

  const { focusWithinProps } = useFocusWithin({
    onFocusWithin: rerender,
    onBlurWithin: rerender,
  });

  const addMessage = useCallback(
    (flashEvent: FlashEvent) => {
      const visibleToastId = visibleErrorToastIdRef.current;

      if (flashEvent.variant === ToastVariant.Error && visibleToastId) {
        state.close(visibleToastId);
        state.remove(visibleToastId);
      }

      const toastId = state.add(flashEvent, {
        priority: getMessagePriority(flashEvent, state),
        timeout: getMessageTimeout(flashEvent, defaultTimeout),
        onClose: () => {
          if (flashEvent.variant === ToastVariant.Error) {
            visibleErrorToastIdRef.current = undefined;
          }
        },
      });

      if (flashEvent.variant === ToastVariant.Error) {
        visibleErrorToastIdRef.current = toastId;
      }
    },
    [defaultTimeout, state]
  );

  useEffect(
    function keepSubscribed() {
      subscribe(addMessage);
      return () => unsubscribe(addMessage);
    },
    [addMessage, subscribe, unsubscribe]
  );

  if (state.visibleToasts.length === 0) {
    return null;
  }

  return (
    <div tabIndex={-1} {...mergeProps(hoverProps, focusWithinProps)}>
      <FlashMessagesRegion state={state} />
    </div>
  );
};

export default FlashMessages;
