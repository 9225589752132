import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect } from "react";

import Box from "ds/components/Box";
import Timestamp from "components/time/Timestamp";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardWidgetsEmptyStateRuns from "../EmptyStateRuns";
import DashboardWidgetsRunTile from "../RunTile";
import DashboardWidgetsRunsSkeleton from "../RunsSkeleton";
import { GET_RUNS_RECENTLY_APPROVED } from "./gql";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";
import styles from "./styles.module.css";

// TODO: [dashboard] add limit to query once added to API
const LIMIT = 10;

const DashboardWidgetsApprovalPolicyEvaluations = () => {
  const pollInterval = usePollInterval();

  const { loading, data, error, refetch, startPolling, stopPolling, networkStatus } = useQuery(
    GET_RUNS_RECENTLY_APPROVED,
    {
      onError() {
        stopPolling();
      },
    }
  );

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.metrics?.runsRecentlyApproved;

  const runs = data?.metrics?.runsRecentlyApproved?.slice(0, LIMIT);
  let emptyState;

  const isEmpty = !isLoading && !runs?.length;

  const hasRuns = !!data?.metrics?.hasRuns;

  if (!hasRuns) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns showDocs widget={Widget.ApprovalPolicyEvaluations} />
    );
  }

  if (hasRuns && isEmpty) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns
        caption="No approval evaluations."
        widget={Widget.ApprovalPolicyEvaluations}
        onlyCaption
      />
    );
  }

  if (showErrorBanner) {
    return (
      <MissingDataBanner
        text="Couldn’t load runs data. Please try to refresh or come back later. "
        refreshHandler={refetch}
        refreshLoading={loading}
        fullWidth
      />
    );
  }

  if (isLoading) {
    return <DashboardWidgetsRunsSkeleton />;
  }

  return (
    <Box className={styles.titleContent}>
      <Box direction="column" gap="medium" fullWidth>
        {emptyState}

        {!emptyState &&
          runs?.map(({ runTile: { id, title, createdAt, stackTile }, approval }, i) => (
            <DashboardWidgetsRunTile
              key={i}
              id={id}
              title={title}
              stackTile={stackTile}
              caption={
                <>
                  Committed <Timestamp timestamp={createdAt} />
                </>
              }
              approval={approval}
              analyticsTitle="Aproval evaulations"
            />
          ))}
      </Box>
    </Box>
  );
};

export default DashboardWidgetsApprovalPolicyEvaluations;
