import useTypedContext from "hooks/useTypedContext";
import { WORKER_POOL_SHARED_LABEL } from "constants/worker_pool";
import { workflowToolLabel } from "constants/terraform_workflow_tools";
import FormFieldViewText from "components/FormFields/ViewText";
import FormFieldViewBoolean from "components/FormFields/ViewBoolean";

import { ModuleSettingsContext } from "../Context";

const ModuleSettingsBehaviorViewForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);

  return (
    <>
      <FormFieldViewText
        label="Worker pool"
        value={module.workerPool ? module.workerPool.name : WORKER_POOL_SHARED_LABEL}
      />
      <FormFieldViewBoolean label="Administrative" value={module.administrative} />

      <FormFieldViewBoolean label="Enable local preview" value={module.localPreviewEnabled} />

      <FormFieldViewBoolean label="Protect from deletion" value={module.protectFromDeletion} />

      <FormFieldViewText label="Workflow tool" value={workflowToolLabel(module.workflowTool)} />
    </>
  );
};

export default ModuleSettingsBehaviorViewForm;
