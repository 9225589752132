import { useEffect } from "react";

import { FiltersContext } from "components/Filters";
import { URL_FILTER_TAB_KEY } from "constants/url_query_keys";
import usePrevious from "hooks/usePrevious";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";

const StackFiltersLayoutSyncWithPredefinedTabs = () => {
  const urlParams = useURLParams();
  const tab = urlParams.get(URL_FILTER_TAB_KEY);
  const prevTab = usePrevious(tab);

  const { forceReMountSidebar, setActiveFiltersFromInitialParams } =
    useTypedContext(FiltersContext);

  useEffect(() => {
    if (prevTab !== tab) {
      forceReMountSidebar();
      setActiveFiltersFromInitialParams();
    }
  }, [prevTab, tab, forceReMountSidebar, setActiveFiltersFromInitialParams]);

  return null;
};

export default StackFiltersLayoutSyncWithPredefinedTabs;
