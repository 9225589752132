import { gql } from "@apollo/client";

export const CREATE_GITLAB_INTEGRATION = gql`
  mutation CreateGitLabIntegrationInVCS(
    $apiHost: String!
    $customInput: CustomVCSInput!
    $privateToken: String!
    $userFacingHost: String
  ) {
    gitlabIntegrationCreate(
      apiHost: $apiHost
      customInput: $customInput
      privateToken: $privateToken
      userFacingHost: $userFacingHost
    ) {
      id
      apiHost
      name
    }
  }
`;

export const UPDATE_GITLAB_INTEGRATION = gql`
  mutation UpdateGitLabIntegration(
    $apiHost: String!
    $customInput: CustomVCSUpdateInput
    $privateToken: String
    $userFacingHost: String
  ) {
    gitlabIntegrationUpdate(
      apiHost: $apiHost
      customInput: $customInput
      privateToken: $privateToken
      userFacingHost: $userFacingHost
    ) {
      id
      apiHost
      name
    }
  }
`;

export const DELETE_GITLAB_INTEGRATION = gql`
  mutation DeleteGitLabIntegration($id: ID!) {
    gitlabIntegrationDelete(id: $id) {
      id
      name
    }
  }
`;

export const CHECK_GITLAB_HAS_DEFAULT_INTEGRATION = gql`
  query CheckGitLabHasDefaultIntegration {
    checkGitLabHasDefaultIntegration: gitlabIntegration {
      id
    }
  }
`;
