import { BulkActionsApplicableAction } from "components/BulkActions/ChooseActionStep/types";
import {
  BulkActionItemID,
  BulkActionsActionsPerItem,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import { Module } from "types/generated";
import BulkActionsDrawerActionStepsItems from "components/BulkActions/Drawer/ActionSteps/Items";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import BulkActionsPatternsActionsList from "components/BulkActionsPatterns/ActionsList";

import { ModuleBulkActions } from "./types";
import ModulesBulkActionsSelectedItem from "./SelectedItem";

type ModulesBulkActionsSelectedItemsViewProps = {
  items: Module[];
  onItemDismiss: (id: BulkActionItemID) => void;
  availableActions: Array<BulkActionsApplicableAction<ModuleBulkActions>>;
  actionsPerItem: BulkActionsActionsPerItem<ModuleBulkActions>;
  onEmptyActionsCancel: () => void;
};

const ModulesBulkActionsSelectedItemsView = ({
  items,
  onItemDismiss,
  actionsPerItem,
  availableActions,
  onEmptyActionsCancel,
}: ModulesBulkActionsSelectedItemsViewProps) => {
  return (
    <>
      <BulkActionsDrawerActionStepsItems selectedItemsCount={items.length}>
        {items.map((item) => (
          <ModulesBulkActionsSelectedItem
            key={item.id}
            item={item}
            onDismiss={onItemDismiss}
            itemActions={actionsPerItem.get(item.id)}
            allActionsQty={availableActions.length}
            step={BulkActionsStep.ChooseAction}
          />
        ))}
      </BulkActionsDrawerActionStepsItems>

      <BulkActionsDrawerActionStepsFooter>
        <BulkActionsPatternsActionsList
          variant={BulkActionsVariant.Drawer}
          availableActions={availableActions}
          onEmptyActionsCancel={onEmptyActionsCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default ModulesBulkActionsSelectedItemsView;
