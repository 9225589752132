import { gql } from "@apollo/client";

export const GET_ACCOUNT_RUNS = gql`
  query GetAccountRuns($start: String!, $end: String!) {
    stacks {
      id
      name
      blocker {
        id
      }
      canWrite
      labels
      lockedAt
      lockedBy
      namespace
      repository
      runsInRange(start: $start, end: $end) {
        id
        commit {
          authorLogin
          authorName
          hash
          message
          timestamp
          url
        }
        createdAt
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
        }
        dependsOn {
          runId
        }
        driftDetection
        needsApproval
        runtimeConfig {
          projectRoot
          runnerImage
          terraform {
            version
            workflowTool
          }
          terragrunt {
            terraformVersion
            terragruntVersion
            tool
          }
        }
        state
        title
        triggeredBy
        type
        branch
      }
      state
      spaceDetails {
        accessLevel
      }
      vendorConfig {
        __typename
      }
    }
    modules {
      id
      name
      canWrite
      labels
      namespace
      repository
      runsInRange(start: $start, end: $end) {
        id
        commit {
          authorLogin
          authorName
          hash
          message
          timestamp
          url
        }
        createdAt
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
        }
        driftDetection
        runtimeConfig {
          projectRoot
          runnerImage
          terraform {
            version
            workflowTool
          }
        }
        state
        title
        triggeredBy
        type
        branch
      }
    }
  }
`;
