import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { StackContext } from "views/Stack/Context";

import { DELETE_STACK_SCHEDULING_TASK } from "./gql";

const useDeleteScheduledTask = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const [stackScheduledTaskDelete, { loading }] = useMutation(DELETE_STACK_SCHEDULING_TASK, {
    refetchQueries: ["GetStackScheduling"],
    awaitRefetchQueries: true,
    onError,
  });

  const deleteScheduledTask = useCallback(
    (scheduleId: string, successCallback?: () => void) => {
      stackScheduledTaskDelete({
        variables: {
          scheduleId,
          stack: stack.id,
        },
      })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: "Task schedule successfully removed.",
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stack.id, stackScheduledTaskDelete]
  );

  return {
    deleteScheduledTask,
    loading,
  };
};

export default useDeleteScheduledTask;
