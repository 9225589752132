import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import Badge from "ds/components/Badge";
import Box from "ds/components/Box";
import Timestamp from "components/Timestamp";
import LinkNew from "ds/components/LinkNew";

import { ConfigNode } from "../../types";

type ConfigManagementTreeGridDrawerDetailsProps = {
  task: ConfigNode;
};

const ConfigManagementTreeGridDrawerDetails = ({
  task,
}: ConfigManagementTreeGridDrawerDetailsProps) => {
  return (
    <>
      <FormFieldViewText label="Task name" value={task.taskName} />
      {task.hostName && <FormFieldViewText label="Host name" value={task.hostName} />}
      {task.playbookName && <FormFieldViewText label="Playbook" value={task.playbookName} />}
      {task.roleName && <FormFieldViewText label="Role" value={task.roleName} />}
      {task.status && (
        <FormField label="Status">
          <Box gap="medium">
            <Badge variant="ansibleStatus" state={task.status} />
            {task.checkMode && <Badge state="neutral">CHECK MODE</Badge>}
          </Box>
        </FormField>
      )}
      {task.timestamp && (
        <Box justify="start" margin="x-large 0 0 0">
          <FormField label="Performed at">
            <Timestamp timestamp={task.timestamp} />
          </FormField>
        </Box>
      )}
      {task.runId && task.stackId && (
        <Box justify="start" margin="x-large 0 0">
          <LinkNew href={`/stack/${task.stackId}/run/${task.runId}`}>Go to Run</LinkNew>
        </Box>
      )}
    </>
  );
};

export default ConfigManagementTreeGridDrawerDetails;
