import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";

type ThemeAppearanceSectionTitleProps = {
  title: string;
  description: string;
  icon: IconComponent;
  isActive?: boolean;
};

const ThemeAppearanceSectionTitle = ({
  icon: IconComponent,
  title,
  description,
  isActive,
}: ThemeAppearanceSectionTitleProps) => {
  return (
    <>
      <Box gap="small" align="center" margin="0 0 small 0">
        <Icon size="large" src={IconComponent} className={styles.titleIcon} />
        <Typography variant="p-t6" tag="h3" margin="0">
          {title}
        </Typography>
        {isActive && <BadgeNext variant="blue" type="strong" text="ACTIVE" />}
      </Box>

      <Typography variant="p-body2" tag="p" className={styles.subDescription}>
        {description}
      </Typography>
    </>
  );
};

export default ThemeAppearanceSectionTitle;
