import { ReactNode, createContext } from "react";

type WarningContextProviderData = {
  setWarning: (value: ReactNode) => void;
  clearWarning: () => void;
  warning: ReactNode | undefined;
};

const WarningContext = createContext<WarningContextProviderData | undefined>(undefined);
WarningContext.displayName = "WarningContext";

export default WarningContext;
