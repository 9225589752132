import { FunctionComponent } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import {
  StackAwsIntegrationAttachment,
  StackAzureIntegrationAttachment,
  StackIntegrationGcp,
} from "types/generated";
import { Loading, Trash } from "components/icons";
import IconTile from "ds/components/IconTile";
import KeyValue from "components/KeyValue";

import { ModuleCreationCloud } from "../../types";
import NewModuleAttachCloudDetailsAWSFields from "./AWSFields";
import NewModuleAttachCloudDetailsAzureFields from "./AzureFields";
import useDetachCloud from "../hooks/useDetachCloud";
import NewModuleAttachCloudDetailsSwitchIcon from "./SwitchIcon";
import NewModuleAttachCloudDetailsGCPFields from "./GCPFields";

type NewModuleAttachCloudDetailsProps = {
  attachedIntegration:
    | StackAwsIntegrationAttachment
    | StackAzureIntegrationAttachment
    | StackIntegrationGcp;
  attachedIntegrationType: ModuleCreationCloud;
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: ModuleCreationCloud;
  }>;
  className?: string;
  moduleId?: string;
  refetchQueries?: string[];
  readOnly?: boolean;
};

const NewModuleAttachCloudDetails = ({
  attachedIntegration,
  attachedIntegrationType,
  cloudConfig,
  className,
  moduleId,
  refetchQueries,
  readOnly,
}: NewModuleAttachCloudDetailsProps) => {
  const { detach, detachLoading } = useDetachCloud({
    refetchQueries,
  });

  const handleDetach = () => {
    switch (attachedIntegrationType) {
      case ModuleCreationCloud.AWS:
      case ModuleCreationCloud.Azure:
        if ("id" in attachedIntegration) {
          detach({ type: attachedIntegrationType, attachmentId: attachedIntegration.id });
        }
        break;
      case ModuleCreationCloud.GCP:
        if (moduleId) {
          detach({ type: attachedIntegrationType, moduleId });
        }
    }
  };

  const config = cloudConfig.find(({ type }) => type === attachedIntegrationType);

  if (!config) {
    return null;
  }

  const Logo = config.logo;

  return (
    <CardWrapper variant="outlined" direction="column" className={className}>
      <Box justify="between" align="center" margin="0 0 large 0">
        <Box align="center" gap="medium">
          <IconTile icon={Logo} />
          <Typography tag="p" variant="p-t5">
            {config.name} integration
          </Typography>
        </Box>
        {!readOnly && (
          <ButtonIcon
            disabled={detachLoading}
            icon={detachLoading ? Loading : Trash}
            onClick={handleDetach}
            variant={detachLoading ? "ghost" : "ghostDanger"}
          >
            Detach
          </ButtonIcon>
        )}
      </Box>
      {attachedIntegrationType === ModuleCreationCloud.AWS && (
        <NewModuleAttachCloudDetailsAWSFields
          integration={attachedIntegration as StackAwsIntegrationAttachment}
        />
      )}

      {attachedIntegrationType === ModuleCreationCloud.Azure && (
        <NewModuleAttachCloudDetailsAzureFields
          integration={attachedIntegration as StackAzureIntegrationAttachment}
        />
      )}

      {attachedIntegrationType === ModuleCreationCloud.GCP && (
        <NewModuleAttachCloudDetailsGCPFields
          integration={attachedIntegration as StackIntegrationGcp}
        />
      )}

      {"read" in attachedIntegration && "write" in attachedIntegration && (
        <>
          <KeyValue align="center" name="Read">
            <NewModuleAttachCloudDetailsSwitchIcon checked={attachedIntegration.read} />
          </KeyValue>
          <KeyValue align="center" name="Write">
            <NewModuleAttachCloudDetailsSwitchIcon checked={attachedIntegration.write} />
          </KeyValue>
        </>
      )}
    </CardWrapper>
  );
};

export default NewModuleAttachCloudDetails;
