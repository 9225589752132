import { useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import { ManagedStateVersion } from "types/generated";
import BadgeNext from "ds/components/BadgeNext";
import Typography from "ds/components/Typography";
import Timestamp from "components/time/Timestamp";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { TimeMachine } from "components/icons";
import Tooltip from "ds/components/Tooltip";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import StackStateHistoryRollBackAction from "../RollbackAction";
import useStateRollback from "../useStateRollback";
import StackStateHistoryListItemEventTitle from "./EventTitle";
import StackStateHistoryListItemRollbackEventTitle from "./RollbackEventTitle";
import StackStateHistoryListItemImportedEventTitle from "./ImportedEventTitle";

type StackStateHistoryListItemProps = {
  item: ManagedStateVersion;
  setRowHeight?: (size: number) => void;
  isCurrentState: boolean;
};

const StackStateHistoryListItem = ({
  item,
  setRowHeight,
  isCurrentState,
}: StackStateHistoryListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const { stack } = useTypedContext(StackContext);

  const { rollbackState, loading } = useStateRollback();

  const handleRollback = () => {
    rollbackState(stack.id, item.id);
  };

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      role={"row"}
    >
      <Box direction="row" align="start" role="cell">
        {item.imported && <StackStateHistoryListItemImportedEventTitle item={item} />}
        {item.rollback && <StackStateHistoryListItemRollbackEventTitle item={item} />}
        {!item.rollback && !item.imported && <StackStateHistoryListItemEventTitle item={item} />}
      </Box>

      <Box direction="row" gap="small" justify="end" role="cell">
        {item.rollback && (
          <Tooltip
            on={(props) => (
              <BadgeNext
                {...props}
                variant="yellow"
                type="strong"
                startIcon={TimeMachine}
                text="Rollback"
              />
            )}
          >
            State rolled back
          </Tooltip>
        )}
      </Box>

      <Typography tag="span" variant="p-body2" role="cell">
        <Timestamp timestamp={item.lastModified} />
      </Typography>

      <Box role="cell">
        <StackStateHistoryRollBackAction
          onRollback={handleRollback}
          isCurrentState={isCurrentState}
          loading={loading}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default StackStateHistoryListItem;
