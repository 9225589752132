import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Mutation, Stack, StackInput } from "types/generated";
import useTypedFlags from "hooks/useTypedFlags";

import { UPDATE_STACK } from "./gql";
import { makeStackUpdateInput } from "./helpers";

type UseUpdateStackProps = {
  stack: Stack;
  refetchQueries?: string[];
};

const useUpdateStack = ({ stack, refetchQueries }: UseUpdateStackProps) => {
  const { showLeakingSensitiveOutputsThroughDependencies } = useTypedFlags();

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updateStack, { loading }] = useMutation<{
    stackUpdate: Mutation["stackUpdate"];
  }>(UPDATE_STACK, {
    onError,
    ...(refetchQueries && {
      refetchQueries,
      awaitRefetchQueries: true,
    }),
  });

  const handleStackUpdate = useCallback(
    (stackChanges: Partial<StackInput>, successCallback?: () => void) => {
      updateStack({
        variables: {
          stackId: stack.id,
          input: makeStackUpdateInput(
            stack,
            stackChanges,
            showLeakingSensitiveOutputsThroughDependencies
          ),
        },
      })
        .then(({ data }) => {
          if (data?.stackUpdate) {
            reportSuccess({ message: "Stack was successfully updated" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, showLeakingSensitiveOutputsThroughDependencies, stack, updateStack]
  );

  return { stackUpdate: handleStackUpdate, loading };
};

export default useUpdateStack;
