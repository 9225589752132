import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

const FullScreenModalFooter = ({
  className,
  children,
  fullWidth = true,
  justify = "end",
  gap = "large",
  padding = "large",
  ...restProps
}: BoxProps) => {
  return (
    <Box
      className={cx(styles.footer, className)}
      fullWidth={fullWidth}
      justify={justify}
      gap={gap}
      padding={padding}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default FullScreenModalFooter;
