import { useState } from "react";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import { AuditTrailEntry } from "types/generated";
import Tab from "ds/components/Tab";
import Box from "ds/components/Box";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import AuditTrailEntriesDetailsBody from "./Body";
import AuditTrailEntriesDetailsMetadata from "./Metadata";

type AuditTrailEntriesDetailsProps = {
  item: AuditTrailEntry;
  onClose: () => void;
};

const AuditTrailEntriesDetails = ({ item, onClose }: AuditTrailEntriesDetailsProps) => {
  const [tab, setTab] = useState<"metadata" | "body">("metadata");

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Log details" />
        <DrawerCloseIcon handleCloseDrawer={onClose} />
      </DrawerHeader>

      <DrawerBody fullHeight>
        <Box gap="medium" margin="0 0 large">
          <Tab
            isActive={tab === "metadata"}
            id="tab-metadata"
            onClick={() => setTab("metadata")}
            label="Metadata"
          />
          <Tab
            isActive={tab === "body"}
            id="tab-body"
            onClick={() => setTab("body")}
            label="Body"
          />
        </Box>

        {tab === "metadata" && (
          <AuditTrailEntriesDetailsMetadata item={item} onApplyFilter={onClose} />
        )}
        {tab === "body" && <AuditTrailEntriesDetailsBody value={item.body} />}

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default AuditTrailEntriesDetails;
