import { isTrackedCommitOutdated } from "shared/Stack/useSyncCommit/accessValidation";
import { Run, RunIgnoredTrigger, RunIgnoredTriggerReasonType, Stack } from "types/generated";

export const getLastIgnoredRunFromStack = (stack: Stack) =>
  stack.searchRunIgnoredTriggers?.edges[0]?.node;

export const shouldShowIgnoredRunCallout = (
  stack: Stack,
  lastIgnoredRun: RunIgnoredTrigger | undefined,
  mostRecentRun?: Run
): lastIgnoredRun is RunIgnoredTrigger => {
  if (!lastIgnoredRun) {
    return false;
  }

  if (isTrackedCommitOutdated(stack)) {
    return true;
  }

  if (!mostRecentRun) {
    return true;
  }

  if (lastIgnoredRun.createdAt < mostRecentRun.createdAt) {
    // last ignored run was later than last run
    return false;
  }

  if (
    lastIgnoredRun.commit.hash === mostRecentRun.commit.hash &&
    lastIgnoredRun.reasonType === RunIgnoredTriggerReasonType.TagsIgnoredByDefault
  ) {
    return true;
  }

  return lastIgnoredRun.commit.hash !== mostRecentRun.commit.hash;
};
