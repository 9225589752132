import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type ApiKeysListItemDropdownProps = {
  handleApiKeyDetailsClick: () => void;
  name: string;
  id: string;
  refetchQueriesOnDelete?: string[];
};

const ApiKeysListItemDropdown = ({
  handleApiKeyDetailsClick,
  refetchQueriesOnDelete,
  name,
  id,
}: ApiKeysListItemDropdownProps) => {
  const onDeleteClick = () => {
    showDeleteConfirmation({ name, id, refetchQueriesOnDelete });
  };

  return (
    <DropdownMenuEllipsis tooltip="API key actions">
      <DropdownMenuItem onAction={handleApiKeyDetailsClick}>Access details</DropdownMenuItem>
      <DropdownMenuItem onAction={onDeleteClick} danger>
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default ApiKeysListItemDropdown;
