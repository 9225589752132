import { ReactNode, createContext, useCallback, useMemo } from "react";

import { SelectOptionOutput } from "./types";
import useGetOutputs from "./useGetOutputs";

type StackDependenciesOutputReferencesContextDataProps = {
  outputsOptions: SelectOptionOutput[];
  loading: boolean;
};

type StackDependenciesOutputReferencesContextApiProps = {
  checkIsOutputExist: (outputName: string) => boolean;
};

export const StackDependenciesOutputReferencesContextData = createContext<
  StackDependenciesOutputReferencesContextDataProps | undefined
>(undefined);
StackDependenciesOutputReferencesContextData.displayName =
  "StackDependenciesOutputReferencesContextData";

export const StackDependenciesOutputReferencesContextApi = createContext<
  StackDependenciesOutputReferencesContextApiProps | undefined
>(undefined);
StackDependenciesOutputReferencesContextApi.displayName =
  "StackDependenciesOutputReferencesContextApi";

type StackDependenciesOutputReferencesContextProviderProps = {
  children: ReactNode;
  stackIdSourceOfOutputs?: string;
};

const StackDependenciesOutputReferencesContextProvider = ({
  children,
  stackIdSourceOfOutputs,
}: StackDependenciesOutputReferencesContextProviderProps) => {
  const { outputsOptions, loading } = useGetOutputs(stackIdSourceOfOutputs);

  const checkIsOutputExist = useCallback(
    (outputName: string) => {
      return outputsOptions.some((option) => option.value === outputName);
    },
    [outputsOptions]
  );

  const dataStore = useMemo(() => ({ outputsOptions, loading }), [outputsOptions, loading]);
  const apiStore = useMemo(() => ({ checkIsOutputExist }), [checkIsOutputExist]);

  return (
    <StackDependenciesOutputReferencesContextData.Provider value={dataStore}>
      <StackDependenciesOutputReferencesContextApi.Provider value={apiStore}>
        {children}
      </StackDependenciesOutputReferencesContextApi.Provider>
    </StackDependenciesOutputReferencesContextData.Provider>
  );
};

export default StackDependenciesOutputReferencesContextProvider;
