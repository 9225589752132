import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { SortOption } from "components/Filters/types";
import { WorkerPool } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { useCachedFilterFields } from "components/Filters/hooks";

import { SEARCH_PRIVATE_WORKER_POOL_USED_BY_SUGGESTIONS } from "./gql";

type UseSearchPrivateWorkerPoolsSuggestions = {
  workerPoolId: string;
};

const useSearchPrivateWorkerPoolUsedBySuggestions = ({
  workerPoolId,
}: UseSearchPrivateWorkerPoolsSuggestions) => {
  const { onError } = useTypedContext(FlashContext);

  const { data } = useQuery<{
    workerPool: WorkerPool;
  }>(SEARCH_PRIVATE_WORKER_POOL_USED_BY_SUGGESTIONS, {
    variables: {
      workerPoolId,
      input: {
        fullTextSearch: null,
        predicates: null,
        fields: null,
      },
    },
    onError,
  });

  const cachedFiltersData = useCachedFilterFields(data?.workerPool?.searchUsersSuggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return cachedFiltersData
      .filter((field) => field.orderable)
      .map((field) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
  }, [cachedFiltersData]);

  return {
    sortOptions,
  };
};

export default useSearchPrivateWorkerPoolUsedBySuggestions;
