import { gql } from "@apollo/client";

export const GET_EDIT_WEBHOOK = gql`
  query GetEditWebhook($id: ID!) {
    namedWebhooksIntegration(id: $id) {
      id
      space {
        id
        name
        accessLevel
      }

      enabled
      endpoint
      name
      labels
      secret
      secretHeaders
    }
  }
`;
