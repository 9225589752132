import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import useDisableModule from "shared/Module/useDisableModule";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type DisableButtonProps = {
  moduleId: Module["id"];
  refetchQueries?: string[];
  callback?: () => void;
};

const DisableButton = ({ moduleId, callback, refetchQueries }: DisableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [disableModule, { loading }] = useDisableModule({
    refetchQueries,
  });

  const handleClick = () => {
    disableModule({ id: moduleId }, (data) => {
      if (data?.moduleDisable) {
        reportSuccess({ message: "Module successfully disabled" });
        callback?.();
      }
    });
  };

  return (
    <DropdownMenuItem onAction={handleClick} loading={loading} isDisabled={loading}>
      Disable
    </DropdownMenuItem>
  );
};

export default DisableButton;
