import { Arrow, File } from "components/icons";

import { createUrl, toBlob } from "../helpers";
import styles from "../styles.module.css";

type DownloadFileProps = {
  fileName: string;
  base64Value: string;
};

const DownloadFile = ({ fileName, base64Value }: DownloadFileProps) => (
  <a className={styles.variableDownload} href={createUrl(toBlob(base64Value))} download={fileName}>
    <File />
    <span className={styles.variableIcon}>
      <Arrow />
    </span>
  </a>
);

export default DownloadFile;
