import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";
import { BulkActionsVariant } from "components/BulkActions/types";
import BulkActionsConfirmActionStep from "components/BulkActions/ConfirmActionStep";

import { ModuleBulkActionsAnalyticsPayload, ModuleBulkActionsSelectedAction } from "./types";

type ModulesBulkActionsConfirmActionFormProps = {
  action: ModuleBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (analyticsPayload: ModuleBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
};

const ModulesBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
}: ModulesBulkActionsConfirmActionFormProps) => {
  return (
    <BulkActionsConfirmActionStep>
      <BulkActionsConfirmActionStepInlineConfirmation
        variant={variant}
        actionLabel={action.title}
        onConfirm={() =>
          onConfirm({
            view: variant,
            metadata: {},
          })
        }
        onCancel={onCancel}
      />
    </BulkActionsConfirmActionStep>
  );
};

export default ModulesBulkActionsConfirmActionForm;
