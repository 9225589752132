import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type CardWrapperProps = BoxProps & {
  variant: "filled" | "outlined";
};

const CardWrapper = ({
  className,
  children,
  direction,
  margin,
  variant,
  gap,
  ...restProps
}: CardWrapperProps) => {
  const elementClass = cx(styles.wrapper, styles[camelCase(variant)], className);

  return (
    <Box className={elementClass} direction={direction} margin={margin} gap={gap} {...restProps}>
      {children}
    </Box>
  );
};

export default CardWrapper;
