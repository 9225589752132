import { Spacing } from "types/Spacing";

import useFiltersSortHeader from "./useFiltersSortHeader";
import FiltersSortHeaderSortableColumn from "./SortableColumn";
import FiltersSortHeaderWrapper from "./Wrapper";

type SortHeaderProps = {
  columnOrder?: string;
  columnGap?: Spacing;
};

const FiltersSortHeader = ({
  columnOrder = "4fr 1fr 1fr 1fr",
  columnGap = "0",
}: SortHeaderProps) => {
  const { sortOptions, querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={columnOrder} columnGap={columnGap}>
      {sortOptions &&
        sortOptions.length > 0 &&
        sortOptions.map((option) => (
          <FiltersSortHeaderSortableColumn
            key={option.value}
            option={option}
            querySortOption={querySortOption}
            querySortDirection={querySortDirection}
            onClick={onItemClick}
          />
        ))}
    </FiltersSortHeaderWrapper>
  );
};

export default FiltersSortHeader;
