import { FilterOption } from "./types";

export const generateFilterButtonText = (
  filterKeyOptions: FilterOption | undefined,
  filterValues: FilterOption[][],
  index: number
) => {
  const key = filterKeyOptions ? `${filterKeyOptions.label} : ` : "+ Add";
  const value =
    filterValues[index]?.length > 0
      ? filterValues[index].map((item) => item.label).join(", ")
      : " filter";
  return `${key}${value}`;
};
