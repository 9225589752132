import { ReactNode } from "react";

import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import { useToggle } from "hooks/useToggle";
import Counter from "ds/components/Counter";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import { BoxProps } from "ds/components/Box";
import { CollapsiblePanelAriaLevel } from "components/CollapsiblePanel/types";

import styles from "./styles.module.css";

type CollapsibleSectionPanelProps = {
  title: string;
  count?: number;
  initialIsCollapsed?: boolean;
  children?: ReactNode;
  contentPadding?: BoxProps["padding"];
  contentGap?: BoxProps["gap"];
  ariaLevel: CollapsiblePanelAriaLevel;
};

const CollapsibleSectionPanel = ({
  title,
  count,
  initialIsCollapsed = true,
  children,
  contentPadding = "0 x-large x-large",
  contentGap = "medium",
  ariaLevel,
}: CollapsibleSectionPanelProps) => {
  const [isCollapsed, toggle] = useToggle(initialIsCollapsed);

  return (
    <CollapsiblePanel variant="section" isCollapsed={isCollapsed} onToggle={toggle} withTransition>
      <CollapsiblePanelHeader padding="x-large" ariaLevel={ariaLevel}>
        <CollapsiblePanelToggleTrigger gap="medium" align="center">
          <CollapsiblePanelToggleIndicator />
          <CollapsiblePanelTitle>{title}</CollapsiblePanelTitle>
          {typeof count !== "undefined" && <Counter count={count} />}
        </CollapsiblePanelToggleTrigger>
      </CollapsiblePanelHeader>

      <CollapsiblePanelContent
        className={styles.content}
        padding={contentPadding}
        gap={contentGap}
        fullWidth
      >
        {children}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default CollapsibleSectionPanel;
