import { Line, LinePath } from "@visx/shape";

import {
  ITEM_SIZE,
  MIDDLE_GROUP_BORDER_SPACE,
  MIDDLE_GROUP_BORDER_RADIUS,
  ITEM_SPACE,
} from "./contstants";
import styles from "./styles.module.css";

type TreeChartGroupNodeRectMiddleGroupProps = {
  nextTaskHasTheSameParent?: boolean;
  prevTaskHasTheSameParent?: boolean;
  x: number;
  y: number;
};

const TreeChartGroupNodeRectMiddleGroup = ({
  nextTaskHasTheSameParent,
  prevTaskHasTheSameParent,
  x,
  y,
}: TreeChartGroupNodeRectMiddleGroupProps) => {
  // First
  if (!prevTaskHasTheSameParent && nextTaskHasTheSameParent) {
    return (
      <LinePath
        className={styles.border}
        d={`M ${x + ITEM_SIZE + ITEM_SPACE} ${y + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE} L ${x + MIDDLE_GROUP_BORDER_RADIUS - MIDDLE_GROUP_BORDER_SPACE} ${y + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 -${MIDDLE_GROUP_BORDER_RADIUS} -${MIDDLE_GROUP_BORDER_RADIUS} L ${x - MIDDLE_GROUP_BORDER_SPACE} ${y - MIDDLE_GROUP_BORDER_SPACE + MIDDLE_GROUP_BORDER_RADIUS} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 ${MIDDLE_GROUP_BORDER_RADIUS} -${MIDDLE_GROUP_BORDER_RADIUS} L ${x + ITEM_SIZE + ITEM_SPACE} ${y - MIDDLE_GROUP_BORDER_SPACE}`}
      />
    );
  }

  // Middle
  if (prevTaskHasTheSameParent && nextTaskHasTheSameParent) {
    return (
      <>
        <Line
          className={styles.border}
          x1={x - ITEM_SPACE}
          x2={x + ITEM_SPACE + ITEM_SIZE}
          y1={y - MIDDLE_GROUP_BORDER_SPACE}
          y2={y - MIDDLE_GROUP_BORDER_SPACE}
        />
        <Line
          className={styles.border}
          x1={x - ITEM_SPACE}
          x2={x + ITEM_SPACE + ITEM_SIZE}
          y1={y + MIDDLE_GROUP_BORDER_SPACE + ITEM_SIZE}
          y2={y + MIDDLE_GROUP_BORDER_SPACE + ITEM_SIZE}
        />
      </>
    );
  }

  // Last
  if (prevTaskHasTheSameParent && !nextTaskHasTheSameParent) {
    return (
      <LinePath
        className={styles.border}
        d={`M ${x - ITEM_SPACE} ${y - MIDDLE_GROUP_BORDER_SPACE} L ${x + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE - MIDDLE_GROUP_BORDER_RADIUS} ${y - MIDDLE_GROUP_BORDER_SPACE} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 ${MIDDLE_GROUP_BORDER_RADIUS} ${MIDDLE_GROUP_BORDER_RADIUS} L ${x + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE} ${y - MIDDLE_GROUP_BORDER_RADIUS + MIDDLE_GROUP_BORDER_SPACE + ITEM_SIZE} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 -${MIDDLE_GROUP_BORDER_RADIUS} ${MIDDLE_GROUP_BORDER_RADIUS} L ${x - ITEM_SPACE} ${y + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE}`}
      />
    );
  }

  // Single
  if (!prevTaskHasTheSameParent && !nextTaskHasTheSameParent) {
    return (
      <LinePath
        className={styles.border}
        d={`M ${x + MIDDLE_GROUP_BORDER_RADIUS - MIDDLE_GROUP_BORDER_SPACE} ${y - MIDDLE_GROUP_BORDER_SPACE} L ${x + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE - MIDDLE_GROUP_BORDER_RADIUS} ${y - MIDDLE_GROUP_BORDER_SPACE} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 ${MIDDLE_GROUP_BORDER_RADIUS} ${MIDDLE_GROUP_BORDER_RADIUS} L ${x + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE} ${y - MIDDLE_GROUP_BORDER_RADIUS + MIDDLE_GROUP_BORDER_SPACE + ITEM_SIZE} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 -${MIDDLE_GROUP_BORDER_RADIUS} ${MIDDLE_GROUP_BORDER_RADIUS} L ${x + MIDDLE_GROUP_BORDER_RADIUS - MIDDLE_GROUP_BORDER_SPACE} ${y + ITEM_SIZE + MIDDLE_GROUP_BORDER_SPACE} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 -${MIDDLE_GROUP_BORDER_RADIUS} -${MIDDLE_GROUP_BORDER_RADIUS} L ${x - MIDDLE_GROUP_BORDER_SPACE} ${y - MIDDLE_GROUP_BORDER_SPACE + MIDDLE_GROUP_BORDER_RADIUS} a${MIDDLE_GROUP_BORDER_RADIUS},${MIDDLE_GROUP_BORDER_RADIUS} 0 0 1 ${MIDDLE_GROUP_BORDER_RADIUS} -${MIDDLE_GROUP_BORDER_RADIUS}`}
      />
    );
  }

  return null;
};

export default TreeChartGroupNodeRectMiddleGroup;
