import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Feedback from "ds/components/Feedback";
import Typography from "ds/components/Typography";

type StackStateHistoryRollBackConfirmationModalProps = {
  onConfirm: () => void;
};

const StackStateHistoryRollBackConfirmationModal = create(
  function StackStateHistoryRollBackConfirmationModal({
    onConfirm,
  }: StackStateHistoryRollBackConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      onConfirm();
      modal.hide();
    };

    return (
      <DeleteConfirmationModal
        title="Roll back state version"
        confirmationButtonLabel="Roll back"
        onConfirm={handleOnConfirm}
      >
        <ConfirmationModalMessage>
          Are you sure you want to restore this version?{" "}
        </ConfirmationModalMessage>

        <Feedback type="banner" variant="warning">
          Please bear in mind that rolling back the state will not change any bit of your current
          infrastructure. This should be used as a break-glass operation,{" "}
          <Typography tag="span" variant="p-t7">
            and you MUST know exactly what are you doing before proceeding.
          </Typography>
        </Feedback>
      </DeleteConfirmationModal>
    );
  }
);

export const showStackStateHistoryRollBackConfirmationModal = (
  props: StackStateHistoryRollBackConfirmationModalProps
) => show(StackStateHistoryRollBackConfirmationModal, props);
