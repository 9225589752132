import React, { forwardRef } from "react";

import Button, { ButtonProps } from "components/button/Button";

import styles from "./styles.module.css";

type FormButtonProps = ButtonProps & {
  children: React.ReactNode;
};

const FormButton = forwardRef<HTMLButtonElement, FormButtonProps>(function FormButton(
  props: FormButtonProps,
  ref
) {
  return (
    <Button className={styles.button} ref={ref} {...props}>
      {props.children}
    </Button>
  );
});

export default FormButton;
