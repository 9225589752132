import { ReactNode, useCallback, useState } from "react";

import Typography from "ds/components/Typography";
import { useToggle } from "hooks/useToggle";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Link from "ds/components/Link";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import CollapsiblePanelClickableHeader from "components/CollapsiblePanel/ClickableHeader";

import styles from "./styles.module.css";

type LaunchPadFAQItemProps = {
  title: string;
  children: ReactNode;
  learnMoreContent?: ReactNode;
  learnMoreTitle?: string;
};

const LaunchPadFAQItem = ({
  title,
  children,
  learnMoreContent,
  learnMoreTitle,
}: LaunchPadFAQItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,
  });

  const [isCollapsed, onToggle] = useToggle(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const handleToggle = useCallback(() => {
    if (isCollapsed) {
      trackSegmentAnalyticsEvent("FAQ Question Click", { title });
    }

    onToggle();
  }, [isCollapsed, onToggle, title, trackSegmentAnalyticsEvent]);

  return (
    <>
      {learnMoreContent && learnMoreTitle && (
        <Drawer visible={isDrawerVisible} onOutsideClick={() => setIsDrawerVisible(false)}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title={learnMoreTitle} />
            <DrawerCloseIcon handleCloseDrawer={() => setIsDrawerVisible(false)} />
          </DrawerHeader>
          <DrawerBody fullHeight hasStickyFooter>
            {learnMoreContent}
          </DrawerBody>
        </Drawer>
      )}
      <CollapsiblePanel isCollapsed={isCollapsed} onToggle={handleToggle} withTransition>
        <CollapsiblePanelClickableHeader padding="large" gap="medium" ariaLevel={3}>
          <CollapsiblePanelToggleIndicator />
          <CollapsiblePanelTitle>{title}</CollapsiblePanelTitle>
        </CollapsiblePanelClickableHeader>
        <CollapsiblePanelContent className={styles.faqItemContent}>
          <Typography tag="p" variant="p-body3">
            {children}{" "}
            {learnMoreContent && (
              <Link size="small" onClick={() => setIsDrawerVisible(true)}>
                Learn More
              </Link>
            )}
          </Typography>
        </CollapsiblePanelContent>
      </CollapsiblePanel>
    </>
  );
};

export default LaunchPadFAQItem;
