import { useState } from "react";
import { useQuery } from "@apollo/client";

import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AuthorizationScheme, Space } from "types/generated";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import UsersInviteWrapper from "views/Account/Settings/Users/InviteWrapper";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import type { PersonalSettingsAuthorizationScheme } from "../types";
import PersonalSettingsSpacesPageLayout from "./PageLayout";
import PersonalSettingsSpacesSortHeader from "./SortHeader";
import SpacesList from "./List";
import { GET_PERSONAL_SPACES } from "./gql";
import PersonalSettingsInviteForm from "../InviteForm";
import SpacesRoleChangeDrawer from "./RoleChangeDrawer";
import PersonalSettingsSpacesEmpty from "./Empty";

const PersonalSettingsSpaces = ({ authorizationScheme }: PersonalSettingsAuthorizationScheme) => {
  const { onError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);
  const [isRoleChangeDrawerVisible, setRoleChangeDrawerVisible] = useState(false);
  const [currentSpace, setCurrentSpace] = useState<Space | undefined>(undefined);

  const userManagementActive = authorizationScheme === AuthorizationScheme.ManagedUsers;

  const { error, loading, data, stopPolling } = useQuery<{ spaces: Space[] }>(GET_PERSONAL_SPACES, {
    onError,
  });

  const trackAnalyticsSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalSpaces,
  });

  const handleRequestRoleChange = (space: Space) => {
    trackAnalyticsSegmentEvent("Request Role Change Click");
    setRoleChangeDrawerVisible(true);
    setCurrentSpace(space);
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (loading && !data?.spaces) {
    return (
      <PersonalSettingsSpacesPageLayout>
        <PageLayoutSkeleton />
      </PersonalSettingsSpacesPageLayout>
    );
  }

  if (!data?.spaces) {
    return <NotFoundPage />;
  }

  return (
    <PersonalSettingsSpacesPageLayout>
      <SpacesRoleChangeDrawer
        isDrawerVisible={isRoleChangeDrawerVisible}
        setDrawerVisibility={setRoleChangeDrawerVisible}
        space={currentSpace}
      />
      {!viewer.admin && userManagementActive && (
        <UsersInviteWrapper>
          <PersonalSettingsInviteForm analyticsPage={AnalyticsPagePersonal.PersonalSpaces} />
        </UsersInviteWrapper>
      )}
      {data.spaces.length === 0 && <PersonalSettingsSpacesEmpty />}
      {data.spaces.length > 0 && (
        <>
          <PersonalSettingsSpacesSortHeader />
          <SpacesList spaces={data.spaces} handleRequestRoleChange={handleRequestRoleChange} />
        </>
      )}
    </PersonalSettingsSpacesPageLayout>
  );
};

export default PersonalSettingsSpaces;
