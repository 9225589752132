import { useCallback, useState } from "react";
import { isEqual } from "lodash-es";
import { useNavigate } from "react-router-dom-v5-compat";

import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import useURLParams from "hooks/useURLParams";
import FullScreenModal from "ds/components/FullScreenModal";
import NotFoundPage from "components/error/NotFoundPage";
import WarningContextProvider from "components/WarningContext/Provider";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import {
  InternalFormData,
  StackCreationWizardFormStep,
  StackCreationWizardStep,
  StackFormFields,
} from "./types";
import { StackFormContext } from "./context";
import NewStackStepper from "./Stepper";
import NewStackDetails from "./Details";
import NewStackVendor from "./Vendor";
import NewStackBehaviour from "./Behaviour";
import NewStackVcs from "./Vcs";
import NewStackAddHooks from "./AddHooks";
import NewStackSummary from "./Summary";
import NewStackAttachContext from "./AttachContext";
import { availableSteps } from "./constants";
import NewStackAttachPolicies from "./AttachPolicies";
import NewStackAttachCloud from "./AttachCloud";
import NewStackIntermediateConfirmation from "./IntermediateConfirmation";
import useInitialValues from "./useInitialValues";
import { SpacesContext } from "../SpacesProvider";

type NewStackProps = {
  initialStep?: StackCreationWizardStep;
  initialStackId?: string;
};

const NewStack = ({
  initialStep = StackCreationWizardStep.Details,
  initialStackId = "",
}: NewStackProps) => {
  const navigate = useNavigate();
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const [AccountOrAuthErrorContent, setAccountOrAuthErrorContent] = useState<
    JSX.Element | undefined
  >();

  const { accountName } = useTypedContext(AccountContext);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [asyncValidationLoading, setAsyncValidationLoading] = useState(false);
  const [stepperNavDisabled, setStepperNavDisabled] = useState(false);
  const [isIntermediateStepActive, setIsIntermediateStepActive] = useState(false);
  const [createdStackId, setCreatedStackId] = useState(initialStackId);

  const currentStepIndex =
    currentStep === StackCreationWizardStep.Summary
      ? availableSteps.length
      : availableSteps.findIndex((step) => step.step === currentStep);

  const [internalData, setInternalFormData] = useState<InternalFormData>({});
  const urlParams = useURLParams();

  const initialValues = useInitialValues(urlParams);

  const [formData, setFormData] = useState<StackFormFields>(initialValues);

  const goToSummary = useCallback(() => {
    setCurrentStep(StackCreationWizardStep.Summary);
  }, []);

  const goToNextStep = useCallback(() => {
    const nextStep =
      availableSteps.find((_step, index) => index > currentStepIndex)?.step ??
      StackCreationWizardStep.Summary;

    setCurrentStep(nextStep);
  }, [currentStepIndex]);

  const goToPreviousStep = useCallback(() => {
    const previousStep =
      availableSteps.findLast((_step, index) => index < currentStepIndex)?.step ??
      StackCreationWizardStep.Details;

    setCurrentStep(previousStep);
  }, [currentStepIndex]);

  const updateStepData = useCallback(
    (step: StackCreationWizardStep, data: StackFormFields[StackCreationWizardFormStep]) => {
      const newFormData = { ...formData, [step]: data };
      setFormData(newFormData);
      return newFormData;
    },
    [formData]
  );

  useTitle(`New stack · ${accountName}`);

  const isInitialDataChanged = !isEqual(formData, initialValues);

  const modalCloseClickHandler = useCallback(async () => {
    if (isInitialDataChanged) {
      await showSimpleLeaveConfirmation({
        title: "Do you want to leave stack creation process?",
        message: createdStackId
          ? "Some of your changes may not be saved."
          : "Your changes will not be saved.",
      });
    }

    if (createdStackId) {
      navigate(`/stack/${createdStackId}`, { replace: true });
    } else {
      navigate("/");
    }
  }, [isInitialDataChanged, createdStackId, navigate]);

  if (AccountOrAuthErrorContent) {
    return AccountOrAuthErrorContent;
  }

  if (!hasEntityCreateAccess) {
    return <NotFoundPage />;
  }

  return (
    <StackFormContext.Provider
      value={{
        availableSteps,
        internalData,
        setInternalFormData,
        goToSummary,
        currentStep,
        currentStepIndex,
        setCurrentStep,
        formData,
        setFormData,
        updateStepData,
        goToPreviousStep,
        goToNextStep,
        asyncValidationLoading,
        setAsyncValidationLoading,
        stepperNavDisabled,
        setStepperNavDisabled,
        isIntermediateStepActive,
        setIsIntermediateStepActive,
        setCreatedStackId,
        createdStackId,
        setAccountOrAuthErrorContent,
      }}
    >
      <FullScreenModal title="Create stack" onClose={modalCloseClickHandler}>
        <WarningContextProvider>
          <Box grow="1" fullWidth scrollable>
            <NewStackStepper />
            <Box fullWidth direction="column" relative>
              {isIntermediateStepActive && <NewStackIntermediateConfirmation />}
              {!isIntermediateStepActive && (
                <>
                  {currentStep === StackCreationWizardStep.Details && <NewStackDetails />}
                  {currentStep === StackCreationWizardStep.Vcs && <NewStackVcs />}
                  {currentStep === StackCreationWizardStep.Vendor && <NewStackVendor />}
                  {currentStep === StackCreationWizardStep.Behaviour && <NewStackBehaviour />}
                  {currentStep === StackCreationWizardStep.Hooks && <NewStackAddHooks />}
                  {currentStep === StackCreationWizardStep.Cloud && <NewStackAttachCloud />}
                  {currentStep === StackCreationWizardStep.AttachContext && (
                    <NewStackAttachContext />
                  )}
                  {currentStep === StackCreationWizardStep.Policies && <NewStackAttachPolicies />}
                  {currentStep === StackCreationWizardStep.Summary && <NewStackSummary />}
                </>
              )}
            </Box>
          </Box>
        </WarningContextProvider>
      </FullScreenModal>
    </StackFormContext.Provider>
  );
};

export default NewStack;
