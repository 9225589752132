import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";
import { BulkActionsVariant } from "components/BulkActions/types";
import BulkActionsConfirmActionStep from "components/BulkActions/ConfirmActionStep";

import {
  NotificationsBulkActionsAnalyticsPayload,
  NotificationsBulkActionsSelectedAction,
} from "./types";

type NotificationsBulkActionsConfirmActionFormProps = {
  action: NotificationsBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (analyticsPayload: NotificationsBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
  loading: boolean;
};

const NotificationsBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
  loading,
}: NotificationsBulkActionsConfirmActionFormProps) => {
  return (
    <BulkActionsConfirmActionStep>
      <BulkActionsConfirmActionStepInlineConfirmation
        variant={variant}
        actionLabel={action.title}
        confirmDisabled={loading}
        onConfirm={() =>
          onConfirm({
            view: variant,
          })
        }
        onCancel={onCancel}
      />
    </BulkActionsConfirmActionStep>
  );
};

export default NotificationsBulkActionsConfirmActionForm;
