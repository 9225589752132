import { useState } from "react";
import { useQuery } from "@apollo/client";

import { AuthorizationScheme, ManagedUserGroupIntegration } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import useTitle from "hooks/useTitle";

import { UserManagementActivationStatus } from "../types";
import { GET_MANAGED_USER_GROUP_INTEGRATIONS } from "./gql";
import ManageSlackAccessDetailsDrawer from "./DetailsDrawer";
import ManageSlackAccessList from "./List";
import ManageSlackAccessHeader from "./SortHeader";
import ManageSlackAccessEmpty from "./Empty";
import ManageSlackAccessPageLayout from "./PageLayout";

type GetManagedUserGroupIntegrationsGql = {
  authorizationScheme: AuthorizationScheme;
  managedUserGroupIntegrations: ManagedUserGroupIntegration[];
};

const ManageSlackAccess = () => {
  const [isManageSlackAccessDetailsDrawerVisible, setManageSlackAccessDetailsDrawerVisibility] =
    useState(false);
  const [slackAccessGroup, setSlackAccessGroup] = useState<ManagedUserGroupIntegration | undefined>(
    undefined
  );

  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery<GetManagedUserGroupIntegrationsGql>(
    GET_MANAGED_USER_GROUP_INTEGRATIONS,
    {
      onError,
    }
  );

  useTitle(`Organization Settings · Integrations · Manage Slack Access · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.managedUserGroupIntegrations) {
    return <PageLoading />;
  }

  if (!data?.managedUserGroupIntegrations) {
    return <NotFoundPage />;
  }

  const handleGroupIntegrationDetailsClick = (details: ManagedUserGroupIntegration) => {
    setSlackAccessGroup(details);
    setManageSlackAccessDetailsDrawerVisibility(true);
  };

  const activationStatus =
    data?.authorizationScheme === AuthorizationScheme.ManagedUsers
      ? UserManagementActivationStatus.ACTIVE
      : UserManagementActivationStatus.INACTIVE;

  return (
    <ManageSlackAccessPageLayout activationStatus={activationStatus}>
      {slackAccessGroup && (
        <ManageSlackAccessDetailsDrawer
          activationStatus={activationStatus}
          isDrawerVisible={isManageSlackAccessDetailsDrawerVisible}
          setDrawerVisibility={setManageSlackAccessDetailsDrawerVisibility}
          currentIntegration={slackAccessGroup}
        />
      )}
      <ManageSlackAccessHeader />

      {data.managedUserGroupIntegrations.length === 0 && <ManageSlackAccessEmpty />}
      {data.managedUserGroupIntegrations.length > 0 && (
        <ManageSlackAccessList
          integrations={data.managedUserGroupIntegrations}
          handleGroupIntegrationClick={handleGroupIntegrationDetailsClick}
          activationStatus={activationStatus}
        />
      )}
    </ManageSlackAccessPageLayout>
  );
};

export default ManageSlackAccess;
