import { gql } from "@apollo/client";

export const GET_MANAGED_USERS = gql`
  query GetManagedUsers {
    managedUsers {
      id
      groupCount
      invitationEmail
      lastLoginTime
      loginMethod
      spaceCount
      status
      username
      role
    }
    managedUserGroups {
      identityProvider
      groupName
    }
  }
`;

export const GET_MANAGED_USER = gql`
  query GetManagedUser($id: ID!) {
    managedUser(id: $id) {
      id
      accessRules {
        space
        spaceAccessLevel
      }
      groupCount
      invitationEmail
      latestGroups
      lastLoginTime
      loginMethod
      role
      spaceCount
      status
      username
      slackMemberID
    }
  }
`;

export const INVITE_MANAGED_USER = gql`
  mutation InviteManagedUser($input: ManagedUserInviteInput!) {
    managedUserInvite(input: $input) {
      status
    }
  }
`;

export const RESEND_MANAGED_USER_INVITE = gql`
  mutation resendManagedUserInvite($id: ID!) {
    managedUserResendInvite(id: $id) {
      id
    }
  }
`;

export const UPDATE_MANAGED_USER = gql`
  mutation UpdateManagedUser($input: ManagedUserUpdateInput!) {
    managedUserUpdate(input: $input) {
      id
      status
    }
  }
`;

export const DELETE_MANAGED_USER = gql`
  mutation DeleteManagedUser($id: ID!) {
    managedUserDelete(id: $id) {
      id
    }
  }
`;
