import Box from "ds/components/Box";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import PerformTaskAgainButton from "../../components/PerformTaskAgainButton";
import PromoteRunButton from "../../components/PromoteRunButton";
import RetryRunButton from "../../components/RetryRunButton";
import { RunHistoryEntryType } from "../../types";

type FinishedEntryProps = {
  timestamp: number;
  canPerformAgain: boolean;
  runTaskCommand: string | null;
  canPromote: boolean;
  runPromoteBlocker: string | null;
  isProposedRun: boolean;
  stackId: string;
  runId: string;
  canRetry: boolean;
  runRetryBlocker: string | null;
};

const FinishedEntry = ({
  timestamp,
  canPerformAgain,
  runTaskCommand,
  stackId,
  canPromote,
  runPromoteBlocker,
  runId,
  isProposedRun,
  canRetry,
  runRetryBlocker,
}: FinishedEntryProps) => {
  const canShowPromoteButton = canPromote || (isProposedRun && runPromoteBlocker);
  const canShowRetryButton = isProposedRun && (canRetry || runRetryBlocker);
  return (
    <HistoryEntryWrapper state={RunHistoryEntryType.Finished} timestamp={timestamp} terminal>
      <Box gap="medium">
        {canPerformAgain && <PerformTaskAgainButton command={runTaskCommand} stackId={stackId} />}
        {canShowPromoteButton && (
          <PromoteRunButton stackId={stackId} runId={runId} promoteBlocker={runPromoteBlocker} />
        )}
        {canShowRetryButton && (
          <RetryRunButton runId={runId} stackId={stackId} runRetryBlocker={runRetryBlocker} />
        )}
      </Box>
    </HistoryEntryWrapper>
  );
};

export default FinishedEntry;
