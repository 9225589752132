import { gql } from "@apollo/client";

import { vcsIntegrationDetailsFragment } from "../gql";

export const SEARCH_VCS_INTEGRATION_USED_BY = gql`
  ${vcsIntegrationDetailsFragment}
  query SearchVcsIntegrationUsedBy(
    $id: ID!
    $provider: VCSProvider!
    $searchUsersInput: SearchInput!
    $searchUsersSuggestionsInput: SearchSuggestionsInput!
  ) {
    vcsIntegration(id: $id, provider: $provider) {
      id
      name
      labels
      description
      provider
      updatedAt
      isDefault
      space {
        id
        name
        accessLevel
      }
      ...vcsIntegrationDetails
      searchUsers(input: $searchUsersInput) {
        edges {
          cursor
          node {
            stackName
            stackId
            isModule
            spaceDetails {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      searchUsersSuggestions(input: $searchUsersSuggestionsInput) {
        filteredCount
      }
    }
  }
`;
