import { NetworkStatus, gql, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { Policy, PolicyType, StackPolicyAttachment } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AUTO_ATTACHMENT_KEY } from "constants/labels";

import { ModuleFormContext } from "../../context";
import { ModuleCreationWizardStep } from "../../types";
import {
  AttachablePolicy,
  AttachedPolicy,
  AutoAttachedPolicy,
  ManuallyAttachablePolicyType,
} from "../types";

export const GET_POLICIES_WITHIN_SPACES = gql`
  query GetPoliciesWithinSpaces($spaceId: ID!) {
    attachablePoliciesInSpace(id: $spaceId) {
      id
      name
      type
      body
      labels
    }
  }
`;

export const GET_ATTACHED_MODULE_POLICIES = gql`
  query GetAttachedModulePolicies($moduleId: ID!) {
    module(id: $moduleId) {
      attachedPolicies {
        id
        policyId
        isAutoattached
      }
    }
  }
`;

type PolicyInSpace = Pick<Policy, "id" | "name" | "type" | "labels" | "body">;

type PoliciesWithinSpacesResponse = {
  attachablePoliciesInSpace: PolicyInSpace[];
};

type AttachedPoliciesResponse = {
  module: {
    attachedPolicies: StackPolicyAttachment[];
  };
};

const isManuallyAttachablePolicyType = (type: PolicyType): type is ManuallyAttachablePolicyType =>
  type === PolicyType.GitPush ||
  type === PolicyType.Approval ||
  type === PolicyType.Plan ||
  type === PolicyType.Trigger;

const isManuallyAttachedPolicyType = (policy: PolicyInSpace): policy is AttachedPolicy =>
  isManuallyAttachablePolicyType(policy.type);

export const useAttachedPolicies = () => {
  const { createdModuleId, formData } = useTypedContext(ModuleFormContext);
  const { onError } = useTypedContext(FlashContext);

  const { labels } = formData[ModuleCreationWizardStep.Details];
  const { space } = formData[ModuleCreationWizardStep.Vcs];
  const labelValues = useMemo(() => labels.map(({ value }) => value), [labels]);

  const {
    data: attachedData,
    loading: attachedLoading,
    networkStatus: attachedNetworkStatus,
    refetch: refetchAttached,
    error: attachedError,
  } = useQuery<AttachedPoliciesResponse>(GET_ATTACHED_MODULE_POLICIES, {
    onError,
    fetchPolicy: "no-cache",
    variables: {
      moduleId: createdModuleId,
    },
  });

  const {
    data: attachableData,
    loading: attachableLoading,
    networkStatus: attachableNetworkStatus,
    refetch: refetchAttachable,
    error: attachableError,
  } = useQuery<PoliciesWithinSpacesResponse>(GET_POLICIES_WITHIN_SPACES, {
    onError,
    variables: {
      spaceId: space,
    },
  });

  const [
    attachablePolicies,
    attachedPolicies,
    autoAttachedPolicies,
    manuallyAttachablePolicyTypes,
  ] = useMemo(() => {
    const availablePolicies = attachableData?.attachablePoliciesInSpace || [];
    const attachedPolicies = attachedData?.module?.attachedPolicies.filter(
      ({ isAutoattached }) => !isAutoattached
    );
    const attachedPolicyIds = attachedPolicies?.map((attachedPolicy) => attachedPolicy.policyId);

    const attachablePoliciesResults: AttachablePolicy[] = [];
    const attachedPoliciesResults: AttachedPolicy[] = [];
    const autoAttachedPoliciesResults: AutoAttachedPolicy[] = [];
    const manuallyAttachablePolicyTypes: ManuallyAttachablePolicyType[] = [];

    for (const policy of availablePolicies) {
      const autoAttachableLabel = policy.labels.find(
        (value) =>
          value === `${AUTO_ATTACHMENT_KEY}*` ||
          (value.startsWith(AUTO_ATTACHMENT_KEY) &&
            labelValues.includes(value.replace(AUTO_ATTACHMENT_KEY, "")))
      );

      if (attachedPolicyIds?.includes(policy.id) && isManuallyAttachedPolicyType(policy)) {
        const attachedPolicy = attachedPolicies?.find(
          (attachedPolicy) => attachedPolicy.policyId === policy.id
        );
        if (attachedPolicy) {
          attachedPoliciesResults.push({ ...policy, attachmentId: attachedPolicy.id });
        }
      } else if (isManuallyAttachablePolicyType(policy.type)) {
        if (autoAttachableLabel) {
          autoAttachedPoliciesResults.push({ ...policy, autoAttachableLabel });
        }

        attachablePoliciesResults.push({
          value: policy.id,
          label: policy.name,
          type: policy.type,
          body: policy.body,
        });

        if (!manuallyAttachablePolicyTypes.includes(policy.type)) {
          manuallyAttachablePolicyTypes.push(policy.type);
        }
      }
    }

    return [
      attachablePoliciesResults,
      attachedPoliciesResults,
      autoAttachedPoliciesResults,
      manuallyAttachablePolicyTypes,
    ];
  }, [
    attachableData?.attachablePoliciesInSpace,
    attachedData?.module?.attachedPolicies,
    labelValues,
  ]);

  const allPolicies = useMemo(
    () => [...attachedPolicies, ...autoAttachedPolicies],
    [attachedPolicies, autoAttachedPolicies]
  );

  const refetch = useCallback(() => {
    refetchAttached();
    refetchAttachable();
  }, [refetchAttached, refetchAttachable]);

  const loading =
    attachedNetworkStatus === NetworkStatus.loading ||
    attachableNetworkStatus === NetworkStatus.loading;

  const refetching =
    (attachedLoading && attachedNetworkStatus === NetworkStatus.refetch) ||
    (attachableLoading && attachableNetworkStatus === NetworkStatus.refetch);

  const hasData = !loading && !!attachedData?.module && !!attachableData;

  return {
    attachablePolicies,
    attachedPolicies,
    autoAttachedPolicies,
    manuallyAttachablePolicyTypes,
    allPolicies,
    loading,
    refetching,
    refetch,
    hasData,
    attachableError,
    attachedError,
  };
};
