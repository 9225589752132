import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { GitlabIntegration, Maybe } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { DELETE_GITLAB_INTEGRATION } from "./gql";

const useDeleteGitLabIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [gitlabIntegrationDelete, { loading }] = useMutation<{
    gitlabIntegrationDelete: Maybe<GitlabIntegration>;
  }>(DELETE_GITLAB_INTEGRATION, {
    refetchQueries: ["SearchVCSIntegrations"],
    onError,
  });

  const deleteGitLabIntegration = useCallback(
    (id: string, attachedEntitiesQty: number, successCallback?: () => void) => {
      return gitlabIntegrationDelete({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `${
                data?.gitlabIntegrationDelete?.name || "GitLab"
              }  integration successfully deleted`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration - Unlink Confirm", {
              attachedEntitiesQty,
              integration: "GitLab",
            });
          }
        })
        .catch(onError);
    },
    [gitlabIntegrationDelete, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return { deleteGitLabIntegration, loading };
};

export default useDeleteGitLabIntegration;
