import { RunPolicyReceipt } from "types/generated";

import { RunPolicyEvaluation } from "../../types";

export const getPolicyEvaluation = (policies: RunPolicyReceipt[]): RunPolicyEvaluation => {
  const firstDeny = policies.find((policy) => policy.outcome === "deny");

  if (firstDeny) {
    return {
      label: firstDeny.outcome,
      badgeVariant: "red",
      timestamp: firstDeny.createdAt,
      isImportant: true,
    };
  }

  return {
    label: policies[0].outcome,
    badgeVariant: "green",
    timestamp: policies[0].createdAt,
    isImportant: false,
  };
};
