import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationStackDisableArgs, Stack } from "types/generated";

export type FetchStackDisableData = { stackDisable: Pick<Stack, "id" | "isDisabled"> };
export const STACK_DISABLE: TypedDocumentNode<FetchStackDisableData, MutationStackDisableArgs> =
  gql`
    mutation DisableStack($id: ID!) {
      stackDisable(id: $id) {
        id
        isDisabled
      }
    }
  `;
