import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type BlockNavigationModalProps = {
  onConfirm: () => void;
  title?: string;
  description?: string;
};

const BlockNavigationModal = create(function BlockNavigationModal({
  onConfirm,
  title = "Are you sure you want to leave?",
  description = "You have unsubmitted changes.",
}: BlockNavigationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    modal.resolve();
    modal.hide();
    onConfirm();
  };

  return (
    <ConfirmationModal
      title={title}
      confirmationButtonLabel="Leave"
      confirmationButtonVariant="dangerPrimary"
      onConfirm={handleOnConfirm}
      size="large"
    >
      <Typography variant="p-body2" tag="p">
        {description}
      </Typography>
    </ConfirmationModal>
  );
});

export const showBlockNavigationModal = (props: BlockNavigationModalProps) =>
  show(BlockNavigationModal, props);
