import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box, { BoxProps } from "ds/components/Box";
import Tab from "ds/components/Tab";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

type TerraformRegistryViewHeaderProps = Omit<BoxProps, "children"> & { children?: ReactNode };

function TerraformRegistryViewHeader({ children, ...rest }: TerraformRegistryViewHeaderProps) {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <ViewHeader firstLevel>
      <Box align="start" justify="between" fullWidth>
        <Box gap="medium" direction="column">
          <ViewHeaderTitle titleSize="p-t3">Terraform registry</ViewHeaderTitle>
          <Box gap="medium">
            <Tab to="/modules" label="Own modules" />
            <Tab to="/shared-modules" label="Shared modules" />
            <Tab to="/providers" label="Providers" />
            {viewer.admin && <Tab to="/gpg-keys" label="GPG keys" />}
          </Box>
        </Box>
        <Box {...rest}>{children}</Box>
      </Box>
    </ViewHeader>
  );
}

export default TerraformRegistryViewHeader;
