import { KeyboardEvent } from "react";

export const isEllipsisActive = (e: HTMLElement, isLineClamp = false) => {
  if (isLineClamp) {
    return e.offsetHeight < e.scrollHeight;
  }

  return e.offsetWidth < e.scrollWidth;
};

export const withEnterKeyPress =
  <T = HTMLElement>(callback?: (e: KeyboardEvent<T>) => void) =>
  (e: KeyboardEvent<T>) => {
    if (e.key === "Enter" && callback) {
      callback(e);
    }
  };
