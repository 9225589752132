import { gql } from "@apollo/client";

export const DELETE_GITHUB_ENTERPRISE_INTEGRATION = gql`
  mutation GitHubEnterpriseIntegrationDelete($id: ID!) {
    githubEnterpriseIntegrationDelete(id: $id) {
      id
      name
    }
  }
`;

export const CHECK_GITHUB_ENTERPRISE_HAS_DEFAULT_INTEGRATION = gql`
  query CheckGitHubEnterpriseHasDefaultIntegration {
    checkGitHubEnterpriseHasDefaultIntegration: githubEnterpriseIntegration {
      id
    }
  }
`;

export const GET_GITHUB_ENTERPRISE_WEBHOOKS_ENDPOINT = gql`
  query GetGithubEnterpriseWebhooksEndpoint($id: ID) {
    githubEnterpriseWebhooksEndpoint(id: $id)
  }
`;
