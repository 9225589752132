import { ChevronNew } from "components/icons";
import Icon from "ds/components/Icon";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { CollapsiblePanelContext } from "./Context";

function CollapsiblePanelToggleIndicator() {
  const { isCollapsed } = useTypedContext(CollapsiblePanelContext);

  return (
    <Icon
      src={ChevronNew}
      className={styles.toggleIndicator}
      rotate={isCollapsed ? undefined : "90"}
      aria-hidden="true"
    />
  );
}

export default CollapsiblePanelToggleIndicator;
