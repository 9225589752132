import cx from "classnames";

import { Tick, CrossNew } from "components/icons";

import MetaInfoListItem from "./Item";
import styles from "./styles.module.css";

type MetaInfoBooleanProps = {
  text: string;
  value: boolean;
};

const MetaInfoBoolean = (props: MetaInfoBooleanProps) => {
  const { text, value } = props;

  return (
    <MetaInfoListItem
      icon={value ? Tick : CrossNew}
      className={cx(value ? styles.success : styles.danger)}
    >
      {text}
    </MetaInfoListItem>
  );
};

export default MetaInfoBoolean;
