import { useModal } from "@ebay/nice-modal-react";

import Markdown from "components/markdown/Markdown";
import DrawerHeader from "ds/components/Drawer/Header";
import { ArrowThin } from "components/icons";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";

import DrawerSimple from "../DrawerNew/Simple";
import Box from "../Box";
import { createDrawer, createDrawerTrigger } from "../DrawerNew/utils";
import ButtonIconNew from "../ButtonIcon/New";

type FullDescriptionDrawerProps = {
  showBackButton?: boolean;
  description: string | null | undefined;
};

const FullDescriptionDrawer = createDrawer(function FullDescriptionDrawer({
  showBackButton,
  description,
}: FullDescriptionDrawerProps) {
  const drawer = useModal();

  const handleGoBack = () => {
    drawer.resolve();
    drawer.hide();
  };

  return (
    <DrawerSimple isDismissable variant="wide">
      <DrawerHeader gap="medium" justify="between">
        <Box gap="medium" align="center">
          {showBackButton && (
            <ButtonIconNew icon={ArrowThin} onPress={handleGoBack} iconRotate="270" variant="ghost">
              Go back
            </ButtonIconNew>
          )}
          <DrawerHeaderTitle title="Description" />
        </Box>
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Markdown markup={description || ""} />
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showFullDescription = createDrawerTrigger(FullDescriptionDrawer);
