import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type VCSIntegrationsBuiltInGitHubListItemDropdownActionsProps = {
  onShowDetails: () => void;
  onEdit: () => void;
  canManageVCSIntegrations: boolean;
};

const VCSIntegrationsBuiltInGitHubListItemDropdownActions = ({
  onShowDetails,
  onEdit,
  canManageVCSIntegrations,
}: VCSIntegrationsBuiltInGitHubListItemDropdownActionsProps) => {
  return (
    <DropdownMenuEllipsis tooltip="Integration actions">
      <DropdownMenuItem onAction={onShowDetails}>See details</DropdownMenuItem>
      {canManageVCSIntegrations && <DropdownMenuItem onAction={onEdit}>Edit</DropdownMenuItem>}
    </DropdownMenuEllipsis>
  );
};

export default VCSIntegrationsBuiltInGitHubListItemDropdownActions;
