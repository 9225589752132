import Link from "ds/components/Link";
import { VcsProvider } from "types/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";

type UserLinkNewProps = {
  login?: string | null;
  name: string;
  provider: VcsProvider;
  className?: string;
};

const UserLinkNew = ({ login, name, provider }: UserLinkNewProps) => {
  return (
    <TextEllipsis tooltip={name} tooltipWidthMode="maxWidthSm">
      {(props) =>
        login && provider === VcsProvider.Github ? (
          <Link href={`https://github.com/${login}`} target="_blank" className={props.className}>
            <Typography {...props} tag="span">
              {login}
            </Typography>
          </Link>
        ) : (
          <Typography {...props} tag="span">
            {name}
          </Typography>
        )
      }
    </TextEllipsis>
  );
};

export default UserLinkNew;
