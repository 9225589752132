import { gql } from "@apollo/client";

import { vcsIntegrationDetailsFragment } from "../../gql";

export const SEARCH_VCS_INTEGRATIONS = gql`
  ${vcsIntegrationDetailsFragment}
  query SearchVCSIntegrations(
    $input: SearchInput!
    $searchUsersSuggestionsInput: SearchSuggestionsInput!
  ) {
    providers
    searchVCSIntegrations(input: $input) {
      edges {
        cursor
        node {
          id
          name
          labels
          description
          provider
          isDefault
          space {
            id
            name
            accessLevel
          }
          searchUsersSuggestions(input: $searchUsersSuggestionsInput) {
            filteredCount
          }
          ...vcsIntegrationDetails
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
    githubIntegration {
      vcsChecks
    }
  }
`;

export const GET_DEFAULT_VCS_INTEGRATIONS = gql`
  query GetDefaultVCSIntegrations {
    searchVCSIntegrations(
      input: { predicates: [{ field: "is_default", constraint: { booleanEquals: [true] } }] }
    ) {
      edges {
        node {
          id
          provider
        }
      }
    }
  }
`;
