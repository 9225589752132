type HistoryEntryUsernameProps = {
  username: string | null;
};

const HistoryEntryUsername = ({ username }: HistoryEntryUsernameProps) => {
  if (!username) {
    return null;
  }

  return (
    <>
      by <i>{username}</i>
    </>
  );
};

export default HistoryEntryUsername;
