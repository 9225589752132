import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";

import TabPanel from "ds/components/TabPanel";
import usePrevious from "hooks/usePrevious";
import useTypedContext from "hooks/useTypedContext";
import { SearchQueryOrderDirection } from "types/generated";
import { AnalyticsPage } from "hooks/useAnalytics";
import { URL_SEARCH_KEY, URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";

import { FiltersContext } from "..";
import FiltersSavedViews from "../SavedViews";
import { ActiveFiltersMap, FilterMode } from "../types";
import styles from "./styles.module.css";
import FiltersTab from "./FiltersTab";
import { getSessionStorageKeys, setUpdatedFiltersUrlParams } from "../helpers";
import { getFiltersFromSavedData } from "../SavedViews/helpers";
import { SavedFiltersProvider } from "../SavedViews/Context";

type SidebarContentProps = {
  analyticsPage?: AnalyticsPage;
  mode: FilterMode;
};

const SidebarContent = ({ analyticsPage, mode }: SidebarContentProps) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const {
    filters,
    reMountSidebar,
    filtersLoading,
    filtersOrderSettingsKey,
    setActiveFilters,
    setCurrentView,
    initialSortDirection,
    initialSortOption,
    filterType,
    resetOpenSections,
    defaultEmptyFilterView,
  } = useTypedContext(FiltersContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const storage = sessionStorage;

  const handleFiltersChange = useCallback(
    (
      option: string,
      direction: SearchQueryOrderDirection,
      filters: ActiveFiltersMap,
      search: string
    ) =>
      () => {
        const { storageUrlSearchKey, storageUrlSortKey, storageUrlSortDirection } =
          getSessionStorageKeys(filtersOrderSettingsKey);

        const updatedFiltersParams = setUpdatedFiltersUrlParams(
          filters,
          searchParams,
          storage,
          filtersOrderSettingsKey
        );

        if (option) {
          updatedFiltersParams.set(URL_SORT_KEY, encodeURIComponent(option));
          storage.setItem(storageUrlSortKey, encodeURIComponent(option));
        } else {
          updatedFiltersParams.delete(URL_SORT_KEY);
          storage.removeItem(storageUrlSortKey);
        }

        if (direction) {
          updatedFiltersParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
          storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));
        } else {
          updatedFiltersParams.delete(URL_SORT_DIRECTION);
          storage.removeItem(storageUrlSortDirection);
        }

        if (search) {
          updatedFiltersParams.set(URL_SEARCH_KEY, search);
          storage.setItem(storageUrlSearchKey, btoa(encodeURIComponent(JSON.stringify(search))));
        } else {
          updatedFiltersParams.delete(URL_SEARCH_KEY);
          storage.removeItem(storageUrlSearchKey);
        }

        setActiveFilters(new Map(filters));
        setSearchParams(updatedFiltersParams, { replace: true });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  );

  const applyView = useCallback(
    (filterData: string, name: string, id: string, isPublic: boolean, isMine: boolean) => {
      const { sortOption, sortDirection, filters, search, order } = getFiltersFromSavedData(
        filterData,
        defaultEmptyFilterView
      );

      resetOpenSections();
      handleFiltersChange(sortOption, sortDirection, filters, search)();
      setCurrentView({
        name,
        id,
        isPublic,
        order,
        filters,
        isMine,
        sortOption: sortOption || initialSortOption,
        sortDirection: sortDirection || initialSortDirection,
        search: search || null,
      });
    },
    [
      defaultEmptyFilterView,
      handleFiltersChange,
      initialSortDirection,
      initialSortOption,
      resetOpenSections,
      setCurrentView,
    ]
  );

  const prevFiltersLoading = usePrevious(filtersLoading);

  // TODO: refactor and remove the state inside sidebar and relay only on one from outisde as this is hard to control
  useEffect(() => {
    if (prevFiltersLoading && !filtersLoading) {
      setInitialLoading(false);
    }
  }, [prevFiltersLoading, filtersLoading]);

  // avoid re-rendering on the Context change
  return useMemo(() => {
    return (
      <SavedFiltersProvider filterType={filterType}>
        <div key={reMountSidebar ? 1 : 0} className={styles.sidebarContent}>
          <TabPanel isActive={mode === FilterMode.Filters} id="filters">
            <FiltersTab
              filters={filters}
              loading={initialLoading}
              applyView={applyView}
              analyticsPage={analyticsPage}
            />
          </TabPanel>

          <TabPanel isActive={mode === FilterMode.Views} id="views">
            <FiltersSavedViews applyView={applyView} />
          </TabPanel>
        </div>
      </SavedFiltersProvider>
    );
  }, [filters, initialLoading, reMountSidebar, mode, analyticsPage, applyView, filterType]);
};

export default SidebarContent;
