import { gql } from "@apollo/client";

export const SEARCH_NOTIFICATIONS = gql`
  query SearchNotifications($input: SearchInput!) {
    newNotificationsCount: notificationCount
    searchNotifications(input: $input) {
      edges {
        cursor
        node {
          id
          title
          body
          dismissed
          severity
          timestamp
          target
          type
          groupingKey
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_NOTIFICATIONS_SUGGESTIONS = gql`
  query SearchNotificationsSuggestions($input: SearchSuggestionsInput!) {
    searchNotificationsSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
      }
    }
  }
`;

export const DISMISS_NOTIFICATIONS = gql`
  mutation DismissNotifications($ids: [ID!]!) {
    dismissNotifications(ids: $ids) {
      id
      dismissed
    }
  }
`;
