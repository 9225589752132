import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

import TextLink from "./TextLink";

const StackHooks = () => (
  <Typography tag="div" variant="p-body2">
    Spacelift workflow can be customized by adding extra commands to be executed before and after
    each of the following phases:
    <ul>
      <li>
        <TextLink href={getDocsUrl("/concepts/run#initializing")}>Initialization</TextLink>{" "}
        (before_init and after_init, respectively)
      </li>
      <li>
        <TextLink href={getDocsUrl("/concepts/run/proposed#planning")}>Planning</TextLink>{" "}
        (before_plan and after_plan, respectively)
      </li>
      <li>
        <TextLink href={getDocsUrl("/concepts/run/tracked#applying")}>Applying</TextLink>{" "}
        (before_apply and after_apply, respectively)
      </li>
      <li>
        Destroying (before_destroy and after_destroy, respectively)
        <ul>
          <li>
            <TextLink href={getDocsUrl("/concepts/run/test-case")}>
              used during module test cases
            </TextLink>
          </li>
          <li>
            used by stacks during destruction that have corresponding
            <TextLink
              href={getDocsUrl(
                "/concepts/stack/stack-dependencies#ordered-stack-creation-and-deletion"
              )}
            >
              stack_destructor_resource
            </TextLink>
          </li>
        </ul>
      </li>

      <li>
        <TextLink href={getDocsUrl("/concepts/run/task#performing-a-task")}>Performing</TextLink>{" "}
        (before_perform and after_perform, respectively)
      </li>
      <li>
        Finally (after_run): Executed after each actively processed run, regardless of its outcome.
        These hooks will execute as part of the last "active" state of the run and will have access
        to an environment variable called TF_VAR_spacelift_final_run_state indicating the final
        state of the run.
      </li>
    </ul>
  </Typography>
);

export default StackHooks;
