import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Typography from "ds/components/Typography";

import { COLUMN_GAP, COLUMN_ORDER, FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "../../constants";

function AuditTrailEntriesTableHeader() {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();
  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Affected resource
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Related resource
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Event type
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Action
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Created by
        </Typography>
      </FiltersSortHeaderStaticColumn>

      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.CreatedAt, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
}

export default AuditTrailEntriesTableHeader;
