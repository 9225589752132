import { useCallback, useEffect, useMemo, useState } from "react";

import { showBulkActionsNewDrawerResultsStepCloseConfirmationModal } from "../Drawer/ResultsStep/CloseConfirmationModal";
import {
  BulkActionsCloseMethod,
  BulkActionsResults,
  BulkActionsStep,
  BulkActionsVariant,
} from "../types";
import { BulkActionsAPIContextValue, BulkActionsDataContextValue } from "./types";

type BulkActionsContextProps = {
  selectedItemsCount: number;
  isExecutingBulkActions: boolean;
  onClose?: (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => void;
  skipResults?: boolean;
};

const useContextValues = ({
  selectedItemsCount,
  isExecutingBulkActions,
  onClose,
  skipResults,
}: BulkActionsContextProps) => {
  const [isBulkActionsActive, setIsBulkActionsActive] = useState(false);

  const [bulkActionsVariant, setBulkActionsVariant] = useState<BulkActionsVariant>(
    BulkActionsVariant.FloatingBar
  );

  const [bulkActionsStep, setBulkActionsStep] = useState<BulkActionsStep>(
    BulkActionsStep.ChooseAction
  );

  const [bulkActionResults, setBulkActionResults] = useState<BulkActionsResults | undefined>(
    undefined
  );

  const resetBulkActions = useCallback(() => {
    setBulkActionsStep(BulkActionsStep.ChooseAction);
    setBulkActionsVariant(BulkActionsVariant.FloatingBar);
    setBulkActionResults(undefined);
  }, []);

  const onSelectAction = useCallback(() => {
    setBulkActionsStep(BulkActionsStep.ActionConfirmation);
  }, []);

  const onConfirmAction = useCallback(() => {
    if (!skipResults) {
      setBulkActionsStep(BulkActionsStep.Results);
    }
  }, [skipResults]);

  const handleClose = useCallback(
    (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => {
      setIsBulkActionsActive(false);
      resetBulkActions();
      onClose?.(variant, method);
    },
    [resetBulkActions, onClose]
  );

  const closeBulkActions = useCallback(
    (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => {
      const doneExecutingBulkActions =
        bulkActionsStep === BulkActionsStep.Results && !isExecutingBulkActions;
      const isFloatingBar = variant === BulkActionsVariant.FloatingBar;
      if (doneExecutingBulkActions || isFloatingBar) {
        handleClose(variant, method);
      } else {
        showBulkActionsNewDrawerResultsStepCloseConfirmationModal({
          isExecutingBulkActions,
          onConfirm: () => {
            handleClose(variant, method);
          },
        });
      }
    },
    [bulkActionsStep, isExecutingBulkActions, handleClose]
  );

  const onContinueWith = useCallback(() => {
    setBulkActionsStep(BulkActionsStep.ChooseAction);
    setBulkActionResults(undefined);
  }, []);

  const onCancelAction = useCallback(() => {
    setBulkActionsStep(BulkActionsStep.ChooseAction);
  }, []);

  const dataContext: BulkActionsDataContextValue = useMemo(
    () => ({
      bulkActionsVariant,
      isBulkActionsActive,
      isExecutingBulkActions,
      bulkActionsStep,
      bulkActionResults,
    }),
    [
      bulkActionResults,
      bulkActionsStep,
      bulkActionsVariant,
      isExecutingBulkActions,
      isBulkActionsActive,
    ]
  );

  const apiContext: BulkActionsAPIContextValue = useMemo(
    () => ({
      closeBulkActions,
      onSelectAction,
      onConfirmAction,
      onCancelAction,
      onContinueWith,
      setBulkActionsVariant,
      setBulkActionResults,
      setIsBulkActionsActive,
    }),
    [closeBulkActions, onCancelAction, onConfirmAction, onContinueWith, onSelectAction]
  );

  useEffect(() => {
    if (
      bulkActionsStep !== BulkActionsStep.Results &&
      isBulkActionsActive &&
      selectedItemsCount === 0
    ) {
      handleClose(bulkActionsVariant, BulkActionsCloseMethod.UnselectedAllItem);
    }
    if (!isBulkActionsActive && selectedItemsCount > 0) {
      setIsBulkActionsActive(true);
    }
  }, [
    bulkActionsStep,
    isBulkActionsActive,
    bulkActionsVariant,
    closeBulkActions,
    selectedItemsCount,
    handleClose,
  ]);

  return {
    dataContext,
    apiContext,
  };
};

export default useContextValues;
