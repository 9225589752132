import { RefObject } from "react";
import cx from "classnames";

import { WebhookDelivery } from "types/generated";
import { Chevron } from "components/icons";
import LoadingSpinner from "components/form/components/loading";

import WebhookDeliveriesListItem from "./Item";
import WebhookDeliveryListItem from "./WebhookItem";
import styles from "./styles.module.css";

type WebhookDeliveriesProps = {
  items?: Array<WebhookDelivery>;
  innerRef?: RefObject<HTMLDivElement>;
  handleClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  token?: string | null | undefined;
  loadMore?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  collapsed: boolean;
  loading?: boolean;
  webhookId?: string;
  stackId?: string;
  openDeliveryCallback?: () => void;
};

const WebhookDeliveriesList = ({
  items,
  innerRef,
  handleClick,
  loadMore,
  token,
  collapsed,
  loading,
  webhookId,
  stackId,
  openDeliveryCallback,
}: WebhookDeliveriesProps) => {
  return (
    <div ref={innerRef}>
      <button type="button" className={styles.button} onClick={handleClick}>
        Recent deliveries{" "}
        <Chevron
          className={cx(styles.chevron, {
            [styles.chevronActive]: collapsed,
          })}
        />
      </button>
      {collapsed && (
        <>
          {items && items.length > 0 && (
            <ul className={styles.list}>
              {items &&
                items.map((item) => {
                  return stackId ? (
                    <WebhookDeliveryListItem
                      key={item.id}
                      item={item}
                      webhookId={webhookId}
                      stackId={stackId}
                      openDeliveryCallback={openDeliveryCallback}
                    />
                  ) : (
                    <WebhookDeliveriesListItem
                      key={item.id}
                      item={item}
                      openDeliveryCallback={openDeliveryCallback}
                    />
                  );
                })}
            </ul>
          )}
          {items && items.length === 0 && (
            <p className={styles.placeholder}>
              No deliveries yet, trigger a run to see deliveries here.
            </p>
          )}
        </>
      )}

      {loading && <LoadingSpinner />}
      {token && collapsed && (
        <button
          type="button"
          className={`${styles.button} ${styles.buttonMore}`}
          onClick={loadMore}
        >
          Load more <Chevron className={styles.chevron} />
        </button>
      )}
    </div>
  );
};

export default WebhookDeliveriesList;
