import { getDocsUrl } from "utils/getDocsUrl";

import { SourceCodeFields, SourceCodeProjects } from "./types";

export const SOURCE_CODE_FIELDS_TOOLTIPS = {
  [SourceCodeFields.Repository]: {
    [SourceCodeProjects.Stack]: {
      text: "repository containing the source code for this stack.",
      link: getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository"),
    },
    [SourceCodeProjects.Module]: {
      text: "repository containing the source code for this module.",
      link: getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository"),
    },
  },
  [SourceCodeFields.RepositoryURL]: {
    [SourceCodeProjects.Stack]: {
      text: "URL pointing to a public Git repository containing the source code for this stack.",
      link: getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository"),
    },
    [SourceCodeProjects.Module]: {
      text: "URL pointing to a public Git repository containing the source code for this module.",
      link: getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository"),
    },
  },
  [SourceCodeFields.Branch]: {
    [SourceCodeProjects.Stack]: {
      text: {
        default:
          "Git branch from which this stack is built. To improve performance, we have not implemented pagination so the dropdown list is not exhaustive. If your desired branch is not on the list, you can still enter it manually.",
        readonly: "Git branch from which this stack is built.",
      },
      link: getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository"),
    },
    [SourceCodeProjects.Module]: {
      text: {
        default:
          "Git branch from which this module is built. To improve performance, we have not implemented pagination so the dropdown list is not exhaustive. If your desired branch is not on the list, you can still enter it manually.",
        readonly: "Git branch from which this module is built.",
      },
      link: getDocsUrl("/concepts/stack/stack-settings#vcs-integration-and-repository"),
    },
  },
  [SourceCodeFields.Integration]: {
    [SourceCodeProjects.Stack]: {
      text: "integration used to access the source code for this stack.",
      link: getDocsUrl("/integrations/source-control/"),
    },
    [SourceCodeProjects.Module]: {
      text: "integration used to access the source code for this module.",
      link: getDocsUrl("/integrations/source-control/"),
    },
  },
  [SourceCodeFields.ProjectRoot]: {
    [SourceCodeProjects.Stack]: {
      text: "Project root is the optional directory relative to the workspace root containing the entrypoint to the stack.",
      link: getDocsUrl("/concepts/stack/stack-settings#project-root"),
    },
    [SourceCodeProjects.Module]: {
      text: "Project root is the optional directory relative to the workspace root containing the entrypoint to the module.",
      link: getDocsUrl("/concepts/stack/stack-settings#project-root"),
    },
  },
};
