import { gql } from "@apollo/client";

export const CREATE_POLICY = gql`
  mutation CreatePolicyRedesigned($input: PolicyCreateInput!) {
    policyCreatev2(input: $input) {
      id
      name
    }
  }
`;

export const GET_POLICY_NAMES = gql`
  query GetPolicyNames {
    policies {
      name
    }
  }
`;

export const GET_TEMPLATE_BODY = gql`
  query GetTemplateBody($id: ID!) {
    policyTemplate(id: $id) {
      body
    }
  }
`;
