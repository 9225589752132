import cx from "classnames";

import { File, Cancel } from "components/icons";

import styles from "../styles.module.css";

const ShowWriteOnlyFile = () => (
  <span className={cx(styles.variableDownload, styles.variableDownloadDisabled)}>
    <File />
    <span className={styles.variableIcon}>
      <Cancel />
    </span>
  </span>
);

export default ShowWriteOnlyFile;
