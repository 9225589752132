import EmptyState from "ds/components/EmptyState";
import { IntegrationColored } from "components/icons";
import Box from "ds/components/Box";

const ManageSlackAccessEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You don’t have any accesses yet"
        icon={IntegrationColored}
        caption="Click on Grant access button to add one."
      />
    </Box>
  );
};

export default ManageSlackAccessEmpty;
