import { gql } from "@apollo/client";

export const GET_STACKS_SIZES = gql`
  query GetStacksSizes {
    metrics {
      largestStacks {
        resourcesCount
        stackTile {
          name
          slug
        }
      }
      maxStackSizeByResourceCount {
        value
      }
      avgStackSizeByResourceCount {
        value
      }
    }
  }
`;
