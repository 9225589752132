import { useMemo } from "react";

import KeyValue from "components/KeyValue";
import EmptyState from "ds/components/EmptyState";
import { UserAccessColored } from "components/icons";

import { ModuleShareFormFields } from "../types";
import NewModuleSummaryValueList from "./ValueList";

type NewModuleSummaryShareProps = {
  data: ModuleShareFormFields;
};

const NewModuleSummaryShare = ({ data }: NewModuleSummaryShareProps) => {
  const sharedAccountsValues = useMemo(
    () => data.sharedAccounts?.map((account) => account.value),
    [data.sharedAccounts]
  );

  return (
    <>
      {!!sharedAccountsValues?.length && (
        <KeyValue name="Accounts">
          <NewModuleSummaryValueList values={sharedAccountsValues} />
        </KeyValue>
      )}
      {!sharedAccountsValues?.length && (
        <EmptyState
          padding="large"
          icon={UserAccessColored}
          title="This module is not shared with any accounts"
          caption="Use the accounts list to share this module with other accounts."
        />
      )}
    </>
  );
};

export default NewModuleSummaryShare;
