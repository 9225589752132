import { RunStateTransition } from "types/generated";

import UnconfirmedEntry from "../entries/UnconfirmedEntry";
import { canShowElement } from "./elements";
import { RunElementType, RunEntryContext } from "../types";
import { getRunStateTransitionId } from "./getRunStateTransitionId";

export const createUnconfirmedEntry = (
  { timestamp, until, note, stateVersion }: RunStateTransition,
  {
    isSimpleView,
    canConfirm,
    canDiscard,
    stackId,
    runId,
    isStackDisabled,
    analyticsPage,
  }: RunEntryContext,
  hasApprovalPolicies: boolean
) => {
  // We want to show the Unconfirmed entry if there's an associated approval policy
  // We also need to show the entry if the user can confirm or discard the run
  if (isSimpleView && !hasApprovalPolicies && !canConfirm && !canDiscard) {
    return null;
  }

  return (
    <UnconfirmedEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      note={note}
      showDiscardButton={canShowElement(RunElementType.TerminateRunAction)}
      showConfirmButton={canShowElement(RunElementType.ConfirmRunAction)}
      canConfirm={canConfirm}
      canDiscard={canDiscard}
      runId={runId}
      stackId={stackId}
      isStackDisabled={isStackDisabled}
      analyticsPage={analyticsPage}
    />
  );
};
