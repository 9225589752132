import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationDismissNotificationsArgs, Notification } from "types/generated";

type FetchDismissNotificationsData = { dismissNotifications: Notification };
export const DISMISS_NOTIFICATIONS: TypedDocumentNode<
  FetchDismissNotificationsData,
  MutationDismissNotificationsArgs
> = gql`
  mutation DismissNotifications($ids: [ID!]!) {
    dismissNotifications(ids: $ids) {
      id
      dismissed
    }
  }
`;
