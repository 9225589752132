import { memo } from "react";

import { WorkerPool } from "types/generated";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import WorkerPoolDeleteAction from "../WorkerPool/DeleteAction";
import WorkerPoolResetAction from "../WorkerPool/ResetAction";
import WorkerPoolCycleAction from "../WorkerPool/CycleAction";
import { showCycleConfirmation } from "../WorkerPool/CycleConfirmation";
import { showResetConfirmation } from "../WorkerPool/ResetConfirmation";
import { showDeleteConfirmation } from "../WorkerPool/DeleteConfirmation";

export type WorkerPoolsListItemDropdownProps = {
  workerPoolId: WorkerPool["id"];
  hasUsers: boolean;
  workerPoolName: string;
  hasBusyWorkers: boolean;
  onEdit: () => void;

  canManageWorkerPools: boolean;
};

const WorkerPoolsListItemDropdown = ({
  workerPoolId,
  hasUsers,
  hasBusyWorkers,
  workerPoolName,
  onEdit,
  canManageWorkerPools,
}: WorkerPoolsListItemDropdownProps) => {
  const handleOnCycle = () => {
    showCycleConfirmation({ id: workerPoolId, name: workerPoolName });
  };

  const handleOnReset = () => {
    showResetConfirmation({ id: workerPoolId, name: workerPoolName });
  };

  const handleOnDelete = () => {
    showDeleteConfirmation({ id: workerPoolId, name: workerPoolName });
  };

  return (
    <DropdownMenuEllipsis tooltip="Worker pool actions" dotsSize="small">
      <CopyFieldDropdownMenuItem title="Copy ID" value={workerPoolId} />
      {canManageWorkerPools && (
        <>
          <DropdownMenuItem onAction={onEdit}>Edit</DropdownMenuItem>
          <WorkerPoolCycleAction onAction={handleOnCycle} hasBusyWorkers={hasBusyWorkers} />
          <WorkerPoolResetAction onAction={handleOnReset} hasBusyWorkers={hasBusyWorkers} />
          <WorkerPoolDeleteAction
            onAction={handleOnDelete}
            hasUsers={hasUsers}
            hasBusyWorkers={hasBusyWorkers}
          />
        </>
      )}
    </DropdownMenuEllipsis>
  );
};

export default memo(WorkerPoolsListItemDropdown);
