import { useLayoutEffect, useState } from "react";
import { throttle } from "lodash-es";

const THROTTLE_TIME = 50;

const useElementWidth = (element: HTMLElement | null, initialValue?: number) => {
  const [elementSize, setElementSize] = useState<number | undefined>(initialValue);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(
      throttle(
        () => {
          if (!element) {
            return;
          }
          setElementSize(element.clientWidth);
        },
        THROTTLE_TIME,
        { leading: true, trailing: true }
      )
    );

    if (element) {
      resizeObserver.observe(element);
    }

    return () => resizeObserver.disconnect();
  }, [element]);

  return elementSize || 0;
};

export default useElementWidth;
