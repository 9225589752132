import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import styles from "./styles.module.css";

type UsersInfoDrawerContentProps = {
  isInviteFormVisible: boolean;
  handleCloseDrawer: () => void;
};

const UsersInfoDrawerContent = ({
  isInviteFormVisible,
  handleCloseDrawer,
}: UsersInfoDrawerContentProps) => {
  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Users" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h6" variant="p-t6">
          User access
        </Typography>
        <Typography tag="p" variant="p-body2" className={styles.description}>
          The user list consists of all individuals who have access to your account.
          <br />
          <br />
          You can manage access rules for anyone who logs into your account by selecting the{" "}
          <Typography tag="span" variant="p-t6">
            {"'access details'"}{" "}
          </Typography>
          option after clicking the three dots to the right of the user entry.
          <br />
          <br />
          To invite new users to your account,{" "}
          {isInviteFormVisible ? (
            <>
              fill in the form and click on the{" "}
              <Typography tag="span" variant="p-t6">
                {"`Invite to Spacelift`"}
              </Typography>{" "}
              button.{" "}
            </>
          ) : (
            <>
              click on the{" "}
              <Typography tag="span" variant="p-t6">
                {"'Invite user'"}
              </Typography>{" "}
              button located in the top right corner.{" "}
            </>
          )}
          You will be able to send them an email invitation link and determine their access level
          during the invitation process.
        </Typography>
        <DrawerFooter>
          <DocumentationButton
            to={getDocsUrl("/concepts/user-management/admin#users")}
            analyticsPage={AnalyticsPageOrganization.OrganizationUsers}
          />
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default UsersInfoDrawerContent;
