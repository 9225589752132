import { Disabled } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

const DisabledStackNotice = () => (
  <div className={styles.wrapper}>
    <Box direction="row" align="center" justify="between" className={styles.disabledNotice}>
      <div>
        <Typography tag="p" variant="p-body3">
          Run creation on this stack is currently disabled.{" "}
        </Typography>
      </div>
      <Icon size="large" src={Disabled} />
    </Box>
  </div>
);

export default DisabledStackNotice;
