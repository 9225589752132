import { RunPolicyReceipt } from "types/generated";

import { RunElementType, RunEntryContext } from "../types";
import { canShowElement } from "./elements";
import TriggerPoliciesEntry from "../entries/TriggerPoliciesEntry";

export const createTriggerPoliciesEntry = (
  list: RunPolicyReceipt[],
  { stackId, runId, isModuleRun }: RunEntryContext
) => {
  const lastTrigger = list[list.length - 1];

  return (
    <TriggerPoliciesEntry
      key={lastTrigger.id}
      list={list}
      openListByDefault={canShowElement(RunElementType.TriggerPolicies)}
      stackId={stackId}
      runId={runId}
      isModuleRun={isModuleRun}
    />
  );
};
