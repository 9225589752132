import { ReactNode } from "react";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryUsername from "../../components/HistoryEntryUsername";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type StoppedEntryProps = {
  timestamp: number;
  username: string | null;
  children: ReactNode;
};

const StoppedEntry = ({ timestamp, username, children }: StoppedEntryProps) => {
  const label = (
    <HistoryEntryLabel>
      <strong>Stopped</strong> <HistoryEntryUsername username={username} />
    </HistoryEntryLabel>
  );

  return (
    <HistoryEntryWrapper
      label={label}
      state={RunHistoryEntryType.Stopped}
      timestamp={timestamp}
      terminal
    >
      {children}
    </HistoryEntryWrapper>
  );
};

export default StoppedEntry;
