import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";

import CardWrapper from "components/CardWrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Drawer from "ds/components/Drawer";
import ContextConfigFormPathField from "components/ContextConfigForm/PathField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import ContextConfigFormFileUploadField from "components/ContextConfigForm/FileUploadField";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";

import ContentDrawer from "./ContentDrawer";
import { ContextAttachedMountedFile } from "../types";
import CreateFileButton from "../ContextSetupEnvironmentForm/CreateFileButton";
import { CONTEXT_ANALYTICS_VERSION } from "../constants";

type ContextMountedFilesAddFormProps = {
  onSave: (item: ContextAttachedMountedFile) => void;
};

const ContextMountedFilesAddForm = ({ onSave }: ContextMountedFilesAddFormProps) => {
  const builderForm = useForm<ContextAttachedMountedFile>({
    defaultValues: {
      path: "",
      isSecret: true,
      description: "",
      file: null,
      content: null,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    clearErrors,
    setValue,
    formState: { isValid },
  } = builderForm;
  const [isContentDrawerVisible, setIsContentDrawerVisible] = useState(false);

  const file = watch("file");
  const content = watch("content");
  const path = watch("path");

  const handleCloseDrawer = useCallback(() => setIsContentDrawerVisible(false), []);
  const handleOpenDrawer = useCallback(() => setIsContentDrawerVisible(true), []);

  const onSubmit = useCallback(
    (formData: ContextAttachedMountedFile) => {
      onSave(formData);
      reset();
      handleCloseDrawer();
    },
    [onSave, reset, handleCloseDrawer]
  );

  useEffect(() => {
    // After user selects a file check if path field is empty, if so, set it to file name
    if (!getValues("path") && file) {
      clearErrors("path");
      setValue("path", file.name);
    }
  }, [getValues, setValue, clearErrors, file]);

  return (
    <CardWrapper variant="filled" direction="column">
      <FormProvider {...builderForm}>
        <Box margin="0 0 large">
          <ContextConfigFormPathField name="path" />
        </Box>

        <ContextConfigFormIsSecretField name="isSecret" />

        <ContextConfigFormDescriptionField name="description" />

        <Box margin="large 0 0" grid gridTemplate={file ? "1fr" : "1fr 1fr"} gap="large">
          <ContextConfigFormFileUploadField name="file" required={!content} />

          {!file && <CreateFileButton onClick={handleOpenDrawer} disabled={!path} />}
        </Box>
        {file && (
          <Box justify="end" fullWidth margin="large 0 0">
            <Button
              analyticsTitle="Add File Click"
              analyticsPage={AnalyticsPageContext.ContextNew}
              analyticsProps={{
                version: CONTEXT_ANALYTICS_VERSION,
                method: "drop",
              }}
              variant="contrast"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              Add file
            </Button>
          </Box>
        )}
        <Drawer position="absoluteRight" visible={isContentDrawerVisible} isResizable>
          <ContentDrawer
            onDrawerClose={handleCloseDrawer}
            name="content"
            onSave={handleSubmit(onSubmit)}
          />
        </Drawer>
      </FormProvider>
    </CardWrapper>
  );
};

export default ContextMountedFilesAddForm;
