import dedent from "dedent";

import { EvaluationRecordOutcomeStatus } from "./types";

export const EMPTY_INPUT_MSG = dedent`
# You don't have any
# evaluation records yet,
# set sample to "true"
# if you have non-admin members
# or replace this comment
# with JSON input.`;

export const BADGE_STATES: Record<string, EvaluationRecordOutcomeStatus> = {
  approve: EvaluationRecordOutcomeStatus.Successful,
  allow: EvaluationRecordOutcomeStatus.Successful,
  track: EvaluationRecordOutcomeStatus.Successful,
  webhook: EvaluationRecordOutcomeStatus.Successful,
  deny: EvaluationRecordOutcomeStatus.Error,
  error: EvaluationRecordOutcomeStatus.Error,
  inbox: EvaluationRecordOutcomeStatus.Error,
  reject: EvaluationRecordOutcomeStatus.Error,
  admin: EvaluationRecordOutcomeStatus.Warning,
  slack: EvaluationRecordOutcomeStatus.Warning,
  warn: EvaluationRecordOutcomeStatus.Warning,
  multiple: EvaluationRecordOutcomeStatus.Idle,
  propose: EvaluationRecordOutcomeStatus.Idle,
  read: EvaluationRecordOutcomeStatus.Idle,
  trigger: EvaluationRecordOutcomeStatus.Idle,
  write: EvaluationRecordOutcomeStatus.Idle,
  ignore: EvaluationRecordOutcomeStatus.Info,
  none: EvaluationRecordOutcomeStatus.Info,
  undecided: EvaluationRecordOutcomeStatus.Info,
};
