import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";
import Typography from "../Typography";

type CodeProps = {
  children: React.ReactNode;
  className?: string;
  whiteSpaceVisible?: boolean;
};

const Code = ({ children, className, whiteSpaceVisible }: CodeProps) => {
  return (
    <div className={cx(styles.code, whiteSpaceVisible && styles.whiteSpaceVisible, className)}>
      <Typography variant="s-body3" tag="code">
        {children}
      </Typography>
    </div>
  );
};

export default Code;
