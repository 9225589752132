import { FetchResult } from "@apollo/client";

import { ApolloMutationHook } from "types/Mutation";

/**
 * @propagateError - if true, the error will be propagated to the caller, mostly used in the bulk actions
 */
type HandleMutationProps<FetchResultData, MutationVariables> = {
  mutation: ApolloMutationHook<FetchResultData, MutationVariables>;
  variables: MutationVariables;
  mutationCallback?: (data: FetchResult<FetchResultData>["data"]) => void;
} & (
  | {
      propagateError?: boolean;
      errorCallback: (error: unknown) => void;
    }
  | { propagateError: true; errorCallback?: never }
);

export const handleMutation = async <FetchResultData, MutationVariables>({
  mutation,
  variables,
  mutationCallback,
  propagateError,
  errorCallback,
}: HandleMutationProps<
  FetchResultData,
  MutationVariables
>): Promise<FetchResult<FetchResultData> | void> => {
  try {
    const response = await mutation({ variables });

    mutationCallback?.(response.data);

    return response;
  } catch (error) {
    if (propagateError) {
      // propagate error to the caller
      throw error;
    } else {
      errorCallback?.(error);
    }
  }
};
