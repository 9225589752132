import { useCallback } from "react";
import { EditorProps } from "@monaco-editor/react";

import useTypedFlags from "hooks/useTypedFlags";

import { EncodedJsonValue, EncodedJsonValueTuple, JsonLocation } from "./types";

type Options = {
  id: string;
  jsonLocations: JsonLocation[];
  onJsonDiffModalOpen: (value: EncodedJsonValueTuple) => void;
  onJsonModalOpen: (value: EncodedJsonValue) => void;
};

export function useJsonCodeLenses({
  id,
  jsonLocations,
  onJsonDiffModalOpen,
  onJsonModalOpen,
}: Options) {
  const { diffViewForJsonEncodedValues } = useTypedFlags();

  const onEditorMount = useCallback<NonNullable<EditorProps["onMount"]>>(
    (editor, monaco) => {
      if (!diffViewForJsonEncodedValues || !jsonLocations.length) {
        return;
      }

      const openJSONDiffViewCommand = monaco.editor.registerCommand(
        "openJSONDiffView",
        (_, [before, after]) => {
          onJsonDiffModalOpen([before, after]);
        }
      );

      const openJSONViewCommand = monaco.editor.registerCommand("openJSONView", (_, value) => {
        onJsonModalOpen(value);
      });

      const provider = monaco.languages.registerCodeLensProvider(
        {
          language: "resourcesDiff",
          pattern: {
            base: "/",
            pattern: id,
          },
        },
        {
          provideCodeLenses() {
            const lenses = [];

            for (const jsonLocation of jsonLocations) {
              const isJsonDiff = Array.isArray(jsonLocation.value);

              lenses.push({
                range: new monaco.Range(jsonLocation.row, 1, jsonLocation.row, 1),
                command: {
                  id: isJsonDiff ? "openJSONDiffView" : "openJSONView",
                  title: isJsonDiff ? "Open JSON diff view" : "Open JSON view",
                  arguments: [jsonLocation.value],
                },
              });
            }

            return {
              lenses,
              dispose() {
                // Nothing to dispose
              },
            };
          },
        }
      );

      editor.onDidDispose(() => {
        openJSONDiffViewCommand.dispose();
        openJSONViewCommand.dispose();
        provider.dispose();
      });
    },
    [id, diffViewForJsonEncodedValues, jsonLocations, onJsonDiffModalOpen, onJsonModalOpen]
  );

  return onEditorMount;
}
