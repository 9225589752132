import { stackDocument } from "./documents";

const Task = `package spacelift

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
# ⚠️ Task run policies only take effect once attached to a Stack ⚠️
#
# Task run policy can prevent a user command from running. As an input, it
# receives the following document:
#
# {
#   "request": {
#     "command": "string",
#     "remote_ip": "string",
#     "timestamp_ns": "number - current Unix timestamp in nanoseconds"
#   },
#   "session": {
#     "admin": "boolean",
#     "login": "string",
#     "name": "string",
#     "teams": ["string"]
#   },
#   "stack": {
${stackDocument(5)}
#   }
# }
#
# The policy may define one or more "deny" rules - each with a message - that
# can prevent the command from even creating a task. These messages are reported
# as API errors to the user, so please keep them civil and informative.
#
# Below you can find a few examples of task run policies.
#
# 1) Prevent non-admins from running commands outside the office:
# deny["non-admins can only run tasks from the office"] {
#   office := "56.78.99.0/24"
#
#   not input.session.admin
#   not net.cidr_contains(office, request.remote_ip)
# }
#
# 2) Prevent non-admins from running commands on weekends:
# deny["non-admins must not run tasks on weekends"] {
#   today   := time.weekday(input.request.timestamp_ns)
#   weekend := {"Saturday", "Sunday"}
#
#   weekend[today]
#   not input.session.admin
# }
#
# 3) Disable the tasks feature altogether:
# deny["no, you will not run arbitrary stuff here"] {
#   true
# }
#
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
`;

export default Task;
