import { gql } from "@apollo/client";

export const GET_STACK_WEBHOOK = gql`
  query GetStackWebhook($stackId: ID!, $id: ID!, $nextToken: String) {
    stack(id: $stackId) {
      id
      integrations {
        webhook(id: $id) {
          id
          deliveries(token: $nextToken) {
            nextToken
            nodes {
              id
              timestamp
              status
            }
          }
        }
      }
    }
  }
`;
