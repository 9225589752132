import { WarningSolid } from "components/icons";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import TooltipInfo from "ds/components/TooltipInfo";
import Typography from "ds/components/Typography";

type DashboardWidgetsResourcesHealthDriftDisabledCentralInfoProps = {
  hasSmallContainer?: boolean;
};

const DashboardWidgetsResourcesHealthDriftDisabledCentralInfo = ({
  hasSmallContainer,
}: DashboardWidgetsResourcesHealthDriftDisabledCentralInfoProps) => {
  return (
    <Box gap="small" justify="center" align="center">
      <Icon src={WarningSolid} color="warning" />

      <Typography color="secondary" tag="span" variant="p-t7">
        RESOURCES
      </Typography>
      <TooltipInfo
        variant={hasSmallContainer ? "modal" : "standard"}
        widthMode={hasSmallContainer ? undefined : "maxWidthSm"}
      >
        {hasSmallContainer ? (
          <Box direction="column" gap="medium">
            <Typography tag="span" variant="p-t6">
              Drift Detection not enabled
            </Typography>
            <Typography tag="span" variant="p-body3">
              Enable drift detection on your stacks to be able to preview your resources health.
            </Typography>

            {/* TODO: [dashboard] userflow trigger */}
            {/* <Box justify="end">
              <Button variant="outlineOnDark">Show me how</Button>
            </Box> */}
          </Box>
        ) : (
          "Enable drift detection on your stacks to be able to preview your resources health."
        )}
      </TooltipInfo>
    </Box>
  );
};

export default DashboardWidgetsResourcesHealthDriftDisabledCentralInfo;
