import Button from "ds/components/Button";
import { HourGlassColored } from "components/icons";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Modal from "ds/components/Modal";
import ModalContent from "ds/components/Modal/Content";
import Heading from "ds/components/Typography/Heading";

import styles from "./styles.module.css";

type SessionHasExpiredModalModalProps = {
  isModalVisible: boolean;
  onConfirm?: () => void;
};

const SessionHasExpiredModal = ({
  isModalVisible,
  onConfirm,
}: SessionHasExpiredModalModalProps) => {
  return (
    <Modal role="alertdialog" isOpen={isModalVisible} isDismissable={false}>
      <ModalContent>
        <Box padding="x-large" direction="column" align="center" justify="center" fullWidth>
          <HourGlassColored className={styles.icon} />
          <Heading variant="p-t2" margin="x-large 0 small" slot="title">
            Your session has expired
          </Heading>
          <Typography variant="p-body2" tag="p" margin="0 0 x-large" color="secondary">
            Click button below to log in again.
          </Typography>
          <Button variant="primary" onClick={onConfirm}>
            Login
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default SessionHasExpiredModal;
