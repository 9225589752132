import { BillingTierFeature } from "types/generated";

import TierInfo from ".";

export const WebhooksTierInfo = () => {
  return (
    <TierInfo
      type="callout"
      variant="promo"
      title="Webhooks are not supported by your plan"
      feature={BillingTierFeature.RunStateChangeWebhooks}
    >
      You may still manage webhooks, but connections won't work until you upgrade to a plan that
      supports them.
    </TierInfo>
  );
};
