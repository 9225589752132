import { useCallback } from "react";

import BadgeNext from "ds/components/BadgeNext";
import TooltipInfo from "ds/components/TooltipInfo";

type YankedBadgeProps = {
  notes?: string | null;
};

const YankedBadge = ({ notes }: YankedBadgeProps) => {
  const MemoizedTooltip = useCallback(
    () => <TooltipInfo widthMode="maxWidthXl">{notes}</TooltipInfo>,
    [notes]
  );

  return (
    <BadgeNext
      variant="red"
      type="strong"
      text="BAD"
      endIcon={notes ? MemoizedTooltip : undefined}
    />
  );
};

export default YankedBadge;
