import { Dispatch, SetStateAction, createContext } from "react";

import {
  InternalFormData,
  StackCreationWizardFormStep,
  StackCreationWizardNavStep,
  StackCreationWizardStep,
  StackFormFields,
} from "./types";

type StackFormContextProps = {
  availableSteps: Array<StackCreationWizardNavStep>;
  currentStep: StackCreationWizardStep;
  currentStepIndex: number;
  formData: StackFormFields;
  setFormData: Dispatch<SetStateAction<StackFormFields>>;
  updateStepData: (
    step: StackCreationWizardStep,
    data: StackFormFields[StackCreationWizardFormStep]
  ) => void;
  setCurrentStep: (step: StackCreationWizardStep) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToSummary: () => void;
  setAsyncValidationLoading: (loading: boolean) => void;
  asyncValidationLoading: boolean;
  setStepperNavDisabled: (disabled: boolean) => void;
  stepperNavDisabled: boolean;
  internalData: InternalFormData;
  setInternalFormData: Dispatch<React.SetStateAction<InternalFormData>>;
  createdStackId: string;
  setCreatedStackId: (id: string) => void;
  isIntermediateStepActive: boolean;
  setIsIntermediateStepActive: (active: boolean) => void;
  setAccountOrAuthErrorContent: (content: JSX.Element) => void;
};

export const StackFormContext = createContext<StackFormContextProps | undefined>(undefined);
StackFormContext.displayName = "StackFormContext";
