import { ArrowThin } from "components/icons";
import Button from "ds/components/Button";
import Icon from "ds/components/Icon";

type VCSIntegrationsContinueButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
};

const VCSIntegrationsContinueButton = ({
  onClick,
  disabled,
  loading,
}: VCSIntegrationsContinueButtonProps) => {
  return (
    <Button
      variant="primary"
      endIcon={({ className }) => <Icon src={ArrowThin} rotate="90" className={className} />}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      Continue
    </Button>
  );
};

export default VCSIntegrationsContinueButton;
