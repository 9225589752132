import { gql } from "@apollo/client";

export const GET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS = gql`
  query GetApiKeysManagedByNonRootAdmins {
    apiKeysManagedByNonRootAdmins
  }
`;

export const SET_API_KEYS_MANAGED_BY_NON_ROOT_ADMINS = gql`
  mutation SetApiKeysManagedByNonRootAdmins($enable: Boolean!) {
    apiKeyEnableNonRootManagers(enable: $enable)
  }
`;
