import Button from "ds/components/Button";

import { BulkActionsVariant } from "../../types";
import { BULK_ACTIONS_ITEM_DATA_ATTRIBUTE, BUTTON_PROPS_BY_VARIANT } from "../helpers";
import { BulkActionsApplicableAction } from "../types";
import styles from "./styles.module.css";

type BulkActionsChooseActionStepActionsMeasureWrapperProps<Action extends string> = {
  variant: BulkActionsVariant;
  availableActions: Array<BulkActionsApplicableAction<Action>>;
};

/**
 * This component is a workaround for the "More dropdown" measurements. It's used to measure the width of the actions,
 * otherwise it would create "flickering" effect where we would need to temporarily render all buttons, just to take the measurements. It's hidden from sight and cannot be entered with keyboard.
 *
 * @param availableActions
 * @param variant
 * @constructor
 */
const BulkActionsChooseActionStepActionsMeasureWrapper = <Action extends string>({
  availableActions,
  variant,
}: BulkActionsChooseActionStepActionsMeasureWrapperProps<Action>) => (
  <div className={styles.measureWrapper} tabIndex={-1} aria-hidden>
    {availableActions.map(({ title, startIcon }) => (
      <Button
        key={title}
        {...BUTTON_PROPS_BY_VARIANT[variant]}
        {...{ [BULK_ACTIONS_ITEM_DATA_ATTRIBUTE]: title }}
        aria-hidden
        disabled
        startIcon={startIcon}
      >
        {title}
      </Button>
    ))}
  </div>
);

export default BulkActionsChooseActionStepActionsMeasureWrapper;
