import { useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Box from "ds/components/Box";
import { WorkerPoolUser } from "types/generated";
import { MenuSpaces } from "components/icons";
import ProjectTypeBadge, { ProjectType } from "components/ProjectTypeBadge";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";

type WorkerPoolUsedByListItemProps = {
  item: WorkerPoolUser;
  setRowHeight?: (size: number) => void;
};

const WorkerPoolUsedByListItem = ({ item, setRowHeight }: WorkerPoolUsedByListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box direction="row" align="center">
        <ProjectTypeBadge projectType={item.isModule ? ProjectType.Module : ProjectType.Stack} />
      </Box>

      <Box direction="row" align="center">
        <ListEntitiesItemLink
          to={`/${item.isModule ? "module" : "stack"}/${item.stackId}`}
          title={item.stackName}
          v5Compat
        />
      </Box>

      <MetaInfoListItem
        icon={MenuSpaces}
        linkText={item.spaceDetails.name}
        href={`/spaces/${item.spaceDetails.id}`}
      />
    </ListEntitiesItem>
  );
};

export default WorkerPoolUsedByListItem;
