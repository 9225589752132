import { useLayoutEffect, useRef, useState } from "react";

const useContainerSize = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(containerRef?.current?.offsetWidth || 0);
  const [height, setHeight] = useState(containerRef?.current?.offsetWidth || 0);

  const handleResize = () => {
    if (containerRef.current) {
      const currentWidth = containerRef.current.offsetWidth;
      if (currentWidth !== width) {
        setWidth(currentWidth);
      }

      const currentHeight = containerRef.current.offsetHeight;

      if (currentHeight !== height) {
        setHeight(currentHeight);
      }
    }
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    containerRef,
    width,
    height,
    handleResize,
  };
};

export default useContainerSize;
