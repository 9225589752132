import { Controller, useFormContext } from "react-hook-form";
import cx from "classnames";

import Box from "ds/components/Box";
import DragDropFileUpload from "ds/components/FileUpload/DragDropFileUpload";
import Typography from "ds/components/Typography";
import { validateFileSize } from "utils/formValidators";

import styles from "./styles.module.css";

type ContextConfigFormFileUploadFieldProps = {
  name: string;
  required: boolean;
  className?: string;
};

const ContextConfigFormFileUploadField = ({
  name,
  required,
  className,
}: ContextConfigFormFileUploadFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? "File is required" : false,
        validate: (value) => validateFileSize(value, 2_000_000),
      }}
      render={({ field, fieldState }) => (
        <Box direction="column" gap="medium" zeroMinWidth>
          <DragDropFileUpload
            ref={field.ref}
            name={field.name}
            file={field.value}
            onChange={field.onChange}
            textAlign="left"
            caption="Drop the file here"
            dragBoxClassName={className}
            className={cx(!field.value && styles.button)}
          />
          {fieldState.error && (
            <Typography tag="span" variant="p-body4" color="danger">
              {fieldState.error.message}
            </Typography>
          )}
          {!fieldState.invalid && (
            <Typography tag="span" variant="p-body4" color="secondary">
              File limit is 0,5 MB. Larger files will be available only in preview mode.
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default ContextConfigFormFileUploadField;
