import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  StackAwsIntegrationAttachment,
  StackAzureIntegrationAttachment,
  StackIntegrationGcp,
} from "types/generated";

import { ATTACH_AWS_INTEGRATION, ATTACH_AZURE_INTEGRATION, ATTACH_GCP_INTEGRATION } from "../gql";
import { ModuleCreationCloud } from "../../types";
import { useNewModuleAnalyticsSegmentEvent } from "../../useNewModuleAnalyticsSegmentEvent";

type SharedParams = {
  integrationId: string;
  read: boolean;
  write: boolean;
};

type AwsParams = SharedParams & {
  type: ModuleCreationCloud.AWS;
};

type AzureParams = SharedParams & {
  type: ModuleCreationCloud.Azure;
  azureSubscriptionId?: string;
};

type GcpParams = {
  type: ModuleCreationCloud.GCP;
  scopes: string[];
};

type Params = AwsParams | AzureParams | GcpParams;

type Options = {
  moduleId: string;
  onSuccess?: () => void;
  refetchQueries?: string[];
};

const useAttachCloud = ({ onSuccess, moduleId, refetchQueries }: Options) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [isIntegrationAwsWarningVisible, setIsIntegrationAwsWarningVisible] = useState(false);
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const [attachAwsIntegration, { loading: attachAwsLoading }] = useMutation<{
    awsIntegrationAttach: StackAwsIntegrationAttachment;
  }>(ATTACH_AWS_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const hideIntegrationAwsWarning = useCallback(() => setIsIntegrationAwsWarningVisible(false), []);

  const [attachAzureIntegration, { loading: attachAzureLoading }] = useMutation<{
    azureIntegrationAttach: StackAzureIntegrationAttachment;
  }>(ATTACH_AZURE_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [attachGcpIntegration, { loading: attachGcpLoading }] = useMutation<{
    stackIntegrationGcpCreate: StackIntegrationGcp;
  }>(ATTACH_GCP_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const attachAws = useCallback(
    async ({ integrationId, read, write }: AwsParams) => {
      return attachAwsIntegration({
        variables: {
          read,
          write,
          integrationId,
          stackId: moduleId,
        },
      })
        .then(({ data }) => {
          if (data?.awsIntegrationAttach?.id) {
            reportSuccess({ message: "AWS integration attached to module" });
            trackSegmentEvent("AWS integration attached");
            onSuccess?.();
          }
        })
        .catch((e) => {
          if (
            e.message === "you need to configure trust relationship section in your AWS account"
          ) {
            setIsIntegrationAwsWarningVisible(true);
          }
          onError(e);
        });
    },
    [
      attachAwsIntegration,
      reportSuccess,
      onError,
      setIsIntegrationAwsWarningVisible,
      moduleId,
      trackSegmentEvent,
      onSuccess,
    ]
  );

  const attachAzure = useCallback(
    async ({ integrationId, read, write, azureSubscriptionId }: AzureParams) => {
      return attachAzureIntegration({
        variables: {
          read,
          write,
          integrationId,
          stackId: moduleId,
          subscriptionId: azureSubscriptionId,
        },
      })
        .then(({ data }) => {
          if (data?.azureIntegrationAttach?.id) {
            reportSuccess({ message: "Azure integration attached to module" });
            trackSegmentEvent("Azure integration attached");
            onSuccess?.();
          }
        })
        .catch(onError);
    },
    [attachAzureIntegration, reportSuccess, onError, moduleId, trackSegmentEvent, onSuccess]
  );

  const attachGcp = useCallback(
    async ({ scopes }: GcpParams) => {
      return attachGcpIntegration({
        variables: {
          moduleId,
          scopes,
        },
      })
        .then(({ data }) => {
          if (data?.stackIntegrationGcpCreate?.activated) {
            reportSuccess({ message: "GCP integration attached to module" });
            trackSegmentEvent("GCP integration attached");
            onSuccess?.();
          }
        })
        .catch(onError);
    },
    [attachGcpIntegration, reportSuccess, onError, moduleId, trackSegmentEvent, onSuccess]
  );

  const attach = useCallback(
    (params: Params) => {
      switch (params.type) {
        case ModuleCreationCloud.AWS:
          return attachAws(params);
        case ModuleCreationCloud.Azure:
          return attachAzure(params);
        case ModuleCreationCloud.GCP:
          return attachGcp(params);
      }
    },
    [attachAws, attachAzure, attachGcp]
  );

  return {
    attach,
    attachLoading: attachAzureLoading || attachAwsLoading || attachGcpLoading,
    isIntegrationAwsWarningVisible,
    hideIntegrationAwsWarning,
  };
};

export default useAttachCloud;
