import { createContext } from "react";

import { Module } from "types/generated";

type ModuleSettingsContextValue = {
  module: Module;
  canManageModule: boolean;
};

export const ModuleSettingsContext = createContext<ModuleSettingsContextValue | undefined>(
  undefined
);
