import { useFormContext } from "react-hook-form";

import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";
import CopyFieldIcon from "components/CopyField/Icon";
import useGetCloudIntegrationExternalId from "shared/CloudIntegration/useGetCloudIntegrationExternalId";

import styles from "./styles.module.css";

type StackSettingsIntegrationsCloudAttachFormAWSIntegrationWarningProps = {
  entityId: string;
};

const StackSettingsIntegrationsCloudAttachFormAWSIntegrationWarning = ({
  entityId,
}: StackSettingsIntegrationsCloudAttachFormAWSIntegrationWarningProps) => {
  const { watch } = useFormContext<{ read: boolean; write: boolean; integrationId: string }>();
  const [read, write, integrationId] = watch(["read", "write", "integrationId"]);

  const { externalId, parsedAssumeRolePolicyStatement } = useGetCloudIntegrationExternalId({
    entityId,
    integrationId,
    read,
    write,
  });

  return (
    <Banner variant="danger" title="Please add statement to “Trust relationship”">
      {externalId && (
        <Box direction="column" gap="small" margin="medium 0 0 0">
          <Box align="center" gap="small" fullWidth>
            <Typography tag="span" variant="p-t7">
              External ID
            </Typography>

            <CopyFieldIcon value={externalId} title="Copy" />
          </Box>
          <Box className={styles.idWrapper} fullWidth>
            <Typography tag="p" variant="p-body3">
              {externalId}
            </Typography>
          </Box>
        </Box>
      )}

      <Typography tag="p" variant="p-body3" margin="large 0 0 0">
        Copy the statement below and add to its “Trust relationship” section:
      </Typography>

      {parsedAssumeRolePolicyStatement && (
        <Box className={styles.codeWrapper} margin="small 0 0 0">
          <CopyFieldIcon
            className={styles.codeCopyButton}
            value={parsedAssumeRolePolicyStatement}
            title="Copy"
          />
          <pre className={styles.code}>{parsedAssumeRolePolicyStatement}</pre>
        </Box>
      )}
    </Banner>
  );
};

export default StackSettingsIntegrationsCloudAttachFormAWSIntegrationWarning;
