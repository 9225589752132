import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

import { REMOVE_VIEW } from "./gql";

type DeleteConfirmationProps = {
  id: string;
  name: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  name,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteView, { loading, data }] = useMutation(REMOVE_VIEW, {
    refetchQueries: ["SavedViewsList", "SavedView"],
    variables: { id },
  });

  const onDelete = () => {
    deleteView()
      .then(() => {
        reportSuccess({ message: `View ${name} successfully deleted` });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete saved view"
      name={name}
      onConfirm={onDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.savedFilterDelete?.id}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
