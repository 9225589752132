import { useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SearchSuggestionsFieldType, SearchSuggestionsOutput } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import {
  getFiltersPredicationFromURI,
  makeFilterItemOptionsFromSuggestionField,
} from "components/Filters/helpers";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import { useCachedFilterFields } from "components/Filters/hooks";

import { FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "./constants";
import { SEARCH_TEMPLATES_SUGGESTIONS } from "./gql";

const EXCLUDED_FILTER_FIELDS = ["name", "description", "body"];

const useSearchTemplatesSuggestions = () => {
  const fieldsRef = useRef<string[] | null>(null);
  const { onError } = useTypedContext(FlashContext);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { data, loading, refetch, previousData } = useQuery<{
    searchPolicyTemplatesSuggestions: SearchSuggestionsOutput;
  }>(SEARCH_TEMPLATES_SUGGESTIONS, {
    variables: {
      input: {
        predicates,
        fullTextSearch: searchInput,
        fields: fieldsRef.current,
      },
    },
    onError,
  });

  const cachedFiltersData = useCachedFilterFields(data?.searchPolicyTemplatesSuggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return cachedFiltersData
      .filter((field) => field.orderable)
      .map((field) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
  }, [cachedFiltersData]);

  const [filters, filtersMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    let labelsCounter = 0;
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    const filterItems = cachedFiltersData
      .filter((field) => field.filterable && !EXCLUDED_FILTER_FIELDS.includes(field.name))
      .map((field) => {
        let key = field.name;

        if (key === "label") {
          labelsCounter += 1;
          key = `label${labelsCounter}`;
        } else {
          key = FILTER_ITEMS_DICTIONARY[key as FilterItemKeys] || key;
        }

        const options = makeFilterItemOptionsFromSuggestionField(field);

        if (options) {
          filtersItemsOptionsMap.set(field.name, options);
        }

        return {
          key,
          filterName: field.name,
          //SearchSuggestionsFieldType is only available if the field is filterable
          type: field.type as SearchSuggestionsFieldType,
        };
      });

    return [filterItems, filtersItemsOptionsMap];
  }, [cachedFiltersData]);

  const handleRefetchActiveSections = (fields: string[]) => {
    fieldsRef.current = fields.length > 0 ? fields : null;

    if (fields.length > 0) {
      refetch({
        input: {
          fullTextSearch: searchInput,
          predicates,
          fields,
        },
      });
    }
  };

  const previousSearchRunsSuggestions = previousData?.searchPolicyTemplatesSuggestions;

  return {
    filters,
    filtersMap,
    sortOptions,
    loading,
    refetchActiveSections: handleRefetchActiveSections,
    filteredCount:
      data?.searchPolicyTemplatesSuggestions?.filteredCount ||
      previousSearchRunsSuggestions?.filteredCount ||
      0,
  };
};

export default useSearchTemplatesSuggestions;
