import Box from "ds/components/Box";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { VcsIntegrationDetails, VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldViewText from "components/FormFields/ViewText";
import DescriptionDetails from "components/DescriptionDetails";

import VCSProviderIcon from "../../components/ProviderIcon";
import {
  BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION,
  BUILT_IN_GITHUB_INTEGRATION_NAME,
} from "./constants";
import { getIntegrationDetails } from "../../components/Details/helpers";

type VCSIntegrationsBuiltInGitHubDetailsDrawerProps = {
  isActive: boolean;
  onCloseDetails: () => void;
  details: VcsIntegrationDetails | undefined;
};

const VCSIntegrationsBuiltInGitHubDetailsDrawer = ({
  details,
  isActive,
  onCloseDetails,
}: VCSIntegrationsBuiltInGitHubDetailsDrawerProps) => {
  return (
    <Drawer position="absoluteRight" visible={isActive}>
      {details && (
        <>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Source code details" />
            <DrawerCloseIcon handleCloseDrawer={onCloseDetails} />
          </DrawerHeader>

          <DrawerBody>
            <FormField label="Provider">
              <Box gap="medium">
                <VCSProviderIcon provider={VcsProvider.Github} />

                <Typography tag="span" variant="p-body2">
                  {HUMANIZE_PROVIDER[VcsProvider.Github]}
                </Typography>
              </Box>
            </FormField>

            <FormFieldViewText label="Integration name" value={BUILT_IN_GITHUB_INTEGRATION_NAME} />

            {getIntegrationDetails(details)}

            <DescriptionDetails description={BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION} />
          </DrawerBody>
        </>
      )}
    </Drawer>
  );
};

export default VCSIntegrationsBuiltInGitHubDetailsDrawer;
