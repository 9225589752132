import { gql } from "@apollo/client";

export const GET_ASPECT = gql`
  query GetAspect($input: AspectInput!) {
    usageAspect(input: $input) {
      granularity
      data {
        name
        timestamp
        value
      }
      summary {
        totals {
          name
          value
        }
        percentiles {
          percentile
          value
        }
      }
    }
  }
`;

export const GET_ASPECT_TOTALS = gql`
  query GetAspectTotals($input: AspectInput!) {
    usageAspect(input: $input) {
      summary {
        totals {
          name
          value
        }
      }
    }
  }
`;
