import { useLayoutEffect, useRef } from "react";

import Tag from "ds/components/Tag";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";

type TagsListFilterableNewSingleTagProps = {
  tag: string;
  tagOnClickHandler: (tag: string, callback?: () => void) => void;
  setTagSizes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  shouldHide: boolean;
  containerSize?: number;
  currentSize?: number;
};

const TagsListFilterableNewSingleTag = ({
  tag,
  tagOnClickHandler,
  setTagSizes,
  shouldHide,
  currentSize,
  containerSize,
}: TagsListFilterableNewSingleTagProps) => {
  const tagRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(() => {
    if (tagRef.current) {
      const newSize = tagRef.current?.clientWidth || 0;
      if (newSize !== currentSize) {
        setTagSizes((tags) => ({ ...tags, [tag]: newSize }));
      }
    }
    // Update when container size changed
  }, [tag, setTagSizes, containerSize, currentSize]);

  if (shouldHide) {
    return null;
  }

  return (
    <Tooltip
      ref={tagRef}
      on={({ ref, ...props }) => (
        <Tag
          {...props}
          ref={ref}
          className={styles.tag}
          tag={tag}
          onClick={() => tagOnClickHandler(tag)}
        />
      )}
    >
      Add to filters
    </Tooltip>
  );
};

export default TagsListFilterableNewSingleTag;
