import { useEffect, useRef } from "react";
import cx from "classnames";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { ManagedUser, UserStatus } from "types/generated";
import { pluralize } from "shared/Pluralize";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import Icon from "ds/components/Icon";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import { getProviderLogo } from "../helpers";
import { UserManagementActivationStatus } from "../../types";
import UsersListInviteDropdown from "./InviteDropdown";
import UsersListDropdown from "./Dropdown";
import UserRoleBadge from "./UserRoleBadge";
import UserStatusBadge from "./UserStatusBadge";
import { SettingsContext } from "../../Context";
import styles from "./styles.module.css";

type UsersListItemProps = {
  item: ManagedUser;
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  setRowHeight?: (size: number) => void;
};

const UsersListItem = ({ handleUserDetailsClick, item, setRowHeight }: UsersListItemProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const hasQueuedStatus = item.status === UserStatus.Queued;
  const hasPendingStatue = item.status === UserStatus.Pending;
  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  const isInvitation = hasQueuedStatus || hasPendingStatue;

  const ProviderIcon = getProviderLogo(item.loginMethod);

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      className={cx(
        (!isUserManagementActive || item.status === UserStatus.Inactive) && styles.item
      )}
    >
      <Box direction="row" align="center">
        <TextEllipsis tooltip={item.username} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {item.username}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box direction="row" align="center">
        <UserRoleBadge role={item.role} />
      </Box>

      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2">
          {`${item.spaceCount} ${pluralize("space", item.spaceCount)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2">
          {`${item.groupCount} ${pluralize("group", item.groupCount)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        {item.lastLoginTime && (
          <TextEllipsis tooltip={<Timestamp timestamp={item.lastLoginTime} />}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                {item.lastLoginTime && <Timestamp timestamp={item.lastLoginTime} />}
              </Typography>
            )}
          </TextEllipsis>
        )}
      </Box>
      <Box direction="row" align="center">
        {ProviderIcon && (
          <Typography tag="span" variant="p-body2">
            {typeof ProviderIcon === "string" ? (
              ProviderIcon
            ) : (
              <Icon src={ProviderIcon} size="large" />
            )}
          </Typography>
        )}
      </Box>
      <Box direction="row" align="center">
        {hasQueuedStatus && (
          <Tooltip
            widthMode="maxWidthSm"
            on={(props) => <UserStatusBadge status={item.status} {...props} />}
          >
            {!isUserManagementActive
              ? "Invite will be sent once management strategy is changed to user management"
              : ""}
          </Tooltip>
        )}
        {hasPendingStatue && (
          <Tooltip on={(props) => <UserStatusBadge status={item.status} {...props} />}>
            Waiting for the user to accept the invite
          </Tooltip>
        )}
        {!(hasQueuedStatus || hasPendingStatue) && <UserStatusBadge status={item.status} />}
      </Box>

      <Box direction="row" align="center">
        {!isInvitation && (
          <UsersListDropdown handleUserDetailsClick={handleUserDetailsClick} item={item} />
        )}
        {isInvitation && item.invitationEmail && (
          <UsersListInviteDropdown handleUserDetailsClick={handleUserDetailsClick} item={item} />
        )}
      </Box>
    </ListEntitiesItem>
  );
};

export default UsersListItem;
