import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type WorkerPoolDeleteActionProps = {
  onAction: () => void;
  hasUsers: boolean;
  hasBusyWorkers: boolean;
};

const hasUsersTooltip = (
  <>
    Before you delete the worker pool, you must make sure it is not used by any stacks or modules.
  </>
);

const hasBusyWorkersTooltip = (
  <>
    Please drain all workers first to make sure that no work is being performed by this worker pool.
  </>
);

const WorkerPoolDeleteAction = ({
  onAction,
  hasUsers,
  hasBusyWorkers,
}: WorkerPoolDeleteActionProps) => (
  <DropdownMenuItem
    tooltip={hasUsers && !hasBusyWorkers ? hasUsersTooltip : hasBusyWorkersTooltip}
    isTooltipActive={hasBusyWorkers || hasUsers}
    tooltipWidthMode="maxWidthSm"
    tooltipPlacement="left"
    onAction={onAction}
    danger
    isDisabled={hasBusyWorkers || hasUsers}
  >
    Delete
  </DropdownMenuItem>
);

export default WorkerPoolDeleteAction;
