export const uniqBy = <T>(arr: T[], getKey: (item: T) => string): T[] => {
  const seenKeys: Record<string, boolean> = {};
  const ret: T[] = [];

  arr.forEach((el) => {
    if (seenKeys[getKey(el)]) {
      return;
    }

    seenKeys[getKey(el)] = true;
    ret.push(el);
  });

  return ret;
};

export const uniqById = <T extends { id: string }>(arr: T[]): T[] => uniqBy(arr, (item) => item.id);

export const uniqByKey = <T>(arr: T[], key: keyof T): T[] =>
  uniqBy(arr, (item) => item[key] as string);
