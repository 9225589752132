import { gql } from "@apollo/client";

export const WEBHOOK_CREATE = gql`
  mutation WebhookCreate($input: NamedWebhooksIntegrationInput!) {
    namedWebhooksIntegrationCreate(input: $input) {
      id
      name
    }
  }
`;

export const WEBHOOK_UPDATE = gql`
  mutation WebhookUpdate($id: ID!, $input: NamedWebhooksIntegrationInput!) {
    namedWebhooksIntegrationUpdate(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const HEADERS_SET = gql`
  mutation WebhookHeaderSet($id: ID!, $input: NamedWebhookHeaderInput!) {
    namedWebhooksIntegrationSetHeaders(id: $id, input: $input) {
      id
    }
  }
`;

export const HEADERS_DELETE = gql`
  mutation WebhookHeaderDelete($id: ID!, $headerKeys: [String!]!) {
    namedWebhooksIntegrationDeleteHeaders(id: $id, headerKeys: $headerKeys) {
      id
    }
  }
`;
