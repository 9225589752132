import { gql } from "@apollo/client";

export const GET_BLUEPRINT = gql`
  query GetBlueprint($id: ID!) {
    blueprint(id: $id) {
      id
      deleted
      name
      description
      createdAt
      updatedAt
      state
      inputs {
        id
        name
        default
        description
        options
        type
      }
      space {
        id
        name
        accessLevel
      }
      labels
      rawTemplate
    }
    blueprintSchema
  }
`;

export const VALIDATE_BLUEPRINT_TEMPLATE = gql`
  mutation ValidateBlueprintTemplate($template: String!) {
    blueprintParseTemplate(template: $template) {
      errors
      inputs {
        id
        name
        default
        description
        options
        type
      }
    }
  }
`;
