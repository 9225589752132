import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

const SelectAutocompleteEmptyPlaceholder = () => {
  return (
    <Box padding="small">
      <Typography tag="p" variant="p-body3">
        No options found.
      </Typography>
    </Box>
  );
};

export default SelectAutocompleteEmptyPlaceholder;
