import { memo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Banner from "ds/components/Banner";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";

import { CreateIntegrationFields } from "./types";
import useCreateIntegration from "./useCreateIntegration";
import { GRANT_SLACK_ACCESS_DRAWER_TEST_ID, commonDefaultValues } from "./constants";
import { UserManagementActivationStatus } from "../../types";

type ManageSlackAccessCreateDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  activationStatus: UserManagementActivationStatus;
};

const ManageSlackAccessCreateDrawer = ({
  activationStatus,
  isDrawerVisible,
  setDrawerVisibility,
}: ManageSlackAccessCreateDrawerProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const groupIntegrationCreateForm = useForm<CreateIntegrationFields>({
    defaultValues: commonDefaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = groupIntegrationCreateForm;

  const { onCreate } = useCreateIntegration();

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const onSubmit: SubmitHandler<CreateIntegrationFields> = (formData) => {
    const input = {
      integrationType: formData.integrationType,
      integrationName: formData.name,
      accessRules: formData.spaces,
      slackChannelID: formData.slackChannelID || "",
    };
    onCreate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Slack access was granted`,
          });

          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  const isNameFieldEmpty = watch("name").length === 0;

  return (
    <Drawer
      visible={isDrawerVisible}
      onOutsideClick={handleCloseDrawer}
      dataTestId={GRANT_SLACK_ACCESS_DRAWER_TEST_ID}
    >
      <FormProvider {...groupIntegrationCreateForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Grant access" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight hasStickyFooter>
          {activationStatus === UserManagementActivationStatus.INACTIVE && (
            <Banner variant="warning" title="User management is inactive">
              Access rules wouldn’t take effect until you switch to user management.
            </Banner>
          )}
          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter the name here"
                error={!!errors?.name}
                {...register("name", {
                  required: "Name field is required.",
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Slack channel ID" error={errors?.slackChannelID?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter the Slack channel ID here"
                error={!!errors?.slackChannelID}
                {...register("slackChannelID", {
                  required: "Slack channel ID field is required.",
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldSpace isDisabled={!!errors?.name || isNameFieldEmpty} />

          <DrawerFooter sticky>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                Add
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(ManageSlackAccessCreateDrawer);
