import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import { RunsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import { BillingTierFeature } from "types/generated";
import TierInfo from "components/TierInfo";

import { StackContext } from "../Context";
import StackHeader from "../components/Header";
import { getStacksBackUrl } from "../helpers";

const StackIgnoredRunsFeatureGate = () => {
  const { stack: stackCtx } = useTypedContext(StackContext);

  useTitle(`Ignored runs · ${stackCtx.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  return (
    <>
      <StackHeader />

      <PageInfo title="Ignored runs" />

      <TierInfo
        type="callout"
        variant="promo"
        feature={BillingTierFeature.RunIgnoredTriggers}
        title="Upgrade plan to see ignored runs"
      >
        Ignored runs are not available in your current plan. Upgrade your plan to use the feature.
      </TierInfo>

      <PageWrapper>
        <EmptyState icon={RunsColored} title="No ignored runs yet" />
      </PageWrapper>
    </>
  );
};

export default StackIgnoredRunsFeatureGate;
