import { PolicyType, RunPolicyReceipt } from "types/generated";
import { Prettify } from "types/helpers";

interface PossibleRunPolicyReceipt {
  __typename?: string;
  policyType?: PolicyType;
}

export const isRunPolicyReceipt = <X extends PolicyType>(
  item: unknown,
  type: X
): item is Prettify<Omit<RunPolicyReceipt, "policyType"> & { policyType: X }> => {
  const possibleReceipt = item as PossibleRunPolicyReceipt;

  return possibleReceipt.__typename === "RunPolicyReceipt" && possibleReceipt.policyType === type;
};
