import cx from "classnames";

import useCopyToClipboard from "hooks/useCopyToClipboard";
import BaseAction, { BaseActionProps } from "ds/components/BaseAction";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";

type CopyFieldProps = {
  title: string;
  value: string;
  callback?: () => void;
  tooltip?: string;
} & BaseActionProps;

const CopyField = ({
  title,
  value,
  className,
  callback,
  tooltip,
  ...restProps
}: CopyFieldProps) => {
  const handleCopy = useCopyToClipboard(value, callback);

  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <BaseAction
          {...tooltipProps}
          {...restProps}
          onClick={handleCopy}
          className={cx(styles.field, className)}
        >
          {title}
        </BaseAction>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default CopyField;
