import cx from "classnames";

import ServiceAction, { ServiceActionProps } from "ds/components/ServiceAction";

import styles from "./styles.module.css";

type SubSidebarSectionItemProps = ServiceActionProps & {
  to: string;
  className?: string;
};

const SubSidebarSectionItem = ({ children, className, ...rest }: SubSidebarSectionItemProps) => {
  return (
    <ServiceAction {...rest} className={cx(styles.wrapper, className)} isNavLink>
      {children}
    </ServiceAction>
  );
};

export default SubSidebarSectionItem;
