import { gql, TypedDocumentNode } from "@apollo/client";

import { Maybe, RunTile, RunTileWithApproval, SpaceliftMetrics } from "types/generated";

type GetRecentlyApprovedData = {
  metrics: Maybe<{
    runsRecentlyApproved: Array<{
      approval: RunTileWithApproval["approval"];
      runTile: Omit<RunTile, "type">;
    }>;
    hasRuns: SpaceliftMetrics["hasRuns"];
  }>;
};

export const GET_RUNS_RECENTLY_APPROVED: TypedDocumentNode<GetRecentlyApprovedData> = gql`
  query GetRunsRecentlyApproved {
    metrics {
      runsRecentlyApproved {
        approval
        runTile {
          id
          title
          stackTile {
            slug
            name
          }
          createdAt
        }
      }
      hasRuns
    }
  }
`;
