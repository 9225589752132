import { memo, useEffect, useRef } from "react";

import useTypedContext from "hooks/useTypedContext";

import { ViewCustomizationContext } from "./Context";

type ViewCustomizationItemProps = {
  id: string;
  children: React.ReactNode;
  onVisibilityChange?: (isVisible: boolean) => void;
};

const ViewCustomizationItem = ({
  id,
  children,
  onVisibilityChange,
}: ViewCustomizationItemProps) => {
  const didMountRef = useRef(false);

  const { itemsVisibility } = useTypedContext(ViewCustomizationContext);

  const isVisible = itemsVisibility[id];

  useEffect(() => {
    if (didMountRef.current) {
      onVisibilityChange?.(isVisible);
    } else {
      didMountRef.current = true;
    }
  }, [isVisible, onVisibilityChange]);

  if (!isVisible) {
    return null;
  }

  return <>{children}</>;
};

export default memo(ViewCustomizationItem);
