import { useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import { ResourcesColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";

import ResourcesWrapper from "./Wrapper";
import useParallelQuery from "./useParallelQuery";

type EntitiesProps = {
  stackIds: string[];
};

const Entities = ({ stackIds }: EntitiesProps) => {
  const { results, finished, loaded, total } = useParallelQuery(stackIds);
  const { search } = useLocation();

  const stacks = useMemo(
    () => (finished ? stackIds.map((stackId) => results[stackId]) : []),
    [finished, results, stackIds]
  );

  const hasResources = useMemo(() => stacks.some((item) => item.entities.length > 1), [stacks]);
  if (finished && !hasResources) {
    return <EmptyState icon={ResourcesColored} title="No Resources to show" />;
  }

  return (
    <ResourcesWrapper
      data={{ stacks }}
      loaded={loaded}
      total={total}
      search={search}
      isAccountWide
    />
  );
};

export default Entities;
