import { useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ContextStackAttachment } from "types/generated";

import { DELETE_CONTEXT, GET_CONTEXT_ATTACHMENTS } from "../gql";

const useDeleteContext = (id: string) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const {
    data,
    loading: attachmentsLoading,
    error,
  } = useQuery<{
    context: {
      attachedStacks: ContextStackAttachment[];
      hasAttachedStacks: boolean;
    };
  }>(GET_CONTEXT_ATTACHMENTS, {
    variables: { id },
    onError,
    fetchPolicy: "no-cache",
  });

  const [deleteContext, { loading: deleteLoading }] = useMutation(DELETE_CONTEXT, {
    refetchQueries: ["SearchContexts"],
    awaitRefetchQueries: true,
    variables: { id, force: data?.context?.hasAttachedStacks },
    onError,
  });

  const onDelete = () => {
    return deleteContext()
      .then(({ data }) => {
        if (data?.contextDelete?.id) {
          reportSuccess({
            message: `Context "${data.contextDelete.name}" was successfully deleted`,
          });
        } else {
          reportError({
            message: "Something went wrong while deleting the context, please try again.",
          });
        }
      })
      .catch(onError);
  };

  const entityCount = useMemo(() => {
    const { hasAttachedStacks, attachedStacks } = data?.context ?? {};
    if (!hasAttachedStacks || !attachedStacks) {
      return { stacks: 0, modules: 0 };
    }

    return attachedStacks.reduce(
      (acc, stack) => {
        if (stack.isModule) {
          acc.modules++;
        } else {
          acc.stacks++;
        }
        return acc;
      },
      { stacks: 0, modules: 0 }
    );
  }, [data?.context]);

  return {
    attachedEntities: data?.context?.attachedStacks ?? [],
    attachmentsError: error,
    entityCount,
    loading: attachmentsLoading || deleteLoading,
    onDelete,
    pendingAttachments: attachmentsLoading,
  };
};

export default useDeleteContext;
