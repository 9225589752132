import { gql } from "@apollo/client";

export const CHECK_TERRAGRUNT_VERSION = gql`
  query CheckTerragruntVersion(
    $terragruntConstraints: String!
    $terraformConstraints: String!
    $tool: TerragruntTool
  ) {
    terragruntEffectiveVersion(
      terragruntConstraints: $terragruntConstraints
      terraformConstraints: $terraformConstraints
      tool: $tool
    ) {
      effectiveTerragruntVersion
      effectiveTerraformVersion
    }
  }
`;
