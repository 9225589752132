import { WebhooksIntegration, WebhooksIntegrationInput } from "types/generated";

import { type WebhookFormFields } from ".";

export const getFormDefaultValues = (
  webhook: WebhooksIntegration | undefined,
  isEditMode: boolean
): WebhookFormFields => ({
  endpoint: webhook?.endpoint || "",
  secret: webhook?.secret || "",
  enabled: isEditMode ? !!webhook?.enabled : true,
});

export const mapCreateWebhookInput = (formData: WebhookFormFields): WebhooksIntegrationInput => {
  return {
    endpoint: formData.endpoint.trim(),
    secret: formData.secret,
    enabled: formData.enabled,
  };
};
