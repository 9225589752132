import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { StackDependency, StackDependencyInput } from "types/generated";

import { CREATE_STACK_DEPENDENCIES_IN_BATCH } from "./gql";

const useCreateStackDependenciesInBatch = () => {
  const { onError } = useTypedContext(FlashContext);

  const [createStackDependenciesInBatch] = useMutation<{
    stackDependenciesCreateInBatch: Pick<StackDependency, "id">[];
  }>(CREATE_STACK_DEPENDENCIES_IN_BATCH, {
    refetchQueries: ["GetStack"],
    onError,
  });

  const handleCreateDependenciesInBatch = useCallback(
    (dependencies: StackDependencyInput[]) => {
      return createStackDependenciesInBatch({
        variables: {
          input: { dependencies },
        },
      });
    },
    [createStackDependenciesInBatch]
  );

  return {
    handleCreateDependenciesInBatch,
  };
};

export default useCreateStackDependenciesInBatch;
