import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActions/types";
import { useBulkDiscardRun } from "shared/Run/useDiscardRun";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";
import { useBulkSetPrioritizeRun } from "shared/Run/useSetPrioritizeRun";
import {
  canDeprioritizeRun,
  canPrioritizeRun,
} from "shared/Run/useSetPrioritizeRun/accessValidation";

import { BulkItemRunWithPosition, BulkWorkerPoolQueuedRunsActions } from "./types";

const useWorkerRunsBulkActions = (isPublicWorkerPool: boolean) => {
  const [runDiscard] = useBulkDiscardRun();
  const [runSetPrioritize] = useBulkSetPrioritizeRun();

  return useMemo<
    Array<BulkEntityActionItem<BulkWorkerPoolQueuedRunsActions, BulkItemRunWithPosition, undefined>>
  >(
    () => [
      {
        key: BulkWorkerPoolQueuedRunsActions.PRIORITIZE,
        title: "Prioritize",
        resultTitle: "Prioritize runs results",
        condition: (runWithPosition) => canPrioritizeRun(runWithPosition.run, isPublicWorkerPool),
        mutation: (run) => runSetPrioritize({ stack: run.stackId, run: run.id, prioritize: true }),
      },
      {
        key: BulkWorkerPoolQueuedRunsActions.DE_PRIORITIZE,
        title: "De-prioritize",
        resultTitle: "De-prioritize runs results",
        condition: (runWithPosition) => canDeprioritizeRun(runWithPosition.run),
        mutation: (run) => runSetPrioritize({ stack: run.stackId, run: run.id, prioritize: false }),
      },
      {
        key: BulkWorkerPoolQueuedRunsActions.DISCARD,
        title: "Discard",
        resultTitle: "Discard runs results",
        condition: (runWithPosition) => canDiscardRun(runWithPosition.run),
        mutation: (run) => runDiscard({ stack: run.stackId, run: run.id, note: null }),
      },
    ],
    [isPublicWorkerPool, runDiscard, runSetPrioritize]
  );
};

export default useWorkerRunsBulkActions;
