import { RunStateTransition } from "types/generated";

import DestroyingEntry from "../entries/DestroyingEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";
import { canShowElement } from "./elements";
import { RunElementType, RunEntryContext } from "../types";
import { mergeWithAfterRunHooks } from "./mergeWithAfterRunHooks";

export const createDestroyingEntry = (
  { timestamp, until, stateVersion, hasLogs }: RunStateTransition,
  { runHooks, runId, stackId, isModuleRun, isRunFinished }: RunEntryContext
) => {
  let afterHooks = runHooks.afterDestroy;

  if (isRunFinished && canShowElement(RunElementType.AfterRunHooks)) {
    afterHooks = mergeWithAfterRunHooks(afterHooks, runHooks.afterRun);
  }

  return (
    <DestroyingEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      hasLogs={hasLogs}
      stateVersion={stateVersion}
      openLogsByDefault={canShowElement(RunElementType.Logs)}
      beforeHooks={runHooks.beforeDestroy}
      afterHooks={afterHooks}
      runId={runId}
      stackId={stackId}
      isModuleRun={isModuleRun}
    />
  );
};
