import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { Policy, PolicyType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { POLICY_TYPES_TITLES } from "constants/policy";
import { SelectOption } from "ds/components/Select/types";

import { GET_ATTACHABLE_POLICIES_IN_SPACE } from "./gql";

type AttachablePolicyOption = SelectOption & {
  type: PolicyType;
};

type AttachablePolicyTypeOption = SelectOption & {
  value: PolicyType;
};

type UseGetAttachablePoliciesProps = {
  spaceId: string;
  alreadyAttachedPolicies: Set<string>;
  skip?: boolean;
};

type UseGetAttachablePoliciesData = {
  policyTypesOptions: AttachablePolicyTypeOption[];
  policiesOptions: AttachablePolicyOption[];
  loading: boolean;
};

const useGetAttachablePolicies = ({
  spaceId,
  alreadyAttachedPolicies,
  skip,
}: UseGetAttachablePoliciesProps): UseGetAttachablePoliciesData => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{
    attachablePoliciesInSpace: Policy[];
  }>(GET_ATTACHABLE_POLICIES_IN_SPACE, {
    onError,
    variables: {
      spaceId,
    },
    skip,
  });

  const [policiesOptions, policyTypesOptions] = useMemo(() => {
    const policiesOptions: AttachablePolicyOption[] = [];
    const policyTypesOptions: Partial<Record<PolicyType, AttachablePolicyTypeOption>> = {};

    for (const policy of data?.attachablePoliciesInSpace || []) {
      // filter out Notification policies, they are not attachable to stacks/modules
      if (policy.type === PolicyType.Notification) {
        continue;
      }

      if (alreadyAttachedPolicies.has(policy.id)) {
        continue;
      }

      policiesOptions.push({
        value: policy.id,
        label: policy.name,
        type: policy.type,
      });

      policyTypesOptions[policy.type] = {
        value: policy.type,
        label: POLICY_TYPES_TITLES[policy.type] || policy.type,
      };
    }

    return [policiesOptions, Object.values(policyTypesOptions)];
  }, [alreadyAttachedPolicies, data?.attachablePoliciesInSpace]);

  return {
    policyTypesOptions,
    policiesOptions,
    loading,
  };
};

export default useGetAttachablePolicies;
