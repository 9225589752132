import { ChangeEvent, MouseEvent, Ref } from "react";
import cx from "classnames";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import TextEllipsis from "ds/components/TextEllipsis";
import { SpaceAccessLevel } from "types/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";
import { getOptionIconSrc } from "./helpers";

type FormFieldsSpacesRoleOptionProps = {
  value: string;
  label: string;
  onChange?: (value: string) => void;
  closeSelect?: () => void;
  className?: string;
  innerRef?: Ref<HTMLElement>;
  disabled?: boolean;
  checked?: boolean;
};

const FormFieldsSpacesRoleOption = (props: FormFieldsSpacesRoleOptionProps) => {
  const { disabled, value, label, onChange, closeSelect, className, innerRef, checked } = props;

  const handleOnChange = (e?: ChangeEvent<HTMLInputElement> | MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (disabled) return false;

    onChange?.(value);
    closeSelect?.();

    return undefined;
  };

  const optionIconSrc = getOptionIconSrc(value as SpaceAccessLevel);

  return (
    <DropdownSectionItem
      key={value}
      onClick={handleOnChange}
      innerRef={innerRef}
      className={cx(className, styles.option)}
      active={checked}
    >
      {optionIconSrc && <Icon src={optionIconSrc} noShrink />}
      <TextEllipsis tooltip={label} delay={400}>
        {(ellipsisProps) => <span {...ellipsisProps}>{label}</span>}
      </TextEllipsis>
    </DropdownSectionItem>
  );
};

export default FormFieldsSpacesRoleOption;
