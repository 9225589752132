import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import { scrollToElementById } from "utils/element";
import useTitle from "hooks/useTitle";
import EmptyState from "ds/components/EmptyState";
import { OutputsColored } from "components/icons";

import { StackContext } from "../Context";
import { GET_STACK_OUTPUTS } from "./gql";
import OutputsItem from "./Item";
import styles from "./styles.module.css";
import StackHeader from "../components/Header";
import { getStacksBackUrl } from "../helpers";

const StackOutputs = () => {
  const { hash } = useLocation();
  const { onError } = useTypedContext(FlashContext);
  const { stack: stackCtx } = useTypedContext(StackContext);

  const { error, loading, data } = useQuery<{ stack: Stack }>(GET_STACK_OUTPUTS, {
    onError,
    variables: { stackId: stackCtx.id },
  });

  useTitle(`Outputs · ${stackCtx.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  const callback = () => {
    if (hash) {
      /**
       * FYI: setTimeout is needed to wait for the editor to be mounted and rendered before scrolling
       * TODO: wait for properly Monaco onMount implementation,
       * in the current version no way to guarantee that the Monaco editor loaded all styles,
       * languages, etc that is affect scrolling to the right anchor position
       */
      setTimeout(() => scrollToElementById(hash.replace("#", "")), 800);
    }
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  return (
    <>
      <StackHeader />
      <PageInfo title="Outputs" />
      <PageWrapper>
        {data?.stack && data?.stack.outputs.length === 0 && (
          <EmptyState
            icon={OutputsColored}
            title="No outputs yet"
            caption="This stack does not export any outputs"
          />
        )}
        {data?.stack && data?.stack.outputs.length > 0 && (
          <div>
            <ul className={styles.list}>
              {data?.stack.outputs.map((item, i) => (
                <OutputsItem
                  key={item.id}
                  item={item}
                  {...(i === data.stack.outputs.length - 1 && { onEditorMount: callback })}
                />
              ))}
            </ul>
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export default StackOutputs;
