import React from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";

type NotificationsPageLayoutProps = {
  children: React.ReactNode;
};

const NotificationsPageLayout = ({ children }: NotificationsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Notifications · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Notification Inbox</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            placeholder="Search by name, ID, type, etc..."
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />
        </ViewHeaderWrapper>
      </ViewHeader>

      {children}
    </>
  );
};

export default NotificationsPageLayout;
