import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Worker } from "types/generated";

import PrivateWorkerPoolWorkersListItem, { type PrivateWorkerPoolWorkersListItemProps } from ".";

type PrivateWorkerPoolWorkersVirtualizedListItemProps = {
  items: Worker[];
  workerPoolId: string;
  canManageWorkerPools: boolean;
  selectedSet: Set<string>;
  onCheckItem: PrivateWorkerPoolWorkersListItemProps["onCheckItem"];
};

const PrivateWorkerPoolWorkersVirtualizedListItem = (
  props: ListChildComponentProps<
    PrivateWorkerPoolWorkersVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <PrivateWorkerPoolWorkersListItem
        key={data.items[index].id}
        worker={data.items[index]}
        workerPoolId={data.workerPoolId}
        setRowHeight={handleSetRowHeight}
        canManageWorkerPools={data.canManageWorkerPools}
        checked={data.selectedSet.has(data.items[index].id)}
        onCheckItem={data.onCheckItem}
      />
    </div>
  );
};

export default memo(PrivateWorkerPoolWorkersVirtualizedListItem, areEqual);
