import { ReactNode, RefObject } from "react";

import useTypedContext from "hooks/useTypedContext";

import { BulkActionsDataContext } from "../Context";
import { BulkActionsStep, BulkActionsVariant } from "../types";
import BulkActionsFloatingBarWrapper from "./Wrapper";

type BulkActionsFloatingBarProps = {
  children: ReactNode;
  isFixed?: boolean;
  isHidden?: boolean;
  virtualizedListContainerRef?: RefObject<HTMLElement>;
};

const BulkActionsFloatingBar = ({
  children,
  isHidden,
  isFixed,
  virtualizedListContainerRef,
}: BulkActionsFloatingBarProps) => {
  const { isBulkActionsActive, bulkActionsVariant, bulkActionsStep } =
    useTypedContext(BulkActionsDataContext);

  const isFloatingBarVisible =
    !isHidden &&
    isBulkActionsActive &&
    bulkActionsVariant === BulkActionsVariant.FloatingBar &&
    bulkActionsStep !== BulkActionsStep.Results;

  return (
    <BulkActionsFloatingBarWrapper
      isFixed={isFixed}
      isVisible={isFloatingBarVisible}
      virtualizedListContainerRef={virtualizedListContainerRef}
    >
      {children}
    </BulkActionsFloatingBarWrapper>
  );
};

export default BulkActionsFloatingBar;
