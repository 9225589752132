import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type MetaInfoListProps = {
  className?: string;
  children: React.ReactNode;
};

const MetaInfoList = ({ children, className }: MetaInfoListProps) => {
  return <div className={cx(styles.metaInfoList, className)}>{children}</div>;
};

export default MetaInfoList;
