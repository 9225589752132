import { useMutation } from "@apollo/client";
import { useFormContext } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GET_STATE_UPLOAD_URL } from "./gql";
import { uploadFileToS3 } from "./uploadFileToS3";
import { StackVendorFormFields } from "../../types";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";

export const useFileUpload = () => {
  const { onError } = useTypedContext(FlashContext);
  const { setValue, setError, clearErrors } = useFormContext<StackVendorFormFields>();
  const [getStateUploadUrl] = useMutation<{ stateUploadUrl: { objectId: string; url: string } }>(
    GET_STATE_UPLOAD_URL
  );

  const analyticsVersion = useStackCreationAnalyticsVersion();
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const handleStateFileChange = async (file: File | null) => {
    if (!file) {
      setValue("terraform.uploadedStateFile", null);
      setValue("terraform.uploadedStateObjectId", "", {
        shouldValidate: true,
      });
      clearErrors("terraform.uploadedStateFile");

      return;
    }

    setValue("terraform.uploadedStateFile", file);

    return getStateUploadUrl()
      .then(({ data }) => {
        if (data?.stateUploadUrl) {
          return Promise.all([
            data?.stateUploadUrl,
            uploadFileToS3(file, data?.stateUploadUrl?.url),
          ]);
        }

        throw Error("Internal error");
      })
      .then(([stateUploadUrl]) => {
        setValue("terraform.uploadedStateObjectId", stateUploadUrl.objectId, {
          shouldValidate: true,
        });
        trackSegmentEvent("State file imported", {
          provider: "Terraform / OpenTofu",
          version: analyticsVersion,
        });
      })
      .catch((error) => {
        setValue("terraform.uploadedStateObjectId", "", {
          shouldValidate: true,
        });

        setError("terraform.uploadedStateFile", {
          message: "We had a problem processing your file",
        });

        onError(error);
      });
  };

  return handleStateFileChange;
};
