import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { ManagedUserGroup } from "types/generated";

import IdpGroupMappingListItem from "./Item";

type IdpGroupMappingListVirtualizedItemProps = {
  groups: ManagedUserGroup[];
  handleGroupClick: (details: ManagedUserGroup) => unknown;
};

const IdpGroupMappingListVirtualizedItem = (
  props: ListChildComponentProps<
    IdpGroupMappingListVirtualizedItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const item = data.groups[index];
  const key = `${index} ${item.id}`;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <IdpGroupMappingListItem
        key={key}
        item={item}
        handleGroupClick={data.handleGroupClick}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(IdpGroupMappingListVirtualizedItem, areEqual);
