import { memo } from "react";

import Filters from "components/Filters";
import { FilterItem, SortOption } from "components/Filters/types";
import { VCS_INTEGRATIONS_LIST_FILTERS_KEY } from "views/Account/VCS/constants";

import { INITIAL_SORT_DIRECTION, INITIAL_SORT_OPTION } from "../constants";
import VCSIntegrationsFiltersLayoutHeader from "./Header";

// FYI: static sort options are used instead
const emptySortOptions: SortOption[] = [];
const emptyFilters: FilterItem[] = [];
const emptyFiltersMap = new Map();
const mockedFunction = () => undefined;

const VCSIntegrationsFiltersLayout = () => {
  return (
    // NO FILTERS PANEL ONLY SORTING HEADER
    <Filters
      filters={emptyFilters}
      filtersItemsOptionsMap={emptyFiltersMap}
      filtersLoading={false}
      sortOptions={emptySortOptions}
      initialSortOption={INITIAL_SORT_OPTION}
      initialSortDirection={INITIAL_SORT_DIRECTION}
      pollActiveSections={mockedFunction}
      filtersOrderSettingsKey={VCS_INTEGRATIONS_LIST_FILTERS_KEY}
      filtersType="vcsIntegrations"
      setCurrentSavedView={mockedFunction}
    >
      <VCSIntegrationsFiltersLayoutHeader />
    </Filters>
  );
};

export default memo(VCSIntegrationsFiltersLayout);
