import fuzzysort from "fuzzysort";

import { SelectOptionHighlight } from "./types";

type SelectOptionHighlightsProps = {
  fullText: string;
  highlights?: SelectOptionHighlight;
};

const SelectOptionHighlights = ({ highlights, fullText }: SelectOptionHighlightsProps) => {
  if (!highlights) return <>{fullText}</>;

  return (
    <>
      {fuzzysort.highlight(highlights, (m, i) => (
        <strong key={i}>{m}</strong>
      ))}
    </>
  );
};

export default SelectOptionHighlights;
