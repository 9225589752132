import { useCallback, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";

import { SPACELIFT_LOGINS_COOKIE } from "constants/cookies";

const getCookieParts = () => {
  const hostnameParts = window.location.hostname.split(".");
  const domain = `.${hostnameParts.slice(-2).join(".")}`;
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);

  return {
    expiryDate,
    domain,
    hostnameParts,
  };
};

const useSpaceliftLoginsCookie = (shouldSetCookieLogin = false) => {
  const [cookies, setCookie] = useCookies([SPACELIFT_LOGINS_COOKIE]);

  const cookieLogins = useMemo(
    () => (cookies[SPACELIFT_LOGINS_COOKIE] ? cookies[SPACELIFT_LOGINS_COOKIE].split(",") : []),
    [cookies]
  );

  const setCookieLogins = useCallback(
    (newCookieLogins: string[]) => {
      const { expiryDate, domain } = getCookieParts();

      setCookie(SPACELIFT_LOGINS_COOKIE, newCookieLogins.join(","), {
        domain,
        expires: expiryDate,
      });
    },
    [setCookie]
  );

  useEffect(() => {
    if (!shouldSetCookieLogin) {
      return;
    }

    const { expiryDate, domain, hostnameParts } = getCookieParts();

    const currentLogin = hostnameParts[0];
    if (cookieLogins.includes(currentLogin)) {
      return;
    }

    cookieLogins.push(currentLogin);
    setCookie(SPACELIFT_LOGINS_COOKIE, cookieLogins.join(","), {
      domain,
      expires: expiryDate,
    });
  }, [cookieLogins, setCookie, shouldSetCookieLogin]);

  return { cookieLogins, setCookieLogins };
};

export default useSpaceliftLoginsCookie;
