import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Typography from "ds/components/Typography";

type GpgKeyListItemDropdownDeleteConfirmationProps = {
  onConfirm: () => void;
  keyName: string;
};

const GpgKeyListItemDropdownDeleteConfirmation = create(
  function GpgKeyListItemDropdownDeleteConfirmation({
    onConfirm,
    keyName,
  }: GpgKeyListItemDropdownDeleteConfirmationProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      onConfirm();
      modal.hide();
    };

    return (
      <DeleteConfirmationModal
        size="large"
        onConfirm={handleOnConfirm}
        confirmationButtonLabel="Revoke"
      >
        <ConfirmationModalMessage>
          Are you sure you want to revoke{" "}
          <Typography tag="span" variant="p-t6">
            {keyName}
          </Typography>
          ?
        </ConfirmationModalMessage>
      </DeleteConfirmationModal>
    );
  }
);

export const showGpgKeyListItemDropdownDeleteConfirmation = (
  props: GpgKeyListItemDropdownDeleteConfirmationProps
) => show(GpgKeyListItemDropdownDeleteConfirmation, props);
