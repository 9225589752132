export enum DeltaType {
  ADD = "add",
  CHANGE = "change",
  CHANGED = "changed",
  IMPORT = "import",
  MOVE = "move",
  DELETE = "delete",
  FORGET = "forget",
  IGNORED = "ignored",
  RESCUED = "rescued",
  SKIPPED = "skipped",
  UNREACHABLE = "unreachable",
  FAILED = "failed",
  OK = "ok",
}
