const ACCESS = (
  <>
    Access policy can give read or write access to individual stacks or modules to non-admin users
    in your account.
  </>
);

const APPROVAL = <>Approval policy defines rules for approving (or rejecting) certain workloads.</>;

const INITIALIZATION = (
  <>
    Initialization policy can prevent a run from being initialized based on a combination of
    stack/module, run and commit data as well as committed runtime configuration.
  </>
);

const LOGIN = (
  <>
    Login policy can allow users to log in to the account, and optionally give them admin
    privileges, too.
  </>
);

const PLAN = (
  <>
    Plan policy can fail a run during a planning phase based on Spacelift stack or module, run and
    commit details as well as resource changes from the change preview (plan).
    <br />
    <br />
    It can also generate warnings that won't prevent the changes from being deployed, but can alert
    humans to possible issues.
  </>
);

const PUSH = (
  <>
    Git push policy determines what to do with a webhook notifying Spacelift about a Git push to one
    of the tracked repos.
  </>
);

const TASK = (
  <>
    Task run policy can prevent a command from running based on a combination of request, stack and
    user session details.
  </>
);

const TRIGGER = (
  <>
    Trigger policy is a "positive" policy in a sense that it does not prevent anything from
    happening but rather "selects" the stacks to trigger tracked runs for.
  </>
);

const NOTIFICATION = (
  <>Notification policy allows you to configure the notifications which are sent by Spacelift.</>
);

const tooltips = {
  ACCESS,
  APPROVAL,
  INITIALIZATION,
  LOGIN,
  PLAN,
  PUSH,
  TASK,
  TRIGGER,
  NOTIFICATION,
};

export default tooltips;
