import { gql } from "@apollo/client";

export const GET_STACK_LOGS_DOWNLOAD_URL = gql`
  query GetStackLogsDownloadURL($stackId: ID!, $runId: ID!, $state: RunState!, $stateVersion: Int) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        logsDownloadURL(state: $state, stateVersion: $stateVersion) {
          url
          expired
        }
      }
    }
  }
`;

export const GET_MODULE_LOGS_DOWNLOAD_URL = gql`
  query GetModuleLogsDownloadURL($moduleId: ID!, $runId: ID!, $state: RunState!) {
    module(id: $moduleId) {
      id
      run(id: $runId) {
        id
        logsDownloadURL(state: $state) {
          url
          expired
        }
      }
    }
  }
`;
