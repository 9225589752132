import {
  CogThin,
  MenuCloudIntegrations,
  MenuContexts,
  MenuPolicies,
  Output,
} from "components/icons";

import { ModuleCreationWizardStep } from "../types";

export const config = [
  {
    title: "Share module",
    description: "Share the module between Spacelift accounts",
    action: {
      title: "Share module",
      step: ModuleCreationWizardStep.Share,
    },
    icon: Output,
  },
  {
    title: "Define module behavior",
    description: "Define additional module settings",
    action: {
      title: "Define behavior",
      step: ModuleCreationWizardStep.Behavior,
    },
    icon: CogThin,
  },

  {
    title: "Attach cloud",
    description: "Attach cloud integration to your module",
    action: {
      title: "Attach cloud",
      step: ModuleCreationWizardStep.Cloud,
    },
    icon: MenuCloudIntegrations,
  },
  {
    title: "Attach policy",
    description: "Attach set of rules to your module",
    action: {
      title: "Attach policy",
      step: ModuleCreationWizardStep.Policies,
    },
    icon: MenuPolicies,
  },
  {
    title: "Attach context",
    description: "Attach bundle of configuration elements to your module",
    action: {
      title: "Attach context",
      step: ModuleCreationWizardStep.Contexts,
    },
    icon: MenuContexts,
  },
];
