import { ReactNode } from "react";

import Box from "ds/components/Box";
import TooltipInfo from "ds/components/TooltipInfo";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";

type ListTitleProps = {
  title: string;
  tooltipBody: ReactNode;
};

const ListTitle = ({ title, tooltipBody }: ListTitleProps) => {
  return (
    <Box gap="small" align="center">
      <Typography tag="p" variant="p-t7">
        {title}
      </Typography>
      <TooltipInfo variant="modal">
        <TooltipModalTitle>{title}</TooltipModalTitle>
        <TooltipModalBody align="start">{tooltipBody}</TooltipModalBody>
      </TooltipInfo>
    </Box>
  );
};

export default ListTitle;
