import { createContext } from "react";

import { BillingAddon, BillingAddonType, BillingPlan, BillingSubscription } from "types/generated";

import { V4BillingTier } from "./types";

type BillingContextValue = {
  tier: V4BillingTier;
  billedExternally: boolean;
  billingSubscription?: BillingSubscription;
  onTrialUntil?: number;
  isTrial: boolean;
  currentAddons: BillingAddonType[];
  availableBillingAddons: BillingAddon[];
  availableSelfServicePlans: BillingPlan[];
};

export const BillingContext = createContext<BillingContextValue | undefined>(undefined);
BillingContext.displayName = "BillingContext";
