import { Minus, Plus, Tilde } from "components/icons";

import DeltaCountsCell from "./Cell";
import { DeltaType } from "./types";
import styles from "./styles.module.css";

type DeltaCountsACDBarProps = {
  skipEmpty?: boolean;
  addCount: number;
  changeCount: number;
  deleteCount: number;
};

const DeltaCountsACDBar = ({
  skipEmpty,
  addCount,
  changeCount,
  deleteCount,
}: DeltaCountsACDBarProps) => (
  <div className={styles.group}>
    {(!skipEmpty || addCount > 0) && (
      <DeltaCountsCell
        count={addCount}
        label={`Added resources: ${addCount}`}
        icon={Plus}
        type={DeltaType.ADD}
      />
    )}
    {(!skipEmpty || changeCount > 0) && (
      <DeltaCountsCell
        count={changeCount}
        label={`Changed resources: ${changeCount}`}
        icon={Tilde}
        type={DeltaType.CHANGE}
      />
    )}
    {(!skipEmpty || deleteCount > 0) && (
      <DeltaCountsCell
        count={deleteCount}
        label={`Deleted resources: ${deleteCount}`}
        icon={Minus}
        type={DeltaType.DELETE}
      />
    )}
  </div>
);

export default DeltaCountsACDBar;
