import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Maybe, ContextStackAttachment } from "types/generated";

import { DETACH_CONTEXT_FROM_ENTITY } from "./gql";

const useDetachContext = (refetchQueries?: string[]) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [detachContext, { loading }] = useMutation<{
    contextDetach: Maybe<ContextStackAttachment>;
  }>(DETACH_CONTEXT_FROM_ENTITY, {
    onError,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handleDetachContext = useCallback(
    (attachmentId: string, successCallback?: () => void) => {
      detachContext({ variables: { id: attachmentId } })
        .then(({ data }) => {
          if (data?.contextDetach) {
            reportSuccess({
              message: "Context was successfully detached",
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [detachContext, onError, reportSuccess]
  );

  return {
    detachContext: handleDetachContext,
    loading,
  };
};

export default useDetachContext;
