import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type BoxProps = {
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  role?: string;
  id?: string;
};

const Box = ({ className = "", innerRef, children, role, id }: BoxProps) => (
  <div className={cx(styles.box, className)} ref={innerRef} role={role} id={id}>
    {children}
  </div>
);

export default Box;
