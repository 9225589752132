import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";

import { BitbucketCloudIntegration, VcsProvider } from "types/generated";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import SecretInput from "ds/components/SecretInput";
import FormFieldHidden from "ds/components/Form/Field/Hidden";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FormFieldSpace from "components/FormFields/Space";
import useTypedFlags from "hooks/useTypedFlags";

import { ManageBitbucketCloudFormFields } from "./types";
import {
  getFormDefaultValues,
  mapCreateIntegrationInputValues,
  mapUpdateIntegrationInputValues,
} from "./helpers";
import VCSIntegrationsDocumentationButton from "../components/DocumentationButton";
import useCloseFullScreenModal from "../useCloseFullScreenModal";
import useCreateBitbucketCloudIntegration from "./useCreateBitbucketCloudIntegration";
import useUpdateBitbucketCloudIntegration from "./useUpdateBitbucketCloudIntegration";
import useCheckBitbucketCloudHasDefaultIntegration from "./useCheckBitbucketCloudHasDefaultIntegration";
import VCSIntegrationTypeFieldControl from "../../components/IntegrationTypeFieldControl";
import VCSChecksToggle from "../components/VCSChecksToggle";

type VCSIntegrationsManageBitbucketCloudFormProps = {
  integration?: BitbucketCloudIntegration;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageBitbucketCloudForm = ({
  integration,
  onIsDirtyChange,
}: VCSIntegrationsManageBitbucketCloudFormProps) => {
  const isEditMode = !!integration;

  const { viewer } = useTypedContext(AccountContext);

  const { handleOnClose } = useCloseFullScreenModal();
  const { aggregatedVcsChecks } = useTypedFlags();

  const manageBitbucketCloudForm = useForm<ManageBitbucketCloudFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setValue,
    trigger,
  } = manageBitbucketCloudForm;

  const { createBitbucketCloudIntegration, loading: createLoading } =
    useCreateBitbucketCloudIntegration();
  const { updateBitbucketCloudIntegration, loading: updateLoading } =
    useUpdateBitbucketCloudIntegration();

  const { hasDefaultIntegration, loading: checkDefaultIntegrationLoading } =
    useCheckBitbucketCloudHasDefaultIntegration(isEditMode);

  const withIntegrationTypeControl = !isEditMode && viewer.admin;
  const withSpaceControl = !withIntegrationTypeControl && !integration?.isDefault;

  const onHiddenToggle = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) {
        setValue("appPassword", "", { shouldValidate: true });
      } else {
        // revalidate the whole form with hidden field
        trigger();
      }
    },
    [setValue, trigger]
  );

  const handleSetup = useCallback(
    (formData: ManageBitbucketCloudFormFields) => {
      if (isEditMode) {
        updateBitbucketCloudIntegration(
          mapUpdateIntegrationInputValues(formData, integration.id),
          handleOnClose
        );
      } else {
        createBitbucketCloudIntegration(mapCreateIntegrationInputValues(formData), handleOnClose);
      }
    },
    [
      createBitbucketCloudIntegration,
      handleOnClose,
      integration?.id,
      isEditMode,
      updateBitbucketCloudIntegration,
    ]
  );

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...manageBitbucketCloudForm}>
      <FullScreenModalBody>
        <FormField
          label="Integration name"
          helperText="The integration name is unique and cannot be changed"
          error={errors?.name?.message}
          noMargin
        >
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter integration name here"
              error={!!errors?.name}
              {...register("name", {
                validate: stringIsRequired("Integration name field is required."),
              })}
              disabled={isEditMode}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {withIntegrationTypeControl && (
          <VCSIntegrationTypeFieldControl
            hasAlreadyDefaultIntegration={hasDefaultIntegration}
            loading={checkDefaultIntegrationLoading}
            provider={VcsProvider.BitbucketCloud}
          />
        )}
        {withSpaceControl && <FormFieldSpace />}

        <FormField label="Username" error={errors?.username?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter username here"
              error={!!errors?.username}
              {...register("username", {
                validate: stringIsRequired("Username field is required."),
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {!isEditMode && (
          <FormField label="App password" error={errors?.appPassword?.message}>
            {({ ariaInputProps }) => (
              <SecretInput
                placeholder="Enter app password here"
                error={!!errors?.appPassword}
                {...register("appPassword", {
                  validate: stringIsRequired("App password field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}

        {isEditMode && (
          <FormFieldHidden
            hiddenPlaceholder="Update app password"
            onHiddenToggle={onHiddenToggle}
            label="App password"
            error={errors?.appPassword?.message}
          >
            {(isHidden) => (
              <SecretInput
                placeholder="Enter app password here"
                error={!!errors?.appPassword}
                {...register("appPassword", {
                  ...(!isHidden && {
                    validate: stringIsRequired("App password field is required."),
                  }),
                })}
              />
            )}
          </FormFieldHidden>
        )}

        {aggregatedVcsChecks && <VCSChecksToggle />}

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here"
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.BitbucketCloud} />

        <Button
          onClick={handleSubmit(handleSetup)}
          variant="primary"
          disabled={!isValid || (isEditMode && !isDirty)}
          loading={createLoading || updateLoading}
        >
          {isEditMode ? "Save" : "Set up"}
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageBitbucketCloudForm;
