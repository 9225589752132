import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";

import { COLUMN_GAP, COLUMN_ORDER, SortableColumns } from "../constants";

const VCSIntegrationFiltersLayoutHeader = () => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderSortableColumn
        option={{
          label: "Type",
          value: SortableColumns.TYPE,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={{
          label: SortableColumns.NAME,
          value: SortableColumns.NAME,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={{
          label: SortableColumns.SPACE,
          value: SortableColumns.SPACE,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
};

export default VCSIntegrationFiltersLayoutHeader;
