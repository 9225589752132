import { UserLogin } from "types/generated";

const LAST_LOGINS_MAX_COUNT = 3;
const hasLoginTime = (managedUser: UserLogin): managedUser is UserLogin & { timestamp: number } =>
  typeof managedUser.timestamp === "number";

export const getLastLogins = (managedUsers?: UserLogin[]) => {
  if (!managedUsers) {
    return [];
  }

  const lastLogins = managedUsers
    .filter(hasLoginTime)
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, LAST_LOGINS_MAX_COUNT);

  return lastLogins;
};
