import { useMemo, type ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import useHiddenItemsList from "hooks/useHiddenItemsList";

import { BulkActionsDataContext } from "../Context";
import BulkActionsChooseActionStepActionsList from "./ActionsList";
import { BulkActionsStep, BulkActionsVariant } from "../types";
import { DROPDOWN_WIDTH } from "./constants";
import { getResizableItems } from "./helpers";
import { BulkActionsApplicableAction } from "./types";
import BulkActionsChooseActionStepActionsMeasureWrapper from "./ActionsMeasureWrapper/ActionsMeasureWrapper";

type BulkActionsChooseActionStepProps<Action extends string> = {
  availableActions: Array<BulkActionsApplicableAction<Action>>;
  variant: BulkActionsVariant;
  children: (items: {
    hiddenItems: Array<BulkActionsApplicableAction<Action>>;
    visibleItems: Array<BulkActionsApplicableAction<Action>>;
  }) => ReactNode;
};

const BulkActionsChooseActionStep = <Action extends string>({
  variant,
  children,
  availableActions,
}: BulkActionsChooseActionStepProps<Action>) => {
  const { bulkActionsStep } = useTypedContext(BulkActionsDataContext);
  const actionItems = useMemo(
    () =>
      availableActions.map(({ action }) => ({
        key: action,
        visible: true,
      })),
    [availableActions]
  );

  const { wrapperRef, visibleItems, hiddenItems } = useHiddenItemsList({
    items: actionItems,
    getResizableItems,
    dropdownWidth: DROPDOWN_WIDTH[variant],
  });

  const items = useMemo(
    () => ({
      hiddenItems: hiddenItems
        .map(({ key }) => availableActions.find(({ action }) => action === key))
        .filter((item) => item !== undefined),
      visibleItems: visibleItems
        .map(({ key }) => availableActions.find(({ action }) => action === key))
        .filter((item) => item !== undefined),
    }),
    [availableActions, hiddenItems, visibleItems]
  );

  if (bulkActionsStep !== BulkActionsStep.ChooseAction) {
    return null;
  }

  return (
    <BulkActionsChooseActionStepActionsList ref={wrapperRef} variant={variant}>
      {children(items)}
      <BulkActionsChooseActionStepActionsMeasureWrapper
        availableActions={availableActions}
        variant={variant}
      />
    </BulkActionsChooseActionStepActionsList>
  );
};

export default BulkActionsChooseActionStep;
