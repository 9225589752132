import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";

import WarningBarItem from "components/warning/Item";
import WarningBar from "components/warning/WarningBar";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Toast from "ds/components/Toast";
import {
  BillingTierFeature,
  Blueprint,
  BlueprintInput,
  BlueprintInputType,
  BlueprintStackCreation,
} from "types/generated";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DescriptionDetails from "components/DescriptionDetails";
import { AccountContext } from "views/AccountWrapper";
import { hasSpaceManageAccess } from "utils/user";
import FormFieldViewText from "components/FormFields/ViewText";
import useTierFeature from "views/Account/hooks/useTierFeature";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { BLUEPRINT_CREATE_STACK } from "../gql";
import TemplatePreviewSkeleton from "./Skeleton";
import PreviewFormField from "./FormField";
import styles from "./styles.module.css";
import { TemplatePreviewFormFields } from "./types";
import { getBlueprintFeatureGatingMessage } from "../FeatureGate/Tooltip";
import { BlueprintActions } from "../FeatureGate/types";

type TemplatePreviewProps = {
  item: Blueprint;
  inputs?: BlueprintInput[];
  errors?: string[];
  loading?: boolean;
  handleCloseDrawer: () => void;
  isPublished: boolean;
  onEditMetadata: (item: Blueprint) => void;
  onOpenFullDescription: (item: Blueprint) => void;
};

const TemplatePreview = ({
  item,
  inputs = item.inputs,
  errors = [],
  loading = false,
  handleCloseDrawer,
  isPublished,
  onEditMetadata,
  onOpenFullDescription,
}: TemplatePreviewProps) => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);
  const hasErrors = errors.length > 0;
  const hasPreview = inputs.length > 0;

  const navigate = useNavigate();
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const [createStack, { loading: stackCreateLoading }] = useMutation<{
    blueprintCreateStack: BlueprintStackCreation;
  }>(BLUEPRINT_CREATE_STACK, {
    onError,
  });

  const defaultValues = useMemo(
    () =>
      inputs.reduce((acc, input) => {
        if (input.type === BlueprintInputType.Boolean) {
          acc[input.id] = input.default === "true";
        } else {
          acc[input.id] = input.default;
        }
        return acc;
      }, {} as TemplatePreviewFormFields),
    [inputs]
  );

  const builderForm = useForm<TemplatePreviewFormFields>({
    defaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = builderForm;

  const onSubmit: SubmitHandler<TemplatePreviewFormFields> = (formData) => {
    createStack({
      variables: {
        id: item.id,
        input: {
          templateInputs: Object.entries(formData).map(([key, value]) => ({
            id: key,
            // FYI: this is a workaround for the backend validation
            value: value.toString(),
          })),
        },
      },
    })
      .then(({ data }) => {
        if (data?.blueprintCreateStack) {
          reportSuccess({ message: `Stack is successfully created` });
          navigate(`/stack/${data.blueprintCreateStack.stackID}`);
        }
      })
      .catch(onError);
  };

  const handleEditMetadata = () => {
    handleCloseDrawer();
    onEditMetadata(item);
  };

  const handleOpenFullDescription = () => {
    onOpenFullDescription(item);
  };

  useEffect(() => {
    // update default values when inputs change but only if the form is not dirty
    if (!isDirty) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, isDirty]);

  const canManageBlueprint = viewer.admin || hasSpaceManageAccess(item.space.accessLevel);

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isPublished ? "Create a stack" : "Template preview"} />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Box direction="column" fullWidth>
          {isPublished && (
            <div className={styles.blueprintInfoSection}>
              <FormFieldViewText label="Blueprint name" value={item.name} />

              <DescriptionDetails
                label="Blueprint description"
                description={item.description}
                onOpenFullDescription={handleOpenFullDescription}
                {...(canManageBlueprint && { onAddDescription: handleEditMetadata })}
              />
            </div>
          )}

          {!isPublished && !hasPreview && !hasErrors && (
            <Toast variant="default" relative className={styles.infoToast} fullWidth>
              No preview available. Please add some inputs to the template.
            </Toast>
          )}

          {!isPublished && hasPreview && !hasErrors && (
            <Toast variant="info" relative className={styles.infoToast} fullWidth>
              This is just a read-only template preview, you cannot edit it.
            </Toast>
          )}

          {!isPublished && hasErrors && (
            <WarningBar>
              {errors.map((error, index) => (
                <WarningBarItem key={index}>{error}</WarningBarItem>
              ))}
            </WarningBar>
          )}

          {loading && <TemplatePreviewSkeleton />}
          {!loading && !hasErrors && (
            <Box direction="column" grow="1" fullWidth>
              {inputs.map((input) => (
                <PreviewFormField key={input.id} input={input} />
              ))}
            </Box>
          )}
        </Box>

        {!hasErrors && (
          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer}>
                Cancel
              </Button>
              &nbsp;&nbsp;
              <Tooltip
                active={!isPublished || !isFeatureActive}
                widthMode="maxWidthSm"
                on={(props) => (
                  <span {...props}>
                    <Button
                      variant="primary"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isPublished || !isFeatureActive}
                      loading={stackCreateLoading}
                    >
                      Create stack
                    </Button>
                  </span>
                )}
              >
                {!isPublished &&
                  "This is just a read-only template preview, you cannot create a stack from it."}
                {isPublished &&
                  !isFeatureActive &&
                  getBlueprintFeatureGatingMessage(BlueprintActions.CreateStack)}
              </Tooltip>
            </DrawerFooterActions>
          </DrawerFooter>
        )}
      </DrawerBody>
    </FormProvider>
  );
};

export default TemplatePreview;
