import { NetworkStatus, useQuery } from "@apollo/client";
import { createContext, useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { SavedFilter } from "types/generated";

import { SavedViewsListGql } from "./types";
import { SAVED_VIEWS_LIST } from "./gql";

type SavedFiltersContextType = {
  loading: boolean;
  data?: SavedViewsListGql;
  privateViews?: SavedFilter[];
  publicViews?: SavedFilter[];
};

export const SavedFiltersContext = createContext<SavedFiltersContextType | undefined>(undefined);
SavedFiltersContext.displayName = "SavedFiltersContext";

type SavedFiltersProps = {
  children: React.ReactNode;
  filterType: string;
};

export const SavedFiltersProvider = ({ children, filterType }: SavedFiltersProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, networkStatus } = useQuery<SavedViewsListGql>(SAVED_VIEWS_LIST, {
    onError,
    variables: {
      type: filterType,
    },
  });

  const privateViews = useMemo(
    () => data?.savedFilters.filter((filter) => !filter.isPublic),
    [data?.savedFilters]
  );

  const publicViews = useMemo(
    () => data?.savedFilters.filter((filter) => filter.isPublic),
    [data?.savedFilters]
  );

  const value = {
    loading: loading && networkStatus === NetworkStatus.loading && !data?.savedFilters,
    data,
    privateViews,
    publicViews,
  };

  return <SavedFiltersContext.Provider value={value}>{children}</SavedFiltersContext.Provider>;
};
