import Skeleton from "react-loading-skeleton";
import cx from "classnames";

import PageWrapper from "components/PageWrapper";
import Box from "ds/components/Box";
import SpaceliftLogo from "ds/components/SpaceliftLogo";

import styles from "./styles.module.css";

const SkeletonLoader = ({ initial }: { initial?: boolean }) => (
  <PageWrapper className={styles.layout}>
    <Box>
      <Box
        direction="column"
        justify="between"
        className={cx(styles.sideNavigation, initial && styles.initial)}
      >
        <Box direction="column" gap="medium">
          <SpaceliftLogo />
          <Box
            gap="medium"
            align="center"
            margin="medium 0 0 0"
            padding="x-large small medium small"
          >
            <Skeleton circle width={24} height={24} />
            <Skeleton width={112} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={160} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={80} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={128} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={112} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={160} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={80} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={128} height={16} borderRadius={8} />
          </Box>
        </Box>
        <Box direction="column" gap="medium">
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={112} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={160} height={16} borderRadius={8} />
          </Box>
          <Box gap="medium" align="center" padding="medium small">
            <Skeleton circle width={24} height={24} />
            <Skeleton width={80} height={16} borderRadius={8} />
          </Box>

          <Box gap="medium" align="center" margin="small 0 0 0" padding="medium 0">
            <Skeleton circle width={32} height={32} />
            <Box gap="small" direction="column">
              <Skeleton width={128} height={16} borderRadius={8} />
              <Skeleton width={104} height={8} borderRadius={4} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box direction="column" className={styles.content}>
        <Box
          justify="between"
          align="center"
          className={cx(styles.header, initial && styles.initial)}
        >
          <Skeleton width={156} height={20} borderRadius={10} />
          <Box gap="medium">
            <Skeleton width={202} height={34} borderRadius={17} />
            <Skeleton width={68} height={34} borderRadius={17} />
            <Skeleton width={68} height={34} borderRadius={17} />
          </Box>
        </Box>
        <SpaceliftLogo short className={styles.logo} />
      </Box>
    </Box>
  </PageWrapper>
);

export default SkeletonLoader;
