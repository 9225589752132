import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_WORKER_POOL } from "./gql";

const useDeleteWorkerPool = (viewPageMode?: boolean) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [deleteWorkerPool, { loading }] = useMutation(DELETE_WORKER_POOL, {
    refetchQueries: ["SearchWorkerPools"],
  });

  const handleDeleteWorkerPool = useCallback(
    (workerPoolId: string) => {
      return deleteWorkerPool({ variables: { workerPoolId } })
        .then(() => {
          reportSuccess({ message: "Worker pool was deleted" });

          if (viewPageMode) {
            navigate("/worker-pools");
          }
        })
        .catch(onError);
    },
    [deleteWorkerPool, navigate, onError, reportSuccess, viewPageMode]
  );

  return {
    deleteWorkerPool: handleDeleteWorkerPool,
    loading,
  };
};

export default useDeleteWorkerPool;
