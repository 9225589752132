import { useBulkLockStack } from "shared/Stack/useLockStack";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import {
  LockNoteMutationVariables,
  StackActionItemForBulkActions,
  StackMutationVariables,
} from "./types";

const useLockBulkAction = (): StackActionItemForBulkActions => {
  const [stackLock] = useBulkLockStack();

  return {
    key: StackBulkActions.Lock,
    title: BULK_ACTION_NAMES[StackBulkActions.Lock],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Lock],
    condition: canLockStack,
    mutation: (stack, mutationVariables) => {
      // TODO: wait for TS will fix Extract for generic union types and then remove the type casting
      const userInput = mutationVariables as Extract<
        StackMutationVariables,
        LockNoteMutationVariables
      >;
      return stackLock({ id: stack.id, note: userInput?.lockNote || null });
    },
  };
};

export default useLockBulkAction;
