import { LDContext, useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

function useLDContextUpdate<T extends LDContext>(context: T, deps: unknown[]) {
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient) {
      const currentContext = ldClient.getContext() as T;

      ldClient.identify({
        ...currentContext,
        ...context,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldClient, ...deps]);
}

export default useLDContextUpdate;
