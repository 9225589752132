import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryUsername from "../../components/HistoryEntryUsername";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type ConfirmedEntryProps = {
  timestamp: number;
  until: number | null;
  username: string | null;
};

const ConfirmedEntry = ({ timestamp, until, username }: ConfirmedEntryProps) => {
  const label = (
    <HistoryEntryLabel>
      <strong>Confirmed</strong> <HistoryEntryUsername username={username} />
    </HistoryEntryLabel>
  );

  return (
    <HistoryEntryWrapper
      label={label}
      state={RunHistoryEntryType.Confirmed}
      timestamp={timestamp}
      until={until}
    />
  );
};

export default ConfirmedEntry;
