import { FetchResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { MutationStarSetArgs, Stack } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { STAR_STACK } from "./gql";

type FetchResultData = { starSet: Stack };

type UseSetStackFavoriteProps = {
  refetchQueries?: string[];
  awaitRefetchQueries?: boolean;
  // FYI: will be removed after the migration to the new useMutationHandler hook
  fetchPolicy?: "no-cache" | "network-only";
};

const useSetStackFavorite = ({
  refetchQueries,
  awaitRefetchQueries,
  fetchPolicy = "network-only",
}: UseSetStackFavoriteProps) => {
  const { onError } = useTypedContext(FlashContext);

  const [starSet, { loading }] = useMutation<FetchResultData, MutationStarSetArgs>(STAR_STACK, {
    refetchQueries,
    awaitRefetchQueries,
    onError,
    fetchPolicy,
  });

  const setStackFavorite = useCallback(
    (
      variables: MutationStarSetArgs,
      mutationCallback?: (data: FetchResult<FetchResultData>["data"]) => void
    ) => {
      starSet({ variables })
        .then(({ data }) => {
          mutationCallback?.(data);
        })
        .catch(onError);
    },
    [onError, starSet]
  );

  return {
    setStackFavorite,
    loading,
  };
};

export default useSetStackFavorite;
