import useTypedContext from "hooks/useTypedContext";

import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsVendorPulumiEdit from "./Edit";
import StackSettingsVendorPulumiView from "./View";
import { StackSettingsContextData } from "../../Context";

const StackSettingsVendorPulumi = (props: StackSettingsVendorComponentBaseProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return canManageStack ? (
    <StackSettingsVendorPulumiEdit {...props} />
  ) : (
    <StackSettingsVendorPulumiView />
  );
};

export default StackSettingsVendorPulumi;
