import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type WorkerPoolCycleActionProps = {
  onAction: () => void;
  hasBusyWorkers: boolean;
};

const WorkerPoolCycleAction = ({ onAction, hasBusyWorkers }: WorkerPoolCycleActionProps) => (
  <DropdownMenuItem
    tooltip={
      <>
        {hasBusyWorkers && (
          <>
            Please drain all workers first to make sure that no work is being performed by this
            worker pool.
            <br />
            <br />
          </>
        )}
        Cycling worker pool sends a self-destruct signal to all the workers. The process can take up
        to 20 seconds to complete.
      </>
    }
    tooltipWidthMode="maxWidthSm"
    tooltipPlacement="left"
    onAction={onAction}
    isDisabled={hasBusyWorkers}
  >
    Cycle
  </DropdownMenuItem>
);

export default WorkerPoolCycleAction;
