import { PUBLIC_WORKER_POOL_NAME } from "views/Account/WorkerPool/constants";

import { Pages } from "./types";

export const enum AnalyticsPageWorkerPool {
  WorkerPoolNew = "WorkerPoolNew",
  WorkerPoolPublic = "WorkerPoolPublic",
  WorkerPoolPublicQueue = "WorkerPoolPublicQueue",
  WorkerPoolPublicUsed = "WorkerPoolPublicUsed",
  WorkerPoolQueue = "WorkerPoolQueue",
  WorkerPoolUsedBy = "WorkerPoolUsedBy",
  WorkerPoolWorkers = "WorkerPoolWorkers",
}

const workerPools = "Worker Pools";
const workerPool = "Worker Pool";
const publicWorkerPool = "Public Worker Pool";

export const pagesWorkerPool: Pages<AnalyticsPageWorkerPool> = {
  [AnalyticsPageWorkerPool.WorkerPoolNew]: [workerPools, "New Worker Pool"],
  [AnalyticsPageWorkerPool.WorkerPoolQueue]: [workerPools, workerPool, "Worker Pool Queue tab"],
  [AnalyticsPageWorkerPool.WorkerPoolUsedBy]: [workerPools, workerPool, "Worker Pool Used By tab"],
  [AnalyticsPageWorkerPool.WorkerPoolWorkers]: [workerPools, workerPool, "Worker Pool Workers tab"],
  [AnalyticsPageWorkerPool.WorkerPoolPublic]: [
    workerPools,
    workerPool,
    `Worker Pool ${PUBLIC_WORKER_POOL_NAME}`,
  ],
  [AnalyticsPageWorkerPool.WorkerPoolPublicQueue]: [
    workerPools,
    publicWorkerPool,
    "Public Worker Pool Queue tab",
  ],
  [AnalyticsPageWorkerPool.WorkerPoolPublicUsed]: [
    workerPools,
    publicWorkerPool,
    "Public Worker Pool Used By tab",
  ],
};
