import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";

type StackSettingsIntegrationsCloudAttachFormIntegrationFieldProps = {
  options: Array<{ label: string; value: string }>;
};

const StackSettingsIntegrationsCloudAttachFormIntegrationField = ({
  options,
}: StackSettingsIntegrationsCloudAttachFormIntegrationFieldProps) => {
  const { control } = useFormContext<{ integrationId: string }>();
  return (
    <Controller
      name="integrationId"
      rules={{ required: true }}
      control={control}
      render={({ field }) => (
        <FormField label="Attach integration">
          {({ ariaInputProps }) => (
            <Select
              autocomplete
              value={field.value}
              options={options}
              onChange={field.onChange}
              ariaInputProps={ariaInputProps}
            />
          )}
        </FormField>
      )}
    />
  );
};

export default StackSettingsIntegrationsCloudAttachFormIntegrationField;
