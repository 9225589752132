import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GET_GITHUB_ENTERPRISE_WEBHOOKS_ENDPOINT } from "./gql";

const useGetGitHunEnterpriseWebhooksEndpoint = () => {
  const { onError } = useTypedContext(FlashContext);
  const [githubEnterpriseWebhooksEndpoint, { data }] = useLazyQuery(
    GET_GITHUB_ENTERPRISE_WEBHOOKS_ENDPOINT,
    {
      onError,
    }
  );

  const generateWebhookUrl = useCallback(
    (id?: string) => {
      githubEnterpriseWebhooksEndpoint({
        ...(id && {
          variables: {
            id,
          },
        }),
      });
    },
    [githubEnterpriseWebhooksEndpoint]
  );

  return {
    webhookUrl: data?.githubEnterpriseWebhooksEndpoint,
    generateWebhookUrl,
  };
};

export default useGetGitHunEnterpriseWebhooksEndpoint;
