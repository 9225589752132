import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_WEBHOOK_INTEGRATION } from "./gql";

type UseDeleteWebhookProps = {
  refetchQueries?: string[];
};

type HandleDeleteWebhookProps = {
  webhookId: string;
  entityId: string; //* Stack or Module ID
  successCallback?: () => void;
};

const useDeleteWebhook = ({ refetchQueries }: UseDeleteWebhookProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deleteWebhook, { loading }] = useMutation(DELETE_WEBHOOK_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError,
  });

  const handleDeleteWebhook = useCallback(
    ({ webhookId, entityId, successCallback }: HandleDeleteWebhookProps) => {
      deleteWebhook({ variables: { id: webhookId, entityId } })
        .then(({ data }) => {
          if (data.webhooksIntegrationDelete) {
            reportSuccess({ message: "Webhook integration successfully deleted" });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [deleteWebhook, onError, reportSuccess]
  );

  return {
    deleteWebhook: handleDeleteWebhook,
    loading,
  };
};

export default useDeleteWebhook;
