import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Module, Stack } from "types/generated";

import { getEntityLogs, getTimestamp } from "./helpers";
import { GET_MODULE_LOGS, GET_STACK_LOGS } from "./gql";

type GetRunLogsOptions = {
  until: number | null;
  state: string;
  stateVersion?: number | null;
  isModuleRun: boolean;
  runId: string;
  stackId: string;
};

export const useGetRunLogs = ({
  until,
  state,
  stateVersion,
  isModuleRun,
  runId,
  stackId,
}: GetRunLogsOptions) => {
  const [backOff, setBackOff] = useState(1);
  const [token, setToken] = useState<string | null>(null);
  const [fetchMoreAt, setFetchMoreAt] = useState<number | null>(null);

  const { onError } = useTypedContext(FlashContext);

  const expectStragglers = useCallback(() => {
    // The phase is not finished yet so we can expect more logs.
    if (until === null) {
      return true;
    }

    // Expect stragglers for up to 5 seconds.
    return getTimestamp() - until < 5;
  }, [until]);

  const { data, error, fetchMore, loading } = useQuery<{ stack: Stack } | { module: Module }>(
    isModuleRun ? GET_MODULE_LOGS : GET_STACK_LOGS,
    {
      onCompleted: (data) => {
        const logs = getEntityLogs(data);

        if (!logs) {
          return;
        }

        const { finished, hasMore, nextToken } = logs;

        if (finished && !expectStragglers()) {
          setFetchMoreAt(null);
          return;
        }

        // Reset back-off if new data is available, otherwise bump it by one.
        const nextBackOff = token !== nextToken ? 1 : backOff + 1;

        setBackOff(nextBackOff);
        setToken(nextToken);

        // Ensure maximum 5 seconds between checks.
        const nextFetchInMs = Math.min(5000, hasMore ? 300 : 900 * nextBackOff);

        setFetchMoreAt(getTimestamp() + nextFetchInMs);
      },
      onError,
      variables: {
        [isModuleRun ? "moduleId" : "stackId"]: stackId,
        runId,
        state,
        stateVersion,
      },
    }
  );

  useEffect(() => {
    let timeoutId: number | null = null;

    if (fetchMoreAt !== null) {
      timeoutId = window.setTimeout(async () => {
        try {
          await fetchMore({
            variables: { token },
            updateQuery: (current, { fetchMoreResult }) => {
              if (!fetchMoreResult) return current;

              const currentLogs = getEntityLogs(current);
              const newLogs = getEntityLogs(fetchMoreResult);

              if (currentLogs && newLogs) {
                newLogs.messages =
                  currentLogs.nextToken === newLogs.nextToken
                    ? currentLogs.messages
                    : currentLogs.messages.concat(newLogs.messages);
              }

              return fetchMoreResult;
            },
          });
        } catch (err) {
          onError(err);
        }
      }, fetchMoreAt - getTimestamp());
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fetchMore, fetchMoreAt, token, onError]);

  const runLogs = getEntityLogs(data);

  return {
    runLogs,
    loading,
    error,
  };
};
