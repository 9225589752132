import { Incognito, Networking, Users } from "components/icons";
import { IconComponent } from "types/Icon";
import { BillingTier } from "types/generated";

import { V4BillingTier } from "../types";

type BillingTierDetails = {
  icon: IconComponent;
  text: string;
};

export const BASE_INFO_BY_TIER: Partial<
  Record<V4BillingTier, { price: string; title?: string; description: string }>
> = {
  [BillingTier.V4StarterPlus]: {
    title: "Annual plan starting from",
    price: "$15,000 / y",
    description:
      "For growing teams looking optimize team workflows with infrastructure automation.",
  },
  [BillingTier.V4Business]: {
    title: "Annual plan",
    price: "Custom pricing",
    description:
      "For growing teams looking to orchestrate infrastructure provisioning & configuration.",
  },
  [BillingTier.V4Enterprise]: {
    title: "Annual plan",
    price: "Custom pricing",
    description:
      "For companies that need SaaS/Self-Hosted with special security, compliance & support.",
  },
};

export const DETAILS_BY_TIER: Partial<Record<V4BillingTier, BillingTierDetails[]>> = {
  [BillingTier.V4Starter]: [
    { icon: Users, text: "10 Users" },
    { icon: Networking, text: "2 Public Workers" },
  ],
  [BillingTier.V4StarterPlus]: [
    { icon: Users, text: "Unlimited users" },
    { icon: Incognito, text: "1 Private Worker" },
  ],
  [BillingTier.V4Business]: [
    { icon: Users, text: "Unlimited users" },
    { icon: Incognito, text: "3+ Private Workers" },
  ],
  [BillingTier.V4Enterprise]: [
    { icon: Users, text: "Unlimited users" },
    { icon: Incognito, text: "5+ Private Workers" },
  ],
};

export const ADDITIONAL_FEATURES_BY_TIER: Partial<
  Record<V4BillingTier, { text: string; infoTooltip?: string }[]>
> = {
  [BillingTier.V4Starter]: [
    {
      text: "OIDC integrations",
      infoTooltip: "Other tool/cloud provider integrations with managed OpenID Connect.",
    },
    {
      text: "Private module registry",
      infoTooltip: "Gives you the ability to host, share, and test your modules.",
    },
    {
      text: "Webhooks",
      infoTooltip: "Send notifications for different events to an HTTP endpoint of your choice.",
    },
    {
      text: "Policy as Code engine",
      infoTooltip: "Enhance your workflow with plan & approval policies.",
    },
    {
      text: "Notifications",
      infoTooltip:
        "Seamless and bi-directional integration between Spacelift and Slack, Notification Inbox, Webhooks, and Microsoft Teams.",
    },
  ],
  [BillingTier.V4StarterPlus]: [
    { text: "2 public workers", infoTooltip: "Used to run your workflows." },
    { text: "Drift detection", infoTooltip: "Detect drift and optionally remediate it." },
  ],
  [BillingTier.V4Business]: [
    {
      text: "Blueprints",
      infoTooltip:
        "Templates for a stack and its configuration (even policy, contexts, and cloud integration attachments).",
    },
    {
      text: "Advanced scheduling",
      infoTooltip: "Scheduled tasks and stack auto deletion based on cron.",
    },
    {
      text: "Private provider registry",
      infoTooltip: "Unlocks the ability to host and share providers.",
    },
    {
      text: "Targeted replans",
      infoTooltip: "Enables you to have targeted runs in your workflow.",
    },
    {
      text: "Dedicated Slack support channel",
      infoTooltip: "Shared Slack channel between you and your dedicated CSM.",
    },
  ],
  [BillingTier.V4Enterprise]: [
    { text: "Concurrent VCS", infoTooltip: "Ability to create multiple integrations per VCS." },
    {
      text: "Audit trail",
      infoTooltip: "Unlocks the ability to create audit trails via configurable webhooks.",
    },
    {
      text: "SSO integration with SAML 2.0",
      infoTooltip: "Supports all SAML 2.0-compliant identity providers.",
    },
    { text: "OIDC API keys", infoTooltip: "Ability to use more secure OIDC-based API keys." },
    {
      text: "Private source control system",
      infoTooltip:
        "Ability to work with private self-hosted source control including Github, Gitlab, and Bitbucket.",
    },
  ],
};
