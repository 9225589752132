import Feedback from "ds/components/Feedback";
import Link from "ds/components/Link";

type VCSIntegrationFeedbackWebhooksInfoProps = {
  provider: string;
  docLink: string;
};

const VCSIntegrationFeedbackWebhooksInfo = ({
  provider,
  docLink,
}: VCSIntegrationFeedbackWebhooksInfoProps) => {
  return (
    <Feedback type="callout" variant="info">
      Webhooks should be manually configured in {provider},{` `}find out more{` `}
      <Link size="small" href={docLink} rel="noopener noreferrer" target="_blank">
        here
      </Link>
      .
    </Feedback>
  );
};

export default VCSIntegrationFeedbackWebhooksInfo;
