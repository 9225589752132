import { gql } from "@apollo/client";

export const GET_MANAGED_USER_GROUP_INTEGRATIONS = gql`
  query GetManagedUserGroupIntegrations {
    authorizationScheme
    managedUserGroupIntegrations {
      integrationName
      integrationType
      id
      slackChannelID
      accessRules {
        space
        spaceAccessLevel
      }
    }
  }
`;

export const CREATE_MANAGED_USER_GROUP_INTEGRATION = gql`
  mutation CreateManagedUserGroupIntegration($input: ManagedUserGroupIntegrationCreateInput!) {
    managedUserGroupIntegrationCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_MANAGED_USER_GROUP_INTEGRATION = gql`
  mutation UpdateManagedUserGroupIntegration($input: ManagedUserGroupIntegrationUpdateInput!) {
    managedUserGroupIntegrationUpdate(input: $input) {
      id
    }
  }
`;

export const DELETE_MANAGED_USER_GROUP_INTEGRATION = gql`
  mutation DeleteManagedUserGroupIntegration($id: ID!) {
    managedUserGroupIntegrationDelete(id: $id) {
      id
    }
  }
`;
