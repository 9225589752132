import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ScheduledTaskInput } from "types/generated";
import { StackContext } from "views/Stack/Context";

import { UPDATE_STACK_SCHEDULING_TASK } from "./gql";

const useUpdateScheduledTask = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const [stackScheduledTaskUpdate, { loading }] = useMutation(UPDATE_STACK_SCHEDULING_TASK, {
    refetchQueries: ["GetStackScheduling"],
    awaitRefetchQueries: true,
    onError,
  });

  const updateScheduledTask = useCallback(
    (scheduleId: string, input: ScheduledTaskInput, successCallback?: () => void) => {
      stackScheduledTaskUpdate({
        variables: {
          scheduleId,
          stack: stack.id,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledTaskUpdate) {
            reportSuccess({
              message: `Task schedule was updated successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stack.id, stackScheduledTaskUpdate]
  );

  return {
    updateScheduledTask,
    loading,
  };
};

export default useUpdateScheduledTask;
