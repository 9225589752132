import { Controller, useFormContext } from "react-hook-form";

import Datepicker from "ds/components/Datepicker";
import FormField from "ds/components/Form/Field";
import { validateDateIsAfterNow } from "utils/formValidators";

type FormFieldDateTimeProps = {
  name: string;
  label?: string;
  minDate?: Date;
};

type TFieldValues = Record<string, Date>;

const FormFieldDateTime = ({
  name,
  label = "Date and time UTC",
  minDate,
}: FormFieldDateTimeProps) => {
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        validate: validateDateIsAfterNow,
      }}
      render={({ field, fieldState }) => (
        <FormField label={label} error={fieldState.error?.message} noMargin>
          <Datepicker
            withTime
            renderTimezone="UTC"
            date={field.value}
            onChange={field.onChange}
            minDate={minDate}
            hasError={!!fieldState.error?.message}
            fullWidth
          />
        </FormField>
      )}
    />
  );
};

export default FormFieldDateTime;
