import { ReactNode } from "react";
import classNames from "classnames";

import Typography, { TypographyProps } from "../Typography";
import styles from "./styles.module.css";

type TextHighlightProps = {
  tag?: TypographyProps["tag"];
  children: ReactNode;
  className?: string;
};

const TextHighlight = ({ children, className, tag = "span" }: TextHighlightProps) => {
  return (
    <Typography tag={tag} className={classNames(styles.wrapper, className)}>
      {children}
    </Typography>
  );
};

export default TextHighlight;
