import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useLocalStorage from "@rehooks/local-storage";
import PropTypes from "prop-types";
import queryString from "query-string";
import cx from "classnames";
import { useNavigate } from "react-router-dom-v5-compat";

import { Spinner } from "components/icons";
import ProgressBar from "ds/components/ProgressBar";
import Tooltip from "ds/components/Tooltip";
import { AccountContext } from "views/AccountWrapper";
import { CrossNew } from "components/icons";
import Select from "components/select/Select";
import formStyles from "components/FormDefault/styles.module.css";
import Icon from "ds/components/Icon";

import Chart from "../Chart";
import Filters from "../Filters";
import SideBar from "../Sidebar";
import {
  generateAccountEntities,
  generateEntities,
  generateFilters,
  generateGroupByOptions,
  generateKeyPossibleValues,
} from "../helpers";
import "./styles.css";
import styles from "./styles.module.css";

const ResourcesWrapper = ({ data, isAccountWide, search, stackName, total, loaded }) => {
  const [defaultGroupBy, setDefaultGroupBy] = useLocalStorage("defaultGroupBy", undefined);

  const [filterField, setFilterField] = useState([""]);
  const [filterValues, setFilterValues] = useState([[]]);
  const [groupByValue, setGroupByValue] = useState(defaultGroupBy || "parent");
  const [entityDetails, setEntityDetails] = useState({});
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [zoomTarget, setZoomTarget] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const { accountName } = useContext(AccountContext);

  const navigate = useNavigate();

  useEffect(() => {
    const params = queryString.parse(search);

    if (Object.keys(params).length === 0) return;

    if (params.filterFields) {
      try {
        setFilterField(JSON.parse(atob(params.filterFields)));
      } catch {
        // Filter fields are malformed, reset to default
        setFilterField([""]);
      }
    }

    if (params.filterValues) {
      try {
        setFilterValues(JSON.parse(decodeURIComponent(atob(params.filterValues))));
      } catch {
        // Filter values are malformed, reset to default
        setFilterValues([[]]);
      }
    }

    if (params.zoomTarget) {
      try {
        setZoomTarget(atob(params.zoomTarget));
      } catch {
        // Zoom target is malformed, reset to default
        setZoomTarget("");
      }
    }

    setGroupByValue(params.groupByValue || "parent");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entities = useMemo(
    () => (isAccountWide ? generateAccountEntities(data, accountName) : generateEntities(data)),
    [data, accountName, isAccountWide]
  );

  const filters = useMemo(
    () => generateFilters(filterField, filterValues),
    [filterField, filterValues]
  );

  const keyPossibleValues = useMemo(
    () => generateKeyPossibleValues(filterField, filters, entities, isAccountWide),
    [filterField, filters, entities, isAccountWide]
  );
  const groupByOptions = generateGroupByOptions(isAccountWide);

  const setParams = useCallback(
    (fields, values, sort, zoomTargetChange) => {
      navigate({
        search: `filterValues=${btoa(
          encodeURIComponent(JSON.stringify(values))
        )}&filterFields=${btoa(JSON.stringify(fields))}&groupByValue=${
          sort || groupByValue
        }&zoomTarget=${btoa(zoomTargetChange || zoomTarget)}`,
      });
    },
    [navigate, groupByValue, zoomTarget]
  );

  const addEmptyFilter = () => {
    setFilterField((filterField) => [...filterField, ""]);
    setFilterValues((filterValues) => [...filterValues, []]);
  };

  const handleFilterAdd = useCallback(
    (key, value) => {
      const [lastFilterField] = filterField.slice(-1);
      if (lastFilterField === "") {
        const newFilterField = filterField.map((item, index) => {
          return index + 1 === filterField.length ? key : item;
        });
        setFilterField(newFilterField);

        const newFilterValues = filterValues.map((item, index) => {
          return index + 1 === filterValues.length ? [{ label: value, value }] : item;
        });

        setFilterValues(newFilterValues);

        setParams(newFilterField, newFilterValues);
      } else {
        setFilterValues((filterValues) => [...filterValues, [{ label: value, value }]]);
        setFilterField((filterField) => [...filterField, key]);
        setParams([...filterField, key], [...filterValues, [{ label: value, value }]]);
      }
    },
    [filterField, filterValues, setParams]
  );

  const handleFilterChange = useCallback(
    (option, index) => {
      let newFilterField = [...filterField];
      let newFilterValues = [...filterValues];

      if (option === null && filterField.length > 1) {
        // Cleared - remove this selector. There's an empty selector following us.

        newFilterField.splice(index, 1);
        newFilterValues.splice(index, 1);
      } else {
        newFilterField[index] = option?.value || "";
        if (newFilterField[index] !== filterField[index]) {
          // if the option changed, clear the values selector
          newFilterValues[index] = [];
        }
      }

      setFilterField(newFilterField);
      setFilterValues(newFilterValues);
      setParams(newFilterField, newFilterValues);
    },
    [filterField, filterValues, setParams]
  );

  const handleMultiFilterChange = useCallback(
    (newValues, index) => {
      let newFilterValues = filterValues.slice();
      newFilterValues[index] = newValues;

      setFilterValues(newFilterValues);
      setParams(filterField, newFilterValues);
    },
    [filterValues, filterField, setParams]
  );

  const handleGroupByChange = useCallback(
    ({ value }) => {
      setGroupByValue(value);
      setDefaultGroupBy(value);
      setParams(filterField, filterValues, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterField, filterValues, setParams]
  );

  const handleZoomTargetChange = useCallback(
    (value) => {
      setZoomTarget(value);
      setParams(filterField, filterValues, undefined, value);
    },
    [filterField, filterValues, setParams]
  );

  const toggleFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const shouldShowAddButton = useMemo(() => {
    return filterField.slice(-1)[0] !== "" && filterValues.slice(-1)[0].length > 0;
  }, [filterField, filterValues]);

  const wrapperClass = cx("resources", {
    "resources--fullscreen": fullScreen,
  });

  return (
    <div className={wrapperClass}>
      <SideBar
        entityDetails={entityDetails}
        setMenuVisible={setMenuVisible}
        isVisible={isMenuVisible}
        handleFilterNewChange={handleFilterAdd}
        stackId={!isAccountWide ? data.stack.id : null}
        fullScreen={fullScreen}
      />
      <div>
        {fullScreen && (
          <div className="resources__close">
            <Tooltip
              on={(props) => (
                <Icon {...props} src={CrossNew} onClick={toggleFullScreen} size="small" />
              )}
              placement="bottom"
            >
              Hide full screen
            </Tooltip>
          </div>
        )}
        <div className="resources-controls">
          <div className="resources-filters">
            <div className="resources__label">Filter by: </div>
            <div className="resources-filters__items">
              {filterField.map((value, index) => (
                <Filters
                  key={`filter-${index}`}
                  keyPossibleValues={keyPossibleValues[index]}
                  filterField={filterField}
                  filterValues={filterValues}
                  index={index}
                  handleFilterChange={handleFilterChange}
                  handleMultiFilterChange={handleMultiFilterChange}
                />
              ))}
              {shouldShowAddButton && (
                <button className="resources-filters__button" onClick={addEmptyFilter}>
                  + Add filter
                </button>
              )}
            </div>
          </div>
          <div className="resources-group-by">
            <div className="resources__label">Group by:</div>
            <Select
              className={cx(formStyles.input, styles.inputMargin)}
              value={groupByOptions.find((option) => option.value === groupByValue)}
              options={groupByOptions}
              onChange={handleGroupByChange}
              placeholder={"Group by:"}
            />
          </div>
        </div>
        {total && total !== loaded && (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingIndicator}>
              Loaded stacks
              <ProgressBar ariaLabel="Loading stacks progress" max={total} current={loaded} />
              {loaded !== total && <Icon src={Spinner} size="large" />}
            </div>

            <div className={cx("d3-container", styles.loadingMessage)}>
              Please wait while we load the data...
            </div>
          </div>
        )}
        {(!total || total === loaded) && (
          <Chart
            accountName={accountName}
            data={entities}
            isAccountWide={isAccountWide}
            groupByKey={groupByValue}
            filters={filters}
            setEntityDetails={setEntityDetails}
            setMenuVisible={setMenuVisible}
            zoomTarget={zoomTarget}
            setZoomTarget={handleZoomTargetChange}
            fullScreen={fullScreen}
            toggleFullScreen={toggleFullScreen}
            stackName={stackName}
            handleFilterAdd={handleFilterAdd}
          />
        )}
      </div>
    </div>
  );
};

ResourcesWrapper.propTypes = {
  data: PropTypes.shape({
    stacks: PropTypes.arrayOf(
      PropTypes.shape({
        entities: PropTypes.arrayOf(
          PropTypes.shape({
            creator: PropTypes.shape({
              id: PropTypes.string.isRequired,
            }),
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            parent: PropTypes.string,
            type: PropTypes.string.isRequired,
            updater: PropTypes.shape({
              id: PropTypes.string.isRequired,
            }),
            vendor: PropTypes.object,
          })
        ),
        id: PropTypes.string.isRequired,
      }).isRequired
    ),
    stack: PropTypes.shape({
      entities: PropTypes.arrayOf(
        PropTypes.shape({
          creator: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          parent: PropTypes.string,
          type: PropTypes.string.isRequired,
          updater: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
          vendor: PropTypes.object,
        })
      ),
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  search: PropTypes.string.isRequired,
  stackName: PropTypes.string,
  total: PropTypes.number,
  loaded: PropTypes.number,
  isAccountWide: PropTypes.bool,
};

export default ResourcesWrapper;
