import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Blueprint } from "types/generated";

import BlueprintListItem from ".";

type BlueprintVirtualizedListItemProps = {
  items: Blueprint[];
  onCreateStack: (item: Blueprint) => void;
  onEditMetadata: (item: Blueprint) => void;
  onCloneBlueprint: (item: Blueprint) => void;
  onShowFullDescription: (item: Blueprint) => void;
  focusedBlueprintId?: string;
};

const BlueprintVirtualizedListItem = (
  props: ListChildComponentProps<BlueprintVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <BlueprintListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onCreateStack={data.onCreateStack}
        onEditMetadata={data.onEditMetadata}
        onCloneBlueprint={data.onCloneBlueprint}
        onShowFullDescription={data.onShowFullDescription}
        isActive={data.focusedBlueprintId === data.items[index].id}
      />
    </div>
  );
};

export default memo(BlueprintVirtualizedListItem, areEqual);
