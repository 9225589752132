import { ReactNode } from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ModuleVersionPageWrapperProps = {
  children: ReactNode;
};

const ModuleVersionPageWrapper = ({ children }: ModuleVersionPageWrapperProps) => {
  return (
    <Box className={styles.wrapper} direction="column">
      {children}
    </Box>
  );
};

export default ModuleVersionPageWrapper;
