import { gql } from "@apollo/client";

export const GET_MEDIAN_RUN_DURATION = gql`
  query GetMedianRunDuration($startTime: Int!, $endTime: Int!) {
    metricsRange(input: { startTime: $startTime, endTime: $endTime }) {
      medianRunDuration {
        value
      }
      medianRunDurationRange {
        value
        timestamp
      }
      hasRuns
    }
  }
`;
