import { TypePolicies } from "@apollo/client";

import {
  StackIntegrations,
  ModuleRepositoryMetadata,
  SpaceliftMetrics,
  Maybe,
  Version,
} from "types/generated";

const TYPE_POLICIES: TypePolicies = {
  Query: {
    fields: {
      searchVCSIntegrations: {
        keyArgs: ["id", "provider"],
      },
      metrics: {
        merge(existing: Maybe<SpaceliftMetrics>, incoming: Maybe<SpaceliftMetrics>) {
          return { ...existing, ...incoming };
        },
      },
      metricsRange: {
        merge(existing: Maybe<SpaceliftMetrics>, incoming: Maybe<SpaceliftMetrics>) {
          return { ...existing, ...incoming };
        },
        keyArgs: false,
      },
    },
  },
  AuditTrailWebhook: {
    keyFields: ["endpoint"],
  },
  ConfigElement: {
    keyFields: ["id", "type"],
  },
  Stack: {
    fields: {
      integrations: {
        merge(existing: StackIntegrations, incoming: StackIntegrations) {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  Module: {
    fields: {
      versions: {
        keyArgs: ["includeFailed"],
        merge(existing: Map<string, Version>, incoming: Version[], { readField }) {
          const merged = new Map<string, Version>(existing);

          for (const item of incoming) {
            const id = readField<string>("id", item);

            if (id) {
              merged.set(id, item);
            }
          }

          return merged;
        },
        read(existing: Map<string, Version>) {
          return existing && Array.from(existing.values());
        },
      },
    },
  },
  Version: {
    fields: {
      metadata: {
        merge(existing: ModuleRepositoryMetadata, incoming: ModuleRepositoryMetadata) {
          return { ...existing, ...incoming };
        },
      },
    },
  },
  BlueprintInput: {
    keyFields: false,
  },
  VCSIntegration: {
    keyFields: ["id", "provider"],
  },
  SpaceliftMetrics: {
    keyFields: false,
  },
};

export default TYPE_POLICIES;
