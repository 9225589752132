import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActions/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActions/Drawer/ActionSteps/Confirmation/EmptyTab";
import { AnalyticsPage } from "hooks/useAnalytics";

import {
  BulkItemRunWithPosition,
  WorkerPoolQueuedRunsBulkActionsAnalyticsPayload,
  WorkerPoolQueuedRunsBulkActionsSelectedAction,
} from "./types";
import WorkerPoolQueuedRunsBulkActionsSelectedItem from "./SelectedItem";
import WorkerPoolQueuedRunsBulkActionsConfirmActionForm from "./ConfirmActionForm";

type WorkerPoolQueuedRunsBulkActionsConfirmationViewProps = {
  applicableItems: BulkItemRunWithPosition[];
  skippedItems: BulkItemRunWithPosition[];
  onItemDismiss: (id: BulkActionItemID) => void;
  selectedBulkAction: WorkerPoolQueuedRunsBulkActionsSelectedAction;
  onConfirm: (analyticsPayload: WorkerPoolQueuedRunsBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
  analyticsPage: AnalyticsPage;
};

const WorkerPoolQueuedRunsBulkActionsConfirmationView = ({
  applicableItems,
  skippedItems,
  onItemDismiss,
  selectedBulkAction,
  analyticsPage,
  onConfirm,
  onCancel,
}: WorkerPoolQueuedRunsBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <WorkerPoolQueuedRunsBulkActionsSelectedItem
                key={item.id}
                item={item}
                onDismiss={onItemDismiss}
                itemCategory={currentTab}
                step={BulkActionsStep.ActionConfirmation}
                analyticsPage={analyticsPage}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <WorkerPoolQueuedRunsBulkActionsConfirmActionForm
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default WorkerPoolQueuedRunsBulkActionsConfirmationView;
