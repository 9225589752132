import { OptionProps, OptionTypeBase, components } from "react-select";
import cx from "classnames";

import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";

const SpaceOption = ({ children, ...otherProps }: OptionProps<OptionTypeBase, false>) => (
  <components.Option {...otherProps}>
    <TextEllipsis
      tooltip={otherProps.label}
      delay={400}
      mode="line-clamp"
      tooltipWidthMode="maxWidthXl"
      tooltipPlacement="top-end"
    >
      {(props) => (
        <span {...props} className={cx(props.className, styles.spaceOption)}>
          {children}
        </span>
      )}
    </TextEllipsis>
  </components.Option>
);

export default SpaceOption;
