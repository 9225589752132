import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSimpleBezierPath } from "reactflow";
import { useMemo } from "react";

import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import { Output } from "components/icons";
import Typography from "ds/components/Typography";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import TooltipModal from "ds/components/TooltipModal";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";
import { EdgeData } from "../types";

const ITEMS_QTY_LIMIT = 10;

type GetDescriptionOptions = {
  hasTriggerAlways: boolean;
  isSingular: boolean;
};
const getDescription = ({ hasTriggerAlways, isSingular }: GetDescriptionOptions) => {
  if (hasTriggerAlways) {
    return isSingular
      ? "The stack will always run regardless of the output reference changes."
      : "The stack will always run regardless of the output references changes.";
  } else {
    return isSingular
      ? "If the output reference changes, the stack will run."
      : "If at least one of the output references changes, the stack will run.";
  }
};

const StackDependenciesGraphEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  ...restProps
}: EdgeProps<EdgeData>) => {
  const [edgePath, labelX, labelY] = getSimpleBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const hasTriggerAlways = useMemo(() => {
    return !!data?.references.find((reference) => reference.triggerAlways);
  }, [data?.references]);

  const filteredReferences = useMemo(() => {
    return data?.references.slice(0, ITEMS_QTY_LIMIT) || [];
  }, [data?.references]);

  const hasReferences = data && data.referenceCount > 0 && filteredReferences.length > 0;
  const hasMore = data && data.referenceCount > ITEMS_QTY_LIMIT;
  const restItemsCount = (hasMore && data?.referenceCount - filteredReferences.length) || undefined;

  return (
    <>
      <BaseEdge id={id} path={edgePath} {...restProps} />

      {hasReferences && (
        <EdgeLabelRenderer>
          <TooltipModal
            placement="bottom"
            closeOnOutsideClick={false}
            className={styles.tooltipModal}
            on={(tooltipProps) => (
              <Box
                {...tooltipProps}
                gap="small"
                align="center"
                justify="center"
                className={styles.edgeOutputReferences}
                style={{
                  position: "absolute",
                  transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                }}
              >
                <Icon src={Output} color="on-solid-primary" />
                <Typography tag="span" variant="p-t6" color="on-solid-primary">
                  {data.referenceCount}
                </Typography>
              </Box>
            )}
          >
            <TooltipModalBody>
              <Typography tag="p" variant="p-body2" className={styles.flatTitle}>
                {getDescription({ hasTriggerAlways, isSingular: data.referenceCount === 1 })}
              </Typography>

              <Box direction="column" gap="medium" fullWidth>
                {filteredReferences.map((reference) => (
                  <Box key={reference.inputName} direction="column" zeroMinWidth>
                    <TextEllipsis tooltip={reference.inputName} tooltipWidthMode="maxWidthXl">
                      {(props) => (
                        <Typography {...props} tag="h6" variant="p-t6">
                          {reference.inputName}
                        </Typography>
                      )}
                    </TextEllipsis>

                    <TextEllipsis tooltip={reference.outputName} tooltipWidthMode="maxWidthXl">
                      {(props) => (
                        <Typography {...props} tag="span" variant="p-body3" color="secondary">
                          Output: {reference.outputName}
                        </Typography>
                      )}
                    </TextEllipsis>
                    {reference.triggerAlways && (
                      <Typography tag="span" variant="p-body3" color="secondary">
                        Always triggers
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>

              {hasMore && restItemsCount && (
                <Typography tag="p" variant="p-body2" color="secondary">
                  +{restItemsCount} more
                </Typography>
              )}
            </TooltipModalBody>
          </TooltipModal>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default StackDependenciesGraphEdge;
