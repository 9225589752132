import { Pages } from "./types";

export const enum AnalyticsPageWebhook {
  WebhooksList = "WebhooksList",
}

const Webhooks = "Webhooks";

export const pagesWebhook: Pages<AnalyticsPageWebhook> = {
  [AnalyticsPageWebhook.WebhooksList]: [Webhooks, "Webhooks list"],
};
