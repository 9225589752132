import { SearchQueryOrderDirection } from "types/generated";

export const initialSortOption = "starred";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const POLL_INTERVAL = 10000;
export const STACKS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "stacksFiltersOrder";

export const STACK_LIST_VIEW_ITEMS_SETTINGS_KEY = "stacksListViewItems";

export enum STACK_LIST_VIEW_ITEM_ID {
  TAGS = "TAGS",
  SPACE = "SPACE",
  VENDOR = "VENDOR",
  ADMINISTRATIVE = "ADMINISTRATIVE",
  BRANCH = "BRANCH",
  COMMIT = "COMMIT",
  REPOSITORY = "REPOSITORY",
  PROJECT_ROOT = "PROJECT_ROOT",
  DELTA_COUNTS = "DELTA_COUNTS",
  UPDATED_AT = "UPDATED_AT",
  DESCRIPTION = "DESCRIPTION",
  BLUEPRINT = "BLUEPRINT",
}

export const INITIAL_STACK_LIST_VIEW_ITEMS = [
  {
    id: STACK_LIST_VIEW_ITEM_ID.TAGS,
    name: "Tags",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.SPACE,
    name: "Space",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.VENDOR,
    name: "Vendor",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.ADMINISTRATIVE,
    name: "Administrative",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.BRANCH,
    name: "Branch",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.COMMIT,
    name: "Commit",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.REPOSITORY,
    name: "Repository",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.PROJECT_ROOT,
    name: "Project root",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.DELTA_COUNTS,
    name: "Delta counts",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.UPDATED_AT,
    name: "Updated at",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.DESCRIPTION,
    name: "Description",
    selected: true,
  },
  {
    id: STACK_LIST_VIEW_ITEM_ID.BLUEPRINT,
    name: "Blueprint",
    selected: true,
  },
];
