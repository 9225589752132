import ConfirmationModal from "../ConfirmationModal";
import { ConfirmationModalProps } from "../ConfirmationModal/types";
import { ConfirmationModalMessage } from "../ConfirmationModal/Message";

type LeaveConfirmationModalProps = Pick<
  ConfirmationModalProps,
  | "onConfirm"
  | "isLoading"
  | "isDismissable"
  | "confirmationButtonLabel"
  | "title"
  | "children"
  | "size"
>;

const LeaveConfirmationModal = ({
  confirmationButtonLabel = "Leave",
  children,
  size = "large",
  ...rest
}: LeaveConfirmationModalProps) => {
  const content =
    typeof children === "string" ? (
      <ConfirmationModalMessage>{children}</ConfirmationModalMessage>
    ) : (
      children
    );

  return (
    <ConfirmationModal confirmationButtonLabel={confirmationButtonLabel} size={size} {...rest}>
      {content}
    </ConfirmationModal>
  );
};

export default LeaveConfirmationModal;
