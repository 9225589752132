import { Pages } from "./types";

export const enum AnalyticsPagePolicy {
  PoliciesList = "PoliciesList",
  PoliciesTemplates = "PoliciesTemplates",
  Policy = "Policy",
  PolicyDetails = "PolicyDetails",
  PolicyNew = "PolicyNew",
  PolicyWorkbench = "PolicyWorkbench",
}

const policies = "Policies";

export const pagesPolicy: Pages<AnalyticsPagePolicy> = {
  [AnalyticsPagePolicy.Policy]: [policies, "Policy"],
  // TODO: remove policy when policy library tuned on
  [AnalyticsPagePolicy.PolicyDetails]: [policies, "Policy details"],
  [AnalyticsPagePolicy.PolicyWorkbench]: [policies, "Workbench"],
  [AnalyticsPagePolicy.PolicyNew]: [policies, "New Policy"],
  [AnalyticsPagePolicy.PoliciesList]: [policies, "Policies list"],
  [AnalyticsPagePolicy.PoliciesTemplates]: [policies, "Policies templates"],
};
