import { ReactNode } from "react";
import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type KeyValueProps = {
  name: string;
  children: ReactNode | string;
  align?: BoxProps["align"];
};

const KeyValue = ({ name, children, align }: KeyValueProps) => {
  if (!children) {
    return null;
  }

  return (
    <Box className={styles.container} justify="between" gap="large" align={align}>
      <Box shrink="0">
        <Typography tag="p" variant="p-t6">
          {name}
        </Typography>
      </Box>
      {typeof children === "string" ? (
        <Box>
          <Typography align="right" tag="p" variant="p-body2" className={cx(styles.value)}>
            {children}
          </Typography>
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};

export default KeyValue;
