import { gql } from "@apollo/client";

export const GET_BITBUCKET_DATA_CENTER_INTEGRATION = gql`
  query GetVCSBitbucketDataCenterIntegration($id: ID!) {
    bitbucketDatacenterIntegration(id: $id) {
      vcsChecks
      apiHost
      description
      id
      isDefault
      labels
      name
      space {
        id
        name
        accessLevel
      }
      userFacingHost
      username
      webhookSecret
    }
  }
`;
