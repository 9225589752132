import { ReactNode } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type DashboardResourcesChartCentralInfoProps = {
  count: number;
  title?: string | ReactNode;
};

const DashboardResourcesChartCentralInfo = ({
  count,
  title = "TOTAL RESOURCES",
}: DashboardResourcesChartCentralInfoProps) => {
  return (
    <Box justify="center" direction="column" align="center">
      <Typography tag="span" className={styles.centralValue}>
        {count}
      </Typography>
      {typeof title === "string" ? (
        <Typography align="center" color="secondary" tag="span" variant="p-t7">
          {title}
        </Typography>
      ) : (
        title
      )}
    </Box>
  );
};

export default DashboardResourcesChartCentralInfo;
