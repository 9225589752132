import { BlueprintState } from "types/generated";
import Dropdown from "ds/components/Dropdown";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";

import BlueprintStateBadge from ".";

type BlueprintStateBadgeDropdownProps = {
  state: BlueprintState;
  className?: string;
  applyFilter: (value: BlueprintState) => void;
};

const BlueprintStateBadgeDropdown = ({
  state,
  className,
  applyFilter,
}: BlueprintStateBadgeDropdownProps) => {
  const getHandleOnClick = (callback: () => void) => () => {
    applyFilter(state);
    callback();
  };

  return (
    <Dropdown
      position="bottomRight"
      renderTriggerComponent={({ onClick, ariaProps }) => (
        <BlueprintStateBadge
          withDropdown
          state={state}
          className={className}
          onClick={onClick}
          {...ariaProps}
        />
      )}
    >
      {({ closeDropdown }) => {
        return (
          <DropdownSection>
            <DropdownSectionItem onClick={getHandleOnClick(closeDropdown)}>
              Add to filters
            </DropdownSectionItem>
          </DropdownSection>
        );
      }}
    </Dropdown>
  );
};

export default BlueprintStateBadgeDropdown;
