import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";

import { RunsBulkActionsConfirmActionStepProps } from "./types";

type RunsBulkActionsConfirmActionStepDefaultProps = RunsBulkActionsConfirmActionStepProps;

const RunsBulkActionsConfirmActionStepDefault = ({
  action,
  bulkActionsVariant,
  onConfirm,
  onCancel,
}: RunsBulkActionsConfirmActionStepDefaultProps) => {
  const handleConfirm = () => {
    onConfirm(
      {},
      {
        view: bulkActionsVariant,
      }
    );
  };

  return (
    <BulkActionsConfirmActionStepInlineConfirmation
      variant={bulkActionsVariant}
      actionLabel={action.title.toLowerCase()}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    />
  );
};

export default RunsBulkActionsConfirmActionStepDefault;
