import { gql } from "@apollo/client";

export const SEARCH_CONTEXTS_NAME_SUGGESTIONS = gql`
  query SearchContextsSuggestions($input: SearchSuggestionsInput!) {
    searchContextsSuggestions(input: $input) {
      fields {
        possibleValuesString {
          values
        }
      }
    }
  }
`;
