import BadgeNextNew from "ds/components/BadgeNext/New";

import { StackStateBadgeProps } from "./types";
import { STACK_STATES } from "./constants";

const StackStateBadgeNewDropdown = ({ state }: StackStateBadgeProps) => {
  if (!STACK_STATES[state]) {
    return null;
  }

  return (
    <BadgeNextNew
      textEllipsis
      type="strong"
      startIcon={STACK_STATES[state].icon}
      variant={STACK_STATES[state].variant}
      text={STACK_STATES[state].text}
    />
  );
};

export default StackStateBadgeNewDropdown;
