import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { SharedModule } from "types/generated";

import ModuleListItem, { ModuleListItemProps } from ".";

type ModuleVirtualizedListItemProps = {
  items: SharedModule[];
  onShowFullDescription: ModuleListItemProps["onShowFullDescription"];
};

const ModuleVirtualizedListItem = (
  props: ListChildComponentProps<ModuleVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <ModuleListItem
        key={data.items[index].id}
        module={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onShowFullDescription={data.onShowFullDescription}
      />
    </div>
  );
};

export default memo(ModuleVirtualizedListItem, areEqual);
