import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";

import PersonalSettingsGroupsListItem from "./Item";

type PersonalSettingsGroupsListVirtualizedItemProps = {
  groups: string[];
};

const PersonalSettingsGroupsListVirtualizedItem = (
  props: ListChildComponentProps<
    PersonalSettingsGroupsListVirtualizedItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const item = data.groups[index];
  const key = `${index} ${item}`;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <PersonalSettingsGroupsListItem key={key} item={item} setRowHeight={handleSetRowHeight} />
    </div>
  );
};

export default memo(PersonalSettingsGroupsListVirtualizedItem, areEqual);
