import { useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PublicWorkerPool } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import {
  getFiltersPredicationFromURI,
  makeFilterItemFromSuggestionField,
  makeFilterItemOptionsFromSuggestionField,
} from "components/Filters/helpers";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import {
  FILTER_ITEMS_DICTIONARY,
  FilterItemKeys,
} from "views/Account/WorkerPool/QueuedRunsView/constants";
import { useCachedFilterFields } from "components/Filters/hooks";

import { SEARCH_PUBLIC_WORKER_POOL_SCHEDULABLE_RUNS_SUGGESTIONS } from "./gql";

const useSearchPublicQueuedRunsSuggestions = () => {
  const fieldsRef = useRef<string[] | null>(null);
  const { onError } = useTypedContext(FlashContext);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { data, loading, refetch, previousData } = useQuery<{
    publicWorkerPool: PublicWorkerPool;
  }>(SEARCH_PUBLIC_WORKER_POOL_SCHEDULABLE_RUNS_SUGGESTIONS, {
    variables: {
      input: {
        predicates,
        fullTextSearch: searchInput,
        fields: fieldsRef.current,
      },
    },
    onError,
  });

  const rawSearchRunsSuggestions = useMemo(() => {
    if (data?.publicWorkerPool) {
      const { searchSchedulableRunsSuggestions } = data.publicWorkerPool;

      return searchSchedulableRunsSuggestions;
    }

    return undefined;
  }, [data?.publicWorkerPool]);

  const cachedFiltersData = useCachedFilterFields(rawSearchRunsSuggestions?.fields);

  const sortOptions = useMemo((): SortOption[] => {
    return cachedFiltersData
      .filter((field) => field.orderable)
      .map((field) => {
        return {
          value: field.name,
          label: field.name,
        };
      });
  }, [cachedFiltersData]);

  const [filters, filtersMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    const filterItems = cachedFiltersData
      .filter((field) => {
        // the priority changing functionality is not supported for Public Worker Pools
        if (field.filterable && field.name === FilterItemKeys.IS_PRIORITIZED) {
          return false;
        }

        return field.filterable;
      })
      .map((field) => {
        const options = makeFilterItemOptionsFromSuggestionField(field);

        if (options) {
          filtersItemsOptionsMap.set(field.name, options);
        }

        return makeFilterItemFromSuggestionField({ field, dictionary: FILTER_ITEMS_DICTIONARY });
      });

    return [filterItems, filtersItemsOptionsMap];
  }, [cachedFiltersData]);

  const handleRefetchActiveSections = (fields: string[]) => {
    fieldsRef.current = fields.length > 0 ? fields : null;

    if (fields.length > 0) {
      refetch({
        input: {
          fullTextSearch: searchInput,
          predicates,
          fields,
        },
      });
    }
  };

  const previousSearchRunsSuggestions =
    previousData?.publicWorkerPool?.searchSchedulableRunsSuggestions;

  return {
    filters,
    filtersMap,
    sortOptions,
    loading,
    refetchActiveSections: handleRefetchActiveSections,
    filteredCount:
      rawSearchRunsSuggestions?.filteredCount || previousSearchRunsSuggestions?.filteredCount || 0,
  };
};

export default useSearchPublicQueuedRunsSuggestions;
