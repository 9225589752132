import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import HistoryEntryNote from "../../components/HistoryEntryNote";
import { RunHistoryEntryType } from "../../types";

type PendingReviewEntryProps = {
  note: string | null;
  timestamp: number;
  until: number | null;
};

const PendingReviewEntry = ({ note, until, timestamp }: PendingReviewEntryProps) => {
  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.PendingReview}
      timestamp={timestamp}
      until={until}
      isLoading={!until}
    >
      <HistoryEntryNote>{note}</HistoryEntryNote>
    </HistoryEntryWrapper>
  );
};

export default PendingReviewEntry;
