import cx from "classnames";

import TextEllipsis from "ds/components/TextEllipsis";
import Typography, { TypographyProps } from "ds/components/Typography";
import { TooltipProps } from "ds/components/Tooltip/types";

import { DEFAULT_TITLE_VARIANT } from "./constants";

type ListEntitiesItemTitleProps = {
  title: string;
  titleTag?: TypographyProps["tag"];
  titleVariant?: TypographyProps["variant"];
  titleColor?: TypographyProps["color"];
  tooltip?: string;
  tooltipWidthMode?: TooltipProps["widthMode"];
  className?: string;
};

const ListEntitiesItemTitle = ({
  title,
  titleTag = "span",
  titleVariant = DEFAULT_TITLE_VARIANT,
  titleColor,
  tooltip = title,
  tooltipWidthMode = "maxWidthSm",
  className,
}: ListEntitiesItemTitleProps) => {
  return (
    <TextEllipsis tooltip={tooltip} tooltipWidthMode={tooltipWidthMode}>
      {(props) => (
        <Typography
          {...props}
          variant={titleVariant}
          tag={titleTag}
          className={cx(props.className, className)}
          color={titleColor}
        >
          {title}
        </Typography>
      )}
    </TextEllipsis>
  );
};

export default ListEntitiesItemTitle;
