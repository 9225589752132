import FormFieldViewText from "components/FormFields/ViewText";
import SourceCodeIntegrationFieldTooltip from "components/Forms/SourceCode/IntegrationField/Tooltip";
import RepositoryTooltip from "components/Forms/SourceCode/RepositoryTooltip";
import { SourceCodeProjects } from "components/Forms/SourceCode/types";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useTypedContext from "hooks/useTypedContext";
import BranchesTooltip from "components/Forms/SourceCode/BranchesTooltip";
import ProjectGlobsTooltip from "components/FormFields/ProjectGlobs/Tooltip";
import ProjectRootTooltip from "components/Forms/SourceCode/ProjectRootTooltip";

import { StackSettingsContextData } from "../Context";

const StackSettingsSourceCodeView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  return (
    <>
      <FormFieldViewText
        label="Provider"
        value={HUMANIZE_PROVIDER[stackSettings.provider]}
        noMargin
      />
      {stackSettings.vcsIntegration && (
        <FormFieldViewText
          label="Integration"
          value={stackSettings.vcsIntegration.name}
          noMargin
          tooltipInfoVariant="modal"
          tooltipInfo={
            <SourceCodeIntegrationFieldTooltip
              provider={stackSettings.provider}
              projectType={SourceCodeProjects.Stack}
            />
          }
        />
      )}
      <FormFieldViewText
        label="Repository"
        value={`${stackSettings.namespace}/${stackSettings.repository}`}
        noMargin
        tooltipInfoVariant="modal"
        tooltipInfo={
          <RepositoryTooltip
            vcsProvider={stackSettings.provider}
            projectType={SourceCodeProjects.Stack}
          />
        }
      />
      {stackSettings.branch && (
        <FormFieldViewText
          label="Branch"
          value={stackSettings.branch}
          noMargin
          tooltipInfoVariant="modal"
          tooltipInfo={<BranchesTooltip projectType={SourceCodeProjects.Stack} isReadOnly />}
        />
      )}
      <FormFieldViewText
        label="Project root"
        value={stackSettings.projectRoot || "Not specified - defaults to root of the repository"}
        tooltipInfoVariant="modal"
        tooltipInfo={<ProjectRootTooltip projectType={SourceCodeProjects.Stack} />}
        noMargin
      />
      {!!stackSettings.additionalProjectGlobs.length && (
        <FormFieldViewText
          label="Additional project globs"
          value={stackSettings.additionalProjectGlobs.join(", ")}
          tooltipInfoVariant="modal"
          tooltipInfo={<ProjectGlobsTooltip />}
          noMargin
        />
      )}
    </>
  );
};

export default StackSettingsSourceCodeView;
