import ButtonIcon from "ds/components/ButtonIcon";
import Markdown from "components/markdown/Markdown";
import Box from "ds/components/Box";
import Drawer, { DrawerProps } from "ds/components/Drawer";
import DrawerHeader from "ds/components/Drawer/Header";
import { Maybe } from "types/generated";
import { ArrowThin } from "components/icons";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import styles from "./styles.module.css";

type FullDescriptionDrawerProps = {
  visible: boolean;
  position?: DrawerProps["position"];
  onCloseDrawer: () => void;
  onBackToDetails?: () => void;
  description?: Maybe<string>;
  onOutsideClick?: () => void;
};

const FullDescriptionDrawer = ({
  visible,
  position = "absoluteRight",
  onCloseDrawer,
  onBackToDetails,
  description,
  onOutsideClick,
}: FullDescriptionDrawerProps) => {
  return (
    <Drawer onOutsideClick={onOutsideClick} position={position} visible={visible} variant="wide">
      <DrawerHeader justify="between">
        <Box gap="medium">
          {onBackToDetails && (
            <ButtonIcon icon={ArrowThin} onClick={onBackToDetails} iconRotate="270" variant="ghost">
              Go back
            </ButtonIcon>
          )}
          <DrawerHeaderTitle title="Description" />
        </Box>
        <DrawerCloseIcon handleCloseDrawer={onCloseDrawer} />
      </DrawerHeader>
      <Box direction="column" className={styles.wrapper}>
        <Markdown markup={description || ""} />
      </Box>
    </Drawer>
  );
};

export default FullDescriptionDrawer;
