import { getColors } from "../Form/Field/utils";

export const getDefaultTooltip = (variant: "checkbox" | "switch", isSelected: boolean) => {
  if (variant === "checkbox" && isSelected) {
    return "Unselect";
  }

  if (variant === "checkbox" && !isSelected) {
    return "Select";
  }

  if (variant === "switch" && isSelected) {
    return "Turn off";
  }

  if (variant === "switch" && !isSelected) {
    return "Turn on";
  }

  return undefined;
};

export const getTextColor = (isDisabled: boolean, color?: "default" | "inversed") => {
  if (color === "inversed" && isDisabled) {
    return "on-solid-disabled";
  }

  if (isDisabled) {
    return "disabled";
  }

  return getColors(color).primaryTextColor;
};
