import { useEffect, useState } from "react";

import CodeEditor from "components/CodeEditor";
import Box from "ds/components/Box";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Banner from "ds/components/Banner";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import styles from "./styles.module.css";

type TriggerWithCustomConfigDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  onTrigger: (config: string) => void;
};

const TriggerWithCustomConfigDrawer = ({
  isVisible,
  onClose,
  onTrigger,
}: TriggerWithCustomConfigDrawerProps) => {
  const [config, setConfig] = useState("");

  useEffect(() => {
    if (!isVisible) {
      setConfig("");
    }
  }, [isVisible]);

  const handleTrigger = () => {
    onTrigger(config);
    onClose();
  };

  const handleCloseDrawer = async () => {
    const isDirty = config !== "";

    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    onClose();
  };
  return (
    <Drawer visible={isVisible}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Runtime config details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Banner variant="info" margin="0 0 x-large 0">
          To type runtime config, please use YAML format.
        </Banner>
        <Typography tag="span" variant="p-t7">
          Runtime config
        </Typography>
        <Box direction="column" margin="large 0 0 0" className={styles.codeEditorWrapper}>
          <CodeEditor
            language="yaml"
            body={config}
            onChange={(value?: string) => setConfig(value ?? "")}
            options={{ automaticLayout: true }}
            rounded
          />
        </Box>
      </DrawerBody>
      <DrawerFooter>
        <DrawerFooterActions>
          <Button variant="secondary" onClick={handleCloseDrawer}>
            Cancel
          </Button>
          <Button disabled={!config} variant="primary" onClick={handleTrigger}>
            Trigger
          </Button>
        </DrawerFooterActions>
      </DrawerFooter>
    </Drawer>
  );
};

export default TriggerWithCustomConfigDrawer;
