import type { UseHiddenItemsListItemsWidth } from "hooks/useHiddenItemsList/types";

export const STACK_MENU_ITEM_DATA_ATTRIBUTE = "data-stack-menu-item";
const BULK_ACTIONS_ITEM_DATA_ACCESSOR = "stackMenuItem";

export const getResizableItems = (wrapperElement: HTMLDivElement): UseHiddenItemsListItemsWidth[] =>
  [...wrapperElement.querySelectorAll(`a[${STACK_MENU_ITEM_DATA_ATTRIBUTE}]`)].map((element) => {
    const item = element as HTMLAnchorElement;
    return {
      key: item.dataset[BULK_ACTIONS_ITEM_DATA_ACCESSOR] || "",
      width: item.offsetWidth,
    };
  });
