import { Controller, useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Box from "ds/components/Box";
import Input from "ds/components/Input";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";

import { StackVendorFormFields } from "../../types";
import { validateRequired, validateRequiredURL } from "../../utils";
import { getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

const NewStackVendorPulumi = () => {
  const analyticsVersion = useStackCreationAnalyticsVersion();

  const { control } = useFormContext<StackVendorFormFields>();

  return (
    <Box gap="x-large" direction="column">
      <Box direction="column">
        <Controller
          name="pulumi.loginURL"
          control={control}
          rules={{ validate: validateRequiredURL("Login URL") }}
          render={({ field, fieldState }) => (
            <FormField
              label="Login URL"
              error={fieldState.error?.message}
              {...getTooltipAnalyticsProps("Vendor", "Login URL", {
                provider: "Pulumi",
                version: analyticsVersion,
              })}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Login URL</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    State backend address Pulumi should log into during run initialization.
                  </TooltipModalBody>
                </>
              }
              tooltipInfoVariant="modal"
              noMargin
            >
              {({ ariaInputProps }) => (
                <Input
                  error={!!fieldState.error}
                  type="text"
                  value={field.value}
                  placeholder="Type in login URL"
                  onChange={field.onChange}
                  ref={field.ref}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          )}
        />
        <Controller
          name="pulumi.stackName"
          control={control}
          rules={{ validate: validateRequired("Stack name") }}
          render={({ field, fieldState }) => (
            <FormField
              label="Stack name"
              error={fieldState.error?.message}
              {...getTooltipAnalyticsProps("Vendor", "Stack name", {
                provider: "Pulumi",
                version: analyticsVersion,
              })}
              tooltipInfo={
                <>
                  <TooltipModalTitle>Stack name</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    The stack name which should be used when persisting to the state backend.
                  </TooltipModalBody>
                </>
              }
              tooltipInfoVariant="modal"
            >
              {({ ariaInputProps }) => (
                <Input
                  error={!!fieldState.error}
                  type="text"
                  value={field.value}
                  placeholder="Name stack in the Pulumi state"
                  onChange={field.onChange}
                  ref={field.ref}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          )}
        />
      </Box>
    </Box>
  );
};

export default NewStackVendorPulumi;
