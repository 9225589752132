import cx from "classnames";
import { useMutation } from "@apollo/client";

import CodeEditor from "components/CodeEditor";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import Callout from "ds/components/Callout";
import { Policy, PolicyType } from "types/generated";
import { trackSegmentEvent } from "shared/Analytics";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { withTestId } from "utils/withTestId";

import styles from "./styles.module.css";
import {
  LOGIN_POLICY_UPDATE_ACTIVE_SESSIONS_WARNING_STORAGE_KEY,
  LOGIN_POLICY_UPDATE_BODY_WRAPPER_TEST_ID,
} from "./constants";
import { UPDATE_POLICY } from "./gql";
import { showLoginPolicyDetailsConfirmationModal } from "./ConfirmationModal";

type LoginPolicyDetailsBodyProps = {
  policy: Policy;
  body: string;
  handleEditorChange: (value?: string) => void;
  isBodyReadyToUpdate: boolean;
  isSimulationVisible: boolean;
  isLoginPolicyActive: boolean;
};

const LoginPolicyDetailsBody = ({
  body,
  handleEditorChange,
  isBodyReadyToUpdate,
  isSimulationVisible,
  policy,
  isLoginPolicyActive,
}: LoginPolicyDetailsBodyProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updatePolicy, { loading }] = useMutation<{
    policyCreate: { name: string };
  }>(UPDATE_POLICY, {
    variables: {
      id: policy.id,
      name: policy.name,
      space: policy.space,
      type: PolicyType.Login,
      labels: policy.labels,
    },
    onError,
    // APOLLO CLIENT UPDATE
    onCompleted: (data) => {
      if (data) {
        trackSegmentEvent("Login Policy Body Updated");
        reportSuccess({ message: `Login policy successfully updated` });
      }
    },
    refetchQueries: ["GetPolicyDetails"],
  });

  const handleSaveChanges = () => {
    updatePolicy({ variables: { body } }).catch(onError);
  };

  const handleConfirmSave = () => {
    showLoginPolicyDetailsConfirmationModal({ onConfirm: handleSaveChanges });
  };

  return (
    <Box
      {...withTestId(LOGIN_POLICY_UPDATE_BODY_WRAPPER_TEST_ID)}
      direction="column"
      className={cx(styles.bodyWrapper, isSimulationVisible && styles.narrowed)}
    >
      <Box justify="between" align="center" className={styles.header}>
        <Typography tag="h2" variant="p-t5">
          Policy body
        </Typography>
        {!isLoginPolicyActive && (
          <Button
            variant="primary"
            disabled={!isBodyReadyToUpdate}
            onClick={handleSaveChanges}
            loading={loading}
          >
            Save changes
          </Button>
        )}
        {isLoginPolicyActive && (
          <Button
            variant="primary"
            disabled={!isBodyReadyToUpdate}
            onClick={handleConfirmSave}
            loading={loading}
          >
            Save changes
          </Button>
        )}
      </Box>
      {isLoginPolicyActive && (
        <Callout
          variant="danger"
          storageKey={LOGIN_POLICY_UPDATE_ACTIVE_SESSIONS_WARNING_STORAGE_KEY}
        >
          After changing the Login policy, all active sessions (except the current one) will be
          invalidated.
        </Callout>
      )}
      <CodeEditor body={body} onChange={handleEditorChange} language="rego" />
    </Box>
  );
};

export default LoginPolicyDetailsBody;
