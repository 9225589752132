import { gql } from "@apollo/client";

export const GET_DRIFT_DETECTION_COVERAGE = gql`
  query GetDriftDetectionCoverage {
    metrics {
      driftDetectionCoverage {
        value
        labels
      }
    }
  }
`;
