import { AccountViewer } from "components/form/graphql/types";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";
import FadeTransition from "../FadeTransition";

type DetailsProps = {
  accountName: string;
  viewer: AccountViewer;
  isExpanded: boolean;
};

const UserCard = ({ accountName, viewer, isExpanded }: DetailsProps) => {
  return (
    <span className={styles.userCard} title={viewer.name}>
      <img className={styles.avatar} src={viewer.avatarURL} alt="Avatar" />
      <FadeTransition visible={isExpanded}>
        <span className={styles.content}>
          <Typography tag="span" className={styles.name}>
            {viewer.name}
          </Typography>
          <Typography tag="span" className={styles.details}>
            {accountName}
          </Typography>
        </span>
      </FadeTransition>
    </span>
  );
};

export default UserCard;
