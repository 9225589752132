import { useCallback, useState, MouseEvent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { flushSync } from "react-dom";
import cx from "classnames";

import Box from "ds/components/Box";
import Input from "ds/components/Input";
import Tag from "ds/components/Tag";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import { Dots } from "components/icons";
import Dropdown from "ds/components/Dropdown";
import Tooltip from "ds/components/Tooltip";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";

type PriorityTagProps = {
  value: number;
  onChange: (value: number) => Promise<void>;
  onDetach: () => Promise<void>;
};

type PriorityFormData = {
  priority: number;
};

const validatePriority = (value: number) => /^[0-9]+$/.test(value.toString());

const PriorityTag = ({ value, onChange, onDetach }: PriorityTagProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDetaching, setIsDetaching] = useState(false);

  const onSubmit = useCallback(
    async (formData: PriorityFormData) => {
      if (value !== formData.priority) {
        await onChange(formData.priority);
      }

      setIsEditing(false);
    },
    [onChange, value]
  );

  const form = useForm<PriorityFormData>({
    defaultValues: {
      priority: value,
    },
    mode: "onChange",
  });

  const { handleSubmit, register, formState, setFocus } = form;

  const handleOnEditClick = useCallback(() => {
    flushSync(() => {
      setIsEditing(true);
    });

    setFocus("priority");
  }, [setFocus]);

  const handleDetach = useCallback(async () => {
    setIsDetaching(true);

    await onDetach();

    setIsDetaching(false);
  }, [onDetach]);

  if (isEditing) {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box align="center" gap="medium">
            <Typography variant="p-t7" tag="span">
              Priority
            </Typography>

            <Input
              error={!!formState.errors.priority}
              size="small"
              className={styles.input}
              disabled={formState.isSubmitting}
              {...register("priority", {
                valueAsNumber: true,
                min: 0,
                required: true,
                validate: validatePriority,
                onBlur: handleSubmit(onSubmit),
              })}
            />
          </Box>
        </form>
      </FormProvider>
    );
  }

  return (
    <Dropdown
      position="bottomLeft"
      renderTriggerComponent={({ onClick, isVisible, ariaProps }) => (
        <Tooltip
          on={(tooltipProps) => (
            <Tag
              {...tooltipProps}
              {...ariaProps}
              className={cx(styles.tag, { [styles.tagActive]: isVisible })}
              onClick={(e) => onClick(e as MouseEvent<Element>)}
              tag={
                <Box align="center">
                  <Typography variant="p-t7" tag="span">
                    Priority:&nbsp;
                  </Typography>
                  <Typography variant="p-body3" tag="span">
                    {value}
                  </Typography>
                  <Box align="center" className={styles.iconWrapper}>
                    <Icon src={Dots} className={styles.icon} />
                  </Box>
                </Box>
              }
            />
          )}
          active={!isVisible}
        >
          Manage context
        </Tooltip>
      )}
    >
      {() => (
        <DropdownSection>
          <DropdownSectionItem onClick={handleOnEditClick}>Edit priority</DropdownSectionItem>
          <DropdownSectionItem onClick={handleDetach} disabled={isDetaching} danger>
            Detach
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </Dropdown>
  );
};

export default PriorityTag;
