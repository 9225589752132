import { ReactNode } from "react";

import { BulkActionResultTabs } from "components/BulkActions/types";
import Box from "ds/components/Box";
import DrawerBody from "ds/components/Drawer/Body";
import TabPanel from "ds/components/TabPanel";

import { TABS_ORDER } from "../constants";
import BulkActionsDrawerResultsStepTabsTab from "./Tab";

type BulkActionsDrawerResultsStepTabsChildrenProps = {
  currentTab: BulkActionResultTabs;
};

type BulkActionsDrawerResultsStepTabsProps = {
  currentTab: BulkActionResultTabs;
  setCurrentTab: (tab: BulkActionResultTabs) => void;
  counter: {
    [key in BulkActionResultTabs]: {
      count: number;
    };
  };
  children: (props: BulkActionsDrawerResultsStepTabsChildrenProps) => ReactNode;
};

const BulkActionsDrawerResultsStepBody = ({
  counter,
  currentTab,
  setCurrentTab,
  children,
}: BulkActionsDrawerResultsStepTabsProps) => {
  return (
    <DrawerBody gap="large" fullHeight>
      <Box gap="medium">
        {TABS_ORDER.map((tab) => (
          <BulkActionsDrawerResultsStepTabsTab
            key={tab}
            currentTab={currentTab}
            setTab={setCurrentTab}
            result={tab}
            counter={counter[tab].count}
          />
        ))}
      </Box>
      {TABS_ORDER.map((tab) => (
        <TabPanel grow="1" key={tab} id={tab} isActive={currentTab === tab}>
          <Box grow="1" gap="large" direction="column">
            {children({ currentTab: tab })}
          </Box>
        </TabPanel>
      ))}
    </DrawerBody>
  );
};

export default BulkActionsDrawerResultsStepBody;
