import { TerragruntTool } from "types/generated";

export const TERRAGRUNT_TOOL_OPTIONS = [
  { label: "OpenTofu", value: TerragruntTool.OpenTofu },
  { label: "Terraform (FOSS)", value: TerragruntTool.TerraformFoss },
  { label: "Manually provisioned", value: TerragruntTool.ManuallyProvisioned },
];

export const TERRAGRUNT_TOOL_INFO_TOOLTIP = `The tool used by Terragrunt to make your infrastructure changes. This can either be
an open source (FOSS) version of Terraform, OpenTofu, or you can manually provision
the tool yourself. When manual provisioning is selected, you must ensure that the
tool is available to the worker, for example by either using a custom image or
downloading it via a hook, and you must also make sure the \`TERRAGRUNT_TFPATH\`
environment variable is set to tell Terragrunt where to find this.`;

/**
 * Gets the label value for the specified tool.
 * @param value the tool enum value
 * @returns the label value, or the raw enum value if the tool is unknown.
 */
export function toolLabel(value: TerragruntTool): string {
  const option = TERRAGRUNT_TOOL_OPTIONS.find((o) => o.value === value);
  if (option) {
    return option.label;
  }

  return value;
}
