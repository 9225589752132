import Skeleton from "react-loading-skeleton";

import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

const MOCK = Array(3).fill(null);

const DashboardWidgetsStackStateRecentItemsSkeleton = () => (
  <>
    {MOCK.map((_, i) => (
      <TileWrapper key={i}>
        <TileContent>
          <Box justify="between" align="center" className={styles.skeletonWrapper}>
            <Skeleton inline count={1} height={18} width={170} />
            <Skeleton inline count={1} height={18} width={30} />
          </Box>
        </TileContent>
      </TileWrapper>
    ))}
  </>
);

export default DashboardWidgetsStackStateRecentItemsSkeleton;
