import moment from "moment";

export const getHumanizedTimestamp = (timestamp: number): string => {
  return moment.unix(timestamp).fromNow();
};

const formatDigits = (value: number, twoDigits: boolean) =>
  twoDigits ? value.toString().padStart(2, "0") : value;

export const getDuration = (
  valueInSeconds?: number | null,
  includeSeconds?: boolean,
  twoDigits: boolean = true
) => {
  if (!valueInSeconds) {
    return undefined;
  }

  const hours = Math.floor(valueInSeconds / 3600);
  const minutes = Math.floor((valueInSeconds % 3600) / 60);
  const seconds = Math.floor(valueInSeconds % 3600) % 60;

  const parsedHours = hours ? `${formatDigits(hours, twoDigits)}h` : "";
  const parsedMinutes = minutes || hours ? `${formatDigits(minutes, twoDigits)}m` : "";
  const parsedSeconds = includeSeconds ? `${formatDigits(seconds, twoDigits)}s` : "";

  return `${parsedHours} ${parsedMinutes} ${parsedSeconds}`.trim();
};

/**
 * Use this function to get the current time in milliseconds.
 * Use the `now` function from `@internationalized/date` to get the current time in a specific timezone.
 * @returns current time in milliseconds
 */
export const getNowTime = () => Date.now();
