import Box from "ds/components/Box";
import Tab from "ds/components/Tab";

import { FilterMode } from "../types";
import styles from "./styles.module.css";

type SidebarHeadingContentProps = {
  setMode: (mode: FilterMode) => void;
  mode: FilterMode;
};

const SidebarHeadingContent = ({ setMode, mode }: SidebarHeadingContentProps) => {
  return (
    <Box direction="row" gap="medium" align="center" className={styles.tabs}>
      <Tab
        onClick={setMode}
        isActive={mode === FilterMode.Filters}
        id={FilterMode.Filters}
        label="Filters"
      />
      <Tab
        onClick={setMode}
        isActive={mode === FilterMode.Views}
        id={FilterMode.Views}
        label="Views"
      />
    </Box>
  );
};

export default SidebarHeadingContent;
