import { useFormContext } from "react-hook-form";

import Banner from "ds/components/Banner";

const StackSettingsIntegrationsCloudAttachFormWriteOnlyWarning = () => {
  const { watch } = useFormContext<{ read: boolean; write: boolean }>();

  const [read, write] = watch(["read", "write"]);

  const showWarning = !read && write;

  if (!showWarning) {
    return null;
  }

  return (
    <Banner variant="warning" title="Read permission not selected">
      Selecting only write permission will make the runs fail in the planning phase.
    </Banner>
  );
};

export default StackSettingsIntegrationsCloudAttachFormWriteOnlyWarning;
