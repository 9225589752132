import { ChangeEvent, useState } from "react";

import Typography from "../Typography";
import Box from "../Box";
import Input from "../Input";

type ConfirmationModalInputProps = {
  keyWord: string;
  setIsValid: (value: boolean) => void;
};

export const ConfirmationModalInput = ({ keyWord, setIsValid }: ConfirmationModalInputProps) => {
  const [value, setValue] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    setIsValid(newValue === keyWord);
  };

  return (
    <Box direction="column" gap="medium" margin="x-large 0 0 0">
      <Typography tag="span" variant="p-body2">
        Please type <b>{keyWord}</b> to confirm.
      </Typography>

      <Input placeholder="Enter the word here" value={value} onChange={handleChange} />
    </Box>
  );
};
