import { VENDOR_KEY_BY_TYPENAME } from "constants/vendor";
import {
  Maybe,
  Stack,
  StackConfigVendor,
  StackConfigVendorInput,
  StackInput,
} from "types/generated";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";

const prepareVendorConfigWithoutAnyChanges = (
  vendorConfig: Maybe<StackConfigVendor>
): StackConfigVendorInput => {
  const { __typename, ...vendorFields } = vendorConfig || {};
  const vendor = __typename && VENDOR_KEY_BY_TYPENAME[__typename];

  return {
    ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
    ...(vendor && {
      [vendor]: vendorFields,
    }),
  };
};

/**
 * TODO: remove when showLeakingSensitiveOutputsThroughDependencies is removed
 */
export const sanitizeInput = <Type extends { enableSensitiveOutputUpload: boolean | null }>(
  input: Type,
  showLeakingSensitiveOutputsThroughDependencies: boolean
): Type => {
  if (showLeakingSensitiveOutputsThroughDependencies) {
    return input;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { enableSensitiveOutputUpload, ...restInput } = input;

  return restInput as Type; // Need to cast for now as the api already has the enableSensitiveOutputUpload field, we still want to filter it out
};

export const makeStackUpdateInput = (
  stack: Stack,
  { vendorConfig: newVendorConfig, ...stackChanges }: Partial<StackInput>,
  showLeakingSensitiveOutputsThroughDependencies = false // TODO: remove this parameter when showLeakingSensitiveOutputsThroughDependencies FF removing
): StackInput => {
  const vendorConfig = newVendorConfig || prepareVendorConfigWithoutAnyChanges(stack.vendorConfig);

  return sanitizeInput(
    {
      additionalProjectGlobs: stack.additionalProjectGlobs,
      administrative: stack.administrative,
      afterApply: stack.afterApply,
      afterDestroy: stack.afterDestroy,
      afterInit: stack.afterInit,
      afterPerform: stack.afterPerform,
      afterPlan: stack.afterPlan,
      afterRun: stack.afterRun,
      autodeploy: stack.autodeploy,
      autoretry: stack.autoretry,
      beforeApply: stack.beforeApply,
      beforeDestroy: stack.beforeDestroy,
      beforeInit: stack.beforeInit,
      beforePerform: stack.beforePerform,
      beforePlan: stack.beforePlan,
      branch: stack.branch,
      description: stack.description,
      enableWellKnownSecretMasking: stack.enableWellKnownSecretMasking,
      enableSensitiveOutputUpload: stack.enableSensitiveOutputUpload,
      githubActionDeploy: stack.githubActionDeploy,
      isDisabled: stack.isDisabled,
      labels: stack.labels,
      localPreviewEnabled: stack.localPreviewEnabled,
      name: stack.name,
      namespace: stack.provider === "GITHUB" ? null : stack.namespace,
      projectRoot: stack.projectRoot,
      protectFromDeletion: stack.protectFromDeletion,
      provider: stack.provider,
      repository: stack.repository,
      repositoryURL: stack.repositoryURL,
      runnerImage: stack.runnerImage,
      space: stack.spaceDetails.id,
      terraformVersion: stack.terraformVersion,
      vcsIntegrationId: stack.vcsIntegration?.id || null,
      workerPool: stack.workerPool ? stack.workerPool.id : null,
      ...stackChanges,
      vendorConfig,
    },
    showLeakingSensitiveOutputsThroughDependencies
  );
};
