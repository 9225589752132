import { memo } from "react";
import { Cell } from "react-aria-components";
import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { TableContext } from "./Context";

type TableCellProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
};

const TableCell = ({ id, children, className }: TableCellProps) => {
  const { visibleColumnIds, mergedColumnsConfig } = useTypedContext(TableContext);
  const isVisible = visibleColumnIds.includes(id);
  const column = mergedColumnsConfig[id];

  if (!isVisible) {
    return null;
  }

  return (
    <Cell
      className={cx(
        styles.tableCell,
        column && column.sticky ? styles.cellSticky : undefined,
        column && column.cellClassName,
        className
      )}
    >
      {children}
    </Cell>
  );
};

export default memo(TableCell);
