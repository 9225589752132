import { UseHiddenItemsListBaseItem, UseHiddenItemsListItemsWidth } from "./types";

export const sortItemsByHideOrder =
  <Item extends UseHiddenItemsListBaseItem>(reversedHideOrder: string[]) =>
  (a: Item, b: Item) => {
    return reversedHideOrder.indexOf(a.key) - reversedHideOrder.indexOf(b.key);
  };

export const getHiddenItemsQty = (
  wrapper: HTMLDivElement,
  itemsWidths: UseHiddenItemsListItemsWidth[],
  dropdownWidth: number
) => {
  // check how many items need to be hidden
  let counter = 0;
  let widthDiff = 0;
  const allElementsWidth = itemsWidths.reduce((acc, item) => acc + item.width, 0);

  while (allElementsWidth - widthDiff > wrapper.offsetWidth) {
    if (counter === 0) {
      widthDiff -= dropdownWidth;
    }

    counter += 1;
    widthDiff += itemsWidths.at(-counter)?.width || 0;
    if (counter === itemsWidths.length) break;
  }
  return counter;
};
