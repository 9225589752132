import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import SortableTable from "components/SortableTable";
import ListEntitiesItem from "components/ListEntitiesItem";
import { SearchQueryOrderDirection } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import { getCommmitDigitsCount } from "utils/commit";
import Timestamp from "components/time/Timestamp";
import Typography from "ds/components/Typography";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import Box from "ds/components/Box";
import { NoResultsColored } from "components/icons";
import { ModuleContext } from "views/Account/Module/Context";

import { VersionContext } from "../../Context";
import { COLUMN_ORDER, columns } from "./constants";
import ModuleVersionScrollableWrapper from "../../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../../components/PageIntoTitle";

const ModuleVersionTestCases = () => {
  const { version } = useTypedContext(VersionContext);
  const { module } = useTypedContext(ModuleContext);

  const testCases = useMemo(() => version?.runs || [], [version?.runs]);

  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Test cases"
            tooltip="Special type of runs executed on the module. Infrastructure is created for the test, and after the test finishes, it will be deleted."
          />
        }
      >
        {/** TODO: confirm what to do with the new Rerun button */}
      </PageInfo>
      <ModuleVersionScrollableWrapper padding="0">
        {testCases.length === 0 && (
          <EmptyState
            padding="x-large"
            icon={NoResultsColored}
            caption="This module has no test cases"
            fullHeight
          />
        )}
        {testCases.length > 0 && (
          <SortableTable
            items={testCases}
            columns={columns}
            columnOrder={COLUMN_ORDER}
            stickyHeader
            renderItems={(sorted) =>
              sorted.map((item) => (
                <ListEntitiesItem key={item.id} gap="large" grid gridTemplate={COLUMN_ORDER}>
                  <Box>
                    <RunStateBadge state={item.state} />
                  </Box>
                  <ListEntitiesItemLink
                    title={item.expectFailure ? `${item.title} (should fail)` : item.title}
                    to={`/module/${module.id}/version/${version.id}/run/${item.id}`}
                  />
                  <MetaInfoListItem
                    href={item.commit.url}
                    linkText={item.commit.hash.slice(0, getCommmitDigitsCount(module.provider))}
                    target="_blank"
                  />
                  <Typography tag="span" variant="p-body2">
                    <Timestamp timestamp={item.createdAt} />
                  </Typography>
                </ListEntitiesItem>
              ))
            }
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionTestCases;
