import cx from "classnames";

import Button from "components/button/Button";

import styles from "./styles.module.css";
import { EnvironmentType } from "../types";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type EnvironmentEditActionsProps = {
  onEdit: () => void;
  override: boolean;
  id: string;
  stackId: string;
  type: EnvironmentType;
};

const EnvironmentEditActions = ({
  id,
  stackId,
  type,
  onEdit,
  override,
}: EnvironmentEditActionsProps) => {
  const handleDeleteClick = () => showDeleteConfirmation({ id, stackId, type });

  return (
    <>
      <Button
        className={cx({ [styles.buttonOverride]: override })}
        onClick={onEdit}
        sm={!override}
        left={!override}
        pill
      >
        {override ? "Override" : "Edit"}
      </Button>
      {override || (
        <Button onClick={handleDeleteClick} sm pill danger right>
          Delete
        </Button>
      )}
    </>
  );
};

export default EnvironmentEditActions;
