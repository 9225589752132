import { gql } from "@apollo/client";

export const SEARCH_CONTEXTS = gql`
  query SearchContexts($input: SearchInput!) {
    searchContexts(input: $input) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          description
          name
          labels
          createdAt
          id
          spaceDetails {
            name
            id
          }
          hasAttachedStacks
        }
      }
    }
  }
`;

export const GET_CONTEXT_SUGGESTIONS = gql`
  query GetContextSuggestions($input: SearchSuggestionsInput!) {
    searchContextsSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const GET_CONTEXT_ATTACHMENTS = gql`
  query GetContextAttachments($id: ID!) {
    context(id: $id) {
      id
      hasAttachedStacks
      attachedStacks {
        id
        stackId
        stackName
        isModule
      }
    }
  }
`;

export const DELETE_CONTEXT = gql`
  mutation DeleteContextForContextList($id: ID!, $force: Boolean) {
    contextDelete(id: $id, force: $force) {
      id
      name
    }
  }
`;

export const UPDATE_CONTEXT = gql`
  mutation UpdateContext(
    $id: ID!
    $name: String!
    $description: String
    $labels: [String!]
    $space: ID
  ) {
    contextUpdateV2(
      id: $id
      input: { name: $name, description: $description, labels: $labels, space: $space }
    ) {
      id
      name
    }
  }
`;
