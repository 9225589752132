import { ReactNode } from "react";

type SubSidebarSectionProps = {
  children: ReactNode;
};

import styles from "./styles.module.css";

const SubSidebarSection = ({ children }: SubSidebarSectionProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default SubSidebarSection;
