import Callout from "ds/components/Callout";
import Link from "ds/components/Link";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

const ACCESS_POLICY_WARNING_KEY = "accessPolicyWarningClosed";

const AccessPolicyWarning = () => {
  return (
    <Callout variant="danger" className={styles.wrapper} storageKey={ACCESS_POLICY_WARNING_KEY}>
      A login policy has been defined for your account, but we were unable to evaluate it. Login
      policy only applies to paid accounts. Learn more about it {` `}
      <Link
        href={getDocsUrl("/concepts/policy/stack-access-policy")}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      >
        here
      </Link>
      .
    </Callout>
  );
};

export default AccessPolicyWarning;
