import { ReactNode } from "react";
import cx from "classnames";
import { NavLinkProps } from "react-router-dom"; // eslint-disable-line no-restricted-imports

import { FlexJustify } from "types/Flex";

import BaseAction from "../BaseAction";
import styles from "./styles.module.css";

type BaseProps<T extends string> = {
  justify?: FlexJustify;
  size?: "small" | "medium";
  label: ReactNode;
  disabled?: boolean;
  className?: string;
  prefixComponent?: ReactNode;
  innerRef?: React.RefObject<HTMLElement>;
  suffixComponent?: ReactNode;
  v5Compat?: boolean;
  end?: boolean;
} & (
  | {
      id: T;
      onClick?: (setter: T) => unknown;
    }
  | {
      id?: never;
      onClick?: never;
    }
);

type ButtonActionProps<T extends string> = BaseProps<T> & {
  isActive: boolean;
};

type NavLinkActionProps<T extends string> = BaseProps<T> &
  Pick<NavLinkProps, "exact" | "to" | "isActive">;

export type TabProps<T extends string> = ButtonActionProps<T> | NavLinkActionProps<T>;

const Tab = <T extends string>({
  onClick,
  className,
  isActive,
  label,
  id,
  disabled,
  size = "medium",
  prefixComponent,
  suffixComponent,
  innerRef,
  ...restProps
}: TabProps<T>) => {
  const handleClick = () => {
    onClick?.(id);
  };

  const baseClassName = cx(styles.tab, styles[size], className);

  const content = (
    <>
      {prefixComponent}
      {label}
      {suffixComponent}
    </>
  );

  if ("to" in restProps && restProps.to) {
    const baseProps = {
      ref: innerRef,
      className: baseClassName,
      onClick: handleClick,
      isNavLink: true,
      ...restProps,
    };

    // TODO: Handle active state using function-based className which requires a refactor of BaseAction
    if (restProps.v5Compat) {
      return <BaseAction {...baseProps}>{content}</BaseAction>;
    }

    return (
      <BaseAction
        isActive={isActive as NavLinkProps["isActive"]}
        activeClassName={styles.active}
        {...baseProps}
      >
        {content}
      </BaseAction>
    );
  }

  return (
    <BaseAction
      ref={innerRef}
      onClick={handleClick}
      id={`tab-${id}`}
      aria-controls={`tab-${id}`}
      className={cx(baseClassName, {
        [styles.active]: isActive,
        [styles.disabled]: disabled,
      })}
      {...restProps}
    >
      {content}
    </BaseAction>
  );
};

export default Tab;
