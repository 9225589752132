import { StackBulkActions } from "./types";

export const BULK_ACTION_RESULTS_TITLE: Record<StackBulkActions, string> = {
  [StackBulkActions.RunTask]: "Run task results",
  [StackBulkActions.ReviewApprove]: "Approve runs results",
  [StackBulkActions.ReviewReject]: "Reject runs results",
  [StackBulkActions.Confirm]: "Confirm runs results",
  [StackBulkActions.Discard]: "Discard runs results",
  [StackBulkActions.Enable]: "Enable stacks results",
  [StackBulkActions.Disable]: "Disable stacks results",
  [StackBulkActions.Lock]: "Lock stacks results",
  [StackBulkActions.Unlock]: "Unlock stacks results",
  [StackBulkActions.SyncCommit]: "Sync commit results",
  [StackBulkActions.Trigger]: "Trigger stacks results",
};

export const BULK_ACTION_NAMES: Record<StackBulkActions, string> = {
  [StackBulkActions.RunTask]: "Run task",
  [StackBulkActions.ReviewApprove]: "Review approve run",
  [StackBulkActions.ReviewReject]: "Review reject run",
  [StackBulkActions.Confirm]: "Confirm",
  [StackBulkActions.Discard]: "Discard",
  [StackBulkActions.Enable]: "Enable",
  [StackBulkActions.Disable]: "Disable",
  [StackBulkActions.Lock]: "Lock",
  [StackBulkActions.Unlock]: "Unlock",
  [StackBulkActions.SyncCommit]: "Sync commit",
  [StackBulkActions.Trigger]: "Trigger",
};
