import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { getManagementStrategy } from "views/Account/Settings/helpers";
import { SettingsContext } from "views/Account/Settings/Context";
import useAnalytics from "hooks/useAnalytics";
import { ManagedUser } from "types/generated";
import { isSaasDistribution } from "utils/distribution";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import { RESEND_MANAGED_USER_INVITE, DELETE_MANAGED_USER } from "../../gql";

const isSaas = isSaasDistribution();

type UsersListInviteDropdownProps = {
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  item: ManagedUser;
};

const UsersListInviteDropdown = ({
  handleUserDetailsClick,
  item,
}: UsersListInviteDropdownProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [resendInvite] = useMutation(RESEND_MANAGED_USER_INVITE, {
    refetchQueries: ["GetManagedUsers"],
  });

  const [revokeInvite] = useMutation(DELETE_MANAGED_USER, {
    refetchQueries: ["GetManagedUsers"],
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const handleRevokeInvite = () => {
    trackSegmentAnalyticsEvent("Revoke Invite");
    revokeInvite({ variables: { id: item.id } })
      .then(() => reportSuccess({ message: "Invitation successfully revoked" }))
      .catch(onError);
  };

  const handleResendInvite = () => {
    trackSegmentAnalyticsEvent("Resend Invite");
    resendInvite({ variables: { id: item.id } })
      .then(() => reportSuccess({ message: "Invitation successfully resent" }))
      .catch(onError);
  };

  const handleAccessDetailsClick = () => {
    handleUserDetailsClick(item);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownMenuEllipsis tooltip="User actions">
        <DropdownMenuItem onAction={handleAccessDetailsClick}>Access details</DropdownMenuItem>
        {isSaas && <DropdownMenuItem onAction={handleResendInvite}>Resend invite</DropdownMenuItem>}
        <DropdownMenuItem onAction={handleRevokeInvite} danger>
          Revoke invite
        </DropdownMenuItem>
      </DropdownMenuEllipsis>
    </div>
  );
};

export default UsersListInviteDropdown;
