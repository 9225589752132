import { StackRunsBulkActions } from "./types";

export const BULK_ACTION_RESULTS_TITLE: Record<StackRunsBulkActions, string> = {
  [StackRunsBulkActions.ReviewApprove]: "Approve runs results",
  [StackRunsBulkActions.ReviewReject]: "Reject runs results",
  [StackRunsBulkActions.Confirm]: "Confirm runs results",
  [StackRunsBulkActions.Discard]: "Discard runs results",
};

export const BULK_ACTION_NAMES: Record<StackRunsBulkActions, string> = {
  [StackRunsBulkActions.ReviewApprove]: "Approve",
  [StackRunsBulkActions.ReviewReject]: "Reject",
  [StackRunsBulkActions.Confirm]: "Confirm",
  [StackRunsBulkActions.Discard]: "Discard",
};
