import { Column, ColumnMode } from "components/DragDropList";
import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
  URL_SORT_DIRECTION,
  URL_SORT_KEY,
} from "constants/url_query_keys";
import { SearchQueryOrderDirection, StackState } from "types/generated";
import { StackSuggestions } from "constants/stack";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import { Flags } from "views/flags";

import DashboardWidgetsStackState from "./Widgets/StackState";
import DashboardWidgetsFavoriteStacks from "./Widgets/FavoriteStacks";
import DashboardWidgetsStackSize from "./Widgets/StackSize";
import DashboardWidgetsRunWorkflow from "./Widgets/RunWorkflow";
import DashboardWidgetsRecentDriftDetectionRuns from "./Widgets/RecentDriftDetectionRuns";
import DashboardWidgetsStackFailures from "./Widgets/StackFailures";
import DashboardWidgetsNextDriftDetectionSchedule from "./Widgets/NextDriftDetectionSchedule";
import DashboardWidgetsDriftDetectionCoverage from "./Widgets/DriftDetectionCoverage";
import DashboardWidgetsManagedResources from "./Widgets/ManagedResources";
import DashboardWidgetsResourcesHealth from "./Widgets/ResourcesHealth";
import DashboardWidgetsAverageRunDuration from "./Widgets/AverageRunDuration";
import DashboardWidgetsMedianRunDuration from "./Widgets/MedianRunDuration";
import DashboardWidgetsNumberOfDeployments from "./Widgets/NumberOfDeployments";
import { DashboardWidgetConfig, OverviewStaticWidget, Widget } from "./types";
import { DEFAULT_TAB_TITLES, DefaultDashboardTabs } from "./constants";
import DashboardWidgetsApprovalPolicyEvaluations from "./Widgets/ApprovalPolicyEvaluations";

export const createInitialConfig = (
  options: Partial<Record<Widget, DashboardWidgetConfig>>,
  columnMode: ColumnMode
) => {
  const keys = Object.entries(options).map(([key, value]) => ({
    value: key,
    hidden: !!value.hidden,
  })) as Column<Widget>;
  return splitColumnsConfigBaseOnMode(keys, columnMode);
};

export const splitColumnsConfigBaseOnMode = (keys: Column<Widget>, columnMode: ColumnMode) => {
  if (columnMode === ColumnMode.Single) {
    return {
      left: keys,
      right: [],
      columnMode,
    };
  }

  const half = Math.ceil(keys.length / 2);

  return {
    left: keys.slice(0, half),
    right: keys.slice(half, keys.length),
    columnMode,
  };
};

export const getInitialConfig = (flags?: Partial<Flags>) => {
  const dashboardWidgetsByTab = getDashboardsWidgetsByTab(flags);

  return {
    [DefaultDashboardTabs.Overview]: {
      ...createInitialConfig(
        dashboardWidgetsByTab[DefaultDashboardTabs.Overview],
        ColumnMode.Double
      ),
      title: DEFAULT_TAB_TITLES[DefaultDashboardTabs.Overview],
      overviewColumn: [
        { value: OverviewStaticWidget.LaunchPad, hidden: false },
        { value: OverviewStaticWidget.UserActivity, hidden: false },
      ],
    },
    [DefaultDashboardTabs.Metrics]: {
      ...createInitialConfig(
        dashboardWidgetsByTab[DefaultDashboardTabs.Metrics],
        ColumnMode.Double
      ),
      title: DEFAULT_TAB_TITLES[DefaultDashboardTabs.Metrics],
    },
  };
};

export const getDashboardsWidgetsByTab = (
  flags: Partial<Flags> | undefined
): Record<DefaultDashboardTabs, Partial<Record<Widget, DashboardWidgetConfig>>> => ({
  [DefaultDashboardTabs.Overview]: {
    [Widget.StacksState]: {
      title: "Stacks state",
      component: DashboardWidgetsStackState,
      moreActions: [
        {
          title: "Go to stacks",
          analyticsPage: AnalyticsPageDashboard.Dashboard,
          analyticsTitle: "Stacks State Widget - Dropdown Menu - Go to Stacks Clicked",
          link: "/stacks",
        },
      ],
    },
    [Widget.RecentDriftDetectionRuns]: {
      title: "Recent drift detection runs",
      component: DashboardWidgetsRecentDriftDetectionRuns,
      moreActions: [
        {
          title: "Go to runs",
          analyticsPage: AnalyticsPageDashboard.Dashboard,
          analyticsTitle: "Recent Drift Detection Runs Widget - Dropdown Menu - Go to Runs Clicked",
          // TODO: [dashboard] define time range if needed and move query keys to contants when runs redesigned
          link: `/runs?filterValues=${btoa(
            encodeURIComponent(JSON.stringify([[{ value: "true", label: "true" }]]))
          )}&filterFields=${btoa(JSON.stringify(["driftDetection"]))}`,
        },
      ],
    },
    [Widget.StacksSize]: {
      title: "Stacks size",
      component: DashboardWidgetsStackSize,
      moreActions: [
        {
          title: "Go to resources",
          analyticsPage: AnalyticsPageDashboard.Dashboard,
          analyticsTitle: "Stacks Size Widget - Dropdown Menu - Go to Resources Clicked",
          link: `/resources?groupByValue=stack`,
        },
      ],
    },
    [Widget.RunsThatRequireAttention]: {
      title: "Runs that require attention",
      component: DashboardWidgetsRunWorkflow,
      infoTooltip: "Runs that require confirmation or approval.",
      moreActions: [
        {
          title: "Go to runs",
          analyticsPage: AnalyticsPageDashboard.Dashboard,
          analyticsTitle: "Runs That Require Attention Widget - Dropdown Menu - Go to Runs Clicked",
          // TODO: [dashboard] define time range if needed and move query keys to contants when runs redesigned
          link: `/runs?filterValues=${btoa(
            encodeURIComponent(JSON.stringify([[{ value: "UNCONFIRMED", label: "UNCONFIRMED" }]]))
          )}&filterFields=${btoa(JSON.stringify(["state"]))}`,
        },
      ],
    },
    [Widget.FavoriteStacks]: {
      title: "Favorite stacks",
      component: DashboardWidgetsFavoriteStacks,
      infoTooltip: "Based on the last change to the stack state.",
      moreActions: [
        {
          title: "Go to stacks",
          analyticsPage: AnalyticsPageDashboard.Dashboard,
          analyticsTitle: "Favorite Stacks Widget - Dropdown Menu - Go to Stacks Clicked",
          link: `/stacks?${URL_SORT_KEY}=${StackSuggestions.StateSetAt}&${URL_SORT_DIRECTION}=${SearchQueryOrderDirection.Desc}&${URL_FILTER_KEYS_KEY}=starred&${URL_FILTER_TYPES_KEY}=BOOLEAN&${URL_FILTER_VALUES_KEY}=${btoa(
            encodeURIComponent(JSON.stringify([["true"]]))
          )}`,
        },
      ],
    },
    [Widget.NextDriftDetectionSchedule]: {
      title: "Next drift detection schedule",
      component: DashboardWidgetsNextDriftDetectionSchedule,
    },
    ...(flags?.approvalPolicyEvaluationsWidgetFrontend
      ? {
          [Widget.ApprovalPolicyEvaluations]: {
            title: "Approval policy evaluations",
            component: DashboardWidgetsApprovalPolicyEvaluations,
            infoTooltip: "Runs that were approved or rejected recently.",
          },
        }
      : null),
  },
  [DefaultDashboardTabs.Metrics]: {
    [Widget.DriftDetectionCoverage]: {
      title: "Drift Detection coverage",
      component: DashboardWidgetsDriftDetectionCoverage,
      infoTooltip: "How many of your stacks have drift detection enabled.",
    },
    [Widget.StacksFailures]: {
      component: DashboardWidgetsStackFailures,
      title: "Stacks failures",
      moreActions: [
        {
          title: "Go to stacks",
          analyticsPage: AnalyticsPageDashboard.Dashboard,
          analyticsTitle: "Stacks Failures Widget - Dropdown Menu - Go to Stacks Clicked",
          link: `/stacks?${URL_FILTER_KEYS_KEY}=state&${URL_FILTER_TYPES_KEY}=ENUM&${URL_FILTER_VALUES_KEY}=${btoa(
            encodeURIComponent(JSON.stringify([[StackState.Failed]]))
          )}`,
        },
      ],
    },
    ...(flags?.numberOfDeploymentsWidgetFrontend
      ? {
          [Widget.NumberOfDeployments]: {
            title: "Number of deployments",
            component: DashboardWidgetsNumberOfDeployments,
            infoTooltip: "How many tracked run finished on a given day.",
          },
        }
      : null),
    [Widget.ManagedResources]: {
      title: "Managed resources",
      component: DashboardWidgetsManagedResources,
    },
    [Widget.MedianRunDuration]: {
      title: "Median run duration",
      component: DashboardWidgetsMedianRunDuration,
    },
    [Widget.AverageRunDuration]: {
      title: "Average run duration",
      component: DashboardWidgetsAverageRunDuration,
      hidden: true,
    },
    [Widget.ResourcesHealth]: {
      title: "Resources health",
      component: DashboardWidgetsResourcesHealth,
    },
  },
});

export const getAllDashboardWidgets = (flags?: Partial<Flags>) => {
  const dashboardWidgetsByTab = getDashboardsWidgetsByTab(flags);

  return {
    ...dashboardWidgetsByTab[DefaultDashboardTabs.Overview],
    ...dashboardWidgetsByTab[DefaultDashboardTabs.Metrics],
  };
};
