import PageWrapper from "components/PageWrapper";
import SpaceliftLogo from "ds/components/SpaceliftLogo";

import styles from "./styles.module.css";

const SpaceliftLoader = () => (
  <PageWrapper className={styles.wrapper}>
    <SpaceliftLogo short className={styles.logo} />
  </PageWrapper>
);

export default SpaceliftLoader;
