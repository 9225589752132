import { FormProvider, useForm } from "react-hook-form";

import CardForm from "./CardForm";
import useAttachCloud from "../hooks/useAttachCloud";
import { ModuleCreationCloud } from "../../types";
import SubmitButton from "./SubmitButton";
import GCPScopesField from "./Fields/GCPScopesField";

type GCPIntegrationProps = {
  moduleId: string;
  onSuccess: () => void;
  refetchQueries?: string[];
};

type FormValues = {
  scopes: Array<{ value: string }>;
};

const DEFAULT_SCOPES = [
  "https://www.googleapis.com/auth/compute",
  "https://www.googleapis.com/auth/cloud-platform",
  "https://www.googleapis.com/auth/ndev.clouddns.readwrite",
  "https://www.googleapis.com/auth/devstorage.full_control",
  "https://www.googleapis.com/auth/userinfo.email",
];

const GCPIntegration = ({ onSuccess, moduleId, refetchQueries }: GCPIntegrationProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      scopes: DEFAULT_SCOPES.map((value) => ({ value })),
    },
  });

  const { handleSubmit } = form;

  const { attach } = useAttachCloud({
    moduleId,
    onSuccess,
    refetchQueries,
  });

  const onSubmit = (data: FormValues) =>
    attach({
      type: ModuleCreationCloud.GCP,
      scopes: data.scopes.map((scope) => scope.value),
    });

  return (
    <FormProvider {...form}>
      <CardForm onSubmit={handleSubmit(onSubmit)}>
        <GCPScopesField />
        <SubmitButton />
      </CardForm>
    </FormProvider>
  );
};

export default GCPIntegration;
