import { useCallback, useEffect, useRef } from "react";

import { useToggle } from "hooks/useToggle";

import { useHiddenLabelsIndicator } from "./helpers";
import { UseTagsList } from "./types";

export const useTagsList = (
  tags: string[],
  { alwaysExpanded = false, onExpand }: UseTagsList = {}
) => {
  const [isExpanded, toggle] = useToggle(alwaysExpanded);
  const labelsWrappersRef = useRef<HTMLDivElement>(null);

  useHiddenLabelsIndicator(labelsWrappersRef, tags);

  const toggleLabelsVisibility = useCallback(() => toggle(), [toggle]);

  useEffect(() => {
    onExpand?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return {
    toggleLabelsVisibility,
    labelsWrappersRef,
    isExpanded,
  };
};
