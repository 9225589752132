import { Stack, StackConfigVendorAnsible, StackConfigVendorInput } from "types/generated";
import { EMPTY_STACK_CONFIG_VENDOR_INPUT } from "constants/stack";
import { VENDOR_KEY } from "constants/vendor";

import { type StackSettingsAnsibleVendorFormFields } from "./Edit";

export const getFormDefaultValues = (
  stackSettings: Stack
): StackSettingsAnsibleVendorFormFields => {
  const config = stackSettings.vendorConfig as StackConfigVendorAnsible;

  return {
    playbook: config.playbook,
  };
};

export const mapCreateStackVendorUpdateInput = (
  formData: StackSettingsAnsibleVendorFormFields
): StackConfigVendorInput => ({
  ...EMPTY_STACK_CONFIG_VENDOR_INPUT,
  [VENDOR_KEY.ANSIBLE]: formData,
});
