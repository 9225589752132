import { useForm, SubmitHandler, Controller, FormProvider } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";

import ButtonIcon from "ds/components/ButtonIcon";
import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import { CrossNew } from "components/icons";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { NamedWebhooksIntegration } from "types/generated";
import NoAccessPage from "components/error/NoAccessPage";
import Toggle from "ds/components/Toggle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ViewWrapper from "components/ViewWrapper";
import SecretInput from "ds/components/SecretInput";
import { DEFAULT_SPACE_NAME } from "views/constants";
import FormFieldSpace from "components/FormFields/Space";
import HeadersField from "components/Forms/HeadersField";
import { getHeadersDiff } from "components/Forms/HeadersField/getHeadersDiff";

import LabelsField from "./LabelsField";
import styles from "./styles.module.css";
import { HEADERS_DELETE, HEADERS_SET, WEBHOOK_CREATE, WEBHOOK_UPDATE } from "./gql";
import { WebhookFormFields } from "./types";
import { SpacesContext } from "../SpacesProvider";

type WebhookFormProps = {
  webhook?: NamedWebhooksIntegration;
};

const WebhookForm = ({ webhook }: WebhookFormProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { hasManageableSpaces } = useTypedContext(SpacesContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const isEditMode = !!webhook;
  const isCreateMode = !webhook;

  const builderForm = useForm<WebhookFormFields>({
    defaultValues: {
      enabled: isEditMode ? webhook.enabled : true,
      enabledHeaders: isEditMode ? webhook.secretHeaders?.length > 0 : false,
      endpoint: webhook?.endpoint || "",
      name: webhook?.name || "",
      secret: webhook?.secret || "",
      space: webhook?.space.id || DEFAULT_SPACE_NAME,
      labels: webhook?.labels.map((value) => ({ value })) || [],
      headers: [],
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [namedWebhookCreate] = useMutation<{
    namedWebhooksIntegrationCreate: NamedWebhooksIntegration;
  }>(WEBHOOK_CREATE);
  const [namedWebhookUpdate] = useMutation<{
    namedWebhooksIntegrationUpdate: NamedWebhooksIntegration;
  }>(WEBHOOK_UPDATE);

  const [namedWebhooksIntegrationDeleteHeaders] = useMutation<{
    namedWebhooksIntegrationDeleteHeaders: NamedWebhooksIntegration;
  }>(HEADERS_DELETE);
  const [namedWebhooksIntegrationSetHeaders] = useMutation<{
    namedWebhooksIntegrationSetHeaders: NamedWebhooksIntegration;
  }>(HEADERS_SET);

  const afterSubmit = () => {
    reset();
  };

  const onSubmit: SubmitHandler<WebhookFormFields> = async (formData) => {
    const namedWebhookInput = {
      enabled: formData.enabled,
      endpoint: formData.endpoint,
      name: formData.name,
      secret: formData.secret,
      space: formData.space,
      labels: formData.labels.map((item) => item.value),
    };

    if (isEditMode) {
      try {
        const { data } = await namedWebhookUpdate({
          variables: {
            id: webhook.id,
            input: namedWebhookInput,
          },
        });

        const { headersToSet, headersKeysToRemove } = getHeadersDiff(
          formData.headers,
          webhook.secretHeaders,
          formData.enabledHeaders
        );

        // Nested try-catch to not block redirect and show separate error in case webhook headers update has failed
        try {
          if (headersKeysToRemove.length) {
            await namedWebhooksIntegrationDeleteHeaders({
              variables: {
                id: data?.namedWebhooksIntegrationUpdate.id,
                headerKeys: headersKeysToRemove,
              },
            });
          }

          if (headersToSet.length) {
            await namedWebhooksIntegrationSetHeaders({
              variables: {
                id: data?.namedWebhooksIntegrationUpdate.id,
                input: { entries: headersToSet },
              },
            });
          }
        } catch (e) {
          onError(e);
        }

        if (data?.namedWebhooksIntegrationUpdate?.id) {
          reportSuccess({
            message: `Webhook "${data.namedWebhooksIntegrationUpdate.name}" was successfully updated`,
          });
          afterSubmit();
          navigate(`/webhook/${data.namedWebhooksIntegrationUpdate.id}`);
        }
      } catch (e) {
        onError(e);
      }
    } else {
      try {
        const { data } = await namedWebhookCreate({
          variables: {
            input: namedWebhookInput,
          },
        });

        const headers = formData.headers
          .map(({ key, value }) => ({ key, value }))
          .filter(({ key }) => !!key);

        if (headers.length) {
          // Nested try-catch to not block redirect and show separate error in case webhook headers creation has failed
          try {
            await namedWebhooksIntegrationSetHeaders({
              variables: {
                id: data?.namedWebhooksIntegrationCreate.id,
                input: { entries: headers },
              },
            });
          } catch (e) {
            onError(e);
          }
        }

        if (data?.namedWebhooksIntegrationCreate?.id) {
          reportSuccess({
            message: `Webhook "${data.namedWebhooksIntegrationCreate.name}" was successfully created`,
          });
          afterSubmit();
          navigate(`/webhook/${data.namedWebhooksIntegrationCreate.id}`);
        }
      } catch (e) {
        onError(e);
      }
    }
  };

  if (!viewer.admin && !hasManageableSpaces) {
    return <NoAccessPage />;
  }

  const navigationUrlForCancel = isEditMode ? `/webhook/${webhook.id}` : "/webhooks";

  return (
    <ViewWrapper>
      <ViewHeader firstLevel>
        <ViewHeaderTitle>{isCreateMode ? "Create a new webhook" : "Edit webhook"}</ViewHeaderTitle>
        <ViewHeaderWrapper direction="row">
          <ButtonIcon icon={CrossNew} to={navigationUrlForCancel} variant="ghost">
            Close
          </ButtonIcon>
        </ViewHeaderWrapper>
      </ViewHeader>
      <Box direction="column" align="center" className={styles.content}>
        <div className={styles.formWrapper}>
          <FormProvider {...builderForm}>
            <div className={styles.viewList}>
              <FormField label="Name" error={errors?.name?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter webhook name here..."
                    error={!!errors?.name}
                    {...register("name", { required: "Name field is required." })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            </div>
            <div className={styles.viewList}>
              <FormField label="Endpoint URL" error={errors?.endpoint?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Full endpoint to send a request to"
                    error={!!errors?.endpoint}
                    {...register("endpoint", { required: "Endpoint field is required." })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            </div>
            <div className={styles.viewList}>
              <FormFieldSpace />
            </div>
            <div className={styles.viewList}>
              <FormField label="Secret" error={errors?.secret?.message}>
                {({ ariaInputProps }) => (
                  <SecretInput
                    placeholder="Secret to verify payload"
                    error={!!errors?.secret}
                    {...register("secret")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            </div>
            <div className={styles.viewList}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => (
                  <Toggle variant="switch" onChange={field.onChange} checked={field.value}>
                    Enable webhook
                  </Toggle>
                )}
              />
            </div>
            <div className={styles.viewList}>
              <LabelsField />
            </div>

            <div className={styles.viewList}>
              <HeadersField isEditMode={isEditMode} previousHeaderKeys={webhook?.secretHeaders} />
            </div>

            <Box direction="row" justify="end" align="center" className={styles.footer}>
              <Box direction="row" className={styles.actions}>
                <Button variant="secondary" to={navigationUrlForCancel}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || (isEditMode && !isDirty)}
                >
                  {isEditMode ? "Save" : "Create"}
                </Button>
              </Box>
            </Box>
          </FormProvider>
        </div>
      </Box>
    </ViewWrapper>
  );
};

export default WebhookForm;
