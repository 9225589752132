import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { StackDependencyReference, StackDependencyReferenceUpdateInput } from "types/generated";

import { STACK_DEPENDENCIES_UPDATE_REFERENCES } from "./gql";

const useUpdateOutputReference = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackDependenciesUpdateReferences] = useMutation<{
    stackDependenciesUpdateReferences: StackDependencyReference[];
  }>(STACK_DEPENDENCIES_UPDATE_REFERENCES, {
    refetchQueries: ["GetStack"],
    onError,
  });

  const handleUpdateReferences = useCallback(
    (references: StackDependencyReferenceUpdateInput[], callback?: () => void) => {
      stackDependenciesUpdateReferences({
        variables: {
          references,
        },
      })
        .then(({ data }) => {
          if (
            data?.stackDependenciesUpdateReferences &&
            data.stackDependenciesUpdateReferences.length > 0
          ) {
            reportSuccess({
              message: `Output reference successfully updated`,
            });
            callback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackDependenciesUpdateReferences]
  );

  return {
    updateOutputReference: handleUpdateReferences,
  };
};

export default useUpdateOutputReference;
