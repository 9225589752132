import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type ManageSlackAccessListConfirmationModalProps = {
  onConfirm: () => void;
  name: string;
};

const ManageSlackAccessListConfirmationModal = create(
  function ManageSlackAccessListConfirmationModal({
    onConfirm,
    name,
  }: ManageSlackAccessListConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      modal.resolve();
      modal.hide();
      onConfirm();
    };

    return (
      <ConfirmationModal
        title="Delete Slack access"
        confirmationButtonLabel="Delete"
        confirmationButtonVariant="dangerPrimary"
        onConfirm={handleOnConfirm}
        size="large"
      >
        <Typography variant="p-body2" tag="p">
          Are you sure you want to remove {name} Slack access?
        </Typography>
      </ConfirmationModal>
    );
  }
);

export const showManageSlackAccessListConfirmationModal = (
  props: ManageSlackAccessListConfirmationModalProps
) => show(ManageSlackAccessListConfirmationModal, props);
