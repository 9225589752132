import cx from "classnames";

import { AccountContext } from "views/AccountWrapper";
import LoginList from "components/LoginList";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import BaseActionButton from "ds/components/BaseAction/Button";
import { ChevronNew } from "components/icons";
import { getDocsUrl } from "utils/getDocsUrl";
import { useAccountDetailsLocationDescriptor } from "views/Account/AccountDetails/useLocationDescriptor";
import Icon from "ds/components/Icon";
import useTypedFlags from "hooks/useTypedFlags";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuDivider from "ds/components/DropdownMenu/Divider";

import UserCard from "./UserCard";
import styles from "./styles.module.css";

type UserDropdownProps = {
  isExpanded: boolean;
};

const isSaas = isSaasDistribution();
const isSelfHosted = isSelfHostedDistribution();

const UserDropdown = ({ isExpanded }: UserDropdownProps) => {
  const { accountName, viewer } = useTypedContext(AccountContext);
  const accountDetailsTo = useAccountDetailsLocationDescriptor();
  const { auditTrailLogsFrontend } = useTypedFlags();

  return (
    <DropdownMenu
      placement="right bottom"
      className={styles.dropdown}
      triggerComponent={
        <BaseActionButton aria-label="User menu" justify="start" className={styles.userDropdown}>
          <UserCard isExpanded={isExpanded} accountName={accountName} viewer={viewer} />
          <Icon
            src={ChevronNew}
            className={cx(styles.userDropdownIcon, isExpanded && styles.expanded)}
            noShrink
          />
        </BaseActionButton>
      }
    >
      {!isSelfHosted && (
        <>
          <LoginList accountName={accountName} />
          <DropdownMenuDivider />
        </>
      )}

      {viewer.admin && <DropdownMenuItem href="/settings">Organization settings</DropdownMenuItem>}
      <DropdownMenuItem href="/personal-settings">Personal settings</DropdownMenuItem>
      <DropdownMenuItem href={accountDetailsTo}>Account details</DropdownMenuItem>

      <DropdownMenuDivider />

      {viewer.admin && auditTrailLogsFrontend && (
        <>
          <DropdownMenuItem href="/audit-trail">Audit Trail</DropdownMenuItem>
          <DropdownMenuDivider />
        </>
      )}

      <DropdownMenuItem href="https://spacelift.io" target="_blank" rel="noreferrer">
        About
      </DropdownMenuItem>
      <DropdownMenuItem href={getDocsUrl()} target="_blank" rel="noreferrer">
        Docs
      </DropdownMenuItem>
      <DropdownMenuItem href={getDocsUrl("/legal/privacy")} target="_blank" rel="noreferrer">
        Privacy
      </DropdownMenuItem>
      {isSaas && (
        <DropdownMenuItem href="https://spacelift.statuspage.io" target="_blank" rel="noreferrer">
          Status
        </DropdownMenuItem>
      )}
      <DropdownMenuItem href={getDocsUrl("/legal/terms")} target="_blank" rel="noreferrer">
        Terms & Conditions
      </DropdownMenuItem>

      <DropdownMenuDivider />

      <DropdownMenuItem href="/logout" danger>
        Logout
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

export default UserDropdown;
