import { useLayoutEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";

type PersonalSettingsGroupsListItemProps = {
  item: string;
  setRowHeight?: (size: number) => void;
};

const PersonalSettingsGroupsListItem = ({
  item,
  setRowHeight,
}: PersonalSettingsGroupsListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useLayoutEffect(handleRowHeight);

  return (
    <ListEntitiesItem ref={rowRef}>
      <Box direction="row" align="center">
        <TextEllipsis tooltip={item} tooltipWidthMode="maxWidthXl">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {item}
            </Typography>
          )}
        </TextEllipsis>
      </Box>
    </ListEntitiesItem>
  );
};

export default PersonalSettingsGroupsListItem;
