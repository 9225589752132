import capitalize from "lodash-es/capitalize";
import moment from "moment";
import DOMPurify from "dompurify";

export const ENTITIES_LABELS = {
  address: "Address",
  authorLogin: "Author Login",
  authorName: "Author Name",
  branch: "Branch",
  commit: "Commit SHA",
  createdAt: "Created At",
  delta: "Delta",
  driftDetection: "Drift Detection",
  hash: "Hash",
  id: "ULID",
  labels: "Labels",
  message: "Message",
  module: "Module",
  repository: "Repository",
  runtimeConfig: "Runtime Configuration",
  state: "State",
  stack: "Stack",
  timestamp: "Time",
  title: "Title",
  user: "User",
  type: "Type",
  url: "URL",
};

const translateEntityValue = (key, value) => {
  if (key === "driftDetection") {
    return JSON.stringify(value); // use false or true string for drift detection
  }

  if (!value) return "none";

  if (key === "commit") {
    return value.hash;
  }

  if (key === "stack") {
    return value.id;
  }

  if (key === "module") {
    return value.id;
  }

  if (key === "createdAt" || key === "timestamp") {
    return moment.unix(value).format("YYYY-MM-DD HH:mm:ss");
  }

  if (key === "vendor") {
    return JSON.stringify(Object.values(value)[0], null, 2);
  }

  return JSON.stringify(value, null, 2);
};

export const translateEntity = (entity, key) => {
  if (key === "message" && typeof entity === "string") {
    return DOMPurify.sanitize(entity);
  }

  if (key !== "message" && typeof entity === "string") {
    return entity;
  }

  return translateEntityValue(key, entity);
};

export const generateEntityLabel = (entity, key) => {
  if (key === "vendor") {
    const [vendorName] = Object.keys(entity);

    return `${ENTITIES_LABELS[key]} (${capitalize(vendorName)})`;
  }

  if (key.startsWith("thirdPartyMetadata.")) {
    const name = key.split(".", 2)[1];

    return `${ENTITIES_LABELS.thirdPartyMetadata} (${capitalize(name)})`;
  }

  if (key in ENTITIES_LABELS) {
    return ENTITIES_LABELS[key];
  } else {
    return key;
  }
};
