import useLocalStorage from "@rehooks/local-storage";

import useSaaSOnboardingProgress from "views/Account/LaunchPad/ChecklistSaaS/useSaaSOnboardingProgress";
import { GET_STARTED_FEATURES } from "views/Account/LaunchPad/ChecklistSaaS/features";

import DashboardOverviewColumnOnboardingLayout from "../OnboardingLayout";

const DashboardOverviewColumnOnboardingSaaS = () => {
  const [isOnboardingFinishedLocalStorage] = useLocalStorage(
    "dashboardWidgetOnboardingFinished",
    false
  );

  const {
    onboardingProgressInitialLoading,
    progressByFeature,
    isOnboardingComplete,
    error,
    refetch,
    loading,
    networkStatus,
  } = useSaaSOnboardingProgress(isOnboardingFinishedLocalStorage, false);

  return (
    <DashboardOverviewColumnOnboardingLayout
      onboardingProgressInitialLoading={onboardingProgressInitialLoading}
      progressByFeature={progressByFeature}
      isOnboardingComplete={isOnboardingComplete}
      error={error}
      refetch={refetch}
      loading={loading}
      networkStatus={networkStatus}
      features={GET_STARTED_FEATURES}
    />
  );
};

export default DashboardOverviewColumnOnboardingSaaS;
