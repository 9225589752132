import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import HistoryEntryNote from "../../components/HistoryEntryNote";
import { RunHistoryEntryType } from "../../types";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";
import HistoryEntryUsername from "../../components/HistoryEntryUsername";

type CommentEntryProps = {
  username: string;
  body: string;
  createdAt: number;
};

const CommentEntry = ({ username, body, createdAt }: CommentEntryProps) => {
  const label = (
    <HistoryEntryLabel>
      <strong>Note</strong> added <HistoryEntryUsername username={username} />
    </HistoryEntryLabel>
  );

  return (
    <HistoryEntryWrapper
      label={label}
      state={RunHistoryEntryType.Comment}
      timestamp={createdAt}
      terminal
    >
      <HistoryEntryNote>
        <ReactMarkdown remarkPlugins={[gfm]} skipHtml>
          {body}
        </ReactMarkdown>
      </HistoryEntryNote>
    </HistoryEntryWrapper>
  );
};

export default CommentEntry;
