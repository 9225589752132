import FormFieldViewText from "components/FormFields/ViewText";
import useTypedFlags from "hooks/useTypedFlags";
import { GithubIntegration } from "types/generated";

import { VCSCheckLabels } from "./helpers";

type VCSIntegrationsDetailsGitHubProps = {
  details: GithubIntegration;
};

const VCSIntegrationsDetailsGitHub = ({ details }: VCSIntegrationsDetailsGitHubProps) => {
  const { aggregatedVcsChecks } = useTypedFlags();

  return (
    <>
      {aggregatedVcsChecks && (
        <FormFieldViewText label="VCS checks" value={VCSCheckLabels[details.vcsChecks]} />
      )}
    </>
  );
};

export default VCSIntegrationsDetailsGitHub;
