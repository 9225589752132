import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { ManagedUserGroup } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";
import ListEntitiesItem from "components/ListEntitiesItem";
import TabPanel from "ds/components/TabPanel";
import Typography from "ds/components/Typography";
import { getProviderName } from "views/Account/Settings/Users/helpers";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import TabWithCounter from "ds/components/Tab/WithCounter";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { GroupsDrawerTabs, UpdateGroupFields } from "./types";
import useUpdateGroup from "./useUpdateGroup";
import styles from "./styles.module.css";
import { getManagementStrategy } from "../../helpers";
import { SettingsContext } from "../../Context";
import UserGroupStatusBadge from "../UserGroupStatusBadge";

type IdpGroupMappingDetailsDrawerContentProps = {
  group: ManagedUserGroup;
  handleCloseDrawer: () => void;
  viewPageMode?: boolean;
};

const IdpGroupMappingDetailsDrawerContent = ({
  group,
  handleCloseDrawer,
}: IdpGroupMappingDetailsDrawerContentProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [currentTab, setCurrentTab] = useState("spaces");

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const groupAddForm = useForm<UpdateGroupFields>({
    defaultValues: {
      spaces: group.accessRules.length > 0 ? group.accessRules : [],
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,

    formState: { isValid, isDirty },
  } = groupAddForm;

  const { onUpdate } = useUpdateGroup();

  const providerName = getProviderName(group.identityProvider);

  const closeDrawer = () => {
    handleCloseDrawer();
    reset();
  };

  const onSubmit: SubmitHandler<UpdateGroupFields> = (formData) => {
    trackSegmentAnalyticsEvent("Update group details");
    const input = {
      id: group.id,
      accessRules: formData.spaces.filter((space) => !!space.space && !!space.spaceAccessLevel),
    };
    onUpdate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Group access was updated`,
          });

          closeDrawer();
        }
      })
      .catch(onError);
  };

  return (
    <FormProvider {...groupAddForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Access details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight hasStickyFooter>
        <FormFieldViewText label="Name" value={group.groupName} />
        <FormFieldViewText label="Login method" value={providerName} noTextEllipsis />
        {group.lastLoginTime && (
          <FormFieldTimestamp timestamp={group.lastLoginTime} label="Last login" />
        )}

        <FormField label="Status" className={styles.status}>
          <UserGroupStatusBadge status={group.status} />
        </FormField>

        <Box direction="row" align="center" gap="medium" className={styles.tabs}>
          <TabWithCounter
            onClick={setCurrentTab}
            isActive={currentTab === GroupsDrawerTabs.Spaces}
            id={GroupsDrawerTabs.Spaces}
            label="Spaces"
            count={group.spaceCount}
          />
          <TabWithCounter
            onClick={setCurrentTab}
            isActive={currentTab === GroupsDrawerTabs.Users}
            id={GroupsDrawerTabs.Users}
            label="Users"
            count={group.memberCount || 0}
          />
        </Box>

        <TabPanel isActive={currentTab === GroupsDrawerTabs.Spaces} id={GroupsDrawerTabs.Spaces}>
          <div className={styles.listWrapper}>
            <FormFieldSpace
              analyticsPage={AnalyticsPageOrganization.OrganizationIdpGroupMapping}
              allowEmpty
            />
          </div>
        </TabPanel>
        <TabPanel isActive={currentTab === GroupsDrawerTabs.Users} id={GroupsDrawerTabs.Users}>
          <div className={styles.listWrapper}>
            {group.members.map((item, index) => {
              return (
                <ListEntitiesItem key={`${item}-${index}`} className={styles.listItem}>
                  <Typography tag="span" variant="p-t6">
                    {item}
                  </Typography>
                </ListEntitiesItem>
              );
            })}
          </div>
        </TabPanel>
        <DrawerFooter sticky>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save changes
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default IdpGroupMappingDetailsDrawerContent;
