import { useCallback, useEffect, useRef, useState } from "react";

type UseSwappableTooltipOptions = {
  initialTooltipText: string;
  swapDelay: number;
};

export const useSwappableTooltip = ({
  initialTooltipText,
  swapDelay,
}: UseSwappableTooltipOptions) => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(true);
  const [tooltipText, setTooltipText] = useState<string>(initialTooltipText);
  const toggleTooltipTextTimeout = useRef<NodeJS.Timeout>();

  useEffect(
    () => () => {
      clearTimeout(toggleTooltipTextTimeout.current);
    },
    []
  );

  const swapText = useCallback(
    (newText: string) => {
      setTooltipActive(false);
      toggleTooltipTextTimeout.current = setTimeout(() => {
        setTooltipText(newText);
        setTooltipActive(true);
      }, swapDelay);
    },
    [swapDelay]
  );

  return {
    tooltipActive,
    tooltipText,
    swapText,
  };
};
