import BadgeNext from "ds/components/BadgeNext";
import Tree, { TreeBranch } from "ds/components/Tree";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import {
  RunApprovalPoliciesEntryList,
  RunApprovalPoliciesEntryType,
  RunEntryEvaluationProps,
  RunHistoryEntryType,
} from "../../types";
import ReviewForm from "./ReviewForm";
import Review from "./Review";
import Policies from "./Policies";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type ApprovalPoliciesEntryProps = RunEntryEvaluationProps & {
  canApprove: boolean;
  list: RunApprovalPoliciesEntryList;
  openListByDefault: boolean;
  showReviewForm: boolean;
  stackId: string;
  runId: string;
  isModuleRun: boolean;
  runQueryToRefetch: string;
};

const ApprovalPoliciesEntry = ({
  canApprove,
  list,
  openListByDefault,
  showReviewForm,
  evaluationLabel,
  evaluationBadgeVariant,
  evaluationTimestamp,
  stackId,
  runId,
  isModuleRun,
  runQueryToRefetch,
}: ApprovalPoliciesEntryProps) => {
  const [isOpen, toggle] = useVisibilityToggle(openListByDefault);

  const label = (
    <>
      <HistoryEntryLabel>
        <strong>Approval policies</strong> evaluated to
      </HistoryEntryLabel>
      <BadgeNext
        text={evaluationLabel.toUpperCase()}
        variant={evaluationBadgeVariant}
        type="strong"
      />
    </>
  );

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.ApprovalPolicies}
      timestamp={evaluationTimestamp}
      onClick={toggle}
      isOpen={isOpen}
      label={label}
      terminal
    >
      {isOpen && (
        <Tree margin="0 0 0 medium">
          {canApprove && showReviewForm && (
            <TreeBranch align="start">
              <ReviewForm runId={runId} stackId={stackId} runQueryToRefetch={runQueryToRefetch} />
            </TreeBranch>
          )}

          {list.map((item) => {
            if (item.type === RunApprovalPoliciesEntryType.Policies) {
              return (
                <Policies
                  policies={item.policies}
                  key={item.policies[0].id}
                  runId={runId}
                  stackId={stackId}
                  isModuleRun={isModuleRun}
                />
              );
            }

            return <Review key={item.review.id} review={item.review} />;
          })}
        </Tree>
      )}
    </HistoryEntryWrapper>
  );
};

export default ApprovalPoliciesEntry;
