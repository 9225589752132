import { ApolloError, NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";

import { StackPolicyAttachment } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { GET_MODULE_ATTACHED_POLICIES, GET_STACK_ATTACHED_POLICIES } from "./gql";
import { EntityType } from "./types";

type UseAttachedPoliciesProps = {
  entityId: string;
  entityType: EntityType;
};

type UseAttachedPoliciesData = {
  manuallyAttachedPolicies: StackPolicyAttachment[];
  autoAttachedPolicies: StackPolicyAttachment[];
  allAttachedPoliciesSet: Set<string>;
  isPageLoading: boolean;
  isPageNotFound: boolean;
  error: ApolloError | undefined;
};

const useGetAttachedPolicies = ({
  entityId,
  entityType,
}: UseAttachedPoliciesProps): UseAttachedPoliciesData => {
  const isStack = entityType === "stack";

  const { onError } = useTypedContext(FlashContext);

  const { data, previousData, error, loading, networkStatus } = useQuery<{
    entity: {
      attachedPolicies: StackPolicyAttachment[];
    };
  }>(isStack ? GET_STACK_ATTACHED_POLICIES : GET_MODULE_ATTACHED_POLICIES, {
    onError,
    fetchPolicy: "no-cache",
    variables: {
      entityId,
    },
  });

  // TODO: check with polling
  const attachedPolicies = useMemo(
    () => data?.entity.attachedPolicies || previousData?.entity.attachedPolicies || [],
    [data?.entity.attachedPolicies, previousData?.entity.attachedPolicies]
  );

  const [manuallyAttachedPolicies, autoAttachedPolicies, allAttachedPoliciesSet] = useMemo(() => {
    return attachedPolicies.reduce<[StackPolicyAttachment[], StackPolicyAttachment[], Set<string>]>(
      (acc, policy) => {
        if (policy.isAutoattached) {
          acc[1].push(policy);
        } else {
          acc[0].push(policy);
        }

        acc[2].add(policy.policyId);

        return acc;
      },
      [[], [], new Set<string>()]
    );
  }, [attachedPolicies]);

  return {
    manuallyAttachedPolicies,
    autoAttachedPolicies,
    allAttachedPoliciesSet,
    isPageLoading: loading && networkStatus === NetworkStatus.loading,
    isPageNotFound: !loading && !error && !data?.entity,
    error,
  };
};

export default useGetAttachedPolicies;
