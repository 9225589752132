import { Module, Stack } from "types/generated";

export const getEntityPolicyReceiptSample = (
  data: { stack: Stack } | { module: Module } | undefined
) => {
  if (!data) return;

  const entity = "module" in data ? data.module : data.stack;

  return entity?.run?.policyReceiptSample;
};
