import { memo, useState } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import TableCell from "./Cell";
import styles from "./styles.module.css";

type TableDropdownCellProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
  dropdownActions?: Array<{ title: string; action: () => void }>;
};

const TableDropdownCell = ({
  id,
  children,
  className,
  dropdownActions,
}: TableDropdownCellProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <TableCell
      id={id}
      className={cx(
        dropdownActions && styles.cellInteractive,
        isActive && styles.cellActive,
        className
      )}
    >
      <Box justify="between" align="start" gap="medium">
        <Box>
          <Box direction="column" className={styles.cellContent}>
            {children}
          </Box>
        </Box>

        {dropdownActions && (
          <DropdownMenuEllipsis
            onOpenChange={setIsActive}
            className={styles.cellDropdown}
            tooltip="Cell options"
          >
            {dropdownActions.map(({ title, action }, i) => (
              <DropdownMenuItem key={i} onAction={action}>
                {title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuEllipsis>
        )}
      </Box>
    </TableCell>
  );
};

export default memo(TableDropdownCell);
