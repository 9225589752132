import { gql } from "@apollo/client";

export const LIST_PRS = gql`
  query ListPRs($stackId: ID!) {
    stack(id: $stackId) {
      id
      pullRequests {
        id
        authorLogin
        createdAt
        headBranch
        headSHA
        run {
          id
          delta {
            addCount
            changeCount
            deleteCount
            resources
            importCount
            forgetCount
            moveCount
          }
          state
        }
        title
        url
      }
      trackedCommit {
        url
        hash
      }
      trackedBranchHead {
        url
        hash
      }
      trackedCommitSetBy
    }
  }
`;
