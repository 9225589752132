import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { GitlabIntegration, MutationGitlabIntegrationCreateArgs } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { CREATE_GITLAB_INTEGRATION } from "./gql";

const useCreateGitLabIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [gitlabIntegrationCreate, { loading }] = useMutation<{
    gitlabIntegrationCreate: GitlabIntegration;
  }>(CREATE_GITLAB_INTEGRATION, {
    onError,
  });

  const createGitLabIntegration = useCallback(
    (input: MutationGitlabIntegrationCreateArgs, successCallback?: () => void) => {
      gitlabIntegrationCreate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.gitlabIntegrationCreate) {
            reportSuccess({
              message: `Integration ${data.gitlabIntegrationCreate.name} was successfully created`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("GitLab - Setup Finished", {
              labels: input.customInput?.labels?.length,
              space: input.customInput?.spaceID,
            });
          }
        })
        .catch(onError);
    },
    [gitlabIntegrationCreate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    createGitLabIntegration,
    loading,
  };
};

export default useCreateGitLabIntegration;
