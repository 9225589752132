import { gql } from "@apollo/client";

export const ADD_CONTEXT_CONFIG = gql`
  mutation AddContextConfig($contextId: ID!, $input: ConfigInput!) {
    contextConfigAdd(context: $contextId, config: $input) {
      id
      type
    }
  }
`;

export const UPDATE_CONTEXT_CONFIG = gql`
  mutation UpdateContextConfig($contextId: ID!, $input: ConfigInput!) {
    contextConfigAdd(context: $contextId, config: $input) {
      id
      type
    }
  }
`;
