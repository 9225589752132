import { gql } from "@apollo/client";

export const SEARCH_MODULE_NAME_SUGGESTIONS = gql`
  query NewModuleSearchModuleSuggestions($input: SearchSuggestionsInput!) {
    searchModulesSuggestions(input: $input) {
      fields {
        possibleValuesString {
          values
          counts
        }
      }
    }
  }
`;
