import { RunStateTransition } from "types/generated";

import PlanningEntry from "../entries/PlanningEntry";
import { canShowElement } from "./elements";
import { RunElementType, RunEntryContext } from "../types";
import { getRunStateTransitionId } from "./getRunStateTransitionId";
import { mergeWithAfterRunHooks } from "./mergeWithAfterRunHooks";

export const createPlanningEntry = (
  { timestamp, until, stateVersion, hasLogs }: RunStateTransition,
  {
    runHooks,
    runId,
    stackId,
    isModuleRun,
    canStop,
    isRunFinished,
    runQueryToRefetch,
  }: RunEntryContext
) => {
  let afterHooks = runHooks.afterPlan;

  if (isRunFinished && canShowElement(RunElementType.AfterRunHooks)) {
    afterHooks = mergeWithAfterRunHooks(afterHooks, runHooks.afterRun);
  }

  return (
    <PlanningEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      until={until}
      stateVersion={stateVersion}
      hasLogs={hasLogs}
      openLogsByDefault={canShowElement(RunElementType.Logs)}
      showStopButton={canShowElement(RunElementType.TerminateRunAction)}
      beforeHooks={runHooks.beforePlan}
      afterHooks={afterHooks}
      runId={runId}
      stackId={stackId}
      isModuleRun={isModuleRun}
      canStop={canStop}
      runQueryToRefetch={runQueryToRefetch}
    />
  );
};
