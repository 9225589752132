import VersionTooltipContent from "components/FormFields/VersionInput/VersionTooltipContent";
import FormFieldViewBoolean from "components/FormFields/ViewBoolean";
import FormFieldViewText from "components/FormFields/ViewText";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { workflowToolLabel } from "constants/terraform_workflow_tools";
import { VENDOR_DICTIONARY } from "constants/vendor";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useTypedContext from "hooks/useTypedContext";
import { StackConfigVendorTerraform, TerraformWorkflowTool } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import { StackSettingsContextData } from "../../Context";
import SettingsNewVendorViewVersion from "../components/ViewVersion";
import { getExternalStateAccessEnabledTooltip, getWorkflowToolTooltip } from "./getTooltips";

const StackSettingsVendorTerraformView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const config = stackSettings.vendorConfig as StackConfigVendorTerraform;
  const isOpenTofu = config.workflowTool === TerraformWorkflowTool.OpenTofu;
  return (
    <>
      <StackSettingsViewVendorFormField
        vendorName={
          isOpenTofu
            ? `${VENDOR_DICTIONARY.TERRAFORM} / ${VENDOR_DICTIONARY.OPEN_TOFU}`
            : VENDOR_DICTIONARY.TERRAFORM
        }
      />
      <FormFieldViewText
        label="Workflow tool"
        value={workflowToolLabel(config.workflowTool)}
        {...getWorkflowToolTooltip()}
        noMargin
      />
      {config.version && (
        <SettingsNewVendorViewVersion
          label="Version"
          version={config.version}
          versionTooltip={
            <>
              <TooltipModalTitle>Version</TooltipModalTitle>
              <TooltipModalBody align="start">
                <VersionTooltipContent />
                <ReadMoreDocsLink
                  docsUrl={getDocsUrl("/concepts/stack/stack-settings#terraform-version")}
                />
              </TooltipModalBody>
            </>
          }
          effectiveVersion={stackSettings.effectiveTerraformVersion}
          effectiveVersionTooltip={
            "When the Terraform version is a version range, this is the highest version in the range that will be used for the next run."
          }
        />
      )}
      <FormFieldViewBoolean
        label="Smart sanitization"
        value={config.useSmartSanitization}
        noMargin
      />
      <FormFieldViewBoolean
        label="Manage state"
        value={stackSettings.managesStateFile}
        {...getExternalStateAccessEnabledTooltip()}
        noMargin
      />
      {stackSettings.managesStateFile && (
        <FormFieldViewText label="Workspace" value={config.workspace} noMargin />
      )}
    </>
  );
};

export default StackSettingsVendorTerraformView;
