import { gql } from "@apollo/client";

export const GET_GITHUB_ENTERPRISE_INTEGRATION = gql`
  query GetGitHubEnterpriseIntegration($id: ID!) {
    githubEnterpriseIntegration(id: $id) {
      vcsChecks
      apiHost
      appID
      appUrl
      description
      githubAppStatus
      id
      labels
      name
      isDefault
      space {
        id
        name
        accessLevel
      }
      userFacingHost
      webhookSecret
      webhookUrl
    }
  }
`;
