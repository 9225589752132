import { useMemo } from "react";

import BadgeNext from "ds/components/BadgeNext";
import { RunPolicyReceipt } from "types/generated";
import Tree from "ds/components/Tree";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import { useVisibilityToggle } from "../../hooks/useVisibilityToggle";
import { getPolicyEvaluation } from "./utils";
import HistoryEntryPolicy from "../../components/HistoryEntryPolicy";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type PushPoliciesEntryProps = {
  list: RunPolicyReceipt[];
  openListByDefault: boolean;
  stackId: string;
  runId: string;
  isModuleRun: boolean;
};

const PushPoliciesEntry = ({
  list,
  openListByDefault,
  stackId,
  runId,
  isModuleRun,
}: PushPoliciesEntryProps) => {
  const [show, toggle] = useVisibilityToggle(openListByDefault);

  const evaluation = useMemo(() => getPolicyEvaluation(list), [list]);

  const label = (
    <>
      <HistoryEntryLabel>
        <strong>Push policies</strong> evaluated to
      </HistoryEntryLabel>
      <BadgeNext
        text={evaluation.label.toUpperCase()}
        variant={evaluation.badgeVariant}
        type="strong"
      />
    </>
  );

  return (
    <HistoryEntryWrapper
      state={RunHistoryEntryType.PushPolicies}
      timestamp={evaluation.timestamp}
      onClick={toggle}
      isOpen={show}
      label={label}
      terminal
    >
      {show && (
        <Tree margin="0 0 0 medium">
          {list.map((policy) => (
            <HistoryEntryPolicy
              key={policy.id}
              item={policy}
              runId={runId}
              stackId={stackId}
              isModuleRun={isModuleRun}
            />
          ))}
        </Tree>
      )}
    </HistoryEntryWrapper>
  );
};

export default PushPoliciesEntry;
