import { ReactNode } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type CollapsibleListEmptyPlaceholderProps = {
  children: ReactNode;
};

const CollapsibleListEmptyPlaceholder = ({ children }: CollapsibleListEmptyPlaceholderProps) => {
  return (
    <Box
      direction="column"
      className={styles.emptyPlaceholder}
      padding="medium large"
      align="center"
      fullWidth
    >
      <Typography tag="div" variant="p-body3">
        {children}
      </Typography>
    </Box>
  );
};

export default CollapsibleListEmptyPlaceholder;
