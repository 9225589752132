import { AccountViewer } from "components/form/graphql/types";
import { Module } from "types/generated";
import { hasSpaceManageAccess, hasSpaceManageOrWriteAccess } from "utils/user";

// TODO: replace all Module-related access checks with these utils
// FYI: the "stack.canWrite" check here is for legacy stacks (could be affected by access policies)
export const hasAtLeastModuleWriteAccess = (module: Module) =>
  module.canWrite || hasSpaceManageOrWriteAccess(module.spaceDetails.accessLevel);

export const canManageModules = (module: Module, viewer: AccountViewer | undefined) =>
  viewer?.admin || hasSpaceManageAccess(module.spaceDetails.accessLevel);
