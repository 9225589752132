import { ReactNode } from "react";

import Typography from "ds/components/Typography";

type TooltipModalTitleProps = {
  children: ReactNode;
};

export const TooltipModalTitle = ({ children }: TooltipModalTitleProps) => {
  return (
    <Typography variant="p-t6" tag="p" id="tooltip-modal-title">
      {children}
    </Typography>
  );
};
