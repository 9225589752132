import ButtonIcon from "ds/components/ButtonIcon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { Pencil } from "components/icons";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import useTypedContext from "hooks/useTypedContext";

import { ContextMountedFilesListItemHeaderProps } from "./types";
import { COLUMN_ORDER_MOUNTED_FILES_LIST_ITEM } from "../../constants";
import styles from "./styles.module.css";
import { ContextContext } from "../../Context";

const ContextMountedFilesListItemHeader = ({
  children,
  item,
  onOpenEditDrawer,
  isCollapsed,
}: ContextMountedFilesListItemHeaderProps) => {
  const { canManageContext } = useTypedContext(ContextContext);
  return (
    <CollapsiblePanelHeader
      padding="large"
      align="center"
      gap="large"
      grid
      gridTemplate={COLUMN_ORDER_MOUNTED_FILES_LIST_ITEM}
    >
      <Box role="heading" aria-level={3} zeroMinWidth>
        <CollapsiblePanelToggleTrigger gap="medium" fullWidth>
          <CollapsiblePanelToggleIndicator />

          <Box direction="column" zeroMinWidth>
            <Box gap="medium" align="center">
              <CollapsiblePanelTitle className={styles.title}>
                <Box>
                  <Typography tag="span" variant="p-body2" color="secondary">
                    /mnt/workspace/
                  </Typography>
                  <TextEllipsis tooltip={item?.id} tooltipWidthMode="maxWidthSm">
                    {(props) => (
                      <Typography tag="span" variant="p-t6" {...props}>
                        {!item.id ? "New file" : item.id}
                      </Typography>
                    )}
                  </TextEllipsis>
                </Box>
              </CollapsiblePanelTitle>
              {!isCollapsed && canManageContext && (
                <ButtonIcon icon={Pencil} onClick={onOpenEditDrawer} variant="ghost">
                  Edit
                </ButtonIcon>
              )}
            </Box>

            {item.description && (
              <ListEntitiesItemDescription id={item.id} description={item.description} />
            )}
          </Box>
        </CollapsiblePanelToggleTrigger>
      </Box>
      <Typography variant="p-body2" tag="p">
        {item.writeOnly ? "Secret" : "Plain"}
      </Typography>

      <Typography variant="p-body2" tag="p">
        {item.createdAt && <Timestamp timestamp={item.createdAt} />}
      </Typography>

      {children}
    </CollapsiblePanelHeader>
  );
};

export default ContextMountedFilesListItemHeader;
