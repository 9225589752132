import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
  URL_SORT_DIRECTION,
  URL_SORT_KEY,
  URL_SEARCH_KEY,
} from "constants/url_query_keys";
import {
  ActiveFiltersMap,
  FilterItem,
  FilterItemOption,
  FilterItemSettings,
  FiltersDictionary,
  HierarchyFilterItemOption,
} from "components/Filters/types";
import {
  SearchQueryFieldConstraint,
  SearchQueryFieldConstraintTimeInLast,
  SearchQueryOrder,
  SearchQueryOrderDirection,
  SearchQueryPredicate,
  SearchSuggestionsField,
  SearchSuggestionsFieldType,
  SearchSuggestionsPossibleValuesHierarchy,
} from "types/generated";
import { decodeURIParam } from "utils/urls";

import { DEFAULT_VIEW_STORAGE_KEY } from "./constants";

const getFilterKey = (name: string, dictionary?: FiltersDictionary) => dictionary?.[name] || name;

export const getSessionStorageKeys = (filtersOrderSettingsKey: string) => {
  const storageUrlFilterKeysKey = `${filtersOrderSettingsKey}-${URL_FILTER_KEYS_KEY}`;
  const storageUrlFilterTypesKey = `${filtersOrderSettingsKey}-${URL_FILTER_TYPES_KEY}`;
  const storageUrlFilterValuesKey = `${filtersOrderSettingsKey}-${URL_FILTER_VALUES_KEY}`;
  const storageUrlSearchKey = `${filtersOrderSettingsKey}-${URL_SEARCH_KEY}`;
  const storageUrlSortDirection = `${filtersOrderSettingsKey}-${URL_SORT_DIRECTION}`;
  const storageUrlSortKey = `${filtersOrderSettingsKey}-${URL_SORT_KEY}`;
  return {
    storageUrlFilterKeysKey,
    storageUrlFilterTypesKey,
    storageUrlFilterValuesKey,
    storageUrlSearchKey,
    storageUrlSortDirection,
    storageUrlSortKey,
  };
};

const getFieldPossibleValuesPerType = (type: SearchSuggestionsFieldType) => {
  switch (type) {
    case SearchSuggestionsFieldType.Boolean:
      return "possibleValuesBoolean";
    case SearchSuggestionsFieldType.Enum:
      return "possibleValuesEnum";
    case SearchSuggestionsFieldType.String:
      return "possibleValuesString";
    case SearchSuggestionsFieldType.Hierarchy:
      return "possibleValuesHierarchy";
    case SearchSuggestionsFieldType.Time:
      return "possibleValuesString";
  }
};

export const removeFiltersFromStorage = (storage: Storage, filtersOrderSettingsKey: string) => {
  const { storageUrlFilterKeysKey, storageUrlFilterValuesKey, storageUrlFilterTypesKey } =
    getSessionStorageKeys(filtersOrderSettingsKey);

  storage.removeItem(storageUrlFilterKeysKey);
  storage.removeItem(storageUrlFilterTypesKey);
  storage.removeItem(storageUrlFilterValuesKey);
};

export const decodeFilter = (value: string | null) => {
  return decodeURIComponent(value || "")
    .split(",")
    .filter(Boolean);
};

const decodeFilterValues = (values: string | null) => {
  const condition = !!values && values !== "null";

  try {
    return (condition && (JSON.parse(decodeURIComponent(atob(values || ""))) as string[][])) || [];
  } catch {
    return [];
  }
};

const getFiltersMapFromURI = (
  urlParams: URLSearchParams,
  dictionary?: FiltersDictionary
): ActiveFiltersMap | undefined => {
  const filterNames = decodeFilter(urlParams.get(URL_FILTER_KEYS_KEY));
  const filterTypes = decodeFilter(urlParams.get(URL_FILTER_TYPES_KEY));
  const filterValues = decodeFilterValues(urlParams.get(URL_FILTER_VALUES_KEY));

  if (filterNames.length !== filterValues.length || filterNames.length === 0) {
    return undefined;
  }

  let labelsCounter = 0;
  return new Map(
    filterNames.map((filterName, index) => {
      let key: string = getFilterKey(filterName, dictionary);

      if (filterName === "label") {
        labelsCounter += 1;
        key = `label${labelsCounter}`;
      }

      return [
        key,
        {
          key,
          filterName,
          type: filterTypes[index] as SearchSuggestionsFieldType,
          values: filterValues[index],
        },
      ];
    })
  );
};

const getFiltersMapFromStorage = (
  storage: Storage,
  filtersOrderSettingsKey: string,
  dictionary?: FiltersDictionary
): ActiveFiltersMap | undefined => {
  const { storageUrlFilterKeysKey, storageUrlFilterValuesKey, storageUrlFilterTypesKey } =
    getSessionStorageKeys(filtersOrderSettingsKey);
  const filterNames = decodeFilter(storage.getItem(storageUrlFilterKeysKey));
  const filterTypes = decodeFilter(storage.getItem(storageUrlFilterTypesKey));
  const filterValues = decodeFilterValues(storage.getItem(storageUrlFilterValuesKey));

  if (filterNames?.length === filterValues.length && filterNames.length > 0) {
    let labelsCounter = 0;
    return new Map(
      filterNames.map((filterName, index) => {
        let key = getFilterKey(filterName, dictionary);

        if (filterName === "label") {
          labelsCounter += 1;
          key = `label${labelsCounter}`;
        }

        return [
          key,
          {
            key,
            filterName,
            type: filterTypes[index] as SearchSuggestionsFieldType,
            values: filterValues[index],
          },
        ];
      })
    );
  }

  return undefined;
};

export const getInitialFiltersMap = (
  urlParams: URLSearchParams,
  storage: Storage,
  filtersOrderSettingsKey: string,
  dictionary?: FiltersDictionary
): [ActiveFiltersMap, FilterItem[]] => {
  let initialFilters = getFiltersMapFromURI(urlParams, dictionary);

  if (!initialFilters) {
    initialFilters = getFiltersMapFromStorage(storage, filtersOrderSettingsKey, dictionary);
  }

  const initialGenericFilters = [...(initialFilters?.values() || [])].map<FilterItem>((filter) => ({
    key: dictionary?.[filter.key] || filter.key,
    filterName: filter.filterName,
    type: filter.type,
  }));

  return [initialFilters || (new Map() as ActiveFiltersMap), initialGenericFilters];
};

export const getLabelFilterName = (filterKey: string, isLastFilterLabel: boolean) => {
  if (filterKey === "label1" && isLastFilterLabel) {
    return "labels";
  }

  const lastKey = filterKey.replace("label", "");

  return `label group ${lastKey}`;
};

export const recalculateLabelsFilter = (
  activeFilters: ActiveFiltersMap
): [ActiveFiltersMap, FilterItem[]] => {
  let labelCounter = 0;
  const filters = [...activeFilters.values()].map((filter) => {
    if (filter.filterName === "label") {
      labelCounter += 1;
      return {
        ...filter,
        key: `label${labelCounter}`,
      };
    }

    return filter;
  });

  const genericFilters = filters.map<FilterItem>((filter) => ({
    key: filter.key,
    filterName: filter.filterName,
    type: filter.type,
  }));

  return [new Map(filters.map((filter) => [filter.key, filter])), genericFilters];
};

const makeFiltersOrder = (filters: FilterItem[]): FilterItemSettings[] => {
  return filters.map((filter) => ({
    name: filter.filterName,
    visible: true,
  }));
};

export const syncFiltersOrder = (
  filters: FilterItem[],
  currentFiltersOrder: FilterItemSettings[] | null
): FilterItemSettings[] => {
  const filtersOrderSource = makeFiltersOrder(filters);

  if (currentFiltersOrder) {
    const uniqueFiltersOrder = [
      ...new Map(
        // the first spread is important for keeping order
        // the second spread is important to getting new filters from backend
        // the third spread is important to set current order visibility
        [...currentFiltersOrder, ...filtersOrderSource, ...currentFiltersOrder].map((item) => [
          item.name,
          item,
        ])
      ).values(),
    ];

    return uniqueFiltersOrder;
  }

  return filtersOrderSource;
};

export const getSortOptionFromURI = (
  urlParams: URLSearchParams,
  initialSortOption: string,
  initialSortDirection: SearchQueryOrderDirection
): SearchQueryOrder[] => {
  const sortOption = decodeURIParam(urlParams.get(URL_SORT_KEY));
  const sortDirection = decodeURIParam(urlParams.get(URL_SORT_DIRECTION));

  if (sortDirection && sortOption) {
    return [
      {
        field: sortOption,
        direction: sortDirection as SearchQueryOrderDirection,
      },
    ];
  }

  return [
    {
      field: initialSortOption,
      direction: initialSortDirection,
    },
  ];
};

export const makeConstraintByType = (
  type: string | SearchSuggestionsFieldType,
  values: string[] | boolean[] | number[]
): SearchQueryFieldConstraint => {
  const isTimeInLast = values.length === 1;

  switch (type) {
    case SearchSuggestionsFieldType.String:
      return {
        booleanEquals: null,
        enumEquals: null,
        stringMatches: values as string[],
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      };

    case SearchSuggestionsFieldType.Enum:
      return {
        booleanEquals: null,
        enumEquals: values as string[],
        stringMatches: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      };

    case SearchSuggestionsFieldType.Boolean:
      return {
        booleanEquals: values as boolean[],
        enumEquals: null,
        stringMatches: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      };

    case SearchSuggestionsFieldType.Hierarchy:
      return {
        booleanEquals: null,
        enumEquals: null,
        stringMatches: null,
        hierarchyNodeValueEquals: values as string[],
        timeInRange: null,
        timeInLast: null,
      };

    case SearchSuggestionsFieldType.Time:
      return {
        booleanEquals: null,
        enumEquals: null,
        stringMatches: null,
        hierarchyNodeValueEquals: null,
        timeInRange: isTimeInLast
          ? null
          : {
              start: values[0] as number,
              end: values[1] as number,
            },
        timeInLast: isTimeInLast ? (values[0] as SearchQueryFieldConstraintTimeInLast) : null,
      };

    default:
      return {
        booleanEquals: null,
        enumEquals: null,
        stringMatches: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      };
  }
};

export const makeConstraintValuesByType = (
  type: string | SearchSuggestionsFieldType,
  values: string[]
) => {
  if (type === SearchSuggestionsFieldType.Boolean) {
    return values.map((value) => value === "true");
  }

  return values;
};

export const getFiltersPredicationFromURI = (urlParams: URLSearchParams, noNull = false) => {
  const filterKeys = decodeFilter(urlParams.get(URL_FILTER_KEYS_KEY));
  const filterTypes = decodeFilter(urlParams.get(URL_FILTER_TYPES_KEY));
  const filterValues = decodeFilterValues(urlParams.get(URL_FILTER_VALUES_KEY));

  if (!noNull && (filterKeys.length !== filterValues.length || filterKeys.length === 0)) {
    return null;
  }

  let labelsCounter = 0;
  return new Map(
    filterKeys.map((field, index): [string, SearchQueryPredicate] => {
      let key = field;

      if (field === "label") {
        labelsCounter += 1;
        key = `label${labelsCounter}`;
      }

      return [
        key,
        {
          field,
          exclude: null,
          constraint: makeConstraintByType(
            filterTypes[index],
            makeConstraintValuesByType(filterTypes[index], filterValues[index])
          ),
        },
      ];
    })
  );
};

export const setUpdatedFiltersUrlParams = (
  activeFilters: ActiveFiltersMap,
  urlParams: URLSearchParams,
  storage: Storage,
  filtersOrderSettingsKey: string
) => {
  const keys = [...activeFilters.values()].map((item) => item.filterName).join(",");
  const valueEntities = [...activeFilters.values()];
  const values = valueEntities.map((filter) => filter.values);
  const types = valueEntities.map((filter) => filter.type).join(",");
  const { storageUrlFilterKeysKey, storageUrlFilterValuesKey, storageUrlFilterTypesKey } =
    getSessionStorageKeys(filtersOrderSettingsKey);

  if (keys.length > 0 && values.length > 0) {
    urlParams.set(URL_FILTER_KEYS_KEY, keys);
    storage.setItem(storageUrlFilterKeysKey, keys);

    urlParams.set(URL_FILTER_TYPES_KEY, types);
    storage.setItem(storageUrlFilterTypesKey, types);

    urlParams.set(URL_FILTER_VALUES_KEY, btoa(encodeURIComponent(JSON.stringify(values))));
    storage.setItem(storageUrlFilterValuesKey, btoa(encodeURIComponent(JSON.stringify(values))));
  } else {
    urlParams.delete(URL_FILTER_KEYS_KEY);
    urlParams.delete(URL_FILTER_TYPES_KEY);
    urlParams.delete(URL_FILTER_VALUES_KEY);

    removeFiltersFromStorage(storage, filtersOrderSettingsKey);
  }

  return urlParams;
};

export const createStorageKey = (id: string, base: string) => `${id}${base}`;

export const getDefaultViewStorageKey = (filtersType: string) =>
  `${DEFAULT_VIEW_STORAGE_KEY}-${filtersType}`;

type MakeFilterItemFromSuggestionFieldProps = {
  field: SearchSuggestionsField;
  dictionary?: FiltersDictionary;
  makeCustomKey?: (key: string) => string;
};

export const makeFilterItemFromSuggestionField = ({
  field,
  dictionary,
  makeCustomKey,
}: MakeFilterItemFromSuggestionFieldProps): FilterItem => {
  return {
    key: makeCustomKey ? makeCustomKey(field.name) : getFilterKey(field.name, dictionary),
    filterName: field.name,
    //SearchSuggestionsFieldType is only available if the field is filterable
    type: field.type as SearchSuggestionsFieldType,
  };
};

export const makeFilterItemOptionsFromSuggestionField = (
  field: SearchSuggestionsField
): HierarchyFilterItemOption[] | FilterItemOption[] | undefined => {
  if (field.type) {
    const propertyTypeValues = field[getFieldPossibleValuesPerType(field.type)];
    let options: HierarchyFilterItemOption[] | FilterItemOption[] = [];

    if (field.type === SearchSuggestionsFieldType.Hierarchy && propertyTypeValues) {
      options = (
        propertyTypeValues as SearchSuggestionsPossibleValuesHierarchy
      ).values.map<HierarchyFilterItemOption>((item, i) => {
        return {
          id: item.id,
          parentId: item.parentId,
          value: item.displayValue,
          count: propertyTypeValues.counts[i],
          type: field.type as SearchSuggestionsFieldType,
        };
      });
    } else if (propertyTypeValues) {
      options = propertyTypeValues.values.map<FilterItemOption>((value, i) => ({
        value: value.toString(),
        count: propertyTypeValues.counts[i],
        type: field.type as SearchSuggestionsFieldType,
      }));
    }

    return options;
  }

  return undefined;
};
