import Link from "ds/components/LinkNew";
import useAnalytics from "hooks/useAnalytics";
import { RunIgnoredTrigger, VcsProvider } from "types/generated";
import { getCommmitDigitsCount } from "utils/commit";

type StackRunsIgnoredRunCalloutCommitLinkProps = {
  ignoredRun: RunIgnoredTrigger;
  vcsProvider: VcsProvider;
};
const StackRunsIgnoredRunCalloutCommitLink = ({
  ignoredRun,
  vcsProvider,
}: StackRunsIgnoredRunCalloutCommitLinkProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handlePress = () => {
    trackSegmentAnalyticsEvent("Ignored run warning commit link clicked");
  };

  return (
    <Link
      variant="secondary"
      size="inherit"
      onPress={handlePress}
      href={ignoredRun.commit.url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {ignoredRun.commit.hash.slice(0, getCommmitDigitsCount(vcsProvider))}
    </Link>
  );
};

export default StackRunsIgnoredRunCalloutCommitLink;
