import { gql } from "@apollo/client";

import { accountDataFragment } from "components/form/graphql";

export const GET_ACCOUNT_DATA = gql`
  query GetAccount {
    ...accountDataFragment
  }
  ${accountDataFragment}
`;
