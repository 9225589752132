import { ReactNode } from "react";

import HistoryEntryNote from "../../components/HistoryEntryNote";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";
import styles from "./styles.module.css";
import PrioritizeRunButton from "../../components/PrioritizeRunButton";

type WorkerPoolEntryWrapperProps = {
  workers: number;
  busyWorkers: number;
  pendingRuns: number;
  label: ReactNode;
  runId: string;
  stackId: string;
  canPrioritize: boolean;
  isRunPrioritized: boolean;
  runQueryToRefetch: string;
};

export const WorkerPoolEntryWrapper = ({
  label,
  workers,
  pendingRuns,
  busyWorkers,
  canPrioritize,
  isRunPrioritized,
  runId,
  stackId,
  runQueryToRefetch,
}: WorkerPoolEntryWrapperProps) => {
  return (
    <HistoryEntryWrapper state={RunHistoryEntryType.WorkerPool} label={label}>
      <HistoryEntryNote>
        <dl className={styles.list}>
          <dt>Workers</dt>
          <dd>{workers}</dd>
          <dt>Busy workers</dt>
          <dd>{busyWorkers}</dd>
          <dt>Pending runs</dt>
          <dd>{pendingRuns}</dd>
        </dl>
      </HistoryEntryNote>
      {canPrioritize && (
        <PrioritizeRunButton
          runId={runId}
          stackId={stackId}
          isRunPrioritized={isRunPrioritized}
          runQueryToRefetch={runQueryToRefetch}
        />
      )}
    </HistoryEntryWrapper>
  );
};
