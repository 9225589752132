import { gql, TypedDocumentNode } from "@apollo/client";

type FetchHasAnsibleStacks = {
  hasAnsibleStacks: boolean;
};

export const GET_HAS_ANSIBLE_STACKS: TypedDocumentNode<FetchHasAnsibleStacks> = gql`
  query GetHasAnsibleStacks {
    hasAnsibleStacks
  }
`;
