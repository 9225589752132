import { FocusScope } from "react-aria";
import cx from "classnames";
import { useCallback } from "react";

import ThemedLogs from "components/ThemedLogs";
import { prettify } from "views/shared/run/ResourcesDiff/helpers";
import ThemedLogsContent from "components/ThemedLogs/Content";
import FullScreenModal from "ds/components/FullScreenModal";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import DownloadFileButton from "components/DownloadFileButton";
import Typography from "ds/components/Typography";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { ConfigNode } from "../../types";
import styles from "./styles.module.css";

type ConfigManagementTreeGridDrawerLogsFullScreenProps = {
  task: ConfigNode;
  fullScreen: boolean;
  toggleFullScreen: (value?: boolean) => void;
  fileName: string;
  analyticsPage?: AnalyticsPage;
};

const ConfigManagementTreeGridDrawerLogsFullScreen = ({
  task,
  fullScreen,
  toggleFullScreen,
  fileName,
  analyticsPage,
}: ConfigManagementTreeGridDrawerLogsFullScreenProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const downloadCallback = useCallback(() => {
    trackSegmentAnalyticsEvent("Logs downloaded");
  }, [trackSegmentAnalyticsEvent]);

  return (
    <FocusScope contain={fullScreen}>
      {fullScreen && (
        <FullScreenModal title="Task details log" onClose={() => toggleFullScreen(false)}>
          {task.logs ? (
            <>
              <ThemedLogs className={cx(styles.fullScreen, styles.withFooter)}>
                <ThemedLogsContent>{prettify(task.logs)}</ThemedLogsContent>
              </ThemedLogs>

              <FullScreenModalFooter justify="between" className={styles.footer}>
                {/* TODO: [Asnible v2] add when documentation created */}
                {/* <DocumentationIconButton
                  href={getDocsUrl("/concepts/policy#policy-workbench")}
                  tooltipText="Go to policy workbench documentation"
                  /> */}
                <DownloadFileButton
                  size="medium"
                  value={task.logs}
                  title="Download logs"
                  fileName={fileName}
                  onClickCapture={downloadCallback}
                />
              </FullScreenModalFooter>
            </>
          ) : (
            <Typography margin="large" variant="p-body2" tag="span">
              No log available
            </Typography>
          )}
        </FullScreenModal>
      )}
    </FocusScope>
  );
};

export default ConfigManagementTreeGridDrawerLogsFullScreen;
