import useTypedContext from "hooks/useTypedContext";

import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsVendorKubernetesEdit from "./Edit";
import StackSettingsVendorKubernetesView from "./View";
import { StackSettingsContextData } from "../../Context";

const StackSettingsVendorKubernetes = (props: StackSettingsVendorComponentBaseProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return canManageStack ? (
    <StackSettingsVendorKubernetesEdit {...props} />
  ) : (
    <StackSettingsVendorKubernetesView />
  );
};

export default StackSettingsVendorKubernetes;
