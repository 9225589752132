import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const COLUMN_GAP: Spacing = "large";
export const COLUMN_ORDER = "8fr 2fr 6.7rem";

export const FILTERS_TYPE = "policiesTemplates";
export const FILTERS_ORDER_SETTINGS_KEY = "policiesTemplatesFiltersOrder";

export enum FilterItemKeys {
  NAME = "name",
  TYPE = "type",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.NAME]: "name",
  [FilterItemKeys.TYPE]: "type",
};

export const INITIAL_SORT_OPTION = FilterItemKeys.NAME;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Asc;
export const ENTITIES_PER_PAGE = 50;
