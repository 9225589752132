import { NoResultsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";

const WorkerPoolQueuedRunsNoResults = () => {
  return (
    <EmptyState
      icon={NoResultsColored}
      title="No results"
      caption="Don’t give up! Check the spelling or try something less specific."
    />
  );
};

export default WorkerPoolQueuedRunsNoResults;
