import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Module } from "types/generated";

import ModuleListItem, { ModuleListItemProps } from ".";

type ModuleVirtualizedListItemProps = {
  items: Module[];
  onCheckItem: ModuleListItemProps["onCheckItem"];
  onShowFullDescription: ModuleListItemProps["onShowFullDescription"];
  selectedSet: Set<string>;
};

const ModuleVirtualizedListItem = (
  props: ListChildComponentProps<ModuleVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <ModuleListItem
        key={data.items[index].id}
        module={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onCheckItem={data.onCheckItem}
        onShowFullDescription={data.onShowFullDescription}
        checked={data.selectedSet.has(data.items[index].id)}
      />
    </div>
  );
};

export default memo(ModuleVirtualizedListItem, areEqual);
