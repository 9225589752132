import { gql } from "@apollo/client";

export const GET_STACK_WORKERPOOLS = gql`
  query GetStackWorkerpools($id: ID!) {
    attachableWorkerPoolsInSpace(id: $id) {
      id
      name
    }
  }
`;
