import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

type SessionsDeactivateAllConfirmationProps = {
  onConfirm: () => void;
};

const SessionsDeactivateAllConfirmation = create(function SessionsDeactivateAllConfirmation({
  onConfirm,
}: SessionsDeactivateAllConfirmationProps) {
  const modal = useModal();

  const handleConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <ConfirmationModal onConfirm={handleConfirm} confirmationButtonLabel="Deactivate">
      <ConfirmationModalMessage>
        Are you sure you want to deactivate <strong>all sessions (except your own)</strong>?
      </ConfirmationModalMessage>
    </ConfirmationModal>
  );
});

export const showSessionsDeactivateAllConfirmation = (
  props: SessionsDeactivateAllConfirmationProps
) => show(SessionsDeactivateAllConfirmation, props);
