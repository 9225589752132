import ListEntitiesNew from "components/ListEntitiesNew";
import { Space } from "types/generated";
import Box from "ds/components/Box";

import SpacesListVirtualizedItem from "./VirtualizedItem";
import styles from "./styles.module.css";

type PersonalSettingsSpacesListProps = {
  spaces: Space[];
  handleRequestRoleChange: (space: Space) => unknown;
};

const SpacesList = ({ spaces, handleRequestRoleChange }: PersonalSettingsSpacesListProps) => {
  return (
    <Box direction="column" grow="1">
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={spaces.length}
          itemProps={{
            spaces,
            handleRequestRoleChange,
          }}
          virtualizedItem={SpacesListVirtualizedItem}
          itemKey={(index) => spaces[index].id}
        />
      </div>
    </Box>
  );
};

export default SpacesList;
