import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationStackSyncCommitArgs, Stack } from "types/generated";

export type FetchSyncCommitData = {
  stackSyncCommit: Pick<Stack, "id" | "trackedCommit" | "trackedBranchHead">;
};
export const STACK_SYNC_COMMIT: TypedDocumentNode<
  FetchSyncCommitData,
  MutationStackSyncCommitArgs
> = gql`
  mutation StackSyncCommit($id: ID!) {
    stackSyncCommit(id: $id) {
      id
      trackedCommit {
        hash
      }
      trackedBranchHead {
        hash
      }
    }
  }
`;
