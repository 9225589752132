import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { SecurityKey } from "types/generated";

import SecurityKeysListItem from "./Item";
import { SecurityKeyView } from "./types";

type SecurityKeysListVirtualizedItemProps = {
  securityKeys: SecurityKeyView[];
  onDelete: (item: SecurityKey) => void;
  showOwner?: boolean;
};

const SecurityKeysListVirtualizedItem = (
  props: ListChildComponentProps<SecurityKeysListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.securityKeys[index];
  const key = item.id;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  const handleDelete = () => {
    data.onDelete(item);
  };

  return (
    <div style={style}>
      <SecurityKeysListItem
        key={key}
        item={item}
        onDelete={item.canDelete ? handleDelete : undefined}
        setRowHeight={handleSetRowHeight}
        showOwner={data.showOwner}
      />
    </div>
  );
};

export default memo(SecurityKeysListVirtualizedItem, areEqual);
