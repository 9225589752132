import { memo, useEffect } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Select from "ds/components/Select";
import { spaceAccessLevelOptions } from "constants/space";
import { Space, SpaceAccessLevel } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import { getDefaultValues } from "./helpers";
import { ChangeRoleFields } from "./types";
import useUserRoleChange from "./useUserRoleChange";

type SpacesRoleChangeDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  space?: Space;
};

const SpacesRoleChangeDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
  space,
}: SpacesRoleChangeDrawerProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const trackAnalyticsSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalSpaces,
  });
  const roleChangeForm = useForm<ChangeRoleFields>({
    defaultValues: getDefaultValues(space),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = roleChangeForm;

  const handleCloseDrawer = (method?: string) => () => {
    if (method) {
      trackAnalyticsSegmentEvent("Role Change Exit", { method });
    }
    setDrawerVisibility(false);
    reset();
  };

  const { onUserRoleChange } = useUserRoleChange();

  const onSubmit: SubmitHandler<ChangeRoleFields> = (formData) => {
    onUserRoleChange(formData)
      .then(() => {
        handleCloseDrawer()();
        trackAnalyticsSegmentEvent("Role Change Send Request Click", {
          role: formData.spaceAccessLevel,
        });
        reportSuccess({ message: "Role request has been sent" });
      })
      .catch(onError);
  };

  const spaceAccessLevelOnChangeHandler =
    (callback: (value: SpaceAccessLevel) => void) => (value: SpaceAccessLevel) => {
      callback(value);
      trackAnalyticsSegmentEvent("Role Change Dropdown Select", {
        role: value,
      });
    };

  useEffect(() => {
    reset(getDefaultValues(space));
  }, [space, reset]);

  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer}>
      <FormProvider {...roleChangeForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Request role change" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <Controller
            name="spaceAccessLevel"
            control={control}
            render={({ field, fieldState }) => (
              <FormField error={fieldState.error?.message} noMargin label="Role">
                {({ ariaInputProps }) => (
                  <Select
                    value={field.value}
                    options={spaceAccessLevelOptions}
                    onChange={spaceAccessLevelOnChangeHandler(field.onChange)}
                    error={!!fieldState.error?.message}
                    ariaInputProps={ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer("cancel")}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || !isDirty}
              >
                Send request
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(SpacesRoleChangeDrawer);
