import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationStackUnlockArgs, Stack } from "types/generated";

export type FetchStackUnlockData = { stackUnlock: Pick<Stack, "id"> };
export const STACK_UNLOCK: TypedDocumentNode<FetchStackUnlockData, MutationStackUnlockArgs> = gql`
  mutation StackUnlock($id: ID!) {
    stackUnlock(id: $id) {
      id
    }
  }
`;
