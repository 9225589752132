import { useState } from "react";
import { useMutation, gql } from "@apollo/client";

import ConfigElement from "components/config/view/ConfigElement";
import FlashContext from "components/FlashMessages/FlashContext";
import EditConfigElement from "components/config/edit/EditConfigElement";
import useTypedContext from "hooks/useTypedContext";

import ConfigEditActions from "./Actions";
import { ENVIRONMENT_QUERY } from "../constants";
import {
  EnvironmentConfigElementWrapperProps,
  UpdateStackConfigGql,
  UpdateStackConfigGqlVariables,
} from "./types";

const UPDATE_STACK_CONFIG = gql`
  mutation UpdateStackConfig($stackId: ID!, $input: ConfigInput!) {
    stackConfigAdd(stack: $stackId, config: $input) {
      id
      type
    }
  }
`;

const EnvironmentConfigElementWrapper = ({
  context,
  element,
  stackId,
  type,
}: EnvironmentConfigElementWrapperProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [editing, setEditing] = useState(false);
  const toggleEditing = () => setEditing(!editing);

  const [updateConfig, { loading: updating }] = useMutation<
    UpdateStackConfigGql,
    UpdateStackConfigGqlVariables
  >(UPDATE_STACK_CONFIG, {
    refetchQueries: [ENVIRONMENT_QUERY[type]],
    variables: { stackId },
  });

  const immutables = {
    id: element.id,
    type: element.type,
    writeOnly: element.writeOnly,
  };

  const onUpdate = (value?: string) => {
    updateConfig({ variables: { input: { ...immutables, value: value || "" } } })
      .then(({ data }) => {
        setEditing(false);
        reportSuccess({ message: `Config ${data?.stackConfigAdd.id} successfully updated` });
      })
      .catch(onError);
  };

  return editing ? (
    <EditConfigElement
      loading={updating}
      onCancel={toggleEditing}
      onSave={onUpdate}
      element={element}
    />
  ) : (
    <ConfigElement {...element}>
      <ConfigEditActions
        type={type}
        stackId={stackId}
        id={element.id}
        onEdit={toggleEditing}
        override={context !== null || element.runtime}
      />
    </ConfigElement>
  );
};

EnvironmentConfigElementWrapper.defaultProps = { context: null };

export default EnvironmentConfigElementWrapper;
