import { memo } from "react";

import Drawer from "ds/components/Drawer";
import { type AnalyticsPage } from "hooks/useAnalytics";

import ApiKeysInfoDrawerContent from "./Content";

type ApiKeysInfoDrawerProps = {
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
  analyticsPage: AnalyticsPage;
};

const ApiKeysInfoDrawer = ({
  isDrawerVisible,
  handleCloseDrawer,
  analyticsPage,
}: ApiKeysInfoDrawerProps) => {
  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer}>
      <ApiKeysInfoDrawerContent
        analyticsPage={analyticsPage}
        handleCloseDrawer={handleCloseDrawer}
      />
    </Drawer>
  );
};

export default memo(ApiKeysInfoDrawer);
