import Tab, { TabProps } from ".";
import { BadgeProps } from "../Badge/types";
import Badge from "../Badge";

type TabWithBadgeProps<T extends string> = TabProps<T> & {
  badgeValue: string;
  badgeState?: BadgeProps["state"];
};

const TabWithBadge = <T extends string>({
  badgeValue,
  badgeState,
  ...restTabProps
}: TabWithBadgeProps<T>) => {
  return (
    <Tab
      {...restTabProps}
      suffixComponent={
        <Badge size="small" state={badgeState || "neutral"}>
          {badgeValue}
        </Badge>
      }
    />
  );
};

export default TabWithBadge;
