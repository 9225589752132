import { memo, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import TableCell from "components/Table/Cell";
import TableDropdownCell from "components/Table/DropdownCell";
import { TableContext } from "components/Table/Context";

import { STACKS_COLUMN_ID } from "../constants";
import styles from "./styles.module.css";
import StackListItemFavoriteCell from "./FavoriteCell";
import StackListItemStateCell from "./StateCell";
import StackListItemDescriptionCell from "./DescriptionCell";
import StackListItemSpaceCell from "./SpaceCell";
import StackListItemBlueprintCell from "./BlueprintCell";
import StackListItemVendorCell from "./VendorCell";
import StackListItemDeltaCell from "./DeltaCell";
import StackListItemRepositoryCell from "./RepositoryCell";
import StackListItemProjectRootCell from "./ProjectRootCell";
import StackListItemBranchCell from "./BranchCell";
import StackListItemUpdatedAtCell from "./UpdatedAtCell";
import StackListItemLabelsCell from "./LabelsCell";
import StackListItemActionsDropdownCell from "./DropdownActionsCell";
import StackListItemNameCell from "./NameCell";
import StackListItemCommitCell from "./CommitCell";
import StackListItemIssuesCell from "./IssuesCell";
import useCellActionsConfig from "./useCellActionsConfig";

const CELLS = {
  [STACKS_COLUMN_ID.FAVORITE]: StackListItemFavoriteCell,
  [STACKS_COLUMN_ID.STATE]: StackListItemStateCell,
  [STACKS_COLUMN_ID.DESCRIPTION]: StackListItemDescriptionCell,
  [STACKS_COLUMN_ID.NAME]: StackListItemNameCell,
  [STACKS_COLUMN_ID.SPACE]: StackListItemSpaceCell,
  [STACKS_COLUMN_ID.BLUEPRINT]: StackListItemBlueprintCell,
  [STACKS_COLUMN_ID.VENDOR]: StackListItemVendorCell,
  [STACKS_COLUMN_ID.DELTA_COUNTS]: StackListItemDeltaCell,
  [STACKS_COLUMN_ID.REPOSITORY]: StackListItemRepositoryCell,
  [STACKS_COLUMN_ID.PROJECT_ROOT]: StackListItemProjectRootCell,
  [STACKS_COLUMN_ID.BRANCH]: StackListItemBranchCell,
  [STACKS_COLUMN_ID.COMMIT]: StackListItemCommitCell,
  [STACKS_COLUMN_ID.UPDATED_AT]: StackListItemUpdatedAtCell,
  [STACKS_COLUMN_ID.LABELS]: StackListItemLabelsCell,
  [STACKS_COLUMN_ID.DROPDOWN]: StackListItemActionsDropdownCell,
  [STACKS_COLUMN_ID.ISSUES]: StackListItemIssuesCell,
};

type StackListItemProps = {
  stack: Stack;
  onShowFullDescription: (stack: Stack) => unknown;
};

const StackListItem = (props: StackListItemProps) => {
  const { stack, onShowFullDescription } = props;

  const { visibleColumnIds } = useTypedContext(TableContext);

  const [isTriggerDropdownVisible, setTriggerDropdownVisibility] = useState(false);

  const cellActionsConfig = useCellActionsConfig(stack);

  if (!stack.spaceDetails) {
    return null;
  }

  return (
    <>
      {visibleColumnIds.map((id) => {
        const Component = CELLS[id as STACKS_COLUMN_ID];
        const dropdownActions = cellActionsConfig[id as STACKS_COLUMN_ID];

        const childrenComponent = (
          <Component
            stack={stack}
            onShowFullDescription={onShowFullDescription}
            setTriggerDropdownVisibility={setTriggerDropdownVisibility}
          />
        );

        const defaultProps = {
          id,
          className: isTriggerDropdownVisible ? styles.activeCell : undefined,
        };

        return dropdownActions ? (
          <TableDropdownCell {...defaultProps} key={id} dropdownActions={dropdownActions}>
            {childrenComponent}
          </TableDropdownCell>
        ) : (
          <TableCell {...defaultProps} key={id}>
            {childrenComponent}
          </TableCell>
        );
      })}
    </>
  );
};

export default memo(StackListItem);
