import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import { AzureDevOpsRepoIntegration } from "types/generated";
import useTypedFlags from "hooks/useTypedFlags";

import { VCSCheckLabels } from "./helpers";
type VCSIntegrationsDetailsAzureDevOpsProps = {
  details: AzureDevOpsRepoIntegration;
};

const VCSIntegrationsDetailsAzureDevOps = ({ details }: VCSIntegrationsDetailsAzureDevOpsProps) => {
  const { aggregatedVcsChecks } = useTypedFlags();
  return (
    <>
      <FormFieldViewText label="Organization URL" value={details.organizationURL} />

      <FormFieldViewText label="User facing host URL" value={details.userFacingHost} />

      <FormField label="Webhook secret">
        <SecretFieldDetails secret={details.webhookPassword} />
      </FormField>

      <FormFieldViewText
        label="Webhook endpoint"
        value={details.webhookUrl}
        withCopy
        noTextEllipsis
        textWordBreak
      />

      {aggregatedVcsChecks && (
        <FormFieldViewText label="VCS checks" value={VCSCheckLabels[details.vcsChecks]} />
      )}
    </>
  );
};

export default VCSIntegrationsDetailsAzureDevOps;
