import { Pages } from "./types";

export const enum AnalyticsPageBilling {
  BillingDashboard = "BillingDashboard",
  BillingSubscription = "BillingSubscription",
  BillingUpgrade = "BillingUpgrade",
}

const billing = "Billing";

export const pagesBilling: Pages<AnalyticsPageBilling> = {
  [AnalyticsPageBilling.BillingDashboard]: [billing, "Dashboard"],
  [AnalyticsPageBilling.BillingSubscription]: [billing, "Subscription"],
  [AnalyticsPageBilling.BillingUpgrade]: [billing, "Upgrade"],
};
