import { gql } from "@apollo/client";

export const GET_STACK_RUNTIME_CONFIG = gql`
  query GetStackRuntimeConfig($stackId: ID!) {
    stack(id: $stackId) {
      runtimeConfig {
        context {
          contextId
          contextName
        }
        element {
          createdAt
          id
          runtime
          type
          value
          writeOnly
        }
      }
    }
  }
`;
