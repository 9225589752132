import { createContext } from "react";

import { ColumnMode } from "components/DragDropList";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import { DashboardColumns, DashboardConfig, DashboardOverviewStaticColumn } from "./types";

type DashboardContextProps = {
  updateManagementDrawerTabOverviewColumnConfig: (column: DashboardOverviewStaticColumn) => void;
  updateTabOverviewColumnConfig: (column: DashboardOverviewStaticColumn) => void;
  updateManagementDrawerTabColumnModeConfig: (tab: string, columns: ColumnMode) => void;
  updateTabColumnsConfig: (tab: string, columns: DashboardColumns) => void;
  updateManagementDrawerTabColumnsConfig: (tab: string, columns: DashboardColumns) => void;
  managementDrawerConfig: DashboardConfig;
  config: DashboardConfig;
  saveManagementDrawerChangesToStorage: () => void;
  isManagementDrawerConfigModified: boolean;
  isManageDrawerVisible: boolean;
  closeManageDrawer: (shouldReset?: boolean) => void;
  openManageDrawer: (tab?: string, analyticsProps?: AnalyticsCommonProps["analyticsProps"]) => void;
  managementDrawerTabs: { id: string; title: string }[];
  tabs: { id: string; title: string }[];
  addNewTab: () => void;
  addNewTabInDrawer: () => void;
  removeTab: (id: string) => void;
  removeTabInDrawer: (id: string) => void;
  changeTabName: (id: string, value: string) => void;
  setCurrentManageDrawerTab: (tab: string) => void;
  currentManageDrawerTab: string;
  canAddMoreViews: boolean;
  canAddMoreManagementDrawerViews: boolean;
  shouldFocusOnInput: boolean;
  configLoading: boolean;
};

export const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);
DashboardContext.displayName = "DashboardContext";
