import { useState } from "react";
import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import ContextConfigFormFileUploadField from "components/ContextConfigForm/FileUploadField";
import ContextConfigFormContentField from "components/ContextConfigForm/ContentField";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import Tab from "ds/components/Tab";
import TabPanel from "ds/components/TabPanel";
import Button from "ds/components/Button";

import { FileMountDrawerTabs, ContextMountedFileFormFields } from "./types";
import styles from "./styles.module.css";

type ContextMountedFilesDraftFormProps = {
  onCancel?: () => void;
};

const ContextMountedFilesDraftForm = ({ onCancel }: ContextMountedFilesDraftFormProps) => {
  const [currentTab, setCurrentTab] = useState(FileMountDrawerTabs.Upload);

  const builderForm = useFormContext<ContextMountedFileFormFields>();

  const {
    watch,
    formState: { isValid, isSubmitting },
  } = builderForm;

  const isUploadTab = currentTab === FileMountDrawerTabs.Upload;
  const isManuallyAddTab = currentTab === FileMountDrawerTabs.AddManually;

  const content = watch("fileBody");
  return (
    <Box className={styles.fileWrapper} direction="column">
      <Box justify="between" fullWidth padding="large" align="center" direction="column">
        <Box justify="between" fullWidth align="center">
          <CollapsiblePanelTitle>File</CollapsiblePanelTitle>
          <Box gap="medium">
            <Button variant="secondary" size="small" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              size="small"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box direction="row" gap="medium" fullWidth padding="large 0 0 0">
          <Tab
            onClick={setCurrentTab}
            isActive={isUploadTab}
            id={FileMountDrawerTabs.Upload}
            label="Upload"
          />
          <Tab
            onClick={setCurrentTab}
            isActive={isManuallyAddTab}
            id={FileMountDrawerTabs.AddManually}
            label="Add manually"
          />
        </Box>
      </Box>
      <Box
        padding={isUploadTab ? "large" : "0"}
        className={styles.file}
        align="stretch"
        justify="stretch"
        direction="column"
      >
        <TabPanel isActive={isUploadTab} id={FileMountDrawerTabs.Upload}>
          <ContextConfigFormFileUploadField
            name="file"
            required={!content}
            className={styles.fileField}
          />
        </TabPanel>
        <TabPanel isActive={isManuallyAddTab} id={FileMountDrawerTabs.AddManually}>
          <ContextConfigFormContentField name="fileBody" className={styles.variablePreviewEditor} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ContextMountedFilesDraftForm;
