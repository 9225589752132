import ButtonIcon from "ds/components/ButtonIcon";
import { CrossNew } from "components/icons";

type BulkActionsDrawerItemDismissProps = {
  onDismiss: () => void;
  tooltip?: string;
};

const BulkActionsDrawerItemDismiss = ({
  onDismiss,
  tooltip = "Unselect",
}: BulkActionsDrawerItemDismissProps) => {
  return (
    <ButtonIcon icon={CrossNew} onClick={onDismiss} variant="ghostDanger">
      {tooltip}
    </ButtonIcon>
  );
};

export default BulkActionsDrawerItemDismiss;
