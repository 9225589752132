import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import { getDocsUrl } from "utils/getDocsUrl";

const Documentation = () => (
  <>
    <Typography tag="p" variant="p-t7" margin="0 0 small 0">
      Source code integration
    </Typography>
    <Typography tag="p" variant="p-body2">
      In this step, you will need to tell Spacelift where to look for the Terraform code for the
      stack - a combination of Git repository and one of its existing branches.
      <br />
      <br />
      The branch that you specify set here is what we called a tracked branch. By default, anything
      that you push to this branch will be considered for deployment. Anything you push to a
      different branch will be tested for changes against the current state.
      <br />
      <br />
      The project root configuration is where inside the repository Spacelift should look for the
      infra project source code (e.g. create a stack for a specific folder in the repository).
      <br />
      <br />A few things worth noting:
      <ul>
        <li>
          you can point multiple Spacelift stacks to the same repository, even the same branch;
        </li>
        <li>
          the default behavior can be tweaked extensively to work with all sorts of Git and
          deployment workflows (yes, we like monorepos, too) using{" "}
          <TextLink href={getDocsUrl("/concepts/policy/push-policy")}>push</TextLink> and{" "}
          <TextLink href={getDocsUrl("/concepts/policy/trigger-policy")}>trigger</TextLink>{" "}
          policies, which are more advanced topics;
        </li>
        <li>
          in order to learn what exactly our Git hosting provider integration means, please refer to{" "}
          <TextLink href={getDocsUrl("/integrations/source-control/github")}>GitHub</TextLink> and{" "}
          <TextLink href={getDocsUrl("/integrations/source-control/gitlab")}>GitLab</TextLink>{" "}
          integration documentation;
        </li>
      </ul>
    </Typography>
  </>
);

export default Documentation;
