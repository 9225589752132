import { useCallback, useEffect, useMemo, useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import { CLOUD_INTEGRATIONS, CloudIntegrationAttachment } from "shared/CloudIntegration/types";
import useAttachableCloudIntegrations from "shared/CloudIntegration/useAttachableCloudIntegrations";
import { isSelfHostedDistribution } from "utils/distribution";
import Button from "ds/components/Button";
import Feedback from "ds/components/Feedback";
import Box from "ds/components/Box";
import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { CloudColored } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import WarningContextProvider from "components/WarningContext/Provider";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";

import { StackSettingsContextData } from "../../Context";
import StackSettingsIntegrationsWrapper from "../components/Wrapper";
import StackSettingsFormWrapper from "../../components/FormWrapper";
import {
  REFETCH_STACK_AND_STACK_SETTINGS_QUERIES,
  REFETCH_STACK_SETTINGS_QUERIES,
} from "../../constants";
import StackSettingsIntegrationsCloudAttachForm from "./AttachForm";
import StackSettingsIntegrationsCloudAttachedItem from "./AttachedItem";

const isSelfHosted = isSelfHostedDistribution();

const StackSettingsIntegrationsCloud = () => {
  const { stackSettings, canManageStack } = useTypedContext(StackSettingsContextData);
  const [cloud, setCloud] = useState<CLOUD_INTEGRATIONS | undefined>();
  const [isCloudIntegrationDrawerOpen, setIsCloudIntegrationDrawerOpen] = useState(false);

  const {
    cloudConfig,
    attachableAzureIntegrations,
    attachableAwsIntegrations,
    hasData,
    refetch,
    refetching,
    loading,
  } = useAttachableCloudIntegrations(stackSettings.spaceDetails.id);

  const integrations = useMemo(() => {
    const awsIntegrations = stackSettings?.integrations?.awsV2 || [];
    const azureIntegrations = stackSettings?.integrations?.azure || [];

    return ([] as Array<CloudIntegrationAttachment>)
      .concat(awsIntegrations)
      .concat(azureIntegrations);
  }, [stackSettings?.integrations]);

  const gcpIntegration = stackSettings?.integrations?.gcp;

  const attachedIds = useMemo(
    () => (integrations || []).map((integration) => integration.integrationId),
    [integrations]
  );

  const filteredAwsIntegrations = useMemo(
    () => attachableAwsIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAwsIntegrations, attachedIds]
  );

  const filteredAzureIntegrations = useMemo(
    () =>
      attachableAzureIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAzureIntegrations, attachedIds]
  );

  const handleCloseCloudIntegrationDrawer = useCallback(() => {
    setIsCloudIntegrationDrawerOpen(false);
    setCloud(undefined);
  }, []);

  const hasSomeIntegrationsAttached = integrations.length > 0 || gcpIntegration?.activated;
  const hasAttachableCloudIntegrations = hasData && cloudConfig.length > 0;

  const canAttachGcpIntegration = !gcpIntegration?.activated && !isSelfHosted;

  const canAttach =
    (filteredAwsIntegrations.length > 0 ||
      filteredAzureIntegrations.length > 0 ||
      canAttachGcpIntegration) &&
    canManageStack;

  const filteredCloudConfig = useMemo(() => {
    if (!canAttachGcpIntegration) {
      return cloudConfig.filter(({ type }) => type !== CLOUD_INTEGRATIONS.GCP);
    }

    return cloudConfig;
  }, [canAttachGcpIntegration, cloudConfig]);

  useEffect(() => {
    // make the first integration selected by default
    if (!loading && !cloud && filteredCloudConfig[0]) {
      setCloud(filteredCloudConfig[0].type);
    }
  }, [cloud, filteredCloudConfig, loading]);

  return (
    <StackSettingsIntegrationsWrapper
      actions={
        <Button
          variant="primary"
          disabled={!canAttach}
          onClick={() => setIsCloudIntegrationDrawerOpen(true)}
        >
          Attach cloud integration
        </Button>
      }
    >
      <Feedback type="callout" variant="info">
        You can only attach integrations from the current space and parent spaces that you inherit
        from.
      </Feedback>
      <StackSettingsFormWrapper withLimitWidth={false} padding="0">
        {hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={CloudColored}
                title="You do not have any attached cloud integrations yet"
                caption="You can attach one of the existing ones, or create a new one."
              >
                <Box gap="medium">
                  {canAttach && (
                    <Button variant="primary" onClick={() => setIsCloudIntegrationDrawerOpen(true)}>
                      Attach cloud integration
                    </Button>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/cloud-providers")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {!hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={CloudColored}
                title="You do not have any cloud integrations yet"
                caption={
                  <>
                    Cloud Integrations are used to dynamically generate short-lived credentials to
                    authenticate to cloud providers (AWS/Azure/GCP currently supported). In this way
                    you avoid using static credentials and by doing so, you reduce breaches.
                  </>
                }
              >
                <DocumentationButton
                  to={getDocsUrl("/integrations/cloud-providers")}
                  label="Documentation"
                />
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {hasSomeIntegrationsAttached && (
          <Box direction="column" align="start" gap="x-large" padding="x-large" limitWidth="medium">
            {integrations.map((integration) => (
              <StackSettingsIntegrationsCloudAttachedItem
                key={integration.id}
                cloudConfig={cloudConfig}
                attachedIntegrationType={
                  integration.__typename === "StackAzureIntegrationAttachment"
                    ? CLOUD_INTEGRATIONS.Azure
                    : CLOUD_INTEGRATIONS.AWS
                }
                attachedIntegration={integration}
                entityId={stackSettings.id}
                refetchQueries={REFETCH_STACK_SETTINGS_QUERIES}
              />
            ))}
            {gcpIntegration?.activated && (
              <StackSettingsIntegrationsCloudAttachedItem
                cloudConfig={cloudConfig}
                attachedIntegrationType={CLOUD_INTEGRATIONS.GCP}
                attachedIntegration={gcpIntegration}
                entityId={stackSettings.id}
                refetchQueries={REFETCH_STACK_SETTINGS_QUERIES}
              />
            )}
          </Box>
        )}
      </StackSettingsFormWrapper>
      <WarningContextProvider>
        <Drawer
          position="fixedRight"
          visible={isCloudIntegrationDrawerOpen}
          onOutsideClick={handleCloseCloudIntegrationDrawer}
          variant="wide"
        >
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Attach cloud integration" />
            <DrawerCloseIcon handleCloseDrawer={handleCloseCloudIntegrationDrawer} />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <StackSettingsIntegrationsCloudAttachForm
              entityId={stackSettings.id}
              hasData={hasData}
              refetch={refetch}
              refetching={refetching}
              setCloud={setCloud}
              cloud={cloud}
              cloudConfig={filteredCloudConfig}
              attachableAzureIntegrations={filteredAzureIntegrations}
              attachableAwsIntegrations={filteredAwsIntegrations}
              canAttachGcpIntegration={canAttachGcpIntegration}
              onSuccess={handleCloseCloudIntegrationDrawer}
              onCancel={handleCloseCloudIntegrationDrawer}
              refetchQueries={REFETCH_STACK_AND_STACK_SETTINGS_QUERIES}
            />
          </DrawerBody>
        </Drawer>
      </WarningContextProvider>
    </StackSettingsIntegrationsWrapper>
  );
};

export default StackSettingsIntegrationsCloud;
