import BadgeNext from "ds/components/BadgeNext";
import { RunExternalDependencyStatus, RunState } from "types/generated";

import { STATUSES } from "./ExternalDependencyBadge";
import { RUN_STATES } from "../../components/StateBadge";
import { prepareName } from "../../components/StateBadge/helpers";

export const RUN_DEPENDENCIES_STATUSES = { ...RUN_STATES, ...STATUSES };

type RunDependencyBadgeProps = {
  state: RunExternalDependencyStatus | RunState;
  className?: string;
};

const RunDependencyBadge = ({ state, className }: RunDependencyBadgeProps) => {
  if (!RUN_DEPENDENCIES_STATUSES[state]) {
    return null;
  }

  const text = prepareName(state);

  return (
    <BadgeNext
      variant={RUN_DEPENDENCIES_STATUSES[state].variant || "gray"}
      type="strong"
      className={className}
      text={text || state}
    />
  );
};

export default RunDependencyBadge;
