import { gql } from "@apollo/client";

export const GET_EXTERNAL_ID_FOR_MODULE = gql`
  query NewModuleGetExternalIdForModule(
    $integrationId: ID!
    $stackId: ID!
    $read: Boolean!
    $write: Boolean!
  ) {
    awsIntegration(id: $integrationId) {
      id
      externalIdForStack(stackId: $stackId, read: $read, write: $write) {
        externalId
        assumeRolePolicyStatement
      }
    }
  }
`;
