import { useLocation } from "react-router-dom-v5-compat";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import Resources from "views/Account/Resources/Wrapper";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import useTitle from "hooks/useTitle";
import EmptyState from "ds/components/EmptyState";
import { ResourcesColored } from "components/icons";

import { StackContext } from "../Context";
import { GET_RESOURCES } from "./gql";
import StackHeader from "../components/Header";
import { getStacksBackUrl } from "../helpers";

const StackResources = () => {
  const { onError } = useTypedContext(FlashContext);
  const { stack: stackCtx } = useTypedContext(StackContext);
  const { search } = useLocation();
  const { error, loading, data } = useQuery<{ stack: Stack }>(GET_RESOURCES, {
    onError,
    variables: { stackId: stackCtx.id },
  });

  useTitle(`Resources · ${stackCtx.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack) {
    return (
      <EmptyState
        icon={ResourcesColored}
        title="No Resources"
        caption="Please trigger a Run to see resources details here."
      />
    );
  }

  return (
    <>
      <StackHeader />
      <PageInfo title="Resources" />
      <PageWrapper>
        {data && data?.stack?.entities?.length === 0 && (
          <EmptyState
            icon={ResourcesColored}
            title="No Resources"
            caption="Please trigger a Run to see resources details here."
          />
        )}
        {data && data?.stack?.entities?.length === 1 && (
          <EmptyState icon={ResourcesColored} title="No Resources to show" />
        )}
        {data && data?.stack?.entities?.length > 1 && (
          <Resources data={data} search={search} stackName={data.stack.id} />
        )}
      </PageWrapper>
    </>
  );
};

export default StackResources;
