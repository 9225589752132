import { ReactNode } from "react";
import cx from "classnames";

import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type DrawerFooterProps = {
  children: ReactNode;
  sticky?: boolean;
} & BoxProps;

const DrawerFooter = ({ children, sticky, className, ...boxProps }: DrawerFooterProps) => {
  return (
    <Box
      direction="row"
      justify="end"
      align="center"
      className={cx(styles.footer, sticky && styles.sticky, className)}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default DrawerFooter;
