import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";

import { VersionInputOption } from "components/FormFields/VersionInput/types";
import { TerraformWorkflowTool } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FormField from "ds/components/Form/Field";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Select from "ds/components/Select";
import { TerraformWorkflowToolOptions } from "constants/terraform_workflow_tools";
import FormFieldVersionInput from "components/FormFields/VersionInput";
import VersionTooltipContent from "components/FormFields/VersionInput/VersionTooltipContent";
import { VENDOR_DICTIONARY, VENDOR_KEY } from "constants/vendor";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import useGetVendorResources from "shared/Stack/Vendor/useGetVendorResources";
import ToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import { stackValidator } from "utils/formValidators";
import { getDocsUrl } from "utils/getDocsUrl";

import {
  getFormDefaultValues,
  getValidateToolVersion,
  mapCreateStackVendorUpdateInput,
} from "./helpers";
import { StackSettingsContextData } from "../../Context";
import StackSettingsFormFooter from "../../components/FormFooter";
import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import { getExternalStateAccessEnabledTooltip, getWorkflowToolTooltip } from "./getTooltips";

export type StackSettingsTerraformVendorFormFields = {
  version: VersionInputOption;
  useSmartSanitization: boolean;
  workflowTool: TerraformWorkflowTool;
  externalStateAccessEnabled: boolean;
  workspace: string;
};

type StackSettingsVendorTerraformProps = StackSettingsVendorComponentBaseProps;

const StackSettingsVendorTerraformEdit = ({
  onStackUpdate,
  updateLoading,
}: StackSettingsVendorTerraformProps) => {
  const { terraformVersions, openTofuVersions, isRefetching, refetch } = useGetVendorResources({
    vendorSpecific: VENDOR_KEY.TERRAFORM,
  });

  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const terraformVendorForm = useForm<StackSettingsTerraformVendorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isValid, isDirty, errors },
    setValue,
    watch,
    trigger,
  } = terraformVendorForm;

  const watchedWorkflow = watch("workflowTool");
  const isOpenTofu = watchedWorkflow === TerraformWorkflowTool.OpenTofu;
  const watchedVersion = watch("version");

  const workflowName = isOpenTofu ? VENDOR_DICTIONARY.OPEN_TOFU : VENDOR_DICTIONARY.TERRAFORM;
  const toolVersions = isOpenTofu ? openTofuVersions : terraformVersions;

  const isVersionInputEnabled = watchedWorkflow !== TerraformWorkflowTool.Custom;

  const handleWorkflowToolChange = useCallback(
    (onFieldChange: (value: TerraformWorkflowTool) => void) => (value: TerraformWorkflowTool) => {
      if (watchedWorkflow === value) {
        return undefined;
      }

      let version: string;
      if (value === TerraformWorkflowTool.TerraformFoss) {
        version = terraformVersions ? terraformVersions[0] : "";
      } else if (value === TerraformWorkflowTool.OpenTofu) {
        version = openTofuVersions ? openTofuVersions[0] : "";
      } else {
        version = "";
      }

      onFieldChange(value);
      setValue("version", {
        value: version,
        type: watchedVersion.type,
      });

      trigger();
    },
    [openTofuVersions, setValue, terraformVersions, trigger, watchedVersion.type, watchedWorkflow]
  );

  const onSubmit = (formData: StackSettingsTerraformVendorFormFields) => {
    onStackUpdate(mapCreateStackVendorUpdateInput(formData));
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  const vendorName = isOpenTofu
    ? `${VENDOR_DICTIONARY.TERRAFORM} / ${VENDOR_DICTIONARY.OPEN_TOFU}`
    : VENDOR_DICTIONARY.TERRAFORM;

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={vendorName} />
      <Controller
        name="workflowTool"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            error={fieldState.error?.message}
            noMargin
            label="Workflow tool"
            {...getWorkflowToolTooltip()}
          >
            {({ ariaInputProps }) => (
              <Select
                value={field.value}
                options={TerraformWorkflowToolOptions}
                onChange={handleWorkflowToolChange(field.onChange)}
                error={!!fieldState.error?.message}
                ariaInputProps={ariaInputProps}
              />
            )}
          </FormField>
        )}
      />
      {isVersionInputEnabled && (
        <Controller
          name="version"
          control={control}
          rules={{
            validate: getValidateToolVersion(toolVersions),
          }}
          render={({ field, fieldState }) => {
            const version = field.value;

            return (
              <FormFieldVersionInput
                title={`${workflowName} version`}
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Choose version</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      <VersionTooltipContent />
                      <ReadMoreDocsLink
                        docsUrl={getDocsUrl("/concepts/stack/stack-settings#terraform-version")}
                      />
                    </TooltipModalBody>
                  </>
                }
                reloadLoading={isRefetching}
                reloadVersionsData={refetch}
                errorMessage={fieldState.error?.message}
                type={version.type}
                version={version.value}
                supportedVersions={toolVersions}
                onChange={field.onChange}
              />
            );
          }}
        />
      )}
      <Controller
        name="useSmartSanitization"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            align="center"
            onChange={(arg) => {
              field.onChange(arg);
              trigger("version");
            }}
            checked={field.value}
            title="Smart Sanitization (recommended)"
            description='If set, Spacelift will attempt to sanitize the resources created by terraform using the information provided by terraform as to which fields are "sensitive" or not'
          />
        )}
      />
      {stackSettings.managesStateFile && (
        <Controller
          name="externalStateAccessEnabled"
          control={control}
          render={({ field }) => (
            <ToggleField
              variant="switch"
              align="center"
              onChange={field.onChange}
              checked={field.value}
              title="External state access"
              description="Enables external, read-only access to the state of this Stack"
              tooltipInfo={getExternalStateAccessEnabledTooltip().tooltipInfo}
            />
          )}
        />
      )}
      {!stackSettings.managesStateFile && (
        <FormField label="Workspace" error={errors?.workspace?.message} isOptional noMargin>
          {({ ariaInputProps }) => (
            <Input
              placeholder="Terraform workspace to select"
              {...register("workspace", {
                validate: (value) => stackValidator.validateWorkspaceField(value) || true,
              })}
              error={!!errors?.workspace?.message}
              {...ariaInputProps}
            />
          )}
        </FormField>
      )}
      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={!isValid || !isDirty || updateLoading}
        submitLoading={updateLoading}
      />
    </>
  );
};

export default StackSettingsVendorTerraformEdit;
