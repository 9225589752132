import { RunPolicyReceipt } from "types/generated";

import { RunPolicyEvaluation } from "../../types";

export const getPolicyEvaluation = (policies: RunPolicyReceipt[]): RunPolicyEvaluation => {
  const firstMultiple = policies.find((policy) => policy.outcome === "multiple");

  if (firstMultiple) {
    return {
      label: firstMultiple.outcome,
      badgeVariant: "green",
      timestamp: firstMultiple.createdAt,
      isImportant: false,
    };
  }

  const targets = new Set(policies.map((policy) => policy.outcome));

  if (targets.size > 1) {
    return {
      label: "Multiple",
      badgeVariant: "green",
      timestamp: policies[0].createdAt,
      isImportant: false,
    };
  }

  return {
    label: Array.from(targets)[0],
    badgeVariant: "green",
    timestamp: policies[0].createdAt,
    isImportant: false,
  };
};
