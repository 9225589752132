import ListEntitiesItem from "components/ListEntitiesItem";
import { SearchQueryOrderDirection } from "types/generated";
import { SortableTableColumn } from "components/SortableTable";

import { RequiredInput } from "../../types";
import InputName from "../InputName";
import CollapsibleTable from "../../../components/CollapsibleTable";

const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
];

type RequiredInputsProps = {
  inputs: Array<RequiredInput>;
};

const RequiredInputs = ({ inputs }: RequiredInputsProps) => {
  return (
    <CollapsibleTable
      title="Required inputs"
      items={inputs}
      columns={columns}
      columnOrder="1fr"
      renderItems={(sorted) =>
        sorted.map((item, index) => (
          <ListEntitiesItem key={item.id} noSeparator={index === inputs.length - 1}>
            <InputName name={item.id} type={item.type} description={item.description} />
          </ListEntitiesItem>
        ))
      }
      initialSortBy="id"
      initialDirection={SearchQueryOrderDirection.Desc}
    />
  );
};

export default RequiredInputs;
