import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AwsIntegration } from "types/generated";

import { GET_EXTERNAL_ID_FOR_ENTITY } from "./gql";

type UseGetCloudIntegrationExternalIdProps = {
  entityId: string; // * Stack or Module ID
  integrationId: string;
  read: boolean;
  write: boolean;
};

const useGetCloudIntegrationExternalId = ({
  entityId,
  integrationId,
  read,
  write,
}: UseGetCloudIntegrationExternalIdProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data } = useQuery<{
    awsIntegration: AwsIntegration;
  }>(GET_EXTERNAL_ID_FOR_ENTITY, {
    variables: {
      read,
      write,
      integrationId,
      entityId,
    },
    skip: !entityId,
    onError,
  });

  const externalId = data?.awsIntegration.externalIdForStack?.externalId;
  const assumeRolePolicyStatement =
    data?.awsIntegration.externalIdForStack?.assumeRolePolicyStatement;

  const parsedAssumeRolePolicyStatement = useMemo(() => {
    try {
      if (assumeRolePolicyStatement) {
        return JSON.stringify(JSON.parse(assumeRolePolicyStatement), null, 2);
      }
    } catch (error) {
      onError(error);
    }

    return undefined;
  }, [onError, assumeRolePolicyStatement]);

  return {
    externalId,
    parsedAssumeRolePolicyStatement,
  };
};

export default useGetCloudIntegrationExternalId;
