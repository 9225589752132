import Skeleton from "react-loading-skeleton";
import cx from "classnames";

import styles from "./styles.module.css";

const Item = () => (
  <div className={styles.skeletonItem}>
    <Skeleton className={styles.tickbox} count={1} width={16} height={16} />

    <Skeleton count={1} height={18} width={300} style={{ marginBottom: 10 }} />
    <Skeleton count={1} height={26} />
    <Skeleton count={1} height={20} width={200} style={{ marginTop: 10 }} />
  </div>
);

type PageLayoutSkeletonProps = {
  className?: string;
  hideHeader?: boolean;
};

const PageLayoutSkeleton = ({ className, hideHeader }: PageLayoutSkeletonProps) => {
  return (
    <div className={cx(styles.skeletonWrapper, className)}>
      {!hideHeader && <Skeleton count={1} height={43} />}

      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
    </div>
  );
};

export default PageLayoutSkeleton;
