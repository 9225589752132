import { gql } from "@apollo/client";

export const PERSONAL_MFA_SETTINGS = gql`
  query PersonalMFASettings {
    hasSSO
    enforceMFA
    viewer {
      id
      securityKeys {
        id
        createdAt
        name
        owner
      }
    }
  }
`;

export const USER_SECURITY_KEY_DELETE_ALL = gql`
  mutation UserSecurityKeyDeleteAll {
    userSecurityKeyDeleteAll {
      id
    }
  }
`;
