import { Stack } from "types/generated";
import Box from "ds/components/Box";
import ListEntitiesItemDescriptionMultipleLines from "components/ListEntitiesItem/Description/MultipleLines";

import useStackListAnalytics from "../useStackListAnalytics";

type StackListItemDescriptionCellProps = {
  stack: Stack;
  onShowFullDescription: (stack: Stack) => unknown;
};

const StackListItemDescriptionCell = ({
  stack,
  onShowFullDescription,
}: StackListItemDescriptionCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  const handleShowFullDescription = () => {
    onShowFullDescription(stack);
    trackAnalytics("Show full description clicked");
  };

  if (!stack.description) {
    return null;
  }

  return (
    <Box direction="row" align="start">
      <ListEntitiesItemDescriptionMultipleLines
        description={stack.description}
        onShowFullDescription={handleShowFullDescription}
      />
    </Box>
  );
};

export default StackListItemDescriptionCell;
