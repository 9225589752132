import { gql } from "@apollo/client";

export const GET_MODULE_PULL_REQUESTS = gql`
  query GetModulePullRequests($id: ID!) {
    module(id: $id) {
      id
      pullRequests {
        id
        authorLogin
        createdAt
        updatedAt
        headBranch
        headSHA
        title
        url
        runs {
          id
          createdAt
          commit {
            authorLogin
            authorName
            hash
            timestamp
            url
          }
          delta {
            addCount
            changeCount
            deleteCount
            resources
            importCount
            forgetCount
            moveCount
          }
          expectFailure
          runtimeConfig {
            terraform {
              version
            }
          }
          state
          title
          triggeredBy
        }
      }
    }
  }
`;
