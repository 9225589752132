import { useMutation } from "@apollo/client";
import { Base64 } from "js-base64";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ApiKey, ApiKeyInput, ApiKeyType, Maybe } from "types/generated";
import { downloadFile } from "utils/file";

import { CREATE_API_KEY } from "./gql";

const prepareConfigFile = (secret: Maybe<string>, id: string) => {
  const encodedSecret = Base64.encode(`api:${id}:${secret || ""}`, true);

  return `Please use the following API secret when communicating with Spacelift
programmatically:

${secret || ""}

Please add this snippet to your .terraformrc file if you want to use this API
key to access Spacelift-hosted Terraform modules outside of Spacelift:

credentials "spacelift.io" {
token = "${encodedSecret}"
}
`;
};

type UseCreateApiKeyOptions = {
  callback?: () => void;
  refetchQueries?: string[];
};

const useCreateApiKey = ({ callback, refetchQueries }: UseCreateApiKeyOptions) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [createApiKey] = useMutation<{ apiKeyCreate: ApiKey }>(CREATE_API_KEY, {
    refetchQueries,
  });

  const onCreate = (input: ApiKeyInput) => {
    createApiKey({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        if (data) {
          const {
            apiKeyCreate: { name, id, type, secret },
          } = data;

          if (type === ApiKeyType.Secret) {
            const secretFile = prepareConfigFile(secret, id);
            downloadFile(`api-key-${name}.config`, secretFile);
            reportSuccess({
              title: "Downloading file credentials",
              message: `Your credentials file is being downloaded for Key ID: ${id}`,
            });
          } else if (type === ApiKeyType.Oidc) {
            reportSuccess({
              message: `API key successfully created`,
            });
          }
          callback?.();
        }
      })
      .catch(onError);
  };

  return { onCreate };
};

export default useCreateApiKey;
