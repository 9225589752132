import EmptyState from "ds/components/EmptyState";
import { UsedByColored } from "components/icons";
import Link from "ds/components/Link";
import { getDocsUrl } from "utils/getDocsUrl";

const WorkerPoolUsedByEmpty = () => {
  return (
    <EmptyState
      title="No usage found"
      icon={UsedByColored}
      caption={
        <>
          Learn how to make a{" "}
          <Link
            href={getDocsUrl("/concepts/worker-pools#using-worker-pools")}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          >
            stack
          </Link>{" "}
          or a{" "}
          <Link
            href={getDocsUrl("/concepts/worker-pools#using-worker-pools")}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          >
            module
          </Link>{" "}
          use a worker pool
        </>
      }
    />
  );
};

export default WorkerPoolUsedByEmpty;
