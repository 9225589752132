import { AspectType } from "types/generated";

import { TimeFilter } from "../types";

// TODO: remove it once BE aligns types
const ASPECT_TYPE_MAP = {
  [AspectType.RunMinutes]: "run-minutes",
  [AspectType.WorkersCount]: "worker-count",
};

export const getCSVDownloadLink = (aspectType: AspectType, timeFilter: TimeFilter) => {
  const aspect = ASPECT_TYPE_MAP[aspectType];
  const since = timeFilter.startTime.toISOString().split("T")[0];
  const until = timeFilter.endTime.toISOString().split("T")[0];

  return `/usageanalytics/csv?aspect=${aspect}&since=${since}&until=${until}`;
};
