import { Stack } from "types/generated";

import { hasAtLeastStackWriteAccess } from "../utils";

/**
 * Check if the current user can lock the stack
 * @param stack
 * @returns boolean
 */
export const canLockStack = (stack: Stack): boolean => {
  const isNotLocked = !stack.lockedAt && !stack.lockedBy;
  const hasPermissionToLock = hasAtLeastStackWriteAccess(stack);

  return isNotLocked && hasPermissionToLock;
};
