import { EntityChangeV2, EntityChangeType, RunState, RunStateTransition } from "types/generated";

import { RunChangesItem, RunChangesStatus } from "./types";

const sortAlphabetically = (a: RunChangesItem, b: RunChangesItem) => {
  if (a.entity.id < b.entity.id) {
    return -1;
  } else if (a.entity.id > b.entity.id) {
    return 1;
  }

  return 0;
};

export const isRunApplied = (history: RunStateTransition[]) => {
  const lastTransitionState = history[0].state;

  if (
    lastTransitionState !== RunState.Applying &&
    history.some((item) => item.state === RunState.Applying)
  ) {
    return true;
  }

  return false;
};

export const makeChangesList = (
  plannedChanges: EntityChangeV2[],
  actualChanges: EntityChangeV2[],
  isRunApplied: boolean
) => {
  const plannedChangesMap = new Map(plannedChanges.map((item) => [item.entity.id, item]));
  const actualChangesMap = new Map(actualChanges.map((item) => [item.entity.id, item]));

  const changesList = Array.from(
    [...plannedChangesMap, ...actualChangesMap].reduce((acc, [key, value]) => {
      if (acc.has(key)) return acc;

      if (isRunApplied && plannedChangesMap.has(key) && actualChangesMap.has(key)) {
        acc.set(key, { ...value, status: RunChangesStatus.SUCCESSFUL });
      } else if (isRunApplied && !plannedChangesMap.has(key) && actualChangesMap.has(key)) {
        acc.set(key, { ...value, status: RunChangesStatus.UNPLANNED });
      } else if (isRunApplied && plannedChangesMap.has(key) && !actualChangesMap.has(key)) {
        const failedStatus =
          value.type === EntityChangeType.Change
            ? RunChangesStatus.UNCHANGED
            : RunChangesStatus.FAILED;

        acc.set(key, { ...value, status: failedStatus });
      } else if (!isRunApplied && plannedChangesMap.has(key)) {
        acc.set(key, { ...value, status: RunChangesStatus.PLANNED });
      }

      return acc;
    }, new Map<string, RunChangesItem>())
  );

  const sortedChangesList = [...changesList].sort(([, a], [, b]) => sortAlphabetically(a, b));

  return sortedChangesList;
};
