import Typography from "ds/components/Typography";
import { SpacingShorthand } from "types/Spacing";

type HistoryEntryWrapperSeparatorProps = {
  margin?: SpacingShorthand;
};

const HistoryEntryWrapperSeparator = ({
  margin = "0 medium",
}: HistoryEntryWrapperSeparatorProps) => {
  return (
    <Typography variant="p-body4" tag="span" color="tertiary" margin={margin} aria-hidden>
      &bull;
    </Typography>
  );
};

export default HistoryEntryWrapperSeparator;
