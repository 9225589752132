import { AccountViewer } from "components/form/graphql/types";
import { Stack } from "types/generated";
import { hasSpaceManageAccess, hasSpaceManageOrWriteAccess } from "utils/user";

// FYI: the "stack.canWrite" check here is for legacy stacks (could be affected by access policies)
export const hasAtLeastStackWriteAccess = (stack: Stack) =>
  stack.canWrite || hasSpaceManageOrWriteAccess(stack.spaceDetails.accessLevel);

export const canManageStacksAndRuns = (stack: Stack, viewer: AccountViewer | undefined) =>
  viewer?.admin || hasSpaceManageAccess(stack.spaceDetails.accessLevel);

export const getDisabledTriggerMessage = (stack: Stack) => {
  let disableTriggerButtonMessage: string | undefined;
  if (stack.isDisabled && stack.vcsDetached) {
    disableTriggerButtonMessage = "Stack is disabled and has no source code integration attached";
  } else if (stack.isDisabled) {
    disableTriggerButtonMessage = "Stack is disabled";
  } else if (stack.vcsDetached) {
    disableTriggerButtonMessage = "Stack has no source code integration attached";
  }

  return disableTriggerButtonMessage;
};
