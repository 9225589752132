import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

type PersonalSettingsGroupsPageLayoutProps = {
  children: ReactNode;
};

const PersonalSettingsGroupsPageLayout = ({ children }: PersonalSettingsGroupsPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Personal Settings · IdP Groups · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">IdP Groups</ViewHeaderTitle>
      </ViewHeader>
      {children}
    </>
  );
};

export default PersonalSettingsGroupsPageLayout;
