import useTypedContext from "hooks/useTypedContext";

import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsVendorTerraformEdit from "./Edit";
import StackSettingsVendorTerraformView from "./View";
import { StackSettingsContextData } from "../../Context";

const StackSettingsVendorTerraform = (props: StackSettingsVendorComponentBaseProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return canManageStack ? (
    <StackSettingsVendorTerraformEdit {...props} />
  ) : (
    <StackSettingsVendorTerraformView />
  );
};

export default StackSettingsVendorTerraform;
