import { PolicyType } from "types/generated";
import Dropdown from "ds/components/Dropdown";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import PolicyTypeBadge from ".";

type PolicyTypeBadgeDropdownProps = {
  type: PolicyType;
  className?: string;
  applyFilter: (value: PolicyType) => void;
};

const PolicyTypeBadgeDropdown = ({
  type,
  className,
  applyFilter,
}: PolicyTypeBadgeDropdownProps) => {
  const getHandleOnClick = (callback: () => void) => () => {
    applyFilter(type);
    callback();
  };

  return (
    <Dropdown
      position="bottomRight"
      renderTriggerComponent={({ onClick, ariaProps }) => (
        <PolicyTypeBadge
          onClick={onClick}
          className={className}
          type={type}
          withDropdown
          ariaProps={ariaProps}
        />
      )}
    >
      {({ closeDropdown }) => {
        return (
          <DropdownSection>
            <DropdownSectionItem onClick={getHandleOnClick(closeDropdown)}>
              Add to filters
            </DropdownSectionItem>
          </DropdownSection>
        );
      }}
    </Dropdown>
  );
};

export default PolicyTypeBadgeDropdown;
