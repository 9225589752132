import FormFieldViewText, { type FormFieldViewTextProps } from "../ViewText";

type FormFieldViewBooleanProps = Omit<FormFieldViewTextProps, "value"> & {
  value: boolean;
};

const FormFieldViewBoolean = ({ value, ...restProps }: FormFieldViewBooleanProps) => {
  return <FormFieldViewText {...restProps} value={value ? "On" : "Off"} />;
};

export default FormFieldViewBoolean;
