import { ConfigElementWithSource } from "types/generated";
import Box from "ds/components/Box";

import EnvironmentPreviewConfigElement from "../ConfigElement";

type EnvironmentPreviewListProps = {
  items: ConfigElementWithSource[];
};

const EnvironmentPreviewList = ({ items }: EnvironmentPreviewListProps) => {
  return (
    <Box direction="column" gap="medium" fullWidth>
      {items.map(({ context, element }) => (
        <EnvironmentPreviewConfigElement
          key={element.id + element.type}
          {...element}
          context={context}
        />
      ))}
    </Box>
  );
};

export default EnvironmentPreviewList;
