import { Form, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import useTitle from "hooks/useTitle";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import ButtonNew from "ds/components/Button/New";
import { stringIsRequired } from "utils/formValidators";
import SecretInput from "ds/components/SecretInput";
import SystemMessage from "components/SystemMessage";
import useSelfHostedInstanceInfo from "hooks/useSelfHostedInstanceInfo";
import NotFoundPage from "components/error/NotFoundPage";

type SelfHostedLoginFormFields = {
  username: string;
  password: string;
};

const SelfHostedLogin = () => {
  useTitle("Log in · Spacelift");

  const instanceInfo = useSelfHostedInstanceInfo();

  const {
    register,
    formState: { errors },
    control,
  } = useForm<SelfHostedLoginFormFields>({
    mode: "onChange",
    progressive: true,
  });

  const handleLoginSuccess = () => {
    // TODO: wait for API to be implemented
  };

  const handleLoginError = () => {
    // TODO: wait for API to be implemented
  };

  if (!instanceInfo || !instanceInfo?.isAdminLoginEnabled) {
    return <NotFoundPage />;
  }

  return (
    <SystemMessage
      title="Log in to your account"
      message="Enter credentials defined during infra deployment process."
    >
      <Form
        control={control}
        method="post"
        headers={{ "Content-Type": "application/json" }}
        validateStatus={(status) => status === 200}
        action="/admin_login"
        onSuccess={handleLoginSuccess}
        onError={handleLoginError}
      >
        <Box direction="column" gap="xx-large">
          <Box direction="column" gap="x-large">
            <FormField label="Username" error={errors?.username?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  error={!!errors?.username}
                  {...register("username", {
                    validate: stringIsRequired("Username field is required."),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
            <FormField label="Password" error={errors?.password?.message} noMargin>
              {({ ariaInputProps }) => (
                <SecretInput
                  error={!!errors?.password}
                  {...register("password", {
                    validate: stringIsRequired("Password field is required."),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </Box>

          <ButtonNew type="submit" variant="primary" fullWidth>
            Log in
          </ButtonNew>
        </Box>
      </Form>
    </SystemMessage>
  );
};

export default SelfHostedLogin;
