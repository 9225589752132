import { Stack } from "types/generated";
import { projectRootTooltipContent } from "components/TooltipSnippets";

import StackListItemTextValue from "./TextValue";

type StackListItemProjectRootCellProps = { stack: Stack };

const StackListItemProjectRootCell = ({ stack }: StackListItemProjectRootCellProps) => {
  if (!stack.projectRoot) {
    return null;
  }

  return (
    <StackListItemTextValue tooltip={projectRootTooltipContent}>
      {stack.projectRoot}
    </StackListItemTextValue>
  );
};

export default StackListItemProjectRootCell;
