import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";

import { CREATE_GPG_KEY } from "../gql";
import { CreateGpgKeyFormFields, CreateGpgKeyGql } from "./types";

const CreateGpgKeyDrawer = createDrawer(() => {
  const drawer = useModal();
  const builderForm = useForm<CreateGpgKeyFormFields>({
    defaultValues: {
      name: "",
      asciiArmor: "",
      description: "",
    },
    mode: "onChange",
  });

  const { onError, reportError, reportSuccess } = useTypedContext(FlashContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [createGpgKey] = useMutation<CreateGpgKeyGql>(CREATE_GPG_KEY, {
    refetchQueries: ["GetGpgKeys"],
  });

  const onCreateSubmit = (formData: CreateGpgKeyFormFields) => {
    createGpgKey({
      variables: {
        name: formData.name,
        description: formData.description,
        asciiArmor: formData.asciiArmor,
      },
    })
      .then(({ data }) => {
        if (data?.gpgKeyCreate?.name) {
          reportSuccess({
            message: `GPG key "${data.gpgKeyCreate.name}" was successfully registered`,
          });
        } else {
          reportError({
            message: "Something went wrong while registering GPG key, please try again.",
          });
        }

        drawer.hide();
      })
      .catch(onError);
  };

  return (
    <DrawerSimple>
      <FormProvider {...builderForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Register GPG key" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter a unique name..."
                error={!!errors?.name}
                {...register("name", { required: "Name field is required." })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="ASCII armor">
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter ASCII armor here..."
                maxRows={15}
                {...register("asciiArmor", { required: "ASCII armor field is required." })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Description" isOptional>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter description here..."
                maxRows={15}
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <DrawerFooter>
            <DrawerFooterActions>
              <DrawerCancelButton />

              <Button
                variant="primary"
                onClick={handleSubmit(onCreateSubmit)}
                disabled={!isValid || !isDirty}
              >
                Register
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </DrawerSimple>
  );
});

export const showCreateGpgKeyDrawer = createDrawerTrigger(CreateGpgKeyDrawer);
