import StackHooks from "components/DocumentationSnippets/StackHooks";
import { Documentation } from "components/icons";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

type StackHooksDocumentationDrawerProps = {
  handleCloseDrawer: () => void;
};

const StackHooksDocumentationDrawer = ({
  handleCloseDrawer,
}: StackHooksDocumentationDrawerProps) => {
  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Hooks" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="p" variant="p-t6">
          Customizing workflow
        </Typography>

        <StackHooks />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button
              variant="secondary"
              startIcon={Documentation}
              target="_blank"
              rel="noopener noreferrer"
              href={getDocsUrl("/concepts/stack/stack-settings#customizing-workflow")}
            >
              Documentation
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default StackHooksDocumentationDrawer;
