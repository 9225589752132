import NotFoundPage from "components/error/NotFoundPage";
import { isSelfHostedDistribution } from "utils/distribution";
import useLDContextUpdate from "hooks/useLDContextUpdate";

import PrivateWorkerPool from "../PrivateWorkerPool";
import PublicWorkerPool from "../PublicWorkerPool";
import { LDWorkerPool } from "./LDWorkerPool";
import { useWorkerPoolInfo } from "./useWorkerPoolInfo";

const isSelfHosted = isSelfHostedDistribution();

const WorkerPool = () => {
  const { workerPoolId, isWorkerPoolPublic } = useWorkerPoolInfo();

  useLDContextUpdate<LDWorkerPool>(
    {
      workerpool_is_public: isWorkerPoolPublic,
      workerpool_ulid: workerPoolId,
    },
    [isWorkerPoolPublic, workerPoolId]
  );

  // FYI: the public worker pool is not available on self-hosted version
  if (isSelfHosted && isWorkerPoolPublic) {
    return <NotFoundPage />;
  }

  return isWorkerPoolPublic ? <PublicWorkerPool /> : <PrivateWorkerPool />;
};

export default WorkerPool;
