import { create, show, useModal } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { DETACH_CONTEXT } from "../../gql";

type DetachContextConfirmationProps = {
  isModule: boolean;
  name: string;
  id: string;
};

const DetachContextConfirmation = create(function DetachContextConfirmation({
  isModule,
  name,
  id,
}: DetachContextConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [detachContext, { loading, data }] = useMutation(DETACH_CONTEXT, {
    onError,
    awaitRefetchQueries: true,
    refetchQueries: ["GetContext"],
    variables: { id },
  });

  const handleDetachContext = useCallback(async () => {
    try {
      await detachContext();
      reportSuccess({ message: "Context successfully detached" });
      modal.hide();
    } catch (error) {
      onError(error);
    }
  }, [detachContext, onError, reportSuccess, modal]);

  return (
    <DeleteConfirmationModal
      title="Detach context"
      confirmationButtonLabel="Detach"
      onConfirm={handleDetachContext}
      isLoading={loading}
      isDismissable={!loading && !data?.contextDetach?.id}
    >
      <ConfirmationModalMessage>
        Are you sure you want to detach context from <strong>{name}</strong>{" "}
        {isModule ? "module" : "stack"}?
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showDetachContextConfirmation = (props: DetachContextConfirmationProps) =>
  show(DetachContextConfirmation, props);
