import ReactFlow, { Background, Controls, MiniMap } from "reactflow";
import useLocalStorage from "@rehooks/local-storage";
import { useMemo } from "react";

import Toast from "ds/components/Toast";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";

import styles from "./styles.module.css";
import { getViewOptions, makeEdges, makeNodesList, makeSpacesTree } from "./helpers";
import SpaceNode from "../SpaceNode";
import { SpacesViewActionsContext } from "../ActionsContext";

const NODE_TYPES = { space: SpaceNode };

const SpacesDiagram = () => {
  const { spaces } = useTypedContext(SpacesContext);
  const { onCloseDrawer } = useTypedContext(SpacesViewActionsContext);

  const [isInfoToastVisible, setInfoToastVisibility] = useLocalStorage<boolean>(
    "spacesInfoMessage",
    true
  );

  const handleToastClose = () => setInfoToastVisibility(false);

  const handleDrawerClose = () => {
    onCloseDrawer();
  };

  const [nodesList, edges] = useMemo(() => {
    const spacesTree = makeSpacesTree(spaces);

    const nodesList = spacesTree ? makeNodesList(spacesTree) : [];
    const edges = makeEdges(spaces);

    return [nodesList, edges];
  }, [spaces]);

  return (
    <div className={styles.wrapper}>
      <ReactFlow
        className={styles.reactFlow}
        onInit={(reactFlowInstance) => {
          reactFlowInstance.fitView({ maxZoom: 1 });
        }}
        nodes={nodesList}
        edges={edges}
        nodeTypes={NODE_TYPES}
        fitViewOptions={getViewOptions(nodesList.length)}
        nodesDraggable={false}
        nodesConnectable={false}
        maxZoom={4}
        minZoom={0.1}
        fitView
        panOnScroll
        zoomOnPinch
        aria-label="Spaces tree graph"
        onClick={handleDrawerClose}
      >
        {nodesList.length > 10 && <MiniMap nodeStrokeWidth={3} />}
        <Controls className={styles.controls} showInteractive={false} />
        <Background />
      </ReactFlow>

      {isInfoToastVisible && (
        <Toast variant="info" onClose={handleToastClose}>
          A solid arrow indicates resources are inherited from the parent space.
          <br />A dashed arrow indicates no inheritance.
        </Toast>
      )}
    </div>
  );
};

export default SpacesDiagram;
