import { WebhookDelivery } from "types/generated";
import { BadgeVariants } from "ds/components/BadgeNext/types";

export enum NetworkStatuses {
  Ok = "Ok",
  Redirect = "Redirect",
  Error = "Error",
  Special = "Special",
}

export type WebhookDeliveryStatusProps = {
  status: WebhookDelivery["status"];
};

type NetworkStatusConfig = {
  variant: BadgeVariants;
};

export const NETWORK_STATUSES: Record<NetworkStatuses, NetworkStatusConfig> = {
  [NetworkStatuses.Ok]: { variant: "green" },
  [NetworkStatuses.Error]: { variant: "red" },
  [NetworkStatuses.Redirect]: { variant: "yellow" },
  [NetworkStatuses.Special]: { variant: "gray" },
};
