import { ReactNode } from "react";
import cx from "classnames";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type TagsListWrapperProps = {
  isExpanded?: boolean;
  children: ReactNode;
  className?: string;
  withTopMargin?: boolean;
};

const TagsListWrapper = ({
  isExpanded,
  children,
  className,
  withTopMargin = true,
}: TagsListWrapperProps) => (
  <Box
    direction="row"
    className={cx(
      styles.tagsList,
      {
        [styles.expanded]: isExpanded,
        // FYI: use Box gap instead
        [styles.withoutTopMargin]: !withTopMargin,
      },
      className
    )}
    data-tags="tagsWrapper"
  >
    {children}
  </Box>
);

export default TagsListWrapper;
