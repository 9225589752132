import { ReactNode } from "react";
import { Header } from "react-aria-components";

import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type DropdownMenuSectionHeaderProps = {
  children: ReactNode;
};

const DropdownMenuSectionHeader = ({ children }: DropdownMenuSectionHeaderProps) => {
  return (
    <Header className={styles.header}>
      <Typography tag="span" variant="p-t7" color="secondary">
        {children}
      </Typography>
    </Header>
  );
};

export default DropdownMenuSectionHeader;
