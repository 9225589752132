import { FormEvent, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import setLocation from "shared/setLocation";
import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import FormGroup from "components/FormDefault/Group";
import Select from "components/select/Select";
import { SamlNameIdFormat } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import formStyles from "components/FormDefault/styles.module.css";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { nameIDFormatOptions } from "./NameIDHelpers";
import { CREATE_SAML } from "./gql";

type SSOSetupFormProps = {
  onCancel: () => unknown;
};

const SSOSetupForm = ({ onCancel }: SSOSetupFormProps) => {
  const { onError } = useTypedContext(FlashContext);

  const [createSaml, { loading }] = useMutation(CREATE_SAML, {
    refetchQueries: ["GetSSOSettings"],
  });

  const metadataRef = useRef<HTMLTextAreaElement>(null);
  const metadataUrlRef = useRef<HTMLInputElement>(null);

  const [dynamic, setDynamic] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [metadataSource, setMetadataSource] = useState<
    HTMLTextAreaElement | HTMLInputElement | null
  >(metadataRef.current);
  const [nameIDFormat, setNameIDFormat] = useState(SamlNameIdFormat.Transient);

  const onChange = () => {
    if (metadataSource) {
      setDisabled(metadataSource.value === "");
    }
  };

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSAML,

    defaultCallbackTrackProperties: {
      method: "SAML",
    },
  });

  useEffect(() => {
    setMetadataSource((dynamic ? metadataUrlRef : metadataRef).current);
  }, [dynamic]);

  useEffect(onChange, [metadataSource]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trackSegmentAnalyticsEvent("Set Up Save");

    if (metadataSource?.value) {
      createSaml({
        variables: { dynamic, metadata: metadataSource.value, nameIDFormat },
      })
        .then(() => setLocation("/saml/start"))
        .catch(onError);
    }
  };

  const cancelHandler = () => {
    trackSegmentAnalyticsEvent("SAML Set Up Cancel");
    onCancel();
  };

  return (
    <form onSubmit={onSubmit}>
      <FormGroup labelText="NameID Format:" labelStyle={{ long: true }}>
        <Select
          className={formStyles.input}
          value={nameIDFormatOptions.find((format) => format.value === nameIDFormat)}
          options={nameIDFormatOptions}
          onChange={({ value }: (typeof nameIDFormatOptions)[0]) => setNameIDFormat(value)}
          placeholder="Select format"
        />
      </FormGroup>
      <FormGroup
        labelText="Dynamic configuration:"
        labelStyle={{
          long: true,
        }}
        type="checkbox"
        checked={dynamic}
        id="dynamic"
        onChange={() => setDynamic(!dynamic)}
      />
      {dynamic ? (
        <FormGroup
          labelText="IdP metadata URL:"
          labelStyle={{
            long: true,
          }}
          type="text"
          placeholder="URL"
          inputRef={metadataUrlRef}
          onChange={onChange}
        />
      ) : (
        <FormGroup
          labelText="IdP metadata:"
          labelStyle={{
            long: true,
          }}
          type="textarea"
          placeholder="Static XML identity provider metadata"
          inputRef={metadataRef}
          onChange={onChange}
          top
        />
      )}
      <FormFooter top>
        <FormButton type="submit" disabled={disabled} loading={loading} full pill>
          Save
        </FormButton>
        <FormButton onClick={cancelHandler} pill>
          Cancel
        </FormButton>
      </FormFooter>
    </form>
  );
};

export default SSOSetupForm;
