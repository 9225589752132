import Box from "ds/components/Box";
import Callout from "ds/components/Callout";
import TriggerButton from "views/Stack/Runs/components/TriggerButton";
import { Stack } from "types/generated";
import { AnalyticsPage } from "hooks/useAnalytics";

type ExpiredInfoProps = {
  stack: Stack;
  analyticsPage?: AnalyticsPage;
};

const ExpiredInfo = ({ stack, analyticsPage }: ExpiredInfoProps) => {
  return (
    <Callout variant="warning">
      <Box direction="column" gap="small" align="start">
        This run has expired. Please trigger a new one.
        <TriggerButton
          analyticsPage={analyticsPage}
          stack={stack}
          variant="contrast"
          size="small"
          text="Trigger new run"
        />
      </Box>
    </Callout>
  );
};

export default ExpiredInfo;
