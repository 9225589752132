import cx from "classnames";

import { Spinner } from "components/icons";
import formStyles from "components/FormDefault/styles.module.css";

const FormLoading = () => (
  <section className={formStyles.form}>
    <section className={cx(formStyles.body, formStyles.center)}>
      <Spinner width="40px" />
    </section>
  </section>
);

export default FormLoading;
