import { subDays, subHours } from "date-fns";

import { PeriodValue } from "ds/components/Datepicker/types";
import { SearchQueryFieldConstraintTimeInLast } from "types/generated";

export const notificationsSuggestionsDictionary = {
  groupingKey: "grouping key",
} as { [key: string]: string };

export const PERIODS: Record<string, PeriodValue> = {
  [SearchQueryFieldConstraintTimeInLast.Last1H]: {
    label: "1 hour",
    range: () => ({
      start: subHours(new Date(), 1),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last4H]: {
    label: "4 hours",
    range: () => ({
      start: subHours(new Date(), 1),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last1D]: {
    label: "1 day",
    range: () => ({
      start: subDays(new Date(), 1),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last7D]: {
    label: "7 days",
    range: () => ({
      start: subDays(new Date(), 7),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last14D]: {
    label: "14 days",
    range: () => ({
      start: subDays(new Date(), 14),
      end: new Date(),
    }),
  },
};
