import { SortableTableColumn } from "components/SortableTable";

export const FILTERS_ORDER_SETTINGS_KEY_VERSION_RESOURCES = "version-resources";

export const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
];
