import { create, show, useModal } from "@ebay/nice-modal-react";

import Typography from "ds/components/Typography";
import ConfirmationModal from "ds/components/ConfirmationModal";

type CustomizeViewDrawerResetConfirmationProps = {
  onReset: () => void;
};

const CustomizeViewDrawerResetConfirmation = create(function CustomizeViewDrawerResetConfirmation({
  onReset,
}: CustomizeViewDrawerResetConfirmationProps) {
  const handleOnReset = () => {
    onReset();
    modal.hide();
  };

  const modal = useModal();

  return (
    <ConfirmationModal
      title="Reset list configuration"
      confirmationButtonLabel="Reset"
      onConfirm={handleOnReset}
      shouldIgnoreOutsideClick
    >
      <Typography variant="p-body2" tag="p">
        Resetting this list to default configuration will clear column order, visibility and size.
        Are you sure you want to reset?
      </Typography>
    </ConfirmationModal>
  );
});

export const showResetConfirmation = (props: CustomizeViewDrawerResetConfirmationProps) =>
  show(CustomizeViewDrawerResetConfirmation, props);
