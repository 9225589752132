import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { ManagedUser } from "types/generated";

import UsersListItem from "./Item";

type UsersListVirtualizedItemProps = {
  users: ManagedUser[];
  handleUserDetailsClick: (details: ManagedUser) => unknown;
};

const UsersListVirtualizedItem = (
  props: ListChildComponentProps<UsersListVirtualizedItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const item = data.users[index];
  const key = `${index} ${item.id}`;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <UsersListItem
        key={key}
        item={item}
        setRowHeight={handleSetRowHeight}
        handleUserDetailsClick={data.handleUserDetailsClick}
      />
    </div>
  );
};

export default memo(UsersListVirtualizedItem, areEqual);
