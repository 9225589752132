import { BulkActionItemResults } from "../../helpers";
import { BulkActionItemID, BulkActionResultTabs, BulkActionsResult } from "../../types";
import { BulkActionResultsStepContinueWith } from "./types";

const getEntityListFromItems = <Entity>(
  resultItems: Set<BulkActionItemID>,
  getEntity: (id: BulkActionItemID) => Entity | undefined
): Entity[] =>
  Array.from(resultItems)
    .map(getEntity)
    .filter((entity) => entity !== undefined);

type BulkActionResultsStepTabItems<Entity> = {
  [key in BulkActionResultTabs]: {
    entitiesList: {
      status: BulkActionsResult;
      items: Array<Entity>;
    }[];
    count: number;
  };
};

export const getTabItems = <Entity>(
  itemResults: BulkActionItemResults,
  getEntity: (id: BulkActionItemID) => Entity | undefined
): BulkActionResultsStepTabItems<Entity> => {
  const completedItems = getEntityListFromItems(
    itemResults[BulkActionsResult.Completed],
    getEntity
  );
  const failedItems = getEntityListFromItems(itemResults[BulkActionsResult.Failed], getEntity);
  const skippedItems = getEntityListFromItems(itemResults[BulkActionsResult.Skipped], getEntity);
  const stoppedItems = getEntityListFromItems(itemResults[BulkActionsResult.Stopped], getEntity);
  const pendingItems = getEntityListFromItems(itemResults[BulkActionsResult.Pending], getEntity);
  const queuedItems = getEntityListFromItems(itemResults[BulkActionsResult.Queued], getEntity);

  return {
    [BulkActionsResult.Completed]: {
      entitiesList: [
        {
          status: BulkActionsResult.Completed,
          items: completedItems,
        },
      ],
      count: completedItems.length,
    },
    [BulkActionsResult.Failed]: {
      entitiesList: [
        {
          status: BulkActionsResult.Failed,
          items: failedItems,
        },
        {
          status: BulkActionsResult.Skipped,
          items: skippedItems,
        },
        {
          status: BulkActionsResult.Stopped,
          items: stoppedItems,
        },
      ],
      count: failedItems.length + skippedItems.length + stoppedItems.length,
    },
    [BulkActionsResult.Pending]: {
      entitiesList: [
        {
          status: BulkActionsResult.Pending,
          items: pendingItems,
        },
        {
          status: BulkActionsResult.Queued,
          items: queuedItems,
        },
      ],
      count: pendingItems.length + queuedItems.length,
    },
  };
};

export const getDefaultOnContinueWith =
  <Entity extends { id: string }>(
    tabItems: BulkActionResultsStepTabItems<Entity>,
    onBulkContinueWith: (set: Set<BulkActionItemID>) => void
  ) =>
  (continueWith: BulkActionResultsStepContinueWith) => {
    if (continueWith === "all") {
      return;
    }

    onBulkContinueWith(
      new Set(
        tabItems[continueWith].entitiesList.map(({ items }) => items.map(({ id }) => id)).flat()
      )
    );
  };
