import { NetworkStatus, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Datum } from "ds/components/Charts/PieChart/types";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import useAnalytics from "hooks/useAnalytics";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardResourcesChart from "../../ResourcesChart";
import DashboardResourcesChartCentralInfo from "../../ResourcesChart/CentralInfo";
import { vendors } from "./constants";
import { GET_RESOURCES_COUNT_BY_VENDOR } from "./gql";
import { GetResourcesCountByVendor } from "./types";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";

const DashboardWidgetsManagedResources = () => {
  const pollInterval = usePollInterval();

  const { data, loading, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetResourcesCountByVendor>(GET_RESOURCES_COUNT_BY_VENDOR, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
  });

  const navigate = useNavigate();

  const isLoading = loading && !data?.metrics?.resourcesCountByVendor;

  const hasNoStacks = !data?.metrics?.hasStacks;

  const parsedData = useMemo(() => {
    if (showErrorBanner) {
      return [];
    }

    return (
      data?.metrics?.resourcesCountByVendor
        ?.map((resource) => {
          const vendorLabel = resource.labels[0];

          return {
            color: vendors[vendorLabel].color,
            activeColor: vendors[vendorLabel].activeColor,
            inactiveColor: vendors[vendorLabel].inactiveColor,
            label: vendorLabel,
            id: vendorLabel,
            value: resource.value,
          };
        })
        ?.sort((a, b) => b.value - a.value) || []
    );
  }, [data?.metrics?.resourcesCountByVendor, showErrorBanner]);

  const navigateToResource = useCallback(
    (data: Datum) => {
      trackSegmentAnalyticsEvent("Managed Resources Widget - Redirect Clicked", {
        resourceType: data.label,
      });
      navigate(
        `/resources?filterValues=${btoa(
          encodeURIComponent(JSON.stringify([[{ value: data.label, label: data.label }]]))
        )}&filterFields=${btoa(JSON.stringify(["vendorName"]))}`
      );
    },
    [navigate, trackSegmentAnalyticsEvent]
  );

  const total = parsedData.reduce((acc, next) => acc + next.value, 0);

  let errorBanner;

  if (showErrorBanner) {
    errorBanner = (
      <MissingDataBanner
        text="Couldn’t load managed resources data. Please try to refresh or come back later."
        refreshHandler={refetch}
        refreshLoading={loading}
        fullWidth
      />
    );
  }

  return (
    <DashboardResourcesChart
      logos={vendors}
      data={parsedData}
      align="center"
      hasNoResources={!data?.metrics?.resourcesCountByVendor.length}
      hasNoStacks={hasNoStacks}
      renderCentralInfo={(data) => (
        <DashboardResourcesChartCentralInfo
          count={data ? data.value : total}
          title={data?.label.toUpperCase()}
        />
      )}
      isLoading={isLoading}
      navigateToResource={navigateToResource}
      widget={Widget.ManagedResources}
      errorBanner={errorBanner}
    />
  );
};

export default DashboardWidgetsManagedResources;
