import { ReactNode } from "react";

import Box, { BoxProps } from "ds/components/Box";

type ModuleSettingsFormWrapperProps = {
  children: ReactNode;
  gap?: BoxProps["gap"];
};

const ModuleSettingsFormWrapper = ({ children, gap }: ModuleSettingsFormWrapperProps) => {
  return (
    <Box direction="column" gap={gap} padding="x-large" limitWidth="medium">
      {children}
    </Box>
  );
};

export default ModuleSettingsFormWrapper;
