import { NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { StackAwsIntegrationAttachment, StackAzureIntegrationAttachment } from "types/generated";

import { ModuleCreationCloud } from "../../types";
import { GET_ATTACHED_MODULE_INTEGRATIONS } from "../gql";
import { GetAttachedCloudIntegrationsGql } from "../types";

type AttachedIntegration =
  | {
      attachedIntegration: StackAwsIntegrationAttachment;
      attachedIntegrationType: ModuleCreationCloud.AWS;
    }
  | {
      attachedIntegration: StackAzureIntegrationAttachment;
      attachedIntegrationType: ModuleCreationCloud.Azure;
    }
  | {
      attachedIntegration: undefined;
      attachedIntegrationType: undefined;
    };

type UseAttachedIntegration = AttachedIntegration & {
  loading: boolean;
  refetching: boolean;
  hasData: boolean;
  refetch: () => void;
};

const useAttachedCloudIntegration = (moduleId: string | undefined): UseAttachedIntegration => {
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, networkStatus, refetch } = useQuery<GetAttachedCloudIntegrationsGql>(
    GET_ATTACHED_MODULE_INTEGRATIONS,
    {
      onError,
      variables: {
        moduleId,
      },
      skip: !moduleId,
      fetchPolicy: "no-cache",
    }
  );

  const integration = useMemo<AttachedIntegration>(() => {
    if (data?.module?.integrations.awsV2.length) {
      return {
        attachedIntegrationType: ModuleCreationCloud.AWS,
        attachedIntegration: data.module.integrations.awsV2[0],
      };
    }

    if (data?.module?.integrations.azure.length) {
      return {
        attachedIntegrationType: ModuleCreationCloud.Azure,
        attachedIntegration: data.module.integrations.azure[0],
      };
    }

    return { attachedIntegrationType: undefined, attachedIntegration: undefined };
  }, [data]);

  return {
    ...integration,
    loading: loading && networkStatus !== NetworkStatus.refetch,
    refetching: loading && networkStatus === NetworkStatus.refetch,
    refetch,
    hasData: !loading && !!data?.module,
  };
};

export default useAttachedCloudIntegration;
