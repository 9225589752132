import { Route, Routes } from "react-router-dom-v5-compat";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AwsIntegration } from "types/generated";
import { AccountContext } from "views/AccountWrapper";

import AwsIntegrationsList from "./List";
import AwsIntegrationView from "./View";
import AwsIntegrationEdit from "./Edit";

type AwsIntegrationsProps = {
  integrations: AwsIntegration[];
};

const AwsIntegrations = ({ integrations }: AwsIntegrationsProps) => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`AWS integrations · ${accountName}`);

  return (
    <Routes>
      <Route
        path=":integrationId/edit"
        element={<AwsIntegrationEdit integrations={integrations} />}
      />
      <Route path=":integrationId" element={<AwsIntegrationView integrations={integrations} />} />
      <Route index element={<AwsIntegrationsList integrations={integrations} />} />
    </Routes>
  );
};

export default AwsIntegrations;
