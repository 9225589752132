import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useEffect, useState } from "react";

type UseLoadStripe = {
  stripe: Stripe | null;
  error?: unknown;
};

const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "")
  : null;

export const useLoadStripe = (): UseLoadStripe => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [stripeError, setStripeError] = useState<unknown>(null);

  useEffect(() => {
    const initStripe = async () => {
      try {
        const stripe = await stripePromise;
        if (!stripe) throw new Error("Fail to load Stripe");

        setStripe(stripe);
      } catch (e: unknown) {
        setStripeError(e);
      }
    };

    initStripe();
  }, []);

  return { stripe, error: stripeError };
};
