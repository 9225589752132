import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";

type NewModuleSummaryValueListProps = {
  values: string[];
};

const NewModuleSummaryValueList = ({ values }: NewModuleSummaryValueListProps) => {
  return (
    <Box direction="column" gap="medium" zeroMinWidth>
      {values.map((value, index) => (
        <TextEllipsis key={index} tooltip={value} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} variant="p-body2" tag="span" align="right">
              {value}
            </Typography>
          )}
        </TextEllipsis>
      ))}
    </Box>
  );
};

export default NewModuleSummaryValueList;
