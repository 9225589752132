import { ReactNode } from "react";
import cx from "classnames";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

import Box from "ds/components/Box";
import DraggableItem from "ds/components/DraggableItem";
import { reorderMultipleDNDLists } from "utils/dnd";

import styles from "./styles.module.css";
import DragDropListItem from "./Item";

export enum ColumnMode {
  Single = "Single",
  Double = "Double",
}

export type Column<T = string> = Array<{
  value: T;
  hidden: boolean;
}>;

type Side = "left" | "right";

export type DragDropListProps<T extends string> = {
  columnMode: ColumnMode;
  children?: ReactNode;
  onChange: (props: { left: Column<T>; right: Column<T> }) => void;
  options: Record<
    T,
    {
      title: string;
      component: () => ReactNode;
      moreActions?: Array<{ title: string; link: string }>;
      infoTooltip?: string;
    }
  >;
  leftColumn: Column<T>;
  rightColumn: Column<T>;
  itemClassName?: string;
  emptyState?: JSX.Element;
};

const DragDropList = <T extends string>({
  onChange,
  columnMode,
  leftColumn,
  rightColumn,
  options,
  itemClassName,
  emptyState,
}: DragDropListProps<T>) => {
  const hideItem = (side: Side, id: T) => {
    const result = { left: leftColumn, right: rightColumn };
    if (side === "left") {
      result.left = result.left.map((item) =>
        item.value === id ? { ...item, hidden: true } : item
      );
    } else {
      result.right = result.right.map((item) =>
        item.value === id ? { ...item, hidden: true } : item
      );
    }

    onChange(result);
  };

  const sliceHiddenItems = (columnConfig: Column<T>) =>
    columnConfig.reduce(
      ({ visible, hidden }, item) => {
        const option = options[item.value];

        if (item.hidden || !option) {
          return {
            hidden: [...hidden, item],
            visible,
          };
        }

        return {
          hidden,
          visible: [...visible, item],
        };
      },
      { visible: [] as Column<T>, hidden: [] as Column<T> }
    );

  const { hidden: leftHidden, visible: leftVisible } = sliceHiddenItems(leftColumn);
  const { hidden: rightHidden, visible: rightVisible } = sliceHiddenItems(rightColumn);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { left, right } = reorderMultipleDNDLists(
      {
        left: [...leftVisible, ...leftHidden],
        right: [...rightVisible, ...rightHidden],
      },
      result.source.droppableId,
      result.destination.droppableId,
      result.source.index,
      result.destination.index
    );

    onChange({
      left,
      right,
    });
  };

  const getItems = (columnConfig: Column<T>, side: Side) =>
    columnConfig.map((item, index) => {
      const option = options[item.value];

      return (
        <Draggable draggableId={item.value} index={index} key={item.value}>
          {(provided, snapshot) => (
            <DraggableItem
              noBorder
              ref={provided.innerRef}
              size="small"
              margin="0 0 x-large 0"
              dragging={snapshot.isDragging}
              dropping={snapshot.isDropAnimating}
              className={cx(styles.draggable, itemClassName)}
              tabIndex={-1}
              {...provided.draggableProps}
            >
              <DragDropListItem
                dragging={snapshot.isDragging}
                dragHandleProps={provided.dragHandleProps}
                onHide={() => hideItem(side, item.value)}
                text={option.title}
                moreActions={option.moreActions}
                infoTooltip={option.infoTooltip}
              >
                {option.component()}
              </DragDropListItem>
            </DraggableItem>
          )}
        </Draggable>
      );
    });

  const leftColumnItems = getItems(leftVisible, "left");
  const rightColumnItems = getItems(rightVisible, "right");

  if (!leftColumnItems.length && !rightColumnItems.length && emptyState) {
    return emptyState;
  }

  return (
    // Key allows us to recalculate size of items (mostly applicable to charts)
    <DragDropContext onDragEnd={handleDragEnd} key={columnMode}>
      <Box className={cx(styles.container, columnMode == ColumnMode.Single && styles.singleColumn)}>
        <Droppable droppableId="left">
          {(droppableProvided) => (
            <Box
              className={styles.column}
              direction="column"
              ref={droppableProvided.innerRef}
              margin={
                columnMode === ColumnMode.Double ? "x-large medium x-large x-large" : "x-large"
              }
              {...droppableProvided.droppableProps}
            >
              {leftColumnItems}
              {!!leftColumnItems.length && droppableProvided.placeholder}
            </Box>
          )}
        </Droppable>

        {columnMode === ColumnMode.Double && (
          <Droppable droppableId="right">
            {(droppableProvided) => (
              <Box
                direction="column"
                ref={droppableProvided.innerRef}
                margin="x-large x-large x-large medium"
                {...droppableProvided.droppableProps}
              >
                {rightColumnItems}
                {!!rightColumnItems.length && droppableProvided.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </Box>
    </DragDropContext>
  );
};

export default DragDropList;
