import { ReactNode } from "react";

import Typography, { TypographyProps } from "ds/components/Typography";

type OutputReferencesTextWrapperProps = {
  children: ReactNode;
  transform?: TypographyProps["transform"];
};

const OutputReferencesTextWrapper = ({ children, transform }: OutputReferencesTextWrapperProps) => {
  return (
    <Typography tag="span" variant="p-body2" color="secondary" transform={transform}>
      {children}
    </Typography>
  );
};

export default OutputReferencesTextWrapper;
