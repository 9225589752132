import prettyBytes from "pretty-bytes";

import Typography from "ds/components/Typography";
import { ManagedStateVersion } from "types/generated";

type StackStateHistoryListItemImportedEventTitleProps = {
  item: ManagedStateVersion;
};

const StackStateHistoryListItemImportedEventTitle = ({
  item,
}: StackStateHistoryListItemImportedEventTitleProps) => {
  const { importedUsername, size } = item;
  return (
    <Typography tag="span" variant="p-body2">
      Manually imported by {importedUsername}. State file size: {prettyBytes(size)}
    </Typography>
  );
};

export default StackStateHistoryListItemImportedEventTitle;
