import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { VcsIntegration } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import useTestVCSIntegrationConnection from "../useTestVCSIntegrationConnection";
import { isTestIntegrationConnectionAvailable } from "../helpers";
import { showVCSDeleteIntegrationModal } from "../components/DeleteIntegrationModal";
import { VCS_INTEGRATIONS_PATH } from "../constants";
import useDeleteVcsIntegration from "../useDeleteVcsIntegration";

type VCSIntegrationDropdownActionsProps = {
  integration: VcsIntegration;
  onEdit: () => void;
  totalEntitiesCount: number;
};

const VCSIntegrationDropdownActions = ({
  integration,
  onEdit,
  totalEntitiesCount,
}: VCSIntegrationDropdownActionsProps) => {
  const navigate = useNavigate();

  const { testIntegrationConnection, loading } = useTestVCSIntegrationConnection({
    id: integration.id,
    name: integration.name || `Default ${HUMANIZE_PROVIDER[integration.provider]}`,
    provider: integration.provider,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const { deleteVcsIntegration } = useDeleteVcsIntegration();

  const isTestConnectionAvailable = isTestIntegrationConnectionAvailable(integration.details);

  const goToVcsIntegrations = useCallback(() => {
    navigate(VCS_INTEGRATIONS_PATH);
  }, [navigate]);

  const handleDeleteVcsIntegration = useCallback(() => {
    // FYI: the ID is not available only for the built-in GitHub integration & the delete option is not available for it
    if (integration.id) {
      deleteVcsIntegration(
        integration.id,
        integration.provider,
        totalEntitiesCount,
        goToVcsIntegrations
      );
    }
  }, [
    deleteVcsIntegration,
    goToVcsIntegrations,
    integration.id,
    integration.provider,
    totalEntitiesCount,
  ]);

  const onDeleteConfirmationModalShow = useCallback(() => {
    showVCSDeleteIntegrationModal({
      attachedEntitiesQty: totalEntitiesCount,
      integrationName: integration.name,
      onConfirm: handleDeleteVcsIntegration,
      onClose: () => trackSegmentAnalyticsEvent("Integration - Unlink Exit"),
    });

    trackSegmentAnalyticsEvent("Integration - Unlink Start");
  }, [
    handleDeleteVcsIntegration,
    integration.name,
    totalEntitiesCount,
    trackSegmentAnalyticsEvent,
  ]);

  return (
    <DropdownMenuEllipsis tooltip="Integration actions" dotsSize="medium">
      <CopyFieldDropdownMenuItem title="Copy ID" value={integration.id} />
      {isTestConnectionAvailable && (
        <DropdownMenuItem onAction={testIntegrationConnection} loading={loading}>
          Test
        </DropdownMenuItem>
      )}

      <DropdownMenuItem onAction={onEdit}>Edit</DropdownMenuItem>
      <DropdownMenuItem onAction={onDeleteConfirmationModalShow} danger>
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default VCSIntegrationDropdownActions;
