import useTypedContext from "hooks/useTypedContext";
import FormFieldViewText from "components/FormFields/ViewText";
import { StackConfigVendorAnsible } from "types/generated";
import { VENDOR_DICTIONARY } from "constants/vendor";

import { StackSettingsContextData } from "../../Context";
import { getPlaybookTooltip } from "./getTooltips";
import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";

const StackSettingsVendorAnsibleView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const config = stackSettings.vendorConfig as StackConfigVendorAnsible;

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.ANSIBLE} />
      <FormFieldViewText
        label="Playbook"
        value={config.playbook}
        {...getPlaybookTooltip()}
        noMargin
      />
    </>
  );
};

export default StackSettingsVendorAnsibleView;
