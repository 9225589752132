import EmptyState from "ds/components/EmptyState";
import { SpaceColored } from "components/icons";
import Box from "ds/components/Box";

const PersonalSettingsSpacesEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You are not added to any Spaces yet"
        icon={SpaceColored}
        caption="This is where your space access list will appear once your admin will add you to any of the
        spaces in your organization."
      />
    </Box>
  );
};

export default PersonalSettingsSpacesEmpty;
