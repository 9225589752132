import { useCallback, useEffect, useState } from "react";

import useTypedFlags from "hooks/useTypedFlags";

const DEFAULT_POOL_INTERVAL = 10000;

const usePollInterval = () => {
  const [isActive, setIsActive] = useState(true);

  const { dashboardRefreshIntervalSeconds } = useTypedFlags();

  const onVisibilityChange = useCallback(() => {
    setIsActive(!document.hidden);
  }, []);

  useEffect(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);

  if (!isActive) {
    return 0;
  }

  if (typeof dashboardRefreshIntervalSeconds === "number") {
    return dashboardRefreshIntervalSeconds * 1000;
  }

  return DEFAULT_POOL_INTERVAL;
};

export default usePollInterval;
