import { ReactNode, createContext, useContext, useState } from "react";

type HideOnScrollContextType = {
  scrollableElement?: HTMLElement | null;
  setScrollableElRef: (ref: HTMLElement | null) => void;
  isHideOnScrollContext: boolean;
};

const HideOnScrollContext = createContext<HideOnScrollContextType>({
  scrollableElement: undefined,
  setScrollableElRef: () => undefined,
  isHideOnScrollContext: false,
});

export const useHideOnScrollContext = () => useContext(HideOnScrollContext);

const HideOnScrollProvider = ({ children }: { children: ReactNode }) => {
  const [scrollableElement, setScrollableElement] = useState<HTMLElement | null>(null);

  return (
    <HideOnScrollContext.Provider
      value={{
        scrollableElement,
        setScrollableElRef: setScrollableElement,
        isHideOnScrollContext: true,
      }}
    >
      {children}
    </HideOnScrollContext.Provider>
  );
};

export default HideOnScrollProvider;
