import cx from "classnames";

import styles from "./styles.module.css";
import { BadgeProps } from "./types";
import Icon from "../Icon";
import TextEllipsis from "../TextEllipsis";
import Box from "../Box";

const BadgeNextNew = ({
  variant,
  className,
  endIcon,
  text,
  startIcon,
  textEllipsis,
  iconOnly,
  type,
}: BadgeProps) => {
  const classNames = cx(
    styles.badge,
    [styles[variant]],
    styles[type],
    {
      [styles.iconOnly]: iconOnly,
    },
    className
  );

  const textContent = textEllipsis ? (
    <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm" delay={400}>
      {(ellipsisProps) => <span {...ellipsisProps}>{text}</span>}
    </TextEllipsis>
  ) : (
    <span>{text}</span>
  );

  return (
    <Box className={classNames}>
      {startIcon && <Icon src={startIcon} className={styles.startIcon} />}
      {!iconOnly && textContent}
      {endIcon && <Icon src={endIcon} className={styles.endIcon} />}
    </Box>
  );
};

export default BadgeNextNew;
