import { Run, RunType, Stack } from "types/generated";
import Box from "ds/components/Box";
import Callout from "ds/components/Callout";
import Link from "ds/components/Link";

import RunStateBadge from "../StateBadge";

type BlockerInfoProps = {
  stack: Stack;
  run: Run;
};

const BlockerInfo = (props: BlockerInfoProps) => {
  const { run, stack } = props;

  if (!stack.blocker) return null;

  if (stack.blocker.id === run.id) {
    const typeRunCopy = run.type === RunType.Task ? "task" : "run";

    return (
      <Callout variant="warning">
        This {typeRunCopy} is blocking the stack. This means that no other blocking run (tracked or
        task) can start until this run is completed.
      </Callout>
    );
  }

  return (
    <Callout variant="warning">
      <Box gap="small" align="center">
        Blocked by
        <Link to={`/stack/${stack.id}/run/${stack.blocker.id}`} size="small">
          {stack.blocker.id}
        </Link>
        <RunStateBadge state={stack.blocker.state} />
      </Box>
    </Callout>
  );
};

export default BlockerInfo;
