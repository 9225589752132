import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { VcsIntegration, VcsProvider } from "types/generated";
import { SelectOption } from "ds/components/Select/types";
import { VCS_INTEGRATION_DETAILS_UNION } from "constants/vcs_providers";
import { AccountContext } from "views/AccountWrapper";

import { QUERY_ATTACHABLE_VCS_INTEGRATIONS } from "./gql";
import { AttachableVCSIntegrationsOption, SelectedVcsIntegration } from "./types";

type UseQueryAttachableVCSIntegrationsOptionsProps = {
  provider: VcsProvider;
  spaceId: string;
  onSelect: (vcsIntegration: SelectedVcsIntegration) => void;
  initialValue?: string;
  editMode?: boolean;
};

const useQueryAttachableVCSIntegrationsOptions = ({
  provider,
  spaceId,
  onSelect,
  initialValue,
  editMode,
}: UseQueryAttachableVCSIntegrationsOptionsProps) => {
  const { onError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const { data, loading } = useQuery<{
    attachableVCSIntegrationsForProviderInSpace: VcsIntegration[];
  }>(QUERY_ATTACHABLE_VCS_INTEGRATIONS, {
    variables: {
      spaceId,
      provider,
    },
    onError,
    fetchPolicy: "no-cache",
    onCompleted: ({ attachableVCSIntegrationsForProviderInSpace: integrations }) => {
      const initialValueInList = integrations.find((item) => item?.id === initialValue);

      if (!editMode) {
        if (initialValue && initialValueInList) {
          onSelect({
            id: initialValueInList.id,
            name: initialValueInList.name,
          });
        } else if (integrations?.[0]) {
          onSelect({
            id: integrations[0].id,
            name: integrations[0].name,
          });
        }
      }
    },
  });

  const options = useMemo<(AttachableVCSIntegrationsOption | SelectOption)[]>(() => {
    if (provider === VcsProvider.GithubEnterprise) {
      return (data?.attachableVCSIntegrationsForProviderInSpace || []).map((item) => {
        if (
          viewer.admin &&
          item.details?.__typename === VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION
        ) {
          return {
            label: item.name,
            value: item.id,
            hasInstallations: item.details.installations?.length > 0,
          };
        }

        return {
          label: item.name,
          value: item.id,
        };
      });
    }

    return (data?.attachableVCSIntegrationsForProviderInSpace || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [data?.attachableVCSIntegrationsForProviderInSpace, provider, viewer.admin]);

  return {
    options,
    loading,
  };
};

export default useQueryAttachableVCSIntegrationsOptions;
