import { Stack } from "types/generated";
import { Expand } from "components/icons";
import { generateBranchUrl } from "utils/urls";
import LinkNew from "ds/components/LinkNew";

import useStackListAnalytics from "../useStackListAnalytics";

type StackListItemBranchCellProps = { stack: Stack };

const StackListItemBranchCell = ({ stack }: StackListItemBranchCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  if (!stack.apiHost) {
    return null;
  }

  return (
    <LinkNew
      size="small"
      target="_blank"
      href={generateBranchUrl({
        apiHost: stack.apiHost,
        namespace: stack.namespace,
        repository: stack.repository,
        repositoryURL: stack.repositoryURL || "",
        provider: stack.provider,
        branch: stack.branch,
      })}
      onPress={() => trackAnalytics("Branch link clicked")}
      endIcon={Expand}
    >
      {stack.branch}
    </LinkNew>
  );
};

export default StackListItemBranchCell;
