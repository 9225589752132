import { ReactNode } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import { IconComponent } from "types/Icon";
import { CrossNew } from "components/icons";

import { FeedbackType, FeedbackVariant } from "./types";
import Typography from "../Typography";
import FeedbackHeadline from "./Headline";
import useCloseFeedback, { UseCloseFeedbackProps } from "./useCloseFeedback";
import FeedbackWrapper from "./Wrapper";
import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";
import ToggleField from "../Form/ToggleField";

export type FeedbackProps = {
  variant: FeedbackVariant;
  type: FeedbackType;
  className?: string;
  fullWidth?: boolean;
  closeCallback?: UseCloseFeedbackProps["closeCallback"];
  title?: ReactNode;
  children?: ReactNode;
  icon?: IconComponent;
  margin?: BoxProps["margin"];
  storageKey?: string;
  permanentCloseConfirmationTooltipInfo?: ReactNode;
  withPermanentCloseConfirmation?: boolean;
};

const Feedback = ({
  variant,
  type = "callout",
  className,
  fullWidth,
  closeCallback,
  title,
  children,
  icon,
  margin,
  storageKey,
  permanentCloseConfirmationTooltipInfo,
  withPermanentCloseConfirmation,
}: FeedbackProps) => {
  const { isHidden, handleClose, saveToLocalStorage, toggleSaveToLocalStorage } = useCloseFeedback({
    initialSaveToLocalStorage: !withPermanentCloseConfirmation,
    storageKey,
    closeCallback,
  });

  if (isHidden) {
    return null;
  }

  return (
    <FeedbackWrapper
      variant={variant}
      type={type}
      className={className}
      fullWidth={fullWidth}
      suffix={
        storageKey ? (
          <Box className={styles.closeWrapper} gap="large">
            {withPermanentCloseConfirmation && (
              <ToggleField
                variant="checkbox"
                checked={saveToLocalStorage}
                onChange={toggleSaveToLocalStorage}
                tooltipInfo={permanentCloseConfirmationTooltipInfo}
                title="Don't show again"
              />
            )}
            <ButtonIcon icon={CrossNew} onClick={handleClose} variant="ghost">
              Close
            </ButtonIcon>
          </Box>
        ) : undefined
      }
      margin={margin}
      icon={icon}
    >
      {title && <FeedbackHeadline>{title}</FeedbackHeadline>}

      <Typography tag="div" variant="p-body3">
        {children}
      </Typography>
    </FeedbackWrapper>
  );
};

export default Feedback;
