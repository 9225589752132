import { gql } from "@apollo/client";

export const CREATE_STACK_SCHEDULING_SCHEDULED_DELETE = gql`
  mutation CreateStackSchedulingScheduledDelete($stack: ID!, $input: ScheduledDeleteInput!) {
    stackScheduledDeleteCreate(stack: $stack, input: $input) {
      id
    }
  }
`;

export const UPDATE_STACK_SCHEDULING_SCHEDULED_DELETE = gql`
  mutation UpdateStackSchedulingScheduledDelete(
    $scheduleId: ID!
    $stack: ID!
    $input: ScheduledDeleteInput!
  ) {
    stackScheduledDeleteUpdate(scheduledDelete: $scheduleId, stack: $stack, input: $input) {
      id
    }
  }
`;

export const REMOVE_STACK_SCHEDULING_SCHEDULED_DELETE = gql`
  mutation RemoveStackSchedulingScheduledDelete($scheduleId: ID!, $stack: ID!) {
    stackScheduledDeleteDelete(scheduledDelete: $scheduleId, stack: $stack) {
      id
    }
  }
`;
