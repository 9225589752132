import { gql } from "@apollo/client";

export const GET_CONTEXT = gql`
  query GetContext($contextId: ID!) {
    context(id: $contextId) {
      id
      hasAttachedStacks
      attachedStacks {
        createdAt
        id
        isAutoattached
        isModule
        stackId
        stackName
        stackSpaceDetails {
          id
          name
        }
      }
      createdAt
      description
      name
      labels
      hooks {
        afterApply
        afterDestroy
        afterInit
        afterPerform
        afterPlan
        afterRun
        beforeApply
        beforeDestroy
        beforeInit
        beforePerform
        beforePlan
      }
      spaceDetails {
        id
        name
        accessLevel
      }
      config {
        createdAt
        id
        type
        value
        writeOnly
        description
      }
      updatedAt
    }
  }
`;

export const DELETE_CONTEXT_CONFIG = gql`
  mutation DeleteContextConfig($contextId: ID!, $configId: ID!) {
    contextConfigDelete(context: $contextId, id: $configId) {
      id
      type
    }
  }
`;

// TODO: remove New when legacy mutation removed
export const DETACH_CONTEXT = gql`
  mutation NewDetachContext($id: ID!) {
    contextDetach(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONTEXT = gql`
  mutation UpdateContextHooks($id: ID!, $input: ContextInput!) {
    contextUpdateV2(id: $id, input: $input) {
      id
      name
    }
  }
`;
