import useTypedContext from "hooks/useTypedContext";

import StackSettingsFormWrapper from "../components/FormWrapper";
import StackSettingsPageLayout from "../components/PageLayout";
import StackSettingsDetailsEdit from "./Edit";
import StackSettingsDetailsView from "./View";
import { StackSettingsContextData } from "../Context";

const StackSettingsDetails = () => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return (
    <StackSettingsPageLayout
      title="Stack details"
      description="Name your stack, choose the space that it will belong to and add labels for improved organization and filtering."
    >
      <StackSettingsFormWrapper>
        {canManageStack ? <StackSettingsDetailsEdit /> : <StackSettingsDetailsView />}
      </StackSettingsFormWrapper>
    </StackSettingsPageLayout>
  );
};

export default StackSettingsDetails;
