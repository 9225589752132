import { GitCommit, Profile, Gears } from "components/icons";
import Icon from "ds/components/Icon";

type RunTriggerCommitProps = {
  triggeredBy?: string;
  className?: string;
};

const RunTriggerCommit = ({ triggeredBy, className }: RunTriggerCommitProps) => {
  let iconSrc = Profile;
  if (!triggeredBy) {
    iconSrc = GitCommit;
  }

  if (triggeredBy?.startsWith("policy/")) {
    iconSrc = Gears;
  }

  if (triggeredBy?.startsWith("reconciliation/")) {
    iconSrc = Gears;
  }

  return <Icon src={iconSrc} className={className} />;
};

export default RunTriggerCommit;
