import { gql } from "@apollo/client";

export const CREATE_POLICY = gql`
  mutation CreateLoginPolicy(
    $name: String!
    $body: String!
    $type: PolicyType!
    $labels: [String!]
    $space: ID
  ) {
    policyCreate(name: $name, body: $body, type: $type, labels: $labels, space: $space) {
      id
      name
      labels
      space
    }
  }
`;

export const GET_ACCOUNT_AUTH_SCHEME = gql`
  query GetAccountAuthScheme {
    authorizationScheme
  }
`;
