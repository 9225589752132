import Icon from "ds/components/Icon";
import { ArrowThin } from "components/icons";
import { EntityChangeType } from "types/generated";

import { CodeChangesValueNode } from "../types";
import styles from "../styles.module.css";
import ValueType from "./ValueType";

type ValueProps = {
  item: CodeChangesValueNode;
};

const Value = ({ item }: ValueProps) => {
  switch (item.type) {
    case EntityChangeType.Change:
    case EntityChangeType.ReplaceCreateBeforeDestroy:
    case EntityChangeType.ReplaceDestroyBeforeCreate:
      return (
        <>
          <ValueType>{item.before}</ValueType>
          {/* TODO: Ask UX/UI for a better arrow icon */}
          <Icon className={styles.codeChangesArrow} src={ArrowThin} rotate={"90"} />
          <ValueType>{item.after}</ValueType>
        </>
      );
    case EntityChangeType.Delete:
      return <ValueType>{item.before}</ValueType>;
    case EntityChangeType.Noop:
    case EntityChangeType.Add:
      return <ValueType>{item.after}</ValueType>;
    default:
      return <span>Unknown change type</span>;
  }
};

export default Value;
