import { ChangeEvent } from "react";

import { UseAutocompleteProps } from "./types";

const useAutocomplete = ({ onChange, onEnterKey, onQueryClear }: UseAutocompleteProps) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const clearQuery = () => {
    onQueryClear?.();
    onChange("");
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (onEnterKey && e.key === "Enter") {
      onEnterKey();
    }
  };

  return { handleOnChange, handleOnKeyDown, clearQuery };
};

export default useAutocomplete;
