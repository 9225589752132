import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

export type LabelStyle = {
  long?: boolean;
  muted?: boolean;
  medium?: boolean;
};

type FormLabelProps = {
  id?: string;
  text: string;
  icon?: JSX.Element;
  styleProps?: LabelStyle;
  className?: string;
  children?: React.ReactNode;
};

const FormLabel = (props: FormLabelProps) => {
  const { icon, id, styleProps, text, children, className } = props;
  const labelClass = cx(
    {
      [styles.label]: true,
      [styles.long]: styleProps?.long,
      [styles.muted]: styleProps?.muted,
      [styles.medium]: styleProps?.medium,
    },
    className
  );

  return (
    <label htmlFor={id} className={labelClass}>
      {children}
      {icon}
      {text}
    </label>
  );
};

export default FormLabel;
