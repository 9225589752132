import FormField, { FormFieldProps } from "ds/components/Form/Field";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography, { TypographyProps } from "ds/components/Typography";

import FormFieldViewTextCopyWrapper from "./CopyWrapper";

export type FormFieldViewTextProps = {
  value: string | undefined | null;
  label?: FormFieldProps["label"];
  tooltipInfo?: FormFieldProps["tooltipInfo"];
  tooltipInfoVariant?: FormFieldProps["tooltipInfoVariant"];
  withCopy?: boolean;
  emptyPlaceholder?: string;
  noMargin?: boolean;
  noTextEllipsis?: boolean;
  textTransform?: TypographyProps["transform"];
  textWordBreak?: TypographyProps["wordBreak"];
};

const FormFieldViewText = ({
  value,
  label,
  tooltipInfo,
  tooltipInfoVariant,
  noMargin = false,
  withCopy = false,
  noTextEllipsis = false,
  emptyPlaceholder = "None",
  textTransform,
  textWordBreak,
}: FormFieldViewTextProps) => {
  const isEmpty = !value;

  let viewTextContent;

  const sharedProps = {
    variant: "p-body2",
    transform: textTransform,
    wordBreak: textWordBreak,
    tag: "span",
  } as TypographyProps;

  if (isEmpty) {
    viewTextContent = (
      <Typography {...sharedProps} color="disabled">
        {emptyPlaceholder}
      </Typography>
    );
  } else if (noTextEllipsis) {
    viewTextContent = <Typography {...sharedProps}>{value}</Typography>;
  } else {
    viewTextContent = (
      <TextEllipsis tooltip={value} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Typography {...props} {...sharedProps}>
            {value}
          </Typography>
        )}
      </TextEllipsis>
    );
  }

  return (
    <FormField
      label={label}
      tooltipInfo={tooltipInfo}
      tooltipInfoVariant={tooltipInfoVariant}
      noMargin={noMargin}
    >
      {withCopy && !isEmpty ? (
        <FormFieldViewTextCopyWrapper value={value}>{viewTextContent}</FormFieldViewTextCopyWrapper>
      ) : (
        viewTextContent
      )}
    </FormField>
  );
};

export default FormFieldViewText;
