import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  ManagedUserGroupIntegration,
  ManagedUserGroupIntegrationCreateInput,
} from "types/generated";

import { UPDATE_MANAGED_USER_GROUP_INTEGRATION } from "../gql";

const useUpdateGroupIntegration = () => {
  const { onError } = useTypedContext(FlashContext);

  const [updateManagedUserGroupIntegration] = useMutation<{
    managedUserGroupIntegrationUpdate: ManagedUserGroupIntegration;
  }>(UPDATE_MANAGED_USER_GROUP_INTEGRATION, {
    onError,
    refetchQueries: ["GetManagedUserGroupIntegrations"],
  });

  const onUpdate = (input: ManagedUserGroupIntegrationCreateInput) => {
    return updateManagedUserGroupIntegration({
      variables: {
        input: input,
      },
    });
  };

  return { onUpdate };
};

export default useUpdateGroupIntegration;
