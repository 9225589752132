import { gql } from "@apollo/client";

export const GET_PUBLIC_WORKER_POOL = gql`
  query GetPublicWorkerPool {
    publicWorkerPool {
      parallelism
      busyWorkers
      pendingRuns
    }
  }
`;
