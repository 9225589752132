import { z } from "zod";

import { Diff } from "./types";

const DEFAULT_RESULT = {};

export const parseDiff = (diff: string | undefined): Diff => {
  if (!diff) {
    return DEFAULT_RESULT;
  }

  try {
    const parsedResult = JSON.parse(diff);

    const result = z
      .record(
        z.string(),
        z.object({
          before: z.union([z.string(), z.record(z.string(), z.unknown())]),
          after: z.union([z.string(), z.record(z.string(), z.unknown())]),
        })
      )
      .catch(DEFAULT_RESULT)
      .parse(parsedResult);

    return result;
  } catch {
    return DEFAULT_RESULT;
  }
};
