import { gql, useMutation } from "@apollo/client";

import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import { LogoSlackColored } from "components/icons";
import setLocation from "shared/setLocation";
import { Maybe, OAuthRedirect, SlackIntegration } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Tile from "ds/components/Tile";
import TileDescription from "ds/components/Tile/Description";
import { isSaasDistribution } from "utils/distribution";

const isSaas = isSaasDistribution();

const GET_SLACK_REDIRECT = gql`
  mutation slackOauthRedirect {
    slackOauthRedirect {
      providerUrl
      temporaryToken
    }
  }
`;

type IntegrationsSlackProps = {
  integration?: Maybe<SlackIntegration>;
};

const SLACK_COOKIE_KEY = "slack-jwt";

const IntegrationsSlack = ({ integration }: IntegrationsSlackProps) => {
  const { onError } = useTypedContext(FlashContext);

  const [slackRedirect, { loading }] = useMutation<{ slackOauthRedirect: OAuthRedirect }>(
    GET_SLACK_REDIRECT
  );

  const onAddIntegration = () => {
    slackRedirect()
      .then(({ data }) => {
        const { host } = window.location;
        const date = new Date();

        let domain = host;
        if (isSaas) {
          domain = host.substring(host.indexOf(".") + 1);
        }

        if (data) {
          const {
            slackOauthRedirect: { providerUrl, temporaryToken },
          } = data;

          date.setTime(date.getTime() + 60 * 1000);

          document.cookie = `${SLACK_COOKIE_KEY}=${temporaryToken};expires=${date.toUTCString()};domain=${domain};path=/`;

          setLocation(providerUrl);
        }
      })
      .catch(onError);
  };

  return (
    <Tile
      title="Slack"
      icon={LogoSlackColored}
      indicator={
        integration ? (
          <Button variant="secondary" size="small" to="/settings/integrations/manage-slack-access">
            Manage Access
          </Button>
        ) : (
          <Button variant="secondary" size="small" onClick={onAddIntegration} loading={loading}>
            Connect
          </Button>
        )
      }
      description={
        integration ? (
          <TileDescription>
            Spacelift is already integrated with {` `}
            <strong>{`${integration.teamName}`}</strong> workspace. You can disable integration by
            removing Spacelift app from your Slack workspace.
          </TileDescription>
        ) : (
          "Many tech companies use Slack for internal communication. As a Spacelift and Slack admin, you can link your Spacelift account to the Slack workspace"
        )
      }
    />
  );
};

export default IntegrationsSlack;
