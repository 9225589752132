import { gql } from "@apollo/client";

export const GET_RUN_CHANGES = gql`
  query GetRunChangesNext($stackId: ID!, $runId: ID!, $input: SearchInput!) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        changesV3(input: {}) {
          resources {
            address
            metadata {
              type
            }
          }
        }
        searchEntityChanges(input: $input) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              address
              previousAddress
              metadata {
                result
                type
                moved
              }
              attributeChanges {
                path
                changeForPhase {
                  metadata {
                    phase
                    type
                  }
                  before {
                    ... on ChangeValueBoolean {
                      boolVal: value
                    }
                    ... on ChangeValueString {
                      stringVal: value
                    }
                    ... on ChangeValueFloat {
                      floatVal: value
                    }
                  }
                  after {
                    ... on ChangeValueBoolean {
                      boolVal: value
                    }
                    ... on ChangeValueString {
                      stringVal: value
                    }
                    ... on ChangeValueFloat {
                      floatVal: value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RUN_CHANGES_SUGGESTIONS = gql`
  query GetRunChangesSuggestions($stackId: ID!, $runId: ID!, $input: SearchSuggestionsInput!) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        searchEntityChangesSuggestions(input: $input) {
          filteredCount
          fields {
            name
            orderable
            filterable
            type
            possibleValuesBoolean {
              values
              counts
            }
            possibleValuesEnum {
              values
              counts
            }
            possibleValuesString {
              values
              counts
            }
            possibleValuesHierarchy {
              values {
                id
                parentId
                displayValue
              }
              counts
            }
          }
        }
      }
    }
  }
`;

export const RUN_TARGETED_REPLAN = gql`
  mutation Mutate($runId: ID!, $stackId: ID!, $targets: [String!]!) {
    runTargetedReplan(run: $runId, stack: $stackId, targets: $targets) {
      id
    }
  }
`;
