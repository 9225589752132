import { gql } from "@apollo/client";

export const GET_VCS_AGENT_POOLS = gql`
  query GetVCSAgentPools {
    vcsAgentPools {
      id
      name
      description
      config
      deleted
      agentConnections {
        id
        metadata
      }
    }
  }
`;
