import useTypedContext from "hooks/useTypedContext";
import Stepper from "components/Stepper";
import StepperStep from "components/Stepper/Step";
import Typography from "ds/components/Typography";

import { ContextFormContext } from "../context";
import { contextCreationWizardNavSteps } from "../constants";

const NewContextStepper = () => {
  const { currentStep, setCurrentStep } = useTypedContext(ContextFormContext);

  return (
    <Stepper>
      <Typography tag="h2" variant="p-t7" color="secondary" transform="uppercase">
        Follow the steps
      </Typography>
      {contextCreationWizardNavSteps.map((item, index) => (
        <StepperStep
          key={item.step}
          active={item.step === currentStep}
          onClick={index < currentStep ? () => setCurrentStep(item.step) : undefined}
          disabled={index > currentStep}
        >
          {item.name}
        </StepperStep>
      ))}
    </Stepper>
  );
};

export default NewContextStepper;
