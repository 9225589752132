import { useCallback, useState } from "react";

export const useVisibilityToggle = (initialValue: boolean, enabled = true) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const isDirty = isOpen !== undefined;

  const toggle = useCallback(
    () => setIsOpen(isDirty ? !isOpen : !initialValue),
    [isDirty, isOpen, initialValue]
  );

  return enabled
    ? ([isDirty ? isOpen : initialValue, toggle] as const)
    : ([false, undefined] as const);
};
