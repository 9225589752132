import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";

import { EvaluationRecordOutcomeStatus } from "./types";

type EvaluationRecordOutcomeConfig = {
  variant: BadgeVariants;
  text: string;
};

export const EVALUATION_RECORD_OUTCOMES: Record<
  EvaluationRecordOutcomeStatus,
  EvaluationRecordOutcomeConfig
> = {
  [EvaluationRecordOutcomeStatus.Error]: { variant: "red", text: "Error" },
  [EvaluationRecordOutcomeStatus.Idle]: { variant: "blue", text: "Idle" },
  [EvaluationRecordOutcomeStatus.Info]: { variant: "blue", text: "Info" },
  [EvaluationRecordOutcomeStatus.Successful]: { variant: "green", text: "Successful" },
  [EvaluationRecordOutcomeStatus.Warning]: { variant: "yellow", text: "Warning" },
};

type EvaluationRecordStatusBadgeProps = {
  status: EvaluationRecordOutcomeStatus;
  className?: string;
  text: string;
};

const EvaluationRecordStatusBadge = ({
  status,
  className,
  text,
}: EvaluationRecordStatusBadgeProps) => {
  return (
    <BadgeNext
      variant={EVALUATION_RECORD_OUTCOMES[status].variant}
      type="strong"
      className={className}
      text={text}
    />
  );
};

export default EvaluationRecordStatusBadge;
