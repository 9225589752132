import moment from "moment";

import { pluralize } from "shared/Pluralize";
import { getNowTime } from "utils/time";

type SettingsAccountDeleteWarning = {
  accountCanBeDeleted: boolean;
  accountCanBeDeletedAt: number;
};

const SettingsAccountDeleteWarning = ({
  accountCanBeDeleted,
  accountCanBeDeletedAt,
}: SettingsAccountDeleteWarning) => {
  const deletionTime = moment.unix(accountCanBeDeletedAt);
  const deletionPeriod = deletionTime.diff(getNowTime(), "days");

  return (
    <>
      {accountCanBeDeleted && (
        <>
          <p>If you want to delete your account, please use the red button above.</p>
          <p>
            Once you delete your account, <u>all your Spacelift data will be deleted</u>!
          </p>
          <p>
            Please be aware that deleting your Spacelift account will not destroy any non-Spacelift
            resources managed by any of your stacks. Also, note that Terraform states managed by
            Spacelift will be removed immediately. You may want to delete your stacks individually
            before proceeding any further.
          </p>
        </>
      )}
      {!accountCanBeDeleted && (
        <>
          <p>
            <u>This account has been marked for deletion.</u> {` `}
            {`You will be able to delete it permanently in ${deletionPeriod} ${pluralize(
              "day",
              deletionPeriod
            )}.`}
          </p>
          <p>
            If this was an accident, or you have since changed your mind, you can unmark the account
            and resume business as usual.
          </p>
        </>
      )}
    </>
  );
};

export default SettingsAccountDeleteWarning;
