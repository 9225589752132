import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { GithubEnterpriseIntegration, Maybe } from "types/generated";

import { CHECK_GITHUB_ENTERPRISE_HAS_DEFAULT_INTEGRATION } from "./gql";

const useCheckGitHubEnterpriseHasDefaultIntegration = (skip: boolean) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{
    checkGitHubEnterpriseHasDefaultIntegration: Maybe<Pick<GithubEnterpriseIntegration, "id">>;
  }>(CHECK_GITHUB_ENTERPRISE_HAS_DEFAULT_INTEGRATION, {
    onError,
    fetchPolicy: "no-cache",
    skip,
  });

  return {
    hasDefaultIntegration:
      typeof data?.checkGitHubEnterpriseHasDefaultIntegration?.id !== "undefined",
    loading,
  };
};

export default useCheckGitHubEnterpriseHasDefaultIntegration;
