import { createContext } from "react";

import { SefHostedFlags } from "views/flags";

type SelfHostedFeatureFlagsContextData = {
  flags: SefHostedFlags;
};

export const SelfHostedFeatureFlagsContext = createContext<
  SelfHostedFeatureFlagsContextData | undefined
>(undefined);
SelfHostedFeatureFlagsContext.displayName = "SelfHostedFeatureFlagsContext";
