import { gql } from "@apollo/client";

export const CREATE_VCS_AGENT_POOL = gql`
  mutation CreateVCSAgentPool($name: String!, $description: String) {
    vcsAgentPoolCreate(name: $name, description: $description) {
      id
      name
      config
    }
  }
`;
