import { Pages } from "./types";

export const enum AnalyticsPageBlueprints {
  BlueprintsList = "BlueprintsList",
}

const blueprints = "Blueprints";

export const pagesBlueprints: Pages<AnalyticsPageBlueprints> = {
  [AnalyticsPageBlueprints.BlueprintsList]: [blueprints, "Blueprints list"],
};
