import InfiniteLoader from "react-window-infinite-loader";
import { useCallback, useState } from "react";

import NotFoundPage from "components/error/NotFoundPage";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import useErrorHandle from "hooks/useErrorHandle";
import ListEntitiesNew from "components/ListEntitiesNew";
import { isSaasDistribution } from "utils/distribution";
import { WorkerPool } from "types/generated";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import EmptyState from "ds/components/EmptyState";
import { NoResultsColored } from "components/icons";

import WorkerPoolVirtualizedListItem from "./ListItem/Virtualized";
import WorkerPoolsEmpty from "./Empty";
import WorkerPoolsFiltersLayout from "./FiltersLayout";
import WorkerPoolsPageLayout from "./PageLayout";
import useSearchWorkerPools from "./useSearchWorkerPools";
import { ITEMS_LIMIT } from "./constants";
import CreateWorkerPool from "./CreateWorkerPool";
import PublicWorkerPoolListItem from "./ListItem/PublicWorkerPoolListItem";

const isSaas = isSaasDistribution();

const WorkerPools = () => {
  const [isCreateDrawerVisible, setCreateDrawerVisibility] = useState(false);
  const [focusedWorkerPool, setFocusedWorkerPool] = useState<WorkerPool | undefined>(undefined);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);

  const {
    publicWorkerPool,
    entities,
    isPageEmpty,
    isPageLoading,
    notFound,
    error,
    stopPolling,
    loadMoreItems,
    hasNextPage,
    isSearchActive,
  } = useSearchWorkerPools();

  const handleCreateWorkerPool = () => {
    setFocusedWorkerPool(undefined);
    setCreateDrawerVisibility(true);
  };

  const handleEditWorkerPool = useCallback(
    (workerPool: WorkerPool) => {
      setFocusedWorkerPool(workerPool);
      setCreateDrawerVisibility(true);
    },
    [setFocusedWorkerPool, setCreateDrawerVisibility]
  );

  const handleOpenFullDescriptionDrawer = (workerPool: WorkerPool) => {
    setFocusedWorkerPool(workerPool);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedWorkerPool(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const isItemLoaded = (value: number) => !hasNextPage || value < entities.length;

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading) {
    return (
      <WorkerPoolsPageLayout>
        <PageLayoutSkeleton />
      </WorkerPoolsPageLayout>
    );
  }

  if (notFound) {
    return <NotFoundPage />;
  }

  return (
    <WorkerPoolsPageLayout onCreate={handleCreateWorkerPool}>
      <WorkerPoolsFiltersLayout />

      <CreateWorkerPool
        workerPool={focusedWorkerPool}
        isDrawerVisible={isCreateDrawerVisible}
        setDrawerVisibility={setCreateDrawerVisibility}
        refetchQuery="SearchWorkerPools"
      />

      {isSaas && publicWorkerPool && (
        <PublicWorkerPoolListItem publicWorkerPool={publicWorkerPool} />
      )}

      {isPageEmpty && !isSearchActive && <WorkerPoolsEmpty />}
      {isPageEmpty && isSearchActive && <EmptyState title="No results" icon={NoResultsColored} />}

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={focusedWorkerPool?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />

      {!isPageEmpty && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={hasNextPage ? entities.length + ITEMS_LIMIT : entities.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <ListEntitiesNew
              itemCount={entities.length}
              itemProps={{
                items: entities,
                onEdit: handleEditWorkerPool,
                onShowFullDescription: handleOpenFullDescriptionDrawer,
                focusedWorkerPoolId: focusedWorkerPool?.id,
              }}
              virtualizedItem={WorkerPoolVirtualizedListItem}
              itemKey={(index) => entities[index].id}
              onItemsRendered={onItemsRendered}
            />
          )}
        </InfiniteLoader>
      )}
    </WorkerPoolsPageLayout>
  );
};

export default WorkerPools;
