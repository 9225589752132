import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Notification } from "types/generated";

import NotificationListItem, { NotificationListItemProps } from ".";

type NotificationVirtualizedListItemProps = {
  items: Notification[];
  onCheckItem: NotificationListItemProps["onCheckItem"];
  selectedSet: Set<string>;
};

const NotificationVirtualizedListItem = (
  props: ListChildComponentProps<NotificationVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <NotificationListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onCheckItem={data.onCheckItem}
        checked={data.selectedSet.has(data.items[index].id)}
      />
    </div>
  );
};

export default memo(NotificationVirtualizedListItem, areEqual);
