import { stackValidator } from "utils/formValidators";
import { TerraformWorkflowTool } from "types/generated";

import { StackCreationVersion, StackCreationVersionType, StackVendorFormFields } from "../../types";

export const getValidateToolVersion =
  (toolVersions?: string[]) =>
  (version: StackCreationVersion, formValues: StackVendorFormFields) => {
    if (!toolVersions || !version.value.trim()) {
      return "Version is required";
    }

    const sanitizationError = stackValidator.validateUseSmartSanitizationField(
      formValues.terraform.useSmartSanitization,
      version.value,
      toolVersions,
      formValues.terraform.workflowTool
    );

    let rangeError;
    if (formValues.terraform.version.type === StackCreationVersionType.Range) {
      const vendor =
        formValues.terraform.workflowTool === TerraformWorkflowTool.OpenTofu
          ? "OpenTofu"
          : "Terraform";

      rangeError = stackValidator.validateVersionRange(version.value, toolVersions, vendor);
    }

    return sanitizationError || rangeError || true;
  };
