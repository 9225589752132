import { useState } from "react";

import Select from "components/select/Select";
import { ConfigInput, ConfigType } from "types/generated";

import NewEnvironmentVariable from "./NewEnvironmentVariable";
import NewFileMount from "./NewFileMount";
import styles from "../styles.module.css";

type ConfigOption = {
  value: ConfigType;
  label: string;
};

const options: ConfigOption[] = [
  { value: ConfigType.EnvironmentVariable, label: "Environment variable" },
  { value: ConfigType.FileMount, label: "Mounted file" },
];

type NewConfigElementProps = {
  loading: boolean;
  onSave: (config: ConfigInput, reset: () => unknown) => unknown;
};

const NewConfigElement = ({ loading, onSave }: NewConfigElementProps) => {
  const [type, setType] = useState(ConfigType.EnvironmentVariable);

  const onSelectType = ({ value }: ConfigOption) => setType(value);

  const onSaveWithType = (element: ConfigInput, reset: () => unknown) => {
    onSave(element, reset);
  };

  return (
    <div className={styles.newConfig}>
      <Select
        className={styles.newConfigInput}
        value={options.find(({ value }) => value === type)}
        options={options}
        onChange={onSelectType}
      />
      {type === ConfigType.EnvironmentVariable && (
        <NewEnvironmentVariable loading={loading} onSave={onSaveWithType} />
      )}
      {type === ConfigType.FileMount && <NewFileMount loading={loading} onSave={onSaveWithType} />}
    </div>
  );
};

export default NewConfigElement;
