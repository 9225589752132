import { Pages } from "./types";

export const enum AnalyticsPageModule {
  ModuleNew = "ModuleNew",
  ModulePolicies = "ModulePolicies",
  ModulesList = "ModulesList",
  ModuleVersions = "ModuleVersions",
}

const modules = "Modules";
const singleModule = "Module";
const moduleSettings = "Settings";

export const pagesModule: Pages<AnalyticsPageModule> = {
  [AnalyticsPageModule.ModuleNew]: [modules, "New Module"],
  [AnalyticsPageModule.ModulesList]: [modules, "Module list"],
  [AnalyticsPageModule.ModulePolicies]: [modules, singleModule, moduleSettings, "Policies"],
  [AnalyticsPageModule.ModuleVersions]: [modules, singleModule, "Versions"],
};
