import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import useAnalytics from "hooks/useAnalytics";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type ContextListItemDropdownDeleteProps = {
  hasAttachedEntities: boolean;
  id: string;
  name: string;
  onSuccess?: () => void;
  showConfirmationCallback?: () => void;
};

const ContextListItemDropdownDelete = ({
  hasAttachedEntities,
  id,
  name,
  onSuccess,
  showConfirmationCallback: close,
}: ContextListItemDropdownDeleteProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleDelete = (closeDropdown?: () => void) => {
    closeDropdown?.();
    trackSegmentAnalyticsEvent("Start Delete");
    showDeleteConfirmation({
      id,
      hasAttachedEntities,
      name,
    }).then((finished) => {
      trackSegmentAnalyticsEvent(finished ? "Confirm Delete" : "Cancel Delete");
      if (finished && onSuccess) {
        onSuccess();
      }
    });
  };

  return (
    <DropdownMenuItem onAction={() => handleDelete(close)} danger>
      Delete
    </DropdownMenuItem>
  );
};

export default ContextListItemDropdownDelete;
