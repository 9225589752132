import React, { UIEvent } from "react";
import { type DebouncedFunc } from "lodash-es";
import debounce from "lodash-es/debounce";

const SCREENS_GAP_QUANTITY = 2;

type ControlledInfiniteListProps = {
  onLoadMore: () => Promise<void>;
  children: (onScroll: DebouncedFunc<(e: UIEvent<HTMLDivElement>) => void>) => React.ReactNode;
  screensGap?: number;
  hasMore?: boolean;
  loading?: boolean;
};

const ControlledInfiniteList = ({
  children,
  screensGap = SCREENS_GAP_QUANTITY,
  onLoadMore,
  hasMore,
  loading,
}: ControlledInfiniteListProps) => {
  const handleLoadMore = debounce((e: UIEvent<HTMLDivElement>) => {
    if (hasMore && !loading) {
      const target = e.target as HTMLDivElement;
      const isBottom = target.scrollHeight - target.scrollTop <= target.clientHeight * screensGap;

      if (isBottom) {
        void onLoadMore();
      }
    }
  }, 20);

  return <>{children(handleLoadMore)}</>;
};

export default ControlledInfiniteList;
