import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

type DismissAllNotificationsConfirmationProps = {
  onConfirm: () => void;
};

const DismissAllNotificationsConfirmation = create(function DismissAllNotificationsConfirmation({
  onConfirm,
}: DismissAllNotificationsConfirmationProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <ConfirmationModal
      size="large"
      confirmationButtonLabel="Dismiss all"
      onConfirm={handleOnConfirm}
    >
      <ConfirmationModalMessage>
        Are you sure you want to dismiss all notifications?
      </ConfirmationModalMessage>
    </ConfirmationModal>
  );
});

export const showDismissAllNotificationsConfirmation = (
  props: DismissAllNotificationsConfirmationProps
) => show(DismissAllNotificationsConfirmation, props);
