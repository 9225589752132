import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import setLocation from "shared/setLocation";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { OAuthRedirect } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

export const LOGIN = gql`
  mutation OAuthLogin {
    oauthRedirect {
      providerUrl
      temporaryToken
    }
  }
`;

type LoginGql = {
  oauthRedirect: OAuthRedirect;
};

const Login = () => {
  useTitle("Redirecting to OAuth provider...");

  const { onError } = useTypedContext(FlashContext);
  const [redirect, { error }] = useMutation<LoginGql>(LOGIN);
  const ErrorContent = useErrorHandle(error);

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const { data } = await redirect();

        if (data) {
          localStorage.setItem("temporaryToken", data.oauthRedirect.temporaryToken);
          setLocation(data.oauthRedirect.providerUrl);
        }
      } catch (e) {
        onError(e);
      }
    };

    asyncEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ErrorContent) {
    return ErrorContent;
  }

  return <SpaceliftLoader />;
};

export default Login;
