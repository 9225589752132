import { dropdownEllipsisWidth } from "ds/components/DropdownMenu/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const COLUMN_ORDER_WITHOUT_PRIORITY = `3rem minmax(4rem, 1fr) minmax(10rem, 1fr) minmax(8rem, 1fr) minmax(18rem, 6fr) minmax(12rem, 1fr) minmax(13rem, 1fr) 3rem ${dropdownEllipsisWidth}`;

export const COLUMN_ORDER = `3rem minmax(4rem, 1fr) minmax(10rem, 1fr) minmax(8rem, 1fr) minmax(18rem, 6fr) minmax(12rem, 1fr) 4rem minmax(13rem, 1fr) 3rem ${dropdownEllipsisWidth}`;

export const COLUMN_GAP: Spacing = "large";

export const FILTERS_TYPE = "workerPoolQueuedRuns";
export const FILTERS_ORDER_SETTINGS_KEY = "workerPoolQueuedRunsFiltersOrder";

export enum FilterItemKeys {
  POSITION = "position",
  TYPE = "type",
  IS_PRIORITIZED = "isPrioritized",
  STACK_NAME = "stackName",
  COMMIT = "commit",
  CREATED_AT = "createdAt",
  IS_DRIFT_DETECTION = "isDriftDetection",
  STATE = "state",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.IS_PRIORITIZED]: "priority",
  [FilterItemKeys.STACK_NAME]: "stack name",
  [FilterItemKeys.COMMIT]: "commit SHA",
  [FilterItemKeys.CREATED_AT]: "created at",
  [FilterItemKeys.IS_DRIFT_DETECTION]: "drift detection",
  [FilterItemKeys.STATE]: "status",
};

export const INITIAL_SORT_OPTION = FilterItemKeys.POSITION;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Asc;

export const POLL_INTERVAL = 7000;
