import { useMemo } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import sortBy from "lodash-es/sortBy";

import { StackContextAttachment } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { GET_MODULE_ATTACHED_CONTEXTS } from "shared/Context/gql";

type AttachedModules<IsAutoAttached extends boolean> = Array<
  Omit<StackContextAttachment, "isAutoattached"> & {
    isAutoattached: IsAutoAttached;
  }
>;

const useAttachedContexts = (moduleId?: string) => {
  const { onError } = useTypedContext(FlashContext);

  const { networkStatus, data, loading, refetch, error } = useQuery<{
    entity: {
      attachedContexts: StackContextAttachment[];
    };
  }>(GET_MODULE_ATTACHED_CONTEXTS, {
    onError,
    variables: { entityId: moduleId },
    fetchPolicy: "no-cache",
    skip: !moduleId,
  });

  const [manuallyAttachedContexts, autoAttachedContexts, manuallyAttachedContextIds, allContexts] =
    useMemo(() => {
      const contexts = sortBy(data?.entity?.attachedContexts || [], "priority");
      const manuallyAttachedContextIds: string[] = [];
      const manuallyAttachedContexts: AttachedModules<false> = [];
      const autoAttachedContexts: AttachedModules<true> = [];

      for (const context of contexts) {
        if (context.isAutoattached) {
          autoAttachedContexts.push({
            ...context,
            isAutoattached: true,
          });
        } else {
          manuallyAttachedContextIds.push(context.contextId);

          manuallyAttachedContexts.push({
            ...context,
            isAutoattached: false,
          });
        }
      }

      return [
        manuallyAttachedContexts,
        autoAttachedContexts,
        manuallyAttachedContextIds,
        [...manuallyAttachedContexts, ...autoAttachedContexts],
      ];
    }, [data?.entity?.attachedContexts]);

  const isLoading = loading && networkStatus !== NetworkStatus.refetch;
  const isRefetching = loading && networkStatus === NetworkStatus.refetch;

  return {
    allContexts,
    manuallyAttachedContexts,
    autoAttachedContexts,
    manuallyAttachedContextIds,
    loading: isLoading,
    refetching: isRefetching,
    hasData: !isLoading && !!data?.entity,
    refetch,
    error,
  };
};

export default useAttachedContexts;
