import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { NamedWebhooksIntegration } from "types/generated";

import { WEBHOOK_DELETE } from "./gql";

type DeleteConfirmationProps = {
  name: string;
  id: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  name,
  id,
}: DeleteConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteWebhook, { loading, data }] = useMutation<{
    namedWebhooksIntegrationDelete: NamedWebhooksIntegration;
  }>(WEBHOOK_DELETE, {
    refetchQueries: ["SearchNamedWebhooksIntegrations"],
    awaitRefetchQueries: true,
    variables: { id },
  });

  const handleDeleteBlueprint = useCallback(() => {
    deleteWebhook()
      .then(() => {
        reportSuccess({
          message: "Webhook successfully deleted",
        });
        modal.resolve();
        modal.hide();
      })
      .catch(onError);
  }, [deleteWebhook, modal, onError, reportSuccess]);

  return (
    <DeleteConfirmationModal
      title="Delete webhook"
      name={name}
      onConfirm={handleDeleteBlueprint}
      isLoading={loading}
      isDismissable={!loading && !data?.namedWebhooksIntegrationDelete?.id}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
