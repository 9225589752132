import { useEffect, useState } from "react";

import { isSelfHostedDistribution } from "utils/distribution";
import useSelfHostedFlags from "hooks/useSelfHostedFlags";

import { SelfHostedInstanceInfo } from "./types";

const isSelfHosted = isSelfHostedDistribution();

const useSelfHostedInstanceProvider = () => {
  const { selfHostedV3InstallationFlow } = useSelfHostedFlags();

  const [isLoading, setIsLoading] = useState(false);
  const [instanceInfo, steInstanceInfo] = useState<SelfHostedInstanceInfo | undefined>(undefined);

  useEffect(() => {
    if (!instanceInfo && !isLoading && isSelfHosted && selfHostedV3InstallationFlow) {
      setIsLoading(true);

      fetch("/instance_info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          steInstanceInfo({
            hasAccountBeenCreated: data.has_account_been_created,
            isAdminLoginEnabled: data.is_admin_login_enabled,
          });
        })
        .catch(() => reportError({ message: "Failed to fetch instance info" }))
        .finally(() => setIsLoading(false));
    }
  }, [instanceInfo, isLoading, selfHostedV3InstallationFlow]);

  return {
    instanceInfo,
  };
};

export default useSelfHostedInstanceProvider;
