import { gql } from "@apollo/client";

export const GET_RUN_ANSIBLE_HOSTS = gql`
  query GetRunAnsibleHosts($input: RunOrStackArgs) {
    ansibleHosts(input: $input) {
      id
      name
      taskExecutions {
        task {
          name
          path
          playbook {
            name
            path
          }
          role {
            name
            path
          }
        }
        status
        timestamp
        checkMode
        stdout
        diff
        runId
        stackId
      }
    }
  }
`;
