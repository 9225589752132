import { Route, Routes } from "react-router-dom-v5-compat";

import NotFoundPage from "components/error/NotFoundPage";

import WebhookDeliveries from "./Deliveries";
import WebhookDeliveryView from "./Delivery";

const Webhook = () => {
  return (
    <Routes>
      <Route index element={<WebhookDeliveries />} />
      <Route path="delivery/:deliveryId/:statusCode/*" element={<WebhookDeliveryView />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Webhook;
