import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser } from "types/generated";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showUserListRevokeAccessConfirmation } from "./ConfirmationModal";
import styles from "./styles.module.css";

type UsersListInviteDropdownProps = {
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  item: ManagedUser;
};

const UsersListDropdown = ({ handleUserDetailsClick, item }: UsersListInviteDropdownProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const confirmationModalShow = () => {
    showUserListRevokeAccessConfirmation({
      id: item.id,
      userName: item.username,
    });
  };

  const handleAccessDetailsClick = () => {
    handleUserDetailsClick(item);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownMenuEllipsis tooltip="User actions">
        <DropdownMenuItem onAction={handleAccessDetailsClick}>Access details</DropdownMenuItem>
        {viewer.id !== item.username && (
          <DropdownMenuItem danger onAction={confirmationModalShow}>
            Revoke access
          </DropdownMenuItem>
        )}
      </DropdownMenuEllipsis>
    </div>
  );
};

export default UsersListDropdown;
