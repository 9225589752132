import Box from "ds/components/Box";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";

type Props = {
  onClick: () => void;
  text: string;
  query: string;
  showAddButton?: boolean;
};

const VcsRepositoryFieldSelectOptionAddNonExisting = ({
  text,
  onClick,
  query,
  showAddButton = true,
}: Props) => {
  const hasQuery = query.trim().length > 0;

  return (
    <Box
      margin="0 medium 0 0"
      direction="column"
      padding="medium"
      gap="small"
      justify="center"
      align="start"
    >
      <Typography tag="p" variant="p-body2">
        {text}{" "}
        {hasQuery && showAddButton && (
          <>
            <Link onClick={onClick}>Click here</Link> to add repository anyway.
          </>
        )}
      </Typography>
    </Box>
  );
};

export default VcsRepositoryFieldSelectOptionAddNonExisting;
