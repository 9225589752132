import { gql } from "@apollo/client";

export const GET_FAVORITE_STACKS = gql`
  query GetFavoriteStacks {
    metrics {
      favouriteStacks {
        name
        slug
        state
      }
      hasStacks
    }
  }
`;
