import { SortableTableColumn } from "components/SortableTable";

export const FILTERS_ORDER_SETTINGS_KEY_VERSION_OUTPUTS = "version-outputs";

export const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
];
