import cx from "classnames";

import Box from "../Box";
import Typography from "../Typography";
import styles from "./styles.module.css";

type ProgressBarProps = {
  current: number;
  max: number;
  fullWidth?: boolean;
  hideLabel?: boolean;
  ariaLabel: string;
  color?: string;
};

const ProgressBar = ({
  current,
  max,
  fullWidth,
  ariaLabel,
  hideLabel,
  color,
}: ProgressBarProps) => {
  return (
    <Box
      role="progressbar"
      aria-label={ariaLabel}
      aria-valuemin={0}
      aria-valuenow={current}
      aria-valuemax={max}
      tabIndex={0}
      gap="medium"
      align="center"
      grow={fullWidth ? "1" : "0"}
    >
      <div className={cx(styles.progressBar, fullWidth && styles.fullWidth)}>
        <div
          className={styles.progressBarValue}
          style={{ width: `${(current * 100) / max}%`, backgroundColor: color }}
        />
      </div>
      {!hideLabel && <Typography tag="span" variant="p-t7">{`${current}/${max}`}</Typography>}
    </Box>
  );
};

export default ProgressBar;
