import { Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState } from "react";

import { RunShortcuts } from "./types";

type RunElementsContextType = {
  shortcuts: RunShortcuts;
  setShortcuts: Dispatch<SetStateAction<RunShortcuts>>;
};

export const RunElementsContext = createContext<RunElementsContextType | undefined>(undefined);

type RunElementsProviderProps = {
  children: ReactNode;
};

export const RunElementsProvider = ({ children }: RunElementsProviderProps) => {
  const [shortcuts, setShortcuts] = useState<RunShortcuts>([]);
  const contextValue = useMemo(() => ({ shortcuts, setShortcuts }), [shortcuts]);

  return <RunElementsContext.Provider value={contextValue}>{children}</RunElementsContext.Provider>;
};
