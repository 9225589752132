import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import CodeEditor from "components/CodeEditor";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import NoAccessPage from "components/error/NoAccessPage";
import FlashContext from "components/FlashMessages/FlashContext";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useAnalytics from "hooks/useAnalytics";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AuthorizationScheme, PolicyType } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import Callout from "ds/components/Callout";
import PageLoading from "components/loading/PageLoading";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import styles from "./styles.module.css";
import { POLICY_TYPES_DATA } from "../Policies/helpers";
import { CREATE_POLICY, GET_ACCOUNT_AUTH_SCHEME } from "./gql";
import { useDetailsFromUrl } from "./useDetailsFromUrl";
import { LOGIN_POLICY_CREATE_ACTIVE_SESSIONS_WARNING_STORAGE_KEY } from "./constants";
import { getManagementStrategy } from "../Settings/helpers";
import { UserManagementActivationStatus } from "../Settings/types";
import { SpacesContext } from "../SpacesProvider";
import { showLoginPolicyCreateConfirmationModal } from "./ConfirmationModal";

const NewLoginPolicy = () => {
  const navigate = useNavigate();
  const { accountName, viewer } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { hasManageableSpaces } = useTypedContext(SpacesContext);
  const { name, labels } = useDetailsFromUrl();

  const { loading: authSchemeLoading, data } = useQuery<{
    authorizationScheme: AuthorizationScheme;
  }>(GET_ACCOUNT_AUTH_SCHEME, {
    onError,
  });

  useTitle(`New login policy · ${accountName}`);

  const activationStatus =
    data?.authorizationScheme === AuthorizationScheme.ManagedUsers
      ? UserManagementActivationStatus.ACTIVE
      : UserManagementActivationStatus.INACTIVE;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationLoginPolicyCreate,

    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const [body, setBody] = useState(POLICY_TYPES_DATA[PolicyType.Login]?.template || "");

  const handleEditorChange = (value?: string) => {
    setBody(value || "");
  };

  const [createPolicy, { loading }] = useMutation<{ policyCreate: { name: string } }>(
    CREATE_POLICY,
    {
      refetchQueries: ["GetLoginPolicies"],
      onError,
      // APOLLO CLIENT UPDATE
      onCompleted: (data) => {
        if (data) {
          trackSegmentAnalyticsEvent("Create Success");
          reportSuccess({ message: `Login policy ${data.policyCreate.name} successfully created` });
          navigate(`/settings/login-policy`);
        }
      },
    }
  );

  const handleCreatePolicy = () => {
    createPolicy({
      variables: {
        name,
        body,
        type: PolicyType.Login,
        labels,
      },
    }).catch(onError);
  };

  const handleConfirmation = () => {
    showLoginPolicyCreateConfirmationModal({
      onConfirm: handleCreatePolicy,
    });
  };

  useBreadcrumbs(
    [
      {
        title: "Organization settings",
        link: "/settings",
      },
      {
        title: "Login Policy",
        link: "/settings/login-policy",
      },
      {
        title: name || "",
      },
    ],
    [name]
  );

  if (authSchemeLoading && !data?.authorizationScheme) {
    return <PageLoading />;
  }

  if (!viewer.admin && !hasManageableSpaces) {
    return <NoAccessPage />;
  }

  const isLoginPolicyActive = data?.authorizationScheme !== AuthorizationScheme.ManagedUsers;

  return (
    <>
      <ViewHeader backConfirmation="Your changes will not be saved.">
        <ViewHeaderWrapper direction="row" align="center" justify="between" fullWidth>
          <ViewHeaderTitle>{name}</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>

      <Box justify="stretch">
        <Box direction="column" className={styles.bodyWrapper}>
          <Box justify="between" align="center" className={styles.header}>
            <Typography tag="h3" variant="p-t5">
              Policy body
            </Typography>
            {!isLoginPolicyActive && (
              <Button variant="primary" loading={loading} onClick={handleCreatePolicy}>
                Create
              </Button>
            )}
            {isLoginPolicyActive && (
              <Button variant="primary" loading={loading} onClick={handleConfirmation}>
                Create
              </Button>
            )}
          </Box>
          {isLoginPolicyActive && (
            <Callout
              variant="danger"
              storageKey={LOGIN_POLICY_CREATE_ACTIVE_SESSIONS_WARNING_STORAGE_KEY}
            >
              After creating the Login policy, all active sessions (except the current one) will be
              invalidated.
            </Callout>
          )}

          <CodeEditor body={body} onChange={handleEditorChange} language="rego" />
        </Box>
      </Box>
    </>
  );
};

export default NewLoginPolicy;
