import { useMemo } from "react";

import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY, URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";
import { decodeURIParam } from "utils/urls";
import { SearchQueryOrderDirection } from "types/generated";
import useTypedContext from "hooks/useTypedContext";

import { FiltersContext } from "..";

export const useSortOptions = () => {
  const urlParams = useURLParams();
  const { initialSortDirection, initialSortOption, filtersOrder } = useTypedContext(FiltersContext);

  const [querySortOption, querySortDirection] = useMemo(() => {
    const querySortOption = decodeURIParam(urlParams.get(URL_SORT_KEY)) || initialSortOption;
    const querySortDirection = (decodeURIParam(urlParams.get(URL_SORT_DIRECTION)) ||
      initialSortDirection) as SearchQueryOrderDirection;
    return [querySortOption, querySortDirection];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  return {
    sort: { direction: querySortDirection, option: querySortOption },
    text: urlParams.get(URL_SEARCH_KEY),
    order: filtersOrder,
  };
};
