import { VENDOR_KEY } from "constants/vendor";

export const makeVendorQueryVariables = (vendor?: VENDOR_KEY) => {
  switch (vendor) {
    case VENDOR_KEY.TERRAFORM:
      return {
        includeTerraform: true,
        includeTerragrunt: false,
        includeKubectl: false,
      };

    case VENDOR_KEY.TERRAGRUNT:
      return {
        includeTerraform: true,
        includeTerragrunt: true,
        includeKubectl: false,
      };

    case VENDOR_KEY.KUBERNETES:
      return {
        includeTerraform: false,
        includeTerragrunt: false,
        includeKubectl: true,
      };

    default:
      return {
        includeTerraform: true,
        includeTerragrunt: true,
        includeKubectl: true,
      };
  }
};
