import { useCallback, useMemo, useState } from "react";

import { AnalyticsPage } from "hooks/useAnalytics";

import { BulkActionsApplicableAction } from "./ChooseActionStep/types";
import { BaseEntity, BulkActionItemID, BulkEntityActionItem } from "./types";
import { getApplicableToTooltipProps } from "./ChooseActionStep/helpers";
import { makeBulkActionsData } from "./helpers";

type UseBulkActionsProps<Action extends string, Entity, MutationVariables> = {
  actions: BulkEntityActionItem<Action, Entity, MutationVariables>[];
  selectedSet: Set<BulkActionItemID>;
  entityItemsMap: Map<BulkActionItemID, Entity>;
  analyticsPage: AnalyticsPage;
};

const useBulkActions = <Action extends string, Entity extends BaseEntity, MutationVariables>({
  actions,
  selectedSet,
  entityItemsMap,
  analyticsPage,
}: UseBulkActionsProps<Action, Entity, MutationVariables>) => {
  const [selectedBulkAction, setSelectedBulkAction] = useState<
    BulkEntityActionItem<Action, Entity, MutationVariables> | undefined
  >(undefined);

  const onSelectAction = useCallback(
    (action: BulkEntityActionItem<Action, Entity, MutationVariables>) => {
      setSelectedBulkAction(action);
    },
    []
  );

  const resetSelectedAction = useCallback(() => {
    setSelectedBulkAction(undefined);
  }, []);

  const [selectedEntityItems, itemsPerAction, actionsPerItem] = useMemo(() => {
    const { selectedEntityItems, actionsPerItem, itemsPerAction } = makeBulkActionsData({
      actions,
      selectedSet,
      entityItemsMap,
    });

    return [selectedEntityItems, itemsPerAction, actionsPerItem];
  }, [selectedSet, entityItemsMap, actions]);

  const [applicableSelectedEntityItems, skippedSelectedEntityItems] = useMemo<
    [Entity[], Entity[]]
  >(() => {
    if (selectedBulkAction?.key) {
      const applicableIds = itemsPerAction.get(selectedBulkAction.key) || [];

      if (applicableIds.length !== selectedEntityItems.length) {
        return selectedEntityItems.reduce<[Entity[], Entity[]]>(
          ([selected, skipped], item) => {
            if (applicableIds.includes(item?.id)) {
              selected.push(item);
            } else {
              skipped.push(item);
            }

            return [selected, skipped];
          },
          [[], []]
        );
      }
    }

    return [selectedEntityItems, []];
  }, [itemsPerAction, selectedBulkAction?.key, selectedEntityItems]);

  const availableActions = useMemo<BulkActionsApplicableAction<Action>[]>(
    () =>
      actions
        .map((action) => {
          const items = itemsPerAction.get(action.key) || [];
          if (!items.length) {
            return null;
          }
          return {
            title: action.title,
            action: action.key,
            onClick: () => {
              onSelectAction(action!);
            },
            applicableCount: items.length,
            notApplicableCount: selectedSet.size - items.length,
            analyticsPage,
            ...getApplicableToTooltipProps(items.length, selectedSet.size),
          };
        })
        .filter((action) => action !== null),
    [actions, itemsPerAction, onSelectAction, analyticsPage, selectedSet.size]
  );

  return {
    selectedBulkAction,
    resetSelectedAction,
    availableActions,
    itemsPerAction,
    actionsPerItem,

    selectedEntityItems,
    applicableSelectedEntityItems,
    skippedSelectedEntityItems,
  };
};

export default useBulkActions;
