import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { LeftRightColored } from "components/icons";
import Button from "ds/components/Button";

const DashboardResourcesChartEmptyNoDrift = () => {
  return (
    <TileWrapper padding="x-large" fullWidth justify="center">
      <EmptyState
        title="Drift Detection not enabled"
        icon={LeftRightColored}
        caption="Enable Drift Detection on your stacks to be able to preview your resources health."
      >
        <Box gap="medium">
          {/* TODO: [dashboard] userflow trigger */}
          <Button variant="primary">Show me how</Button>
        </Box>
      </EmptyState>
    </TileWrapper>
  );
};

export default DashboardResourcesChartEmptyNoDrift;
