import { Controller, useFormContext } from "react-hook-form";

import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";

type ContextConfigFormIsSecretFieldProps = {
  name: string;
};

const ContextConfigFormIsSecretField = ({ name }: ContextConfigFormIsSecretFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Toggle variant="switch" align="start" onChange={field.onChange} checked={field.value}>
          <Box direction="column" shrink="1">
            <Typography tag="span" variant="p-t7">
              Mark as secret
            </Typography>
            <Typography tag="span" variant="p-body4" color="secondary">
              Secret values are only made available to Runs and Tasks and are not accessible in the
              web GUI and through the API. Marking variables as a secret is a recommended option.
            </Typography>
          </Box>
        </Toggle>
      )}
    />
  );
};

export default ContextConfigFormIsSecretField;
