import { Spinner } from "components/icons";
import PageWrapper from "components/PageWrapper";

import styles from "./styles.module.css";

type PageLoadingProps = {
  message?: string;
};

const PageLoading = ({ message }: PageLoadingProps) => (
  <PageWrapper className={styles.wrapper}>
    <div className={styles.loading}>
      <Spinner width="50px" />
      {message}
    </div>
  </PageWrapper>
);

export default PageLoading;
