import { RefObject, useCallback, useEffect } from "react";
import debounce from "lodash-es/debounce";

const isLabelWrapped = (parent: Element, child: Element) => {
  const itemRect = child.getBoundingClientRect();
  const containerRect = parent.getBoundingClientRect();

  return Math.round(itemRect.top - itemRect.height) > containerRect.top;
};

export const useHiddenLabelsIndicator = (parentRef: RefObject<HTMLElement>, tags: string[]) => {
  const indicateHiddenLabels = useCallback(() => {
    if (parentRef.current) {
      const parentElement = parentRef.current.querySelector('[data-tags="tagsWrapper"]');
      const toggleElement = parentRef.current.querySelector('[data-tags="toggle"]');

      if (parentElement && toggleElement) {
        const labels = Array.from(
          parentElement.querySelectorAll('[data-tags="tagField"]')
        ).reverse();

        let hiddenElements = 0;
        labels.find((label) => {
          if (isLabelWrapped(parentElement, label)) {
            hiddenElements += 1;
            return false;
          }

          if (hiddenElements > 0) {
            toggleElement.classList.add("element-labels__compact--toggle__visible");
            toggleElement.setAttribute("data-hidden-elements", `+${hiddenElements}`);
          }

          return true;
        });

        if (!hiddenElements) {
          parentRef.current
            .querySelector("[data-tags='toggle']")
            ?.classList.remove("element-labels__compact--toggle__visible");
        }
      }
    }
  }, [parentRef]);

  useEffect(() => {
    window.addEventListener("resize", debounce(indicateHiddenLabels, 100));

    return () => {
      window.removeEventListener("resize", indicateHiddenLabels);
    };
  }, [indicateHiddenLabels]);

  useEffect(() => {
    indicateHiddenLabels();
  }, [tags, indicateHiddenLabels]);
};
