import { ReactNode, useState } from "react";

import Box from "ds/components/Box";
import DrawerBody from "ds/components/Drawer/Body";
import { BulkActionActionConfirmationTabs } from "components/BulkActions/types";
import TabWithCounter from "ds/components/Tab/WithCounter";
import TabPanel from "ds/components/TabPanel";

import { CONFIRMATION_TABS, CONFIRMATION_TABS_DICTIONARY } from "./constants";

type BulkActionsDrawerActionStepsConfirmationChildrenProps = {
  currentTab: BulkActionActionConfirmationTabs;
};

type BulkActionsDrawerActionStepsConfirmationProps = {
  applicableCount: number;
  skippedCount: number;
  children: (props: BulkActionsDrawerActionStepsConfirmationChildrenProps) => ReactNode;
};

const BulkActionsDrawerActionStepsConfirmation = ({
  applicableCount,
  skippedCount,
  children,
}: BulkActionsDrawerActionStepsConfirmationProps) => {
  const [currentTab, setCurrentTab] = useState<BulkActionActionConfirmationTabs>(
    BulkActionActionConfirmationTabs.Applicable
  );

  const getCounter = (tab: BulkActionActionConfirmationTabs) =>
    tab === BulkActionActionConfirmationTabs.Applicable ? applicableCount : skippedCount;

  return (
    <DrawerBody gap="large" fullHeight>
      <Box gap="medium">
        {CONFIRMATION_TABS.map((tab) => (
          <TabWithCounter
            key={tab}
            isActive={currentTab === tab}
            id={tab}
            label={CONFIRMATION_TABS_DICTIONARY[tab]}
            count={getCounter(tab)}
            onClick={setCurrentTab}
          />
        ))}
      </Box>

      {CONFIRMATION_TABS.map((tab) => (
        <TabPanel grow="1" key={tab} id={tab} isActive={currentTab === tab}>
          <Box grow="1" gap="large" direction="column">
            {children({ currentTab: tab })}
          </Box>
        </TabPanel>
      ))}
    </DrawerBody>
  );
};
export default BulkActionsDrawerActionStepsConfirmation;
