import { useCallback, useMemo, useState } from "react";

import CardWrapper from "components/CardWrapper";
import EmptyState from "ds/components/EmptyState";
import { CloudColored } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import Callout from "ds/components/Callout";
import Box from "ds/components/Box";
import { ModuleCreationCloud } from "views/Account/NewModule/types";
import useCloudConfig from "views/Account/NewModule/AttachCloud/hooks/useCloudConfig";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import CloudDetails from "views/Account/NewModule/AttachCloud/Details";
import AttachCloudForm from "views/Account/NewModule/AttachCloud/Form";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import WarningContextProvider from "components/WarningContext/Provider";
import PageLoading from "components/loading/PageLoading";
import { isSelfHostedDistribution } from "utils/distribution";

import { ModuleSettingsContext } from "../Context";
import useAttachedCloudIntegrations from "./hooks/useAttachedCloudIntegrations";
import ModuleSettingsIntegrationsPageLayout from "./components/PageLayout";
import useAttachedGcpIntegration from "./hooks/useAttachedGcpIntegration";
import ModuleSettingsFormWrapper from "../components/FormWrapper";

const isSelfHosted = isSelfHostedDistribution();

const ModuleSettingsIntegrationsCloudIntegrations = () => {
  const [cloud, setCloud] = useState<ModuleCreationCloud | undefined>();
  const { module, canManageModule } = useTypedContext(ModuleSettingsContext);
  const [isCloudIntegrationDrawerOpen, setIsCloudIntegrationDrawerOpen] = useState(false);

  const {
    loading: cloudConfigLoading,
    cloudConfig,
    attachableAzureIntegrations,
    attachableAwsIntegrations,
    hasData,
    refetch,
    refetching,
  } = useCloudConfig(module.spaceDetails.id);

  const handleCloseCloudIntegrationDrawer = useCallback(() => {
    setIsCloudIntegrationDrawerOpen(false);
    setCloud(undefined);
  }, []);

  const { loading: integrationsLoading, integrations } = useAttachedCloudIntegrations(module.id);

  const { loading: gcpIntegrationLoading, integration: gcpIntegration } = useAttachedGcpIntegration(
    module.id
  );

  const attachedIds = useMemo(
    () => integrations.map((integration) => integration.integrationId),
    [integrations]
  );

  const filteredAwsIntegrations = useMemo(
    () => attachableAwsIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAwsIntegrations, attachedIds]
  );

  const filteredAzureIntegrations = useMemo(
    () =>
      attachableAzureIntegrations.filter((integration) => !attachedIds.includes(integration.id)),
    [attachableAzureIntegrations, attachedIds]
  );

  const hasSomeIntegrationsAttached = integrations.length > 0 || gcpIntegration?.activated;

  const hasAttachableCloudIntegrations = hasData && cloudConfig.length > 0;

  const canAttachGcpIntegration = !gcpIntegration?.activated && !isSelfHosted;

  const canAttach =
    (filteredAwsIntegrations.length > 0 ||
      filteredAzureIntegrations.length > 0 ||
      canAttachGcpIntegration) &&
    canManageModule;

  const loading = cloudConfigLoading || integrationsLoading || gcpIntegrationLoading;

  return (
    <>
      <ModuleSettingsIntegrationsPageLayout
        actions={
          <Button
            variant="primary"
            disabled={!canAttach || loading}
            onClick={() => setIsCloudIntegrationDrawerOpen(true)}
          >
            Attach cloud integration
          </Button>
        }
      >
        {!loading && (
          <Callout variant="info">
            You can only attach integrations from the current space and parent spaces that you
            inherit from.
          </Callout>
        )}

        {loading && <PageLoading />}

        {!loading && hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={CloudColored}
                title="You do not have any attached cloud integrations yet"
                caption="You can attach one of the existing ones, or create a new one."
              >
                <Box gap="medium">
                  {canManageModule && (
                    <Button variant="primary" onClick={() => setIsCloudIntegrationDrawerOpen(true)}>
                      Attach cloud integration
                    </Button>
                  )}
                  <DocumentationButton
                    to={getDocsUrl("/integrations/cloud-providers")}
                    label="Documentation"
                  />
                </Box>
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {!loading && !hasAttachableCloudIntegrations && !hasSomeIntegrationsAttached && (
          <Box align="center" justify="center" grow="1" fullWidth>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={CloudColored}
                title="You do not have any cloud integrations yet"
                caption={
                  <>
                    Cloud Integrations are used to dynamically generate short-lived credentials to
                    authenticate to cloud providers (AWS/Azure/GCP currently supported). In this way
                    you avoid using static credentials and by doing so, you reduce breaches.
                    <br /> Read more in the Documentation.
                  </>
                }
              >
                <DocumentationButton
                  to={getDocsUrl("/integrations/cloud-providers")}
                  label="Documentation"
                />
              </EmptyState>
            </CardWrapper>
          </Box>
        )}

        {!loading && hasSomeIntegrationsAttached && (
          <ModuleSettingsFormWrapper gap="large">
            {integrations.map((integration) => (
              <CloudDetails
                key={integration.id}
                cloudConfig={cloudConfig}
                attachedIntegrationType={
                  integration.__typename === "StackAzureIntegrationAttachment"
                    ? ModuleCreationCloud.Azure
                    : ModuleCreationCloud.AWS
                }
                attachedIntegration={integration}
                moduleId={module.id}
                refetchQueries={["GetAttachedModuleIntegrations"]}
                readOnly={!canManageModule}
              />
            ))}
            {gcpIntegration?.activated && (
              <CloudDetails
                cloudConfig={cloudConfig}
                attachedIntegrationType={ModuleCreationCloud.GCP}
                attachedIntegration={gcpIntegration}
                moduleId={module.id}
                refetchQueries={["GetModuleAttachedGcpIntegration"]}
                readOnly={!canManageModule}
              />
            )}
          </ModuleSettingsFormWrapper>
        )}
      </ModuleSettingsIntegrationsPageLayout>
      {canManageModule && (
        <WarningContextProvider>
          <Drawer
            position="fixedRight"
            visible={isCloudIntegrationDrawerOpen}
            onOutsideClick={handleCloseCloudIntegrationDrawer}
            variant="wide"
          >
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Attach cloud integration" />
              <DrawerCloseIcon handleCloseDrawer={handleCloseCloudIntegrationDrawer} />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <AttachCloudForm
                moduleId={module.id}
                hasData={hasData}
                refetch={refetch}
                refetching={refetching}
                setCloud={setCloud}
                cloud={cloud}
                cloudConfig={cloudConfig}
                attachableAzureIntegrations={filteredAzureIntegrations}
                attachableAwsIntegrations={filteredAwsIntegrations}
                canAttachGcpIntegration={canAttachGcpIntegration}
                onSuccess={handleCloseCloudIntegrationDrawer}
                refetchQueries={[
                  "GetAttachedModuleIntegrations",
                  "GetModuleAttachedGcpIntegration",
                ]}
              />

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <Button variant="secondary" onClick={handleCloseCloudIntegrationDrawer}>
                    Close
                  </Button>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </Drawer>
        </WarningContextProvider>
      )}
    </>
  );
};

export default ModuleSettingsIntegrationsCloudIntegrations;
