import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import NiceModal from "@ebay/nice-modal-react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom-v5-compat";

import Account from "views/Account/Account";
import ApiKeyToken from "views/Account/ApiKeyToken";
import Login from "views/Login";
import Logout from "views/Logout";
import SSOLogin from "views/SSOLogin";
import UnauthorizedPage from "components/error/UnauthorizedPage";
import AccountWrapper from "views/AccountWrapper";
import Theme from "views/Theme";
import CLILogin from "views/CLI/CLILogin";
import CLIAuthSuccess from "views/CLI/CLIAuthSuccess";
import CLIAuthFailure from "views/CLI/CLIAuthFailure";
import BreadcrumbsContextProvider from "components/Breadcrumbs/Context";
import LoginTerraform from "views/LoginTerraform";
import SubscriptionWrapper from "views/Account/SubscriptionWrapper";
import ThirdPartyLibsWrapper from "views/ThirdPartyLibsWrapper";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import MFA from "views/MFA";
import { isMFASupported } from "utils/mfa";
import EnableMFA from "views/EnableMFA";
import RedirectPage from "views/Redirect";
import { pageUserEvent } from "shared/Analytics/server-mutation/page";
import LaunchPadRedirectWrapper from "views/Account/LaunchPad/LaunchPadRedirectWrapper";
import SpacesProvider from "views/Account/SpacesProvider";
import LoginLimitExceededPage from "components/error/LoginLimitExceeded";
import SelfHostedLogout from "views/SelfHosted/Logout";
import SelfHostedLogin from "views/SelfHosted/Login";
import SelfHostedCreateAccount from "views/SelfHosted/CreateAccount";
import SelfHostedInstanceProviderGate from "views/SelfHosted/InstanceProvider/Gate";

const _hsq = (window._hsq = window._hsq || []);
const isSelfHosted = isSelfHostedDistribution();

const isSaas = isSaasDistribution();
const isMFA = isMFASupported();

const Routes = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView);
  }, [history]);

  function trackPageView() {
    _hsq.push(["setPath", window.location.pathname]);
    _hsq.push(["trackPageView"]);
    pageUserEvent();
  }

  // Remove Trailing Slash from URLs
  useEffect(() => {
    if (location.pathname !== "/" && /\/$/.test(location.pathname) && location.search === "") {
      history.replace(location.pathname.slice(0, -1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Theme>
      <ReactRouterRoutes>
        <Route path="/apikeytoken" element={<ApiKeyToken />} />

        <Route path="/login" element={<Login />} />

        <Route path="/logout" element={<Logout />} />
        <Route path="/sso/login" element={<SSOLogin />} />

        <Route path="/unauthorized" element={<UnauthorizedPage />} />

        <Route path="/login-limit-exceeded" element={<LoginLimitExceededPage />} />

        <Route path="/cli_login" element={<CLILogin />} />

        <Route path="/auth_success" element={<CLIAuthSuccess />} />

        <Route path="/auth_failure" element={<CLIAuthFailure />} />

        <Route path="/redirect" element={<RedirectPage />} />

        {isSelfHosted && <Route path="/see-you-later" element={<SelfHostedLogout />} />}
        {isSelfHosted && <Route path="/admin-login" element={<SelfHostedLogin />} />}
        {isSelfHosted && <Route path="/create-account" element={<SelfHostedCreateAccount />} />}

        {isSaas && isMFA && <Route path="/enable_mfa" element={<EnableMFA />} />}
        {isSaas && isMFA && <Route path="/mfa" element={<MFA />} />}
        <Route
          path="/terraform-login"
          element={
            <AccountWrapper>
              <LoginTerraform />
            </AccountWrapper>
          }
        />
        <Route
          path="*"
          element={
            <SelfHostedInstanceProviderGate>
              <AccountWrapper>
                <SubscriptionWrapper>
                  <SpacesProvider>
                    <ThirdPartyLibsWrapper>
                      <LaunchPadRedirectWrapper>
                        <BreadcrumbsContextProvider>
                          <NiceModal.Provider>
                            <Account />
                          </NiceModal.Provider>
                        </BreadcrumbsContextProvider>
                      </LaunchPadRedirectWrapper>
                    </ThirdPartyLibsWrapper>
                  </SpacesProvider>
                </SubscriptionWrapper>
              </AccountWrapper>
            </SelfHostedInstanceProviderGate>
          }
        />
      </ReactRouterRoutes>
    </Theme>
  );
};

export default Routes;
