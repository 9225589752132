import { BulkActionsVariant, BulkEntityActionItem } from "components/BulkActions/types";
import { RunWithPosition } from "types/generated";

export enum BulkWorkerPoolQueuedRunsActions {
  PRIORITIZE = "Prioritize",
  DE_PRIORITIZE = "De-prioritize",
  DISCARD = "Discard",
}

export type WorkerPoolQueuedRunsBulkActionsAnalyticsPayload = {
  view: BulkActionsVariant;
};

export type BulkItemRunWithPosition = RunWithPosition & {
  id: string;
};

export type WorkerPoolQueuedRunsBulkActionsSelectedAction = BulkEntityActionItem<
  BulkWorkerPoolQueuedRunsActions,
  BulkItemRunWithPosition,
  undefined
>;
