import Box from "../Box";
import Typography from "../Typography";

const SelectEmptyPlaceholder = () => {
  return (
    <Box padding="small">
      <Typography tag="p" variant="p-body3">
        No options available.
      </Typography>
    </Box>
  );
};

export default SelectEmptyPlaceholder;
