import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";

import NewContextFooter from "../Footer";
import { useSaveContext } from "../hooks/useSaveContext";
import { ContextFormFields } from "../types";
import NewContextSummaryDetails from "./Details";
import NewContextSummaryAttached from "./Attached";
import NewContextSummaryEnvironment from "./Environment";
import NewContextSummaryHooks from "./Hooks";

const NewContextSummary = () => {
  const { handleSubmit } = useFormContext<ContextFormFields>();

  const [saveContext, isSavingContext] = useSaveContext();

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Summary
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary">
          Please verify your context setup. Once context is created you still will be able to edit
          it.
        </Typography>
        <Box direction="column" gap="large" margin="large 0">
          <NewContextSummaryDetails />
          <NewContextSummaryAttached />
          <NewContextSummaryEnvironment />
          <NewContextSummaryHooks />
        </Box>
      </FullScreenModalBody>
      <NewContextFooter
        onCreateContextClick={handleSubmit(saveContext)}
        isCreatingContext={isSavingContext}
      />
    </>
  );
};

export default NewContextSummary;
