import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Link from "ds/components/Link";
import { MenuSpaces } from "components/icons";
import MetaInfoBoolean from "components/MetaInfoList/Boolean";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { WebhookContext } from "../Context";
import styles from "./styles.module.css";

type WebhookDrawerViewProps = {
  handleCloseDrawer: () => void;
};

const WebhookDrawerView = ({ handleCloseDrawer }: WebhookDrawerViewProps) => {
  const { webhook } = useTypedContext(WebhookContext);

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Webhook details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <FormFieldViewText label="Name" value={webhook.name} />

        <FormField label="Endpoint URL">
          <Typography tag="span" variant="p-body2">
            <Link href={webhook.endpoint} target="_blank" v5Compat>
              {webhook.endpoint}
            </Link>
          </Typography>
        </FormField>

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={webhook.space.name}
            href={`/spaces/${webhook.space.id}`}
          />
        </FormField>

        <FormField label="Enabled">
          <MetaInfoBoolean
            text={webhook.enabled ? "Enabled" : "Disabled"}
            value={webhook.enabled}
          />
        </FormField>
        {webhook.secret && (
          <FormField label="Secret">
            <SecretFieldDetails secret={webhook.secret} />
          </FormField>
        )}
        <FormFieldTimestamp timestamp={webhook.createdAt} label="Created at" />
        {webhook.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={webhook.labels} alwaysExpanded />
          </FormField>
        )}
        {!!webhook.secretHeaders.length && (
          <Box direction="column" className={styles.headersWrapper} gap="large">
            <Typography tag="span" variant="p-t5">
              Headers
            </Typography>

            {webhook.secretHeaders.map((key) => (
              <Box __deprecatedGap="1rem" align="center" key={key}>
                <FormField label="Key" className={styles.headerField}>
                  <Typography tag="span" variant="p-body2">
                    {key}
                  </Typography>
                </FormField>
                <FormField label="Value" className={styles.headerField}>
                  <Typography tag="span" variant="p-body2">
                    &bull;&bull;&bull;&bull;&bull;&bull;&bull;
                  </Typography>
                </FormField>
              </Box>
            ))}
          </Box>
        )}
      </DrawerBody>
    </>
  );
};

export default WebhookDrawerView;
