import { VcsProvider } from "types/generated";

export const VCS_INTEGRATIONS_LIST_FILTERS_KEY = "VCSIntegrationsListFiltersKey";

export const MULTIPLE_VCS_PROVIDERS = {
  [VcsProvider.AzureDevops]: VcsProvider.AzureDevops,
  [VcsProvider.BitbucketCloud]: VcsProvider.BitbucketCloud,
  [VcsProvider.BitbucketDatacenter]: VcsProvider.BitbucketDatacenter,
  [VcsProvider.GithubEnterprise]: VcsProvider.GithubEnterprise,
  [VcsProvider.Github]: VcsProvider.Github,
  [VcsProvider.Gitlab]: VcsProvider.Gitlab,
};

export enum VCS_INTEGRATION_TYPES {
  DEFAULT = "default",
  MULTIPLE = "multiple",
}

export const URL_KEY_AZURE_DEVOPS = "azure-devops";
export const URL_KEY_BITBUCKET_CLOUD = "bitbucket-cloud";
export const URL_KEY_BITBUCKET_DATACENTER = "bitbucket-data-center";
export const URL_KEY_GITLAB = "gitlab";
export const URL_KEY_GITHUB_ENTERPRISE = "github-enterprise";
export const URL_KEY_GITHUB = "github";

export const VCS_PROVIDERS_URL_KEYS = {
  [MULTIPLE_VCS_PROVIDERS.AZURE_DEVOPS]: URL_KEY_AZURE_DEVOPS,
  [MULTIPLE_VCS_PROVIDERS.BITBUCKET_CLOUD]: URL_KEY_BITBUCKET_CLOUD,
  [MULTIPLE_VCS_PROVIDERS.BITBUCKET_DATACENTER]: URL_KEY_BITBUCKET_DATACENTER,
  [MULTIPLE_VCS_PROVIDERS.GITLAB]: URL_KEY_GITLAB,
  [MULTIPLE_VCS_PROVIDERS.GITHUB_ENTERPRISE]: URL_KEY_GITHUB_ENTERPRISE,
  [MULTIPLE_VCS_PROVIDERS.GITHUB]: URL_KEY_GITHUB,
};

export const VCS_INTEGRATIONS_PATH = "/vcs/integrations";
export const VCS_INTEGRATION_PATH = "/vcs/integration/:provider/:id";
