import { gql } from "@apollo/client";

export const SEARCH_TEMPLATES = gql`
  query SearchTemplates($input: SearchInput!) {
    searchPolicyTemplates(input: $input) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          id
          type
          body
          description
          labels
        }
      }
    }
  }
`;

export const SEARCH_TEMPLATES_SUGGESTIONS = gql`
  query SearchTemplatesSuggestions($input: SearchSuggestionsInput!) {
    searchPolicyTemplatesSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;
