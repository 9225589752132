import { DebugInfo } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FormFieldViewText from "components/FormFields/ViewText";

import { useLicensingDates } from "./useLicensingDates";

type AccountDetailsSelfHostedProps = {
  debugInfo?: DebugInfo;
};

const AccountDetailsSelfHosted = ({ debugInfo }: AccountDetailsSelfHostedProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { startDate, endDate } = useLicensingDates(debugInfo);

  return (
    <>
      {debugInfo?.selfHostedVersion && (
        <FormFieldViewText label="Self-hosted version" value={debugInfo.selfHostedVersion} />
      )}
      {debugInfo?.commitSha && (
        <FormFieldViewText label="Build" value={debugInfo.commitSha} withCopy />
      )}
      <FormFieldViewText
        label="Login received from the Identity Provider"
        value={viewer.id}
        withCopy
      />
      <FormFieldViewText label="License start date" value={startDate} />
      <FormFieldViewText label="License expiry date" value={endDate} />
    </>
  );
};

export default AccountDetailsSelfHosted;
