import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PublicWorkerPool } from "types/generated";

import { POLL_INTERVAL } from "./constants";
import { GET_PUBLIC_WORKER_POOL } from "./gql";

const useSearchPublicWorkerPool = () => {
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data, stopPolling, networkStatus, previousData } = useQuery<{
    publicWorkerPool: PublicWorkerPool;
  }>(GET_PUBLIC_WORKER_POOL, {
    onError,
    pollInterval: POLL_INTERVAL,
    // APOLLO CLIENT UPDATE
  });

  return {
    publicWorkerPool: data?.publicWorkerPool || previousData?.publicWorkerPool,
    error,
    stopPolling,
    isPageLoading: loading && !data?.publicWorkerPool && networkStatus === NetworkStatus.loading,
  };
};

export default useSearchPublicWorkerPool;
