import { useEffect, useRef } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { LABEL_FOLDER_KEY } from "constants/labels";

import { normalizeTags } from "./helpers";

import "@pathofdev/react-tag-input/build/index.css";
import "./styles.css";

type Tags = string[];

type TagInputProps = {
  tags: Tags;
  entity?: string;
  placeholder?: string;
  readOnly?: boolean;
  setTags?: (tags: Tags) => void;
};

const TagInput = ({
  entity,
  placeholder,
  readOnly = false,
  setTags = () => false,
  tags,
}: TagInputProps) => {
  const { reportError } = useTypedContext(FlashContext);
  const tagInputRef = useRef<ReactTagInput>(null);
  const isValidRef = useRef(true);

  useEffect(() => {
    if (tagInputRef.current) {
      const currentRef = tagInputRef.current;
      const input = currentRef.inputRef.current;

      if (input) {
        const addNewTag = (e: Event) => {
          e.stopPropagation();
          const target = e.target as HTMLInputElement;
          if (!(tags.indexOf(target.value) !== -1) && target.value.length > 0) {
            currentRef.addTag(target.value);
          }
        };

        input.addEventListener("blur", (e) => addNewTag(e), false);

        return () => {
          input.removeEventListener("blur", (e) => addNewTag(e), false);
        };
      }
    }

    return undefined;
  });

  const handleChange = (tags: Tags) => {
    if (isValidRef.current) {
      setTags(tags);
    }
  };

  const validator = (value: string) => {
    let validatorState = true;
    if (tags.indexOf(value) !== -1 && tagInputRef.current?.state.input !== "") {
      reportError({ message: `${entity || ""} '${value}' already added` });
      validatorState = false;
    }

    if (!value.trim()) {
      reportError({ message: "Empty label is not allowed" });
      validatorState = false;
    }

    if (value.trim() === LABEL_FOLDER_KEY) {
      reportError({ message: `${entity || ""} '${LABEL_FOLDER_KEY}' is a reserved keyword` });
      validatorState = false;
    }

    isValidRef.current = validatorState;

    return validatorState;
  };

  let placeholderText = placeholder;
  if (tags.length === 0) {
    placeholderText += " (optional)";
  }

  return (
    <ReactTagInput
      tags={normalizeTags(tags, readOnly)}
      editable={true}
      onChange={handleChange}
      placeholder={placeholderText}
      readOnly={readOnly}
      removeOnBackspace={true}
      validator={validator}
      ref={tagInputRef}
    />
  );
};

export default TagInput;
