import Typography from "ds/components/Typography";

const Documentation = () => (
  <Typography tag="p" variant="p-body2">
    Attaching a policy to your module gives you the possibility to control how many approvals you
    need for a run, control what resources can be created and what parameters those resources can
    have, where to send notifications on a failed/successful run, what happens when a Pull Request
    is open and more.
  </Typography>
);

export default Documentation;
