import { useState } from "react";

import Box from "ds/components/Box";
import { Policy } from "types/generated";
import useTakenPolicyNames from "views/Account/Policies/useTakenPolicyNames";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import { hasSpaceManageOrWriteAccess } from "utils/user";

import { GET_POLICY } from "../gql";
import PolicySimulation from "../../PolicySimulation";
import PolicyEditDrawer from "../../PolicyEditDrawer";
import PolicyDetailsBodyContent from "./Content";
import styles from "./styles.module.css";

type PolicyDetailsBodyProps = {
  policy: Policy;
  body: string;
  isBodyReadyToUpdate: boolean;
  setBody: (value: string) => void;
  isUpdateDrawerVisible: boolean;
  setIsUpdateDrawerVisible: (value: boolean) => void;
};

const PolicyDetailsBody = ({
  policy,
  body,
  isBodyReadyToUpdate,
  setBody,
  isUpdateDrawerVisible,
  setIsUpdateDrawerVisible,
}: PolicyDetailsBodyProps) => {
  const canManagePolicy = hasSpaceManageOrWriteAccess(policy.spaceDetails.accessLevel);

  const handleEditorChange = (value?: string) => {
    setBody(value || "");
  };

  const [isSimulationVisible, setIsSimulationVisible] = useState(false);

  const takenPolicyNames = useTakenPolicyNames();

  return (
    <Box justify="stretch" grow="1">
      {canManagePolicy && (
        <PolicyEditDrawer
          analyticsPage={AnalyticsPagePolicy.PolicyDetails}
          takenPolicyNames={takenPolicyNames}
          policy={policy}
          isDrawerVisible={isUpdateDrawerVisible}
          onCloseDrawer={() => setIsUpdateDrawerVisible(false)}
          refetchQueries={[
            {
              query: GET_POLICY,
              variables: {
                policyId: policy.id,
              },
            },
          ]}
        />
      )}
      <PolicyDetailsBodyContent
        body={body}
        handleEditorChange={handleEditorChange}
        policy={policy}
        isBodyReadyToUpdate={isBodyReadyToUpdate}
        isSimulationVisible={isSimulationVisible}
        canManagePolicies={canManagePolicy}
      />

      {canManagePolicy && (
        <PolicySimulation
          className={styles.simulationWrapper}
          policyId={policy.id}
          body={body}
          type={policy.type}
          isSimulationVisible={isSimulationVisible}
          setIsSimulationVisible={setIsSimulationVisible}
          analyticsPage={AnalyticsPagePolicy.PolicyDetails}
        />
      )}
    </Box>
  );
};

export default PolicyDetailsBody;
