import { GithubIntegration, MutationGithubIntegrationUpdateArgs, VcsCheck } from "types/generated";

import { ManageGitHubManualFormFields } from "./types";

export const getFormDefaultValues = (
  integration?: GithubIntegration
): ManageGitHubManualFormFields => ({
  vcsChecks: integration?.vcsChecks || VcsCheck.Individual,
});

export const mapUpdateGitHubIntegrationInputValues = (
  values: ManageGitHubManualFormFields
): MutationGithubIntegrationUpdateArgs => ({
  vcsChecks: values.vcsChecks,
});
