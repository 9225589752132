import Box from "ds/components/Box";
import Link from "ds/components/Link";
import Typography from "ds/components/Typography";

type Props = {
  onClick: () => void;
  text: string;
  query: string;
};

const OutputReferencesDrawerSelectOptionAddNonExisting = ({ text, onClick, query }: Props) => {
  const hasQuery = query.trim().length > 0;

  return (
    <Box direction="column" padding="medium" gap="small" justify="center" align="start">
      <Typography tag="p" variant="p-body2">
        {text}{" "}
        {hasQuery && (
          <>
            <Link onClick={onClick}>Click here</Link> to add reference anyway.
          </>
        )}
      </Typography>
    </Box>
  );
};

export default OutputReferencesDrawerSelectOptionAddNonExisting;
