import Box, { BoxProps } from "../../Box";

type TileContentProps = Pick<
  BoxProps,
  "justify" | "align" | "gap" | "className" | "direction" | "children"
>;

const TileContent = ({
  children,
  direction = "column",
  gap = "medium",
  align,
  className,
  justify,
}: TileContentProps) => {
  return (
    <Box
      className={className}
      justify={justify}
      direction={direction}
      gap={gap}
      align={align}
      fullWidth
    >
      {children}
    </Box>
  );
};

export default TileContent;
