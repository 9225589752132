import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";
import { CollapsiblePanelAriaLevel } from "./types";

type CollapsiblePanelHeaderProps = BoxProps & {
  ariaLevel?: CollapsiblePanelAriaLevel; // Use only if the Trigger is the only element in the CollapsiblePanelHeader
};

function CollapsiblePanelHeader({
  children,
  className,
  ariaLevel,
  ...rest
}: CollapsiblePanelHeaderProps) {
  const ariaProps = ariaLevel ? { role: "heading", "aria-level": ariaLevel } : {};

  return (
    <Box fullWidth className={cx(styles.header, className)} {...ariaProps} {...rest}>
      {children}
    </Box>
  );
}

export default CollapsiblePanelHeader;
