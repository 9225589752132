import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { ApolloError, useLazyQuery } from "@apollo/client";

import { StackVendorFormFields } from "../../types";
import { CHECK_TERRAGRUNT_VERSION } from "./gql";

const DEBOUNCE_TIMEOUT = 300;

const useAsyncTerragruntVersionValidation = (
  setAsyncValidationLoading: (isLoading: boolean) => void
) => {
  const { setError, getValues, formState, clearErrors } = useFormContext<StackVendorFormFields>();

  const terragruntError = formState.errors.terragrunt?.terragruntVersion;
  const terraformError = formState.errors.terragrunt?.workflowVersion;
  const timeout = useRef<NodeJS.Timeout>();

  const stopLoading = () => {
    if (!timeout.current) {
      setAsyncValidationLoading(false);
    }
  };

  const onTerragruntQueryError = (error: ApolloError) => {
    stopLoading();
    if ((!terragruntError || terragruntError.type === "custom") && !terraformError) {
      setError("terragrunt.terragruntVersion", {
        type: "custom",
        message: error.message,
      });
    }
  };

  const onTerragruntQueryComplete = () => {
    stopLoading();
    if (terragruntError && terragruntError.type === "custom") {
      clearErrors("terragrunt.terragruntVersion");
    }
  };

  const [checkTerragruntVersion] = useLazyQuery(CHECK_TERRAGRUNT_VERSION, {
    onError: onTerragruntQueryError,
    onCompleted: onTerragruntQueryComplete,
  });

  const values = getValues();
  const terragruntValues = values.terragrunt;

  useEffect(() => {
    if (
      terragruntValues.tool &&
      terragruntValues.terragruntVersion.value &&
      terragruntValues.workflowVersion.value
    ) {
      setAsyncValidationLoading(true);
      timeout.current = setTimeout(() => {
        if (terragruntError && terragruntError.type === "custom") {
          clearErrors("terragrunt.terragruntVersion");
        }
        checkTerragruntVersion({
          variables: {
            terragruntConstraints: terragruntValues.terragruntVersion.value,
            terraformConstraints: terragruntValues.workflowVersion.value,
            tool: terragruntValues.tool,
          },
        });
        timeout.current = undefined;
      }, DEBOUNCE_TIMEOUT);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }

      setAsyncValidationLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terragruntValues.terragruntVersion, terragruntValues.workflowVersion]);
};

export default useAsyncTerragruntVersionValidation;
