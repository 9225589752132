import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { RunState, Module, Stack } from "types/generated";
import { downloadFileFromURL } from "utils/file";
import { Download } from "components/icons";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";
import useAnalytics from "hooks/useAnalytics";
import { RunContext } from "views/shared/run/Context";

import { GET_MODULE_LOGS_DOWNLOAD_URL, GET_STACK_LOGS_DOWNLOAD_URL } from "./gql";

type DownloadLogsButtonProps = {
  expired: boolean;
  state: RunState;
  stateVersion?: number | null;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const DownloadLogsButton = ({
  state,
  stateVersion,
  expired,
  runId,
  stackId,
  isModuleRun,
}: DownloadLogsButtonProps) => {
  const { onError, reportError } = useTypedContext(FlashContext);
  const {
    run: { state: runState },
  } = useTypedContext(RunContext);
  const trackSegmentAnalyticsEvent = useAnalytics();

  const [getDownloadUrl, { loading }] = useLazyQuery<{ module: Module } | { stack: Stack }>(
    isModuleRun ? GET_MODULE_LOGS_DOWNLOAD_URL : GET_STACK_LOGS_DOWNLOAD_URL,
    {
      variables: {
        [isModuleRun ? "moduleId" : "stackId"]: stackId,
        runId,
        state,
        stateVersion,
      },
      onError,
    }
  );

  const handleDownloadCLick = useCallback(async () => {
    const { data } = await getDownloadUrl();

    if (!data) {
      return;
    }

    const entity = "module" in data ? data?.module : data?.stack;

    if (entity?.run?.logsDownloadURL?.expired || !entity?.run?.logsDownloadURL?.url) {
      reportError({ message: "Logs have expired or are not available. Please try again later." });
      return;
    }

    const fileName = `${entity.id}-${runId}-${state}.logs.txt`;

    downloadFileFromURL(entity.run.logsDownloadURL.url, fileName);
    trackSegmentAnalyticsEvent("Run Logs - download logs", {
      logsState: state,
      runState,
    });
  }, [getDownloadUrl, runId, state, reportError, trackSegmentAnalyticsEvent, runState]);

  return (
    <Tooltip
      active={expired}
      on={(props) => (
        <Button
          {...props}
          variant="secondary"
          startIcon={Download}
          size="small"
          onClick={handleDownloadCLick}
          loading={loading}
          disabled={loading || expired}
        >
          Download logs
        </Button>
      )}
    >
      These logs have expired.
    </Tooltip>
  );
};

export default DownloadLogsButton;
