import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroup, ManagedUserGroupCreateInput } from "types/generated";

import { CREATE_MANAGED_USER_GROUP } from "../gql";

const useAddGroup = () => {
  const { onError } = useTypedContext(FlashContext);

  const [createManagedUserGroup] = useMutation<{ managedUserGroupCreate: ManagedUserGroup }>(
    CREATE_MANAGED_USER_GROUP,
    {
      onError,
      refetchQueries: ["GetManagedUserGroups"],
    }
  );

  const onCreate = (formData: ManagedUserGroupCreateInput) => {
    return createManagedUserGroup({
      variables: {
        input: formData,
      },
    });
  };

  return { onCreate };
};

export default useAddGroup;
