export const TOOLTIP_OFFSET = { top: -30, left: 80 };
export const CHART_THICKNESS = 24;
export const EMPTY_DATA = [
  {
    value: 1,
    color: "var(--colors-gray-100)",
    activeColor: "var(--colors-gray-100)",
    inactiveColor: "var(--colors-gray-100)",
    id: "empty",
    label: "empty",
  },
];
