import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  AwsIntegrationStackAttachment,
  AzureIntegrationStackAttachment,
  Maybe,
  StackIntegrationGcp,
} from "types/generated";

import { DETACH_AWS_INTEGRATION, DETACH_AZURE_INTEGRATION, DETACH_GCP_INTEGRATION } from "../gql";
import { ModuleCreationCloud } from "../../types";
import { useNewModuleAnalyticsSegmentEvent } from "../../useNewModuleAnalyticsSegmentEvent";

type AwsParams = {
  type: ModuleCreationCloud.AWS;
  attachmentId: string;
};

type AzureParams = {
  type: ModuleCreationCloud.Azure;
  attachmentId: string;
};

type GcpParams = {
  type: ModuleCreationCloud.GCP;
  moduleId: string;
};

type Params = AwsParams | AzureParams | GcpParams;

type UseDetachCloudOptions = {
  refetchQueries?: string[];
};

const useDetachCloud = (options?: UseDetachCloudOptions) => {
  const refetchQueries = options?.refetchQueries;
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [detachAwsIntegration, { loading: detachAwsLoading }] = useMutation<{
    awsIntegrationDetach: Maybe<AwsIntegrationStackAttachment>;
  }>(DETACH_AWS_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const detachAws = useCallback(
    ({ attachmentId }: AwsParams) => {
      detachAwsIntegration({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.awsIntegrationDetach?.id) {
            reportSuccess({ message: "AWS integration was detached" });
            trackSegmentEvent("AWS integration detached");
          }
        })
        .catch(onError);
    },
    [detachAwsIntegration, reportSuccess, onError, trackSegmentEvent]
  );

  const [detachAzureIntegration, { loading: detachAzureLoading }] = useMutation<{
    azureIntegrationDetach: Maybe<AzureIntegrationStackAttachment>;
  }>(DETACH_AZURE_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const detachAzure = useCallback(
    ({ attachmentId }: AzureParams) => {
      detachAzureIntegration({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.azureIntegrationDetach?.id) {
            reportSuccess({ message: "Azure integration was detached" });
            trackSegmentEvent("Azure integration detached");
          }
        })
        .catch(onError);
    },
    [detachAzureIntegration, reportSuccess, onError, trackSegmentEvent]
  );

  const [detachGcpIntegration, { loading: detachGcpLoading }] = useMutation<{
    stackIntegrationGcpDelete: Maybe<StackIntegrationGcp>;
  }>(DETACH_GCP_INTEGRATION, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const detachGcp = useCallback(
    ({ moduleId }: GcpParams) => {
      detachGcpIntegration({ variables: { moduleId } })
        .then(({ data }) => {
          if (data?.stackIntegrationGcpDelete?.activated === false) {
            reportSuccess({ message: "GCP integration was detached" });
            trackSegmentEvent("GCP integration detached");
          }
        })
        .catch(onError);
    },
    [detachGcpIntegration, reportSuccess, onError, trackSegmentEvent]
  );

  const detach = useCallback(
    (params: Params) => {
      switch (params.type) {
        case ModuleCreationCloud.AWS:
          return detachAws(params);
        case ModuleCreationCloud.Azure:
          return detachAzure(params);
        case ModuleCreationCloud.GCP:
          return detachGcp(params);
      }
    },
    [detachAws, detachAzure, detachGcp]
  );

  return {
    detach,
    detachLoading: detachAwsLoading || detachAzureLoading || detachGcpLoading,
  };
};

export default useDetachCloud;
