import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { BitbucketDatacenterIntegration, Maybe } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { DELETE_BITBUCKET_DATA_CENTER_INTEGRATION } from "./gql";

const useDeleteBitbucketDataCenterIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [bitbucketDatacenterIntegrationDelete, { loading }] = useMutation<{
    bitbucketDatacenterIntegrationDelete: Maybe<BitbucketDatacenterIntegration>;
  }>(DELETE_BITBUCKET_DATA_CENTER_INTEGRATION, {
    refetchQueries: ["SearchVCSIntegrations"],
    onError,
  });

  const deleteBitbucketDataCenterIntegration = useCallback(
    (id: string, attachedEntitiesQty: number, successCallback?: () => void) => {
      return bitbucketDatacenterIntegrationDelete({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `${
                data?.bitbucketDatacenterIntegrationDelete?.name || "Bitbucket Data Center"
              } integration successfully deleted`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration - Unlink Confirm", {
              attachedEntitiesQty,
              integration: "Bitbucket Data Center",
            });
          }
        })
        .catch(onError);
    },
    [bitbucketDatacenterIntegrationDelete, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return { deleteBitbucketDataCenterIntegration, loading };
};

export default useDeleteBitbucketDataCenterIntegration;
