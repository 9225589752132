import { gql } from "@apollo/client";

export const CREATE_STACK_SCHEDULED_RUN = gql`
  mutation StackScheduledRunCreate($stack: ID!, $input: ScheduledRunInput!) {
    stackScheduledRunCreate(stack: $stack, input: $input) {
      id
    }
  }
`;

export const UPDATE_STACK_SCHEDULED_RUN = gql`
  mutation StackScheduledRunUpdate($stack: ID!, $scheduledRun: ID!, $input: ScheduledRunInput!) {
    stackScheduledRunUpdate(stack: $stack, scheduledRun: $scheduledRun, input: $input) {
      id
    }
  }
`;

export const DELETE_STACK_SCHEDULED_RUN = gql`
  mutation StackScheduledRunDelete($stack: ID!, $scheduledRun: ID!) {
    stackScheduledRunDelete(stack: $stack, scheduledRun: $scheduledRun) {
      id
    }
  }
`;
