import { Controller, FormProvider, useForm } from "react-hook-form";

import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import Input from "ds/components/Input";
import FormField from "ds/components/Form/Field";
import SecretInput from "ds/components/SecretInput";
import HeadersField from "components/Forms/HeadersField";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { validateRequiredURL } from "utils/formValidators";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";

import { AuditTrailFormFields, AuditTrailFormProps } from "./types";
import { useSubmitAuditTrailWebhook } from "./useSubmitAuditTrailWebhook";
import parseAuditTrailFormFields from "./parseAuditTrailFormFields";

const EditDrawer = ({ onFormClose, integration }: AuditTrailFormProps) => {
  const {
    enabled = true,
    endpoint,
    secret,
    customHeaders,
    includeRuns = false,
  } = integration || {};
  const isEdit = typeof endpoint !== "undefined";
  const defaultValues = {
    endpoint: endpoint || "",
    secret: secret || "",
    enabled: enabled,
    includeRuns: includeRuns,
    headers: [],
    enabledHeaders: (customHeaders?.length || 0) > 0,
  };

  const form = useForm<AuditTrailFormFields>({ defaultValues, mode: "onChange" });

  const { control, register, handleSubmit, formState } = form;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAuditTrail,
  });

  const handleCancel = () => {
    trackSegmentAnalyticsEvent(typeof endpoint === "undefined" ? "Cancel" : "Edit Cancel");
    onFormClose();
  };

  const { onSubmitAuditTrail, loading } = useSubmitAuditTrailWebhook({
    isEdit,
    trackSegmentAnalyticsEvent,
    onFormClose,
  });

  const onSubmit = (formFields: AuditTrailFormFields) => {
    const inputs = parseAuditTrailFormFields({ formFields, customHeaderKeys: customHeaders || [] });

    onSubmitAuditTrail(inputs);
  };

  const isDisabledSubmit = !formState.isDirty || loading;

  return (
    <FormProvider {...form}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isEdit ? "Edit audit trail" : "Set up audit trail"} />
        <DrawerCloseIcon handleCloseDrawer={onFormClose} />
      </DrawerHeader>
      <DrawerBody gap="x-large" hasDivider>
        <FormField
          noMargin
          label="Endpoint"
          tooltipInfo="Full endpoint to send a POST request to"
          error={formState.errors.endpoint?.message}
        >
          {({ ariaInputProps }) => (
            <Input
              error={!!formState.errors.endpoint}
              placeholder="Enter the endpoint here"
              {...register("endpoint", { validate: validateRequiredURL() })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField noMargin label="Secret" tooltipInfo="Secret to verify payload" isOptional>
          {({ ariaInputProps }) => (
            <SecretInput
              placeholder="Enter the secret here"
              {...register("secret")}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <Controller
          name="enabled"
          control={control}
          render={({ field }) => (
            <Toggle variant="switch" checked={field.value} onChange={field.onChange}>
              Enable
            </Toggle>
          )}
        />
      </DrawerBody>
      <DrawerBody gap="x-large" hasDivider>
        <Box gap="small" direction="column">
          <Typography variant="p-t5" tag="p">
            Include runs
          </Typography>
          <Typography variant="p-body2" tag="p" color="secondary">
            Controls whether run state change events will be sent to the audit webhook in addition
            to standard audit events.
          </Typography>
        </Box>
        <Controller
          name="includeRuns"
          control={control}
          render={({ field }) => (
            <Toggle variant="switch" checked={field.value} onChange={field.onChange}>
              Include
            </Toggle>
          )}
        />
      </DrawerBody>
      <DrawerBody gap="x-large">
        <HeadersField isEditMode previousHeaderKeys={customHeaders} />
      </DrawerBody>
      <DrawerFooter>
        <DrawerFooterActions>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isDisabledSubmit}
            loading={loading}
          >
            Save
          </Button>
        </DrawerFooterActions>
      </DrawerFooter>
    </FormProvider>
  );
};

export default EditDrawer;
