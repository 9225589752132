import { memo } from "react";
import { noop } from "lodash-es";

import Filters from "components/Filters";
import { FilterItem } from "components/Filters/types";

import {
  FILTERS_ORDER_SETTINGS_KEY,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../constants";
import UsersFiltersLayoutHeader from "./Header";

const emptyFilters: FilterItem[] = [];
const emptyFiltersMap = new Map();
const mockedFunction = noop;

const UsersFiltersLayout = () => {
  // TODO this will be either remove or populated with data from backend once backend decide how they want to handle sorting
  const sortOptions = [
    {
      value: "name",
      label: "name",
    },
    {
      value: "role",
      label: "role",
    },
    {
      value: "space",
      label: "space",
    },
    {
      value: "group",
      label: "group",
    },
    {
      value: "lastLogin",
      label: "last login",
    },
    {
      value: "loginMethod",
      label: "login method",
    },
    {
      value: "status",
      label: "status",
    },
  ];

  return (
    // NO FILTERS PANEL ONLY SORTING HEADER
    <Filters
      filters={emptyFilters}
      filtersItemsOptionsMap={emptyFiltersMap}
      filtersLoading={false}
      sortOptions={sortOptions}
      initialSortOption={INITIAL_SORT_OPTION}
      initialSortDirection={INITIAL_SORT_DIRECTION}
      pollActiveSections={mockedFunction}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="users"
      setCurrentSavedView={mockedFunction}
    >
      <UsersFiltersLayoutHeader />
    </Filters>
  );
};

export default memo(UsersFiltersLayout);
