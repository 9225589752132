const createElementWatcher = async (selector: string): Promise<HTMLElement> => {
  for (let attempt = 0; attempt < 10; attempt++) {
    const element = document.querySelector<HTMLElement>(selector);
    if (element) {
      return element;
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  return Promise.reject();
};

const listenToResize = () => {
  let timeoutId: NodeJS.Timeout | null = null;

  const resizeListener = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      updateBeamerTooltipPosition();
    }, 150);
  };

  window.addEventListener("resize", resizeListener);
};

const updateBeamerTooltipPosition = async () => {
  try {
    const tooltipVerticalOffset = 16;

    const [button, buttonCountBadge, tooltip, tooltipArrow] = await Promise.all([
      createElementWatcher("#beamerButton.beamer_beamerSelector"),
      createElementWatcher("#beamerButton.beamer_beamerSelector .beamer_icon"),
      createElementWatcher("#beamerLastPostTitle.active"),
      createElementWatcher("#beamerLastPostTitle.active .popper__arrow"),
    ]);

    const buttonRect = button.getBoundingClientRect();
    const buttonCountBadgeRect = buttonCountBadge.getBoundingClientRect();
    const arrowStyles = window.getComputedStyle(tooltipArrow, ":before");

    // Position the tooltip above the update count badge inside the Beamer button
    // The tooltip will be displayed above the badge with a slight vertical offset
    // The horizontal position will be aligned with the left position of the button
    tooltip.style.transform = `
      translate3d(
        ${buttonRect.left}px,
        calc(${buttonCountBadgeRect.top - tooltipVerticalOffset}px - 100%),
        0
      )
    `;

    // Avoid flickering when the tooltip is first shown
    tooltip.style.setProperty("display", "flex", "important");

    // Position the arrow in the middle of the badge
    tooltipArrow.style.left = `
      calc(
        ${buttonCountBadgeRect.left}px - ${arrowStyles.getPropertyValue("width")} / 2 + ${
          buttonCountBadgeRect.width / 2
        }px
      )
    `;
  } catch {
    // Either tooltip was already shown or the Beamer button is not present
  }
};

export const initBeamerTooltipPositionHack = () => {
  listenToResize();
  window.updateBeamerTooltipPosition = updateBeamerTooltipPosition;
};
