import { gql } from "@apollo/client";

export const GET_STACK_VENDOR_RESOURCES = gql`
  query GetStackVendorResources(
    $includeTerraform: Boolean = true
    $includeTerragrunt: Boolean = true
    $includeKubectl: Boolean = true
  ) {
    terraformVersions @include(if: $includeTerraform)
    terragruntVersions @include(if: $includeTerragrunt)
    kubectlVersions @include(if: $includeKubectl)
    openTofuVersions @include(if: $includeTerraform)
  }
`;
