import { gql } from "@apollo/client";

export const SEARCH_DEPENDENCIES_STACKS = gql`
  query SearchDependenciesStacks($input: SearchInput!) {
    searchStacks(input: $input) {
      edges {
        cursor
        node {
          id
          name
          spaceDetails {
            accessLevel
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const CREATE_STACK_DEPENDENCIES_IN_BATCH = gql`
  mutation CreateStackDependenciesInBatch($input: StackDependencyBatchInput!) {
    stackDependenciesCreateInBatch(input: $input) {
      id
    }
  }
`;

export const DELETE_STACK_DEPENDENCY = gql`
  mutation DeleteStackDependency($id: ID!) {
    stackDependencyDelete(id: $id) {
      id
    }
  }
`;

export const GET_STACK_OUTPUTS = gql`
  query GetStackOutputs($stackId: ID!) {
    stack(id: $stackId) {
      id
      outputs {
        id
        sensitive
        value
      }
    }
  }
`;
