import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import differenceWith from "lodash-es/differenceWith";

import { Context } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SelectOption } from "ds/components/Select/types";

import { GET_ATTACHABLE_CONTEXTS_IN_SPACE } from "./gql";

type AttachableContextOption = SelectOption & {
  value: Context["name"];
};

type UseGetAttachableContextsProps = {
  spaceId: string;
  attachedContextIds: string[];
  skip?: boolean;
};

type UseGetAttachableContextsData = {
  contexts: AttachableContextOption[];
  loading: boolean;
};

const useGetAttachableContexts = ({
  spaceId,
  attachedContextIds,
}: UseGetAttachableContextsProps): UseGetAttachableContextsData => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{ attachableContextsInSpace: Context[] }>(
    GET_ATTACHABLE_CONTEXTS_IN_SPACE,
    {
      onError,
      variables: { id: spaceId },
    }
  );

  const contexts = useMemo(
    () =>
      differenceWith(
        (data?.attachableContextsInSpace || []).map((context) => ({
          value: context.id,
          label: context.name,
        })),
        attachedContextIds,
        (a, b) => a.value === b
      ),
    [data?.attachableContextsInSpace, attachedContextIds]
  );

  return {
    contexts,
    loading,
  };
};

export default useGetAttachableContexts;
