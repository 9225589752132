import cx from "classnames";
import { Button } from "react-aria-components";

import { IconComponent } from "types/Icon";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles/icon.module.css";

type IconButtonProps = {
  icon: IconComponent;
  className?: string;
  text?: string;
  info?: boolean;
  onPress?: () => void;
};

/**
 * @deprecated use DS Button or IconAction instead
 */
const IconButton = ({ icon: Icon, text, info, className, ...otherProps }: IconButtonProps) => (
  <Button
    className={cx(styles.iconButton, className, {
      [styles.info]: info,
    })}
    {...otherProps}
  >
    <Icon />

    {text && (
      <TextEllipsis tooltip={text} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <span {...props} className={cx(styles.text, props.className)}>
            {text}
          </span>
        )}
      </TextEllipsis>
    )}
  </Button>
);

export default IconButton;
