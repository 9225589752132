import { type ReactNode } from "react";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type BulkActionsDrawerItemProps = {
  children: ReactNode;
} & BoxProps;

const BulkActionsDrawerItem = ({
  children,
  gap = "medium",
  padding = "x-large",
  align = "center",
  surfaceColor = "primary",
  zeroMinWidth = true,
  fullWidth = true,
  ...restProps
}: BulkActionsDrawerItemProps) => {
  return (
    <Box
      gap={gap}
      padding={padding}
      align={align}
      className={styles.drawerItem}
      surfaceColor={surfaceColor}
      zeroMinWidth={zeroMinWidth}
      fullWidth={fullWidth}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default BulkActionsDrawerItem;
