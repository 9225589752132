import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { AwsIntegration } from "types/generated";

import AWSIntegrationWarning from "./AWSIntegrationWarning";
import CardForm from "./CardForm";
import IntegrationField from "./Fields/IntegrationField";
import ReadToggleField from "./Fields/ReadToggleField";
import WriteToggleField from "./Fields/WriteToggleField";
import WriteOnlyWarning from "./WriteOnlyWarning";
import SubmitButton from "./SubmitButton";
import useAttachCloud from "../hooks/useAttachCloud";
import { ModuleCreationCloud } from "../../types";

type AWSIntegrationProps = {
  attachableIntegrations: AwsIntegration[] | undefined;
  moduleId: string;
  onSuccess: () => void;
  refetchQueries?: string[];
};

type FormValues = {
  integrationId: string;
  read: boolean;
  write: boolean;
};

const AWSIntegration = ({
  attachableIntegrations,
  moduleId,
  onSuccess,
  refetchQueries,
}: AWSIntegrationProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      integrationId: "",
      read: false,
      write: false,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;

  const { attach, isIntegrationAwsWarningVisible, hideIntegrationAwsWarning } = useAttachCloud({
    moduleId,
    onSuccess,
    refetchQueries,
  });

  useEffect(() => {
    const subscription = watch(hideIntegrationAwsWarning);
    return subscription.unsubscribe;
  }, [hideIntegrationAwsWarning, watch]);

  useObserveForWarning(
    isDirty,
    <>
      You have not attached the <b>selected AWS integration</b>. Do you want to continue without
      attaching it?
    </>
  );

  const onSubmit = (data: FormValues) =>
    attach({
      type: ModuleCreationCloud.AWS,
      integrationId: data.integrationId,
      read: data.read,
      write: data.write,
    });

  const options = useMemo(() => {
    return attachableIntegrations
      ? attachableIntegrations.map((value) => ({ value: value.id, label: value.name }))
      : [];
  }, [attachableIntegrations]);

  return (
    <FormProvider {...form}>
      <CardForm onSubmit={handleSubmit(onSubmit)}>
        <IntegrationField options={options} />
        <ReadToggleField />
        <WriteToggleField />
        <WriteOnlyWarning />
        <SubmitButton />
      </CardForm>
      {isIntegrationAwsWarningVisible && <AWSIntegrationWarning moduleId={moduleId} />}
    </FormProvider>
  );
};

export default AWSIntegration;
