import EmptyState from "ds/components/EmptyState";
import { NoResultsColored } from "components/icons";
import Box from "ds/components/Box";

const VCSIntegrationsHasNoResults = () => {
  return (
    // TODO: sync the design with the design team
    <Box direction="column" grow="1" fullWidth>
      <EmptyState title="No results" icon={NoResultsColored} caption="Try different filters." />
    </Box>
  );
};

export default VCSIntegrationsHasNoResults;
