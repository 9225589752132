import { AccountViewer } from "components/form/graphql/types";
import { Stack } from "types/generated";

import { canManageStacksAndRuns, hasAtLeastStackWriteAccess } from "../utils";

/**
 * Check if the current user can unlock the stack
 * * a user with the write access can't unlock a stack locked by another user
 *
 * @param stack
 * @param viewer
 * @returns boolean
 * */
export const canUnlockStack = (stack: Stack, viewer: AccountViewer | undefined): boolean => {
  const isLocked = !!stack.lockedAt && !!stack.lockedBy;
  const hasPermissionToUnlock =
    canManageStacksAndRuns(stack, viewer) ||
    (viewer?.id === stack.lockedBy && hasAtLeastStackWriteAccess(stack));

  return isLocked && hasPermissionToUnlock;
};
