import { moduleDocument, runDocument, stackDocument } from "./documents";

const Initialization = `package spacelift

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
# ⚠️ Initialization policies only take effect once attached to a Stack or Module ⚠️
#
# Initialization policy can prevent a run from being initialized, thus blocking
# any custom code or commands from being executed. As input, it
# receives a JSON document like this:
#
# {
#   "commit": {
#     "author": "string",
#     "branch": "string",
#     "created_at": "number (timestamp in nanoseconds)",
#     "message": "string"
#   },
#   "request": {
#     "timestamp_ns": "number - current Unix timestamp in nanoseconds"
#   },
#   "run": {
${runDocument(5)}
#   },
#   "stack": {
${stackDocument(5)}
#   },
#   "stack": {
${moduleDocument(5)}
#   }
# }
#
# The policy may then define one or more "deny" rules along with a message, to
# prevent unwanted runs from being executed. Each "deny" rule will print out its
# message to user-facing log so please keep these civil and informative. Below
# are a few examples of what you can do.
#
# 1) Only allow some whitelisted commands to be executed before initialization:
# deny[sprintf("before_init command not whitelisted (%s)", [command])] {
#   whitelisted := {"terraform fmt -check"}
#   command := input.run.runtime_config.before_init[_]
#
#   not whitelisted[command]
# }
#
# 2) Prevent minor Terraform version bumps, while allowing patch changes:
# stackTF := input.stack.terraform_version
# runTF := input.run.runtime_config.terraform_version
#
# deny[msg] {
#   stackTF != null
#   msg := sprintf("must not skip a patch Terraform version (%s vs. %s)", [stackTF, runTF])
#
#   tfSemverDiff("patch") > 1
# }
#
# tfSemverDiff(key) = ret {
#   runVersion := semver(runTF)
#   stackVersion := semver(stackTF)
#
#   ret := runVersion[key] - stackVersion[key]
# }
#
# semver(string) = ret {
#   parts := [part | strParts := split(string, ".")
#                    part := to_number(strParts[_])]
#   ret := { "major": parts[0], "minor": parts[1], "patch": parts[2] }
# }
#
# 3) Don't run anything on weekends:
# deny["must not run anything on weekends"] {
#   today := time.weekday(input.request.timestamp_ns)
#   weekend := {"Saturday", "Sunday"}
#
#   weekend[today]
# }
#
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
`;

export default Initialization;
