import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type StackSettingsIntegrationsWebhooksDropdownProps = {
  onEditClick: () => void;
  onDetailsClick: () => void;
  canManageStack: boolean;
  endpoint: string;
  id: string;
  refetchQueriesOnDelete?: string[];
  stackId: string;
};

const StackSettingsIntegrationsWebhooksDropdown = ({
  onEditClick,
  refetchQueriesOnDelete,
  endpoint,
  id,
  stackId,
  onDetailsClick,
  canManageStack,
}: StackSettingsIntegrationsWebhooksDropdownProps) => {
  const handleDeleteClick = () => {
    showDeleteConfirmation({ endpoint, id, refetchQueriesOnDelete, stackId });
  };

  return (
    <DropdownMenuEllipsis tooltip="Webhook actions">
      <DropdownMenuItem onAction={onDetailsClick}>Details</DropdownMenuItem>
      {canManageStack && <DropdownMenuItem onAction={onEditClick}>Edit</DropdownMenuItem>}
      {canManageStack && (
        <DropdownMenuItem onAction={handleDeleteClick} danger>
          Delete
        </DropdownMenuItem>
      )}
    </DropdownMenuEllipsis>
  );
};

export default StackSettingsIntegrationsWebhooksDropdown;
