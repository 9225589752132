import {
  Popover,
  DialogTrigger,
  DialogProps,
  PopoverProps,
  DialogTriggerProps,
} from "react-aria-components";

import DropdownListNew from "./List/New";

export type DropdownProps = {
  children: DialogProps["children"];
  triggerComponent: React.ReactNode;
  placement?: PopoverProps["placement"];
  isOpen?: boolean;
  onOpenChange?: DialogTriggerProps["onOpenChange"];
  className?: string;
};

const DropdownNew = ({
  children,
  triggerComponent,
  placement = "bottom left",
  isOpen,
  onOpenChange,
  className,
}: DropdownProps) => {
  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
      {triggerComponent}
      <Popover placement={placement}>
        <DropdownListNew className={className}>{children}</DropdownListNew>
      </Popover>
    </DialogTrigger>
  );
};

export default DropdownNew;
