import Timer from "components/time/Timer";
import Typography from "ds/components/Typography";

type HistoryEntryDurationProps = {
  end: number | null;
  start: number;
};

const HistoryEntryDuration = ({ start, end }: HistoryEntryDurationProps) => {
  return (
    <Typography tag="span" variant="p-body3" color="secondary">
      <Timer since={start} until={end} />
    </Typography>
  );
};

export default HistoryEntryDuration;
