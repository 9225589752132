import { gql, TypedDocumentNode } from "@apollo/client";

import { Module, MutationStarSetArgs } from "types/generated";

type FetchModuleStarData = { starSet: Module };
export const STAR_MODULE: TypedDocumentNode<FetchModuleStarData, MutationStarSetArgs> = gql`
  mutation StarSet($id: ID!, $star: Boolean!) {
    starSet(id: $id, star: $star) {
      id
      starred
    }
  }
`;
