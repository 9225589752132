import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";

import useCycleWorkerPool from "../WorkerPools/useCycleWorkerPool";

type WorkerPoolCycleConfirmationProps = {
  name: string;
  id: string;
};

const WorkerPoolCycleConfirmation = create(function WorkerPoolCycleConfirmation({
  name,
  id,
}: WorkerPoolCycleConfirmationProps) {
  const { cycleWorkerPool, loading } = useCycleWorkerPool();

  const modal = useModal();

  const handleCycle = () => {
    cycleWorkerPool(id).then(() => modal.hide());
  };

  return (
    <ConfirmationModal
      title="Cycle worker pool"
      confirmationButtonLabel="Cycle"
      onConfirm={handleCycle}
      isLoading={loading}
      isDismissable={!loading}
    >
      <ConfirmationModalMessage>
        Would you like to cycle the <strong>{name}</strong> worker pool? This action cannot be
        undone.
      </ConfirmationModalMessage>
    </ConfirmationModal>
  );
});

export const showCycleConfirmation = (props: WorkerPoolCycleConfirmationProps) =>
  show(WorkerPoolCycleConfirmation, props);
