import { Copy } from "components/icons";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import ButtonIcon, { ButtonIconProps } from "ds/components/ButtonIcon";

type CopyFieldIconProps = {
  title: string;
  value: string;
  callback?: () => void;
} & Pick<ButtonIconProps, "disabled" | "tooltipMode" | "className">;

const CopyFieldIcon = ({ title, value, callback, ...restProps }: CopyFieldIconProps) => {
  const handleCopy = useCopyToClipboard(value, callback);

  return (
    <ButtonIcon {...restProps} onClick={handleCopy} icon={Copy} variant="ghost">
      {title}
    </ButtonIcon>
  );
};

export default CopyFieldIcon;
