import { useMemo } from "react";
import useLocalStorage from "@rehooks/local-storage";

import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import Toggle from "ds/components/Toggle";
import { RunState, RunType } from "types/generated";
import { isModuleRunType } from "utils/run";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { AnalyticsPage } from "hooks/useAnalytics";
import { canConfirmRun } from "shared/Run/useConfirmRun/accessValidation";
import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { hasAtLeastModuleWriteAccess } from "shared/Module/utils";
import { canDiscardRun } from "shared/Run/useDiscardRun/accessValidation";
import { isRunReviewable } from "shared/Run/useReviewRun/accessValidation";
import { canPerformAgain } from "shared/Stack/useTaskCreate/accessValidation";

import { RunElementsProvider } from "../../RunElementsContext";
import StackLockNotice from "../../components/StackLockNotice";
import ExpiredInfo from "../../components/ExpiredInfo";
import BlockerInfo from "../../components/BlockerInfo";
import NegativeRunInfo from "../../components/NegativeRunInfo";
import RunPageWrapper from "../../components/RunPageWrapper";
import CommentForm from "../../components/CommentForm";
import HistoryTimeline from "../../components/HistoryTimeline";
import WorkerPoolEntry from "../../entries/WorkerPoolEntry";
import NavigationBar from "../../components/NavigationBar";
import { createRunTimeline } from "../../utils/createRunTimeline";
import { RunEntryContext } from "../../types";
import { RunContext } from "../../../run/Context";

type RunHistoryProps = {
  analyticsPage?: AnalyticsPage | undefined;
};

const RunHistory = ({ analyticsPage }: RunHistoryProps) => {
  const { run, stack, versionId } = useTypedContext(RunContext);
  const { viewer } = useTypedContext(AccountContext);
  const isModuleRun = isModuleRunType(run.type);
  const isTaskRun = run.type === RunType.Task;
  const isModuleVersionRun = isModuleRun && !!versionId;
  const isProposedRun = run.type === RunType.Proposed;
  const [isSimpleView, setIsSimpleView] = useLocalStorage("simple-view", true);

  const isStack = stack.__typename === "Stack";
  const isModule = stack.__typename === "Module";

  const isRunBlocking =
    run.state === RunState.Pending ||
    run.state === RunState.Queued ||
    run.state === RunState.Ready ||
    run.state === RunState.Confirmed;

  const isRunProposed = run.type === RunType.Proposed;

  let canManage = false;
  if (isStack) {
    canManage = hasAtLeastStackWriteAccess(stack);
  } else if (isModule) {
    canManage = hasAtLeastModuleWriteAccess(stack);
  }

  const canConfirm = isStack && canConfirmRun(run, stack, viewer);

  const canPerformAgainValue = isStack && canPerformAgain(run, stack, viewer);

  const canDiscard = canManage && canDiscardRun(run);

  const canPrioritize = canManage && !!stack.workerPool && !run.finished;

  const canPromote = run.finished && isProposedRun;

  const canStop =
    run.state === RunState.Initializing ||
    run.state === RunState.Planning ||
    run.state === RunState.Applying ||
    run.state === RunState.Performing ||
    run.state === RunState.Destroying;

  const isReviewable = isRunReviewable(run);

  const shouldShowBlockerInfo = isStack && !isRunProposed && isRunBlocking;

  const shouldShowExpiredInfo = isStack && run.expired;
  const runHooks = useMemo(
    () => ({
      afterRun: run.runtimeConfig?.afterRun,
      afterApply: run.runtimeConfig?.afterApply,
      afterDestroy: run.runtimeConfig?.afterDestroy,
      afterInit: run.runtimeConfig?.afterInit,
      afterPerform: run.runtimeConfig?.afterPerform,
      afterPlan: run.runtimeConfig?.afterPlan,
      beforeApply: run.runtimeConfig?.beforeApply,
      beforeDestroy: run.runtimeConfig?.beforeDestroy,
      beforeInit: run.runtimeConfig?.beforeInit,
      beforePerform: run.runtimeConfig?.beforePerform,
      beforePlan: run.runtimeConfig?.beforePlan,
    }),
    [run.runtimeConfig]
  );

  let runQueryToRefetch = "GetRun";

  if (isModuleVersionRun) {
    runQueryToRefetch = "GetVersionRun";
  } else if (isModuleRun) {
    runQueryToRefetch = "GetModuleRun";
  }

  const entryContext = useMemo<RunEntryContext>(
    () => ({
      canManage,
      isReviewable,
      runHooks,
      isSimpleView: isSimpleView!,
      canConfirm,
      canDiscard,
      canPerformAgain: canPerformAgainValue,
      runId: run.id,
      stackId: stack.id,
      runTaskCommand: run.command,
      isStackDisabled: stack.isDisabled,
      isModuleRun,
      canRetry: run.canRetry,
      runRetryBlocker: run.retryBlocker,
      runState: run.state,
      canStop,
      isRunFinished: run.finished,
      isTaskRun,
      canPromote,
      runPromoteBlocker: run.promoteBlocker,
      isProposedRun,
      runQueryToRefetch,
      analyticsPage,
    }),
    [
      canManage,
      isReviewable,
      runHooks,
      isSimpleView,
      canConfirm,
      canDiscard,
      canPerformAgainValue,
      run.id,
      stack.id,
      run.command,
      stack.isDisabled,
      isModuleRun,
      run.canRetry,
      run.retryBlocker,
      run.state,
      canStop,
      run.finished,
      isTaskRun,
      canPromote,
      run.promoteBlocker,
      isProposedRun,
      runQueryToRefetch,
      analyticsPage,
    ]
  );

  const historyList = useMemo(
    () =>
      createRunTimeline(
        {
          history: run.history,
          policyReceipts: run.policyReceipts,
          reviews: run.reviews,
          comments: run.comments,
          planPoliciesOutcomes: run.planPoliciesOutcomes,
          runDependencies: run.dependsOn,
          externalDependencies: run.externalDependencies,
        },
        entryContext
      ),
    [
      entryContext,
      run.history,
      run.comments,
      run.reviews,
      run.policyReceipts,
      run.dependsOn,
      run.externalDependencies,
      run.planPoliciesOutcomes,
    ]
  );

  return (
    <Box direction="column" grow="1" fullWidth style={{ position: "relative" }} id="runWrapper">
      <PageInfo title="Run history">
        <Toggle
          variant="switch"
          checked={isSimpleView!}
          onChange={(e) => setIsSimpleView(e.target.checked)}
        >
          Simplified view
        </Toggle>
      </PageInfo>
      <RunElementsProvider>
        {shouldShowExpiredInfo && <ExpiredInfo stack={stack} />}
        {shouldShowBlockerInfo && <BlockerInfo stack={stack} run={run} />}
        {isStack && stack.lockedBy !== null && (
          <StackLockNotice lockedAt={stack.lockedAt} lockedBy={stack.lockedBy} viewer={viewer.id} />
        )}
        {run.expectFailure && <NegativeRunInfo />}
        <RunPageWrapper>
          <CommentForm runId={run.id} stackId={stack.id} runQueryToRefetch={runQueryToRefetch} />

          <HistoryTimeline>
            {isRunBlocking && (
              <WorkerPoolEntry
                workerPool={stack.workerPool}
                canPrioritize={canPrioritize}
                isRunPrioritized={run.isPrioritized}
                runId={run.id}
                stackId={stack.id}
                runQueryToRefetch={runQueryToRefetch}
              />
            )}
            {historyList}
          </HistoryTimeline>
        </RunPageWrapper>

        <NavigationBar next={run?.next?.id} previous={run?.previous?.id} />
      </RunElementsProvider>
    </Box>
  );
};

export default RunHistory;
