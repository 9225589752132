export const EDITOR_CONFIG = {
  glyphMargin: false,
  readOnly: true,
  scrollBeyondLastLine: false,
  scrollbar: {
    verticalScrollbarSize: 4,
    horizontalScrollbarSize: 4,
    alwaysConsumeMouseWheel: false,
  },
  unicodeHighlight: {
    invisibleCharacters: false,
  },
};
