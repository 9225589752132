import { LogoOpenTofu, SettingsGear, LogoTerraform, LogoTerragrunt } from "components/icons";
import {
  TERRAGRUNT_MANUALLY_PROVISIONED,
  VENDOR_CONFIG_TYPENAME,
  VENDOR_DICTIONARY,
  VENDOR_KEY,
} from "constants/vendor";
import { IconComponent } from "types/Icon";
import {
  RuntimeConfig,
  StackConfigVendor,
  TerraformWorkflowTool,
  TerragruntTool,
} from "types/generated";

import { getTerragruntToolDetails } from "./terragrunt";

export const isStackVendorTerraform = (typename?: string) => {
  return typename === VENDOR_CONFIG_TYPENAME.TERRAFORM;
};

type VendorDetails = {
  icon: IconComponent;
  text?: string;
};

// FYI: `vendorTool` is optional and is used for specific vendors, e.g. Terragrunt
type VendorToolDetails = {
  icon: IconComponent;
  text?: string;
};

type VendorInfo = {
  vendor: VendorDetails;
  vendorTool?: VendorToolDetails;
};

// TODO: add support for other vendors and use it in all vendor related components
export const getStackVendorInfo = (
  stackConfigVendor: StackConfigVendor
): VendorInfo | undefined => {
  if (stackConfigVendor.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT) {
    const vendorText =
      stackConfigVendor.effectiveVersions?.effectiveTerragruntVersion ||
      stackConfigVendor.terragruntVersion ||
      undefined;

    let vendorToolText =
      stackConfigVendor.effectiveVersions?.effectiveTerraformVersion ||
      stackConfigVendor.terraformVersion ||
      undefined;

    let vendorToolIcon = LogoTerraform;
    if (stackConfigVendor?.tool === TerragruntTool.OpenTofu) {
      vendorToolIcon = LogoOpenTofu;
    } else if (stackConfigVendor?.tool === TerragruntTool.ManuallyProvisioned) {
      vendorToolIcon = SettingsGear;
      vendorToolText = TERRAGRUNT_MANUALLY_PROVISIONED;
    }

    return {
      vendor: {
        icon: LogoTerragrunt,
        text: vendorText,
      },
      vendorTool: {
        icon: vendorToolIcon,
        text: vendorToolText,
      },
    };
  }

  return undefined;
};

// TODO: use `getRuntimeVendorInfo` in all runtime vendor related components
export const getRuntimeConfigVendorInfo = (
  stackConfigVendor: StackConfigVendor,
  runtimeConfig: RuntimeConfig
): VendorInfo | undefined => {
  if (stackConfigVendor.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM) {
    return {
      vendor: {
        icon:
          runtimeConfig.terraform?.workflowTool === TerraformWorkflowTool.OpenTofu
            ? LogoOpenTofu
            : LogoTerraform,
        text: runtimeConfig.terraform?.version || undefined,
      },
    };
  }

  if (stackConfigVendor.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT) {
    // HACK: Terragrunt runtimeConfig is available only for new runs,
    //       the old runs should use terraform related fields
    if (runtimeConfig.terragrunt) {
      return {
        vendor: {
          icon: LogoTerragrunt,
          text: runtimeConfig.terragrunt.terragruntVersion,
        },
        vendorTool: getTerragruntToolDetails(
          runtimeConfig.terragrunt.tool,
          runtimeConfig.terragrunt.terraformVersion || undefined
        ),
      };
    }

    return {
      vendor: {
        icon: LogoTerraform,
        text: runtimeConfig?.terraform?.version || undefined,
      },
    };
  }

  return undefined;
};

export const getVendorName = (
  key: VENDOR_CONFIG_TYPENAME | VENDOR_KEY | string | undefined
): VENDOR_DICTIONARY | undefined => {
  switch (key) {
    case VENDOR_CONFIG_TYPENAME.TERRAFORM:
    case VENDOR_KEY.TERRAFORM:
      return VENDOR_DICTIONARY.TERRAFORM;
    case VENDOR_CONFIG_TYPENAME.PULUMI:
    case VENDOR_KEY.PULUMI:
      return VENDOR_DICTIONARY.PULUMI;
    case VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION:
    case VENDOR_KEY.CLOUD_FORMATION:
      return VENDOR_DICTIONARY.CLOUD_FORMATION;
    case VENDOR_CONFIG_TYPENAME.KUBERNETES:
    case VENDOR_KEY.KUBERNETES:
      return VENDOR_DICTIONARY.KUBERNETES;
    case VENDOR_CONFIG_TYPENAME.ANSIBLE:
    case VENDOR_KEY.ANSIBLE:
      return VENDOR_DICTIONARY.ANSIBLE;
    case VENDOR_CONFIG_TYPENAME.TERRAGRUNT:
    case VENDOR_KEY.TERRAGRUNT:
      return VENDOR_DICTIONARY.TERRAGRUNT;
    default:
      return undefined;
  }
};
