import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type ListBoxIconsProps = {
  headerIcon?: React.ReactNode;
  className?: string;
};

const ListBoxIcons = (props: ListBoxIconsProps) => {
  const { headerIcon, className } = props;

  if (!headerIcon) {
    return null;
  }
  return <div className={cx(styles.listBoxIcons, className)}>{headerIcon}</div>;
};

export default ListBoxIcons;
