import { ChevronNew } from "components/icons";
import useTypedContext from "hooks/useTypedContext";

import DropdownNew, { DropdownProps } from "../Dropdown/New";
import styles from "./styles.module.css";
import { SplitControlContext } from "./context";
import ButtonIconNew from "../ButtonIcon/New";

type SplitControlDropdownProps = Omit<DropdownProps, "triggerComponent"> & {
  tooltip: string;
  loading?: boolean;
  disabled?: boolean;
};

const SplitControlDropdown = ({
  tooltip,
  loading,
  disabled,
  children,
  ...restProps
}: SplitControlDropdownProps) => {
  const { variant, size } = useTypedContext(SplitControlContext);

  return (
    <DropdownNew
      {...restProps}
      triggerComponent={
        <ButtonIconNew
          justify="center"
          variant={variant}
          size={size}
          loading={loading}
          disabled={disabled}
          className={styles.splitControlDropdownTrigger}
          icon={ChevronNew}
          iconRotate="90"
        >
          {tooltip}
        </ButtonIconNew>
      }
    >
      {children}
    </DropdownNew>
  );
};

export default SplitControlDropdown;
