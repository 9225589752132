import React, { useContext } from "react";
import cx from "classnames";

import ButtonIcon from "ds/components/ButtonIcon";
import { CrossNew, InfoNew } from "components/icons";
import { LayoutContext } from "components/layout/Context";

import styles from "./styles.module.css";
import Box, { BoxProps } from "../Box";

type ToastProps = {
  variant: "default" | "info" | "danger";
  onClose?: () => void;
  relative?: boolean;
  children: React.ReactNode;
  className?: string;
} & Pick<BoxProps, "direction" | "align" | "justify" | "fullWidth">;

/**
 * @deprecated use DS Banner instead
 */
const Toast = ({
  children,
  variant,
  onClose,
  relative,
  className,
  direction = "row",
  align = "start",
  ...restProps
}: ToastProps) => {
  const contextData = useContext(LayoutContext);

  return (
    <Box
      direction={direction}
      align={align}
      {...restProps}
      className={cx(
        styles.toast,
        styles[variant],
        {
          [styles.expanded]: contextData?.isExpandedMode,
          [styles.relative]: relative,
        },
        className
      )}
    >
      <InfoNew className={styles.icon} />

      {children}

      {onClose && (
        <ButtonIcon onClick={onClose} className={styles.close} icon={CrossNew} variant="ghost">
          Close
        </ButtonIcon>
      )}
    </Box>
  );
};

export default Toast;
