import { gql } from "@apollo/client";

export const SEARCH_WEBHOOKS = gql`
  query SearchNamedWebhooksIntegrations($input: SearchInput!) {
    searchNamedWebhooksIntegrations(input: $input) {
      edges {
        cursor
        node {
          id
          createdAt
          space {
            id
            name
            accessLevel
          }
          enabled
          endpoint
          labels
          name
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_WEBHOOKS_SUGGESTIONS = gql`
  query SearchNamedWebhooksIntegrationsSuggestions($input: SearchSuggestionsInput!) {
    searchNamedWebhooksIntegrationsSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;

export const WEBHOOK_DELETE = gql`
  mutation WebhookDelete($id: ID!) {
    namedWebhooksIntegrationDelete(id: $id) {
      id
    }
  }
`;
