import { gql } from "@apollo/client";

export const GET_POLICIES = gql`
  query GetLoginPolicies {
    policies {
      id
      name
      type
      labels
      body
      createdAt
      updatedAt
      spaceDetails {
        id
        name
        accessLevel
      }
      attachedStacks {
        id
      }
    }
  }
`;
