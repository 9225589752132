import Access from "./Access";
import Approval from "./Approval";
import Initialization from "./Initialization";
import Login from "./Login";
import Plan from "./Plan";
import Push from "./Push";
import Task from "./Task";
import Trigger from "./Trigger";
import Notification from "./Notification";

const templates = {
  ACCESS: Access,
  APPROVAL: Approval,
  INITIALIZATION: Initialization,
  LOGIN: Login,
  PLAN: Plan,
  PUSH: Push,
  TASK: Task,
  TRIGGER: Trigger,
  NOTIFICATION: Notification,
};

export default templates;
