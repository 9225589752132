import { Stack } from "types/generated";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsStep,
  BulkActionsVariant,
} from "components/BulkActions/types";
import BulkActionsDrawerActionStepsFooter from "components/BulkActions/Drawer/ActionSteps/Footer";
import BulkActionsDrawerActionStepsConfirmation from "components/BulkActions/Drawer/ActionSteps/Confirmation";
import BulkActionsDrawerActionStepsConfirmationEmptyTab from "components/BulkActions/Drawer/ActionSteps/Confirmation/EmptyTab";

import StacksBulkActionsSelectedItem from "./SelectedStackItem";
import StacksBulkActionsConfirmActionForm from "./ConfirmActionForm";
import { StackBulkActionsAnalyticsPayload, StackBulkActionsSelectedAction } from "./types";
import { StackMutationVariables } from "./useStackBulkActions/types";

type StacksBulkActionsConfirmationViewProps = {
  applicableItems: Stack[];
  skippedItems: Stack[];
  onItemDismiss: (id: BulkActionItemID) => void;
  selectedBulkAction: StackBulkActionsSelectedAction;
  onConfirm: (
    mutationVariables: StackMutationVariables,
    analyticsPayload: StackBulkActionsAnalyticsPayload
  ) => void;
  onCancel: () => void;
};

const StacksBulkActionsConfirmationView = ({
  applicableItems,
  skippedItems,
  onItemDismiss,
  selectedBulkAction,
  onConfirm,
  onCancel,
}: StacksBulkActionsConfirmationViewProps) => {
  const getItems = (tab: BulkActionActionConfirmationTabs) => {
    if (tab === BulkActionActionConfirmationTabs.Applicable) {
      return applicableItems;
    }

    return skippedItems;
  };

  return (
    <>
      <BulkActionsDrawerActionStepsConfirmation
        applicableCount={applicableItems.length}
        skippedCount={skippedItems.length}
      >
        {({ currentTab }) =>
          getItems(currentTab).length === 0 ? (
            <BulkActionsDrawerActionStepsConfirmationEmptyTab currentTab={currentTab} />
          ) : (
            getItems(currentTab).map((item) => (
              <StacksBulkActionsSelectedItem
                key={item.id}
                item={item}
                onDismiss={onItemDismiss}
                itemCategory={currentTab}
                step={BulkActionsStep.ActionConfirmation}
              />
            ))
          )
        }
      </BulkActionsDrawerActionStepsConfirmation>

      <BulkActionsDrawerActionStepsFooter>
        <StacksBulkActionsConfirmActionForm
          variant={BulkActionsVariant.Drawer}
          action={selectedBulkAction}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </BulkActionsDrawerActionStepsFooter>
    </>
  );
};

export default StacksBulkActionsConfirmationView;
