import useTypedContext from "hooks/useTypedContext";

import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsVendorTerragruntEdit from "./Edit";
import StackSettingsVendorTerragruntView from "./View";
import { StackSettingsContextData } from "../../Context";

const StackSettingsVendorTerragrunt = (props: StackSettingsVendorComponentBaseProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return canManageStack ? (
    <StackSettingsVendorTerragruntEdit {...props} />
  ) : (
    <StackSettingsVendorTerragruntView />
  );
};

export default StackSettingsVendorTerragrunt;
