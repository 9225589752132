import { NetworkStatus, useQuery } from "@apollo/client";

import { Stack } from "types/generated";
import Box from "ds/components/Box";
import { getConfigs } from "utils/config";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import MissingDataBanner from "components/MissingDataBanner";

import EnvironmentPreviewSection from "./Section";
import EnvironmentPreviewList from "./List";
import { GET_STACK_RUNTIME_CONFIG } from "./gql";
import EnvironmentPreviewSkeleton from "./Skeleton";

type EnvironmentPreviewProps = {
  stackId: string;
  className?: string;
};

const EnvironmentPreview = ({ stackId, className }: EnvironmentPreviewProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, refetch, networkStatus } = useQuery<{ stack: Stack }>(
    GET_STACK_RUNTIME_CONFIG,
    {
      onError,
      variables: { stackId },
    }
  );

  const runtimeConfig = data?.stack?.runtimeConfig;

  const { variables, mountedFiles, spaceliftVariables } = getConfigs(runtimeConfig || []);

  const isLoading = loading && networkStatus !== NetworkStatus.refetch;
  const isRefetching = loading && networkStatus === NetworkStatus.refetch;

  if (isLoading && !data?.stack) {
    return (
      <Box direction="column" className={className}>
        <EnvironmentPreviewSkeleton />
      </Box>
    );
  }

  if (!isLoading && !data?.stack) {
    return (
      <MissingDataBanner
        text="Couldn't load environment, please refresh or come back later"
        refreshHandler={refetch}
        refreshLoading={isRefetching}
      />
    );
  }

  return (
    <Box direction="column" className={className}>
      {!!variables?.length && (
        <EnvironmentPreviewSection title="Variables">
          <EnvironmentPreviewList items={variables} />
        </EnvironmentPreviewSection>
      )}

      {!!mountedFiles?.length && (
        <EnvironmentPreviewSection title="Mounted files">
          <EnvironmentPreviewList items={mountedFiles} />
        </EnvironmentPreviewSection>
      )}

      {!!spaceliftVariables?.length && (
        <EnvironmentPreviewSection title="Spacelift Environment">
          <EnvironmentPreviewList items={spaceliftVariables} />
        </EnvironmentPreviewSection>
      )}
    </Box>
  );
};

export default EnvironmentPreview;
