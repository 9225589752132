import { gql } from "@apollo/client";

export const GET_ENTITY = gql`
  query GetEntity($stackId: ID!, $id: ID!) {
    stack(id: $stackId) {
      id
      entity(id: $id) {
        id
        address
        name
        drifted
        parent
        type
        creator {
          id
        }
        updater {
          id
        }
        vendor {
          ... on EntityVendorAnsible {
            ansible {
              ... on AnsibleResource {
                __typename
              }
            }
          }
          ... on EntityVendorTerraform {
            terraform {
              ... on TerraformResource {
                module
                address
                provider
                tainted
                values
              }
              ... on TerraformOutput {
                hash
                sensitive
                value
              }
              ... on TerraformModule {
                __typename
              }
            }
          }
          ... on EntityVendorCloudFormation {
            cloudFormation {
              ... on CloudFormationResource {
                logicalResourceId
                physicalResourceId
                template
              }
            }
          }
          ... on EntityVendorKubernetes {
            kubernetes {
              ... on KubernetesResource {
                data
              }
            }
          }
          ... on EntityVendorPulumi {
            pulumi {
              ... on PulumiResource {
                urn
                id
                provider
                parent
                outputs
              }
              ... on PulumiOutput {
                hash
                sensitive
                value
              }
            }
          }
        }
        thirdPartyMetadata
      }
    }
  }
`;
