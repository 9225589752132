import { IntegrationColored } from "components/icons";
import CollapsibleList from "components/CollapsibleList";
import EmptyState from "ds/components/EmptyState";

import NewStackAttachedPolicy from "../AttachedPolicy";
import { AttachedPolicy } from "../types";

type NewStackManuallyAttachedPoliciesProps = {
  items: AttachedPolicy[];
};

const NewStackManuallyAttachedPolicies = ({ items }: NewStackManuallyAttachedPoliciesProps) => {
  return (
    <CollapsibleList
      ariaLevel={2}
      title="Manually attached"
      count={items.length}
      emptyPlaceholder={
        <EmptyState
          padding="large"
          icon={IntegrationColored}
          title="No policies are attached to this stack"
          caption="Use policies list to attach a policy to stack."
        />
      }
      initialIsCollapsed={false}
    >
      {items.map((item) => (
        <NewStackAttachedPolicy key={item.id} item={item} />
      ))}
    </CollapsibleList>
  );
};

export default NewStackManuallyAttachedPolicies;
