import cx from "classnames";
import { useCallback } from "react";

import { Chevron } from "components/icons";
import ListBoxInfoWrapper from "components/listBox/InfoWrapper";
import ListBoxItem from "components/listBox/Item";
import { Maybe, PullRequest, VcsProvider } from "types/generated";
import { Branch, Clock } from "components/icons";
import Timestamp from "components/time/Timestamp";
import UserLink from "components/UserLink";
import { generateBranchUrl } from "utils/urls";
import ListBox from "components/listBox";
import { useToggle } from "hooks/useToggle";
import { getCommmitDigitsCount } from "utils/commit";

import RunItem from "./RunItem";
import styles from "./styles.module.css";

type PullRequestProps = {
  moduleApiHost: Maybe<string>;
  moduleId: string;
  moduleRepository: string;
  moduleRepositoryURL: string | null;
  moduleNamespace: string;
  provider: VcsProvider;
  pullRequest: PullRequest;
};

const PullRequestComponent = (props: PullRequestProps) => {
  const {
    moduleId,
    moduleApiHost,
    moduleNamespace,
    moduleRepository,
    provider,
    pullRequest,
    moduleRepositoryURL,
  } = props;

  const [isCollapsed, toggle] = useToggle(false);

  const hasRuns = pullRequest.runs.length > 0;
  const handleToggle = useCallback(() => toggle(), [toggle]);

  return (
    <div className={styles.prListItemWrapper}>
      <ListBox
        headerLink={{
          name: `${pullRequest.title} #${pullRequest.id}`,
          to: pullRequest.url,
        }}
        className={styles.prListItem}
        compact
      >
        {hasRuns && (
          <button
            className={cx(styles.prListItemToggle, {
              [styles.prListItemToggleCollapsed]: isCollapsed,
            })}
            onClick={handleToggle}
          >
            <Chevron />
          </button>
        )}

        <ListBoxInfoWrapper>
          <ListBoxItem icon={Clock}>
            Opened <Timestamp timestamp={pullRequest.createdAt} /> by{" "}
            <UserLink login={pullRequest.authorLogin} name="" provider={provider} />
          </ListBoxItem>
          <ListBoxItem icon={Clock}>
            Updated <Timestamp timestamp={pullRequest.updatedAt} />
          </ListBoxItem>
          {moduleApiHost && (
            <ListBoxItem
              link={{
                url: generateBranchUrl({
                  apiHost: moduleApiHost || "",
                  namespace: moduleNamespace,
                  repository: moduleRepository,
                  repositoryURL: moduleRepositoryURL,
                  provider: provider,
                  branch: pullRequest.headBranch,
                }),
                text: pullRequest.headBranch,
              }}
              icon={Branch}
            />
          )}
          <ListBoxItem
            link={{
              url: pullRequest.headSHA,
              text: pullRequest.headSHA.slice(0, getCommmitDigitsCount(provider)),
            }}
          />
        </ListBoxInfoWrapper>
      </ListBox>
      {hasRuns && (
        <div
          className={cx(styles.prListRunsWrapper, {
            [styles.prListRunsWrapperHidden]: isCollapsed,
          })}
        >
          {pullRequest.runs.map((run) => (
            <RunItem key={run.id} run={run} moduleId={moduleId} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PullRequestComponent;
