import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";

import { GitlabIntegration, VcsProvider } from "types/generated";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Button from "ds/components/Button";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import SecretInput from "ds/components/SecretInput";
import FormFieldHidden from "ds/components/Form/Field/Hidden";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FormFieldSpace from "components/FormFields/Space";

import { ManageGitLabFormFields } from "./types";
import {
  getFormDefaultValues,
  mapCreateGitLabIntegrationInputValues,
  mapUpdateGitLabIntegrationInputValues,
} from "./helpers";
import VCSIntegrationsDocumentationButton from "../components/DocumentationButton";
import useCloseFullScreenModal from "../useCloseFullScreenModal";
import useCreateGitLabIntegration from "./useCreateGitLabIntegration";
import useUpdateGitLabIntegration from "./useUpdateGitLabIntegration";
import VCSIntegrationTypeFieldControl from "../../components/IntegrationTypeFieldControl";
import useCheckGitLabHasDefaultIntegration from "./useCheckGitLabHasDefaultIntegration";
import useTypedFlags from "../../../../../hooks/useTypedFlags";
import VCSChecksToggle from "../components/VCSChecksToggle";

type VCSIntegrationsManageGitLabFormProps = {
  integration?: GitlabIntegration;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageGitLabForm = ({
  integration,
  onIsDirtyChange,
}: VCSIntegrationsManageGitLabFormProps) => {
  const isEditMode = !!integration;

  const { viewer } = useTypedContext(AccountContext);

  const { handleOnClose } = useCloseFullScreenModal();
  const { aggregatedVcsChecks } = useTypedFlags();

  const manageGitLabForm = useForm<ManageGitLabFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setValue,
    trigger,
  } = manageGitLabForm;

  const { createGitLabIntegration, loading: createLoading } = useCreateGitLabIntegration();
  const { updateGitLabIntegration, loading: updateLoading } = useUpdateGitLabIntegration();

  const { hasDefaultIntegration, loading: checkDefaultIntegrationLoading } =
    useCheckGitLabHasDefaultIntegration(isEditMode);

  const withIntegrationTypeControl = !isEditMode && viewer.admin;
  const withSpaceControl = !withIntegrationTypeControl && !integration?.isDefault;

  const onHiddenToggle = useCallback(
    (isHidden: boolean) => {
      if (!isHidden) {
        setValue("privateToken", "", { shouldValidate: true });
      } else {
        // revalidate the whole form with hidden field
        trigger();
      }
    },
    [setValue, trigger]
  );

  const handleSetup = useCallback(
    (formData: ManageGitLabFormFields) => {
      if (isEditMode) {
        updateGitLabIntegration(
          mapUpdateGitLabIntegrationInputValues(formData, integration.id),
          handleOnClose
        );
      } else {
        createGitLabIntegration(mapCreateGitLabIntegrationInputValues(formData), handleOnClose);
      }
    },
    [createGitLabIntegration, handleOnClose, integration?.id, isEditMode, updateGitLabIntegration]
  );

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...manageGitLabForm}>
      <FullScreenModalBody>
        <FormField
          label="Integration name"
          helperText="The integration name is unique and cannot be changed"
          error={errors?.name?.message}
          noMargin
        >
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter integration name here"
              error={!!errors?.name}
              {...register("name", {
                validate: stringIsRequired("Integration name field is required."),
              })}
              disabled={isEditMode}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {withIntegrationTypeControl && (
          <VCSIntegrationTypeFieldControl
            hasAlreadyDefaultIntegration={hasDefaultIntegration}
            loading={checkDefaultIntegrationLoading}
            provider={VcsProvider.Gitlab}
          />
        )}
        {withSpaceControl && <FormFieldSpace />}

        <FormField label="API host URL" error={errors?.apiHost?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="https://"
              error={!!errors?.apiHost}
              {...register("apiHost", {
                validate: validateRequiredURL(),
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="User facing host URL" error={errors?.userFacingHost?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="https://"
              error={!!errors?.userFacingHost}
              {...register("userFacingHost", { validate: validateRequiredURL() })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        {!isEditMode && (
          <FormField label="API token" error={errors?.privateToken?.message}>
            {({ ariaInputProps }) => (
              <SecretInput
                placeholder="Enter API token here"
                error={!!errors?.privateToken}
                {...register("privateToken", {
                  validate: stringIsRequired("API token field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}

        {isEditMode && (
          <FormFieldHidden
            hiddenPlaceholder="Update API token"
            onHiddenToggle={onHiddenToggle}
            label="API token"
            error={errors?.privateToken?.message}
          >
            {(isHidden) => (
              <SecretInput
                placeholder="Enter API token here"
                error={!!errors?.privateToken}
                {...register("privateToken", {
                  ...(!isHidden && { validate: stringIsRequired("API token field is required.") }),
                })}
              />
            )}
          </FormFieldHidden>
        )}

        {aggregatedVcsChecks && <VCSChecksToggle />}

        <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here"
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.Gitlab} />

        <Button
          onClick={handleSubmit(handleSetup)}
          variant="primary"
          disabled={!isValid || (isEditMode && !isDirty)}
          loading={createLoading || updateLoading}
        >
          {isEditMode ? "Save" : "Set up"}
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageGitLabForm;
