import { AccountViewer } from "components/form/graphql/types";
import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { Run, RunState, Stack, StackState } from "types/generated";

// Check if the user can confirm the stack run having only a stack
export const canConfirmStackRun = (stack: Stack): boolean =>
  stack.state === StackState.Unconfirmed && hasAtLeastStackWriteAccess(stack);

// Check if the user can confirm the run based on the run
export const canConfirmRun = (run: Run, stack: Stack, viewer: AccountViewer | undefined): boolean =>
  run.state === RunState.Unconfirmed &&
  !run.expired &&
  !run.needsApproval &&
  (viewer?.admin || hasAtLeastStackWriteAccess(stack));
