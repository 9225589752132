import LaunchPadChecklistLayout from "../components/ChecklistLayout";
import { GET_STARTED_FEATURES } from "./features";
import useSaaSOnboardingProgress from "./useSaaSOnboardingProgress";

const LaunchPadChecklistSaaS = () => {
  const { onboardingProgressInitialLoading, progressByFeature } = useSaaSOnboardingProgress();

  return (
    <LaunchPadChecklistLayout
      onboardingProgressInitialLoading={onboardingProgressInitialLoading}
      features={GET_STARTED_FEATURES}
      progressByFeature={progressByFeature}
    />
  );
};

export default LaunchPadChecklistSaaS;
