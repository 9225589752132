import InfoBox from "components/InfoBox";

import CLIWrapper from "./CLIWrapper";
import styles from "./styles.module.css";

const CLIAuthSuccess = () => {
  return (
    <CLIWrapper>
      <div className={styles.text}>
        <b>Spacelift CLI</b>
      </div>
      <InfoBox className={styles.zeroMargin}>
        The authentication flow has completed successfully.
        <br />
        You may close this window.
      </InfoBox>
    </CLIWrapper>
  );
};

export default CLIAuthSuccess;
