import { SearchQueryFieldConstraintTimeInLast, SearchQueryOrderDirection } from "types/generated";

export const initialSortOption = "timestamp";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const POLL_INTERVAL = 6000;
export const ITEMS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "notificationsFiltersOrder";

export const INITIAL_PERIOD = SearchQueryFieldConstraintTimeInLast.Last14D;
