import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageInfo from "components/PageWrapper/Info";

import { StackContext } from "../Context";
import StackHeader from "../components/Header";
import StackSchedulingContextProvider from "./Context";
import StackSchedulingList from "./List";
import StackSchedulingCreateDropdown from "./CreateDropdown";
import { getStacksBackUrl } from "../helpers";
import StackSchedulingTierInfo from "./TierInfo";

const StackScheduling = () => {
  const { stack } = useTypedContext(StackContext);

  useTitle(`Scheduling · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  return (
    <StackSchedulingContextProvider>
      <StackHeader />
      <PageInfo title="Scheduling">
        <StackSchedulingCreateDropdown componentLocation="Header" />
      </PageInfo>
      <StackSchedulingTierInfo />
      <StackSchedulingList stackId={stack.id} />
    </StackSchedulingContextProvider>
  );
};

export default StackScheduling;
