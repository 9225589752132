import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { AccountContext } from "views/AccountWrapper";

const useIgnoredRunNoticeDescription = () => {
  const isIgnoredRunsEnabled = useTierFeature(BillingTierFeature.RunIgnoredTriggers);
  const { viewer } = useTypedContext(AccountContext);

  if (isIgnoredRunsEnabled) {
    return "When enabled, Spacelift will notify you about ignored runs on the tracked runs view.";
  }

  return viewer.admin
    ? "Ignored runs notifications are not available in your current plan. Upgrade your plan to use the feature. When enabled, Spacelift will notify you about ignored runs on the tracked runs view."
    : "Ignored runs notifications are not available in your current plan. Ask your admin to upgrade your plan to use the feature. When enabled, Spacelift will notify you about ignored runs on the tracked runs view.";
};

export default useIgnoredRunNoticeDescription;
