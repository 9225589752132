import { Node, Edge, MarkerType } from "reactflow";
import {
  dagConnect,
  sugiyama,
  decrossTwoLayer,
  Dag,
  layeringSimplex,
  coordCenter,
} from "d3-dag/dist";
import { ConnectDatum } from "d3-dag/dist/dag/create";

import { StackDependency, StackDependencyDetail } from "types/generated";

import { EdgeData } from "./types";

export const makeDependenciesGraphNodes = (
  dependencies: StackDependency[]
): [Node<StackDependencyDetail>[], Edge<EdgeData>[]] => {
  const deps = dependencies.map<Edge<EdgeData>>((dependency) => ({
    id: `${dependency.dependsOnStack.id}-${dependency.stack.id}`,
    source: dependency.dependsOnStack.id,
    target: dependency.stack.id,
    type: "stackDependency",
    animated: false,
    markerStart: "flowDot",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 18,
      height: 18,
    },
    data: {
      referenceCount: dependency.referenceCount,
      references: dependency.references,
    },
  }));

  const seenEdges = new Set();
  const edges = [];
  for (const edge of deps) {
    if (!seenEdges.has(edge.id)) {
      seenEdges.add(edge.id);
      edges.push(edge);
    }
  }

  const seenNodes = new Set();
  const nodes = dependencies.reduce<Node<StackDependencyDetail>[]>((acc, dependency) => {
    if (!seenNodes.has(dependency.dependsOnStack.id)) {
      seenNodes.add(dependency.dependsOnStack.id);
      acc.push({
        id: dependency.dependsOnStack.id,
        data: dependency.dependsOnStack,
        position: { x: 0, y: 0 },
        type: "stackDependency",
      });
    }

    if (!seenNodes.has(dependency.stack.id)) {
      seenNodes.add(dependency.stack.id);
      acc.push({
        id: dependency.stack.id,
        data: dependency.stack,
        position: { x: 0, y: 0 },
        type: "stackDependency",
      });
    }

    return acc;
  }, []);

  const create = dagConnect();
  const dag = (create as (data: [string, string][]) => Dag<ConnectDatum, [string, string]>)(
    edges.map((e) => [e.source, e.target])
  );

  const layout: (dag: Dag<ConnectDatum, [string, string]>) => void = sugiyama()
    .layering(layeringSimplex())
    .decross(decrossTwoLayer())
    .coord(coordCenter())
    .nodeSize(() => [330, 150 * 3]);

  layout(dag);

  const newNodes: Node<StackDependencyDetail>[] = [];

  for (const node of dag) {
    const n = nodes.find((n) => n.id === node.data.id);
    const newNode = {
      ...n,
      position: { x: node.y, y: node.x },
    } as Node<StackDependencyDetail>;
    newNodes.push(newNode);
  }

  return [newNodes, edges];
};
