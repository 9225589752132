import InfiniteLoader from "react-window-infinite-loader";

import PageInfo from "components/PageWrapper/Info";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import Box from "ds/components/Box";
import ListEntitiesNew from "components/ListEntitiesNew";
import { WorkerPoolUser } from "types/generated";
import { SortOption } from "components/Filters/types";
import PrivateWorkersTierInfo from "views/Account/PrivateWorkersTierInfo";

import WorkerPoolUsedByVirtualizedListItem from "./ListItem/Virtualized";
import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import WorkerPoolUsedByFiltersLayout from "./FiltersLayout";
import WorkerPoolUsedByEmpty from "./Empty";
import WorkerPoolUsedByNoResults from "./HasNoResults";

type WorkerPoolUsedByViewProps = {
  entities: WorkerPoolUser[];
  loadMoreEntities: () => Promise<void>;
  isPageEmpty: boolean;
  totalUsersCount: number;
  loadingIndication: boolean;
  hasNoFilteringResults: boolean;
  sortOptions: SortOption[];
  isPublic: boolean;
};

const WorkerPoolUsedByView = ({
  entities,
  loadMoreEntities,
  isPageEmpty,
  totalUsersCount,
  loadingIndication,
  hasNoFilteringResults,
  sortOptions,
  isPublic,
}: WorkerPoolUsedByViewProps) => {
  const isItemLoaded = (value: number) => value < entities.length;

  return (
    <>
      <PageInfo title="Used by">
        {!isPageEmpty && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={entities.length}
              totalCount={totalUsersCount}
              loading={loadingIndication}
              minimumLoadingDuration={200}
            />

            <SearchInput
              placeholder="Search by name"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          </Box>
        )}
      </PageInfo>

      {!isPublic && <PrivateWorkersTierInfo />}

      {!isPageEmpty && <WorkerPoolUsedByFiltersLayout sortOptions={sortOptions} />}

      {isPageEmpty && <WorkerPoolUsedByEmpty />}

      {hasNoFilteringResults && <WorkerPoolUsedByNoResults />}

      {entities.length > 0 && (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={totalUsersCount}
          loadMoreItems={loadMoreEntities}
        >
          {({ onItemsRendered }) => (
            <ListEntitiesNew
              itemCount={entities.length}
              itemProps={{
                items: entities,
              }}
              virtualizedItem={WorkerPoolUsedByVirtualizedListItem}
              itemKey={(index) => entities[index].stackId}
              onItemsRendered={onItemsRendered}
            />
          )}
        </InfiniteLoader>
      )}
    </>
  );
};

export default WorkerPoolUsedByView;
