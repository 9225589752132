import AccountNotFoundError from "components/error/AccountNotFound";
import NoAccessPage from "components/error/NoAccessPage";
import { ErrorType } from "types/Error";

import { shouldShowAccountError, shouldShowUnauthorizedError } from "./helpers";

const useErrorHandle = (error: ErrorType | undefined) => {
  const showAccountError = shouldShowAccountError(error);
  const showUnauthorizedError = shouldShowUnauthorizedError(error);

  if (showAccountError) {
    return <AccountNotFoundError />;
  }

  if (showUnauthorizedError) {
    return <NoAccessPage />;
  }

  return undefined;
};

export default useErrorHandle;
