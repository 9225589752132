import { ChangeEvent, ReactNode } from "react";

import CardWrapper from "components/CardWrapper";

import SegmentedControl, {
  SegmentedControlOption,
  SegmentedControlProps,
} from "../SegmentedControl";
import FormField, { FormFieldProps } from "../Form/Field";
import Box from "../Box";

type SegmentedControlFormFieldProps = {
  value: SegmentedControlProps["value"];
  segments: SegmentedControlOption[];
  onChange: (currentSegment: SegmentedControlOption["value"]) => void;
  label: FormFieldProps["label"];
  tooltipInfo?: FormFieldProps["tooltipInfo"];
  tooltipWidthMode?: FormFieldProps["tooltipWidthMode"];
  children: ReactNode;
  noMargin?: boolean;
};

const SegmentedControlFormField = ({
  value,
  segments,
  onChange,
  label,
  tooltipInfo,
  tooltipWidthMode,
  children,
  noMargin,
}: SegmentedControlFormFieldProps) => {
  const handleSegmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <CardWrapper variant="filled" direction="column" margin={noMargin ? "0" : "large 0 0"}>
      <FormField
        label={label}
        tooltipInfo={tooltipInfo}
        tooltipWidthMode={tooltipWidthMode}
        noMargin
      >
        <Box direction="column" gap="large" padding="medium 0 0" fullWidth>
          <SegmentedControl value={value} onChange={handleSegmentChange} options={segments} />

          {children}
        </Box>
      </FormField>
    </CardWrapper>
  );
};

export default SegmentedControlFormField;
