import { create, show, useModal } from "@ebay/nice-modal-react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

type ProviderDeleteConfirmationProps = { providerId: string; onConfirm: () => void };

const ProviderDeleteConfirmation = create(function ProviderListItemDropdownDeleteConfirmation({
  providerId,
  onConfirm,
}: ProviderDeleteConfirmationProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return <DeleteConfirmationModal size="large" onConfirm={handleOnConfirm} name={providerId} />;
});

export const showProviderDeleteConfirmation = (props: ProviderDeleteConfirmationProps) =>
  show(ProviderDeleteConfirmation, props);
