import { ReactNode } from "react";

import Box from "ds/components/Box";
import TileContent from "ds/components/Tile/Content";
import TileWrapper from "ds/components/Tile/Wrapper";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { MenuStacks } from "components/icons";
import { RunState, StackTile } from "types/generated";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import BadgeNextNew from "ds/components/BadgeNext/New";

import styles from "./styles.module.css";

type DashboardWidgetsRunTileProps = {
  id: string;
  title: string;
  state?: RunState;
  stackTile: StackTile;
  caption?: ReactNode;
  needsApproval?: boolean;
  analyticsTitle?: string;
  approval?: boolean;
};

const DashboardWidgetsRunTile = ({
  id,
  title,
  caption,
  state,
  stackTile,
  needsApproval = false,
  analyticsTitle,
  approval,
}: DashboardWidgetsRunTileProps) => {
  return (
    <TileWrapper>
      <TileContent>
        <Box justify="between" align="center" gap="medium" fullWidth>
          <Box grow="1">
            <Box direction="column" gap="medium" fullWidth>
              <Box fullWidth>
                <TextEllipsis tooltip={title}>
                  {(props) => (
                    <Link
                      listLink
                      analyticsTitle={`${analyticsTitle} - Run Clicked`}
                      analyticsPage={AnalyticsPageDashboard.Dashboard}
                      analyticsProps={{
                        stackName: stackTile.name,
                        runState: state,
                      }}
                      to={`/stack/${stackTile.slug}/run/${id}`}
                      variant="secondary"
                      className={styles.title}
                    >
                      <Typography {...props} tag="span" variant="p-body2">
                        {title}
                      </Typography>
                    </Link>
                  )}
                </TextEllipsis>
              </Box>

              {caption && (
                <TextEllipsis tooltip={title}>
                  {(props) => (
                    <Typography {...props} color="secondary" tag="span" variant="p-body3">
                      {caption}
                    </Typography>
                  )}
                </TextEllipsis>
              )}
            </Box>
          </Box>

          <Box className={styles.metaWrapper}>
            <Box
              direction="column"
              align="end"
              gap="medium"
              fullWidth
              className={styles.metaContent}
            >
              {approval === true && <BadgeNextNew text="Approved" variant="green" type="strong" />}

              {approval === false && <BadgeNextNew text="Rejected" variant="red" type="strong" />}

              {state && <RunStateBadge state={state} />}

              {needsApproval && (
                <Box gap="small" align="center">
                  <div className={styles.dot} />
                  <Typography color="primary" tag="span" variant="p-body3">
                    Needs approval
                  </Typography>
                </Box>
              )}

              <Box align="end" gap="medium" fullWidth>
                <MetaInfoListItem
                  fullWidth
                  analyticsTitle={`${analyticsTitle} - Stack Clicked`}
                  analyticsPage={AnalyticsPageDashboard.Dashboard}
                  analyticsProps={{
                    stackName: stackTile.name,
                    runState: state,
                  }}
                  to={`/stack/${stackTile.slug}`}
                  linkText={stackTile.name}
                  icon={MenuStacks}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </TileContent>
    </TileWrapper>
  );
};

export default DashboardWidgetsRunTile;
