import { MouseEvent } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom-v5-compat";

import Highlight from "components/text/Highlight";

import { ListBoxItemLink } from "./types";
import styles from "./styles.module.css";

const ListBoxLink = (props: ListBoxItemLink) => {
  const {
    headerLink,
    renderAsCode,
    routerLink,
    text,
    url,
    highlightMatches,
    target = "_blank",
  } = props;

  const linkClass = classNames(styles.listBoxLink, {
    [styles.listBoxInfo]: !headerLink,
  });
  const textClass = classNames({ [styles.listBoxCode]: renderAsCode });

  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  if (routerLink) {
    return (
      <Link className={linkClass} to={url}>
        <span className={textClass}>
          <Highlight text={text} matches={highlightMatches} />
        </span>
      </Link>
    );
  }

  return (
    <a
      className={linkClass}
      href={url}
      rel="noopener noreferrer"
      target={target}
      onClick={stopPropagation}
    >
      {text}
    </a>
  );
};

export default ListBoxLink;
