import TextEllipsis from "ds/components/TextEllipsis";
import useTypedContext from "hooks/useTypedContext";

import Typography, { TypographyProps } from "../../Typography";
import { TileContext } from "../Context";

type TileTitleProps = {
  children: React.ReactNode;
  variant?: TypographyProps["variant"];
  color?: TypographyProps["color"];
};

const TileTitle = ({ children, variant = "p-t7", color }: TileTitleProps) => {
  const { titleId } = useTypedContext(TileContext);

  return (
    <TextEllipsis tooltip={children} tooltipWidthMode="maxWidthSm">
      {(props) => (
        <Typography id={titleId} {...props} tag="span" variant={variant} color={color}>
          {children}
        </Typography>
      )}
    </TextEllipsis>
  );
};

export default TileTitle;
