import { gql } from "@apollo/client";

export const CREATE_STACK_DRIFT_DETECTION_SCHEDULING = gql`
  mutation CreateStackDriftDetectionScheduling(
    $stack: ID!
    $input: DriftDetectionIntegrationInput!
  ) {
    stackIntegrationDriftDetectionCreate(stack: $stack, input: $input) {
      schedule
    }
  }
`;

export const UPDATE_STACK_DRIFT_DETECTION_SCHEDULING = gql`
  mutation UpdateStackDriftDetectionScheduling(
    $stack: ID!
    $input: DriftDetectionIntegrationInput!
  ) {
    stackIntegrationDriftDetectionUpdate(stack: $stack, input: $input) {
      reconcile
    }
  }
`;

export const DELETE_STACK_DRIFT_DETECTION_INTEGRATION = gql`
  mutation DeleteStackDriftDetectionIntegration($stackId: ID!) {
    stackIntegrationDriftDetectionDelete(stack: $stackId) {
      deleted
    }
  }
`;
