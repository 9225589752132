import React from "react";

import styles from "./styles.module.css";

type SettingItemActionsProps = {
  children: React.ReactNode;
};

const SettingItemActions = ({ children }: SettingItemActionsProps) => {
  return <div className={styles.actions}>{children}</div>;
};

export default SettingItemActions;
