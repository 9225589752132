import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";
import { BulkActionsVariant } from "components/BulkActions/types";
import BulkActionsConfirmActionStep from "components/BulkActions/ConfirmActionStep";

import { ChangesBulkActionsAnalyticsPayload, ChangesBulkActionsSelectedAction } from "./types";

type ChangesBulkActionsConfirmActionFormProps = {
  action: ChangesBulkActionsSelectedAction;
  variant: BulkActionsVariant;
  onConfirm: (analyticsPayload: ChangesBulkActionsAnalyticsPayload) => void;
  onCancel: () => void;
  isProcessing: boolean;
};

const ChangesBulkActionsConfirmActionForm = ({
  action,
  variant,
  onConfirm,
  onCancel,
  isProcessing,
}: ChangesBulkActionsConfirmActionFormProps) => {
  return (
    <BulkActionsConfirmActionStep>
      <BulkActionsConfirmActionStepInlineConfirmation
        confirmDisabled={isProcessing}
        variant={variant}
        actionLabel={action.title}
        onConfirm={() =>
          onConfirm({
            view: variant,
          })
        }
        onCancel={onCancel}
      />
    </BulkActionsConfirmActionStep>
  );
};

export default ChangesBulkActionsConfirmActionForm;
