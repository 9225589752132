import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type PageWrapperProps = BoxProps;

/**
 * FYI: the component uses specific margins by default to be aligned with Page Title and Stack Header,
 * the default margin in css probably will be removed while redesigning
 */
const PageWrapper = ({
  children,
  direction = "column",
  className,
  ...restProps
}: PageWrapperProps) => {
  return (
    <Box className={cx(styles.pageWrapper, className)} direction={direction} {...restProps}>
      {children}
    </Box>
  );
};

export default PageWrapper;
