import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { BitbucketDatacenterIntegration, Maybe } from "types/generated";

import { CHECK_BITBUCKET_DATA_CENTER_HAS_DEFAULT_INTEGRATION } from "./gql";

const useCheckBitbucketDataCenterHasDefaultIntegration = (skip: boolean) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading } = useQuery<{
    checkBitbucketDataCenterHasDefaultIntegration: Maybe<
      Pick<BitbucketDatacenterIntegration, "id">
    >;
  }>(CHECK_BITBUCKET_DATA_CENTER_HAS_DEFAULT_INTEGRATION, {
    onError,
    fetchPolicy: "no-cache",
    skip,
  });

  return {
    hasDefaultIntegration:
      typeof data?.checkBitbucketDataCenterHasDefaultIntegration?.id !== "undefined",
    loading,
  };
};

export default useCheckBitbucketDataCenterHasDefaultIntegration;
