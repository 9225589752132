import { ReactNode } from "react";

import Box from "ds/components/Box";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

type VCSIntegrationsSetupDropdownOptionProps = {
  children: ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
};

const VCSIntegrationsSetupDropdownOption = ({
  children,
  onClick,
  isDisabled,
}: VCSIntegrationsSetupDropdownOptionProps) => {
  return (
    <DropdownSectionItem
      onClick={onClick}
      disabled={isDisabled}
      tooltip={
        isDisabled ? (
          <>
            The ability to make multiple concurrent VCS integrations of the same type is an
            Enterprise feature.
            <br />
            <br />
            Please remove one of the integrations or upgrade your plan to create multiple concurrent
            VCS connections.
          </>
        ) : undefined
      }
      tooltipWidthMode="maxWidthSm"
      tooltipPlacement="left"
    >
      <Box gap="medium" justify="between" align="center">
        {children}
      </Box>
    </DropdownSectionItem>
  );
};

export default VCSIntegrationsSetupDropdownOption;
