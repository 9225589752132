import DeltaCountsCell from "./Cell";
import { DeltaType } from "./types";
import styles from "./styles.module.css";

type DeltaCountsAnsibleBarProps = {
  skipEmpty?: boolean;
  changeCount: number;
  ignoreCount: number;
  okCount: number;
  rescueCount: number;
  skipCount: number;
  unreachableCount: number;
  failedCount: number;
};

const DeltaCountsAnsibleBar = ({
  skipEmpty,
  changeCount,
  ignoreCount,
  okCount,
  rescueCount,
  skipCount,
  unreachableCount,
  failedCount,
}: DeltaCountsAnsibleBarProps) => {
  return (
    <div className={styles.group}>
      {(!skipEmpty || okCount > 0) && (
        <DeltaCountsCell count={okCount} label={`Ok tasks: ${okCount}`} type={DeltaType.OK} />
      )}
      {(!skipEmpty || changeCount > 0) && (
        <DeltaCountsCell
          count={changeCount}
          label={`Changed tasks: ${changeCount}`}
          type={DeltaType.CHANGED}
        />
      )}
      {(!skipEmpty || unreachableCount > 0) && (
        <DeltaCountsCell
          count={unreachableCount}
          label={`Unreachable tasks: ${unreachableCount}`}
          type={DeltaType.UNREACHABLE}
        />
      )}
      {(!skipEmpty || failedCount > 0) && (
        <DeltaCountsCell
          count={failedCount}
          label={`Failed tasks: ${failedCount}`}
          type={DeltaType.FAILED}
        />
      )}
      {(!skipEmpty || skipCount > 0) && (
        <DeltaCountsCell
          count={skipCount}
          label={`Skipped tasks: ${skipCount}`}
          type={DeltaType.SKIPPED}
        />
      )}
      {(!skipEmpty || rescueCount > 0) && (
        <DeltaCountsCell
          count={rescueCount}
          label={`Rescued tasks: ${rescueCount}`}
          type={DeltaType.RESCUED}
        />
      )}
      {(!skipEmpty || ignoreCount > 0) && (
        <DeltaCountsCell
          count={ignoreCount}
          label={`Ignored tasks: ${ignoreCount}`}
          type={DeltaType.IGNORED}
        />
      )}
    </div>
  );
};

export default DeltaCountsAnsibleBar;
