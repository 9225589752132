import { useNavigate } from "react-router-dom-v5-compat";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import DocumentationIconButton from "components/DocumentationIconButton";
import Button from "ds/components/Button";
import { ClockNew, MenuSpaces } from "components/icons";
import Link from "ds/components/Link";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoBoolean from "components/MetaInfoList/Boolean";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import WebhookOptionDropdown from "views/Account/Webhooks/OptionsDropdown";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import { FILTERS_ORDER_SETTINGS_KEY } from "views/Account/Webhooks/constants";
import TextEllipsis from "ds/components/TextEllipsis";
import { getDocsUrl } from "utils/getDocsUrl";
import { showDeleteConfirmation } from "views/Account/Webhooks/DeleteConfirmation";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import { WebhookContext } from "../Context";
import styles from "./styles.module.css";

const WebhookHeader = () => {
  const { webhook, setDrawerVisibility } = useTypedContext(WebhookContext);
  const navigate = useNavigate();

  const { id, space, enabled, endpoint, name, createdAt } = webhook;

  useBreadcrumbs([
    {
      title: "Webhooks",
      link: getBreadcrumbsBackUrl("/webhooks", FILTERS_ORDER_SETTINGS_KEY),
    },
    {
      title: name,
    },
  ]);

  const handleDeleteClick = () =>
    showDeleteConfirmation({ name, id }).then(() => {
      navigate("/webhooks", { replace: true });
    });

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" justify="between" fullWidth>
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle>{name}</ViewHeaderTitle>
        </ViewHeaderWrapper>

        <ViewHeaderWrapper direction="row" align="end" shrink="0">
          <DocumentationIconButton
            href={getDocsUrl("/integrations/webhooks")}
            tooltipText="Go to webhooks documentation"
          />
          <Button variant="secondary" onClick={() => setDrawerVisibility(true)}>
            Details
          </Button>
          <WebhookOptionDropdown
            id={id}
            space={space}
            onDeleteClick={handleDeleteClick}
            dotSize="medium"
          />
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>
      <ViewHeaderWrapper direction="row" fullWidth>
        <Typography variant="p-body2" tag="span" className={styles.link}>
          Webhook to&nbsp;
          <TextEllipsis tooltip={endpoint} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Link href={endpoint} target="_blank" {...props} v5Compat>
                {endpoint}
              </Link>
            )}
          </TextEllipsis>
        </Typography>
      </ViewHeaderWrapper>

      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          {space && (
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={space.name}
              href={`/spaces/${space.id}`}
            />
          )}
          <MetaInfoBoolean text={enabled ? "Enabled" : "Disabled"} value={enabled} />
          <MetaInfoListItem icon={ClockNew}>
            <Timestamp timestamp={createdAt} />
          </MetaInfoListItem>
        </MetaInfoList>
      </ViewHeaderScrollCollapse>
    </ViewHeader>
  );
};

export default WebhookHeader;
