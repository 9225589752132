import { gql } from "@apollo/client";

export const CREATE_AZURE_DEVOPS_INTEGRATION = gql`
  mutation CreateAzureDevOpsIntegration(
    $userFacingHost: String
    $organizationURL: String!
    $personalAccessToken: String!
    $customInput: CustomVCSInput!
  ) {
    azureDevOpsRepoIntegrationCreate(
      userFacingHost: $userFacingHost
      customInput: $customInput
      organizationURL: $organizationURL
      personalAccessToken: $personalAccessToken
    ) {
      id
      name
    }
  }
`;

export const UPDATE_AZURE_DEVOPS_INTEGRATION = gql`
  mutation UpdateAzureDevOpsIntegration(
    $organizationURL: String!
    $customInput: CustomVCSUpdateInput
    $personalAccessToken: String
  ) {
    azureDevOpsRepoIntegrationUpdate(
      organizationURL: $organizationURL
      customInput: $customInput
      personalAccessToken: $personalAccessToken
    ) {
      id
      name
    }
  }
`;

export const DELETE_AZURE_DEVOPS_INTEGRATION = gql`
  mutation DeleteAzureDevOpsIntegration($id: ID!) {
    azureDevOpsRepoIntegrationDelete(id: $id) {
      id
      name
    }
  }
`;

export const CHECK_AZURE_DEVOPS_HAS_DEFAULT_INTEGRATION = gql`
  query CheckAzureDevOpsHasDefaultIntegration {
    checkAzureDevOpsHasDefaultIntegration: azureDevOpsRepoIntegration {
      id
    }
  }
`;
