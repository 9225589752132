import { useCallback, useMemo } from "react";
import useLocalStorage from "@rehooks/local-storage";

import CustomizeView from "components/CustomizeView";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import useTypedContext from "hooks/useTypedContext";
import { TableContext } from "components/Table/Context";
import ButtonNew from "ds/components/Button/New";
import DrawerFooter from "ds/components/Drawer/Footer";
import { getLocalStorageConfigSizesId } from "components/Table/utils";
import { CustomizeViewConfig } from "components/CustomizeView/types";

import { getLabel } from "./utils";
import { ROW_HEADER_ID, STACKS_COLUMN_ID, STACK_COLUMNS_STORAGE_KEY } from "../constants";
import { INITIAL_COLUMNS_CONFIG } from "./constants";
import { showResetConfirmation } from "./ResetConfirmation";
import useStackListAnalytics from "../useStackListAnalytics";

const ALWAYS_VISIBLE_ITEMS = [ROW_HEADER_ID];

type CustomizeViewDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  onConfigReset: () => void;
};

const CustomizeViewDrawer = ({ isVisible, onClose, onConfigReset }: CustomizeViewDrawerProps) => {
  const [, , clearOrderConfig] = useLocalStorage(STACK_COLUMNS_STORAGE_KEY);
  const [, , clearSizesConfig] = useLocalStorage(
    getLocalStorageConfigSizesId(STACK_COLUMNS_STORAGE_KEY)
  );

  const trackAnalytics = useStackListAnalytics();

  const resetConfig = useCallback(() => {
    trackAnalytics("Table Configuration Reset");
    clearOrderConfig();
    clearSizesConfig();
    onClose();
    // Move to end of queue
    setTimeout(onConfigReset);
  }, [clearOrderConfig, clearSizesConfig, onClose, onConfigReset, trackAnalytics]);

  const handleOnChange = useCallback(
    (config: CustomizeViewConfig<STACKS_COLUMN_ID>) => {
      trackAnalytics("Table configuration changed", { ...config, method: "customize drawer" });
    },
    [trackAnalytics]
  );

  const { mergedColumnsConfig } = useTypedContext(TableContext);

  const staticColumns = useMemo(
    () =>
      Object.keys(mergedColumnsConfig)
        .filter((key) => mergedColumnsConfig[key].static)
        .map((key) => key as STACKS_COLUMN_ID),
    [mergedColumnsConfig]
  );

  return (
    <Drawer visible={isVisible} onOutsideClick={onClose}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Customize list" />
        <DrawerCloseIcon handleCloseDrawer={onClose} />
      </DrawerHeader>
      <DrawerBody noPadding>
        <CustomizeView
          visibleSectionTitle="Visible items"
          hiddenSectionTitle="Hidden items"
          storageKey={STACK_COLUMNS_STORAGE_KEY}
          defaultConfig={INITIAL_COLUMNS_CONFIG}
          staticItems={staticColumns}
          getLabel={getLabel}
          onChange={handleOnChange}
          alwaysVisibleItems={ALWAYS_VISIBLE_ITEMS}
        />
      </DrawerBody>
      <DrawerFooter>
        <ButtonNew
          onPress={() => showResetConfirmation({ onReset: resetConfig })}
          variant="secondary"
        >
          Reset to default
        </ButtonNew>
      </DrawerFooter>
    </Drawer>
  );
};

export default CustomizeViewDrawer;
