import ButtonIcon from "ds/components/ButtonIcon";
import CodeEditor from "components/CodeEditor";
import CollapsibleList from "components/CollapsibleList";
import PolicyTypeBadge from "components/PolicyTypeBadge";
import { Expand } from "components/icons";
import { StackPolicyAttachment } from "types/generated";
import Box from "ds/components/Box";
import useDetachPolicy from "shared/Policy/useDetachPolicy";
import useTypedContext from "hooks/useTypedContext";
import Tag from "ds/components/Tag";
import CollapsibleListTitle from "components/CollapsibleList/Title";
import { getAttachedLabel } from "utils/labels";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import { GET_POLICIES_REFETCH_QUERIES } from "./constants";
import { ModuleContext } from "../Context";
import { showModulePoliciesDetachConfirmationModal } from "./ConfirmationModal";

type ModulePoliciesListItemProps = {
  policy: StackPolicyAttachment;
  moduleLabels?: string[];
};

const ModulePoliciesListItem = ({ policy, moduleLabels }: ModulePoliciesListItemProps) => {
  const isAutoAttached = policy.isAutoattached;
  const policyLink = `/policy/${policy.policyId}/`;

  const { canManageModule } = useTypedContext(ModuleContext);

  const { detachPolicy, loading } = useDetachPolicy("module", GET_POLICIES_REFETCH_QUERIES);

  const onDelete = () => {
    detachPolicy(policy.id);
  };

  const onDetachConfirm = () => {
    showModulePoliciesDetachConfirmationModal({
      onConfirm: onDelete,
      name: policy.policyName,
    });
  };

  return (
    <CollapsibleList
      ariaLevel={4}
      title={<CollapsibleListTitle variant="p-t6">{policy.policyName}</CollapsibleListTitle>}
      action={
        <Box justify="between" align="center" gap="medium" grow="1">
          <ButtonIcon icon={Expand} to={policyLink} variant="ghost">
            Go to policy editor
          </ButtonIcon>

          <Box align="center" gap="medium">
            {/* FYI: show only the first auto attached label from the list */}
            {isAutoAttached && moduleLabels && (
              <Tag tag={getAttachedLabel(moduleLabels, policy.policyLabels)} />
            )}

            <PolicyTypeBadge type={policy.policyType} />

            <DropdownMenuEllipsis tooltip="Policy actions" buttonVariant="ghost">
              <DropdownMenuItem href={policyLink}>Go to policy editor</DropdownMenuItem>
              {!isAutoAttached && canManageModule && (
                <DropdownMenuItem loading={loading} danger onAction={onDetachConfirm}>
                  Detach policy
                </DropdownMenuItem>
              )}
            </DropdownMenuEllipsis>
          </Box>
        </Box>
      }
    >
      <CodeEditor
        className={styles.codeEditor}
        body={policy.policyBody}
        language="rego"
        autoHeight
        maxAutoHeight={460}
        readOnly
      />
    </CollapsibleList>
  );
};

export default ModulePoliciesListItem;
