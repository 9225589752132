import { useMemo } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import TileTitle from "ds/components/Tile/Title";
import Badge from "ds/components/Badge";
import { IconComponent } from "types/Icon";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileIndicator from "ds/components/Tile/Indicator";
import Box from "ds/components/Box";
import { ChevronNew } from "components/icons";
import Icon from "ds/components/Icon";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import useAnalytics from "hooks/useAnalytics";

type GetStartedChecklistFeatureTileProps = {
  title: string;
  icon: IconComponent;
  to: string;
  isCompleted: boolean;
  analyticsTitle: string;
};

const GetStartedChecklistFeatureTile = ({
  title,
  isCompleted,
  icon,
  to,
  analyticsTitle,
}: GetStartedChecklistFeatureTileProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,
  });

  const navigate = useNavigate();

  const handleClick = () => {
    trackSegmentAnalyticsEvent(analyticsTitle);
    navigate(to);
  };

  const indicatorComponent = useMemo(() => {
    if (isCompleted) {
      return <Badge state="info">Complete</Badge>;
    }

    return <Icon src={ChevronNew} />;
  }, [isCompleted]);

  return (
    <TileWrapper selected={isCompleted} onClick={isCompleted ? undefined : handleClick}>
      <Box align="center" justify="between" grow="1">
        <TileContent direction="row" align="center">
          <IconTile icon={icon} variant={isCompleted ? "primary" : "secondary"} />
          <TileTitle>{title}</TileTitle>
        </TileContent>
        <TileIndicator indicator={indicatorComponent} />
      </Box>
    </TileWrapper>
  );
};

export default GetStartedChecklistFeatureTile;
