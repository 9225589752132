import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import DrawerBody from "ds/components/Drawer/Body";

import EnvironmentPreview from "..";
import styles from "./styles.module.css";

type EnvironmentPreviewDrawerProps = {
  moduleId: string;
  visible: boolean;
  onCloseDrawer: () => void;
};

const EnvironmentPreviewDrawer = ({
  moduleId,
  visible,
  onCloseDrawer,
}: EnvironmentPreviewDrawerProps) => {
  return (
    <Drawer position="fixedRight" visible={visible} onOutsideClick={onCloseDrawer} variant="wide">
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Environment preview" />
        <DrawerCloseIcon handleCloseDrawer={onCloseDrawer} />
      </DrawerHeader>
      <DrawerBody hasStickyFooter>
        <EnvironmentPreview moduleId={moduleId} className={styles.bodyContent} />

        <DrawerFooter sticky>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onCloseDrawer}>
              Close
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default EnvironmentPreviewDrawer;
