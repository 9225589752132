import React from "react";

import useTitle from "hooks/useTitle";

import styles from "./styles.module.css";

type SettingItemContentProps = {
  title: string;
  children: React.ReactNode;
};

const SettingItemContent = ({ title, children }: SettingItemContentProps) => {
  useTitle(title);

  return <div className={styles.content}>{children}</div>;
};

export default SettingItemContent;
