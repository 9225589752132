import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { ApolloError, useLazyQuery } from "@apollo/client";

import { TerraformWorkflowTool } from "types/generated";

import { StackCreationVersionType, StackVendorFormFields } from "../../types";
import { CHECK_OPEN_TOFU_VERSION, CHECK_TERRAFORM_VERSION } from "./gql";

const DEBOUNCE_TIMEOUT = 300;

const useAsyncRangeVersionValidation = (
  setAsyncValidationLoading: (isLoading: boolean) => void
) => {
  const { setError, getValues, formState, clearErrors } = useFormContext<StackVendorFormFields>();
  const timeout = useRef<NodeJS.Timeout>();

  const stopLoading = () => {
    if (!timeout.current) {
      setAsyncValidationLoading(false);
    }
  };

  const onError = (error: ApolloError) => {
    stopLoading();

    if (!formState.errors.terraform?.version) {
      setError("terraform.version", {
        type: "custom",
        message: error.message,
      });
    }
  };

  const onCompleted = () => {
    stopLoading();

    if (formState.errors.terraform?.version?.type === "custom") {
      clearErrors("terraform.version");
    }
  };

  const [checkTerraformVersion] = useLazyQuery(CHECK_TERRAFORM_VERSION, {
    onError,
    onCompleted,
  });

  const [checkOpenTofuVersion] = useLazyQuery(CHECK_OPEN_TOFU_VERSION, {
    onError,
    onCompleted,
  });

  const values = getValues();
  const terraformValues = values.terraform;

  useEffect(() => {
    if (
      terraformValues.workflowTool &&
      terraformValues.version.value &&
      terraformValues.version.type === StackCreationVersionType.Range
    ) {
      setAsyncValidationLoading(true);
      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        if (terraformValues.workflowTool == TerraformWorkflowTool.OpenTofu) {
          checkOpenTofuVersion({ variables: { constraints: terraformValues.version.value } });
        } else {
          checkTerraformVersion({ variables: { constraints: terraformValues.version.value } });
        }
      }, DEBOUNCE_TIMEOUT);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }

      setAsyncValidationLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terraformValues.version, terraformValues.workflowTool]);
};

export default useAsyncRangeVersionValidation;
