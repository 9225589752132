import { useCallback, useEffect, useRef } from "react";
import { localPoint } from "@visx/event";
import { Bar } from "@visx/shape";
import { Point } from "@visx/point";
import { ScaleBand } from "d3";
import { StringLike } from "@visx/scale";

type BarChartOverlayProps<T extends StringLike> = {
  keys: T[];
  xScale: ScaleBand<T>;
  padding: number;
  yMax: number;
  onMouseMove: (barIndex: number, coordinates: Point | null) => void;
  onMouseLeave: () => void;
};

const MOUSE_LEAVE_TIMEOUT = 200;

const BarChartOverlay = <T extends StringLike>({
  keys,
  xScale,
  yMax,
  padding,
  onMouseMove,
  onMouseLeave,
}: BarChartOverlayProps<T>) => {
  const mouseLeaveTimeoutId = useRef<number>();

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearTimeout(mouseLeaveTimeoutId.current);
    };
  }, []);

  const handleMouseLeave = useCallback(() => {
    mouseLeaveTimeoutId.current = window.setTimeout(() => {
      onMouseLeave();
    }, MOUSE_LEAVE_TIMEOUT);
  }, [onMouseLeave]);

  const createMouseMoveHandler = useCallback(
    (barIndex: number) => (event: React.MouseEvent<SVGRectElement>) => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current);

      const eventSvgCoords = localPoint(event);

      onMouseMove(barIndex, eventSvgCoords);
    },
    [onMouseMove]
  );

  return (
    <>
      {keys.map((key, index) => {
        const barWidth = xScale.bandwidth() / padding;
        const barHeight = yMax;
        const originalBarCenter = (xScale(key) || 0) + xScale.bandwidth() / 2;
        const barX = originalBarCenter - barWidth / 2;
        const barY = 0;

        // Bar overlays help to show tooltip on hover more smoothly
        return (
          <Bar
            key={`bar-overlay-${key}`}
            x={barX}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill="transparent"
            onMouseLeave={handleMouseLeave}
            onMouseMove={createMouseMoveHandler(index)}
          />
        );
      })}
    </>
  );
};

export default BarChartOverlay;
