import { useMemo } from "react";

import { RunReviewDecision } from "types/generated";

import { StackActionItemForBulkActions } from "./types";
import useDisableStackBulkAction from "./useDisableStackBulkAction";
import useDiscardStackRunBulkAction from "./useDiscardStackRun";
import useEnableStackBulkAction from "./useEnableStackBulkAction";
import useReviewStackBulkAction from "./useReviewStackRunBulkAction";
import useRunTaskBulkAction from "./useRunTaskBulkAction";
import useSyncCommitBulkAction from "./useSyncCommitBulkAction";
import useTriggerStackBulkAction from "./useTriggerStackBulkAction";
import useLockBulkAction from "./useLockBulkAction";
import useUnlockBulkAction from "./useUnlockBulkAction";
import useConfirmStackRunBulkAction from "./useConfirmStackRun";

const useStackBulkActions = (): StackActionItemForBulkActions[] => {
  const confirmStackRunAction = useConfirmStackRunBulkAction();
  const discardStackRunAction = useDiscardStackRunBulkAction();
  const lockBulkAction = useLockBulkAction();
  const unlockBulkAction = useUnlockBulkAction();
  const syncCommitBulkAction = useSyncCommitBulkAction();
  const enableStackBulkAction = useEnableStackBulkAction();
  const disableStackBulkAction = useDisableStackBulkAction();
  const reviewApproveStackBulkAction = useReviewStackBulkAction(RunReviewDecision.Approve);
  const reviewRejectStackBulkAction = useReviewStackBulkAction(RunReviewDecision.Reject);
  const runTaskStackBulkAction = useRunTaskBulkAction();
  const triggerStackBulkAction = useTriggerStackBulkAction();

  return useMemo(
    () => [
      confirmStackRunAction,
      discardStackRunAction,
      triggerStackBulkAction,
      syncCommitBulkAction,
      lockBulkAction,
      unlockBulkAction,
      enableStackBulkAction,
      disableStackBulkAction,
      reviewApproveStackBulkAction,
      reviewRejectStackBulkAction,
      runTaskStackBulkAction,
    ],
    [
      confirmStackRunAction,
      discardStackRunAction,
      triggerStackBulkAction,
      syncCommitBulkAction,
      lockBulkAction,
      unlockBulkAction,
      enableStackBulkAction,
      disableStackBulkAction,
      reviewApproveStackBulkAction,
      reviewRejectStackBulkAction,
      runTaskStackBulkAction,
    ]
  );
};

export default useStackBulkActions;
