import fuzzysort from "fuzzysort";
import { useMemo } from "react";

import { getAutocompleteHighlights } from "utils/fuzzySearch";

import styles from "./styles.module.css";

type SearchHighlightProps = {
  fullText: string;
  searchQuery: string;
};

const SearchHighlight = ({ searchQuery, fullText }: SearchHighlightProps) => {
  const highlights = useMemo(
    () => getAutocompleteHighlights(fullText, searchQuery),
    [fullText, searchQuery]
  );

  if (!highlights) return <>{fullText}</>;

  return (
    <span aria-label={fullText}>
      <span aria-hidden>
        {fuzzysort.highlight(highlights, (m, i) => (
          <strong key={i} className={styles.highlight}>
            {m}
          </strong>
        ))}
      </span>
    </span>
  );
};

export default SearchHighlight;
