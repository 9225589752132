import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPage } from "hooks/useAnalytics";

import { FilterItem, FiltersApplyView } from "../types";
import FilterSection from "./FilterSection";
import FiltersSidebarSkeleton from "./FiltersSidebarSkeleton";
import FiltersSavedViewsPanel from "../SavedViews/Panel";
import SidebarActions from "./SidebarActions";
import { SavedFiltersContext } from "../SavedViews/Context";

type FiltersTabProps = {
  analyticsPage?: AnalyticsPage;
  applyView: FiltersApplyView;
  filters: FilterItem[];
  loading: boolean;
};

const FiltersTab = ({
  analyticsPage,
  applyView,
  filters,
  loading: loadingFilters,
}: FiltersTabProps) => {
  const { loading: savedViewsLoading } = useTypedContext(SavedFiltersContext);

  const loading = savedViewsLoading || loadingFilters;

  return (
    <Box direction="column">
      {!loading && <FiltersSavedViewsPanel applyView={applyView} />}

      <SidebarActions analyticsPage={analyticsPage} />

      {!loading ? (
        filters.map((filterItem, i) => (
          <FilterSection
            key={filterItem.key}
            filterItem={filterItem}
            isLastLabelsFilter={
              filterItem.filterName === "label" && filters[i + 1]?.filterName !== "label"
            }
            analyticsPage={analyticsPage}
          />
        ))
      ) : (
        <FiltersSidebarSkeleton />
      )}
    </Box>
  );
};

export default FiltersTab;
