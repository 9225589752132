import { forwardRef, ReactNode } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type DropdownProps = {
  className?: string;
  children?: ReactNode;
};

const Dropdown = forwardRef(function Dropdown(
  props: DropdownProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { className, children } = props;

  return (
    <div ref={ref} className={cx(styles.dropdown, className)}>
      {children}
    </div>
  );
});

export default Dropdown;
