import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { nanoid } from "nanoid";
import { useState } from "react";

import { reorderMultipleDNDLists } from "utils/dnd";
import Box from "ds/components/Box";
import { HookType } from "utils/hooks";

import { Commands } from "./types";
import BeforeAfterCommandsList from "./List";

type BeforeAfterCommandsProps = {
  commands: Commands;
  onChange: (commands: Commands) => void;
  readOnly?: boolean;
  onAfterInputChange?: (value: string) => void;
  onBeforeInputChange?: (value: string) => void;
};

const BeforeAfterCommands = ({
  commands = { before: [], after: [] },
  onChange,
  readOnly,
  onAfterInputChange,
  onBeforeInputChange,
}: BeforeAfterCommandsProps) => {
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragStart = () => {
    setIsDragActive(true);
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragActive(false);

    if (!result.destination) {
      return;
    }

    onChange(
      reorderMultipleDNDLists(
        commands,
        result.source.droppableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index
      )
    );
  };

  const handleAddCommands = (type: HookType, lines: string[]) => {
    onChange({
      ...commands,
      [type]: [
        ...commands[type],
        ...lines.map((line) => ({
          text: line,
          id: nanoid(),
        })),
      ],
    });
  };

  const handleRemoveCommand = (type: HookType, id: string) =>
    onChange({
      ...commands,
      [type]: commands[type].filter((command) => command.id !== id),
    });

  const handleEditCommand = (type: HookType, id: string, text: string) => {
    onChange({
      ...commands,
      [type]: commands[type].map((command) => (command.id === id ? { ...command, text } : command)),
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Box gap="medium" direction="column" fullWidth>
        {commands.before && (
          <BeforeAfterCommandsList
            isDragActive={isDragActive}
            type="before"
            title="Before"
            commands={commands.before}
            onAddCommands={handleAddCommands}
            onRemoveCommand={handleRemoveCommand}
            readOnly={readOnly}
            onEditCommand={handleEditCommand}
            onChangeCallback={onAfterInputChange}
          />
        )}
        {commands.after && (
          <BeforeAfterCommandsList
            isDragActive={isDragActive}
            type="after"
            title="After"
            commands={commands.after}
            onAddCommands={handleAddCommands}
            onRemoveCommand={handleRemoveCommand}
            readOnly={readOnly}
            onEditCommand={handleEditCommand}
            onChangeCallback={onBeforeInputChange}
          />
        )}
      </Box>
    </DragDropContext>
  );
};

export default BeforeAfterCommands;
