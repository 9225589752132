import cx from "classnames";

import Box, { BoxProps } from "ds/components/Box";

import styles from "./styles.module.css";

type SystemMessagePageWrapperProps = BoxProps;

const SystemMessagePageWrapper = ({
  children,
  className,
  ...restProps
}: SystemMessagePageWrapperProps) => {
  return (
    <Box
      align="center"
      justify="center"
      className={cx(styles.pageWrapper, className)}
      fullWidth
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default SystemMessagePageWrapper;
