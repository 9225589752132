import { useCallback, useMemo } from "react";
import { Base64 } from "js-base64";
import { EditorProps } from "@monaco-editor/react";

import useTypedFlags from "hooks/useTypedFlags";
import Box from "ds/components/Box";
import { EntityAttributeChange, EntityChangeType } from "types/generated";
import CodeEditor from "components/CodeEditor";
import { CodeChangesGroupNode } from "components/CodeChanges/types";
import CodeChanges from "components/CodeChanges";

import {
  generateNestedObjectBasedOnJsonPath,
  generateEntityChangeValue,
  getPrettyPrintedResources,
  populateChangeTree,
  normalizePath,
} from "./helpers";
import styles from "./styles.module.css";
import { EncodedJsonValue, EncodedJsonValueTuple, RunChangesEntityChangeType } from "./types";
import { showJsonDiffModal } from "./JsonDiffModal";
import { showJsonModal } from "./JsonModal";
import { useJsonCodeLenses } from "./hooks";

type RunChangesResourcesDiffProps = {
  items: EntityAttributeChange[];
  changeType: RunChangesEntityChangeType;
  editorHeight: number;
  setEditorHeight: (height: number) => unknown;
  id: string;
};

const monacoOptions: EditorProps["options"] = {
  lineNumbers: "off",
  glyphMargin: false,
  unicodeHighlight: {
    invisibleCharacters: false,
  },
};

const RunChangesResourcesDiff = ({
  editorHeight,
  items,
  changeType,
  setEditorHeight,
  id,
}: RunChangesResourcesDiffProps) => {
  const { diffViewForJsonEncodedValues, runChangesDiffImprovements } = useTypedFlags();

  const changesArray = useMemo(
    () =>
      items.map((item) => {
        return {
          name: item.path,
          value: generateEntityChangeValue(changeType, item.changeForPhase),
          type: item.changeForPhase.metadata.type,
        };
      }),
    [items, changeType]
  );

  const resourceChanges = useMemo(
    () => generateNestedObjectBasedOnJsonPath(changesArray),
    [changesArray]
  );

  const resourcesChangesOutcome = useMemo(
    () => getPrettyPrintedResources(resourceChanges, changeType, diffViewForJsonEncodedValues),
    [resourceChanges, changeType, diffViewForJsonEncodedValues]
  );

  let resourcesChangeText = "Not possible to display.";

  if (resourcesChangesOutcome.result.length) {
    resourcesChangeText = resourcesChangesOutcome.result.trimEnd();
  } else if (changeType === EntityChangeType.Move && changesArray.length === 0) {
    resourcesChangeText = "Resource moved without any modifications.";
  }

  const handleJsonDiffModalOpen = useCallback((value: EncodedJsonValueTuple) => {
    showJsonDiffModal({ value });
  }, []);

  const handleOnJsonModalOpen = useCallback((value: EncodedJsonValue) => {
    showJsonModal({ value });
  }, []);

  const onEditorMount = useJsonCodeLenses({
    id: Base64.encodeURI(id),
    jsonLocations: resourcesChangesOutcome.jsonLocations,
    onJsonDiffModalOpen: handleJsonDiffModalOpen,
    onJsonModalOpen: handleOnJsonModalOpen,
  });

  const parsedItemsNewCodeChanges = useMemo((): CodeChangesGroupNode => {
    const root: CodeChangesGroupNode = {
      id: "root",
      children: [],
    };

    if (!runChangesDiffImprovements) return root;

    items.forEach((item) => {
      const keys = normalizePath(item.path);

      populateChangeTree(item, keys, root);
    });

    return root;
  }, [items, runChangesDiffImprovements]);

  return (
    <Box
      direction="column"
      grow="1"
      fullWidth
      relative
      className={styles.details}
      style={{ height: editorHeight }}
    >
      {runChangesDiffImprovements ? (
        <CodeChanges changes={parsedItemsNewCodeChanges} onResize={setEditorHeight} />
      ) : (
        <CodeEditor
          body={resourcesChangeText}
          language="resourcesDiff"
          className={styles.editor}
          handleHeightCallback={setEditorHeight}
          path={diffViewForJsonEncodedValues ? Base64.encodeURI(id) : undefined}
          options={monacoOptions}
          readOnly
          onMountCallback={onEditorMount}
          skeletonCount={3}
        />
      )}
    </Box>
  );
};

export default RunChangesResourcesDiff;
