import { VcsProvider } from "types/generated";

export const VCS_PROVIDERS = VcsProvider;

export const HUMANIZE_PROVIDER = {
  [VcsProvider.BitbucketCloud]: "Bitbucket Cloud",
  [VcsProvider.BitbucketDatacenter]: "Bitbucket Data Center",
  [VcsProvider.Github]: "GitHub",
  [VcsProvider.GithubEnterprise]: "GitHub (custom App)",
  [VcsProvider.Gitlab]: "GitLab",
  [VcsProvider.Showcase]: "Showcase (Experimental)",
  [VcsProvider.AzureDevops]: "Azure DevOps",
  [VcsProvider.Git]: "Raw Git",
};

export enum VCS_INTEGRATION_DETAILS_UNION {
  AZURE_DEVOPS_REPO_INTEGRATION = "AzureDevOpsRepoIntegration",
  BITBUCKET_CLOUD_INTEGRATION = "BitbucketCloudIntegration",
  BITBUCKET_DATACENTER_INTEGRATION = "BitbucketDatacenterIntegration",
  GITHUB_ENTERPRISE_INTEGRATION = "GithubEnterpriseIntegration",
  GITHUB_INTEGRATION = "GithubIntegration",
  GITLAB_INTEGRATION = "GitlabIntegration",
}
