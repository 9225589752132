/* eslint react/prop-types: warn */
import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import cx from "classnames";

import { Drift } from "components/icons";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import FullScreenToggleButton from "./FullScreenToggleButton";
import {
  getIdText,
  getRectPosition,
  getVisibleTreeNodes,
  groupBy,
  zoomIntoSubtree,
} from "./helpers";
import { getKeyValue, getVisibleEntities } from "../helpers";

import "./styles.css";

const Chart = (props) => {
  const {
    data,
    filters: parentFilters,
    fullScreen,
    groupByKey: parentGroupByKey,
    handleFilterAdd,
    isAccountWide,
    setEntityDetails,
    setMenuVisible,
    toggleFullScreen,
    zoomTarget,
    setZoomTarget,
    stackName,
  } = props;
  const d3Container = useRef(null);
  const d3Wrapper = useRef(null);
  const [height, setHeight] = useState(500);
  const [width, setWidth] = useState(1000);
  const [showTooManyRectanglesWarning, setShowTooManyRectanglesWarning] = useState(false);

  const createChart = useCallback(() => {
    const node = d3Container.current;
    let allEntities = data;
    const filters = parentFilters;

    const t = d3
      .select(node)
      .attr("width", width)
      .attr("height", height)
      .transition()
      .duration(1000);

    const lineWidth = 200;
    const rectWidth = 20;
    const rectHeight = 20;
    const rectsInLine = lineWidth / rectWidth;
    let groupByKey = parentGroupByKey;

    // Create the tooltip if it doesn't exist yet. It's invisible initially.
    // The [0] is a placeholder so we create a single one.
    d3.select("body")
      .selectAll(".d3-tooltip")
      .data([0])
      .enter()
      .append("div")
      .attr("class", "d3-tooltip")
      .style("opacity", 0);

    // If we're grouping by the resource parents, then we want to present it in the hierarchical view.
    let hierarchical = groupByKey === "parent";

    // Handle possible zooming.
    let zoomParentId = "";
    let allEntitiesRoot = "";
    [allEntities, zoomParentId, allEntitiesRoot] = zoomIntoSubtree(allEntities, zoomTarget);

    let visibleEntities = getVisibleEntities(allEntities, filters);
    const tooManyRectangles = visibleEntities.length > 1000;
    setShowTooManyRectanglesWarning(tooManyRectangles);
    let visibleGroupedByKey = groupBy(visibleEntities, groupByKey);

    // *** universal variables ***

    // Position for each resource's rectangle.
    let rectTransforms = {};

    // *** flat layout variables ***

    // Texts on the left side of the canvas, describing group names.
    let flatTexts = [];

    // *** hierarchical layout variables ***

    // Describes all links between tree nodes.
    let hierarchicalLinks = [];

    // Describes all tree nodes. Their ID's and positions.
    let hierarchicalLinkNodes = [];

    // The tree gets created with the root being in (0,0), and branches extending in all directions.
    // Here we store the minimum X value and add it everywhere,
    // so we're sure to not go into negative X values.
    let hierarchicalMinX = Infinity;

    // This is a set of all visible entities which are also nodes of the tree.
    // In other words, these entities are parents of other entities.
    // Not all tree nodes are entities, some will be entity-less (modules),
    // then we won't see any entity on the tree node.
    let hierarchicalNodeEntities = new Set();

    // This is a transform which all rendered things get in the hierarchical layout,
    // to adjust the position to the viewport.
    let hierarchicalGlobalTransform = 0;

    // Both of these dictate how much space there will be between tree nodes.
    // The X will later be set based on the biggest group of rectangles under a node.
    // Unfortunately, all nodes must have the same size, we can't fine-tune each node's size.
    let nodeSizeX = 0;
    let nodeSizeY = 320;

    // *** end variables ***

    if (!hierarchical) {
      // This branch of the if sets up positioning for a flat view, with group names one the left,
      // and the groups being displayed line by line.

      // Each group occupies a number of lines based on the number of rectangles in a single line,
      // and the number of rectangles in the group.
      // This allows us to calculate at which line each of the groups should start at.
      let groupOffset = {};
      let curLine = 1;
      for (let key of Object.keys(visibleGroupedByKey).sort()) {
        groupOffset[key] = curLine * rectHeight;
        curLine += !tooManyRectangles
          ? Math.ceil(visibleGroupedByKey[key].length / rectsInLine)
          : 1;
        // We leave a one-line gap between each group.
        curLine++;
      }

      setHeight(curLine * rectHeight);
      setWidth(750);

      // Each entity will have the index of itself in its group stored here.
      let positionInGroup = {};
      for (let entity of visibleEntities) {
        let keyValue = getKeyValue(groupByKey, entity);
        for (let i = 0; i < visibleGroupedByKey[keyValue].length; i++) {
          if (visibleGroupedByKey[keyValue][i].id === entity.id) {
            positionInGroup[entity.id] = i;
            break;
          }
        }
      }

      // We could think about fine-tuning the text-width.
      let textWidth = 500;
      for (let entity of visibleEntities) {
        // The transform of a rectangle is:
        //  1. Translation to its offset inside of its group. <- specific to this rectangle
        //  2. Translation to the line its group starts at. <- same for all rectangles in a group
        //  3. Translation to the right, so we leave space for the text. <- same for all rectangles
        rectTransforms[entity.id] = `translate(${getRectPosition(
          positionInGroup[entity.id],
          rectWidth,
          rectHeight,
          lineWidth
        )}) translate(0 ${groupOffset[getKeyValue(groupByKey, entity)]}) translate(${textWidth} 0)`;
      }

      // For each group we set the text label for it.
      for (let key of Object.keys(visibleGroupedByKey).sort()) {
        // We use a composite key, so ID's change when changing the grouping.
        // Otherwise update bugs start to appear.
        flatTexts.push({
          key: groupByKey + "_" + key,
          offset: groupOffset[key],
          text: key,
          count: visibleGroupedByKey[key]?.length,
        });
      }
    } else {
      // This branch of the if sets up positioning for a hierarchical tree view,
      // with "modules"/"treeNodes of entities" as tree nodes, and non-parent entities as leaves.

      // HACK: This is used to check if a parent exists when calling stratify
      // or displaying entities. Reason for this is that Pulumi parents aren't
      // updated correctly if aliases are used breaking this screen all together..
      let entitiesById = new Set(allEntities.map((e) => e.id));

      visibleEntities = visibleEntities.filter(
        (entity) => entity.parent === "" || entitiesById.has(entity.parent)
      );
      let visibleTreeNodes = getVisibleTreeNodes(allEntities, visibleEntities);

      // If an entity's ID is someone's parent, then this entity will be displayed as a tree node.
      for (let entity of visibleEntities) {
        if (visibleTreeNodes[entity.id]) {
          hierarchicalNodeEntities.add(entity.id);
        }
      }

      // Each entity will have the index of itself in its group stored here.
      // A caveat is that only leaf entities are displayed in the group.
      // If an entity is a tree node, we omit it here.
      let positionInGroup = {};
      for (let entity of visibleEntities) {
        if (hierarchicalNodeEntities.has(entity.id)) {
          continue;
        }
        let keyValue = getKeyValue(groupByKey, entity);
        let hierarchyNodeEntitiesSeen = 0;
        for (let i = 0; i < visibleGroupedByKey[keyValue].length; i++) {
          if (hierarchicalNodeEntities.has(visibleGroupedByKey[keyValue][i].id)) {
            hierarchyNodeEntitiesSeen++;
            continue;
          }
          if (visibleGroupedByKey[keyValue][i].id === entity.id) {
            positionInGroup[entity.id] = i - hierarchyNodeEntitiesSeen;
            break;
          }
        }
      }

      // Find largest node (in terms of present rectangles)
      // We'll base each node's X size on it.
      let maxNodeLines = 0;
      if (!tooManyRectangles) {
        for (let key of Object.keys(visibleGroupedByKey)) {
          let currentNodeLines = Math.ceil(visibleGroupedByKey[key].length / rectsInLine);
          if (currentNodeLines > maxNodeLines) {
            maxNodeLines = currentNodeLines;
          }
        }
      }
      nodeSizeX = (maxNodeLines + 2) /*two rectangles of padding*/ * rectWidth;

      // Create a list of tree nodes. Those may be both visible entities (resources),
      // as well as invisible ones (modules).
      let treeNodes = [];

      for (let entity of allEntities) {
        if (!visibleTreeNodes[entity.id]) {
          continue;
        }
        if (entity.parent !== "" && !entitiesById.has(entity.parent)) {
          continue;
        }

        treeNodes.push({
          id: entity.id,
          name: entity.name,
          parent: entity.parent,
          count: visibleGroupedByKey[entity.id]?.length,
        });
      }

      // Stratify creates a tree structure from a child -> parent list.
      if (treeNodes.length === 0) return;

      let stratified = d3
        .stratify()
        .id((d) => d.id)
        .parentId((d) => d.parent)(treeNodes);

      // Create a tree builder based on the tree nodes we found and the node sizes.
      let tree = d3.tree().nodeSize([nodeSizeX, nodeSizeY])(stratified);

      if (zoomTarget !== "") {
        // When we're zooming, then the root node is just a gateway to zooming out.
        // That's why we rearrange everything to be a bit closer to the root node,
        // which doesn't even have a label.
        let zoomTargetParent = tree;
        const zoomTarget = tree.children[0];
        let moveBy = (zoomTarget.y - zoomTargetParent.y) * 0.8;
        zoomTarget.eachBefore((node) => {
          node.y -= moveBy;
        });
      }

      // Calculate the group offset for each tree node. Find the extrema of the group coordinates.
      let maxX = -Infinity;
      let minY = Infinity;
      let maxY = -Infinity;
      let groupOffset = { "": [0, 0] };
      for (let node of tree.descendants()) {
        // Watch out, there's a trick here, these coordinates are swapped, as we want a left-to-right tree.
        groupOffset[node.data.id] = [node.y, node.x];
        if (node.x < hierarchicalMinX) {
          hierarchicalMinX = node.x;
        }
        if (node.x > maxX) {
          maxX = node.x;
        }
        if (node.y < minY) {
          minY = node.y;
        }
        if (node.y > maxY) {
          maxY = node.y;
        }
      }

      // Here again, coordinates are swapped, as X is *the tree's* X, not the viewport's X.
      setHeight(maxX - hierarchicalMinX + nodeSizeX + rectWidth);
      setWidth(maxY - minY + nodeSizeY);

      hierarchicalGlobalTransform = `translate(12, ${-hierarchicalMinX + rectWidth})`;

      // Calculate the position of each visible entity.
      for (let entity of visibleEntities) {
        // The transform of a non-tree-node rectangle is:
        //  1. Translation to its offset inside of its group. <- specific to this rectangle
        //  2. Translation to its offset with respect to its parent tree node. <- same for all rectangles
        //  2. Translation to the position of its group, based on the parent tree node position. <- same for all rectangles in a group
        //  3. Translation based on the minimum X coordinate value, so that the tree is in the viewport. <- same for all rectangles
        rectTransforms[entity.id] = `translate(${getRectPosition(
          positionInGroup[entity.id],
          rectWidth,
          rectHeight,
          lineWidth
        )}) translate(16,16) translate(${
          groupOffset[getKeyValue(groupByKey, entity)]
        }) ${hierarchicalGlobalTransform}`;

        if (groupOffset[entity.id]) {
          // The transform of a tree-node rectangle is:
          //  1. Translation to the position of the group its a parent of, based on the the tree node position. <- specific to this tree node
          //  2. Translation to fine-tune the position of the rectangle with respect to the position of the tree node. <- same for all tree nodes
          //  3. Scaling to make it a bit smaller. <- same for all tree nodes
          //  4. Translation based on the minimum X coordinate value, so that the tree is in the viewport. <- same for all tree nodes
          rectTransforms[entity.id] = `translate(${groupOffset[entity.id]}) translate(-4.5, ${
            -hierarchicalMinX / 4 + 1
          })  scale(0.75) translate(12, ${-hierarchicalMinX + rectWidth * 0.75})`;
        }
      }

      // Save the tree structure so the rendering part has access to it.
      hierarchicalLinks = tree.links();
      hierarchicalLinkNodes = tree.descendants();
    }

    // All the rendering has to happen regardless of layout, otherwise objects aren't deleted properly.
    // (i.e. tree links would stay when changing grouping, so you have to try to render tree links,
    // which sees there are none in the data, and deletes any existing ones)

    // *** universal

    // Adjust the viewport size.
    d3.select(node)
      .transition()
      .duration(100)
      .attr("height", height > 500 ? height : 500)
      .attr("width", width > 1000 ? width : 1000);

    // Render the rectangles.
    d3.select(node)
      .selectAll("g")
      .data(["rectangles"])
      .enter()
      .append("g")
      .attr("id", "rectangles");

    d3.select("#rectangles")
      .selectAll("g")
      .data(tooManyRectangles ? [] : visibleEntities, (d) => {
        if (!d) {
          return undefined;
        }

        return d.id;
      })
      .join(
        (enter) => {
          let g = enter
            .append("g")
            .attr("id", (d) => getIdText(d.id))
            .attr("height", rectHeight)
            .attr("width", rectWidth)
            .attr("opacity", 0)
            .on("click", function (event, entity) {
              // Show the info bar.
              setEntityDetails(entity);
              setMenuVisible(true);
              // Any other possibly selected entity should be unselected.
              d3.selectAll(`.d3-entity-selected`).classed("d3-entity-selected", false);
              // Mark the clicked entity as selected.
              d3.selectAll(`#${getIdText(entity.id)} .d3-entity`).classed(
                "d3-entity-selected",
                true
              );
            })
            .on("mouseover", function (event, entity) {
              let tooltip = d3.select("body").selectAll(".d3-tooltip");

              // Show the tooltip when an entity is hovered
              tooltip.transition().duration(200).style("opacity", 0.9);
              tooltip
                .html(`${entity.type}  -  ${entity.name}`)
                .style("left", event.pageX + "px")
                .style("top", event.pageY + "px");
            })
            .on("mouseout", function () {
              let tooltip = d3.select("body").selectAll(".d3-tooltip");

              tooltip.transition().duration(100).style("opacity", 0);
            })
            .attr("transform", (d) => rectTransforms[d.id]);
          g.append("rect")
            .attr("stroke-width", 2)
            .attr("opacity", 1)
            .attr("x", 0)
            .attr("y", 0)
            .attr("height", rectHeight)
            .attr("width", rectWidth)
            .attr("class", (d) => {
              const vendor = Object.keys(d.vendor)[0];
              const className = cx("d3-entity", {
                "d3-entity--pulumi": vendor === "pulumi",
                "d3-entity--cloudformation": vendor === "cloudFormation",
                "d3-entity--kubernetes": vendor === "kubernetes",
                "d3-entity--drifted": d.drifted,
              });

              return className;
            })
            .attr("rx", 3)
            .attr("ry", 3);

          g.filter(function (d) {
            return d.drifted;
          })
            .append("path")
            .attr("class", "d3-entity-drift-icon")
            .attr("opacity", 1)
            .attr("transform", "scale(0.65) translate(3,5)")
            .attr("stroke-width", 0.5)
            .attr("height", rectHeight)
            .attr("width", rectWidth)
            .attr("stroke-miterlimit", 2.6131)
            .attr("d", (d) => {
              return d.drifted
                ? "M19 3h-1a7.973 7.973 0 0 0-6 2.727A7.973 7.973 0 0 0 6 3H5V0L0 5.113 5 10V7h1a4 4 0 0 1 4 4v11h4V11a4 4 0 0 1 4-4h1v3l5-4.887L19 0z"
                : "";
            });

          return g;
        },
        (update) => update,
        (exit) => {
          exit.transition().duration(500).attr("opacity", 0).remove();
        }
      )
      .transition(t)
      // Only transform and opacity is animated, other properties stay constant from creation of a rectangle.
      .attr("transform", (d) => rectTransforms[d.id])
      .attr("opacity", 1);

    // *** flat

    // Render the group names.
    d3.select(node)
      .selectAll("text.d3-label")
      .data(flatTexts, (d) => d.key)
      .join(
        (enter) => {
          return (
            enter
              .append("text")
              .attr("class", "d3-label")
              // Only show 45 character long group names.
              .html((d) => {
                const count = typeof d.count === "number" ? ` (${d.count})` : "";
                return d.text.length <= 40
                  ? `${d.text}${count}`
                  : `${d.text.substring(0, 39) + "…"}${count}`;
              })
              // Move the text label to the proper line.
              .attr("transform", (d) => `translate(0 ${d.offset})`)
              .attr("x", 0)
              .attr("y", rectHeight * 0.8)
              .attr("opacity", 0)
              .on("dblclick", (event, entity) => {
                event.preventDefault();
                handleFilterAdd(groupByKey, entity.text);
              })
              .on("mouseover", function (event) {
                let tooltip = d3.select("body").selectAll(".d3-tooltip");

                // Show the tooltip when an entity is hovered
                tooltip.transition().duration(200).style("opacity", 0.9);
                tooltip
                  .html(`Double click to set as filter`)
                  .style("left", event.pageX + "px")
                  .style("top", event.pageY + "px");
              })
              .on("mouseout", function () {
                let tooltip = d3.select("body").selectAll(".d3-tooltip");

                tooltip.transition().duration(100).style("opacity", 0);
              })
          );
        },
        (update) => update,
        (exit) => {
          exit.attr("y", 500).attr("height", 0).remove();
        }
      )
      .transition(t)
      .attr("transform", (d) => `translate(0 ${d.offset})`)
      .attr("opacity", 1);

    // *** hierarchical

    // This is a link path creator. It basically creates the curves.
    let treeLink = d3
      .linkHorizontal()
      .x((d) => d.y)
      .y((d) => d.x);

    // Create the tree links (draw tree lines).
    d3.select(node)
      .selectAll("path:not(.d3-entity-drift-icon)")
      // Those ID shenanigans make sure that links are recreated on change. This makes for a nice animation in practice.
      .data(
        hierarchicalLinks,
        (d) =>
          d.source.id +
          "$" +
          d.target.id +
          `$${d.source.x}$${d.source.y}$${d.target.x}$${d.target.y}`
      )
      .join(
        (enter) => {
          return enter
            .append("path")
            .attr("fill", "none")
            .attr("stroke", "var(--color-default-primary)")
            .attr("stroke-opacity", 0)
            .attr("stroke-width", 1.5)
            .attr("d", treeLink)
            .attr("transform", `${hierarchicalGlobalTransform}`)
            .attr("class", "d3-hierarchical-link");
        },
        (update) => {
          // we want to update tree lines when entities change i.e. after filter apply
          return update
            .attr(
              "d",
              d3
                .linkHorizontal()
                .x((d) => d.y)
                .y((d) => d.x)
            )
            .attr("transform", `${hierarchicalGlobalTransform}`);
        },
        (exit) => {
          exit.remove();
        }
      )
      .transition(t)
      .attr("stroke-opacity", 0.1);

    const nodeIsEntity = (d) => hierarchicalNodeEntities.has(d.data.id) && !tooManyRectangles;

    // Create the tree nodes.
    d3.select(node)
      .selectAll("g.node")
      .data(hierarchicalLinkNodes, (d) => d.id)
      .join(
        (enter) => {
          let g = enter
            .append("g")
            .attr("class", (d) => `node ${d.data.parent === "" ? "node--root" : ""}`)
            .attr("transform", (d) => `translate(${d.y},${d.x}) ${hierarchicalGlobalTransform}`);

          // Each tree node has a label.
          g.append("text")
            // The label shifts a bit to the right to make more space if there's an entity rectangle on this tree node.
            .attr("transform", (d) =>
              nodeIsEntity(d)
                ? `translate(12, 3.5) scale(0.7) translate(4, 0)`
                : `translate(12, 3.5) scale(0.7)`
            )
            .attr("x", 0)
            .attr("y", 0)
            .attr("class", "d3-hierarchical-label")
            .attr("opacity", 0)
            .transition(t)
            .attr("opacity", 1);

          // Each tree node has a circle representing it, which may be bigger or smaller
          // based on whether there's an entity rectangle on this tree node.
          g.append("circle")
            .attr("opacity", 0)
            .attr("r", (d) => (nodeIsEntity(d) ? 2 : 4))
            .on("click", function (event, entity) {
              if (entity.data.id === allEntitiesRoot) {
                // Trying to zoom into the original root of the tree resets the zoom level.
                setZoomTarget("");
                return;
              }
              setZoomTarget(entity.id);
            })
            .transition(t)
            .attr("opacity", 0.7);

          return g;
        },
        (update) => {
          // Update nodes position after entities change, i.e filter apply
          update
            .transition(t)
            .attr("transform", (d) => `translate(${d.y},${d.x}) ${hierarchicalGlobalTransform}`);

          update
            .selectAll("text")
            .data((d) => [d]) // Re-bind the updated data to each text element
            .html((d) => {
              const count = typeof d.data.count === "number" ? ` (${d.data.count})` : "";

              if (zoomTarget !== "" && d.id === zoomParentId) {
                return "../";
              }

              if (d.data.name === "root_module") {
                return `${stackName || ""}${count}`;
              }
              return `${d.data.name}${count}`;
            })
            .transition(t)
            .attr("transform", (d) =>
              nodeIsEntity(d)
                ? `translate(12, 3.5) scale(0.7) translate(4, 0)`
                : `translate(12, 3.5) scale(0.7)`
            )
            .attr("opacity", 1);

          update
            .selectAll("circle")
            .data((d) => [d]) // Re-bind the updated data to each circle element
            .attr(
              "class",
              (d) =>
                `d3-hierarchical-node-point ${
                  (d.data.parent === "" && zoomTarget === "") || zoomTarget === d.id
                    ? "d3-hierarchical-node-point--disabled"
                    : ""
                }`
            )
            .on("mouseover", (event, entity) => {
              if ((entity.data.parent === "" && zoomTarget === "") || zoomTarget === entity.id)
                return null;
              let tooltip = d3.select("body").selectAll(".d3-tooltip");

              // Show a zoom tooltip when a circle is hovered
              tooltip.transition().duration(200).style("opacity", 0.9);
              tooltip
                .html(`zoom ${zoomTarget !== "" && entity.id === zoomParentId ? "out" : "in"}`)
                .style("left", event.pageX + "px")
                .style("top", event.pageY + "px");
            })
            .on("mouseout", function () {
              let tooltip = d3.select("body").selectAll(".d3-tooltip");

              tooltip.transition().duration(100).style("opacity", 0);
            })
            .transition(t)
            .attr("r", (d) => (nodeIsEntity(d) ? 2 : 4))
            .attr("opacity", 0.7);

          return update;
        },
        (exit) => {
          exit.remove();
        }
      );
  }, [
    data,
    handleFilterAdd,
    height,
    parentFilters,
    parentGroupByKey,
    setEntityDetails,
    setMenuVisible,
    zoomTarget,
    setZoomTarget,
    stackName,
    width,
  ]);

  useEffect(() => {
    createChart();
  }, [createChart]);

  useEffect(() => {
    if (parentGroupByKey !== "parent") {
      const wrapper = d3Wrapper.current;
      wrapper.scrollTop = 0;
    } else {
      const container = d3Container.current;
      const wrapper = d3Wrapper.current;
      const rootNode = container.querySelector(".node--root");
      if (rootNode) {
        const { top } = rootNode.getBoundingClientRect();

        wrapper.scrollTop = top - 400;
      }
    }
  }, [height, parentGroupByKey]);

  useEscapeKeypress(fullScreen, toggleFullScreen);

  const d3ContainerClass = cx("d3-container", {
    "d3-container--account": isAccountWide,
    "d3-container--full": fullScreen,
  });

  return (
    <div className="d3-chart-container">
      {showTooManyRectanglesWarning && (
        <div className="resources-too-many-rectangles">
          Too many rectangles to render simultaneously. Please zoom in or filter to get under 1000.
        </div>
      )}

      <div className="resources-legend">
        <div className="resources-legend__module"></div>
        Module/Stack
        <div className="resources-legend__resource"></div>
        Resource
        <div className="resources-legend__drift">
          <Drift className="resources-legend__drift-icon" />
        </div>
        Drift
      </div>
      <FullScreenToggleButton fullScreen={fullScreen} toggleFullScreen={toggleFullScreen} />
      <div ref={d3Wrapper} className={d3ContainerClass}>
        <svg ref={d3Container} className="d3-svg"></svg>
      </div>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  fullScreen: PropTypes.bool,
  groupByKey: PropTypes.string.isRequired,
  isAccountWide: PropTypes.bool,
  setEntityDetails: PropTypes.func.isRequired,
  setMenuVisible: PropTypes.func.isRequired,
  toggleFullScreen: PropTypes.func,
  stackName: PropTypes.string,
};

export default Chart;
