import { ReactNode } from "react";

import { AutoAttach, Folder } from "components/icons";
import { AUTO_ATTACHMENT_KEY, LABEL_FOLDER_KEY } from "constants/labels";
import { IconComponent } from "types/Icon";

export const getTagIconAndRenderValue = (tag: ReactNode, overrideIcon?: IconComponent) => {
  const isTextTag = typeof tag === "string";
  const isFolder = isTextTag && tag.startsWith(LABEL_FOLDER_KEY);
  const isAutoAttachment = isTextTag && tag.startsWith(AUTO_ATTACHMENT_KEY);

  const tagValue = isFolder ? tag.replace(LABEL_FOLDER_KEY, "") : tag;
  const tagIcon = overrideIcon || (isFolder && Folder) || (isAutoAttachment && AutoAttach);

  return { isTextTag, tagIcon, tagValue };
};
