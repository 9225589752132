import { ReactNode } from "react";
import { ToastAria } from "@react-aria/toast";

import Typography from "../../Typography";
import styles from "./styles.module.css";

type ToastHeaderProps = {
  children: ReactNode;
  ariaProps?: ToastAria["descriptionProps"];
};

const ToastDescription = ({ children, ariaProps }: ToastHeaderProps) => {
  return (
    <Typography {...ariaProps} tag="p" className={styles.description}>
      {children}
    </Typography>
  );
};

export default ToastDescription;
