import { gql } from "@apollo/client";

export const GET_MANAGED_USER_GROUPS = gql`
  query GetManagedUserGroups {
    managedUserGroups {
      id
      groupName
      identityProvider
      lastLoginTime
      memberCount
      spaceCount
      status
    }
  }
`;

export const GET_MANAGED_USER_GROUP = gql`
  query GetManagedUserGroup($id: ID!) {
    managedUserGroup(id: $id) {
      id
      groupName
      identityProvider
      lastLoginTime
      memberCount
      members
      spaceCount
      status
      accessRules {
        space
        spaceAccessLevel
      }
    }
  }
`;

export const CREATE_MANAGED_USER_GROUP = gql`
  mutation CreateManagedUserGroup($input: ManagedUserGroupCreateInput!) {
    managedUserGroupCreate(input: $input) {
      id
      groupName
    }
  }
`;

export const UPDATE_MANAGED_USER_GROUP = gql`
  mutation UpdateManagedUserGroup($input: ManagedUserGroupUpdateInput!) {
    managedUserGroupUpdate(input: $input) {
      id
      groupName
    }
  }
`;

export const DELETE_MANAGED_USER_GROUP = gql`
  mutation DeleteManagedUserGroup($id: ID!) {
    managedUserGroupDelete(id: $id) {
      id
    }
  }
`;
