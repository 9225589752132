import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom-v5-compat";

import { ManagedUserGroup } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useAnalytics from "hooks/useAnalytics";
import useTitle from "hooks/useTitle";
import useErrorHandle from "hooks/useErrorHandle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import IdpGroupMappingSortHeader from "./SortHeader";
import IdpGroupMappingList from "./List";
import IdpGroupMappingDetailsDrawer from "./DetailsDrawer";
import { GET_MANAGED_USER_GROUPS } from "./gql";
import IdpGroupMappingEmpty from "./Empty";
import IdpGroupMappingPageLayout from "./PageLayout";
import { SettingsContext } from "../Context";
import { getManagementStrategy } from "../helpers";

type GetManagedUserGroupsGql = {
  managedUserGroups: ManagedUserGroup[];
};

const IdpGroupMapping = () => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const [group, setGroup] = useState<ManagedUserGroup | undefined>(undefined);

  const handleGroupDetailsClick = (details: ManagedUserGroup) => {
    trackSegmentAnalyticsEvent("Open group details");
    setGroup(details);
  };
  const handleCloseGroupDetailsDrawer = () => {
    setGroup(undefined);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery<GetManagedUserGroupsGql>(GET_MANAGED_USER_GROUPS, {
    onError,
  });

  useEffect(() => {
    if (data?.managedUserGroups && searchParams.size > 0) {
      const identityProviderParam = searchParams.get("identityProvider");
      const groupNameParam = searchParams.get("groupName");
      const currentGroup = data.managedUserGroups.find(
        ({ identityProvider, groupName }) =>
          identityProvider === identityProviderParam && groupName === groupNameParam
      );
      if (currentGroup) {
        setGroup(currentGroup);
      }

      setSearchParams(undefined, { replace: true });
    }
  }, [data, searchParams, setSearchParams]);

  useTitle(`Organization Settings · IdP group mapping · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,

    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.managedUserGroups) {
    return <PageLoading />;
  }

  if (!data?.managedUserGroups) {
    return <NotFoundPage />;
  }

  return (
    <IdpGroupMappingPageLayout>
      <IdpGroupMappingDetailsDrawer
        isDrawerVisible={!!group}
        handleCloseDrawer={handleCloseGroupDetailsDrawer}
        currentGroup={group}
      />
      {data.managedUserGroups.length === 0 && <IdpGroupMappingEmpty />}
      {data.managedUserGroups.length > 0 && (
        <>
          <IdpGroupMappingSortHeader />
          <IdpGroupMappingList
            groups={data.managedUserGroups}
            handleGroupClick={handleGroupDetailsClick}
          />
        </>
      )}
    </IdpGroupMappingPageLayout>
  );
};

export default IdpGroupMapping;
