import { gql } from "@apollo/client";

export const GET_PUBLIC_WORKER_POOL = gql`
  query GetPublicWorkerPoolDetails {
    publicWorkerPool {
      parallelism
      schedulableRunsCount
      usersCount
    }
  }
`;
