import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { AzureIntegration } from "types/generated";

import CardForm from "./CardForm";
import IntegrationField from "./Fields/IntegrationField";
import AzureSubscriptionIDField from "./Fields/AzureSubscriptionIDField";
import ReadToggleField from "./Fields/ReadToggleField";
import WriteToggleField from "./Fields/WriteToggleField";
import WriteOnlyWarning from "./WriteOnlyWarning";
import SubmitButton from "./SubmitButton";
import { ModuleCreationCloud } from "../../types";
import useAttachCloud from "../hooks/useAttachCloud";

type AzureIntegrationFormProps = {
  attachableIntegrations: AzureIntegration[] | undefined;
  moduleId: string;
  onSuccess: () => void;
  refetchQueries?: string[];
};

type FormValues = {
  azureSubscriptionId: string;
  integrationId: string;
  read: boolean;
  write: boolean;
};

const AzureIntegrationForm = ({
  attachableIntegrations,
  onSuccess,
  moduleId,
  refetchQueries,
}: AzureIntegrationFormProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      azureSubscriptionId: "",
      integrationId: "",
      read: false,
      write: false,
    },
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;

  const { attach } = useAttachCloud({
    moduleId,
    onSuccess,
    refetchQueries,
  });

  const integrationId = watch("integrationId");

  useEffect(() => {
    if (integrationId) {
      const subscriptionId = attachableIntegrations?.find(
        ({ id }) => id === integrationId
      )?.defaultSubscriptionId;

      if (subscriptionId) {
        setValue("azureSubscriptionId", subscriptionId);
      }
    }
  }, [integrationId, attachableIntegrations, setValue]);

  useObserveForWarning(
    isDirty,
    <>
      You have not attached the <b>selected Azure integration</b>. Do you want to continue without
      attaching it?
    </>
  );

  const onSubmit = (data: FormValues) =>
    attach({
      type: ModuleCreationCloud.Azure,
      integrationId: data.integrationId,
      read: data.read,
      write: data.write,
      azureSubscriptionId: data.azureSubscriptionId,
    });

  const options = useMemo(() => {
    return attachableIntegrations
      ? attachableIntegrations.map((value) => ({ value: value.id, label: value.name }))
      : [];
  }, [attachableIntegrations]);

  return (
    <FormProvider {...form}>
      <CardForm onSubmit={handleSubmit(onSubmit)}>
        <IntegrationField options={options} />
        <AzureSubscriptionIDField />
        <ReadToggleField />
        <WriteToggleField />
        <WriteOnlyWarning />
        <SubmitButton />
      </CardForm>
    </FormProvider>
  );
};

export default AzureIntegrationForm;
