import { RefObject, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import { defaultHideOrder, DROPDOWN_PLACEHOLDER_WIDTH } from "./constants";
import { getHiddenItemsQty, sortItemsByHideOrder } from "./helpers";
import { UseHiddenItemsListBaseItem, UseHiddenItemsListItemsWidth } from "./types";

type UseHiddenItemsListProps<Item extends UseHiddenItemsListBaseItem> = {
  items: Item[];
  getResizableItems: (wrapperElement: HTMLDivElement) => UseHiddenItemsListItemsWidth[];
  defaultItemsWidths?: UseHiddenItemsListItemsWidth[];
  defaultHideItemsQty?: number;
  dropdownWidth?: number;
  hideOrder?: string[];
};

export type UseHiddenItemsListReturnType<Item extends UseHiddenItemsListBaseItem> = {
  wrapperRef: RefObject<HTMLDivElement>;
  sharedState: {
    hideItemsQty: number;
    itemsWidths: UseHiddenItemsListItemsWidth[];
  };
  visibleItems: Item[];
  hiddenItems: Item[];
  hideItemsQty: number;
};

/**
 * Hook to manage hidden/visible items based on their container.
 * Use default values to make the calculation more stable
 *
 * @param items - list of items to manage
 * @param getResizableItems - function that returns the list of items with their corresponding widths, it's the most stable if it returns the full list of items every time
 * @param defaultItemsWidths
 * @param defaultHideItemsQty
 * @param dropdownWidth - the width of the dropdown (or other element) that will contain the hidden elements
 * @param hideOrder - use to change the order of hidden items, by default it's from the last to the first
 */
const useHiddenItemsList = <Item extends UseHiddenItemsListBaseItem>({
  items,
  getResizableItems,
  defaultItemsWidths,
  defaultHideItemsQty,
  dropdownWidth = DROPDOWN_PLACEHOLDER_WIDTH,
  hideOrder = defaultHideOrder,
}: UseHiddenItemsListProps<Item>): UseHiddenItemsListReturnType<Item> => {
  const hasDefaults = defaultHideItemsQty && (defaultItemsWidths?.length || 0) > 0;

  const [itemsWidths, setItemsWidths] = useState<UseHiddenItemsListItemsWidth[]>(
    hasDefaults && defaultItemsWidths ? defaultItemsWidths : []
  );
  const [hideItemsQty, setHideItemsQty] = useState(hasDefaults ? defaultHideItemsQty : 0);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const reversedHideOrder = useMemo(() => [...hideOrder].reverse(), [hideOrder]);

  const [filteredItems, orderedByHideOrderItems] = useMemo(() => {
    const filteredItems = items.filter((item) => item.visible);
    const orderedByHideOrderItems = [...filteredItems].sort(
      sortItemsByHideOrder(reversedHideOrder)
    );

    return [filteredItems, orderedByHideOrderItems];
  }, [items, reversedHideOrder]);

  const [visibleItems, hiddenItems] = useMemo(() => {
    const hiddenItems = orderedByHideOrderItems.slice(-hideItemsQty);
    const visibleItems = filteredItems.filter((item) => !hiddenItems.includes(item));

    return hideItemsQty > 0 ? [visibleItems, hiddenItems] : [filteredItems, []];
  }, [filteredItems, hideItemsQty, orderedByHideOrderItems]);

  const sharedState = useMemo(() => ({ hideItemsQty, itemsWidths }), [hideItemsQty, itemsWidths]);

  const setHiddenItemsQty = useCallback(
    (dropdownWidth: number, itemsWidths: UseHiddenItemsListItemsWidth[]) => {
      if (!wrapperRef.current) return undefined;

      const hiddenItemsQty = getHiddenItemsQty(wrapperRef.current, itemsWidths, dropdownWidth);

      setHideItemsQty(hiddenItemsQty);
    },
    []
  );

  useLayoutEffect(() => {
    if (!wrapperRef.current) return undefined;

    const observer = new ResizeObserver(() => setHiddenItemsQty(dropdownWidth, itemsWidths));

    observer.observe(wrapperRef.current);

    return () => {
      observer.disconnect();
    };
  }, [setHiddenItemsQty, dropdownWidth, itemsWidths]);

  useLayoutEffect(() => {
    if (wrapperRef.current && items.length > 0 && !hasDefaults) {
      const gap = parseInt(window.getComputedStyle(wrapperRef.current).gap) || 0;

      const newItemsWidths = getResizableItems(wrapperRef.current)
        .map(({ key, width }, i, { length }) => {
          const ignoreGap = i === length - 1;

          return {
            key: key,
            width: width + (ignoreGap ? 0 : gap),
          };
        })
        .sort(sortItemsByHideOrder(reversedHideOrder));

      setItemsWidths(newItemsWidths);
      setHiddenItemsQty(dropdownWidth, newItemsWidths);
    }
  }, [items, getResizableItems, reversedHideOrder, hasDefaults, setHiddenItemsQty, dropdownWidth]);

  return {
    wrapperRef,
    sharedState,
    visibleItems,
    hiddenItems,
    hideItemsQty,
  };
};

export default useHiddenItemsList;
