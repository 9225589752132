import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { CreateModuleGql, GetData } from "./types";
import { ModuleFormFields } from "../types";
import { ModuleFormContext } from "../context";
import { getModuleConfig } from "../getModuleConfig";
import { CREATE_MODULE } from "./gql";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

export const useContinueAndCreateModule = (
  setLoading: (loading: boolean) => void,
  getData?: GetData
) => {
  const {
    createdModuleId,
    setCreatedModuleId,
    setIsIntermediateStepActive,
    setStepperNavDisabled,
  } = useTypedContext(ModuleFormContext);
  const { onError } = useTypedContext(FlashContext);

  const [createModule] = useMutation<CreateModuleGql>(CREATE_MODULE);

  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const continueAndCreateModuleHandler = () => {
    Promise.resolve(getData?.()).then((formData: ModuleFormFields | void) => {
      if (!createdModuleId && formData) {
        setLoading(true);
        setStepperNavDisabled(true);

        createModule({ variables: getModuleConfig(formData) })
          .then(({ data }) => {
            if (data?.moduleCreate?.id) {
              setCreatedModuleId(data.moduleCreate.id);
              trackSegmentEvent("Module Created");
              setIsIntermediateStepActive(true);
            } else {
              onError(new Error("Unable to create module"));
            }
          })
          .catch((e) => {
            onError(e);
          })
          .finally(() => {
            setLoading(false);
            setStepperNavDisabled(false);
          });
      }
    });
  };

  return continueAndCreateModuleHandler;
};
