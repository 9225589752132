import { createContext } from "react";

import type { BulkActionsAPIContextValue, BulkActionsDataContextValue } from "./types";

export const BulkActionsDataContext = createContext<BulkActionsDataContextValue | undefined>(
  undefined
);

export const BulkActionsApiContext = createContext<BulkActionsAPIContextValue | undefined>(
  undefined
);
