import { ReactNode } from "react";

import useContextValues from "./Context/useContextValues";
import { BulkActionsApiContext, BulkActionsDataContext } from "./Context";
import { BulkActionsCloseMethod, BulkActionsVariant } from "./types";

type BulkActionsProps = {
  selectedItemsCount: number;
  children: ReactNode;
  onClose: (variant: BulkActionsVariant, method: BulkActionsCloseMethod) => void;
  isExecutingBulkActions: boolean;
  skipResults?: boolean;
};

const BulkActions = ({
  selectedItemsCount,
  children,
  onClose,
  isExecutingBulkActions,
  skipResults,
}: BulkActionsProps) => {
  const { apiContext, dataContext } = useContextValues({
    selectedItemsCount,
    onClose,
    isExecutingBulkActions,
    skipResults,
  });

  return (
    <BulkActionsApiContext.Provider value={apiContext}>
      <BulkActionsDataContext.Provider value={dataContext}>
        {children}
      </BulkActionsDataContext.Provider>
    </BulkActionsApiContext.Provider>
  );
};

export default BulkActions;
