import cx from "classnames";

import Counter, { CounterProps } from "../Counter";
import styles from "./styles.module.css";
import Tab, { TabProps } from ".";
import Tooltip from "../Tooltip";

type TabWithCounterProps<T extends string> = TabProps<T> & {
  count: number;
  tooltip?: string;
  hasException?: boolean;
  counterExceptionVariant?: CounterProps["variant"];
};

const TabWithCounter = <T extends string>({
  count,
  tooltip,
  hasException,
  counterExceptionVariant,
  ...restTabProps
}: TabWithCounterProps<T>) => {
  const counterVariant = hasException ? counterExceptionVariant : "default";

  const counter = count !== undefined && (
    <Tooltip
      active={!!tooltip}
      on={(props) => (
        <Counter
          {...props}
          count={count}
          size="small"
          variant={counterVariant}
          className={cx(styles.counter, {
            [styles.hasException]: hasException,
          })}
        />
      )}
    >
      {tooltip}
    </Tooltip>
  );

  return <Tab {...restTabProps} suffixComponent={counter} />;
};

export default TabWithCounter;
