import { Controller, useFormContext } from "react-hook-form";
import cx from "classnames";

import Box from "ds/components/Box";
import CodeEditor from "components/CodeEditor";
import Callout from "ds/components/Callout";

import styles from "./styles.module.css";

type ContextConfigFormContentFieldProps = {
  name: string;
  className?: string;
};

const options = {
  quickSuggestions: false,
};

const ContextConfigFormContentField = ({ name, className }: ContextConfigFormContentFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: "File content can't be empty" }}
      render={({ field, fieldState }) => (
        <Box direction="column">
          {fieldState.error?.message && (
            <Callout variant="danger">{fieldState.error?.message}</Callout>
          )}
          <CodeEditor
            className={cx(styles.variablePreviewEditor, className)}
            body={field.value}
            onChange={field.onChange}
            language="plaintext"
            options={options}
          />
        </Box>
      )}
    />
  );
};

export default ContextConfigFormContentField;
