import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type RowProps = {
  className?: string;
  align?: boolean;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Row = (props: RowProps) => {
  const { children, className, align, ...restProps } = props;
  return (
    <div
      className={cx(styles.row, className, {
        [styles.rowAlign]: align,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Row;
