import useTypedContext from "hooks/useTypedContext";

import { ModuleSettingsContext } from "../Context";
import ModuleSettingsPageLayout from "../components/PageLayout";
import ModuleSettingsSourceCodeEditForm from "./EditForm";
import ModuleSettingsSourceCodeViewForm from "./ViewForm";
import ModuleSettingsFormWrapper from "../components/FormWrapper";

const ModuleSettingsSourceCode = () => {
  const { canManageModule } = useTypedContext(ModuleSettingsContext);

  return (
    <ModuleSettingsPageLayout
      title="Source code"
      description="Tell Spacelift where to look for the Terraform code for the module."
    >
      <ModuleSettingsFormWrapper>
        {canManageModule ? (
          <ModuleSettingsSourceCodeEditForm />
        ) : (
          <ModuleSettingsSourceCodeViewForm />
        )}
      </ModuleSettingsFormWrapper>
    </ModuleSettingsPageLayout>
  );
};

export default ModuleSettingsSourceCode;
