import { BillingTierFeature, PolicyType } from "types/generated";

export const POLICY_TYPES_FEATURES: { [key in PolicyType]?: BillingTierFeature } = {
  [PolicyType.Notification]: BillingTierFeature.NotificationPolicies,
  [PolicyType.Access]: BillingTierFeature.AccessPolicies,
  [PolicyType.GitPush]: BillingTierFeature.WorkflowPolicies,
  [PolicyType.Login]: BillingTierFeature.AccessPolicies,
  [PolicyType.Trigger]: BillingTierFeature.WorkflowPolicies,
};

export const AVAILABLE_POLICY_TYPES = [
  PolicyType.Access,
  PolicyType.Approval,
  PolicyType.GitPush,
  PolicyType.Plan,
  PolicyType.Trigger,
  PolicyType.Notification,
];

export const POLICY_TYPES_TITLES: { [key in PolicyType]?: string } = {
  [PolicyType.Access]: "Access",
  [PolicyType.Approval]: "Approval",
  [PolicyType.GitPush]: "Push",
  [PolicyType.Initialization]: "Initialization",
  [PolicyType.Login]: "Login",
  [PolicyType.Plan]: "Plan",
  [PolicyType.Task]: "Task",
  [PolicyType.Trigger]: "Trigger",
  [PolicyType.Notification]: "Notification",
};

export const searchPolicySuggestionsDictionary: Partial<Record<PolicySuggestions, string>> = {
  label: "labels",
};

export enum PolicySuggestions {
  Label = "label",
  Folder = "folder",
  Type = "type",
  Space = "space",
}

export const searchPolicyTemplateSuggestionsDictionary: Partial<
  Record<PolicyTemplateSuggestions, string>
> = searchPolicySuggestionsDictionary;

export enum PolicyTemplateSuggestions {
  Label = "label",
  Type = "type",
}
