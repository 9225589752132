import cx from "classnames";
import { useLayoutEffect, useRef, useState } from "react";

import Box from "ds/components/Box";
import usePrevious from "hooks/usePrevious";
import LinkNew from "ds/components/LinkNew";
import useElementWidth from "hooks/useElementWidth";

import styles from "./styles.module.css";
import ListEntitiesItemDescriptionContent from "./Content";

type ListEntitiesItemDescriptionMultipleLinesProps =
  | {
      description: string;
      onShowFullDescription: (description: string) => void;
      className?: string;
      lines?: number;
    }
  | {
      description: string;
      onShowFullDescription?: never;
      className?: string;
      lines?: number;
    };

const ListEntitiesItemDescriptionMultipleLines = ({
  description,
  onShowFullDescription,
  className,
  lines = 3,
}: ListEntitiesItemDescriptionMultipleLinesProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const containerSize = useElementWidth(textRef.current || null);
  const [isTextEllipsis, setIsTextEllipsis] = useState(false);
  const prevWidth = usePrevious(containerSize);

  useLayoutEffect(() => {
    if (prevWidth !== containerSize) {
      const isTextEllipsis =
        (textRef.current?.scrollHeight || 0) >
        (textRef.current?.getBoundingClientRect().height || 0);

      setIsTextEllipsis(isTextEllipsis);
    }
  }, [prevWidth, containerSize]);

  const handleShowFullDescription = () => {
    onShowFullDescription?.(description);
  };

  return (
    <Box
      grow="1"
      direction="column"
      gap="small"
      align="start"
      className={cx(styles.wrapper, styles.multipleLinesWrapper)}
    >
      <ListEntitiesItemDescriptionContent
        ref={textRef}
        description={description}
        style={{ WebkitLineClamp: lines }}
        className={cx(className, styles.multipleLines)}
        color="primary"
      />

      {isTextEllipsis && (
        <LinkNew
          onPress={handleShowFullDescription}
          size="small"
          className={styles.showFullDescriptionLink}
        >
          Show full description
        </LinkNew>
      )}
    </Box>
  );
};

export default ListEntitiesItemDescriptionMultipleLines;
