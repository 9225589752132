import TierInfo from "components/TierInfo";
import { BillingTierFeature } from "types/generated";

type PrivateWorkersTierInfoProps = {
  storageKey?: string;
};

const PrivateWorkersTierInfo = ({ storageKey }: PrivateWorkersTierInfoProps) => {
  return (
    <TierInfo
      type="callout"
      variant="promo"
      title="Private workers are not supported by your plan"
      feature={BillingTierFeature.PrivateWorkers}
      storageKey={storageKey}
    >
      You may manage them, but jobs will not be scheduled on private workers until you upgrade to a
      plan that supports them.
    </TierInfo>
  );
};

export default PrivateWorkersTierInfo;
