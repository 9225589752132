import { RefObject } from "react";

import { DropdownPosition } from "./types";

export function getFixedStyle(
  position: DropdownPosition,
  triggerRef: RefObject<HTMLDivElement>,
  cords?: DOMRect
) {
  const offsetTop = cords ? cords.top : 0;
  const offsetLeft = cords ? cords.left : 0;
  const triggerHeight = triggerRef.current ? triggerRef.current.offsetHeight : 0;
  const triggerWidth = triggerRef.current ? triggerRef.current.offsetWidth : 0;

  return {
    height: position === "right" || position === "left" ? `${triggerHeight}px` : undefined,
    width: `${triggerWidth}px`,
    top: `${offsetTop + triggerHeight}px`,
    left: `${offsetLeft}px`,
  };
}
