import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationStackLockArgs, Stack } from "types/generated";

export type FetchStackLockData = { stackLock: Pick<Stack, "id"> };
export const STACK_LOCK: TypedDocumentNode<FetchStackLockData, MutationStackLockArgs> = gql`
  mutation StackLock($id: ID!, $note: String) {
    stackLock(id: $id, note: $note) {
      id
    }
  }
`;
