import { Stack } from "types/generated";
import { useBulkEnableStack } from "shared/Stack/useEnableStack";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { canEnableStack } from "shared/Stack/useEnableStack/accessValidation";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";

const useEnableStackBulkAction = () => {
  const { viewer } = useTypedContext(AccountContext);
  const [enableStack] = useBulkEnableStack();

  return {
    key: StackBulkActions.Enable,
    title: BULK_ACTION_NAMES[StackBulkActions.Enable],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Enable],
    condition: (stack: Stack) => canEnableStack(stack, viewer),
    mutation: (stack: Stack) => enableStack({ id: stack.id }),
  };
};

export default useEnableStackBulkAction;
