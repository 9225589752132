import { Elements } from "@stripe/react-stripe-js";

import { BillingTier } from "types/generated";
import Header from "components/header/Header";
import HeaderTitle from "components/header/Title";
import Button from "ds/components/Button";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import Typography from "ds/components/Typography";
import { useLoadStripe } from "hooks/useLoadStripe";
import { TIER_PLANS_LABELS } from "constants/tiers";

import Form from "./Wrapper";
import Info from "../Form/Info";
import styles from "../Form/styles.module.css";

const TIER = BillingTier.V3Cloud;
const TIER_PRICE = "250";

const BillingUpgradeForm = () => {
  const { stripe, error: stripeError } = useLoadStripe();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Elements stripe={stripe}>
      {stripeError ? (
        <Callout variant="danger" title="Payment method error">
          <Typography tag="p" variant="p-body3" margin="0 0 medium 0">
            Our payment platform failed to load. Please refresh the page or contact our support
            team.
          </Typography>
          <FeedbackActions>
            <Button size="small" variant="secondary" onClick={refreshPage}>
              Refresh
            </Button>
          </FeedbackActions>
        </Callout>
      ) : null}
      <div className={styles.billingFormHeader}>
        <Header>
          <HeaderTitle>Upgrade your plan</HeaderTitle>
        </Header>
      </div>
      <div className={styles.billingFormWrapper}>
        <div className={styles.billingFormInfo}>
          <Info
            key={`plan-${TIER}`}
            plan={{
              amount: TIER_PRICE,
              label: TIER_PLANS_LABELS[TIER],
              value: TIER,
            }}
            active
          />
        </div>
        <div className={styles.billingFormContent}>
          <Form tier={TIER} />
        </div>
      </div>
    </Elements>
  );
};

export default BillingUpgradeForm;
