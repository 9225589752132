import Box, { BoxProps } from "ds/components/Box";

type ModuleVersionScrollableWrapperProps = Omit<
  BoxProps,
  "direction" | "fullWidth" | "scrollable" | "grow"
>;

const ModuleVersionScrollableWrapper = ({
  children,
  padding = "x-large",
  ...rest
}: ModuleVersionScrollableWrapperProps) => {
  return (
    <Box {...rest} grow="1" direction="column" padding={padding} fullWidth scrollable>
      {children}
    </Box>
  );
};

export default ModuleVersionScrollableWrapper;
