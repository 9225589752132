import { ReactNode } from "react";
import cx from "classnames";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type ListContentWrapperProps = {
  children: ReactNode;
  className?: string;
};

const ListContentWrapper = ({ children, className }: ListContentWrapperProps) => (
  <Box className={cx(styles.content, className)} fullWidth direction="column">
    {children}
  </Box>
);

export default ListContentWrapper;
