import getUnixTime from "date-fns/getUnixTime";

import { LogMessage, Module, Stack } from "types/generated";

// For V8 on 32 bit system https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length
const MAX_STRING_SIZE = 2 ** 28 - 16;

type ProcessLogsOptions = {
  logsMessages?: LogMessage[];
  exists: boolean;
  expired: boolean;
  loading: boolean;
  error: boolean;
};

export const processLogs = ({
  logsMessages,
  exists,
  expired,
  loading,
  error,
}: ProcessLogsOptions) => {
  if (expired) {
    return "These logs have expired. Our standard retention period is 60 days.";
  }

  if (error) {
    return "Could not load logs...";
  }

  const hasLogs = logsMessages && logsMessages.length > 0;

  if (loading) {
    return "Loading logs...";
  } else if (!exists || !hasLogs) {
    // The log stream does not yet exist.
    return "Waiting for logs...";
  }

  let messages = "";

  for (let i = logsMessages.length - 1; i >= 0; i--) {
    const { message } = logsMessages[i];
    if (messages.length + message.length < MAX_STRING_SIZE) {
      messages = `${message}${messages}`;
    } else {
      const diff = MAX_STRING_SIZE - messages.length;
      messages = `${message.slice(diff * -1)}${messages}`;
      break;
    }
  }

  let prefix = "";
  let text = "";
  const rawBlob = new TextEncoder().encode(messages);

  if (rawBlob.byteLength > 102400) {
    prefix =
      "[33m[The log is too large to display in your browser, please download the file for further details. To do so, click on the downward arrow icon, located on the top right of the logs screen.][0m\n\n";
    text = new TextDecoder().decode(rawBlob.slice(-102400));
  } else {
    text = messages;
  }

  const textLines = text.match(/[^\r\n]+/g);
  if (textLines && textLines?.length > 2000) {
    text = textLines.slice(-2000).join("\n");
  }

  return prefix + text;
};

export const getTimestamp = () => getUnixTime(new Date());

export const getEntityLogs = (data: { stack: Stack } | { module: Module } | undefined) => {
  if (!data) return;

  const entity = "module" in data ? data.module : data.stack;

  return entity?.run?.logs;
};
