import { useEffect } from "react";

import useTypedContext from "hooks/useTypedContext";

import { BreadcrumbsContextApi } from "./Context";
import { BreadcrumbsContextItem } from "./types";

const useBreadcrumbs = (items: BreadcrumbsContextItem[], watchRule: unknown[] = []) => {
  const { setItems, resetItems } = useTypedContext(BreadcrumbsContextApi);

  useEffect(() => {
    setItems(items);

    return () => {
      resetItems();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watchRule);

  return null;
};

export default useBreadcrumbs;
