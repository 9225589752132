import { create, show, useModal } from "@ebay/nice-modal-react";
import { useMutation } from "@apollo/client";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { DeleteContextConfigGql } from "./types";
import { DELETE_CONTEXT_CONFIG } from "../../gql";

type DeleteVariableConfirmationProps = {
  id: string;
  contextId: string;
};

const DeleteVariableConfirmation = create(function DeleteVariableConfirmation({
  id,
  contextId,
}: DeleteVariableConfirmationProps) {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const modal = useModal();

  const [deleteContextConfig, { loading, data }] = useMutation<DeleteContextConfigGql>(
    DELETE_CONTEXT_CONFIG,
    {
      onError,
      awaitRefetchQueries: true,
      refetchQueries: ["GetContext"],
      variables: { contextId, configId: id },
    }
  );

  const handleDeleteConfig = () => {
    deleteContextConfig({})
      .then(({ data }) => {
        if (data?.contextConfigDelete?.id) {
          reportSuccess({
            message: `Config variable was successfully deleted`,
          });

          modal.hide();
        }
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      name={id}
      title="Delete context variable"
      onConfirm={handleDeleteConfig}
      isLoading={loading}
      isDismissable={!loading && !data?.contextConfigDelete?.id}
    />
  );
});

export const showDeleteVariableConfirmation = (props: DeleteVariableConfirmationProps) =>
  show(DeleteVariableConfirmation, props);
