import { memo, useState } from "react";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import DrawerFooter from "ds/components/Drawer/Footer";
import Select from "ds/components/Select";
import DocumentationButton from "components/DocumentationButton";
import { PolicyType } from "types/generated";
import Typography from "ds/components/Typography";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import { DocsUrl, getDocsUrl } from "utils/getDocsUrl";

type AvailableTypes =
  | PolicyType.Approval
  | PolicyType.Notification
  | PolicyType.Plan
  | PolicyType.GitPush
  | PolicyType.Trigger;

const options: Array<{ value: AvailableTypes; label: string }> = [
  { value: PolicyType.Approval, label: "Approval" },
  { value: PolicyType.Notification, label: "Notification" },
  { value: PolicyType.Plan, label: "Plan" },
  { value: PolicyType.GitPush, label: "GitPush" },
  { value: PolicyType.Trigger, label: "Trigger" },
];

const policiesDescriptions: Record<AvailableTypes, { link: DocsUrl; content: JSX.Element }> = {
  [PolicyType.Approval]: {
    link: getDocsUrl("/concepts/policy/approval-policy"),
    content: (
      <Typography tag="p" variant="p-body2">
        The approval policy allows organizations to create sophisticated run review and approval
        flows that reflect their preferred workflow, security goals, and business objectives.
        Without an explicit approval policy, anyone with write access to a stack can create a run
        (or a task). An approval policy can make this way more granular and contextual.
        <br />
        <br />
        Runs can be reviewed when they enter one of the three states - queued, unconfirmed, or
        pending review. When a queued run needs approval, it will not be scheduled before that
        approval is received, and if it is of a blocking type, it will block newer runs from
        scheduling, too. A queued run that's pending approval can be canceled at any point.
        <br />
        <br />
        Your approval policy can define the following boolean rules:
        <ul>
          <li>approve: the run is approved and no longer requires (or allows) review;</li>
          <li>reject: the run fails immediately;</li>
        </ul>
        While the 'approve' rule must be defined in order for the run to be able to progress, it's
        perfectly valid to not define the 'reject' rule. In that case, runs that look invalid can be
        cleaned up (canceled or discarded) manually. It's also perfectly acceptable for any given
        policy evaluation to return 'false' on both 'approve' and 'reject' rules. This only means
        that the result is yet 'undecided' and more reviews will be necessary to reach the
        conclusion. A perfect example would be a policy that requires 2 approvals for a given job -
        the first review is not yet supposed to set the 'approve' value to 'true'.
      </Typography>
    ),
  },
  [PolicyType.Notification]: {
    link: getDocsUrl("/concepts/policy/notification-policy"),
    content: (
      <Typography tag="p" variant="p-body2">
        Notification policies can be used to filter, route and adjust the body of notification
        messages sent by Spacelift. The policy works at the Space level meaning that it does not
        need to be attached to a specific stack, but rather is always evaluated if the Space it's in
        can be accessed by whatever action is being evaluated. It's also important to note that all
        notifications go through the policy evaluation. This means any of them can be redirected to
        the routes defined in the policy. A notification policy can define the following rules:
        <ul>
          <li>inbox - allows messages to be routed to the Spacelift notification inbox;</li>
          <li>slack - allows messages to be routed to a given Slack channel;</li>
          <li>webhook - allows messages to be routed to a given webhook;</li>
        </ul>
        If no rules match no action is taken.
      </Typography>
    ),
  },
  [PolicyType.Plan]: {
    link: getDocsUrl("/concepts/policy/terraform-plan-policy"),
    content: (
      <Typography tag="p" variant="p-body2">
        Plan policies are evaluated during a planning phase after vendor-specific change preview
        command (eg. terraform plan) executes successfully. The body of the change is exported to
        JSON and parts of it are combined with Spacelift metadata to form the data input to the
        policy.
        <br />
        <br />
        Plan policies are the only ones that have access to the actual changes to the managed
        resources, so this is probably the best place to enforce organizational rules and best
        practices as well as do automated code review.
        <br />
        <br />
        There are two types of rules here that Spacelift will care about: <strong>
          deny
        </strong> and <strong>warn</strong>. Each of them must come with an appropriate message that
        will be shown in the logs.
        <br />
        <br />
        Any deny rules will print in red and will automatically fail the run, while warn rules will
        print in yellow and will at most mark the run for human review if the change affects the
        tracked branch and the Stack is set to autodeploy.
      </Typography>
    ),
  },
  [PolicyType.GitPush]: {
    link: getDocsUrl("/concepts/policy/push-policy/"),
    content: (
      <Typography tag="p" variant="p-body2">
        Git push policies are triggered on a per-stack basis to determine the action that should be
        taken for each individual Stack or Module in response to a Git push or Pull Request
        notification. There are three possible outcomes:
        <ul>
          <li>
            track: set the new head commit on the stack / module and create a tracked Run, ie. one
            that can be applied;
          </li>
          <li>propose: create a proposed Run against a proposed version of infrastructure;</li>
          <li>ignore: do not schedule a new Run;</li>
        </ul>
        Using this policy it is possible to create a very sophisticated, custom-made setup. We can
        think of two main - and not mutually exclusive - use cases. The first one would be to ignore
        changes to certain paths - something you'd find useful both with classic monorepos and
        repositories containing multiple Terraform projects under different paths. The second one
        would be to only attempt to apply a subset of changes - for example, only commits tagged in
        a certain way.
      </Typography>
    ),
  },
  [PolicyType.Trigger]: {
    link: getDocsUrl("/concepts/policy/trigger-policy"),
    content: (
      <>
        <Typography tag="p" variant="p-body2">
          Frequently, your infrastructure consists of a number of projects (stacks in Spacelift
          parlance) that are connected in some way - either depend logically on one another, or must
          be deployed in a particular order for some other reason - for example, a rolling deploy in
          multiple regions.
          <br />
          Enter trigger policies. Trigger policies are evaluated at the end of each stack-blocking
          run (which includes tracked runs and tasks) as well as on module version releases and
          allow you to decide if some tracked Runs should be triggered. This is a very powerful
          feature, effectively turning Spacelift into a Turing machine.
          <br />
          <br />
          All runs triggered - directly or indirectly - by trigger policies as a result of the same
          initial run are grouped into a so-called workflow. In the trigger policy you can access
          all other runs in the same workflow as the currently finished run, regardless of their
          Stack. This lets you coordinate executions of multiple Stacks and build workflows which
          require multiple runs to finish in order to commence to the next stage (and trigger
          another Stack).
          <br />
          <br />
        </Typography>
        <Banner variant="warning">
          Note that in order to support various use cases this policy type is currently evaluated
          every time a blocking Run reaches a terminal state, which includes states like Canceled,
          Discarded, Stopped or Failed in addition to the more obvious Finished. This allows for
          very interesting and complex workflows (eg. automated retry logic) but please be aware of
          that when writing your own policies.
        </Banner>
      </>
    ),
  },
};

type PoliciesTemplatesTypesDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
};

const PoliciesTemplatesTypesDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
}: PoliciesTemplatesTypesDrawerProps) => {
  const [selectedType, setSelectedType] = useState(options[0].value);

  const handleCloseDrawer = () => setDrawerVisibility(false);

  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Policy types" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight hasStickyFooter>
        <FormField helperText="Select type to see its description" label="Select type">
          {({ ariaInputProps }) => (
            <Select
              value={selectedType}
              options={options}
              onChange={setSelectedType}
              ariaInputProps={ariaInputProps}
            />
          )}
        </FormField>
        <Box direction="column" padding="large 0 0 0">
          {policiesDescriptions[selectedType].content}
        </Box>
        <DrawerFooter sticky>
          <DocumentationButton to={policiesDescriptions[selectedType].link} />
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default memo(PoliciesTemplatesTypesDrawer);
