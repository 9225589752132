import Typography from "ds/components/Typography";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";

import { COLUMN_GAP, COLUMN_ORDER, SortableColumns } from "../constants";

const WorkerPoolsFiltersLayoutHeader = () => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderStaticColumn>&nbsp;</FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderSortableColumn
        option={{
          label: SortableColumns.NAME,
          value: SortableColumns.NAME,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={{
          label: SortableColumns.SPACE,
          value: SortableColumns.SPACE,
        }}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Workers
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Busy
        </Typography>
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderStaticColumn>
        <Typography tag="span" variant="p-t6">
          Queue
        </Typography>
      </FiltersSortHeaderStaticColumn>
    </FiltersSortHeaderWrapper>
  );
};

export default WorkerPoolsFiltersLayoutHeader;
