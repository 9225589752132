import { Fragment } from "react";

type JoinTextsProps = {
  texts?: string[];
  wrapper?: "b" | "span";
};

const JoinTexts = ({ texts, wrapper: Wrapper = "b" }: JoinTextsProps) => {
  if (!texts || texts.length === 0) {
    return null;
  }
  const boldTexts = texts.map((text) => <Wrapper key={text}>{text}</Wrapper>);

  if (texts.length === 1) {
    return boldTexts[0];
  }
  const lastWord = boldTexts.slice(-1);
  const preLastWords = boldTexts.slice(0, -1);
  return (
    <>
      {preLastWords.map((text, index) => (
        <Fragment key={text.key}>
          {text}
          {index !== preLastWords.length - 1 && ","}{" "}
        </Fragment>
      ))}
      and {lastWord}
    </>
  );
};

export default JoinTexts;
