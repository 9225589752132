import { ReactNode, useCallback, useMemo, useRef } from "react";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useMergeRefs } from "@floating-ui/react";

import Typography from "ds/components/Typography";

import Box from "../../Box";
import styles from "./styles.module.css";
import { MeterChartDataPoint } from "./types";
import ChartTooltip from "../components/Tooltip";
import { TOOLTIP_OFFSET, TOOLTIP_STYLES } from "./constants";

type MeterChartProps = {
  data: Array<MeterChartDataPoint>;
  isLoading?: boolean;
  emptyTooltipContent?: ReactNode;
};

type TooltipData = {
  hoveredIndex: number;
};

const MeterChart = ({ data, isLoading, emptyTooltipContent }: MeterChartProps) => {
  const total = useMemo(() => data.reduce((acc, { value }) => acc + value, 0), [data]);

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const localContainerRef = useRef();

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  const mergedContainerRef = useMergeRefs([containerRef, localContainerRef]);

  const createMouseMoveHandler = useCallback(
    (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
      if (!localContainerRef.current) return;

      const coordinates = localPoint(localContainerRef.current, event);

      const tooltipData = { hoveredIndex: index };

      showTooltip({
        tooltipData,
        tooltipTop: coordinates?.y,
        tooltipLeft: coordinates?.x,
      });
    },
    [showTooltip]
  );

  const renderTooltipContentByIndex = useCallback(
    (index: number) => {
      if (total === 0) {
        return emptyTooltipContent || "no data";
      }

      return data[index].renderTooltipContent?.(data[index].value, total);
    },
    [data, emptyTooltipContent, total]
  );

  return (
    <>
      <Box direction="column" fullWidth gap="x-large">
        {isLoading ? (
          <Skeleton count={1} height={24} borderRadius={6} width="100%" />
        ) : (
          <Box
            tabIndex={0}
            align="center"
            grow="1"
            className={cx(styles.meterChart)}
            ref={mergedContainerRef}
          >
            {total > 0 ? (
              data.map(({ color, value }, index) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  key={color}
                  className={styles.meterChartSegment}
                  style={{ backgroundColor: color, width: `${(value * 100) / total}%` }}
                  onMouseMove={createMouseMoveHandler(index)}
                  onMouseLeave={handleMouseLeave}
                />
              ))
            ) : (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={styles.meterChartSegment}
                style={{
                  backgroundColor: "var(--semantic-color-surface-secondary)",
                  width: "100%",
                }}
                onMouseMove={createMouseMoveHandler(0)}
                onMouseLeave={handleMouseLeave}
              />
            )}
          </Box>
        )}

        <Box gap="medium">
          {data.map(({ renderLegendLabel, color, value }, index) =>
            renderLegendLabel ? (
              <Box key={index} gap="medium" align="center">
                <div className={styles.legendSquare} style={{ backgroundColor: color }} />

                {isLoading ? (
                  <Skeleton count={1} height={17} width={100} inline />
                ) : (
                  <Typography variant="p-body3" tag="span">
                    {renderLegendLabel(value, total)}
                  </Typography>
                )}
              </Box>
            ) : null
          )}
        </Box>
      </Box>
      {tooltipOpen && tooltipData?.hoveredIndex !== undefined && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          offsetLeft={TOOLTIP_OFFSET.left}
          offsetTop={TOOLTIP_OFFSET.top}
          style={TOOLTIP_STYLES}
        >
          <ChartTooltip>{renderTooltipContentByIndex(tooltipData.hoveredIndex)}</ChartTooltip>
        </TooltipInPortal>
      )}
    </>
  );
};

export default MeterChart;
