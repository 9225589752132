import { CalendarCell, CalendarGrid, Heading } from "react-aria-components";

import { ChevronNew } from "components/icons";

import ButtonIconNew from "../ButtonIcon/New";
import styles from "./styles.module.css";
import Box from "../Box";

const DatepickerCalendarContent = () => (
  <>
    <Box align="center" justify="between" margin="0 0 large 0">
      <ButtonIconNew
        slot="previous"
        icon={ChevronNew}
        iconRotate="180"
        tooltipMode="parent"
        variant="ghost"
      >
        Previous
      </ButtonIconNew>
      <Heading className={styles.calendarHeading} />
      <ButtonIconNew slot="next" icon={ChevronNew} tooltipMode="parent" variant="ghost">
        Next
      </ButtonIconNew>
    </Box>
    <CalendarGrid weekdayStyle="short">
      {(date) => <CalendarCell className={styles.calendarCell} date={date} />}
    </CalendarGrid>
  </>
);

export default DatepickerCalendarContent;
