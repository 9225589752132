import { IconComponent } from "types/Icon";

import { ReactComponent as Stack } from "./stack.svg";

type AttachedIconProps = {
  className?: string;
};

const makeNoFill = (Icon: IconComponent) =>
  function MakeNoFill({ className }: AttachedIconProps) {
    return <Icon style={{ fill: "none" }} className={className} />;
  };

export const StackIcon = makeNoFill(Stack);
