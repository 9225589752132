import { ReactNode } from "react";

import FullScreenModalWrapper from "./Wrapper";
import FullScreenModalHeader from "./Header";

type FullScreenModalProps = {
  title: string;
  onClose: () => void;
  children: ReactNode;
};

const FullScreenModal = ({ title, onClose, children }: FullScreenModalProps) => {
  return (
    <FullScreenModalWrapper>
      <FullScreenModalHeader onClose={onClose}>{title}</FullScreenModalHeader>
      {children}
    </FullScreenModalWrapper>
  );
};

export default FullScreenModal;
