import { Space } from "types/generated";

export const getDefaultValues = (isEditMode: boolean, space?: Space) =>
  isEditMode
    ? {
        name: space?.name || "",
        description: space?.description || "",
        inheritEntities: !!space?.inheritEntities,
        parentSpace: space?.parentSpace || "",
        labels: space?.labels.map((value) => ({ value })) || [],
      }
    : {
        name: "",
        description: "",
        inheritEntities: true,
        parentSpace: space?.id,
        labels: [],
      };
