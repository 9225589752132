import { useCallback, useMemo } from "react";

import { Stack } from "types/generated";
import useApplyFilter from "components/Filters/useApplyFilter";
import { StackSuggestions } from "constants/stack";
import { getCommmitDigitsCount } from "utils/commit";
import { generateRepoUrlText } from "utils/urls";

import { STACKS_COLUMN_ID } from "../constants";
import { APPLY_FILTERS_SUGGESTIONS } from "./constants";
import usePredefinedFilters, { PredefinedFilterType } from "../usePredefinedFilters";
import useStackListAnalytics from "../useStackListAnalytics";

const ADD_TO_FILTERS = "Add to filters";

const useCellActionsConfig = (stack: Stack) => {
  const { applySpaceFilter, applyFilter } =
    useApplyFilter<StackSuggestions>(APPLY_FILTERS_SUGGESTIONS);
  const { predefinedFilterTab } = usePredefinedFilters();
  const trackAnalytics = useStackListAnalytics();

  const trackFilterClickedAnalytics = useCallback(
    (type: string) => {
      trackAnalytics("Add to filters clicked", { type });
    },
    [trackAnalytics]
  );

  const cellActionsConfig: Record<
    string,
    | Array<{
        title: string;
        action: () => void;
      }>
    | undefined
  > = useMemo(() => {
    const commitValue = stack.trackedCommit?.hash.slice(0, getCommmitDigitsCount(stack.provider));
    const isFilterStateTabUnactivated =
      !predefinedFilterTab || predefinedFilterTab === PredefinedFilterType.AllStacks;
    return {
      [STACKS_COLUMN_ID.STATE]: isFilterStateTabUnactivated
        ? [
            {
              title: ADD_TO_FILTERS,
              action: () => {
                applyFilter(StackSuggestions.State)(stack.state);
                trackFilterClickedAnalytics(StackSuggestions.State);
              },
            },
          ]
        : undefined,
      [STACKS_COLUMN_ID.SPACE]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applySpaceFilter(StackSuggestions.Space)(stack.spaceDetails.id);
            trackFilterClickedAnalytics(StackSuggestions.Space);
          },
        },
      ],
      [STACKS_COLUMN_ID.REPOSITORY]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applyFilter(StackSuggestions.Repository)(
              generateRepoUrlText({
                namespace: stack.namespace,
                repository: stack.repository,
              })
            );
            trackFilterClickedAnalytics(StackSuggestions.Repository);
          },
        },
      ],
      [STACKS_COLUMN_ID.PROJECT_ROOT]: stack.projectRoot
        ? [
            {
              title: ADD_TO_FILTERS,
              action: () => {
                applyFilter(StackSuggestions.ProjectRoot)(stack.projectRoot!);
                trackFilterClickedAnalytics(StackSuggestions.ProjectRoot);
              },
            },
          ]
        : undefined,
      [STACKS_COLUMN_ID.BRANCH]: [
        {
          title: ADD_TO_FILTERS,
          action: () => {
            applyFilter(StackSuggestions.Branch)(stack.branch);
            trackFilterClickedAnalytics(StackSuggestions.Branch);
          },
        },
      ],
      [STACKS_COLUMN_ID.COMMIT]: commitValue
        ? [
            {
              title: ADD_TO_FILTERS,
              action: () => {
                applyFilter(StackSuggestions.Commit)(commitValue);
                trackFilterClickedAnalytics(StackSuggestions.Commit);
              },
            },
          ]
        : undefined,
    };
  }, [
    applySpaceFilter,
    applyFilter,
    stack.branch,
    stack.namespace,
    stack.projectRoot,
    stack.provider,
    stack.repository,
    stack.spaceDetails.id,
    stack.state,
    stack.trackedCommit?.hash,
    trackFilterClickedAnalytics,
    predefinedFilterTab,
  ]);

  return cellActionsConfig;
};

export default useCellActionsConfig;
