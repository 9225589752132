import { memo } from "react";

import Drawer from "ds/components/Drawer";

import IdpGroupMappingInfoDrawerContent from "./Content";

type IdpGroupMappingInfoDrawerProps = {
  isDrawerVisible: boolean;
  handleCloseDrawer: () => void;
};

const IdpGroupMappingInfoDrawer = ({
  isDrawerVisible,
  handleCloseDrawer,
}: IdpGroupMappingInfoDrawerProps) => {
  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer}>
      <IdpGroupMappingInfoDrawerContent handleCloseDrawer={handleCloseDrawer} />
    </Drawer>
  );
};

export default memo(IdpGroupMappingInfoDrawer);
