import { gql } from "@apollo/client";

export const GET_ATTACHABLE_WORKER_POOLS_IN_SPACE = gql`
  query GetAttachableWorkerPoolsInSpace($id: ID!) {
    attachableWorkerPoolsInSpace(id: $id) {
      id
      name
    }
  }
`;
