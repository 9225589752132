import { ReactNode } from "react";

import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import Typography from "ds/components/Typography";

export const getEnableSensitiveOutputUploadTooltip = (): ReactNode => (
  <>
    <TooltipModalTitle>Transfer sensitive outputs across dependencies</TooltipModalTitle>
    <TooltipModalBody align="start">
      <Typography tag="p" variant="p-body3">
        This option makes it possible to pass sensitive outputs from this stack to dependent stacks
        through stack dependencies. Bear in mind that for this mechanism to work the stack must also
        use a worker pool that has the upload option for sensitive outputs enabled (enabled by
        default on the public worker pool).
      </Typography>
    </TooltipModalBody>
  </>
);
