import cx from "classnames";

import { WebhookDelivery } from "types/generated";

import styles from "./styles.module.css";

type WebhookDeliveryStatusProps = {
  status: WebhookDelivery["status"];
};

const WebhookDeliveriesStatus = ({ status }: WebhookDeliveryStatusProps) => {
  const statusLabelClassName = cx(styles.statusLabel, {
    [styles.statusLabelOk]: status >= 200 && status < 300,
    [styles.statusLabelRedirect]: status >= 300 && status < 400,
    [styles.statusLabelError]: status >= 400 && status < 600,
    [styles.statusLabelSpecial]: status === 0,
  });
  return (
    <div className={styles.status}>
      <div className={`${statusLabelClassName} ${styles.statusIndicator}`}></div>
      <label className={statusLabelClassName}>{status}</label>
    </div>
  );
};

export default WebhookDeliveriesStatus;
