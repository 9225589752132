import { stackValidator } from "utils/formValidators";

import { StackCreationVersion, StackCreationVersionType, StackVendorFormFields } from "../../types";

export const getValidateTerragruntVersion =
  (terragruntVersions: string[] = []) =>
  (version: StackCreationVersion, formValues: StackVendorFormFields) => {
    if (!version.value.trim()) {
      return "Version is required";
    }

    const rangeError =
      formValues.terragrunt.terragruntVersion.type === StackCreationVersionType.Range &&
      stackValidator.validateVersionRange(version.value, terragruntVersions, "Terragrunt");

    return rangeError || true;
  };
