import removeMd from "remove-markdown";
import { CSSProperties, forwardRef } from "react";

import Typography from "ds/components/Typography";

type ListEntitiesItemDescriptionContentProps = {
  description: string;
  style?: CSSProperties;
  className?: string;
  color?: "primary" | "secondary";
};

const ListEntitiesItemDescriptionContent = forwardRef(function ListEntitiesItemDescriptionContent(
  { description, color = "secondary", ...restProps }: ListEntitiesItemDescriptionContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Typography {...restProps} ref={ref} tag="div" variant="p-body3" color={color}>
      {removeMd(description)}
    </Typography>
  );
});

export default ListEntitiesItemDescriptionContent;
