import CardWrapper from "components/CardWrapper";
import { Command, HookType } from "utils/hooks";
import Typography from "ds/components/Typography";

import { ContextHook } from "../../types";
import ContextHooksList from "../ContextHooksList";
import StackHooksList from "../StackHooksList";

type PhaseHooksProps = {
  type: HookType;
  contextHooks: ContextHook[];
  stackHooks: Command[];
  isDragActive: boolean;
};

const PhaseHooks = ({ type, contextHooks, stackHooks, isDragActive }: PhaseHooksProps) => {
  const contextHooksList = <ContextHooksList type={type} hooks={contextHooks} />;
  const stackHooksList = (
    <StackHooksList isDragActive={isDragActive} type={type} hooks={stackHooks} />
  );
  return (
    <CardWrapper variant="outlined" direction="column" gap="large">
      <Typography tag="p" color="secondary" variant="p-t7" transform="uppercase">
        {type === "before" ? "Before" : "After"}
      </Typography>
      {type == "before" ? (
        <>
          {contextHooksList}
          {stackHooksList}
        </>
      ) : (
        <>
          {stackHooksList}
          {contextHooksList}
        </>
      )}
    </CardWrapper>
  );
};

export default PhaseHooks;
