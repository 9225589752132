import cx from "classnames";

import { OK, Cross } from "components/icons";

import styles from "./styles.module.css";

type FormBooleanIconType = {
  success: boolean;
  className?: string;
};

const FormBooleanIcon = ({ success, className }: FormBooleanIconType) => {
  return success ? (
    <OK className={cx(styles.icon, styles.success, className)} />
  ) : (
    <Cross className={cx(styles.icon, styles.danger, className)} />
  );
};

export default FormBooleanIcon;
