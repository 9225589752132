import BadgeNext from "ds/components/BadgeNext";
import { BadgeVariants } from "ds/components/BadgeNext/types";
import { SpaceAccessLevel } from "types/generated";

type SpaceAccessConfig = {
  variant: BadgeVariants;
  text: string;
};

export const SPACE_ACCESS_LEVELS: Record<SpaceAccessLevel, SpaceAccessConfig> = {
  [SpaceAccessLevel.Admin]: { variant: "yellow", text: "Admin" },
  [SpaceAccessLevel.None]: { variant: "gray", text: "No access" },
  [SpaceAccessLevel.Read]: { variant: "blue", text: "Read" },
  [SpaceAccessLevel.Write]: { variant: "green", text: "Write" },
};

type SpaceAccessBadgeProps = {
  accessLevel: SpaceAccessLevel;
  className?: string;
};

const SpaceAccessBadge = ({ accessLevel, className }: SpaceAccessBadgeProps) => {
  return (
    <BadgeNext
      variant={SPACE_ACCESS_LEVELS[accessLevel].variant}
      className={className}
      text={SPACE_ACCESS_LEVELS[accessLevel].text}
      type="weak"
    />
  );
};

export default SpaceAccessBadge;
