import { PolicyType } from "types/generated";

import { ManuallyAttachablePolicyType } from "./types";

export const POLICY_TYPE_LABELS: Record<ManuallyAttachablePolicyType, string> = {
  [PolicyType.GitPush]: "Push",
  [PolicyType.Approval]: "Approval",
  [PolicyType.Plan]: "Plan",
  [PolicyType.Trigger]: "Trigger",
};
