import { ReactNode } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import useTypedContext from "hooks/useTypedContext";
import { Pencil } from "components/icons";
import CollapsibleList from "components/CollapsibleList";

import { ContextFormContext } from "../context";
import { ContextCreationWizardStep } from "../constants";

type NewContextSummaryPanelProps = {
  name: string;
  step: ContextCreationWizardStep;
  children: ReactNode;
  goToStepDisabled?: boolean;
  count?: number;
};

const NewContextSummaryPanel = ({
  name,
  step,
  children,
  count,
  goToStepDisabled,
}: NewContextSummaryPanelProps) => {
  const { setCurrentStep } = useTypedContext(ContextFormContext);

  const goToStep = () => {
    setCurrentStep(step);
  };

  return (
    <CollapsibleList
      ariaLevel={2}
      title={name}
      count={count}
      initialIsCollapsed={false}
      action={
        <ButtonIcon disabled={goToStepDisabled} icon={Pencil} onClick={goToStep} variant="ghost">
          Edit step
        </ButtonIcon>
      }
    >
      {children}
    </CollapsibleList>
  );
};

export default NewContextSummaryPanel;
