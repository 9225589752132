import { useCallback, useState } from "react";

import { BulkActionItemID } from "./types";

const useBulkActionsSelection = () => {
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [selectedSet, updateSelectedSet] = useState<Set<BulkActionItemID>>(new Set());

  const onBulkSelectAll = useCallback((selectedSet: Set<BulkActionItemID>) => {
    setAllItemsSelected(true);
    updateSelectedSet(selectedSet);
  }, []);

  const onBulkResetAll = useCallback(() => {
    setAllItemsSelected(false);
    updateSelectedSet(new Set());
  }, []);

  const onBulkContinueWith = useCallback(
    (items: Set<BulkActionItemID>) => {
      setAllItemsSelected(false);
      updateSelectedSet(items);
    },
    [updateSelectedSet]
  );

  const syncAllSelectedItems = useCallback((syncedSelectedSet: Set<BulkActionItemID>) => {
    updateSelectedSet(syncedSelectedSet);
  }, []);

  const handleCheck = useCallback(
    (state: Set<BulkActionItemID>, id: BulkActionItemID) => new Set([...state, id]),
    []
  );
  const handleUncheck = useCallback((state: Set<BulkActionItemID>, id: BulkActionItemID) => {
    if (state.has(id)) {
      state.delete(id);
      setAllItemsSelected(false);
    }

    return new Set([...state]);
  }, []);

  const toggleItem = useCallback(
    (id: BulkActionItemID) =>
      updateSelectedSet((state) => {
        if (state.has(id)) {
          return handleUncheck(state, id);
        }

        return handleCheck(state, id);
      }),
    [handleCheck, handleUncheck]
  );

  const selectItem = useCallback(
    (id: BulkActionItemID) => {
      updateSelectedSet((state) => handleCheck(state, id));
    },
    [handleCheck]
  );

  const unselectItem = useCallback(
    (id: BulkActionItemID) => {
      updateSelectedSet((state) => handleUncheck(state, id));
    },
    [handleUncheck]
  );

  const syncSelectedItemsWithVisibleOnes = useCallback(
    (visibleItems: Map<BulkActionItemID, unknown>) => {
      updateSelectedSet((state) => {
        const newSelectedSet = new Set<BulkActionItemID>();

        state.forEach((id) => {
          if (visibleItems.has(id)) {
            newSelectedSet.add(id);
          }
        });

        return newSelectedSet;
      });
    },
    []
  );

  return {
    selectedSet,
    allSelected: allItemsSelected,
    onBulkContinueWith,
    onBulkSelectAll,
    onBulkResetAll,
    syncAllSelectedItems,
    selectItem,
    unselectItem,
    toggleItem,
    syncSelectedItemsWithVisibleOnes,
  };
};

export default useBulkActionsSelection;
