import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Typography from "ds/components/Typography";

type ProviderVersionDeleteConfirmationProps = {
  onConfirm: () => void;
  isPublished: boolean;
  versionNumber: string;
};

const ProviderVersionDeleteConfirmation = create(function ProviderVersionDeleteConfirmation({
  onConfirm,
  isPublished,
  versionNumber,
}: ProviderVersionDeleteConfirmationProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <DeleteConfirmationModal
      size="large"
      onConfirm={handleOnConfirm}
      confirmationButtonLabel={isPublished ? "Revoke" : "Delete"}
    >
      <ConfirmationModalMessage>
        Are you sure you want to {isPublished ? "revoke" : "delete"} version{" "}
        <Typography tag="span" variant="p-t6">
          {versionNumber}
        </Typography>
        ?
      </ConfirmationModalMessage>
    </DeleteConfirmationModal>
  );
});

export const showProviderVersionDeleteConfirmation = (
  props: ProviderVersionDeleteConfirmationProps
) => show(ProviderVersionDeleteConfirmation, props);
