import { runDocument, stackDocument } from "./documents";

const Approval = `package spacelift

# Always sample in the beginning to be able to debug more easily!
sample { true }

# 🦕 Feel free to remove commented code once your policy is ready. 🦕
#
# ⚠️ Approval policies only take effect once attached to a Stack or Module ⚠️
#
# Approval policy defines rules for approving (or rejecting) certain workloads.
# As input, it receives a JSON document like this:
#
# {
#   "reviews": {
#     "current": [{
#       "approvals": [{
#         "author": "string - reviewer username",
#         "request": {
#           "remote_ip": "string - user IP",
#           "timestamp_ns": "number - review creation Unix timestamp in nanoseconds",
#         },
#         "session": {
#           "login": "string - username of the reviewer",
#           "name": "string - full name of the reviewer",
#           "teams": ["string - names of teams the reviewer was a member of"]
#         },
#         "state": "string - the state of the run at the time of the approval",
#       }],
#       "rejections": ["negative reviews, same as 'approvals']
#     }],
#     "older": ["reviews for previous state(s), same as 'current'"]
#   },
#   "run": {
${runDocument(5)}
#   },
#   "stack": {
${stackDocument(5)}
#   },
# }
#
# Your policy can define an arbitrary number of following rules:
#
#   - approve: approves the current run - it can proceed;
#   - reject: reject the current run - it fails;
#
# Keep in mind that either decision is final but you don't have to make it, it's
# perfectly fine not to approve or reject a run if the criteria aren't met.
#
# Below are few examples of what you can do with these rules:
#
# 1) Require two approvals and no rejections to approve an Unconfirmed run:
#
# approve { input.run.state != "UNCONFIRMED" }
# 
# approve {
#   count(input.reviews.current.approvals) > 1
#   count(input.reviews.current.rejections) == 0
# }
#
# 2) Require two approvals to approve a run, two rejections to reject it:
#
# approve { input.run.state != "UNCONFIRMED" }
# approve { count(input.reviews.current.approvals) > 1 }
# reject  { count(input.reviews.current.rejections) > 1 }
# 
# 3) Require approval for a task command not on the allowlist
# 
# allowlist := ["ps", "ls", "rm -rf /"]
# 
# approve { input.run.type != "TASK" }
# approve { input.run.command == allowlist[_] }
# approve { count(input.reviews.current.approvals) > 1 }
#
# 4) Create sophisticated role-based approval workflows:
# 
# First, let's define all conditions that require explicit
# user approval.
# requires_approval { input.run.state == "UNCONFIRMED" }
# requires_approval { input.run.type == "TASK" }
# approve           { not requires_approval }
#
# approvals := input.reviews.current.approvals
# 
# # Let's define what it means to be approved by a director, DevOps amd Security.
# director_approval { approvals[_].session.teams[_] == "Director" }
# devops_approval   { approvals[_].session.teams[_] == "DevOps" }
# security_approval { approvals[_].session.teams[_] == "Security" }
# 
# # Approve when a single director approves:
# approve { director_approval }
# 
# # Approve when both a DevOps and Security both approve:
# approve { devops_approval; security_approval }
# 
# Now go ahead and unleash your inner lawgiver. For more information on the rule
# language, please visit https://www.openpolicyagent.org/docs/latest/
`;
export default Approval;
