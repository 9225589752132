import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { ContextContext } from "../../Context";
import { ContextMountedFilesListItemActionsProps } from "./types";
import DownloadFileButton from "./Download";
import { showDeleteConfirmation } from "./DeleteConfirmation";

const ContextMountedFilesListItemActions = ({
  item,
  onEdit,
  forceOverwrite,
}: ContextMountedFilesListItemActionsProps) => {
  const { context, canManageContext } = useTypedContext(ContextContext);

  if (!canManageContext) return null;

  const handleDeleteClick = () => {
    showDeleteConfirmation({ id: item.id, contextId: context.id });
  };

  return (
    <Box align="center" justify="end">
      <DropdownMenuEllipsis tooltip="File actions">
        <DropdownMenuItem
          tooltip="This file is too large to edit, you can only overwrite it."
          tooltipPlacement="left"
          isTooltipActive={forceOverwrite}
          onAction={onEdit}
        >
          {item.writeOnly || forceOverwrite ? "Overwrite" : "Edit file"}
        </DropdownMenuItem>

        {!item.writeOnly && item.value && (
          <DownloadFileButton fileName={item.id} href={item.value} />
        )}

        <DropdownMenuItem onAction={handleDeleteClick} danger>
          Delete
        </DropdownMenuItem>
      </DropdownMenuEllipsis>
    </Box>
  );
};

export default ContextMountedFilesListItemActions;
