import { create, show, useModal } from "@ebay/nice-modal-react";
import { ReactNode } from "react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import { ConfirmationModalProps } from "ds/components/ConfirmationModal/types";

type NewModuleFooterConfirmationProps = {
  onConfirm: () => void;
  warning: ReactNode;
  confirmationButtonLabel: ConfirmationModalProps["confirmationButtonLabel"];
};

const NewModuleFooterConfirmation = create(function NewModuleFooterConfirmation({
  onConfirm,
  warning,
  confirmationButtonLabel,
}: NewModuleFooterConfirmationProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.hide();
  };

  return (
    <ConfirmationModal
      size="large"
      onConfirm={handleOnConfirm}
      confirmationButtonVariant="primary"
      confirmationButtonLabel={confirmationButtonLabel}
    >
      <ConfirmationModalMessage>
        {warning || "You have unsubmitted changes"}
      </ConfirmationModalMessage>
    </ConfirmationModal>
  );
});

export const showNewModuleFooterConfirmation = (props: NewModuleFooterConfirmationProps) =>
  show(NewModuleFooterConfirmation, props);
