import { Stack } from "types/generated";
import Box from "ds/components/Box";
import StackStateBadgeNewDropdown from "components/StackStateBadge/New";

type StackListItemStateCellProps = { stack: Stack };

const StackListItemStateCell = ({ stack }: StackListItemStateCellProps) => {
  return (
    <Box align="center">
      <StackStateBadgeNewDropdown state={stack.state} />
    </Box>
  );
};

export default StackListItemStateCell;
