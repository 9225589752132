import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { VcsIntegration } from "types/generated";

import VCSIntegrationsListItem from ".";
import { getVCSIntegrationId } from "../helpers";

type VCSIntegrationsVirtualizedListItemProps = {
  items: VcsIntegration[];
  onEdit: (item: VcsIntegration) => void;
  onShowDetails: (item: VcsIntegration) => void;
  onShowFullDescription: (item: VcsIntegration) => void;
  focusedItemSlug?: string;
};

const VCSIntegrationsVirtualizedListItem = (
  props: ListChildComponentProps<
    VCSIntegrationsVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <VCSIntegrationsListItem
        key={getVCSIntegrationId(data.items[index])}
        item={data.items[index]}
        onEdit={data.onEdit}
        setRowHeight={handleSetRowHeight}
        onShowDetails={data.onShowDetails}
        onShowFullDescription={data.onShowFullDescription}
        isActive={data.focusedItemSlug === getVCSIntegrationId(data.items[index])}
      />
    </div>
  );
};

export default memo(VCSIntegrationsVirtualizedListItem, areEqual);
