import cx from "classnames";
import { useMutation } from "@apollo/client";

import ButtonIcon from "ds/components/ButtonIcon";
import Box from "ds/components/Box";
import { Loading, Trash } from "components/icons";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import { useToggle } from "hooks/useToggle";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import Tag from "ds/components/Tag";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Maybe, PolicyStackAttachment } from "types/generated";
import PolicyTypeBadge from "components/PolicyTypeBadge";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";

import { AttachedPolicy, ManuallyAttachablePolicyType } from "../types";
import { AutoAttachedPolicy } from "../types";
import NewStackAttachedPolicyContent from "./Content";
import styles from "./styles.module.css";
import { DETACH_POLICY } from "./gql";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";

type NewStackAttachedPolicyProps = {
  item: AttachedPolicy | AutoAttachedPolicy;
  isSummaryView?: boolean;
};

const NewStackAttachedPolicy = ({ item, isSummaryView }: NewStackAttachedPolicyProps) => {
  const { name, attachmentId, body, type } = item;
  const isAutoAttached = "autoAttachableLabel" in item;
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const [detachPolicy, { loading }] = useMutation<{ policyDetach: Maybe<PolicyStackAttachment> }>(
    DETACH_POLICY,
    {
      refetchQueries: ["GetAttachedStackPolicies"],
      awaitRefetchQueries: true,
    }
  );

  const handleDetach = () => {
    detachPolicy({ variables: { attachmentId } })
      .then(({ data }) => {
        if (data?.policyDetach?.id) {
          reportSuccess({ message: "Policy was detached" });
          trackSegmentEvent("Policy detached");
        }
      })
      .catch(onError);
  };

  const canDetach = !!attachmentId && !isSummaryView;

  const [isCollapsed, toggle] = useToggle(true);

  return (
    <CollapsiblePanel isCollapsed={isCollapsed} onToggle={toggle}>
      <CollapsiblePanelHeader padding="medium large" gap="medium" align="center">
        <Box role="heading" aria-level={3}>
          <CollapsiblePanelToggleTrigger gap="medium">
            <CollapsiblePanelToggleIndicator />

            <Box grow="1">
              <TextEllipsis tooltip={name} tooltipWidthMode="maxWidthSm" delay={400}>
                {(props) => (
                  <Typography {...props} variant="p-t6" tag="h6">
                    {name}
                  </Typography>
                )}
              </TextEllipsis>
            </Box>
          </CollapsiblePanelToggleTrigger>
        </Box>

        <Box
          align="center"
          gap="large"
          justify="end"
          grow="1"
          className={cx(isAutoAttached && styles.badgeAutoattachContainer)}
        >
          {isAutoAttached && (
            <div className={styles.badgeWrapper}>
              <Tag tag={item.autoAttachableLabel} />
            </div>
          )}

          {!isAutoAttached && <PolicyTypeBadge type={type as ManuallyAttachablePolicyType} />}
        </Box>
        {canDetach && (
          <ButtonIcon
            disabled={loading}
            icon={loading ? Loading : Trash}
            onClick={handleDetach}
            variant={loading ? "ghost" : "ghostDanger"}
          >
            Detach
          </ButtonIcon>
        )}
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent>
        <NewStackAttachedPolicyContent policyBody={body} />
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default NewStackAttachedPolicy;
