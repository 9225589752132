import { Clock } from "components/icons";
import Timestamp from "components/time/Timestamp";

import styles from "../styles.module.css";

type ConfigTimestampProps = {
  createdAt: number;
};

const ConfigTimestamp = ({ createdAt }: ConfigTimestampProps) => (
  <div className={styles.variableTimestamp}>
    <Clock className={styles.variableTimestampIcon} />
    <Timestamp timestamp={createdAt} />
  </div>
);

export default ConfigTimestamp;
