import { SearchQueryOrderDirection } from "types/generated";

export const FILTERS_ORDER_SETTINGS_KEY = "modulesFiltersOrder";
export const MODULE_LIST_VIEW_ITEMS_SETTINGS_KEY = "modulesListViewItems";

export const initialSortOption = "starred";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const POLL_INTERVAL = 10000;
export const MODULES_LIMIT = 50;

export enum MODULE_LIST_VIEW_ITEM_ID {
  TAGS = "TAGS",
  SPACE = "SPACE",
  ADMINISTRATIVE = "ADMINISTRATIVE",
  BRANCH = "BRANCH",
  REPOSITORY = "REPOSITORY",
  PROJECT_ROOT = "PROJECT_ROOT",
  DESCRIPTION = "DESCRIPTION",
  PROVIDER = "PROVIDER",
}

export const INITIAL_MODULE_LIST_VIEW_ITEMS = [
  {
    id: MODULE_LIST_VIEW_ITEM_ID.TAGS,
    name: "Tags",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.SPACE,
    name: "Space",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.ADMINISTRATIVE,
    name: "Administrative",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.BRANCH,
    name: "Branch",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.REPOSITORY,
    name: "Repository",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.PROJECT_ROOT,
    name: "Project root",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.DESCRIPTION,
    name: "Description",
    selected: true,
  },
  {
    id: MODULE_LIST_VIEW_ITEM_ID.PROVIDER,
    name: "Module provider",
    selected: true,
  },
];
