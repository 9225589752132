import { Draggable, Droppable } from "react-beautiful-dnd";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import DraggableItemHandle from "ds/components/DraggableItem/Handle";
import useNavigationConfig from "hooks/useNavigationConfig";
import DraggableItem from "ds/components/DraggableItem";
import { SideNavigationItemType } from "types/Navigation";
import { DropColored } from "components/icons";
import DropEmptyState from "components/DropEmptyState";
import useNavigationDefaultView from "hooks/useNavigationDefaultView";
import Badge from "ds/components/Badge";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import { DroppableId } from "./types";

type CustomizeSidebarItemsProps = {
  type: DroppableId;
  title: string;
  items: SideNavigationItemType[];
  isDropDisabled?: boolean;
  isDragActive?: boolean;
  onManualChange: (from: DroppableId, to: DroppableId, fromIndex: number, toIndex: number) => void;
  onSetDefaultView: (view: SideNavigationItemType) => void;
};

const CustomizeSidebarItems = ({
  type,
  title,
  items,
  isDropDisabled,
  isDragActive,
  onManualChange,
  onSetDefaultView,
}: CustomizeSidebarItemsProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const navigationConfig = useNavigationConfig({ isAdmin: viewer.admin });

  const defaultView = useNavigationDefaultView(viewer.admin);

  const handleVisibilityChange = (index: number) => () => {
    const nextType = type === DroppableId.Hidden ? DroppableId.Visible : DroppableId.Hidden;
    onManualChange(type, nextType, index, 0);
  };

  const handleMoveUp = (index: number) => () => {
    onManualChange(type, type, index, index - 1);
  };

  const handleMoveDown = (index: number) => () => {
    onManualChange(type, type, index, index + 1);
  };

  const handleSetDefaultView = (item: SideNavigationItemType) => () => {
    onSetDefaultView(item);
  };

  return (
    <Box
      fullWidth
      direction="column"
      padding={items.length > 0 ? "large large medium large" : "large"}
      className={styles.card}
    >
      <Typography tag="h3" variant="p-t7" color="secondary" transform="uppercase">
        {title}
      </Typography>
      <Droppable droppableId={type} isDropDisabled={isDropDisabled}>
        {(droppableProvided) => (
          <Box
            direction="column"
            ref={droppableProvided.innerRef}
            margin="medium 0 0 0"
            className={isDropDisabled ? styles.dropDisabled : undefined}
            {...droppableProvided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable draggableId={item} index={index} key={item}>
                {(provided, snapshot) => (
                  <DraggableItem
                    ref={provided.innerRef}
                    size="small"
                    margin="0 0 medium 0"
                    dragging={snapshot.isDragging}
                    dropping={snapshot.isDropAnimating}
                    disabled={isDropDisabled}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DraggableItemHandle fullWidth>
                      <Box align="center" justify="between" fullWidth>
                        <Typography tag="span" variant="p-body2">
                          {navigationConfig[item]?.title}
                        </Typography>
                        <Box align="center" gap="x-large">
                          {defaultView.id === item && <Badge state="info">Default</Badge>}
                          <DropdownMenuEllipsis
                            tooltip="More"
                            placement="bottom end"
                            buttonVariant="ghost"
                          >
                            {defaultView.id !== item && (
                              <DropdownMenuItem onAction={handleSetDefaultView(item)}>
                                Set as default
                              </DropdownMenuItem>
                            )}

                            <DropdownMenuItem onAction={handleVisibilityChange(index)}>
                              {type === DroppableId.Hidden ? "Show in the sidebar" : "Hide"}
                            </DropdownMenuItem>
                            {index !== 0 && (
                              <DropdownMenuItem onAction={handleMoveUp(index)}>
                                Move up
                              </DropdownMenuItem>
                            )}
                            {index !== items.length - 1 && (
                              <DropdownMenuItem onAction={handleMoveDown(index)}>
                                Move down
                              </DropdownMenuItem>
                            )}
                          </DropdownMenuEllipsis>
                        </Box>
                      </Box>
                    </DraggableItemHandle>
                  </DraggableItem>
                )}
              </Draggable>
            ))}
            {items.length !== 0 && droppableProvided.placeholder}

            {items.length === 0 && (
              <DropEmptyState
                isDragActive={!!isDragActive}
                padding="large"
                icon={DropColored}
                caption="Drag & Drop a feature from the section above if you want to hide it"
                dropCaption="You can drop the feature here"
              />
            )}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default CustomizeSidebarItems;
