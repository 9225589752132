import capitalize from "lodash-es/capitalize";

import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import HistoryEntryNote from "../../components/HistoryEntryNote";
import { RunHistoryEntryType } from "../../types";

type SkippedEntryProps = {
  note: string | null;
  timestamp: number;
};

const SkippedEntry = ({ note, timestamp }: SkippedEntryProps) => {
  return (
    <HistoryEntryWrapper state={RunHistoryEntryType.Skipped} timestamp={timestamp} terminal>
      {note && <HistoryEntryNote>{capitalize(note)}</HistoryEntryNote>}
    </HistoryEntryWrapper>
  );
};

export default SkippedEntry;
