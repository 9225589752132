import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { SOURCE_CODE_FIELDS_TOOLTIPS } from "../constants";
import { SourceCodeFields, SourceCodeProjects } from "../types";

type BranchesTooltipProps = {
  projectType: SourceCodeProjects;
  isReadOnly?: boolean;
};

const BranchesTooltip = ({ projectType, isReadOnly }: BranchesTooltipProps) => (
  <>
    <TooltipModalTitle>Branch</TooltipModalTitle>
    <TooltipModalBody align="start">
      {
        SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Branch][projectType].text[
          isReadOnly ? "readonly" : "default"
        ]
      }
      <ReadMoreDocsLink
        docsUrl={SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Branch][projectType].link}
      />
    </TooltipModalBody>
  </>
);
export default BranchesTooltip;
