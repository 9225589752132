import {
  Maybe,
  SavedFilter,
  SearchQueryOrderDirection,
  SearchSuggestionsFieldType,
} from "types/generated";

export type FilterItem = {
  key: string; // unique key
  filterName: string;
  type: SearchSuggestionsFieldType;
};

export type FilterItemSettings = {
  name: string;
  visible: boolean;
};

export type FiltersItemsOptionsMap = Map<string, FilterItemOption[] | HierarchyFilterItemOption[]>;

export type FilterItemOption = {
  type: SearchSuggestionsFieldType;
  value: string | number | boolean;
  count: number | undefined;
};

export type HierarchyFilterItemOption = {
  id: string;
  parentId: Maybe<string>;
} & FilterItemOption;

export type HierarchyFilterItemMapOption = {
  children: string[];
} & HierarchyFilterItemOption;

export type HierarchyFilterOptionsMap = Map<string, HierarchyFilterItemMapOption>;

export type SortOption = {
  value: string;
  label: string;
};

export type ActiveFilter = FilterItem & {
  values: string[];
};

export type ActiveFiltersMap = Map<string, ActiveFilter>;

export type FiltersDictionary = {
  [key: string]: string;
};

export type SavedFilterView = Pick<SavedFilter, "id" | "name" | "isPublic"> & {
  order: FilterItemSettings[];
  filters: ActiveFiltersMap;
  isMine: boolean;
  sortOption: string | null;
  sortDirection: SearchQueryOrderDirection | null;
  search: string | null;
};

export enum FilterMode {
  Filters = "filters",
  Views = "views",
}

export type SavedFilterListItem = {
  id: SavedFilter["id"];
  name: SavedFilter["name"];
  data: SavedFilter["data"];
  isPublic: SavedFilter["isPublic"];
  createdBy: SavedFilter["createdBy"];
  type: SavedFilter["type"];
};

export type FiltersApplyView = (
  filterData: SavedFilterListItem["data"],
  name: SavedFilterListItem["name"],
  id: SavedFilterListItem["id"],
  isPublic: SavedFilterListItem["isPublic"],
  isMine: boolean
) => unknown;

export type DefaultView = {
  loading: boolean;
  data: SavedFilterView | undefined;
  rawData: string;
  removeFromStorage: () => void;
};
