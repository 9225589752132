import { gql } from "@apollo/client";

export const GET_STACK_FAILURES = gql`
  query GetStackFailures($startTime: Int!, $endTime: Int!) {
    metricsRange(input: { startTime: $startTime, endTime: $endTime }) {
      stackFailuresRange {
        timestamp
        value
      }
    }
  }
`;
