import { BillingCycleInterval } from "types/generated";

export const BILLING_CYCLE_SELECT_OPTIONS = [
  {
    label: "Monthly",
    value: BillingCycleInterval.Monthly,
  },
  {
    label: "Annual",
    value: BillingCycleInterval.Yearly,
  },
];
