import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import { getDocsUrl } from "utils/getDocsUrl";

const Documentation = () => (
  <Typography tag="p" variant="p-body2">
    In Terraform, modules help you abstract away common functionality in your infrastructure.
    Spacelift provides everything you need to make your module easily maintainable and usable. There
    is CI/CD for multiple specified versions of Terraform, which "runs" your module on each commit.
    You get an autogenerated page describing your Module and its intricacies, so your users can
    explore them and gather required information at a glimpse. It's also deeply integrated with all
    the features <TextLink href={getDocsUrl("/concepts/stack")}>Stacks</TextLink> use which you know
    and love, like{" "}
    <TextLink href={getDocsUrl("/concepts/configuration/environment")}>Environments</TextLink>,{" "}
    <TextLink href={getDocsUrl("/concepts/policy")}>Policies</TextLink>,{" "}
    <TextLink href={getDocsUrl("/concepts/configuration/context")}>Contexts</TextLink> and{" "}
    <TextLink href={getDocsUrl("/concepts/stack/stack-settings#worker-pool")}>
      Worker Pools
    </TextLink>
    .
  </Typography>
);

export default Documentation;
