import {
  RunExternalDependency,
  RunDependency,
  RunState,
  RunExternalDependencyStatus,
} from "types/generated";

export type CombinedDependencies = Array<
  (RunDependency | (RunExternalDependency & { state: RunExternalDependencyStatus })) & {
    timestamp: number;
  }
>;

type DependencyStatus = RunState | RunExternalDependencyStatus;

const isFailed = (state: DependencyStatus) =>
  state === RunState.Failed ||
  state === RunState.Discarded ||
  state === RunState.Stopped ||
  state === RunState.Canceled ||
  state === RunExternalDependencyStatus.Failed;

export const isFinished = (state: DependencyStatus) =>
  state === RunState.Finished || state === RunExternalDependencyStatus.Finished;

/**
 * Determines the final state from the run dependencies and external dependencies.
 * The algorithm is as follows:
 * 1. If any dependency has a failed state, the final state is failed.
 * 2. If all dependencies have a finished state, the final state is finished.
 * 3. Otherwise, the most recent state is returned.
 */
export const getDependencyEvaluation = (dependencies: CombinedDependencies) => {
  // Find first failed state
  const failedState = dependencies.find((item) => isFailed(item.state));

  if (failedState) {
    return {
      state: failedState.state,
      timestamp: failedState.timestamp,
      isImportant: true,
    };
  }

  // Check if there are any non-finished states
  // We need to skip finished states to avoid marking dependencies as finished too early
  const firstNonFinished = dependencies.find((item) => !isFinished(item.state));

  if (firstNonFinished) {
    return {
      state: firstNonFinished.state,
      timestamp: firstNonFinished.timestamp,
      isImportant: true,
    };
  }

  // If all states are finished, return the most recent one
  return {
    state: dependencies[0].state,
    timestamp: dependencies[0].timestamp,
    isImportant: false,
  };
};

export const combineDependencies = (
  runDependencies: RunDependency[] | undefined,
  externalDependencies: RunExternalDependency[] | undefined
): CombinedDependencies => {
  const combined = ([] as CombinedDependencies).concat(
    (runDependencies || []).map((item) => ({ ...item, timestamp: item.runUpdatedAt })),
    (externalDependencies || []).map((item) => ({
      ...item,
      timestamp: item.completedAt || item.createdAt,
      state: item.status,
    }))
  );

  combined.sort((a, b) => b.timestamp - a.timestamp);

  return combined;
};
