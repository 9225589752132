import cx from "classnames";

import styles from "./styles.module.css";

type CountdownProps = {
  className?: string;
  ttl?: number;
  isPaused?: boolean;
};

const Countdown = ({ className, isPaused, ttl = 5000 }: CountdownProps) => (
  // TODO: use Icon component
  <svg
    aria-hidden
    className={cx(styles.countdown, className, isPaused && styles.paused)}
    viewBox="0 0 30 30"
  >
    <circle
      style={{ animationDuration: `${ttl}ms` }}
      className={cx(styles.countdownCircle)}
      cx={15}
      cy={15}
      r={14}
      strokeWidth="1.5"
    />
  </svg>
);

export default Countdown;
