import { useCallback, useEffect, useState, MouseEvent } from "react";

import FlashContext from "components/FlashMessages/FlashContext";

import useTypedContext from "./useTypedContext";

function useCopyToClipboard(value: string, callback?: () => void) {
  const [isCopied, setIsCopied] = useState(false);

  const { reportSuccess, reportError } = useTypedContext(FlashContext);

  const handleCopy = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation();

      const asyncFn = async () => {
        setIsCopied(true);

        try {
          await navigator.clipboard.writeText(value);
          reportSuccess({ message: "Copied to clipboard" });
        } catch {
          reportError({ message: "Could not copy to clipboard" });
        }

        callback?.();
      };

      void asyncFn();
    },

    [value, callback, reportSuccess, reportError]
  );

  useEffect(() => {
    let timeOutId: NodeJS.Timeout | undefined = undefined;

    if (isCopied) {
      timeOutId = setTimeout(() => setIsCopied(false), 2500);
    }

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [isCopied]);

  return handleCopy;
}

export default useCopyToClipboard;
