import { create, show, useModal } from "@ebay/nice-modal-react";

import Button from "components/button/Button";
import ModalSimple from "ds/components/ModalSimple";
import ModalContent from "ds/components/Modal/Content";
import { Cross } from "components/icons";

import styles from "./styles.module.css";

type SessionsConfirmationModalProps = {
  sessionId: string;
  userId: string;

  confirmationCallback: () => void;
  isCurrentSession: boolean;
};

const SessionsConfirmationModal = create(function SessionsConfirmationModal(
  props: SessionsConfirmationModalProps
) {
  const { sessionId, userId, confirmationCallback, isCurrentSession } = props;

  const modal = useModal();

  const handleConfirmation = () => {
    confirmationCallback();
    modal.hide();
  };

  return (
    <ModalSimple size="large">
      <ModalContent align="center">
        <div className={styles.bubble}>
          <div className={styles.innerBubble}>
            <div className={styles.bubbleIconWrapper}>
              <Cross className={styles.bubbleIcon} />
            </div>
          </div>
        </div>

        <h3 className={styles.title}>Session deactivation</h3>
        <p className={styles.content}>
          {isCurrentSession ? (
            <>
              Do you want to deactivate your <span className={styles.bold}>current session</span> (
              {sessionId})?
            </>
          ) : (
            <>
              Do you want to deactivate the session <span className={styles.bold}>{userId}</span> (
              {sessionId})?
            </>
          )}
        </p>

        <div>
          <Button className={styles.actionCancel} onClick={modal.hide} pill>
            Cancel
          </Button>
          <Button onClick={handleConfirmation} pill danger>
            {isCurrentSession ? "Logout" : "Deactivate"}
          </Button>
        </div>
      </ModalContent>
    </ModalSimple>
  );
});

export const showSessionsConfirmationModal = (props: SessionsConfirmationModalProps) =>
  show(SessionsConfirmationModal, props);
