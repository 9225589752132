import { ReactNode } from "react";

import { RunState } from "types/generated";
import Tree, { TreeBranch, TreeBranchLink } from "ds/components/Tree";

import RunPhaseLogsHooks from "./Hooks";
import RunLogs from "../RunLogs";

type RunPhaseLogsProps = {
  until: number | null;
  stateVersion: number | null;
  state: RunState;
  beforeHooks?: string[] | undefined;
  afterHooks?: string[] | undefined;
  children?: ReactNode;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const RunPhaseLogs = ({ beforeHooks, afterHooks, children, ...rest }: RunPhaseLogsProps) => {
  const logs = <RunLogs {...rest} />;

  if (!beforeHooks?.length && !afterHooks?.length) {
    return (
      <>
        {logs}
        {children}
      </>
    );
  }

  return (
    <Tree padding="0 0 0 medium">
      <RunPhaseLogsHooks type="before" hooks={beforeHooks} />

      <TreeBranch gap="medium" align="start">
        <TreeBranchLink position="top">{logs}</TreeBranchLink>

        {children}
      </TreeBranch>

      <RunPhaseLogsHooks type="after" hooks={afterHooks} />
    </Tree>
  );
};

export default RunPhaseLogs;
