import useTitle from "hooks/useTitle";
import { Module, Stack } from "types/generated";
import { filterOutputReferences } from "utils/config";

import { configArray } from "../configArray";
import ViewConfigMap from "./ConfigMap";
import EnvironmentCollapsibleSection from "../CollapsibleSection";

type EnvironmentViewModeProps = {
  stack: Module | Stack;
};

const EnvironmentViewMode = ({ stack }: EnvironmentViewModeProps) => {
  useTitle(`Environment · ${stack.name}`);

  const [filteredRuntimeConfigElements, outputReferenceElementsMap] = filterOutputReferences(
    stack.runtimeConfig
  );
  const [config, spaceliftConfig] = configArray(filteredRuntimeConfigElements);

  return (
    <div className="environment-runtime__view">
      <ViewConfigMap runtimeConfig={config} />

      <h4 className="environment-runtime__view-sp-env-title">Spacelift environment</h4>
      <ViewConfigMap runtimeConfig={spaceliftConfig} />

      {outputReferenceElementsMap.size > 0 && (
        <>
          <h4 className="environment-runtime__view-sp-env-title">Output references</h4>

          {[...outputReferenceElementsMap].map(([key, config]) => (
            <EnvironmentCollapsibleSection key={key} config={config} sectionName={key} />
          ))}
        </>
      )}
    </div>
  );
};

export default EnvironmentViewMode;
