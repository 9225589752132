import ListEntitiesNew from "components/ListEntitiesNew";
import { ApiKey } from "types/generated";
import Box from "ds/components/Box";
import ListContentWrapper from "components/ListContentWrapper";

import ApiKeysVirtualizedItem from "./VirtualizedItem";
import styles from "./styles.module.css";

type ApiKeysListProps = {
  apiKeys: ApiKey[];
  handleApiKeyOpenDetails: (details: ApiKey) => unknown;
  refetchQueriesOnDelete?: string[];
};

const ApiKeysList = ({
  apiKeys,
  handleApiKeyOpenDetails,
  refetchQueriesOnDelete,
}: ApiKeysListProps) => {
  return (
    <Box direction="column" grow="1">
      <ListContentWrapper className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={apiKeys.length}
          itemProps={{
            apiKeys,
            handleApiKeyOpenDetails,
            refetchQueriesOnDelete,
          }}
          virtualizedItem={ApiKeysVirtualizedItem}
          itemKey={(index) => apiKeys[index].id}
        />
      </ListContentWrapper>
    </Box>
  );
};

export default ApiKeysList;
