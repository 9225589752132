import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ScheduledDeleteInput } from "types/generated";
import { StackContext } from "views/Stack/Context";

import { CREATE_STACK_SCHEDULING_SCHEDULED_DELETE } from "./gql";

const useCreateScheduledDelete = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);

  const [stackScheduledDeleteCreate, { loading }] = useMutation(
    CREATE_STACK_SCHEDULING_SCHEDULED_DELETE,
    {
      refetchQueries: ["GetStackScheduling"],
      awaitRefetchQueries: true,
      onError,
    }
  );

  const createScheduledDelete = useCallback(
    (input: ScheduledDeleteInput, successCallback?: () => void) => {
      stackScheduledDeleteCreate({
        variables: {
          stack: stack.id,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledDeleteCreate) {
            reportSuccess({
              message: `Delete Stack schedule was set up successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stack.id, stackScheduledDeleteCreate]
  );

  return {
    createScheduledDelete,
    loading,
  };
};

export default useCreateScheduledDelete;
