import { Context } from "types/generated";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import ContextListItemDropdownDelete from "./Delete";
import { openContextUpdateDrawer } from "../UpdateDrawer";

type ContextListItemDropdownProps = {
  context: Context;
  canManageContexts: boolean;
};

function ContextListItemDropdown({ context, canManageContexts }: ContextListItemDropdownProps) {
  const handleOnEdit = () => {
    openContextUpdateDrawer({
      context,
      refetchQuery: "SearchContexts",
      id: context.id,
    });
  };

  return (
    <DropdownMenuEllipsis tooltip="Context actions">
      {canManageContexts && <DropdownMenuItem onAction={handleOnEdit}>Edit</DropdownMenuItem>}
      <CopyFieldDropdownMenuItem title="Copy ID" value={context.id} />
      {canManageContexts && (
        <ContextListItemDropdownDelete
          hasAttachedEntities={context.hasAttachedStacks}
          id={context.id}
          name={context.name}
          showConfirmationCallback={close}
        />
      )}
    </DropdownMenuEllipsis>
  );
}

export default ContextListItemDropdown;
