import { gql } from "@apollo/client";

export const SEARCH_STACK_NAME_SUGGESTIONS = gql`
  query NewStackSearchStacksSuggestions($input: SearchSuggestionsInput!) {
    searchStacksSuggestions(input: $input) {
      fields {
        possibleValuesString {
          values
          counts
        }
      }
    }
  }
`;
