import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

const VersionTooltipContent = () => {
  return (
    <>
      <Typography tag="p" variant="p-body3">
        We support strict and loose comparisons as well as ranges defined by SemVer 2.0.0.
      </Typography>
      <Typography tag="p" variant="p-body3">
        E.g:
      </Typography>
      <Typography tag="p" variant="s-body3">
        <code className={styles.code}>~1.2.3</code>
        {` is >=1.2.3 <1.3.0`}
      </Typography>
      <Typography tag="p" variant="s-body3">
        <code className={styles.code}>^1.2.3</code>
        {` is >=1.2.3 <2.0.0`}
      </Typography>
    </>
  );
};

export default VersionTooltipContent;
