import { Unlink } from "components/icons";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";

const VCSDetachedIndicator = () => {
  return (
    <Tooltip
      widthMode="maxWidthXSm"
      textAlign="center"
      on={(props) => <Icon {...props} src={Unlink} color="danger" className={styles.indicator} />}
    >
      Source code integration has been detached
    </Tooltip>
  );
};

export default VCSDetachedIndicator;
