export const transformDeltaCount = (count: number) => {
  if (count > 999 && count < 1000000) {
    return `${Math.floor(count / 1000)}k`;
  }

  if (count > 999999) {
    return `${Math.floor(count / 1000000)}m`;
  }

  return count;
};
