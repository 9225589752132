import { Stack, StackInput } from "types/generated";
import { isSelfHostedDistribution } from "utils/distribution";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";

import { type StackSettingsBehaviorFormFields } from "./Edit";

const isSelfHosted = isSelfHostedDistribution();

const getWorkerPoolInitialData = (workerPool: string | undefined) => {
  if (isSelfHosted && workerPool === WORKER_POOL_SHARED_VALUE) {
    return "";
  }

  return workerPool || "";
};

export const getFormDefaultValues = (stackSettings: Stack): StackSettingsBehaviorFormFields => ({
  workerPool: getWorkerPoolInitialData(stackSettings?.workerPool?.id || WORKER_POOL_SHARED_VALUE),
  runnerImage: stackSettings?.runnerImage || "",
  administrative: stackSettings.administrative,
  githubActionDeploy: stackSettings.githubActionDeploy,
  autodeploy: stackSettings.autodeploy,
  autoretry: stackSettings.autoretry,
  protectFromDeletion: stackSettings.protectFromDeletion,
  localPreviewEnabled: stackSettings.localPreviewEnabled,
  enableWellKnownSecretMasking: stackSettings.enableWellKnownSecretMasking,
  enableSensitiveOutputUpload: stackSettings.enableSensitiveOutputUpload,
});

export const mapCreateStackBehaviorUpdateInput = (
  formData: StackSettingsBehaviorFormFields
): Partial<StackInput> => {
  return {
    ...formData,
    runnerImage: formData.runnerImage || null,
    workerPool: formData.workerPool === WORKER_POOL_SHARED_VALUE ? null : formData.workerPool,
  };
};
