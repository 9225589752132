import { scaleLinear } from "@visx/scale";
import { useMemo } from "react";

import { Datum } from "./types";
import { LINE_CHART_MARGIN } from "./constants";

type UseConfigProps = {
  parentWidth: number;
  parentHeight: number;
  aspectRatio?: number;
  xKey: string;
  data: Datum[];
  items: string[];
};

const useConfig = ({
  parentWidth,
  parentHeight,
  aspectRatio,
  data,
  items,
  xKey,
}: UseConfigProps) => {
  const width = parentWidth;
  const height = aspectRatio ? parentWidth * aspectRatio : parentHeight;

  const xMax = width - LINE_CHART_MARGIN.left - LINE_CHART_MARGIN.right;
  const yMax = height - LINE_CHART_MARGIN.top - LINE_CHART_MARGIN.bottom;

  const xDomain = useMemo(() => data.map((d) => d[xKey]), [data, xKey]);

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [Math.min(...xDomain), Math.max(...xDomain)],
        range: [0, xMax],
      }),
    [xDomain, xMax]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...data.map((d: Datum) => Math.max(...items.map((item) => d[item]))))],
        range: [yMax, 0],
        nice: true,
      }),
    [data, items, yMax]
  );

  return { yScale, xScale, xDomain, xMax, yMax, width, height };
};

export default useConfig;
