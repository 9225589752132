import { useModal, create, show } from "@ebay/nice-modal-react";
import { ReactNode, useCallback } from "react";

import LeaveConfirmationModal from "./index";
import { ConfirmationModalMessage } from "../ConfirmationModal/Message";

type SimpleLeaveConfirmationProps = {
  title?: string;
  message?: ReactNode;
};

const SimpleLeaveConfirmation = create(function SimpleLeaveConfirmation({
  title = "Discard changes",
  message,
}: SimpleLeaveConfirmationProps) {
  const modal = useModal();

  const handleConfirm = useCallback(() => {
    modal.resolve();
    modal.hide();
  }, [modal]);

  return (
    <LeaveConfirmationModal title={title} onConfirm={handleConfirm}>
      {message || (
        <ConfirmationModalMessage>
          Do you really want to leave? <strong>Changes won’t be saved.</strong>
        </ConfirmationModalMessage>
      )}
    </LeaveConfirmationModal>
  );
});

export const showSimpleLeaveConfirmation = (props?: SimpleLeaveConfirmationProps) =>
  show(SimpleLeaveConfirmation, props);
