import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser, ManagedUserUpdateInput } from "types/generated";

import { GET_MANAGED_USER, UPDATE_MANAGED_USER } from "../gql";

const useUpdateUser = (userId: string) => {
  const { onError } = useTypedContext(FlashContext);

  const [updateManagedUserGroup] = useMutation<{ managedUserUpdate: ManagedUser }>(
    UPDATE_MANAGED_USER,
    {
      onError,
      refetchQueries: [
        "GetManagedUsers",
        {
          query: GET_MANAGED_USER,
          variables: {
            id: userId,
          },
        },
      ],
    }
  );

  const onUpdate = (input: ManagedUserUpdateInput) => {
    return updateManagedUserGroup({
      variables: {
        input,
      },
    });
  };

  return { onUpdate };
};

export default useUpdateUser;
