import { gql } from "@apollo/client";

export const UPDATE_POLICY = gql`
  mutation UpdatePolicyInEditDrawer(
    $id: ID!
    $name: String!
    $body: String!
    $labels: [String!]
    $space: ID
    $description: String
  ) {
    policyUpdate(
      id: $id
      name: $name
      body: $body
      labels: $labels
      space: $space
      description: $description
    ) {
      id
      name
      body
      description
      labels
    }
  }
`;
