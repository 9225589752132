import { ReactNode } from "react";

import { TypographyProps } from "ds/components/Typography";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";

type CollapsibleListTitleProps = {
  children: ReactNode;
  variant?: TypographyProps["variant"];
};

const CollapsibleListTitle = ({ children, variant = "p-t5" }: CollapsibleListTitleProps) => {
  return <CollapsiblePanelTitle variant={variant}>{children}</CollapsiblePanelTitle>;
};

export default CollapsibleListTitle;
