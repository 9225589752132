import { Routes, Route } from "react-router-dom-v5-compat";

import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import DocumentationIconButton from "components/DocumentationIconButton";
import NotFoundPage from "components/error/NotFoundPage";
import { Networking } from "components/icons";
import PageLoading from "components/loading/PageLoading";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import TabWithCounter from "ds/components/Tab/WithCounter";
import { getDocsUrl } from "utils/getDocsUrl";
import HideOnScrollProvider from "components/HideOnScroll/HideOnScrollContext";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import {
  NO_PUBLIC_WORKERS_AVAILABLE_MESSAGE,
  PUBLIC_WORKER_POOL_NAME,
  PUBLIC_WORKER_POOL_URL,
} from "../WorkerPool/constants";
import useSearchPublicWorkerPool from "./useSearchPublicWorkerPool";
import PublicWorkerPoolQueuedRuns from "./QueuedRuns";
import PublicWorkerPoolUsedBy from "./UsedBy";

const PublicWorkerPool = () => {
  const { publicWorkerPool, error, stopPolling, isPageLoading } = useSearchPublicWorkerPool();

  useTitle(`Workers · ${PUBLIC_WORKER_POOL_NAME}`);

  useBreadcrumbs([
    {
      title: "Worker pools",
      link: "/worker-pools",
    },
    {
      title: PUBLIC_WORKER_POOL_NAME,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();

    return ErrorContent;
  }

  if (isPageLoading && !publicWorkerPool) {
    return <PageLoading />;
  }

  if (!publicWorkerPool) {
    return <NotFoundPage />;
  }

  const runsCount = publicWorkerPool.schedulableRunsCount;
  const hasWarning = publicWorkerPool.parallelism === 0 && runsCount > 0;

  return (
    <HideOnScrollProvider>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{PUBLIC_WORKER_POOL_NAME}</ViewHeaderTitle>
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <DocumentationIconButton
              href={getDocsUrl("/concepts/worker-pools")}
              tooltipText="Go to worker pools documentation"
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem icon={Networking}>Public</MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>

        <ViewHeaderNavigation>
          <TabWithCounter
            to={`${PUBLIC_WORKER_POOL_URL}/queue`}
            count={runsCount}
            hasException={hasWarning}
            counterExceptionVariant="warning"
            tooltip={hasWarning ? NO_PUBLIC_WORKERS_AVAILABLE_MESSAGE : undefined}
            label="Queue"
            v5Compat
          />

          <TabWithCounter
            to={`${PUBLIC_WORKER_POOL_URL}/used-by`}
            count={publicWorkerPool.usersCount}
            label="Used by"
            v5Compat
          />
        </ViewHeaderNavigation>
      </ViewHeader>

      <Routes>
        <Route path="queue" element={<PublicWorkerPoolQueuedRuns />} />
        <Route path="used-by" element={<PublicWorkerPoolUsedBy />} />
      </Routes>
    </HideOnScrollProvider>
  );
};

export default PublicWorkerPool;
