import useLocalStorage from "@rehooks/local-storage";
import { useEffect, useState } from "react";

type ReducedMotionStorage = {
  configValue: boolean;
  useSystemValue: boolean;
};

type ReducedMotionStateHandler = (value: boolean | "system") => void;

const QUERY = "(prefers-reduced-motion: no-preference)";
const getInitialState = () => !window.matchMedia(QUERY).matches;
const LOCAL_STORAGE_KEY = "prefers-reduced-motion";
const DEFAULT_VALUE: ReducedMotionStorage = {
  configValue: false,
  useSystemValue: true,
};

type UseReducedMotionConfig = ReducedMotionStorage & {
  setReducedMotion: ReducedMotionStateHandler;
};

type UseReducedMotionReturn = [boolean, UseReducedMotionConfig];

// reference: https://www.joshwcomeau.com/react/prefers-reduced-motion/

export function useReducedMotion(): UseReducedMotionReturn {
  const [storage, setStorage] = useLocalStorage<ReducedMotionStorage>(LOCAL_STORAGE_KEY);
  const [useSystemValue, setUseSystemValue] = useState(
    storage?.useSystemValue ?? DEFAULT_VALUE.useSystemValue
  );
  const [systemValue, setSystemValue] = useState(getInitialState);
  const [configValue, setConfigValue] = useState(storage?.configValue ?? DEFAULT_VALUE.configValue);

  // If the user prefers the system value, listen for the system value
  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);

    const listener = (event: MediaQueryListEvent) => {
      setSystemValue(!event.matches);
    };

    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [useSystemValue]);

  const setReducedMotion = (value: boolean | "system") => {
    if (value === "system") {
      setUseSystemValue(true);
      setStorage({ configValue: storage?.configValue ?? false, useSystemValue: true });
    } else {
      setUseSystemValue(false);
      setConfigValue(value);
      setStorage({ configValue: value, useSystemValue: false });
    }
  };

  const reducedMotion = useSystemValue ? systemValue : configValue;

  return [reducedMotion, { configValue, setReducedMotion, useSystemValue }];
}
