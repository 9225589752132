import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";

import { StacksBulkActionsConfirmActionStepProps } from "./types";

type StacksBulkActionsConfirmActionStepDefaultProps = StacksBulkActionsConfirmActionStepProps;

const StacksBulkActionsConfirmActionStepDefault = ({
  action,
  bulkActionsVariant,
  onConfirm,
  onCancel,
}: StacksBulkActionsConfirmActionStepDefaultProps) => {
  return (
    <BulkActionsConfirmActionStepInlineConfirmation
      variant={bulkActionsVariant}
      actionLabel={action.title.toLowerCase()}
      onConfirm={() =>
        onConfirm(
          {},
          {
            view: bulkActionsVariant,
            metadata: {},
          }
        )
      }
      onCancel={onCancel}
    />
  );
};

export default StacksBulkActionsConfirmActionStepDefault;
