import { Controller, useForm } from "react-hook-form";

import BulkActionsConfirmActionStepInlineConfirmation from "components/BulkActions/ConfirmActionStep/InlineConfirmation";
import FormField from "ds/components/Form/Field";
import ToggleField from "ds/components/Form/ToggleField";
import Input from "ds/components/Input";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";

import { BulkActionsVariant } from "../../../../../components/BulkActions/types";
import { StackBulkActionsAnalyticsPayload } from "../types";
import { RunTaskVariables } from "../useStackBulkActions/types";
import { StacksBulkActionsConfirmActionStepProps } from "./types";

type StacksBulkActionsConfirmActionStepRunTaskProps = StacksBulkActionsConfirmActionStepProps & {
  onConfirm: (
    mutationVariables: RunTaskVariables,
    analyticsPayload: StackBulkActionsAnalyticsPayload
  ) => void;
};

const StacksBulkActionsConfirmActionStepRunTask = ({
  bulkActionsVariant,
  onConfirm,
  onCancel,
  action,
}: StacksBulkActionsConfirmActionStepRunTaskProps) => {
  const form = useForm<RunTaskVariables>({
    defaultValues: {
      skipInitialization: false,
    },
    mode: "onChange",
  });

  const inputsColor = bulkActionsVariant === BulkActionsVariant.Drawer ? "default" : "inversed";

  const {
    control,
    register,
    getValues,
    formState: { errors, isValid },
  } = form;

  const handleConfirm = () => {
    const values = getValues();
    onConfirm(values, {
      view: bulkActionsVariant,
      metadata: {
        hasSkipInitialization: !!values.skipInitialization,
      },
    });
  };

  return (
    <>
      <Controller
        name="skipInitialization"
        control={control}
        render={({ field }) => (
          <ToggleField
            tooltipInfo={
              <>
                <TooltipModalTitle>Skip initialization</TooltipModalTitle>
                <TooltipModalBody align="start">
                  <Typography tag="p" variant="p-body3">
                    Initialize the workspace before running this task. This means executing all the
                    pre-initialization hooks as well as running the vendor-specific initialization
                    procedure Toggle this off only if you want to explicitly skip this step.
                  </Typography>
                </TooltipModalBody>
              </>
            }
            color={inputsColor}
            variant="switch"
            title={"Skip initialization"}
            checked={!!field.value}
            onChange={field.onChange}
          />
        )}
      />
      <FormField noMargin color={inputsColor} label="Command" error={errors?.command?.message}>
        {({ ariaInputProps }) => (
          <Input
            color={inputsColor}
            error={!!errors?.command}
            {...register("command", { required: "Command is required." })}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <BulkActionsConfirmActionStepInlineConfirmation
        variant={bulkActionsVariant}
        actionLabel={action.title.toLowerCase()}
        confirmDisabled={!isValid}
        onConfirm={handleConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default StacksBulkActionsConfirmActionStepRunTask;
