import { useEffect } from "react";

import { StackState, VersionState } from "types/generated";

type States = StackState | VersionState;

enum Color {
  Blue = "blue",
  Red = "red",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray",
}

const MAPPING: Record<States, string> = {
  [StackState.Preparing]: Color.Blue,
  [StackState.PreparingApply]: Color.Blue,
  [StackState.PreparingReplan]: Color.Blue,
  [StackState.Initializing]: Color.Blue,
  [StackState.Planning]: Color.Blue,
  [StackState.Failed]: Color.Red,
  [StackState.Finished]: Color.Green,
  [StackState.Unconfirmed]: Color.Yellow,
  [StackState.Discarded]: Color.Red,
  [StackState.Confirmed]: Color.Gray,
  [StackState.Applying]: Color.Blue,
  [StackState.Stopped]: Color.Red,
  [StackState.None]: Color.Gray,
  [StackState.Destroying]: Color.Blue,
  [StackState.ReplanRequested]: Color.Gray,
  [VersionState.Active]: Color.Green,
  [VersionState.Pending]: Color.Blue,
};

const useFavicon = (state?: States) => {
  useEffect(() => {
    const link =
      document.querySelector<HTMLLinkElement>("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `/favicons/${(state && MAPPING[state]) || "plain"}.ico`;
    document.getElementsByTagName("head")[0].appendChild(link);

    return () => {
      link.href = "/favicons/plain.ico";
    };
  }, [state]);
};

export default useFavicon;
