import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import { getDocsUrl } from "utils/getDocsUrl";

const Documentation = () => (
  <Typography tag="p" variant="p-body2">
    Stack is one of the core concepts in Spacelift. A stack is an isolated, independent entity and
    the choice of the word mirrors products like{" "}
    <TextLink href="https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacks.html">
      AWS CloudFormation
    </TextLink>
    , or <TextLink href="https://www.pulumi.com/docs/concepts/stack/">Pulumi</TextLink> (of which we
    support both). You can think about a stack as a combination of source code, current state of the
    managed infrastructure (eg. Terraform state file) and configuration in the form of{" "}
    <TextLink href={getDocsUrl("/concepts/configuration/environment")}>environment</TextLink>{" "}
    variables and mounted files.
  </Typography>
);

export default Documentation;
