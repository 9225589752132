import { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import moment from "moment";
import cx from "classnames";
import { useSearchParams } from "react-router-dom-v5-compat";

import Tooltip from "ds/components/Tooltip";
import { CrossNew } from "components/icons";
import Select from "components/select/Select";
import formStyles from "components/FormDefault/styles.module.css";
import Icon from "ds/components/Icon";

import Chart from "../Chart";
import Filters from "../Filters";
import { FilterOption } from "../Filters/types";
import SideBar, { ResourcesSideBarProps } from "../Sidebar";
import {
  generateAccountEntities,
  generateFilters,
  generateGroupByOptions,
  generateKeyPossibleValues,
  getDefaultDateRange,
} from "../helpers";
import { AccountRunsData, RunEntity, RunsFilterValues } from "../types";
import Datepicker from "./Datepicker";
import "views/Account/Resources/Wrapper/styles.css";
import styles from "./styles.module.css";
import RunsBulkActions from "../BulkActions";

type DateRange = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  label: string;
};

type RunsWrapperProps = {
  data: Partial<AccountRunsData>;
  search: string;
  dateRange: DateRange;
  setDateRange: (value: DateRange) => void;
  isAccountWide?: boolean;
  refetchData: () => void;
};

const RunsWrapper = (props: RunsWrapperProps) => {
  const { data, search, dateRange, setDateRange, isAccountWide, refetchData } = props;

  const [filterField, setFilterField] = useState([""]);
  const [filterValues, setFilterValues] = useState<RunsFilterValues>([[]]);
  const [groupByValue, setGroupByValue] = useState("trigger");
  const [entityDetails, setEntityDetails] = useState<ResourcesSideBarProps["entityDetails"]>(
    {} as ResourcesSideBarProps["entityDetails"]
  );
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [zoomTarget, setZoomTarget] = useState<string | null>("");
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleItems, transferVisibleItems] = useState<Array<RunEntity>>([]);

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params: {
      filterFields?: string;
      filterValues?: string;
      groupByValue?: string;
      zoomTarget?: string;
      dateRange?: string;
    } = queryString.parse(search);

    if (Object.keys(params).length === 0) return;

    if (params.filterFields) {
      try {
        setFilterField(JSON.parse(atob(params.filterFields)));
      } catch {
        // Filter fields are malformed, reset to default
        setFilterField([""]);
      }
    }

    if (params.filterValues) {
      try {
        setFilterValues(JSON.parse(decodeURIComponent(atob(params.filterValues))));
      } catch {
        // Filter values are malformed, reset to default
        setFilterValues([[]]);
      }
    }

    if (params.zoomTarget) {
      try {
        setZoomTarget(JSON.parse(atob(params.zoomTarget)));
      } catch {
        // Zoom target is malformed, reset to default
        setZoomTarget("");
      }
    }

    if (params.dateRange) {
      try {
        const dateRangeValue = JSON.parse(atob(params.dateRange));

        setDateRange({
          startDate: moment(moment.unix(dateRangeValue.startDate).format("YYYY-MM-DD HH:mm:ss")),
          endDate: moment(moment.unix(dateRangeValue.endDate).format("YYYY-MM-DD HH:mm:ss")),
          label: `${moment.unix(dateRangeValue.startDate).format("DD-MM-YYYY HH:mm")} - ${moment
            .unix(dateRangeValue.endDate)
            .format("DD-MM-YYYY HH:mm")}`,
        });
      } catch {
        // Date range is malformed, reset to default
        setDateRange(getDefaultDateRange());
      }
    }

    setGroupByValue(params.groupByValue || "trigger");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entities = useMemo(() => generateAccountEntities(data), [data]);

  const filters = useMemo(
    () => generateFilters(filterField, filterValues),
    [filterField, filterValues]
  );

  const keyPossibleValues = useMemo(
    () => generateKeyPossibleValues(filterField, filters, entities),
    [filterField, filters, entities]
  );
  const groupByOptions = generateGroupByOptions();

  const setParams = useCallback(
    (
      fields: Array<string>,
      values: Array<Array<{ label: string; value: string }>>,
      sort?: string,
      zoomTargetChange?: string,
      dateRangeChange?: DateRange
    ) => {
      const dateRangeValue = dateRangeChange || dateRange;
      const dateRangeParam = {
        startDate: dateRangeValue.startDate.unix(),
        endDate: dateRangeValue.endDate.unix(),
        label: dateRangeValue.label,
      };

      const newSearchParams = new URLSearchParams({
        filterValues: btoa(encodeURIComponent(JSON.stringify(values))),
        filterFields: btoa(JSON.stringify(fields)),
        groupByValue: sort || groupByValue,
        zoomTarget: btoa(JSON.stringify(zoomTargetChange || zoomTarget)),
        dateRange: btoa(JSON.stringify(dateRangeParam)),
      });

      setSearchParams(newSearchParams);
    },
    [dateRange, setSearchParams, groupByValue, zoomTarget]
  );

  const addEmptyFilter = () => {
    setFilterField((filterField) => [...filterField, ""]);
    setFilterValues((filterValues) => [...filterValues, []]);
  };

  const handleFilterAdd = useCallback(
    (key: string, value: string) => {
      const [lastFilterField] = filterField.slice(-1);
      if (lastFilterField === "") {
        const newFilterField = filterField.map((item, index) => {
          return index + 1 === filterField.length ? key : item;
        });
        setFilterField(newFilterField);

        const newFilterValues = filterValues.map((item, index) => {
          return index + 1 === filterValues.length ? [{ label: value, value }] : item;
        });

        setFilterValues(newFilterValues);

        setParams(newFilterField, newFilterValues);
      } else {
        setFilterField((filterField) => [...filterField, key]);
        setFilterValues((filterValues) => [...filterValues, [{ label: value, value }]]);
        setParams([...filterField, key], [...filterValues, [{ label: value, value }]]);
      }
    },
    [filterField, filterValues, setParams]
  );

  const handleFilterChange = useCallback(
    (option: FilterOption | null, index: number) => {
      const newFilterField = [...filterField];
      const newFilterValues = [...filterValues];

      if (option === null && filterField.length > 1) {
        // Cleared - remove this selector. There's an empty selector following us.

        newFilterField.splice(index, 1);
        newFilterValues.splice(index, 1);
      } else {
        newFilterField[index] = option?.value || "";
        if (newFilterField[index] !== filterField[index]) {
          // if the option changed, clear the values selector
          newFilterValues[index] = [];
        }
      }

      setFilterField(newFilterField);
      setFilterValues(newFilterValues);
      setParams(newFilterField, newFilterValues);
    },
    [filterField, filterValues, setParams]
  );

  const handleMultiFilterChange = useCallback(
    (newValues: Array<FilterOption>, index: number) => {
      const newFilterValues = filterValues.slice();
      newFilterValues[index] = newValues;

      setFilterValues(newFilterValues);
      setParams(filterField, newFilterValues);
    },
    [filterValues, filterField, setParams]
  );

  const handleGroupByChange = useCallback(
    ({ value }: { value: string }) => {
      setGroupByValue(value);
      setParams(filterField, filterValues, value);
    },
    [filterField, filterValues, setParams]
  );

  const handleZoomTargetChange = useCallback(
    (value: string) => {
      setZoomTarget(value);
      setParams(filterField, filterValues, undefined, value);
    },
    [filterField, filterValues, setParams]
  );

  const toggleFullScreen = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  const handleDateRangeChange = useCallback(
    (startDate: moment.Moment, endDate: moment.Moment, label: string) => {
      const value = {
        startDate: startDate,
        endDate: endDate,
        label: label,
      };
      setDateRange(value);

      setParams(filterField, filterValues, undefined, undefined, value);
    },
    [filterField, filterValues, setDateRange, setParams]
  );

  const shouldShowAddButton = useMemo(() => {
    return filterField.slice(-1)[0] !== "" && filterValues.slice(-1)[0].length > 0;
  }, [filterField, filterValues]);

  const wrapperClass = cx(styles.wrapper, {
    "resources--fullscreen": fullScreen,
  });

  const handleBulkActionsFinish = async () => {
    await refetchData?.();
  };

  const bulkActionItems = useMemo(() => {
    return visibleItems.filter((item) => !item.isModule);
  }, [visibleItems]);

  const bulkActionsSet = useMemo(() => {
    return new Set(bulkActionItems.map((item) => item.id));
  }, [bulkActionItems]);

  const bulkActionsMap = useMemo(() => {
    return new Map(bulkActionItems.map((item) => [item.id, item]));
  }, [bulkActionItems]);

  return (
    <div className={wrapperClass}>
      <SideBar
        entityDetails={entityDetails}
        fullScreen={fullScreen}
        setMenuVisible={setMenuVisible}
        setZoomTarget={setZoomTarget}
        isVisible={isMenuVisible}
        handleFilterNewChange={handleFilterAdd}
      />
      <div>
        {fullScreen && (
          <div className="resources__close">
            <Tooltip
              placement="bottom"
              on={(props) => <Icon {...props} src={CrossNew} onClick={toggleFullScreen} />}
            >
              Hide full screen
            </Tooltip>
          </div>
        )}
        <div className="resources-controls">
          <div className="resources-filters resources-filters--run">
            <div className="resources__label">Filter by: </div>
            <div className="resources-filters__items">
              {filterField.map((_value, index) => (
                <Filters
                  key={`filter-${index}`}
                  keyPossibleValues={keyPossibleValues[index]}
                  filterField={filterField}
                  filterValues={filterValues}
                  index={index}
                  handleFilterChange={handleFilterChange}
                  handleMultiFilterChange={handleMultiFilterChange}
                />
              ))}
              {shouldShowAddButton && (
                <button className="resources-filters__button" onClick={addEmptyFilter}>
                  + Add filter
                </button>
              )}
            </div>
          </div>
          <Datepicker dateRange={dateRange} handleDateRangeChange={handleDateRangeChange} />
          <div className="resources-group-by">
            <div className="resources__label">Group by:</div>
            <Select
              className={cx(formStyles.input, styles.inputMargin)}
              value={groupByOptions.find((option) => option.value === groupByValue)}
              options={groupByOptions}
              onChange={handleGroupByChange}
              placeholder={"Group by:"}
            />
          </div>
        </div>
        <Chart
          isAccountWide={isAccountWide}
          data={entities}
          groupByKey={groupByValue}
          filters={filters}
          setEntityDetails={setEntityDetails}
          setMenuVisible={setMenuVisible}
          zoomTarget={zoomTarget}
          setZoomTarget={handleZoomTargetChange}
          fullScreen={fullScreen}
          toggleFullScreen={toggleFullScreen}
          handleFilterAdd={handleFilterAdd}
          transferVisibleItems={transferVisibleItems}
        />

        <RunsBulkActions
          runsMap={bulkActionsMap}
          selectedSet={bulkActionsSet}
          onFinish={handleBulkActionsFinish}
        />
      </div>
    </div>
  );
};

export default RunsWrapper;
