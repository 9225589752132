import cx from "classnames";

import Box from "ds/components/Box";
import SegmentedControl, { SegmentedControlProps } from "ds/components/SegmentedControl";

import styles from "./styles.module.css";

type DropdownFooterProps = SegmentedControlProps;

const DropdownFooterSwitch = ({ className, onChange, value, options }: DropdownFooterProps) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      fullWidth
      className={cx(styles.footer, className)}
    >
      <SegmentedControl onChange={onChange} value={value} options={options} />
    </Box>
  );
};

export default DropdownFooterSwitch;
