import { gql } from "@apollo/client";

import actionFields from "views/shared/run/ActionButtons/ActionFragment";

export const GET_RUN = gql`
  query GetRun($stackId: ID!, $runId: ID!) {
    stack(id: $stackId) {
      id
      blocker {
        id
        state
        type
      }
      isDisabled
      managesStateFile
      run(id: $runId) {
        ...actionFields
        finished
        isPrioritized
        retryBlocker
        canRetry
        branch
        promoteBlocker
        command
        comments {
          __typename
          body
          createdAt
          username
        }
        createdAt
        commit {
          authorLogin
          authorName
          hash
          timestamp
          url
        }
        delta {
          addCount
          changeCount
          deleteCount
          resources
          importCount
          forgetCount
          moveCount
          ignoreCount
          okCount
          rescueCount
          skipCount
          unreachableCount
          failedCount
        }
        dependsOn {
          id
          state
          runId
          runUpdatedAt
          stackId
        }
        driftDetection
        expired
        externalDependencies {
          id
          status
          completedAt
          createdAt
        }
        isMostRecent
        needsApproval
        policyReceipts {
          id
          createdAt
          flags
          policyName
          policyType
          policySlug
          policyDeleted
          outcome
          sampled
          sampleExpired
        }
        reviews {
          id
          author
          decision
          note
          timestamp
        }
        runtimeConfig {
          afterApply
          afterDestroy
          afterInit
          afterPerform
          afterPlan
          afterRun
          beforeApply
          beforeDestroy
          beforeInit
          beforePerform
          beforePlan
          terraform {
            version
            workflowTool
          }
          terragrunt {
            terraformVersion
            terragruntVersion
            tool
          }
          yaml
        }
        customRuntimeConfig {
          yaml
        }
        planPoliciesOutcomes {
          id
          deny
          warn
          createdAt
        }
        supportsDetailedResources
        title
        triggeredBy
        type
        next {
          id
        }
        previous {
          id
        }
      }
      workerPool {
        id
        name
        busyWorkers
        pendingRuns
        workers {
          id
          busy
          drained
        }
      }
    }
  }
  ${actionFields}
`;

export const GET_NEXT_RUN_INFO = gql`
  query GetNextRunInfo($stackId: ID!, $runId: ID!) {
    stack(id: $stackId) {
      id
      run(id: $runId) {
        id
        type
        next {
          id
        }
      }
    }
  }
`;
