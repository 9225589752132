import Link from "ds/components/Link";
import { VcsProvider } from "types/generated";

type CommitAuthorLinkProps = {
  name: string;
  provider: VcsProvider;
  login?: string;
};

const CommitAuthorLink = ({ login, name, provider }: CommitAuthorLinkProps) => {
  // TODO(runHistoryRedesign): check if we can support other providers
  if (login && provider === VcsProvider.Github) {
    return (
      <Link href={`https://github.com/${login}`} rel="noopener noreferrer" target="_blank">
        {login}
      </Link>
    );
  }

  return <>{name}</>;
};

export default CommitAuthorLink;
