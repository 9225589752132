import MarkdownGithub from "components/markdown/MarkdownGithub";
import PageInfo from "components/PageWrapper/Info";

import ModuleVersionScrollableWrapper from "../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../components/PageIntoTitle";

type ModuleVersionChangelogProps = {
  content: string | undefined;
};

const ModuleVersionChangelog = ({ content }: ModuleVersionChangelogProps) => {
  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Changelog"
            tooltip="Module's changelog generated by your CHANGELOG.md file."
          />
        }
      />
      <ModuleVersionScrollableWrapper surfaceColor="primary">
        <MarkdownGithub markup={content || "No changelog available"} />
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionChangelog;
