import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import useTypedFlags from "hooks/useTypedFlags";
import { GithubIntegration, VcsProvider } from "types/generated";

import VCSChecksToggle from "../../components/VCSChecksToggle";
import VCSIntegrationsDocumentationButton from "../../components/DocumentationButton";
import useCloseFullScreenModal from "../../useCloseFullScreenModal";
import { getFormDefaultValues, mapUpdateGitHubIntegrationInputValues } from "./helpers";
import { ManageGitHubManualFormFields } from "./types";
import useUpdateGitHubIntegration from "./useUpdateGitHubIntegration";
import { BUILT_IN_GITHUB_INTEGRATION_NAME } from "../../List/constants";

type VCSIntegrationsManageGitHubManualProps = {
  integration?: GithubIntegration;
  onIsDirtyChange: (value: boolean) => void;
};

const VCSIntegrationsManageGitHubManual = ({
  integration,
  onIsDirtyChange,
}: VCSIntegrationsManageGitHubManualProps) => {
  const { aggregatedVcsChecks } = useTypedFlags();

  const { handleOnClose } = useCloseFullScreenModal();

  const manageGitHubManualForm = useForm<ManageGitHubManualFormFields>({
    defaultValues: getFormDefaultValues(integration),
    mode: "onChange",
  });

  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = manageGitHubManualForm;

  const { updateGitHubIntegration, loading: updateLoading } = useUpdateGitHubIntegration();

  const handleSetup = useCallback(
    (formData: ManageGitHubManualFormFields) => {
      updateGitHubIntegration(mapUpdateGitHubIntegrationInputValues(formData), handleOnClose);
    },
    [handleOnClose, updateGitHubIntegration]
  );

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <FormProvider {...manageGitHubManualForm}>
      <FullScreenModalBody>
        <FormField
          label="Integration name"
          helperText="The integration name is unique and cannot be changed"
          noMargin
        >
          {({ ariaInputProps }) => (
            <Input value={BUILT_IN_GITHUB_INTEGRATION_NAME} disabled {...ariaInputProps} />
          )}
        </FormField>

        {aggregatedVcsChecks && <VCSChecksToggle />}
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.Github} />

        <Button
          onClick={handleSubmit(handleSetup)}
          variant="primary"
          disabled={!isValid || !isDirty}
          loading={updateLoading}
        >
          Save
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageGitHubManual;
