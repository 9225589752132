import DescriptionDetails from "components/DescriptionDetails";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import { MenuSpaces } from "components/icons";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { VcsIntegration } from "types/generated";
import Box from "ds/components/Box";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldViewText from "components/FormFields/ViewText";

import { getIntegrationDetails } from "./helpers";
import VCSProviderIcon from "../ProviderIcon";
import { getEditIntegrationURL } from "../../helpers";

type VCSIntegrationDetailsProps = {
  isActive: boolean;
  item?: VcsIntegration;
  canEdit: boolean;
  onOpenFullDescription: (item: VcsIntegration) => void;
  onCloseDetails: () => void;
};

const VCSIntegrationDetails = ({
  isActive,
  item,
  canEdit,
  onOpenFullDescription,
  onCloseDetails,
}: VCSIntegrationDetailsProps) => {
  const handleOpenFullDescription = () => {
    if (item) {
      onOpenFullDescription(item);
    }
  };

  return (
    <Drawer position="absoluteRight" visible={isActive}>
      {item && (
        <>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Source code details" />
            <DrawerCloseIcon handleCloseDrawer={onCloseDetails} />
          </DrawerHeader>

          <DrawerBody>
            <FormField label="Provider">
              <Box gap="medium">
                <VCSProviderIcon provider={item.provider} />

                <Typography tag="span" variant="p-body2">
                  {HUMANIZE_PROVIDER[item.provider]}
                </Typography>
              </Box>
            </FormField>

            <FormFieldViewText label="Integration name" value={item.name} />

            {!item.isDefault && (
              <FormField label="Space">
                <MetaInfoListItem
                  icon={MenuSpaces}
                  linkText={item.space.name}
                  href={`/spaces/${item.space.id}`}
                />
              </FormField>
            )}

            {item.details && getIntegrationDetails(item.details)}

            <FormField label="Labels">
              <TagsList
                tags={item.labels}
                {...(canEdit && { addTagsUrl: getEditIntegrationURL(item.provider, item.id) })}
                alwaysExpanded
                withEmptyPlaceholder
              />
            </FormField>

            <DescriptionDetails
              description={item.description}
              onOpenFullDescription={handleOpenFullDescription}
              {...(canEdit && { addDescriptionUrl: getEditIntegrationURL(item.provider, item.id) })}
            />
          </DrawerBody>
        </>
      )}
    </Drawer>
  );
};

export default VCSIntegrationDetails;
