import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { GitlabIntegration, Maybe } from "types/generated";

import { GET_GITLAB_INTEGRATION } from "./gql";

const useGetGitLabIntegration = (id: Maybe<string>) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error } = useQuery<{ gitlabIntegration: GitlabIntegration }>(
    GET_GITLAB_INTEGRATION,
    {
      variables: {
        id,
      },
      onError,
      fetchPolicy: "no-cache",
    }
  );

  return {
    integration: data?.gitlabIntegration,
    isPageLoading: loading && !data?.gitlabIntegration,
    error,
  };
};

export default useGetGitLabIntegration;
