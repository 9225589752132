import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type WebhookDropdownProps = {
  onEditClick: () => void;
  onDetailsClick: () => void;
  canManageModule: boolean;
  endpoint: string;
  id: string;
  refetchQueriesOnDelete?: string[];
  moduleId: string;
};

const WebhookDropdown = ({
  onEditClick,
  refetchQueriesOnDelete,
  endpoint,
  id,
  moduleId,
  onDetailsClick,
  canManageModule,
}: WebhookDropdownProps) => {
  const handleDeleteClick = () => {
    showDeleteConfirmation({ endpoint, id, refetchQueriesOnDelete, moduleId });
  };

  return (
    <DropdownMenuEllipsis tooltip="Webhook actions">
      <DropdownMenuItem onAction={onDetailsClick}>Details</DropdownMenuItem>
      {canManageModule && <DropdownMenuItem onAction={onEditClick}>Edit</DropdownMenuItem>}
      {canManageModule && (
        <DropdownMenuItem onAction={handleDeleteClick} danger>
          Delete
        </DropdownMenuItem>
      )}
    </DropdownMenuEllipsis>
  );
};

export default WebhookDropdown;
