import { useNavigate } from "react-router-dom-v5-compat";

import ButtonIcon from "ds/components/ButtonIcon";
import { ArrowThin } from "components/icons";
import useTypedContext from "hooks/useTypedContext";

import { BreadcrumbsContextData } from "./Context";
import { showBreadcrumbsConfirmationModal } from "./ConfirmationModal";
import styles from "./styles.module.css";

type BreadcrumbStepBackProps = {
  confirmation?: string;
};

const BreadcrumbStepBack = ({ confirmation }: BreadcrumbStepBackProps) => {
  const items = useTypedContext(BreadcrumbsContextData);
  const navigate = useNavigate();

  if (items.length <= 0) return null;

  const link = items?.[items.length - 2]?.link || "/";

  const handleStepBackConfirm = () => {
    showBreadcrumbsConfirmationModal({
      content: confirmation,
      onConfirm: () => navigate(link),
    });
  };

  if (confirmation) {
    return (
      <ButtonIcon
        icon={ArrowThin}
        iconRotate="270"
        onClick={handleStepBackConfirm}
        className={styles.stepBack}
        variant="ghost"
      >
        Go back
      </ButtonIcon>
    );
  }

  return (
    <ButtonIcon
      icon={ArrowThin}
      iconRotate="270"
      to={link}
      className={styles.stepBack}
      variant="ghost"
    >
      Go back
    </ButtonIcon>
  );
};

export default BreadcrumbStepBack;
