import { useCallback } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

/**
 * useFormValidations
 * @param builderForm result of useForm hook
 * @returns Promise<boolean> - true if form is valid, false otherwise
 */
export const useFormValidations = <T extends FieldValues>(
  builderForm: UseFormReturn<T>
): (() => Promise<boolean>) => {
  const { trigger } = builderForm;

  const runFormValidations = useCallback(() => {
    // trigger form validations. Undefined triggers for all fields.
    // shouldFocus is set to true to focus on the first invalid field
    return trigger(undefined, { shouldFocus: true });
  }, [trigger]);

  return runFormValidations;
};
