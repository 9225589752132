import { BillingTierFeature } from "types/generated";
import useTierFeature from "views/Account/hooks/useTierFeature";

import StackIgnoredRunsFeatureAvailable from "./FeatureAvailable";
import StackIgnoredRunsFeatureGate from "./FeatureGate";

const StackIgnoredRuns = () => {
  const isIgnoredRunsEnabled = useTierFeature(BillingTierFeature.RunIgnoredTriggers);

  return isIgnoredRunsEnabled ? (
    <StackIgnoredRunsFeatureAvailable />
  ) : (
    <StackIgnoredRunsFeatureGate />
  );
};

export default StackIgnoredRuns;
