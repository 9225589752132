import { SpaceAccessLevel } from "types/generated";

type SpaceAccessField = {
  space: string;
  spaceAccessLevel: SpaceAccessLevel;
};

export type UpdateGroupIntegrationFields = {
  spaces: SpaceAccessField[];
};

export enum GroupsIntegrationsDrawerTabs {
  Spaces = "spaces",
}
