import { useCallback, useEffect, useRef } from "react";
import * as d3 from "d3";
import cx from "classnames";

import ButtonIcon from "ds/components/ButtonIcon";
import useOutsideClick from "hooks/useOutsideClick";
import { CrossNew } from "components/icons";
import Box from "ds/components/Box";

import Wrapper from "./Wrapper";
import { ResourcesSideBarProps } from "./types";
import styles from "./styles.module.css";
import "./styles.css";

const ResourcesSideBar = (props: ResourcesSideBarProps) => {
  const { entityDetails, fullScreen, handleFilterNewChange, setMenuVisible, isVisible, stackId } =
    props;

  const wrapperRef = useRef(null);

  const closeMenu = useCallback(() => {
    setMenuVisible(false);
    d3.select(".d3-entity-selected").classed("d3-entity-selected", false);
  }, [setMenuVisible]);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        closeMenu();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [closeMenu]);

  useOutsideClick(wrapperRef, closeMenu);

  const sidebarClass = cx("resources-sidebar", {
    "resources-sidebar--visible": isVisible,
    "resources-sidebar--fullscreen": fullScreen,
  });

  return (
    <div ref={wrapperRef} className={sidebarClass}>
      <Box relative padding="large">
        <ButtonIcon
          className={styles.closeAction}
          icon={CrossNew}
          onClick={closeMenu}
          variant="ghost"
        >
          Close
        </ButtonIcon>
      </Box>
      {isVisible && (
        <Wrapper
          entityDetails={entityDetails}
          handleFilterNewChange={handleFilterNewChange}
          stackId={stackId}
        />
      )}
    </div>
  );
};

export default ResourcesSideBar;
