import Timestamp from "components/time/Timestamp";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";

type FormFieldTimestampProps = {
  timestamp: number;
  label: string;
  noMargin?: boolean;
};

const FormFieldTimestamp = ({ timestamp, label, noMargin }: FormFieldTimestampProps) => {
  return (
    <FormField label={label} noMargin={noMargin}>
      <Typography tag="span" variant="p-body2">
        <Timestamp timestamp={timestamp} />
      </Typography>
    </FormField>
  );
};

export default FormFieldTimestamp;
