import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { WorkerPool } from "types/generated";

import { UPDATE_WORKER_POOL } from "../gql";
import { CreateWorkerPoolFields } from "./types";

const useEditWorkerPool = (refetchQuery?: string) => {
  const { onError } = useTypedContext(FlashContext);

  const [updateWorkerPool] = useMutation<{ workerPoolUpdate: WorkerPool }>(UPDATE_WORKER_POOL, {
    onError,
    ...(refetchQuery && { refetchQueries: [refetchQuery] }),
  });

  const onEdit = (formData: CreateWorkerPoolFields, workerPool: WorkerPool) => {
    return updateWorkerPool({
      variables: {
        id: workerPool.id,
        name: formData.name,
        description: formData.description,
        labels: formData.labels.map((item) => item.value),
        space: formData.space,
      },
    });
  };

  return { onEdit };
};

export default useEditWorkerPool;
