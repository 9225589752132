import { ClaimMapping } from "types/generated";

import { SLACK_MEMBER_ID_CLAIM_NAME, SLACK_MEMBER_ID_CLAIM_VALUE } from "./constants";

export const shouldUseSlackMemberIDFromIdp = (claimMapping?: ClaimMapping) => {
  if (!claimMapping) {
    return false;
  }

  return claimMapping.entries.some(
    (entry) =>
      entry.name === SLACK_MEMBER_ID_CLAIM_NAME && entry.value === SLACK_MEMBER_ID_CLAIM_VALUE
  );
};
