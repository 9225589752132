import { Pages } from "./types";

export const enum AnalyticsPagePersonal {
  Personal = "Personal",
  PersonalApiKeys = "PersonalApiKeys",
  PersonalIdpGroups = "PersonalIdpGroups",
  PersonalSpaces = "PersonalSpaces",
  PersonalSidebarCustomization = "PersonalSidebarCustomization",
}

const personalSettings = "Personal settings";

export const pagesPersonal: Pages<AnalyticsPagePersonal> = {
  [AnalyticsPagePersonal.PersonalSidebarCustomization]: [personalSettings, "Sidebar Customization"],
  [AnalyticsPagePersonal.Personal]: [personalSettings],
  [AnalyticsPagePersonal.PersonalIdpGroups]: [personalSettings, "IdP Groups"],
  [AnalyticsPagePersonal.PersonalSpaces]: [personalSettings, "Spaces"],
  [AnalyticsPagePersonal.PersonalApiKeys]: [personalSettings, "API keys"],
};
