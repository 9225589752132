import { gql } from "@apollo/client";

export const GET_ATTACHABLE_CLOUD_INTEGRATIONS = gql`
  query GetAttachableCloudIntegrations($spaceId: ID!) {
    attachableAzureIntegrationsInSpace(id: $spaceId) {
      id
      name
      defaultSubscriptionId
    }
    attachableAwsIntegrationsInSpace(id: $spaceId) {
      id
      name
      legacy
    }
  }
`;

export const GET_ATTACHED_STACK_INTEGRATIONS = gql`
  query GetAttachedStackIntegrations($stackId: ID!) {
    stack(id: $stackId) {
      integrations {
        awsV2 {
          id
          read
          write
          durationSeconds
          externalId
          name
          roleArn
        }
        azure {
          id
          read
          write
          defaultSubscriptionId
          subscriptionId
          integrationName
        }
      }
    }
  }
`;

export const ATTACH_AWS_INTEGRATION = gql`
  mutation NewStackAttachAwsIntegration(
    $integrationId: ID!
    $stackId: ID!
    $read: Boolean!
    $write: Boolean!
  ) {
    awsIntegrationAttach(id: $integrationId, stack: $stackId, read: $read, write: $write) {
      id
    }
  }
`;

export const ATTACH_AZURE_INTEGRATION = gql`
  mutation NewStackAttachAzureIntegration(
    $integrationId: ID!
    $stackId: ID!
    $read: Boolean!
    $write: Boolean!
    $subscriptionId: String
  ) {
    azureIntegrationAttach(
      id: $integrationId
      stack: $stackId
      read: $read
      write: $write
      subscriptionId: $subscriptionId
    ) {
      id
    }
  }
`;

export const DETACH_AWS_INTEGRATION = gql`
  mutation NewStackDetachAwsIntegration($attachmentId: ID!) {
    awsIntegrationDetach(id: $attachmentId) {
      id
    }
  }
`;

export const DETACH_AZURE_INTEGRATION = gql`
  mutation NewStackDetachAzureIntegration($attachmentId: ID!) {
    azureIntegrationDetach(id: $attachmentId) {
      id
    }
  }
`;
