import Box, { BoxProps } from "ds/components/Box";

type StackSettingsFormWrapperProps = {
  withLimitWidth?: boolean;
} & Omit<BoxProps, "limitWidth" | "fullWidth">;

const StackSettingsFormWrapper = ({
  children,
  withLimitWidth = true,
  padding = "x-large",
  gap = "x-large",
  ...restProps
}: StackSettingsFormWrapperProps) => {
  return (
    <Box
      direction="column"
      padding={padding}
      gap={gap}
      grow="1"
      fullWidth={!withLimitWidth}
      limitWidth={withLimitWidth ? "medium" : undefined}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default StackSettingsFormWrapper;
