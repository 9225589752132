import { gql } from "@apollo/client";

export const DELETE_WEBHOOK_INTEGRATION = gql`
  mutation DeleteWebhookIntegration($entityId: ID!, $id: ID!) {
    webhooksIntegrationDelete(id: $id, stack: $entityId) {
      id
    }
  }
`;

export const CREATE_WEBHOOK_INTEGRATION = gql`
  mutation CreateWebhookIntegration($entityId: ID!, $input: WebhooksIntegrationInput!) {
    webhooksIntegrationCreate(stack: $entityId, input: $input) {
      id
    }
  }
`;

export const UPDATE_WEBHOOK_INTEGRATION = gql`
  mutation UpdateWebhookIntegration($id: ID!, $entityId: ID!, $input: WebhooksIntegrationInput!) {
    webhooksIntegrationUpdate(id: $id, stack: $entityId, input: $input) {
      id
    }
  }
`;
