export const searchBlueprintSuggestionsDictionary: Partial<Record<BlueprintSuggestions, string>> = {
  label: "labels",
};

export enum BlueprintSuggestions {
  Label = "label",
  Folder = "folder",
  State = "state",
  Space = "space",
}
