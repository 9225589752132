import { ConfigNode } from "../types";
import { ITEM_SIZE, ITEM_SPACE, ITEMS_PER_ROW } from "./contstants";

export const getNodeHeight = (config: ConfigNode<string>) => {
  if ("group" in config) {
    return (
      Math.ceil((config.group as Array<unknown>).length / ITEMS_PER_ROW) *
        (ITEM_SIZE + ITEM_SPACE) +
      60
    );
  }

  return 60;
};
