import { SortableTableColumn } from "components/SortableTable";

export const FILTERS_ORDER_SETTINGS_KEY_VERSION_CONSUMERS = "version-consumers";

export const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
  {
    value: "space",
    label: "Space",
  },
];

export const COLUMN_ORDER = "2fr 1fr";
