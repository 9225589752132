import { gql } from "@apollo/client";

export const GET_SSO_SETTINGS = gql`
  query GetSSOSettings {
    managedUsers {
      id
    }
    samlSettings {
      adminLogin
      idpMetadata
      idpMetadataURL
      nameIDFormat
    }
    oidcSettings {
      adminLogin
      clientId
      identityProviderHost
      claimMapping {
        entries {
          name
          value
        }
      }
    }
  }
`;
