import EmptyState from "ds/components/EmptyState";
import { KeyColored } from "components/icons";
import Box from "ds/components/Box";

const ApiKeysEmptyList = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You don’t have any API Keys yet"
        icon={KeyColored}
        caption="Add API keys to assign them access to Spacelift."
      />
    </Box>
  );
};

export default ApiKeysEmptyList;
