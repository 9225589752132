import { gql } from "@apollo/client";

import { stackEditingDetails } from "components/form/graphql";

export const GET_STACK_SETTINGS = gql`
  query GetStackSettings($stackId: ID!) {
    stack(id: $stackId) {
      ...stackEditingDetails
    }
  }
  ${stackEditingDetails}
`;
