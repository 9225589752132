import DrawerBody from "ds/components/Drawer/Body";
import Feedback from "ds/components/Feedback";

const BulkActionsDrawerActionStepsInfoBanner = () => (
  <DrawerBody hasDivider shrink="0">
    <Feedback variant="info" type="banner">
      This action will affect multiple items. Please carefully review the list below.
    </Feedback>
  </DrawerBody>
);

export default BulkActionsDrawerActionStepsInfoBanner;
