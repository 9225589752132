import cx from "classnames";

import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import FormGroup from "components/FormDefault/Group";
import FormViewer from "components/FormDefault/Viewer";
import { OidcSettings } from "types/generated";
import TooltipInfo from "ds/components/TooltipInfo";
import { OIDCUseSlackIDFromIdpTooltip } from "components/TooltipSnippets";
import useTypedFlags from "hooks/useTypedFlags";

import styles from "./styles.module.css";
import { shouldUseSlackMemberIDFromIdp } from "./utils";

type ViewConfigurationProps = {
  integration: OidcSettings;
  onFormClose: () => unknown;
};

const ViewConfiguration = ({ integration, onFormClose }: ViewConfigurationProps) => {
  const { useSlackMemberIdFromIdpForUserProfile } = useTypedFlags();

  const labelClassName = cx(
    styles.infoLabel,
    useSlackMemberIdFromIdpForUserProfile && styles.wideInfoLabel
  );

  const shouldUseSlackMemberID = shouldUseSlackMemberIDFromIdp(integration?.claimMapping);

  return (
    <>
      {integration.adminLogin && (
        <FormGroup
          labelText="Admin Login:"
          labelStyle={{
            muted: true,
          }}
          labelClassName={labelClassName}
          divided
        >
          <FormViewer>{integration.adminLogin}</FormViewer>
        </FormGroup>
      )}

      <FormGroup
        labelText="Client ID:"
        labelStyle={{
          muted: true,
        }}
        labelClassName={labelClassName}
        divided
      >
        <FormViewer>{integration.clientId}</FormViewer>
      </FormGroup>

      <FormGroup
        labelText="Provider URL:"
        labelStyle={{
          muted: true,
        }}
        labelClassName={labelClassName}
        divided
      >
        <FormViewer>{integration.identityProviderHost}</FormViewer>
      </FormGroup>

      {useSlackMemberIdFromIdpForUserProfile && (
        <FormGroup
          labelText="Use Provided Slack Member ID:"
          labelStyle={{
            muted: true,
          }}
          labelClassName={labelClassName}
          labelIcon={
            <TooltipInfo variant="modal" widthMode="maxWidthSm" iconClassName={styles.formInfoIcon}>
              <OIDCUseSlackIDFromIdpTooltip />
            </TooltipInfo>
          }
        >
          <FormViewer>{shouldUseSlackMemberID ? "Yes" : "No"}</FormViewer>
        </FormGroup>
      )}

      <FormFooter top>
        <FormButton type="button" onClick={onFormClose} pill>
          Close
        </FormButton>
      </FormFooter>
    </>
  );
};

export default ViewConfiguration;
