import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { DashboardContext } from "../context";
import { OverviewStaticWidget } from "../types";

const DashboardOverviewColumnOnboardingDropdown = () => {
  const { updateTabOverviewColumnConfig, config } = useTypedContext(DashboardContext);

  const onHide = useCallback(() => {
    if (config["overview"].overviewColumn) {
      updateTabOverviewColumnConfig(
        config["overview"].overviewColumn.map((item) =>
          item.value === OverviewStaticWidget.LaunchPad ? { ...item, hidden: true } : item
        )
      );
    }
  }, [config, updateTabOverviewColumnConfig]);

  return (
    <DropdownMenuEllipsis tooltip="Settings" buttonVariant="ghost">
      <DropdownMenuItem onAction={onHide}>Hide</DropdownMenuItem>
      <DropdownMenuItem
        analyticsPage={AnalyticsPageDashboard.Dashboard}
        analyticsTitle="Dashboard - Go to Launchpad Clicked"
        href="/launchpad"
      >
        Go to LaunchPad
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default DashboardOverviewColumnOnboardingDropdown;
