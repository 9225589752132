import { RunComment } from "types/generated";

import CommentEntry from "../entries/CommentEntry";

export const createCommentEntry = ({ username, body, createdAt }: RunComment) => {
  return (
    <CommentEntry
      key={createdAt + username}
      username={username}
      body={body}
      createdAt={createdAt}
    />
  );
};
