import { gql } from "@apollo/client";

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    billingSubscriptionDelete {
      isActive
    }
  }
`;

export type CancelSubscriptionMutation = {
  isActive: boolean;
};
