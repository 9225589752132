import { Space } from "types/generated";
import Tooltip from "ds/components/Tooltip";
import Icon from "ds/components/Icon";
import { LinkThin, Unlink } from "components/icons";
import TreeListItem from "ds/components/TreeList/Item";
import { BaseTreeListItemProps } from "ds/components/TreeList/types";

type SpaceSelectOptionProps = BaseTreeListItemProps<Space>;

const SpaceSelectItem = (props: SpaceSelectOptionProps) => {
  return (
    <TreeListItem<Space>
      {...props}
      postfix={
        <Tooltip
          on={(tooltipProps) => (
            <Icon {...tooltipProps} src={props.item.inheritEntities ? LinkThin : Unlink} />
          )}
        >
          {props.item.inheritEntities ? "Inherited" : "Not inherited"}
        </Tooltip>
      }
    />
  );
};

export default SpaceSelectItem;
