import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useSetPrioritizeRun from "shared/Run/useSetPrioritizeRun";

import { useModifyRunErrorHandler } from "./useModifyRunError";

const useSetPriority = () => {
  const onError = useModifyRunErrorHandler();
  const { reportSuccess } = useTypedContext(FlashContext);

  const [setPrioritizeRun] = useSetPrioritizeRun({
    //TODO: provide refetchQueries as a property
    refetchQueries: ["SearchPrivateWorkerPoolQueuedRuns"],
    onError,
  });

  const handleSetPriority = useCallback(
    (isPrioritized: boolean, runId: string, stackId: string) =>
      setPrioritizeRun({ prioritize: isPrioritized, run: runId, stack: stackId }, (data) => {
        if (data?.runPrioritizeSet) {
          if (data.runPrioritizeSet.isPrioritized) {
            reportSuccess({ message: `Run ${runId} was prioritized` });
          }

          if (!data.runPrioritizeSet.isPrioritized) {
            reportSuccess({ message: `Run ${runId} was de-prioritized` });
          }
        }
      }),
    [setPrioritizeRun, reportSuccess]
  );

  return {
    setPriority: handleSetPriority,
  };
};

export default useSetPriority;
