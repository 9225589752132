import EmptyState from "ds/components/EmptyState";
import { BlueprintsColored, NoResultsColored } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import DocumentationButton from "components/DocumentationButton";
import Button from "ds/components/Button";
import { getDocsUrl } from "utils/getDocsUrl";

import { SpacesContext } from "../SpacesProvider";
import BlueprintsFeatureGateTooltip from "./FeatureGate/Tooltip";
import { BlueprintActions } from "./FeatureGate/types";

type BlueprintsEmptyProps = {
  hasNoResults?: boolean;
  onCreate: () => void;
};

const BlueprintsEmpty = ({ hasNoResults, onCreate }: BlueprintsEmptyProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <>
      {hasNoResults && (
        <EmptyState title="No results" caption="Try different filters." icon={NoResultsColored} />
      )}

      {!hasNoResults && (
        <EmptyState
          title="You don’t have any blueprints yet"
          icon={BlueprintsColored}
          caption={`Blueprints are "stack factories". They can be used by your teammates to quickly provision
          a stack by filling out a simple form.`}
        >
          <Box gap="medium">
            <DocumentationButton to={getDocsUrl("/concepts/blueprint")} label="Documentation" />
            {hasEntityCreateAccess && (
              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.CreateBlueprint}
                on={({ isDisabled, ...props }) => (
                  <Button {...props} variant="primary" onClick={onCreate} disabled={isDisabled}>
                    Create blueprint
                  </Button>
                )}
              />
            )}
          </Box>
        </EmptyState>
      )}
    </>
  );
};

export default BlueprintsEmpty;
