import { gql } from "@apollo/client";

export const GET_BRANCHES = gql`
  query GetBranches(
    $provider: VCSProvider!
    $namespace: String!
    $repository: String!
    $repositoryURL: String
    $vcsIntegrationId: ID
  ) {
    branches(
      provider: $provider
      namespace: $namespace
      repository: $repository
      repositoryURL: $repositoryURL
      vcsIntegrationID: $vcsIntegrationId
    )
  }
`;

export type GetBranchesGql = {
  branches: string[];
};
