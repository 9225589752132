import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { BitbucketDatacenterIntegration, Maybe } from "types/generated";

import { GET_BITBUCKET_DATA_CENTER_INTEGRATION } from "./gql";

const useGetBitbucketDataCenterIntegration = (id: Maybe<string>) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error } = useQuery<{
    bitbucketDatacenterIntegration: BitbucketDatacenterIntegration;
  }>(GET_BITBUCKET_DATA_CENTER_INTEGRATION, {
    variables: {
      id,
    },
    onError,
    fetchPolicy: "no-cache",
  });

  return {
    integration: data?.bitbucketDatacenterIntegration,
    isPageLoading: loading && !data?.bitbucketDatacenterIntegration,
    error,
  };
};

export default useGetBitbucketDataCenterIntegration;
