import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

import NotFoundPage from "components/error/NotFoundPage";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldTags from "components/FormFields/Tags";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import useTypedFlags from "hooks/useTypedFlags";
import useURLParams from "hooks/useURLParams";
import { VcsCheck, VcsProvider } from "types/generated";
import { stringIsRequired } from "utils/formValidators";
import VCSIntegrationTypeFieldControl from "views/Account/VCS/components/IntegrationTypeFieldControl";
import { VCS_INTEGRATION_TYPES } from "views/Account/VCS/constants";
import { AccountContext } from "views/AccountWrapper";

import VCSChecksToggle from "../../components/VCSChecksToggle";
import VCSIntegrationsDocumentationButton from "../../components/DocumentationButton";
import useCloseFullScreenModal from "../../useCloseFullScreenModal";
import useCheckGitHubEnterpriseHasDefaultIntegration from "../useCheckGitHubEnterpriseHasDefaultIntegration";
import { mapCreateGitHubAppFromManifestInputValues } from "./helpers";
import { ManageGitHubEnterpriseWizardFinalDetailsFormFields } from "./types";
import useCreateGitHubAppFromManifest from "./useCreateGitHubAppFromManifest";

const VCSIntegrationsManageGitHubEnterpriseWizardStepFinal = () => {
  const urlParams = useURLParams();
  const codeUrlParam = urlParams.get("code");
  const stateUrlParam = urlParams.get("state");

  const { viewer } = useTypedContext(AccountContext);
  const { aggregatedVcsChecks } = useTypedFlags();

  const manageGitHubEnterpriseWizardFinalForm =
    useForm<ManageGitHubEnterpriseWizardFinalDetailsFormFields>({
      defaultValues: {
        name: "",
        space: undefined,
        labels: [],
        description: "",
        integrationType: VCS_INTEGRATION_TYPES.MULTIPLE,
        vcsChecks: VcsCheck.Individual,
      },
      mode: "onChange",
    });

  const { register, formState, handleSubmit } = manageGitHubEnterpriseWizardFinalForm;

  const { hasDefaultIntegration, loading: checkDefaultIntegrationLoading } =
    useCheckGitHubEnterpriseHasDefaultIntegration(false);

  const { createGitHubApp, loading } = useCreateGitHubAppFromManifest();
  const { handleOnClose } = useCloseFullScreenModal();

  const handleSetup = useCallback(
    (formData: ManageGitHubEnterpriseWizardFinalDetailsFormFields) => {
      if (codeUrlParam && stateUrlParam) {
        createGitHubApp(
          mapCreateGitHubAppFromManifestInputValues(formData, codeUrlParam, stateUrlParam),
          handleOnClose
        );
      } else {
        reportError("Missing code or state URL parameter.");
      }
    },
    [codeUrlParam, createGitHubApp, handleOnClose, stateUrlParam]
  );

  if (!codeUrlParam || !stateUrlParam) {
    return <NotFoundPage />;
  }

  return (
    <FormProvider {...manageGitHubEnterpriseWizardFinalForm}>
      <FullScreenModalBody gap="x-large" grow="1">
        <Typography variant="p-t4" tag="h3" align="center">
          Add some additional information like integration unique name and select the space.
        </Typography>

        <Box fullWidth gap="large">
          <FormField
            label="Integration name"
            helperText="The integration name is unique and cannot be changed"
            error={formState.errors?.name?.message}
            fullWidth
            noMargin
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter integration name here"
                error={!!formState.errors?.name}
                {...register("name", {
                  validate: stringIsRequired("Integration name field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        </Box>

        {viewer.admin && (
          <VCSIntegrationTypeFieldControl
            hasAlreadyDefaultIntegration={hasDefaultIntegration}
            loading={checkDefaultIntegrationLoading}
            provider={VcsProvider.GithubEnterprise}
            noMargin
          />
        )}
        {!viewer.admin && <FormFieldSpace noMargin />}

        {aggregatedVcsChecks && <VCSChecksToggle />}

        <Box direction="column">
          <FormFieldTags label="Labels" tagName="label" name="labels" isOptional noMargin />
        </Box>

        <FormField label="Description" isOptional fullWidth noMargin>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here"
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.GithubEnterprise} />

        <Button
          variant="primary"
          onClick={handleSubmit(handleSetup)}
          loading={loading}
          disabled={!formState.isValid}
        >
          Set up
        </Button>
      </FullScreenModalFooter>
    </FormProvider>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseWizardStepFinal;
