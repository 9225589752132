import { useNavigate } from "react-router-dom-v5-compat";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { SPACELIFT_LANDING_URL } from "constants/url";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FlashContext from "components/FlashMessages/FlashContext";

import {
  StackCreationWizardFormStep,
  StackCreationWizardStep,
  StackFormFields,
  StackHooksFormFields,
} from "../types";
import { StackFormContext } from "../context";
import NewStackFooter from "../Footer";
import NewStackSummaryPanel from "./Panel";
import NewStackSummaryDetails from "./Details";
import NewStackSummaryVendor from "./Vendor";
import NewStackSummaryBehaviour from "./Behaviour";
import NewStackSummaryHooks from "./Hooks";
import NewStackSummaryVcs from "./Vcs";
import { encodeDataToUrl } from "../utils";
import NewStackSummaryAttachedContexts from "./AttachedContexts";
import NewStackSummaryPolicies from "./Policies";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";
import NewStackSummaryCloud from "./Cloud";

type Components = {
  [key in StackCreationWizardFormStep]: React.FunctionComponent<{ data: StackFormFields[key] }>;
};

const components: Partial<Components> = {
  [StackCreationWizardStep.Details]: NewStackSummaryDetails,
  [StackCreationWizardStep.Behaviour]: NewStackSummaryBehaviour,
  [StackCreationWizardStep.Hooks]: NewStackSummaryHooks,
  [StackCreationWizardStep.Vcs]: NewStackSummaryVcs,
  [StackCreationWizardStep.Vendor]: NewStackSummaryVendor,
};

const getCount = (
  step: StackCreationWizardStep,
  data: StackFormFields[StackCreationWizardFormStep]
) => {
  if (step === StackCreationWizardStep.Hooks && data) {
    return Object.values(data as StackHooksFormFields).reduce(
      (acc, nextValue) => acc + nextValue.length,
      0
    );
  }

  return undefined;
};

const NewStackSummary = () => {
  const { availableSteps, formData, createdStackId } = useTypedContext(StackFormContext);
  const navigate = useNavigate();
  const { reportSuccess, reportError } = useTypedContext(FlashContext);

  const trackOnlySegmentEvent = useNewStackAnalyticsSegementEvent();

  const finishStackCreation = () => {
    navigate(`/stack/${createdStackId}`, { replace: true });
  };
  // Create a link to the new stack creation page,
  // which has the query param with all data encoded into base64
  const handleCopyLink = async () => {
    if (!formData) {
      return;
    }

    // Omit file
    const data = {
      ...formData,
      [StackCreationWizardStep.Vendor]: {
        ...formData[StackCreationWizardStep.Vendor],
        terraform: {
          ...formData[StackCreationWizardStep.Vendor].terraform,
          uploadedStateFile: null,
          uploadedStateObjectId: null,
        },
      },
    };

    const link = `${SPACELIFT_LANDING_URL}/app/new/stack?${encodeDataToUrl(data)}`;

    try {
      await navigator.clipboard.writeText(link);
      trackOnlySegmentEvent("Copy stack link button");
      reportSuccess({ message: "Shareable link copied to clipboard" });
    } catch {
      console.info("Could not copy to clipboard:", link);
      reportError({ message: "Could not copy to clipboard" });
    }
  };

  const data: Array<{
    name: string;
    step: StackCreationWizardFormStep;
  }> = availableSteps.map(({ summaryName, step }) => ({
    name: summaryName,
    step,
  }));

  return (
    <>
      <FullScreenModalBody fullWidth>
        <Typography tag="h1" variant="p-t4" align="center">
          Summary
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Please verify your stack setup. Once stack is created you still will be able to apply
          changes from stack settings menu.
        </Typography>

        <Box direction="column" gap="large" margin="large 0">
          {data.map((panelData: { step: StackCreationWizardFormStep; name: string }) => {
            if (panelData.step === StackCreationWizardStep.AttachContext) {
              return (
                <NewStackSummaryAttachedContexts title={panelData.name} key={panelData.step} />
              );
            }
            if (panelData.step === StackCreationWizardStep.Policies) {
              return <NewStackSummaryPolicies title={panelData.name} key={panelData.step} />;
            }

            if (panelData.step === StackCreationWizardStep.Cloud) {
              return <NewStackSummaryCloud title={panelData.name} key={panelData.step} />;
            }

            const Component = components[panelData.step] as React.FunctionComponent<{
              data: StackFormFields[StackCreationWizardFormStep];
            }>;
            const data = formData[panelData.step];
            const count = getCount(panelData.step, data);

            if (!Component || !data) {
              return null;
            }

            return (
              <NewStackSummaryPanel count={count} key={panelData.step} {...panelData}>
                <Box direction="column">
                  <Component data={data} />
                </Box>
              </NewStackSummaryPanel>
            );
          })}
        </Box>
      </FullScreenModalBody>
      <NewStackFooter
        handleOnCreateStackClick={finishStackCreation}
        shareStackHandler={handleCopyLink}
      />
    </>
  );
};

export default NewStackSummary;
