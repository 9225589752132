import { FormHeader, Header } from "./types";

export const getHeadersDiff = (
  newHeaders: FormHeader[],
  prevHeaderKeys: string[],
  headerEnabled: boolean
) => {
  if (!headerEnabled) {
    return {
      headersKeysToRemove: prevHeaderKeys,
      headersToSet: [],
    };
  }

  const headersKeysToRemove: string[] = prevHeaderKeys.filter((prevKey) => {
    const updatedHeader = newHeaders.find(({ key }) => key === prevKey);
    return !updatedHeader || (updatedHeader.isSecret === false && updatedHeader.value);
  });

  const headersToSet: Header[] = newHeaders
    .filter(({ isSecret }) => !isSecret)
    .map(({ key, value }) => ({ key, value }));

  return {
    headersKeysToRemove,
    headersToSet,
  };
};
