import { gql } from "@apollo/client";

import { PRIVATE_WORKER_POOL_FIELDS } from "../gql";

export const SEARCH_PRIVATE_WORKER_POOL_WORKERS = gql`
  ${PRIVATE_WORKER_POOL_FIELDS}
  query SearchPrivateWorkerPoolWorkers($workerPoolId: ID!, $input: SearchInput!) {
    workerPool(id: $workerPoolId) {
      ...PrivateWorkerPoolFields
      searchWorkers(input: $input) {
        edges {
          cursor
          node {
            id
            status
            metadata
            createdAt
            drained
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const SEARCH_PRIVATE_WORKER_POOL_WORKERS_SUGGESTIONS = gql`
  ${PRIVATE_WORKER_POOL_FIELDS}
  query SearchPrivateWorkerPoolWorkersSuggestions(
    $workerPoolId: ID!
    $input: SearchSuggestionsInput!
  ) {
    workerPool(id: $workerPoolId) {
      ...PrivateWorkerPoolFields
      searchWorkersSuggestions(input: $input) {
        filteredCount
        fields {
          name
          orderable
        }
      }
    }
  }
`;
