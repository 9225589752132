import VersionTooltipContent from "components/FormFields/VersionInput/VersionTooltipContent";
import FormFieldViewBoolean from "components/FormFields/ViewBoolean";
import FormFieldViewText from "components/FormFields/ViewText";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { toolLabel } from "constants/terragrunt_tools";
import { VENDOR_DICTIONARY } from "constants/vendor";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import useTypedContext from "hooks/useTypedContext";
import { StackConfigVendorTerragrunt, TerragruntTool } from "types/generated";
import { getDocsUrl } from "utils/getDocsUrl";

import StackSettingsViewVendorFormField from "../../components/ViewVendorFormField";
import { StackSettingsContextData } from "../../Context";
import SettingsNewVendorViewVersion from "../components/ViewVersion";
import { getToolTooltip } from "./getTooltips";

const StackSettingsVendorTerragruntView = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const config = stackSettings.vendorConfig as StackConfigVendorTerragrunt;
  const isOpenTofu = config.tool === TerragruntTool.OpenTofu;
  const terraformOrOpenTofuLabel = isOpenTofu
    ? VENDOR_DICTIONARY.OPEN_TOFU
    : VENDOR_DICTIONARY.TERRAFORM;

  return (
    <>
      <StackSettingsViewVendorFormField vendorName={VENDOR_DICTIONARY.TERRAGRUNT} />
      <FormFieldViewBoolean label="Use run all" value={config.useRunAll} noMargin />
      <FormFieldViewBoolean
        label="Smart sanitization"
        value={config.useSmartSanitization}
        noMargin
      />
      <FormFieldViewText
        label="Tool"
        value={toolLabel(config.tool)}
        {...getToolTooltip()}
        noMargin
      />
      {config.terragruntVersion && (
        <SettingsNewVendorViewVersion
          label={`${VENDOR_DICTIONARY.TERRAGRUNT} version`}
          version={config.terragruntVersion}
          versionTooltip={
            <>
              <TooltipModalTitle>Version</TooltipModalTitle>
              <TooltipModalBody align="start">
                <VersionTooltipContent />
              </TooltipModalBody>
            </>
          }
          effectiveVersion={config.effectiveVersions.effectiveTerragruntVersion}
          effectiveVersionTooltip={`When the ${VENDOR_DICTIONARY.TERRAGRUNT} version is a version range, this is the highest version in the range that will be used for the next run.`}
        />
      )}
      {config.terraformVersion && (
        <SettingsNewVendorViewVersion
          label={`${terraformOrOpenTofuLabel} version`}
          version={config.terraformVersion}
          versionTooltip={
            <>
              <TooltipModalTitle>Version</TooltipModalTitle>
              <TooltipModalBody align="start">
                <VersionTooltipContent />
                <ReadMoreDocsLink
                  docsUrl={getDocsUrl("/concepts/stack/stack-settings#terraform-version")}
                />
              </TooltipModalBody>
            </>
          }
          effectiveVersion={config.effectiveVersions.effectiveTerraformVersion}
          effectiveVersionTooltip={`When the ${terraformOrOpenTofuLabel} version is a version range, this is the highest version in the range that will be used for the next run.`}
        />
      )}
    </>
  );
};

export default StackSettingsVendorTerragruntView;
