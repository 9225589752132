import { useMemo } from "react";

import { BulkEntityActionItem } from "components/BulkActions/types";
import { Module } from "types/generated";
import { useBulkSetModuleFavorite } from "shared/Module/useSetModuleFavorite";
import {
  canSetModuleFavorite,
  canUnsetModuleFavorite,
} from "shared/Module/useSetModuleFavorite/accessValidation";
import { canDisableModule } from "shared/Module/useDisableModule/accessValidation";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { useBulkDisableModule } from "shared/Module/useDisableModule";
import { useBulkEnableModule } from "shared/Module/useEnableModule";
import { canEnableModule } from "shared/Module/useEnableModule/accessValidation";

import { ModuleBulkActions } from "./types";

const useModuleBulkActions = (): Array<
  BulkEntityActionItem<ModuleBulkActions, Module, undefined>
> => {
  const { viewer } = useTypedContext(AccountContext);

  const [setModuleFavorite] = useBulkSetModuleFavorite();
  const [disableModule] = useBulkDisableModule();
  const [enableModule] = useBulkEnableModule();

  return useMemo<Array<BulkEntityActionItem<ModuleBulkActions, Module, undefined>>>(
    () => [
      {
        key: ModuleBulkActions.AddToFavorites,
        title: "Add to favorites",
        resultTitle: "Add modules to favorites",
        condition: canSetModuleFavorite,
        mutation: (module) => setModuleFavorite({ id: module.id, star: true }),
      },
      {
        key: ModuleBulkActions.RemoveFromFavorites,
        title: "Remove from favorites",
        resultTitle: "Remove modules from favorites",
        condition: canUnsetModuleFavorite,
        mutation: (module) => setModuleFavorite({ id: module.id, star: false }),
      },
      {
        key: ModuleBulkActions.Disable,
        title: "Disable",
        resultTitle: "Disable modules results",
        condition: (module) => canDisableModule(module, viewer),
        mutation: (module) => disableModule({ id: module.id }),
      },
      {
        key: ModuleBulkActions.Enable,
        title: "Enable",
        resultTitle: "Enable modules results",
        condition: (module) => canEnableModule(module, viewer),
        mutation: (module) => enableModule({ id: module.id }),
      },
    ],
    [disableModule, enableModule, setModuleFavorite, viewer]
  );
};

export default useModuleBulkActions;
