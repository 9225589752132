import { create, show, useModal } from "@ebay/nice-modal-react";

import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import Typography from "ds/components/Typography";

type OutputReferencesDeleteConfirmationModalProps = {
  name: string;
  onConfirm: () => void;
};

const OutputReferencesDeleteConfirmationModal = create(
  function OutputReferencesDeleteConfirmationModal({
    name,
    onConfirm,
  }: OutputReferencesDeleteConfirmationModalProps) {
    const modal = useModal();

    const handleOnConfirm = () => {
      onConfirm();
      modal.hide();
    };

    return (
      <DeleteConfirmationModal
        title="Delete reference"
        confirmationButtonLabel="Delete reference"
        onConfirm={handleOnConfirm}
      >
        <ConfirmationModalMessage>
          Are you sure you want to delete the output reference{" "}
          <Typography tag="span" variant="p-t5">
            {name}
          </Typography>
          ? You cannot undo the action.
        </ConfirmationModalMessage>
      </DeleteConfirmationModal>
    );
  }
);

export const showOutputReferencesDeleteConfirmationModal = (
  props: OutputReferencesDeleteConfirmationModalProps
) => show(OutputReferencesDeleteConfirmationModal, props);
