export const createCollection = <T>() => {
  let collection: T[] | null = null;

  return {
    get hasItems() {
      return collection !== null && collection.length > 0;
    },
    add(policy: T) {
      if (!collection) {
        collection = [];
      }

      collection.push(policy);
    },
    drain() {
      const currentCollection = collection;
      collection = null;
      return currentCollection!;
    },
  };
};
