import Skeleton from "react-loading-skeleton";

import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import Box from "ds/components/Box";

import styles from "./styles.module.css";

const MOCK = Array(5).fill(null);

const DashboardWidgetsFavoriteStacksSkeleton = () => (
  <Box fullWidth gap="medium" direction="column" className={styles.skeletonWrapper}>
    {MOCK.map((_, i) => (
      <TileWrapper key={i}>
        <TileContent>
          <Box justify="between" align="center">
            <Skeleton count={1} height={18} width={170} />
            <Skeleton count={1} height={20} width={30} />
          </Box>
        </TileContent>
      </TileWrapper>
    ))}
  </Box>
);

export default DashboardWidgetsFavoriteStacksSkeleton;
