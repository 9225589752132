import { ReactNode } from "react";

import Box from "ds/components/Box";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import FiltersSortHeaderStatic from "components/Filters/SortHeader/Static";
import PageInfoHeader from "ds/components/PageInfoHeader";

import { COLUMN_GAP, COLUMN_ORDER, FILTERS_ORDER_SETTINGS_KEY } from "../constants";

type PolicyDetailsUsedByFiltersLayoutProps = {
  children: ReactNode;
  renderedEntitiesCount: number;
  isPageEmpty: boolean;
  loadingIndication: boolean;
  filteredCount: number;
};

// TODO: [Policy library] update when backend with filtering ready
const PolicyDetailsUsedByFiltersLayout = ({
  children,
  renderedEntitiesCount,
  isPageEmpty,
  loadingIndication,
  filteredCount,
}: PolicyDetailsUsedByFiltersLayoutProps) => {
  return (
    <>
      <PageInfoHeader title="Used by">
        {!isPageEmpty && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={renderedEntitiesCount}
              totalCount={filteredCount}
              loading={loadingIndication}
              minimumLoadingDuration={200}
            />

            <SearchInput
              placeholder="Search..."
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          </Box>
        )}
      </PageInfoHeader>

      {!isPageEmpty && (
        <FiltersSortHeaderStatic
          sortOptions={["Type", "Name", "Space"]}
          columnOrder={COLUMN_ORDER}
          columnGap={COLUMN_GAP}
        />
      )}

      {children}
    </>
  );
};

export default PolicyDetailsUsedByFiltersLayout;
