import { gql } from "@apollo/client";

export const GET_UI_CONFIG = gql`
  query GetUIConfig {
    uiConfigGet
  }
`;

export const UPDATE_UI_CONFIG = gql`
  mutation UpdateUIConfig($input: String!) {
    uiConfigStore(input: $input)
  }
`;
