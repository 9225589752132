import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { Incognito, MenuSpaces, Networking } from "components/icons";
import TagsList from "components/TagsList";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";

import ProviderListItemDropdown from "../ProviderListItemDropdown";
import { ProviderListItemProps } from "./types";

function ProviderListItem({ item, onEdit, onShowFullDescription }: ProviderListItemProps) {
  const handleShowFullDescription = () => {
    onShowFullDescription(item);
  };

  return (
    <>
      <Box direction="column" justify="center" __deprecatedGap="0.2rem">
        <Box align="center" __deprecatedGap="1.2rem">
          <ListEntitiesItemLink title={item.id} to={`/provider/${item.id}`} />

          <Tooltip
            on={(props) => <Icon {...props} src={item.public ? Networking : Incognito} noShrink />}
          >
            {item.public ? "Public provider" : "Private provider"}
          </Tooltip>
        </Box>

        {item.description && (
          <ListEntitiesItemDescription
            description={item.description}
            onShowFullDescription={handleShowFullDescription}
          />
        )}

        <TagsList tags={item.labels} key={item.labels.join()} />
      </Box>

      {item.space && (
        <MetaInfoListItem
          icon={MenuSpaces}
          linkText={item.spaceDetails.name}
          href={`/spaces/${item.space}`}
        />
      )}

      <Box align="center">
        <Typography tag="div" variant="p-body2">
          {item.latestVersionNumber || "-"}
        </Typography>
      </Box>

      <Box align="center">
        <ProviderListItemDropdown item={item} onEdit={onEdit} />
      </Box>
    </>
  );
}

export default ProviderListItem;
