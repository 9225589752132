import { useCallback, useState } from "react";

import FullScreenModal from "ds/components/FullScreenModal";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import useCloseFullScreenModal from "../useCloseFullScreenModal";
import VCSIntegrationsManageAzureDevOpsForm from "./Form";

const VCSIntegrationsManageAzureDevOps = () => {
  const [isDirty, setIsDirty] = useState(false);

  const { handleOnClose } = useCloseFullScreenModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const modalCloseClickHandler = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    handleOnClose();
    trackSegmentAnalyticsEvent("Azure DevOps - Exit");
  }, [handleOnClose, trackSegmentAnalyticsEvent, isDirty]);

  const toggleIsDirty = useCallback((value: boolean) => {
    setIsDirty(value);
  }, []);

  return (
    <FullScreenModal title="Set up Azure DevOps integration" onClose={modalCloseClickHandler}>
      <VCSIntegrationsManageAzureDevOpsForm onIsDirtyChange={toggleIsDirty} />
    </FullScreenModal>
  );
};

export default VCSIntegrationsManageAzureDevOps;
