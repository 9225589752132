import useTitle from "hooks/useTitle";
import { Module, Stack } from "types/generated";
import { filterOutputReferences } from "utils/config";

import { configArray } from "../configArray";
import EditConfigMap from "./ConfigMap";
import NewConfig from "./NewConfig";
import { EnvironmentType } from "../types";
import EnvironmentCollapsibleSection from "../CollapsibleSection";

type EnvironmentEditModeProps = {
  stack: Module | Stack;
  type: EnvironmentType;
};

const EnvironmentEditMode = ({ stack, type }: EnvironmentEditModeProps) => {
  useTitle(`Editing environment · ${stack.name}`);

  const [filteredRuntimeConfigElements, outputReferenceElementsMap] = filterOutputReferences(
    stack.runtimeConfig
  );
  const [config, spaceliftConfig] = configArray(filteredRuntimeConfigElements);

  return (
    <>
      <NewConfig stackId={stack.id} type={type} />
      <div className="environment-edit__config">
        <EditConfigMap config={config} stackId={stack.id} type={type} />

        <h4 className="environment-runtime__view-sp-env-title">Spacelift environment</h4>
        <EditConfigMap config={spaceliftConfig} stackId={stack.id} type={type} />

        {outputReferenceElementsMap.size > 0 && (
          <>
            <h4 className="environment-runtime__view-sp-env-title">Output references</h4>

            {[...outputReferenceElementsMap].map(([key, config]) => (
              <EnvironmentCollapsibleSection
                key={key}
                config={config}
                sectionName={key}
                editMode
                stackId={stack.id}
                type={type}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default EnvironmentEditMode;
