import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { WorkerPool } from "types/generated";
import { isSaasDistribution } from "utils/distribution";
import { WORKER_POOL_SHARED_VALUE, WORKER_POOL_SHARED_LABEL } from "constants/worker_pool";

import { GET_STACK_WORKERPOOLS } from "./gql";

const isSaas = isSaasDistribution();

export const useWorkerPoolOptions = (space: string) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, error, refetch, networkStatus } = useQuery<{
    attachableWorkerPoolsInSpace: WorkerPool[];
  }>(GET_STACK_WORKERPOOLS, {
    onError,
    variables: {
      id: space,
    },
  });

  const workerPools = useMemo(
    () =>
      data?.attachableWorkerPoolsInSpace.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [data?.attachableWorkerPoolsInSpace]
  );

  const workerPoolsOptions = useMemo(
    () => [
      // Only show the public worker pool option for SaaS. Self-hosting only supports private workers.
      ...(isSaas ? [{ value: WORKER_POOL_SHARED_VALUE, label: WORKER_POOL_SHARED_LABEL }] : []),
      ...(workerPools || []),
    ],
    [workerPools]
  );

  return {
    workerPoolsOptions,
    loading,
    error,
    hasData: !!data,
    refetch,
    networkStatus,
  };
};
