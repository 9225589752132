import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { AwsIntegration } from "types/generated";
import CopyFieldIcon from "components/CopyField/Icon";

import styles from "./styles.module.css";
import { StackFormContext } from "../../context";
import { GET_EXTERNAL_ID_FOR_STACK } from "./gql";

type NewStackAttachCloudAWSIntegrationWarningProps = {
  read: boolean;
  write: boolean;
  integrationId: string;
};

const NewStackAttachCloudAWSIntegrationWarning = ({
  read,
  write,
  integrationId,
}: NewStackAttachCloudAWSIntegrationWarningProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { createdStackId } = useTypedContext(StackFormContext);

  const { data } = useQuery<{
    awsIntegration: AwsIntegration;
  }>(GET_EXTERNAL_ID_FOR_STACK, {
    variables: {
      read,
      write,
      integrationId,
      stackId: createdStackId,
    },
    skip: !createdStackId,
    onError,
  });

  const externalId = data?.awsIntegration.externalIdForStack?.externalId;
  const assumeRolePolicyStatement =
    data?.awsIntegration.externalIdForStack?.assumeRolePolicyStatement;

  const parsedAssumeRolePolicyStatement = useMemo(() => {
    try {
      if (assumeRolePolicyStatement) {
        return JSON.stringify(JSON.parse(assumeRolePolicyStatement), null, 2);
      }
    } catch (error) {
      onError(error);
    }

    return undefined;
  }, [onError, assumeRolePolicyStatement]);

  return (
    <Banner variant="danger" title="Please add statement to “Trust relationship”">
      {externalId && (
        <Box direction="column" gap="small" margin="medium 0 0 0">
          <Box align="center" gap="small" fullWidth>
            <Typography tag="span" variant="p-t7">
              External ID
            </Typography>

            <CopyFieldIcon value={externalId} title="Copy" />
          </Box>
          <Box className={styles.idWrapper} fullWidth>
            <Typography tag="p" variant="p-body3">
              {externalId}
            </Typography>
          </Box>
        </Box>
      )}

      <Typography tag="p" variant="p-body3" margin="large 0 0 0">
        Copy the statement below and add to its “Trust relationship” section:
      </Typography>

      {parsedAssumeRolePolicyStatement && (
        <Box className={styles.codeWrapper} margin="small 0 0 0">
          <CopyFieldIcon
            className={styles.codeCopyButton}
            value={parsedAssumeRolePolicyStatement}
            title="Copy"
          />
          <pre className={styles.code}>{parsedAssumeRolePolicyStatement}</pre>
        </Box>
      )}
    </Banner>
  );
};

export default NewStackAttachCloudAWSIntegrationWarning;
