import { useMemo } from "react";
import cx from "classnames";
import { nanoid } from "nanoid";

import styles from "./styles.module.css";
import { getTextHighlightsFuzzySort } from "./helpers";

type HighlightProps = {
  text: string;
  matches?: Set<number>;
};

const Highlight = (props: HighlightProps) => {
  const { text, matches } = props;

  const textArray = useMemo(() => {
    return matches && matches.size > 0 ? getTextHighlightsFuzzySort(text, matches) : [];
  }, [text, matches]);

  if (!matches || matches?.size === 0) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {textArray.map(({ highlight, text }) => (
        <span key={nanoid()} className={cx({ [styles.highlight]: highlight })}>
          {Array.from(text).map((t) => (t === " " ? <span key={nanoid()}>&nbsp;</span> : t))}
        </span>
      ))}
    </span>
  );
};

export default Highlight;
