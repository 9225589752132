import DiscardRunButton from "../../components/DiscardRunButton";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunHistoryEntryType } from "../../types";

type QueuedEntryProps = {
  timestamp: number;
  until: number | null;
  showDiscardButton: boolean;
  canDiscard: boolean;
  stackId: string;
  runId: string;
  runQueryToRefetch: string;
};

const QueuedEntry = ({
  timestamp,
  until,
  showDiscardButton,
  canDiscard,
  stackId,
  runId,
  runQueryToRefetch,
}: QueuedEntryProps) => {
  return (
    <HistoryEntryWrapper state={RunHistoryEntryType.Queued} timestamp={timestamp} until={until}>
      {canDiscard && showDiscardButton && (
        <DiscardRunButton stackId={stackId} runId={runId} runQueryToRefetch={runQueryToRefetch} />
      )}
    </HistoryEntryWrapper>
  );
};

export default QueuedEntry;
