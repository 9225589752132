import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { AutoattachmentTargets, AutoattachmentTarget } from "types/generated";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Tooltip from "ds/components/Tooltip";
import { pluralize } from "shared/Pluralize";
import Icon from "ds/components/Icon";
import { Info, MagnetColored } from "components/icons";
import CollapsibleList from "components/CollapsibleList";
import EmptyState from "ds/components/EmptyState";

import NewContextAttachedProject from "../AttachedProject";
import { PROJECTS_LIMIT } from "../constants";
import { GET_AUTOATTACHMENTS_FOR_LABELS } from "./gql";

type NewContextAutoAttachedProjectsProps = {
  space?: string;
  labels: Record<"value", string>[];
  setAutoAttachedProjectCount?: (count: number) => void;
  setAutoAttachProject?: (projects: AutoattachmentTarget[], isAuto?: boolean) => void;
};

const emptyPlaceholder = (
  <EmptyState
    padding="large 0"
    icon={MagnetColored}
    title="Context is not attached to any items"
    caption="Use auto-attach label to attach context automatically."
  />
);

const NewContextAutoAttachedProjects = ({
  space,
  labels,
  setAutoAttachedProjectCount,
  setAutoAttachProject,
}: NewContextAutoAttachedProjectsProps) => {
  const { onError } = useTypedContext(FlashContext);

  const { data } = useQuery<{
    autoattachmentForLabels: AutoattachmentTargets;
  }>(GET_AUTOATTACHMENTS_FOR_LABELS, {
    onError,
    variables: {
      space,
      labels: labels.map((item) => item.value),
    },
    skip: !space || !labels.length,
  });

  const limitedItems = useMemo(
    () => (data?.autoattachmentForLabels?.sample || []).slice(0, PROJECTS_LIMIT + 1),
    [data?.autoattachmentForLabels?.sample]
  );

  const count = data?.autoattachmentForLabels?.count || 0;
  const hasMore = count > PROJECTS_LIMIT;
  const remainingItemsCount = count - limitedItems.length;
  const remainingItemsLabel = pluralize("more project", remainingItemsCount);

  useEffect(() => {
    setAutoAttachedProjectCount?.(count);
  }, [count, setAutoAttachedProjectCount]);

  useEffect(() => {
    setAutoAttachProject?.(limitedItems, true);
  }, [limitedItems, setAutoAttachProject]);

  return (
    <CollapsibleList
      title="Auto-attached"
      count={count}
      emptyPlaceholder={emptyPlaceholder}
      ariaLevel={2}
    >
      {limitedItems.map((item) => (
        <NewContextAttachedProject key={item.id} item={item} />
      ))}
      {hasMore && (
        <Box gap="medium" justify="center" align="center" margin="small 0 0">
          <Typography variant="p-body2" tag="p" align="center">
            +{remainingItemsCount} {remainingItemsLabel}
          </Typography>
          <Tooltip widthMode="maxWidthSm" on={(props) => <Icon src={Info} {...props} />}>
            You will be able to preview the full list of projects that the context is attached to on
            Used by tab within Context view.
          </Tooltip>
        </Box>
      )}
    </CollapsibleList>
  );
};

export default NewContextAutoAttachedProjects;
