import useTypedContext from "hooks/useTypedContext";

import { StackSettingsVendorComponentBaseProps } from "../types";
import StackSettingsVendorCloudFormationEdit from "./Edit";
import StackSettingsVendorCloudFormationView from "./View";
import { StackSettingsContextData } from "../../Context";

const StackSettingsVendorCloudFormation = (props: StackSettingsVendorComponentBaseProps) => {
  const { canManageStack } = useTypedContext(StackSettingsContextData);

  return canManageStack ? (
    <StackSettingsVendorCloudFormationEdit {...props} />
  ) : (
    <StackSettingsVendorCloudFormationView />
  );
};

export default StackSettingsVendorCloudFormation;
