import { ReactNode, useState } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { Info } from "components/icons";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import IntegrationsInfoDrawer from "./InfoDrawer";
import styles from "./styles.module.css";

type GroupsPageLayoutProps = {
  children: ReactNode;
};

const IntegrationsLayout = ({ children }: GroupsPageLayoutProps) => {
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIntegrations,
  });

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    setIsInfoDrawerVisible(true);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" align="center" fullWidth>
          <ViewHeaderTitle tag="h2">Integrations</ViewHeaderTitle>
          <ButtonIcon onClick={openInfoDrawer} icon={Info} variant="ghost">
            Open info drawer
          </ButtonIcon>
        </ViewHeaderWrapper>
      </ViewHeader>
      <IntegrationsInfoDrawer
        isDrawerVisible={isInfoDrawerVisible}
        handleCloseDrawer={() => setIsInfoDrawerVisible(false)}
      />
      <PageWrapper className={styles.container}>{children}</PageWrapper>
    </>
  );
};

export default IntegrationsLayout;
