import useTypedContext from "hooks/useTypedContext";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { ContextContext } from "../../Context";
import { ContextVariablesListItemDropdownProps } from "./types";
import { showDeleteVariableConfirmation } from "./DeleteVariableConfirmation";

const ContextVariablesListItemDropdown = ({
  item,
  onEdit,
}: ContextVariablesListItemDropdownProps) => {
  const { context, canManageContext } = useTypedContext(ContextContext);

  const handleEditConfig = () => {
    onEdit(item);
  };

  if (!canManageContext) {
    return null;
  }

  const handleDeleteVariable = () => {
    showDeleteVariableConfirmation({ id: item.id, contextId: context.id });
  };

  return (
    <DropdownMenuEllipsis tooltip="Variable actions">
      <DropdownMenuItem onAction={handleEditConfig}>Edit</DropdownMenuItem>

      <DropdownMenuItem danger onAction={handleDeleteVariable}>
        Delete
      </DropdownMenuItem>
    </DropdownMenuEllipsis>
  );
};

export default ContextVariablesListItemDropdown;
