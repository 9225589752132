import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  AzureDevOpsRepoIntegration,
  MutationAzureDevOpsRepoIntegrationUpdateArgs,
} from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { UPDATE_AZURE_DEVOPS_INTEGRATION } from "./gql";

const useUpdateAzureDevOpsIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
  });

  const [azureDevOpsRepoIntegrationUpdate, { loading }] = useMutation<{
    azureDevOpsRepoIntegrationUpdate: AzureDevOpsRepoIntegration;
  }>(UPDATE_AZURE_DEVOPS_INTEGRATION, {
    onError,
    refetchQueries: ["SearchVCSIntegrations"],
  });

  const updateAzureDevOpsIntegration = useCallback(
    (input: MutationAzureDevOpsRepoIntegrationUpdateArgs, successCallback?: () => void) => {
      azureDevOpsRepoIntegrationUpdate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.azureDevOpsRepoIntegrationUpdate) {
            reportSuccess({
              message: `Integration ${data.azureDevOpsRepoIntegrationUpdate.name} was successfully updated`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration Edit - Finish", {
              integration: "Azure DevOps",
            });
          }
        })
        .catch(onError);
    },
    [azureDevOpsRepoIntegrationUpdate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    updateAzureDevOpsIntegration,
    loading,
  };
};

export default useUpdateAzureDevOpsIntegration;
