import CardWrapper from "components/CardWrapper";
import Box from "ds/components/Box";
import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import { AutoAttach, ContextsColored, LinkThin } from "components/icons";
import Tooltip from "ds/components/Tooltip";
import { HookType } from "utils/hooks";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

import ListTitle from "../ListTitle";
import ContextHookListItem from "../ContextHookListItem";
import styles from "./styles.module.css";
import { ContextHook } from "../../types";

type ContextHooksListProps = {
  type: HookType;
  hooks: ContextHook[];
};

const ContextHooksList = ({ type, hooks }: ContextHooksListProps) => {
  const setHookPriorityLabel = (hook: ContextHook) => {
    if (!hook.isAutoattached) return hook.priority;

    return type === "before" ? "Z->A" : "A->Z";
  };

  return (
    <CardWrapper direction="column" variant="filled">
      <ListTitle
        title="Context hooks"
        tooltipBody={
          <>
            Hooks coming from either auto or manually attached contexts can not be edited. Their
            order is based on context priority.
            <br />
            <Link
              // TODO: Add docs about hook ordering
              href={getDocsUrl("/concepts/stack/stack-settings.html#note-on-hook-ordering")}
              target="_blank"
            >
              Learn more
            </Link>
          </>
        }
      />

      <Box direction="column" gap="medium" margin="medium 0 0">
        {hooks.map((hook, index) => (
          <ContextHookListItem key={hook.text + index}>
            <TextEllipsis tooltip={hook.text} tooltipWidthMode="maxWidthXl">
              {(props) => (
                <Typography {...props} tag="span" variant="p-body3">
                  {hook.text}
                </Typography>
              )}
            </TextEllipsis>
            <Box align="center" gap="large">
              <TextEllipsis tooltip={hook.contextName} tooltipWidthMode="maxWidthSm">
                {(ellipsisProps) => (
                  <Link
                    to={`/context/${hook.contextId}`}
                    gap="medium"
                    className={styles.contextLink}
                  >
                    <Tooltip
                      on={(tooltipProps) => (
                        <Icon src={hook.isAutoattached ? AutoAttach : LinkThin} {...tooltipProps} />
                      )}
                    >
                      {hook.isAutoattached ? "Auto-attached context" : "Manually attached context"}
                    </Tooltip>

                    <Typography {...ellipsisProps} variant="p-body3" tag="span">
                      {hook.contextName}
                    </Typography>
                  </Link>
                )}
              </TextEllipsis>

              <Tooltip
                active={hook.isAutoattached}
                on={(props) => (
                  <Typography variant="p-t7" tag="span" className={styles.priority} {...props}>
                    Priority: {setHookPriorityLabel(hook)}
                  </Typography>
                )}
              >
                {type === "before"
                  ? "Auto-attached contexts are using reversed alphabetical order."
                  : "Auto-attached contexts are using alphabetical order."}
              </Tooltip>
            </Box>
          </ContextHookListItem>
        ))}
        {hooks.length === 0 && (
          <EmptyState
            padding="large 0"
            icon={ContextsColored}
            title="No context hooks attached yet"
            caption={`If you have any hooks added ${type} the phase to the context that is manually attached to the stack or using autoattach label this is where they will appear.`}
          />
        )}
      </Box>
    </CardWrapper>
  );
};

export default ContextHooksList;
