import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";

import { COLUMN_GAP, FilterItemKeys, FILTER_ITEMS_DICTIONARY, COLUMN_ORDER } from "../constants";

type PoliciesListFiltersLayoutHeader = {
  isPublic?: boolean;
};

const PoliciesListFiltersLayoutHeader = () => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.NAME, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.TYPE, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.SPACE, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.CREATED_AT, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.UPDATED_AT, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
};

export default PoliciesListFiltersLayoutHeader;
