import { useCallback } from "react";

import useCopyToClipboard from "hooks/useCopyToClipboard";
import DropdownMenuItem, { DropdownMenuItemProps } from "ds/components/DropdownMenu/Item";

type CopyFieldDropdownItem = Omit<DropdownMenuItemProps, "value"> & {
  title: string;
  value: string;
  onCopy?: () => void;
};

const CopyFieldDropdownMenuItem = ({
  title,
  value,
  onCopy,
  ...restProps
}: CopyFieldDropdownItem) => {
  const copyToClipboard = useCopyToClipboard(value);

  const handleCopy = useCallback(() => {
    copyToClipboard();
    onCopy?.();
  }, [copyToClipboard, onCopy]);

  return (
    <DropdownMenuItem {...restProps} onAction={handleCopy}>
      {title}
    </DropdownMenuItem>
  );
};

export default CopyFieldDropdownMenuItem;
