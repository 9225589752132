import { RefObject } from "react";

export function getAbsoluteStyle(
  wrapperRef: RefObject<HTMLDivElement>,
  triggerRef: RefObject<HTMLDivElement>
) {
  const offsetTop = wrapperRef.current ? wrapperRef.current.offsetTop : 0;
  const offsetLeft = wrapperRef.current ? wrapperRef.current.offsetLeft : 0;
  const triggerHeight = triggerRef.current ? triggerRef.current.offsetHeight : 0;

  return {
    top: `${offsetTop + triggerHeight}px`,
    left: `${offsetLeft}px`,
  };
}
