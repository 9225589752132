import { RunStateTransition } from "types/generated";

import FinishedEntry from "../entries/FinishedEntry";
import { getRunStateTransitionId } from "./getRunStateTransitionId";
import { RunEntryContext } from "../types";

export const createFinishedEntry = (
  { timestamp, stateVersion }: RunStateTransition,
  {
    canPerformAgain,
    stackId,
    runTaskCommand,
    runId,
    canPromote,
    runPromoteBlocker,
    isProposedRun,
    canRetry,
    runRetryBlocker,
  }: RunEntryContext
) => {
  return (
    <FinishedEntry
      key={getRunStateTransitionId(timestamp, stateVersion)}
      timestamp={timestamp}
      canPerformAgain={canPerformAgain}
      stackId={stackId}
      runTaskCommand={runTaskCommand}
      runId={runId}
      canPromote={canPromote}
      runPromoteBlocker={runPromoteBlocker}
      isProposedRun={isProposedRun}
      canRetry={canRetry}
      runRetryBlocker={runRetryBlocker}
    />
  );
};
