import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import Typography from "ds/components/Typography";

type LoginPolicyCreateConfirmationModalProps = {
  onConfirm: () => void;
};

const LoginPolicyCreateConfirmationModal = create(function LoginPolicyCreateConfirmationModal({
  onConfirm,
}: LoginPolicyCreateConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    modal.resolve();
    modal.hide();
    onConfirm();
  };

  return (
    <ConfirmationModal
      title="Create policy"
      confirmationButtonLabel="Save"
      confirmationButtonVariant="primary"
      onConfirm={handleOnConfirm}
      size="large"
    >
      <Typography tag="p" variant="p-body2">
        Are you sure? After creating the Login policy, all active sessions (except the current one){" "}
        <Typography tag="span" variant="p-t6">
          will be invalidated
        </Typography>
        .
      </Typography>
    </ConfirmationModal>
  );
});

export const showLoginPolicyCreateConfirmationModal = (
  props: LoginPolicyCreateConfirmationModalProps
) => show(LoginPolicyCreateConfirmationModal, props);
