import { type ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import { BulkActionsApiContext } from "components/BulkActions/Context";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import useAnalytics from "hooks/useAnalytics";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { BulkActionsChooseActionStepActionItemProps } from "../ActionItem";

type BulkActionsChooseActionStepActionMoreDropdownItemProps<Action extends string> =
  BulkActionsChooseActionStepActionItemProps<Action> & {
    children: ReactNode;
  };

const BulkActionsChooseActionStepActionMoreDropdownItem = <Action extends string>({
  children,
  onClick,
  tooltip,
  showTooltip,
  startIcon,
  applicableCount,
  notApplicableCount,
  action,
  variant,
  analyticsPage,
}: BulkActionsChooseActionStepActionMoreDropdownItemProps<Action>) => {
  const { onSelectAction } = useTypedContext(BulkActionsApiContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });
  const handleOnClick = () => {
    onClick();
    onSelectAction();
    trackSegmentAnalyticsEvent("Bulk Actions - Action Clicked", {
      applicableCount,
      notApplicableCount,
      action,
      view: variant,
      variant: "dropdown",
    });
  };

  return (
    <DropdownMenuItem tooltip={tooltip} isTooltipActive={showTooltip} onAction={handleOnClick}>
      <Box gap="medium">
        {startIcon && <Icon src={startIcon} />}
        {children}
      </Box>
    </DropdownMenuItem>
  );
};

export default BulkActionsChooseActionStepActionMoreDropdownItem;
