import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Version } from "types/generated";

import VersionsListItem, { VersionsListItemProps } from ".";

type VersionsVirtualizedListItemProps = {
  items: Version[];
} & Omit<VersionsListItemProps, "setRowHeight" | "item">;

const VersionsVirtualizedListItem = ({
  style,
  index,
  data,
}: ListChildComponentProps<VersionsVirtualizedListItemProps & ListEntitiesBaseItemProps>) => {
  const { items, ...props } = data;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <VersionsListItem
        key={items[index].id}
        {...props}
        item={items[index]}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(VersionsVirtualizedListItem, areEqual);
