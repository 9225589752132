import { useState } from "react";
import { useFormContext } from "react-hook-form";

import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import BeforeAfterCommands from "components/BeforeAfterCommands";
import { Commands } from "components/BeforeAfterCommands/types";
import { PhaseName, phases } from "utils/hooks";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Counter from "ds/components/Counter";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";

import NewContextFooter from "../Footer";
import { useSaveContext } from "../hooks/useSaveContext";
import { ContextFormFields } from "../types";

const NewContextAddHooks = () => {
  const [selectedPhase, setSelectedPhase] = useState(Object.keys(phases)[0] as PhaseName);
  const { handleSubmit, setValue, watch } = useFormContext<ContextFormFields>();
  const [hasUnsubmittedChanges, setHasUnsubmittedChanges] = useState(false);

  const [beforeValue, setBeforeValue] = useState("");
  const [afterValue, setAfterValue] = useState("");

  const onBeforeInputChange = (value: string) => {
    setBeforeValue(value);
    setHasUnsubmittedChanges?.(!!(value || afterValue));
  };

  const onAfterInputChange = (value: string) => {
    setAfterValue(value);
    setHasUnsubmittedChanges?.(!!(value || beforeValue));
  };

  useObserveForWarning(
    hasUnsubmittedChanges,
    <>
      You have not added the <strong>command</strong>. Do you want to continue without adding it?
    </>
  );

  const allCommands = watch("hooks");
  const commands = allCommands[selectedPhase];

  const handleCommandsChange = (commands: Commands) => {
    if (commands.before) {
      setValue(`hooks.${selectedPhase}.before`, commands.before);
    }

    if (commands.after) {
      setValue(`hooks.${selectedPhase}.after`, commands.after);
    }
  };

  const [saveContext, isSavingContext] = useSaveContext();

  const phaseOptions = (Object.entries(phases) as [PhaseName, string][]).map(([name, label]) => {
    const commands = allCommands[name];
    const beforeCommandsCount = commands?.before?.length ?? 0;
    const afterCommandsCount = commands?.after?.length ?? 0;

    return {
      label,
      value: name,
      postfix: String(beforeCommandsCount + afterCommandsCount),
    };
  });

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h2" variant="p-t4" align="center">
          Add hooks (optional)
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary">
          Spacelift workflow can be customized by adding extra commands to be executed before and
          after each of the phases.
        </Typography>
        <Box direction="column" gap="medium" margin="x-large 0 large 0">
          <FormField
            label="Customize workflow"
            tooltipInfoVariant="modal"
            tooltipInfo={
              <>
                <TooltipModalTitle>Customize workflow</TooltipModalTitle>
                <TooltipModalBody align="start">
                  <Typography tag="p" variant="p-body3">
                    Customize Spacelift run lifecycle by adding commands to run before and after
                    various execution phases.
                  </Typography>
                </TooltipModalBody>
              </>
            }
          >
            {({ ariaInputProps }) => (
              <Select
                value={selectedPhase}
                options={phaseOptions}
                onChange={setSelectedPhase}
                renderOption={({ label, value, postfix, onChange, closeSelect, checked }) => (
                  <DropdownSectionItem
                    onClick={() => (onChange?.(value), closeSelect?.())}
                    key={label}
                    active={checked}
                    innerRef={createSelectOptionRef(checked)}
                  >
                    <Box justify="between" align="center" grow="1">
                      <Typography tag="span" variant="p-body2">
                        {label}
                      </Typography>
                      <Counter count={postfix} />
                    </Box>
                  </DropdownSectionItem>
                )}
                ariaInputProps={ariaInputProps}
              />
            )}
          </FormField>
          <BeforeAfterCommands
            onAfterInputChange={onAfterInputChange}
            onBeforeInputChange={onBeforeInputChange}
            commands={commands}
            onChange={handleCommandsChange}
          />
        </Box>
      </FullScreenModalBody>
      <NewContextFooter
        onCreateContextClick={handleSubmit(saveContext)}
        isCreatingContext={isSavingContext}
      />
    </>
  );
};

export default NewContextAddHooks;
