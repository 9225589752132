import { ReactNode } from "react";

import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type HistoryEntryLabelProps = {
  children: ReactNode;
};

const HistoryEntryLabel = ({ children }: HistoryEntryLabelProps) => {
  return (
    <Typography tag="span" variant="p-body3" color="secondary" className={styles.label}>
      {children}
    </Typography>
  );
};

export default HistoryEntryLabel;
