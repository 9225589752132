import Link from "ds/components/LinkNew";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { AuditActor } from "types/generated";
import useApplyFilter from "components/Filters/useApplyFilter";

import { FILTER_ITEMS_DICTIONARY, AuditTrailEntrySuggestions } from "../../constants";

type AuditTrailListItemCreatedByProps = {
  value: AuditActor;
  onApplyFilter?: () => void;
};

const AuditTrailListItemCreatedBy = ({
  value,
  onApplyFilter,
}: AuditTrailListItemCreatedByProps) => {
  const { applyFilter } = useApplyFilter<AuditTrailEntrySuggestions>({
    searchSuggestionsDictionary: FILTER_ITEMS_DICTIONARY,
  });

  return (
    <>
      {value.run === null && (
        <TextEllipsis tooltip={value.username} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Link
              {...props}
              onPress={() => (
                applyFilter(AuditTrailEntrySuggestions.Username)(value.username), onApplyFilter?.()
              )}
            >
              {value.username}
            </Link>
          )}
        </TextEllipsis>
      )}

      {value.run && (
        <Typography tag="span" variant="p-body2">
          Run&nbsp;
          <Link href={`/stack/${value.run.stackId}/run/${value.run.id}`}>{value.run.id}</Link>
        </Typography>
      )}
    </>
  );
};

export default AuditTrailListItemCreatedBy;
