import { InfoNew } from "components/icons";
import { ButtonProps } from "ds/components/Button";
import { UseHiddenItemsListItemsWidth } from "hooks/useHiddenItemsList/types";

import { BulkActionsVariant } from "../types";

const getItemsText = (applicableTo?: number) => {
  if (applicableTo === 1) {
    return (
      <>
        <strong>1</strong> item
      </>
    );
  }
  return (
    <>
      <strong>{applicableTo || 0}</strong> items
    </>
  );
};

export const getApplicableToTooltipProps = (applicableTo: number, selectedItemsCount: number) => {
  if (applicableTo !== selectedItemsCount) {
    return {
      tooltip: (
        <>
          This action will only be applied to {getItemsText(applicableTo)}. <br />
          The rest of the items will be unaffected.
        </>
      ),
      showTooltip: true,
      startIcon: InfoNew,
    };
  }

  return {
    showTooltip: false,
  };
};

export const BUTTON_PROPS_BY_VARIANT: Record<
  BulkActionsVariant,
  {
    size: ButtonProps["size"];
    variant: ButtonProps["variant"];
  }
> = {
  [BulkActionsVariant.FloatingBar]: {
    size: "small",
    variant: "outlineOnDark",
  },
  [BulkActionsVariant.Drawer]: {
    size: "medium",
    variant: "secondary",
  },
};

export const BULK_ACTIONS_ITEM_DATA_ATTRIBUTE = "data-bulk-action-item";
const BULK_ACTIONS_ITEM_DATA_ACCESSOR = "bulkActionItem";
export const getResizableItems = (wrapperElement: HTMLDivElement): UseHiddenItemsListItemsWidth[] =>
  [...wrapperElement.querySelectorAll(`button[${BULK_ACTIONS_ITEM_DATA_ATTRIBUTE}]`)].map(
    (element) => {
      const item = element as HTMLAnchorElement;
      return {
        key: item.dataset[BULK_ACTIONS_ITEM_DATA_ACCESSOR] || "",
        width: item.offsetWidth,
      };
    }
  );
