import { ReactNode } from "react";

import styles from "./styles.module.css";

type ThemedLogsContentProps = {
  children: ReactNode;
};

const ThemedLogsContent = ({ children }: ThemedLogsContentProps) => {
  return (
    <pre className={styles.logs} tabIndex={-1}>
      {children}
    </pre>
  );
};

export default ThemedLogsContent;
