import { Run, RunState, RunType, Stack } from "types/generated";
import { AccountViewer } from "components/form/graphql/types";

/**
 * Check if the current user can create tasks on the stack
 */
export const canCreateTask = (stack: Stack, viewer: AccountViewer): boolean => {
  if (stack.isDisabled) {
    return false;
  }
  if (stack.lockedBy) {
    return viewer.id === stack.lockedBy;
  }

  return stack.canWrite;
};

/**
 * Check if the current user can perform the task again (history run)
 */
export const canPerformAgain = (run: Run, stack: Stack, viewer: AccountViewer): boolean => {
  const canPerformAgain =
    run.type === RunType.Task &&
    (run.state === RunState.Failed ||
      run.state === RunState.Discarded ||
      run.state === RunState.Stopped ||
      run.state === RunState.Finished);

  return canPerformAgain && canCreateTask(stack, viewer);
};
