import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import { TerraformWorkflowToolOptions } from "constants/terraform_workflow_tools";
import { WORKER_POOL_SHARED_VALUE, WORKER_POOL_SHARED_LABEL } from "constants/worker_pool";
import KeyValue from "components/KeyValue";
import { TerraformWorkflowTool } from "types/generated";

import { ModuleBehaviorFormFields } from "../types";
import { formatToggleValue } from "./formatToggleValue";
import { ModuleFormContext } from "../context";

type NewModuleSummaryBehaviorProps = {
  data: ModuleBehaviorFormFields;
};

const getTerraformWorkflowToolLabel = (workflowTool: TerraformWorkflowTool) => {
  const option = TerraformWorkflowToolOptions.find(({ value }) => value === workflowTool);

  if (option) {
    return option.label;
  }

  return "";
};

const NewModuleSummaryBehavior = ({ data }: NewModuleSummaryBehaviorProps) => {
  const { internalData } = useTypedContext(ModuleFormContext);
  const workflowToolLabel = getTerraformWorkflowToolLabel(
    data.workflowTool as TerraformWorkflowTool
  );

  return (
    <>
      <KeyValue name="Worker pool">
        <Typography tag="p" variant="p-body2">
          {data.workerPool === WORKER_POOL_SHARED_VALUE && WORKER_POOL_SHARED_LABEL}
          {data.workerPool !== WORKER_POOL_SHARED_VALUE ? internalData.workerPoolLabel : undefined}
        </Typography>
      </KeyValue>
      <KeyValue name="Workflow tool">{workflowToolLabel}</KeyValue>
      <KeyValue name="Runner image">{data.runnerImage}</KeyValue>
      <KeyValue name="Administrative">{formatToggleValue(data.administrative)}</KeyValue>
      <KeyValue name="Enable local preview">{formatToggleValue(data.localPreviewEnabled)}</KeyValue>
      <KeyValue name="Protect from deletion">
        {formatToggleValue(data.protectFromDeletion)}
      </KeyValue>
    </>
  );
};

export default NewModuleSummaryBehavior;
