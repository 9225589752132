import { LockNew } from "components/icons";
import Timestamp from "components/time/Timestamp";
import Callout from "ds/components/Callout";

type StackLockNoticeProps = {
  lockedBy: string | null;
  lockedAt?: number | null;
  viewer: string;
};

const StackLockNotice = ({ lockedAt, lockedBy, viewer }: StackLockNoticeProps) => {
  return (
    <Callout variant="warning" icon={LockNew}>
      This stack was locked for exclusive use by {lockedBy === viewer ? "yourself" : lockedBy}
      {lockedAt && (
        <>
          &nbsp;
          <Timestamp timestamp={lockedAt} />
        </>
      )}
      .
    </Callout>
  );
};

export default StackLockNotice;
