import { type ReactNode, RefObject, useRef } from "react";
import cx from "classnames";

import Box from "ds/components/Box";

import SlideTransition from "./SlideTransition";
import styles from "./styles.module.css";
import BulkActionsFloatingBarSpacer from "./Spacer";

type BulkActionsFloatingBarWrapperProps = {
  children: ReactNode;
  isVisible: boolean;
  isFixed?: boolean;
  virtualizedListContainerRef?: RefObject<HTMLElement>;
};

const BulkActionsFloatingBarWrapper = ({
  children,
  isVisible,
  isFixed,
  virtualizedListContainerRef,
}: BulkActionsFloatingBarWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <SlideTransition visible={isVisible}>
        <Box
          ref={ref}
          className={cx(styles.wrapper, {
            [styles.fixed]: isFixed,
          })}
          justify="center"
        >
          <Box className={styles.panel} direction="column">
            {children}
          </Box>
        </Box>
      </SlideTransition>
      <BulkActionsFloatingBarSpacer
        isVisible={isVisible}
        floatingBlockRef={ref}
        virtualizedListContainerRef={virtualizedListContainerRef}
      />
    </>
  );
};

export default BulkActionsFloatingBarWrapper;
