import React from "react";

import Box from "ds/components/Box";

import styles from "./styles.module.css";

type FiltersPanelProps = {
  children: React.ReactNode;
};

const FiltersPanel = ({ children }: FiltersPanelProps) => {
  return (
    <Box direction="row" className={styles.filtersPanel} align="center" justify="between">
      {children}
    </Box>
  );
};

export default FiltersPanel;
