import cx from "classnames";
import { forwardRef } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import Input from "ds/components/Input";
import { CrossNew, Down, Spinner } from "components/icons";
import Box from "ds/components/Box";
import LoadingIndicator from "ds/components/LoadingIndicator";

import { SelectAutocompleteProps } from "../types";
import useAutocomplete from "../useAutocomplete";
import styles from "./styles.module.css";

const SelectAutocomplete = forwardRef(function SelectAutocomplete(
  {
    ariaProps,
    query,
    onChange,
    className,
    placeholder = "Type in or select from the list",
    onEnterKey,
    onQueryClear,
    visibilityToggle,
    active,
    error,
    loading,
    disabled,
    inputRef,
    tabOnlyInput,
  }: SelectAutocompleteProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { handleOnChange, handleOnKeyDown, clearQuery } = useAutocomplete({
    onChange,
    onEnterKey,
    onQueryClear,
  });

  const handleInputClick = () => {
    if (!active) {
      visibilityToggle();
    }
  };

  return (
    <div
      className={cx(
        styles.selectAutocomplete,
        loading && styles.loading,
        className,
        disabled && styles.disabled,
        query.length > 0 && styles.withQuery
      )}
      ref={ref}
    >
      <Input
        {...ariaProps}
        value={query}
        onChange={handleOnChange}
        placeholder={placeholder}
        onKeyDown={handleOnKeyDown}
        onClick={handleInputClick}
        active={active}
        error={error}
        disabled={disabled}
        ref={inputRef}
      />
      <Box gap="small" align="center" className={styles.icons}>
        <LoadingIndicator loading={loading} icon={Spinner} />

        {query.length > 0 && (
          <ButtonIcon icon={CrossNew} onClick={clearQuery} disabled={disabled} variant="ghost">
            Clear
          </ButtonIcon>
        )}

        <ButtonIcon
          icon={Down}
          onClick={visibilityToggle}
          disabled={disabled}
          aria-haspopup={ariaProps?.["aria-haspopup"]}
          aria-expanded={ariaProps?.["aria-expanded"]}
          tabIndex={tabOnlyInput ? -1 : 0}
          variant="ghost"
        >
          {active ? "Close" : "Open"}
        </ButtonIcon>
      </Box>
    </div>
  );
});

export default SelectAutocomplete;
