import { ErrorType } from "types/Error";
import { shouldShowUnauthorizedError } from "hooks/useErrorHandle/helpers";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

type ErrorHandler = (error: ErrorType | undefined) => void;

export const useModifyRunErrorHandler = (): ErrorHandler => {
  const { onError, reportError } = useTypedContext(FlashContext);

  return (error) => {
    if (shouldShowUnauthorizedError(error)) {
      reportError({
        title: "No access",
        message: "You are either unauthorized or have no access to perform this action",
      });
    } else {
      onError(error);
    }
  };
};
