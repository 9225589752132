import { ListChildComponentProps } from "react-window";

import LineElement from "./LineElement";
import { RunChangesItem } from "./types";

type VirtualizedLineElementProps = {
  changesList: [string, RunChangesItem][];
  setRowHeight: (index: number, size: number) => void;
};

const VirtualizedLineElement = (props: ListChildComponentProps<VirtualizedLineElementProps>) => {
  const {
    index,
    style,
    data: { changesList, setRowHeight },
  } = props;

  const item = changesList[index];

  const handleSetRowHeight = (size: number) => setRowHeight(index, size);

  return (
    <div style={style}>
      <LineElement key={item[0]} item={item[1]} setRowHeight={handleSetRowHeight} />
    </div>
  );
};

export default VirtualizedLineElement;
