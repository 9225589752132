import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import FlashContext from "components/FlashMessages/FlashContext";
import Button from "ds/components/Button";
import { ConfigType } from "types/generated";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import useTypedContext from "hooks/useTypedContext";
import { ConfigElement } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

import { ContextVariablesFormFields } from "../FormDrawer/types";
import { ContextMountedFile } from "../types";
import { UPDATE_CONTEXT_CONFIG } from "../FormDrawer/gql";

type ContextMountedFilesUpdateFormDrawerProps = {
  contextId: string;
  item?: ContextMountedFile;
};

const ContextMountedFilesUpdateFormDrawer = createDrawer(
  ({ contextId, item }: ContextMountedFilesUpdateFormDrawerProps) => {
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const [addConfig] = useMutation<{
      contextConfigAdd: ConfigElement;
    }>(UPDATE_CONTEXT_CONFIG, {
      refetchQueries: ["GetContext"],
      variables: { contextId },
    });

    const drawer = useModal();

    const closeDrawer = () => {
      drawer.resolve();
      drawer.hide();
    };

    const onSave = (formData: ContextVariablesFormFields) => {
      const input = {
        id: formData.id,
        description: formData.description || "",
        writeOnly: formData.writeOnly,
        type: formData.type,
        value: formData.value,
      };
      addConfig({ variables: { input } })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `Config ${data.contextConfigAdd.id} successfully edited`,
            });
            closeDrawer();
          }
        })
        .catch(onError);
    };
    const fileForm = useForm<ContextVariablesFormFields>({
      defaultValues: {
        id: item?.id || "",
        description: item?.description || "",
        writeOnly: item?.writeOnly ? item.writeOnly : false,
        type: ConfigType.FileMount,
        value: item?.value || "",
      },
      mode: "onChange",
    });

    const {
      handleSubmit,
      formState: { isValid, isDirty },
    } = fileForm;

    const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
      onSave(formData);
    };

    return (
      <DrawerSimple>
        <FormProvider {...fileForm}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Edit file" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <Box margin="0 0 large">
              <FormFieldViewText label="Path" value={`/mnt/workspace/${item?.id}`} />
            </Box>
            <ContextConfigFormIsSecretField name="writeOnly" />
            <ContextConfigFormDescriptionField name="description" />

            <DrawerFooter>
              <DrawerFooterActions>
                <Button variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || !isDirty}
                >
                  Save
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showContextMountedFilesUpdateFormDrawer = createDrawerTrigger(
  ContextMountedFilesUpdateFormDrawer
);
