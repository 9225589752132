import { gql } from "@apollo/client";

export const GET_LIMIT = gql`
  query GetLimit {
    vcsEventTriggeredRunsLimit
  }
`;

export const UPDATE_LIMIT = gql`
  mutation UpdateLimit($limit: Int) {
    accountUpdateVCSEventTriggeredRunsLimit(limit: $limit)
  }
`;
