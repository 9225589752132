import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { WebhooksIntegration } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import Banner from "ds/components/Banner";
import SecretInput from "ds/components/SecretInput";
import Toggle from "ds/components/Toggle";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { validateRequiredURL } from "utils/formValidators";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { CREATE_WEBHOOK_INTEGRATION, UPDATE_WEBHOOK_INTEGRATION } from "./gql";

type WebhookDrawerFormProps = {
  onCloseDrawer: () => void;
  webhook: WebhooksIntegration | null;
  moduleId: string;
};

type FormValues = {
  endpoint: string;
  secret: string;
  enabled: boolean;
};

const WebhookDrawerForm = ({ onCloseDrawer, webhook, moduleId }: WebhookDrawerFormProps) => {
  const isEditMode = !!webhook?.id;

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const builderForm = useForm<FormValues>({
    defaultValues: {
      endpoint: webhook?.endpoint || "",
      secret: webhook?.secret || "",
      enabled: isEditMode ? !!webhook?.enabled : true,
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [createWebhook, { loading: creatingWebhook }] = useMutation(CREATE_WEBHOOK_INTEGRATION, {
    refetchQueries: ["GetModuleWebhooks"],
    variables: {
      moduleId,
    },
  });

  const [updateWebhook, { loading: updatingWebhook }] = useMutation(UPDATE_WEBHOOK_INTEGRATION, {
    refetchQueries: ["GetModuleWebhooks"],
    variables: {
      id: webhook?.id,
      moduleId,
    },
  });

  const onCreateSubmit = (formData: FormValues) => {
    createWebhook({
      variables: {
        input: {
          endpoint: formData.endpoint,
          secret: formData.secret,
          enabled: formData.enabled,
        },
      },
    })
      .then(({ data }) => {
        if (data?.webhooksIntegrationCreate?.id) {
          reportSuccess({
            message: `Webhook integration was successfully created`,
          });
          reset();
          onCloseDrawer();
        }
      })
      .catch(onError);
  };

  const onUpdateSubmit = (formData: FormValues) => {
    updateWebhook({
      variables: {
        input: {
          endpoint: formData.endpoint,
          secret: formData.secret,
          enabled: formData.enabled,
        },
      },
    })
      .then(({ data }) => {
        if (data?.webhooksIntegrationUpdate?.id) {
          reportSuccess({
            message: `Webhook integration was successfully updated`,
          });
          reset();
          onCloseDrawer();
        }
      })
      .catch(onError);
  };

  const onSubmit = (formData: FormValues) => {
    if (isEditMode) {
      onUpdateSubmit(formData);
    } else {
      onCreateSubmit(formData);
    }
  };

  const handleCancel = () => {
    reset();
    onCloseDrawer();
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={isEditMode ? "Edit webhook" : "Create webhook"} />
        <DrawerCloseIcon handleCloseDrawer={onCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Banner variant="info">
          You can set up a webhook integration to ensure that a POST request is sent every time an
          event of interest occurs within the scope of this module. Make sure to set a secret so
          that you're able to verify that the request comes from us!
        </Banner>

        <Box gap="large" direction="column" margin="large 0 0">
          <FormField label="Endpoint" error={errors?.endpoint?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Full endpoint to send a POST request to"
                error={!!errors?.endpoint}
                {...register("endpoint", {
                  validate: validateRequiredURL({
                    requiredStringError: "Endpoint is required",
                    urlError: "Endpoint must be a valid URL",
                  }),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Secret" error={errors?.secret?.message} noMargin>
            {({ ariaInputProps }) => (
              <SecretInput
                placeholder="Secret to verify payload"
                error={!!errors?.secret}
                {...register("secret")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <Controller
            name="enabled"
            render={({ field }) => (
              <Toggle variant={"switch"} onChange={field.onChange} checked={field.value}>
                <Typography tag="span" variant="p-t7">
                  Enabled
                </Typography>
              </Toggle>
            )}
          />
        </Box>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty || creatingWebhook || updatingWebhook}
              loading={creatingWebhook || updatingWebhook}
            >
              {isEditMode ? "Save" : "Create"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default WebhookDrawerForm;
