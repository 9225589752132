import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

const TRIGGER_VERSION = gql`
  mutation TriggerVersion($moduleId: ID!, $id: ID!) {
    versionTrigger(module: $moduleId, id: $id) {
      id
    }
  }
`;

type TriggerAgainDropdownItemProps = {
  id: string;
  moduleId: string;
};

const TriggerAgainDropdownItem = ({ id, moduleId }: TriggerAgainDropdownItemProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [triggerVersion, { loading }] = useMutation<{
    versionTrigger: { id: string };
  }>(TRIGGER_VERSION, {
    refetchQueries: ["GetModuleVersions"],
    variables: { moduleId, id },
  });

  const handleTrigger = async () => {
    try {
      const { data } = await triggerVersion();
      reportSuccess({ message: "Version triggered" });
      navigate(`/module/${moduleId}/version/${data?.versionTrigger.id}`);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <DropdownMenuItem isDisabled={loading} onAction={handleTrigger}>
      Trigger again
    </DropdownMenuItem>
  );
};

export default TriggerAgainDropdownItem;
