import { hasAtLeastStackWriteAccess } from "shared/Stack/utils";
import { Run, RunState, Stack } from "types/generated";

// Check if the user can review the run based on the run
export const isRunReviewable = (run?: Run | null): boolean =>
  !!run &&
  run.needsApproval &&
  (run.state === RunState.Unconfirmed ||
    run.state === RunState.Queued ||
    run.state === RunState.PendingReview);

export const canReviewRun = (run: Run, stack: Stack): boolean =>
  isRunReviewable(run) && hasAtLeastStackWriteAccess(stack);

// Check if the user can review the stack run having only a stack
export const canReviewStackRun = (stack: Stack): boolean =>
  isRunReviewable(stack.blocker) && hasAtLeastStackWriteAccess(stack);
