import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { REQUEST_MANAGED_USER_ROLE_CHANGE } from "../gql";
import { ChangeRoleFields } from "./types";

const useUserRoleChange = () => {
  const { onError } = useTypedContext(FlashContext);

  const [requestManagedUserRoleChange] = useMutation(REQUEST_MANAGED_USER_ROLE_CHANGE, {
    onError,
    refetchQueries: ["GetManagedUsers"],
  });

  const onUserRoleChange = (formData: ChangeRoleFields) => {
    return requestManagedUserRoleChange({
      variables: {
        spaceId: formData.spaceId,
        requestedAccessLevel: formData.spaceAccessLevel,
      },
    });
  };

  return { onUserRoleChange };
};

export default useUserRoleChange;
