import Skeleton from "react-loading-skeleton";

const style = {
  width: "calc(100% - 32px)",
  margin: "16px 16px 0",
};
const height = 32;

const TemplatePreviewSkeleton = () => {
  return (
    <>
      <Skeleton count={1} height={height} style={style} />
      <Skeleton count={1} height={height} style={style} />
      <Skeleton count={1} height={height} style={style} />
      <Skeleton count={1} height={height} style={style} />
      <Skeleton count={1} height={height} style={style} />
    </>
  );
};

export default TemplatePreviewSkeleton;
