import { RunsBulkActions } from "./types";

export const BULK_ACTION_RESULTS_TITLE: Record<RunsBulkActions, string> = {
  [RunsBulkActions.ReviewApprove]: "Approve runs results",
  [RunsBulkActions.ReviewReject]: "Reject runs results",
  [RunsBulkActions.Confirm]: "Confirm runs results",
  [RunsBulkActions.Discard]: "Discard runs results",
};

export const BULK_ACTION_NAMES: Record<RunsBulkActions, string> = {
  [RunsBulkActions.ReviewApprove]: "Approve",
  [RunsBulkActions.ReviewReject]: "Reject",
  [RunsBulkActions.Confirm]: "Confirm",
  [RunsBulkActions.Discard]: "Discard",
};
