import { SegmentPropertiesObject } from "./types";

/**
 * Docs for supported properties:
 * https://segment.com/docs/connections/spec/page/#properties
 */
export const getDefaultPageProperties = (): SegmentPropertiesObject => ({
  path: location.pathname,
  referrer: document.referrer,
  search: location.search,
  title: document.title,
  url: location.href,
});

/**
 * Docs for supported properties:
 * https://segment.com/docs/connections/spec/common/#context-fields-automatically-collected
 */
export const getDefaultContext = (): SegmentPropertiesObject => {
  return {
    locale: navigator.language,
    page: getDefaultPageProperties(),
    screen: {
      height: screen.height,
      width: screen.width,
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userAgent: navigator.userAgent,
    userAgentData: navigator.userAgentData,
  };
};
