import { ConfigElementWithSource } from "types/generated";
import { isSpaceliftConfig } from "utils/config";

export const configArray = (configElements: ConfigElementWithSource[] = []) => {
  return configElements.reduce(
    ([config, spaceliftConfig], elem) => {
      return isSpaceliftConfig(elem)
        ? [config, [...spaceliftConfig, elem]]
        : [[...config, elem], spaceliftConfig];
    },
    [[], []] as ConfigElementWithSource[][]
  );
};
