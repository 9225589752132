import { SortableTableColumn } from "components/SortableTable";

export const columns: SortableTableColumn[] = [
  {
    value: "id",
    label: "Name",
  },
  {
    value: "default",
    label: "Default",
    static: true,
  },
];

export const COLUMN_ORDER = "2fr 1fr";
