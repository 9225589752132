import { useCallback, useMemo, useState } from "react";

import { getTabItems } from "./Drawer/ResultsStep/helpers";
import { type BulkActionItemResults } from "./helpers";
import { BulkActionItemID } from "./types";

export const useResultTabItems = <Entity>(bulkActionItemResults: BulkActionItemResults) => {
  const [savedMap, setSavedMap] = useState<Map<BulkActionItemID, Entity>>(new Map());

  const resultsTabItems = useMemo(
    () => getTabItems<Entity>(bulkActionItemResults, (id) => savedMap.get(id)),
    [bulkActionItemResults, savedMap]
  );

  const resetEntityMap = useCallback(() => {
    setSavedMap(new Map());
  }, []);

  return {
    resultsTabItems,
    saveEntityMap: setSavedMap,
    resetEntityMap,
  };
};
