import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

type FormFooterProps = {
  top?: boolean;
  children: React.ReactNode;
};

const FormFooter = ({ children, top }: FormFooterProps) => {
  const footerClass = cx({
    [styles.footer]: true,
    [styles.marginTop]: top,
  });

  return (
    <section className={footerClass}>
      <div className={cx(styles.group, styles.buttons)}>{children}</div>
    </section>
  );
};

export default FormFooter;
