import { fromUnixTime } from "date-fns";
import cronsTrue from "cronstrue";
import { useCallback } from "react";

import { ScheduledTask } from "types/generated";
import CollapsibleList from "components/CollapsibleList";
import FormSummaryKeyValueElement from "components/FormSummary/KeyValueElement";
import { formatDateTimeByLocale } from "utils/date";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import Badge from "ds/components/Badge";
import { StackContext } from "views/Stack/Context";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { getNextSchedule } from "../helpers";
import { StackSchedulingContextApi } from "../Context";
import useDeleteScheduledTask from "./useDeleteScheduledTask";
import { showManageScheduledTaskConfirmationModal } from "./ConfirmationModal";

type StackManageScheduledTaskListItemProps = {
  integration: ScheduledTask;
  hasPrivateWorkerPool: boolean;
};

const StackManageScheduledTaskListItem = ({
  integration,
  hasPrivateWorkerPool,
}: StackManageScheduledTaskListItemProps) => {
  const { canManageStackAndRuns } = useTypedContext(StackContext);
  const { onEdit } = useTypedContext(StackSchedulingContextApi);
  const { deleteScheduledTask, loading } = useDeleteScheduledTask();

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const onDelete = useCallback(() => {
    deleteScheduledTask(integration.id, () => {
      trackSegmentEvent("Schedule Delete Saved", { type: SCHEDULING_TYPE.TASK });
    });
  }, [deleteScheduledTask, integration.id, trackSegmentEvent]);

  const handleConfirmDelete = useCallback(() => {
    showManageScheduledTaskConfirmationModal({
      onConfirm: onDelete,
    });
  }, [onDelete]);

  const handleEdit = useCallback(() => {
    onEdit(integration);
  }, [onEdit, integration]);

  const isRecurring = !!integration.cronSchedule;
  const isFinished = !integration.nextSchedule && !isRecurring;
  const isBlocked = !hasPrivateWorkerPool;

  return (
    <CollapsibleList
      ariaLevel={3}
      title="Task"
      initialIsCollapsed={false}
      action={
        <>
          {isFinished && (
            <Badge state="neutral" size="small">
              FINISHED
            </Badge>
          )}

          {canManageStackAndRuns && (
            <DropdownMenuEllipsis tooltip="Scheduled task actions" buttonVariant="ghost">
              <DropdownMenuItem onAction={handleEdit}>Edit</DropdownMenuItem>
              <DropdownMenuItem
                loading={loading}
                onAction={handleConfirmDelete}
                danger
                analyticsPage={AnalyticsPageStack.StackScheduling}
                analyticsTitle="Schedule Delete Clicked"
                analyticsProps={{ type: SCHEDULING_TYPE.TASK }}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuEllipsis>
          )}
        </>
      }
      alwaysVisibleContent={
        isBlocked && (
          <Banner
            variant="danger"
            title="Scheduled Task jobs for a given stack are not scheduling:"
            fullWidth
          >
            The stack uses an unsupported public worker pool.
            <FeedbackActions>
              <Button
                variant="secondary"
                size="small"
                href={getDocsUrl("/concepts/stack/scheduling#scheduled-task")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more
              </Button>
            </FeedbackActions>
          </Banner>
        )
      }
    >
      <Box direction="column">
        <FormSummaryKeyValueElement name="Command">
          {integration.command}
        </FormSummaryKeyValueElement>

        {!isBlocked && isRecurring && integration.nextSchedule && (
          <FormSummaryKeyValueElement name="Starts in">
            {getNextSchedule(integration.nextSchedule)}
          </FormSummaryKeyValueElement>
        )}

        {integration.timestampSchedule && (
          <FormSummaryKeyValueElement name="Scheduled date">
            {formatDateTimeByLocale({
              date: fromUnixTime(integration.timestampSchedule),
              format: "dateTimeShort",
              renderTimezone: "UTC",
            })}
          </FormSummaryKeyValueElement>
        )}

        {integration.cronSchedule && (
          <FormSummaryKeyValueElement name="Scheduled">
            <Box direction="column">
              {integration.cronSchedule.map((item, i, { length }) => (
                <Typography key={`${item}.${i}`} variant="p-body2" tag="span">
                  {length > 1 && " •"} {cronsTrue.toString(item.trim())}
                </Typography>
              ))}
            </Box>
          </FormSummaryKeyValueElement>
        )}

        {integration.timezone && (
          <FormSummaryKeyValueElement name="Timezone">
            {integration.timezone}
          </FormSummaryKeyValueElement>
        )}
      </Box>
    </CollapsibleList>
  );
};

export default StackManageScheduledTaskListItem;
