import { Fullscreen, FullscreenExit } from "components/icons";
import "./styles.css";
import Tooltip from "ds/components/Tooltip";

type ChartFullScreenButtonProps = {
  fullScreen: boolean;
  toggleFullScreen: () => void;
};

const ChartFullScreenButton = (props: ChartFullScreenButtonProps) => {
  const { fullScreen, toggleFullScreen } = props;

  return (
    // It rerenders tooltip to hide it when mouseeffect can't be handled, because element is displaced
    <div key={String(fullScreen)}>
      <Tooltip
        on={(props) => (
          <span {...props} className="d3-chart-button-tooltip">
            <button
              className={`d3-chart-button d3-chart-button--${fullScreen ? "expanded" : "regular"}`}
              type="button"
              onClick={() => toggleFullScreen()}
            >
              {fullScreen ? <FullscreenExit /> : <Fullscreen />}
            </button>
          </span>
        )}
      >
        {fullScreen ? "Hide" : "Show"} full screen
      </Tooltip>
    </div>
  );
};

export default ChartFullScreenButton;
