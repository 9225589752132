type Highlight = {
  highlight: boolean;
  text: string;
};

const isHighlightArray = (object: Highlight) => object.highlight;

export const getTextHighlightsFuzzySort = (text: string, indices: Set<number>) =>
  text.split("").reduce<Highlight[]>((acc, letter, index) => {
    const accLast = acc.length > 0 ? acc[acc.length - 1] : acc[0];
    if (indices.has(index)) {
      if (accLast && isHighlightArray(accLast)) {
        accLast.text += letter;
      } else {
        acc.push({
          highlight: true,
          text: letter,
        });
      }
    } else {
      if (!accLast || isHighlightArray(accLast)) {
        acc.push({
          highlight: false,
          text: letter,
        });
      } else {
        accLast.text += letter;
      }
    }

    return acc;
  }, []);
