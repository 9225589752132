import { ReactNode } from "react";

import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";

type BulkActionsDrawerItemsProps = {
  selectedItemsCount: number;
  children: ReactNode;
};

const BulkActionsDrawerActionStepsItems = ({
  selectedItemsCount,
  children,
}: BulkActionsDrawerItemsProps) => {
  return (
    <DrawerBody gap="large" fullHeight>
      <Typography tag="h5" variant="p-t5">
        <Box gap="medium" align="center">
          Selected items
          <Counter size="small" count={selectedItemsCount} />
        </Box>
      </Typography>

      {children}
    </DrawerBody>
  );
};
export default BulkActionsDrawerActionStepsItems;
