import Box, { BoxProps } from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { CollapsiblePanelContext } from "./Context";

type CollapsiblePanelContent = {
  alwaysVisible?: boolean;
} & BoxProps;

function CollapsiblePanelContent({ children, alwaysVisible, ...rest }: CollapsiblePanelContent) {
  const { isCollapsed, contentId } = useTypedContext(CollapsiblePanelContext);

  return (
    <Box
      className={styles.collapsible}
      grid
      gridTemplate="1fr"
      aria-hidden={!alwaysVisible && isCollapsed}
      id={alwaysVisible ? undefined : contentId}
    >
      <Box className={styles.collapsibleContent}>
        <Box fullWidth direction="column" {...rest}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default CollapsiblePanelContent;
