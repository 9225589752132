import { memo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";

import { AddGroupFields } from "./types";
import useAddGroup from "./useAddGroup";
import { ADD_GROUP_DRAWER_TEST_ID } from "./constants";
import { getManagementStrategy } from "../../helpers";
import { SettingsContext } from "../../Context";

type IdpGroupMappingMapGroupDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
};

const IdpGroupMappingMapGroupDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
}: IdpGroupMappingMapGroupDrawerProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const groupMapForm = useForm<AddGroupFields>({
    defaultValues: {
      name: "",
      spaces: [{ space: undefined, spaceAccessLevel: undefined }],
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = groupMapForm;

  const { onCreate } = useAddGroup();

  const handleCloseDrawer = () => {
    trackSegmentAnalyticsEvent("Close map IdP group");
    setDrawerVisibility(false);
    reset();
  };

  const onSubmit: SubmitHandler<AddGroupFields> = (formData) => {
    trackSegmentAnalyticsEvent("Submit map IdP group");

    const input = {
      groupName: formData.name,
      accessRules: formData.spaces,
    };
    onCreate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Group access was added`,
          });

          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  const isNameFieldEmpty = watch("name").length === 0;

  return (
    <Drawer
      visible={isDrawerVisible}
      onOutsideClick={handleCloseDrawer}
      position="fixedRight"
      dataTestId={ADD_GROUP_DRAWER_TEST_ID}
    >
      <FormProvider {...groupMapForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Map IdP group" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight hasStickyFooter>
          <FormField
            label="Group name"
            tooltipMode="parent"
            tooltipInfo={
              <>
                <TooltipModalTitle>Group name</TooltipModalTitle>
                <TooltipModalBody align="start">
                  Type in any group name that you have defined in your IdP (e.g. GitHub). It has to
                  be exactly the same.
                  <Link
                    href={getDocsUrl("/concepts/user-management/user.html#groups")}
                    target="_blank"
                  >
                    Learn more
                  </Link>
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
            error={errors?.name?.message}
            helperText="Name needs to match the one from your IdP"
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter the name here"
                error={!!errors?.name}
                {...register("name", {
                  required: "Name field is required.",
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldSpace
            isDisabled={!!errors?.name || isNameFieldEmpty}
            analyticsPage={AnalyticsPageOrganization.OrganizationIdpGroupMapping}
          />

          <DrawerFooter sticky>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                Add
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(IdpGroupMappingMapGroupDrawer);
