import { AccountViewer } from "components/form/graphql/types";
import { Stack } from "types/generated";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";

/**
 * Check if the current user can trigger the stack, checks all the conditions
 * */
export const canTriggerStackStrict = (stack: Stack, viewer: AccountViewer | undefined): boolean =>
  !stack.isDisabled && !stack.vcsDetached && (canUnlockStack(stack, viewer) || canLockStack(stack));

/**
 * Check if the current user can trigger the stack
 * Allows to show the trigger button to inform the user on the disabled state conditions
 * Make sure that you test for other conditions when using this check
 * */
export const canTriggerStack = (stack: Stack, viewer: AccountViewer | undefined): boolean =>
  canUnlockStack(stack, viewer) || canLockStack(stack);
