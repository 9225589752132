import { useCallback, useEffect, useRef } from "react";
import { Bar } from "@visx/shape";
import { localPoint } from "@visx/event";
import { Point } from "d3-dag/dist/dag";

type LineChartOverlayProps = {
  onMouseEnter: (coordinates: Point | null) => void;
  onMouseLeave: () => void;
  yMax: number;
  xMax: number;
};

const MOUSE_LEAVE_TIMEOUT = 200;

const LineChartOverlay = ({ onMouseEnter, onMouseLeave, yMax, xMax }: LineChartOverlayProps) => {
  const mouseLeaveTimeoutId = useRef<number>();

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearTimeout(mouseLeaveTimeoutId.current);
    };
  }, []);

  const handleMouseLeave = useCallback(() => {
    mouseLeaveTimeoutId.current = window.setTimeout(() => {
      onMouseLeave();
    }, MOUSE_LEAVE_TIMEOUT);
  }, [onMouseLeave]);

  const mouseEnterHandler = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current);

      onMouseEnter(localPoint(event));
    },
    [onMouseEnter]
  );

  return (
    <Bar
      x={0}
      y={0}
      width={xMax}
      height={yMax}
      fill="transparent"
      onMouseLeave={handleMouseLeave}
      onMouseMove={mouseEnterHandler}
    />
  );
};

export default LineChartOverlay;
