import { ReactNode } from "react";

import useTypedFlags from "hooks/useTypedFlags";
import Button from "ds/components/Button";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import Dropdown from "ds/components/Dropdown";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { ChevronNew, Expand } from "components/icons";
import { DocsUrl } from "utils/getDocsUrl";

import { KnowledgeBaseItemActionProps } from "./content";

type KnowledgeBaseTileIndicatorProps = {
  title: string;
  buttonTitle: string;
  onDrawer: (content: ReactNode, docsLink: DocsUrl) => void;
} & KnowledgeBaseItemActionProps;

const ANALYTICS_TITLE = "Knowledge Base Click";

const LaunchpadKnowledgeBaseTileIndicator = ({
  title,
  buttonTitle,
  buttonLink,
  drawerContent,
  onDrawer,
  externalLinks,
  drawerFullDocs,
  openInNewTab,
}: KnowledgeBaseTileIndicatorProps) => {
  const { policyTemplatesFrontend } = useTypedFlags();

  const dropdownClickHandler = (url: string, closeDropdown: () => void) => () => {
    window.open(url, "_blank");
    closeDropdown();
  };

  if (buttonLink) {
    const link = buttonLink(location.origin, policyTemplatesFrontend);
    const href = link.startsWith(location.origin) ? undefined : link;
    const to = link.startsWith(location.origin) ? link.replace(location.origin, "") : undefined;

    return (
      <Button
        variant="secondary"
        size="small"
        target={openInNewTab && openInNewTab(policyTemplatesFrontend) ? "_blank" : undefined}
        href={href}
        to={to}
        analyticsPage={AnalyticsPageLaunchpad.Launchpad}
        analyticsTitle={ANALYTICS_TITLE}
        analyticsProps={{ title }}
        endIcon={openInNewTab && openInNewTab(policyTemplatesFrontend) ? Expand : undefined}
      >
        {buttonTitle}
      </Button>
    );
  }

  if (drawerContent) {
    return (
      <Button
        variant="secondary"
        size="small"
        onClick={() => {
          onDrawer?.(drawerContent, drawerFullDocs);
        }}
        analyticsPage={AnalyticsPageLaunchpad.Launchpad}
        analyticsTitle={ANALYTICS_TITLE}
        analyticsProps={{ title }}
      >
        {buttonTitle}
      </Button>
    );
  }

  if (externalLinks) {
    return (
      <Dropdown
        renderTriggerComponent={({ onClick, ariaProps }) => (
          <Button
            variant="secondary"
            onClick={onClick}
            endIcon={ChevronNew}
            endIconRotate="90"
            {...ariaProps}
          >
            {buttonTitle}
          </Button>
        )}
      >
        {({ closeDropdown }) => (
          <DropdownSection>
            {externalLinks.map(({ title: linkTitle, link }) => (
              <DropdownSectionItem
                key={`${title} - ${linkTitle}`}
                onClick={dropdownClickHandler(link, closeDropdown)}
                analyticsPage={AnalyticsPageLaunchpad.Launchpad}
                analyticsTitle={ANALYTICS_TITLE}
                analyticsProps={{ title: `${title} - ${linkTitle}` }}
              >
                {linkTitle}
              </DropdownSectionItem>
            ))}
          </DropdownSection>
        )}
      </Dropdown>
    );
  }

  return null;
};

export default LaunchpadKnowledgeBaseTileIndicator;
