import cx from "classnames";
import { Link } from "react-router-dom-v5-compat";
import { useQuery } from "@apollo/client";
import { useMemo, useId } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import { ChevronNew, Dots, MenuExpander } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";
import { LayoutContext } from "components/layout/Context";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import DropdownSection from "ds/components/Dropdown/Section";
import { AccountContext } from "views/AccountWrapper";
import { trackSegmentEvent } from "shared/Analytics";
import useTypedFlags from "hooks/useTypedFlags";
import { SideNavigationItemType } from "types/Navigation";
import SpaceliftLogo from "ds/components/SpaceliftLogo";

import { checkIsActive } from "./helpers";
import UserDropdown from "./UserDropdown";
import styles from "./styles.module.css";
import MenuItem from "./MenuItem";
import NotificationsMenuItem from "./NotificationsMenuItem";
import { useNavigationItemsConfig } from "./useNavigationItemsConfig";
import DropdownPortalled from "../../ds/components/Dropdown/DropdownPortalled";
import LaunchPadMenuItem from "./LaunchPadMenuItem";
import { GET_HAS_ANSIBLE_STACKS } from "./gql";
import FeaturebaseSH from "./FeaturebaseSH";
import HelpSection from "./HelpSection";

type SideNavigationProps = {
  onChange: (value: boolean) => void;
};

const MenuExpanderIcon = ({ className, ...restProps }: { className?: string }) => (
  <MenuExpander className={cx(styles.expanderIcon, className)} {...restProps} />
);

const isSaas = isSaasDistribution();
const isSelfHosted = isSelfHostedDistribution();

const SideNavigation = ({ onChange }: SideNavigationProps) => {
  const sideMenuId = useId();
  const { isExpandedMode } = useTypedContext(LayoutContext);
  const { viewer } = useTypedContext(AccountContext);
  const { ansibleConfigurationManagementFrontend } = useTypedFlags();

  const isAdmin = viewer.admin;

  const handleToggleMode = () => onChange(!isExpandedMode);

  const { data } = useQuery(GET_HAS_ANSIBLE_STACKS, {
    skip: !ansibleConfigurationManagementFrontend,
  });

  const { hiddenItems, visibleItems } = useNavigationItemsConfig();

  const moreItemsActiveRule = (path: string) =>
    !!hiddenItems.find(({ rule }) => checkIsActive(path, rule));

  const itemsIdConditions: Partial<Record<SideNavigationItemType, boolean>> = useMemo(() => {
    return {
      [SideNavigationItemType.Resources]: !!data?.hasAnsibleStacks,
    };
  }, [data?.hasAnsibleStacks]);

  return (
    <div
      id={sideMenuId}
      className={cx(styles.sideNavigationWrapper, { [styles.expanded]: isExpandedMode })}
    >
      <div className={styles.sideNavigation}>
        <ButtonIcon
          className={cx(styles.toggleButton, { [styles.expanded]: isExpandedMode })}
          onClick={handleToggleMode}
          variant="secondary"
          size="small"
          icon={MenuExpanderIcon}
          disableTooltip
          aria-expanded={isExpandedMode}
          aria-controls={sideMenuId}
        >
          Toggle side navigation mode
        </ButtonIcon>
        <Link
          onClick={() => trackSegmentEvent("Spacelift logo click")}
          className={styles.logo}
          to="/"
          aria-label="Spacelift homepage"
        >
          <SpaceliftLogo short={!isExpandedMode} />
        </Link>

        <div className={styles.menuWrapper}>
          <Box gap="medium" direction="column">
            {visibleItems.map(({ icon, to, rule, title, beta, id, type }) => (
              <MenuItem
                id={id?.(itemsIdConditions[type])}
                beta={beta}
                key={to}
                short={!isExpandedMode}
                icon={icon}
                to={to}
                rule={rule}
                ariaLabel={title}
              >
                {title}
              </MenuItem>
            ))}
            {hiddenItems.length ? (
              <DropdownPortalled
                listClassName={styles.dropdown}
                position="right"
                renderTriggerComponent={({ onClick, ref, isVisible }) => (
                  <MenuItem
                    tooltipDisabled={isVisible}
                    short={!isExpandedMode}
                    ref={ref}
                    icon={Dots}
                    onClick={onClick}
                    rule={moreItemsActiveRule}
                    ariaLabel="More features"
                  >
                    <Box justify="between" grow="1" gap="small" align="center">
                      More features
                      {isExpandedMode && <Icon src={ChevronNew} />}
                    </Box>
                  </MenuItem>
                )}
              >
                {({ closeDropdown }) => (
                  <DropdownSection>
                    {hiddenItems.map(({ icon, to, title, rule, beta, id, type }) => (
                      <MenuItem
                        id={id?.(itemsIdConditions[type])}
                        beta={beta}
                        onMouseUp={() => {
                          closeDropdown();
                        }}
                        tooltipDisabled
                        key={to}
                        short={false}
                        icon={icon}
                        to={to}
                        rule={rule}
                        ariaLabel={title}
                      >
                        {title}
                      </MenuItem>
                    ))}
                  </DropdownSection>
                )}
              </DropdownPortalled>
            ) : null}
          </Box>

          <Box gap="medium" direction="column">
            {isAdmin && <LaunchPadMenuItem />}

            <NotificationsMenuItem />

            {isSelfHosted && <FeaturebaseSH />}
            {isSaas && <HelpSection />}

            <UserDropdown isExpanded={isExpandedMode} />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
